

import { API } from "aws-amplify"

export const mutationActionHandler = async (params, dispatch) =>{

  console.log('Initiating ', params)

  try {
    const response = await API.graphql(params)
    console.log('Response from API ', response)
    if(response) {
      console.log('Success ', response)
      const newState = dispatch(response)
    } else{
      console.error('Mutation error ', response.errors)
    }

  } catch (err) {

    console.error('General Error ',err.errors)
  }

}

export const qGetFormById = `query getCustomFormById($id: ID!) {
  customForm(id: $id) {
    id
    companyId
    name
    description
    route
    isPublished
    isDeleted
    createdAt
    updatedAt
    pages(limit: 100) {
      items {
        id
        order
        name
        description
        fields(limit: 100) {
          items {
            id
            type
            headerText
            subText
            order
            options
            properties {
              isRequired
              isVisible
              rowHeight
              maxLength
              defaultValue
              isDatePickerMonthYear
              currencyType
              minRange
              maxRange
            }
          }
        }
      }
    }
  }
}`


export const mCreatePage = `mutation createCustomFormPage($formId: ID!, $order: Int) {
  customFormPageCreate(
    formId: $formId
    order: $order
  ) {
    id
    order
  }
}`


export const mUpdatePage = `mutation updateCustomFormPage($id: ID!, $formId: ID!, $name: String, $description: String) {
  customFormPageUpdate(
    id: $id
    formId: $formId
    description: $description
    name: $name
  ) {
    id
  }
}`

export const mUpdatePageOrder = `mutation updateCustomFormPage($id: ID!, $formId: ID!, $name: String, $description: String, $order: Int) {
  customFormPageUpdate(
    id: $id
    formId: $formId
    description: $description
    name: $name
    order: $order
  ) {
    id
  }
}`


export const mDeletePage = `mutation deleteCustomFormPage($formId: ID = "e09da3bd-5cdd-4795-9124-6f4e6d3316ba", $id: ID = "2ae6c995-550b-4e01-ade5-8b61d0f1015c") {
  customFormPageDelete(id: $id, formId: $formId)
}`


export const mBulkReorderPage = `mutation bulkUpdateCustomFormPageOrder($arrangement: [ArrangementInput], $formId: ID!) {
  customFormPageBulkUpdateOrders(arrangement: $arrangement, formId: $formId) {
    id
  }
}`


export const mCreateField = `mutation createCustomFormField($pageId: ID!, $type: ColumnType, $order: Int, $options: [String], $properties: CustomFormFieldPropertiesInput) {
  customFormFieldCreate(
    order: $order
    pageId: $pageId
    options: $options
    type: $type
    properties: $properties
  ) {
    id
    order
  }
}`


export const mUpdateField = `mutation updateCustomFormField($id: ID!, $pageId: ID!, $headerText: String, $subText: String) {
  customFormFieldUpdate(
    pageId: $pageId
    id: $id
    headerText: $headerText
    subText: $subText
  ) {
    id
  }
}`

export const mUpdateOptionsType = `mutation updateCustomFormField($id: ID!, $pageId: ID!, $type: ColumnType, $options: [String], $properties: CustomFormFieldPropertiesInput) {
  customFormFieldUpdate(
    pageId: $pageId
    id: $id
    type: $type
    options: $options
    properties: $properties
  ) {
    id
    properties {
      isRequired
      isVisible
      rowHeight
      maxLength
      defaultValue
      isDatePickerMonthYear
      currencyType
      minRange
      maxRange
    }
  }
}`


export const mUpdateFieldOrder = `mutation updateCustomFormField($id: ID!, $pageId: ID!, $order: Int) {
  customFormFieldUpdate(
    pageId: $pageId
    id: $id
    order: $order
  ) {
    id
  }
}`


export const mUpdateOptions = `mutation updateCustomFormField($id: ID!, $pageId: ID!, $options: [String]) {
  customFormFieldUpdate(
    pageId: $pageId
    id: $id
    options: $options
  ) {
    id
  }
}`


export const mUpdateProperties = `mutation updateCustomFormField($id: ID!, $pageId: ID!, $properties: CustomFormFieldPropertiesInput) {
  customFormFieldUpdate(
    pageId: $pageId
    id: $id
    properties: $properties
  ) {
    id
    properties {
      isRequired
      isVisible
      rowHeight
      maxLength
      defaultValue
      isDatePickerMonthYear
      currencyType
      minRange
      maxRange
    }
  }
}`


export const mDeleteField = `mutation deleteCustomFormField($pageId: ID!, $id: ID!) {
  customFormFieldDelete(id: $id, pageId: $pageId)
}`


export const mBulkReorderField = `mutation bulkUpdateCustomFormFieldOrder($arrangement: [ArrangementInput], $pageId: ID!) {
  customFormFieldBulkUpdateOrders(pageId: $pageId, arrangement: $arrangement) {
    id
  }
}`


export const qListForm = `query getCustomFormsByCompany($companyId: ID!, $isDeleted: Boolean = false) {
  customFormsByCompany(companyId: $companyId, isDeleted: $isDeleted) {
    id
    companyId
    name
    description
    route
    isPublished
    isDeleted
    createdAt
    updatedAt
    pages(limit: 100) {
      items {
        id
        name
        order
        section(limit: 100) {
          items {
            id
            name
            order
            fields(limit: 100) {
              items {
                id
                type
                headerText
                order
                options {
                  items {
                    optionText
                    order
                  }
                }
                properties {
                  items {
                    defaultValue
                    isRequired
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`

// export const testHandler = async (dispatch, mutation) =>{

//   try {
//     const mutationResponse = mutation
//     //console.log(mutationResponse)

//     if(mutationResponse.success) {
//       console.log('Success ',mutationResponse)
//       const newState = dispatch();
//     } else{
//       console.log('FAILED ',mutationResponse)
//     }

//   } catch (err) {

//     console.error(err)
//   }
  
// }