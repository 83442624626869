import { API } from "aws-amplify";

/** Start Background List */
const BRIEFS_QUERY = `
    query getBriefsByClientMatter($id: ID) {
      clientMatter(id: $id) {
        briefs{
          items {
            id
            name
            userTypeAccess
            date
            order
            createdAt
            createdBy {
              id
              firstName
              lastName
            }
            updatedAt
            updatedBy {
              id
              firstName
              lastName
            }
            backgrounds(limit: 50, sortOrder: ORDER_ASC) {
              items {
                id
                description
                date
                dynamicData
                files{
                  items{
                    id
                    name
                  }
                }
              }
            }
            hasCommentAccess
            columnIds
            phase
          }
        }
      }
    }
    `;

const ARCHIVED_BRIEFS_QUERY = `
query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs(isDeleted: true){
      items {
        id
        name
        userTypeAccess
        date
        order
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        hasCommentAccess
        columnIds
      }
    }
  }
}
`;

export async function getArchivedBgList(matter_id) {
  try {
    const params = {
      query: ARCHIVED_BRIEFS_QUERY,
      variables: { id: matter_id },
    };

    const response = await API.graphql(params);
    return response?.data?.clientMatter?.briefs?.items;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}

export async function getBgList(matter_id) {
  try {
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: matter_id,
      },
    };
    const response = await API.graphql(params);
    return response?.data?.clientMatter?.briefs?.items;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}
