import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { API, Auth, Storage } from "aws-amplify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ScrollToTop from "react-scroll-to-top";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import ReactDOM from "react-dom";
import dateFormat from "dateformat";
import Select from "react-dropdown-select";
import { getBgList } from "../../shared/graphql/Briefs/queries";
// import { useIdleTimer } from "react-idle-timer";
import {
  List,
  WindowScroller,
  AutoSizer,
  defaultTableHeaderRenderer,
  defaultTableRowRenderer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Table,
} from "react-virtualized";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import config from "../../aws-exports";
import html2pdf from "html2pdf.js";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";

import { Base64 } from "js-base64";

// Icons
import { CgTrash } from "react-icons/cg";
import * as IoIcons from "react-icons/io";
import { FaEye, FaTachometerAlt, FaSlash } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { AiFillTags, AiOutlineDownload } from "react-icons/ai";
import {
  BsArrowLeft,
  BsFillTrashFill,
  BsSortDown,
  BsSortUpAlt,
} from "react-icons/bs";
import { FaRegFileAudio, FaRegFileVideo, FaSort } from "react-icons/fa";
import {
  FiChevronDown,
  FiChevronUp,
  FiChevronsDown,
  FiChevronsUp,
  FiCopy,
  FiUpload,
} from "react-icons/fi";
import {
  // GrDocument,
  GrDocumentExcel,
  GrDocumentImage,
  GrDocumentPdf,
  GrDocumentText,
  GrDocumentTxt,
  GrDocumentWord,
} from "react-icons/gr";
import { MdArrowBackIos, MdDragIndicator } from "react-icons/md";
import { BiArrowToTop } from "react-icons/bi";

import { AppRoutes } from "../../constants/AppRoutes";
import BlankState from "../blank-state";
import BlankStateMobile from "../mobile-blank-state";
import FilterLabels from "./filter-labels-modal";
import Loading from "../loading/loading";
import NoResultState from "../no-result-state";
import RemoveFileModal from "./remove-file-modal";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import ToastNotification from "../toast-notification";
import UploadLinkModal from "./file-upload-modal";
// import ellipsis from "../../shared/ellipsis";
import isMSdoc from "../../shared/msFileTypeChecker";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import AccessControl from "../../shared/accessControl";
import Illustration from "../../assets/images/no-data.svg";
import googleLogin from "../../assets/images/gmail-print.png";
import "../../assets/styles/Mobile.css";
import "../../assets/styles/BlankState.css";
import "../../assets/styles/custom-styles.css";
import "../../assets/styles/FileBucket.css";
import "../../assets/styles/Inbox.css";
import MobileEmailPreview from "./MobileEmailPreview";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { CgChevronLeft } from "react-icons/cg";
import Button from "../../shared/Button";
import MobileDescriptionModal from "./mobile-description-preview";
import { updateBackgroundOrders } from "../../shared/graphql/Backgrounds/mutations";
import {
  dateFormatter,
  timezoneFormatter,
  datePickerDateFormatter,
} from "../../shared/userPreferredDateTime";

export var selectedRows = [];
export var selectedCompleteDataRows = [];
export var pageSelectedLabels;

export default function FileBucket() {
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [files, setFiles] = useState(null);
  const [matterFiles, setMatterFiles] = useState(files);
  const [labels, setLabels] = useState(null);
  const [clientMatterName, setClientMatterName] = useState("");
  const [updateProgess, setUpdateProgress] = useState(false);
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState("");
  const [fileAlert, setFileAlert] = useState("");
  const [descAlert, setDesAlert] = useState("");
  const [fileId, setFileId] = useState("");
  const [detId, setDetId] = useState("");
  const [textName, setTextName] = useState("");
  const [textDetails, setTextDetails] = useState("");
  const { matter_id, background_id } = useParams();
  const [searchFile, setSearchFile] = useState();
  // const [filterLabelsData, setFilterLabelsData] = useState([]);
  // const [pageTotal, setPageTotal] = useState(0);
  // const [pageSize, setPageSize] = useState(20);
  // const [pageIndex, setPageIndex] = useState(1);
  const [vNextToken, setVnextToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [maxLoading, setMaxLoading] = useState(false);
  const [currentUserType, setCurrentUserType] = useState("");

  const [ascDesc, setAscDesc] = useState(null);
  const [SubAscDesc, setSubAscDesc] = useState(null);
  const [sortOrder, setSortOrder] = useState("ORDER_ASC");
  const [pageReferenceFileId, setPageReferenceFileId] = useState("");
  const [pageReferenceBackgroundId, setPageReferenceBackgroundId] =
    useState("");
  const [pageReferenceClientMatter, setPageReferenceClientMatter] =
    useState("");
  const [pageReferenceDescription, setPageReferenceDescription] = useState("");
  const [pageReferenceRowOrder, setPageReferenceRowOrder] = useState("");
  const [isShiftDown, setIsShiftDown] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [descriptionClass, setDescriptionClass] = useState(true);
  const [descriptionClassId, setDescriptionClassId] = useState("");

  const [showRemoveFileModal, setshowRemoveFileModal] = useState(false);
  const [showRemoveFileButton, setshowRemoveFileButton] = useState(false);
  const [showAttachBackgroundButton, setshowAttachBackgroundButton] =
    useState(false);
  const [showCopyToBackgroundButton, setShowCopyToBackgroundButton] =
    useState(false);
  var fileCount = 0;

  const [filterLabels, setFilterLabels] = useState(false);
  const [deletingState, setDeletingState] = useState(false);

  const [filterModalState, setFilterModalState] = useState(true);

  const [filteredFiles, setFilteredFiles] = useState(null);
  const [filterState, setFilterState] = useState(false);

  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();

  const [copyOptions, showCopyOptions] = useState(false);
  const [textDesc, setTextDesc] = useState("");

  const [Briefs, setBriefs] = useState(null);
  const [copyBgOptions, setCopyBgOptions] = useState(null);
  const [copyBgIds, setCopyBgIds] = useState(null);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const itemsRef = useRef([]);

  let history = useHistory();
  const bindList = useRef(null);

  // const [briefNames, setBriefNames] = useState(null);
  const [ShowLabel, setShowLabel] = useState([{ index: -1 }]);
  const [DisableSelect, setDisableSelect] = useState(false);

  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [activeGmailMessageId, setActiveGmailMessageId] = useState(null);
  const [emailPreview, setEmailPreview] = useState([]);
  const [emailPreviewFrom, setEmailPreviewFrom] = useState(null);
  const [emailPreviewDate, setEmailPreviewDate] = useState(null);
  const [emailPreviewSubject, setEmailPreviewSubject] = useState(null);
  const [emailPreviewTo, setEmailPreviewTo] = useState(null);
  const [emailPreviewCc, setEmailPreviewCC] = useState(null);
  const [showIndex, setShowIndex] = useState(-1);

  const [allowEditDate, setAllowEditDate] = useState(false);
  const [allowEditDescription, setAllowEditDescription] = useState(false);
  const [allowEditLabels, setAllowEditLabels] = useState(false);
  const [allowEditFiles, setAllowEditFiles] = useState(false);
  const [allowEditFileName, setAllowEditFileName] = useState(false);
  const [allowDeleteRows, setAllowDeleteRows] = useState(false);
  const [allowShowLabels, setAllowShowLabels] = useState(false);

  // For Mobile Email Preview
  const [showEmail, setShow] = useState(false);
  const [preview, setPreview] = useState([]);
  const [from, setFrom] = useState(null);
  const [dateEmail, setDateEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCC] = useState(null);
  const [snippetId, setSnippetId] = useState(null);
  const [isGmailPDF, setIsGmailPDF] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [newLabelDropdown, setNewLabelDropdown] = useState(false);

  var moment = require("moment");

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  const hideToast = () => {
    setShowToast(false);
  };

  // Getting User Access Type
  const userId = localStorage.getItem("userId");

  const mgetUserById = `
    query user($id: String) {
      user(id: $id) {
        userType
      }
    }
  `;
  const getUserById = async (userId) => {
    const response = await API.graphql({
      query: mgetUserById,
      variables: {
        id: userId,
      },
    }).then((response) => {
      setCurrentUserType(response.data.user.userType);
    });
  };

  const qGetBackgroundsByBriefId = `query getBriefByID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
    brief(id: $id) {
      id
      name
      columnIds
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          description
          comments
          date
          createdAt
          order
          dynamicData
          email
          files {
            items {
              id
              name
              details
              gmailMessageId
              gmailMessage {
                gmailLabelIds
              }
              isGmailPDF
              isGmailAttachment
              s3ObjectKey
              order
              createdAt
            }
          }
          briefs {
            id
            name
          }
          labels {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
    }
  }
  `;

  const mCreateActivity = `
    mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
      activityCreate(
        activity: $activity
        briefId: $briefId
        clientMatterId: $clientMatterId
        companyId: $companyId
        previous: $previous
        field: $field
        current: $current
        appModule: $appModule
        rowId: $rowId
      ) {
        id
      }
    }`;

  useEffect(() => {
    getUserById(userId);

    // Add tracker for when page has been accessed.
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: matter_id,
        activity: `page has been accessed.`,
        appModule: "FILEBUCKET",
        userId: localStorage.getItem("userId"),
      },
    };
    const addActivity = API.graphql(params).then((result) => {
      console.log("addActivity page access recorded", result);
    });
  }, []);

  //MMA-1953 Background (File Bucket): Default Background Columns are not auto-tagging for Labels created at the File Bucket
  const BACKGROUND_TABLE_QUERY = `
    query getBackgroundTable($clientMatterId: ID!) {
        backgroundTable(clientMatterId: $clientMatterId) {
          id
          columns {
            id
            accessorKey
            headerText
            enabled
            type
            optionsText
            order
            presets {
              id
              name
            }
          }
          createdAt
          updatedAt
        }
    }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
    mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
      backgroundTableUpdate(id: $id, input: $input) {
        id
        columns {
          id
          accessorKey
          headerText
          type
          enabled
          optionsText
          order
          presets {
            id
            name
          }
        }
      }
    }
    `;

  const updatePresetsForNewlyCreatedBrief = async (brief, briefName) => {
    let createdBriefId = brief.data.briefCreate.id;

    const backgroundTable = await API.graphql({
      query: BACKGROUND_TABLE_QUERY,
      variables: {
        clientMatterId: matter_id,
      },
    });

    if (backgroundTable?.data?.backgroundTable) {
      const { id, columns } = backgroundTable?.data?.backgroundTable;

      const updatedBriefs = columns.map((obj) => {
        if (
          (parseInt(obj?.accessorKey) !== 3 &&
            obj?.presets !== null &&
            obj.headerText == "Gmail") ||
          obj.headerText == "Label" ||
          obj.headerText === "Email Date" ||
          obj.headerText === "Description of Background" ||
          obj.headerText === "Document" ||
          obj.headerText === "Date"
        ) {
          const presetsToUse = [
            ...obj?.presets?.filter((preset) => preset?.id !== createdBriefId),
            { id: createdBriefId, name: briefName },
          ];

          console.log("Created Presets for new Brief:", presetsToUse);
          return { ...obj, presets: presetsToUse };
        } else {
          return obj;
        }
      });

      const variables = {
        id,
        input: {
          columns: updatedBriefs,
        },
      };

      const updateBriefTable = await API.graphql({
        query: UPDATE_BACKGROUND_TABLE_MUTATION,
        variables,
      });

      console.log("updateBriefTable", updateBriefTable);
    }
  };

  // Reapply table after getting User Access Type
  useEffect(() => {
    getMatterFiles(1);
    featureAccessFilters();
  }, [currentUserType]);

  const [showSessionTimeout, setShowSessionTimeout] = useState(false);

  const previewAndDownloadFile = async (id) => {
    console.log("preview and download file: ", id);
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      console.log("preview: ", result.data.file);
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
        console.log("downloadURL", downloadURL);
      }
    });
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const getEmailPayload = async (messageId) => {
    console.log("getEmailPayload()");
    const params = {
      query: qGetMessagePayload,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);

    return result?.data?.gmailMessage;
  };

  const previewAndDownloadPDF = async (id, gmailMessageId, subjectTitle) => {
    // For PDF creation

    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: config.aws_user_gmail_attachments_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    var opt = {
      margin: [30, 50, 30, 50],
      filename: subjectTitle,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        dpi: 96,
        scale: 1,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: "#FFF",
      },
      jsPDF: { unit: "pt", format: "a4", orientation: "p" },
      pagebreak: {
        before: ".page-break",
        mode: ["avoid-all", "css", "legacy"],
      },
    };

    var content = `<span>`;
    content += `<img src=${googleLogin} alt="" /><hr></hr>`;
    content += `<h2><b>${subjectTitle}</b></h2><hr></hr><br />`;
    content += `<p>From: ${from}</p>`;
    content += `<p>Date: ${moment(dateEmail).format(
      "DD MMM YYYY, hh:mm A"
    )}</p>`;
    content += `<p>To: ${to}</p>`;
    content += cc ? `<p>CC: ${cc}</p>` : "";
    content += `</span>`;

    const emailPayload = await getEmailPayload(gmailMessageId);

    if (emailPayload) {
      const htmlContent = emailPayload.payload
        .map((em) => em.content)
        .join("")
        .split('data":"')
        .pop()
        .split('"}')[0];

      content += Base64.decode(htmlContent).replace("body{color:", "");

      await html2pdf()
        .from(content)
        .set(opt)
        .toPdf()
        .output("datauristring")
        .then(function (pdfAsString) {
          const preBlob = dataURItoBlob(pdfAsString);
          const file = new File([preBlob], subjectTitle, {
            type: "application/pdf",
          });
          console.log(file);

          var key = `${gmailMessageId}/${Number(new Date())}${file.name
              .replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            type = "application/pdf",
            size = file.size;

          // put objects to s3
          try {
            Storage.put(key, file, {
              contentType: type,
              progressCallback(progress) {
                console.log(progress);
              },
              errorCallback: (err) => {
                console.error("204: Unexpected error while uploading", err);
              },
            })
              .then((fd) => {
                const mUpdateMatterFileObjectKey = `
                    mutation updateMatterFile ($id: ID, $s3ObjectKey: String, $size: Int, $type: String) {
                      matterFileUpdate(id: $id, s3ObjectKey: $s3ObjectKey, size: $size, type: $type) {
                        id
                      }
                    }
                `;

                const params = {
                  query: mUpdateMatterFileObjectKey,
                  variables: {
                    id: id,
                    s3ObjectKey: fd.key,
                    size: parseInt(size),
                    type: type,
                  },
                };

                API.graphql(params).then(async (res) => {
                  console.log("Get Details", res);
                  const params = {
                    query: qGetFileDownloadLink,
                    variables: {
                      id: id,
                    },
                  };
                  await API.graphql(params).then(async (result) => {
                    const { type, downloadURL, s3ObjectKey } = result.data.file;
                    window.open(downloadURL);
                  });
                });
              })
              .catch((err) => {
                console.error("Unexpected error while uploading", err);
              });
          } catch (e) {
            const response = {
              error: e.message,
              errorStack: e.stack,
              statusCode: 500,
            };
            console.error("Unexpected error while uploading", response);
          }
        });
    }
  };

  const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
    gmailMessage(id: $id) {
      payload {
        content
      }
    }
  }
  `;

  const mBulkCreateMatterFile = `
        mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
          matterFileBulkCreate(files: $files) {
            id
            name
            order
          }
        }
    `;

  const mUpdateBulkMatterFileOrder = `
    mutation bulkUpdateMatterFileOrders($arrangement: [ArrangementInput]) {
      matterFileBulkUpdateOrders(arrangement: $arrangement) {
        id
        order
      }
    }
    `;

  const getName = `
  query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          date
          order
        }
      }
    }
  }
  `;

  const qBriefBackgroundList = `
    query getBriefByID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
      brief(id: $id) {
        id
        name
        userTypeAccess
        backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
          items {
            id
            description
            comments
            date
            createdAt
            order
            files {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
      }
    }
  `;

  const qBriefUserTypeAccess = `
  query briefUserTypeAccess {
    brief(id:"5e787f3a-d523-472f-a290-12f8c400db73"){
      id
      name
      userTypeAccess
    }
  }
  `;

  const handleUploadLink = async (uf) => {
    var uploadedFiles = uf?.files?.map((f) => ({
      ...f,
      matterId: matter_id,
    }));
    window.scrollTo(0, 0);
    //adjust order of existing files
    let tempMatter = [...matterFiles];
    const result = tempMatter.map(({ id }, index) => ({
      id: id,
      order: index + uploadedFiles.length,
    }));

    if (result && result.length > 0) {
      await API.graphql({
        query: mUpdateBulkMatterFileOrder,
        variables: {
          arrangement: result,
        },
      });
    }

    //add order to new files
    var sortedFiles = uploadedFiles.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    console.log("Uploaded Files", sortedFiles);

    await createMatterFile(sortedFiles);

    setResultMessage(`File successfully uploaded!`);
    setShowToast(true);
    handleModalClose();
    setTimeout(() => {
      setShowToast(false);
      getMatterFiles(1);
    }, 3000);

    //don't delete for single upload
    // sortedFiles.map((file) => {
    //   createMatterFile(file);
    // });
  };

  const isBackgroundBriefNameExist = async (target) => {
    //query all background brief names
    console.log("isBackgroundBriefNameExist");
    console.log("TARGET", target);
    const params = {
      query: getName,
      variables: {
        id: matter_id,
        nextToken: null,
      },
    };
    const request = await API.graphql(params);
    console.log("getNames", request);

    //Check if 'background' is existing in briefs
    let backgroundExist = false;
    request.data.clientMatter.briefs?.items.map((item) => {
      if (
        String(item.name.toLowerCase().trim()) === String(target.toLowerCase())
      ) {
        backgroundExist = true;
      }
    });

    console.log("briefs backgroundExist:", backgroundExist);

    return backgroundExist;
  };

  const isBackgroundLabelExist = async (target) => {
    console.log("isBackgroundLabelExist()");
    console.log("TARGET", target);
    //query all labels
    const labelsOpt = await API.graphql({
      query: listLabels,
      variables: {
        clientMatterId: matter_id,
      },
    });

    console.log("getLabels", labelsOpt);

    //Check if 'background' is existing in labels
    let backgroundExist = false;
    labelsOpt.data.clientMatter.labels.items.map((item) => {
      if (
        String(item.name.toLowerCase().trim()) === String(target.toLowerCase())
      ) {
        backgroundExist = true;
      }
    });

    console.log("labels backgroundExist:", backgroundExist);

    return backgroundExist;
  };

  //getting labels
  const [labelOption, setLabelOption] = useState([]);

  const getLabelOption = async () => {
    // const labelsOpt = await API.graphql({
    //   query: getBgList,
    //   variables: {
    //     clientMatterId: matter_id,
    //   },
    // });

    // console.log("UPDATE LABELS", labelsOpt);

    await getBgList(matter_id).then(async (briefsList) => {
      console.log("UPDATE LABELS", briefsList);

      var temp = [];

      briefsList.map(
        (x) => (temp = [...temp, { value: x?.id, label: x?.name }])
      );

      const filteredItems = temp.filter((item) => !item.label.startsWith("_"));
      setLabelOption(filteredItems);
    });
  };

  useEffect(() => {
    getLabelOption();
  }, []);

  const getLabelbyName = async (target) => {
    console.group("getLabelbyName");
    const labelsOpt = await API.graphql({
      query: listLabels,
      variables: {
        clientMatterId: matter_id,
      },
    });

    console.log("labelsOpt", labelsOpt);

    const final = labelsOpt?.data?.clientMatter?.labels?.items.filter(
      (label) => {
        if (label.name.toLowerCase() === target.toLowerCase()) {
          return label;
        }
      }
    );
    console.groupEnd();
    return final[0]; //get the First result // if any duplicates, we don't have a label delete mutation
  };

  const getFileType = (type) => {
    if (type !== null) {
      return type.split("/").slice(0, -1).join("/");
    }
  };

  // const getBackgroundByBriefName = async (target, brief) => {
  //   console.group("getBackgroundByBriefName");
  //   console.log("TARGET", brief);

  //   const params = {
  //     query: qBriefBackgroundList,
  //     variables: {
  //       id: brief,
  //       nextToken: null,
  //       sortOrder: "ORDER_ASC",
  //     },
  //   };
  //   const request = await API.graphql(params);
  //   console.log("getNames", request);

  //   console.groupEnd();
  //   if (request?.data?.brief.name === target) {
  //     return request?.data?.brief?.backgrounds?.items[0].id;
  //   }
  //   //return final[0]; //get the First result // if any duplicates
  // };

  const getBriefByName = async (target) => {
    console.group("getBriefByName");
    console.log("TARGET", target);
    const request = await API.graphql({
      query: qBriefByName,
      variables: {
        clientMatterId: matter_id,
        name: target,
      },
    });
    console.log("getBriefs", request);
    console.groupEnd();
    return request.data?.briefByName?.id;
  };

  const bindMatterToDefaultLabel = async (matterid, brief) => {
    console.group("bindMatterToDefaultLabel");
    console.log("matterid", matterid);

    const createBackground = await API.graphql({
      query: mCreateBackground,
      variables: {
        briefs: [{ name: "Background", id: brief }],
        description: "",
        date: moment().utc().toISOString(),
        comments: "",
        order: 0,
      },
    });

    console.log("createBackground", createBackground);
    if (createBackground.data == null) {
      console.error("Failed to createBackground");
    }
    const updateBackground = await API.graphql({
      query: mUpdateBackgroundFile,
      variables: {
        backgroundId: createBackground.data.backgroundCreate.id,
        files: [{ id: matterid }],
      },
    });

    console.log("updateBackground", updateBackground);
    if (updateBackground.data == null) {
      console.error("Failed to updateBackground");
    }
    console.groupEnd();

    const backgroundOptNew = await API.graphql({
      query: qGetBackgroundsByBriefId,
      variables: {
        id: brief,
        nextToken: null,
        sortOrder: "ORDER_ASC",
        limit: null,
      },
    }).catch((error) => {
      console.log("ERROR FETCHING BACKGROUND DATA: RESTART PAGE");
    });

    if (backgroundOptNew?.data?.brief?.backgrounds?.items !== null) {
      var result = backgroundOptNew?.data.brief.backgrounds.items.map(
        ({
          id,
          description,
          comments,
          date,
          createdAt,
          order,
          files,
          labels,
          briefs,
          dynamicData,
          email,
        }) => ({
          createdAt: createdAt,
          id: id,
          description: description,
          comments: comments,
          date: date,
          order: order,
          files: files,
          labels: labels,
          briefs: briefs,
          email: email,
          dynamicData,
        })
      );

      if (result !== null) {
        const rowArrangement = result.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement);

        // For FE purposes: Update existing background array with the updated order data
        result.forEach((item, index) => {
          item.order = index + 1;
        });
      }
    }
  };

  // const getOrigFiles = async (target, backgroundid) => {
  //   const backgroundFilesOpt = await API.graphql({
  //     query: qlistBackgroundFiles,
  //     variables: {
  //       id: backgroundid,
  //     },
  //   });

  //   return backgroundFilesOpt.data.background.files.items.map((file) => {
  //     return { id: file.id };
  //   });
  // };

  async function createMatterFile(param) {
    param.forEach(function (i) {
      delete i.oderSelected;
    });

    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    console.log("createMatterFile", request);

    //Create label and background, brief if not existing
    if (!(await isBackgroundLabelExist("Background"))) {
      console.log("No Background Label Exist, Creating Label");
      const createLabel = await API.graphql({
        query: mCreateLabel,
        variables: {
          clientMatterId: matter_id,
          name: "Background",
        },
      });

      console.log("createLabel", createLabel);
      if (createLabel.data == null) {
        console.error("Failed to create label");
      }
      console.groupEnd();
    }

    if (!(await isBackgroundBriefNameExist("Background"))) {
      //
      console.group("No Background Brief Exist, Creating Brief");
      const label = await getLabelbyName("Background");

      const params = {
        clientMatterId: matter_id,
        name: label.name,
        date: null,
        order: 0,
        labelId: label.id,
      };

      const createBrief = await API.graphql({
        query: mCreateBrief,
        variables: params,
      });

      console.log("createBrief", createBrief);

      const createBackground = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefId: { id: createBrief.data.briefCreate.id, name: "Background" },
          description: "",
          comments: "",
          date: null,
        },
      });

      console.log("createBackground", createBackground);
      if (createBackground.data == null) {
        console.error("Failed to create background");
      }

      updatePresetsForNewlyCreatedBrief(createBrief, "Background");

      console.groupEnd();
    }

    const brief = await getBriefByName("Background");
    if (brief === null || brief === undefined || brief === "") {
      alert("534: Brief not found!");
    }
    //const background = await getBackgroundByBriefName("Background", brief);
    const label = await getLabelbyName("Background");
    if (label === null || label === undefined || label === "") {
      alert("539: Label not found!");
    }
    //const files = await getOrigFiles("Background", background);

    console.log("brief", brief);
    console.log("label", label);
    const updateBrief = await API.graphql({
      query: mUpdateBrief,
      variables: {
        id: brief,
        labelId: label.id,
      },
    });

    console.log("updateBrief", updateBrief);

    /*
    for (const matterFile of request?.data?.matterFileBulkCreate) {
        console.log("Binding File", matterFile.name, " to a 'Background' label");
        await bindMatterToDefaultLabel(matterFile.id, brief);
    }
    */

    request?.data?.matterFileBulkCreate.forEach(async (matterFile, idx) => {
      console.log("Binding File", matterFile.name, " to a 'Background' label");
      await bindMatterToDefaultLabel(matterFile.id, brief);

      if (idx === request?.data?.matterFileBulkCreate.length - 1) {
        console.log(
          "Last callback call at index " +
            idx +
            " with value " +
            matterFile.name
        );

        //making sure to refresh matterfiles when file upload is longer at last index
        setTimeout(() => {
          console.log("Refreshing Matter Files");
          getMatterFiles(1);
        }, 3000);
      }
    });

    //var labelsList = [];

    //auto create label named 'Background'

    /*
    if (request?.data?.matterFileBulkCreate) {
      request.data.matterFileBulkCreate.forEach(async (matterFile) => {
        const createLabel = await API.graphql({
          query: mCreateLabel,
          variables: {
            clientMatterId: matter_id,
            name: "Background",
          },
        });
        console.log("createLabel", createLabel);
        let updateLabel = labels;
        updateLabel.push({
          value: createLabel.data.labelCreate.id,
          label: createLabel.data.labelCreate.name,
        });
        setLabels(updateLabel);
        labelsList = [
          ...labelsList,
          {
            id: createLabel.data.labelCreate.id,
            name: createLabel.data.labelCreate.name,
          },
        ];
        createBackgroundFromLabel(
          matterFile.id,
          {
            id: createLabel.data.labelCreate.id,
            name: createLabel.data.labelCreate.name,
          },
          matterFile,
          true
        );
      });
    }
    */

    //createBackgroundFromLabel(request.data.matterFileBulkCreate.id);
    //don't delete for single upload
    // const request = API.graphql({
    //   query: mCreateMatterFile,
    //   variables: param,
    // });

    return request;
  }

  const handleModalClose = () => {
    setShowUploadModal(false);
    setshowRemoveFileModal(false);
    setFilterLabels(false);
  };

  const mCreateMatterFile = `
      mutation createMatterFile ($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int, $details: String) {
        matterFileCreate(matterId: $matterId, s3ObjectKey: $s3ObjectKey, size: $size, type: $type, name: $name, order: $order, details: $details) {
          id
          name
          order
        }
      }
  `;

  const mUpdateMatterFile = `
      mutation updateMatterFile ($id: ID, $name: String, $details: String, $labels : [LabelInput]) {
        matterFileUpdate(id: $id, name: $name, details: $details, labels : $labels ) {
          id
          name
          details
        }
      }
  `;

  const mUpdateMatterFileDesc = `
      mutation updateMatterFile ($id: ID, $details: String) {
        matterFileUpdate(id: $id, details: $details) {
          id
          details
        }
      }
  `;

  const mUpdateMatterFileName = `
      mutation updateMatterFile ($id: ID, $name: String) {
        matterFileUpdate(id: $id, name: $name) {
          id
          name
        }
      }
  `;

  const mUpdateMatterFileDate = `
      mutation updateMatterFile ($id: ID, $date: AWSDateTime) {
        matterFileUpdate(id: $id, date: $date) {
          id
          date
        }
      }
  `;

  const mSoftDeleteMatterFile = `
      mutation softDeleteMatterFile ($id: ID) {
        matterFileSoftDelete(id: $id) {
          id
        }
      }
  `;

  const qGetFileDownloadLink = `
  query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const listLabels = `
query listLabels($clientMatterId: ID) {
  clientMatter(id: $clientMatterId) {
    labels {
      items {
        id
        name
      }
    }
  }
}
`;

  const mCreateLabel = `
  mutation createLabel($clientMatterId: String, $name: String) {
      labelCreate(clientMatterId:$clientMatterId, name:$name) {
          id
          name
      }
  }
`;

  const mTagFileLabel = `
mutation tagFileLabel($fileId: ID, $labels: [LabelInput]) {
  fileLabelTag(file: {id: $fileId}, label: $labels) {
    file {
      id
    }
  }
}
`;

  //   const mUpdateMatterFileOrder = `
  //     mutation updateMatterFile ($id: ID, $order: Int) {
  //       matterFileUpdate(id: $id, order: $order) {
  //         id
  //         order
  //       }
  //     }
  // `;

  const mUpdateBackgroundFile = `
    mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
      backgroundFileTag(backgroundId: $backgroundId, files: $files) {
        id
      }
    }
  `;

  // WITH PAGINAGTION

  const qGetFilesByMatter = `
query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
  matterFiles(isDeleted: $isDeleted, matterId: $matterId, nextToken: $nextToken, limit: $limit, sortOrder: $sortOrder) {
    items {
      id
      name
      details
      date
      s3ObjectKey
      gmailMessageId
      isGmailPDF
      labels {
        items {
          id
          name
        }
      }
      backgrounds {
        items {
          id
          files{
            items{
              id
            }
          }
          order
          description
          date
          createdAt
          briefs {
            id
            name
            userTypeAccess
          }
        }
      }
      createdAt
      order
      type
      size
    }
    nextToken
  }
}
`;

  // WITHOUT PAGINAGTION

  //   const mNoPaginationbyItems = `
  // query getFilesByMatter($isDeleted: Boolean, $matterId: ID) {
  //   matterFiles(isDeleted: $isDeleted, matterId: $matterId, sortOrder:ORDER_ASC) {
  //     items {
  //       id
  //       name
  //       details
  //       date
  //       s3ObjectKey
  //       labels {
  //         items {
  //           id
  //           name
  //         }
  //       }
  //       backgrounds {
  //         items {
  //           id
  //           order
  //           description
  //         }
  //       }
  //       createdAt
  //       order
  //       type
  //       size
  //     }
  //     nextToken
  //   }
  // }
  // `;

  const qlistBackgroundFiles = `
  query getBackgroundByID($id: ID) {
    background(id: $id) {
      id
      files {
        items {
          id
          details
          name
		  order
        }
      }
    }
  }`;

  const mUpdateBackgroundDesc = `
  mutation updateBackground($id: ID, $description: String) {
    backgroundUpdate(id: $id, description: $description) {
      id
      description
    }
  }
`;

  const mUpdateBackgroundDate = `
    mutation updateBackground($id: ID, $date: AWSDateTime) {
      backgroundUpdate(id: $id, date: $date) {
        id
        date
      }
    }
  `;

  const qListBriefId = `
  query getBriefsByBackground($id: ID) {
    background(id: $id) {
      briefs {
        id
        name
      }
    }
  }
  `;

  const mBulkUpdateBackgroundOrder = `
  mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
	backgroundBulkUpdateOrders(arrangement: $arrangement) {
	  id
	  order
	}
  }`;

  async function tagBackgroundFile() {
    let arrFiles = [];
    let arrFileResult = [];
    const seen = new Set();

    setShowToast(true);
    setResultMessage(`Copying attachment to background..`);

    const backgroundFilesOpt = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: backgroundRowId,
      },
    });

    if (backgroundFilesOpt.data.background.files !== null) {
      arrFileResult = backgroundFilesOpt?.data?.background?.files?.items?.map(
        ({ id }) => ({
          id: id,
        })
      );
    }

    arrFiles = selectedRows.map(({ id }) => ({
      id: id,
    }));

    arrFiles.push(...arrFileResult);

    const filteredArr = arrFiles.filter((el) => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });

    if (backgroundRowId !== null) {
      const request = API.graphql({
        query: mUpdateBackgroundFile,
        variables: {
          backgroundId: backgroundRowId,
          files: filteredArr,
        },
      });

      console.log("tagBackgroundFile", filteredArr);

      setTimeout(() => {
        setShowToast(false);
        const back_id = "0";
        window.location.href = `${
          AppRoutes.BACKGROUND
        }/${back_id}/${matter_id}/${background_id}/?matter_name=${utf8_to_b64(
          matter_name
        )}&client_name=${utf8_to_b64(client_name)}`;
      }, 2000);
    }
  }

  // async function updateMatterFileOrder(id, data) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const request = API.graphql({
  //         query: mUpdateMatterFileOrder,
  //         variables: {
  //           id: id,
  //           order: data.order,
  //         },
  //       });

  //       resolve(request);
  //     } catch (e) {
  //       reject(e.errors[0].message);
  //     }
  //   });
  // }

  const listBriefsName = `
  query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
        }
      }
    }
  }
  `;

  const getLabels = async () => {
    //Get Background Briefs name
    let briefsName = [];

    const params = {
      query: listBriefsName,
      variables: {
        id: matter_id,
        limit: 50,
        nextToken: null,
      },
    };

    await API.graphql(params).then((brief) => {
      let briefList = brief.data.clientMatter.briefs?.items;
      briefsName = briefList.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    });

    //console.log("COMBINED", briefsName)
    //ADD LABEL FIX
    let result = [];

    const labelsOpt = await API.graphql({
      query: listLabels,
      variables: {
        clientMatterId: matter_id,
      },
    });

    if (labelsOpt.data.clientMatter.labels !== null) {
      if (labelsOpt.data.clientMatter.labels.items !== null) {
        result = labelsOpt.data.clientMatter.labels.items
          .map(({ id, name }) => ({
            value: id,
            label: name,
          }))
          .sort((a, b) => a.label?.localeCompare(b.label));
      }
    }

    //Combine the Labels with the Background Briefs name
    const combinedArray = [...result, ...briefsName];
    //Remove any duplicate backgrounds that was added within the label
    const filterLabels = new Set();

    result = combinedArray.filter((item) => {
      if (filterLabels.has(item.label)) {
        return false;
      }
      filterLabels.add(item.label);
      return true;
    });

    // Remove duplicated label names
    const uniqueLabels = [];
    const labelNames = [];

    result.forEach((x) => {
      if (!labelNames.includes(x.label)) {
        labelNames.push(x.label);
        uniqueLabels.push(x);
      }
    });

    if (labelNames.length === 0) {
      setFilterModalState(true);
    } else {
      setFilterModalState(false);
    }

    pageSelectedLabels = labelNames;

    setLabels(uniqueLabels);
    console.log("Labels: ", uniqueLabels);
  };

  useEffect(() => {
    if (matterFiles === null) {
      console.log("matterFiles is null");
      getUserById(userId);
    }

    if (labels === null) {
      getLabels();
    }

    if (searchFile !== undefined) {
      filterRecord(searchFile);
    }

    if (Briefs === null) {
      getBriefs();
    }

    // if (briefNames === null){
    //   loadBriefNames();
    // }

    console.log("searchFile", searchFile);
    console.log("matterFiles", matterFiles);
  }, [searchFile]);

  function applyUserAccess(newMatterFiles) {
    if (currentUserType === "OWNER") {
      setFiles(newMatterFiles);
      setMatterFiles(newMatterFiles);
      setSkeletonLoad(false);
    } else {
      if (currentUserType !== null && currentUserType !== "") {
        const filteredMatterFiles = newMatterFiles.filter(
          (file) =>
            file.backgrounds &&
            file.backgrounds.items &&
            file.backgrounds.items.some(
              (background) =>
                background.briefs &&
                background.briefs.some((brief) =>
                  brief.userTypeAccess.includes(currentUserType)
                )
            )
        );
        setFiles(filteredMatterFiles);
        setMatterFiles(filteredMatterFiles);
        setSkeletonLoad(false);
      } else {
        getUserById(userId);
      }
    }
  }

  let getMatterFiles = async (next) => {
    const params = {
      query: qGetFilesByMatter,
      variables: {
        matterId: matter_id,
        isDeleted: false,
        limit: 1500,
        nextToken: next === 1 ? null : vNextToken,
        sortOrder: sortOrder,
      },
    };
    await API.graphql(params).then((files) => {
      let matterFilesList = files?.data?.matterFiles?.items;
      console.log("matterFilesList: TEST", matterFilesList);
      setVnextToken(files?.data?.matterFiles?.nextToken);
      setFiles(sortByOrder(matterFilesList));
      setMatterFiles(sortByOrder(matterFilesList)); // no need to use sortByOrder
      setMaxLoading(false);
      applyUserAccess(matterFilesList);
      getBriefs();
    });
  };

  let loadMoreMatterFiles = async () => {
    if (vNextToken !== null && !loading) {
      const params = {
        query: qGetFilesByMatter,
        variables: {
          matterId: matter_id,
          isDeleted: false,
          limit: 200,
          nextToken: vNextToken,
          sortOrder: sortOrder,
        },
      };

      await API.graphql(params).then((files) => {
        let matterFilesList = files?.data?.matterFiles?.items;
        console.log("Files", matterFilesList);
        setVnextToken(files?.data?.matterFiles?.nextToken);
        setFiles(matterFilesList);
        let arrConcat = matterFiles.concat(matterFilesList);
        if (ascDesc !== null) {
          console.log("sorting is ascending?", ascDesc);

          if (ascDesc === true) {
            console.log("set order by Date ASC, CreatedAt DESC");

            arrConcat = arrConcat
              ?.slice()
              ?.sort(
                (a, b) =>
                  new Date(a.date) - new Date(b.date) ||
                  new Date(b.createdAt) - new Date(a.createdAt)
              );
          } else if (!ascDesc) {
            console.log("set order by Date DESC, CreatedAt DESC");
            arrConcat = arrConcat
              ?.slice()
              ?.sort(
                (a, b) =>
                  new Date(b.date) - new Date(a.date) ||
                  new Date(b.createdAt) - new Date(a.createdAt)
              );
          }

          setFiles(sortByOrder(arrConcat));
          setMatterFiles(sortByOrder(arrConcat));
          console.log("Files", matterFilesList);
        } else {
          setFiles(sortByOrder(arrConcat));
          setMatterFiles(sortByOrder(arrConcat));
          console.log("Files", matterFilesList);
        }
      });
    } else {
      setMaxLoading(true);
    }
  };

  async function updateMatterFile(id, data) {
    console.group("updateMatterFile()");
    console.log("id:", id);
    console.log("data:", data);
    console.groupEnd();
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateMatterFile,
          variables: {
            id: id,
            name: data.name,
            details: data.details,
            // labels: data.labels.items,
          },
        });

        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  async function tagFileLabel(fileId, labels) {
    console.log("tagFileLabel()");
    console.log("fileId", fileId, "check", labels);
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mTagFileLabel,
          variables: {
            fileId: fileId,
            labels: labels,
          },
        });
        resolve(request);
        console.log("reqq", request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const mainGrid = {
    display: "grid",
    gridtemplatecolumn: "1fr auto",
    position: "sticky",
    top: 0,
  };

  const autoAdjustRowHeight = (index) => {
    if (bindList && cache) {
      //clear first
      cache?.current.clearAll();
      bindList?.current?.recomputeRowHeights(index);
      bindList?.current?.forceUpdateGrid(index);
    } else {
      console("List reference not found || cache not found!");
    }
  };

  const autoAdjustAllRow = async () => {
    try {
      if (bindList && cache) {
        cache?.current.clearAll();
        //while loop is recommended fastest loop

        var i = 0,
          len = matterFiles?.length;
        while (i < len) {
          setTimeout(() => {
            bindList?.current?.recomputeRowHeights(len);
          }, 100);

          i++;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLabelChanged = async (id, e, existingLabels, index) => {
    console.log("event", e, "id", id);
    console.log("index", index);
    var labelsList = [];

    /*
    for (var i = 0; i < e.length; i++) {
      if (e[i].__isNew__) {
        const createLabel = await API.graphql({
          query: mCreateLabel,
          variables: {
            clientMatterId: matter_id,
            name: e[i].label,
          },
        });
        console.log("createLabel", createLabel);
        let updateLabel = labels;
        updateLabel.push({
          value: createLabel.data.labelCreate.id,
          label: createLabel.data.labelCreate.name,
        });
        setLabels(updateLabel);
        labelsList = [
          ...labelsList,
          {
            id: createLabel.data.labelCreate.id,
            name: createLabel.data.labelCreate.name,
          },
        ];
      } else {
        if (e[i]) {
          labelsList = [...labelsList, { id: e[i].value, name: e[i].label }];
        } else {
          labelsList = [...labelsList, { id: e.value, name: e.label }];
        }
      }
    }
 */

    if (e.label) {
      if (e.__isNew__) {
        await API.graphql({
          query: mCreateLabel,
          variables: {
            clientMatterId: matter_id,
            name: e.label,
          },
        }).then((r) => {
          console.log("createLabel", r);

          const newLabelId = r.data.labelCreate.id,
            newLabelName = r.data.labelCreate.name;

          console.log("newLabelId", newLabelId);
          console.log("newLabelName", newLabelName);

          let updateLabel = labels;
          updateLabel.push({
            value: newLabelId,
            label: newLabelName,
          });

          // Remove duplicates using Set
          const uniqueLabels = Array.from(
            new Set(updateLabel.map((label) => label.value))
          ).map((value) => updateLabel.find((label) => label.value === value));

          setLabels(uniqueLabels);

          labelsList = [
            ...labelsList,
            {
              id: newLabelId,
              name: newLabelName,
            },
          ];
          const data = createBackgroundFromLabel(
            id,
            {
              id: newLabelId,
              name: newLabelName,
            },
            true
          );
        });
      } else {
        labelsList = [...labelsList, { id: e.value, name: e.label }];

        try {
          const data = await createBackgroundFromLabel(id, {
            id: e.value,
            name: e.label,
          });
        } catch (e) {
          console.log("error", e);
        }

        // var indx = matterFiles.findIndex(x => x.id === id);
        // const add = {
        //   briefs: {items: [{
        //     id: data,
        //     name: e.label
        //   }]},
        //   createdAt: null,
        //   date: null,
        //   description: "",
        //   files: [],
        //   id: null,
        //   order: 0
        // }

        // matterFiles[indx].backgrounds.items = [...matterFiles[indx].backgrounds.items, add];
        // console.log("updated", matterFiles);
      }
    }

    // if (request) {
    setDisableSelect(true);
    setShowLabel([{ index: -1 }]);
    setResultMessage("Creating Background..");
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
      setDisableSelect(false);
      getLabelOption();
    }, 2000);
    //auto adjust must be called later than fetching new data
    setTimeout(() => {
      autoAdjustRowHeight(index);
    }, 3000);
    setNewLabelDropdown(false);

    // }
  };

  const convertArrayToObject = (array) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        item: item,
      };
    }, initialValue);
  };

  //description saving
  const handleDetailsContent = (e, details, id, index) => {
    if (!descAlert) {
      setTextDetails(!details ? "" : details);
      setDetId(id);
      setDesAlert("");
    } else {
      setTextDetails("");
    }
    setDescriptionClassId(id);
    autoAdjustRowHeight(index);
  };

  const handleOnChangeDetails = (event, index) => {
    setTextDetails(event.currentTarget.textContent);
    autoAdjustRowHeight(index);
  };

  const handleSaveDetails = async (e, details, id, index) => {
    const updatedDesc = matterFiles.map((obj) => {
      if (obj.id === id) {
        return {
          ...obj,
          details: e.target.innerHTML,
        };
      }
      return obj;
    });
    setMatterFiles(updatedDesc);
    setDescriptionClassId("");
    autoAdjustRowHeight(index);
    if (textDetails.length <= 0) {
      setDesAlert("Description can't be empty");
    } else if (textDetails === details) {
      setDesAlert("");
      const data = {
        details: e.target.innerHTML,
      };
      await updateMatterFileDesc(id, data);
      setTimeout(() => {
        setResultMessage(`Successfully updated `);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1000);
      }, 1000);
    } else {
      const updatedDesc = matterFiles.map((obj) => {
        if (obj.id === id) {
          return {
            ...obj,
            details: e.target.innerHTML,
          };
        }
        return obj;
      });
      setMatterFiles(updatedDesc);

      setDesAlert("");
      const data = {
        details: e.target.innerHTML,
      };
      await updateMatterFileDesc(id, data);
      setTimeout(() => {
        setResultMessage(`Successfully updated `);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1000);
      }, 1000);
      // }, 1000);
    }
    autoAdjustRowHeight(index);
  };

  async function updateMatterFileDesc(id, data) {
    console.log("data:", data);
    const request = API.graphql({
      query: mUpdateMatterFileDesc,
      variables: {
        id: id,
        details: data.details,
      },
    });
    console.log(request);
  }

  //filename saving
  const handleNameContent = (e, name, id) => {
    if (!fileAlert) {
      setTextName(!name ? "" : name);
      setFileId(id);
      setFileAlert("");
    } else {
      setTextName("");
    }
  };

  const handleOnChangeName = (event) => {
    setTextName(event.currentTarget.textContent);
  };

  const handleSaveName = async (e, name, id) => {
    if (textName.length <= 0) {
      setFileAlert("File name can't be empty");
    } else if (textName === name) {
      setFileAlert("");
      const data = {
        name: name,
      };
      await updateMatterFileName(id, data);
      setTimeout(() => {
        setResultMessage(`Successfully updated `);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1000);
      }, 1000);
    } else {
      setFileAlert("");
      const data = {
        name: textName,
      };
      await updateMatterFileName(id, data);
      setTimeout(() => {
        setResultMessage(`Successfully updated `);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1000);
      }, 1000);
    }
  };

  async function updateMatterFileName(id, data) {
    console.log("data:", data);
    console.groupEnd();
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateMatterFileName,
          variables: {
            id: id,
            name: data.name,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const handleChangeDate = async (selected, id) => {
    const data = {
      date: selected !== null ? String(selected) : null,
    };
    await updateMatterFileDate(id, data);
    const updatedArray = matterFiles.map((p) =>
      p.id === id ? { ...p, date: data.date } : p
    );

    if (filterState) {
      const updatedArray = filteredFiles.map((p) =>
        p.id === id ? { ...p, date: data.date } : p
      );
      setMatterFiles(updatedArray);
      setFilteredFiles(updatedArray);
    } else {
      const updatedArray = matterFiles.map((p) =>
        p.id === id ? { ...p, date: data.date } : p
      );
      setMatterFiles(updatedArray);
      getMatterFiles();
    }
  };

  async function updateMatterFileDate(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateMatterFileDate,
          variables: {
            id: id,
            date:
              data.date !== null && data.date !== "null" && data.date !== ""
                ? moment
                    .utc(moment(new Date(data.date), "YYYY-MM-DD"))
                    .toISOString()
                : null,
          },
        });

        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const defaultOptions = (items) => {
    if (items !== null) {
      const newOptions = items.map(({ id: value, name: label }) => ({
        value,
        label,
      }));
      console.log("optionscheck", newOptions);
      return newOptions;
    } else {
      return null;
    }
  };

  //sorting files function
  function sortByOrder(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }

    return sort;
  }

  function sortArrayByKey(array, key) {
    return array.sort((a, b) => {
      let x = a[key];
      let y = b[key];

      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  //drag and drop functions
  const handleDragEnd = async (e) => {
    let tempMatter = [...matterFiles];

    let [selectedRow] = tempMatter.splice(e.source.index, 1);

    tempMatter.splice(e.destination.index, 0, selectedRow);
    setMatterFiles(tempMatter);

    const result = tempMatter.map(({ id }, index) => ({
      id: id,
      order: index + 1,
    }));

    const mUpdateBulkMatterFileOrder = `
    mutation bulkUpdateMatterFileOrders($arrangement: [ArrangementInput]) {
      matterFileBulkUpdateOrders(arrangement: $arrangement) {
        id
        order
      }
    }
    `;

    if (result && result.length > 0) {
      await API.graphql({
        query: mUpdateBulkMatterFileOrder,
        variables: {
          arrangement: result,
        },
      });
    }
  };

  //checkbox-related functions
  const [checkedState, setCheckedState] = useState(
    new Array(fileCount).fill(false)
  );
  const [isAllChecked, setIsAllChecked] = useState(false);

  //checking each row
  function checked(id, fileName, details, size, s3ObjectKey, type, date, idx) {
    if (isAllChecked) {
      selectedRows.splice(
        selectedRows.indexOf(selectedRows.find((temp) => temp.id === id)),
        1
      );

      selectedCompleteDataRows.splice(
        selectedCompleteDataRows.indexOf(
          selectedCompleteDataRows.find((temp) => temp.id === id)
        ),
        1
      );

      const updatedCheckedState = checkedState.map((item, index) =>
        index === idx ? !item : item
      );

      setCheckedState(updatedCheckedState);
      setIsAllChecked(false);
    } else {
      if (
        selectedRows.indexOf(selectedRows.find((temp) => temp.id === id)) > -1
      ) {
        selectedRows.splice(
          selectedRows.indexOf(selectedRows.find((temp) => temp.id === id)),
          1
        );

        selectedCompleteDataRows.splice(
          selectedCompleteDataRows.indexOf(
            selectedCompleteDataRows.find((temp) => temp.id === id)
          ),
          1
        );

        setIsAllChecked(false);
        const updatedCheckedState = checkedState.map((item, index) =>
          index === idx ? !item : item
        );
        setCheckedState(updatedCheckedState);
      } else {
        selectedRows = [
          ...selectedRows,
          { id: id, fileName: fileName, details: details, date: date },
        ];

        selectedCompleteDataRows = [
          ...selectedCompleteDataRows,
          {
            id: id,
            fileName: fileName,
            details: details,
            date: date,
            size: size,
            type: type,
            s3ObjectKey: s3ObjectKey,
            order: 0,
          },
        ];

        console.log("THIS IS SELECTED", selectedCompleteDataRows);

        setIsAllChecked(false);
        const updatedCheckedState = checkedState.map((item, index) =>
          index === idx ? !item : item
        );
        setCheckedState(updatedCheckedState);
      }
    }

    if (selectedRows.length > 0) {
      setshowRemoveFileButton(true);
      setShowCopyToBackgroundButton(true);
      if (background_id !== "000") {
        setshowAttachBackgroundButton(true);
      }
    } else {
      setshowRemoveFileButton(false);
      setShowCopyToBackgroundButton(false);
      if (background_id !== "000") {
        setshowAttachBackgroundButton(false);
      }
    }
  }

  //checking all rows
  function checkAll(e) {
    if (e.target.checked) {
      setshowRemoveFileButton(true);
      setShowCopyToBackgroundButton(true);
      if (background_id !== "000") {
        setshowAttachBackgroundButton(true);
      }
      const xmatterFiles = matterFiles.map(
        ({
          id,
          backgrounds,
          createdAt,
          date,
          details,
          labels,
          name,
          order,
          s3ObjectKey,
          size,
          type,
        }) => ({
          id,
          fileName: name,
          backgrounds,
          createdAt,
          date,
          details,
          labels,
          name,
          order,
          s3ObjectKey,
          size,
          type,
        })
      );
      setIsAllChecked(true);
      setSelectedItems(matterFiles.map((x) => x.id));
      selectedRows = xmatterFiles;
      selectedCompleteDataRows = xmatterFiles;
    } else {
      selectedRows = [];
      selectedCompleteDataRows = [];
      setIsAllChecked(false);
      setshowRemoveFileButton(false);
      setShowCopyToBackgroundButton(false);
      if (background_id !== "000") {
        setshowAttachBackgroundButton(false);
      }
      setSelectedItems([]);
    }
  }

  //delete function
  const mBulkSoftDelete = `mutation bulkSoftDeleteMatterFiles($id: [ID]) {
    matterFileBulkSoftDelete(id: $id) {
        id
    }
  }`;

  const handleDeleteFile = async (fileID) => {
    setDeletingState(true);
    var idArray = [];

    fileID.map((x) => (idArray = [...idArray, x.id]));

    const request = await API.graphql({
      query: mBulkSoftDelete,
      variables: {
        id: idArray,
      },
    });

    selectedRows = [];
    selectedCompleteDataRows = [];
    setshowRemoveFileButton(false);
    setShowCopyToBackgroundButton(false);
    setResultMessage(`Deleting File`);
    setShowToast(true);
    handleModalClose();
    setTimeout(() => {
      setIsAllChecked(false);
      const newArr = Array(files?.length).fill(false);
      setCheckedState(newArr);
      setResultMessage(`Successfully Deleted!`);
      setShowToast(true);
      setSelectedItems([]);
      setTimeout(() => {
        getMatterFiles(1);
        setShowToast(false);
        setDeletingState(false);
      }, 1000);
    }, 1000);
  };

  const handleChageBackground = (id) => {
    setSelected(id);
    if (active) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  function newOptions(data, oldOpt) {
    var myArray = data;

    if (Array.isArray(oldOpt) && oldOpt.length > 0) {
      var newOptions = oldOpt.map(({ id: value, name: label }) => ({
        value,
        label,
      }));
      return data;
    } else {
      return data;
    }
  }

  const [selectedOption, setSelect] = useState(null);
  const handleChange = (selectedOption) => {
    setSelect(selectedOption);
  };
  const removeOption = (e) => {
    const newSelect = selectedOption.filter(
      (item) => item.value !== e.target.name
    );
    setSelect(newSelect);
  };

  const handleSearchFileChange = (e) => {
    console.log("handleSearchFileChange()", e.target.value);
    setSearchFile(e.target.value);
  };

  const filterRecord = (v) => {
    let timeoutId;
    console.log("filter", v);
    const searchQuery = v.trim().toLowerCase();
    const delay = 500; // milliseconds

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (searchQuery === "") {
        getMatterFiles(1);
      } else {
        console.log(files);
        const filterRecord = files?.filter((x) => {
          // Check if name and description properties exist and perform case-insensitive search
          const nameMatch =
            x.name && x.name.toLowerCase().includes(searchQuery);
          const descriptionMatch =
            x.details && x.details.toLowerCase().includes(searchQuery);
          return nameMatch || descriptionMatch;
        });

        setMatterFiles(filterRecord);
      }
    }, delay);
  };

  const mGetFilesByLabel = `
    query getFilesByLabel($id: [ID]) {
      multipleLabels(id: $id) {
        files {
        items {
          id
          name
          details
          date
          s3ObjectKey
          labels {
            items {
              id
              name
            }
          }
          backgrounds {
            items {
              id
              order
              description
              date
              briefs {
                id
                name
              }
            }
          }
          createdAt
          order
          type
          size
        }
        nextToken
        }
      }
    }
    `;

  // const mSoftDeleteBrief = `
  //     mutation softDeleteBriefById($id: ID) {
  //       briefSoftDelete(id: $id) {
  //         id
  //       }
  //     }
  //     `;

  const mDeleteBackground = `mutation deleteBackground($id: ID) {
  backgroundDelete(id: $id) {
    id
  }
}`;

  const mDeleteFileAttachment = `
    mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
      backgroundFileTag(backgroundId: $backgroundId, files: $files) {
        id
      }
    }
  `;

  //deleteBackgroundFromLabel
  const handleDeleteBackground = (rowId, rowFiles, fileId, index) => {
    //console.log("rowId",rowId);
    //console.log("rowFiles", rowFiles);
    //console.log("fileId", fileId); //remove in rowFiles

    const filesArr = rowFiles?.items.map(({ id }) => ({
      id: id,
    }));

    const filteredArrFiles = filesArr.filter((i) => i.id !== fileId);

    //console.log("passthis", filteredArrFiles);

    const request = API.graphql({
      query: mDeleteFileAttachment,
      variables: {
        backgroundId: rowId,
        files: filteredArrFiles,
      },
    });

    //console.log("success", request);

    setShowToast(true);
    setResultMessage(`Successfully deleted a background`);
    getBriefs();
    setTimeout(() => {
      setShowToast(false);
      getMatterFiles(1);
    }, 2000);
    setTimeout(() => {
      autoAdjustRowHeight(index);
    }, 3000);
  };

  //filter function
  const handleFilter = async (fileFilter) => {
    console.log("ff", fileFilter);
    console.log("filesToFilter", matterFiles);
    setFilterLabels(false);

    var next = 1;

    //var filterRecord = [];
    if (
      fileFilter === null ||
      fileFilter === undefined //||
      //fileFilter.length === 0
    ) {
      getMatterFiles(next);
      setMatterFiles(sortByOrder(matterFiles));
      setFilterState(false);
    } else {
      //Replace this with a query if need performance
      setMatterFiles(sortByOrder(fileFilter));
      setFilteredFiles(sortByOrder(fileFilter));
      setFilterState(true);

      /* Previous Implementation which only queries by label */

      // console.log("labels", labels);
      // var labelsList = labels;
      // var labelsIdList = [];

      // for (var i = 0; i < fileFilter.length; i++) {
      //   labelsList.map((x) =>
      //     x.label === fileFilter[i]
      //       ? (labelsIdList = [...labelsIdList, x.value])
      //       : (labelsIdList = labelsIdList)
      //   );
      // }

      // fileFilter.forEach((x)=>{
      //   labelsIdList.push(x.id)
      // })

      // var uniqueIds = [
      //   ...new Map(labelsIdList.map((x) => [JSON.stringify(x), x])).values(),
      // ];

      //console.log("labelIds", uniqueIds);

      // const result = await API.graphql({
      //   query: mGetFilesByLabel,
      //   variables: {
      //     id: uniqueIds,
      //   },
      // });

      // setTimeout(() => {
      //   console.log("ssss", result);
      //   var newFiles = result.data.multipleLabels;

      //   var newFiles1 = [];
      //   var newFiles2 = [];

      //   if (result === null) {
      //     newFiles2 = [];
      //   } else {
      //     result.data.multipleLabels.map(
      //       (x) => (newFiles1 = [...newFiles1, x.files.items])
      //     );
      //     newFiles1.map((x) => x.map((y) => (newFiles2 = [...newFiles2, y])));
      //   }

      //   function removeDuplicateObjectFromArray(array, key) {
      //     var check = new Set();
      //     return array.filter(
      //       (obj) => !check.has(obj[key]) && check.add(obj[key])
      //     );
      //   }

      //   console.log(
      //     "putinmatterfiles",
      //     removeDuplicateObjectFromArray(newFiles2, "id")
      //   );
      //   // setMatterFiles(sortByOrder(newFiles2));
      //   setFilteredFiles(
      //     sortByOrder(removeDuplicateObjectFromArray(newFiles2, "id"))
      //   );
      //   setFilterState(true);

      //   console.log("res", result);
      // }, 5000);
    }
  };

  const mCreateBackground = `
    mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
      backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
        id
		order
      }
    }
`;

  async function addFileBucketToBackground() {
    let arrFiles = [];
    setShowToast(true);
    setResultMessage(`Copying details to background..`);

    arrFiles = selectedRows.map(({ id, details, date }) => ({
      id: id,
      details: details,
      date: date,
    }));

    var counter = 0;
    for (let i = 0; i < arrFiles.length; i++) {
      counter++;

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefId: background_id,
          description: arrFiles[i].details,
          comments: "",
          date:
            arrFiles[i].date !== null
              ? moment
                  .utc(moment(new Date(arrFiles[i].date), "YYYY-MM-DD"))
                  .toISOString()
              : null,
        },
      });
      console.log("CREATE BACKGROUND ROW IS:", createBackgroundRow);
      if (createBackgroundRow.data.backgroundCreate.id !== null) {
        const request = await API.graphql({
          query: mUpdateBackgroundFile,
          variables: {
            backgroundId: createBackgroundRow.data.backgroundCreate.id,
            files: [{ id: arrFiles[i].id }],
          },
        });
      }
    }

    setTimeout(() => {
      setShowToast(false);
      const back_id = "0";
      window.location.href = `${
        AppRoutes.BACKGROUND
      }/${back_id}/${matter_id}/${background_id}/?matter_name=${utf8_to_b64(
        matter_name
      )}&client_name=${utf8_to_b64(client_name)}`;
    }, 1000);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Comment or uncomment the code block below AND the useIdleTimer import statement to toggle session timeout

  // var timeoutId, timeoutNotif;

  // const handleOnAction = (event) => {
  //   loadMoreMatterFiles();
  //   //function for detecting if user moved/clicked.
  //   //if modal is active and user moved, automatic logout (session expired)
  //   // bool.current = false;
  //   if (showSessionTimeout) {
  //     setTimeout(() => {
  //       Auth.signOut().then(() => {
  //         clearLocalStorage();
  //         console.log("Sign out completed.");
  //         history.push("/");
  //       });

  //       function clearLocalStorage() {
  //         localStorage.removeItem("userId");
  //         localStorage.removeItem("email");
  //         localStorage.removeItem("firstName");
  //         localStorage.removeItem("lastName");
  //         localStorage.removeItem("userType");
  //         localStorage.removeItem("company");
  //         localStorage.removeItem("companyId");
  //         // localStorage.removeItem("access");
  //       }
  //     }, 3000);
  //   }

  //   clearTimeout(timeoutId);
  //   clearTimeout(timeoutNotif);
  // };

  // const handleOnIdle = (event) => {
  //   loadMoreMatterFiles();
  //   timeoutId = setTimeout(() => {
  //     // timeoutNotif = setTimeout(() => {
  //     //   setShowSessionTimeout(true);
  //     // }, 1000 * 300); //5 minutes to confirm

  //     // if (
  //     //   alert(
  //     //     "Session will expire in 5 minutes. Please confirm that you are still active before the session expires"
  //     //   )
  //     // ) {
  //     //   clearTimeout(timeoutNotif);
  //     //   clearTimeout(timeoutId);
  //     // }
  //     setShowSessionTimeout(true);
  //   }, 60000 * 120); //2 hours before session timeout
  // };

  // useIdleTimer({
  //   timeout: 60 * 40,
  //   onAction: handleOnAction,
  //   onIdle: handleOnIdle,
  //   debounce: 1000,
  // });

  const handleDuplicate = async () => {
    let next = 1;

    // Get the length of selectedCompleteDataRows
    const lengthSelectedRows = selectedCompleteDataRows.length;

    // Iterate over each item in selectedCompleteDataRows
    selectedCompleteDataRows.map(async function (items, index) {
      console.log("items", items);

      // Make a GraphQL API request to create a duplicate matter file
      const request = await API.graphql({
        query: mCreateMatterFile,
        variables: {
          matterId: matter_id,
          s3ObjectKey: items.s3ObjectKey,
          size: items.size,
          name: "Copy of " + items.fileName,
          details: items.details,
          type: items.type,
          order: items.order,
        },
      });

      // Reset some state variables
      setIsAllChecked(false);
      setSelectedItems([]);
      const newArr = Array(files?.length).fill(false);
      setCheckedState(newArr);
      setshowAttachBackgroundButton(false);
      setShowCopyToBackgroundButton(false);
      setshowRemoveFileButton(false);

      // Get matter files
      getMatterFiles(next);

      // Check if it's the last iteration
      if (index === lengthSelectedRows - 1) {
        // Reset selectedCompleteDataRows and selectedRows
        selectedCompleteDataRows = [];
        selectedRows = [];
        console.log("END", selectedCompleteDataRows);
      }
    });
  };

  useEffect(() => {
    // Get the dateAscDesc from localStorage when the component mounts
    const storedAscDesc = localStorage.getItem("dateAscDesc");
    const parsedAscDesc = JSON.parse(storedAscDesc);
    setAscDesc(parsedAscDesc);

    const storedSubAscDesc = localStorage.getItem("SubDateAscDesc");
    const parsedSubAscDesc = JSON.parse(storedSubAscDesc);
    setSubAscDesc(parsedSubAscDesc);
  }, []);

  const SortBydate = async () => {
    console.group("SortBydate()");

    setSkeletonLoad(true); // Set to true for initial loading
    setMatterFiles(null); // Clear matter files to trigger loading indicator
    setSubAscDesc(null);
    localStorage.setItem("SubDateAscDesc", null);

    if (ascDesc === null) {
      // If sorting order is null, set order by Date ASC, CreatedAt DESC
      console.log("Set order by Date ASC, CreatedAt DESC");
      setAscDesc(true);
      localStorage.setItem("dateAscDesc", true);

      const params = {
        query: qGetFilesByMatter,
        variables: {
          matterId: matter_id,
          isDeleted: false,
          nextToken: null,
          sortOrder: "DATE_ASC",
        },
      };

      // Retrieve files from API with specified sort order
      await API.graphql(params).then((files) => {
        let matterFilesList = files?.data?.matterFiles?.items;
        matterFilesList.sort((a, b) => {
          if (a?.backgrounds?.date && b?.backgrounds?.date) {
            return (
              new Date(b?.backgrounds?.date) - new Date(a?.backgrounds?.date)
            );
          } else if (a?.backgrounds?.date) {
            return +1;
          } else {
            return 0;
          }
        });
        console.log("this matterFilesList: ", sortOrder, matterFilesList);
        setVnextToken(files?.data?.matterFiles?.nextToken);
        setFiles(matterFilesList);
        setMatterFiles(matterFilesList); // Update matter files list
        setMaxLoading(false); // Stop loading indicator
        setSkeletonLoad(false); // Stop to for initial loading
      });
    } else if (ascDesc === true) {
      // If sorting order is ASC, set order by Date DESC, CreatedAt DESC
      console.log("Set order by Date DESC, CreatedAt DESC");
      setAscDesc(false);
      localStorage.setItem("dateAscDesc", false);
      const params = {
        query: qGetFilesByMatter,
        variables: {
          matterId: matter_id,
          isDeleted: false,
          nextToken: null,
          sortOrder: "DATE_DESC",
        },
      };

      // Retrieve files from API with specified sort order
      await API.graphql(params).then((files) => {
        let matterFilesList = files?.data?.matterFiles?.items;
        matterFilesList.sort((a, b) => {
          if (a?.backgrounds?.date && b?.backgrounds?.date) {
            return (
              new Date(a?.backgrounds?.date) - new Date(b?.backgrounds?.date)
            );
          } else if (a?.backgrounds?.date) {
            return +1;
          } else {
            return 0;
          }
        });
        console.log("matterFilesList: ", sortOrder, matterFilesList);
        setVnextToken(files?.data?.matterFiles?.nextToken);
        setFiles(matterFilesList);
        setMatterFiles(matterFilesList); // Update matter files list
        setMaxLoading(false); // Stop loading indicator
        setSkeletonLoad(false); // Stop to for initial loading
      });
    } else if (!ascDesc) {
      // If sorting order is DESC or not set, reset to default order
      setAscDesc(null);
      localStorage.setItem("dateAscDesc", null);
      console.log("Set order by DEFAULT: Order ASC, CreatedAt DESC");
      getMatterFiles(); // Retrieve matter files with default order
      setSkeletonLoad(false); // Stop to for initial loading
    }

    console.groupEnd();
  };

  const SortBySubDate = async () => {
    console.group("SortBySubDate()");

    setSkeletonLoad(true); // Set to true for initial loading
    setMatterFiles(null); // Clear matter files to trigger loading indicator
    setAscDesc(null);
    localStorage.setItem("dateAscDesc", null);

    if (SubAscDesc === null) {
      // If sorting order is null, set order by Date ASC, CreatedAt DESC
      console.log("Set order by Date ASC, CreatedAt DESC");
      setSubAscDesc(true);
      localStorage.setItem("SubDateAscDesc", true);

      const params = {
        query: qGetFilesByMatter,
        variables: {
          matterId: matter_id,
          isDeleted: false,
          nextToken: null,
          sortOrder: "DATE_ASC",
        },
      };

      // Retrieve files from API with specified sort order
      await API.graphql(params).then((files) => {
        let matterFilesList = files?.data?.matterFiles?.items;
        matterFilesList.sort((a, b) => {
          if (a?.createdAt && b?.createdAt) {
            return new Date(a?.createdAt) - new Date(b?.createdAt);
          } else if (a?.createdAt) {
            return +1;
          } else {
            return 0;
          }
        });
        console.log("SUB matterFilesList: ", sortOrder, matterFilesList);
        setVnextToken(files?.data?.matterFiles?.nextToken);
        setFiles(matterFilesList);
        setMatterFiles(matterFilesList); // Update matter files list
        setMaxLoading(false); // Stop loading indicator
        setSkeletonLoad(false); // Stop to for initial loading
      });
    } else if (SubAscDesc === true) {
      // If sorting order is ASC, set order by Date DESC, CreatedAt DESC
      console.log("Set order by Date DESC, CreatedAt DESC");
      setSubAscDesc(false);
      localStorage.setItem("SubDateAscDesc", false);
      const params = {
        query: qGetFilesByMatter,
        variables: {
          matterId: matter_id,
          isDeleted: false,
          nextToken: null,
          sortOrder: "DATE_DESC",
        },
      };

      // Retrieve files from API with specified sort order
      await API.graphql(params).then((files) => {
        let matterFilesList = files?.data?.matterFiles?.items;
        matterFilesList.sort((a, b) => {
          if (a?.createdAt && b?.createdAt) {
            return new Date(b?.createdAt) - new Date(a?.createdAt);
          } else if (a?.createdAt) {
            return +1;
          } else {
            return 0;
          }
        });
        console.log("SUB matterFilesList: ", sortOrder, matterFilesList);
        setVnextToken(files?.data?.matterFiles?.nextToken);
        setFiles(matterFilesList);
        setMatterFiles(matterFilesList); // Update matter files list
        setMaxLoading(false); // Stop loading indicator
        setSkeletonLoad(false); // Stop to for initial loading
      });
    } else if (!SubAscDesc) {
      // If sorting order is DESC or not set, reset to default order
      setSubAscDesc(null);
      localStorage.setItem("SubDateAscDesc", null);
      console.log("Set order by DEFAULT: Order ASC, CreatedAt DESC");
      getMatterFiles(); // Retrieve matter files with default order
      setSkeletonLoad(false); // Stop to for initial loading
    }

    console.groupEnd();
  };

  const style = {
    paddingLeft: "0rem",
  };

  const showPageReference = async (
    fileId,
    backgroundId,
    clientMatter,
    description,
    rowOrder
  ) => {
    setPageReferenceFileId(fileId);
    setPageReferenceBackgroundId(backgroundId);
    setPageReferenceClientMatter(clientMatter);
    setPageReferenceDescription(description);
    setPageReferenceRowOrder(rowOrder);
  };

  function b64_to_utf8(str) {
    try {
      // Convert base64 to percent-encoded string
      const percentEncodedStr = atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("");

      // Decode percent-encoded string to utf-8
      return decodeURIComponent(percentEncodedStr);
    } catch (e) {
      console.error("Error decoding base64 string", e);
      return null;
    }
  }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const m_name = getParameterByName("matter_name");
  const c_name = getParameterByName("client_name");
  const backgroundRowId = getParameterByName("background_id");
  const matter_name = b64_to_utf8(m_name);
  const client_name = b64_to_utf8(c_name);

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
  function showAlert() {
    alert("No selected Labels on page.");
  }

  const checkFormat = (str) => {
    var check = str;
    check = check.replace("%20", " "); //returns my_name
    return check;
  };

  //new copy to BG
  const handleShowCopyOptions = () => {
    if (copyOptions) {
      showCopyOptions(false);
    } else {
      showCopyOptions(true);
    }
  };

  const listBriefs = `
  query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          date
          order
        }
      }
    }
  }
  `;

  const getBriefs = async () => {
    var opts = [];
    console.log("matterid", matter_id);
    const params = {
      query: listBriefs,
      variables: {
        id: matter_id,
        limit: 50,
        nextToken: null,
      },
    };

    await API.graphql(params).then((brief) => {
      let briefList = brief.data.clientMatter.briefs?.items;
      console.log("mfl", briefList);
      var temp = briefList.map(
        (x) => (opts = [...opts, { label: x.name, value: x.id }])
      );
      setCopyBgOptions(opts);
      setBriefs(briefList);
    });
  };

  const handleFilterChange = (evt) => {
    setCopyBgIds(evt);
  };

  const handleFilterRemoveChange = (evt) => {
    if (evt.length === 0) {
      setCopyBgIds(null);
    }
  };

  const handleCopyToBg = async () => {
    console.log("cb", copyBgOptions);

    let temp = copyBgIds;
    var searchIds = [];
    var searchLabels = [];

    for (var i = 0; i < temp.length; i++) {
      copyBgOptions.map((x) =>
        x.label === temp[i] ? (searchIds = [...searchIds, x.value]) : x
      );
    }

    console.log("searchthis", searchLabels); //ids of backgrounds [id, id] correct
    console.log("SEARCH ID:", searchIds);
    //from old code, attach to bg
    let arrFiles = [];
    setShowToast(true);
    setResultMessage(`Copying files to background..`);

    showCopyOptions(false);
    setshowRemoveFileButton(false);
    setShowCopyToBackgroundButton(false);
    setshowAttachBackgroundButton(false);

    arrFiles = selectedRows.map(({ id, details, date }) => ({
      id: id,
      details: details,
      date: date,
    }));

    for (let j = 0; j < searchIds.length; j++) {
      for (let i = 0; i < arrFiles.length; i++) {
        var updatedBriefsArr = copyBgOptions
          .filter((item) => item.value === searchIds[j])
          .map(function (item) {
            return { id: item.value, name: item.label };
          });
        console.log("UPDATED BRIEFS:", updatedBriefsArr);
        const createBackgroundRow = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: updatedBriefsArr,
            description: arrFiles[i].details,
            comments: "",
            date:
              arrFiles[i].date != null
                ? moment
                    .utc(moment(new Date(arrFiles[i].date), "YYYY-MM-DD"))
                    .toISOString()
                : null,
            order: 1,
          },
        });
        if (createBackgroundRow.data.backgroundCreate.id !== null) {
          const backgroundOptNew = await API.graphql({
            query: qGetBackgroundsByBriefId,
            variables: {
              id: searchIds[j],
              nextToken: null,
              sortOrder: "ORDER_ASC",
              limit: null,
            },
          }).catch((error) => {
            console.log("ERROR FETCHING BACKGROUND DATA: RESTART PAGE");
          });

          console.log("BACKGROUND OPT IS:", backgroundOptNew);

          if (backgroundOptNew.data.brief != null) {
            if (backgroundOptNew.data.brief.backgrounds.items !== null) {
              if (backgroundOptNew.data.brief.backgrounds.items.length > 1) {
                let newRowArrangement =
                  backgroundOptNew.data.brief.backgrounds.items.map(
                    (item, index) => {
                      return { id: item.id, order: index + 1 };
                    }
                  );
                console.log("NEW ROW ARRANGEMENT:", newRowArrangement);

                const response = await API.graphql({
                  query: mBulkUpdateBackgroundOrder,
                  variables: {
                    arrangement: newRowArrangement,
                  },
                });

                console.log("RESPONSE FROM ROW UPDATE:", response);
              }
            }
          }

          // const bgFilesList = await API.graphql({
          // 	query: qlistBackgroundFiles,
          // 	variables: {
          // 		id: createBackgroundRow.data.backgroundCreate.id,
          // 	},
          // });
          // console.log("BG FILES LIST:", bgFilesList);

          const request = await API.graphql({
            query: mUpdateBackgroundFile,
            variables: {
              backgroundId: createBackgroundRow.data.backgroundCreate.id,
              files: [{ id: arrFiles[i].id }],
            },
          });
        }
      }
    }
    setShowToast(false);
    setIsAllChecked(false);
    setSelectedItems([]);
    setResultMessage(`Files successfully copied in backgrounds!`);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 1000);

    selectedRows = [];
    selectedCompleteDataRows = [];
    getMatterFiles();
    setTimeout(() => {
      setShowToast(false);
      getBriefs();
      setIsAllChecked(false);
      const newArr = Array(files?.length).fill(false);
      setCheckedState(newArr);
    }, 1000);
  };

  const handleDescContent = (e, description, id, test, index) => {
    if (!descAlert) {
      setTextDesc(description);
      autoAdjustRowHeight(index);
    }
    setDescriptionClassId(id);
    setDescriptionClass(false);
    autoAdjustRowHeight(index);

    /*const next = itemsRef.current[index];
    if (next) {
      next.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }*/
  };

  const handleChangeDescription = (e, description, id, test, index) => {
    console.log("ITEMS", e);
    setDescriptionClassId(id);
    setDescriptionClass(false);
    autoAdjustRowHeight(index);
    /* const next = itemsRef.current[index];
    if (next) {
      next.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    } */
  };

  const handleChangeDesc = (event, index) => {
    setTextDesc(event.currentTarget.textContent);
    autoAdjustRowHeight(index);
  };

  const handleSaveDesc = async (e, description, date, id, index) => {
    matterFiles.map((obj) => {
      if (obj.id === id) {
        return { ...obj, description: e.target.innerHTML };
      }
      return obj;
    });

    // matterFiles.map((obj) => {
    //   if (obj.id === id) {

    //   }
    // });

    setDescriptionClass(true);
    setDescriptionClassId("");

    if (textDesc.length <= 0) {
      // notify error on description
    } else if (textDesc === description) {
      setShowToast(true);

      const data = {
        description: e.target.innerHTML,
      };

      const success = await updateBackgroundDesc(id, data);
      if (success) {
        setShowToast(true);
      }

      setTimeout(() => {
        setShowToast(false);
      }, 1000);
    } else {
      const data = {
        description: e.target.innerHTML,
      };
      const success = await updateBackgroundDesc(id, data);
      if (success) {
        setShowToast(true);
      }
      setTimeout(() => {
        setShowToast(false);
        getMatterFiles(1);
      }, 1000);
    }
    autoAdjustRowHeight(index);
  };

  async function updateBackgroundDesc(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDesc,
          variables: {
            id: id,
            description: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const handleKeyUp = (e) => {
    if (e.key === "Shift" && isShiftDown) {
      setIsShiftDown(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Shift" && !isShiftDown) {
      setIsShiftDown(true);
    }
  };

  const handleSelectItem = (e, index) => {
    const { value } = e.target;
    const nextValue = getNextValue(value);
    setSelectedItems(nextValue);
    setLastSelectedItem(value);

    console.log("matterfiles", matterFiles);

    if (nextValue.length > 0) {
      const mf = matterFiles.filter((item) => nextValue.includes(item.id));
      const xmatterFiles = mf.map(
        ({
          id,
          backgrounds,
          createdAt,
          date,
          details,
          labels,
          name,
          order,
          s3ObjectKey,
          size,
          type,
        }) => ({
          id,
          fileName: name,
          backgrounds,
          createdAt,
          date,
          details,
          labels,
          name,
          order,
          s3ObjectKey,
          size,
          type,
        })
      );

      selectedRows = xmatterFiles;
      selectedCompleteDataRows = xmatterFiles;
      setshowRemoveFileButton(true);
      setShowCopyToBackgroundButton(true);
      if (background_id !== "000") {
        setshowAttachBackgroundButton(true);
      }
    } else {
      selectedRows = [];
      selectedCompleteDataRows = [];
      setshowRemoveFileButton(false);
      setShowCopyToBackgroundButton(false);
      if (background_id !== "000") {
        setshowAttachBackgroundButton(false);
      }
    }
  };

  const getNextValue = (value) => {
    const hasBeenSelected = !selectedItems.includes(value);

    if (isShiftDown) {
      const newSelectedItems = getNewSelectedItems(value);

      const selections = [...new Set([...selectedItems, ...newSelectedItems])];

      if (!hasBeenSelected) {
        return selections.filter((item) => !newSelectedItems.includes(item));
      }

      return selections;
    }

    // if it's already in there, remove it, otherwise append it
    return selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value];
  };

  const getNewSelectedItems = (value) => {
    const currentSelectedIndex = matterFiles.findIndex(
      (item) => item.id === value
    );
    const lastSelectedIndex = matterFiles.findIndex(
      (item) => item.id === lastSelectedItem
    );

    return matterFiles
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
      )
      .map((item) => item.id);
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp, false);
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyUp, handleKeyDown]);

  const handleChangeDateBackground = async (selected, id, fileId) => {
    const data = {
      date: selected !== null ? String(selected) : null,
    };
    await updateBackgroundDate(id, data);

    const filteredFiles = matterFiles.filter((i) => i.id === fileId);

    const filteredBackground = filteredFiles.map(({ backgrounds }) => ({
      id: backgrounds,
    }));
    getMatterFiles(1);
  };

  async function updateBackgroundDate(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDate,
          variables: {
            id: id,
            date:
              data.date !== null
                ? moment
                    .utc(moment(new Date(data.date), "YYYY-MM-DD"))
                    .toISOString()
                : null,
          },
        });

        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const getBriefName = (backgroundId) => {
    return backgroundId;
  };

  const handleRedirectLink = async (e, backgroundId) => {
    var arrBackgroundResult = [];
    const backgroundRedirect = await API.graphql({
      query: qListBriefId,
      variables: {
        id: backgroundId,
      },
    });

    console.log(
      "backgroundRedirect:",
      backgroundRedirect.data.background.briefs[0].name
    );
    if (backgroundRedirect.data.background.briefs !== null) {
      arrBackgroundResult = backgroundRedirect.data.background.briefs?.map(
        ({ id }) => ({
          id: id,
        })
      );
      setTimeout(() => {
        setShowToast(false);
        const back_id = "0";
        window.location.href = `${
          AppRoutes.BACKGROUND
        }/${back_id}/${matter_id}/${
          arrBackgroundResult[0].id
        }/?matter_name=${utf8_to_b64(matter_name)}&client_name=${utf8_to_b64(
          client_name
        )}`;
      }, 200);
    } else {
      alert("Error encountered!");
    }
  };

  const EMAIL_PREVIEW = `
      query getEmailDetails($id: ID) {
        gmailMessage(id: $id) {
          id
          from
          date
          to
          cc
          bcc
          subject
          recipient
          receivedAt
          payload {
            id
            content
          }
        }
      }`;

  const handleSnippetEmail = async (gmailMessageId, index) => {
    setShowEmailPreview(true);
    setActiveGmailMessageId(gmailMessageId);
    setShowIndex(index);

    const params = {
      query: EMAIL_PREVIEW,
      variables: {
        id: gmailMessageId,
      },
    };

    await API.graphql(params).then((result) => {
      setEmailPreview(result.data.gmailMessage.payload);
      setEmailPreviewFrom(result.data.gmailMessage.from);
      setEmailPreviewDate(result.data.gmailMessage.date);
      setEmailPreviewSubject(result.data.gmailMessage.subject);
      setEmailPreviewTo(result.data.gmailMessage.to);
      setEmailPreviewCC(result.data.gmailMessage.cc);
    });
  };

  const handleRootClose = () => {
    setShowEmailPreview(false);
    // reset data
    setActiveGmailMessageId(null);
    setEmailPreview(null);
    setEmailPreviewFrom(null);
    setEmailPreviewDate(null);
    setEmailPreviewSubject(null);
    setEmailPreviewTo(null);
    setEmailPreviewCC(null);
  };

  //Mobile functions
  const [contentHeight, setContentHeight] = useState();
  const [readMoreStateOuter, setReadMoreStateOuter] = useState([]);
  const [readMoreStateInner, setReadMoreStateInner] = useState([]);
  const [readMoreStateDesc, setReadMoreStateDesc] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isExpandAllActive, setIsExpandAllActive] = useState(false);
  const { height, width } = useWindowDimensions();
  const [headerReadMore, setHeaderReadMore] = useState(false);
  const [headerLines, setHeaderLines] = useState();

  const [readmoreModal, setreadmoreModal] = useState(false);
  const [readmoreModalData, setreadmoreModalData] = useState([]);

  function countLines(tag) {
    var divHeight = tag.offsetHeight;
    var lineHeight = parseInt(
      window.getComputedStyle(tag).getPropertyValue("line-height")
    );
    var lines = Math.round(divHeight / lineHeight);
    return lines;
  }

  useEffect(() => {
    if (width < 844) {
      var headerTag = document.getElementById("headerTag");
      setHeaderLines(countLines(headerTag));
      if (headerReadMore && headerTag) {
        setContentHeight(height - 94 - headerTag.offsetHeight);
      } else {
        setContentHeight(
          height -
            94 -
            parseInt(
              window.getComputedStyle(headerTag).getPropertyValue("line-height")
            )
        );
      }
    }
  }, [height, width, headerReadMore]);

  function handleReadMoreStateDesc(fileId) {
    if (
      readMoreStateDesc.find((temp) => {
        return temp === fileId;
      }) === undefined
    ) {
      setReadMoreStateDesc([...readMoreStateDesc, fileId]);
    } else {
      setReadMoreStateDesc((current) =>
        current.filter((id) => {
          return id !== fileId;
        })
      );
    }
  }

  function handleReadMoreModal(fileId) {
    setreadmoreModal(true);
    var selectedMatterFile = matterFiles.filter((item, i) => item.id == fileId);
    setreadmoreModalData(selectedMatterFile);

    if (selectedMatterFile.length == 0 || selectedMatterFile.length > 1) {
      setreadmoreModal(false);
    } else {
      setreadmoreModal(true);
    }
    // console.log(selectedMatterFile)
  }

  function handleReadMoreStateOuter(fileId) {
    if (
      readMoreStateOuter.find((temp) => {
        return temp === fileId;
      }) === undefined
    ) {
      setReadMoreStateOuter([...readMoreStateOuter, fileId]);
    } else {
      setReadMoreStateOuter((current) =>
        current.filter((id) => {
          return id !== fileId;
        })
      );
    }
  }

  function handleReadMoreStateInner(fileId, bgId) {
    if (
      readMoreStateInner.find((temp) => {
        return temp === fileId + "/" + bgId;
      }) === undefined
    ) {
      setReadMoreStateInner([...readMoreStateInner, fileId + "/" + bgId]);
    } else {
      setReadMoreStateInner((current) =>
        current.filter((id) => {
          return id !== fileId + "/" + bgId;
        })
      );
    }
  }

  function handleCollapseAll(fileId) {
    setReadMoreStateOuter((current) =>
      current.filter((id) => {
        return id !== fileId;
      })
    );
    setReadMoreStateDesc((current) =>
      current.filter((id) => {
        return id !== fileId;
      })
    );
    setReadMoreStateInner((current) =>
      current.filter((id) => {
        return id.split("/")[0] !== fileId;
      })
    );
  }

  const mCreateBrief = `
  mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }
  `;

  const mUpdateBrief = `
  mutation updateBrief($id: ID, $labelId: ID) {
    briefUpdate(id: $id, labelId: $labelId) {
      id
    }
  }
  `;

  const qBriefByName = `
  query getBriefByName($clientMatterId: ID, $name: String) {
    briefByName(clientMatterId: $clientMatterId, name: $name) {
      id
      labelId
    }
  }
  `;

  const createBackgroundFromLabel = async (row_id, label, isNew) => {
    console.group("createBackgroundFromLabel");
    console.log("ROW_ID", row_id, "INSIDE FROM LABEL", label);
    var returnBriefId, returnBgId, returnFileDate;

    const mf = matterFiles.filter((item) => row_id.includes(item.id)); // get row details
    // check if brief already exists
    let briefNameExists = false;
    const getBriefByName = await API.graphql({
      query: qBriefByName,
      variables: {
        clientMatterId: matter_id,
        name: label.name,
      },
    });

    let briefId = getBriefByName.data.briefByName.id,
      existingBriefNameLabel = getBriefByName.data.briefByName.labelId;

    if (briefId !== "" && briefId !== null) {
      briefNameExists = true;
    }

    if (isNew) {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
          columnIds: [0, 1, 2, 3, 4],
        };

        const createBrief = await API.graphql({
          query: mCreateBrief,
          variables: params,
        });

        console.log("createBrief", createBrief);
        briefId = createBrief.data.briefCreate.id;

        returnBriefId = createBrief.data.briefCreate.id;

        updatePresetsForNewlyCreatedBrief(createBrief, label.name);
      } else {
        console.log("existingBriefNameLabel", existingBriefNameLabel);
        if (existingBriefNameLabel === null) {
          const params = {
            id: briefId,
            labelId: label.id,
          };

          await API.graphql({
            query: mUpdateBrief,
            variables: params,
          });
        }

        returnBriefId = briefId;
      }

      const fileId = row_id,
        fileDetails = mf[0].details,
        fileDate =
          mf[0].date != null
            ? moment
                .utc(moment(new Date(mf[0].date), "YYYY-MM-DD"))
                .toISOString()
            : null;

      // Create Background
      const createBackground = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: [{ name: label.name, id: briefId }],
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      returnBgId = createBackground.data.backgroundCreate.id;
      returnFileDate = fileDate;
      console.log("createBackground", createBackground);
      if (createBackground.data.backgroundCreate.id !== null) {
        // Tag File to Background
        await API.graphql({
          query: mUpdateBackgroundFile,
          variables: {
            backgroundId: createBackground.data.backgroundCreate.id,
            files: [{ id: fileId }],
          },
        });
      }
    } else {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
        };

        // Create Brief
        const createBrief = await API.graphql({
          query: mCreateBrief,
          variables: params,
        });

        console.log("createBrief", createBrief);
        briefId = createBrief.data.briefCreate.id;

        updatePresetsForNewlyCreatedBrief(createBrief, label.name);
      } else {
        console.log(label.name === null ? "null" : label.name);
        if (existingBriefNameLabel === null) {
          // Tag Label to Brief
          await API.graphql({
            query: mUpdateBrief,
            variables: {
              id: briefId,
              labelId: label.id,
            },
          });
        }
      }

      const fileId = mf[0].id,
        fileDetails = mf[0].details,
        fileDate =
          mf[0].date != null
            ? moment
                .utc(moment(new Date(mf[0].date), "YYYY-MM-DD"))
                .toISOString()
            : null;

      // Create Background
      const createBackground = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: [{ name: label.name, id: briefId }],
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      returnBgId = createBackground.data.backgroundCreate.id;
      returnFileDate = fileDate;
      console.log("createBackground", createBackground);
      if (createBackground.data.backgroundCreate.id !== null) {
        // Tag File to Background
        await API.graphql({
          query: mUpdateBackgroundFile,
          variables: {
            backgroundId: createBackground.data.backgroundCreate.id,
            files: [{ id: fileId }],
          },
        });
      }
    }

    const backgroundOptNew = await API.graphql({
      query: qGetBackgroundsByBriefId,
      variables: {
        id: briefId,
        nextToken: null,
        sortOrder: "ORDER_ASC",
        limit: null,
      },
    }).catch((error) => {
      console.log("ERROR FETCHING BACKGROUND DATA: RESTART PAGE");
    });

    if (backgroundOptNew?.data?.brief?.backgrounds?.items !== null) {
      var result = backgroundOptNew?.data.brief.backgrounds.items.map(
        ({
          id,
          description,
          comments,
          date,
          createdAt,
          order,
          files,
          labels,
          briefs,
          dynamicData,
          email,
        }) => ({
          createdAt: createdAt,
          id: id,
          description: description,
          comments: comments,
          date: date,
          order: order,
          files: files,
          labels: labels,
          briefs: briefs,
          email: email,
          dynamicData,
        })
      );

      if (result !== null) {
        const rowArrangement = result.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        console.log("!!! rowArrangement", rowArrangement);

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement).then(() => {
          getMatterFiles(1);
        });

        console.log("!!! done updateBackgroundOrders");
      }
    }

    // return returnBriefId;

    // var indx = matterFiles.findIndex((x) => x.id === row_id);
    // const add = {
    //   briefs: [{id: returnBriefId,name: label.name }],
    //   id: returnBgId,
    //   createdAt: returnFileDate,
    //   date: returnFileDate,
    //   description: "",
    //   order: 0,
    // };

    // matterFiles[indx].backgrounds.items = [
    //   ...matterFiles[indx].backgrounds.items,
    //   add,
    // ];

    console.log("updated", matterFiles);
    console.groupEnd("createBackgroundFromLabel");
  };

  useEffect(() => {
    console.log("MATTER FILES IS:", matterFiles);
  }, [matterFiles]);

  useEffect(() => {
    console.log("TRIGGERED");
    if (isExpandAllActive) {
      let outerStateArray = [];
      let descStateArray = [];
      let innerStateArray = [];
      matterFiles.map((data) => {
        outerStateArray = [...outerStateArray, data.id];
        descStateArray = [...descStateArray, data.id];
        data.backgrounds.items.map((background) => {
          innerStateArray = [...innerStateArray, data.id + "/" + background.id];
        });
      });
      setReadMoreStateDesc(descStateArray);
      setReadMoreStateOuter(outerStateArray);
      setReadMoreStateInner(innerStateArray);
      console.log("EXPAND");
    } else {
      setReadMoreStateDesc([]);
      setReadMoreStateOuter([]);
      setReadMoreStateInner([]);
      console.log("COLLAPSE");
    }
  }, [isExpandAllActive]);

  function isReadMoreExpandedOuter(fileId) {
    return (
      readMoreStateOuter.find((temp) => {
        return temp === fileId;
      }) !== undefined
    );
  }
  function isReadMoreExpandedDesc(fileId) {
    return (
      readMoreStateDesc.find((temp) => {
        return temp === fileId;
      }) !== undefined
    );
  }

  function isReadMoreExpandedInner(fileId, bgId) {
    return (
      readMoreStateInner.find((temp) => {
        return temp === fileId + "/" + bgId;
      }) !== undefined
    );
  }

  function countLines(tag) {
    if (tag !== null) {
      var divHeight = tag.offsetHeight;
      var lineHeight = parseInt(
        window.getComputedStyle(tag).getPropertyValue("line-height")
      );
      var lines = Math.round(divHeight / lineHeight);
      return lines;
    }
  }
  function handleScrollEvent(e) {
    const top = e.target.scrollTop > 20;
    if (top) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }
  function handleScrollToTop() {
    let d = document.getElementById("mobileContent");
    d.scrollTo(0, 0);
  }

  const [mflength, setMflength] = useState(0);

  useEffect(() => {
    if (matterFiles != null) {
      matterFiles.map((data) => {
        var descTag = document.getElementById(data.id + ".desc");
        if (descTag !== null) {
          var lines = countLines(descTag);
          var descButtonTag = document.getElementById(data.id + ".descButton");
          /* if (lines > 5) {
            let bool = isReadMoreExpandedOuter(data.id);
            descButtonTag.style.display = bool ? "inline-block" : "none";
          } else {
            descButtonTag.style.display = "none";
          } */
        }
      });

      setMflength(matterFiles?.length);
      autoAdjustAllRow();
    }
  }, [matterFiles, readMoreStateDesc, readMoreStateOuter, width]);

  const handleSnippetEmailMobile = async (file) => {
    setSelectedFile(file);
    setShow(true);
    if (file.isGmailPDF) {
      setIsGmailPDF(true);
      // setSnippetId(gmailMessageId);
      const params = {
        query: EMAIL_PREVIEW,
        variables: {
          id: file.gmailMessageId,
        },
      };

      await API.graphql(params).then((result) => {
        setPreview(result.data.gmailMessage.payload);
        setFrom(result.data.gmailMessage.from);
        setDateEmail(result.data.gmailMessage.date);
        setSubject(result.data.gmailMessage.subject);
        setTo(result.data.gmailMessage.to);
        setCC(result.data.gmailMessage.cc);
      });
    } else {
      setIsGmailPDF(false);
      const params = {
        query: qGetFileDownloadLink,
        variables: {
          id: file.id,
        },
      };

      await API.graphql(params).then(async (result) => {
        const { type, downloadURL, s3ObjectKey } = result.data.file;

        if (
          (type &&
            (type.includes("vnd.openxmlformats-officedocument") ||
              type.includes("application/msword"))) ||
          isMSdoc(s3ObjectKey)
        ) {
          var encodedUrl = encodeURIComponent(downloadURL);
          var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
          setDownloadLink(documentViewerUrl);
          setShow(true);
        } else {
          setDownloadLink(downloadURL);
          setShow(true);
        }
      });
    }
  };

  //trying tanstack in file bucket mobile
  const parentRef = React.useRef();

  // The virtualizer
  // const rowVirtualizer = useVirtualizer({
  //   count: mflength,
  //   getScrollElement: () => parentRef.current,
  //   // estimateSize: () => 200,
  //   overscan: 20,
  //   estimateSize: useCallback(() => 120, []),
  //   initialOffset: 5,
  //   getTotalSize: () => height - 70,
  // });

  // const virtualRows = rowVirtualizer.getVirtualItems();
  // const totalSize = rowVirtualizer.getTotalSize();

  const handleMobileClose = () => {
    setShow(false);
    setDownloadLink("");
    setSubject(null);
  };

  const featureAccessFilters = async () => {
    console.log("\n\n - - - featureAccessFilters - - - \n\n");

    const filebucketAccess = await AccessControl("FILEBUCKET");
    const filebucketFeatures = filebucketAccess?.data?.features;
    console.log("filebucketAccess:", filebucketAccess);
    if (filebucketAccess.status !== "restrict") {
      setAllowEditDate(filebucketFeatures.includes("EDITDATE"));
      setAllowEditDescription(filebucketFeatures.includes("EDITDESCRIPTION"));
      setAllowEditLabels(filebucketFeatures.includes("EDITLABELS"));
      setAllowEditFiles(filebucketFeatures.includes("EDITFILES"));
      setAllowEditFileName(filebucketFeatures.includes("EDITFILENAME"));
      setAllowDeleteRows(filebucketFeatures.includes("DELETEROWS"));
      setAllowShowLabels(filebucketFeatures.includes("SHOWLABELS"));
    } else {
      console.log(filebucketAccess.message);
    }
  };
  const dateHeader = () => {
    return (
      <div>
        <span> Date &nbsp; </span>
        {(() => {
          if (ascDesc == null) {
            return (
              <FaSort
                className="mx-auto inline-block"
                alt="Sort"
                title="Sort"
                onClick={SortBydate}
                style={{ cursor: "pointer" }}
              />
            );
          } else if (ascDesc === true) {
            return (
              <BsSortUpAlt
                className="mx-auto inline-block"
                alt="Sort"
                title="Sort"
                onClick={SortBydate}
                style={{ cursor: "pointer" }}
              />
            );
          } else if (ascDesc === false) {
            return (
              <BsSortDown
                className="mx-auto inline-block"
                alt="Sort"
                title="Sort"
                onClick={SortBydate}
                style={{ cursor: "pointer" }}
              />
            );
          }
        })()}
      </div>
    );
  };

  // TANSTACK VIRTUALIZED WEB
  const tableContainerRef = useRef(null);

  const table = useReactTable({
    data: matterFiles !== null ? matterFiles : [],
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    parentRef: tableContainerRef,
    count: rows.length,
    overscan: rows.length,
    estimateSize: useCallback(() => 102, []), // THIS IS AN ESTIMATE FOR WEB, ADD CONDITION FOR MOBILE
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  const totalSize = rowVirtualizer.getTotalSize();

  const vTablePaddingTop =
    virtualRows?.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const vTablePaddingBottom =
    virtualRows?.length > 0
      ? totalSize - (virtualRows[virtualRows?.length - 1]?.end || 0)
      : 0;

  const paddingBottom = 20;

  //mobile
  // const paddingTop = virtualRows?.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  // const paddingBottom =
  //   virtualRows?.length > 0
  //     ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
  //     : 0;

  return (
    <>
      {/* MAIN CONTAINER */}
      <div
        className={
          "test123 p-0 static bg-gray-100 sm:bg-white sm:relative flex flex-col min-w-screen min-h-screen sm:min-h-0 sm:min-w-0 break-words sm:shadow-lg sm:rounded contentDiv "
        }
      >
        {width > 844 ? (
          <>
            <div className="sticky pt-5 pl-16 sm:pl-5 top-0 py-4 hidden sm:flex items-center gap-2 bg-white z-10 w-full">
              <div
                onClick={() => history.goBack()}
                className="w-8 py-5 cursor-pointer"
              >
                <CgChevronLeft />
              </div>
              <div>
                <p className="flex flex-col">
                  <span className="text-lg font-bold">File Bucket</span>{" "}
                  <span className=" text-grey-600">
                    {checkFormat(client_name)} / {checkFormat(matter_name)}
                  </span>
                </p>
                <div className="flex items-center gap-3 text-gray-500 mt-2">
                  <Link to="/dashboard">
                    <div className="flex gap-3">
                      <FaTachometerAlt />
                      <p className="font-semibold hidden sm:block">Dashboard</p>
                    </div>
                  </Link>
                  <span>/</span>
                  <p className="font-semibold sm:hidden">RFI</p>
                  <Link
                    to={`${
                      AppRoutes.FILEBUCKET
                    }/${matter_id}/000/?matter_name=${utf8_to_b64(
                      matter_name
                    )}&client_name=${utf8_to_b64(client_name)}`}
                  >
                    <p className="font-bold text-black">File Bucket</p>
                  </Link>

                  <span>/</span>
                  <Link
                    to={`${
                      AppRoutes.BRIEFS
                    }/${matter_id}/?matter_name=${utf8_to_b64(
                      matter_name
                    )}&client_name=${utf8_to_b64(client_name)}`}
                  >
                    <p className="font-semibold hidden sm:block">Background</p>
                  </Link>
                </div>
              </div>
            </div>
            {/* WEB VIEW */}
            {/* <div className="block flex-1 z-40">
          <div style={mainGrid}>
            <div>
                <button className="bg-white hover:bg-gray-100 text-black font-semibold py-2.5 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring mb-3"
                  onClick={() => history.goBack()}>
                  <MdArrowBackIos />
                  Back
                </button>
            </div>
          </div>
        </div> */}

            {/* DON'T DELETE THIS PART. THIS IS A CLONE FOR SCROLLING DOWN */}
            {/* <div
          style={{ position: "sticky", top: "0" }}
          className="block py-5 bg-white z-30"
        >
          <p className="font-bold text-xl bg-white w-full">
            File Bucket&nbsp;<span className="text-xl">of</span>&nbsp;
            <span className="font-semibold text-xl">
              {checkFormat(client_name)}
            </span>
            /
            <span className="font-semibold text-xl">
              {checkFormat(matter_name)}
            </span>
          </p>
        </div> */}
            {/* END OF CLONE */}

            {/* BREADCRUMB MENU */}
            <div
              className="block bg-white z-40 sticky p-5 pb-0"
              style={{ top: "67px" }}
            >
              {/* <nav
            aria-label="Breadcrumb"
            style={style}
            className="-ml-7 mb-3"
          >
            <ol className="px-0 flex items-left space-x-2 max-w-7xl px-8">
              <li>
                <div className="flex items-center">
                  <Link
                    className="sm:mr-2 text-xs uppercase sm:normal-case sm:text-sm font-medium text-gray-400 sm:text-gray-900"
                    to={`${AppRoutes.DASHBOARD}`}
                  >
                    Dashboard
                  </Link>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="w-4 h-5 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
              <li className="text-sm">
                <Link
                  aria-current="page"
                  className="text-xs uppercase sm:normal-case sm:text-sm font-medium text-gray-400 sm:text-gray-900"
                  to={`${
                    AppRoutes.BRIEFS
                  }/${matter_id}/?matter_name=${utf8_to_b64(
                    matter_name
                  )}&client_name=${utf8_to_b64(client_name)}`}
                >
                  Background
                </Link>
              </li>
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-4 h-5 text-gray-300"
              >
                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
              </svg>
              <li className="text-sm">
                <Link
                  aria-current="page"
                  className="text-xs uppercase sm:normal-case sm:text-sm underline underline-offset-4 sm:no-underline font-medium text-gray-700 sm:text-gray-500"
                  to={`${
                    AppRoutes.FILEBUCKET
                  }/${matter_id}/000/?matter_name=${utf8_to_b64(
                    matter_name
                  )}&client_name=${utf8_to_b64(client_name)}`}
                >
                  File Bucket
                </Link>
              </li>
            </ol>
          </nav> */}

              {files !== null && files?.length !== 0 && (
                <div className="block w-full mb-3 pb-2">
                  <span className="z-40 leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 py-3 px-3">
                    <IoIcons.IoIosSearch />
                  </span>
                  <input
                    type="search"
                    placeholder="Type to search files in the File Bucket ..."
                    onChange={handleSearchFileChange}
                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pl-10"
                  />
                </div>
              )}

              <div className="hidden sm:grid z-50 pl-2 py-1 grid-cols-1 gap-4">
                <div className="">
                  {matterFiles !== null && matterFiles?.length !== 0 && (
                    <input
                      type="checkbox"
                      className="mt-1 mr-3 px-2"
                      onChange={(e) => checkAll(e)}
                      checked={
                        isAllChecked && selectedItems.length !== 0
                          ? true
                          : false
                      }
                    />
                  )}
                  <span
                    style={{
                      display: !allowEditFiles ? "none" : "",
                    }}
                  >
                    <Button
                      className="py-1 px-5"
                      onClick={() => setShowUploadModal(true)}
                    >
                      FILE UPLOAD &nbsp;
                      <FiUpload />
                    </Button>
                  </span>
                  <div className="inline-flex">
                    {showCopyToBackgroundButton && (
                      <Button
                        className="py-1 px-5 mx-2"
                        onClick={() => handleShowCopyOptions()}
                      >
                        COPY {selectedItems.length}{" "}
                        {selectedItems.length > 1 ? "ROWS" : "ROW"} TO
                        BACKGROUND PAGE &nbsp;
                        <FiCopy />
                      </Button>
                    )}

                    {copyOptions && selectedItems.length !== 0 ? (
                      <div className="w-72 h-38 z-50 bg-white absolute mt-10 ml-2 rounded border-0 shadow outline-none">
                        <div className="flex">
                          <p className="px-2 py-2 text-gray-400 text-xs font-semibold">
                            Results
                          </p>
                          <button
                            className={
                              copyBgIds
                                ? "px-2 py-2 text-blue-400 text-xs font-semibold ml-16 cursor-pointer"
                                : "px-2 py-2 text-blue-200 text-xs font-semibold ml-16"
                            }
                            onClick={() => handleCopyToBg()}
                            disabled={copyBgIds ? false : true}
                          >
                            Copy To Background
                          </button>
                        </div>

                        <Multiselect
                          isObject={false}
                          onSelect={(event) => handleFilterChange(event)}
                          onRemove={(event) => handleFilterRemoveChange(event)}
                          options={copyBgOptions.map((x) => x.label)}
                          value={selected}
                          showCheckbox
                          className="z-50"
                          placeholder={"Search"}
                        />
                      </div>
                    ) : null}
                  </div>

                  {showAttachBackgroundButton && backgroundRowId !== "000" && (
                    <button
                      className="bg-blue-400 hover:bg-blue-300 text-white font-semibold py-1 px-5 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring"
                      onClick={() => tagBackgroundFile()}
                    >
                      Attach to Background &nbsp;|
                      <BsArrowLeft />
                    </button>
                  )}

                  {matterFiles !== null &&
                    matterFiles?.length !== 0 &&
                    showRemoveFileButton && (
                      <Button
                        variant="primary"
                        className="py-1 px-5 ml-3"
                        onClick={() => handleDuplicate()}
                      >
                        Duplicate {selectedItems.length}{" "}
                        {selectedItems.length > 1 ? "Rows" : "Row"} &nbsp;
                        <FiCopy />
                      </Button>
                    )}

                  <div className="inline-flex mr-0 float-right">
                    {matterFiles !== null &&
                      matterFiles?.length !== 0 &&
                      showRemoveFileButton && (
                        <Button
                          variant="danger"
                          className="py-1 px-5 ml-3"
                          onClick={() => setshowRemoveFileModal(true)}
                        >
                          DELETE {selectedItems.length}{" "}
                          {selectedItems.length > 1 ? "ROWS" : "ROW"} &nbsp;
                          <BsFillTrashFill />
                        </Button>
                      )}

                    <Button
                      className="py-1 px-5 ml-3"
                      variant="default-s"
                      size="xs"
                      onClick={
                        filterModalState
                          ? () => showAlert()
                          : () => setFilterLabels(true)
                      }
                      disabled={filterModalState}
                    >
                      <AiFillTags />
                    </Button>
                  </div>
                </div>

                <div className=" grid justify-items-end mr-0"></div>
              </div>

              <div className="hidden sm:block px-2 py-0 left-0">
                <p className={"text-sm font-small font-bold"}>FILES</p>
              </div>
            </div>

            {/* MAIN TABLE */}
            {skeletonLoad === true ? (
              //Web skeleton loading

              width > 640 ? (
                <>
                  <table className="table-fixed min-w-full divide-y divide-gray-200 text-xs">
                    <thead
                      className="bg-gray-100 z-20"
                      style={{ position: "sticky", top: "235px" }}
                    >
                      <tr>
                        <th className="px-2 py-4 text-center whitespace-nowrap w-1/12">
                          <Skeleton count={1} />
                        </th>
                        <th className="px-2 py-4 text-center whitespace-nowrap w-1/12">
                          <span className="">
                            <Skeleton count={1} />
                          </span>
                        </th>
                        <th className="px-2 py-4 text-center whitespace-nowrap w-2/12">
                          <Skeleton count={1} />
                        </th>
                        <th className="px-2 py-4 text-center whitespace-nowrap w-5/12">
                          <Skeleton count={1} />
                        </th>
                        <th className="px-2 py-4 text-center whitespace-nowrap w-2/12">
                          <Skeleton count={1} />
                        </th>
                        <th className="px-2 py-4 text-center whitespace-nowrap w-1/12">
                          <Skeleton count={1} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(4).keys()].map((data, index) => {
                        return (
                          <tr>
                            <td className="p-2 align-top">
                              <div className="ml-6 align-top">
                                <SkeletonTheme width={"20px"} height={"20px"}>
                                  <p>
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="p-2 align-top">
                              <div>
                                <SkeletonTheme height="40px" width="110px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="p-2 align-top">
                              <div>
                                <SkeletonTheme>
                                  <p>
                                    <Skeleton count={3} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="p-2 align-top">
                              <div>
                                <SkeletonTheme>
                                  <p>
                                    <Skeleton count={3} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="p-2 align-top">
                              <div>
                                <SkeletonTheme height="40px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="p-2 align-top">
                              <div>
                                <SkeletonTheme height="40px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : null
            ) : (
              //End of web skeleton loading
              //BLANK OR EMPTY STATE
              <>
                {matterFiles?.length === 0 &&
                (searchFile === undefined || searchFile === "") ? (
                  <div className="bg-white rounded-lg rounded-none p-5 px-5 py-1 left-0">
                    <div
                      className="w-full flex items-center sm:flex-none sm:h-42 sm:bg-gray-100 sm:rounded-lg sm:border sm:border-gray-200 sm:mb-6 sm:py-1 sm:px-1"
                      style={{ height: width > 640 ? "auto" : contentHeight }}
                    >
                      {width > 640 ? (
                        <BlankState
                          title={"items"}
                          txtLink={"file upload button"}
                          handleClick={() => setShowUploadModal(true)}
                        />
                      ) : (
                        <BlankStateMobile
                          header={"There are no items to show in this view."}
                          content={
                            "Any uploaded files in the desktop will appear here"
                          }
                          svg={Illustration}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  //Blank or empty table

                  <>
                    {matterFiles !== null && matterFiles?.length !== 0 ? (
                      <>
                        <ScrollToTop
                          smooth
                          color="rgb(117, 117, 114);"
                          style={{ padding: "0.4rem" }}
                        />
                        <div className="block p-5 pt-0">
                          <div className="shadow border-b border-gray-200 rounded-lg my-5">
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="table-filebucket-1">
                                {(provider) => (
                                  <WindowScroller key={0}>
                                    {({
                                      height,
                                      isScrolling,
                                      scrollTop,
                                      scrollLeft,
                                    }) => (
                                      <table
                                        id="actualTable"
                                        className="min-w-full divide-y divide-gray-200 table-auto"
                                        style={{
                                          overflow: "auto",
                                        }}
                                      >
                                        <thead
                                          className="bg-gray-100 z-20"
                                          style={{
                                            position: "sticky",
                                            top: "110px",
                                          }}
                                        >
                                          <tr>
                                            <th
                                              className="px-2 py-4 text-center whitespace-nowrap w-1/12"
                                              colSpan={1}
                                            >
                                              Item No.
                                            </th>
                                            <th
                                              className="px-2 py-4 text-center whitespace-nowrap w-1/12"
                                              colSpan={1}
                                            >
                                              <span>
                                                {" "}
                                                Date Received: &nbsp;{" "}
                                              </span>
                                              {(() => {
                                                if (ascDesc == null) {
                                                  return (
                                                    <FaSort
                                                      className="mx-auto inline-block"
                                                      alt="Sort"
                                                      title="Sort"
                                                      onClick={SortBydate}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  );
                                                } else if (ascDesc === true) {
                                                  return (
                                                    <BsSortUpAlt
                                                      className="mx-auto inline-block"
                                                      alt="Sort"
                                                      title="Sort"
                                                      onClick={SortBydate}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  );
                                                } else if (ascDesc === false) {
                                                  return (
                                                    <BsSortDown
                                                      className="mx-auto inline-block"
                                                      alt="Sort"
                                                      title="Sort"
                                                      onClick={SortBydate}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  );
                                                }
                                              })()}
                                            </th>
                                            <th
                                              className="px-2 py-4 text-center whitespace-nowrap w-1/12"
                                              colSpan={1}
                                            >
                                              <span>
                                                {" "}
                                                Date Submitted: &nbsp;{" "}
                                              </span>
                                              {(() => {
                                                if (SubAscDesc == null) {
                                                  return (
                                                    <FaSort
                                                      className="mx-auto inline-block"
                                                      alt="Sort"
                                                      title="Sort"
                                                      onClick={SortBySubDate}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  );
                                                } else if (
                                                  SubAscDesc === true
                                                ) {
                                                  return (
                                                    <BsSortUpAlt
                                                      className="mx-auto inline-block"
                                                      alt="Sort"
                                                      title="Sort"
                                                      onClick={SortBySubDate}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  );
                                                } else if (
                                                  SubAscDesc === false
                                                ) {
                                                  return (
                                                    <BsSortDown
                                                      className="mx-auto inline-block"
                                                      alt="Sort"
                                                      title="Sort"
                                                      onClick={SortBySubDate}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  );
                                                }
                                              })()}
                                            </th>
                                            <th
                                              className="px-2 py-4 text-center whitespace-nowrap w-2/12"
                                              colSpan={2}
                                            >
                                              Name
                                            </th>
                                            <th
                                              className="px-2 py-4 text-center whitespace-nowrap w-3/12 2xl:w-4/12"
                                              colSpan={5}
                                            >
                                              Description
                                            </th>
                                            {!allowShowLabels ? (
                                              <></>
                                            ) : (
                                              <th
                                                className="px-2 py-4 text-center whitespace-nowrap w-2/12"
                                                colSpan={2}
                                              >
                                                Labels
                                              </th>
                                            )}
                                            <th
                                              className="px-2 py-4 text-center whitespace-nowrap w-2/12 2xl:w-1/12"
                                              colSpan={1}
                                            >
                                              Page Reference
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          ref={provider.innerRef}
                                          {...provider.droppableProps}
                                          className="bg-white divide-y divide-gray-200"
                                          style={{
                                            width: "100%",
                                            height: "100vh",
                                          }}
                                        >
                                          {/* Tanstack web -kjmf */}
                                          {virtualRows.map((virtualRow) => {
                                            const row = rows[virtualRow.index];
                                            const item = row.original;
                                            const index = row.index;
                                            const style = "";

                                            return (
                                              // <div
                                              //   style={{
                                              //     ...style,
                                              //     width: "100%",
                                              //     height: "100%",
                                              //     paddingBottom:
                                              //       index ===
                                              //       matterFiles.length - 1
                                              //         ? `${paddingBottom}px`
                                              //         : "0",
                                              //   }}
                                              // >
                                              <Draggable
                                                key={item.id + "-" + index}
                                                draggableId={
                                                  item.id + "-" + index
                                                }
                                                index={index}
                                              >
                                                {(provider, snapshot) => (
                                                  <tr
                                                    key={item.id}
                                                    index={index}
                                                    className="min-w-full h-full"
                                                    {...provider.draggableProps}
                                                    ref={provider.innerRef}
                                                    style={{
                                                      ...provider.draggableProps
                                                        .style,
                                                      backgroundColor:
                                                        snapshot.isDragging ||
                                                        item.id === selected
                                                          ? "rgba(255, 255, 239, 0.767)"
                                                          : "",
                                                      top: "auto !important",
                                                    }}
                                                  >
                                                    {/* COL 1: numbering and checkbox */}
                                                    <td
                                                      {...provider.dragHandleProps}
                                                      className="px-2 py-3 align-top col-span-1 w-1/12"
                                                      colSpan={1}
                                                    >
                                                      <div className="grid grid-cols-1 border-l-2">
                                                        <div className="inline-flex mb-16">
                                                          <MdDragIndicator
                                                            className="text-2xl"
                                                            onClick={() =>
                                                              handleChageBackground(
                                                                item.id
                                                              )
                                                            }
                                                          />
                                                          <input
                                                            className="cursor-pointer mr-1"
                                                            onChange={
                                                              handleSelectItem
                                                            }
                                                            type="checkbox"
                                                            checked={selectedItems.includes(
                                                              item.id
                                                            )}
                                                            value={item.id}
                                                            id={`data-${item.id}`}
                                                            disabled={
                                                              deletingState
                                                                ? true
                                                                : false
                                                            }
                                                          />
                                                          <span className="text-xs mt-1">
                                                            {index + 1}
                                                          </span>
                                                        </div>
                                                        {/* sub items */}
                                                        {sortByOrder(
                                                          item.backgrounds.items
                                                        ).map(
                                                          (
                                                            background,
                                                            counter
                                                          ) => (
                                                            <div className="text-xs flex ml-7 mt-7 border-l-2 pt-0.5 ">
                                                              {index + 1}.{" "}
                                                              {counter + 1}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </td>
                                                    {/* end COL 1: numbering and checkbox */}

                                                    {/* COL 2: Date Received*/}
                                                    <td
                                                      className="align-top py-2 col-span-1  w-1/12"
                                                      colSpan={1}
                                                    >
                                                      <div className="inline-flex mb-16">
                                                        <DatePicker
                                                          popperProps={{
                                                            positionFixed: true,
                                                          }}
                                                          className="border w-28 rounded text-xs py-2 px-1 border-gray-300"
                                                          dateFormat={datePickerDateFormatter(
                                                            localStorage.getItem(
                                                              "userpreferredDateFormatType"
                                                            )
                                                          )}
                                                          selected={
                                                            item?.date === null
                                                              ? null
                                                              : item?.date ===
                                                                undefined
                                                              ? null
                                                              : timezoneFormatter(
                                                                  item?.date,
                                                                  localStorage.getItem(
                                                                    "preferredTimezone"
                                                                  )
                                                                )
                                                          }
                                                          placeholderText="No Date"
                                                          onChange={(
                                                            selected
                                                          ) =>
                                                            handleChangeDate(
                                                              selected,
                                                              item.id
                                                            )
                                                          }
                                                          disabled={
                                                            !allowEditDate
                                                          }
                                                        />
                                                      </div>
                                                      {/* sub item */}
                                                      {sortByOrder(
                                                        item.backgrounds.items
                                                      ).map(
                                                        (background, index) => (
                                                          <div className="text-xs block mt-2 ml-3">
                                                            <DatePicker
                                                              popperProps={{
                                                                positionFixed: true,
                                                              }}
                                                              className=" mt-1 border w-28 rounded text-xs py-2 px-1 border-gray-300"
                                                              dateFormat={datePickerDateFormatter(
                                                                localStorage.getItem(
                                                                  "userpreferredDateFormatType"
                                                                )
                                                              )}
                                                              selected={
                                                                background.date ===
                                                                null
                                                                  ? null
                                                                  : background.date ===
                                                                    undefined
                                                                  ? null
                                                                  : timezoneFormatter(
                                                                      background?.date,
                                                                      localStorage.getItem(
                                                                        "preferredTimezone"
                                                                      )
                                                                    )
                                                              }
                                                              placeholderText="No Date"
                                                              onChange={(
                                                                selected
                                                              ) =>
                                                                handleChangeDateBackground(
                                                                  selected,
                                                                  background.id,
                                                                  item.id
                                                                )
                                                              }
                                                              disabled={
                                                                !allowEditDate
                                                              }
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </td>
                                                    {/* end COL 2: Date Received*/}

                                                    {/* COL 3: Date Submitted */}
                                                    <td
                                                      className="align-top py-2 col-span-1 w-1/12"
                                                      colSpan={1}
                                                    >
                                                      <div className="inline-flex mb-16">
                                                        <DatePicker
                                                          popperProps={{
                                                            positionFixed: true,
                                                          }}
                                                          className="border w-28 rounded text-xs py-2 px-1 border-gray-300"
                                                          dateFormat={datePickerDateFormatter(
                                                            localStorage.getItem(
                                                              "userpreferredDateFormatType"
                                                            )
                                                          )}
                                                          selected={
                                                            item?.createdAt ===
                                                            null
                                                              ? null
                                                              : item?.createdAt ===
                                                                undefined
                                                              ? null
                                                              : timezoneFormatter(
                                                                  item?.createdAt,
                                                                  localStorage.getItem(
                                                                    "preferredTimezone"
                                                                  )
                                                                )
                                                          }
                                                          placeholderText="No Date"
                                                          readOnly={true}
                                                        />
                                                      </div>
                                                    </td>
                                                    {/* end COL 3: Date Submitted */}

                                                    {/* COL 4: File Name */}
                                                    <td
                                                      {...provider.dragHandleProps}
                                                      className="px-2 py-3 align-top relative col-span-2 w-2/12"
                                                      colSpan={2}
                                                    >
                                                      <div className="flex flex-col place-items-start">
                                                        <div className="flex">
                                                          {getFileType(
                                                            item.type
                                                          ) === "image" ? (
                                                            <GrDocumentImage className="text-2xl flex-none" />
                                                          ) : getFileType(
                                                              item.type
                                                            ) === "audio" ? (
                                                            <FaRegFileAudio className="text-2xl flex-none" />
                                                          ) : getFileType(
                                                              item.type
                                                            ) === "video" ? (
                                                            <FaRegFileVideo className="text-2xl flex-none" />
                                                          ) : getFileType(
                                                              item.type
                                                            ) === "text" ? (
                                                            <GrDocumentTxt className="text-2xl flex-none" />
                                                          ) : getFileType(
                                                              item.type
                                                            ) ===
                                                              "application" &&
                                                            item.type
                                                              .split(".")
                                                              .pop() ===
                                                              "sheet" ? (
                                                            <GrDocumentExcel className="text-2xl flex-none" />
                                                          ) : getFileType(
                                                              item.type
                                                            ) ===
                                                              "application" &&
                                                            item.type
                                                              .split(".")
                                                              .pop() ===
                                                              "document" ? (
                                                            <GrDocumentWord className="text-2xl flex-none" />
                                                          ) : getFileType(
                                                              item.type
                                                            ) ===
                                                              "application" &&
                                                            item.type
                                                              .split(".")
                                                              .pop() ===
                                                              "text" ? (
                                                            <GrDocumentText className="text-2xl flex-none" />
                                                          ) : getFileType(
                                                              item.type
                                                            ) ===
                                                            "application" ? (
                                                            <GrDocumentPdf className="text-2xl flex-none" />
                                                          ) : (
                                                            <GrDocumentText className="text-2xl flex-none" />
                                                          )}
                                                          &nbsp;&nbsp;
                                                          <div>
                                                            <div
                                                              className="p-2 font-poppins break-all"
                                                              style={{
                                                                cursor: "auto",
                                                                outlineColor:
                                                                  "rgb(204, 204, 204, 0.5)",
                                                                outlineWidth:
                                                                  "thin",
                                                              }}
                                                              suppressContentEditableWarning={
                                                                true
                                                              }
                                                              onClick={(
                                                                event
                                                              ) =>
                                                                handleNameContent(
                                                                  event,
                                                                  item.name,
                                                                  item.id
                                                                )
                                                              }
                                                              onInput={(
                                                                event
                                                              ) =>
                                                                handleOnChangeName(
                                                                  event
                                                                )
                                                              }
                                                              onBlur={(e) =>
                                                                handleSaveName(
                                                                  e,
                                                                  item.name,
                                                                  item.id
                                                                )
                                                              }
                                                              contentEditable={
                                                                allowEditFileName &&
                                                                !updateProgess
                                                                  ? true
                                                                  : false
                                                              }
                                                            >
                                                              {item.name}
                                                            </div>

                                                            <div className="relative">
                                                              {item.gmailMessageId !==
                                                                null &&
                                                                item.gmailMessageId !==
                                                                  "0" &&
                                                                item.isGmailPDF && (
                                                                  <>
                                                                    {showEmailPreview &&
                                                                    item.gmailMessageId ===
                                                                      activeGmailMessageId ? (
                                                                      <button
                                                                        className="p-0 text-red-500 text-opacity-90 text-[12px] font-normal inline-flex items-center gap-x-2 rounded primary_light hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs"
                                                                        type="button"
                                                                        aria-expanded="false"
                                                                        onClick={
                                                                          handleRootClose
                                                                        }
                                                                      >
                                                                        close
                                                                        email
                                                                        <MdClose
                                                                          size={
                                                                            11
                                                                          }
                                                                        />
                                                                      </button>
                                                                    ) : (
                                                                      <button
                                                                        className="text-blue-600 underline text-opacity-90 text-[12px] font-normal inline-flex items-center gap-x-2 rounded primary_light hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs"
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          handleSnippetEmail(
                                                                            item.gmailMessageId,
                                                                            index
                                                                          )
                                                                        }
                                                                      >
                                                                        read
                                                                        more
                                                                        <FaEye
                                                                          size={
                                                                            11
                                                                          }
                                                                        />
                                                                      </button>
                                                                    )}
                                                                  </>
                                                                )}

                                                              {showEmailPreview &&
                                                                index ===
                                                                  showIndex && (
                                                                  <div
                                                                    className="absolute rounded shadow-2xl bg-white p-6 z-50 max-w-4xl overflow-auto"
                                                                    style={{
                                                                      maxHeight:
                                                                        "450px",
                                                                      minHeight:
                                                                        "450px",
                                                                    }}
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      e.stopPropagation()
                                                                    }
                                                                  >
                                                                    {item.gmailMessageId ===
                                                                      activeGmailMessageId &&
                                                                    emailPreviewFrom !==
                                                                      null ? (
                                                                      <>
                                                                        <p>{`From: ${emailPreviewFrom}`}</p>
                                                                        <p>{`Date: ${moment(
                                                                          emailPreviewDate
                                                                        ).format(
                                                                          "DD MMM YYYY, hh:mm A"
                                                                        )}`}</p>
                                                                        <p>{`Subject: ${emailPreviewSubject}`}</p>
                                                                        <p>{`To: ${emailPreviewTo}`}</p>
                                                                        {emailPreviewCc && (
                                                                          <p>{`CC: ${emailPreviewCc}`}</p>
                                                                        )}
                                                                        <p
                                                                          className="mt-8 p-2"
                                                                          dangerouslySetInnerHTML={{
                                                                            __html:
                                                                              Base64.decode(
                                                                                emailPreview
                                                                                  ?.map(
                                                                                    (
                                                                                      em
                                                                                    ) =>
                                                                                      em.content
                                                                                  )
                                                                                  .join(
                                                                                    ""
                                                                                  )
                                                                                  .split(
                                                                                    'data":"'
                                                                                  )
                                                                                  .pop()
                                                                                  .split(
                                                                                    '"}'
                                                                                  )[0]
                                                                              ).replace(
                                                                                "body{color:",
                                                                                ""
                                                                              ),
                                                                          }}
                                                                        ></p>
                                                                      </>
                                                                    ) : (
                                                                      <div
                                                                        style={{
                                                                          height:
                                                                            "450px",
                                                                          width:
                                                                            "750px",
                                                                        }}
                                                                      >
                                                                        <SkeletonTheme
                                                                          width={
                                                                            "300px"
                                                                          }
                                                                        >
                                                                          <p>
                                                                            <Skeleton
                                                                              count={
                                                                                4
                                                                              }
                                                                            />
                                                                          </p>
                                                                        </SkeletonTheme>
                                                                        <p className="mt-8 p-2">
                                                                          <Skeleton
                                                                            count={
                                                                              15
                                                                            }
                                                                          />
                                                                        </p>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </div>
                                                          <span>
                                                            {item.s3ObjectKey ===
                                                              null &&
                                                            item.isGmailPDF ? (
                                                              <AiOutlineDownload
                                                                className="text-blue-400 mx-1 text-2xl cursor-pointer right-0"
                                                                onClick={() =>
                                                                  previewAndDownloadPDF(
                                                                    item.id,
                                                                    item.gmailMessageId,
                                                                    item.name
                                                                  )
                                                                }
                                                              />
                                                            ) : (
                                                              <AiOutlineDownload
                                                                className="text-blue-400 mx-1 text-2xl cursor-pointer right-0"
                                                                onClick={() =>
                                                                  previewAndDownloadFile(
                                                                    item.id
                                                                  )
                                                                }
                                                              />
                                                            )}
                                                          </span>
                                                        </div>

                                                        <p className="absolute text-red-400 filename-validation z-100">
                                                          {item.id === fileId &&
                                                            fileAlert}
                                                        </p>
                                                      </div>
                                                    </td>
                                                    {/* end COL 4: File Name */}

                                                    {/* COL 5: Description */}
                                                    <td
                                                      {...provider.dragHandleProps}
                                                      className="px-2 py-3 align-top place-items-center relative col-span-5 w-3/12"
                                                      colSpan={5}
                                                    >
                                                      <div>
                                                        <div className="flex mb-12">
                                                          <span
                                                            className={
                                                              item.id ===
                                                              descriptionClassId
                                                                ? "w-full p-2 font-poppins h-full mx-2"
                                                                : "w-full p-2 font-poppins h-full mx-2 single-line"
                                                            }
                                                            style={{
                                                              cursor: "auto",
                                                              outlineColor:
                                                                "rgb(204, 204, 204, 0.5)",
                                                              outlineWidth:
                                                                "thin",
                                                            }}
                                                            suppressContentEditableWarning={
                                                              true
                                                            }
                                                            onClick={(event) =>
                                                              handleDetailsContent(
                                                                event,
                                                                item.details,
                                                                item.id,
                                                                index
                                                              )
                                                            }
                                                            onInput={(event) =>
                                                              handleOnChangeDetails(
                                                                event,
                                                                index
                                                              )
                                                            }
                                                            onBlur={(e) =>
                                                              handleSaveDetails(
                                                                e,
                                                                item.details,
                                                                item.id,
                                                                index
                                                              )
                                                            }
                                                            contentEditable={
                                                              allowEditDescription &&
                                                              !updateProgess
                                                                ? true
                                                                : false
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                item.details,
                                                            }}
                                                          ></span>

                                                          {item.details ===
                                                            null ||
                                                          item.details ===
                                                            undefined ||
                                                          item.details === "" ||
                                                          item.details.length <
                                                            47 ? (
                                                            <p></p>
                                                          ) : item.id ===
                                                            descriptionClassId ? (
                                                            <p></p>
                                                          ) : (
                                                            <p className="py-2 -ml-1">
                                                              {" "}
                                                              ...{" "}
                                                            </p>
                                                          )}
                                                        </div>
                                                        <br />
                                                        <span className="text-red-400 filename-validation">
                                                          {item.id === detId &&
                                                            descAlert}
                                                        </span>

                                                        {sortByOrder(
                                                          item.backgrounds.items
                                                        ).map(
                                                          (background, i) => (
                                                            <div className="flex mt-3.5">
                                                              <span
                                                                className={
                                                                  background.id ===
                                                                  descriptionClassId
                                                                    ? "w-full p-2 font-poppins mx-2 h-full"
                                                                    : "w-full p-2 font-poppins h-full single-line"
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "auto",
                                                                  outlineColor:
                                                                    "rgb(204, 204, 204, 0.5)",
                                                                  outlineWidth:
                                                                    "thin",
                                                                }}
                                                                suppressContentEditableWarning
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  handleDescContent(
                                                                    event,
                                                                    background.description,
                                                                    background.id,
                                                                    index +
                                                                      "-" +
                                                                      i,
                                                                    index
                                                                  )
                                                                }
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    background.description,
                                                                }}
                                                                onInput={(
                                                                  event
                                                                ) =>
                                                                  handleChangeDesc(
                                                                    event,
                                                                    index
                                                                  )
                                                                }
                                                                onBlur={(e) =>
                                                                  handleSaveDesc(
                                                                    e,
                                                                    background.description,
                                                                    background.date,
                                                                    background.id,
                                                                    index
                                                                  )
                                                                }
                                                                contentEditable={
                                                                  allowEditDescription &&
                                                                  !updateProgess
                                                                    ? true
                                                                    : false
                                                                }
                                                                ref={(el) =>
                                                                  (itemsRef.current[
                                                                    index +
                                                                      "-" +
                                                                      i
                                                                  ] = el)
                                                                }
                                                                onFocus={(e) =>
                                                                  handleChangeDescription(
                                                                    e,
                                                                    background.description,
                                                                    background.id,
                                                                    index +
                                                                      "-" +
                                                                      i,
                                                                    index
                                                                  )
                                                                }
                                                              ></span>

                                                              {background.description ===
                                                                null ||
                                                              background.description ===
                                                                undefined ||
                                                              background.description ===
                                                                "" ||
                                                              background
                                                                ?.description
                                                                .length < 47 ? (
                                                                <p></p>
                                                              ) : background.id ===
                                                                descriptionClassId ? (
                                                                <p></p>
                                                              ) : (
                                                                <p className="py-2">
                                                                  {" "}
                                                                  ...{" "}
                                                                </p>
                                                              )}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </td>
                                                    {/* end COL 5: Description */}

                                                    {/* COL 6: Labels */}
                                                    {!allowShowLabels ? (
                                                      <></>
                                                    ) : (
                                                      <td
                                                        className="px-2 py-3 align-top place-items-center relative col-span-2 w-2/12"
                                                        colSpan={2}
                                                      >
                                                        {!allowEditLabels ? (
                                                          <div className="px-5 py-1 mr-2 mt-8"></div>
                                                        ) : null}

                                                        <span
                                                          style={{
                                                            display:
                                                              !allowEditLabels
                                                                ? "none"
                                                                : "",
                                                          }}
                                                        >
                                                          <Button
                                                            onClick={() => {
                                                              if (
                                                                ShowLabel[0]
                                                                  .index ===
                                                                index
                                                              ) {
                                                                setShowLabel([
                                                                  { index: -1 },
                                                                ]);
                                                              } else {
                                                                setShowLabel([
                                                                  {
                                                                    index:
                                                                      index,
                                                                  },
                                                                ]);
                                                              }
                                                            }}
                                                            className={`px-5 py-1 mr-2 mt-4`}
                                                            style={{
                                                              width: "110px",
                                                            }}
                                                          >
                                                            {ShowLabel[0]
                                                              .index === index
                                                              ? "Cancel Label"
                                                              : "Add Label"}
                                                          </Button>
                                                        </span>

                                                        {ShowLabel[0].index ===
                                                          index && (
                                                          <>
                                                            <Select
                                                              menuPlacement={
                                                                "auto"
                                                              }
                                                              disabled={
                                                                !allowEditLabels &&
                                                                DisableSelect
                                                              }
                                                              menuPortalTarget={
                                                                document.body
                                                              }
                                                              style={{
                                                                zIndex: "1000",
                                                                position:
                                                                  "absolute",
                                                                minWidth:
                                                                  "230px",
                                                              }}
                                                              options={labelOption.filter(
                                                                (elem) =>
                                                                  !item.backgrounds.items
                                                                    .map(
                                                                      (
                                                                        item1
                                                                      ) => {
                                                                        return item1
                                                                          .briefs[0]
                                                                          .name;
                                                                      }
                                                                    )
                                                                    .includes(
                                                                      elem.label
                                                                    )
                                                              )}
                                                              clearable={true}
                                                              searchable={true}
                                                              openOnFocus={true}
                                                              onChange={(
                                                                selectedItems
                                                              ) => {
                                                                if (
                                                                  !newLabelDropdown
                                                                ) {
                                                                  handleLabelChanged(
                                                                    item.id,
                                                                    selectedItems[0],
                                                                    item.labels
                                                                      .items,
                                                                    index
                                                                  );
                                                                }
                                                              }}
                                                              create
                                                              onCreateNew={(
                                                                e
                                                              ) => {
                                                                console.log(
                                                                  item.backgrounds.items.map(
                                                                    (item1) => {
                                                                      return item1
                                                                        .briefs[0]
                                                                        .name;
                                                                    }
                                                                  ),
                                                                  !item.backgrounds.items
                                                                    .map(
                                                                      (
                                                                        item1
                                                                      ) => {
                                                                        return item1
                                                                          .briefs[0]
                                                                          .name;
                                                                      }
                                                                    )
                                                                    .includes(
                                                                      e.label
                                                                    )
                                                                );
                                                                setNewLabelDropdown(
                                                                  true
                                                                );
                                                                if (
                                                                  !item.backgrounds.items
                                                                    .map(
                                                                      (
                                                                        item1
                                                                      ) => {
                                                                        return item1
                                                                          .briefs[0]
                                                                          .name;
                                                                      }
                                                                    )
                                                                    .includes(
                                                                      e.label
                                                                    )
                                                                ) {
                                                                  console.log(
                                                                    "Create",
                                                                    {
                                                                      label:
                                                                        e.label,
                                                                      value:
                                                                        e.value,
                                                                      __isNew__: true,
                                                                    }
                                                                  );
                                                                  handleLabelChanged(
                                                                    item.id,
                                                                    {
                                                                      label:
                                                                        e.label,
                                                                      value:
                                                                        e.value,
                                                                      __isNew__: true,
                                                                    },
                                                                    item.labels
                                                                      .items,
                                                                    index
                                                                  );
                                                                }
                                                              }}
                                                              placeholder="Labels"
                                                              className="mt-2 -mb-2 absolute w-full placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring z-100"
                                                            />
                                                          </>
                                                        )}

                                                        <div className="grid grid-cols-1">
                                                          <div className="flex mb-14"></div>
                                                          {sortByOrder(
                                                            item.backgrounds
                                                              .items
                                                          ).map(
                                                            (
                                                              background,
                                                              index
                                                            ) =>
                                                              background?.briefs ===
                                                                null ||
                                                              background?.briefs ===
                                                                undefined ? (
                                                                <div
                                                                  key={
                                                                    background.id
                                                                  }
                                                                  index={index}
                                                                ></div>
                                                              ) : (
                                                                <div
                                                                  className="overflow-y-auto max-h-10 w-full py-3 p-1 mb-1.5 text-xs bg-gray-100  hover:bg-gray-900 hover:text-white rounded-lg cursor-pointer flex"
                                                                  index={index}
                                                                  onClick={(
                                                                    event
                                                                  ) =>
                                                                    handleRedirectLink(
                                                                      event,
                                                                      background.id
                                                                    )
                                                                  }
                                                                >
                                                                  <b>
                                                                    {
                                                                      background
                                                                        ?.briefs[0]
                                                                        ?.name
                                                                    }
                                                                  </b>
                                                                </div>
                                                              )
                                                          )}
                                                        </div>
                                                      </td>
                                                    )}
                                                    {/* end COL 6: Labels */}

                                                    {/* COL 7: Page Reference */}
                                                    <td
                                                      {...provider.dragHandleProps}
                                                      className="px-2 py-3 align-top place-items-center relative flex-wrap col-span-1 w-1/12"
                                                      colSpan={1}
                                                    >
                                                      <div className="grid grid-cols-1">
                                                        <div className="flex mb-24"></div>
                                                        {sortByOrder(
                                                          item.backgrounds.items
                                                        ).map(
                                                          (background, index) =>
                                                            background?.briefs ===
                                                              null ||
                                                            background?.briefs ===
                                                              undefined ? (
                                                              <div
                                                                className="h-10.5 py-3 p-1 mb-1.5"
                                                                index={index}
                                                              ></div>
                                                            ) : (
                                                              <>
                                                                <div className="flex mb-1.5 h-10.5">
                                                                  <div
                                                                    className="h-10 items-center w-24 p-1 text-xs bg-gray-100  hover:bg-gray-900 hover:text-white rounded-md cursor-pointer flex"
                                                                    index={
                                                                      index
                                                                    }
                                                                    onClick={(
                                                                      event
                                                                    ) =>
                                                                      handleRedirectLink(
                                                                        event,
                                                                        background.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <b>
                                                                      {`Row ${background.order}`}
                                                                    </b>
                                                                  </div>

                                                                  {allowDeleteRows && (
                                                                    <div
                                                                      className="ml-2 mr-2 h-10 items-center w-6 p-1 text-xs text-red-400 bg-gray-100  hover:bg-gray-900 hover:text-white rounded-md cursor-pointer flex justify-center"
                                                                      index={
                                                                        index
                                                                      }
                                                                      onClick={() =>
                                                                        handleDeleteBackground(
                                                                          background?.id,
                                                                          background?.files,
                                                                          item.id,
                                                                          index
                                                                        )
                                                                      }
                                                                    >
                                                                      <b>
                                                                        <CgTrash />
                                                                      </b>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )
                                                        )}
                                                      </div>
                                                    </td>
                                                    {/* end COL 7: Page Reference */}
                                                  </tr>
                                                )}
                                              </Draggable>
                                              // </div>
                                            );
                                          })}
                                          {provider.placeholder}
                                        </tbody>
                                      </table>
                                    )}
                                  </WindowScroller>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                          <div className="p-2"></div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {/* MOBILE VIEW (INFORM KJMF FOR MAJOR EDITS) */}
            <div className="flex flex-col justify-end">
              <div className="pt-5 sm:bg-white sm:z-40 justify-end">
                <p className="hidden sm:block font-bold text-3xl ">
                  File Bucket&nbsp;<span className="text-3xl">of</span>&nbsp;
                  <span className="font-semibold text-3xl">
                    {checkFormat(client_name)}
                  </span>
                  /
                  <span className="font-semibold text-3xl">
                    {checkFormat(matter_name)}
                  </span>
                </p>
                <div
                  className="flex justify-end px-5"
                  style={{
                    position: headerLines > 1 ? "absolute" : "static",
                    zIndex: headerLines > 1 ? "-50" : "auto",
                  }}
                >
                  <p
                    id="headerTag"
                    className="sm:hidden font-bold"
                    style={{ lineHeight: "24px" }}
                  >
                    <span className="font-semibold text-base">
                      {checkFormat(client_name)}
                    </span>
                    &nbsp;
                    <span className="font-light text-base text-gray-500">
                      {checkFormat(matter_name)}
                    </span>
                  </p>
                  {width < 301 ? (
                    <>
                      <button className="invisible font-semibold rounded inline-flex items-center border-0 w-5 h-5 rounded-full outline-none focus:outline-none active:bg-current">
                        {!headerReadMore ? <FiChevronDown /> : <FiChevronUp />}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* IF HEADER LINES IS LONG, THEN OVERLAY WITH READMORE */}
                {headerLines > 1 ? (
                  <div className="sm:hidden flex justify-items-end items-end flex-row w-full justify-end text-right">
                    <p
                      className={
                        "flex-auto pl-14 sm:hidden " +
                        (headerReadMore ? "" : "truncate")
                      }
                    >
                      <span className="font-semibold text-base">
                        {checkFormat(client_name)}
                      </span>
                      &nbsp;
                      <span className="font-light text-base text-gray-500">
                        {checkFormat(matter_name)}
                        {/*headerReadMore ? checkFormat(matter_name) : ellipsis(checkFormat(matter_name),30)*/}
                      </span>
                    </p>
                    <button
                      onClick={() => setHeaderReadMore(!headerReadMore)}
                      className="shrink-0 hover:bg-gray-100 text-gray-500 font-semibold rounded inline-flex items-center border-0 w-5 h-5 rounded-full outline-none focus:outline-none active:bg-current"
                    >
                      {!headerReadMore ? <FiChevronDown /> : <FiChevronUp />}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="justify-end">
                <nav
                  aria-label="Breadcrumb"
                  //style={style}
                  className="pb-6  justify-end"
                >
                  <ol className="px-5 flex items-right sm:space-x-2 lg:max-w-7xl lg:px-8 items-center justify-end">
                    <li>
                      <div className="flex items-center">
                        <Link
                          className="sm:mr-2 text-xs uppercase sm:normal-case sm:text-sm font-medium text-gray-400 sm:text-gray-900"
                          to={`${AppRoutes.DASHBOARD}`}
                        >
                          Dashboard
                        </Link>
                      </div>
                    </li>
                    <FaSlash className="h-2 w-4 text-gray-300 rotate-45 transform" />
                    <li className="text-sm">
                      <Link
                        aria-current="page"
                        className="text-xs uppercase sm:normal-case sm:text-sm font-medium text-gray-400 sm:text-gray-900"
                        to={`${
                          AppRoutes.BRIEFS
                        }/${matter_id}/?matter_name=${utf8_to_b64(
                          matter_name
                        )}&client_name=${utf8_to_b64(client_name)}`}
                      >
                        Background
                      </Link>
                    </li>

                    <FaSlash className="h-2 w-4 text-gray-300 rotate-45 transform" />
                    <li className="text-sm underline underline-offset-4 sm:no-underline font-medium text-gray-700 sm:text-gray-500">
                      <Link
                        aria-current="page"
                        className="text-xs uppercase sm:normal-case sm:text-sm underline underline-offset-4 sm:no-underline font-medium text-gray-700 sm:text-gray-500"
                        to={`${
                          AppRoutes.FILEBUCKET
                        }/${matter_id}/000/?matter_name=${utf8_to_b64(
                          matter_name
                        )}&client_name=${utf8_to_b64(client_name)}`}
                      >
                        File Bucket
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            {/* SEARCHBAR */}
            {files !== null && files?.length !== 0 ? (
              <div className="block w-full mb-1 pb-1 -mt-1 px-1">
                <span className="z-10 leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 py-3 px-3">
                  <IoIcons.IoIosSearch />
                </span>
                <input
                  type="search"
                  placeholder="Type to search files in the File Bucket ..."
                  onChange={handleSearchFileChange}
                  className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pl-10"
                />
              </div>
            ) : (
              // Search Loading
              <>
                <div className="block w-full mb-1 pb-1 -mt-3 px-1">
                  <span className="z-10 leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 py-3 px-3">
                    <IoIcons.IoIosSearch />
                  </span>
                  <input
                    type="search"
                    disabled={true}
                    placeholder="Type to search files in the File Bucket ..."
                    onChange={handleSearchFileChange}
                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-gray-100 rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pl-10"
                  />
                </div>
              </>
            )}

            {/* Table */}

            <div className="px-1">
              <div
                className="flex flex-col py-3 bg-white rounded-lg sm:hidden test123"
                style={{ height: contentHeight }}
              >
                {skeletonLoad ? (
                  <>
                    <div>
                      {[...Array(8).keys()].map((data, index) => {
                        return (
                          <div className="w-full flex justify-between px-1">
                            <div className="">
                              <div>
                                <td className="p-2 align-top">
                                  <div className="ml-2 align-top">
                                    <SkeletonTheme
                                      width={"20px"}
                                      height={"20px"}
                                    >
                                      <p>
                                        <Skeleton count={1} />
                                      </p>
                                    </SkeletonTheme>
                                  </div>
                                </td>
                                <td className="p-2 align-top">
                                  <div>
                                    <SkeletonTheme height="10px" width="110px">
                                      <p className="">
                                        <Skeleton count={1} />
                                      </p>
                                      <p className="">
                                        <Skeleton count={1} />
                                      </p>
                                      <p className="">
                                        <Skeleton count={1} />
                                      </p>
                                    </SkeletonTheme>
                                  </div>
                                </td>
                              </div>
                            </div>
                            <div className=" flex items-center">
                              <td className="p-2">
                                <div className="ml-2 align-top">
                                  <SkeletonTheme width={"20px"} height={"20px"}>
                                    <p>
                                      <Skeleton count={1} />
                                    </p>
                                  </SkeletonTheme>
                                </div>
                              </td>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    {matterFiles !== null && matterFiles?.length === 0 ? (
                      <>
                        <div>
                          <BlankStateMobile
                            header={"There are no items to show in this view."}
                            content={
                              "Any uploaded files in the desktop will appear here"
                            }
                            svg={Illustration}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div
                      id="mobileContent"
                      onScroll={(e) => handleScrollEvent(e)}
                      className="px-5 overflow-y-auto test123 h-min "
                      style={{ scrollBehavior: "smooth" }}
                    >
                      {showScrollButton ? (
                        <>
                          <div
                            className="scrollButtonInner flex"
                            onClick={() => handleScrollToTop()}
                          >
                            <BiArrowToTop
                              style={{
                                color: "white",
                                display: "block",
                                margin: "auto",
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* TANSTACK -KJMF */}
                      <div
                        ref={parentRef}
                        style={{ height: `700px`, overflow: "auto" }}
                      >
                        {/* The large inner element to hold all of the items */}
                        <div
                          style={{
                            height: `${rowVirtualizer?.getTotalSize()}px`,
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          {vTablePaddingTop > 0 && (
                            <div
                              style={{
                                height: `${vTablePaddingTop}px`,
                                zIndex: 100,
                                background: "white",
                              }}
                            ></div>
                          )}
                          {rowVirtualizer
                            .getVirtualItems()
                            .map((virtualItem) => {
                              const data = matterFiles[virtualItem?.index];

                              return (
                                <>
                                  <div
                                    key={virtualItem?.key}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: `${virtualItem?.size}px`,
                                      transform: `translateY(${virtualItem?.start}px)`,
                                    }}
                                    className="border-b border-dashed"
                                  >
                                    <div className="flex flex-row">
                                      <div className="flex flex-col relative">
                                        <div
                                          className="absolute left-0 right-0 mx-auto bottom-2 rounded-full bg-gray-200"
                                          style={{
                                            height: "5.5px",
                                            width: "5.5px",
                                          }}
                                        ></div>
                                        <div className="font-semibold text-cyan-400">
                                          {virtualItem.index + 1}
                                        </div>
                                        <div
                                          className="relative flex-auto mb-2"
                                          style={{
                                            backgroundImage:
                                              "linear-gradient(#e5e7eb, #e5e7eb)",
                                            backgroundSize: "1px 100%",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center center",
                                          }}
                                        ></div>
                                      </div>

                                      {/* start */}
                                      <div className="ml-2 flex flex-col flex-auto">
                                        <div className="w-full relative">
                                          <p className="font-medium text-cyan-400">
                                            {data?.date === null
                                              ? "No Date"
                                              : data?.date === undefined
                                              ? "No Date"
                                              : dateFormatter(data?.date)}
                                          </p>
                                          {/* mobile email preview start */}
                                          <>
                                            {data?.gmailMessageId !== null &&
                                              data?.gmailMessageId !== "0" &&
                                              data?.isGmailPDF && (
                                                <>
                                                  {showEmailPreview &&
                                                  data?.gmailMessageId ===
                                                    activeGmailMessageId ? (
                                                    <></>
                                                  ) : (
                                                    <button
                                                      className="text-cyan-600 underline text-opacity-90 text-[12px] font-normal inline-flex items-center gap-x-2 rounded primary_light hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs"
                                                      onClick={(e) => {
                                                        handleSnippetEmailMobile(
                                                          data
                                                        );
                                                      }}
                                                    >
                                                      Show Preview{" "}
                                                      <FaEye size={11} />
                                                    </button>
                                                  )}
                                                </>
                                              )}
                                          </>
                                          {/* mobile email preview end */}
                                          <div className="flex flex-row">
                                            <div className="flex-auto">
                                              <p
                                                className={
                                                  !isReadMoreExpandedOuter(
                                                    data?.id
                                                  )
                                                    ? "line-clamp-2"
                                                    : ""
                                                }
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    data?.name?.length > 60
                                                      ? `${data?.name.substring(
                                                          0,
                                                          60
                                                        )}...`
                                                      : data?.name,
                                                }}
                                                style={{
                                                  wordBreak: "break-word",
                                                }}
                                              ></p>
                                            </div>
                                            {/* start of preview*/}

                                            {/* end of preview*/}
                                            {data?.s3ObjectKey === null &&
                                            data?.isGmailPDF ? (
                                              <>
                                                <AiOutlineDownload
                                                  className="ml-1 flex-none text-cyan-400 text-base cursor-pointer"
                                                  onClick={() =>
                                                    previewAndDownloadPDF(
                                                      data?.id,
                                                      data?.gmailMessageId,
                                                      data?.name
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <AiOutlineDownload
                                                  className="ml-1 flex-none text-cyan-400 text-base cursor-pointer"
                                                  onClick={() =>
                                                    previewAndDownloadFile(
                                                      data?.id
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </div>
                                          <p
                                            id={data?.id + ".desc"}
                                            className="mt-1 absolute text-red-200 pointer-events-none invisible"
                                            dangerouslySetInnerHTML={{
                                              __html: data?.details,
                                            }}
                                            style={{
                                              wordBreak: "break-word",
                                              top: -10000,
                                              zIndex: -1000,
                                            }}
                                          ></p>
                                          <p
                                            className={
                                              (isReadMoreExpandedOuter(
                                                data?.id
                                              ) && data?.details
                                                ? !isReadMoreExpandedDesc(
                                                    data?.id
                                                  )
                                                  ? " line-clamp-5 "
                                                  : " "
                                                : " hidden ") + " mt-1"
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: data?.details,
                                            }}
                                            style={{ wordBreak: "break-word" }}
                                          ></p>
                                          {data?.details == "" &&
                                          data?.type == null ? null : (
                                            <button
                                              className="text-cyan-400"
                                              onClick={() =>
                                                handleReadMoreModal(data?.id)
                                              }
                                            >
                                              read more...
                                            </button>
                                          )}
                                          {/* <button
                                            className={
                                              (!isReadMoreExpandedOuter(
                                                data.id
                                              ) &&
                                              (data.backgrounds.items ===
                                                null ||
                                                data.backgrounds.items
                                                  .length === 0) &&
                                              data.details !== "" &&
                                              data.details !== null
                                                ? "block"
                                                : "hidden") +
                                              " text-cyan-400 mt-1"
                                            }
                                            onClick={() =>
                                              {
                                              handleReadMoreStateOuter(data.id);
                                              }
                                            }
                                          >
                                            read more{" "}
                                            <FiChevronDown className="inline" />
                                          </button> */}
                                        </div>

                                        {isReadMoreExpandedDesc(data?.id) |
                                          isReadMoreExpandedOuter(data?.id) &&
                                        ((data?.details !== "") &
                                          (data?.details !== undefined) &
                                          (data?.details !== null)) |
                                          ((data?.backgrounds.items !== null) &
                                            (data?.backgrounds?.items?.length >
                                              0)) ? (
                                          <button
                                            className="h-5 block relative mt-1 text-cyan-400 text-xs self-start"
                                            onClick={() =>
                                              handleCollapseAll(data?.id)
                                            }
                                          >
                                            collapse all{" "}
                                            <FiChevronUp className="inline" />
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                          <br />
                        </div>
                      </div>
                      {/* END OF TANSTACK -KJMF */}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* END OF MOBILE VIEW */}
          </>
        )}
        {/* END OF MAIN CONTAINER */}

        {/* MODALS AND TOAST NOTIFICATION */}
        {readmoreModal && (
          <MobileDescriptionModal
            setreadmoreModal={setreadmoreModal}
            setreadmoreModalData={setreadmoreModalData}
            readmoreModalData={readmoreModalData}
          />
        )}

        {showEmail && (
          <MobileEmailPreview
            showEmail={showEmail}
            setShow={setShow}
            snippetId={snippetId}
            gmailMessageId={snippetId}
            preview={preview}
            from={from}
            dateEmail={dateEmail}
            subject={subject}
            to={to}
            cc={cc}
            downloadLink={downloadLink}
            setDownloadLink={setDownloadLink}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            previewAndDownloadFile={previewAndDownloadFile}
            previewAndDownloadPDF={previewAndDownloadPDF}
            handleMobileClose={handleMobileClose}
          />
        )}

        {showRemoveFileModal && (
          <RemoveFileModal
            handleSave={handleDeleteFile}
            handleModalClose={handleModalClose}
          />
        )}

        {showUploadModal && (
          <UploadLinkModal
            title={""}
            handleSave={handleUploadLink}
            bucketName={matter_id}
            handleModalClose={handleModalClose}
          />
        )}

        {filterLabels && (
          <FilterLabels
            handleSave={handleFilter}
            handleModalClose={handleModalClose}
            datePickerRef1={datePickerRef1}
            datePickerRef2={datePickerRef2}
            getMatterFiles={getMatterFiles}
            files={files}
            setFiles={setFiles}
            setFilterState={setFilterState}
            matterFiles={matterFiles}
            setMatterFiles={setMatterFiles}
          />
        )}

        {showToast && resultMessage && (
          <ToastNotification title={resultMessage} hideToast={hideToast} />
        )}

        {showSessionTimeout && <SessionTimeout />}

        {/* END OF MODALS AND TOAST NOTIFICATION */}
      </div>
    </>
  );
}
