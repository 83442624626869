import React ,{useState,useEffect} from "react";
import Spinner from "../../shared/Spinner";
import { HiMinus } from "react-icons/hi";
import ReactTooltip from "react-tooltip";

const Research = (
    {
        setSystemInstruction,
        systemInstruction,
        handleTextareaChange,
        textareas,
        setTextareas,
        handleResearchPrompt,
        isLoading,
        setIsLoading,
        handleDeleteTextarea,
        deleteIsClicked,
        setDeleteIsClicked,
    }
) => {

    const addPrompt = () => {
        setTextareas(prevTextareas => [
        ...prevTextareas,
        {
            id: prevTextareas.length+1,
            value: '',
        },
        ]);
    };

    const textareaStyle = {
        width: '75%',
        height: "80px",
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        resize: 'none',
      };

    return (
        <div className="flex flex-col justify-center items-center mt-8"
            style={{
                width:"55vw",
                height:"85vh",
                borderRadius: '8px',
                padding:"10px",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}
        >
            {isLoading && <Spinner />}
            <div
                className="justify-center items-center flex flex-col -mt-20 py-12"
            >
                <b style={{ 
                        fontSize: '40px',
                        fontStyle:"italic", 
                        color:"rgba(220, 87, 51, 1)" }}
                >
                    Research Phase
                </b>
            </div>
            <textarea
                placeholder="Sytem Instruction"
                className="mb-8"
                value={systemInstruction}
                style={textareaStyle}
                onChange={(e) => setSystemInstruction(e.target.value)}
            />
            {textareas.map(textarea => (
                <div key={textarea.id} className="justify-center items-center flex flex-row" style={{ width : "100%"}}>
                    <textarea
                        placeholder="Research Prompt"
                        value={textarea.value}
                        className="mb-8 mr-5"
                        onChange={(e) => handleTextareaChange(textarea.id, e.target.value)}
                        style={textareaStyle}
                    />
                    <button
                        onClick={() =>
                            handleDeleteTextarea(textarea.id)
                        }
                        className="-mt-9"
                        style={{
                            fontSize: '1.5rem',
                            color: !deleteIsClicked ? '#ffffff' : '#A0AEC0', 
                            backgroundColor: !deleteIsClicked ? '#E53E3E' : 'transparent', 
                            padding: '8px', 
                            borderRadius: '10%', 
                            cursor: 'pointer', 
                            }}
                    >
                        <HiMinus
                            data-tip="Delete Prompt"
                        />
                        <ReactTooltip/>
                    </button>
                </div>
            ))}
            <button
                className="w-min text-green-400 border my-2 border-green-400 px-4 py-2 rounded-md"
                onClick={addPrompt}
            >
                Add Prompt
            </button>
            {/* <textarea
                placeholder="Research Prompt"
                style={textareaStyle}
            />
            <button
                className="w-min text-green-400 border my-2 border-green-400 px-4 py-2 rounded-md"
            >
                Add Prompt
            </button> */}
            {/* <textarea
                placeholder="Header Prompt"
                style={textareaStyle}
            />
            <textarea
                placeholder="System Instruction"
                style={textareaStyle}
            />  */}
            <button
                className="w-min text-cyan-400 border border-cyan-400 px-4 py-2 rounded-md"
                onClick={() => handleResearchPrompt()}
            >
                Execute Prompt
                
            </button>
    </div>
    )
}
export default Research;