import React, { useState, useEffect, useRef } from "react";
import { API, Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Select from "react-select";
import { FaUserCog } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdClose, MdVerified } from "react-icons/md";
import { CgChevronLeft } from "react-icons/cg";
import { LuMail } from "react-icons/lu";

import ChangePassword from "../change-password";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import config from "../../aws-exports";
import Button from "../../shared/Button";
import ToastNotification from "../toast-notification";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import { SidebarData } from "../sidebar/SidebarData";

import "../../assets/styles/AccountSettings.css";
import headerSample from "../../assets/images/accountSettings-header.png";
import uploadIcon from "../../assets/images/upload-icon.png";

var momentTZ = require("moment-timezone");

function AccountSettings() {
  Storage.configure({
    region: config.aws_user_files_s3_bucket_region,
    bucket: config.aws_user_company_assets_s3_bucket,
    identityPoolId: config.aws_user_pools_id,
  });

  const [userInfo, setuserInfo] = useState(null);
  const [getUser, setGetUser] = useState({});
  const [inputUser, setInputUser] = useState({});
  const [Image, setImage] = useState("");
  const [isEditing, setisEditing] = useState(true);
  const inputFile = useRef(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [businessName, setBusinessName] = useState();
  const [email, setEmail] = useState();
  const [deleteProfilePicture, setDeleteProfilePicture] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [dateTimeFormatOptions, setDateTimeFormatOptions] = useState([]);
  const [defaultCompany, setDefaultCompany] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [userTimezone, setUsertimezone] = useState(momentTZ.tz.guess());
  const [userDateFormat, setUserDateFormat] = useState("MM-DD-YYYY");
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(null);
  const originalInfo = useRef();
  const hideToast = () => {
    setShowToast(false);
  };
  const [loading, setLoading] = useState(true);

  let history = useHistory();
  const bool = useRef(false);

  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  const [personalInfoEdit, setPersonalInfoEdit] = useState(false);

  const tempUserInfo = {
    firstName: "",
    lastName: "",
    email: "",
    photo: selectedPhoto,
  };

  const mUpdateProfilePicture = `
    mutation updateProfilePicture($id: ID!, $email: AWSEmail!, $profilePictureKey: String) {
      userUpdate(email: $email, id: $id, profilePictureKey: $profilePictureKey) {
        id
        profilePicture
        profilePictureKey
      }
    }`;

  const mDeleteProfilePicture = `
  mutation deleteProfilePicture($id: ID!, $email: AWSEmail!, $profilePictureKey: String) {
    userUpdate(email: $email, id: $id, profilePictureKey: $profilePictureKey) {
      id
      profilePicture
      profilePictureKey
    }
  }`;

  const qUpdateUser = `mutation updateUser($email: AWSEmail!, $firstName: String, $id: ID!, $lastName: String, $businessName: String, $preferredTimezone: String, $preferredDateFormat: String, $isTwoFactorEnabled: Boolean, $defaultCompany: CompanyInput, $history: [AWSJSON]) {
    userUpdate(
      firstName: $firstName
      id: $id
      lastName: $lastName
      email: $email
      businessName: $businessName
      preferredTimezone: $preferredTimezone
      preferredDateFormat: $preferredDateFormat
      isTwoFactorEnabled: $isTwoFactorEnabled
      defaultCompany: $defaultCompany
      history: $history
    ) {
      id
    }
  }`;

  const mgetUserById = `
    query user($id: String) {
      user(id: $id) {
        company {
          id
          name
        }
        defaultCompany {
          id
          name
        }
        id
        email
        firstName
        lastName
        userType
        profilePictureKey
        profilePicture
        businessName
        preferredTimezone
        preferredDateFormat
        isTwoFactorEnabled
        history
        organisations {
          items {
            company {
              id
              name
              representative {
                id
                firstName
                lastName
              }
            }
            userType
            hasPortalAccess
            isDeleted
          }
        }
      }
    }
  `;
  const getUserById = async (userId) => {
    const response = await API.graphql({
      query: mgetUserById,
      variables: {
        id: userId,
      },
    }).then((response) => {
      console.log("response from getuser ", response);
      setGetUser(response);

      let tempUser = {};
      let userData = response.data.user;

      tempUser.userId = userData.id;
      tempUser.email = userData.email;
      tempUser.firstName = userData.firstName;
      tempUser.lastName = userData.lastName;
      tempUser.profilePicture = userData.profilePicture;
      tempUser.profilePictureKey = userData.profilePictureKey;
      tempUser.businessName = userData.businessName;
      tempUser.company = userData.company;
      tempUser.preferredTimezone = userData.preferredTimezone;
      tempUser.preferredDateFormat = userData.preferredDateFormat;
      tempUser.isTwoFactorEnabled = userData.isTwoFactorEnabled;
      tempUser.defaultCompany = userData.defaultCompany;
      tempUser.organisations = userData.organisations.items;
      tempUser.history = userData.history;

      setuserInfo(tempUser);
      setIsTwoFactorEnabled(tempUser.isTwoFactorEnabled);
      setFirstName(tempUser.firstName);
      setLastName(tempUser.lastName);
      setImage(userData.profilePicture);
      if (userData.profilePicture !== null) {
        localStorage.setItem("profilePicture", userData.profilePicture);
      }
      if (userData.preferredTimezone !== null) {
        setUsertimezone(userData.preferredTimezone);
      }
      if (userData.preferredDateFormat !== null) {
        setUserDateFormat(userData.preferredDateFormat);
      }
      if (userData.defaultCompany !== null) {
        setDefaultCompany(userData.defaultCompany);
      } else {
        // if only has 1 organisation, set as default
        if (tempUser.organisations.length === 1) {
          setDefaultCompany(tempUser.organisations[0].company);
        }
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getUserById(localStorage.getItem("userId"));
    initializeDatePreferences();
  }, []);

  function convertTimezoneToLabel(timezone) {
    return timezone !== undefined || timezone !== null
      ? timezone.replace("/", " - ").replaceAll("_", " ")
      : "None";
  }

  function convertToCurrentTime(format, today) {
    return format + " (" + momentTZ(today).format(format) + ")";
  }

  const initializeDatePreferences = () => {
    //INTIIALIZING TIMEZONE OPTIONS
    let TZNames = momentTZ.tz.names();
    let TZNamesConverted = [];
    let TZOptions = [];

    for (let i = 0; i < TZNames.length; i++) {
      TZNamesConverted = [
        ...TZNamesConverted,
        convertTimezoneToLabel(TZNames[i]),
      ];
      let object = {
        label: TZNamesConverted[i],
        value: TZNames[i],
      };
      TZOptions = [...TZOptions, object];
    }
    setTimezoneOptions(TZOptions);

    //INITIALIZING DATE FORMAT OPTIONS
    let dateFormats = [
      "MM-DD-YYYY",
      "MM/DD/YYYY",
      "MMM DD YYYY",
      "DD-MM-YYYY",
      "DD/MM/YYYY",
      "DD MMM YYYY",
    ];
    let today = new Date();
    let formatOptions = [];
    dateFormats.forEach((format) => {
      let object = {
        label: convertToCurrentTime(format, today),
        value: format,
      };
      formatOptions = [...formatOptions, object];
    });
    setDateTimeFormatOptions(formatOptions);
  };

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  useEffect(() => {
    console.log("LOCAL", localStorage);
    if (userInfo === null) {
      let ls = {
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        company: {
          id: localStorage.getItem("companyId"),
          name: localStorage.getItem("company"),
        },
        profilePicture: localStorage.getItem("profilePicture"),
        preferredTimezone: userTimezone, //localStorage.getItem("preferredTimezone"),
        preferredDateFormat: userDateFormat, //localStorage.getItem("preferredDateFormat")
        defaultCompany: JSON.parse(localStorage.getItem("defaultCompany")),
        history: JSON.parse(localStorage.getItem("history")),
      };
      originalInfo.current = { ...ls };
      setuserInfo(ls);
      //getUserById(localStorage.getItem("userId"));
    } else {
      if (
        userInfo.preferredTimezone === null ||
        userInfo.preferredTimezone === "null"
      ) {
        userInfo.preferredTimezone = userTimezone;
      }

      if (
        userInfo.preferredDateFormat === null ||
        userInfo.preferredDateFormat === "null"
      ) {
        userInfo.preferredDateFormat = userDateFormat;
      }
    }

    console.log("UserInfo:", userInfo);
  }, [userInfo]);

  /* useEffect(() => {

  }, [inputUser]); */

  ///////////////////////////////////////////////////////////////////////////
  // Comment or uncomment the code block below AND the useIdleTimer import statement to toggle session timeout

  // var timeoutId;
  // //session timeout
  // const handleOnAction = (event) => {
  //   //function for detecting if user moved/clicked.
  //   //if modal is active and user moved, automatic logout (session expired)
  //   //bool.current = false;
  //   if (showSessionTimeout) {
  //     setTimeout(() => {
  //       Auth.signOut().then(() => {
  //         clearLocalStorage();
  //         console.log("Sign out completed.");
  //         history.push("/");
  //       });

  //       function clearLocalStorage() {
  //         localStorage.removeItem("userId");
  //         localStorage.removeItem("email");
  //         localStorage.removeItem("firstName");
  //         localStorage.removeItem("lastName");
  //         localStorage.removeItem("userType");
  //         localStorage.removeItem("company");
  //         localStorage.removeItem("companyId");
  //         // localStorage.removeItem("access");
  //         localStorage.removeItem("profilePicture");
  //         //localStorage.removeItem("preferredTimezone");
  //         //localStorage.removeItem("preferredDateFormat");
  //       }
  //     }, 3000);
  //   }

  //   clearTimeout(timeoutId);
  // };

  // const handleOnIdle = (event) => {
  //   //function for detecting if user is on idle.
  //   //after 30 mins, session-timeout modal will show
  //   //bool.current = true;
  //   timeoutId = setTimeout(() => {
  //     setShowSessionTimeout(true);
  //   }, 60000 * 40);
  // };

  // useIdleTimer({
  //   timeout: 60 * 40,
  //   onAction: handleOnAction,
  //   onIdle: handleOnIdle,
  //   debounce: 1000,
  // });

  async function updateProfilePicture(data, key) {
    const user = {
      id: data.userId ? data.userId : userInfo.userId,
      email: data.email ? data.email : userInfo.email,
      profilePictureKey: key.key,
    };
    console.log("upload details", userInfo.userId, userInfo.email, key, user);

    if (key === "null") {
      const updateProfile = await API.graphql({
        query: mDeleteProfilePicture,
        variables: {
          id: data.userId ? data.userId : userInfo.userId,
          email: data.email !== "" ? data.email : userInfo.email,
          profilePicture: key,
        },
      }).then((response) => {
        console.log("response: ", response);
        localStorage.setItem(
          "profilePicture",
          response.data.userUpdate.profilePicture
        );
        setResultMessage("Profile details successfully updated");
        setShowToast(true);
        setTimeout(() => window.location.reload(), 2000);
      });
    } else {
      const updateProfile = await API.graphql({
        query: mUpdateProfilePicture,
        variables: {
          id: userInfo.userId,
          email: data.email !== "" ? data.email : userInfo.email,
          profilePictureKey: key,
        },
      }).then((response) => {
        // localStorage.setItem(
        //   "profilePicture",
        //   response.data.userUpdate.profilePicture
        // );
        setShowToast(true);
        setTimeout(() => window.location.reload(), 2000);
      });
    }
  }
  const handleSaveMobile = async () => {
    console.log("tui", tempUserInfo);
    var fname = document.getElementById("firstName").value;
    var lname = document.getElementById("lastName").value;
    var eadd = document.getElementById("email").value;

    console.log("selectedphoto", selectedPhoto);

    if (selectedPhoto) {
      console.log("Image", selectedPhoto);
      var name = selectedPhoto.name,
        key = `${userInfo.company.id}/profile-pictures/${Number(
          new Date()
        )}${name
          ?.replaceAll(/\s/g, "")
          ?.replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`;
      try {
        const upload = await Storage.put(key, selectedPhoto, {
          contentType: "image/jpeg",
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            if (progress.loaded === progress.total) {
              setResultMessage("Profile details successfully updated");
              updateProfilePicture(userInfo, key);
            }
          },
        }).then((output) => {
          console.log("key: ", output);
        });
      } catch (e) {
        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };
        console.error("228: Unexpected error while uploading", response);
        console.error("Error uploading file:", e);
        setResultMessage("Error uploading profile picture");
        setShowToast(true);
      }

      try {
        const request = await API.graphql({
          query: qUpdateUser,
          variables: {
            firstName: fname !== "" ? fname : userInfo.firstName,
            lastName: lname !== "" ? lname : userInfo.lastName,
            email: eadd !== "" ? eadd : userInfo.email,
            id: userInfo.userId,
            businessName: userInfo.businessName,
            preferredTimezone: userInfo.preferredTimezone,
            preferredDateFormat: userInfo.preferredDateFormat,
            isTwoFactorEnabled: userInfo.isTwoFactorEnabled,
            defaultCompany: userInfo.defaultCompany,
          },
        }).then(() => {
          localStorage.setItem("userId", userInfo.userId);
          localStorage.setItem("email", eadd !== "" ? eadd : userInfo.email);
          localStorage.setItem(
            "firstName",
            fname !== "" ? fname : userInfo.firstName
          );
          localStorage.setItem(
            "lastName",
            lname !== "" ? lname : userInfo.lastName
          );
          localStorage.setItem("preferredTimezone", userInfo.preferredTimezone);
          localStorage.setItem(
            "userpreferredDateFormatType",
            userInfo.preferredDateFormat
          );
        });
      } catch (error) {
        console.error(error);
      }
      console.log("Success");
      getUserById(localStorage.getItem("userId"));
    } else {
      console.log("entered condition");

      try {
        const request = await API.graphql({
          query: qUpdateUser,
          variables: {
            firstName: fname !== "" ? fname : userInfo.firstName,
            lastName: lname !== "" ? lname : userInfo.lastName,
            email: eadd !== "" ? eadd : userInfo.email,
            id: userInfo.userId,
            businessName: userInfo.businessName,
            preferredTimezone: userInfo.preferredTimezone,
            preferredDateFormat: userInfo.preferredDateFormat,
            isTwoFactorEnabled: userInfo.isTwoFactorEnabled,
            defaultCompany: userInfo.defaultCompany,
          },
        }).then(() => {
          localStorage.setItem("userId", userInfo.userId);
          localStorage.setItem("email", eadd !== "" ? eadd : userInfo.email);
          localStorage.setItem(
            "firstName",
            fname !== "" ? fname : userInfo.firstName
          );
          localStorage.setItem(
            "lastName",
            lname !== "" ? lname : userInfo.lastName
          );
          localStorage.setItem("preferredTimezone", userInfo.preferredTimezone);
          localStorage.setItem(
            "userpreferredDateFormatType",
            userInfo.preferredDateFormat
          );
          console.log(userInfo.defaultCompany);
          localStorage.setItem(
            "defaultCompany",
            JSON.stringify({
              id: userInfo?.defaultCompany?.id,
              name: userInfo?.defaultCompany?.name,
            })
          );

          if (deleteProfilePicture) {
            updateProfilePicture(tempUserInfo, "null");
          }
        });
      } catch (error) {
        console.error(error);
      }

      console.log("Success");
      getUserById(localStorage.getItem("userId"));
      setResultMessage("Profile details successfully updated");
      setShowToast(true);

      setTimeout(() => window.location.reload(), 2000);
    }
  };

  const createHistory = (prevSettings, newSettings) => {
    let history = {};
    if (prevSettings.firstName !== newSettings.firstName) {
      history.firstName = prevSettings.firstName;
    }
    if (prevSettings.lastName !== newSettings.lastName) {
      history.lastName = prevSettings.lastName;
    }

    if (prevSettings.history) {
      let arr = prevSettings.history.map((u) => JSON.stringify(u));

      return Object.keys(history).length > 0
        ? [...arr, JSON.stringify(history)]
        : arr;
    } else {
      return Object.keys(history).length > 0 ? [JSON.stringify(history)] : null;
    }
  };
  const handleSave = async () => {
    // setUploadStart(true);
    console.log("SELECTED PHOTO: ", selectedPhoto);
    console.log("SAVING USER", userInfo);

    let history = createHistory(originalInfo.current, userInfo);

    if (selectedPhoto) {
      console.log("Image", selectedPhoto);
      var name = selectedPhoto.name,
        key = `${userInfo.company.id}/profile-pictures/${Number(
          new Date()
        )}${name
          ?.replaceAll(/\s/g, "")
          ?.replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`;
      try {
        const upload = await Storage.put(key, selectedPhoto, {
          contentType: "image/jpeg",
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            if (progress.loaded === progress.total) {
              setResultMessage("Profile details successfully updated");
              updateProfilePicture(userInfo, key);
            }
          },
        }).then((output) => {
          console.log("key: ", output);
        });
      } catch (e) {
        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };
        console.error("228: Unexpected error while uploading", response);
        console.error("Error uploading file:", e);
        setResultMessage("Error uploading profile picture");
        setShowToast(true);
      }
      try {
        const request = await API.graphql({
          query: qUpdateUser,
          variables: {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            id: userInfo.userId,
            businessName: userInfo.businessName,
            preferredTimezone: userInfo.preferredTimezone,
            preferredDateFormat: userInfo.preferredDateFormat,
            isTwoFactorEnabled: userInfo.isTwoFactorEnabled,
            defaultCompany: userInfo.defaultCompany,
            ...(history ? { history: history } : {}),
          },
        }).then(() => {
          localStorage.setItem("userId", userInfo.userId);
          localStorage.setItem("email", userInfo.email);
          localStorage.setItem("firstName", userInfo.firstName);
          localStorage.setItem("lastName", userInfo.lastName);
          localStorage.setItem("preferredTimezone", userInfo.preferredTimezone);
          localStorage.setItem(
            "userpreferredDateFormatType",
            userInfo.preferredDateFormat
          );
          localStorage.setItem(
            "history",
            JSON.stringify(history.map((u) => JSON.parse(u)))
          );
        });
      } catch (error) {
        console.error(error);
      }
      console.log("Success");
      getUserById(localStorage.getItem("userId"));
    } else {
      if (deleteProfilePicture) {
        updateProfilePicture(userInfo, "null");
      }

      try {
        const request = await API.graphql({
          query: qUpdateUser,
          variables: {
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            email: userInfo?.email,
            id: userInfo?.userId,
            businessName: userInfo?.businessName,
            preferredTimezone: userInfo?.preferredTimezone,
            preferredDateFormat: userInfo?.preferredDateFormat,
            isTwoFactorEnabled: userInfo?.isTwoFactorEnabled,
            defaultCompany: userInfo?.defaultCompany,
            ...(history ? { history: history } : {}),
          },
        }).then(() => {
          localStorage.setItem("userId", userInfo.userId);
          localStorage.setItem("email", userInfo.email);
          localStorage.setItem("firstName", userInfo.firstName);
          localStorage.setItem("lastName", userInfo.lastName);
          localStorage.setItem("preferredTimezone", userInfo.preferredTimezone);
          localStorage.setItem(
            "userpreferredDateFormatType",
            userInfo.preferredDateFormat
          );
          localStorage.setItem(
            "history",
            JSON.stringify(history.map((u) => JSON.parse(u)))
          );
          console.log(userInfo.defaultCompany);
          // localStorage.setItem(
          //   "defaultCompany",
          //   JSON.stringify({
          //     id: userInfo?.defaultCompany?.id,
          //     name: userInfo?.defaultCompany?.name,
          //   })
          // );
        });
      } catch (error) {
        console.error(error);
      }

      console.log("Success");
      getUserById(localStorage.getItem("userId"));
      setResultMessage("Profile details successfully updated");
      setShowToast(true);

      setTimeout(() => window.location.reload(), 2000);
    }
  };

  var assigneeInitials = "";
  if (
    userInfo &&
    `${userInfo?.firstName?.toLowerCase()} ${userInfo?.lastName?.toLowerCase()}` ===
      "john dela cruz"
  ) {
    assigneeInitials = "JDC";
  } else {
    assigneeInitials += userInfo?.firstName
      ? userInfo.firstName?.charAt(0)
      : "";
    assigneeInitials += userInfo?.lastName ? userInfo.lastName?.charAt(0) : "";
  }

  const { width } = useWindowDimensions();

  /* const handleUpdate = async () => {
    const request = await API.graphql({
      query: qUpdateUser,
      variables:

    })
  } */

  const hiddenPhotoInput = useRef(null);
  const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; //list of rejected files
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [selectedFiles, _setSelectedFiles] = useState([]);
  const [uploadedImg, setUploadedImg] = useState(null);
  const myCurrentRef = useRef(selectedFiles);
  const setSelectedFiles = (data) => {
    myCurrentRef.current = data;
    _setSelectedFiles(data);
  };

  const handleNewUserPhoto = (files) => {
    if (!files || !files.length) return;
    console.log("FILES!!!!", files);

    var file = files[0];
    console.log("RETRIEVED FILE", file);
    if (!file.type.includes("image")) {
      window.alert("Please input an image file");
      return;
    }

    let objUrl = URL.createObjectURL(file);
    console.log("THIS IS THE URL!", file);
    setSelectedPhoto(objUrl);

    const tempArr = [];

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[0];

    const result = rejectFiles.find((item) =>
      item.includes(re.exec(file.name)[0])
    );
    const fileSize = file.size;

    if (result || fileSize > 2147483648) {
      invalidFiles.push({
        data: file,
        url: objUrl,
      });
    } else {
      tempArr.push({
        data: file,
        url: objUrl,
      });
    }

    setSelectedFiles([...myCurrentRef.current, ...tempArr]);
    setUploadedImg(tempArr[0]);
    //setShowEdit(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    handleNewUserPhoto(files);
    setPersonalInfoEdit(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPersonalInfoEdit(true);
  };

  const [selectedPhotoCopy, setSelectedPhotoCopy] = useState("");

  const handleUpload = (e) => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      const areAllFilesImages = Array.from(selectedFiles).every((file) =>
        /\.(jpg|jpeg|png|gif)$/i.test(file.name)
      );

      if (areAllFilesImages) {
        setSelectedPhotoCopy(URL.createObjectURL(selectedFiles[0]));
        console.log(selectedFiles);
      } else {
        alert("Please select only image files (JPEG, PNG, GIF, etc.).");
        e.target.value = null;
      }
    } else {
      alert("Please select one file.");
    }
  };

  const handlePhotoUpload = (event) => {
    hiddenPhotoInput.current.click();
    console.log("evt", event);
  };

  const handlePersonalInfoSave = () => {
    for (const key in tempUserInfo) {
      if (tempUserInfo.hasOwnProperty(key)) {
        const value = tempUserInfo[key];
        if (value !== null && value !== "") {
          if (key === "firstName") {
            if (value !== userInfo.firstName) {
              userInfo[firstName] = value;
            }
          } else if (key === "lastName") {
            if (value !== userInfo.lastName) {
              userInfo[lastName] = value;
            }
          } else if (key === "photo") {
            setImage(value);
          } else {
            if (value !== userInfo.email) {
              userInfo.email = value;
              setEmail(value);
            }
          }
        }
      }
    }

    setPersonalInfoEdit(false);
  };

  return (
    <>
      <div className="sm:bg-white z-30 pl-4 sm:pt-5 contentDiv">
        {/* <h1>Account Settings</h1>
        <div className="page-crumbs">
          <FaUserCog />
          <p>Account Settings</p>
        </div> */}
        {width > 844 ? (
          <div className="pl-15 sm:pl-0 top-0 py-2 hidden sm:flex items-center gap-2 bg-white z-10">
            <div
              onClick={() => {
                history.replace(dashboardPath);
              }}
              className="w-8 py-5 cursor-pointer"
            >
              <CgChevronLeft />
            </div>
            <div>
              <p className="flex flex-col">
                <span className="text-lg font-bold">Profile Settings</span>
              </p>
              <div className="flex items-center gap-3 text-gray-500 mt-0">
                <div className="flex gap-3">
                  <FaUserCog />
                  <p className="font-semibold hidden sm:block">
                    Profile Settings
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {loading ? (
        <div className={" p-5 sm:pl-20 lg:ml-5"}>
          <div className="category-content m-0 w-0">
            {/* <AccountSettingsCategory
            title={"Public Profile"}
            desc={"Update your Profile Settings"}
            svg={<BiUser />}
          /> */}
          </div>
          <div className="settings-input">
            {/* <AccountSettingsHeader
            title={"Personal info"}
            desc={"Info about you and your preferences across MMA."}
            graphics={Illustration2}
          /> */}
            <div className="grid grid-cols-1 gap-2 justify-items-center">
              <div className="w-20 h-20 rounded-full">
                <Skeleton
                  height={"5rem"}
                  width={"5rem"}
                  className="rounded-full"
                >
                  <SkeletonTheme />
                </Skeleton>
              </div>
              <h1 className="text-base leading-6 font-normal">
                <Skeleton height={"16px"} width="150px">
                  <SkeletonTheme />
                </Skeleton>
              </h1>
            </div>
            <div className="input-grid">
              <div className="md:flex-auto">
                <p className="input-name">First Name</p>
                <div className="my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                </div>
              </div>
              <div className="flex-auto">
                <p className="input-name">Last Name</p>
                <div className="my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                </div>
              </div>
            </div>
            <div className="input-grid">
              <div className="flex-auto">
                <p className="input-name">Business Name</p>
                <div className="my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                </div>
              </div>
              <div className="flex-auto">
                <p className="input-name">Email Address</p>
                <div className="my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                </div>
              </div>
            </div>

            {/* <div className="relative flex-auto">
                <input
                  type="text"
                  className="rounded-md p-2 border border-gray-300 outline-0 w-full"
                  defaultValue={userInfo?.lastName}
                  onChange={(e) => {
                    //setLastName(e.target.value);
                    userInfo.lastName = e.target.value;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex-auto">
            <p className="input-name">Business Name</p>
            <div className="my-2">
              <input
                type="text"
                className="rounded-md p-2 border border-gray-300 outline-0 w-full"
                defaultValue={userInfo?.businessName}
                onInput={(e) => {
                  setBusinessName(e.target.value);
                  userInfo.businessName = e.target.value;
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 gap-y-8">
            <div className="flex-auto">
              <p className="input-name">Email Address</p>
              <div className="my-2">
                <input
                  type="text"
                  className="rounded-md p-2 border border-gray-300 outline-0 w-full"
                  defaultValue={userInfo?.email}
                  onInput={(e) => {
                    setEmail(e.target.value);
                    userInfo.email = e.target.value;
                  }}
                />
              </div>
            </div>

            <div className="relative flex-auto">
              <p className="input-name">Password
              </p>
              <div className="relative my-2">
                  <input type="password" className="input-field" defaultValue="Test"/>
              </div>
            </div> */}

            <div className="input-grid">
              <div className="flex-auto">
                <p className="input-name">Date Format</p>
                <div className="my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                </div>
              </div>
              <div className="flex-auto">
                <p className="input-name">Timezone</p>
                <div className="my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                </div>
              </div>
            </div>
            <div className="input-grid">
              <div className="flex-auto">
                <p className="input-name">Default Company</p>
                <div className="my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                </div>
              </div>
            </div>
            <div className="ml-auto">
              <Button
                variant="secondary"
                size="large"
                onClick={() => handleSave()}
              >
                <span className="font-medium text-base">Save</span>
              </Button>
            </div>
            <ChangePassword loading={false} />
          </div>
        </div>
      ) : width > 844 ? (
        <>
          <div className={" p-5 sm:pl-20 lg:ml-5"}>
            <div className="category-content m-0 w-0">
              {/* <AccountSettingsCategory
            title={"Public Profile"}
            desc={"Update your Profile Settings"}
            svg={<BiUser />}
          /> */}
            </div>
            <div className="settings-input">
              {/* <AccountSettingsHeader
            title={"Personal info"}
            desc={"Info about you and your preferences across MMA."}
            graphics={Illustration2}
          /> */}
              <div className="grid grid-cols-1 gap-2 justify-items-center">
                {/* Profile Pic */}
                <div className="w-20 h-20">
                  <input
                    type="file"
                    id="file"
                    accept="image/png, image/jpeg"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        console.log("trigerred");
                        setImage(URL.createObjectURL(event.target.files[0]));
                        setSelectedPhoto(event.target.files[0]);
                      }
                    }}
                  />
                  <div className="relative flex bg-gray-500 text-white h-full w-full rounded-full text-2xl justify-center items-center cursor-pointer">
                    <div className="absolute top-0 left-0 rounded-full w-20 h-20 z-20 transition-opacity opacity-100 md:opacity-0 md:hover:opacity-70">
                      {Image !== null ? (
                        <div
                          className="absolute top-0 -right-3 rounded-full w-7 h-7 z-30 bg-danger transition-opacity"
                          onClick={() => {
                            setSelectedPhoto(null);
                            setImage(null);
                            setDeleteProfilePicture(true);
                          }}
                        >
                          <div className="flex items-center justify-center w-full h-full">
                            <MdClose />
                          </div>
                        </div>
                      ) : null}

                      <div
                        className="rounded-full w-20 h-20 absolute z-20 bg-black transition-opacity opacity-0 hover:opacity-40 md:hover:opacity-100 md:opacity-100"
                        onClick={() => {
                          inputFile.current.click();
                        }}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <FiEdit className="" />
                        </div>
                      </div>
                    </div>
                    {Image === null || Image === "" ? (
                      <>{assigneeInitials.toUpperCase()}</>
                    ) : (
                      <img className="h-full w-full rounded-full" src={Image} />
                    )}
                  </div>
                </div>
                {/* Account Name */}
                {userInfo !== null && (
                  <h1 className="text-base leading-6 font-normal">
                    {firstName} {lastName}
                  </h1>
                )}
              </div>
              <div className="input-grid">
                <div className="md:flex-auto">
                  <p className="input-name">First Name</p>
                  <div className="my-2">
                    <input
                      type="text"
                      className="rounded-md p-2 border border-gray-300 outline-0 w-full"
                      defaultValue={userInfo?.firstName}
                      onChange={(e) => {
                        //setFirstName(e.target.value);
                        userInfo.firstName = e.target.value;
                        console.log(userInfo);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-auto">
                  <p className="input-name">Last Name</p>
                  <div className="my-2">
                    <input
                      type="text"
                      className="rounded-md p-2 border border-gray-300 outline-0 w-full"
                      defaultValue={userInfo?.lastName}
                      onChange={(e) => {
                        //setLastName(e.target.value);
                        userInfo.lastName = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="block sm:grid grid-cols-2 gap-4">
                <div className="flex-auto">
                  <p className="input-name">Business Name</p>
                  <div className="my-2">
                    <input
                      type="text"
                      className="rounded-md p-2 border border-gray-300 outline-0 w-full"
                      defaultValue={userInfo?.businessName}
                      onInput={(e) => {
                        setBusinessName(e.target.value);
                        userInfo.businessName = e.target.value;
                      }}
                    />
                  </div>
                </div>
                <div className="flex-auto">
                  <p className="input-name">Email Address</p>
                  <div className="my-2">
                    <input
                      type="text"
                      className="rounded-md p-2 border border-gray-300 outline-0 w-full"
                      defaultValue={userInfo?.email}
                      disabled
                      onInput={(e) => {
                        setEmail(e.target.value);
                        userInfo.email = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="block sm:grid grid-cols-2 gap-4">
                <div className="flex-auto">
                  <p className="input-name">Date Format</p>
                  <div className="my-2">
                    <Select
                      name={`userType`}
                      options={dateTimeFormatOptions}
                      value={{
                        label: convertToCurrentTime(userDateFormat),
                        value: userDateFormat,
                      }}
                      type="text"
                      onChange={(e) => {
                        userInfo.preferredDateFormat = e.value;
                        setUserDateFormat(e.value);
                        console.log(userInfo);
                      }}
                      className={
                        "rounded-md focus:border-gray-800 border-gray-300 w-full"
                      }
                    />
                  </div>
                </div>
                <div className="flex-auto">
                  <p className="input-name">Timezone</p>
                  <div className="my-2">
                    <Select
                      name={`userType`}
                      options={timezoneOptions}
                      value={{
                        label: convertTimezoneToLabel(userTimezone),
                        value: userTimezone,
                      }}
                      type="text"
                      onChange={(e) => {
                        userInfo.preferredTimezone = e.value;
                        setUsertimezone(e.value);
                        console.log(userInfo);
                      }}
                      className={
                        "rounded-md focus:border-gray-800 border-gray-300 w-full"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="block sm:grid grid-cols-2 gap-4">
                <div className="flex-auto">
                  <p className="input-name">Default Company</p>
                  <div className="my-2">
                    <Select
                      name={`defaultCompany`}
                      isOptionSelected={(option, selectValue) =>
                        selectValue.some(
                          (val) => val.value.id === option.value.id
                        )
                      }
                      options={userInfo?.organisations
                        ?.filter(
                          (item) =>
                            item.hasPortalAccess !== false &&
                            item.isDeleted !== true
                        )
                        .map((item) => {
                          return {
                            label: item?.company?.name || "",
                            value: {
                              id: item?.company?.id || "",
                              name: item?.company?.name || "",
                            },
                          };
                        })}
                      value={{
                        label: defaultCompany.name,
                        value: defaultCompany,
                      }}
                      onChange={(e) => {
                        userInfo.defaultCompany = e.value;
                        setDefaultCompany(e.value);
                        console.log(userInfo);
                      }}
                      className={
                        "rounded-md focus:border-gray-800 border-gray-300 w-full"
                      }
                    />
                  </div>
                </div>
              </div>

              <p className="ml-5 flex flex-col">
                <span className="text-lg font-bold">Security Settings</span>
              </p>
              <div className="block sm:grid grid-cols-2 gap-4">
                <div className="flex">
                  <p className="input-name my-auto">2-Factor Authentication</p>{" "}
                  {/*THIS IS A COMMENT 2 FACTOR AUTH SWITCH COMPONENT OF THE PAGE */}
                  <div className="relative w-14 ml-4 my-auto mr-auto align-middle select-none transition duration-200 ease-in">
                    <input
                      name={`isTwoFactorEnabled`}
                      type="checkbox"
                      className="toggle-checkbox absolute block w-7 h-7 rounded-full bg-white border-4 appearance-none cursor-pointer disabled:opacity-75 transition-colors duration-700"
                      checked={
                        userInfo.isTwoFactorEnabled !== null
                          ? userInfo.isTwoFactorEnabled
                          : false
                      } /*for catching null values and setting it to false since this is a newly created field some fields might not have it*/
                      onChange={() => {
                        /* when switched we change the value of the isTwoFactor to its opposite value */
                        userInfo.isTwoFactorEnabled = !isTwoFactorEnabled;
                        setIsTwoFactorEnabled(userInfo.isTwoFactorEnabled);
                        console.log(userInfo);
                      }}
                    />
                    <label className="toggle-label block overflow-hidden h-7 rounded-full bg-gray-300 cursor-pointer transition-colors duration-700"></label>
                  </div>
                </div>
              </div>

              <div className="ml-auto">
                <Button
                  variant="secondary"
                  size="large"
                  onClick={() => handleSave()}
                >
                  <span className="font-medium text-base">Save</span>
                </Button>
              </div>
              <ChangePassword loading={false} />
            </div>
          </div>
        </>
      ) : (
        //profile settings mobile -continue kjmf
        <>
          <div className={"bg-gray-50 "}>
            {/* HEADER */}
            <img
              src={headerSample}
              alt="header"
              className="w-full z-0 relative"
            />
            <div className="relative w-24 h-24 -mt-10">
              <div className="-mt-15 ml-3 relative flex bg-gray-500 text-white h-full w-full rounded-full text-2xl justify-center items-center cursor-pointer">
                <div className="absolute top-0 left-0 rounded-full w-20 h-20 z-20 transition-opacity opacity-100 md:opacity-0 md:hover:opacity-70">
                  {Image !== null ? (
                    <MdVerified className="absolute z-30 text-blue-500 w-6 h-6 ml-16 mt-20" />
                  ) : null}
                  {/* <div
                        className="rounded-full w-20 h-20 absolute z-20 bg-black transition-opacity opacity-0 hover:opacity-40 md:hover:opacity-100 md:opacity-100"
                        onClick={() => {
                          inputFile.current.click();
                        }}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <FiEdit className="" />
                        </div>
                      </div> */}
                </div>
                {Image === null || Image === "" ? (
                  <>
                    <div>{assigneeInitials.toUpperCase()}</div>
                  </>
                ) : (
                  <img className="h-full w-full rounded-full" src={Image} />
                )}
              </div>
              {/* <div className="w-30 h-30 -mt-5 relative z-10 border-4 border-white rounded-full ml-3 shadow-lg" style={{transform: "translateY(-2.5rem)"}}>
                <div className="flex bg-gray-500 text-white rounded-full text-2xl justify-center items-center shadow-inner">
                  {Image === null || Image === "" || Image === undefined ? (
                    <div size={20} className=" rounded-full">{assigneeInitials.toUpperCase()}</div>
                  ) : (
                    <img size={20} className="rounded-full" alt="user" src={Image} />
                  )}
                </div>
                <MdVerified className="absolute z-30 text-blue-500 w-6 h-6 ml-16 -mt-5"/>
              </div> */}
              {/* <div className="absolute z-20 flex justify-center items-center" style={{transform: "translate(20vw, -8vh)"}}>
                <div className="relative z-20 bg-white rounded-full w-3 h-3"></div>

              </div> */}
            </div>
            {userInfo !== null && (
              <div className="ml-3">
                <h1 className="relative text-xl font-open-sans font-medium mt-3">
                  {firstName} {lastName}
                </h1>
                <p className="font-open-sans text-gray-600">
                  @{firstName} {lastName}
                </p>
              </div>
            )}
            {/* PERSONAL INFO */}
            <div className="pl-3 mt-4">
              <p className="font-medium font-open-sans text-sm text-gray-700">
                Personal info
              </p>
              <p className="font-open-sans text-sm font-light text-gray-600">
                Update your photo and personal details.
              </p>
              <div className="mt-8 border border-gray-200 rounded-xl bg-white -ml-3">
                <div className="p-6">
                  {/* USER NAME */}
                  <div className="input-grid">
                    <div className="md:flex-auto">
                      <p className="font-open-sans text-sm text-gray-700 font-normal">
                        First Name
                      </p>
                      <div className="my-2">
                        <input
                          id="firstName"
                          type="text"
                          className="rounded-md py-2 px-3 border border-gray-300 outline-0 w-full font-open-sans font-light text-md"
                          defaultValue={userInfo?.firstName}
                          onChange={(e) => {
                            // setFirstName(e.target.value);
                            // userInfo.firstName = e.target.value;
                            setPersonalInfoEdit(true);
                            tempUserInfo.firstName = e.target.value;
                            console.log(tempUserInfo);
                            console.log(userInfo);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex-auto">
                      <p className="font-open-sans text-sm text-gray-700 font-normal">
                        Last Name
                      </p>
                      <div className="my-2">
                        <input
                          id="lastName"
                          type="text"
                          className="rounded-md py-2 px-3 border border-gray-300 outline-0 w-full font-open-sans font-light text-md"
                          defaultValue={userInfo?.lastName}
                          onChange={(e) => {
                            //setLastName(e.target.value);
                            //userInfo.lastName = e.target.value;
                            setPersonalInfoEdit(true);
                            tempUserInfo.lastName = e.target.value;
                            console.log(tempUserInfo);
                            console.log(userInfo);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* EMAIL */}
                  <div className="flex-auto mt-4">
                    <p className="font-open-sans text-sm text-gray-700 font-normal">
                      Email Address
                    </p>
                    <div className="my-2 rounded-md py-2 px-3 border border-gray-300 outline-0 w-full flex flex-row items-end bg-gray-100">
                      <LuMail className="text-gray-500 mr-2" />
                      <input
                        id="email"
                        type="email"
                        className="font-open-sans font-light text-md w-full"
                        defaultValue={userInfo?.email}
                        disabled
                        // onInput={(e) => {
                        //   //setEmail(e.target.value);
                        //   //userInfo.email = e.target.value;
                        //   setPersonalInfoEdit(true);
                        //   tempUserInfo.email = e.target.value;
                        //   console.log(tempUserInfo);
                        //   console.log(userInfo);
                        // }}
                      />
                    </div>
                  </div>
                  {/* PHOTO UPLOAD */}
                  <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    className="flex justify-center flex-col items-center h-32 mx-auto border w-full px-3 rounded-xl border-gray-300 content-center mt-6"
                  >
                    {!selectedPhoto ? (
                      <>
                        <img src={uploadIcon} className="mb-2" alt="icon" />
                        <div className="flex flex-col justify-center text-center items-center">
                          <p className="text-sm text-center font-open-sans">
                            <span
                              className="text-hbBlue font-semibold cursor-pointer hover:underline font-open-sans"
                              onClick={() => handlePhotoUpload()}
                              // onChange={(event) => {
                              //   if (event.target.files && event.target.files[0]) {
                              //     console.log("trigerred")
                              //     setImage(URL.createObjectURL(event.target.files[0]));
                              //     setSelectedPhoto(event.target.files[0]);
                              //   }
                              // }}
                            >
                              Click to upload
                            </span>
                            {/* {" "} or drag and drop */}
                          </p>
                          <p className="text-gray-400 font-normal font-open-sans mx-auto">
                            SVG, PNG, JPG or GIF &#40;max. 800x400px&#41;
                          </p>
                        </div>
                        <input
                          type="file"
                          ref={hiddenPhotoInput}
                          onChange={(e) => {
                            handleUpload(e);
                            setPersonalInfoEdit(true);
                            setSelectedPhoto(e.target.files[0]);
                            console.log("tempUserInfo", tempUserInfo);
                          }}
                          value=""
                          className="hidden"
                          multiple={false}
                        />
                      </>
                    ) : (
                      <img
                        src={selectedPhotoCopy}
                        alt="user upload"
                        className="py-2 w-full h-full"
                      ></img>
                    )}
                  </div>

                  <div className="block sm:grid grid-cols-2 gap-4 mt-4">
                    <div className="flex">
                      <p className="input-name my-auto">
                        2-Factor Authentication
                      </p>{" "}
                      {/*THIS IS A COMMENT 2 FACTOR AUTH SWITCH COMPONENT OF THE PAGE */}
                      <div className="relative w-14 ml-4 my-auto mr-auto align-middle select-none transition duration-200 ease-in">
                        <input
                          id="isTwoFactorEnabled"
                          name={`isTwoFactorEnabled`}
                          type="checkbox"
                          className="toggle-checkbox absolute block w-7 h-7 rounded-full bg-white border-4 appearance-none cursor-pointer disabled:opacity-75 transition-colors duration-700"
                          checked={
                            userInfo.isTwoFactorEnabled !== null
                              ? userInfo.isTwoFactorEnabled
                              : false
                          } /*for catching null values and setting it to false since this is a newly created field some fields might not have it*/
                          onChange={() => {
                            /* when switched we change the value of the isTwoFactor to its opposite value */
                            userInfo.isTwoFactorEnabled = !isTwoFactorEnabled;
                            setIsTwoFactorEnabled(userInfo.isTwoFactorEnabled);
                            setPersonalInfoEdit(true);
                            console.log(userInfo);
                          }}
                        />
                        <label className="toggle-label block overflow-hidden h-7 rounded-full bg-gray-300 cursor-pointer transition-colors duration-700"></label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* BUTTONS */}
                {personalInfoEdit && (
                  <div className="border-t border-gray-200 flex flex-row gap-x-2 w-full py-4 px-6">
                    <Button
                      className="font-open-sans text-sm font-normal shadow-none py-2"
                      block={"full"}
                      onClick={() => {
                        setSelectedPhoto(null);
                        document.getElementById("firstName").value =
                          userInfo.firstName;
                        document.getElementById("lastName").value =
                          userInfo.lastName;
                        document.getElementById("email").value = userInfo.email;
                        document.getElementById("isTwoFactorEnabled").value =
                          userInfo.isTwoFactorEnabled;

                        setPersonalInfoEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="hbBlue"
                      block={"full"}
                      className="font-open-sans text-sm font-normal shadow-none py-2"
                      onClick={() => {
                        handlePersonalInfoSave();
                        handleSaveMobile();
                      }}
                    >
                      Save changes
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {/* PASSWORD */}
            <div className="pl-3 mt-4">
              <p className="font-medium font-open-sans text-sm text-gray-700">
                Password
              </p>
              <p className="font-open-sans text-sm font-light text-gray-600">
                Please enter your current password to change your password.
              </p>
              <div className="mt-8 border border-gray-200 rounded-xl bg-white -ml-3">
                <ChangePassword loading={false} />
              </div>
            </div>
          </div>
        </>
      )}
      {showSessionTimeout && <SessionTimeout />}
      {showToast && resultMessage ? (
        <ToastNotification
          title={resultMessage}
          error={
            resultMessage === "Profile details successfully updated" ||
            resultMessage === "Profile details failed updating"
              ? false
              : true
          }
          hideToast={hideToast}
        />
      ) : null}
    </>
  );
}

export default AccountSettings;
