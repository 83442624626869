import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import momentTZ from "moment-timezone";
import { dateFormatter } from "../../shared/userPreferredDateTime";
import { AiOutlineDownload } from "react-icons/ai";

/**
 * Component for rendering variations content
 * @param {Object} props - Props object
 * @param {Object} props.background - Background object
 * @param {Object} props.style - Style object
 * @param {boolean} props.readMoreState - Read more state
 * @param {function} props.isReadMoreExpanded - Function to check if read more is expanded
 * @param {function} props.setSaving - Function to set saving state
 * @param {function} props.getRowLabels - Function to get row labels
 * @param {function} props.handleDescContent - Function to handle description content
 * @param {function} props.handleReadMoreState - Function to handle read more state
 * @param {function} props.handleChangeDesc - Function to handle change in description
 * @param {function} props.handleSaveDesc - Function to handle saving description
 * @param {function} props.handleSnippetEmail - Function to handle snippet email
 * @param {Array} props.virtualRows - Array of virtual rows
 * @param {Array} props.rows - Array of rows
 */
const VariationsContentComponent = ({
  background,
  style,
  readMoreState,
  isReadMoreExpanded,
  setSaving,
  getRowLabels,
  handleDescContent,
  handleReadMoreState,
  handleChangeDesc,
  handleSaveDesc,
  handleSnippetEmail,
  virtualRows,
  rows,
  columns,
}) => {
  return (
    <div>
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const item = row.original;
        const index = row.index;

        const dynamicData =
          typeof item.dynamicData === "string"
            ? JSON.parse(item.dynamicData)
            : item.dynamicData;

        {
          /* Function to format dollar amount */
        }
        function formatNumber(number) {
          if (number === null || number === undefined) {
            return "0.00";
          }

          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(number);
        }

        return (
          <div
            key={item.id}
            className="flex flex-row w-full px-5"
            style={{
              ...style,
              width: "100%",
              borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
              borderBottomStyle: "solid",
              paddingTop: index === 0 ? 0 : 20,
              paddingBottom: 20,
              maxHeight: "350px",
            }}
          >
            <p className="text-xs">{index + 1}</p>
            <div className="ml-2 overflow-y-auto">
              {/* Date */}
              <p className="text-xs pb-1">
                {item.date !== null && item.date !== ""
                  ? dateFormatter(item.date)
                  : "No date"}
              </p>

              {/* Description */}
              <p
                id={item.id + ".descM"}
                className={`overflow-y-auto text-xs pb-1 ${
                  isReadMoreExpanded(item.id) ? "" : "line-clamp-6"
                }`}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
                style={{
                  wordBreak: "break-word",
                  outlineColor: "rgb(204, 204, 204, 0.5)",
                  outlineWidth: "thin",
                }}
                contentEditable={true}
                suppressContentEditableWarning
                onClick={(event) => {
                  handleDescContent(event, item.description, item.id, index);
                  setSaving(true);
                }}
                onInput={(event) => handleChangeDesc(event, index)}
                onBlur={(e) => {
                  handleSaveDesc(
                    e,
                    item.description,
                    item.date,
                    item.id,
                    index
                  );
                  setSaving(false);
                }}
              ></p>

              {/* DynamicColumns code */}
              {/* Variation Cost */}
              {columns.map((column) => {
                if (
                  column.header === "Variation Cost" ||
                  column.header === "Variation Cost ($)"
                ) {
                  const variationCost =
                    dynamicData && dynamicData[column.accessorKey];
                  const costText = formatNumber(variationCost);
                  return (
                    <span key={column.accessorKey} className="text-xs">
                      ${costText} -
                    </span>
                  );
                }
                return null;
              })}

              {/* Variation Status */}
              {columns.map((column) => {
                if (column.header === "Variation Status") {
                  const variationStatus =
                    dynamicData && dynamicData[column.accessorKey];
                  const statusText =
                    variationStatus !== undefined && variationStatus !== null
                      ? variationStatus
                      : "Pending";
                  return (
                    <span key={column.accessorKey} className="text-xs">
                      {statusText}
                    </span>
                  );
                }
                return null;
              })}
              {/* DynamicColumns code */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VariationsContentComponent;
