import React, { useEffect, useState } from "react";
import ToastNotification from "../toast-notification";
import { Auth, API } from "aws-amplify";
import { useForm } from "react-hook-form";
import InfoMessage from "../info-message";
import { AppRoutes } from "../../constants/AppRoutes";
import { MdCheck, MdOutlineClose, MdSave } from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../../assets/styles/AccountSettings.css";
import { useHistory } from "react-router-dom";
import Button from "../../shared/Button";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";

const mCreateActivity = `
mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
	activity: $activity
	briefId: $briefId
	clientMatterId: $clientMatterId
	companyId: $companyId
	previous: $previous
	field: $field
	current: $current
	appModule: $appModule
	rowId: $rowId
  ) {
	id
  }
}`;

export default function ChangePassword({ loading }) {
  let history = useHistory();
  const { width } = useWindowDimensions();
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState({
    old: false,
    new: false,
  });
  const hideToast = () => {
    setShowToast(false);
  };

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const [passwordReq, setPasswordReq] = useState({
    minimum: false,
    uppercase: false,
    lowercase: false,
    numeral: false,
    symbol: false,
  });

  const minimumRegex = new RegExp("^(?=.{8,})");
  const lowerRegex = new RegExp("^(?=.*[a-z])");
  const upperRegex = new RegExp("^(?=.*[A-Z])");
  const numeralRegex = new RegExp("^(?=.*[0-9])");
  const symbolRegex = new RegExp("[^A-Za-z0-9]");

  function handlePasswordRequirements(event) {
    setPassword(event.target.value);
    let minimum = false;
    let lowercase = false;
    let uppercase = false;
    let numeral = false;
    let symbol = false;

    if (minimumRegex.test(event.target.value)) {
      console.log("MINIMUM TRUE!");
      minimum = true;
    }

    if (lowerRegex.test(event.target.value)) {
      lowercase = true;
    }

    if (upperRegex.test(event.target.value)) {
      uppercase = true;
    }

    if (numeralRegex.test(event.target.value)) {
      numeral = true;
    }

    if (symbolRegex.test(event.target.value)) {
      symbol = true;
    }

    setPasswordReq({
      minimum: minimum,
      lowercase: lowercase,
      uppercase: uppercase,
      numeral: numeral,
      symbol: symbol,
    });
  }

  /**
   * @param {boolean} req - gets the boolean value of the requirement
   * @param {boolean} booleanReturn - determines if the function will return a boolean or an icon
   * @returns {boolean} - returns a boolean if booleanReturn is true
   * @returns {JSX} - returns an icon if booleanReturn is false
   * @description - returns a boolean or an icon depending on the value of the requirement
   **/

  function handleCheckmark(req, booleanReturn) {
    return req === true ? (
      !booleanReturn ? (
        <MdCheck className="text-secondary w-5 flex-none" />
      ) : (
        true
      )
    ) : !booleanReturn ? (
      <MdOutlineClose className="text-danger w-5 flex-none" />
    ) : (
      false
    );
  }

  function handlePasswordVisibility(event, target) {
    event.preventDefault();
    if (target === "oldPassword") {
      setPasswordVisibility({
        old: !passwordVisibility.old,
        new: passwordVisibility.new,
      });
    }
    if (target === "newPassword") {
      setPasswordVisibility({
        old: passwordVisibility.old,
        new: !passwordVisibility.new,
      });
    }
    // setPasswordVisibility(!passwordVisibility)
  }

  const handleSave = async (formdata) => {
    console.log("formdata",formdata)
    const { oldPassword, newPassword } = formdata;

    /* Resets toast's state variables */
    revertToToastDefaults();

    if (oldPassword.trim() === "") {
      setResultMessage("Old password is required.");
      setShowToast(true);
      return false;
    }

    if (newPassword.trim() === "") {
      setResultMessage("New password is required.");
      setShowToast(true);
      return false;
    }

    /* Checks if new password complies with requirements */
    for (const key in passwordReq) {
      if (!handleCheckmark(passwordReq[key], true)) {
        setResultMessage("Please check the password requirements.");
        setToastError(true);
        setShowToast(true);
        return;
      }
    }

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(async (data) => {
        console.log("result", data);
        if (data === "SUCCESS") {
          setResultMessage("Your password has been changed.");
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
            reset({ oldPassword: "", newPassword: "" });
            history.push(AppRoutes.SIGNOUT);
          }, 2000);
          const activityParams = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              activity: `Password Changed`,
              field: "Password",
              appModule: "AUTH",
              previous: null,
              current: null
            },
          };

          const addActivity = await API.graphql(activityParams).then((result) =>
            console.log(`Add activity`, result)
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        var msg = err.toString();
        if (msg.indexOf("Incorrect username or password") > -1) {
          msg = "Incorrect old password";
        } else if (msg.indexOf("New Password is required") > -1) {
          msg = "Invalid new password";
        } else if (msg.includes("limit exceeded")) {
          msg = "Attempt limit exceeded, please try again later.";
        }
        setResultMessage(msg);
        setToastError(true);
        setShowToast(true);
      });
  };

  const revertToToastDefaults = () => {
    setToastError(false);
    setShowToast(false);
  };

  const [passwordEdit, setPasswordEdit] = useState(false);

  // useEffect(() => {
  //   getCompany();
  //   getUser();
  // });

  // async function getCompany() {
  //   const res = await API.graphql({
  //     query: getCompanyById,
  //     variables: {
  //       id: "5d21d259-9288-46de-b66f-fee37241bab0",
  //     },
  //   });
  //   console.log(res);
  // }

  // async function getUser() {
  //   const res = await API.graphql({
  //     query: getUserById,
  //     variables: {
  //       id: "0c3f9e9e-98e0-492b-a2f3-8b635560c786",
  //     },
  //   });
  //   console.log(res);
  // }

  //   const getUserById = `
  //       query user($id: String) {
  //         user(id: $id) {
  //           company {
  //             id
  //             name
  //           }
  //           email
  //           firstName
  //           lastName
  //           userType
  //         }
  //       }
  //     `;

  //   const getCompanyById = `
  //   query getCompanyById($id: String) {
  //     company(id: $id) {
  //       createdAt
  //       email
  //       id
  //       logo
  //       name
  //       phone
  //       updatedAt
  //     }
  //   }
  // `;

  return (
    <>
      {loading ? (
        <>
          <form className="grid gap-4" onSubmit={handleSubmit(handleSave)}>
            <InfoMessage
              title={"Change Password"}
              desc={
                "Your new password must be different from previous used passwords."
              }
            />
            <div className="flex flex-col md:grid md:grid-cols-2 md:gap-4">
              <div className="relative flex-auto">
                <p className="input-name">Old Password</p>
                <div className="relative my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e, "oldPassword")}
                  >
                    {passwordVisibility.old ? (
                      <FaEyeSlash className="text-xl text-hbGray" />
                    ) : (
                      <FaEye className="text-xl text-hbGray" />
                    )}
                  </button>
                </div>
                {errors.oldPassword?.type === "required" && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    <p>Old Password is required</p>
                  </div>
                )}
              </div>
              <div className="relative flex-auto">
                <p className="input-name">New Password</p>
                <div className="relative my-2">
                  <Skeleton height={"35px"} width="100%">
                    <SkeletonTheme />
                  </Skeleton>
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e, "newPassword")}
                  >
                    {passwordVisibility.new ? (
                      <FaEyeSlash className="text-xl text-hbGray" />
                    ) : (
                      <FaEye className="text-xl text-hbGray" />
                    )}
                  </button>
                </div>
                {/* Password Requirements */}
                <div className="mt-4">
                  <ul className="ml-2">
                    <Skeleton height={"20px"} width="100%">
                      <SkeletonTheme />
                    </Skeleton>
                    <Skeleton height={"20px"} width="100%">
                      <SkeletonTheme />
                    </Skeleton>
                    <Skeleton height={"20px"} width="100%">
                      <SkeletonTheme />
                    </Skeleton>
                    <Skeleton height={"20px"} width="100%">
                      <SkeletonTheme />
                    </Skeleton>
                    <Skeleton height={"20px"} width="100%">
                      <SkeletonTheme />
                    </Skeleton>
                  </ul>
                </div>
                {errors.newPassword?.type === "required" && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    <p>New Password is required</p>
                  </div>
                )}
                {errors.comparePassword && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    {errors.comparePassword.message}
                  </div>
                )}
              </div>
            </div>
            <div className="grid justify-end">
              <Button variant="secondary" size="large" type="submit" disabled>
                <span className="font-medium text-base">Change Password</span>
                <MdSave className="ml-2" />
              </Button>
              {/* <button className="save-btn" type="submit">
          <p>Save Changes</p>
          <MdSave />
        </button> */}
            </div>
            {showToast && resultMessage && (
              <ToastNotification
                title={resultMessage}
                hideToast={hideToast}
                error={toastError}
              />
            )}
          </form>
        </>
      ) : (
        width > 844 ? (
        <>
          <form className="grid gap-4" onSubmit={handleSubmit(handleSave)}>
            <InfoMessage
              title={"Change Password"}
              desc={
                "Your new password must be different from previous used passwords."
              }
            />
            <div className="flex flex-col md:grid md:grid-cols-2 md:gap-4">
              <div className="relative flex-auto">
                <p className="input-name">Old Password</p>
                <div className="relative my-2">
                  <input
                    type={passwordVisibility.old ? "text" : "password"}
                    className="input-field"
                    placeholder="Old Password"
                    {...register("oldPassword", { required: true })}
                    onChange={(elm) => {
                      if (elm.target.value) {
                        clearErrors("oldPassword");
                      }
                    }}
                  />
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e, "oldPassword")}
                  >
                    {passwordVisibility.old ? (
                      <FaEyeSlash className="text-xl text-hbGray" />
                    ) : (
                      <FaEye className="text-xl text-hbGray" />
                    )}
                  </button>
                </div>
                {errors.oldPassword?.type === "required" && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    <p>Old Password is required</p>
                  </div>
                )}
              </div>
              <div className="relative flex-auto">
                <p className="input-name">New Password</p>
                <div className="relative my-2">
                  <input
                    type={passwordVisibility.new ? "text" : "password"}
                    className="input-field"
                    placeholder="New Password"
                    {...register("newPassword", { required: true })}
                    onChange={(elm) => {
                      const values = getValues();
                      handlePasswordRequirements(elm);
                      if (values.oldPassword) {
                        clearErrors("newPassword");
                      }
                      if (values.oldPassword && elm.target.value) {
                        if (values.oldPassword === elm.target.value) {
                          setError("comparePassword", {
                            type: "manual",
                            message:
                              "The new password you entered is the same as your old password. Enter a different password.",
                          });
                        } else {
                          clearErrors("comparePassword");
                        }
                      } else {
                        clearErrors("comparePassword");
                      }
                      console.log(errors);
                    }}
                  />
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e, "newPassword")}
                  >
                    {passwordVisibility.new ? (
                      <FaEyeSlash className="text-xl text-hbGray" />
                    ) : (
                      <FaEye className="text-xl text-hbGray" />
                    )}
                  </button>
                </div>
                {/* Password Requirements */}
                <div className="mt-4">
                  <ul className="ml-2">
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.minimum, false)}
                      Minimum of 8 characters
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.lowercase, false)}
                      Must include at least 1 lowercase character (a-z)
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.uppercase, false)}
                      Must include at least 1 uppercase character (A-Z)
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.numeral, false)}
                      Must include at least 1 numeral (0-9)
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.symbol, false)}
                      Must include at least 1 symbol
                    </li>
                  </ul>
                </div>
                {errors.newPassword?.type === "required" && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    <p>New Password is required</p>
                  </div>
                )}
                {errors.comparePassword && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    {errors.comparePassword.message}
                  </div>
                )}
              </div>

              

              
            </div>
            <div className="grid justify-end">
              <Button variant="secondary" size="large" type="submit">
                <span className="font-medium text-base">Change Password</span>
                <MdSave className="ml-2" />
              </Button>
            </div>
            {showToast && resultMessage && (
              <ToastNotification
                title={resultMessage}
                hideToast={hideToast}
                error={toastError}
              />
            )}
          </form>
        </>
        ) : (
        // mobile view
        <>
          <form className="grid gap-4 p-4 py-6" onSubmit={handleSubmit(handleSave)}>
            <div className="flex flex-col md:grid md:grid-cols-2 md:gap-4">
              <div className="relative flex-auto">
                <p className="input-name">Current Password</p>
                <div className="relative my-2">
                  <input
                    type={passwordVisibility.old ? "text" : "password"}
                    className="input-field"
                    placeholder="Old Password"
                    {...register("oldPassword", { required: true })}
                    onChange={(elm) => {
                      //setPasswordEdit(true);
                      if (elm.target.value) {
                        clearErrors("oldPassword");
                      }
                    }}
                    id="currentPassword"
                  />
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e, "oldPassword")}
                  >
                    {passwordVisibility.old ? (
                      <FaEyeSlash className="text-xl text-hbGray" />
                    ) : (
                      <FaEye className="text-xl text-hbGray" />
                    )}
                  </button>
                </div>
                {errors.oldPassword?.type === "required" && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    <p>Old Password is required</p>
                  </div>
                )}
              </div>
              <div className="relative flex-auto">
                <p className="input-name">New Password</p>
                <div className="relative my-2">
                  <input
                    type={passwordVisibility.new ? "text" : "password"}
                    className="input-field"
                    placeholder="New Password"
                    {...register("newPassword", { required: true })}
                    onChange={(elm) => {
                      const values = getValues();
                      handlePasswordRequirements(elm);
                      if (values.oldPassword) {
                        clearErrors("newPassword");
                      }
                      if (values.oldPassword && elm.target.value) {
                        if (values.oldPassword === elm.target.value) {
                          setError("comparePassword", {
                            type: "manual",
                            message:
                              "The new password you entered is the same as your old password. Enter a different password.",
                          });
                        } else {
                          clearErrors("comparePassword");
                        }
                      } else {
                        clearErrors("comparePassword");
                      }
                      console.log(errors);
                    }}
                    id="newPassword"
                  />
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e, "newPassword")}
                  >
                    {passwordVisibility.new ? (
                      <FaEyeSlash className="text-xl text-hbGray" />
                    ) : (
                      <FaEye className="text-xl text-hbGray" />
                    )}
                  </button>
                </div>
                {/* Password Requirements */}
                <div className="mt-4">
                  <ul className="ml-2">
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.minimum, false)}
                      Minimum of 8 characters
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.lowercase, false)}
                      Must include at least 1 lowercase character (a-z)
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.uppercase, false)}
                      Must include at least 1 uppercase character (A-Z)
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.numeral, false)}
                      Must include at least 1 numeral (0-9)
                    </li>
                    <li className="text-base md:text-md flex items-center gap-2">
                      {handleCheckmark(passwordReq.symbol, false)}
                      Must include at least 1 symbol
                    </li>
                  </ul>
                </div>
                {errors.newPassword?.type === "required" && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    <p>New Password is required</p>
                  </div>
                )}
                {errors.comparePassword && (
                  <div className="error-msg">
                    <AiFillInfoCircle />
                    {errors.comparePassword.message}
                  </div>
                )}
              </div>
              {/* confirmPassword */}
              <div className="w-full pt-6">
                <p className="font-medium font-open-sans text-sm text-gray-700 mb-3">Confirm New Password</p>
                <div className="relative my-2">

                  <input
                    id="confirmPassword"
                    type={passwordVisibility.new ? "text" : "password"}
                    placeholder="Confirm New Password"
                    {...register("currentPassword", { required: true })}
                    className="input-field w-full font-open-sans font-light text-md"
                    onChange={(elm) => {
                      //setPasswordEdit(true);
                      // const values = getValues();
                      console.log("np",document.getElementById("newPassword").value, "cp", document.getElementById("confirmPassword").value);
                      if(document.getElementById("newPassword").value === document.getElementById("confirmPassword").value){
                        setPasswordEdit(true);
                      }
                      // if (values.newPassword) {
                      //   clearErrors("currentPassword");
                      // }
                      // if (values.newPassword && elm.target.value) {
                      //   if (values.oldPassword === elm.target.value) {
                      //     clearErrors("confirmPassword");
                      //   } else {
                      //     setError("confirmPassword", {
                      //       type: "manual",
                      //       message:
                      //         "The passwords do not match. Please enter the same password.",
                      //     });
                      //   }
                      // } else {
                      //   clearErrors("confirmPassword");
                      // }
                      // console.log(errors);
                    }}
                  />
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e, "newPassword")}
                  >
                    {passwordVisibility.new ? (
                      <FaEyeSlash className="text-xl text-hbGray" />
                    ) : (
                      <FaEye className="text-xl text-hbGray" />
                    )}
                  </button>
                </div>
               
              </div>
            </div>
            {passwordEdit && (
              <div className="flex flex-row justify-end border-t w-full py-4 px-6 gap-x-2">
              <Button
                className="font-open-sans text-sm font-normal shadow-none py-2 px-2"
                onClick={() => {
                  document.getElementById("currentPassword").value = "";
                  document.getElementById("newPassword").value = "";
                  document.getElementById("confirmPassword").value = "";
                  setPasswordEdit(false);
                }}
              >Cancel</Button>
              {/* <Button
                variant="hbBlue"
                type="submit"
                className="font-open-sans text-sm font-normal shadow-none py-2 px-4"
              >Save changes</Button> */}
              <Button variant="hbBlue"  type="submit"
                // onClick={() => }
              >
                <span className="font-medium text-base">Save Changes</span>
                <MdSave className="ml-2" />
              </Button>
            </div>
            )}
            {showToast && resultMessage && (
              <ToastNotification
                title={resultMessage}
                hideToast={hideToast}
                error={toastError}
              />
            )}
          </form>
        </>
        )
      )}
    </>
  );
}
