import React, { useState, useEffect, useRef } from "react";
import { AppRoutes } from "../../constants/AppRoutes";
import { API } from "aws-amplify";
import ToastNotification from "../toast-notification";
import { FaTimes, FaFolder } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { RiFileInfoLine } from "react-icons/ri";
import CreatableSelect from "react-select/creatable";
import Loading from "../loading/loading";
import Select from "react-dropdown-select";
import { getBriefListPerPersonaDropDown } from "../../constants/PersonaTiles";
import { hbaDomains } from "../../constants/AppDomains";

export default function BriefModal(props) {
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [selectedBrief, setSelectedBrief] = useState(null);
  const [Briefs, setBriefs] = useState(null);
  const [briefsOptions, setBriefOptions] = useState();
  const [loadSave, setLoadSave] = useState(false);

  const selectedBriefId = useRef("");
  const selectedBriefName = useRef("");

  useEffect(() => {
    getListBriefs();
  }, []);

  const hideToast = () => {
    setShowToast(false);
  };

  const handleModalClose = () => {
    props.handleModalClose();
  };

  const listBriefs = `
    query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
      clientMatter(id: $id) {
        briefs(limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            date
            order
          }
        }
      }
    }
    `;

  const mCreateBrief = `
  mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }
  `;

  const mUpdateBrief = `
  mutation tagBriefBackground($briefId: ID, $background: [BackgroundInput]) {
    briefBackgroundTag(briefId: $briefId, background: $background) {
      id
    }
  }
  `;

  const qBriefBackgroundList = `
    query getBriefByID($id: ID, $sortOrder: OrderBy) {
      brief(id: $id) {
        id
        backgrounds(sortOrder: $sortOrder) {
          items {
            id
            description
            comments
            date
            createdAt
            order
            files {
              items {
                id
                name
              }
            }
          }
          nextToken
        }
      }
    }  
  `;

  const mCreateLabel = `
    mutation createLabel($clientMatterId: String, $name: String) {
        labelCreate(clientMatterId:$clientMatterId, name:$name) {
            id
            name
        }
    }
  `;

  const BACKGROUND_TABLE_QUERY = `
  query getBackgroundTable($clientMatterId: ID!) {
      backgroundTable(clientMatterId: $clientMatterId) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
          presets {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }
  `;

  // MMA-2337
  const handleUpdateLabel = (newValue) => {
    props.updateLabel(newValue);
  };

  const handleUpdateLabelValue = (newValue) => {
    props.updateLabelValue(newValue);
  };

  const handleBriefChanged = async (newValue) => {
    console.group("handleBriefChanged");
    let { label, value } = newValue;

    if (label === value) {
      console.log("Creating new brief");

      setCreatedNew(true);
      handleUpdateLabel(`${label}`);
      handleUpdateLabelValue(`${value}`);
      handleSaveBrief(label);
    } else {
      console.log("Tagging to existing brief");
      setSelectedBrief(newValue);

      selectedBriefId.current = value;
      selectedBriefName.current = label;

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: props.matterId,
        },
      });

      if (backgroundTable?.data?.backgroundTable) {
        const { id, columns } = backgroundTable?.data?.backgroundTable;

        const updatedBriefs = columns.map((obj) => {
          if (
            (parseInt(obj?.accessorKey) !== 3 &&
              obj?.presets !== null &&
              obj.headerText == "Gmail") ||
            obj.headerText == "Label" ||
            obj.headerText === "Email Date" ||
            obj.headerText === "Description of Background" ||
            obj.headerText === "Document" ||
            obj.headerText === "Date"
          ) {
            const presetsToUse = [
              ...obj.presets.filter(
                (preset) => preset.id !== selectedBriefId.current
              ),
              { id: selectedBriefId.current, name: selectedBriefName.current },
            ];

            return { ...obj, presets: presetsToUse };
          } else {
            return obj;
          }
        });

        const variables = {
          id,
          input: {
            columns: updatedBriefs,
          },
        };

        const updateBriefTable = await API.graphql({
          query: UPDATE_BACKGROUND_TABLE_MUTATION,
          variables,
        });

        console.log("updateBriefTable", updateBriefTable);

        setShowToast(true);
        setResultMessage("Successfully Saved!");
        setTimeout(() => {
          //handleModalClose();
          setShowToast(false);
        }, 2000);
      }
    }
    setCreatedNew(false);
    console.groupEnd("handleBriefChanged");
  };

  const handleSaveBrief = async (briefname) => {
    // Add this to labels library
    await API.graphql({
      query: mCreateLabel,
      variables: {
        clientMatterId: props.matterId,
        name: briefname,
      },
    }).then((r) => {
      console.log("createdLabel", r);
    });

    // Add this to new brief backgrounds
    const addBrief = await API.graphql({
      query: mCreateBrief,
      variables: {
        clientMatterId: props.matterId,
        name: briefname,
        date: null,
        order: 0,
        columnIds: [0, 1, 2, 3, 4],
      },
    });

    selectedBriefId.current = addBrief.data.briefCreate.id;
    selectedBriefName.current = addBrief.data.briefCreate.name;
    const briefArr = {
      label: selectedBriefName.current,
      value: selectedBriefId.current,
    };
    setSelectedBrief(briefArr);

    const backgroundTable = await API.graphql({
      query: BACKGROUND_TABLE_QUERY,
      variables: {
        clientMatterId: props.matterId,
      },
    });
    console.log("BACKGROUND TABLE IS:", {
      ...backgroundTable?.data?.backgroundTable?.columns,
    });
    if (backgroundTable?.data?.backgroundTable) {
      const { id, columns } = backgroundTable?.data?.backgroundTable;

      const updatedBriefs = columns.map((obj) => {
        if (
          (parseInt(obj?.accessorKey) !== 3 &&
            obj?.presets !== null &&
            obj.headerText == "Gmail") ||
          obj.headerText == "Label" ||
          obj.headerText === "Email Date" ||
          obj.headerText === "Description of Background" ||
          obj.headerText === "Document" ||
          obj.headerText === "Date"
        ) {
          const presetsToUse = [
            ...obj.presets.filter(
              (preset) => preset.id !== selectedBriefId.current
            ),
            { id: selectedBriefId.current, name: selectedBriefName.current },
          ];

          return { ...obj, presets: presetsToUse };
        } else {
          return obj;
        }
      });
      console.log("UPDATED BRIEFS ARE:", updatedBriefs);
      const variables = {
        id,
        input: {
          columns: updatedBriefs,
        },
      };

      const updateBriefTable = await API.graphql({
        query: UPDATE_BACKGROUND_TABLE_MUTATION,
        variables,
      });

      console.log("updateBriefTable", updateBriefTable);
    }
  };

  const getListBriefs = async () => {
    const params = {
      query: listBriefs,
      variables: {
        id: props.matterId,
        limit: 200,
        nextToken: null,
      },
    };

    await API.graphql(params).then((brief) => {
      const result = brief.data.clientMatter.briefs.items.map(
        ({ id, name }) => ({
          value: id,
          label: name,
        })
      );
      var filteredArr = result.filter(function (sel) {
        return sel.value !== props.briefId;
      });
      

      const currUrl = window.location.hostname;
      if (hbaDomains.includes(currUrl)) {
        const filteredPerPersonaArr = getBriefListPerPersonaDropDown(filteredArr)
        setBriefOptions(filteredPerPersonaArr);
      } else {
        const filterUnderlineItems =  filteredArr.filter((item) => !item.label.includes('_'));
        setBriefOptions(filterUnderlineItems);
      }
    });
  };

  // MMA-2337
  const handleCreateClick = async () => {
    props.setIsCreateBriefClicked(true);

    // await props.setIsCreateBriefClicked(true);

    handleSaveBriefItems();
  };

  const mUpdateBackgroundOrder = `
    mutation updateBackgroundOrder($id: ID, $order: Int) {
		backgroundUpdate(id: $id, order: $order) {
        id
        order
      }
    }
  `;

  // Create new background rows
  const mCreateBackground = `
		mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
		backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
			id
			createdAt
			date
			description
			order
			briefs {
				id
				name
			}
			files {
				items {
					id
					name
					details
					gmailMessageId
					gmailMessage {
					gmailLabelIds
					}
					isGmailPDF
					isGmailAttachment
					s3ObjectKey
					order
					createdAt
				}
			}
		}
		}
	`;

  const BACKGROUNDFILE_TAG_MUTATION = `
		mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
		backgroundFileTag(backgroundId: $backgroundId, files: $files) {
			id
		}
		}
	`;

  const handleSaveBriefItems = async () => {
    // Clear selected items and set loading state
    props.setSelectedItems([]);
    props.setcheckAllState(false);
    props.setSelectRow([]);
    setLoadSave(true);

    console.log("PROPS ARE:", props);

    // Extracting selected item IDs
    const resultArray = props.selectedRowsBG.map(({ id }) => ({ id: id }));
    console.log("RESULT ARRAY IS:", resultArray);

    // Fetch existing backgrounds
    const backgroundOpt = await API.graphql({
      query: qBriefBackgroundList,
      variables: { id: props.briefId, sortOrder: "ORDER_ASC" },
    });

    const targetBackground = await API.graphql({
      query: qBriefBackgroundList,
      variables: { id: selectedBriefId.current, sortOrder: "ORDER_ASC" },
    });

    console.log("BACKGROUND OPT IS:", backgroundOpt);

    // Filter existing items based on selected items
    let filteredArr = [];
    if (backgroundOpt.data.brief.backgrounds.items !== null) {
      const resultExistingList = backgroundOpt.data.brief.backgrounds.items;
      filteredArr = resultExistingList.filter((existing) => {
        return resultArray.find((item) => item.id === existing.id);
      });
    }

    console.log("FILTERED ARRAY IS:", filteredArr);
    console.log("TARGET BACKGROUND:", targetBackground);

    // Process filtered items and create new background rows
    if (filteredArr.length > 0) {
      let finalArray = [];
      let filesMap = new Map(); // Change from filesList to filesMap

      filteredArr.forEach(async (item, index) => {
        let files = [];
        let tabFiles = [];

        if (item.files.items.length > 0) {
          item.files.items.forEach((file) => {
            tabFiles.push({ fileIds: file.id });
            files.push({ id: file.id, name: file.name });
          });
          filesMap.set(item.id, files);

          // Rest of your code for attachment-related operations goes here
        }

        finalArray.push({
          id: item.id,
          date: item.date,
          description: item.description,
          comments: item.comments,
          order: index + 1,
          briefs: [
            { id: selectedBriefId.current, name: selectedBriefName.current },
          ],
          tabFiles: item.files.items.length > 0 ? tabFiles : [], // Only include tabFiles if there are attachments
        });
      });

      // ... (remaining code remains unchanged)

      finalArray.map(async (item, i) => {
        resultArray.map(async (result, j) => {
          if (result.id === item.id) {
            console.log("SHOW ARR INSIDE", item);
            const createBackgroundRow = await API.graphql({
              query: mCreateBackground,
              variables: {
                briefs: item.briefs,
                description: item.description,
                comments: item.comments,
                date: item.date,
                order: item.order,
              },
            });
            console.log(
              "CREATE BACKGROUND:",
              createBackgroundRow.data.backgroundCreate
            );

            if (createBackgroundRow) {
              console.log(
                "INSIDE FILES",
                createBackgroundRow.data.backgroundCreate.id
              );
              console.log("INSIDE FILES FILES", filesMap.get(item.id)); // Use filesMap instead of filesList
              const backgroundEdit = await API.graphql({
                query: BACKGROUNDFILE_TAG_MUTATION,
                variables: {
                  backgroundId: createBackgroundRow.data.backgroundCreate.id,
                  files: filesMap.get(item.id), // Use filesMap instead of filesList
                },
              });
              console.log("BACKGROUND EDIT IS:", backgroundEdit);
            } else {
              console.log(
                "Skipping BACKGROUNDFILE_TAG_MUTATION as filesMap.get(item.id) is undefined or null"
              );
            }
          }
        });
      });

      // Show success message and navigate after a delay
      setShowToast(true);
      setResultMessage("Successfully copied it to another brief");

      setTimeout(() => {
        setLoadSave(false);
        setShowToast(false);
        handleModalClose();
        const back_id = "0";
        window.location = `${AppRoutes.BACKGROUND}/${back_id}/${
          props.matterId
        }/${selectedBriefId.current}/?matter_name=${utf8_to_b64(
          props.matter_name
        )}&client_name=${utf8_to_b64(props.client_name)}`;
      }, 2000);
    }
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  const [createdNew, setCreatedNew] = useState(false);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
            <div className="items-center">
              <div className="flex items-center justify-center p-6 rounded-b">
                <h3 className="text-2xl font-semibold">
                  Create or Update Brief
                </h3>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="font-semi-bold text-sm">Brief Title *</p>
                {/* <CreatableSelect
                  options={briefsOptions}
                  isClearable
                  isSearchable
                  //onChange={handleBriefChanged}
                  onChange={(e) => {
                    console.log("OLD Change", e);
                  }}
                  value={selectedBrief}
                  placeholder="Brief Name"
                  className="placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                /> */}
                <Select
                  options={briefsOptions}
                  clearable
                  searchable
                  onChange={(selectedItems) => {
                    console.log("selectedItems", selectedItems);
                    handleBriefChanged(selectedItems[0]);
                    console.log("Changed ", selectedItems[0]);
                  }}
                  create
                  //values={selectedBrief}
                  placeholder="Brief Name"
                  className="placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                />
              </div>
              <div className="flex items-center justify-center rounded-b mb-5">
                <div className="px-5 w-full flex items-center justify-center text-md"></div>
              </div>

              <div className="flex items-center justify-end p-6 rounded-b">
                <div className="px-5 w-full flex items-center justify-center text-md">
                  <button
                    className="mr-2 bg-white hover:bg-gray-300 text-black text-sm py-2 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring"
                    onClick={handleModalClose}
                  >
                    Cancel &nbsp; <TiCancel />
                  </button>

                  <button
                    className={
                      selectedBrief === null || loadSave
                        ? "ml-2 bg-green-200 text-white font-semibold py-2 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring disabled:opacity-25 cursor-not-allowed"
                        : "ml-2 bg-green-400 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring"
                    }
                    onClick={() => {
                      handleCreateClick();
                    }}
                    type="button"
                    disabled={selectedBrief === null || loadSave ? true : false}
                  >
                    Create &nbsp; <FaFolder />
                  </button>
                  {loadSave ? (
                    <>
                      <Loading w="30" h="30" />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {showToast && (
                <ToastNotification
                  title={resultMessage}
                  hideToast={hideToast}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
