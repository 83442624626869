import React, { useEffect, useState } from "react";
import Button from "../../shared/Button";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import emailIntegIcon from "../../assets/images/hba-email-integration-icon.png";
import emailIntegConnectBg from "../../assets/images/email-integ-connect.png";
import emailIntegAllCaughtUpBg from "../../assets/images/email-integ-all-caught-up.png";
import noiseEffect from "../../assets/images/noise-effect.png";
import { API } from "aws-amplify";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import "./email-integ.css";

const qGetCompanyEmailToken = `
query getCompanyEmailToken($companyId: String) {
  company(id: $companyId) {
    gmailToken {
      id
      refreshToken
      isOutOfSync
    }
    outlookToken {
      email
      refreshToken
      isOutOfSync
    }
  }
}`;

const qOutlookMessages = `
  query qOutlookMessages($id: String, $email: String, $startDate: String, $endDate: String){
    company (id: $id) {
      outlookMessages(
        email: $email,
        startDate: $startDate,
        endDate: $endDate
        ) {
        items {
          id
          date
          description
          subject
          outlookLabelIds
        }
      }
    }
  }`;

const qGmailMessagesbyCompany = `
  query gmailMessagesByCompany($id: String, $email: String, $startDate: String, $endDate: String) {
    company (id: $id) {
      gmailMessages(
        email: $email,
        startDate: $startDate,
        endDate: $endDate
      ) {
        items {
          id
          date
          description
          subject
          gmailLabelIds
        }
      }
    }
  }`;

export default function EmailInteg({ companyId, width, redirectUrl }) {
  const [isFlipped, setIsFlipped] = useState({ status: false, effect: "" });
  var momentTZ = require("moment-timezone");
  const userTimeZone = momentTZ.tz.guess();

  const history = useHistory();

  function visitBrief() {
    history.push(redirectUrl);
  }

  const [isOutlookIntegrated, setIsOutlookIntegrated] = useState(null);
  const [connectedEmail, setConnectedEmail] = useState(
    localStorage.getItem("connectedGmailAccount")
  );
  const [connectedOutlookAccount, setConnectedOutlookAccount] = useState(
    localStorage.getItem("connectedOutlookAccount")
  );
  const [companyIntegratedEmail, setCompanyIntegratedEmail] = useState(null);
  // const [refreshToken, setRefreshToken] = useState(null);
  // const [isOutOfSync, setIsOutOfSync] = useState(false);
  const [recentEmails, setRecentEmails] = useState(null);
  const [activeEmailIndex, setActiveEmailIndex] = useState(0);

  const getSavedAuthToken = async () => {
    // Check if theres an outlook token in DB
    const emailParams = {
      query: qGetCompanyEmailToken,
      variables: {
        companyId: companyId,
      },
    };

    const emailResult = await API.graphql(emailParams);
    console.log("Email Auth Result:", emailResult.data.company);

    const gmailToken = emailResult.data.company.gmailToken;
    if (gmailToken.id) {
      saveAccountState(gmailToken);
      localStorage.setItem("connectedGmailAccount", gmailToken.id);
      setIsOutlookIntegrated(false);
      // console.log("GMAIL TOKEN!", gmailToken.id);
      // console.log("CONNECTEDEMAIL", connectedEmail);
      setConnectedEmail(gmailToken.id);
      // setRenderGmailButton(true);
      return;
    }
    // setRenderGmailButton(true);
    const outlookToken = emailResult.data.company.outlookToken;

    if (outlookToken.email) {
      saveAccountState(outlookToken);
      localStorage.setItem("connectedOutlookAccount", outlookToken.email);
      setIsOutlookIntegrated(true);
      setConnectedOutlookAccount(outlookToken.email);
      return;
    }
  };

  const saveAccountState = async (gmailToken) => {
    const gmailTokenId = isOutlookIntegrated ? gmailToken.email : gmailToken.id; // user email address
    const gmailRefreshToken = gmailToken.refreshToken;
    const gmailIsOutOfSync =
      gmailToken.isOutOfSync === null || gmailToken.isOutOfSync === undefined
        ? false
        : gmailToken.isOutOfSync;

    if (connectedEmail !== gmailTokenId) {
      console.group("Connected Email != Company-Integrated Email");
      console.log("Connected Email:", connectedEmail);
      console.log("Company-Integrated Email:", gmailTokenId);
      console.groupEnd();
    }

    //MMA 1013

    //When gmailTokenId is null will require user to sign in
    // if (gmailTokenId === null) {
    //   setCompanyIntegratedEmail(null);
    //   setRefreshToken(null);
    //   setIsOutOfSync(false);
    //   setRenderGmailButton(true);
    // }

    //When current email doesnt have access to integrated company email will require user to sign in
    // if (email !== gmailTokenId && accessedEmail !== gmailTokenId) {
    //   setCompanyIntegratedEmail(null);
    //   setRefreshToken(null);
    //   setIsOutOfSync(false);
    //   setRenderGmailButton(true);
    // }

    //When current email has access to integrated company email will automatically login & list the emails
    // if (email === gmailTokenId) {
    //   setCompanyIntegratedEmail(gmailTokenId);
    //   setRefreshToken(gmailRefreshToken);
    //   setIsOutOfSync(gmailIsOutOfSync);
    //   setRenderGmailButton(true);
    // }

    //When current email dont have access but logged in an email will list the emails
    // if (accessedEmail && accessedEmail === gmailTokenId) {
    //   setCompanyIntegratedEmail(gmailTokenId);
    //   setRefreshToken(gmailRefreshToken);
    //   setIsOutOfSync(gmailIsOutOfSync);
    //   setRenderGmailButton(true);
    // }

    //MMA 2150
    if (width < 844 && gmailIsOutOfSync === true) {
      setCompanyIntegratedEmail(null);
      // setRefreshToken(null);
    } else {
      setCompanyIntegratedEmail(gmailTokenId);
      // setRefreshToken(gmailRefreshToken);
    }
    // setIsOutOfSync(gmailIsOutOfSync);
    console.groupEnd();
    return;
  };

  useEffect(() => {
    if (isOutlookIntegrated !== null) getUnSavedEmails();
    else getSavedAuthToken();
  }, [isOutlookIntegrated]);

  useEffect(() => {
    console.log("Recent Emails", recentEmails);
  }, [recentEmails]);

  useEffect(() => {
    console.log("Active Emails Index", activeEmailIndex);
  }, [activeEmailIndex]);

  useEffect(() => {
    setTimeout(() => {
      setIsFlipped(false);
    }, 200);
  }, [isFlipped]);

  const getUnSavedEmails = async (filters, batchSize = 50, action) => {
    try {
      // console.log("Search Unsaved Filters:", filters.subject);

      // const filterSubject = filters?.subject;
      // const filterHasAttachments = filters?.hasAttachments;

      // if (action !== "clear") {
      //   /** Remove this to prevent refreshing list after saving emails from mobile */
      //   if (width > 844) {
      //     setWaitUnSaved(true);
      //   }
      // }

      let result;
      let emailList = [];

      const params = {
        query: isOutlookIntegrated ? qOutlookMessages : qGmailMessagesbyCompany,
        variables: {
          id: companyId,
          email: isOutlookIntegrated
            ? connectedOutlookAccount
            : localStorage.getItem("connectedGmailAccount"),
          startDate: momentTZ(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            userTimeZone
          ).format("YYYY-MM-DD"),
          endDate: momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
        },
      };

      result = await API.graphql(params);

      emailList = isOutlookIntegrated
        ? result.data?.company?.outlookMessages?.items || []
        : result.data?.company?.gmailMessages?.items || [];

      let filteredEmails = isOutlookIntegrated
        ? emailList?.filter(
            (om) =>
              om.outlookLabelIds?.includes("INBOX") &&
              om.outlookLabelIds?.includes("UNREAD")
          )
        : emailList?.filter(
            (gm) =>
              gm.gmailLabelIds?.includes("INBOX") &&
              gm.gmailLabelIds?.includes("UNREAD")
          );

      // ONLY DISPLAY 3 EMAILS
      let numberOfEmails = 3;
      let limitedEmails = filteredEmails.slice(0, numberOfEmails);
      setRecentEmails(limitedEmails);
    } catch (error) {
      console.log("Error fetching emails:", error);
    }
  };

  const truncateStyles = (lines) => {
    return {
      display: "-webkit-box",
      WebkitLineClamp: lines,
      WebkitBoxOrient: "vertical",
    };
  };

  return (

      <div className={`w-full`}>
        <div
          style={
            recentEmails?.length > 0
              ? { "background-color": "white" }
              : {
                  "backgroundImage":
                    "linear-gradient(220deg, #3498EA 10%, #6363F1 50%, #A163F1 100%)",
                }
          }
          className="relative rounded-lg border  border-neutral-200 overflow-hidden hover:bg-gray-100"
        >
          {isOutlookIntegrated !== null && recentEmails?.length > 0 ? (
            <div>
              <div
                className={
                  "w-full pt-6 px-8 flex-col justify-start items-start gap-3 inline-flex"
                }
              >
                <div className="gap-1 z-40 w-full">
                  {width > 844 ? (
                    <div className="justify-between flex-col flex w-full pb-2">
                      <p className="text-xs font-open-sans font-bold text-transparent bg-clip-text bg-gradient-to-tr from-blue-400 via-purple-500 to-pink-400">
                        Email Integration
                        <BsInfoCircle className="text-white text-xs ml-1 mb-5 inline" />
                      </p>
                      <p className="text-black text-lg font-semibold">
                        {`You've got ${recentEmails.length} new`}
                      </p>
                      <p className="text-black text-lg font-semibold">
                        {`messages waiting for you`}
                      </p>
                      <p className="text-black pt-1 text-md font-normal">
                        {`Dive into your inbox and start tagging them to stay organised and on track. Let's go!`}
                      </p>
                    </div>
                  ) : (
                    <div className="justify-between flex-col flex w-full pb-2">
                      <p className=" text-xs text-white font-open-sans font-semibold">
                        Email Integration
                        <BsInfoCircle className="text-white text-xs ml-1 mb-5 inline" />
                      </p>
                      <p className="text-black text-lg font-semibold">
                        {`You've got ${recentEmails.length} new`}
                      </p>
                      <p className="text-black text-lg font-semibold">
                        {`messages waiting for you`}
                      </p>
                      <p className="text-black pt-1 text-md font-normal">
                        {`Dive into your inbox and start tagging them to stay organised and on track. Let's go!`}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="px-8 pb-8 pt-4 w-full ">
                  <div className="relative flex flex-col align-middle justify-center h-32 w-full">
                    {recentEmails &&
                      recentEmails.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              let n = recentEmails.length;
                              const nextItem =
                                index + 1 == recentEmails.length
                                  ? 0
                                  : index + 1;
                              setIsFlipped({ status: true, effect: "jump" });
                              setActiveEmailIndex(nextItem);
                            }}
                            className={` absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg hover:shadow-xl rounded-lg text-white ${
                              isFlipped.status
                                ? " animate-fade transition-all duration-200"
                                : ""
                            } ${
                              activeEmailIndex === index && index === 0
                                ? "z-30 mt-6 w-full  " // front
                                : activeEmailIndex !== index &&
                                  index === 1 &&
                                  activeEmailIndex === 0
                                ? "z-20 mt-3 w-11/12" // second
                                : activeEmailIndex !== index &&
                                  index === 2 &&
                                  activeEmailIndex === 0
                                ? "z-10 w-10/12" // last
                                : activeEmailIndex !== index &&
                                  index === 0 &&
                                  activeEmailIndex === 1
                                ? "z-10 mt-6 w-11/12" // last
                                : activeEmailIndex === index && index === 1
                                ? "z-30 mt-3 w-full  " // front
                                : activeEmailIndex !== index &&
                                  index === 2 &&
                                  activeEmailIndex === 1
                                ? "z-20 mb-3 w-11/12" // second
                                : activeEmailIndex !== index &&
                                  index === 0 &&
                                  activeEmailIndex === 2
                                ? "z-20 mt-3 w-11/12" // second
                                : activeEmailIndex !== index &&
                                  index === 1 &&
                                  activeEmailIndex === 2
                                ? "z-10 mt-6 w-10/12" // last
                                : activeEmailIndex === index && index === 2
                                ? "z-30 w-full  " // front
                                : ""
                            }`}
                            style={{
                              "background-image": `${
                                index === 0
                                  ? "linear-gradient(220deg, #3498EA 10%, #6363F1 50%, #A163F1 100%)"
                                  : index === 1
                                  ? "linear-gradient(220deg, #6363F1 10%, #A163F1 50%, #3498EA 100%)"
                                  : index === 2
                                  ? "linear-gradient(260deg, #A163F1 10%, #6363F1 50%, #3498EA 100%)"
                                  : ""
                              }`,
                              "user-select": "none",
                            }}
                          >
                            <div className="bgNoise cursor-pointer rounded-lg px-2 py-2">
                              <div
                                className="z-5 justify-center items-center inline-flex"
                                style={{
                                  opacity: "0.2",
                                }}
                              >
                                <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
                                  <div className="relative">
                                    <div
                                      className="overflow-hidden absolute top-0.5 left-0 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                                      style={{
                                        borderColor: "rgb(255, 255, 255)",
                                      }}
                                    ></div>
                                    <div
                                      className="overflow-hidden absolute top-0.5 left-0 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                                      style={{
                                        borderColor: "rgb(255, 255, 255, 0.75)",
                                      }}
                                    ></div>
                                    <div
                                      className="overflow-hidden absolute top-0.5 left-0 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                                      style={{
                                        borderColor: "rgb(255, 255, 255, 0.50)",
                                      }}
                                    ></div>
                                    <div
                                      className="overflow-hidden absolute top-0.5 left-0 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                                      style={{
                                        borderColor: "rgb(255, 255, 255, 0.25)",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div className="px-4">
                                <p
                                  className="relative text-white font-semibold overflow-hidden break-words max-w-full"
                                  style={truncateStyles(1)}
                                >
                                  {recentEmails[index].subject}
                                </p>
                                <p
                                  className="relative opacity-75 font-light h-16 text-sm mb-4 overflow-hidden break-words text-ellipses w-full"
                                  style={truncateStyles(3)}
                                >
                                  {recentEmails[index].description}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className=" z-20 p-4 pt-2 pb-4 w-full flex flex-col justify-between">
                  <div className="py-1 flex justify-center gap-2 w-full">
                    {recentEmails.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            setIsFlipped({ status: true, effect: "jump" });
                            setActiveEmailIndex(index);
                          }}
                          className={`rounded-sm w-3 h-0.5 p-0.5 cursor-pointer ${
                            activeEmailIndex === index
                              ? "bg-hbBlue"
                              : " bg-hbLightGray"
                          }`}
                        ></div>
                      );
                    })}
                  </div>
                  <div className="pt-2">
                    <Button
                      variant={"hbWhite"}
                      className="py-2 cursor-pointer z-20"
                      block={"full"}
                      onClick={visitBrief}
                    >
                      Go to Inbox
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : isOutlookIntegrated !== null && recentEmails?.length == 0 ? (
            <div className="bgNoise">
              <div className="w-full pt-1 pb-1 pl-4 flex-col justify-start items-start gap-1 inline-flex">
                <div className="w-11 h-11 justify-center items-center inline-flex">
                  <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
                    <div className="relative">
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.30)",
                        }}
                      ></div>
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.25)",
                        }}
                      ></div>
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.20)",
                        }}
                      ></div>
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.15)",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div
                  className="gap-1 z-20 h-80 bg-no-repeat bg-right-bottom"
                  style={{ backgroundImage: `url(${emailIntegAllCaughtUpBg})` }}
                >
                  <div className="justify-start items-center gap-2 pb-5">
                    <div className="inline-flex">
                      <div className="text-white text-lg font-open-sans font-semibold mr-1">
                        All Caught Up!
                      </div>
                    </div>
                    <div className=" w-3/6 text-sm font-normal font-open-sans text-white pt-2 leading-tight">
                      No new messages in your inbox. Take this moment to tidy up
                      other sections or simply take a well deserved break. Enjoy
                      the calm!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bgNoise">
              <div className="w-full pt-1 pb-1 pl-4 flex-col justify-start items-start gap-1 inline-flex">
                <div className="w-11 h-11 justify-center items-center inline-flex">
                  <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
                    <div className="relative">
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.30)",
                        }}
                      ></div>
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.25)",
                        }}
                      ></div>
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.20)",
                        }}
                      ></div>
                      <div
                        className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                        style={{
                          borderColor: "rgb(243, 244, 246, 0.15)",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div
                  className="gap-1 z-20 h-80 bg-no-repeat bg-right-bottom"
                  style={{ backgroundImage: `url(${emailIntegConnectBg})` }}
                >
                  <div className="justify-start items-center gap-2 pb-5">
                    <div className="inline-flex">
                      <div className="text-white text-lg font-open-sans font-semibold mr-1">
                        Email Integration
                      </div>
                    </div>
                    <div className=" w-3/6 text-sm font-normal font-open-sans text-white pt-2 leading-tight">
                      Seamlessly integrate your home building journey with your
                      email to ensure you're always up-to-date.
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative w-full px-4 flex-col justify-start items-start pb-4 z-100">
                <div className="w-full h-10 rounded-lg justify-start items-start inline-flex">
                  {isOutlookIntegrated !== null ? (
                    recentEmails === null ? (
                      <div className="w-full text-center text-white font-medium text-lg">
                        Loading recent emails...
                      </div>
                    ) : (
                      <div className="w-full text-center text-white font-medium text-lg">
                        There are no recent emails within a month
                      </div>
                    )
                  ) : (
                    <Button
                      variant={"hbWhite"}
                      className="py-2 cursor-pointer"
                      block={"full"}
                      onClick={visitBrief}
                    >
                      Connect Now
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
          {/** End Header Email Integration */}
        </div>
      </div>

  );
}
