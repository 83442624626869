export const DefaultPhases = [
  {
    name: "PRECONSTRUCTION",
    label: "Pre Construction",
    order: 1,
    defaultBriefs: [
      "Project Location",
      "Email Integration",
      "Tenders",
      "Budget",
      "Task List",
      "Contracts",
      "Development Approvals",
      "Building Permits",
      "Insurance",
    ],
  },
  {
    name: "CONSTRUCTION",
    label: "Construction",
    order: 2,
    defaultBriefs: [
      "Email Integration",
      "Site Diary",
      "Task List",
      "Defects List",
      "Progress Photos",
      "Invoices",
      "Variations",
      "Budget",
      "Provisional Sum",
      "Prime Cost",
      "Project Location",
      "Progress of work",
      "Work Safety Compliance",
      "Warranties and Certifications",
      "Contracts",
    ],
  },
  {
    name: "HANDOVER",
    label: "Handover",
    order: 3,
    defaultBriefs: [
      "Project Location",
      "Email Integration",
      "Progress Photos",
      "Defects List",
      "Warranties and Certifications",
      "Insurance",
      "Certificates",
    ],
  },
];
