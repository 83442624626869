import { Switch } from "@headlessui/react";
import { API } from "aws-amplify";
import { useState, useEffect } from "react";
import { useColumnContext } from "../context/ColumnContext";
import CreatableSelect from "react-select/creatable";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const TASKLIST_PRESETS_QUERY = `
  query taskListPresets($companyId: ID) {
    taskListPresets(companyId: $companyId) {
      id
      columnIds
      label
      name
    }
  }`;

const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
    activity: $activity
    briefId: $briefId
    clientMatterId: $clientMatterId
    companyId: $companyId
    previous: $previous
    field: $field
    current: $current
    appModule: $appModule
    rowId: $rowId
    ) {
    id
    }
  }`;

const UPDATE_TASK_LIST_TABLE_MUTATION = `
  mutation taskListTableUpdate(
      $id: ID!
      $input: TaskListTableInput!
      ) {
    taskListTableUpdate(
      id: $id
      input: $input
    ) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          label
        }
      }
    }
  }`;

const CREATE_TASKLIST_PRESET_MUTATION = `
  mutation createTasklistPresets($companyId: ID, $label: String, $columnIds: [Int]) {
    taskListPresetsCreate(
      companyId: $companyId
      label: $label
      columnIds: $columnIds
    ) {
      id
    }
  }`;

const UPDATETASKLISTPRESET_MUTATION = `
  mutation updateTasklistPresets($columnIds: [Int], $id: ID) {
    taskListPresetsUpdate(id: $id, columnIds: $columnIds) {
      id
    }
  }`;

const selectStyle = {
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 99999,
  }),
};

const ColumnSettingTab = ({
  preset,
  setPreset,
  presetsLibrary,
  setPresetsLibary,
}) => {
  // console.log("ColumnSettingTab PRESET", preset);
  const tableId = localStorage.getItem("task-list-table-id");
  // const [presetsLibrary, setPresetsLibary] = useState([]);
  const [presetsOption, setPresetsOption] = useState([]);
  const [columns, setColumns] = useColumnContext();
  const [query, setQuery] = useState("");

  useEffect(() => {
    // if (presetsLibrary.length === 0 && presetsOption.length === 0) {
    getPresetsLibrary();
    // }
  }, []);

  useEffect(() => {
    if (preset) {
      runPresets(preset);
    }
  }, [preset]);

  async function getPresetsLibrary() {
    // console.group("ColumnSettingTab: getPresetsLibrary()");
    setPresetsOption([]);
    const { data } = await API.graphql({
      query: TASKLIST_PRESETS_QUERY,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    });

    // console.log("taskListPresets", data);

    const tlPresets = sortArrayByLabel(data?.taskListPresets);
    const presetOpt = tlPresets.map((x) => {
      return {
        label: x.label,
        value: x.id,
      };
    });

    setPresetsLibary(tlPresets);
    setPresetsOption(presetOpt);
  }

  async function runPresets(preset) {
    console.group("runPresets()");
    const presetColumns = presetsLibrary?.find((obj) => obj.id === preset);
    // console.log("PRESET COLUMNS", presetColumns);
    const presetColumnIds = new Set(presetColumns?.columnIds || []);

    // console.log("presetColumnIds", presetColumnIds);
    const newColumnSettingsWithId = columns.map((column) => ({
      ...column,
      enabled: presetColumnIds.has(parseInt(column.accessorKey)),
    }));

    // console.log("newColumnSettingsWithId", newColumnSettingsWithId);

    const columnsToSetApi = sortByAccesorKey(newColumnSettingsWithId).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    // console.log("columnsToSetApi", columnsToSetApi);

    if (columnsToSetApi.length > 0) {
      const { data } = await API.graphql({
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      });

      // console.log("Updated Cols ", data.taskListTableUpdate.columns);

      const tableColumns = data.taskListTableUpdate.columns.map((c) => {
        if (c.type === "DROPDOWN") {
          return {
            accessorKey: c.accessorKey,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            order: c.order,
            presets: c.presets,
            optionsText: c.optionsText ? c.optionsText : [],
          };
        }
        return {
          accessorKey: c.accessorKey,
          header: c.headerText,
          type: c.type,
          order: c.order,
          presets: c.presets,
          enabled: c.enabled,
        };
      });

      setColumns(tableColumns);

      console.groupEnd();
    }
  }

  function findObjectByLabel(arr, label) {
    return arr.find((obj) => obj.label === label);
  }

  async function handleRegisterChanged(id, cols) {
    // console.log("\n\noriginal", cols);

    const newCols = await Promise.allSettled(
      cols.map(async (r) => {
        if (r?.__isNew__) {
          const { label } = r;
          const objChecker = findObjectByLabel(presetsOption, label);

          // console.log("objChecker:-->", objChecker);
          if (!objChecker) {
            const newId = await createNewRegister(label);
            return { value: newId, label };
          }
        }
        return r;
      })
    );

    const updatedCols = newCols.map((r) => {
      // if (r?.__isNew__) {
      const v = r?.value;
      return { value: v.value, label: v.label };
      // }
      // return r;
    });

    // console.log("newCols", newCols);
    console.log("\n\n *** updatedCols:", updatedCols);
    await updateRegisters(id, updatedCols);
  }

  async function createNewRegister(label) {
    const companyId = localStorage.getItem("companyId");
    const { data } = await API.graphql({
      query: CREATE_TASKLIST_PRESET_MUTATION,
      variables: {
        companyId,
        label,
        columnIds: null,
      },
    });

    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: null,
        briefId: null,
        activity: `create new register`,
        field: "Sidebar",
        appModule: "TASKS",
        previous: null,
        current: null
      },
    };

    const addActivity = await API.graphql(params).then((result) =>
      console.log(`add activity`, result, label)
    );
    // console.log("Newly Added ID is", data?.taskListPresetsCreate?.id);
    return data?.taskListPresetsCreate?.id;
  }

  async function updateRegisters(accessorKey, registers) {
    // console.log("updateRegisters()");
    const presets = registers.map((item) => {
      const { value, ...rest } = item;
      return { id: value, ...rest };
    });

    const newColumnSettingsWithId = columns.map((column, index) => {
      if (column.accessorKey === accessorKey) {
        column.presets = presets;
      }
      return column;
    });

    // console.log("Columns:", columns);
    // console.log("Columns with Presets:", newColumnSettingsWithId);

    await saveColumns(newColumnSettingsWithId);

    const groupedPresets = await groupByPresetsId(newColumnSettingsWithId);
    // console.log("groupedPresets", groupedPresets);

    const mappedPresets = Object.keys(groupedPresets).map((key) => ({
      presetId: key,
      columnIds: groupedPresets[key],
    }));

    // console.log("mappedPresets", mappedPresets);

    const savePromises = mappedPresets.map(async ({ columnIds, presetId }) => {
      try {
        await API.graphql({
          query: UPDATETASKLISTPRESET_MUTATION,
          variables: { columnIds, id: presetId },
        });
      } catch (error) {
        console.error(error);
      }
    });

    await Promise.allSettled(savePromises);
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: null,
        briefId: null,
        activity: `updated task list preset`,
        field: "Sidebar",
        appModule: "TASKS",
        previous: null,
        current: null
      },
    };

    const addActivity = await API.graphql(params).then((result) =>
      console.log(`add activity`, result)
    );
    // setPreset("");
    await getPresetsLibrary();
  }

  async function toggleColumn(accessorKey, open) {
    // setPreset("");
    const newColumnSettingsWithId = columns.map((column, index) => {
      if (column.accessorKey === accessorKey) {
        column.enabled = open;
      }
      return column;
    });
    await saveColumns(newColumnSettingsWithId);
  }

  async function groupByPresetsId(arr) {
    return arr.reduce((acc, curr) => {
      curr?.presets?.forEach((preset) => {
        if (!acc[preset.id]) {
          acc[preset.id] = [];
        }

        // console.log("curr.accessorKey", curr.accessorKey);
        if (curr.accessorKey !== "") {
          acc[preset.id].push(parseInt(curr.accessorKey, 10));
        }
      });
      return acc;
    }, {});
  }

  async function saveColumns(newColumnSettingsWithId) {
    // console.log("saveColumns()");
    const columnsToSetApi = sortByAccesorKey(newColumnSettingsWithId).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    if (columnsToSetApi.length > 0) {
      const { data } = await API.graphql({
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      });


      const tableColumns = data.taskListTableUpdate.columns.map((c) => {
        if (c.type === "DROPDOWN") {
          return {
            accessorKey: c.accessorKey,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            order: c.order,
            presets: c.presets,
            optionsText: c.optionsText ? c.optionsText : [],
          };
        }
        return {
          accessorKey: c.accessorKey,
          header: c.headerText,
          type: c.type,
          order: c.order,
          presets: c.presets,
          enabled: c.enabled,
        };
      });

      // console.log("setColumns", tableColumns);
      setColumns(tableColumns);
    }
  }

  function sortArrayByLabel(arr) {
    arr.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return arr;
  }

  function sortByAccesorKey(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.accessorKey - b.accessorKey);
    } else {
      sort = arr;
    }

    return sort;
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    width: "100%",
  });

  async function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setColumns(
      arr_move(filteredColumns, result.source.index, result.destination.index)
    );

    const newColumnOrder = columns.map((column, index) => {
      column.order = index + 1;
      return column;
    });

    const columnsToSetApi = sortByAccesorKey(newColumnOrder).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    if (columnsToSetApi.length > 0) {
      const { data } = await API.graphql({
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      });

      const tableColumns = data.taskListTableUpdate.columns.map((c) => {
        if (c.type === "DROPDOWN") {
          return {
            accessorKey: c.accessorKey,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            order: c.order,
            presets: c.presets,
            optionsText: c.optionsText ? c.optionsText : [],
          };
        }
        return {
          accessorKey: c.accessorKey,
          header: c.headerText,
          type: c.type,
          order: c.order,
          presets: c.presets,
          enabled: c.enabled,
        };
      });
      setColumns(tableColumns);
    }
  }

  const filteredColumns =
    query === ""
      ? sortByOrder(columns)
      : sortByOrder(columns).filter((column) => {
          return column.header.toLowerCase().includes(query.toLowerCase());
        });

  function sortByOrder(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.order - b.order);
    } else {
      sort = arr;
    }

    return sort;
  }

  function arr_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  return (
    <div className="mr-1">
      <input
        type="text"
        className="p-3 border w-full rounded-md ml-1"
        placeholder="Search Column"
        onChange={(event) => setQuery(event.target.value)}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="flex flex-col mt-6 space-y-5"
            >
              {filteredColumns.map((column, index) => (
                <Draggable
                  key={column.accessorKey}
                  draggableId={column.accessorKey}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      key={column.accessorKey}
                      className="flex w-full"
                    >
                      <p className="truncate ml-2">{column.header}</p>
                      <div className="ml-auto">
                        <Switch
                          onChange={(e) => {
                            toggleColumn(column.accessorKey, e);
                          }}
                          checked={column.enabled}
                          className={`${
                            column.enabled
                              ? "cursor-pointer bg-black"
                              : "bg-gray-200"
                          } relative inline-flex h-5 w-10 items-center rounded-full`}
                        >
                          <span
                            className={`${
                              column.enabled ? "translate-x-6" : "translate-x-1"
                            } inline-block h-3 w-3 transform rounded-full bg-white`}
                          />
                        </Switch>
                      </div>

                      <CreatableSelect
                        key={`select-${column.accessorKey}`}
                        options={presetsOption}
                        className="w-80 z-auto ml-5"
                        styles={selectStyle}
                        defaultValue={column?.presets?.map((a) => {
                          return { value: a.id, label: a.label };
                        })}
                        onChange={(e) => {
                          handleRegisterChanged(column.accessorKey, e);
                        }}
                        placeholder="Task List Register"
                        isMulti={true}
                        openMenuOnClick={true}
                        openMenuOnFocus={true}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ColumnSettingTab;
