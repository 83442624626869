import React, { useEffect, useRef, useState } from "react";
import { API } from "aws-amplify";
import Select from "react-select/creatable";
import { CgClose, CgTrash } from "react-icons/cg";

export default function ClientMatterSelect({
  options,
  name,
  disabled,
  item,
  index,
  inputCMList,
  setInputCMList,
  clientMatterList,
}) {
  const [selectValue, setSelectValue] = useState()

  const handleDelete = (index) => {
    setInputCMList(inputCMList.filter((_, idx) => idx !== index));
  };

  function handleChange(newValue) {
    console.log("newValue", newValue)
    setSelectValue(newValue)
    const list = [...inputCMList]
    const target = clientMatterList.filter((item) => {
      return item.id === newValue.value
    })
    list[index] = target[0]
    console.log("target", target)
    setInputCMList(list);
  }

  const defaultVal = {
    label: `${item?.client?.name} / ${item?.matter?.name}`,
    value: item?.id,
  }

  return(
    <>
      <div className="flex gap-2 ">
        <Select
          key={item?.id}
          name={name}
          className="w-full placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring mb-2"
          value={selectValue}
          defaultValue={item?.client?.name === "" ? null : defaultVal}
          onChange={(newValue) => handleChange(newValue)}
          options={options}
          menuPortalTarget={document.body}
          isDisabled={disabled}
          styles={{
            control: (styles, { isDisabled }) => {
              return {
                ...styles,
                cursor: isDisabled ? "not-allowed" : "default",
                backgroundColor: "white",
                color: "black",
              };
            },
            container: (base) => ({
              ...base,
              zIndex: "999",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 99 }),
          }}
          isSearchable
          isValidNewOption={() => false}
          openMenuOnClick={true}
          isClearable={false}
        />
        {!disabled &&
          <CgTrash
            className="border border-gray-200 text-4xl rounded p-2 cursor-pointer hover:bg-gray-100"
            color={`lightcoral`}
            onClick={() => handleDelete(index)}
          />
        }
      </div>
    </>
  )
}