import React, { useEffect, useState } from "react";
import { API /*Storage*/ } from "aws-amplify";
import { FiArchive } from "react-icons/fi";
import { Switch } from "@headlessui/react";
import {
  isMobileDevice,
  // useWindowDimensions,
} from "../../shared/mobileViewFunctions";

var moment = require("moment");

const ACTIVITY_CREATE = `
  mutation createActivity($activity: String, $appModule: AppModules,$clientMatterId: ID, $briefId: ID, $companyId: ID, $current: String, $field: String, $previous: String, $rowId: String) {
    activityCreate(
      companyId: $companyId
	  briefId: $briefId
      clientMatterId: $clientMatterId
      activity: $activity
      appModule: $appModule
      current: $current
      field: $field
      previous: $previous
      rowId: $rowId
    ) {
      id
    }
  }
`;

const ActionButtons = ({
  taskListColumns,
  handleScrollIndex,
  selectedEmail,
  setIsModal,
  selectedUnsavedItems,
  selectedSavedItems,
  setSelectedUnsavedItems,
  setSelectedSavedItems,
  openTab,
  setOpenTab,
  getUnSavedEmails,
  getSavedEmails,
  unSavedEmails,
  savedEmails,
  fetchData,
  setWaitUnSaved,
  setWaitSaved,
  setWaitArchived,
  setShowToast,
  setResultMessage,
  setpendingMessage,
  setSavedEmails,
  setUnsavedEmails,
  setSaveLoading,
  setDoneAllocatingEmails,
  saveLoading,
  sortByDate,
  selectedArchivedItems,
  setSelectedArchivedItems,
  archivedEmails,
  setArchivedEmails,
  selectedTaskList,
  setSelectedTaskList,
  selectedTaskRegister,
  setSelectedTaskRegister,
  tasklistGmailIds,
  setTasklistGmailIds,
  unSavedForTaskListCtr,
  savedForTaskListCtr,
  displayImageAttachments,
  setDisplayImageAttachments,
  bindList,
  selectAllCheck,
  setSelectAllCheck,
  setEmailContentOpen,
  selectAllUnsaved,
  selectAllSaved,
  selectAllArchived,
  setMobileLoading,
  showDetailsCopy,
  setShowDetailsCopy,
  showCopy,
  searchGmail,
  setSearchGmail,
  isOutlookIntegrated,
  setPreviousUnsavedEmails,
  setPreviousSavedEmails,
  setPreviousArchivedEmails,
  setUnsavedEmailsCount,
  setSavedEmailsCount,
  setArchivedEmailsCount,
  taskRegisterList,
  emailFilters,
  handleExecuteFilter,
  hideAttachmentOnly,
  saveProgress,
  setSaveProgress,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // Only perform an action if displayImageAttachments is undefined.
      if (displayImageAttachments === undefined) {
        // by default, set the hide image to true
        setDisplayImageAttachments(true);
      }
    }, 0);

    // Cleanup function to clear the timeout
    return () => clearTimeout(timer);
  }, [displayImageAttachments]);

  useEffect(() => {
    //console.log("useEffectWidth:", window.innerWidth);
    width = window.innerWidth;
    // if (window.innerHeight > 1800) {
    //   setWidthMul(0.85);
    // } else {
    //   setWidthMul(0.90);
    // }
  }, [window.innerWidth]);

  // const [fileIds, setFileIds] = useState([]);

  // Storage.configure({
  //   region: config.aws_user_files_s3_bucket_region,
  //   bucket: config.aws_user_gmail_attachments_s3_bucket,
  //   identityPoolId: config.aws_user_pools_id,
  // });

  const companyId = localStorage.getItem("companyId");

  //const { height, width } = useWindowDimensions();
  // const [widthMul, setWidthMul] = useState(0.9);
  var width = window.innerWidth;

  const GMAIL_MESSAGE_SAVE = `mutation saveGmailMessage($companyId: ID, $id: ID, $isSaved: Boolean) {
    gmailMessageSave(companyId: $companyId, id: $id, isSaved: $isSaved) {
      id
    }
  }`;

  const OUTLOOK_MESSAGE_SAVE = `mutation saveOutlookMessage($companyId: ID, $id: ID, $isSaved: Boolean) {
    outlookMessageSave(companyId: $companyId, id: $id, isSaved: $isSaved) {
      id
    }
  }`;

  const GMAIL_MESSAGE_ARCHIVE = `mutation archiveGmailMessage($companyId: ID, $id: [ID]) {
    gmailMessageBulkSoftDelete(companyId: $companyId, id: $id) {
      id
    }
  }`;

  const OUTLOOK_MESSAGE_ARCHIVE = `mutation archiveOutlookMessage($companyId: ID, $id: [ID]) {
    outlookMessageBulkSoftDelete(companyId: $companyId, id: $id) {
      id
    }
  }`;

  const GMAIL_MESSAGE_RESTORE = `mutation bulkRestoreGmailMessage($companyId: ID, $id: [ID]) {
    gmailMessageBulkRestore(companyId: $companyId, id: $id) {
      id
    }
  }`;

  const OUTLOOK_MESSAGE_RESTORE = `mutation bulkRestoreOutlookMessage($companyId: ID, $id: [ID]) {
    outlookMessageBulkRestore(companyId: $companyId, id: $id) {
      id
    }
  }`;

  const CREATE_MATTERFILE_MUTATION = `mutation createMatterFile ($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int, $isGmailAttachment: Boolean, $isGmailPDF: Boolean, $gmailMessageId: String, $details: String, $date: AWSDateTime) {
    matterFileCreate(matterId: $matterId, s3ObjectKey: $s3ObjectKey, size: $size, type: $type, name: $name, order: $order,isGmailAttachment: $isGmailAttachment, isGmailPDF: $isGmailPDF, gmailMessageId: $gmailMessageId, details: $details, date: $date) {
        id
        name
        order
      }
    }`;

  const TASK_LIST_DATA_CREATE = `mutation taskListDataCreate($companyId: ID!, $input: TaskListDataInput!) {
    taskListDataCreate(companyId: $companyId, input: $input) {
      data
      id
      createdAt
    }
  }`;

  const TASK_LIST_DATA_BULKCREATE = `mutation taskListDataBulkCreate($companyId: ID!, $input: [TaskListDataInput!]!) {
    taskListDataBulkCreate(companyId: $companyId, input: $input) {
      data
      id
      createdAt
    }
  }`;

  const TASKLIST_DATA_COUNT = `query getTaskListsCount($companyId: ID!) {
    taskListDataCount(companyId: $companyId) {
      status
      count
    }
  }`;

  const TASK_LIST_DATA_UPDATE = `mutation taskListDataUpdate($id: ID!, $input: TaskListDataInput!) {
      taskListDataUpdate(id: $id, input: $input) {
        data
        id
        createdAt
      }
    }`;

  const FILE_LABEL_TAG = `mutation tagFileLabel($fileId: ID, $labels: [LabelInput]) {
      fileLabelTag(file: {id: $fileId}, label: $labels) {
        file {
          id
        }
      }
    }`;

  const MATTER_FILES_QUERY = `query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
    matterFiles(
      isDeleted: $isDeleted
      matterId: $matterId
      nextToken: $nextToken
      limit: $limit
      sortOrder: $sortOrder
    ) {
      items {
        id
        name
        details
        date
        s3ObjectKey
        gmailMessageId
        labels {
          items {
            id
            name
          }
        }
        backgrounds {
          items {
            id
            files {
              items {
                id
              }
            }
            order
            description
            date
            createdAt
            briefs {
              id
              name
            }
          }
        }
        createdAt
        order
        type
        size
      }
      nextToken
    }
  }`;

  const BRIEF_BY_NAME_QUERY = `query getBriefByName($clientMatterId: ID, $name: String) {
    briefByName(clientMatterId: $clientMatterId, name: $name) {
      id
      labelId
    }
  }`;

  const BRIEF_CREATE = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }`;

  const BRIEF_UPDATE = `mutation updateBrief($id: ID, $labelId: ID) {
    briefUpdate(id: $id, labelId: $labelId) {
      id
    }
  }`;

  const BACKGROUND_CREATE = `mutation createBackground($description: String,$comments:String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
    backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
      id
    }
  }`;

  const BACKGROUND_FILE_TAG = `mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileTag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }`;

  const BACKGROUND_TABLE_QUERY = `query getBackgroundTable($clientMatterId: ID!) {
    backgroundTable(clientMatterId: $clientMatterId) {
      id
      columns {
        id
        accessorKey
        headerText
        enabled
        type
        optionsText
        order
        presets {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }`;

  const mCreateActivity = `mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

  const procEmailListState = async (arrCheckEmails) => {
    try {
      console.log("procEmailListState()");
      // Colate Unsaved emails that has tasklist data
      const arrArchivedTasklist = arrCheckEmails?.filter(
        (item) => item.taskList?.isDeleted === true
      );
      // Add selected unsaved emails from unsavedEmails
      const arrByDates = sortByDate(savedEmails.concat(arrCheckEmails)) || [];
      // Remove selected saved emails from unSavedEmails
      const filteredEmails =
        unSavedEmails?.filter((item) => !arrCheckEmails.includes(item)) || [];

      console.log(arrCheckEmails, "<- Unsaved email to saved");
      console.log(filteredEmails, "<- Filtered Unsaved Emails");
      console.log(
        arrArchivedTasklist,
        "<- Saved email that Tasklist data is in archive and need to update"
      );

      console.log("check updated email list", filteredEmails);
      //window.location.reload();

      //Set Unsaved Emails
      setPreviousUnsavedEmails(filteredEmails);

      // Clear list of virtualized emails to be displayed
      setUnsavedEmails([]);

      // Display updated state and virtualized emails
      setUnsavedEmailsCount(
        (count) => count - (unSavedEmails.length - filteredEmails.length)
      );
      setUnsavedEmails(filteredEmails);
      //Set Saved Emails
      setSavedEmailsCount(
        (count) => count - (savedEmails.length - arrByDates.length)
      );
      setSavedEmails(arrByDates);
      setPreviousSavedEmails(arrByDates);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const isProccessingEmails = async (
    hasZero,
    arrCheckEmails,
    taskListNextUniqueId,
    status,
    filteredTasklistSaving
  ) => {
    if (!hasZero) {
      try {
        document.body.style.cursor = "wait";
        // Save selected unsaved emails to file bucket
        const selectedEmails = unSavedEmails?.filter((item) =>
          selectedUnsavedItems?.includes(item.id)
        );

        console.log(
          "Save selected unsaved emails to file bucket",
          selectedEmails
        );

        // Update the state without modifying it directly
        await procEmailListState(arrCheckEmails);

        console.log("initialize batchAPICalls()");
        const batchAPICalls = (apiCalls) => Promise.all(apiCalls);

        const handleUploadResults = [];

        console.log("initialize processEmail()");
        const processEmail = async (item, index) => {
          const { clientMatters: { items: clientMattersItems } = {} } = item;

          if (!clientMattersItems) {
            console.log("No client matters items available");
            return; // early exit if there are no client matter items
          }

          setInputTaskListArray([]); //empty it first to make sure previous will not be included
          // Map client matters items to promises directly without pushing to array
          const emailAPIPromises = clientMattersItems.map(
            (clientMatter, cmIndex) => {
              const {
                id: clientMatterId,
                client: { name: clientName },
                matter: { name: matterName },
              } = clientMatter;

              const tlUniqueId = taskListNextUniqueId + index + cmIndex + 1; // Assuming unique ID logic is correct

              return handleUploadGmailEmail(
                item.id,
                item.description,
                item.subject,
                item.from,
                item.to,
                item.cc,
                item.date,
                clientMatterId,
                item.labels,
                clientName,
                matterName,
                item.assignee,
                item.priority,
                item.notes,
                item.attachments,
                tlUniqueId,
                item,
                filteredTasklistSaving
              );
            }
          );

          // Batch email API calls for better performance
          console.log("Step 5: Batch email API calls for better performance");
          const emailResults = await batchAPICalls(emailAPIPromises);

          handleUploadResults.push(...emailResults);
        };

        // Assuming you have some mechanism to chunk the selectedEmails into manageable portions
        // (e.g., 50 emails at a time). You can adapt this based on your specific requirements.
        const chunkSize = 50;
        const emailChunks = [];

        for (let i = 0; i < selectedEmails.length; i += chunkSize) {
          emailChunks.push(selectedEmails.slice(i, i + chunkSize));
        }

        // Process email chunks in parallel
        console.log("Step 4: Process email chunks in parallel");
        await Promise.all(
          emailChunks?.map((chunk) => Promise.all(chunk?.map(processEmail)))
        );

        // Execute all handleUploadGmailEmail and FILE_LABEL_TAG API calls in parallel
        console.log("Step 6: Execute all handleUploadGmailEmail in parallel");
        await Promise.all(handleUploadResults);

        console.log("7: Bulk Save all the tasklistArrays");
        console.log("this will be saved");
        await bulkSaveToTaskList(companyId, inputTaskListArray);

        document.body.style.cursor = "default";
        setpendingMessage("Saving...");

        await Promise.all([
          selectedUnsavedItems?.map((obj) => {
            console.log("selectedUnsavedItems OBJECTS", obj);
            API.graphql({
              query: isOutlookIntegrated
                ? OUTLOOK_MESSAGE_SAVE
                : GMAIL_MESSAGE_SAVE,
              variables: {
                companyId: companyId,
                id: obj, // Assuming you only use the first element
                isSaved: status,
              },
            });
          }),
        ]);

        console.timeEnd("SaveEmail_Execution_Time");
        setResultMessage("Successfully saved an email!");
        setSaveProgress(true);
        setEmailContentOpen(false);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          setSelectedUnsavedItems([]);
          setSaveLoading(false);
          setDoneAllocatingEmails(false);
        }, 1000);
      } catch (error) {
        console.error("Error:", error);
        setSaveLoading(false);
        setDoneAllocatingEmails(false);
        document.body.style.cursor = "default";
        alert(
          "An error occurred while saving the email. Please try again later."
        );
      }
    } else {
      setSaveLoading(false);
      setDoneAllocatingEmails(false);
      setSelectAllCheck(true);
      document.body.style.cursor = "default";
      alert(
        "Client/Matter is required. You have selected an email that has no Client/Matter."
      );
    }
  };

  const procUnsavingEmails = async (status) => {
    document.body.style.cursor = "wait";
    setpendingMessage("Unsaving...");

    const handleUploadResults = selectedSavedItems?.map((obj) =>
      API.graphql({
        query: isOutlookIntegrated ? OUTLOOK_MESSAGE_SAVE : GMAIL_MESSAGE_SAVE,
        variables: {
          companyId: companyId,
          id: obj,
          isSaved: status,
        },
      })
    );

    await Promise.all(handleUploadResults);

    setResultMessage("Successfully unsaved an email.");
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 1000);
    setSelectedUnsavedItems([]);
    setSelectedSavedItems([]);

    // Add selected saved emails to unsaved emails
    const arrSavedEmails = savedEmails?.filter((item) =>
      selectedSavedItems?.includes(item.id)
    );

    const arrByDates = sortByDate(unSavedEmails.concat(arrSavedEmails)) || [];
    setUnsavedEmailsCount(
      (count) => count - (unSavedEmails.length - arrByDates.length)
    );
    setUnsavedEmails(arrByDates);
    setPreviousUnsavedEmails(arrByDates);

    // Remove selected saved emails from saved emails
    const arrRemoveUnSavedEmails = savedEmails?.filter(
      (item) => !selectedSavedItems?.includes(item.id)
    );

    // Remove Tasklist
    // for (let item of arrSavedEmails) {
    //   if (item.taskList.isDeleted !== true) {
    //     await API.graphql({
    //       query: TASK_LIST_DATA_UPDATE,
    //       variables: {
    //         id: item.taskList.id,
    //         input: {
    //           isDeleted: true,
    //         },
    //       },
    //     }).then((result) => {
    //       console.log(result);
    //     });
    //   }
    // }
    const updatePromises = arrSavedEmails?.map((item) => {
      if (item.taskList.isDeleted !== true) {
        return API.graphql({
          query: TASK_LIST_DATA_UPDATE,
          variables: {
            id: item.taskList.id,
            input: {
              isDeleted: true,
            },
          },
        }).then((result) => {
          console.log(result);
        });
      }
    });

    await Promise.all(updatePromises);

    let sortByDateSaved = sortByDate(arrRemoveUnSavedEmails) || [];
    setSavedEmailsCount(
      (count) => count - (savedEmails.length - sortByDateSaved.length)
    );
    setSavedEmails(sortByDateSaved);
    setPreviousSavedEmails(arrRemoveUnSavedEmails);
    setSaveLoading(false);
    setDoneAllocatingEmails(false);
    document.body.style.cursor = "default";
  };

  //call tasklist items
  const [tasklistItems, setTasklistItems] = useState(null);
  const [inputTaskListArray, setInputTaskListArray] = useState([]);

  useEffect(() => {
    if (tasklistItems === null) {
      getTasklistItems();
    }
  }, [tasklistItems]);

  const TASK_LIST_DATA_QUERY = `
  query taskListData($companyId: ID!, $limit: Int, $nextToken: String) {
    taskListData(companyId: $companyId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        clientMatterId
        gmailMessageId
        createdAt
        isDeleted
        updatedAt
      }
      nextToken
    }
  }
`;

  const getTasklistItems = async () => {
    const result = await API.graphql({
      query: TASK_LIST_DATA_QUERY,
      variables: {
        companyId,
        isDeleted: false,
      },
    });

    console.log("TASKLIST LIST", result?.data?.taskListData?.items);

    setTasklistItems(result?.data?.taskListData?.items);
  };

  const handleUploadGmailEmail = async (
    gmailMessageId,
    description,
    fileName,
    from,
    to,
    cc,
    dateEmail,
    matterId,
    labels,
    clientName,
    matterName,
    assignee,
    priority,
    notes,
    attachments,
    taskListNextUniqueId,
    item,
    filteredTasklistSaving
  ) => {
    // AQS - MMA-1255
    console.log("handleUploadGmailEmail() Start");
    // Add the Gmail email to a file bucket
    const params = {
      query: CREATE_MATTERFILE_MUTATION,
      variables: {
        matterId: matterId,
        s3ObjectKey: null,
        size: 0,
        name: fileName,
        type: null,
        order: 0,
        isGmailPDF: true,
        isGmailAttachment: true,
        gmailMessageId: gmailMessageId,
        details: description,
        date: new Date(dateEmail).toISOString(),
      },
    };

    console.log("params", params);

    // Perform a GraphQL query to create a matter file
    const result = await API.graphql(params);
    if (!result.data.matterFileCreate) {
      throw new Error("MatterFile creation failed");
    }

    const matterFileId = result.data.matterFileCreate.id;

    // Check if labels exist and there are items to map over
    if (labels && Array.isArray(labels.items) && labels.items.length > 0) {
      const backgroundTasksPromises = labels.items.map((label) =>
        createBackgroundFromLabel(
          matterId,
          matterFileId,
          description,
          dateEmail,
          { id: label.id, name: label.name },
          false,
          attachments,
          gmailMessageId,
          dateEmail
        )
      );

      // Create background tasks from labels associated with the email
      await Promise.all(backgroundTasksPromises);
    } else {
      console.log("No labels to process.");
    }

    // Create a clientMatterName by combining clientName and matterName
    const clientMatterName = `${clientName}/${matterName}`;

    // // Check if the email is selected in the task list
    // selectedTaskList.filter(
    //   (value, index) => selectedTaskList.indexOf(value) === index
    // ); //check for duplicate gmails

    if (
      filteredTasklistSaving?.includes(gmailMessageId) ||
      selectedTaskRegister?.find((register) => register.id === gmailMessageId)
    ) {
      const taskRegisterSelection = selectedTaskRegister?.find(
        (register) => register.id === gmailMessageId
      );
      let registers = [];
      if (taskRegisterSelection) {
        registers = taskRegisterSelection.registers;
      }
      let createdBy = taskListColumns.find(
        (col) => col.headerText === "Task Created By"
      );
      // Define the input data for creating or updating a task list
      const varInput = {
        isDeleted: false,
        dueDate: new Date(),
        priority: 1,
        uniqueId: taskListNextUniqueId,
        clientMatterId: matterId,
        gmailMessageId: gmailMessageId,
        data: JSON.stringify({
          0: clientMatterName,
          1: fileName,
          2: from,
          3: dateEmail,
          4: result.data.matterFileCreate.id,
          6: notes,
          7: "Outstanding",
          8: priority ? priority : 1,
          9: assignee ? assignee : "",
          10: new Date(),
          11: new Date(),
          registers: registers,
          ...(createdBy && createdBy.id
            ? { [createdBy.id]: localStorage.getItem("userId") }
            : {}),
        }),
      };

      // Log the input data for debugging
      // console.log("varInput", varInput);

      // Log item information for debugging
      console.log("checkk3rd", item);

      var createTasklistEntry = true;

      tasklistItems?.map((x) =>
        x.gmailMessageId === gmailMessageId ? (createTasklistEntry = false) : x
      );

      if (createTasklistEntry === true) {
        console.log(
          varInput,
          "<- Input Varaible Tasklist to add in BulkSaving"
        );
        inputTaskListArray.push(varInput);
      } else {
        console.log(
          varInput,
          "<- Input Varaible Tasklist Update to add in BulkUpdate"
        );
        const taskListResult = await API.graphql({
          query: TASK_LIST_DATA_UPDATE,
          variables: {
            id: item.taskList.id,
            input: varInput,
          },
        });
        console.log("TASKLIST UPDATED", taskListResult);
        //MMA-1441 Add Activity for Task Creator
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: companyId,
            briefId: null,
            rowId: taskListResult.data.taskListDataUpdate.id,
            activity: `Created a task`,
            field: "Create a task",
            current: null,
            previous: null,
            appModule: "TASKS",
            userId: localStorage.getItem("userId"),
          },
        };
        await API.graphql(params);
      }

      // MMA 1983 - GBS: Remove the proccessed tasklist in local storage & selectedTaskList after saving the tasklist
      const modifiedNotesTaskList = Array.isArray(
        JSON.parse(localStorage.getItem("modifiedTaskNotes"))
      )
        ? JSON.parse(localStorage.getItem("modifiedTaskNotes"))
        : [];

      localStorage.setItem(
        "modifiedTaskNotes",
        JSON.stringify(
          modifiedNotesTaskList?.filter((item) => item !== gmailMessageId)
        )
      );

      setSelectedTaskList(
        modifiedNotesTaskList?.filter((item) => item !== gmailMessageId)
      );

      // setSelectedTaskList([]);

      console.log("should be removed", selectedTaskList);

      // Assign taskList.id to selected emails
      var tempVar = unSavedEmails;
      tempVar.forEach((email) => {
        if (
          selectedTaskList?.includes(email.id) ||
          selectedTaskRegister?.includes(email.id)
        ) {
          email.taskList.id = email.id;
        }
      });
      //setUnsavedEmails(tempVar);
      // Change arrangement of codes
    }

    // Associate labels with the created matter file
    await API.graphql({
      query: FILE_LABEL_TAG,
      variables: {
        fileId: result.data.matterFileCreate.id,
        labels: labels.items,
      },
    });
  };

  //add to tasklist button only for saved
  const handleAddToTaskListSaved = async (
    gmailMessageId,
    notes,
    fileName,
    from,
    to,
    cc,
    dateEmail,
    matterId,
    labels,
    clientName,
    matterName,
    assignees,
    registers,
    taskListNextUniqueId
  ) => {
    setSelectAllCheck(false);
    console.log("matteridd", matterId);
    //recover files in files bucket
    const params = {
      query: MATTER_FILES_QUERY,
      variables: {
        matterId: matterId,
        isDeleted: false,
        limit: 1500,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    };

    var filebucketId = "";
    await API.graphql(params).then((files) => {
      let matterFilesList = files.data.matterFiles.items;
      console.log("matterFilesList:", matterFilesList);
      matterFilesList?.map((x) =>
        x.gmailMessageId === gmailMessageId ? (filebucketId = x.id) : null
      );
      console.log("filebucketid", filebucketId);
    });

    // AQS - MMA-1255
    // Save to TaskList table

    var clientMatterId = "";
    var clientMatterName = clientName + "/" + matterName;

    selectedSavedItems
      ?.filter((task) => task === gmailMessageId)
      ?.map((obj) => {
        const filteredEmailsArr = savedEmails?.filter(
          (item) => item.id === obj
        );
        const defaultRegister = taskRegisterList?.filter(
          (register) =>
            register?.label?.toLowerCase().replace(/ /g, "") === "tasklist"
        );
        filteredEmailsArr?.map(async (item) => {
          item.clientMatters.items?.map((clientMatters) => {
            clientMatterId = clientMatters.id;
          });

          let createdBy = taskListColumns.find(
            (col) => col.headerText === "Task Created By"
          );

          await API.graphql({
            query: TASK_LIST_DATA_CREATE,
            variables: {
              companyId,
              input: {
                isDeleted: false,
                dueDate: new Date(),
                priority: 1,
                uniqueId: taskListNextUniqueId,
                clientMatterId: clientMatterId,
                gmailMessageId: gmailMessageId,
                data: JSON.stringify({
                  0: clientMatterName,
                  1: fileName,
                  2: from,
                  3: dateEmail,
                  4: filebucketId,
                  6: notes,
                  7: "Outstanding",
                  8: 1,
                  ...(assignees && assignees !== null && assignees.length !== 0
                    ? { 9: assignees }
                    : {}),
                  10: new Date(),
                  11: new Date(),
                  registers:
                    registers.length === 0 ? defaultRegister : registers,
                  ...(createdBy && createdBy.id
                    ? { [createdBy.id]: localStorage.getItem("userId") }
                    : {}),
                }),
              },
            },
          }).then(async (result) => {
            const params = {
              query: ACTIVITY_CREATE,
              variables: {
                companyId: companyId,
                briefId: null,
                clientmatterId: clientMatterId,
                rowId: result.data.taskListDataCreate.id,
                activity: `Saved Task from Email`,
                field: "Saved Task",
                current: null,
                previous: null,
                appModule: "TASKS",
                userId: localStorage.getItem("userId"),
              },
            };
            const addActivity = await API.graphql(params).then((result) => {
              console.log("addActivity result", result, "Saved Task");
            });

            console.log(result);
            setSelectedTaskList([]);
            setSelectedSavedItems([]);
          });
        });
      });
  };

  //Triggered when "Save Emails" for UNSAVED is clicked -KJMF
  //First function for saving unsaved emails
  async function savingEmails(status) {
    console.log("For tasklist", selectedTaskList); //ID of selected emails for tasklist
    console.log("selected emails", selectedUnsavedItems); //ID of selected emails to be saved

    var filteredTasklistSaving = [];

    //Filter selected for tasklist array to remove duplicates
    selectedTaskList.map((x) =>
      selectedUnsavedItems.map((y) =>
        y === x ? (filteredTasklistSaving = [...filteredTasklistSaving, y]) : y
      )
    );

    console.log("filteredTasklistSaving", filteredTasklistSaving); //filtered selected for tasklist

    //Unselect all checkbox
    setSelectAllCheck(false);
    //trigger loading
    setSaveLoading(true);
    //clear archived list
    setSelectedArchivedItems([]);
    //loading cursor
    document.body.style.cursor = "wait";
    //SAVING PROPER STARTS HERE
    handleEmailsProper(status, filteredTasklistSaving);
    //Activity Log for saving emails
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: companyId,
        activity:
          selectedUnsavedItems.length === 1
            ? "Saved an email"
            : `Saved ${selectedUnsavedItems.length} emails`,
        appModule: "INBOX",
      },
    };

    await API.graphql(params).then((result) =>
      console.log("Add activity result", result, selectedUnsavedItems.length)
    );
  }

  //Second function for saving unsaved emails
  const handleEmailsProper = async (status, filteredTasklistSaving) => {
    if (status) {
      console.log("Check2", filteredTasklistSaving, selectedUnsavedItems);
      await procSavingEmails(status, filteredTasklistSaving);
    } else {
      console.log("Check2", filteredTasklistSaving, selectedUnsavedItems);
      await procUnsavingEmails(status, filteredTasklistSaving);
    }
    setSaveProgress(true);
  };

  //Third function for saving unsaved emails
  const procSavingEmails = async (status, filteredTasklistSaving) => {
    //get tasklist count
    const tlDataCount = (
      await API.graphql({
        query: TASKLIST_DATA_COUNT,
        variables: {
          companyId,
        },
      })
    )?.data?.taskListDataCount?.find((obj) => obj.status === "total");
    const taskListNextUniqueId = parseInt(tlDataCount?.count || "0", 10);

    //tile data for selected unsaved emails
    let arrCheckEmails = [];

    unSavedEmails.forEach((item) => {
      if (
        selectedUnsavedItems.includes(item.id) &&
        !arrCheckEmails.some((tempItem) => tempItem.id == item.id)
      ) {
        arrCheckEmails[arrCheckEmails.length] = item;
      }
    });

    console.log("FINAL ARRAY IS:", arrCheckEmails);

    //check if there is client matter allocated
    const hasZero = arrCheckEmails.some(
      (item) => item?.clientMatters?.items?.length === 0
    );

    //process emails
    await isProccessingEmails(
      hasZero,
      arrCheckEmails,
      taskListNextUniqueId,
      status,
      filteredTasklistSaving
    );

    if (unSavedEmails.length === 0) {
      setSearchGmail("");
      fetchData("clear");
    }
  };

  const createBackgroundFromLabel = async (
    matter_id,
    row_id,
    attachDetails,
    attachDate,
    label,
    isNew,
    attachments,
    gmailMessageId,
    dateEmail
  ) => {
    // check if brief already exists
    let briefNameExists = false;
    const getBriefByName = await API.graphql({
      query: BRIEF_BY_NAME_QUERY,
      variables: {
        clientMatterId: matter_id,
        name: label.name,
      },
    });

    let briefId = getBriefByName.data.briefByName.id,
      existingBriefNameLabel = getBriefByName.data.briefByName.labelId;

    if (briefId !== "" && briefId !== null) {
      briefNameExists = true;
    }

    if (isNew) {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
          columnIds: [0, 1, 2, 3, 4],
        };

        const createBrief = await API.graphql({
          query: BRIEF_CREATE,
          variables: params,
        });

        briefId = createBrief.data.briefCreate.id;

        const backgroundTable = API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns?.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj.presets?.filter((preset) => preset.id !== briefId),
                { id: briefId, name: label.name },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });
        }
      } else {
        if (existingBriefNameLabel === null) {
          const params = {
            id: briefId,
            labelId: label.id,
          };

          await API.graphql({
            query: BRIEF_UPDATE,
            variables: params,
          });
        }
      }

      const fileId = row_id,
        fileDetails = attachDetails,
        fileDate =
          attachDate != null
            ? moment
                .utc(moment(new Date(attachDate), "YYYY-MM-DD"))
                .toISOString()
            : null;

      const tempBriefDetails = [{ id: briefId, name: label.name }];

      // Create Background
      const createBackground = await API.graphql({
        query: BACKGROUND_CREATE,
        variables: {
          briefs: tempBriefDetails,
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      if (createBackground.data.backgroundCreate.id !== null) {
        // Tag File to Background
        await API.graphql({
          query: BACKGROUND_FILE_TAG,
          variables: {
            backgroundId: createBackground.data.backgroundCreate.id,
            files: [{ id: fileId }],
          },
        });

        // Adding Attachments to File Bucket
        attachments.items?.map(async (attachment, index) => {
          if (attachment.isDeleted !== true) {
            await API.graphql({
              query: CREATE_MATTERFILE_MUTATION,
              variables: {
                matterId: matter_id,
                s3ObjectKey: attachment.s3ObjectKey,
                size: attachment.size,
                name: attachment.name,
                type: attachment.type,
                order: index + 1,
                isGmailAttachment: true,
                isGmailPDF: false,
                gmailMessageId: gmailMessageId,
                details: attachment.details,
                date: new Date(dateEmail).toISOString(),
              },
            }).then(async (result) => {
              await API.graphql({
                query: BACKGROUND_FILE_TAG,
                variables: {
                  backgroundId: createBackground.data.backgroundCreate.id,
                  files: [{ id: result.data.matterFileCreate.id }],
                },
              });

              await API.graphql({
                query: FILE_LABEL_TAG,
                variables: {
                  fileId: result.data.matterFileCreate.id,
                  labels: attachment.labels.items,
                },
              });
            });
          }
        });
      }
    } else {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
        };

        // Create Brief
        const createBrief = await API.graphql({
          query: BRIEF_CREATE,
          variables: params,
        });

        briefId = createBrief.data.briefCreate.id;

        const backgroundTable = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns?.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj.presets?.filter((preset) => preset.id !== briefId),
                { id: briefId, name: label.name },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });
        }
      } else {
        if (existingBriefNameLabel === null) {
          // Tag Label to Brief
          await API.graphql({
            query: BRIEF_UPDATE,
            variables: {
              id: briefId,
              labelId: label.id,
            },
          });
        }
      }

      const fileId = row_id,
        fileDetails = attachDetails,
        fileDate =
          attachDate != null
            ? moment
                .utc(moment(new Date(attachDate), "YYYY-MM-DD"))
                .toISOString()
            : null;

      // Create Background
      const tempBriefDetails = [{ id: briefId, name: label.name }];

      // Create Background
      const createBackground = await API.graphql({
        query: BACKGROUND_CREATE,
        variables: {
          briefs: tempBriefDetails,
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      if (createBackground.data.backgroundCreate.id !== null) {
        let attachmentId = "";
        let newId = [];
        const listAttachmentIds = attachments.items?.map(
          async (attachment, index) => {
            if (attachment.isDeleted !== true) {
              let ids = await API.graphql({
                query: CREATE_MATTERFILE_MUTATION,
                variables: {
                  matterId: matter_id,
                  s3ObjectKey: attachment.s3ObjectKey,
                  size: attachment.size,
                  name: attachment.name,
                  type: attachment.type,
                  order: index + 1,
                  isGmailAttachment: true,
                  isGmailPDF: false,
                  gmailMessageId: gmailMessageId,
                  details: attachment.details,
                  date: new Date(dateEmail).toISOString(),
                },
              }).then(async (result) => {
                await API.graphql({
                  query: FILE_LABEL_TAG,
                  variables: {
                    fileId: result.data.matterFileCreate.id,
                    labels: attachment.labels.items,
                  },
                });

                attachmentId = result.data.matterFileCreate.id;
                newId = { id: attachmentId };

                return newId;
              });
              return ids;
            }
          }
        );

        Promise.all(listAttachmentIds)
          .then(async (results) => {
            const filteredResults = results?.filter((value) => {
              return value !== undefined;
            });

            await API.graphql({
              query: BACKGROUND_FILE_TAG,
              variables: {
                backgroundId: createBackground.data.backgroundCreate.id,
                files: filteredResults,
              },
            }).then((res) => {
              console.log("Attachment saved to backgrounds: ", res);
            });
          })
          .catch((error) => {
            console.error("Error saving attachments: ", error);
          });
      }
    }
  };

  const handleArchive = async () => {
    if (isMobileDevice(width)) handleScrollIndex(selectedEmail);
    setSelectAllCheck(false);
    setSaveLoading(true);
    setDoneAllocatingEmails(true);
    document.body.style.cursor = "wait";
    let arrArchivedEmails = unSavedEmails?.filter(function (item) {
      return selectedUnsavedItems.indexOf(item.id) !== -1;
    });
    var arrByDates = sortByDate(archivedEmails.concat(arrArchivedEmails));
    setArchivedEmailsCount(
      (count) => count - (archivedEmails.length - arrByDates.length)
    );
    setArchivedEmails(arrByDates);
    setPreviousArchivedEmails(arrByDates);

    // Remove from Unsaved Emails
    let arrRemoveUnSavedEmails = unSavedEmails?.filter(function (item) {
      return selectedUnsavedItems.indexOf(item.id) === -1;
    });

    let sortByDateUnsaved = sortByDate(arrRemoveUnSavedEmails) || [];
    setPreviousUnsavedEmails(sortByDateUnsaved);
    setUnsavedEmailsCount(
      (count) => count - (unSavedEmails.length - sortByDateUnsaved.length)
    );
    setUnsavedEmails(sortByDateUnsaved);

    await Promise.all([
      selectedUnsavedItems?.map((obj) => {
        API.graphql({
          query: isOutlookIntegrated
            ? OUTLOOK_MESSAGE_ARCHIVE
            : GMAIL_MESSAGE_ARCHIVE,
          variables: {
            companyId: companyId,
            id: obj,
          },
        }).then((result) => {
          console.log("GETNOW", result);
        });
      }),
    ]).then(() => {
      setSaveLoading(false);
      setDoneAllocatingEmails(false);
      document.body.style.cursor = "default";
      setResultMessage("Successfully archived an email.");
      setShowToast(true);
      setSelectedArchivedItems([]);
    });

    if (selectedUnsavedItems.length === 1) {
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: companyId,
          activity: `Archived an email`,
          appModule: "INBOX",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log("add activity result", result, "Archived an email")
      );
    } else {
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: companyId,
          activity: `Archived ${selectedUnsavedItems.length} emails`,
          appModule: "INBOX",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log(
          "add activity result",
          result,
          `Archived ${selectedUnsavedItems.length} emails`
        )
      );
    }

    setSelectedUnsavedItems([]);
  };

  const handleRetrieveEmails = async () => {
    setSelectAllCheck(false);
    console.log(selectedArchivedItems);
    setSaveLoading(true);
    setDoneAllocatingEmails(true);
    document.body.style.cursor = "wait";

    // Add to unsaved Emails
    const arrArchivedEmails = archivedEmails?.filter(
      (item) => selectedArchivedItems.indexOf(item.id) !== -1
    );
    const arrByDates =
      sortByDate(unSavedEmails.concat(arrArchivedEmails)) || [];
    setPreviousUnsavedEmails(arrByDates);
    setUnsavedEmailsCount(
      (count) => count - (unSavedEmails.length - arrByDates.length)
    );
    setUnsavedEmails(arrByDates);

    // Remove from archived Emails
    const arrRemoveUnSavedEmails = archivedEmails?.filter(
      (item) => selectedArchivedItems.indexOf(item.id) === -1
    );
    let sortByDateArchived = sortByDate(arrRemoveUnSavedEmails);
    setArchivedEmailsCount(
      (count) => count - (archivedEmails.length - sortByDateArchived.length)
    );
    setArchivedEmails(sortByDateArchived);

    setPreviousArchivedEmails(sortByDate(arrRemoveUnSavedEmails));
    try {
      await Promise.all(
        selectedArchivedItems?.map(async (obj) => {
          const result = await API.graphql({
            query: isOutlookIntegrated
              ? OUTLOOK_MESSAGE_RESTORE
              : GMAIL_MESSAGE_RESTORE,
            variables: {
              companyId: companyId,
              id: obj,
            },
          });
        })
      );

      setSaveLoading(false);
      setDoneAllocatingEmails(false);
      document.body.style.cursor = "default";

      if (selectedArchivedItems.length === 1) {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: companyId,
            activity: "Retrieve an email",
            appModule: "INBOX",
          },
        };
        const addActivity = await API.graphql(params);
        console.log("Add activity result", addActivity, "Retrieve an email");
      } else if (selectedArchivedItems.length > 1) {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: companyId,
            activity: "Retrieve multiple email",
            appModule: "INBOX",
          },
        };
        const addActivity = await API.graphql(params);
        console.log(
          "Add activity result",
          addActivity,
          "Retrieve multiple email"
        );
      }

      setSelectedArchivedItems([]);
      // Clear the selected items
    } catch (error) {
      console.error("Error:", error);
    }

    // selectedArchivedItems?.map(async (obj) => {
    //   await API.graphql({
    //     query: isOutlookIntegrated ? OUTLOOK_MESSAGE_RESTORE : GMAIL_MESSAGE_RESTORE,
    //     variables: {
    //       companyId: companyId,
    //       id: obj,
    //     },
    //   }).then((result) => {
    //     setSaveLoading(false);
    //     setDoneAllocatingEmails(false);
    //     document.body.style.cursor = "default";
    //     setResultMessage("Successfully restored an email.");
    //     setShowToast(true);
    //     setSelectedArchivedItems([]);
    //     // Add to unsaved Emails
    //     let arrArchivedEmails = archivedEmails?.filter(function (item) {
    //       return selectedArchivedItems.indexOf(item.id) !== -1;
    //     });
    //     var arrByDates = sortByDate(unSavedEmails.concat(arrArchivedEmails));
    //     setUnsavedEmails(arrByDates);

    //     // Remove from archived Emails
    //     let arrRemoveUnSavedEmails = archivedEmails?.filter(function (item) {
    //       return selectedArchivedItems.indexOf(item.id) === -1;
    //     });
    //     setArchivedEmails(sortByDate(arrRemoveUnSavedEmails));
    //   });

    //   if(selectedArchivedItems.length === 1) {
    //     const params = {
    //       query: mCreateActivity,
    //       variables: {
    //         companyId: companyId,
    //         activity: `Retrieve an email`,
    //         appModule: "INBOX",
    //       },
    //     };
    //     const addActivity = await API.graphql(params).then((result) =>
    //       console.log("Add activity result", result, "Retrieve an email")
    //     );
    //   } else if (selectedArchivedItems.length > 1) {
    //     const params = {
    //       query: mCreateActivity,
    //       variables: {
    //         companyId: companyId,
    //         activity: `Retrieve multiple email`,
    //         appModule: "INBOX",
    //       },
    //     };
    //     const addActivity = await API.graphql(params).then((result) =>
    //       console.log("Add activity result", result, "Retrieve multiple email" )
    //     );
    //   }

    // });
  };

  const handleTasklist = async () => {
    console.group("handleTasklist()");
    setSaveLoading(true);
    setDoneAllocatingEmails(true);
    document.body.style.cursor = "wait";
    console.log("savedEmailss", savedEmails);

    const tl = await API.graphql({
      query: TASKLIST_DATA_COUNT,
      variables: {
        companyId,
      },
    });

    const tlDataCount = tl?.data?.taskListDataCount?.find(
      (obj) => obj.status === "total"
    );

    const taskListNextUniqueId = tlDataCount?.count
      ? parseInt(tlDataCount?.count, 10)
      : null;

    let tasklistIds = savedEmails?.filter(function (item) {
      return selectedSavedItems.indexOf(item.id) !== -1 && !item.taskList.id;
    });

    console.log("checkkk", tasklistIds);
    var tempid = [];

    tasklistIds?.map((x) => {
      tempid = [...tempid, x.id];
    });

    var clientMatterId = "";
    var clientName = "";
    var matterName = "";

    for (var i = 0; i < savedEmails.length; i++) {
      for (var j = 0; j < tempid.length; j++) {
        if (savedEmails[i].id === tempid[j]) {
          savedEmails[i].taskList.id = tempid[j];
        }
      }
    }

    tasklistIds?.map((item, index) => {
      item.clientMatters.items?.map((clientMatters) => {
        clientMatterId = clientMatters.id;
        clientName = clientMatters.client.name;
        matterName = clientMatters.matter.name;
      });

      var loopCnt = index + 1;
      var tlUniqueId = taskListNextUniqueId + loopCnt;

      console.log("handleAddToTaskListSaved()");
      handleAddToTaskListSaved(
        item.id,
        item.notes,
        item.subject,
        item.from,
        item.to,
        item.cc,
        item.date,
        clientMatterId,
        item.labels,
        clientName,
        matterName,
        item.assignee,
        item.registers,
        tlUniqueId
      );
    });

    //setSelectedTaskList([]);
    setSelectedSavedItems([]);

    setResultMessage("Added to Taskslist.");
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
      setSaveLoading(false);
      setDoneAllocatingEmails(false);
      document.body.style.cursor = "default";
    }, 2000);

    setSelectAllCheck(false);
  };

  const bulkSaveToTaskList = async (companyId, tasklistInput) => {
    console.log(companyId, tasklistInput, "<- Bulk tasklist data");
    let userId = localStorage.getItem("userId");
    try {
      const { data } = await API.graphql({
        query: TASK_LIST_DATA_BULKCREATE,
        variables: {
          companyId,
          input: tasklistInput,
        },
      });

      let tasks = data.taskListDataBulkCreate.map((task) => task);
      let tasksWithCM = tasks.map((task) => {
        let temp = tasklistInput.find((input) => input.data === task.data);
        return {
          id: task.id,
          ...(temp ?? { clientMatterId: temp?.clientMatterId }),
        };
      });

      console.log("Creating activity for created tasks", tasksWithCM);
      await Promise.all(
        tasksWithCM.map(async (task) => {
          const params = {
            query: ACTIVITY_CREATE,
            variables: {
              companyId: companyId,
              briefId: null,
              clientmatterId: task.clientMatterId,
              rowId: task.id,
              activity: `Saved Task from Email`,
              field: "Saved Task",
              current: null,
              previous: null,
              appModule: "TASKS",
              userId: userId,
            },
          };

          await API.graphql(params).then((result) => {
            console.log("addActivity result", result, "Saved Task");
          });
        })
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  if (hideAttachmentOnly)
    return (
      <div style={{ display: "flex" }}>
        <Switch
          checked={displayImageAttachments}
          onChange={() => setDisplayImageAttachments(!displayImageAttachments)}
          className={`${
            displayImageAttachments ? "cursor-pointer bg-black" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Hide Images</span>
          <span
            className={`${
              displayImageAttachments ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
        &nbsp; Hide Images
      </div>
    );

  return (
    <>
      {width > 844 ? (
        <>
          {/* <div
            className="mt-2"
            style={
              window.innerWidth < 1290 || window.innerHeight > 1800
                ? {
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "flex-end",
                    width: (width * 0.89) + "px",
                  }
                : {
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "flex-end",
                    width: (width * 0.92 - 80) + "px",
                  }
            }
          >
            <div style={{ display: "flex" }}>
              <Switch
                checked={displayImageAttachments}
                onChange={() =>
                  setDisplayImageAttachments(!displayImageAttachments)
                }
                className={`${
                  displayImageAttachments
                    ? "cursor-pointer bg-black"
                    : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Hide Image attachments</span>
                <span
                  className={`${
                    displayImageAttachments ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              &nbsp; Hide Image attachments
            </div>
          </div> */}

          <div
            className="grid grid-rows grid-flow-col z-50 pt-3"
            // style={{ position: "sticky", top: "0px" }}
          >
            <div className="flex flex-col gap-y-4 w-full">
              {/* {selectedUnsavedItems.length !== 0 ||
                selectedArchivedItems.length !== 0 ||
                selectedSavedItems.length !== 0 ? (
                  <input
                    type="checkbox"
                    onClick={
                      openTab === 1
                        ? (e) => selectAllUnsaved(e, true)
                        : openTab === 2
                        ? (e) => selectAllSaved(e)
                        : (e) => selectAllArchived(e)
                    }
                    checked={selectAllCheck ? true : false}
                    className="mr-3"
                  />
                ) : null} */}
              {(selectedUnsavedItems.length !== 0 && openTab === 1) ||
              showDetailsCopy ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      if (
                        showDetailsCopy &&
                        selectedUnsavedItems.length === 0
                      ) {
                        alert(
                          "Email is required, You have to select an email."
                        );
                      } else {
                        savingEmails(true);
                        setShowDetailsCopy(false);
                      }
                    }}
                    className={
                      "text-sm font-medium flex items-center justify-center w-full bg-green-400 hover:bg-green-500 text-white py-3 px-4 rounded border-0 shadow outline-none focus:outline-none focus:ring" +
                      (saveLoading
                        ? " disabled:opacity-25 cursor-wait"
                        : " cursor-default")
                    }
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Saving Emails..." : "Save Emails"}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (
                        showDetailsCopy &&
                        selectedUnsavedItems.length === 0
                      ) {
                        alert(
                          "Email is required, You have to select an email."
                        );
                      } else {
                        handleArchive();
                        setShowDetailsCopy(false);
                      }
                    }}
                    className={
                      "text-sm font-medium flex items-center justify-center w-full bg-red-400 hover:bg-red-500 text-white py-3 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring " +
                      (saveLoading
                        ? " disabled:opacity-25 cursor-wait"
                        : " cursor-default")
                    }
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Archiving Emails" : "Archive Emails"}
                  </button>
                </>
              ) : (
                <></>
              )}
              {selectedSavedItems.length !== 0 && openTab === 2 ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      // handleEmails(false);
                      savingEmails(false);
                    }}
                    // className={
                    //   saveLoading
                    //     ? " text-sm py-1 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring disabled:opacity-25 cursor-wait"
                    //     : "bg-green-400 hover:bg-green-500 text-white text-sm py-1 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring cursor-default"
                    // }
                    className={
                      "text-sm font-medium flex items-center justify-center w-full bg-green-400 hover:bg-green-500 text-white py-3 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring " +
                      (saveLoading
                        ? " disabled:opacity-25 cursor-wait"
                        : " cursor-default")
                    }
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Unsaving Emails..." : "UnSave Emails"}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleTasklist();
                    }}
                    // className={
                    //   saveLoading
                    //     ? "bg-blue-400 hover:bg-blue-500 text-white text-sm py-1 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring mx-4 disabled:opacity-25 cursor-wait"
                    //     : "bg-blue-400 hover:bg-blue-500 text-white text-sm py-1 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring mx-4 cursor-default"
                    // }
                    className={
                      "text-sm font-medium flex items-center justify-center w-full bg-blue-400 hover:bg-blue-500 text-white py-3 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring " +
                      (saveLoading
                        ? " disabled:opacity-25 cursor-wait"
                        : " cursor-default")
                    }
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Save To Tasklist" : "Save To Tasklist"}
                  </button>
                </>
              ) : (
                <></>
              )}

              {selectedArchivedItems.length !== 0 && openTab === 3 ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      handleRetrieveEmails(false);
                    }}
                    className={
                      "text-sm font-medium flex items-center justify-center w-full bg-green-400 hover:bg-green-500 text-white py-3 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring " +
                      (saveLoading
                        ? " disabled:opacity-25 cursor-wait"
                        : " cursor-default")
                    }
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Retrieving Emails..." : "Retrieve Emails"}
                  </button>
                </>
              ) : (
                <></>
              )}

              {/* <div className="col-span-2">
                <p className="text-xs absolute bottom-0 right-0">
                  {(selectedUnsavedItems.length !== 0 ||
                    unSavedForTaskListCtr !== 0) &&
                    openTab === 1 && (
                      <p>
                        <b>{selectedUnsavedItems.length}</b> total selected
                        emails / <b>{unSavedForTaskListCtr}</b> selected for
                        Task List
                      </p>
                    )}

                  {(selectedSavedItems.length !== 0 ||
                    savedForTaskListCtr !== 0) &&
                    openTab === 2 && (
                      <p>
                        <b>{selectedSavedItems.length}</b> total selected emails
                        / <b>{savedForTaskListCtr}</b> selected for Task List
                      </p>
                    )}

                  {selectedArchivedItems.length !== 0 && openTab === 3 && (
                    <p>
                      <b>{selectedArchivedItems.length}</b> total selected
                      emails
                    </p>
                  )}
                </p>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          {/* mobile action buttons */}
          {!showCopy ? (
            <></>
          ) : (
            <div className="block h-full bg-white text-center pt-3 pb-4">
              <div className="flex -ml-3 text-center">
                {/* unsaved emails button */}
                {selectedUnsavedItems.length !== 0 && openTab === 1 ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        handleArchive();
                      }}
                      className={`flex-none w-14 bg-red-400 hover:bg-red-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring mx-4 ${
                        saveLoading ? "disabled:opacity-25" : ""
                      }`}
                      disabled={saveLoading}
                    >
                      {/* {saveLoading ? "Archive Emails" : "Archive Emails"} */}
                      <FiArchive />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        savingEmails(true);
                      }}
                      className={`-ml-2 mr-2 flex-1 bg-green-400 hover:bg-green-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring ${
                        saveLoading ? "disabled:opacity-25" : ""
                      }`}
                      disabled={saveLoading}
                    >
                      {saveLoading ? "Saving Emails..." : "Save Emails"}
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {/* unsaved emails button end*/}
                {/* {saved emails button} */}
                {selectedSavedItems.length !== 0 && openTab === 2 ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        // handleEmails(false);
                        savingEmails(false);
                      }}
                      className={`ml-5 mr-2 flex-1 bg-green-400 hover:bg-green-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring ${
                        saveLoading ? "disabled:opacity-25" : ""
                      }`}
                      disabled={saveLoading}
                    >
                      {saveLoading ? "Unsaving Emails..." : "UnSave Emails"}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleTasklist();
                      }}
                      className={`mr-2 flex-1 bg-blue-400 hover:bg-blue-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring ${
                        saveLoading && "disabled:opacity-25"
                      }`}
                      disabled={saveLoading}
                    >
                      {saveLoading ? "Save To Tasklist" : "Save To Tasklist"}
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {/* {saved emails button end} */}
                {/* archive buttons */}
                {selectedArchivedItems.length !== 0 && openTab === 3 ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        handleRetrieveEmails(false);
                      }}
                      className={`mr-2 flex-1 block bg-green-400 hover:bg-green-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring mx-4 ${
                        saveLoading ? "disabled:opacity-25" : ""
                      }`}
                      disabled={saveLoading}
                    >
                      {saveLoading ? "Retrieving Emails..." : "Retrieve Emails"}
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {/* archive buttons end */}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default ActionButtons;
