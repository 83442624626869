import React, { useState, useEffect } from "react";
import { IoCaretDown } from "react-icons/io5";
import { isMobileDevice } from "../../../shared/mobileViewFunctions";
import { MdSave } from "react-icons/md";
import { CgTrash } from "react-icons/cg";
import Select, { components } from "react-select";
import { FiPlus } from "react-icons/fi";

const LOCAL_STORAGE_KEY = "clientApp.ContactsTeam";
export default function TeamTab({
	close,
	user,
	isEditing,
	InputData,
	setInputData,
	ContactList,
	setContactList,
	tagTeamMember,
	TeamOptions,
	UserTypes,
	TeamList,
	setalertMessage,
	setShowToast,
	setTeamOptions,
	getTeams,
	width,
	handleSave,
	RemoveMember,
	setRemoveMember,
	isSaving,
	setIsSaving,
}) {
	const [isDisabled, setisDisabled] = useState(true);
	const [IsHovering, setIsHovering] = useState(false);
	const [Options, setOptions] = useState([]);
	const [FinalData, setFinalData] = useState([]);

	const ChangesHaveMade = (obj, i) => {
		if (user.teams.items[i]) {
			if (obj.team !== user.teams.items[i].name) {
				return true;
			} else {
				return false;
			}
		} else {
			if (obj.userType !== "" && obj.team !== "") {
				return true;
			}
			return false;
		}
	};

	const validate = (obj, i) => {
		//Detect if null && changes have been made
		//console.log("CALLED");
		//console.log("VALIDATE OBJ", obj);

		// console.log("ilen", InputData.length);

		if (user.teams.items.length > InputData.length) {
			// console.log("HIT |TRUE");
			return true;
		}

		let changes = ChangesHaveMade(obj, i);
		// console.log("Changes", changes);
		if (obj.team !== "" && obj.userType !== "" /*&& changes*/) {
			return true;
		} else {
			return false;
		}
	};

	const SaveButton = () => {
		return (
			<button
				onClick={() => {
					handleSave();
					setIsSaving(true);
				}}
				className={`border border-gray-200 ml-auto rounded-md ${
					isDisabled ? "bg-green-200" : "bg-green-400"
				} text-white flex flex-row justify-center items-center gap-2 font-normal px-6 py-1.5 mt-2 hover:bg-green-200 gap-2${
					isDisabled ? " cursor-default" : ""
				}`}
				disabled={isDisabled}
			>
				Save <MdSave color={`white`} />
			</button>
		);
	};

	const validateNull = (obj, i) => {
		if (obj.userType !== "" && obj.team) {
			return true;
		} else {
			return false;
		}
	};

	//Revert to old data when editing is cancelled
	useEffect(() => {
		if (!isEditing) {
			setOptions(TeamOptions);

			const teams = user.teams.items;
			const result =
				teams.length > 0
					? teams.map((team) => ({
							userId: user.id,
							teamId: team.id,
							team: team.name,
							userType: team.members?.items[0]?.userType || user.userType,
							teams,
					  }))
					: [
							{
								userId: user.id,
								team: "",
								userType: user.userType,
							},
					  ];

			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(result));

			if (!InputData.length) {
				setInputData(result);
			}
		}
	}, [isEditing]);

	useEffect(() => {
		const validations = InputData.map((input, idx) => validate(input, idx));
		const nullValues =
			InputData && InputData.map((input, i) => validateNull(input, i));
		console.log("CURRENT USER", user);

		let oFinal = InputData.map((input) => {
			let final = {
				teamId: input.teamId,
				userId: input.userId,
				userType: input.userType,
			};

			return final;
		});

		setFinalData(oFinal);

		setisDisabled(!validations.includes(true));
		if (nullValues.includes(false)) {
			setisDisabled(true);
		}
	}, [InputData, user]);

	useEffect(() => {
		console.log("FINALDATA", FinalData);
	}, [FinalData]);

	const handleSelectChange = (e, val, i, property) => {
		const list = [...InputData];
		if (property === "team") {
			list[i]["teamId"] = e.id;
		}
		list[i][property] = e.value;
		setInputData(list);
	};

	useEffect(() => {
		let data = InputData.map((input) => {
			return input.teamId;
		});

		setOptions(TeamOptions.filter((t) => !data.includes(t.id)));
	}, [InputData, TeamOptions]);

	useEffect(() => {
		setOptions(TeamOptions);

		const teams = user.teams.items;
		const result =
			teams.length > 0
				? teams.map((team) => ({
						userId: user.id,
						teamId: team.id,
						team: team.name,
						userType: team.members?.items[0]?.userType || user.userType,
						teams,
				  }))
				: [
						{
							userId: user.id,
							team: "",
							userType: user.userType,
						},
				  ];

		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(result));

		if (!InputData.length) {
			setInputData(result);
		}
	}, []);

	const handleDelete = (index, teamId, userId) => {
		setInputData(InputData.filter((_, idx) => idx !== index));
		let member = { teamId: teamId ? teamId : "", userId: userId };
		if (teamId) {
			setRemoveMember((prev) => [...prev, member]);
		}
	};

	useEffect(() => {
		console.log("REmove member", RemoveMember);
	}, [RemoveMember]);

	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<IoCaretDown className="text-sm" />
				</components.DropdownIndicator>
			)
		);
	};
	const filteredOptions = Options.filter((option) => {
		return (
			option &&
			option.value !== undefined &&
			option.value !== null &&
			option.value.trim() !== "" &&
			option.label !== undefined &&
			option.label !== null &&
			option.label.trim() !== ""
		);
	});

	const AddMore = (id) => {
		return (
			<button
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}
				onClick={() => {
					setisDisabled(true);
					setIsHovering(false);
					setInputData([
						...InputData,
						{
							userId: user.id,
							team: "",
							userType: user.userType,
						},
					]);
				}}
				className={
					"m-2 my-3 font-medium gap-1 mr-auto flex flex-row justify-center items-center text-md text-cyan-500 hover:text-cyan-300 cursor-pointer"
				}
			>
				Add More
				{/* <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM11 8.75H8.75V11C8.75 11.4375 8.40625 11.75 8 11.75C7.5625 11.75 7.25 11.4375 7.25 11V8.75H5C4.5625 8.75 4.25 8.4375 4.25 8C4.25 7.59375 4.5625 7.25 5 7.25H7.25V5C7.25 4.59375 7.5625 4.25 8 4.25C8.40625 4.25 8.75 4.59375 8.75 5V7.25H11C11.4062 7.25 11.75 7.59375 11.75 8C11.75 8.4375 11.4062 8.75 11 8.75Z"
            fill={IsHovering ? "rgb(152,241,255)" : "#1CC1E9"}
          />
        </svg> */}
				<FiPlus
					size={16}
					color={IsHovering ? "rgb(152, 241, 255)" : "#1CC1E9"}
				/>
			</button>
		);
	};
	return (
		<>
			{InputData.map((x, i) => (
				<div
					className={
						"flex  " + (isMobileDevice(width) ? "flex-col" : "flex-row")
					}
					key={i}
				>
					<div className="flex flex-col p-1">
						<div className="text-sm font-medium text-gray-400">{`Team Name`}</div>
						<Select
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator: DropdownIndicator,
							}}
							menuPortalTarget={document.body}
							styles={{
								control: (styles, { isDisabled }) => {
									return {
										...styles,
										cursor: isDisabled ? "not-allowed" : "default",
										backgroundColor: "white",
										color: "black",
									};
								},
								container: (base) => ({
									...base,
									zIndex: "99999",
								}),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }),
								menuList: (provided) => ({
									...provided,
									maxHeight: "7rem",
								}),
							}}
							name={`team`}
							options={filteredOptions}
							type="text"
							value={{
								value: x.team,
								label: x.team,
							}}
							isDisabled={!isEditing}
							onChange={(e, val) => handleSelectChange(e, val, i, `team`)}
							className={
								"rounded-md focus:border-gray-100 text-gray-400 " +
								(isMobileDevice(width) ? "w-full" : "w-80")
							}
						/>
					</div>
					<div className="flex flex-col p-1">
						<div className="text-sm font-medium text-gray-400">{`User Type`}</div>

						<Select
							menuPortalTarget={document.body}
							styles={{
								control: (styles, { isDisabled }) => {
									return {
										...styles,
										cursor: isDisabled ? "not-allowed" : "default",
										backgroundColor: "white",
										color: "black",
									};
								},
								container: (base) => ({
									...base,
									zIndex: "99999",
								}),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }),
								menuList: (provided) => ({
									...provided,
									maxHeight: "7rem",
								}),
							}}
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator: DropdownIndicator,
							}}
							name={`userType`}
							options={UserTypes}
							type="text"
							isDisabled={!isEditing}
							value={{
								value: x.userType,
								label: x.userType,
							}}
							onChange={(e, val) => handleSelectChange(e, val, i, `userType`)}
							className={
								"rounded-md focus:border-gray-100 text-gray-400 bg-white " +
								(isMobileDevice(width) ? "w-full" : "w-80")
							}
						/>
					</div>
					<div className="flex flex-col p-1">
						<div className="opacity-0">1</div>

						{isEditing && InputData.length > 1 && (
							<CgTrash
								className="border border-gray-200 text-4xl p-2 cursor-pointer hover:bg-gray-100"
								color={`lightcoral`}
								onClick={() => handleDelete(i, x.teamId, x.userId)}
							/>
						)}
					</div>
				</div>
			))}
			{isEditing && <AddMore />}
			{isEditing && <SaveButton />}
		</>
	);
}
