/* Imports */
import React, { useReducer, useState, useEffect } from "react";
import { AppRoutes } from "../../constants/AppRoutes";

/* Assets */
import "../../assets/styles/UrgencySlider.css";
/* Loading Screen */
import loading from "../../assets/images/loading.gif";
import loadingDone from "../../assets/images/loading-done.svg";
import done from "../../assets/images/check.svg";
import completeForm from "../../assets/images/complete-form.gif";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory,
  withRouter,
  Redirect,
} from "react-router-dom";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ReactTooltip from "react-tooltip";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import Spinner from "../../shared/Spinner.jsx";
import Choices from "./InputTypeFactory";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import _ from "lodash";

const currentEnv = process.env.REACT_APP_DOMAIN;
const mockup =
  "https://mma-public-assets-dev.s3.ap-southeast-1.amazonaws.com/onboarding-assets/page-1/page-1-mockups.svg";

export default function MultiStepForm({ dataWrapper }) {
  //States
  const {
    initialAnswers,
    state,
    dispatch,
    reducer,
    hasLegalNotices,
    user,
    isCompanyIdSet,
    setIsCompanyIdSet,
    sendCompletionLink,
    page,
    tempStore,
    setTempStore,
    isStillFetching,
    initializeSession,
    isStillInitializing,
    isPageLoading,
  } = dataWrapper;

  //Routing
  let history = useHistory();
  let location = useLocation();

  //Logs for debugging
  console.log(state);
  console.log(tempStore);

  //Functions
  const setPage = (newPage) => {
    /*
     * I've encountered a problem wherein if you go back to the first page and
     * make a new attempt, the state will be the same as the previous attempt.
     * This is a workaround to reset the state
     */
    if (newPage === "page 2") {
      dispatch({ type: "RESET_STATE", payload: initialAnswers });
    }

    /***
     * This is to prevent saving state.Progress to email-prompt
     * when the user clicks save and continue later
     ***/
    if (newPage !== "email-prompt") {
      dispatch({ type: "CHANGE_PROGRESS", payload: newPage });
    }

    // This is a hacky way to get the page number for the URL
    const url = newPage.split(" ");

    /***
     * In charge of moving the user to the next page
     * (ex. Progress: page 1. Only 1 will be passed to the URL)
     ***/
    if (url.length > 1) {
      history.push(`${AppRoutes.ONBOARDING}/${url[1]}`);
      return;
    }

    /***
     * In charge of moving the user to the next page if the new page is not numbered
     * (ex. Progress: "done". Done will be passed to the URL)
     ***/
    history.push(`${AppRoutes.ONBOARDING}/${newPage}`);
  };

  const ProgressCalculator = () => {
    let progress = window.location.href.split("/");
    let pageCount = 1;
    for (const key in state) {
      if (key?.includes("page")) {
        pageCount++;
      }
    }
    //Takes the page number from the URL
    progress = progress[progress.length - 1];
    let progressValue = 100;
    if (progress !== "done") {
      progressValue = (progress / pageCount) * 100;
    }
    return progressValue + "%";
  };

  const ProgressBar = () => {
    return (
      <>
        {page === "page 1" ||
          (tempStore.sessionId && (
            //Set the div to a fixed size to avoid unwanted jitters
            <div className="flex items-center mx-auto w-96 h-3 rounded-lg bg-gray-300">
              <div
                className="bg-green-600 h-2.5 rounded-lg"
                style={{ width: ProgressCalculator() }}
              ></div>
            </div>
          ))}
      </>
    );
  };

  const SaveAndContinue = ({ setPage }) => {
    //This is a hacky way to get the page number for the URL
    const pageNumber = window.location.href.split("/");
    const page = pageNumber[pageNumber.length - 1];

    const excludedPages = ["1", "2", "done", "email-prompt"];

    return (
      <>
        {!excludedPages.includes(page) && tempStore.sessionId && (
          <div className="mx-auto flex gap-1 items-center">
            <p
              className="text-gray-500 font-semibold cursor-pointer"
              onClick={() => setPage("email-prompt")}
            >
              Save and continue later
            </p>
            <span data-tip data-for="react-tooltip">
              <IoMdInformationCircleOutline className="text-gray-500 w-6 h-6 cursor-pointer" />
              <ReactTooltip
                id="react-tooltip"
                place="right"
                effect="solid"
                border={true}
                className="customTheme"
              >
                <div className="py-3 flex flex-col gap-2">
                  <h1 className="text-sm font-semibold">
                    Save and Continue Later
                  </h1>
                  <p className="text-justify text-sm font-light">
                    Not ready to finish? Click this button to save your
                    progress. You'll be prompted to enter your email address.
                    We'll send you a link which allows you to pick up right
                    where you left off and complete the survey at your
                    convenience.
                  </p>
                </div>
              </ReactTooltip>
            </span>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="m-auto flex gap-5 flex-col">
        <ProgressBar />
        <Switch>
          <Route
            path={`${AppRoutes.ONBOARDING}/1`}
            render={(props) => (
              <PageOne
                {...props}
                setPage={setPage}
                isCompanyIdSet={isCompanyIdSet}
                isStillFetching={isStillFetching}
                tempStore={tempStore}
                setTempStore={setTempStore}
                initializeSession={initializeSession}
              />
            )}
          />
          {/* IF IN CASE THE USER TRIES TO BYPASS THE PAGES */}
          {
            <Switch>
              {Object.entries(state).map(([key, page]) => {
                return (
                  <Route
                    key={page.order}
                    path={`${AppRoutes.ONBOARDING}/${page.order}`}
                    render={(props) => (
                      <DynamicPage
                        {...props}
                        tempStore={tempStore}
                        setPage={setPage}
                        dataWrapper={dataWrapper}
                        page={page}
                        title={page?.headerText}
                        subtitle={page?.subText}
                        style={page?.style}
                        preset={page?.type}
                        choices={page?.options}
                        answer={state[`page_${page?.order}`]?.answers}
                      />
                    )}
                  />
                );
              })}
              <Route
                key={page}
                path={`${AppRoutes.ONBOARDING}/done`}
                render={(props) => (
                  <PageTwelve
                    {...props}
                    setPage={setPage}
                    tempStore={tempStore}
                    dataWrapper={dataWrapper}
                  />
                )}
              />
              <Route
                path="*"
                component={
                  isStillInitializing && isPageLoading && SessionTimeout
                }
              />
            </Switch>
          }
          <Switch>
            {/* To avoid showing the session timeout component when the user is
            still initializing/loading from an email link */}
            <Route
              path="*"
              component={
                !isStillInitializing && !isPageLoading && SessionTimeout
              }
            />
          </Switch>
        </Switch>
        <SaveAndContinue setPage={setPage} />
      </div>
    </>
  );
}

function PageOne({
  setPage,
  isCompanyIdSet,
  isStillFetching,
  tempStore,
  setTempStore,
  initializeSession,
}) {
  return (
    <div
      className="flex flex-col gap-8 items-center"
      style={{ width: "36rem" }}
    >
      <div className="flex flex-col gap-4">
        <span className="text-center text-3xl font-semibold">
          Resolve your tenancy dispute—
          <br />
          step by step with MMA
        </span>
        <span className="text-center text-black font-normal">
          We understand that dealing with a tenancy dispute can be challenging.
          Share your perspective and concerns with us, and we'll guide you
          through the process, building a personalised case to address your
          specific situation.
        </span>
      </div>

      <div className="flex flex-col gap-4">
        <img src={mockup} alt="" />
      </div>

      <div className="inline-flex">
        <button
          disabled={
            tempStore.companyId === null ||
            isStillFetching ||
            Object.keys(tempStore).length === 0
          }
          onClick={() => {
            initializeSession(tempStore, setTempStore);
            setPage("page 2");
          }}
          className={
            "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
            (tempStore.companyId === null ||
            isStillFetching ||
            Object.keys(tempStore).length === 0
              ? "bg-opacity-50 cursor-not-allowed"
              : "hover:bg-green-50 hover:text-green-800")
          }
        >
          LET'S START
        </button>
      </div>
    </div>
  );
}

function DynamicPage({
  tempStore,
  setPage,
  dataWrapper,
  page,
  title,
  subtitle,
  style,
  preset,
  choices,
  answer,
}) {
  const { onboardingQuestions, state, dispatch, isStillInitializing } =
    dataWrapper;
  console.log(page);
  console.log(answer);

  const handlePageChange = () => {
    const pageNumber = page.order;
    const nextPage =
      page?.options?.find((x) => x.order === answer[0]?.order)?.redirect ||
      page?.properties?.redirectTo;

    // Check if the next page exists
    if (!onboardingQuestions.some((x) => nextPage === x.order)) {
      setPage("done");
      return;
    }
    setPage(`page ${nextPage}`);
  };

  /**
   * Handles skipping of pages
   * @returns {void}
   */
  const handleSkipPage = () => {
    const nextPage = page?.properties?.redirectTo;

    if (!onboardingQuestions?.some((x) => nextPage === x.order)) {
      setPage("done");
      return;
    }
    setPage(`page ${nextPage}`);
  };

  /**
   * Handles adding of more options
   * @returns {void}
   */
  const handleAddMore = () => {
    /* Checks if the option that will be concatenated is duplicated */
    let lastOrder = page?.options[page?.options.length - 1]?.order;
    let concatOptions = page?.options;
    let filteredConcatOptions = [];
    concatOptions.forEach((x) => {
      if (!filteredConcatOptions.some((y) => y?.optionText === x?.optionText)) {
        filteredConcatOptions.push(x);
      }
    });

    /* Replace the order number to something that doesn't exist yet */
    const newOptions = filteredConcatOptions.map((x) => {
      lastOrder++;
      return {
        ...x,
        order: lastOrder,
      };
    });

    const newPayload = [...page?.options, ...newOptions];
    dispatch({
      page: `page_${page.order}`,
      type: "CHANGE_MAIN_OPTIONS",
      payload: newPayload,
    });
  };

  /**
   * Handles the main answer
   * @param {event} eventAnswer - The event
   * @param {string} choiceType - The type of choice attached to the page
   * @param {object} corequisite - The corequisite object
   * @param {number} answerIndex - The order of the answer
   * @returns {void}
   * @example
   * handleMainAnswer(event, "single", undefined, 0)
   * handleMainAnswer(event, "multiple", undefined, 0)
   * handleMainAnswer(event, "multiple", corequisite, 0)
   * handleMainAnswer(event, "linear-scale", undefined, 0)
   */
  const handleMainAnswer = (
    eventAnswer,
    pageChoiceType,
    corequisite,
    answerIndex,
    choiceType
  ) => {
    let inputType = choiceType;
    if (eventAnswer.hasOwnProperty("target")) {
      eventAnswer.stopPropagation();
      inputType = eventAnswer?.target?.localName;
      console.log(eventAnswer.target.localName);

      switch (eventAnswer.target.localName) {
        case "button":
          eventAnswer = eventAnswer.target.innerText;
          break;
        case "input":
          eventAnswer = eventAnswer.target.value;
          break;
        case "span":
          eventAnswer = eventAnswer.target.innerText;
          break;
        default:
          eventAnswer =
            eventAnswer?.target?.innerText || eventAnswer?.target?.value;
      }
    }

    console.log("HANDLE ANSWER", inputType);
    if (pageChoiceType == "single") {
      const singleAnswerPayload = {
        ...({} && { order: answerIndex, answer: eventAnswer }),
        ...(!(corequisite === undefined) && { corequisite: "" }),
      };

      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_MAIN_ANSWER",
        payload: singleAnswerPayload,
      });
    } else if (pageChoiceType == "multiple") {
      /*************************************************************/
      /* If the multiple-choice question has a "Select All" option */
      /*************************************************************/
      if (
        typeof eventAnswer === "string" &&
        (eventAnswer === "Select All" || eventAnswer.includes("Select All"))
      ) {
        choices.forEach((choice) => {
          const selectAllPayload = {
            ...({} && { order: choice?.order, answer: choice?.optionText }),
            ...(!(corequisite === undefined) && { corequisite: "" }),
          };

          // Checks if the answer already exists in the answer array
          if (
            !answer.some(
              (x) =>
                x.answer === choice.optionText ||
                x.answer.includes(choice.optionText)
            ) &&
            choice?.optionText !== "Others"
          ) {
            dispatch({
              page: `page_${page.order}`,
              type: "CHANGE_MAIN_ANSWER_MULTIPLE",
              inputType: inputType,
              payload: selectAllPayload,
            });
          }
        });
        return;
      }

      /********************************************/
      /* If there is no "Select All" option given */
      /********************************************/
      const multipleAnswerPayload = {
        ...({} && { order: answerIndex, answer: eventAnswer }),
        ...(!(corequisite === undefined) && { corequisite: "" }),
      };

      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_MAIN_ANSWER_MULTIPLE",
        inputType: inputType,
        payload: multipleAnswerPayload,
      });
    } else if (pageChoiceType === "linear-scale") {
      const singleAnswerPayload = {
        ...({} && { order: answerIndex, answer: eventAnswer }),
        ...(!(corequisite === undefined) && { corequisite: "" }),
      };

      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_MAIN_ANSWER",
        payload: singleAnswerPayload,
      });
    }
  };

  /**
   * Handles the corequisite answer
   * @param {event} answer - The event
   * @param {number} answerIndex - The order of the answer
   * @param {object} selectedCorequisite - The selected corequisite
   * @returns {void}
   */
  const handleCorequisiteAnswer = (
    answer,
    answerIndex,
    selectedCorequisite,
    corequisiteType
  ) => {
    if (corequisiteType !== "datepicker") {
      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_COREQUISITE_ANSWER",
        answerIndex: answerIndex,
        corequisiteIndex: selectedCorequisite.order,
        payload: {
          answer: answer.target.value,
          order: selectedCorequisite.order,
        },
      });
    }

    if (corequisiteType === "datepicker") {
      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_COREQUISITE_ANSWER",
        answerIndex: answerIndex,
        corequisiteIndex: selectedCorequisite.order,
        payload: {
          answer: answer,
          order: selectedCorequisite.order,
        },
      });
    }
  };

  /**
   * Checks if the button should be disabled or not
   * @param {boolean} className - If true, returns the class name. If false, returns the boolean value
   * @returns {boolean} - Returns the class name if className is true. Returns the boolean value if className is false
   */
  const checkIfButtonDisabled = (className) => {
    const corequisiteLength = page?.options?.find((item) =>
      item?.corequisite?.some((x) =>
        answer?.some(
          (ans) =>
            ans?.corequisite &&
            ans?.corequisite?.some((y) => x.order === y.order)
        )
      )
    )?.corequisite?.length;

    if (
      page?.properties?.isRequired &&
      (page?.type === "none" || page?.type === "linear-scale")
    ) {
      return className ? "hover:bg-green-50 hover:text-green-800" : false;
    }

    if (page?.properties?.isRequired && (!answer || answer.length === 0)) {
      return className ? "bg-opacity-50 cursor-not-allowed" : true;
    }

    //Checking for corequisite answer length
    if (page?.properties?.isRequired && "corequisite" in answer[0]) {
      if (
        answer[0]?.corequisite.length > 0 &&
        !answer[0]?.corequisite?.some(
          (item) =>
            item?.answer === "" ||
            item?.answer === undefined ||
            item?.answer === null
        ) &&
        answer[0]?.corequisite?.length === corequisiteLength
      ) {
        return className ? "hover:bg-green-50 hover:text-green-800" : false;
      }
      return className ? "bg-opacity-50 cursor-not-allowed" : true;
    }

    if (
      page?.properties?.isRequired &&
      (!answer[0]?.answer || answer[0]?.answer.length === 0)
    ) {
      return className ? "bg-opacity-50 cursor-not-allowed" : true;
    }

    return className ? "hover:bg-green-50 hover:text-green-800" : false;
  };

  const urgency = answer[0]?.answer || 1;

  return (
    <>
      {isStillInitializing && <Spinner />}
      <div className="flex flex-col gap-8 items-center px-10">
        <div className="flex flex-col gap-4">
          <span className="text-center text-3xl font-semibold max-w-xl mx-auto">
            {title}
          </span>
          <span className="text-center pb-5 max-w-xl mx-auto">{subtitle}</span>
          <Choices
            style={style}
            preset={preset}
            action={(e) => handleMainAnswer(e, page.type, undefined, 0)}
            choices={choices}
            className="w-10/11"
          >
            {choices?.map((choice, index) => (
              <React.Fragment key={choice?.optionText + index + "main"}>
                <Choices.Item
                  tempStore={tempStore}
                  style={style}
                  type={choice?.type}
                  preset={preset}
                  sub={choice?.sub || ""}
                  action={(e) =>
                    handleMainAnswer(
                      e,
                      page.type,
                      choice.corequisite,
                      choice.order,
                      choice.type
                    )
                  }
                  active={
                    answer?.some((item) => item?.order === choice?.order)
                      ? true
                      : false
                  }
                  value={
                    answer[0] && answer[0]?.answer?.key
                      ? answer
                      : answer?.length > 0 &&
                        answer?.find((x) => x.order === choice.order)?.answer
                  }
                  linearScale={{
                    urgency,
                    choice,
                  }}
                  formData={state}
                >
                  {choice.optionText}
                </Choices.Item>
                {/* {console.log(choice)}
                {console.log(answer)} */}
                {!(choice.corequisite === undefined) &&
                  choice.corequisite[0]?.corequisiteText.length > 0 &&
                  choice.corequisite.map((corequisite, index) => (
                    <Choices.Corequisite
                      key={choice + index + "corequisite"}
                      active={answer?.some((x) => x?.order === choice?.order)}
                      style={style}
                      value={
                        answer[0]?.corequisite?.length > 0 &&
                        answer[0]?.corequisite?.find(
                          (x) => x.order === corequisite.order
                        )?.answer
                      }
                      type={corequisite?.type}
                      action={(e) =>
                        handleCorequisiteAnswer(
                          e,
                          choice.order,
                          corequisite,
                          corequisite?.type
                        )
                      }
                      configuration={corequisite?.config}
                    >
                      {corequisite?.corequisiteText}
                    </Choices.Corequisite>
                  ))}
              </React.Fragment>
            ))}
          </Choices>
        </div>
        <div className="flex gap-1 w-full items-center">
          {page?.properties?.isAddMore && (
            <>
              <hr className="w-full border" />
              <div className="inline-flex gap-3">
                <button
                  onClick={() => handleAddMore()}
                  className={
                    "w-36 mx-auto py-3 bg-white text-green-700 text-base font-semibold hover:text-green-500"
                  }
                >
                  Add More +
                </button>
              </div>
              <hr className="w-full border" />
            </>
          )}
        </div>
        <div className="inline-flex gap-3">
          {page?.properties?.isSkippable && (
            <button
              onClick={() => handleSkipPage()}
              className={
                "w-36 mx-auto py-3 bg-white rounded-lg border text-black text-base font-semibold hover:bg-gray-700 hover:text-white"
              }
            >
              Skip
            </button>
          )}
          <button
            disabled={checkIfButtonDisabled(false)}
            onClick={() => handlePageChange()}
            className={
              "w-36 mx-auto py-3 bg-green-600 rounded-lg text-white text-base font-normal " + //Why is there no disabled class in this version of tailwind?!
              checkIfButtonDisabled(true)
            }
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

// /* MOBILE VERSION */
// function Mobile({
//   formData,
//   handleDateChange,
//   handleDescriptionChange,
//   handleDescriptionDisplay,
//   handleSubmission,
//   handleDeleteFile,
//   attachFiles,
//   getFile,
//   brief_id,
//   email,
// }) {
//   const [isEditing, setIsEditing] = useState(false);

//   useEffect(() => {
//     if (isEditing) {
//       window.onbeforeunload = () => true;
//     } else {
//       window.onbeforeunload = undefined;
//     }
//   }, [isEditing]);

//   const constructFileForDelete = (arr, index, file) => {
//     let backgroundId = arr[index].id;
//     let fileId = file.id;
//     let fileName = file.name;

//     return {
//       backgroundId: backgroundId,
//       name: fileName,
//       id: fileId,
//     };
//   };

//   return (
//     <div className="mt-1">
//       {formData.length > 0 //Checks if there is data
//         ? formData.map((data, index) => (
//             <div
//               key={data.id}
//               className={
//                 "mx-3 flex flex-col flex-wrap rounded-md bg-white py-2 shadow " +
//                 (formData.length === index + 1 ? "mb-16" : "mb-2") //Accounts for the fixed button below the screen
//               }
//             >
//               <div className="flex flex-row gap-3 px-4 py-1">
//                 <div className="flex flex-row items-start gap-2">
//                   <div className="flex flex-row items-center">
//                     <span
//                       className="text-sm font-medium"
//                       style={{ marginTop: "0.07rem" }}
//                     >
//                       {index + 1}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex w-full flex-col gap-2">
//                   <div className="w-full">
//                     <div
//                       className="w-full rounded-md border border-gray-300 p-2.5 text-justify"
//                       style={{
//                         cursor: "auto",
//                         outlineColor: "rgb(204, 204, 204, 0.5)",
//                         outlineWidth: "thin",
//                         width: "100%",
//                         minWidth: "100px",
//                       }}
//                       suppressContentEditableWarning
//                       contentEditable={true}
//                       onInput={(e) => {
//                         handleDescriptionDisplay(e.currentTarget.textContent);
//                       }}
//                       onClick={() => handleDescriptionDisplay(data.description)}
//                       onPaste={(e) => {
//                         e.preventDefault();
//                         const text = e.clipboardData.getData("text/plain");
//                         document.execCommand("insertHTML", false, text);
//                       }}
//                       onBlur={(e) => {
//                         handleDescriptionChange(
//                           index,
//                           e.target.textContent,
//                           data.id
//                         );
//                         setIsEditing(false);
//                       }}
//                       onFocus={() => setIsEditing(true)}
//                     ></div>
//                   </div>
//                   <button
//                     className="w-full rounded-lg border border-green-500 bg-green-100 bg-opacity-50 px-1.5 py-2 font-semibold text-green-500"
//                     onClick={() => attachFiles(data.id, index)}
//                   >
//                     Upload File +
//                   </button>
//                   {formData[index].description.length > 0
//                     ? formData[index].files.items.map((data, i) => (
//                         <div
//                           key={data.id}
//                           className="flex items-center gap-2 rounded-md bg-gray-200 py-1.5 px-3"
//                           onClick={() => getFile(data.id)}
//                         >
//                           <span className="font-medium">{data.name}</span>
//                         </div>
//                       ))
//                     : null}
//                 </div>
//               </div>
//             </div>
//           ))
//         : null}
//     </div>
//   );
// }

// function PageTwo({ setPage, dataWrapper }) {
//   const { state, dispatch, isStillInitializing } = dataWrapper;

//   //States
//   const legalStakeholder = state?.Page_2?.answer?.legalStakeholder;
//   const pageTwoQuestion = state?.Page_2?.question;

//   const handleLegalStakeholder = (e) => {
//     if (e !== "Family Member" && e !== "Authorized Rep") {
//       dispatch({ type: "CHANGE_LEGALSTAKEHOLDER", payload: e });
//       dispatch({ type: "CHANGE_STAKEHOLDERRELATIONSHIP", payload: "" });
//     }
//     dispatch({ type: "CHANGE_LEGALSTAKEHOLDER", payload: e });
//   };

//   const handleStakeholderRelationship = (e) => {
//     dispatch({ type: "CHANGE_STAKEHOLDERRELATIONSHIP", payload: e });
//   };

//   return (
//     <>
//       {isStillInitializing && <Spinner />}
//       <div
//         className="flex flex-col gap-8 items-center"
//         style={{ width: "30rem" }}
//       >
//         <div className="flex flex-col gap-4">
//           <span className="text-center text-3xl font-semibold mb-5">
//             {pageTwoQuestion}
//           </span>
//           <div className="flex flex-col gap-2">
//             <button
//               onClick={(e) => handleLegalStakeholder("Individual")}
//               className={
//                 legalStakeholder === "Individual"
//                   ? "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center bg-green-800 text-white"
//                   : "text-green-800 bg-green-50 px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white"
//               }
//             >
//               I am the individual involved in the legal matter
//             </button>
//             <button
//               onClick={() => handleLegalStakeholder("Family Member")}
//               className={
//                 legalStakeholder === "Family Member"
//                   ? "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center bg-green-800 text-white"
//                   : "text-green-800 bg-green-50 px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white"
//               }
//             >
//               I am a concerned family member seeking legal advice
//             </button>
//             <button
//               onClick={() => handleLegalStakeholder("Authorized Rep")}
//               className={
//                 legalStakeholder === "Authorized Rep"
//                   ? "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center bg-green-800 text-white"
//                   : "text-green-800 bg-green-50 px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white"
//               }
//             >
//               I am an authorised representative acting on behalf of someone
//               else.
//             </button>

//             {/* Should only appear on 3rd button select */}
//             {(legalStakeholder === "Family Member" ||
//               legalStakeholder === "Authorized Rep") && (
//               <div className="flex flex-col gap-1">
//                 <span className="text-slate-700 text-sm">
//                   If a family member or authorised representative, specify your
//                   relationship with the individual involved in the legal matter
//                 </span>
//                 <input
//                   className="px-4 py-2.5 text-base rounded-lg border w-full"
//                   onChange={(e) =>
//                     handleStakeholderRelationship(e.target.value)
//                   }
//                   placeholder="Relationship"
//                   type="text"
//                   value={state.Page_2.answer.stakeholderRelationship || ""}
//                 />
//               </div>
//             )}

//             <input
//               onChange={(e) => handleLegalStakeholder(e.target.value)}
//               className="px-4 py-2.5 text-base rounded-lg border"
//               placeholder="Others (specify)"
//               type="text"
//               value={
//                 legalStakeholder !== "Individual" &&
//                 legalStakeholder !== "Family Member" &&
//                 legalStakeholder !== "Authorized Rep"
//                   ? legalStakeholder
//                   : ""
//               }
//             />
//           </div>
//         </div>

//         <div className="inline-flex">
//           <button
//             disabled={
//               legalStakeholder === "Family Member" ||
//               legalStakeholder === "Authorized Rep"
//                 ? state.Page_2.answer.stakeholderRelationship === ""
//                   ? true
//                   : false
//                 : legalStakeholder === ""
//                 ? true
//                 : false
//             }
//             onClick={() => {
//               setPage("page 3");
//             }}
//             className={
//               "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " + //Why is there no disabled class in this version of  tailwind?!
//               (legalStakeholder === "Family Member" ||
//               legalStakeholder === "Authorized Rep"
//                 ? state.Page_2.answer.stakeholderRelationship === ""
//                   ? "bg-opacity-50 cursor-not-allowed"
//                   : "hover:bg-green-50 hover:text-green-800"
//                 : legalStakeholder === ""
//                 ? "bg-opacity-50 cursor-not-allowed"
//                 : "hover:bg-green-50 hover:text-green-800")
//             }
//           >
//             CONTINUE
//           </button>
//         </div>
//       </div>
//     </>
//   );
// }

// function PageThree({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;

//   //States
//   const areaOfLaw = state.Page_3.answer;
//   const pageThreeQuestion = state?.Page_3?.question;

//   //Options
//   const options = [
//     "Family Law",
//     "Personal Injury",
//     "Business Law",
//     "Employment",
//     "Real Estate",
//     "Criminal Defense",
//     "I don't know",
//   ];

//   const handleAreaOfLaw = (e) => {
//     dispatch({ type: "CHANGE_AREAOFLAW", payload: e });
//   };

//   return (
//     <div className="flex flex-col gap-12">
//       <div className="flex flex-col">
//         <p className="text-3xl font-semibold text-center my-10 max-w-xl">
//           {pageThreeQuestion}
//         </p>
//         <div className="flex flex-col gap-4">
//           {options.map((option) => (
//             <button
//               key={option}
//               onClick={() => handleAreaOfLaw(option)}
//               className={
//                 " px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white " +
//                 (areaOfLaw === option
//                   ? "bg-green-800 text-white"
//                   : "text-green-800 bg-green-50")
//               }
//             >
//               {option}
//             </button>
//           ))}
//           <input
//             className="px-4 py-2.5 text-base rounded-lg border"
//             placeholder="Others (specify)"
//             type="text"
//             onInput={(e) => handleAreaOfLaw(e.target.value)}
//             value={options.includes(areaOfLaw) ? "" : areaOfLaw}
//           />
//         </div>
//       </div>
//       <div className="flex w-full justify-center">
//         <button
//           disabled={areaOfLaw === ""}
//           onClick={() => setPage("page 4")}
//           className={
//             "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
//             (areaOfLaw === ""
//               ? "bg-opacity-50 cursor-not-allowed"
//               : "hover:bg-green-50 hover:text-green-800")
//           }
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageFour({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;

//   //States
//   const clientChallenges = state?.Page_4?.answer;
//   const pageFourQuestion = state?.Page_4?.question;

//   //Options
//   // const challenges = [
//   //   { title: "Financial Constraints", imageSrc: optionOne },
//   //   { title: "Lack of Legal Representation", imageSrc: optionTwo },
//   //   {
//   //     title: "Communication Issues with Involved Parties",
//   //     imageSrc: optionThree,
//   //   },
//   //   { title: "Complex Legal Process", imageSrc: optionFour },
//   //   { title: "Emotional Distress", imageSrc: optionFive },
//   //   { title: "Time Sensitivity", imageSrc: optionSix },
//   //   { title: "Uncertainty about Rights and Opinions", imageSrc: optionSeven },
//   //   { title: "Skip", sub: "I'm not exactly sure", imageSrc: optionEight },
//   // ];

//   const handleSelect = (title) => {
//     const selectedChallenge = challenges.find(
//       (challenge) => challenge.title === title
//     );

//     if (!selectedChallenge) {
//       return;
//     }

//     if (title === "Skip") {
//       dispatch({
//         type: "CHANGE_CHALLENGES",
//         payload: clientChallenges?.includes("Skip") ? [] : ["Skip"],
//       });
//     } else {
//       const updatedChallenges = clientChallenges?.includes("Skip")
//         ? [title]
//         : clientChallenges?.includes(title)
//         ? clientChallenges?.filter((stateTitle) => stateTitle !== title)
//         : [...(clientChallenges || []), title];

//       dispatch({
//         type: "CHANGE_CHALLENGES",
//         payload: updatedChallenges.filter((title) => title !== "Skip"),
//       });
//     }
//   };

//   return (
//     <div className="flex flex-col gap-8 items-center">
//       <div className="flex flex-col gap-8">
//         <span className="text-center text-3xl font-semibold mx-auto max-w-xl">
//           {pageFourQuestion}
//         </span>
//         <span className="text-center text-black font-normal">
//           Select all that apply
//         </span>

//         <div className="grid grid-cols-4 gap-8">
//           {challenges.map((item, index) => (
//             <button
//               key={index}
//               onClick={() => handleSelect(item.title)}
//               className={
//                 clientChallenges?.includes(item.title)
//                   ? "w-56 h-52 flex flex-col p-6 bg-green-50 rounded-2xl gap-3 items-center justify-center"
//                   : "w-56 h-52 flex flex-col p-6 bg-gray-50 rounded-2xl gap-3 items-center justify-center hover:bg-green-50"
//               }
//             >
//               <img
//                 className="w-20 h-20"
//                 src={item.imageSrc}
//                 alt={`option-${index}`}
//               />
//               <div className="flex-col items-center gap-2 flex">
//                 <span className="text-center text-gray-900 text-lg font-semibold">
//                   {item.title}
//                 </span>
//                 {item.sub && (
//                   <span className="text-center text-gray-500 text-sm font-normal">
//                     {item.sub}
//                   </span>
//                 )}
//               </div>
//             </button>
//           ))}
//         </div>
//       </div>

//       <div className="inline-flex">
//         <button
//           disabled={clientChallenges?.length === 0}
//           onClick={() => setPage("page 5")}
//           className={
//             "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
//             (clientChallenges?.length === 0
//               ? "bg-opacity-50 cursor-not-allowed"
//               : "hover:bg-green-50 hover:text-green-800")
//           }
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageFive({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;
//   const pageFiveQuestion = state?.Page_5?.question;

//   const parts = pageFiveQuestion.split(/(\d+%)/g).map((part, index) => {
//     if (/\d+%/.test(part)) {
//       return (
//         <span key={index} className="text-green-600 text-2xl font-semibold">
//           {part}
//         </span>
//       );
//     }
//     return part;
//   });

//   return (
//     <div className="flex flex-col gap-12">
//       <div className="flex flex-col">
//         <p className="text-2xl font-semibold text-center my-5 max-w-xl">
//           {parts}
//         </p>
//         <div
//           className="flex m-auto w-full h-96"
//           style={{
//             paddingBottom: "1.85rem",
//             background: `repeating-linear-gradient(to bottom, rgba(128, 128, 128, 0.15), rgba(128, 128, 128, 0.15) 1px, transparent 1px, transparent 4rem)`,
//             backgroundSize: "100%",
//           }}
//         >
//           <div className="flex flex-col gap-3 justify-end w-1/2 h-full ml-10">
//             <div className="flex w-10 mx-auto h-4/6 rounded-t-lg bg-gradient-to-tr from-green-700 to-green-600 shadow-md"></div>
//             <div className="mx-auto">Your Case</div>
//           </div>
//           <div className="flex flex-col gap-3 justify-end w-1/2 h-full mr-10">
//             <div className="flex w-10 mx-auto h-3/6 bg-gradient-to-tr from-green-900 to-green-600 rounded-t-lg shadow-md"></div>
//             <div className="mx-auto">Others</div>
//           </div>
//         </div>
//       </div>
//       <div className="flex w-full justify-center">
//         <button
//           onClick={() => setPage("page 6")}
//           className="flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold hover:bg-green-50 hover:text-green-800"
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageSix({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;

//   //States
//   const ongoingNegotiations = state?.Page_6?.answer.hasOngoingNegotiations;
//   const discussion = state?.Page_6?.answer.discussion;
//   const pageSixQuestion = state?.Page_6?.question;

//   const handleOngoingNegotiations = (e) => {
//     if (e === "Yes" || e === "No") {
//       dispatch({ type: "CHANGE_ONGOINGNEGOTIATIONS", payload: e });
//       dispatch({ type: "CHANGE_DISCUSSION", payload: "" });
//       return;
//     }
//     dispatch({ type: "CHANGE_ONGOINGNEGOTIATIONS", payload: e });
//   };

//   const handleDiscussion = (e) => {
//     dispatch({ type: "CHANGE_DISCUSSION", payload: e });
//   };

//   return (
//     <div className="flex flex-col gap-8 items-center">
//       <div className="flex flex-col gap-4 items-center">
//         <span className="text-center text-3xl font-semibold mb-5 max-w-xl">
//           {pageSixQuestion}
//         </span>
//         <div className="flex flex-col gap-2" style={{ width: "30rem" }}>
//           <button
//             onClick={() => handleOngoingNegotiations("Yes")}
//             className={
//               ongoingNegotiations === "Yes"
//                 ? "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center bg-green-800 text-white"
//                 : "text-green-800 bg-green-50 px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white"
//             }
//           >
//             Yes, we are actively engaged in negotiations.
//           </button>
//           <button
//             onClick={() => handleOngoingNegotiations("Limited")}
//             className={
//               ongoingNegotiations === "Limited"
//                 ? "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center bg-green-800 text-white"
//                 : "text-green-800 bg-green-50 px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white"
//             }
//           >
//             We have initiated discussions, but progress is limited.
//           </button>
//           {ongoingNegotiations === "Limited" && (
//             <div className="flex flex-col gap-1">
//               <p>Briefly discuss the negotiations/discussions</p>
//               <textarea
//                 className="px-4 py-2.5 text-base rounded-lg border resize-none h-36"
//                 placeholder="Enter a description... (Required)"
//                 type="text"
//                 onInput={(e) => handleDiscussion(e.target.value)}
//                 value={discussion || ""}
//               />
//             </div>
//           )}
//           <button
//             onClick={() => handleOngoingNegotiations("No")}
//             className={
//               ongoingNegotiations === "No"
//                 ? "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center bg-green-800 text-white"
//                 : "text-green-800 bg-green-50 px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white"
//             }
//           >
//             No, there are no ongoing negotiations or discussions.
//           </button>
//         </div>
//       </div>

//       <div className="inline-flex">
//         <button
//           disabled={
//             ongoingNegotiations
//               ? ongoingNegotiations === "Limited" && discussion === ""
//                 ? true
//                 : false
//               : true
//           }
//           onClick={() => setPage("page 7")}
//           className={
//             "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
//             (ongoingNegotiations
//               ? ongoingNegotiations === "Limited" && discussion === ""
//                 ? "bg-opacity-50 cursor-not-allowed"
//                 : "hover:bg-green-50 hover:text-green-800"
//               : "bg-opacity-50 cursor-not-allowed")
//           }
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageSeven({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;

//   //States
//   const hasLegalNotices = state?.Page_7?.answer?.hasLegalNotices;
//   const pageSevenQuestion = state?.Page_7?.question;

//   const handleLegalNotices = () => {
//     dispatch({
//       type: "CHANGE_HASLEGALNOTICE",
//       payload: true,
//     });
//   };

//   const handleNotification = (e) => {
//     dispatch({
//       type: "CHANGE_NOTIFICATION",
//       payload: e.target.value,
//     });
//   };

//   const handleEmptyNotification = () => {
//     dispatch({
//       type: "CHANGE_HASLEGALNOTICE",
//       payload: false,
//     });
//     dispatch({
//       type: "CHANGE_NOTIFICATION",
//       payload: "",
//     });
//     dispatch({ type: "CHANGE_SUMMARY", payload: "" });
//   };

//   return (
//     <div className="flex flex-col gap-12">
//       <div className="flex flex-col">
//         <p className="text-3xl font-semibold text-center my-10 max-w-xl">
//           {pageSevenQuestion}
//         </p>
//         <div className="flex flex-col gap-4">
//           <button
//             onClick={() => handleLegalNotices()}
//             className={
//               "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white " +
//               (hasLegalNotices === true
//                 ? "bg-green-800 text-white"
//                 : "text-green-800 bg-green-50")
//             }
//           >
//             Yes, I have received official notifications.
//           </button>
//           {hasLegalNotices === true && (
//             <div className="flex flex-col gap-2">
//               <div className="flex flex-col gap-1">
//                 <p>What is the official notification about?</p>
//                 <input
//                   className="px-4 py-2.5 text-base rounded-lg border"
//                   placeholder="Describe..."
//                   type="text"
//                   onInput={(e) => handleNotification(e)}
//                   value={state?.Page_7?.answer?.notification || ""}
//                 />
//               </div>
//               <div className="flex flex-col gap-1">
//                 <p>Give a brief summary of the notification</p>
//                 <textarea
//                   className="px-4 py-2.5 text-base rounded-lg border resize-none"
//                   placeholder="Enter a description..."
//                   type="text"
//                   onInput={(e) =>
//                     dispatch({
//                       type: "CHANGE_SUMMARY",
//                       payload: e.target.value,
//                     })
//                   }
//                   value={state?.Page_7?.answer?.summary || ""}
//                 />
//               </div>
//             </div>
//           )}
//           <button
//             onClick={() => handleEmptyNotification()}
//             className={
//               "px-4 py-2.5 text-base font-semibold rounded-lg justify-center items-center hover:bg-green-800 hover:text-white " +
//               (hasLegalNotices === false
//                 ? "bg-green-800 text-white"
//                 : "text-green-800 bg-green-50")
//             }
//           >
//             No, I have not received any official notifications.
//           </button>
//         </div>
//       </div>
//       <div className="flex w-full justify-center">
//         <button
//           disabled={
//             hasLegalNotices === undefined
//               ? true
//               : hasLegalNotices !== false
//               ? state?.Page_7?.answer?.notification === "" ||
//                 state?.Page_7?.answer?.summary === ""
//                 ? true
//                 : false
//               : false
//           }
//           onClick={() => setPage("page 8")}
//           className={
//             "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
//             (hasLegalNotices === undefined
//               ? "bg-opacity-50 cursor-not-allowed"
//               : hasLegalNotices !== false
//               ? state?.Page_7?.answer?.notification === "" ||
//                 state?.Page_7?.answer?.summary === ""
//                 ? "bg-opacity-50 cursor-not-allowed"
//                 : "hover:bg-green-50 hover:text-green-800"
//               : "hover:bg-green-50 hover:text-green-800")
//           }
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageEight({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;

//   //States
//   const urgency = state.Page_8.answer;
//   const pageEightQuestion = state?.Page_8?.question;

//   const calculatePos = (urgencyRange) => {
//     return Number(((urgencyRange - 1) * 100) / (10 - 1));
//   };
//   const selectorStyle = {
//     left: `calc(${calculatePos(urgency)}% + (${
//       -4 - calculatePos(urgency) * 0.25
//     }px))`,
//   };
//   const tooltipStyle = {
//     left: `calc(${calculatePos(urgency)}% + (${
//       7 - calculatePos(urgency) * 0.25
//     }px))`,
//   };
//   const progressStyle = {
//     width: `calc(${calculatePos(urgency)}% + (${
//       2 - calculatePos(urgency) * 0.25
//     }px))`,
//   };

//   const handleUrgencyRange = (e) => {
//     dispatch({ type: "CHANGE_URGENCYRANGE", payload: e });
//   };

//   return (
//     <div
//       className="flex flex-col gap-8 items-center"
//       style={{ width: "36rem" }}
//     >
//       <div className="flex flex-col gap-4">
//         <span className="text-center text-3xl font-semibold mb-14 max-w-lg">
//           {pageEightQuestion}
//         </span>
//         <div className="flex flex-col gap-2 relative">
//           <div
//             style={selectorStyle}
//             className="w-9 h-9 px-3 py-2 bg-gray-900 rounded-lg flex-col inline-flex justify-center absolute bottom-7"
//           >
//             <div className="text-center text-white text-xs font-semibold">
//               {urgency}
//             </div>
//           </div>
//           <div
//             style={tooltipStyle}
//             className="urgencyTooltip w-3.5 h-3.5 bg-gray-900 rounded-sm absolute bottom-6"
//           ></div>
//           <input
//             className="urgencySlider"
//             type="range"
//             value={urgency}
//             min="1"
//             max="10"
//             step="1"
//             onChange={(e) => handleUrgencyRange(e.target.value)}
//           />
//           <div style={progressStyle} className="urgencyProgress"></div>
//         </div>
//       </div>

//       <div className="inline-flex">
//         <button
//           onClick={() => setPage("page 9")}
//           className="flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold hover:bg-green-50 hover:text-green-800"
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageNine({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;

//   //States
//   const objectives = state?.Page_9?.answer?.objectives;
//   const pageNineQuestion = state?.Page_9?.question;

//   const handleSelectObjectives = (e) => {
//     if (e === "selectAll") {
//       dispatch({ type: "CHANGE_OBJECTIVES", payload: options });
//       return;
//     }
//     if (e.length === 1) {
//       return (e = e[0]);
//     }

//     //Converts single array to string
//     e = options.filter((option, index) => index === e)[0];
//     const updatedObjectives = objectives.find((objective) => objective === e)
//       ? objectives.filter((objective) => objective !== e)
//       : [...(objectives || []), e];
//     dispatch({ type: "CHANGE_OBJECTIVES", payload: updatedObjectives });
//   };

//   const handleOtherObjectives = (e) => {
//     dispatch({ type: "CHANGE_OBJECTIVES_OTHERS", payload: e });
//   };

//   const options = [
//     "Obtain a fair settlement or compensation",
//     "Protect my legal rights and interests",
//     "Resolve the matter amicably through negotiation or mediation",
//     "Clarify legal responsibilities and obligations",
//     "Seek justice and hold the responsible party accountable",
//     "Obtain a favorable judgment in court",
//     "Prevent further harm or recurrence of the issue",
//   ];

//   return (
//     <div className="flex flex-col gap-8 items-center">
//       <div className="flex flex-col gap-8 items-center">
//         <span className="text-center text-3xl font-semibold max-w-xl">
//           {pageNineQuestion}
//         </span>
//         <button
//           className="text-green-600 hover:underline"
//           onClick={() => handleSelectObjectives("selectAll")}
//         >
//           Select all 7
//         </button>

//         <div className="flex flex-col gap-2">
//           <div className="flex gap-2 justify-center">
//             <button
//               onClick={() => handleSelectObjectives(0)}
//               className={
//                 objectives.includes(options[0])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={objectives.includes(options[0]) ? check : scaleBalanced}
//                 alt="scale"
//               ></img>
//               <span className="text-base font-normal">
//                 Obtain a fair settlement or compensation
//               </span>
//             </button>
//             <button
//               onClick={() => handleSelectObjectives(1)}
//               className={
//                 objectives.includes(options[1])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={objectives.includes(options[1]) ? check : userShield}
//                 alt="user-shield"
//               ></img>
//               <span className="text-base font-normal">
//                 Protect my legal rights and interests
//               </span>
//             </button>
//           </div>
//           <div className="flex gap-2 justify-center">
//             <button
//               onClick={() => handleSelectObjectives(2)}
//               className={
//                 objectives.includes(options[2])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={objectives.includes(options[2]) ? check : handshake}
//                 alt="handshake"
//               ></img>
//               <span className="text-base font-normal">
//                 Resolve the matter amicably through negotiation or mediation
//               </span>
//             </button>
//             <button
//               onClick={() => handleSelectObjectives(3)}
//               className={
//                 objectives.includes(options[3])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={objectives.includes(options[3]) ? check : userCheck}
//                 alt="user-check"
//               ></img>
//               <span className="text-base font-normal">
//                 Clarify legal responsibilities and obligations
//               </span>
//             </button>
//           </div>
//           <div className="flex gap-2 justify-center">
//             <button
//               onClick={() => handleSelectObjectives(4)}
//               className={
//                 objectives.includes(options[4])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={objectives.includes(options[4]) ? check : gavel}
//                 alt="gavel"
//               ></img>
//               <span className="text-base font-normal">
//                 Seek justice and hold the responsible party accountable
//               </span>
//             </button>
//             <button
//               onClick={() => handleSelectObjectives(5)}
//               className={
//                 objectives.includes(options[5])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={objectives.includes(options[5]) ? check : buildingColumns}
//                 alt="building"
//               ></img>
//               <span className="text-base font-normal">
//                 Obtain a favorable judgment in court
//               </span>
//             </button>
//           </div>
//           <div className="flex gap-2 justify-center">
//             <button
//               onClick={() => handleSelectObjectives(6)}
//               className={
//                 objectives.includes(options[6])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={objectives.includes(options[6]) ? check : circleExclam}
//                 alt="circle-exclamation"
//               ></img>
//               <span className="text-base font-normal">
//                 Prevent further harm or recurrence of the issue
//               </span>
//             </button>
//             <input
//               placeholder="Others"
//               onInput={(e) => handleOtherObjectives(e.target.value)}
//               className="w-80 px-4 py-2.5 text-black text-base font-normal rounded border"
//               value={
//                 options.includes(state?.Page_9?.answer?.others)
//                   ? ""
//                   : state?.Page_9?.answer?.others
//               }
//             />
//           </div>
//         </div>
//       </div>

//       <div className="inline-flex">
//         <button
//           disabled={
//             objectives.length === 0 && state.Page_9.answer.others === ""
//           }
//           onClick={() => setPage("page 10")}
//           className={
//             "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
//             (objectives.length === 0 && state.Page_9.answer.others === ""
//               ? "bg-opacity-50 cursor-not-allowed"
//               : "hover:bg-green-50 hover:text-green-800")
//           }
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageTen({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;

//   //States
//   const isOptOut = state.Page_10.answer;
//   const pageTenQuestion = state?.Page_10?.question;

//   const optOutOptions = [
//     { option: "Yes", imageSrc: optionYes },
//     { option: "No", imageSrc: optionNo },
//     { option: "Unsure", imageSrc: optionUnsure },
//   ];

//   const handleIsOptOut = (e) => {
//     dispatch({ type: "CHANGE_ISOPTOUT", payload: e });
//   };

//   return (
//     <div className="flex flex-col gap-8 items-center">
//       <div className="flex flex-col gap-8 items-center">
//         <span className="text-center text-3xl font-semibold max-w-lg">
//           {pageTenQuestion}
//         </span>
//         <div className="flex gap-8">
//           {optOutOptions.map((item, index) => (
//             <button
//               key={index}
//               onClick={() => handleIsOptOut(item.option)}
//               className={
//                 isOptOut === item.option
//                   ? "w-64 h-52 flex flex-col p-6 bg-green-50 rounded-2xl gap-3 items-center justify-center"
//                   : "w-64 h-52 flex flex-col p-6 bg-gray-50 rounded-2xl gap-3 items-center justify-center hover:bg-green-50"
//               }
//             >
//               <img
//                 className="w-20 h-20"
//                 src={item.imageSrc}
//                 alt={`option-${index}`}
//               />
//               <div className="flex-col items-center gap-2 flex">
//                 <span className="text-center text-gray-900 text-lg font-semibold">
//                   {item.option}
//                 </span>
//               </div>
//             </button>
//           ))}
//         </div>
//       </div>

//       <div className="inline-flex">
//         <button
//           disabled={isOptOut === ""}
//           onClick={() => setPage("page 11")}
//           className={
//             "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font- " +
//             (isOptOut === ""
//               ? "bg-opacity-50 cursor-not-allowed"
//               : "hover:bg-green-50 hover:text-green-800")
//           }
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

// function PageEleven({ setPage, dataWrapper }) {
//   const { state, dispatch } = dataWrapper;
//   //States
//   const preferences = state.Page_11.answer.preferences;
//   const pageTenQuestion = state?.Page_11?.question;

//   const concerns = [
//     "Minimize Expenses",
//     "Finding Attorney",
//     "Effective Communication",
//     "Understand Risks",
//     "Swift Resolution",
//     "Alternative Methods",
//   ];

//   const handleSelectPreferences = (e) => {
//     if (e === "selectAll") {
//       dispatch({ type: "CHANGE_SELECTEDCONCERNS", payload: concerns });
//       return;
//     }
//     if (e.length === 1) {
//       return (e = e[0]);
//     }

//     //Converts single array to string
//     e = concerns.filter((option, index) => index === e)[0];
//     const updatedPreferences = preferences.find(
//       (preference) => preference === e
//     )
//       ? preferences.filter((preference) => preference !== e)
//       : [...(preferences || []), e];
//     dispatch({ type: "CHANGE_SELECTEDCONCERNS", payload: updatedPreferences });
//   };

//   const handleOtherPreferences = (e) => {
//     dispatch({ type: "CHANGE_SELECTEDCONCERNS_OTHERS", payload: e });
//   };

//   const options = [
//     "Minimize legal expenses and costs",
//     "Find an attorney with expertise in the relevant area of law",
//     "Ensure effective communication and regular updates throughout the process",
//     "Understand the potential risks and outcomes associated with litigation",
//     "Obtain a swift resolution to the legal matter",
//     "Explore alternative methods of dispute resolution",
//   ];

//   return (
//     <div className="flex flex-col gap-8 items-center">
//       <div className="flex flex-col gap-8 items-center">
//         <span className="text-center text-3xl font-semibold max-w-xl">
//           {pageTenQuestion}
//         </span>
//         <button
//           className="text-green-600 hover:underline"
//           onClick={() => handleSelectPreferences("selectAll")}
//         >
//           Select all 6
//         </button>

//         <div className="flex flex-col gap-2">
//           <div className="flex gap-2 justify-center">
//             <button
//               onClick={() => handleSelectPreferences(0)}
//               className={
//                 preferences.find((concern) => concern === concerns[0])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={
//                   preferences.find((concern) => concern === concerns[0])
//                     ? check
//                     : scaleBalanced
//                 }
//                 alt="scale"
//               ></img>
//               <span className="text-base font-normal">
//                 Minimize legal expenses and costs
//               </span>
//             </button>
//             <button
//               onClick={() => handleSelectPreferences(1)}
//               className={
//                 preferences.find((concern) => concern === concerns[1])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={
//                   preferences.find((concern) => concern === concerns[1])
//                     ? check
//                     : userTie
//                 }
//                 alt="user-tie"
//               ></img>
//               <span className="text-base font-normal">
//                 Find an attorney with expertise in the relevant area of law
//               </span>
//             </button>
//           </div>
//           <div className="flex gap-2 justify-center">
//             <button
//               onClick={() => handleSelectPreferences(2)}
//               className={
//                 preferences.find((concern) => concern === concerns[2])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={
//                   preferences.find((concern) => concern === concerns[2])
//                     ? check
//                     : bells
//                 }
//                 alt="bells"
//               ></img>
//               <span className="text-base font-normal">
//                 Ensure effective communication and regular updates throughout
//                 the process
//               </span>
//             </button>
//           </div>
//           <div className="flex gap-2">
//             <button
//               onClick={() => handleSelectPreferences(3)}
//               className={
//                 preferences.find((concern) => concern === concerns[3])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={
//                   preferences.find((concern) => concern === concerns[3])
//                     ? check
//                     : faceThinking
//                 }
//                 alt="thinking"
//               ></img>
//               <span className="text-base font-normal">
//                 Understand the potential risks and outcomes associated with
//                 litigation
//               </span>
//             </button>
//             <button
//               onClick={() => handleSelectPreferences(4)}
//               className={
//                 preferences.find((concern) => concern === concerns[4])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={
//                   preferences.find((concern) => concern === concerns[4])
//                     ? check
//                     : buildingColumns
//                 }
//                 alt="building"
//               ></img>
//               <span className="text-base font-normal">
//                 Obtain a swift resolution to the legal matter
//               </span>
//             </button>
//           </div>
//           <div className="flex gap-2">
//             <button
//               onClick={() => handleSelectPreferences(5)}
//               className={
//                 preferences.find((concern) => concern === concerns[5])
//                   ? "px-4 py-2.5 rounded items-center gap-2.5 inline-flex bg-green-700 text-white"
//                   : "px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black hover:bg-green-700 hover:text-white"
//               }
//             >
//               <img
//                 src={
//                   preferences.find((concern) => concern === concerns[5])
//                     ? check
//                     : search
//                 }
//                 alt="search"
//               ></img>
//               <span className="text-base font-normal">
//                 Explore alternative dispute resolution methods before pursuing
//                 litigation
//               </span>
//             </button>
//             <input
//               placeholder="Others"
//               className="w-80 px-4 py-2.5 text-black text-base font-normal rounded border"
//               onChange={(e) => handleOtherPreferences(e.target.value)}
//               value={
//                 options.includes(state?.Page_11?.answer?.others)
//                   ? ""
//                   : state?.Page_11?.answer?.others
//               }
//             />
//           </div>
//         </div>
//       </div>

//       <div className="inline-flex">
//         <button
//           disabled={
//             preferences.length === 0 && state.Page_11.answer.others === ""
//           }
//           onClick={() => setPage("done")}
//           className={
//             "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
//             (preferences.length === 0 && state.Page_11.answer.others === ""
//               ? "bg-opacity-50 cursor-not-allowed"
//               : "hover:bg-green-50 hover:text-green-800")
//           }
//         >
//           CONTINUE
//         </button>
//       </div>
//     </div>
//   );
// }

function PageTwelve({ setPage, tempStore, dataWrapper }) {
  // Testing to simulate loading, replace later
  const { isUserCompleteWithOnboarding } = dataWrapper;
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [isDoneQuerying, setIsDoneQuerying] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const loadingInterval = setInterval(moveToNextStep, 1000);

    return () => {
      clearInterval(loadingInterval);
      if (currentStep === 3 && isDoneQuerying) {
        setIsLoadingComplete(true);
      }
    };
  }, [currentStep]);

  const moveToNextStep = () => {
    if (currentStep <= 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(async () => {
    if (currentStep === 2) {
      localStorage.setItem("authState", "signup");
      await isUserCompleteWithOnboarding(tempStore.sessionId).then((res) => {
        setIsDoneQuerying(true);
      });
    }

    /* In charge for redirecting the user to the Signup Component */
    if (isLoadingComplete) {
      setTimeout(() => {
        history.push(`${AppRoutes.SIGNOUT}`);
      }, 1000);
    }
  }, [currentStep, isLoadingComplete]);

  return (
    <div className="flex flex-col gap-8 items-center">
      <div>
        {isLoadingComplete ? (
          <img src={loadingDone} alt="loading-done" />
        ) : (
          <img src={loading} alt="loading" className="w-16 h-16" />
        )}
      </div>

      <div className="flex flex-col gap-8 items-center">
        <div className="text-black text-3xl font-semibold">
          Creating your personal experience
        </div>

        {currentStep === 0 ? (
          <div className="items-center gap-2 inline-flex">
            <span className="text-black text-xl font-normal">
              Processing your legal situation...
            </span>
          </div>
        ) : (
          <div className="items-center gap-2 inline-flex">
            <img src={done} alt="step-done" />
            <span className="text-green-700 text-xl font-normal">
              Processing your legal situation
            </span>
          </div>
        )}
        {currentStep === 1 ? (
          <div className="items-center gap-2 inline-flex">
            <span className="text-black text-xl font-normal">
              Evaluating your tenancy dispute...
            </span>
          </div>
        ) : currentStep >= 2 ? (
          <div className="items-center gap-2 inline-flex">
            <img src={done} alt="step-done" />
            <span className="text-green-700 text-xl font-normal">
              Evaluating your tenancy dispute
            </span>
          </div>
        ) : null}
        {currentStep === 2 ? (
          <div className="items-center gap-2 inline-flex">
            <span className="text-black text-xl font-normal">
              Reviewing your legal concerns...
            </span>
          </div>
        ) : currentStep >= 3 ? (
          <div className="items-center gap-2 inline-flex">
            <img src={done} alt="step-done" />
            <span className="text-green-700 text-xl font-normal">
              Reviewing your legal concerns
            </span>
          </div>
        ) : null}
        {currentStep === 3 ? (
          <div className="items-center gap-2 inline-flex">
            <span className="text-black text-xl font-normal">
              Building your client matter file...
            </span>
          </div>
        ) : currentStep >= 4 ? (
          <div className="items-center gap-2 inline-flex">
            <img src={done} alt="step-done" />
            <span className="text-green-700 text-xl font-normal">
              Building your client matter file
            </span>
          </div>
        ) : null}
        {isLoadingComplete ? (
          <div className="absolute top-0 left-0 flex items-center justify-center z-40">
            <img
              className="w-screen h-screen"
              src={completeForm}
              alt="Confetti"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
