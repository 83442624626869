import React, { useRef, useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import config from "../../aws-exports";
import { FaExpandAlt } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import axios from 'axios';
import { MdOutlineMinimize } from "react-icons/md";
import { TiAttachment } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";
import { IoMdCopy, IoMdAttach } from "react-icons/io";
import { MdPersonAddAlt1 } from "react-icons/md";
import { TbArrowsDiagonalMinimize2 } from "react-icons/tb";
import { BiPencil } from "react-icons/bi";
import { BsPersonCheck } from "react-icons/bs";
import AddContactModal from "../contacts/add-contact-modal";
import {
  mOutlookDraftEmail,
  mOutlookForwardEmail,
  mOutlookSendEmail,
} from "../../shared/graphql/mutations";
import QuillEditor from "./reusableRichtext/QuillEditor";
import { ListUsers } from "../../shared/graphql/queries";
import { isMobileDevice } from "../../shared/mobileViewFunctions";
import Select from "react-select";
import isMSdoc from "../../shared/msFileTypeChecker";
import humaReadableFormat from "../../shared/humanReadableFormat";
import WebNotificationModal from "./web-notification-modal";
import MobileNotificationModal from "./mobile-notification-modal";

const DEFAULT_USERTYPES_QUERY = `
    query getDefaultUserTypes {
    defaultUserType
  }`;

const qGetFileDownloadLink = `
query getAttachmentDownloadLink($id: String) {
  gmailAttachment(id: $id) {
    downloadURL
    s3ObjectKey
    type
  }
}`;

const qOutlookGetFileDownloadLink = `query getOutlookAttachmentDownloadLink($id: String) {
  outlookAttachment(id: $id) {
    downloadURL
    name
    type
    isInline
  }
}`;
const mTagEmailClientMatter = `
mutation tagGmailMessageClientMatter($clientMatterId: ID, $gmailMessageId: String) {
  gmailMessageClientMatterTag(
    clientMatterId: $clientMatterId
    gmailMessageId: $gmailMessageId
  ) {
    id,
  }
}`;

const mTagOutlookEmailClientMatter = `mutation tagOutlookMessageClientMatter($clientMatterId: ID, $gmailMessageId: String) {
  outlookMessageClientMatterTag(
    clientMatterId: $clientMatterId
    outlookMessageId: $gmailMessageId
  ) {
    id,
  }
}`;

const mAddEmailAttachmentLabel = `
mutation saveGmailAttachmentLabel($attachmentId: String, $labelId: [ID]) {
  gmailAttachmentLabelTag(attachmentId: $attachmentId, labelId: $labelId) {
    id
  }
}`;

const mAddOutlookAttachmentLabel = `mutation saveOutlookAttachmentLabel($attachmentId: String, $labelId: [ID]) {
  outlookAttachmentLabelTag(attachmentId: $attachmentId, labelId: $labelId) {
    id
  }
}`;

const mAddEmailLabel = `
  mutation saveGmailMessageLabel($gmailMessageId: String, $labelId: [ID]) {
    gmailMessageLabelTag(labelId: $labelId, gmailMessageId: $gmailMessageId) {
      id
    }
  }`;

const mAddEmailLabelOutlook = `mutation saveOutlookMessageLabel($gmailMessageId: String, $labelId: [ID]) {
    outlookMessageLabelTag(labelId: $labelId, outlookMessageId: $gmailMessageId) {
      id
    }
  }`;

const mTagFileLabel = `
mutation tagFileLabel($fileId: ID, $labels: [LabelInput]) {
  fileLabelTag(file: {id: $fileId}, label: $labels) {
    file {
      id
    }
  }
}`;

const mUpdateBackgroundFile = `
    mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
      backgroundFileTag(backgroundId: $backgroundId, files: $files) {
        id
      }
    }
  `;

const TASKLIST_DATA_COUNT = `
    query getTaskListsCount($companyId: ID!) {
      taskListDataCount(companyId: $companyId) {
        status
        count
      }
    }`;

const qGetMessage = `query getMessageByMessageId($id: ID) {
      gmailMessage(id: $id) {
        id
        from
        to
        cc
        bcc
        subject
        date
        snippet
        labels {
          items {
            id
            name
          }
        }
        gmailLabelIds
        description
        taskList {
          id
          isDeleted
        }
        clientMatters {
          items {
            id
            client {
              id
              name
            }
            matter {
              id
              name
            }
            contacts {
              items {
                type
                details {
                  id
                  email
                  name
                  number
                }
              }
            }
          }
        }
        attachments {
          items {
            id
            details
            name
            s3ObjectKey
            size
            type
            labels {
              items {
                id
                name
              }
            }
            isDeleted
          }
        }
        assignee
        priority
        notes
        receivedAt
        internalDate
      }
    }
    `;

const qGetOutlookMessage = `query getMessageByMessageId($id: ID) {
  outlookMessage(id: $id) {
    assignee
    bcc
    cc
    attachments {
      items {
        id
        details
        name
        s3ObjectKey
        size
        type
        isDeleted
        isInline
        labels {
          items {
            id
            name
          }
        }
      }
    }
    date
    description
    from
    outlookLabelIds
    id
    internalDate
    notes
    priority
    receivedAt
    snippet
    subject
    to
    threadId
    labels {
      items {
        id
        name
      }
    }
    taskList {
      id
      isDeleted
    }
    clientMatters {
      items {
        id
        client {
          id
          name
        }
        matter {
          id
          name
        }
      }
    }
  }
}`;

const FILE_LABEL_TAG = `mutation tagFileLabel($fileId: ID, $labels: [LabelInput]) {
  fileLabelTag(file: {id: $fileId}, label: $labels) {
      file {
        id
      }
    }
  }`;

const mCreateMatterFile = `
mutation createMatterFile ($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int, $isGmailPDF: Boolean, $isGmailAttachment: Boolean, $gmailMessageId: String, $details: String, $date: AWSDateTime) {
  matterFileCreate(matterId: $matterId, s3ObjectKey: $s3ObjectKey, size: $size, type: $type, name: $name, order: $order, isGmailPDF: $isGmailPDF, isGmailAttachment: $isGmailAttachment, gmailMessageId: $gmailMessageId, details: $details, date: $date) {
    id
    name
    order
  }
}
`;

const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

const BRIEF_BY_NAME_QUERY = `
query getBriefByName($clientMatterId: ID, $name: String) {
  briefByName(clientMatterId: $clientMatterId, name: $name) {
    id
    labelId
  }
}
`;

const BRIEF_CREATE = `
  mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }
  `;

const BACKGROUND_TABLE_QUERY = `
  query getBackgroundTable($clientMatterId: ID!) {
      backgroundTable(clientMatterId: $clientMatterId) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
          presets {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
  }`;

const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }
  `;

const BRIEF_UPDATE = `
  mutation updateBrief($id: ID, $labelId: ID) {
    briefUpdate(id: $id, labelId: $labelId) {
      id
    }
  }
  `;

const BACKGROUND_CREATE = `
    mutation createBackground($description: String,$comments:String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
      backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
        id
      }
    }
  `;

const BACKGROUND_FILE_TAG = `
    mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
      backgroundFileTag(backgroundId: $backgroundId, files: $files) {
        id
      }
    }
  `;

const mSaveAttachmentEmailsToMatter = `
  mutation createMatterFile($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int, $isGmailAttachment: Boolean, $isGmailPDF: Boolean, $gmailMessageId: String, $date: AWSDateTime, $details: String) {
    matterFileCreate(
      matterId: $matterId
      s3ObjectKey: $s3ObjectKey
      size: $size
      type: $type
      name: $name
      order: $order
      isGmailAttachment: $isGmailAttachment
      isGmailPDF: $isGmailPDF
      gmailMessageId: $gmailMessageId
      details: $details
      date: $date
    ) {
      id
      name
      order
    }
  }`;

const Compose = ({
  selectedIsReplyDetails,
  onClose,
  visible,
  refreshToken,
  from,
  reSubject,
  isReply,
  setIsReply,
  threadId,
  messageId,
  selectedRawHtml,
  setSelectedRawHtml,
  width,
  isUpdatedClientMatter,
  isOutlookIntegrated,
}) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  // const [originalContactList, setOriginalContactList] = useState([]);
  const [searchEmailTo, setSearchEmailTo] = useState("");
  const [searchEmailCc, setSearchEmailCc] = useState("");
  const [searchEmailBcc, setSearchEmailBcc] = useState("");
  const inputRefTo = useRef(null);
  const inputRefCc = useRef(null);
  const inputRefBcc = useRef(null);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [htmlMessage, setHtmlMessage] = useState("");
  const [forwardDetails, setForwardDetails] = useState("");
  const [fromUnTrimed, setFromUntrimed] = useState("");
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [status, setStatus] = useState("Sending Email ..");
  const inputRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const expandedMessageRef = useRef();
  const messageRef = useRef();
  const [mentionableNames, setMentionableNames] = useState([]);
  const [composeType, setComposeType] = useState({
    value: 0,
    label: "New Email",
  });
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [bodyContent, setBodyContent] = useState("");

  useEffect(() => {
    if (threadId === "") {
      console.log("New Compose Email");
    }
    if (threadId !== "") {
      console.log("Reply/Forward Compose Email");
      setComposeType({ value: 1, label: "Reply" });
    }
  }, [threadId]);

  const [contactOption, setContactOption] = useState({
    value: 1,
    label: "Select",
  });
  const [updatedEmailDetails, setUpdatedEmailDetails] = useState();
  var moment = require("moment");

  let emailChecker =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [contacts, setContacts] = useState([]);
  const [editTo, setEditTo] = useState(false);
  const [editCc, setEditCc] = useState(false);
  const [editBcc, setEditBcc] = useState(false);
  const [isCc, setIsCc] = useState(false);
  const [isBcc, setIsBcc] = useState(false);

  const [isSavingClientMatter, setIsSavingClientMatter] = useState(false);
  const [reSaveGmailMessageId, setReSaveGmailMessageId] = useState("");
  const [UserTypes, setUserTypes] = useState([]);

  let getContacts = async () => {
    try {
      await ListUsers(localStorage.getItem("companyId"))
        .then((companyUsers) => {
          console.log("usersssss", companyUsers);
          var temp = companyUsers.data.company.users.items;
          temp.sort((a, b) => a.firstName.localeCompare(b.firstName));
          temp.map(
            (x) =>
              (x.firstName =
                x.firstName.charAt(0).toUpperCase() +
                x.firstName.slice(1).toLowerCase())
          );
          console.log(temp, "<-contact list");
          temp.map((x) => {
            setContacts((old) => [
              ...old,
              {
                name: `${x.firstName} ${x.lastName}`,
                emailAddress: `${x.email}`,
              },
            ]);
          });
        })
        .catch((error) => console.log("ERROR", error));
    } catch (e) {
      console.log("ERROR getContacts", e);
    }
  };

  async function ForwardEmail() {
    let toEmailAddresses = to.map((userDetails) => userDetails.emailAddress);
    let ccEmailAddresses = cc.map((userDetails) => userDetails.emailAddress);
    let bccEmailAddresses = bcc.map((userDetails) => userDetails.emailAddress);
    const cleanseHtmlMessage = htmlMessage.replace(
      htmlMessage.substring(
        htmlMessage.lastIndexOf("---------- Forwarded message ---------"),
        htmlMessage.lastIndexOf(">") + 1
      ),
      " "
    );
    const forwardHtmlMessage =
      cleanseHtmlMessage +
      forwardDetails +
      `<br> <br> <p>${selectedRawHtml}</p>`;
    let bufferHtmlMessage = Buffer.from(forwardHtmlMessage, "utf8").toString(
      "base64"
    );
    console.log("FORWARD HTML MESSAGE!", forwardHtmlMessage);
    let bufferMessage = Buffer.from(message, "utf8").toString("base64");

    if (composeType.label === "Forward") {
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: "Send an email reply type",
          appModule: "INBOX",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log("add activity result", result, composeType.label)
      );
    }

    return new Promise((resolve, reject) => {

      try {
        const outLookParams = {
          query: mOutlookForwardEmail,
          variables: {
            subject: subject,
            to: toEmailAddresses,
            cc: ccEmailAddresses,
            bcc: bccEmailAddresses,
            attachments: attachments,
            html: bufferHtmlMessage,
            refreshToken: refreshToken,
            forwardEmail: messageId,
          },
        };

        if (isOutlookIntegrated) {
          const request = API.graphql(outLookParams);
          resolve(request);
        } else {
          const data =  {
            from: fromUnTrimed,
            to: toEmailAddresses,
            cc: ccEmailAddresses,
            bcc: bccEmailAddresses,
            subject: subject,
            message: bufferMessage,
            attachments: attachments,
            html: bufferHtmlMessage,
            refreshToken: refreshToken,
            type: "forward"
          }
          const options = {
            headers: {
              'Content-Type' : 'application/json',
            },
          }
          axios.post(
            `${process.env.REACT_APP_GMAIL_COMPOSE_ENDPOINT}`,
            JSON.stringify(data),
            options
          ).then((res) =>{
            const result =  {
              data: {
                gmailForwardEmail : res.data
              }
            }
            resolve(result);
          });
        }

        // console.log("Running email params", params);

        // const request = API.graphql(params);
        // resolve(request);
      } catch (e) {
        reject(e.message);
      }
    });
  }

  //MMA 1883 Get the latest details of email when updating Client Matter on Email preview
  const handleGetUpdatedEmail = async (gmailMessageId) => {
    if (!isUpdatedClientMatter) {
      console.log("selected", selectedIsReplyDetails);
      setUpdatedEmailDetails(selectedIsReplyDetails);
    } else {
      const params = {
        query: isOutlookIntegrated ? qGetOutlookMessage : qGetMessage,
        variables: {
          id: gmailMessageId,
        },
      };
      let result = await API.graphql(params);
      const message = isOutlookIntegrated
        ? result.data.outlookMessage
        : result.data.gmailMessage;
      console.log("Updated Email info", message);
      setUpdatedEmailDetails(message);
    }
  };

  async function SendEmail() {
    let toEmailAddresses = to.map((userDetails) => userDetails.emailAddress);
    let ccEmailAddresses = cc.map((userDetails) => userDetails.emailAddress);
    let bccEmailAddresses = bcc.map((userDetails) => userDetails.emailAddress);
    let threadId = selectedIsReplyDetails?.id;
    let bufferHtmlMessage = "";
    let bufferMessage = "";
    if (isReply && composeType.label === "Reply") {
      const to = selectedIsReplyDetails?.to;
      const from = selectedIsReplyDetails?.from;
      const FromName = from.substring(0, from.lastIndexOf("<"));
      const FromEmail = from.includes("<")
        ? from.substring(from.lastIndexOf("<") + 1, from.lastIndexOf(">"))
        : from;
      const date = moment(selectedIsReplyDetails?.date).format(
        isOutlookIntegrated
          ? "DD MMMM YYYY HH:mm"
          : "ddd, MMM D, YYYY, [at] hh:mm A"
      );
      const subject = selectedIsReplyDetails?.subject;

      let filteredToText = "";
      if (isOutlookIntegrated) {
        let arrOfTo = Array.from(to.matchAll(/([^<]+)<([^>]+)>/g), (match) => ({
          name: match[1].trim(),
          email: match[2].trim(),
        }));
        let formattedArr = arrOfTo.map(
          (e) =>
            `${e.name} &lt;<a href="mailto:${e.email}" target="_blank">${e.email}</a>&gt;`
        );
        filteredToText = formattedArr.join(", ");
      }

      let ForwardDetailsOutlook = `<hr style="display:inline-block;width:98%"><div><strong>From:</strong>&nbsp;${FromName}&lt;<a href="mailto:${FromEmail}" target="_blank">${FromEmail}</a>&gt;</div><div><strong>Sent:</strong>&nbsp;${date}</div><div><strong>To:</strong>&nbsp;${filteredToText};</div><div><strong>Subject:</strong>&nbsp;${subject}</div><div>&nbsp;</div><div>${selectedRawHtml}</div>`;

      const replyHtmlMessage =
        htmlMessage +
        (isOutlookIntegrated
          ? ForwardDetailsOutlook
          : `<br> <div class="gmail_quote"> On ${moment(
              selectedIsReplyDetails?.date
            ).format(
              "ddd, MMM D, YYYY, [at] hh:mmA"
            )} ${FromName} <span dir="auto">&lt;<a href="mailto:${FromEmail}">${FromEmail}</a>&gt;</span>wrote: <br> <blockquote class="gmail_quote" style="margin: 0px 0px 0px 0.8ex; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex;"><div dir="ltr">${selectedRawHtml}</div></blockquote> </div> <br>`);
      console.log("REPLY HTML MESSAGE!", replyHtmlMessage);
      bufferHtmlMessage = Buffer.from(replyHtmlMessage, "utf8").toString(
        "base64"
      );
      bufferMessage = Buffer.from(message, "utf8").toString("base64");
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: "Send an email reply type",
          appModule: "INBOX",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log("add activity result", result)
      );
    } else {
      bufferHtmlMessage = Buffer.from(htmlMessage, "utf8").toString("base64");
      bufferMessage = Buffer.from(message, "utf8").toString("base64");
      // log activity
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: "Send an email not reply",
          appModule: "INBOX",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log("add activity result", result)
      );
    }


    if (!isReply) {
      return new Promise((resolve, reject) => {
        try {
          const outLookParams = {
                query: mOutlookSendEmail,
                variables: {
                  to: toEmailAddresses,
                  cc: ccEmailAddresses,
                  bcc: bccEmailAddresses,
                  subject: subject,
                  attachments: attachments,
                  html: bufferHtmlMessage,
                  refreshToken: refreshToken,
                },
              };


          console.log("Running email params", outLookParams);
          if (isOutlookIntegrated) {
            const request = API.graphql(outLookParams);
            resolve(request);
          } else {
            const data =  {
              from: fromUnTrimed,
              to: toEmailAddresses,
              cc: ccEmailAddresses,
              bcc: bccEmailAddresses,
              subject: subject,
              message: bufferMessage,
              attachments: attachments,
              html: bufferHtmlMessage,
              refreshToken: refreshToken,
              type: "send"
            }
            const options = {
              headers: {
                'Content-Type' : 'application/json',
              },
            }
            axios.post(
              `${process.env.REACT_APP_GMAIL_COMPOSE_ENDPOINT}`,
              JSON.stringify(data),
              options
            ).then((res) =>{
              const result =  {
                data: {
                  gmailSendEmail : res.data
                }
              }
              resolve(result);
            });
          }

          // console.log("Running email params", params);
          // const result = API.graphql(params);
          // console.log(result, '<- Request')
          // resolve(result);

        } catch (e) {
          reject(e.message);
        }
      });
    }

    if (isReply) {
      console.log("threadId ->", threadId);

      return new Promise((resolve, reject) => {
        try {
          const outLookParams = {
                query: mOutlookSendEmail,
                variables: {
                  to: toEmailAddresses,
                  cc: ccEmailAddresses,
                  bcc: bccEmailAddresses,
                  subject: subject,
                  attachments: attachments,
                  html: bufferHtmlMessage,
                  refreshToken: refreshToken,
                  inReplyTo: messageId,
                },
              };

          console.log("Running email params", outLookParams);
          if (isOutlookIntegrated) {
            const request = API.graphql(outLookParams);
            resolve(request);
          } else {
            const data =  {
              from: fromUnTrimed,
              to: toEmailAddresses,
              cc: ccEmailAddresses,
              bcc: bccEmailAddresses,
              subject: subject,
              message: bufferMessage,
              attachments: attachments,
              html: bufferHtmlMessage,
              refreshToken: refreshToken,
              threadId: threadId,
              inReplyTo: messageId,
              references: messageId,
              type: "reply"
            }
            const options = {
              headers: {
                'Content-Type' : 'application/json'
              },
            }
            axios.post(
              `${process.env.REACT_APP_GMAIL_COMPOSE_ENDPOINT}`,
              JSON.stringify(data),
              options
            ).then((res) =>{
              const result =  {
                data: {
                  gmailSendEmail : res.data
                }
              }
              resolve(result);
            });
          }

          // console.log("Running email params", params);
          // const result = API.graphql(params);
          // resolve(result);

        } catch (e) {
          reject(e.message);
        }
      });
    }
  }

  async function SaveDraftsEmail() {
    let toEmailAddresses = to.map((userDetails) => userDetails.emailAddress);
    let ccEmailAddresses = cc.map((userDetails) => userDetails.emailAddress);
    let bccEmailAddresses = bcc.map((userDetails) => userDetails.emailAddress);
    let bufferHtmlMessage = Buffer.from(htmlMessage, "utf8").toString("base64");
    let forwardEmail = null;
    let inReplyTo = null;

    if (composeType.label === "Forward") {
      const forwardHtmlMessage =
        htmlMessage + `<br> <br> <p>${selectedRawHtml}</p>`;
      bufferHtmlMessage = Buffer.from(forwardHtmlMessage, "utf8").toString(
        "base64"
      );
      forwardEmail = messageId;

      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: "Save an email to drafts forward",
          appModule: "INBOX",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log("add activity result", result)
      );
    }

    if (composeType.label === "Reply") {
      bufferHtmlMessage = Buffer.from(htmlMessage, "utf8").toString("base64");
      inReplyTo = messageId;
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: "Send an email to drafts reply",
          appModule: "INBOX",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log("add activity result", result)
      );
    }

    let bufferMessage = Buffer.from(message, "utf8").toString("base64");

    return new Promise((resolve, reject) => {
      try {
        const outLookParams = {
              query: mOutlookDraftEmail,
              variables: {
                to: toEmailAddresses,
                cc: ccEmailAddresses,
                bcc: bccEmailAddresses,
                subject: subject,
                attachments: attachments,
                html: bufferHtmlMessage,
                refreshToken: refreshToken,
                inReplyTo: inReplyTo,
                forwardEmail: forwardEmail,
                isDraft: true,
              },
            };

        if (isOutlookIntegrated) {
          const request = API.graphql(outLookParams);
          resolve(request);
        } else {
          const data =  {
            from: fromUnTrimed,
            to: toEmailAddresses,
            cc: ccEmailAddresses,
            bcc: bccEmailAddresses,
            subject: subject,
            message: bufferMessage,
            attachments: attachments,
            html: bufferHtmlMessage,
            refreshToken: refreshToken,
            threadId: threadId,
            inReplyTo: messageId,
            references: messageId,
            type: "draft"
          }
          const options = {
            headers: {
              'Content-Type' : 'application/json',
            },
          }
          axios.post(
            `${process.env.REACT_APP_GMAIL_COMPOSE_ENDPOINT}`,
            JSON.stringify(data),
            options
          ).then((res) =>{
            const result =  {
              data: {
                gmailForwardEmail : res.data
              }
            }
            resolve(result);
          });
        }
        // console.log("Running email params", params);
        // const request = API.graphql(params);

        // resolve(request);
        handleClear();
      } catch (e) {
        reject(e.message);
        handleClear();
      }
    });
  }

  const handleSendEmail = async (e) => {
    console.log(to, "<- To");
    console.log(cc, "<- Cc");
    console.log(bcc, "<- Bcc");
    console.log(message, "<- Message");
    console.log(refreshToken, "<- RefreshToken");
    console.log(subject, "<- Subject");
    attachments.map(function (item) {
      delete item.size;
      delete item.percent;
      return item;
    });
    console.log(attachments, "<- Attachments");
    console.log(mentionableNames, "<-mentionableNames");
    console.log(htmlMessage, "<- Html base64");
    setIsSendingEmail(true);

    const result = await SendEmail();
    const statusCode = isOutlookIntegrated
      ? result?.data?.outlookSendEmail?.statusCode
      : result?.data?.gmailSendEmail?.statusCode;
    const resultMessageId = isOutlookIntegrated
      ? result?.data?.outlookSendEmail?.messageId
      : result?.data?.gmailSendEmail?.messageId;
    console.log("send result", result);

    if (isReply && result && statusCode === 200) {
      saveClientMatter(resultMessageId);
      //To do toast
    }

    if (!isReply && result && statusCode === 200) {
      //To do toast
      handleClear();
    }

    if (isReply && result && statusCode === 400) {
      //To do toast
      handleClear();
    }

    if (!isReply && result && statusCode === 400) {
      //To do toast
      handleClear();
    }
  };

  //MMA 1884
  //To modify send/reply/forward API to return the thread Id, (Required to add label)

  const createBackgroundFromLabel = async (
    matter_id,
    row_id,
    attachDetails,
    attachDate,
    label,
    isNew,
    attachments,
    gmailMessageId,
    dateEmail
  ) => {
    // check if brief already exists
    let briefNameExists = false;
    const getBriefByName = await API.graphql({
      query: BRIEF_BY_NAME_QUERY,
      variables: {
        clientMatterId: matter_id,
        name: label.name,
      },
    });

    let briefId = getBriefByName.data.briefByName.id,
      existingBriefNameLabel = getBriefByName.data.briefByName.labelId;

    if (briefId !== "" && briefId !== null) {
      briefNameExists = true;
    }

    if (isNew) {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
          columnIds: [0, 1, 2, 3, 4],
        };

        const createBrief = await API.graphql({
          query: BRIEF_CREATE,
          variables: params,
        });

        briefId = createBrief.data.briefCreate.id;

        const backgroundTable = API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj.presets.filter((preset) => preset.id !== briefId),
                { id: briefId, name: label.name },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });
        }
      } else {
        if (existingBriefNameLabel === null) {
          const params = {
            id: briefId,
            labelId: label.id,
          };

          await API.graphql({
            query: BRIEF_UPDATE,
            variables: params,
          });
        }
      }

      const fileId = row_id,
        fileDetails = attachDetails,
        fileDate =
          attachDate != null
            ? moment
                .utc(moment(new Date(attachDate), "YYYY-MM-DD"))
                .toISOString()
            : null;

      const tempBriefDetails = [{ id: briefId, name: label.name }];

      // Create Background
      const createBackground = await API.graphql({
        query: BACKGROUND_CREATE,
        variables: {
          briefs: tempBriefDetails,
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      if (createBackground.data.backgroundCreate.id !== null) {
        // Tag File to Background
        await API.graphql({
          query: BACKGROUND_FILE_TAG,
          variables: {
            backgroundId: createBackground.data.backgroundCreate.id,
            files: [{ id: fileId }],
          },
        });

        // Adding Attachments to File Bucket
        attachments.items.map(async (attachment) => {
          if (attachment.isDeleted !== true) {
            await API.graphql({
              query: mSaveAttachmentEmailsToMatter,
              variables: {
                matterId: matter_id,
                s3ObjectKey: attachment.s3ObjectKey,
                size: attachment.size,
                name: attachment.name,
                type: attachment.type,
                order: 0,
                isGmailAttachment: true,
                isGmailPDF: false,
                gmailMessageId: gmailMessageId,
                details: attachment.details,
                date: new Date(dateEmail).toISOString(),
              },
            }).then(async (result) => {
              await API.graphql({
                query: BACKGROUND_FILE_TAG,
                variables: {
                  backgroundId: createBackground.data.backgroundCreate.id,
                  files: [{ id: result.data.matterFileCreate.id }],
                },
              });

              await API.graphql({
                query: FILE_LABEL_TAG,
                variables: {
                  fileId: result.data.matterFileCreate.id,
                  labels: attachment.labels.items,
                },
              });
            });
          }
        });
      }
    } else {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
        };

        // Create Brief
        const createBrief = await API.graphql({
          query: BRIEF_CREATE,
          variables: params,
        });

        briefId = createBrief.data.briefCreate.id;

        const backgroundTable = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj.presets.filter((preset) => preset.id !== briefId),
                { id: briefId, name: label.name },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });
        }
      } else {
        if (existingBriefNameLabel === null) {
          // Tag Label to Brief
          await API.graphql({
            query: BRIEF_UPDATE,
            variables: {
              id: briefId,
              labelId: label.id,
            },
          });
        }
      }

      const fileId = row_id,
        fileDetails = attachDetails,
        fileDate =
          attachDate != null
            ? moment
                .utc(moment(new Date(attachDate), "YYYY-MM-DD"))
                .toISOString()
            : null;

      // Create Background
      const tempBriefDetails = [{ id: briefId, name: label.name }];

      // Create Background
      const createBackground = await API.graphql({
        query: BACKGROUND_CREATE,
        variables: {
          briefs: tempBriefDetails,
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      if (createBackground.data.backgroundCreate.id !== null) {
        let attachmentId = "";
        let newId = [];
        const listAttachmentIds = attachments.items.map(async (attachment) => {
          if (attachment.isDeleted !== true) {
            let ids = await API.graphql({
              query: mSaveAttachmentEmailsToMatter,
              variables: {
                matterId: matter_id,
                s3ObjectKey: attachment.s3ObjectKey,
                size: attachment.size,
                name: attachment.name,
                type: attachment.type,
                order: 0,
                isGmailAttachment: true,
                isGmailPDF: false,
                gmailMessageId: gmailMessageId,
                details: attachment.details,
                date: new Date(dateEmail).toISOString(),
              },
            }).then(async (result) => {
              await API.graphql({
                query: FILE_LABEL_TAG,
                variables: {
                  fileId: result.data.matterFileCreate.id,
                  labels: attachment.labels.items,
                },
              });

              attachmentId = result.data.matterFileCreate.id;
              newId = { id: attachmentId };

              return newId;
            });
            return ids;
          }
        });

        Promise.all(listAttachmentIds)
          .then(async (results) => {
            const filteredResults = results.filter((value) => {
              return value !== undefined;
            });

            await API.graphql({
              query: BACKGROUND_FILE_TAG,
              variables: {
                backgroundId: createBackground.data.backgroundCreate.id,
                files: filteredResults,
              },
            }).then((res) => {
              console.log("Attachment saved to backgrounds: ", res);
            });
          })
          .catch((error) => {
            console.error("Error saving attachments: ", error);
          });
      }
    }
  };

  const handleUploadGmailEmail = async (
    gmailMessageId,
    description,
    fileName,
    from,
    to,
    cc,
    dateEmail,
    matterId,
    labels,
    clientName,
    matterName,
    assignee,
    priority,
    notes,
    attachments,
    taskListNextUniqueId
  ) => {
    const params = {
      query: mCreateMatterFile,
      variables: {
        matterId: matterId,
        s3ObjectKey: null,
        size: 0,
        name: fileName,
        type: null,
        order: 0,
        isGmailPDF: true,
        isGmailAttachment: true,
        gmailMessageId: gmailMessageId,
        details: description,
        date: new Date(dateEmail).toISOString(),
      },
    };

    const result = await API.graphql(params);

    await Promise.all(
      labels.items.map((label) => {
        return createBackgroundFromLabel(
          matterId,
          result.data.matterFileCreate.id,
          description,
          dateEmail,
          {
            id: label.id,
            name: label.name,
          },
          false,
          attachments,
          gmailMessageId,
          dateEmail
        );
      })
    );

    await API.graphql({
      query: FILE_LABEL_TAG,
      variables: {
        fileId: result.data.matterFileCreate.id,
        labels: labels.items,
      },
    });

    setStatus("Successfully Saved in its Client Matter");
    handleClear();
  };

  const reSaveClientMatter = async (gmailMessageId) => {
    // WAIT 1s BETWEEN CHECKING FOR RESAVE
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const companyId = localStorage.getItem("companyId");
    const params = {
      query: isOutlookIntegrated ? qGetOutlookMessage : qGetMessage,
      variables: {
        id: gmailMessageId,
      },
    };
    await API.graphql(params).then(async (res) => {
      console.log(res, "Response");
      let messageResult = isOutlookIntegrated
        ? res.data?.outlookMessage
        : res.data?.gmailMessage;
      if (messageResult !== null && messageResult.id) {
        const sentEmailInfo = messageResult;
        // Retrieve task list data count

        const batchAPICalls = async (apiCalls) => {
          const results = await Promise.all(apiCalls);
          return results;
        };

        const tl = API.graphql({
          query: TASKLIST_DATA_COUNT,
          variables: {
            companyId,
          },
        });
        const tlDataCount = tl?.data?.taskListDataCount?.find(
          (obj) => obj.status === "total"
        );

        const taskListNextUniqueId = tlDataCount?.count
          ? parseInt(tlDataCount?.count, 10)
          : null;

        const selectedEmails = [sentEmailInfo];
        const chunkSize = 50;
        const emailChunks = [];
        const handleUploadResults = [];

        const processEmail = async (item) => {
          const emailAPIPromises = item.clientMatters.items.map(
            async (clientMatters, index) => {
              const clientMatterId = clientMatters.id;
              const clientName = clientMatters.client.name;
              const matterName = clientMatters.matter.name;

              var loopCnt = index + 1;
              var tlUniqueId = taskListNextUniqueId + loopCnt;

              handleUploadResults.push(
                handleUploadGmailEmail(
                  item.id,
                  item.description,
                  item.subject,
                  item.from,
                  item.to,
                  item.cc,
                  item.date,
                  clientMatterId,
                  item.labels,
                  clientName,
                  matterName,
                  item.assignee,
                  item.priority,
                  item.notes,
                  item.attachments,
                  tlUniqueId
                )
              );

              const attachmentAPIPromises = item.attachments.items.map(
                async (attachment) => {
                  if (!attachment.isDeleted) {
                    // Save attachment to matter
                    const result = await API.graphql({
                      query: mSaveAttachmentEmailsToMatter,
                      variables: {
                        // ... attachment variables ...
                      },
                    });

                    handleUploadResults.push(result);

                    // Apply labels to the attachment
                    handleUploadResults.push(
                      API.graphql({
                        query: FILE_LABEL_TAG,
                        variables: {
                          // ... label variables ...
                        },
                      })
                    );
                  }
                }
              );

              // Batch attachment API calls for better performance
              const attachmentResults = await batchAPICalls(
                attachmentAPIPromises
              );
              handleUploadResults.push(...attachmentResults);
            }
          );

          // Batch email API calls for better performance
          const emailResults = await batchAPICalls(emailAPIPromises);
          handleUploadResults.push(...emailResults);
        };

        for (let i = 0; i < selectedEmails.length; i += chunkSize) {
          emailChunks.push(selectedEmails.slice(i, i + chunkSize));
        }

        // Process email chunks in parallel
        await Promise.all(
          emailChunks.map((chunk) => Promise.all(chunk.map(processEmail)))
        );

        setIsSavingClientMatter(false);
        setReSaveGmailMessageId("");
      } else {
        reSaveClientMatter(gmailMessageId);
      }
    });
  };

  const saveClientMatter = async (gmailMessageId) => {
    console.log(updatedEmailDetails, "<-details");
    const clientMatterId = updatedEmailDetails.clientMatters?.items[0]?.id;
    const companyId = localStorage.getItem("companyId");
    console.log("updated", clientMatterId);

    if (clientMatterId) {
      setIsMinimized(true);
      setIsExpanded(false);
      const labels = updatedEmailDetails.labels.items.map((a) => a.id);
      const attachments = updatedEmailDetails.attachments?.items;
      await API.graphql({
        query: isOutlookIntegrated
          ? mTagOutlookEmailClientMatter
          : mTagEmailClientMatter,
        variables: {
          clientMatterId: clientMatterId,
          gmailMessageId: gmailMessageId,
        },
      }).then(async function (result) {
        attachments.map(async (item, index) => {
          let label = item.labels.items;
          let labelsIds = label.map((a) => a.id);
          const result = await API.graphql({
            query: isOutlookIntegrated
              ? mAddOutlookAttachmentLabel
              : mAddEmailAttachmentLabel,
            variables: {
              labelId: labelsIds,
              attachmentId: item.id,
            },
          });
        });
        setStatus("Saving to Client/Matter ...");
        await API.graphql({
          query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
          variables: {
            labelId: labels,
            gmailMessageId: gmailMessageId,
          },
        }).then(async (result) => {
          console.log("Result add email label", result);
        });
      });

      const params = {
        query: isOutlookIntegrated ? qGetOutlookMessage : qGetMessage,
        variables: {
          id: gmailMessageId,
        },
      };
      console.log("params", params);
      let result = await API.graphql(params);
      console.log("Email info to be added in background", result);

      let messageResult = isOutlookIntegrated
        ? result.data?.gmailMessage
        : result.data?.outlookMessage;
      if (messageResult === null) {
        setIsSavingClientMatter(true);
        setReSaveGmailMessageId(gmailMessageId);
      }
      if (messageResult !== null) {
        reSaveClientMatter(gmailMessageId);
      }

      //Revised proccess of saving client matter MMA 1994
      // let intervalId = setInterval(async function () {
      //   let counterQueries = 0;
      //   const res = await API.graphql(params);
      //   console.log(res, "<- get Data");
      //   if (res.data?.gmailMessage !== null && res.data?.gmailMessage.id) {
      //     const sentEmailInfo = res.data?.gmailMessage;
      //     // Retrieve task list data count

      //     const batchAPICalls = async (apiCalls) => {
      //       const results = await Promise.all(apiCalls);
      //       return results;
      //     };

      //     const tl = API.graphql({
      //       query: TASKLIST_DATA_COUNT,
      //       variables: {
      //         companyId,
      //       },
      //     });
      //     const tlDataCount = tl?.data?.taskListDataCount?.find(
      //       (obj) => obj.status === "total"
      //     );

      //     const taskListNextUniqueId = tlDataCount?.count
      //       ? parseInt(tlDataCount?.count, 10)
      //       : null;

      //     const selectedEmails = [sentEmailInfo];
      //     const chunkSize = 50;
      //     const emailChunks = [];
      //     const handleUploadResults = [];

      //     const processEmail = async (item) => {
      //       const emailAPIPromises = item.clientMatters.items.map(
      //         async (clientMatters, index) => {
      //           const clientMatterId = clientMatters.id;
      //           const clientName = clientMatters.client.name;
      //           const matterName = clientMatters.matter.name;

      //           var loopCnt = index + 1;
      //           var tlUniqueId = taskListNextUniqueId + loopCnt;

      //           handleUploadResults.push(
      //             handleUploadGmailEmail(
      //               item.id,
      //               item.description,
      //               item.subject,
      //               item.from,
      //               item.to,
      //               item.cc,
      //               item.date,
      //               clientMatterId,
      //               item.labels,
      //               clientName,
      //               matterName,
      //               item.assignee,
      //               item.priority,
      //               item.notes,
      //               item.attachments,
      //               tlUniqueId
      //             )
      //           );

      //           const attachmentAPIPromises = item.attachments.items.map(
      //             async (attachment) => {
      //               if (!attachment.isDeleted) {
      //                 // Save attachment to matter
      //                 const result = await API.graphql({
      //                   query: mSaveAttachmentEmailsToMatter,
      //                   variables: {
      //                     // ... attachment variables ...
      //                   },
      //                 });

      //                 handleUploadResults.push(result);

      //                 // Apply labels to the attachment
      //                 handleUploadResults.push(
      //                   API.graphql({
      //                     query: FILE_LABEL_TAG,
      //                     variables: {
      //                       // ... label variables ...
      //                     },
      //                   })
      //                 );
      //               }
      //             }
      //           );

      //           // Batch attachment API calls for better performance
      //           const attachmentResults = await batchAPICalls(
      //             attachmentAPIPromises
      //           );
      //           handleUploadResults.push(...attachmentResults);
      //         }
      //       );

      //       // Batch email API calls for better performance
      //       const emailResults = await batchAPICalls(emailAPIPromises);
      //       handleUploadResults.push(...emailResults);
      //     };

      //     for (let i = 0; i < selectedEmails.length; i += chunkSize) {
      //       emailChunks.push(selectedEmails.slice(i, i + chunkSize));
      //     }

      //     // Process email chunks in parallel
      //     await Promise.all(
      //       emailChunks.map((chunk) => Promise.all(chunk.map(processEmail)))
      //     );

      //     clearInterval(intervalId);
      //   }
      //   if (counterQueries == 4) {
      //     setStatus("Failed to Save Client Matter");
      //     if (counterQueries === 5) {
      //       clearInterval(intervalId);
      //     }
      //   }
      //   counterQueries = counterQueries + 1;
      //   console.log("Loading, Getting resources of Message id");
      //   //From 10 secs of getting the email resources, Minimized it and use 7 secs for much more faster experience and response.
      // }, 7000);
    } else {
      handleClear();
    }
  };

  const handleForwardEmail = async (e) => {
    console.log(to, "<- To");
    console.log(cc, "<- Cc");
    console.log(bcc, "<- Bcc");
    console.log(message, "<- Message");
    console.log(refreshToken, "<- RefreshToken");
    console.log(subject, "<- Subject");
    attachments.map(function (item) {
      delete item.size;
      delete item.percent;
      return item;
    });
    console.log(attachments, "<- Attachments");
    console.log(mentionableNames, "<-mentionableNames");
    console.log(htmlMessage, "<- Html base64");
    setIsSendingEmail(true);

    const result = await ForwardEmail();
    const statusCode = isOutlookIntegrated
      ? result?.data?.outlookSendEmail?.statusCode
      : result?.data?.gmailForwardEmail?.statusCode;
    const resultMessageId = isOutlookIntegrated
      ? result?.data?.outlookSendEmail?.messageId
      : result?.data?.gmailForwardEmail?.messageId;
    console.log("forward result", result);

    if (result && statusCode === 200) {
      saveClientMatter(resultMessageId);
      //To do toast
    }

    if (result && statusCode === 400) {
      //To do toast
      handleClear();
    }
  };

  const getUserTypes = async () => {
    const params = {
      query: DEFAULT_USERTYPES_QUERY,
    };

    await API.graphql(params).then((userTypes) => {
      if (userTypes.data.defaultUserType) {
        console.log("userTypes", userTypes.data.defaultUserType);
        userTypes.data.defaultUserType.map((userType) => {
          let oUserType = {
            value: userType,
            label: userType,
          };
          setUserTypes((prev) => [...prev, oUserType]);
        });
      }
    });
  };

  const options = [
    { value: 0, label: "New Email" },
    { value: 1, label: "Reply" },
    { value: 2, label: "Forward" },
  ];

  const userTypeOptions = UserTypes.map((item) => ({
    ...item,
    label: humaReadableFormat(
      item.label
        .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
        .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
    ),
  }));

  const contactOptions = [{ value: "", label: "Select" }, ...userTypeOptions];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      width: "max-content",
      minWidth: "100%",
      zIndex: 9999,
    }),
    ///.....
  };

  const handleContactsfromClientMatter = async (target) => {
    if (updatedEmailDetails) {
      const contacts =
        updatedEmailDetails.clientMatters.items[0].contacts.items;
      let contactList = [];
      if (composeType.label === "Forward") {
        contacts.map((item) => {
          if (item.type === target.value) {
            console.log("Populated Contacts of ", target.label);
            contactList = item.details;
          }
        });

        contactList.map((item) => {
          if (item.email) {
            const receiver = {
              name: item.name ? item.name : item.email,
              emailAddress: item.email,
            };
            handleReceiverTo(receiver, "add");
          }
        });
      }
    }
  };

  const handleDraftEmail = async (e) => {
    console.log(to, "<- To");
    console.log(cc, "<- Cc");
    console.log(bcc, "<- Bcc");
    console.log(message, "<- Message");
    console.log(refreshToken, "<- RefreshToken");
    console.log(subject, "<- Subject");
    console.log(threadId, "<- Thread Id");
    console.log(messageId, "<- References");
    console.log(messageId, "<- In Reply To");
    attachments.map(function (item) {
      delete item.size;
      delete item.percent;
      return item;
    });
    console.log(attachments, "<- Attachments");
    console.log(htmlMessage, "<- Html base64");
    setIsSendingEmail(true);
    const result = await SaveDraftsEmail();
    console.log(result);
  };

  const handleClear = () => {
    setIsSendingEmail(false);
    setEditTo(false);
    setEditCc(false);
    setEditBcc(false);
    setIsCc(false);
    setIsBcc(false);
    setIsExpanded(false);
    setIsMinimized(false);
    setStatus("Sending Email ...");
    setContactOption({
      value: 1,
      label: "SELECT",
    });
    setTo([]);
    setCc([]);
    setBcc([]);
    setSelectedRawHtml("");
    setSubject("");
    setMessage("");
    setHtmlMessage("");
    setForwardDetails("");
    setAttachments([]);
    setMentionableNames([]);
    onClose();
    setComposeType({ value: 0, label: "New Email" });
  };

  useEffect(() => {}, [message]);
  const handleMessage = ({ html, text }) => {
    setHtmlMessage(html);
    setMessage(text);
  };

  const handleCheckEmail = (receiverType, email, isAddContact) => {
    if (emailChecker.test(email)) {
      if (isAddContact) {
        localStorage.setItem("addEmail", email);
        setShowContactModal(true);
        setIsExpanded(false);
        setIsMinimized(true);
      }

      if (!isAddContact) {
        switch (receiverType) {
          case "to":
            handleReceiverTo({ name: email, emailAddress: email }, "add");
            setSearchEmailTo("");
            break;
          case "cc":
            handleReceiverCc({ name: email, emailAddress: email }, "add");
            setSearchEmailCc("");
            break;
          case "bcc":
            handleReceiverBcc({ name: email, emailAddress: email }, "add");
            setSearchEmailBcc("");
            break;
          default:
            return;
        }
      }
    } else {
      console.log("Invalid Email");
    }
  };

  const handleReceiverTo = (receiver, type) => {
    if (type === "remove") {
      setTo(to.filter((user) => user.name !== receiver.name));
      setMentionableNames(
        mentionableNames.filter((user) => user.value !== receiver.name)
      );

      setSearchEmailTo("");
    }
    if (type === "add") {
      const contains = to.some((e) => {
        return JSON.stringify(receiver) === JSON.stringify(e);
      });

      if (contains) {
        console.log(receiver, " is Already in TO array");
      } else {
        setTo((old) => [...old, receiver]);
        setMentionableNames((old) => [
          ...old,
          {
            id: mentionableNames.length + 1,
            value: receiver.name,
          },
        ]);
        setSearchEmailTo("");
      }
    }
  };

  const handleReceiverCc = (receiver, type) => {
    if (type === "remove") {
      setCc(cc.filter((user) => user.name !== receiver.name));
      setMentionableNames(
        mentionableNames.filter((user) => user.value !== receiver.name)
      );
      setSearchEmailCc("");
    }
    if (type === "add") {
      if (cc.includes(receiver)) {
        console.log(receiver, " is Already in Cc array");
      } else {
        setCc((old) => [...old, receiver]);
        setMentionableNames((old) => [
          ...old,
          {
            id: mentionableNames.length + 1,
            value: receiver.name,
          },
        ]);
        setSearchEmailCc("");
      }
    }
  };

  const handleReceiverBcc = (receiver, type) => {
    if (type === "remove") {
      setBcc(bcc.filter((user) => user.name !== receiver.name));
      setMentionableNames(
        mentionableNames.filter((user) => user.value !== receiver.name)
      );
      setSearchEmailBcc("");
    }
    if (type === "add") {
      if (bcc.includes(receiver)) {
        console.log(receiver, " is Already in Bcc array");
      } else {
        setBcc((old) => [...old, receiver]);
        setMentionableNames((old) => [
          ...old,
          {
            id: mentionableNames.length + 1,
            value: receiver.name,
          },
        ]);
        setSearchEmailBcc("");
      }
    }
  };

  const getAttachmentLink = async (id, filename) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetFileDownloadLink
        : qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { isInline, type, name, downloadURL, s3ObjectKey } =
        isOutlookIntegrated
          ? result.data.outlookAttachment
          : result.data.gmailAttachment;

      const addAttachment = isOutlookIntegrated
        ? {
            filename: name,
            path: downloadURL,
            isInline: true,
            contentType: type,
          }
        : {
            filename: filename,
            path: downloadURL,
            content: "",
            encoding: "",
          };
      setAttachments((old) => [...old, addAttachment]);
    });
  };

  const handleOpenFile = () => {
    inputRef.current.click();
  };

  const handleOnchangeFile = async (e) => {
    const files = e.target.files;

    await handleUploadAttachmentFiles(files);

    // await totalFilesChecker(files);
    // await getBase64(files);
  };

  const handleUploadAttachmentFiles = async (files) => {
    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: isOutlookIntegrated
        ? process.env.REACT_APP_S3_OUTLOOK_ATTACHMENT_BUCKET
        : config.aws_user_gmail_attachments_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    let uploadFile = []; // Create an array to store the selected files

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      uploadFile.push(file);
      setAttachments((old) => [...old, {
        filename : file.name,
        percent: "0%"
      }])
    }

    console.log(uploadFile, "<- Upload File");

    var idxx = 0;
    for (let uf of uploadFile) {
      const date = Number(new Date());
      let AttachmentID = `${date}${uf.name
        .replaceAll(/\s/g, "")
        .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`;
      if (uf.name.split(".").pop() == "docx") {
        var name = uf.name,
          size = uf.size,
          type =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          key = `compose/${date}${uf.name
            .replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      } else {
        var name = uf.name,
          size = uf.size,
          type = uf.type,
          key = `compose/${date}${uf.name
            .replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      }

      idxx = idxx + 1;

      try {
        await Storage.put(key, uf, {
          contentType: type,
          progressCallback(progress) {
            const progressInPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            // attachments.map((i) => i.filename === uf.name ? i.percent = `${progressInPercentage}%` : '0%')
            setAttachments(prevAttachments => prevAttachments.map((item) => // <-- new array
            item.filename === uf.name
                ? { ...item, percent: `${progressInPercentage}%` } // <-- new object
                : item
            ));
            console.log(`Progress: ${progressInPercentage}%, ${uf.name}`);
          },
          errorCallback: (err) => {
            console.error("204: Unexpected error while uploading", err);
          },
        })
          .then(async (fd) => {
            var fileData = {
              messageId: AttachmentID,
              s3ObjectKey: fd.key,
              size: parseInt(size),
              type: type,
              name: name.split(".").slice(0, -1).join("."),
              oderSelected: orderSelected,
              order: orderSelected,
            };
            console.log(fileData, fd, "fd");

            //Get downloadUrl of uploaded attachment
            const url = await Storage.get(fd.key, {
              download: false,
              expires: 3600 // URL expires in 1 hour
            });

            if (url) {
              const attachmentItem = isOutlookIntegrated ? {
                  filename: name,
                  path: url,
                  isInline: false,
                  contentType: type,
                  percent: "100%"
              } : {
                filename: name,
                path: url,
                content: "",
                encoding: "",
                percent: "100%"
              };

              setAttachments(prevAttachments => prevAttachments.map((item) => // <-- new array
                item.filename === uf.name
                    ? { ...item, ...attachmentItem } // <-- new object
                    : item
                ));
            }

            //Bring back to default after uploading
            Storage.configure({
              region: config.aws_user_files_s3_bucket_region,
              bucket: config.aws_user_files_s3_bucket,
              identityPoolId: config.aws_user_pools_id,
            });

          })
          .catch((err) => {
            //Bring back to default after uploading
            Storage.configure({
              region: config.aws_user_files_s3_bucket_region,
              bucket: config.aws_user_files_s3_bucket,
              identityPoolId: config.aws_user_pools_id,
            });

            console.log(Storage, "<- Revert Storage default Bucket");

            console.error("220: Unexpected error while uploading", err);
          });
      } catch (e) {
        //Bring back to default after uploading
        Storage.configure({
          region: config.aws_user_files_s3_bucket_region,
          bucket: config.aws_user_files_s3_bucket,
          identityPoolId: config.aws_user_pools_id,
        });

        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };

        console.log("ERROR: ", response);
      }
    }
  };

  // const totalFilesChecker = async (files) => {
  //   let fileSize = 0;

  //   for (let file of files) {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.fileSize = file.size;
  //     fileSize = fileSize + reader.fileSize;
  //   }

  //   let convertedToMB = fileSize / 1024 / 1024;
  //   convertedToMB = convertedToMB.toFixed(2);

  //   if (convertedToMB > 2) {
  //     // 2 MiB for bytes.
  //     setAttachments([]); // empty attachments
  //     setBodyContent(
  //       `Sorry, your attachment(s) have exceeded the file size limit. The total file size of your attachments is ${convertedToMB}MB. The accepted file size should be under 2MB.`
  //     );
  //     setIsMinimized(true);
  //     setIsExpanded(false);
  //     setShowNotificationModal(true);
  //     return;
  //   } else {
  //     await getBase64(files);
  //   }
  // };

  // const getBase64 = async (files) => {
  //   for (let file of files) {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.fileName = file.name;
  //     reader.onload = async () => {
  //       let result = reader.result.split("base64,");
  //       let regex = `/data:([^;]+);/`;
  //       await setAttachments((old) => [
  //         ...old,
  //         isOutlookIntegrated
  //           ? {
  //               // size: file.size,
  //               filename: file.name,
  //               ...getAttachmentTypeAndBytes(reader.result),
  //               isInline: false,
  //             }
  //           : {
  //               size: file.size,
  //               filename: file.name,
  //               content: reader.result.split("base64,")[1],
  //               encoding: "base64",
  //             },
  //       ]);
  //     };
  //   }
  // };

  // const getAttachmentTypeAndBytes = (dataUrl) => {
  //   const match = dataUrl.match(/^data:([^;]+);base64,(.*)$/);
  //   if (!match) {
  //     throw new Error("Invalid data URL format");
  //   }

  //   return {
  //     contentType: match[1],
  //     contentBytes: match[2],
  //   };
  // };

  const loadReplyInfo = () => {
    setAttachments([]);
    if (selectedIsReplyDetails.from && to.length === 0) {
      const user = selectedIsReplyDetails.from;
      if (user.includes("<") && user.substring(0) !== "<") {
        setContacts((old) => [
          ...old,
          {
            name: user.substring(0, user.lastIndexOf("<") - 1),
            emailAddress: user.substring(
              user.lastIndexOf("<") + 1,
              user.lastIndexOf(">")
            ),
          },
        ]);
        setTo((old) => [
          ...old,
          {
            name: user.substring(0, user.lastIndexOf("<") - 1),
            emailAddress: user.substring(
              user.lastIndexOf("<") + 1,
              user.lastIndexOf(">")
            ),
          },
        ]);
        setMentionableNames((old) => [
          ...old,
          {
            id: mentionableNames.length + 1,
            value: user.substring(0, user.lastIndexOf("<") - 1),
          },
        ]);
      } else {
        setTo((old) => [
          ...old,
          {
            name: user,
            emailAddress: user,
          },
        ]);
      }
    }
  };

  const loadForwardInfo = () => {
    setTo([]);
    setContacts([]);
    setMentionableNames([]);
    setAttachments([]);
    const attachments = selectedIsReplyDetails?.attachments?.items;
    if (attachments.length > 1) {
      attachments.map((item_attach) => {
        if (!item_attach?.details.includes("EMAIL_PDF_VERSION")) {
          getAttachmentLink(item_attach?.id, item_attach.name);
        }
      });
    }
  };

  useEffect(() => {
    console.log("Saving in specified Client Matter");
    if (isSavingClientMatter === true && reSaveGmailMessageId !== "") {
      reSaveClientMatter(reSaveGmailMessageId);
    }
  }, [setIsSavingClientMatter, isSavingClientMatter, reSaveGmailMessageId]);

  useEffect(() => {
    if (attachments.length > 0) {
      let fileTotalSize = 0;
      attachments.map((item) => {
        fileTotalSize = fileTotalSize + item.size;
      });

      let convertedToMB = fileTotalSize / 1024 / 1024;
      convertedToMB = convertedToMB.toFixed(2);
      console.log(attachments, fileTotalSize, "<- ATTACHMENT STATE");
      if (convertedToMB > 2) {
        // 2 MiB for bytes.
        setAttachments([]); // empty attachments
        setBodyContent(
          `Sorry, your attachment(s) have exceeded the file size limit. The total file size of your attachments is ${convertedToMB}MB. The accepted file size should be under 2MB.`
        );
        setIsMinimized(true);
        setIsExpanded(false);
        setShowNotificationModal(true);
      }
    }
  }, [attachments]);

  //Check if compose is triggered via Specific tab
  useEffect(() => {
    if (fromUnTrimed == "" && from) {
      setFromUntrimed(
        `${
          localStorage.getItem("connectedGmailAccountName")
            ? localStorage.getItem("connectedGmailAccountName")
            : ""
        } <${from}>`
      );
    }
  });

  useEffect(() => {
    if (selectedIsReplyDetails.id && isUpdatedClientMatter) {
      handleGetUpdatedEmail(selectedIsReplyDetails.id);
    }
  }, [isUpdatedClientMatter]);

  useEffect(() => {
    if (selectedIsReplyDetails) {
      setUpdatedEmailDetails(selectedIsReplyDetails);
    }
  }, [selectedIsReplyDetails]);

  useEffect(() => {
    if (contacts.length === 0) {
      getContacts();
      getUserTypes();
    }
  }, [contacts]);

  useEffect(() => {
    if (isReply === true && composeType.value === 1) {
      loadReplyInfo();
      let isSubject = reSubject.replace("Re:", "");
      isSubject = isSubject.replace("Fwd:", "");
      setSubject(`Re:${isSubject}`);
    }

    if (isReply === true && composeType.value === 2) {
      loadForwardInfo();
      let isSubject = reSubject.replace("Re:", "");
      isSubject = isSubject.replace("Fwd:", "");
      setSubject(`Fwd:${isSubject}`);
    }
  }, [isReply, composeType.label]);

  useEffect(() => {
    if (composeType.value === 0) {
      setIsReply(false);
      setEditTo(true);
      setTo([]);
      setAttachments([]);
      setSubject("");
    } else {
      setIsReply(true);
      setEditTo(false);
    }
  }, [composeType.label]);

  useEffect(() => {
    if (expandedMessageRef.current) {
      expandedMessageRef.current.innerText = message;
    }

    if (messageRef.current) {
      messageRef.current.innerText = message;
    }
  }, [isExpanded, isMinimized]);

  useEffect(() => {
    setIsExpanded(isMobileDevice(width));
  }, [visible, width]);

  if (!visible) return null;

  return (
    <>
      {showNotificationModal && width < 844 && (
        <MobileNotificationModal
          title="Warning"
          body={bodyContent}
          confirm={() => {
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
            setShowNotificationModal(false);
          }}
          cancel={() => {
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
            setShowNotificationModal(false);
          }}
          close={() => {
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
            setShowNotificationModal(false);
          }}
        />
      )}
      {showNotificationModal && width > 844 && (
        <WebNotificationModal
          title="Warning"
          body={bodyContent}
          confirm={() => {
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
            setShowNotificationModal(false);
          }}
          cancel={() => {
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
            setShowNotificationModal(false);
          }}
          close={() => {
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
            setShowNotificationModal(false);
          }}
        />
      )}

      {isExpanded ? (
        //Expanded window
        <div
          style={{ backgroundColor: `rgba(0, 0, 0, .5)` }}
          className="z-101 fixed inset-0 flex justify-center items-center"
        >
          <div
            style={{ boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` }}
            className={`animate-transformUp sticky z-101 bg-white shadow-md h-auto rounded-md max-h-full overflow-hidden
              ${isMobileDevice(width) ? "w-full" : "w-3/5"}`}
          >
            <div className="p-3">
              <div className=" relative overflow-auto test123 min-w-full min-h-full h-auto bg-white">
                {/* Headers Div Container */}
                <div className="flex justify-between gap-2">
                  <div className=" flex flex-nowrap justify-start p-2 truncate">
                    {threadId !== "" && !isSendingEmail ? (
                      <div className="flex justify-start gap-2">
                        <Select
                          value={composeType}
                          className="relative font-semibold"
                          options={options}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          onChange={(value) => {
                            setComposeType(value);
                            handleGetUpdatedEmail(selectedIsReplyDetails.id);
                            if (value.label === "Reply") {
                              setHtmlMessage("");
                            }
                          }}
                        />
                        {composeType.label === "Forward" &&
                        updatedEmailDetails?.clientMatters ? (
                          <Select
                            value={contactOption}
                            className="relative font-semibold"
                            options={contactOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            onChange={(value) => {
                              setContactOption(value);
                              handleContactsfromClientMatter(value);
                            }}
                          />
                        ) : null}
                      </div>
                    ) : null}
                    <p className=" text-green-300 font-semibold truncate">
                      {isSendingEmail ? status : ""}
                    </p>
                  </div>
                  <div className="h-8 py-2  flex justify-end gap-2 bg-white rounded-t-md">
                    <button
                      onClick={() => {
                        setIsMinimized(true);
                        setIsExpanded(false);
                      }}
                      className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                    >
                      <span>
                        <MdOutlineMinimize size={16} />
                      </span>
                    </button>
                    {!isMobileDevice(width) && (
                      <button
                        onClick={() => {
                          if (!isExpanded && messageRef.current) {
                            message.current.innerHTML = message;
                          }
                          setIsExpanded(false);
                        }}
                        className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                      >
                        <span>
                          <TbArrowsDiagonalMinimize2 size={18} />
                        </span>
                      </button>
                    )}
                    <button
                      disabled={isSendingEmail}
                      onClick={() => {
                        handleClear();
                      }}
                      className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                    >
                      <AiOutlineClose className="bg-gray-50" size={16} />
                    </button>
                  </div>
                </div>

                {/* Email Addresses Container Div */}
                <div className="flex flex-row flex-wrap w-full border-b-2 border-b-gray-100">
                  <div className="flex flex-wrap w-full gap-1">
                    {/* To: Container Seaction */}
                    <div
                      id="to"
                      className="my-1 flex flex-wrap flex-auto w-3/4"
                    >
                      <span className="cursor-pointer text-sm m-1 p-1 text-gray-600 font-medium">
                        To:
                      </span>

                      {to.map((udetails, index) => {
                        return (
                          <div
                            key={index}
                            className="flex-auto border-2 h-8 border-gray-100 px-2 m-1 flex justify-between items-center gap-1 bg-white rounded-lg "
                          >
                            <span className="truncate">{udetails.name}</span>
                            {editTo && (
                              <button
                                onClick={() => {
                                  handleReceiverTo(udetails, "remove");
                                  inputRefTo.current.focus();
                                }}
                                className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                              >
                                <AiOutlineClose size={16} />
                              </button>
                            )}
                          </div>
                        );
                      })}

                      {editTo && (
                        <div className="flex-auto flex justify-between">
                          <div className="flex-auto relative">
                            <div className="flex gap-2">
                              {" "}
                              <input
                                ref={inputRefTo}
                                disabled={isSendingEmail}
                                autoFocus
                                value={searchEmailTo}
                                onChange={(e) =>
                                  setSearchEmailTo(e.target.value)
                                }
                                className="rounded flex-auto border-2 border-green-100 m-1 p-1 outline-none focus:out"
                                type="email "
                                placeholder=""
                              />
                              {searchEmailTo != "" &&
                              searchEmailTo.includes("@") &&
                              emailChecker.test(searchEmailTo) ? (
                                <>
                                  <button
                                    className="py-1 px-2 font-bold text-blue-400 rounded-md"
                                    onClick={() =>
                                      handleCheckEmail(
                                        "to",
                                        searchEmailTo,
                                        false
                                      )
                                    }
                                  >
                                    <span>
                                      {" "}
                                      <BsPersonCheck size={25} />
                                    </span>
                                  </button>
                                  <button
                                    className="py-1 px-2 font-semibold text-blue-400 rounded-md"
                                    onClick={() =>
                                      handleCheckEmail(
                                        "to",
                                        searchEmailTo,
                                        true
                                      )
                                    }
                                  >
                                    <span>
                                      <MdPersonAddAlt1 size={30} />
                                    </span>
                                  </button>
                                </>
                              ) : null}
                            </div>
                            {/* Search Bar Container */}
                            {searchEmailTo !== "" ? (
                              <div className="absolute w-52 text-left z-40 my-1 p-2 max-h-80 test123 overflow-y-auto">
                                {contacts.map((user, index) => {
                                  return searchEmailTo != "" &&
                                    (user.name
                                      .toLocaleLowerCase()
                                      .includes(
                                        searchEmailTo.toLocaleLowerCase()
                                      ) ||
                                      user.emailAddress
                                        .toLocaleLowerCase()
                                        .includes(
                                          searchEmailTo.toLocaleLowerCase()
                                        )) ? (
                                    <div
                                      key={index}
                                      className="cursor-pointer my-1 text-left bg-white shadow-md rounded-md hover:bg-blue-200  p-2"
                                    >
                                      <p
                                        onClick={() => {
                                          handleReceiverTo(user, "add");
                                          inputRefTo.current.focus();
                                        }}
                                        className="font-medium"
                                      >
                                        {user.name}
                                        <p className="truncate text-xs text-gray-700">
                                          {user.emailAddress}
                                        </p>
                                      </p>
                                    </div>
                                  ) : null;
                                })}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-8 float-right  px-2 flex justify-between items-center gap-1 bg-white rounded-lg">
                      <button
                        className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                        onClick={() => {
                          setEditTo(!editTo);
                        }}
                      >
                        <BiPencil />
                      </button>
                    </div>
                  </div>

                  {/* CC: Container Seaction */}
                  {isCc ? (
                    <div className="flex flex-wrap w-full gap-1">
                      <div
                        id="cc"
                        className="my-1 flex flex-wrap flex-auto w-3/4"
                      >
                        <span
                          onClick={() => {
                            setIsCc(false);
                            setEditCc(false);
                          }}
                          className="cursor-pointer text-sm m-1 p-1 justify-end text-gray-600 font-medium"
                        >
                          Cc:
                        </span>
                        {cc.map((udetails, index) => {
                          return (
                            <div
                              key={index}
                              className="flex-auto border-2 h-8 border-gray-100 px-2 m-1 flex justify-between items-center gap-1 bg-white rounded-lg truncate"
                            >
                              <span className="truncate">{udetails.name}</span>
                              {editCc && (
                                <button
                                  onClick={() => {
                                    handleReceiverCc(udetails, "remove");
                                    inputRefCc.current.focus();
                                  }}
                                  className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                                >
                                  <AiOutlineClose size={16} />
                                </button>
                              )}
                            </div>
                          );
                        })}

                        {editCc && (
                          <div className="flex-auto flex justify-between">
                            <div className="flex-auto relative">
                              <div className="flex gap-2">
                                <input
                                  ref={inputRefCc}
                                  disabled={isSendingEmail}
                                  autoFocus
                                  value={searchEmailCc}
                                  onChange={(e) =>
                                    setSearchEmailCc(e.target.value)
                                  }
                                  className="rounded flex-auto border-2 border-green-100 m-1 p-1 outline-none focus:out"
                                  type="email "
                                  placeholder=""
                                />
                                {searchEmailCc != "" &&
                                searchEmailCc.includes("@") &&
                                emailChecker.test(searchEmailCc) ? (
                                  <>
                                    <button
                                      className="py-1 px-2 font-bold text-blue-400 rounded-md"
                                      onClick={() =>
                                        handleCheckEmail(
                                          "cc",
                                          searchEmailCc,
                                          false
                                        )
                                      }
                                    >
                                      <span>
                                        <BsPersonCheck size={25} />
                                      </span>
                                    </button>
                                    <button
                                      className="py-1 px-2 font-semibold text-blue-400 rounded-md"
                                      onClick={() =>
                                        handleCheckEmail(
                                          "cc",
                                          searchEmailCc,
                                          true
                                        )
                                      }
                                    >
                                      <MdPersonAddAlt1 size={30} />
                                    </button>
                                  </>
                                ) : null}
                              </div>
                              {/* Search Bar Container */}
                              {searchEmailCc !== "" ? (
                                <div className="absolute w-52 text-left z-30 my-1 p-2 max-h-80 test123 overflow-y-auto">
                                  {contacts.map((user, index) => {
                                    return searchEmailCc != "" &&
                                      (user.name
                                        .toLocaleLowerCase()
                                        .includes(
                                          searchEmailCc.toLocaleLowerCase()
                                        ) ||
                                        user.emailAddress
                                          .toLocaleLowerCase()
                                          .includes(
                                            searchEmailCc.toLocaleLowerCase()
                                          )) ? (
                                      <div
                                        key={index}
                                        className="cursor-pointer my-1 text-left bg-white shadow-md rounded-md hover:bg-blue-200  p-2"
                                      >
                                        <p
                                          onClick={() => {
                                            handleReceiverCc(user, "add");
                                            inputRefCc.current.focus();
                                          }}
                                          className="font-medium"
                                        >
                                          {user.name}
                                          <p className="truncate text-xs text-gray-700">
                                            {user.emailAddress}
                                          </p>
                                        </p>
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-8 float-right  px-2 flex justify-between items-center gap-1 bg-white rounded-lg">
                        <button
                          className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                          onClick={() => {
                            setEditCc(!editCc);
                            if (cc.length == 0) {
                              setIsCc(false);
                            }
                          }}
                        >
                          <BiPencil />
                        </button>
                      </div>
                    </div>
                  ) : null}

                  {/* BCC: Container Seaction */}
                  {isBcc ? (
                    <div className="flex flex-wrap w-full gap-1">
                      <div
                        id="bcc"
                        className="my-1 flex flex-wrap flex-auto w-3/4"
                      >
                        <span
                          onClick={() => setIsBcc(false)}
                          className=" content-center cursor-pointer text-xs mt-2 m-1 p-1 justify-end text-gray-600 font-medium"
                        >
                          Bcc:
                        </span>
                        {bcc.map((udetails, index) => {
                          return (
                            <div
                              key={index}
                              className="flex-auto border-2 h-8 border-gray-100 px-2 m-1 flex justify-between items-center gap-1 bg-white rounded-lg truncate"
                            >
                              <span className="truncate">{udetails.name}</span>
                              {editBcc && (
                                <button
                                  onClick={() => {
                                    handleReceiverBcc(udetails, "remove");
                                    inputRefBcc.current.focus();
                                  }}
                                  className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                                >
                                  <AiOutlineClose size={16} />
                                </button>
                              )}
                            </div>
                          );
                        })}

                        {editBcc && (
                          <div className="flex-auto flex justify-between">
                            <div className="flex-auto relative">
                              <div className="flex gap-2">
                                <input
                                  ref={inputRefBcc}
                                  disabled={isSendingEmail}
                                  autoFocus
                                  value={searchEmailBcc}
                                  onChange={(e) =>
                                    setSearchEmailBcc(e.target.value)
                                  }
                                  className="rounded border-2 flex-auto border-green-100 m-1 p-1 outline-none focus:out"
                                  type="email "
                                  placeholder=""
                                />
                                {searchEmailBcc != "" &&
                                searchEmailBcc.includes("@") &&
                                emailChecker.test(searchEmailBcc) ? (
                                  <>
                                    <button
                                      className="py-1 px-2 font-bold text-blue-400 rounded-md"
                                      onClick={() =>
                                        handleCheckEmail(
                                          "bcc",
                                          searchEmailBcc,
                                          false
                                        )
                                      }
                                    >
                                      <span>
                                        <BsPersonCheck size={25} />
                                      </span>
                                    </button>
                                    <button
                                      className="py-1 px-2 font-semibold text-blue-400 rounded-md"
                                      onClick={() =>
                                        handleCheckEmail(
                                          "bcc",
                                          searchEmailBcc,
                                          true
                                        )
                                      }
                                    >
                                      <MdPersonAddAlt1 size={30} />
                                    </button>
                                  </>
                                ) : null}
                              </div>
                              {/* Search Bar Container */}
                              {searchEmailBcc !== "" ? (
                                <div className="absolute w-52 text-left z-20 my-1 p-2 max-h-80 test123 overflow-y-auto">
                                  {contacts.map((user, index) => {
                                    return searchEmailBcc != "" &&
                                      (user.name
                                        .toLocaleLowerCase()
                                        .includes(
                                          searchEmailBcc.toLocaleLowerCase()
                                        ) ||
                                        user.emailAddress
                                          .toLocaleLowerCase()
                                          .includes(
                                            searchEmailBcc.toLocaleLowerCase()
                                          )) ? (
                                      <div
                                        key={index}
                                        className="cursor-pointer my-1 text-left bg-white shadow-md rounded-md hover:bg-blue-200  p-2"
                                      >
                                        <p
                                          onClick={() => {
                                            handleReceiverBcc(user, "add");
                                            inputRefBcc.current.focus();
                                          }}
                                          className="font-medium"
                                        >
                                          {user.name}
                                          <p className="truncate text-xs text-gray-700">
                                            {user.emailAddress}
                                          </p>
                                        </p>
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-8 float-right  px-2 flex justify-between items-center gap-1 bg-white rounded-lg">
                        <button
                          className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                          onClick={() => {
                            setEditBcc(!editBcc);
                            if (bcc.length == 0) {
                              setIsBcc(false);
                            }
                          }}
                        >
                          <BiPencil />
                        </button>
                      </div>
                    </div>
                  ) : null}

                  <div className="ml-auto">
                    {!isCc ? (
                      <button
                        disabled={isSendingEmail}
                        className="text-gray-600 background-transparent font-bold p-2 text-xs outline-none focus:outline-none my-1 ease-linear transition-all duration-150"
                        onClick={() => {
                          setIsCc(!isCc);
                          setEditCc(!editCc);
                        }}
                      >
                        Cc
                      </button>
                    ) : null}
                    {!isBcc ? (
                      <button
                        disabled={isSendingEmail}
                        className="text-gray-600 background-transparent font-bold p-2 text-xs outline-none focus:outline-none my-1 ease-linear transition-all duration-150"
                        onClick={() => {
                          setIsBcc(!isBcc);
                          setEditBcc(!editBcc);
                        }}
                      >
                        Bcc
                      </button>
                    ) : null}
                  </div>
                </div>

                {/* Subject Div Container*/}
                {isReply ? (
                  <div className="flex flex-wrap w-full border-b-2 border-b-gray-100">
                    <div className="my-1 flex justify-between w-full">
                      <input
                        className="truncate my-1 w-full p-2 font-semibold outline-none focus:out"
                        type="text"
                        placeholder="Subject"
                        value={subject}
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap w-full border-b-2 border-b-gray-100">
                    <div className="my-1 w-full">
                      <input
                        disabled={isSendingEmail}
                        className="w-full my-1 p-2 font-semibold outline-none focus:out"
                        type="text"
                        placeholder="Subject"
                        value={subject}
                        onClick={() => {
                          if (bcc.length > 0) {
                            setIsBcc(true);
                          } else {
                            setIsBcc(false);
                          }
                          if (cc.length > 0) {
                            setIsCc(true);
                          } else {
                            setIsCc(false);
                          }
                        }}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* Message Div Container */}
                <QuillEditor
                  isSendingEmail={isSendingEmail}
                  isReply={isReply}
                  selectedRawHtml={selectedRawHtml}
                  composeType={composeType}
                  emailInfo={selectedIsReplyDetails}
                  mentionData={mentionableNames}
                  htmlMessage={htmlMessage}
                  setHtmlMessage={setHtmlMessage}
                  setForwardDetails={setForwardDetails}
                  handleMessage={(e) => handleMessage(e)}
                  setEditBcc={setEditBcc}
                  setEditCc={setEditCc}
                  setEditTo={setEditTo}
                  cc={cc}
                  bcc={bcc}
                  setIsCc={setIsCc}
                  setIsBcc={setIsBcc}
                  setAttachments={setAttachments}
                  attachments={attachments}
                  inputRef={inputRef}
                  placeholder={"Input your email Message Here"}
                  isOutlookIntegrated={isOutlookIntegrated}
                />
              </div>
            </div>

            {/* Buttons Div Container */}
            <div className="px-3 py-2 flex flex-wrap gap-2 absolute bottom-0 bg-gray-50 rounded-b-md justify-between w-full items-center">
              <div className="flex gap-2 justify-start">
                {/* Send Email Button */}
                {to.length > 0 && subject !== "" ? (
                  isReply && composeType.value === 2 ? (
                    <button
                      disabled={isSendingEmail}
                      className={
                        isSendingEmail
                          ? "bg-blue-300 rounded font-semibold text-white p-2"
                          : "bg-blue-500 rounded font-semibold text-white p-2"
                      }
                      onClick={() => handleForwardEmail()}
                    >
                      Forward
                    </button>
                  ) : (
                    <button
                      disabled={isSendingEmail}
                      className={
                        isSendingEmail
                          ? "bg-blue-300 rounded font-semibold text-white p-2"
                          : "bg-blue-500 rounded font-semibold text-white p-2"
                      }
                      onClick={() => handleSendEmail()}
                    >
                      Send
                    </button>
                  )
                ) : (
                  <button
                    className="rounded font-semibold bg-blue-300 text-white p-2"
                    disabled
                  >
                    Send
                  </button>
                )}
                {/* Draft Email Button */}
                {to.length > 0 && subject !== "" ? (
                  <button
                    disabled={isSendingEmail}
                    className={
                      isSendingEmail
                        ? "bg-blue-300 rounded font-semibold text-white p-2"
                        : "bg-blue-500 rounded font-semibold text-white p-2"
                    }
                    onClick={() => handleDraftEmail()}
                  >
                    Save To Drafts
                  </button>
                ) : (
                  <button
                    className="rounded font-semibold bg-blue-300 text-white p-2"
                    disabled
                  >
                    Save To Drafts
                  </button>
                )}
                {/* Attachment Email Button */}
                <div className="flex flex-wrap justify-between">
                  <input
                    className="hidden"
                    ref={inputRef}
                    multiple="multiple"
                    type="file"
                    accept=".3gp, .flv, .mkv, .rtf, .odp, .ods, .odt, .doc,.docx, application/msword, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf, image/*, text/html, video/*, audio/*"
                    onChange={(e) => {
                      handleOnchangeFile(e);
                    }}
                  />
                  <button
                    disabled={isSendingEmail}
                    className=" rounded font-semibold text-gray-400 p-1 flex flex-col items-center gap-1"
                    onClick={() => {
                      handleOpenFile();
                    }}
                  >
                    <IoMdAttach size={25} />
                    <span className="text-gray-400 text-xs flex items-center leading-none">
                      Add
                      <br />
                      Files
                    </span>
                  </button>
                </div>
                {/* Copy Email Button */}
                <button
                  disabled={isSendingEmail}
                  className=" rounded font-semibold text-gray-400 p-1 flex flex-col items-center gap-1"
                >
                  <IoMdCopy size={25} />
                  <span className="text-gray-400 text-xs flex items-center leading-none">
                    Copy
                    <br />
                    Email
                  </span>
                </button>
              </div>
              <div className="flex justify-end">
                {/* Delete Email Button */}
                <button
                  disabled={isSendingEmail}
                  onClick={() => handleClear()}
                  className=" rounded font-semibold text-gray-400 p-1 flex flex-col items-center gap-1"
                >
                  <AiOutlineDelete size={25} />
                  <span className="text-gray-400 text-xs flex items-center leading-none">
                    Delete
                    <br />
                    Email
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : //Small window
      isMinimized ? (
        <div
          style={{ boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` }}
          onClick={() => {
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
          }}
          className={`fixed bottom-0 z-101 bg-white rounded-t-3xl p-3
            ${isMobileDevice(width) ? "w-full" : "right-7 w-2/5 max-w-md"}`}
        >
          <div className="flex justify-between gap-2">
            <div className="flex flex-nowrap justify-start p-2 truncate">
              <p className=" text-green-300 font-semibold truncate">
                {reSubject && isReply && !isSendingEmail
                  ? `${subject}`
                  : reSubject && isReply && isSendingEmail
                  ? `${status}`
                  : isSendingEmail
                  ? `Sending Email ... `
                  : "New Message"}
              </p>
            </div>
            <div className="h-8 py-2 flex justify-end gap-2 bg-white rounded-t-md">
              {isSendingEmail ? null : (
                <>
                  <button
                    onClick={() => {
                      setIsMinimized(false);
                    }}
                    className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                  >
                    <span>
                      <FaExpandAlt size={16} />
                    </span>
                  </button>

                  <button
                    disabled={isSendingEmail}
                    onClick={() => {
                      handleClear();
                    }}
                    className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                  >
                    <AiOutlineClose className="bg-gray-50" size={16} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` }}
          className=" animate-transformUp fixed bottom-0 z-101 bg-white shadow-md right-7 w-2/5 max-h-full overflow-hidden h-auto rounded-md"
        >
          <div className="p-3">
            <div className=" relative overflow-auto test123  min-h-full h-auto bg-white">
              {/* Headers Div Container */}
              <div className="flex justify-between gap-2">
                <div className="flex flex-nowrap justify-start p-2 truncate">
                  {threadId !== "" && !isSendingEmail ? (
                    <div className="flex justify-start gap-2">
                      <Select
                        value={composeType}
                        className="relative font-semibold"
                        options={options}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={customStyles}
                        onChange={(value) => {
                          setComposeType(value);
                          handleGetUpdatedEmail(selectedIsReplyDetails.id);
                          if (value.label === "Reply") {
                            setHtmlMessage("");
                          }
                        }}
                      />
                      {composeType.label === "Forward" &&
                      updatedEmailDetails?.clientMatters ? (
                        <Select
                          value={contactOption}
                          className="relative font-semibold"
                          options={contactOptions}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          onChange={(value) => {
                            setContactOption(value);
                            handleContactsfromClientMatter(value);
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  <p className="z-50 text-green-300 font-semibold truncate">
                    {isSendingEmail ? status : ""}
                  </p>
                </div>
                <div className="h-8 py-2 flex justify-end gap-2 bg-white rounded-t-md">
                  <button
                    onClick={() => {
                      setIsMinimized(true);
                    }}
                    className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                  >
                    <span>
                      <MdOutlineMinimize size={16} />
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      if (isExpanded && expandedMessageRef.current) {
                        message.current.innerHTML = message;
                      }
                      setIsExpanded(true);
                    }}
                    className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                  >
                    <span>
                      <FaExpandAlt size={16} />
                    </span>
                  </button>
                  <button
                    disabled={isSendingEmail}
                    onClick={() => {
                      handleClear();
                    }}
                    className="flex cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                  >
                    <AiOutlineClose className="bg-gray-50" size={16} />
                  </button>
                </div>
              </div>

              {/* Email Addresses Container Div */}
              <div className="flex flex-row flex-wrap w-full border-b-2 border-b-gray-100">
                <div className="flex flex-wrap w-full gap-1">
                  {/* To: Container Seaction */}
                  <div id="to" className="my-1 flex flex-wrap flex-auto w-3/4">
                    <span className="cursor-pointer text-sm m-1 p-1 text-gray-600 font-medium">
                      To:
                    </span>

                    {to.map((udetails, index) => {
                      return (
                        <div
                          key={index}
                          className="flex-auto border-2 h-8 border-gray-100 px-2 m-1 flex justify-between items-center gap-1 bg-white rounded-lg "
                        >
                          <span className="truncate">{udetails.name}</span>
                          {editTo && (
                            <button
                              onClick={() => {
                                handleReceiverTo(udetails, "remove");
                                inputRefTo.current.focus();
                              }}
                              className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                            >
                              <AiOutlineClose size={16} />
                            </button>
                          )}
                        </div>
                      );
                    })}

                    {editTo && (
                      <div className="flex-auto flex justify-between">
                        <div className="flex-auto relative">
                          <div className="flex gap-2">
                            {" "}
                            <input
                              ref={inputRefTo}
                              disabled={isSendingEmail}
                              autoFocus
                              value={searchEmailTo}
                              onChange={(e) => setSearchEmailTo(e.target.value)}
                              className="rounded flex-auto border-2 border-green-100 m-1 p-1 outline-none focus:out"
                              type="email "
                              placeholder=""
                            />
                            {searchEmailTo != "" &&
                            searchEmailTo.includes("@") &&
                            emailChecker.test(searchEmailTo) ? (
                              <>
                                <button
                                  className="py-1 px-2 font-bold text-blue-400 rounded-md"
                                  onClick={() =>
                                    handleCheckEmail("to", searchEmailTo, false)
                                  }
                                >
                                  <span>
                                    {" "}
                                    <BsPersonCheck size={25} />
                                  </span>
                                </button>
                                <button
                                  className="py-1 px-2 font-semibold text-blue-400 rounded-md"
                                  onClick={() =>
                                    handleCheckEmail("to", searchEmailTo, true)
                                  }
                                >
                                  <span>
                                    <MdPersonAddAlt1 size={30} />
                                  </span>
                                </button>
                              </>
                            ) : null}
                          </div>
                          {/* Search Bar Container */}
                          {searchEmailTo !== "" ? (
                            <div className="absolute w-52 text-left z-40 my-1 p-2 max-h-80 test123 overflow-y-auto">
                              {contacts.map((user, index) => {
                                return searchEmailTo != "" &&
                                  (user.name
                                    .toLocaleLowerCase()
                                    .includes(
                                      searchEmailTo.toLocaleLowerCase()
                                    ) ||
                                    user.emailAddress
                                      .toLocaleLowerCase()
                                      .includes(
                                        searchEmailTo.toLocaleLowerCase()
                                      )) ? (
                                  <div
                                    key={index}
                                    className="cursor-pointer my-1 text-left bg-white shadow-md rounded-md hover:bg-blue-200  p-2"
                                  >
                                    <p
                                      onClick={() => {
                                        handleReceiverTo(user, "add");
                                        inputRefTo.current.focus();
                                      }}
                                      className="font-medium"
                                    >
                                      {user.name}
                                      <p className="truncate text-xs text-gray-700">
                                        {user.emailAddress}
                                      </p>
                                    </p>
                                  </div>
                                ) : null;
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-8 float-right  px-2 flex justify-between items-center gap-1 bg-white rounded-lg">
                    <button
                      className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                      onClick={() => {
                        setEditTo(!editTo);
                      }}
                    >
                      <BiPencil />
                    </button>
                  </div>
                </div>

                {/* CC: Container Seaction */}
                {isCc ? (
                  <div className="flex flex-wrap w-full gap-1">
                    <div
                      id="cc"
                      className="my-1 flex flex-wrap flex-auto w-3/4"
                    >
                      <span
                        onClick={() => {
                          setIsCc(false);
                          setEditCc(false);
                        }}
                        className="cursor-pointer text-sm m-1 p-1 justify-end text-gray-600 font-medium"
                      >
                        Cc:
                      </span>
                      {cc.map((udetails, index) => {
                        return (
                          <div
                            key={index}
                            className="flex-auto border-2 h-8 border-gray-100 px-2 m-1 flex justify-between items-center gap-1 bg-white rounded-lg truncate"
                          >
                            <span className="truncate">{udetails.name}</span>
                            {editCc && (
                              <button
                                onClick={() => {
                                  handleReceiverCc(udetails, "remove");
                                  inputRefCc.current.focus();
                                }}
                                className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                              >
                                <AiOutlineClose size={16} />
                              </button>
                            )}
                          </div>
                        );
                      })}

                      {editCc && (
                        <div className="flex-auto flex justify-between">
                          <div className="flex-auto relative">
                            <div className="flex gap-2">
                              <input
                                ref={inputRefCc}
                                disabled={isSendingEmail}
                                autoFocus
                                value={searchEmailCc}
                                onChange={(e) =>
                                  setSearchEmailCc(e.target.value)
                                }
                                className="rounded flex-auto border-2 border-green-100 m-1 p-1 outline-none focus:out"
                                type="email "
                                placeholder=""
                              />
                              {searchEmailCc != "" &&
                              searchEmailCc.includes("@") &&
                              emailChecker.test(searchEmailCc) ? (
                                <>
                                  <button
                                    className="py-1 px-2 font-bold text-blue-400 rounded-md"
                                    onClick={() =>
                                      handleCheckEmail(
                                        "cc",
                                        searchEmailCc,
                                        false
                                      )
                                    }
                                  >
                                    <span>
                                      <BsPersonCheck size={25} />
                                    </span>
                                  </button>
                                  <button
                                    className="py-1 px-2 font-semibold text-blue-400 rounded-md"
                                    onClick={() =>
                                      handleCheckEmail(
                                        "cc",
                                        searchEmailCc,
                                        true
                                      )
                                    }
                                  >
                                    <MdPersonAddAlt1 size={30} />
                                  </button>
                                </>
                              ) : null}
                            </div>
                            {/* Search Bar Container */}
                            {searchEmailCc !== "" ? (
                              <div className="absolute w-52 text-left z-30 my-1 p-2 max-h-80 test123 overflow-y-auto">
                                {contacts.map((user, index) => {
                                  return searchEmailCc != "" &&
                                    (user.name
                                      .toLocaleLowerCase()
                                      .includes(
                                        searchEmailCc.toLocaleLowerCase()
                                      ) ||
                                      user.emailAddress
                                        .toLocaleLowerCase()
                                        .includes(
                                          searchEmailCc.toLocaleLowerCase()
                                        )) ? (
                                    <div
                                      key={index}
                                      className="cursor-pointer my-1 text-left bg-white shadow-md rounded-md hover:bg-blue-200  p-2"
                                    >
                                      <p
                                        onClick={() => {
                                          handleReceiverCc(user, "add");
                                          inputRefCc.current.focus();
                                        }}
                                        className="font-medium"
                                      >
                                        {user.name}
                                        <p className="truncate text-xs text-gray-700">
                                          {user.emailAddress}
                                        </p>
                                      </p>
                                    </div>
                                  ) : null;
                                })}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-8 float-right  px-2 flex justify-between items-center gap-1 bg-white rounded-lg">
                      <button
                        className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                        onClick={() => {
                          setEditCc(!editCc);
                          if (cc.length == 0) {
                            setIsCc(false);
                          }
                        }}
                      >
                        <BiPencil />
                      </button>
                    </div>
                  </div>
                ) : null}

                {/* BCC: Container Seaction */}
                {isBcc ? (
                  <div className="flex flex-wrap w-full gap-1">
                    <div
                      id="bcc"
                      className="my-1 flex flex-wrap flex-auto w-3/4"
                    >
                      <span
                        onClick={() => setIsBcc(false)}
                        className=" content-center cursor-pointer text-xs mt-2 m-1 p-1 justify-end text-gray-600 font-medium"
                      >
                        Bcc:
                      </span>
                      {bcc.map((udetails, index) => {
                        return (
                          <div
                            key={index}
                            className="flex-auto border-2 h-8 border-gray-100 px-2 m-1 flex justify-between items-center gap-1 bg-white rounded-lg truncate"
                          >
                            <span className="truncate">{udetails.name}</span>
                            {editBcc && (
                              <button
                                onClick={() => {
                                  handleReceiverBcc(udetails, "remove");
                                  inputRefBcc.current.focus();
                                }}
                                className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                              >
                                <AiOutlineClose size={16} />
                              </button>
                            )}
                          </div>
                        );
                      })}

                      {editBcc && (
                        <div className="flex-auto flex justify-between">
                          <div className="flex-auto relative">
                            <div className="flex gap-2">
                              <input
                                ref={inputRefBcc}
                                disabled={isSendingEmail}
                                autoFocus
                                value={searchEmailBcc}
                                onChange={(e) =>
                                  setSearchEmailBcc(e.target.value)
                                }
                                className="rounded border-2 flex-auto border-green-100 m-1 p-1 outline-none focus:out"
                                type="email "
                                placeholder=""
                              />
                              {searchEmailBcc != "" &&
                              searchEmailBcc.includes("@") &&
                              emailChecker.test(searchEmailBcc) ? (
                                <>
                                  <button
                                    className="py-1 px-2 font-bold text-blue-400 rounded-md"
                                    onClick={() =>
                                      handleCheckEmail(
                                        "bcc",
                                        searchEmailBcc,
                                        false
                                      )
                                    }
                                  >
                                    <span>
                                      <BsPersonCheck size={25} />
                                    </span>
                                  </button>
                                  <button
                                    className="py-1 px-2 font-semibold text-blue-400 rounded-md"
                                    onClick={() =>
                                      handleCheckEmail(
                                        "bcc",
                                        searchEmailBcc,
                                        true
                                      )
                                    }
                                  >
                                    <MdPersonAddAlt1 size={30} />
                                  </button>
                                </>
                              ) : null}
                            </div>
                            {/* Search Bar Container */}
                            {searchEmailBcc !== "" ? (
                              <div className="absolute w-52 text-left z-20 my-1 p-2 max-h-80 test123 overflow-y-auto">
                                {contacts.map((user, index) => {
                                  return searchEmailBcc != "" &&
                                    (user.name
                                      .toLocaleLowerCase()
                                      .includes(
                                        searchEmailBcc.toLocaleLowerCase()
                                      ) ||
                                      user.emailAddress
                                        .toLocaleLowerCase()
                                        .includes(
                                          searchEmailBcc.toLocaleLowerCase()
                                        )) ? (
                                    <div
                                      key={index}
                                      className="cursor-pointer my-1 text-left bg-white shadow-md rounded-md hover:bg-blue-200  p-2"
                                    >
                                      <p
                                        onClick={() => {
                                          handleReceiverBcc(user, "add");
                                          inputRefBcc.current.focus();
                                        }}
                                        className="font-medium"
                                      >
                                        {user.name}
                                        <p className="truncate text-xs text-gray-700">
                                          {user.emailAddress}
                                        </p>
                                      </p>
                                    </div>
                                  ) : null;
                                })}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-8 float-right  px-2 flex justify-between items-center gap-1 bg-white rounded-lg">
                      <button
                        className="cursor-pointer outline-none focus:outline-none transition-all text-gray-400 hover:text-gray-600"
                        onClick={() => {
                          setEditBcc(!editBcc);
                          if (bcc.length == 0) {
                            setIsBcc(false);
                          }
                        }}
                      >
                        <BiPencil />
                      </button>
                    </div>
                  </div>
                ) : null}

                <div className="ml-auto">
                  {!isCc ? (
                    <button
                      disabled={isSendingEmail}
                      className="text-gray-600 background-transparent font-bold p-2 text-xs outline-none focus:outline-none my-1 ease-linear transition-all duration-150"
                      onClick={() => {
                        setIsCc(!isCc);
                        setEditCc(!editCc);
                      }}
                    >
                      Cc
                    </button>
                  ) : null}
                  {!isBcc ? (
                    <button
                      disabled={isSendingEmail}
                      className="text-gray-600 background-transparent font-bold p-2 text-xs outline-none focus:outline-none my-1 ease-linear transition-all duration-150"
                      onClick={() => {
                        setIsBcc(!isBcc);
                        setEditBcc(!editBcc);
                      }}
                    >
                      Bcc
                    </button>
                  ) : null}
                </div>
              </div>

              {/* Subject Div Container*/}
              {isReply ? (
                <div className="flex flex-wrap w-full border-b-2 border-b-gray-100">
                  <div className="my-1 flex justify-between w-full">
                    <input
                      className="truncate my-1 w-full p-2 font-semibold outline-none focus:out"
                      type="text"
                      placeholder="Subject"
                      value={subject}
                      disabled
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap w-full border-b-2 border-b-gray-100">
                  <div className="my-1 w-full">
                    <input
                      disabled={isSendingEmail}
                      className="w-full my-1 p-2 font-semibold outline-none focus:out"
                      type="text"
                      placeholder="Subject"
                      value={subject}
                      onClick={() => {
                        if (bcc.length > 0) {
                          setIsBcc(true);
                        } else {
                          setIsBcc(false);
                        }
                        if (cc.length > 0) {
                          setIsCc(true);
                        } else {
                          setIsCc(false);
                        }
                      }}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Message Div Container */}
              <QuillEditor
                isSendingEmail={isSendingEmail}
                isReply={isReply}
                composeType={composeType}
                selectedRawHtml={selectedRawHtml}
                emailInfo={selectedIsReplyDetails}
                mentionData={mentionableNames}
                htmlMessage={htmlMessage}
                setHtmlMessage={setHtmlMessage}
                setForwardDetails={setForwardDetails}
                handleMessage={(e) => handleMessage(e)}
                setEditBcc={setEditBcc}
                setEditCc={setEditCc}
                setEditTo={setEditTo}
                cc={cc}
                bcc={bcc}
                setIsCc={setIsCc}
                setIsBcc={setIsBcc}
                setAttachments={setAttachments}
                attachments={attachments}
                inputRef={inputRef}
                placeholder={"Input your email Message Here."}
                isOutlookIntegrated={isOutlookIntegrated}
              />
            </div>
          </div>

          {/* Buttons Div Container */}
          <div className="px-3 py-2 flex flex-wrap gap-3 bg-gray-50 absolute bottom-0 justify-between w-full ">
            <div className="flex gap-2 justify-start">
              {/* Send Email Button */}
              {to.length > 0 && subject !== "" ? (
                isReply && composeType.value === 2 ? (
                  <button
                    disabled={isSendingEmail}
                    className={
                      isSendingEmail
                        ? "bg-blue-300 rounded font-semibold text-white p-2"
                        : "bg-blue-500 rounded font-semibold text-white p-2"
                    }
                    onClick={() => handleForwardEmail()}
                  >
                    Forward
                  </button>
                ) : (
                  <button
                    disabled={isSendingEmail}
                    className={
                      isSendingEmail
                        ? "bg-blue-300 rounded font-semibold text-white p-2"
                        : "bg-blue-500 rounded font-semibold text-white p-2"
                    }
                    onClick={() => handleSendEmail()}
                  >
                    Send
                  </button>
                )
              ) : (
                <button
                  className="rounded font-semibold bg-blue-300 text-white p-2"
                  disabled
                >
                  Send
                </button>
              )}
              {/* Draft Email Button */}
              {to.length > 0 && subject !== "" ? (
                <button
                  disabled={isSendingEmail}
                  className={
                    isSendingEmail
                      ? "bg-blue-300 rounded font-semibold text-white p-2"
                      : "bg-blue-500 rounded font-semibold text-white p-2"
                  }
                  onClick={() => handleDraftEmail()}
                >
                  Save To Drafts
                </button>
              ) : (
                <button
                  className="rounded font-semibold bg-blue-300 text-white p-2"
                  disabled
                >
                  Save To Drafts
                </button>
              )}
              {/* Attachment Email Button */}
              <div className="flex flex-wrap justify-between">
                <input
                  className="hidden"
                  ref={inputRef}
                  multiple="multiple"
                  type="file"
                  accept=".3gp, .flv, .mkv, .rtf, .odp, .ods, .odt, .doc,.docx, application/msword, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf, image/*, text/html, video/*, audio/*"
                  onChange={(e) => {
                    handleOnchangeFile(e);
                  }}
                />
                <button
                  disabled={isSendingEmail}
                  className=" rounded font-semibold text-gray-400 p-1 flex flex-col items-center gap-1"
                  onClick={() => {
                    handleOpenFile();
                  }}
                >
                  <IoMdAttach size={25} />
                  <span className="text-gray-400 text-xs flex items-center leading-none">
                    Add
                    <br />
                    Files
                  </span>
                </button>
              </div>
              {/* Copy Email Button */}
              <button
                disabled={isSendingEmail}
                className=" rounded font-semibold text-gray-400 p-1 flex flex-col items-center gap-1"
              >
                <IoMdCopy size={25} />
                <span className="text-gray-400 text-xs flex items-center leading-none">
                  Copy
                  <br />
                  Email
                </span>
              </button>
            </div>
            <div className="flex justify-end">
              {/* Delete Email Button */}
              <button
                disabled={isSendingEmail}
                onClick={() => handleClear()}
                className=" rounded font-semibold text-gray-400 p-1 flex flex-col items-center gap-1"
              >
                <AiOutlineDelete size={25} />
                <span className="text-gray-400 text-xs flex items-center leading-none">
                  Delete
                  <br />
                  Email
                </span>
              </button>
            </div>
          </div>
        </div>
      )}

      {showContactModal && (
        <AddContactModal
          close={() => {
            setShowContactModal(false);
            localStorage.removeItem("addEmail");
            setIsMinimized(false);
            setIsExpanded(isMobileDevice(width));
          }}
          fromComposeModal={true}
          setalertMessage={setalertMessage}
          setShowToast={setShowToast}
          setContactEmailList={setContacts}
          contactEmailList={contacts}
          width={width}
        />
      )}
    </>
  );
};

export default Compose;
