/* Import */
import React, { useState } from "react";

/* Assets */
import alert from "../../assets/images/alert.svg";
import { MdOutlineEmail } from "react-icons/md";
import { AppRoutes } from "../../constants/AppRoutes";

export default function ExitIntentModal({
  history,
  state,
  setShowContinuePrompt,
  resetProgress,
}) {
  return (
    <div className="relative">
      <div
        onClick={() => setShowContinuePrompt(false)}
        className="fixed inset-0 bg-white bg-opacity-90 backdrop-blur-md"
      ></div>
      <div className="fixed inset-0 flex items-center justify-center z-50 drop-shadow-xl">
        <div className="p-6 bg-white rounded-xl shadow flex-col justify-start items-center gap-8 inline-flex">
          <div className="w-96 flex-col justify-start items-start gap-5 inline-flex">
            <img src={alert} alt="alert" />
            <div className="flex-col justify-start items-start gap-2 inline-flex">
              <span className="text-gray-900 text-lg font-semibold">
                Do you want to continue your progress?
              </span>
              <span className="text-gray-500 text-sm font-normal">
                You have an unfinished progress. Do you want to continue where
                you left off last time?
              </span>
            </div>
            <div className="flex-col justify-start items-start gap-2 inline-flex">
              <span className="text-gray-400 text-xs font-normal">
                Clicking Cancel will delete your progress and you will have to
                start over again.
              </span>
            </div>
          </div>

          <div className="w-full justify-start items-center gap-3 inline-flex">
            <button
              onClick={() => {
                setShowContinuePrompt(false);
                resetProgress();
              }}
              className="w-full text-slate-700 text-base font-semibold px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                history.replace(
                  `${AppRoutes.ONBOARDING}/${state.Progress.split(" ")[1]}`
                );
                setShowContinuePrompt(false);
              }}
              className="w-full text-white text-base font-semibold px-4 py-2.5 bg-green-700 rounded-lg shadow border border-emerald-700 justify-center items-center hover:bg-green-50 hover:text-green-800"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
