import React from 'react';
import PropTypes from 'prop-types';

const RowTile = ({ children }) => {
  return (
    <div className="flex w-full bg-white mb-4 rounded-lg border border-gray-300">
      {children}
    </div>
  );
};

RowTile.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RowTile;
