import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { API } from "aws-amplify";
import "../../assets/styles/Inbox.css";
import Button from "../../shared/Button";
import { MdArrowBackIos } from "react-icons/md";
import { BiPencil, BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import EmailParser from "../../shared/EmailParser";
import { dateTimeNoSecondsFormatter } from "../../shared/userPreferredDateTime";

var moment = require("moment");

function EmailPreview({
  handleComposeUnsavedTabs,
  allowComposeEmail,
  emailContent,
  setEmailContent,
  showPreview,
  setShowPreview,
  emailInfo,
  setEmailInfo,
  setShow,
  isUpdatedClientMatter,
}) {
  const [emailHTML, setEmailHTML] = useState("");
  const [messageId, setMessageId] = useState("");
  const [threadId, setThreadId] = useState("");
  const [emailPreviewError, setEmailPreviewError] = useState(false);

  // MMA-2016 Expand Email Contacts
  const [emailExpanded, setEmailExpanded] = useState(false);

  useMemo(() => emailInfo, [emailInfo]);
  useMemo(() => showPreview, [showPreview]);
  useMemo(() => emailContent, [emailContent]);

  function handleClose() {
    setShowPreview(false);
    setShow(false);
    setEmailContent(null);
    setEmailInfo(null);
  }
  useEffect(() => {
    console.group("Email Preview");
    console.log("emailContent", emailContent);
    console.log("emailInfo", emailInfo);
    console.log("threadId", threadId);
    console.log("messageId", messageId);
    console.groupEnd();
  });

  const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
    gmailMessage(id: $id) {
      payload {
        content
      }
      threadId
    }
  }
  `;

  const qGetFileDownloadLink = `
  query getAttachmentDownloadLink($id: String) {
    gmailAttachment(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const getEmailPayload = useCallback(async (messageId) => {
    const params = {
      query: qGetMessagePayload,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);
    let newPayload = { payload: [{ content: "" }] };

    if (result?.data?.gmailMessage?.payload) {
      result?.data?.gmailMessage?.payload?.forEach((data, index) => {
        newPayload = {
          payload: [
            {
              content: newPayload.payload[0].content + data?.content,
            },
          ],
        };
      });

      return newPayload;
    }
  }, []);

  const getInlineAttachmentUrl = useCallback(async (id) => {
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    const { data } = await API.graphql(params);
    return data?.gmailAttachment?.downloadURL;
  }, []);

  const replaceCidWithUrl = async (content, attachments) => {
    const mapping = []; // Array to store the mapping of attachment URLs and names

    for (const attachment of attachments) {
      // Get the inline attachment URL asynchronously
      const url = await getInlineAttachmentUrl(attachment.id);

      // Add the URL and name to the mapping array
      mapping.push({
        url: url,
        name: attachment.name,
      });
    }

    let modifiedContent = content; // Variable to store the modified content

    mapping.forEach((item) => {
      const srcRegex = new RegExp(`src="cid:[^"]*"`, "g");
      // Remove the original src attribute value
      modifiedContent = modifiedContent.replace(srcRegex, "");

      const altRegex = new RegExp(`alt="${item.name}"`, "g");
      // Replace the alt attribute with the new src attribute and the corresponding URL and name
      modifiedContent = modifiedContent.replace(
        altRegex,
        `src="${item.url}" alt="${item.name}"`
      );
    });

    return modifiedContent; // Return the modified content
  };

  const handleGetParsedEmail = useCallback(async () => {
    const gmailMessageId = emailInfo.id;
    let msgPreview = await getEmailPayload(gmailMessageId);
    if (msgPreview?.payload) {
      console.log("Email Details", emailContent);

      try {
        const parsedPayload = JSON.parse(msgPreview.payload[0].content);

        if (parsedPayload) {
          const parsedEmail = EmailParser(parsedPayload);

          const modifiedEmailContent = await replaceCidWithUrl(
            parsedEmail.html,
            emailInfo?.attachments?.items
          );
          setMessageId(parsedEmail.messageId);
          setEmailHTML(modifiedEmailContent);
        }
      } catch (error) {
        console.error(error);
        setEmailPreviewError(true);
      }
    }
  }, []);

  // Handling email content to remove unsupportive characters
  useEffect(() => {
    async function setEmailContent() {
      if (!emailContent) return;
      //MMA 1984 when emailContent.payload does not exist onClick
      //Get the latest update of email payload

      if (!emailContent.payload && emailInfo?.id) {
        handleGetParsedEmail();
      }

      if (emailContent.payload) {
        console.log("Email Details", emailContent);

        try {
          const parsedPayload = JSON.parse(emailContent.payload[0].content);

          if (parsedPayload) {
            const parsedEmail = EmailParser(parsedPayload);

            const modifiedEmailContent = await replaceCidWithUrl(
              parsedEmail.html,
              emailInfo?.attachments?.items
            );

            setMessageId(parsedEmail.messageId);
            setEmailHTML(modifiedEmailContent);
          }
        } catch (error) {
          console.error(error);
          setEmailPreviewError(true);
        }
      }
    }

    setEmailContent();
  }, [emailContent]);

  useEffect(() => {
    if (emailInfo && emailInfo?.id) {
      setThreadId(emailInfo.id);
    }
  }, [emailInfo]);

  return (
    <>
      <div>
        <div
          className="fixed inset-0 items-center justify-center flex-none max-h-screen overflow-x-hidden overflow-y-hidden outline-none focus:outline-none"
          style={{ zIndex: 70 }}
        >
          <div
            className="w-full h-screen max-w-md max-h-screen pt-16 mx-auto"
            onClick={(e) => handleClose()}
          >
            <div
              className="relative flex flex-col items-center w-full max-h-full min-h-full p-4 bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Heading Div */}
              <div className="w-full pb-2" id="emailHeader">
                <div className="w-full flex justify-between">
                  <Button
                    className="mb-2 border-gray-300"
                    onClick={(e) => handleClose()}
                  >
                    <MdArrowBackIos className="float-left text-xl" />
                    Back
                  </Button>
                  {allowComposeEmail && (
                    <Button
                      variant="green-l"
                      className="ml-2 mb-2 flex items-center justify-center rounded-md bg-green-500 p-2 px-3 text-white hover:bg-green-400"
                      onClick={() => {
                        handleComposeUnsavedTabs(
                          isUpdatedClientMatter,
                          true,
                          true,
                          emailInfo,
                          threadId,
                          messageId,
                          emailHTML
                        );
                      }}
                    >
                      <BiPencil className="text-white mr-1" />
                      <span className="text-white">Compose</span>
                    </Button>
                  )}
                </div>
              </div>

              <div className="w-full overflow-x-auto overflow-y-auto test123">
                <div className="font-medium border-b mb-2 pb-1">
                  {emailInfo && emailInfo.subject !== undefined ? (
                    <>
                      <p>{`From: ${emailInfo.from}`}</p>
                      <p>
                        {`Date: ${dateTimeNoSecondsFormatter(emailInfo.date)}`}
                      </p>
                      <p>{`Subject: ${emailInfo.subject}`}</p>
                      {emailInfo.to.split(", ").length > 1 ? (
                        <div className="flex justify-between items-center">
                          {!emailExpanded ? (
                            <>
                              <p>{`To: ${emailInfo.to.split(", ")[0]}, +${
                                emailInfo.to.split(", ").length - 1
                              } more`}</p>
                              <div
                                className="flex gap-2 items-center rounded-md px-3"
                                onClick={() => setEmailExpanded(!emailExpanded)}
                              >
                                <p className="text-blue-500 underline">
                                  View All
                                </p>
                                <BiSolidUpArrow size={10} />
                              </div>
                            </>
                          ) : (
                            <>
                              <p>{`To: ${emailInfo.to.split(", ")[0]}`}</p>
                              <div
                                className="flex gap-2 items-center rounded-md px-3"
                                onClick={() => setEmailExpanded(!emailExpanded)}
                              >
                                <p className="text-blue-500 underline">Hide</p>
                                <BiSolidDownArrow size={10} />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <p>{`To: ${emailInfo.to}`}</p>
                      )}
                      {emailExpanded && (
                        <div className="flex flex-col">
                          {emailInfo.to
                            .split(", ")
                            .slice(1)
                            .map((item) => (
                              <div className="flex gap-1">
                                <p className="text-white">To: </p>
                                <p key={item}>{item}</p>
                              </div>
                            ))}
                        </div>
                      )}
                      {emailInfo.cc && <p>{`CC: ${emailInfo.cc}`}</p>}
                    </>
                  ) : (
                    <>
                      <p>
                        <Skeleton count={4} />
                      </p>
                    </>
                  )}
                </div>
                {emailPreviewError ? (
                  <p className="text-red-500 mt-2">
                    {`Sorry about that. We encountered an issue while trying to preview the email
                  content.`}
                  </p>
                ) : emailHTML ? (
                  <p
                    className="mt-2 mx-auto whitespace-normal"
                    dangerouslySetInnerHTML={{
                      __html: emailHTML,
                    }}
                  ></p>
                ) : (
                  <SkeletonTheme>
                    <Skeleton count={25} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-25"
          style={{ zIndex: 60 }}
        ></div>
      </div>
    </>
  );
}

export default EmailPreview;
