import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

function Signout() {
  let history = useHistory();

  const signOut = async () => {
    try {
      await Auth.signOut();

      // Retrieve necessary items before clearing localStorage
      const sharedLink = localStorage.getItem("lastUrlAccessed");
      const signInError = localStorage.getItem("SignInError");
      // const localUserType = localStorage.getItem('localUserType'); // Uncomment if needed

      // Clear localStorage and selectively restore saved data
      localStorage.clear();

      if (sharedLink) {
        localStorage.setItem("lastUrlAccessed", sharedLink);
        console.log("Sign out completed w/ shared link.");
      }
      // Restore SignInError if it exists
      if (signInError !== null) {
        localStorage.setItem("SignInError", signInError);
      }
      // Restore localUserType if it exists
      // if (localUserType !== null) { // Uncomment if needed
      //   localStorage.setItem('localUserType', localUserType);
      // }

      console.log("Sign out completed.");
      // history.push("/");
      // Refresh current components when user logged out.
      window.location.href = "/";
    } catch (error) {
      // Handle the error appropriately
      console.error("An error occurred during sign out", error);
      // Add any additional error handling logic here, if necessary
    }
  };

  /** Unused function
  function clearLocalStorage() {
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("userType");
    localStorage.removeItem("company");
    localStorage.removeItem("companyId");
    // localStorage.removeItem("access");
    localStorage.removeItem('answerpage_1');
    localStorage.removeItem('answerpage_2');
    localStorage.removeItem('answerpage_3');
    localStorage.removeItem('answerpage_4');
    localStorage.removeItem('answerpage_5');
  }
  **/

  useEffect(() => {
    signOut();
  });

  return null;
}

export default Signout;
