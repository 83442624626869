import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import ToastNotification from "../toast-notification";
import Illustration from "../../assets/images/session-timeout.png";
import { Auth } from "aws-amplify";
import { AiFillRightCircle } from "react-icons/ai";
import { AppRoutes } from "../../constants/AppRoutes";

export default function SessionTimeout() {
  let history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  // useEffect(() => {
  //     //Signout();
  // });

  const hideToast = () => {
    setShowToast(false);
  };

  async function Signout() {
    await Auth.signOut().then(() => {
      clearLocalStorage();
      console.log("Sign out completed.");
      history.push(`${AppRoutes.SIGNOUT}`);
    });

    function clearLocalStorage() {
      localStorage.removeItem("userId");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("userType");
      localStorage.removeItem("company");
      localStorage.removeItem("companyId");
      // localStorage.removeItem("access");
    }
  }

  const imageSize = {
    width: 400,
    height: 400,
  };

  return (
    <>
      <div className="justify-center items-center flex flex-col overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="fixed w-full my-6 mx-auto h-full">
          <div className="h-full border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
            <div className="items-center w-full h-full ">
              <div className="details-txt text-center content-center h-full my-auto px-10">
                <div className="flex items-center justify-center py-3">
                  <img src={Illustration} alt="" style={imageSize} />
                </div>
                <h1 className="text-center content-center">Whoops!</h1>
                <p className="content-center items-center justify-items-center">
                  It seems like the page you were looking for went out to get
                  some milk.
                </p>
                <div className="flex flex-col gap-4">
                  <div className="text-center content-center pt-5">
                    <button
                      className="bg-gray-800 text-white px-5 py-3 rounded"
                      onClick={() => Signout()}
                    >
                      <div className="inline-flex text-white items-center">
                        Go to Login{" "}
                        <AiFillRightCircle className="mt-1/2 ml-1" />
                      </div>
                    </button>
                  </div>
                  <div className="text-center content-center">
                    <p
                      className="inline-flex text-white items-center font-semibold cursor-pointer"
                      onClick={() => history.goBack()}
                    >
                      Go Back
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
