import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import ToastNotification from "../toast-notification";
import { Auth, API } from "aws-amplify";
import "../../assets/styles/AccountSettings.css";
import { MdArrowForwardIos } from "react-icons/md";
import Select from "react-dropdown-select";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FaTachometerAlt } from "react-icons/fa";
import { CgChevronLeft } from "react-icons/cg";
import Button from "../../shared/Button";
import {
	useWindowDimensions,
	isMobileDevice,
} from "../../shared/mobileViewFunctions";
import CreateCategoryModal from "./create-category-modal";

export default function CategoryLibrary() {
	const { matter_id } = useParams();
	let history = useHistory();
	const companyId = localStorage.getItem("companyId");
	const { height, width } = useWindowDimensions();

	const [categoryList, setCategoryList] = useState([]);
	const [categoryName, setCategoryName] = useState("");
	const [originalCategory, setOriginalCategory] = useState("");
	const [show, setShow] = useState(true);

	const [showCreateModal, setShowCreateModal] = useState(false);

	const qGetCategoryByCompany = `query getCategoryByCompany($id: String) {
		company(id: $id) {
		  categories{
			items {
			  id
			  name
			  createdAt
			}
		  }
		}
	  }`;

	const mUpdateCategoryByID = `mutation updateCategory($id: ID, $name: String) {
		categoryUpdate(id: $id, name: $name) {
		  id
		}
	  }`;

	useEffect(() => {
		getCategories();
	}, []);

	const getCategories = async () => {
		//fetch category list form backend
		setShow(false);
		const categoryResult = await API.graphql({
			query: qGetCategoryByCompany,
			variables: { id: companyId },
		});
		try {
			console.log("CATEGORY RESULT IS:", categoryResult);
			let temp = categoryResult.data.company.categories.items.sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});
			setCategoryList(temp);
		} catch (error) {
			setCategoryList([]);
			console.log("ERROR FROM FETCHING CATEGORY LIBRARY:", error);
		}
		setShow(true);
	};

	//changing values
	const handleChangeCategory = (e) => {
		setCategoryName(e.currentTarget.textContent);
	};

	function stringValPatternValidation(stringVal) {
		return stringVal.trim().length === 0;
	}

	const saveCategory = async (categoryID) => {
		if (
			categoryName === "" ||
			categoryName === null ||
			stringValPatternValidation(categoryName) === true
		) {
			setShow(false);
			alert("Category Name Cannot Be Blank");
			getCategories();
		} else if (checkDuplicate(categoryID, categoryName)) {
			setShow(false);
			alert("Category Name Cannot Have Duplicates");
			getCategories();
		} else if (categoryName.length > 30) {
			setShow(false);
			alert("Category Name Cannot Be Longer Than 30 Characters");
			getCategories();
		} else if (checkIfEdited(categoryID, categoryName)) {
			setShow(false);
			let updateCategoryResult = await API.graphql({
				query: mUpdateCategoryByID,
				variables: { id: categoryID, name: categoryName },
			});
			console.log("UPDATE CATEGORY RESULT:", updateCategoryResult);
			getCategories();
		}
	};

	//returns true if category name has been edited
	function checkIfEdited(categoryID, categoryName) {
		for (const category of categoryList) {
			if (
				category.name.toLowerCase() === categoryName.toLowerCase() &&
				category.id == categoryID
			) {
				return false;
			}
		}
		return true;
	}

	//returns true if category name has duplicate
	function checkDuplicate(categoryID, categoryName) {
		for (const category of categoryList) {
			if (
				category.name.toLowerCase() === categoryName.toLowerCase() &&
				category.id != categoryID
			) {
				return true;
			}
		}
		return false;
	}

	return (
		<>
			{width > 844 ? (
				<>
					{/* WEB VIEW */}
					<div className="ml-16">
						{showCreateModal ? (
							<CreateCategoryModal
								setShowCreateModal={setShowCreateModal}
								categoryList={categoryList}
								setCategoryList={setCategoryList}
								companyId={companyId}
							></CreateCategoryModal>
						) : (
							<></>
						)}
						<div className="flex flex-col ml-6">
							<div className="sticky hidden pl-12 sm:pl-0 top-0 py-4 sm:flex items-center gap-2 bg-white z-10">
								<div
									onClick={() => history.goBack()}
									className="w-8 py-5 cursor-pointer"
								>
									<CgChevronLeft />
								</div>
								<div>
									<p className="flex flex-col">
										<span className="text-lg font-bold flex">
											Category Library
										</span>
										<span className=" text-grey-600">Manage Categories</span>
									</p>
									<div className="flex items-center gap-3 text-gray-500 mt-2">
										<Link to="/dashboard">
											<div className="flex items-center gap-3">
												<FaTachometerAlt />
												<p className="hidden sm:block font-semibold ">
													Dashboard
												</p>
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="flex justify-end w-full px-5">
							<Button
								type="button"
								variant="secondary"
								className="self-end"
								size="medium"
								onClick={() => setShowCreateModal(true)}
							>
								Create Category
							</Button>
						</div>
						<div className="p-5 ml-5">
							<table className="min-w-full divide-y divide-gray-200 border flex-1">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-5 text-left text-xs font-medium text-gray-500 tracking-wider w-2/6"
										>
											Category List
										</th>
									</tr>
								</thead>
								<tbody className="bg-white divide-y divide-gray-200">
									{categoryList !== null ? (
										categoryList?.length === 0 ? (
										<>
											<tr>
												<div className="ml-6 py-5">No result found</div>
											</tr>
										</>
										) : 
										(
											<>
												{categoryList?.map((category, index) => (
													<tr key={category?.id}>
														<td className="p-2 flex">
															<p className="w-10 mt-2">{index + 1}</p>
															{show === true ? (
																<div
																	id={category?.id}
																	className={`p-1 border border-2 border-gray-300 overflow resize-none w-full bg-white rounded font-poppins h-max-1 py-1 ml-1 mt-1`}
																	suppressContentEditableWarning
																	dangerouslySetInnerHTML={{
																		__html: category?.name,
																	}}
																	onBlur={() => {
																		saveCategory(category?.id);
																	}}
																	onInput={(e) => {
																		handleChangeCategory(e);
																	}}
																	onClick={() => {
																		setCategoryName(category?.name);
																		setOriginalCategory(category?.name);
																	}}
																	contentEditable={true}
																></div>
															) : (
																<>
																	<div className="w-full">
																		<SkeletonTheme height={"30px"}>
																			<Skeleton count={1} />
																		</SkeletonTheme>
																	</div>
																</>
															)}
														</td>
													</tr>
												))
												}
											</>
										)
									) : 
									(
									<></>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</>
			) : (
				<>{/* MOBILE VIEW */}</>
			)}
		</>
	);
}
