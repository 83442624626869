import React, { useEffect, useState } from "react";
// import {
//   Route,
//   Switch,
//   useHistory,
// } from "react-router-dom/cjs/react-router-dom.min";
import {
  Route,
  Switch,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";

import { AppRoutes } from "../../../constants/AppRoutes";
import Spinner from "../../../shared/Spinner";
import QuestionChoice from "./input-type-factory";
import { API, Storage } from "aws-amplify";
import config from "../../../aws-exports";

// ASSETS FOR SPLASH SCREEN
// import loading from "../../../assets/images/loading.gif";
import loading from "../../../assets/images/loadingHba.gif";
import loadingDone from "../../../assets/images/loading-done.svg";
import checkDone from "../../../assets/images/check-green.svg";
import completeForm from "../../../assets/images/complete-form.gif";
import Button from "../../../shared/Button";
import { FiArrowRight } from "react-icons/fi";
import HBAImage from "./hba.png";
import "./slide.css";
import anime from "animejs";

import ProgressBar from "@ramonak/react-progress-bar";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

const moment = require("moment");

Storage.configure({
  region: config.aws_user_files_s3_bucket_region,
  bucket: config.aws_user_company_assets_s3_bucket,
  identityPoolId: config.aws_user_pools_id,
});

// GRAPHQL

const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput], $dynamicData: AWSJSON) {
    backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs, dynamicData: $dynamicData) {
        id
        createdAt
        date
        description
        order
        briefs {
          id
          name
        }
        dynamicData
    }
}`;

const mCreateMatterFile = `
      mutation createMatterFile ($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int, $details: String) {
        matterFileCreate(matterId: $matterId, s3ObjectKey: $s3ObjectKey, size: $size, type: $type, name: $name, order: $order, details: $details) {
          id
          name
        }
      }
  `;

const mTagBackgroundFile = `
  mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileTag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }
`;

const BACKGROUND_TABLE_QUERY = `
query getBackgroundTable($clientMatterId: ID!) {
  backgroundTable(clientMatterId: $clientMatterId) {
    id
    columns {
    id
    accessorKey
    headerText
    enabled
    type
    optionsText
    order
    presets {
      id
      name
    }
    }
    createdAt
    updatedAt
  }
}`;

// const mUpdateUserType = `mutation updateUser($id: ID!, $email: AWSEmail!, $userCategory: UserCategory) {
//   userUpdate(
//     id: $id
//     email: $email
//     userCategory: $userCategory
//   ) {
//     id
//   }
// }`;

const mUpdateUserType = `mutation updateUserCompany($companyId: ID, $userCategory: UserCategory, $userId: ID) {
  userCompanyUpdate(
    companyId: $companyId
    userCategory: $userCategory
    userId: $userId
  ) {
    id
  }
}`;

const qBriefBackgroundList = `
query getBriefByID($id: ID, $sortOrder: OrderBy) {
  brief(id: $id) {
    id
    backgrounds(sortOrder: $sortOrder) {
      items {
        id
        description
        comments
        date
        createdAt
        order
        files {
          items {
            id
            name
          }
        }
      }
      nextToken
    }
  }
}
`;

const mUpdateBackgroundDynamicData = `
mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
  backgroundUpdate(id: $id, dynamicData: $dynamicData) {
    id
    dynamicData
  }
}
`;

const MultiStepForm = ({ dataWrapper }) => {
  const {
    initialStates,
    state,
    dispatch,
    page,
    pageNumber,
    reducer,
    clientMatterId,
  } = dataWrapper;

  let history = useHistory();
  // console.log("CLIENT MATTER ID: ", clientMatterId);
  // console.log("ANSWERS: ", state);
  // localStorage.setItem("answers", JSON.stringify(state));
  // console.log(JSON.parse(localStorage.getItem("answers")));
  // console.log("PAGE: ", page);

  const setPage = (newPage) => {
    /*
     * I've encountered a problem wherein if you go back to the first page and
     * make a new attempt, the state will be the same as the previous attempt.
     * This is a workaround to reset the state
     */
    // if (newPage === "page 2") {
    //     dispatch({ type: "RESET_STATE", payload: initialStates });
    // }

    /***
     * This is to prevent saving state.Progress to email-prompt
     * when the user clicks save and continue later
     ***/
    if (newPage !== "email-prompt") {
      dispatch({ type: "CHANGE_PROGRESS", payload: newPage });
    }

    // This is a hacky way to get the page number for the URL
    const url = newPage.split(" ");

    /***
     * In charge of moving the user to the next page
     * (ex. Progress: page 1. Only 1 will be passed to the URL)
     ***/
    if (url.length > 1) {
      history.push(`${AppRoutes.GETTINGSTARTED}/${url[1]}`);
      return;
    }

    /***
     * In charge of moving the user to the next page if the new page is not numbered
     * (ex. Progress: "done". Done will be passed to the URL)
     ***/
    history.push(`${AppRoutes.GETTINGSTARTED}/${newPage}`);
  };

  // This function is used to return a version of the state wherin all the
  // questions in the removeArr are removed.
  const changeQuestionOrder = (state, removeArr) => {
    let tempCurrentState = resetOrder(state); // Resets state
    let tempOrderArr = [];
    let newState = {};

    let i = 0;
    Object.keys(tempCurrentState).forEach(function (key, index) {
      let obj = tempCurrentState[key];

      if (obj.category && !removeArr.includes(obj.category)) {
        newState[key] = {
          ...tempCurrentState[key],
        };
        tempOrderArr.push(tempCurrentState[key].order);
      }
    });

    for (let i = 0; i < tempOrderArr.length - 1; i++) {
      newState[`page_${tempOrderArr[i]}`] = {
        ...tempCurrentState[`page_${tempOrderArr[i]}`],
        order: tempOrderArr[i],
        redirectTo: tempOrderArr[i + 1],
      };
    }

    newState[`page_${tempOrderArr[tempOrderArr.length - 1]}`] = {
      ...tempCurrentState[`page_${tempOrderArr[tempOrderArr.length - 1]}`],
      order: tempOrderArr[tempOrderArr.length - 1],
      redirectTo: "end",
    };

    newState["Progress"] = state.Progress;
    console.log("\n\n\n\n\n\n");
    console.log("changeQuestionOrder() => newState:", newState);
    console.log("\n\n\n\n\n\n");
    return newState;
  };

  // This function is used to reset the state to contain previously removed questions
  function resetOrder(state) {
    let newState = {
      ...initialStates,
      ...state,
    };

    let i = 1;
    Object.keys(newState).forEach(function (key, index) {
      let obj = newState[key];
      if (obj.category) {
        if (Object.keys(newState).length > index) {
          newState[key] = {
            ...newState[key],
            order: i,
            redirectTo: i + 1,
          };
        } else {
          newState[key] = {
            ...newState[key],
            order: i,
            redirectTo: "end",
          };
        }
        i++;
      }
    });

    return newState;
  }
  const ProgressCalculator = () => {
    let progress = window.location.href.split("/");
    let pageCount = 1;
    for (const key in state) {
      if (key?.includes("page")) {
        pageCount++;
      }
    }
    //Takes the page number from the URL
    progress = progress[progress.length - 1];
    let progressValue = 100;
    if (progress !== "done") {
      progressValue = (progress / pageCount) * 100;
    }
    return progressValue + "%";
  };

  const [saving, setSaving] = useState(false);

  // useEffect(() => {
  //   if (pageNumber == 5 && state.page_5.answers.length > 0) {
  //     setSaving(true);
  //     const unloadCallback = (event) => {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       return "";
  //     };

  //     window.addEventListener("beforeunload", unloadCallback);
  //     return () => window.removeEventListener("beforeunload", unloadCallback);
  //   }
  // }, [pageNumber, state]);

  return (
    <>
      <div className="h-full flex gap-12 flex-col">
        {/* {pageNumber < 6 && (
          <div className="text-center text-xl font-bold">
            {" "}
            Step {pageNumber} of 5{" "}
          </div>
        )} */}
        {/* <Switch> */}
        {/* <Route
                        path={`${AppRoutes.ONBOARDING}/1`}
                        render={(props) => (
                            <PageOne
                                {...props}
                                setPage={setPage}
                                // isStillFetching={isStillFetching}
                                // isCompanyIdSet={isCompanyIdSet}
                                // tempStore={tempStore}
                                // setTempStore={setTempStore}
                                // initializeSession={initializeSession}
                            />
                        )}
                    /> */}
        {/* IF IN CASE THE USER TRIES TO BYPASS THE PAGES */}
        {
          <Switch key="questionnaire-switch">
            {Object.entries(state).map(([key, page]) => {
              return (
                <Route
                  key={page.order}
                  exact
                  path={`${AppRoutes.GETTINGSTARTED}/${page.order}`}
                  render={(props) => (
                    <DynamicPage
                      {...props}
                      // tempStore={tempStore}
                      setPage={setPage}
                      dataWrapper={dataWrapper}
                      page={page}
                      title={page?.headerText}
                      subtitle={page?.subheader}
                      image={page?.image}
                      style={page?.style}
                      preset={page?.type}
                      choices={page?.questions}
                      answer={state[`page_${page?.order}`]?.answers}
                      setSaving={setSaving}
                      changeQuestionOrder={changeQuestionOrder}
                      resetOrder={resetOrder}
                    />
                  )}
                />
              );
            })}
            <Route
              key={page}
              exact
              path={`${AppRoutes.GETTINGSTARTED}/setting-up`}
              render={(props) => (
                <SplashScreen
                  {...props}
                  // tempStore={tempStore}
                  dataWrapper={dataWrapper}
                />
              )}
            />
            {/*
                            <Route
                                path="*"
                                component={
                                    isStillInitializing && isPageLoading && SessionTimeout
                                }
                            /> */}
          </Switch>
        }
        {/* <Switch> */}
        {/* To avoid showing the session timeout component when the user is
            still initializing/loading from an email link */}
        {/* <Route
                            path="*"
                            component={
                                !isStillInitializing && !isPageLoading && SessionTimeout
                            }
                        /> */}
        {/* </Switch> */}
        {/* </Switch> */}
        {/* <SaveAndContinue setPage={setPage} /> */}
      </div>
    </>
  );
};

// function PageOne({
//     setPage,
//     isCompanyIdSet,
//     isStillFetching,
//     tempStore,
//     setTempStore,
//     initializeSession,
// }) {
//     return (
//         <div
//             className="flex flex-col gap-8 items-center"
//             style={{ width: "36rem" }}
//         >
//             <div className="flex flex-col gap-4">
//                 <span className="text-center text-3xl font-semibold">
//                     Resolve your tenancy dispute—
//                     <br />
//                     step by step with MMA
//                 </span>
//                 <span className="text-center text-black font-normal">
//                     We understand that dealing with a tenancy dispute can be challenging.
//                     Share your perspective and concerns with us, and we'll guide you
//                     through the process, building a personalised case to address your
//                     specific situation.
//                 </span>
//             </div>

//             <div className="inline-flex">
//                 <button
//                     disabled={
//                         tempStore.companyId === null ||
//                         isStillFetching ||
//                         Object.keys(tempStore).length === 0
//                     }
//                     onClick={() => {
//                         initializeSession(tempStore, setTempStore);
//                         setPage("page 2");
//                     }}
//                     className={
//                         "flex items-start px-7 py-4 bg-green-700 rounded-lg text-white text-base font-semibold " +
//                         (tempStore.companyId === null ||
//                             isStillFetching ||
//                             Object.keys(tempStore).length === 0
//                             ? "bg-opacity-50 cursor-not-allowed"
//                             : "hover:bg-green-50 hover:text-green-800")
//                     }
//                 >
//                     LET'S START
//                 </button>
//             </div>
//         </div>
//     );
// }

function DynamicPage({
  tempStore,
  setPage,
  dataWrapper,
  page,
  title,
  subtitle,
  image,
  style,
  preset,
  choices,
  answer,
  setSaving,
  resetOrder,
  changeQuestionOrder,
}) {
  const {
    questions,
    state,
    dispatch,
    isStillInitializing,
    showToast,
    setShowToast,
    setAlertMessage,
  } = dataWrapper;
  // console.log(answer);
  const handlePageChange = () => {
    var nextPage = page?.redirectTo;
    var tempState = { ...state };

    // USED TO VALIDATE USER ANSWERS
    if (!isInputValid()) return;

    /*
      If user is on usertype and has selected "Owner Builder",
      remove the remaining preliminary questions, else reset question order
    */
    if (page.category === "About User Types") {
      console.log("QUESTIONS ->> ", page?.answers[0]?.question);
      console.log("ANSWER ->> ", page?.answers[0]?.order);
      if (page?.answers[0]?.order === 3) {
        let removeArr = [
          "About Property Address",
          "About Builders",
          "About Contracts",
          "About Project Status",
          "Property Address",
          "Contract Details",
          "Builder Details",
          "Contracts",
          "Project Completion Date",
        ];
        tempState = changeQuestionOrder(state, removeArr);
        nextPage = tempState[`page_${page.order}`].redirectTo;
      } else if (page?.answers[0]?.order === 2) {
        let removeArr = ["About Builders", "Builder Details"];
        tempState = changeQuestionOrder(state, removeArr);
        nextPage = tempState[`page_${page.order}`].redirectTo;
      } else {
        tempState = resetOrder(state);
        nextPage = tempState[`page_${page.order}`].redirectTo;
      }
    }

    // if (
    //   page.category === "About Property Address" ||
    //   page.category === "Project Completion Date"
    // ) {
    //   if (localStorage.getItem("userCategory") === "HBA_HOMEOWNER") {
    //     let removeArr = ["About Builders", "Builder Details"];
    //     tempState = changeQuestionOrder(state, removeArr);
    //     nextPage = tempState[`page_${page.order}`].redirectTo;
    //   }
    // }

    if (page.category === "About Contracts") {
      let removeArr = [];
      Object.keys(state).forEach(function (key, index) {
        let obj = state[key];
        if (obj.category) {
          if (obj?.answers[0]?.order === 2) {
            switch (obj.category) {
              case "About Property Address":
                removeArr.push("Property Address");
                break;
              case "About Builders":
                removeArr.push("Builder Details");
                break;
              case "About Contracts":
                removeArr.push("Contract Details");
                removeArr.push("Project Completion Date");
                removeArr.push("Contracts");
                break;
            }
          }
        }
      });

      // if (localStorage.getItem("userCategory") === "HBA_HOMEOWNER") {
      //   removeArr.push("About Builders");
      //   removeArr.push("Builder Details");
      // }

      tempState = changeQuestionOrder(state, removeArr);
      nextPage = tempState[`page_${page.order}`].redirectTo;
    }

    /*
      If user is on project status and has selected "Have started construction",
      remove the remaining preliminary questions, else reset question order

      If user is on project status (which is the last preliminary question)
      remove main onboarding questions depending on the users' answers on the
      preliminary questions

      DEVS: If there will be changes to the prelim questions, adjust the last
      preliminary question accordingly
    */

    if (page.category === "About Project Status") {
      if (page?.answers[0]?.order === 2) {
        let removeArr = [
          "About Property Address",
          "About Builders",
          "About Contracts",
        ];
        tempState = changeQuestionOrder(state, removeArr);
        nextPage = tempState[`page_${page.order}`].redirectTo;
      } else {
        tempState = resetOrder(state);
        nextPage = tempState[`page_${page.order}`].redirectTo;
      }
    }

    // UPDATE STATE
    if (tempState !== state) {
      dispatch({ type: "CHANGE_STATE", payload: tempState });
    }

    // Check if the next page exists
    setPage(
      nextPage === "end" || !questions.some((x) => nextPage === x.order)
        ? `setting-up`
        : `page ${nextPage}`
    );
  };

  const isInputValid = () => {
    // CHECKING IF THERE ARE QUESTIONS
    const questions = page.questions;
    if (!questions) {
      // RETURN TRUE BECAUSE THERE IS NO INPUT FIELDS TO BE CHECKED
      return true;
    }

    // CHECKING IF ALL INPUT FIELDS ARE FILLED IN
    const answers = page?.answers?.filter(
      (answer) => answer && answer.answer !== ""
    );
    const isRequired = page.properties?.isRequired;
    const isSingle = page.type;
    // if (
    //   isRequired &&
    //   ((isSingle === "single" && answers?.length === 0) ||
    //     (isSingle !== "single" && answers?.length !== questions?.length))
    // ) {
    //   showError("Fill up all input fields before proceeding");
    //   return false;
    // }

    // CHECKING FOR EMAIL INPUT
    // const emailQuestions = questions.filter(
    //   (question) => question.type === "email"
    // );
    // if (emailQuestions.length > 0) {
    //   const emailAnswers = answers?.filter((answer) =>
    //     emailQuestions.find((question) => question.order === answer?.order)
    //   );

    //   let emailRegex = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //   for (const obj of emailAnswers) {
    //     if (!emailRegex.test(obj?.answer)) {
    //       showError("Email is not valid format");
    //       return false;
    //     }
    //   }
    // }

    // RETURNS TRUE IF ALL ANSWERS ARE VALID
    return true;
  };

  const showError = (message) => {
    if (!showToast) {
      setAlertMessage(message);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  };
  /**
   * Handles skipping of pages
   * @returns {void}
   */
  const handleSkipPage = () => {
    const nextPage = page?.redirectTo;

    // EMPTIES ANSWER WHEN SKIP BUTTON IS SELECTED
    state["page_" + page.order].answers = [];

    if (nextPage === "end") {
      setPage("setting-up");
      return;
    }
    setPage(`page ${nextPage}`);
  };

  /**
   * Handles adding of more options
   * @returns {void}
   */
  const handleAddMore = () => {
    /* Checks if the option that will be concatenated is duplicated */
    let lastOrder = page?.options[page?.options.length - 1]?.order;
    let concatOptions = page?.options;
    let filteredConcatOptions = [];
    concatOptions.forEach((x) => {
      if (!filteredConcatOptions.some((y) => y?.optionText === x?.optionText)) {
        filteredConcatOptions.push(x);
      }
    });

    /* Replace the order number to something that doesn't exist yet */
    const newOptions = filteredConcatOptions.map((x) => {
      lastOrder++;
      return {
        ...x,
        order: lastOrder,
      };
    });

    const newPayload = [...page?.options, ...newOptions];
    dispatch({
      page: `page_${page.order}`,
      type: "CHANGE_MAIN_OPTIONS",
      payload: newPayload,
    });
  };

  /**
   * Handles the main answer
   * @param {event} eventAnswer - The event
   * @param {string} choiceType - The type of choice attached to the page
   * @param {object} corequisite - The corequisite object
   * @param {number} answerIndex - The order of the answer
   * @returns {void}
   * @example
   * handleMainAnswer(event, "single", undefined, 0)
   * handleMainAnswer(event, "multiple", undefined, 0)
   * handleMainAnswer(event, "multiple", corequisite, 0)
   * handleMainAnswer(event, "linear-scale", undefined, 0)
   */

  useEffect(() => {
    if (page === 5) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };

      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [page]);

  //mma-2702
  const handleMainAnswer = (
    eventAnswer,
    pageChoiceType,
    corequisite,
    answerIndex,
    choiceType,
    question
  ) => {
    let inputType = choiceType;
    if (eventAnswer.hasOwnProperty("target")) {
      eventAnswer.stopPropagation();
      inputType = eventAnswer?.target?.localName;
      // console.log(eventAnswer.target.localName);

      switch (eventAnswer.target.localName) {
        case "button":
          eventAnswer = eventAnswer.target.innerText;
          break;
        case "input":
          eventAnswer = eventAnswer.target.value;
          break;
        case "span":
          eventAnswer = eventAnswer.target.innerText;
          break;
        case "dropdown":
          eventAnswer = eventAnswer.target.value;
          break;
        default:
          eventAnswer =
            eventAnswer?.target?.innerText || eventAnswer?.target?.value;
      }
    }

    console.log("HANDLE ANSWER", inputType);

    if (pageChoiceType === "single") {
      /*************************************************************/
      /* If the single-choice question usertype set local and backend */
      /*************************************************************/

      // Update local user type based on the selected question
      const questionNum = location?.pathname?.split("/").pop();

      if (questionNum === "2") {
        // User Persona Question 2
        const updateLocalUserType = (question) => {
          const userTypeMap = {
            "I’m an Owner-Builder": "OWNERBUILDER",
            "I’m an Architect Superintendent": "HBA_ARCHITECT_SUPERINTENDENT",
            "I’m a Homeowner": "HBA_HOMEOWNER",
          };
          localStorage.setItem("userCategory", userTypeMap[question]);
        };

        updateLocalUserType(question);
      }

      // Retrieve user information
      const userInput = {
        userId: localStorage.getItem("userId"),
        companyId: localStorage.getItem("companyId"),
        userCategory: localStorage.getItem("userCategory"),
      };

      // Update user type in backend
      const updateUserType = async () => {
        console.log(userInput);
        try {
          const result = await API.graphql({
            query: mUpdateUserType,
            variables: userInput,
          });
          console.log("User type has been successfully updated:", result);
        } catch (error) {
          console.error("Error updating user type:", error);
        }
      };

      const singleAnswerPayload = {
        ...({} && { order: answerIndex, answer: eventAnswer }),
        ...(!(corequisite === undefined) && { corequisite: "" }),
      };

      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_MAIN_ANSWER",
        payload: singleAnswerPayload,
      });

      // Update user type
      updateUserType();
    } else if (pageChoiceType === "multiple") {
      /*************************************************************/
      /* If the multiple-choice question has a "Select All" option */
      /*************************************************************/
      if (
        typeof eventAnswer === "string" &&
        (eventAnswer === "Select All" || eventAnswer.includes("Select All"))
      ) {
        choices.forEach((choice) => {
          const selectAllPayload = {
            ...({} && { order: choice?.order, answer: choice?.optionText }),
            ...(!(corequisite === undefined) && { corequisite: "" }),
          };

          // Checks if the answer already exists in the answer array
          if (
            !answer.some(
              (x) =>
                x.answer === choice.optionText ||
                x.answer.includes(choice.optionText)
            ) &&
            choice?.optionText !== "Others"
          ) {
            dispatch({
              page: `page_${page.order}`,
              type: "CHANGE_MAIN_ANSWER_MULTIPLE",
              inputType: inputType,
              payload: selectAllPayload,
            });
          }
        });
        return;
      }

      // CLEARS ANSWER
      if (typeof eventAnswer === "string" && eventAnswer === "clear") {
        const multipleAnswerPayload = {
          ...({} && { order: answerIndex, answer: "" }),
          ...(!(corequisite === undefined) && { corequisite: "" }),
        };
        dispatch({
          page: `page_${page.order}`,
          type: "CHANGE_MAIN_ANSWER_MULTIPLE",
          inputType: inputType,
          payload: multipleAnswerPayload,
        });
        return;
      }

      /********************************************/
      /* If there is no "Select All" option given */
      /********************************************/
      const multipleAnswerPayload = {
        ...({} && { order: answerIndex, answer: eventAnswer }),
        ...(!(corequisite === undefined) && { corequisite: "" }),
      };

      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_MAIN_ANSWER_MULTIPLE",
        inputType: inputType,
        payload: multipleAnswerPayload,
      });
    } else if (pageChoiceType === "linear-scale") {
      const singleAnswerPayload = {
        ...({} && { order: answerIndex, answer: eventAnswer }),
        ...(!(corequisite === undefined) && { corequisite: "" }),
      };

      dispatch({
        page: `page_${page.order}`,
        type: "CHANGE_MAIN_ANSWER",
        payload: singleAnswerPayload,
      });
    }
  };

  /**
   * Handles the corequisite answer
   * @param {event} answer - The event
   * @param {number} answerIndex - The order of the answer
   * @param {object} selectedCorequisite - The selected corequisite
   * @returns {void}
   */
  // const handleCorequisiteAnswer = (
  //     answer,
  //     answerIndex,
  //     selectedCorequisite,
  //     corequisiteType
  // ) => {
  //     if (corequisiteType !== "datepicker") {
  //         dispatch({
  //             page: `page_${page.order}`,
  //             type: "CHANGE_COREQUISITE_ANSWER",
  //             answerIndex: answerIndex,
  //             corequisiteIndex: selectedCorequisite.order,
  //             payload: {
  //                 answer: answer.target.value,
  //                 order: selectedCorequisite.order,
  //             },
  //         });
  //     }

  //     if (corequisiteType === "datepicker") {
  //         dispatch({
  //             page: `page_${<page.order>}`,
  //             type: "CHANGE_COREQUISITE_ANSWER",
  //             answerIndex: answerIndex,
  //             corequisiteIndex: selectedCorequisite.order,
  //             payload: {
  //                 answer: answer,
  //                 order: selectedCorequisite.order,
  //             },
  //         });
  //     }
  // };

  /**
   * Checks if the button should be disabled or not
   * @param {boolean} className - If true, returns the class name. If false, returns the boolean value
   * @returns {boolean} - Returns the class name if className is true. Returns the boolean value if className is false
   */
  const checkIfButtonDisabled = (className) => {
    const corequisiteLength = page?.options?.find((item) =>
      item?.corequisite?.some((x) =>
        answer?.some(
          (ans) =>
            ans?.corequisite &&
            ans?.corequisite?.some((y) => x.order === y.order)
        )
      )
    )?.corequisite?.length;

    if (
      page?.properties?.isRequired &&
      (page?.type === "none" || page?.type === "linear-scale")
    ) {
      return className ? " hover:bg-hbDirtyWhite text-secondary" : false;
    }

    if (page?.properties?.isRequired && (!answer || answer.length === 0)) {
      return className
        ? " onboarding-disabled-btn bg-hbDirtyWhite text-secondary"
        : true;
    }

    //Checking for corequisite answer length
    if (page?.properties?.isRequired && "corequisite" in answer[0]) {
      if (
        answer[0]?.corequisite.length > 0 &&
        !answer[0]?.corequisite?.some(
          (item) =>
            item?.answer === "" ||
            item?.answer === undefined ||
            item?.answer === null
        ) &&
        answer[0]?.corequisite?.length === corequisiteLength
      ) {
        return className ? " hover:bg-hbDirtyWhite text-secondary" : false;
      }
      return className ? " bg-hbDirtyWhite text-secondary" : true;
    }

    if (
      page?.properties?.isRequired &&
      (!answer[0]?.answer || answer[0]?.answer.length === 0)
    ) {
      return className ? " bg-hbDirtyWhite text-secondary" : true;
    }

    return className ? " hover:bg-hbDirtyWhite text-secondary" : false;
  };

  const urgency = answer[0]?.answer || 1;

  // const params = useParams();
  // const { :step } = params;

  const location = useLocation();
  console.log("numbers", location?.pathname?.split("/").pop());

  function countProgress(obj) {
    const percentage = ((obj - 1) / 10) * 100;
    return percentage;
  }

  const [initialClassname, setInitialClassname] = useState(
    "flex flex-col gap-8 -mt-10"
  );
  const [initialButtonClass, setInitialButtonClass] = useState(
    "w-28 mx-auto py-1 -mt-5 hover:bg-gray-300"
  );

  useEffect(() => {
    setTimeout(() => {
      const mrClass = location?.pathname?.split("/").pop() === "2" ? "" : "";

      setInitialClassname(`flex flex-col gap-8 -mt-10 newClass ${mrClass}`);
      setInitialButtonClass(
        "w-28 mx-auto py-1 -mt-5 hover:bg-gray-300 newClassButton"
      );
      // setInitialClassname("flex flex-col gap-8 -mt-10 newClass")
    }, 100);
  });

  return (
    <>
      {isStillInitializing && <Spinner />}

      <div
        className={
          "mx-auto flex flex-col gap-12 items-center px-10 -mt-4 wrapper w-3/4"
        }
      >
        <div className="w-96 pt-2 pb-4 ">
          <ProgressBar
            completed={countProgress(location?.pathname?.split("/").pop())}
            bgColor="#1e3a8a"
            height="10px"
            //animateOnRender={true}
            labelClassName="hidden"
            width="100%"
          />
        </div>

        <div
          className={initialClassname}
          // className="flex flex-col gap-8 -mt-10 "
          id="slide"
        >
          {title ? (
            <span className="text-center text-3xl font-semibold max-w-xl mx-auto">
              {title}
            </span>
          ) : null}
          {subtitle ? (
            <span className="text-center pb-5 max-w-xl mx-auto font-normal">
              {subtitle}
            </span>
          ) : null}
          <QuestionChoice
            style={style}
            preset={preset}
            action={(e) => handleMainAnswer(e, page.type, undefined, 0)}
            choices={choices}
            className="w-10/11"
          >
            {/* KJMFADD IMAGE  */}
            {image ? (
              <div className="-mt-5 flex items-center justify-center w-full">
                <img src={image} className="text-center items-center w-4/5" />
              </div>
            ) : (
              <></>
            )}

            {choices?.map((choice, index) => (
              <React.Fragment key={String(choice?.question) + index + "main"}>
                <QuestionChoice.Item
                  className="-mt-1"
                  tempStore={tempStore}
                  toggleText={choice?.toggleText}
                  style={style}
                  type={choice?.type}
                  preset={preset}
                  // sub={choice?.sub || ""}
                  action={(e) =>
                    handleMainAnswer(
                      e,
                      page.type,
                      choice.corequisite,
                      choice.order,
                      choice.type,
                      choice.question
                    )
                  }
                  value={
                    answer[0] && answer[0]?.answer?.key
                      ? answer
                      : answer?.length > 0 &&
                        answer?.find((x) => x.order === choice.order)?.answer
                  }
                  active={
                    answer?.some((item) => item?.order === choice?.order)
                      ? true
                      : false
                  }
                  linearScale={{
                    urgency,
                    choice,
                  }}
                  placeHolder={choice?.placeHolder}
                  formData={state}
                  subText={choice?.subText}
                  icons={choice?.icons}
                >
                  <div className="flex flex-col">
                    <span className="text-lg font-normal">
                      {choice?.question}
                    </span>
                  </div>
                </QuestionChoice.Item>

                {choice?.subheader ? (
                  <p className="text-xs">{choice.subheader}</p>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </QuestionChoice>
        </div>

        <div className="flex gap-1 w-full items-center">
          {page?.properties?.isAddMore && (
            <>
              <hr className="w-full border" />
              <div className="inline-flex gap-3">
                <button
                  onClick={() => handleAddMore()}
                  className={
                    "w-36 mx-auto py-3 bg-white text-hbBlue text-base font-semibold hover:text-hbBlue"
                  }
                >
                  Add More +
                </button>
              </div>
              <hr className="w-full border" />
            </>
          )}
        </div>
        <div className="inline-flex gap-3 mb-5 -mt-10 " id="slide">
          {page.properties?.isSkippable && (
            <Button
              variant="hbBlue-l"
              size="xxxl"
              onClick={() => handleSkipPage()}
              className={"w-48 mx-auto py-1 -mt-5"}
            >
              <span className="font-medium text-base flex content-center">
                SKIP FOR NOW
              </span>
            </Button>
          )}

          <Button
            variant="secondary"
            size="xxxl"
            disabled={checkIfButtonDisabled(false)}
            onClick={() => {
              handlePageChange();
            }}
            className={
              initialButtonClass +
              checkIfButtonDisabled(true) +
              (page.properties?.isSkippable ? " w-48" : " w-28")
            }
            id="button1"
          >
            <span className="font-medium text-base flex content-center">
              NEXT <FiArrowRight className="my-auto ml-2" />
            </span>
          </Button>
        </div>
      </div>
    </>
  );
}

function SplashScreen({ tempStore, dataWrapper }) {
  // Testing to simulate loading, replace later
  const {
    isUserCompleteWithOnboarding,
    state,
    builderDetailsBrief,
    contractDetailsBrief,
    variationsBrief,
    invoicesBrief,
    progressOfWorkBrief,
    progressPhotoBrief,
    projectLocationBrief,
    contractsBrief,
    siteDiaryBrief,
    contractsBriefFiles,
    clientMatterId,
  } = dataWrapper;

  const [currentStep, setCurrentStep] = useState(0);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [isDoneQuerying, setIsDoneQuerying] = useState(false);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const response = await API.graphql({
      query: BACKGROUND_TABLE_QUERY,
      variables: {
        clientMatterId: clientMatterId,
      },
    });

    const {
      contractDateKey,
      contractPriceKey,
      contractValueKey,
      builderNameKey,
      builderContactNameKey,
      builderContactEmailKey,
      builderContactNumberKey,
    } = extractAccessorKeys(response?.data?.backgroundTable);

    console.log("contractDetailsBrief", contractDetailsBrief);
    console.log("progressOfWorkBrief", progressOfWorkBrief);
    console.log("builderDetailsBrief", builderDetailsBrief);
    console.log("contractsBrief", contractsBrief);
    console.log("contractsBriefFiles", contractsBriefFiles);
    console.log("projectLocationBrief", projectLocationBrief);

    const {
      projectLocationFormState,
      contractDetailsFormState,
      progressOfWorkFormState,
      builderDetailsFormState,
      contractsFormState,
    } = extractFormStates(state);

    if (projectLocationFormState?.answers?.length > 0) {
      const projectLocationAnswer = projectLocationFormState?.answers?.map(
        (x) => x.answer
      );

      const projectLocation =
        projectLocationAnswer[0] && projectLocationAnswer[0] !== ""
          ? projectLocationAnswer[0]
          : null;

      console.log("projectLocationAnswer", projectLocationAnswer);

      if (projectLocation) {
        let saveProjectLocation = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: [
              {
                id: projectLocationBrief.id,
                name: projectLocationBrief.name,
              },
            ],
            description: projectLocation,
            order: 1,
          },
        });
        console.log("Save to Project Location", saveProjectLocation);
      }
    }

    var contractDetails;

    let contractDetailsBriefUpdated = await API.graphql({
      query: qBriefBackgroundList,
      variables: { id: contractDetailsBrief?.id, sortOrder: "ORDER_ASC" },
    });

    let contractDetailsBackgrounds =
      contractDetailsBriefUpdated.data.brief.backgrounds.items;

    if (contractDetailsFormState?.answers?.length > 0) {
      let contractDetailsAnswer = []; //= contractDetailsFormState.answers.map((x) => x.answer);
      console.log("contractDetailsAnswer", contractDetailsAnswer);

      for (let i = 0; i < contractDetailsFormState.questions.length; i++) {
        let answer = contractDetailsFormState?.answers?.find(
          (ansObj) => ansObj.order === i + 1
        );
        contractDetailsAnswer[i] = answer?.answer ? answer?.answer : "";
      }

      const contractDateAnswer =
        contractDetailsAnswer[0] !== ""
          ? moment(contractDetailsAnswer[0], "YYYY-MM-DD").toISOString()
          : null;

      contractDetails = {
        ...(contractDateAnswer && { [contractDateKey]: contractDateAnswer }),
        [contractPriceKey]: contractDetailsAnswer[1]
          ? contractDetailsAnswer[1]
          : "", // contractPrice
        [contractValueKey]: "0", // TEMP DEFAULT VALUE
      };
    } else {
      contractDetails = {
        [contractPriceKey]: "",
        [contractValueKey]: "",
      };
    }
    const saveContractDetails = await API.graphql({
      query: mUpdateBackgroundDynamicData,
      variables: {
        id: contractDetailsBackgrounds[0].id,
        dynamicData: JSON.stringify(contractDetails), // Pass cloned dynamicData
      },
    });
    console.log("Save to saveContractDetails", saveContractDetails);

    if (progressOfWorkFormState?.answers?.length > 0) {
      const progressOfWorkAnswer = progressOfWorkFormState?.answers?.map(
        (x) => x.answer
      );
      console.log("progressOfWorkAnswer", progressOfWorkAnswer);

      const progressOfWork =
        progressOfWorkAnswer[0] && progressOfWorkAnswer[0] !== ""
          ? moment(progressOfWorkAnswer[0], "YYYY-MM-DD").toISOString()
          : null;

      if (progressOfWork) {
        const saveProgressOfWork = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: [
              {
                id: progressOfWorkBrief.id,
                name: progressOfWorkBrief.name,
              },
            ],
            date: progressOfWork,
            order: 1,
          },
        });
        console.log("Save to Progress of Work", saveProgressOfWork);
      }
    }

    // KJMF EDIT FIX
    var builderDetails;

    let builderDetailsBriefUpdated = await API.graphql({
      query: qBriefBackgroundList,
      variables: { id: builderDetailsBrief?.id, sortOrder: "ORDER_ASC" },
    });

    let builderDetailsBGs =
      builderDetailsBriefUpdated.data.brief.backgrounds.items;

    if (builderDetailsFormState?.answers?.length > 0) {
      let builderDetailsAnswer = []; // = builderDetailsFormState.answers.map((x) => x.answer);

      console.log("builderDetailsAnswer", builderDetailsAnswer);
      for (let i = 0; i < builderDetailsFormState.questions.length; i++) {
        let answer = builderDetailsFormState?.answers?.find(
          (ansObj) => ansObj.order === i + 1
        );
        builderDetailsAnswer[i] = answer?.answer ? answer?.answer : "";
      }

      builderDetails = {
        [builderNameKey]: builderDetailsAnswer[0]
          ? builderDetailsAnswer[0]
          : "", // builderName
        [builderContactNameKey]: builderDetailsAnswer[1]
          ? builderDetailsAnswer[1]
          : "", // builderContactPerson
        [builderContactEmailKey]: builderDetailsAnswer[2]
          ? builderDetailsAnswer[2]
          : "", // builderEmail
        [builderContactNumberKey]: builderDetailsAnswer[3]
          ? builderDetailsAnswer[3]
          : "", // builderPhone
      };
    } else {
      builderDetails = {
        [builderNameKey]: "", // builderName
        [builderContactNameKey]: "", // builderContactPerson
        [builderContactEmailKey]: "", // builderEmail
        [builderContactNumberKey]: "", // builderPhone
      };
    }
    const saveBuilderDetails = await API.graphql({
      query: mUpdateBackgroundDynamicData,
      variables: {
        id: builderDetailsBGs[0].id,
        dynamicData: JSON.stringify(builderDetails), // Pass cloned dynamicData
      },
    });
    console.log("Save to saveBuilderDetails", saveBuilderDetails);

    if (contractsFormState?.answers?.length > 0) {
      console.log("will upload", state?.page_5?.answers);

      processContractsAnswers(contractsFormState?.answers, clientMatterId)
        .then((results) => {
          console.log("All contracts uploaded:", results);
          const orderToFileMap = new Map(
            results.map((item) => [item.order, item.files])
          );

          // Map through results to match and merge information.
          const mergedArray = contractsBriefFiles.map((result) => {
            const files = orderToFileMap.get(result.order) || [];

            // const extractedFiles = files.map((file) => ({
            //   id: file.id,
            //   name: file.name,
            // }));

            return { ...result, files };
          });

          const filteredArray = mergedArray.filter(
            (item) => item.files && item.files.length > 0
          );

          filteredArray.map((item) => {
            // maps each item to a promise that results from an API call
            return API.graphql({
              query: mTagBackgroundFile,
              variables: {
                backgroundId: item.id,
                files: item.files,
              },
            }).catch((error) => {
              console.error(
                `An error occurred while tagging background file for backgroundId ${item.id}:`,
                error
              );
              return null; // Return a non-throwing value to keep other promises running.
            });
          });

          console.log("filteredArray", filteredArray);
        })
        .catch((error) => {
          console.error("An error occurred while uploading contracts", error);
        });
    } else {
      console.log("will not upload");
    }
    for (let i = 1; i <= 5; i++) {
      localStorage.removeItem(`answerpage_${i}`);
      console.log(localStorage.getItem(`answerpage_${i}`));
    }
    localStorage.removeItem("clientMatterId");
    localStorage.removeItem("main_onboarding_state");
  }, []);

  async function processContractsAnswers(page_5_answers, clientMatterId) {
    try {
      const uploadPromises = page_5_answers.map(async (x) => {
        try {
          const itemPromises = await Promise.all(
            Object.keys(x.answer).map((fileKey, index) =>
              handleUploadContracts(x.answer[fileKey], index)
                .then(async (uploadResult) => {
                  const createMatterFile = await API.graphql({
                    query: mCreateMatterFile,
                    variables: {
                      matterId: clientMatterId,
                      ...uploadResult,
                    },
                  });
                  // Consider logging only if this information is crucial.
                  console.log(
                    `Successful saving to database: `,
                    createMatterFile
                  );
                  return createMatterFile?.data?.matterFileCreate;
                })
                .catch((error) => {
                  console.error(`Error with file ${fileKey}:`, error);
                  // Decide whether to throw or handle the error based on your use case.
                  throw error;
                })
            )
          );

          // Return the structured data containing order and files.
          return { order: x.order, files: itemPromises };
        } catch (error) {
          // Handle or log individual upload errors without stopping other uploads.
          console.error(`Error processing answer order ${x.order}:`, error);
          // Return a structure indicating the failure for this particular batch.
          return { order: x.order, files: [], error };
        }
      });

      // Await all uploads and post-processing calls.
      return await Promise.all(uploadPromises);
    } catch (error) {
      throw new Error(
        "An overall error occurred during processing answers",
        error
      );
    }
  }

  function extractAccessorKeys(backgroundTable) {
    let accessorKeys = {};

    backgroundTable.columns.map((col) => {
      if (col.headerText === "Builder Name") {
        accessorKeys["builderNameKey"] = col.accessorKey;
        return col;
      }
      if (col.headerText === "Builder Contact Name") {
        accessorKeys["builderContactNameKey"] = col.accessorKey;
        return col;
      }
      if (col.headerText === "Builder Contact Email") {
        accessorKeys["builderContactEmailKey"] = col.accessorKey;
        return col;
      }
      if (col.headerText === "Builder Contact Number") {
        accessorKeys["builderContactNumberKey"] = col.accessorKey;
        return col;
      }
      if (col.headerText === "Start Date") {
        accessorKeys["contractDateKey"] = col.accessorKey;
        return col;
      }
      if (col.headerText === "Contract Price") {
        accessorKeys["contractPriceKey"] = col.accessorKey;
        return col;
      }
      if (col.headerText === "Contract Value") {
        accessorKeys["contractValueKey"] = col.accessorKey;
        return col;
      }
    });

    return accessorKeys;
  }

  function extractFormStates(state) {
    let states = {};
    Object.keys(state).forEach(function (key, index) {
      let obj = state[key];
      switch (obj?.category) {
        case "Property Address":
          states["projectLocationFormState"] = obj;
          break;
        case "Contract Details":
          states["contractDetailsFormState"] = obj;
          break;
        case "Project Completion Date":
          states["progressOfWorkFormState"] = obj;
          break;
        case "Builder Details":
          states["builderDetailsFormState"] = obj;
          break;
        case "Contracts":
          states["contractsFormState"] = obj;
          break;
        default:
          break;
      }
    });
    return states;
  }
  useEffect(() => {
    const loadingInterval = setInterval(moveToNextStep, 1000);

    return () => {
      clearInterval(loadingInterval);
      if (currentStep === 3 && isDoneQuerying) {
        setIsLoadingComplete(true);
      }
    };
  }, [currentStep]);

  const moveToNextStep = () => {
    if (currentStep <= 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(async () => {
    if (currentStep === 2) {
      // localStorage.setItem("authState", "signup");
      // await isUserCompleteWithOnboarding(tempStore.sessionId).then((res) => {
      setIsDoneQuerying(true);
      // });
    }

    /* In charge for redirecting the user to the Signup Component */
    if (isLoadingComplete) {
      localStorage.setItem("onboardingProgress", "true");
      localStorage.setItem("userProgress", "true");
      const userCategory = localStorage.getItem("userCategory");
      setTimeout(() => {
        if (userCategory === "OWNERBUILDER") {
          // history.push(`${AppRoutes.DASHBOARD}`);
          /** Update for updated sidebar component to refresh */
          window.location.href = AppRoutes.DASHBOARD;
        } else {
          // history.push(`${AppRoutes.HBADASHBOARD}`);
          /** Update for updated sidebar component to refresh */
          window.location.href = AppRoutes.HBADASHBOARD;
        }
      }, 2000);
    }
  }, [currentStep, isLoadingComplete]);
  const handleUploadContracts = async (file, index) => {
    console.log("handleUploadContracts()", file, index);

    try {
      const extension = file?.name?.split(".").pop();

      let type =
        extension === "docx"
          ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          : file.type;

      const sanitizedFilename = file?.name
        ?.replaceAll(/\s/g, "")
        ?.replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "");

      const key = `${clientMatterId}/${Number(new Date())}${sanitizedFilename}`;

      const fd = await Storage.put(key, file, {
        contentType: type,
        errorCallback: (err) => {
          console.error("204: Unexpected error while uploading", err);
        },
      });

      var fileData = {
        s3ObjectKey: fd.key,
        size: parseInt(file.size),
        type: type,
        name: file?.name?.split(".")?.slice(0, -1)?.join("."),
        order: index,
      };

      return fileData;
    } catch (e) {
      console.error("228: Unexpected error while uploading", e);
    }
  };

  function countProgress(obj) {
    const percentage = (obj / 4) * 100;
    return percentage;
  }

  return (
    <div className="flex flex-col px-5 gap-8 items-center">
      <div className="w-48">
        {/* {isLoadingComplete ? (
          <img src={loadingDone} alt="loading-done" />
        ) : (
          <img src={loading} alt="loading" className="w-16 h-16" />
        )} */}
        <ProgressBar
          completed={100}
          bgColor="#1e3a8a"
          height="10px"
          //animateOnRender={true}
          labelClassName="hidden"
          width="130%"
          className="-ml-20"
        />
        <br />

        {/* KJMFADD CIRCLE BAR */}
        <div className="w-3/4 ml-5">
          <CircularProgressbarWithChildren
            value={currentStep}
            maxValue={4}
            styles={buildStyles({
              textColor: "#184067",
              pathColor: "#228B22",
              textColor: "black",
            })}
            animateOnRender={true}
            className="mt-12"
          >
            <div className="font-semibold" style={{ fontSize: 16 }}>
              {countProgress(currentStep)} %
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>

      <div className="flex flex-col gap-8">
        {isLoadingComplete ? (
          <div className="text-black text-3xl font-semibold text-center">
            Welcome to your HBA Dashboard!
          </div>
        ) : (
          <div className="text-black text-3xl font-semibold text-center">
            One sec, we’re setting things up for you...
          </div>
        )}
        {currentStep === 0 ? (
          <div className="items-left lg:text-center gap-2 flex flex-col">
            <span className="text-black text-xl font-normal">
              Gathering Your Details...
            </span>
            <p className="italic">
              Your project is taking shape based on your inputs!
            </p>
          </div>
        ) : (
          <div className="items-left lg:items-center gap-2 flex flex-col">
            <div className="inline-flex">
              <img src={checkDone} alt="step-done" className="mr-2" />
              <span className="text-secondary text-xl font-normal">
                Gathering Your Details
              </span>
            </div>
          </div>
        )}
        {currentStep === 1 ? (
          <div className="items-left lg:text-center gap-2 flex flex-col">
            <span className="text-black text-xl font-normal">
              Creating Your Personal Dashboard...
            </span>
            <p className="italic text-xs">
              Watch as your dream home's journey comes to life.
            </p>
          </div>
        ) : currentStep >= 2 ? (
          <div className="items-left lg:items-center gap-2 flex flex-col">
            <div className="inline-flex">
              <img src={checkDone} alt="step-done" className="mr-2" />
              <span className="text-secondary text-xl font-normal">
                Creating Your Personal Dashboard
              </span>
            </div>
          </div>
        ) : null}
        {currentStep === 2 ? (
          <div className="items-left lg:text-center gap-2 flex flex-col">
            <span className="text-black text-xl font-normal">
              Organising Your Documents...
            </span>
            <p className="italic text-xs">
              Ensuring all your important files are in one accessible place.
            </p>
          </div>
        ) : currentStep >= 3 ? (
          <div className="items-left lg:items-center gap-2 flex flex-col">
            <div className="inline-flex">
              <img src={checkDone} alt="step-done" className="mr-2" />
              <span className="text-secondary text-xl font-normal">
                Organising Your Documents
              </span>
            </div>
          </div>
        ) : null}
        {currentStep === 3 ? (
          <div className="items-left lg:text-center gap-2 flex flex-col">
            <span className="text-black text-xl font-normal">
              Almost There!...
            </span>
            <p className="italic text-xs">
              Just a bit more magic to get everything perfect for you.
            </p>
          </div>
        ) : currentStep >= 4 ? (
          <div className="items-left lg:items-center gap-2 flex flex-col">
            <div className="inline-flex">
              <img src={checkDone} alt="step-done" className="mr-2" />
              <span className="text-secondary text-xl font-normal">
                Building your client matter file
              </span>
            </div>
          </div>
        ) : null}
        {isLoadingComplete ? (
          <div className="absolute top-0 left-0 flex items-center justify-center z-40">
            <img
              className="w-screen h-screen"
              src={completeForm}
              alt="Confetti"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MultiStepForm;
