import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import {
  BsArrowDownUp,
  BsInfoCircle,
  BsExclamationCircleFill,
  BsFillClockFill,
  BsCheck2Circle,
} from "react-icons/bs";
import { FaBookReader } from "react-icons/fa";
import Button from "../../shared/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import sitediaryIcon from "../../assets/images/sitediary-icon.png";
import { FiEdit } from "react-icons/fi";

export default function DynamicTiles(props) {
  const history = useHistory();

  function visitBrief() {
    history.push(redirectUrl);
  }

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
        query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
        brief(id: $id) {
        id
        backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
            items {
            id
            description
			      dynamicData
            comments
            date
            createdAt
            order
            files {
                items {
                id
                name
                }
            }
            }
            nextToken
        }
        }
    }
    `;

  const { briefId, redirectUrl, backgrounds, tileName } = props;

  return (
    <>
      <div className=" bg-white shadow rounded-lg border border-neutral-200 overflow-hidden">
        <div className="w-full pt-6 px-8">
          <div className="relative">
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img
                src={sitediaryIcon}
                alt="Site Diary Icon"
                className="pb-2 w-11 h-auto"
              />
            </div>
            <div
              className="p-2 rounded-lg border bg-white border-gray-300 justify-center items-center hover:bg-gray-100 cursor-pointer hover:text-gray-500"
              onClick={visitBrief}
            >
              <FiEdit className="w-4 h-4" />
            </div>
          </div>

          <div className="relative z-20">
            <div className="inline-block w-full items-center gap-2 pb-5">
              <div className="inline-flex">
                <div className="text-black text-lg font-open-sans font-semibold mr-1 pb-2">
                  {tileName}
                </div>
                <BsInfoCircle className="text-xs" />
              </div>
              {backgrounds?.length === 0 ? (
                <>
                  <div
                    className="w-full text-sm font-normal font-open-sans text-gray-600 pt-4 leading-tight pl-2 pr-2"
                    style={{ color: "#5A5A5A" }}
                  >
                    Plan and manage plan changes, track variations, costs, and
                    approvals efficiently in your construction project.
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="w-full inline-block text-sm font-normal font-open-sans text-gray-600 pt-6 leading-tight rounded-md"
                    style={{
                      color: "#5A5A5A",
                      backgroundColor: "#F3F4F7",
                      padding: "12px",
                    }}
                  >
                    {backgrounds?.slice(0, 2).map((item, index) => {
                      const originalDate = new Date(item?.date);
                      const formattedDate = `${originalDate.getDate()} ${originalDate.toLocaleString(
                        "default",
                        { month: "short" }
                      )} ${originalDate.getFullYear()}`;
                      const maxLength = 80;
                      const truncatedDescription =
                        item?.description?.length > maxLength
                          ? item?.description?.substring(0, maxLength) + "..."
                          : item?.description;

                      return (
                        <div key={index}>
                          <div className="w-full flex justify-between items-center">
                            <div className="text-xs text-gray-400 font-semibold">
                              {formattedDate}
                            </div>
                          </div>
                          <div className="w-full text-sm font-normal font-open-sans text-gray-600 leading-tight pb-2">
                            {truncatedDescription}
                          </div>
                          {index !== 1 ? <hr className="pb-2"></hr> : null}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
