import React, { useEffect, useRef, useState } from "react";
import { LiaHandshakeSolid } from "react-icons/lia";
import { GrCircleInformation } from "react-icons/gr";
import Button from "../../shared/Button";
import ReactTooltip from "react-tooltip";
import contractsIcon from "../../assets/images/contracts-icon.png";
import { FiEdit } from "react-icons/fi";

export default function Contracts({
  visitBrief,
  briefDetails,
  isComplete,
  uploadContractsRef,
}) {
  function handleUploadClick() {
    console.log("UPLOAD CLICK", briefDetails[0]);
    visitBrief(briefDetails[0].id);
  }

  return (
    <>
      <div
        className=" bg-white shadow rounded-lg border border-neutral-200 overflow-hidden"
      >
        <div className="w-full pt-6 pb-4 px-8">
          <div className="relative">
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img src={contractsIcon} alt="Contracts Icon" className="pb-2 w-11 h-auto"/>
            </div>
            <div 
              className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
              onClick={handleUploadClick}
            >
              <FiEdit className="w-4 h-4"/>
            </div>
          </div>

          <div className="gap-1 z-20 relative">
            <div className="justify-start items-center gap-2 pb-5">
              <div className="inline-flex">
                <div className="text-black text-lg font-open-sans font-semibold mr-1">
                  Contracts
                </div>
                {isComplete ? (
                  <>
                    <GrCircleInformation
                      data-tip
                      data-for="contracts-tile-tooltip"
                    ></GrCircleInformation>
                    <ReactTooltip
                      id={"contracts-tile-tooltip"}
                      place={"bottom"}
                      scrollHide
                      className="w-72"
                    >
                      Review your construction contract and related documents.
                      Access all the important details and terms of your
                      agreement at your fingertips...
                    </ReactTooltip>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-2">
            {isComplete ? (
              <div className="flex justify-between">
                <span>Contract Status</span>
                <span className="text-green-500 bg-green-100 rounded-full font-semibold px-2">
                  SIGNED
                </span>
              </div>
            ) : (
              <span className="font-open-sans text-sm">
                Review your construction contract and related documents. Access
                all the important details and terms of your agreement at your
                fingertips..
              </span>
            )}
          </div>
		  </div>

      </div>
    </>
  );
}
