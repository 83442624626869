import CreatableSelect from "react-select/creatable";
import anime from "animejs";
import React, { useEffect, useRef, useState } from "react";
import { FiFolderPlus } from "react-icons/fi";
import Button from "../../shared/Button";
import { API } from "aws-amplify";

const CreateCategoryModal = ({
	setShowCreateModal,
	setCategoryList,
	categoryList,
	companyId,
}) => {
	const modalContainer = useRef(null);
	const modalContent = useRef(null);
	const [newCategoryName, setNewCategoryName] = useState("");
	const [isDuplicate, setIsDuplicate] = useState(false);

	const mCreateCategory = `mutation createCategory($companyId: String, $name: String) {
		categoryCreate(companyId: $companyId, name: $name) {
		  id
		  name
		}  
	  }`;

	const qGetCategoryByID = `query getCategoryById($id: ID) {
		category(id: $id) {
		  id
		  name
		  createdAt
		  updatedAt
		}
	  }`;

	useEffect((e) => {
		anime({
			targets: modalContainer.current,
			opacity: [0, 1],
			duration: 100,
			easing: "easeInOutQuad",
			complete: () => {
				anime({
					targets: modalContent.current,
					scale: [0.9, 1],
					opacity: [0, 1],
					duration: 100,
					easing: "easeInOutQuad",
				});
			},
		});
	}, []);

	async function handleSubmit() {
		// For implementation with GraphQL
		let createCategoryResult = await API.graphql({
			query: mCreateCategory,
			variables: { companyId: companyId, name: newCategoryName },
		});
		console.log("CREATE CATEGORY RESULT", createCategoryResult);

		//to catch if creation of category is successful
		if (createCategoryResult.data.categoryCreate.id) {
			let newCategory = {
				id: createCategoryResult.data.categoryCreate.id,
				name: createCategoryResult.data.categoryCreate.name,
			};
			setShowCreateModal(false);
			setCategoryList((current) => [newCategory, ...current]);
		}
	}

	useEffect(() => {
		setIsDuplicate(checkDuplicate());
	}, [newCategoryName]);

	function checkDuplicate() {
		for (const category of categoryList) {
			if (category.name.toLowerCase() === newCategoryName.toLowerCase()) {
				return true;
			}
		}
		return false;
	}

	return (
		<div
			ref={modalContainer}
			onClick={() => setShowCreateModal(false)}
			className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none opacity-0 focus:outline-none 0 backdrop-filter backdrop-blur-md"
		>
			<div
				ref={modalContent}
				className="z-50 inline-flex flex-col items-center justify-start h-auto max-w-md gap-8 p-6 scale-90 bg-white shadow opacity-0 rounded-xl w-1/2"
				onClick={(e) => e.stopPropagation()}
			>
				{/* Modal Content */}
				<div className="inline-flex flex-col items-start justify-start w-full gap-5">
					<div className="inline-flex flex-col items-start justify-start w-full gap-2">
						<div className="text-lg font-semibold text-gray-900">
							Create New Category
						</div>
					</div>

					<div className="inline-flex flex-col items-start justify-start w-full gap-4">
						<div className="w-full flex-col justify-start items-start gap-1.5 inline-flex">
							<label
								className="text-sm font-normal text-gray-700"
								htmlFor="client"
							>
								Category Name*
							</label>
							<input
								name="categoryName"
								type="text"
								className="rounded-md p-2 border border-gray-300 outline-0 w-full"
								disabled={false}
								onChange={(e) => setNewCategoryName(e.target.value)}
							/>
							{isDuplicate ? (
								<span className="text-red-500 p-0 m-0 absolute top-36">
									Category Name Already Exists
								</span>
							) : (
								<></>
							)}
							{newCategoryName.length > 30 ? (
								<span className="text-red-500 p-0 m-0 absolute top-36">
									Category Name Cannot Be Longer Than 30 Characters
								</span>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>

				{/* Divider */}
				<div className="w-full h-px border border-neutral-200"></div>

				{/* Modal Action Buttons */}
				<div className="flex items-start justify-center w-full gap-3 bg-green-50">
					{/* <button
						className="flex items-center justify-center w-full gap-2 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow hover:bg-gray-100"
						onClick={() => setShowCreateModal(false)}
					>
						Cancel
					</button> */}
					<Button
						type="button"
						variant="gray-light"
						size="medium"
						block="full"
						onClick={() => setShowCreateModal(false)}
					>
						Cancel
					</Button>
					<Button
						type="button"
						variant="secondary"
						size="medium"
						block="full"
						onClick={() => handleSubmit()}
						disabled={
							newCategoryName.length === 0 ||
							newCategoryName.length > 30 ||
							checkDuplicate()
						}
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CreateCategoryModal;
