import React, { useEffect, useState } from "react";

// Components
import DatePicker from "react-datepicker";
import Select from "react-select";
import Button from "../../shared/Button";

// UI Exports
import { RiFilter2Fill } from "react-icons/ri";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TbFilterOff } from "react-icons/tb";
import { FaTimes } from "react-icons/fa";

// Function Exports
import {
  dateFormatter,
  datePickerDateFormatter,
  timezoneFormatter,
} from "../../shared/userPreferredDateTime";

const SortMatterModal = ({
  isDialogOpen,
  closeDialog,
  clientMatterFilters,
  handleExecuteFilter,
  handleClearMatterFilter,
}) => {
  const [startDate, setStartDate] = useState(
    clientMatterFilters.startDate || null
  );
  const [endDate, setEndDate] = useState(clientMatterFilters.endDate || null);
  const [invalidState, setInvalidState] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [isSortedByDateRange, setIsSortedByDateRange] = useState(false);
  const [isSortedByLastOpened, setIsSortedByLastOpened] = useState(false);

  // console.log("Sorted by last opened: ", isSortedByLastOpened);
  // console.log("Sorted by date range: ", isSortedByDateRange);

  //Date Range Filter
  const [oneMonth, setOneMonth] = useState();
  const [oneDay, setOneDay] = useState();
  const [threeDays, setThreeDays] = useState();
  const [sevenDays, setSevenDays] = useState();

  const dateOptions = [
    { value: oneDay, label: "Today" },
    { value: threeDays, label: "3 Days" },
    { value: sevenDays, label: "7 Days" },
    { value: oneMonth, label: "1 Month" },
  ];

  /* Styling for our Select component */
  const selectStyle = {
    option: (base, selectProps) => ({
      ...base,
      backgroundColor:
        selectProps.isFocused && !selectProps.isSelected
          ? "transparent"
          : base.backgroundColor,
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 40,
      margin: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(3600deg)",
      paddingRight: "0px",
      paddingLeft: "0px",
      color: "text-gray-600",
      display: "none",
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      padding: 0,
      margin: 0,
      height: 34,
      minHeight: 34,
      borderColor: "rgba(209, 213, 219, 1)",
    }),
    placeholder: (base, state) => ({
      ...base,
      fontSize: "0.76rem",
      paddingTop: "0.12rem",
      color: "#374151",
    }),
    menu: (base) => ({
      ...base,
      marginTop: 0,
      padding: 0,
      margin: 0,
      width: "max-content",
      minWidth: "100%",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "0.76rem",
    }),
  };

  useEffect(() => {
    /* Sets initial values of dateOptions */
    let dependentTime = new Date(startDate);
    let forMonth = new Date(startDate);
    setOneDay(new Date(dependentTime.setDate(dependentTime.getDate())));
    setThreeDays(new Date(dependentTime.setDate(dependentTime.getDate() + 3)));
    setSevenDays(new Date(dependentTime.setDate(dependentTime.getDate() + 4)));
    setOneMonth(new Date(forMonth.setMonth(forMonth.getMonth() + 1)));
  }, []);

  useEffect(() => {
    if (
      startDate !== null &&
      startDate !== "" &&
      endDate !== null &&
      endDate !== ""
    ) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        setInvalidDateRange(true);
        setInvalidState(true);
      } else {
        setInvalidDateRange(false);
        setInvalidState(false);
      }
    } else {
      setInvalidState(true);
    }
  }, [startDate, endDate]);

  const handleStartDateChange = (selected) => {
    if (endDate !== null) {
      /* Calculate the difference in days between the previous startDate and endDate */
      const oneDay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor((endDate - startDate) / oneDay);
      let newEndDate = new Date(selected.getTime());

      if (daysDifference > 7) newEndDate.setMonth(newEndDate.getMonth() + 1);
      else
        newEndDate = new Date(newEndDate.getTime() + daysDifference * oneDay);

      setEndDate(newEndDate);
    }

    /* Because .setMonth is needed and to avoid mutation 
        and miscalculation, we must declare two of the same instance */
    const dependentTime = new Date(selected);
    const forMonth = new Date(selected);

    setStartDate(selected);

    /* Updates the options */
    setOneDay(new Date(dependentTime.setDate(dependentTime.getDate())));
    setThreeDays(new Date(dependentTime.setDate(dependentTime.getDate() + 3)));
    setSevenDays(new Date(dependentTime.setDate(dependentTime.getDate() + 4)));
    setOneMonth(new Date(dependentTime.setDate(dependentTime.getDate() + 23)));
  };

  // console.log("Selected Start Date: ", startDate);
  // console.log("Selected End Date: ", endDate);

  const handleEndDateChange = (selected) => {
    setEndDate(selected);
  };

  /* Checks if endDate is previously modified in the current filter */
  const isEndDateOccupied = () => {
    if (endDate) {
      /* Calculate the difference in days between the previous startDate and endDate */
      const oneDay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor((endDate - startDate) / oneDay);

      /* Returns previously selected preset */
      if (daysDifference === 0) return "Today";

      if (daysDifference === 3) return "3 Days";

      if (daysDifference === 7) return "7 Days";

      if (daysDifference > 25) return "1 Month";
    }
  };

  const handleClearFilter = () => {
    // props.executeFilter(null);
  };

  const handleSave = () => {
    let filters = {
      startDate: startDate,
      endDate: endDate,
      isSortedByDateRange: isSortedByDateRange,
      isSortedByLastOpened: isSortedByLastOpened,
    };
    // props.setMobileLoading(true);
    handleExecuteFilter(filters);
  };

  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center ${
        isDialogOpen ? "block" : "hidden"
      }`}
    >
      <dialog
        id="ShowMore"
        open={isDialogOpen}
        onClose={closeDialog}
        className="w-[500px] h-[500px] overflow-auto rounded-lg p-6 fixed mx-auto"
      >
        <h1 className=" flex justify-between gap-3 font-bold text-xl">
          Manage Filters
          <span
            onClick={closeDialog}
            className="transform hover:scale-110 hover:text-red-500 transition duration-100"
          >
            <FaTimes size={22} className="bg-gray-300 rounded-full p-1" />
          </span>
        </h1>
        <div className="relative flex-auto pt-5 border-b-2 border-gray-200 pb-2">
          <label className="text-base font-bold flex gap-1">
            <input
              type="checkbox"
              name="sortByDateCreated"
              value={"sortByDateCreated"}
              className="text-sm w-4"
              onChange={() => setIsSortedByDateRange(!isSortedByDateRange)}
            />
            Date Range
          </label>
          <div className="grid grid-cols-2 gap-4 pt-2">
            <div className="relative w-full flex-auto">
              <div className="text-sm font-medium text-gray-400">{`From`}</div>
              <div className="relative my-1">
                <DatePicker
                  className="w-full rounded border border-gray-300 py-2 px-2.5 text-xs"
                  placeholderText={"Start Date"}
                  popperProps={{
                    positionFixed: true,
                  }}
                  dateFormat={datePickerDateFormatter(
                    localStorage.getItem("userpreferredDateFormatType")
                  )}
                  selected={
                    startDate === null
                      ? null
                      : timezoneFormatter(
                          startDate,
                          localStorage.getItem("preferredTimezone")
                        )
                  }
                  onChange={(selected) => {
                    handleStartDateChange(selected);
                    console.log("Start Date selected: ", startDate);
                  }}
                />
              </div>
            </div>
            <div className="relative w-full flex-auto">
              <div className="text-sm font-medium text-gray-400">{`To`}</div>
              <div className="relative my-1">
                <div className="pin-r pin-t text-purple-lighter absolute mt-4 mr-5 ml-2"></div>
                <Select
                  styles={selectStyle}
                  options={dateOptions}
                  placeholder={endDate ? isEndDateOccupied() : null}
                  isSearchable={false}
                  isValidNewOption={() => false}
                  onChange={(selected) => {
                    handleEndDateChange(selected.value);
                    console.log("End Date selected: ", endDate);
                  }}
                  components={{ IndicatorSeparator: () => null }}
                />
              </div>
            </div>
          </div>
          {invalidDateRange && (
            <div className="pt-1">
              <small className="text-red-400">
                End date should not be earlier than start date.
              </small>
            </div>
          )}
        </div>
        <div className="my-4">
          <label className="text-base font-bold flex gap-1">
            <input
              type="checkbox"
              name="sortByLastVisited"
              value={"sortByLastVisited"}
              className="text-sm w-4"
              onChange={() => setIsSortedByLastOpened(!isSortedByLastOpened)}
            />
            Last Opened
          </label>
        </div>
        <div className="flex gap-3 justify-center my-6">
          {clientMatterFilters.startDate && clientMatterFilters.endDate && (
            <Button
              variant="danger"
              className="p-2 rounded-lg border-2 border-red-500 bg-red-500 text-white text-base w-15 flex gap-1 items-center transform hover:bg-gray-100 hover:text-red-500 transition duration-100"
              type="button"
              onClick={() => {
                handleClearFilter();
                closeDialog();
                handleClearMatterFilter();
              }}
            >
              Clear Filter <TbFilterOff />
            </Button>
          )}

          <Button
            variant="secondary"
            className="p-2 rounded-lg border-2 border-green-400 bg-green-400 text-white text-base w-15 flex gap-1 items-center transform hover:bg-gray-100 hover:text-green-500 transition duration-100"
            type="submit"
            disabled={invalidState}
            onClick={() => {
              handleSave();
              closeDialog();
            }}
          >
            Apply Filter <RiFilter2Fill />
          </Button>
        </div>
      </dialog>
    </div>
  );
};

export default SortMatterModal;
