import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import {
  BsArrowDownUp,
  BsInfoCircle,
  BsExclamationCircleFill,
  BsFillClockFill,
  BsCheck2Circle,
} from "react-icons/bs";
import { FiCopy, FiDollarSign } from "react-icons/fi";
import Button from "../../shared/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import variationsIcon from "../../assets/images/variations-icon.png";

import { TbClockCheck } from "react-icons/tb";
import { PiSealCheck } from "react-icons/pi";
import shieldDollar from "../../assets/images/shield-dollar.png";
import { FiEdit } from "react-icons/fi";
import { parseInt } from "lodash";

export default function Variations(props) {
  const history = useHistory();

  const { briefId, redirectUrl, variationsRef } = props;

  function visitBrief() {
    history.push(redirectUrl);
  }

  const getValueDynamicData = (dynamicData, type) => {
    if (dynamicData !== null && type) {
      const item = JSON.parse(dynamicData);
      const keys = Object.keys(item);
      const values = [];
      keys.forEach((key) => {
        values.push(item[key]);
      });

      if (type === "Variation Status") {
        return values[0];
      }

      if (type === "Variation Cost" || type === "Variation Cost ($)") {
        return values[1];
      }
    }
  };

  const procVariations = (items) => {
    let approvedItems = 0;
    let forApprovedItems = 0;
    let approvedToDateTotal = 0;
    let needDecision = 0;
    items.map((item) => {
      if (item.variationStatus === null) {
        needDecision = needDecision + 1;
      }

      if (item.variationStatus === "Approved") {
        approvedItems = approvedItems + 1;
      }

      if (item.variationStatus === "For Approval") {
        forApprovedItems = forApprovedItems + 1;
      }

      if (item.variationsCost !== null && item.variationStatus === "Approved") {
        approvedToDateTotal = approvedToDateTotal + item.variationsCost;
      }
    });

    setApproved(approvedItems);
    setForApproval(forApprovedItems);
    setNeedDecisions(needDecision);

    const formattedAmount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(approvedToDateTotal);

    setApprovedToDate(formattedAmount);
  };

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
        query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
        brief(id: $id) {
        id
        backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
            items {
            id
            description
			      dynamicData
            comments
            date
            createdAt
            order
            files {
                items {
                id
                name
                }
            }
            }
            nextToken
        }
        }
    }
    `;

  const [needDecision, setNeedDecisions] = useState(0);
  const [variationRows, setVariationRows] = useState([]);
  const [totalReceived, setTotalReceived] = useState(null);
  const [forApproval, setForApproval] = useState(0);
  const [approved, setApproved] = useState(0);
  const [approvedToDate, setApprovedToDate] = useState(null);

  const getBackgrounds = async (id) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });

    if (backgroundOpt.data?.brief?.backgrounds?.items !== null) {
      var result = backgroundOpt?.data?.brief?.backgrounds?.items.map(
        ({ id, description, dynamicData, date, createdAt, order, files }) => {
          const variations = JSON.parse(dynamicData);
          // Populate custom column data inside of dynamic data
          console.log("Variation Dynamic Data:,", dynamicData);
          const variationStatus = getValueDynamicData(
            dynamicData,
            "Variation Status"
          );
          const variationCost = getValueDynamicData(
            dynamicData,
            "Variation Cost ($)"
          );

          return {
            createdAt: createdAt,
            id: id,
            description: description,
            dynamicData: variations,
            variationStatus:
              variations && variationStatus ? variationStatus : null,
            variationsCost:
              variations && variationCost ? parseInt(variationCost) : null,
            date: date,
            order: order,
            files: files,
          };
        }
      );

      setVariationRows(result);

      setTotalReceived(result.length);
      procVariations(result);

      // setTotalReceived(3)
      // setForApproval(2)
      // setApproved(2)

      // const formattedAmount = new Intl.NumberFormat('en-US', {
      // 	style: 'currency',
      // 	currency: 'USD',
      //   }).format(5100);

      // setApprovedToDate(formattedAmount)
    }
  };

  useEffect(() => {
    getBackgrounds(briefId);
  }, []);

  return (
    <>
      <div
        className=" bg-white shadow rounded-lg border border-neutral-200 overflow-hidden"
        ref={variationsRef}
        id="Variations"
      >
        <div className="w-full pt-6 px-8">
          <div className="relative">
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img
                src={variationsIcon}
                alt="Variations Icon"
                className="pb-2 w-11 h-auto"
              />
            </div>
            <div
              className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
              onClick={visitBrief}
            >
              <FiEdit className="w-4 h-4" />
            </div>
          </div>

          <div className="gap-1 z-20 relative">
            <div className="justify-start items-center gap-2 pb-5">
              <div className="inline-flex">
                <div className="text-black text-lg font-open-sans font-semibold mr-1">
                  Variations
                </div>
                {variationRows?.length !== 0 ? (
                  <div>
                    <BsInfoCircle className="text-xs" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/** End Header Variations */}

        {variationRows?.length !== 0 ? (
          <>
            <div className="pl-4 pr-4 font-sans">
              {/** Start Notification for unapproved variations */}
              {needDecision != 0 ? (
                <div className="mt-4 bg-red-50 rounded-2xl p-2 flex items-center gap-2">
                  <div className="w-14.67 h-14.67 p-1.22 justify-center items-center inline-flex">
                    <BsExclamationCircleFill className="text-xs text-red-700" />
                    &nbsp;
                  </div>
                  <div className="text-red-700 text-xs font-normal font-sans leading-18">
                    {`${needDecision} Variation needs your decision`}
                  </div>
                </div>
              ) : null}
              {/** End Notification for unapproved variations */}

              {/** Start Counter Tiles */}
              <div className="mt-4 flex justify-between">
                <div className="w-1/2 mb-4 pr-2">
                  <div className="p-2 bg-white rounded-lg border border-neutral-200 h-40 flex flex-col">
                    <div
                      className="w-10 h-10 p-1 rounded-lg text-white flex justify-center items-center"
                      style={{
                        backgroundImage: "linear-gradient(#C569CF, #EE609C)",
                      }}
                    >
                      {/** Icon */}
                      <FiCopy />
                    </div>
                    <div className="mt-2 text-zinc-600 text-xs font-normal font-sans leading-18">
                      Total Received
                    </div>
                    <div className="text-blue-950 text-lg font-bold font-sans leading-7 mt-10">
                      {totalReceived}
                    </div>
                  </div>
                </div>
                <div className="w-1/2 mb-4">
                  <div className="p-2 bg-white rounded-lg border border-neutral-200 h-40 flex flex-col">
                    <div
                      className="w-10 h-10 p-1 rounded-lg text-white flex justify-center items-center"
                      style={{
                        backgroundImage: "linear-gradient(#43CBFF, #9708CC)",
                      }}
                    >
                      {/** Icon */}
                      <TbClockCheck />
                    </div>
                    <div className="mt-2 text-zinc-600 text-xs font-normal font-sans leading-18">
                      For your approval
                    </div>
                    <div className="text-blue-950 text-lg font-bold font-sans leading-7 mt-10">
                      {forApproval}
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch mb-6">
                <div className="flex justify between">
                  <div className="w-1/2 pr-2">
                    <div className="p-2 bg-white rounded-lg border border-neutral-200 h-40 flex flex-col">
                      <div
                        className="w-10 h-10 p-1 rounded-lg text-white flex justify-center items-center"
                        style={{
                          backgroundImage: "linear-gradient(#FF7A00, #FFD439)",
                        }}
                      >
                        {/** Icon */}
                        <PiSealCheck />
                      </div>
                      <div className="mt-2 text-zinc-600 text-xs font-normal font-sans leading-18">
                        Approved
                      </div>
                      <div className="text-blue-950 text-lg font-bold font-sans leading-7 mt-10">
                        {approved}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="p-2 bg-white rounded-lg border border-neutral-200 h-40 flex flex-col">
                      <div
                        className="w-10 h-10 p-1 rounded-lg text-white flex justify-center items-center"
                        style={{
                          backgroundImage: "linear-gradient(#F97794, #623AA2)",
                        }}
                      >
                        {/** Icon */}
                        <img
                          src={shieldDollar}
                          alt="shield dollar"
                          className="w-5 h-auto"
                        />
                      </div>
                      <div className="mt-2 text-zinc-600 text-xs font-normal font-sans leading-18">
                        Approved Variation Cost to Date
                      </div>
                      <div className="text-blue-950 text-lg font-bold font-sans leading-7 mt-2">
                        {approvedToDate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/** End Counter Tiles */}
          </>
        ) : (
          <>
            <div className="pl-6 font-sans">
              <div className="w-[315px] h-24 mt-4 flex-col justify-start items-start gap-4 inline-flex">
                <div
                  className="w-full text-sm font-normal text-gray-600 leading-tight font-open-sans"
                  style={{ color: "#5A5A5A" }}
                >
                  Request changes or modifications to the original plan and keep
                  track of any variations and their corresponding costs and
                  approvals.
                </div>
              </div>
            </div>
            {/* <div class="w-full pb-6 px-4 flex-col justify-start items-start gap-3 inline-flex"></div> */}
          </>
        )}
      </div>
    </>
  );
}
