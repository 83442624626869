import { API } from "aws-amplify"

export const mutationActionHandler = async (params, dispatch) =>{

  console.log('Initiating ', params)

  try {
    const response = await API.graphql(params)
    console.log('Response from API ', response)
    if(response) {
      console.log('Success ', response)
      const newState = dispatch(response)
    } else{
      console.error('Mutation error ', response.errors)
    }

  } catch (err) {

    console.error('General Error ',err.errors)
  }

}

export const qGetFormById = `query getCustomFormById($id: ID!) {
  customForm(id: $id) {
    id
    companyId
    name
    description
    route
    isPublished
    isDeleted
    createdAt
    updatedAt
    pages(limit: 100) {
      items {
        id
        order
        name
        description
        fields(limit: 100) {
          items {
            id
            type
            headerText
            subText
            order
            options
            properties {
              isRequired
              isVisible
              rowHeight
              maxLength
              defaultValue
              isDatePickerMonthYear
              currencyType
              minRange
              maxRange
            }
          }
        }
      }
    }
  }
}`

//be322c80-809d-4e9e-ad23-488059b49a37 - First form submission id
export const mSubmitForm = `mutation createCustomFormSubmission($companyId: ID!, $formId: ID!, $data: AWSJSON) {
  customFormSubmissionCreate(companyId: $companyId, data: $data, formId: $formId) {
    id
  }
}`


export const mFetchSubmissions = `query getCustomFormSubmissions($companyId: ID!, $formId: ID!) {
  customFormSubmission(formId: $formId, companyId: $companyId) {
    id
    data
    createdAt
    completedAt
    updatedAt
    payload
  }
}`