import React, {
	useState,
	useEffect,
	useRef,
	useLayoutEffect,
	useCallback,
	useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes.jsx";
import ToastNotification from "../toast-notification/index.jsx";
import { AiOutlineDownload, AiFillCloseCircle } from "react-icons/ai";
import { BiCloudUpload } from "react-icons/bi";
import RemoveFileModal from "../file-bucket/remove-file-modal.jsx";
import { FaPaste, FaSync, FaSort } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import CreatableSelect from "react-select/creatable";
import { components, createFilter } from "react-select";
import ReactDOM from "react-dom";
import { HiPlus } from "react-icons/hi";
import { HiMinus } from "react-icons/hi";
import { updateBackgroundOrders } from "../../shared/graphql/Backgrounds/mutations.jsx";
import {
	timezoneFormatter,
	datePickerDateFormatter,
} from "../../shared/userPreferredDateTime.jsx";
import momentTZ from "moment-timezone";
import html2pdf from "html2pdf.js";
import {
	WindowScroller,
	AutoSizer,
	defaultTableHeaderRenderer,
	defaultTableRowRenderer,
	CellMeasurer,
	CellMeasurerCache,
	Column,
	List,
	Table,
} from "react-virtualized";
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from "react-sortable-hoc";
import classNames from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./labelCell.css";

import {
	BsFillTrashFill,
	BsFillBucketFill,
	BsSortUpAlt,
	BsSortDown,
} from "react-icons/bs";
import EmptyRow from "./empty-row.jsx";
import { ModalParagraph } from "./modal.jsx";
import { API, Storage } from "aws-amplify";
import config from "../../aws-exports.js";
//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import { MdDragIndicator, MdClose } from "react-icons/md";

import RemoveModal from "../delete-prompt-modal/index.jsx";
import { Prompt } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
// import imgLoading from "../../assets/images/loading-circle.gif";
import "../../assets/styles/background.css";
import ScrollToTop from "react-scroll-to-top";
import UploadLinkModal from "../file-bucket/file-upload-modal.jsx";
import NoResultState from "../no-result-state/index.jsx";
import ReactTooltip from "react-tooltip";
import RFIEditor from "./rfi-editor.jsx";
import { useWindowDimensions } from "../../shared/mobileViewFunctions.jsx";
// import { ThemeProvider } from "@remirror/react";
import isMSdoc from "../../shared/msFileTypeChecker.jsx";
import googleLogin from "../../assets/images/gmail-print.png";

//import Button from "../shared-library/Button";
// import EditableBackgroundField from "./editableBackgroundField";
import Button from "../../shared/Button.jsx";
import EmailParser from "../../shared/EmailParser.jsx";
import { updateBackgroundFilesOrder } from "../../shared/graphql/Backgrounds/mutations.jsx";
import CustomColumn from "./custom-column.jsx";
import cloneDeep from "lodash/cloneDeep";
import { getBgList } from "../../shared/graphql/Briefs/queries.jsx";

import {
	BsFillFileEarmarkFill,
	BsFillFileEarmarkFontFill,
	BsFileEarmarkPdfFill,
	BsFillFileEarmarkExcelFill,
	BsFillFileEarmarkWordFill,
	BsFillFileEarmarkPptFill,
	BsFillFileEarmarkImageFill,
	BsFillFileEarmarkPlayFill,
	BsFillFileEarmarkMusicFill,
	BsThreeDots,
} from "react-icons/bs";
import { CircularProgressbar } from "react-circular-progressbar";
import SlateMention from "./slate-description.jsx";

import { FaFileCsv } from "react-icons/fa";

//right panel
import RightPanel from "./right-panel.jsx";
import { drop } from "lodash";
import AddPaymentModal from "./add-payment-modal.jsx";
import { use } from "swiper/swiper-bundle.js";

export let selectedRowsBGPass = [],
	selectedRowsBGFilesPass = [];

const TableInfoBreakdown = ({
	backgroundDuplicate,
	background,
	files,
	wait,
	setIdList,
	setBackground,
	previousBackground,
	setPreviousBackground,
	checkAllState,
	setcheckAllState,
	checkedState,
	setCheckedState,
	settotalChecked,
	search,
	getId,
	setId,
	getBackground,
	matterId,
	selectedRowsBG,
	setSelectedRowsBG,
	ShowModalParagraph,
	setShowModalParagraph,
	paragraph,
	setParagraph,
	ascDesc,
	setShowDeleteButton,
	activateButton,
	setSelectedRowsBGFiles,
	selectedRowsBGFiles,
	setSelectedId,
	selectRow,
	setSelectRow,
	pasteButton,
	setSrcIndex,
	client_name,
	matter_name,
	setPasteButton,
	setLoading,
	sortByOrder,
	SortBydate,
	briefId,
	briefName,
	searchDescription,
	selectedItems,
	setSelectedItems,
	setHoldDelete,
	highlightRow,
	pastedRows,
	setPastedRows,
	checkedRows,
	setCheckedRows,
	setbindList,
	setCache,
	setMoveButton,
	moveButton,
	fontSize,
	bgLoading,
	setBgLoading,
	allowEditDate,
	allowEditComments,
	allowEditLabels,
	allowShowLabels,
	commentAccess,
	allowDeleteFiles,
	allowDragNDrop,
	uploadedDocumentList,
	qSearchValue,
	pressedQSearch,
	totalHits,
	setTotalHits,
	qSearchPtr,
	setQSearchPtr,
	inRow,
	reducedTabsArray,
	setReducedTabsArray,
	reduceTabFilesToArray,
	reducedUploadedFilesArray,
	reduceUploadedFilesToArray,
	currLabelCellSelected,
	currLabelCellSelected2,
	handleChangeLabel,
	justChangedLabel,
	allowScroll,
	showRightPanel,
	setShowRightPanel,
	fileView,
	setWait,
	isSiteDiary,

	// TANSTACK VIRTUALIZED
	virtualRows,
	rows,
}) => {
	const creatableSelectRef = useRef();
	const numOfRows = useRef(0);
	const prevRow = useRef(0);
	if (background.length > 0) {
		numOfRows.current = background.length;
	}

	console.log("BACKGROUND -::>", background);
	console.log("Backgourn Columns -::>", columns);
	const adjustments = useRef(1);
	const handleRef = useCallback((ref) => {
		if (ref !== null) {
			ref.scrollToPosition =
				(scrollTop, adjustmentsP) =>
				// removed simply-utils lib
				() => {
					const scrollEl = window;
					if (scrollEl) {
						scrollEl.scrollTo({
							top: scrollTop + adjustmentsP,
							behavior: "smooth",
						});
						const updatedScrollTop = function (scrollEl) {
							return (
								(scrollEl === null || scrollEl === void 0
									? void 0
									: scrollEl.scrollTop) ||
								scrollEl.scrollY ||
								0
							);
						};
						console.log(
							"scrollTop:",
							scrollTop,
							"\nupdatedScrollTop:",
							updatedScrollTop,
							"\nadjustment:",
							adjustmentsP
						);
						return updatedScrollTop === scrollTop;
					}
					// Try next
					return false;
				};
			ref.scrollToRow = (index) => {
				console.log(index, numOfRows.current);
				if (index / numOfRows.current < 0.2) {
					adjustments.current = Math.random() * 5;
				} else if (index / numOfRows.current < 0.5) {
					adjustments.current = Math.random() * 5 + 100;
				} else {
					adjustments.current = 150 + Math.random() * 5;
				}
				const scrollTop = ref.getOffsetForRow({ index, alignment: "center" });
				ref.scrollToPosition(scrollTop, adjustments.current);
			};
		}
	}, []);

	let temp = selectedRowsBG;
	let tempFiles = selectedRowsBGFiles;
	const { height, width } = useWindowDimensions();
	const [saving, setSaving] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [alertMessage, setalertMessage] = useState();
	const [selected, setSelected] = useState("");
	const [descId, setDescId] = useState("");
	const [textDesc, setTextDesc] = useState("");
	const [descAlert, setDescAlert] = useState("");
	const [showRemoveFileModal, setshowRemoveFileModal] = useState(false);
	const [selectedFileBG, setselectedFileBG] = useState([]);
	const [highlightRows, setHighlightRows] = useState("bg-green-200");
	//const [sortByDate, setSortByDate] = useState([]);
	const [isShiftDown, setIsShiftDown] = useState(false);
	const [lastSelectedItem, setLastSelectedItem] = useState(null);
	const [holdReducedTabsArrayCopy, setHoldReducedTabsArrayCopy] =
		useState(null);
	const [selectedRowId, setSelectedRowID] = useState(null);
	const [goToFileBucket, setGoToFileBucket] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [holdPaste, setHoldPaste] = useState(false);
	const [bgInput, setBgInput] = useState([]);
	const [holdDeleteFile, setHoldDeleteFile] = useState(false);
	const bool = useRef(true);
	const [selectedFileId, setSelectedFileId] = useState(null);
	const [SelectedIndex, setSelectedIndex] = useState("");
	const [snippetId, setSnippetId] = useState(null);
	const [show, setShow] = useState(false);
	const [downloadLink, setDownloadLink] = useState("");
	const [preview, setPreview] = useState([]);
	const [from, setFrom] = useState(null);
	const [dateEmail, setDateEmail] = useState(null);
	const [subject, setSubject] = useState(null);
	const [to, setTo] = useState(null);
	const [cc, setCC] = useState(null);
	const [iframeLoad, setIframeLoad] = useState(true);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [selectedCell, setSelectedCell] = useState(null);
	const [isActiveCell, setIsActiveCell] = useState(null);
	const [emailDateAsc, setEmailDateAsc] = useState(null);
	const [breakdownData, setBreakdownData] = useState([]);

	//labels
	const [labels, setLabels] = useState(null);
	const [briefList, setBriefList] = useState([]);
	const location = useLocation();
	const history = useHistory();
	const [taggedColumns, setTaggedColumns] = useState();
	const [inputDynamic, setInputDynamic] = useState();

	//right panel items
	const [rightPanelItems, setRightPanelItems] = useState([]);

	const [columns, setColumns] = useState([]);


	// const defaultColumnIds = columnIds;
	const defaultColumnIds = [0, 1, 2, 3, 4];

	const sortIconClass = "mx-auto inline-block cursor-pointer";
	const cache = useRef(
		new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 30,
			minHeight: 30,
		})
	);

	var moment = require("moment");

	const selectStyles = (base, state) => {
		console.log("select styles", base);
		return base;
	};

	const queryParams = new URLSearchParams(location.search);

	const hideToast = () => {
		setShowToast(false);
	};

	const handleCell = (column, id) => {
		setIsActiveCell(column);
		setSelectedCell(id);
	};

	// Please check with SFE first before uncommenting
	//   useEffect(() => {
	//     console.log("backgroundlist", background);
	//     if(ascDesc == null)
	// 	setBackground((backgroundDuplicate));
	//   }, [backgroundDuplicate, setBackground]);

	const showModal = (id, backgroundId, fileName) => {
		var tempIndex = [];
		tempIndex = [
			...tempIndex,
			{ id: id, backgroundId: backgroundId, fileName: fileName },
		];
		setselectedFileBG(tempIndex);
		setshowRemoveFileModal(true);
		setSelectedFileId(id);
	};

	const handleModalClose = () => {
		setshowRemoveFileModal(false);
		setShowUploadModal(false);
		setShowRemoveRowModal(false);
	};

	const handleCheckboxChange = (position, event, id, date, details) => {
		const checkedId = selectRow.some((x) => x.id === id);
		if (!checkedId && event.target.checked) {
			const x = selectRow;
			x.push({ id: id, date: date, details: details });
			setSelectRow(x);
			setSrcIndex(position);
			setShowDeleteButton(true);
		}
		if (checkedId) {
			var x = selectRow.filter(function (sel) {
				return sel.id !== id;
			});
			setSelectRow(x);
			setSrcIndex("");
		}

		const updatedCheckedState = checkedState.map((item, index) =>
			index === position ? !item : item
		);
		setCheckedState(updatedCheckedState);

		let tc = updatedCheckedState.filter((v) => v === true).length;
		settotalChecked(tc);

		if (tc !== background.length) {
			if (checkAllState) {
				setcheckAllState(false);
			}
		} else {
			if (!checkAllState) {
				setcheckAllState(true);
			}
		}
		if (event.target.checked) {
			if (!background.includes({ id: event.target.name })) {
				setId((item) => [...item, event.target.name]);
				if (temp.indexOf(temp.find((tempp) => tempp.id === id)) > -1) {
				} else {
					//edited part
					temp = [...temp, { id: id, fileName: position.toString() }];
					selectedRowsBGPass = temp;
					setSelectedRowsBG(temp);

					if (temp.length > 0) {
						setShowDeleteButton(true);
					} else {
						setShowDeleteButton(false);
					}
				}
			}
		} else {
			setId((item) => [...item.filter((x) => x !== event.target.name)]);
			if (temp.indexOf(temp.find((tempp) => tempp.id === id)) > -1) {
				temp.splice(temp.indexOf(temp.find((tempp) => tempp.id === id)), 1);
				setSelectedRowsBG(temp);
				selectedRowsBGPass = temp;
			}

			if (temp.length > 0) {
				setShowDeleteButton(true);
			} else {
				setShowDeleteButton(false);
			}
		}
	};

	useEffect(() => {
		getLabels();

		setTimeout(() => {
			setLoading(false);
		}, 1000);

		setIdList(getId);
	}, [getId, isSiteDiary]);

	useEffect(() => {
		fetchTable();
	}, []);

	const fetchTable = async () => {
		const data = await API.graphql({
		  query: BACKGROUND_TABLE_QUERY,
		  variables: {
			clientMatterId: matter_id,
		  },
		}).then((result) => {
		  let columnNames = result?.data?.backgroundTable?.columns?.map(
			(elem) => elem.headerText
		  );
		  let columnOrder = result?.data?.backgroundTable?.columns?.map((elem) =>
			parseInt(elem.order)
		  );
		  console.log("BACKGROUND_TABLE_QUERY RESULT", result);
		  if (result.data.backgroundTable) {
			// console.log(
			//   "is Label present?",
			//   columnNames.indexOf("Label") > -1 ? "YES" : "NO"
			// );

			// console.log(
			//   "is Email Date present?",
			//   columnNames.indexOf("Email Date") > -1 ? "YES" : "NO"
			// );

			const tableColumns = result.data.backgroundTable.columns;

			if (
			//   columnNames.indexOf("Label") > -1 &&
			  columnNames.indexOf("Email Date") > -1
			) {

			  console.log("BACKGROUND TABLE RESULT:", result.data.backgroundTable);
			  const taggedTableColumns = result.data.backgroundTable?.columns
				.map((c) => {
				  console.log("COLUMNS HERE:", c);
				  let columnObj;
				  if (c.type === "DROPDOWN") {
					columnObj = {
					  accessorKey: c.id,
					  header: c.headerText,
					  type: c.type,
					  enabled: c.enabled,
					  order: c.order,
					  presets: c.presets,
					  optionsText: c.optionsText ? c.optionsText : [],
					};
				  } else {
					columnObj = {
					  accessorKey: c.id,
					  header: c.headerText,
					  type: c.type,
					  enabled: c.enabled,
					  presets: c.presets,
					  order: c.order,
					};
				  }
				  console.log("BRIEF ID:", briefId);

				  // Commented out because of MMA-2126 Background Label: Auto-Tagging of columns to labels created in manage user
				  // Presets from background table does not update when created using manage user

				  if (
					columnObj.presets &&
					parseInt(columnObj.accessorKey) !== 3
				  ) {
					if (columnObj?.presets?.length === 0) {
					  return columnObj;
					}
					if (
					  columnObj?.presets?.some((preset) => preset?.id === briefId)
					) {
					  return columnObj;
					}
				  }
				})
				.filter((obj) => obj !== undefined);

			  const columnIds = taggedTableColumns.map((obj) =>
				parseInt(obj.accessorKey)
			  );

			  console.log("taggedTableColumns", taggedTableColumns);

			  const mergedWithDefaultCols = columnIds;

			  // Exclude disabled/hidden columns on the briefs settings
			  // Unticks Email Date column on first load:

			  const filteredTaggedCols = taggedTableColumns
				.filter(
				  (obj) =>
					mergedWithDefaultCols.includes(parseInt(obj.accessorKey)) &&
					obj.enabled
				)
				.map((column) => ({
				  ...column,
				  visible: column.header === "Email Date" ? false : true,
				}));

			  console.log("Tagged Columns", filteredTaggedCols);
			  // Headers to remove
			let headersToRemove = ["Gmail", "Label", "Document", "Email Date"];

			// Filter out items with headers to remove
			let filteredArray = filteredTaggedCols.filter(item => !headersToRemove.includes(item.header));

			  setColumns(filteredArray);
			  //  }
			} else {
			//   if (columnNames.indexOf("Label") === -1) {
			// 	// addLabelColumn(tableColumns, result.data.backgroundTable.id);
			//   }
			  if (columnNames.indexOf("Email Date") === -1) {
				// addEmailDateColumn(tableColumns, result.data.backgroundTable.id);
				// window.location.reload(true);
			  }
			}
		  } else {
			console.log("Initialize Background Table");
		  }
		});
	  };

	const bindList = useRef();

	useEffect(() => {
		console.log("CALLED AUTO ADJUST INITIAL");
		autoAdjustAllRow();
	}, []);

	useEffect(() => {
		if (bindList && cache) {
			setbindList(bindList);
			setCache(cache);
		}
	}, [bindList]);

	const autoAdjustRowHeight = (index) => {
		//bindList and cache must not be null
		console.log("INDEXS", index);

		if (bindList && cache) {
			//clear first
			cache?.current?.clear(index);
			bindList?.current?.recomputeRowHeights(index);
			//bindList?.current?.forceUpdateGrid(index); //remove becuase recomputeRowHeights already calls forceUpdate by default
		} else {
			console("List reference not found || cache not found!");
		}
	};
	useEffect(() => {
		autoAdjustAllRow();
	}, [fontSize]);

	const handleDescContent = (e, description, id, index) => {
		if (!descAlert) {
			const cleanDescription = handleDescription(description, index);
			setTextDesc(cleanDescription);
			setDescId(id);
			setDescAlert("");
		} else {
			setDescAlert("");
		}
		//autoAdjustRowHeight(index);
	};

	const handleChangeDesc = (event, index) => {
		setTextDesc(event.target.innerText, index);
		console.log(event.target.innerText, index);
	};

	const handleFontSize = (currentClass, fontSize) => {
		return classNames(currentClass, {
			"text-sm": fontSize === 0,
			"text-base": fontSize === 1,
			"text-lg": fontSize === 2,
		});
	};

	const handleDescription = (description, index) => {
		if (description) {
			var parsedDescription = description?.replace(
				/style="[^\"]*"/gi,
				`className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[div]+/g,
				`<div className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[span]+/g,
				`<span className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[p ]+/g,
				`<p className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[font ]+/g,
				`<p className="${handleFontSize("", fontSize)}"`
			);
			if (
				pressedQSearch &&
				totalHits > 0 &&
				typeof qSearchValue !== "undefined" &&
				qSearchValue.length < 100
			) {
				parsedDescription = parsedDescription?.replace(
					new RegExp(qSearchValue, "g"),
					`<span class="bg-yellow-500">${qSearchValue}</span>`
				);
				//console.log("=>",parsedDescription);
				// console.log(inRow);
				if (index === inRow.current[0]) {
					console.log("STR", inRow.current);
					let idxSubstring = [
						...parsedDescription.matchAll(new RegExp("bg-yellow-", "g")),
					].map((a) => a.index);
					let idxSingle = idxSubstring[inRow.current[1]];

					let str =
						parsedDescription.substring(0, idxSingle) +
						"bg-green-" +
						parsedDescription.substring(idxSingle + 10);

					parsedDescription = `${str}`;
				}
			}

			return parsedDescription;
		}
	};

	const handleComment = (description) => {
		if (description) {
			var parsedDescription = description?.replace(
				/style="[^\"]*"/gi,
				`className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[div]+/g,
				`<div className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[span]+/g,
				`<span className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[p ]+/g,
				`<p className="${handleFontSize("", fontSize)}"`
			);
			parsedDescription = parsedDescription?.replace(
				/<[font ]+/g,
				`<p className="${handleFontSize("", fontSize)}"`
			);
			return parsedDescription;
		}
	};

	async function saveDescription({ id, description, origDescription }) {
		const success = await updateBackgroundDesc(id, { description }).then(
			async () => {
				const params = {
					query: mCreateActivity,
					variables: {
						companyId: localStorage.getItem("companyId"),
						clientMatterId: matter_id,
						briefId: briefId,
						activity: "updated the background description",
						field: "Description",
						current: description,
						previous: origDescription,
						appModule: "BACKGROUND",
						rowId: id,
					},
				};
				const addActivity = await API.graphql(params).then((result) => {
					console.log("addActivity params", params);
					console.log("addActivity result", result);
				});
			}
		);
	}

	useEffect(() => {
		if (saving === true) {
			window.onbeforeunload = () => "";
		}
		if (saving === false) {
			window.onbeforeunload = null;
		}
	}, [saving]);

	function extractText(desc) {
		const regex = /(<([^>]+)>)/gi;
		const newString = desc?.replace(regex, "");

		return newString;
	}

	const handleSaveDesc = async (e, description, date, id, index) => {
		console.log("check desc", extractText(e.target.innerText));

		let mentionMenuOpen = document.querySelector(".mention-menu");

		if (mentionMenuOpen) {
			return null;
		}

		if (e.target !== document.activeElement) {
			const origDescription = handleDescription(description, index);
			// const newDescription = handleDescription(e.target.innerHTML, index);
			//added new newDescription to cleanup text and only pass on "@Tab" without any numbers following it. Also deletes white spaces after @Tab instances
			const receivedDescription = handleDescription(e.target.innerText, index);
			const modifiedDescription = receivedDescription?.replace(
				/@Tab\s*\([^)]*\)\s*/gi,
				"@Tab "
			);
			// cleanup double spacing when user used only one space
			const modifiedDescriptionWithoutDoubleNewlines = modifiedDescription
				.replace(/\n{2,}/g, "\n")
				.replace(/~start~[\s\S]*?~end~/g, ""); //added new condition to prevent the mention element menu from being included in the description
			const newDescription = extractText(
				modifiedDescriptionWithoutDoubleNewlines
			);

			console.log("New Description", newDescription);

			// let newDescription = extractText(e.target.innerText);
			// newDescription = newDescription?.replace(/\&nbsp;/g, '').replace(/\n/g, '');

			//added new newDescription to cleanup text and only pass on "@Tab" without any numbers following it. Also deletes white spaces after @Tab instances

			console.log("Saved the text " + newDescription);
			console.log("textDesc", textDesc);

			//Causes MMA-680 bug Background: Description cursor relocates to the first word when switching tabs
			//Investigated by Jed
			/*
      const updateArr = background.map((obj) => {
        if (obj.id === id) {
          return { ...obj, description: e.target.innerHTML };
        }
        return obj;
      });
      setBackground(updateArr);
      */

			//background.recomputeRowHeights(index);

			{
				/*Added fix for both MMA 680 and 680 fix remove if causes bug*/
			}
			// if (!isScrolledIntoView(e.target)) {
			//   let foundIndex = background.findIndex((x) => x.id == id);
			//   background[foundIndex].description = e.target.innerHTML;
			//   Nag ccause po ito ng bug, nilipat ko sa last part nung code. Edited by: KJMF
			// }
			{
				/*End*/
			}

			if (textDesc?.length <= 0) {
				console.log("1st condition");
				// setDescAlert("Description can't be empty.");
				setDescAlert("");
				setalertMessage("Saving in progress...");
				setShowToast(true);
				const data = {
					description: newDescription,
				};
				const success = await updateBackgroundDesc(id, data).then(async () => {
					const params = {
						query: mCreateActivity,
						variables: {
							companyId: localStorage.getItem("companyId"),
							clientMatterId: matter_id,
							briefId: briefId,
							activity: "updated the background description",
							field: "Description",
							current: newDescription,
							previous: origDescription,
							appModule: "BACKGROUND",
							rowId: id,
						},
					};

					await API.graphql(params).then((result) => {
						console.log("addActivity result", result);
						setalertMessage("Successfully updated.");
						setShowToast(true);
						setTimeout(() => {
							setShowToast(false);
						}, 1000);
					});
				});
				console.log("THIS IS ID", id);
			} else if (textDesc === description) {
				console.log("2nd condition");
				setDescAlert("");
				setalertMessage("No Changes");
				setShowToast(true);
				setTimeout(() => {
					setShowToast(false);
				}, 1000);
			} else {
				console.log("3rd Condition");
				setDescAlert("");
				setalertMessage("Saving in progress...");

				console.log(textDesc);
				// Start the background task
				const backgroundTask = async () => {
					try {
						const data = {
							description: newDescription,
						};

						const res = await updateBackgroundDesc(id, data);
						console.log(res);

						const params = {
							query: mCreateActivity,
							variables: {
								companyId: localStorage.getItem("companyId"),
								clientMatterId: matter_id,
								briefId: briefId,
								activity: "updated the background description",
								field: "Description",
								current: newDescription,
								previous: origDescription,
								appModule: "BACKGROUND",
								rowId: id,
							},
						};

						const result = await API.graphql(params);
						console.log("addActivity result", result);

						setalertMessage("Successfully updated.");
						autoAdjustRowHeight(index);
					} catch (err) {
						console.log("ERR", err);
					}
				};

				// Start the background task without blocking the UI
				backgroundTask();

				// Show the toast immediately
				setShowToast(true);

				// Hide the toast after a delay
				setTimeout(() => {
					setShowToast(false);
				}, 1000);
			}

			let foundIndex = background.findIndex((x) => x.id == id);
			background[foundIndex].description = e.target.innerText;
		}

		//** ADDED WINDOW RELOAD TO SIMULATE REFETCHING OF BACKGROUND AFTER SAVING DESC */
	};
	function handleEmailDate(item) {
		const file = item?.files?.items;
		const fileIndex = file.findIndex((file) => file.isGmailAttachment);
		if (fileIndex > -1) {
			var dateSent = file[fileIndex].createdAt;
			var convertedDate = moment(dateSent).format("MM/DD/YYYY");
			var convertedTime = moment(dateSent).format("hh:mm A");
			return (
				<div className="w-full">
					<p className="w-full">{convertedDate}</p>
					<p className="w-full">{convertedTime}</p>
				</div>
			);
		}
		return (
			<div>
				<p>N/A </p>
			</div>
		);
	}

	const handleSaveTabElement = async (e, description, date, id, index) => {
		const handleMentions = (text) => {
			return text.replace(/@Ta?b?/gi, "@Tab");
		};
		if (e.target === document.activeElement) {
			const origDescription = handleDescription(description, index);
			const receivedDescription = handleDescription(e.target.innerText, index);

			const modifiedDescription = handleMentions(
				receivedDescription?.replace(/@Tab\s*\([^)]*\)\s*/gi, "@Tab ")
			);

			// cleanup double spacing when user used only one space
			const modifiedDescriptionWithoutDoubleNewlines =
				modifiedDescription.replace(/\n{2,}/g, "\n");
			const newDescription = extractText(
				modifiedDescriptionWithoutDoubleNewlines
			);

			// console.log("New Description", newDescription);

			const backgroundTask = async () => {
				try {
					const data = {
						description: newDescription,
					};

					const res = await updateBackgroundDesc(id, data);
					console.log(res);

					const params = {
						query: mCreateActivity,
						variables: {
							companyId: localStorage.getItem("companyId"),
							clientMatterId: matter_id,
							briefId: briefId,
							activity: "updated the background description",
							field: "Description",
							current: newDescription,
							previous: origDescription,
							appModule: "BACKGROUND",
							rowId: id,
						},
					};

					const result = await API.graphql(params);
					console.log("addActivity result", result);

					setalertMessage("Successfully updated.");
				} catch (err) {
					console.log("ERR", err);
				}
			};

			backgroundTask();

			let foundIndex = background.findIndex((x) => x.id == id);
			// background[foundIndex].description = e.target.innerText;
			background[foundIndex].description = newDescription;
		}
	};

	const handleSaveComment = async (e, comments, id, index) => {
		const origComments = handleComment(comments);
		setalertMessage("Saving in progress...");
		setShowToast(true);
		const data = {
			comments: e.target.innerHTML,
		};
		const success = await updateBackgroundComment(id, data).then(async () => {
			const params = {
				query: mCreateActivity,
				variables: {
					companyId: localStorage.getItem("companyId"),
					clientMatterId: matter_id,
					briefId: briefId,
					activity: "updated the background comments",
					field: "Comment",
					current: data.comments,
					previous: origComments,
					appModule: "BACKGROUND",
					rowId: id,
				},
			};
			const addActivity = await API.graphql(params).then((result) => {
				console.log("addActivity result", result);
			});
		});

		if (success) {
			setalertMessage("Successfully updated.");
			setShowToast(true);
		}
		setTimeout(() => {
			setShowToast(false);
		}, 1000);
		//autoAdjustRowHeight(index);

		let foundIndex = background.findIndex((x) => x.id === id);
		background[foundIndex].comments = e.target.innerText;
	};

	const handleChangeDate = async (selected, id, origDate, index) => {
		console.log("selected", selected);
		const data = {
			date:
				selected !== null
					? timezoneFormatter(selected, localStorage.getItem("preferredTimezone") == "null" ? momentTZ.tz.guess() : localStorage.getItem("preferredTimezone"))
					: null,
		};
		console.log("DATE", data);

		const origDateString =
			moment.utc(origDate).toISOString() !== null
				? String(moment.utc(origDate).toISOString())
				: null;

		await updateBackgroundDate(id, data).then(async () => {
			const params = {
				query: mCreateActivity,
				variables: {
					companyId: localStorage.getItem("companyId"),
					clientMatterId: matter_id,
					briefId: briefId,
					activity: "changed the background date",
					field: "Date",
					current:
						data.date !== null ? moment.utc(data.date).toISOString() : null,
					previous: origDateString,
					appModule: "BACKGROUND",
					rowId: id,
				},
			};
			const addActivity = await API.graphql(params).then((result) => {
				console.log("addActivity result", result);
			});
		});

		const updatedOSArray = background.map((p) =>
			p.id === id ? { ...p, date: data.date } : p
		);

		setBackground(updatedOSArray);

		//Added for previouseBackground (Search Functionality)
		const updatedOSArrayPrev = previousBackground.map((p) =>
			p.id === id ? { ...p, date: data.date } : p
		);

		setPreviousBackground(updatedOSArrayPrev);
	};

	const mUpdateBackgroundDate = `
    mutation updateBackground($id: ID, $date: AWSDateTime) {
      backgroundUpdate(id: $id, date: $date) {
        id
        date
      }
    }
  `;

	const mUpdateBackgroundDynamicData = `
  mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
    backgroundUpdate(id: $id, dynamicData: $dynamicData) {
      id
      dynamicData
    }
  }
  `;

	const mUpdateBackgroundDesc = `
  mutation updateBackground($id: ID, $description: String) {
    backgroundUpdate(id: $id, description: $description) {
      id
      description
    }
  }
`;

	const mUpdateBackgroundComment = `
  mutation updateBackground($id: ID, $comments: String) {
    backgroundUpdate(id: $id, comments: $comments) {
      id
      comments
    }
  }
`;

	const EMAIL_PREVIEW = `
  query getEmailDetails($id: ID) {
    gmailMessage(id: $id) {
      id
      from
      date
      to
      cc
      bcc
      subject
      recipient
      receivedAt
      payload {
        id
        content
      }
    }
  }`;

	const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
    gmailMessage(id: $id) {
      payload {
        content
      }
    }
  }
  `;

	const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

	async function updateBackgroundDate(id, data) {
		console.log("updateBackgroundDate:", data);

		return new Promise((resolve, reject) => {
			try {
				const request = API.graphql({
					query: mUpdateBackgroundDate,
					variables: {
						id: id,
						date:
							data.date !== null ? moment.utc(data.date).toISOString() : null,
					},
				});
				resolve(request);
			} catch (e) {
				reject(e.errors[0].message);
			}
		});
	}
	async function updateBackgroundDesc(id, data) {
		return new Promise((resolve, reject) => {
			try {
				const request = API.graphql({
					query: mUpdateBackgroundDesc,
					variables: {
						id: id,
						description: data.description,
					},
				});
				resolve(request);
			} catch (e) {
				reject(e.errors[0].message);
			}
		});
	}

	async function updateBackgroundComment(id, data) {
		return new Promise((resolve, reject) => {
			try {
				const request = API.graphql({
					query: mUpdateBackgroundComment,
					variables: {
						id: id,
						comments: data.comments,
					},
				});
				resolve(request);
			} catch (e) {
				reject(e.errors[0].message);
			}
		});
	}

	const autoAdjustAllRow = async () => {
		try {
			if (bindList && cache) {
				cache?.current.clearAll();
				//while loop is recommended fastest loop

				var i = 0,
					len = background.length;
				while (i < len) {
					setTimeout(() => {
						bindList?.current?.recomputeRowHeights(len);
					}, 100);

					i++;
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	function stripedTags(str) {
		const stripedStr = str?.replace(/<[^>]+>/g, "");
		return stripedStr;
	}

	// Function for updating the Row Order in the Database
	const handleRowOrderChangeDB = async (newRowArrangement) => {
		//POKS
		const mBulkUpdateBackgroundOrder = `
      mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
        backgroundBulkUpdateOrders(arrangement: $arrangement) {
          id
          order
        }
      }`;

		if (newRowArrangement && newRowArrangement.length > 0 && ascDesc === null) {
			const response = await API.graphql({
				query: mBulkUpdateBackgroundOrder,
				variables: {
					arrangement: newRowArrangement,
				},
			});
		}

		autoAdjustAllRow();
	};

	//Handling of changing row order using the Draggable Feature (React DnD / Hello Pangea)
	const handleDragEnd = async (e, index) => {
		console.log("DRAG END", e);

		let tempBackground = [...background];

		let [selectedRow] = tempBackground.splice(e.source.index, 1);

		tempBackground.splice(e.destination.index, 0, selectedRow);
		setBackground(tempBackground);

		//new function for multiple tabs reference
		const outputArray = reduceTabFilesToArray(tempBackground);
		setReducedTabsArray(outputArray);

		//new function for multiple tabs reference
		const res = tempBackground.map(({ id }, index) => ({
			id: id,
			order: index + 1,
		}));

		handleRowOrderChangeDB(res);
	};

	//Handling of changing the selected row/s order using the 'Move Row' button from the ActionButtons
	const handleMoveRow = (rowIndex) => {
		console.log("ROW INDEX ", rowIndex);

		//Copy of the Background array and local storage Stored Rows
		let tempBackground = [...background];

		const storedItemRows = JSON.parse(localStorage.getItem("selectedRows"));
		let tempStoredItemRows = [...storedItemRows];

		//Placeholder for the filtered and removed items
		let filteredBackgroundItems = [];
		let removedBackgroundItems = [];

		//Filter the current background to remove all the selected background by Id
		//Then put the removed items in the removedBackgroundItems array
		tempBackground.forEach((backgroundItem) => {
			let ifFound = tempStoredItemRows.findIndex(
				(storedItem) => storedItem?.id == backgroundItem?.id
			);
			//console.log("Found Index: ",ifFound)
			if (ifFound > -1) {
				removedBackgroundItems.push(backgroundItem);
				tempStoredItemRows.splice(ifFound, 1);
			} else {
				filteredBackgroundItems.push(backgroundItem);
			}
		});
		//console.log("FILTERED BACKGROUND ", filteredBackgroundItems);
		//console.log("REMOVED BACKGROUND ", removedBackgroundItems);

		//Move the selected rows to the specified index
		filteredBackgroundItems.splice(rowIndex, 0, ...removedBackgroundItems);

		//Update the order values to reflect the new arrangement
		const restore = filteredBackgroundItems.map((item, index) => ({
			...item,
			order: index + 1,
		}));
		setBackground(restore);
		setPreviousBackground(restore);
		console.log("New Background Arrangement: ", restore);

		//new function for multiple tabs reference
		const outputArray = reduceTabFilesToArray(restore);
		setReducedTabsArray(outputArray);

		//new function for multiple tabs reference
		const res = restore.map(({ id, order }) => ({
			id: id,
			order: order,
		}));

		//Save to DB the new order values
		handleRowOrderChangeDB(res);

		//Reset the states
		setShowDeleteButton(false);
		setPasteButton(false);
		setMoveButton(false);
		setcheckAllState(false);
		setSelectRow([]);
		setSelectedItems([]);
		setSelectedRowsBG([]);
		localStorage.removeItem("selectedRows");

		setalertMessage("Successfully moved rows");
		setShowToast(true);

		setTimeout(() => {
			setShowToast(false);
		}, 1500);
	};

	const handleDragEndPopup = async (e, index) => {
		allowScroll();
		console.log(e);
		try {
			let labelsCopy = cloneDeep(labels);

			let [selectedOption] = labelsCopy.splice(e.source.index, 1);

			labelsCopy.splice(e.destination.index, 0, selectedOption);
			setLabels(labelsCopy);
		} catch (error) {
			console.error(error);
		}
	};

	const handleChangeBackground = (id) => {
		setSelected(id);
	};

	const previewAndDownloadFile = async (id) => {
		const params = {
			query: qGetFileDownloadLink,
			variables: {
				id: id,
			},
		};

		await API.graphql(params).then(async (result) => {
			const { type, downloadURL, s3ObjectKey } = result.data.file;

			if (
				(type &&
					(type.includes("vnd.openxmlformats-officedocument") ||
						type.includes("application/msword"))) ||
				isMSdoc(s3ObjectKey)
			) {
				var encodedUrl = encodeURIComponent(downloadURL);
				var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
				window.open(documentViewerUrl);
			} else {
				window.open(downloadURL);
			}
		});
	};

	const getEmailPayload = async (messageId) => {
		const params = {
			query: qGetMessagePayload,
			variables: {
				id: messageId,
			},
		};

		const result = await API.graphql(params);
		console.log(result);
		if (result) {
			let newPayload = { payload: [{ content: "" }] };
			console.log("getEmailPayload()", result);

			result?.data?.gmailMessage.payload?.forEach((data, index) => {
				newPayload = {
					payload: [
						{
							content: newPayload.payload[0].content + data?.content,
						},
					],
				};
			});
			return newPayload;
			// return result?.data?.gmailMessage;
		} else {
			return "<b>PDF file has not been created properly.</b>";
		}
	};

	function dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data
		var byteString;
		if (dataURI.split(",")[0].indexOf("base64") >= 0)
			byteString = atob(dataURI.split(",")[1]);
		else byteString = unescape(dataURI.split(",")[1]);

		// separate out the mime component
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ia], { type: mimeString });
	}

	const previewAndDownloadPDF = async (id, gmailMessageId, subjectTitle) => {
		Storage.configure({
			region: config.aws_user_files_s3_bucket_region,
			bucket: config.aws_user_gmail_attachments_s3_bucket,
			identityPoolId: config.aws_user_pools_id,
		});

		var opt = {
			margin: [30, 50, 30, 50],
			filename: subjectTitle,
			image: { type: "png", quality: 0.95 },
			html2canvas: {
				dpi: 96,
				scale: 1,
				scrollX: 0,
				scrollY: 0,
				backgroundColor: "#FFF",
			},
			addImage: { contentDataURL: "PNG" },
			jsPDF: { unit: "pt", format: "a4", orientation: "p" },
			pagebreak: {
				before: ".page-break",
				mode: ["avoid-all", "css", "legacy"],
			},
		};

		var content = `<span>`;
		content += `<img src=${googleLogin} alt="" /><hr></hr>`;
		content += `<h2><b>${subjectTitle}</b></h2><hr></hr><br />`;
		content += `<p>From: ${from}</p>`;
		content += `<p>Date: ${moment(dateEmail).format(
			"DD MMM YYYY, hh:mm A"
		)}</p>`;
		content += `<p>To: ${to}</p>`;
		content += cc ? `<p>CC: ${cc}</p>` : "";
		content += `</span>`;

		const emailPayload = await getEmailPayload(gmailMessageId);

		if (emailPayload) {
			const parsedEmail = EmailParser(
				JSON.parse(emailPayload.payload[0].content)
			);
			const htmlContent = parsedEmail.html;
			console.log("Original Content - ", emailPayload);
			// const htmlContent = emailPayload.payload
			//   .map((em) => em.content)
			//   .join("")
			//   .split('data":"')
			//   .pop()
			//   .split('"}')[0];

			content += htmlContent;
			// content += Base64.decode(htmlContent).replace("body{color:", "");

			console.log("Edited Content - ", content);

			await html2pdf()
				.from(content)
				.set(opt)
				.toPdf()
				.output("datauristring")
				.then(function (pdfAsString) {
					const preBlob = dataURItoBlob(pdfAsString);
					const file = new File([preBlob], subjectTitle, {
						type: "application/pdf",
					});
					console.log(file);

					var key = `${gmailMessageId}/${Number(new Date())}${file.name
							?.replaceAll(/\s/g, "")
							.replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
						type = "application/pdf",
						size = file.size;

					// put objects to s3
					try {
						Storage.put(key, file, {
							contentType: type,
							progressCallback(progress) {
								console.log(progress);
							},
							errorCallback: (err) => {
								console.error("204: Unexpected error while uploading", err);
							},
						})
							.then((fd) => {
								const mUpdateMatterFileObjectKey = `
                    mutation updateMatterFile ($id: ID, $s3ObjectKey: String, $size: Int, $type: String) {
                      matterFileUpdate(id: $id, s3ObjectKey: $s3ObjectKey, size: $size, type: $type) {
                        id
                      }
                    }
                `;

								const params = {
									query: mUpdateMatterFileObjectKey,
									variables: {
										id: id,
										s3ObjectKey: fd.key,
										size: parseInt(size),
										type: type,
									},
								};

								API.graphql(params).then(async (res) => {
									console.log("Get Details", res);
									const params = {
										query: qGetFileDownloadLink,
										variables: {
											id: id,
										},
									};
									await API.graphql(params).then(async (result) => {
										const { type, downloadURL, s3ObjectKey } = result.data.file;
										window.open(downloadURL);
									});
								});
							})
							.catch((err) => {
								console.error("Unexpected error while uploading", err);
							});
					} catch (e) {
						const response = {
							error: e.message,
							errorStack: e.stack,
							statusCode: 500,
						};
						console.error("Unexpected error while uploading", response);
					}
				});
		}
	};

	const BACKGROUNDFILE_TAG_MUTATION = `
    mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
      backgroundFileTag(backgroundId: $backgroundId, files: $files) {
        id
      }
    }
  `;

	const [deleteRow, setDeleteRow] = useState([]);
	const [showRemoveRowModal, setShowRemoveRowModal] = useState(false);

	const showDeleteRowModal = (item) => {
		const ids = [
			{
				id: item.id,
				fileName: "",
			},
		];

		setDeleteRow(ids);
		setShowRemoveRowModal(true);
	};

	var holdDeleteBrief;
	const [holdDeleteRow, setHoldDeleteRow] = useState(false);
	const [backgroundCopy, setBackgroundCopy] = useState([]);


	const handleDeleteRow = async (item) => {
		var bgCopy = background;
		setBackgroundCopy(bgCopy);
		setHoldReducedTabsArrayCopy([...reducedTabsArray]);
		const updatedBgCopy = bgCopy.filter((x) => x.id !== item[0].id);

		console.log("Updated background copy", updatedBgCopy);

		// Added for previousBackground (Search Functionality)
		const updatedBgCopyPrev = previousBackground.filter(
			(x) => x.id !== item[0].id
		);
		setPreviousBackground(updatedBgCopyPrev);

		setalertMessage(`Rows Deleted. Click HERE to undo action`);
		setShowToast(true);
		setHoldDeleteRow(true);
		const newArr = Array(background.length).fill(false);
		setCheckedState(newArr);

		setBackground([]);

		window.onbeforeunload = function () {
			return "Changes you saved might not be saved.";
		};

		if (item.length === 0) {
			window.alert("Please select row.");
		} else {
			setShowToast(false);
			// Delete file permanently
			if (bool.current) {
				// FIX FOR MMA-2255: Copied delete query from Filebucket to also delete labels
				const mDeleteFileAttachment = `
					mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
						backgroundFileTag(backgroundId: $backgroundId, files: $files) {
							id
						}
					}
				`;

				const mDeleteBackground = `
					mutation untagBriefBackground($briefId: ID, $background: [BackgroundInput]) {
						briefBackgroundUntag(briefId: $briefId, background: $background) {
							id
						}
					}
				`;
				try {
					const request = API.graphql({
						query: mDeleteFileAttachment,
						variables: {
							backgroundId: item[0].id,
							files: item,
						},
					});
					const deletedId = await API.graphql({
						query: mDeleteBackground,
						variables: {
							briefId: briefId,
							background: { id: item[0].id },
						},
					}).then(async () => {
						if (item?.length > 1) {
							const params = {
								query: mCreateActivity,
								variables: {
									companyId: localStorage.getItem("companyId"),
									clientMatterId: matter_id,
									briefId: briefId,
									activity: "removed multiple backgrounds",
									field: "Background",
									appModule: "BACKGROUND",
								},
							};
							const addActivity = await API.graphql(params).then((result) => {
								console.log("addActivity result", result);
							});
						} else {
							const params = {
								query: mCreateActivity,
								variables: {
									companyId: localStorage.getItem("companyId"),
									clientMatterId: matter_id,
									briefId: briefId,
									activity: "removed a background",
									field: "Background",
									appModule: "BACKGROUND",
								},
							};
							const addActivity = await API.graphql(params).then((result) => {
								console.log("addActivity result", result);
							});
						}
					});
				} catch (e) {
					console.log("error here", e);
				}
				setalertMessage(`Successfully Deleted`);
				setBackground(updatedBgCopy);
				setShowToast(true);
			}

			window.onbeforeunload = null;

			const rowArrangement = bgCopy.map(({ id }, index) => ({
				id: id,
				order: index + 1,
			}));

			updateBackgroundOrders(rowArrangement);

			// For FE purposes: Update existing background array with the updated order data
			bgCopy.forEach((item, index) => {
				item.order = index + 1;
			});
			setShowRemoveRowModal(false);
		}
	};

	const handleDelete = async (item) => {
		setHoldDeleteFile(true);
		setalertMessage(`Deleting File. Click HERE to undo action`);
		setShowToast(true);
		setshowRemoveFileModal(false);

		setTimeout(() => {
			setShowToast(false);
		}, 9000);

		setTimeout(() => {
			if (bool.current) {
				deleteFileProper(item);
			} else {
				cancelDeleteFile();
			}
		}, 10000);
	};

	const deleteFileProper = async (item) => {
		console.log("handleDelete", item);
		const backgroundFilesOpt = await API.graphql({
			query: qlistBackgroundFiles,
			variables: {
				id: item[0].backgroundId,
			},
		});

		// console.log("all background file attachment", backgroundFilesOpt);

		if (backgroundFilesOpt.data.background.files !== null) {
			const arrFileResult = backgroundFilesOpt.data.background.files.items.map(
				({ id }) => ({
					id: id,
				})
			);

			const filteredArrFiles = arrFileResult.filter((i) => i.id !== item[0].id);

			const request = API.graphql({
				query: BACKGROUNDFILE_TAG_MUTATION,
				variables: {
					backgroundId: item[0].backgroundId,
					files: filteredArrFiles,
				},
			}).then(async () => {
				const params = {
					query: mCreateActivity,
					variables: {
						companyId: localStorage.getItem("companyId"),
						clientMatterId: matter_id,
						briefId: briefId,
						activity: `removed a background file named "${item[0].fileName}"`,
						field: "File",
						appModule: "BACKGROUND",
						rowId: item[0].backgroundId,
					},
				};
				const addActivity = await API.graphql(params).then((result) => {
					console.log("addActivity result", result);
				});
			});

			setTimeout(async () => {
				// list updated result files
				const backgroundFilesOptReq = await API.graphql({
					query: qlistBackgroundFiles,
					variables: {
						id: item[0].backgroundId,
					},
				});

				if (backgroundFilesOptReq.data.background.files !== null) {
					const newFilesResult =
						backgroundFilesOptReq.data.background.files.items.map(
							({ id, name, description }) => ({
								id: id,
								name: name,
								description: description,
							})
						);

					const updateArrFiles = background.map((obj) => {
						if (obj.id === item[0].backgroundId) {
							return { ...obj, files: { items: newFilesResult } };
						}
						return obj;
					});

					console.log(newFilesResult);
					setBackground(updateArrFiles);
					//Added for previouseBackground (Search Functionality)
					setPreviousBackground(updateArrFiles);
				}
			}, 1000);
		}
		setalertMessage(`Successfully Deleted File.`);
		setShowToast(true);

		setTimeout(() => {
			setShowToast(false);
			setHoldDeleteFile(false);
		}, 2000);
	};

	const cancelDeleteFile = () => {
		bool.current = false;
		setHoldDeleteFile(false);
	};

	function undoDeleteFile() {
		bool.current = false;
		setalertMessage(`Restored File.`);
		setShowToast(true);
		setHoldDeleteFile(false);

		setTimeout(() => {
			setShowToast(false);
		}, 3000);
	}

	//end

	setTimeout(() => {
		setHighlightRows("bg-white");

		if (queryParams.has("count")) {
			// queryParams.delete("count");
			// history.replace({
			//   search: queryParams.toString(),
			// });
			const back_id = "0";
			history.push(
				`${
					AppRoutes.BACKGROUND
				}/${back_id}/${matterId}/?matter_name=${utf8_to_b64(
					matter_name
				)}&client_name=${utf8_to_b64(client_name)}`
			);
		}
	}, 10000);

	function compareValues(key, order = "asc") {
		return function innerSort(a, b) {
			if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
				return 0;
			}

			const varA = new Date(a[key]);
			const varB = new Date(b[key]);
			let comparison = 0;
			if (varA > varB) {
				comparison = 1;
			} else if (varA < varB) {
				comparison = -1;
			}
			return order === "desc" ? comparison * -1 : comparison;
		};
	}

	const handleFilesCheckboxChange = (
		event,
		id,
		files_id,
		background_id,
		name
	) => {
		var temp = pastedRows;
		var temp2 = checkedRows;

		if (event.target.checked) {
			if (!files.includes({ uniqueId: event.target.name })) {
				if (
					tempFiles.indexOf(
						tempFiles.find((temppFiles) => temppFiles.id === id)
					) > -1
				) {
					// temp.map((x)=> x === background_id ? temp.splice(temp.indexOf(x), 1) : x)
					// setPastedRows(temp);
				} else {
					tempFiles = [
						...tempFiles,
						{
							id: id,
							files: files_id,
							backgroundId: background_id,
							name: name,
						},
					];
					selectedRowsBGFilesPass = tempFiles;

					// temp = [...temp, background_id+"/"+files_id];
					// console.log("selected", temp);

					temp2 = [...temp2, background_id + "/" + files_id];
					console.log("selected row+file id", temp2);
					setCheckedRows(temp2);
					// setPastedRows(temp);
					setSelectedRowsBGFiles(tempFiles);
				}
			}
		} else {
			if (
				tempFiles.indexOf(
					tempFiles.find((temppFiles) => temppFiles.id === id)
				) > -1
			) {
				tempFiles.splice(
					tempFiles.indexOf(
						tempFiles.find((temppFiles) => temppFiles.id === id)
					),
					1
				);
				console.log("bgid", background_id);
				console.log("temp", temp);

				var pass = [];
				var pass2 = [];

				// for(var i = 0; i<temp.length; i++){
				//   var splitId = temp[i].split("/");
				//   console.log("firstsecond", splitId);
				//   if(splitId[0] === background_id && splitId[1] === files_id){
				//     pass = pass;
				//   }else{
				//     pass = [...pass, temp[i]];
				//   }
				// }

				for (var i = 0; i < temp2.length; i++) {
					var splitId = temp2[i].split("/");
					if (splitId[0] === background_id && splitId[1] === files_id) {
						pass2 = pass2;
					} else {
						pass2 = [...pass2, temp2[i]];
					}
				}
				console.log("selected row+file id", temp2);
				setCheckedRows(pass2);
				// setPastedRows(pass);
				setSelectedRowsBGFiles(tempFiles);
				selectedRowsBGFilesPass = tempFiles;
			}
		}
	};

	const qlistBackgroundFiles = `
  query getBackgroundByID($id: ID) {
    background(id: $id) {
      id
      files {
        items {
          createdAt
          id
          details
          name
          order
        }
      }
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

	const qGetFileDownloadLink = `
  query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

	const pasteFilestoBackground = async (background_id, index) => {
		var temp = pastedRows;
		temp = [...temp, background_id];
		setPastedRows(temp);
		setLoading(true);
		let arrCopyFiles = [];
		let arrFileResult = [];
		const seen = new Set();

		const backgroundFilesOpt = await API.graphql({
			query: qlistBackgroundFiles,
			variables: {
				id: background_id,
			},
		});

		if (backgroundFilesOpt.data.background.files !== null) {
			arrFileResult = backgroundFilesOpt.data.background.files.items.map(
				({ id, name }) => ({
					id: id,
					name: name,
				})
			);
		}
		console.log("selectedRowsBGFiles", selectedRowsBGFiles);

		arrCopyFiles = selectedRowsBGFiles.map(({ files, name }) => ({
			id: files,
			name: name,
		}));

		arrCopyFiles.push(...arrFileResult);

		const filteredArr = arrCopyFiles.filter((el) => {
			const duplicate = seen.has(el.id);
			seen.add(el.id);
			return !duplicate;
		});
		console.log("BACKGROUND ID:", background_id);
		console.log("content-files", filteredArr);

		if (background_id !== null) {
			const request = await API.graphql({
				query: BACKGROUNDFILE_TAG_MUTATION,
				variables: {
					backgroundId: background_id,
					files: filteredArr,
				},
			});

			console.log("REQUEST FROM:", background_id, "--", request);

			console.log("pasted files:", filteredArr);

			// Activity Log
			Promise.all(
				arrCopyFiles.map(async (file) => {
					const params = {
						query: mCreateActivity,
						variables: {
							companyId: localStorage.getItem("companyId"),
							clientMatterId: matter_id,
							briefId: briefId,
							activity: `copied a background file named "${file.name}"`,
							field: "File",
							appModule: "BACKGROUND",
							rowId: background_id,
						},
					};
					const addActivity = await API.graphql(params).then((result) => {
						console.log("addActivity result", result);
					});
				})
			);

			// End of Activity Log

			const backgroundFilesOptReq = await API.graphql({
				query: qlistBackgroundFiles,
				variables: {
					id: background_id,
				},
			});

			console.log("checkthisss", backgroundFilesOptReq);

			if (backgroundFilesOptReq.data.background.files !== null) {
				const newFilesResult =
					backgroundFilesOptReq.data.background.files.items.map(
						({ id, name, description }) => ({
							id: id,
							name: name,
							description: description,
						})
					);

				const updateArrFiles = background.map((obj) => {
					if (obj.id === background_id) {
						return { ...obj, files: { items: newFilesResult } };
					}
					return obj;
				});
				setBackground(updateArrFiles);
				//Added for previouseBackground (Search Functionality)
				setPreviousBackground(updateArrFiles);
			}
		}

		setSelectedId(background_id);
		setTimeout(() => {
			setLoading(false);
			setSelectedId(0);
		}, 500);
		autoAdjustRowHeight(index);
	};

	const mCreateBackground = `
    mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
      backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
        id
        createdAt
        date
        description
        order
        briefs {
          id
          name
        }
      }
    }
      `;

	const mBulkUpdateBackgroundOrder = `
      mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
        backgroundBulkUpdateOrders(arrangement: $arrangement) {
          id
          order
        }
      }`;

	const mDeleteBackground = `
  mutation untagBriefBackground($briefId: ID, $background: [BackgroundInput]) {
    briefBackgroundUntag(briefId: $briefId, background: $background) {
      id
    }
  }
  `;

	const handlePasteRow = async (targetIndex, action) => {
		if (action === "paste") {
			let tempBackground = [...background];
			let arrFileResultBG = [];

			setCheckedState(new Array(background.length).fill(false));

			const storedItemRows = JSON.parse(localStorage.getItem("selectedRows"));
			var counter = 1;

			console.log("LOOOK", storedItemRows);

			const tempBriefDetails = [{ id: briefId, name: briefName }];

			// Use Promise.all() to execute all API calls asynchronously
			await Promise.all(
				storedItemRows.map(async function (x) {
					// Use destructuring to get the relevant properties from createBackgroundRow.data.backgroundCreate
					const { createdAt, id, date, description, order } = (
						await API.graphql({
							query: mCreateBackground,
							variables: {
								briefs: tempBriefDetails,
								description: x.description,
								comments: "",
								date: x.date,
								files: { items: [] },
								order: counter++,
							},
						})
					).data.backgroundCreate;

					var arrResult = {
						createdAt,
						id,
						files: { items: x.files.items },
						date,
						description,
						order,
						briefs: tempBriefDetails,
					};

					const arrId = [{ id }];

					bgInput.push({ id });
					arrFileResultBG.push(...[arrResult]);

					// Use Promise.all() to execute both API calls simultaneously
					await Promise.all([
						API.graphql({
							query: BACKGROUNDFILE_TAG_MUTATION,
							variables: {
								backgroundId: id,
								files: x.files.items.map((item) => ({
									id: item.id,
									name: item.name,
								})),
							},
						}),
					]);
				})
			);

			// Activity Logging
			const params = {
				query: mCreateActivity,
				variables: {
					companyId: localStorage.getItem("companyId"),
					clientMatterId: matter_id,
					briefId: briefId,
					activity: `copied ${storedItemRows.length} background ${
						storedItemRows.length > 1 ? "rows" : "row"
					}`,
					field: "Background",
					appModule: "BACKGROUND",
				},
			};
			const addActivity = await API.graphql(params).then((result) => {
				console.log("addActivity result", result);
			});

			setTimeout(async function () {
				// console.log("Updated Arr", sortByOrder(arrFileResultBG));

				const reversedArr = sortByOrder(arrFileResultBG).reverse();

				for (const x of reversedArr) {
					var resultBG = {
						createdAt: x.createdAt,
						id: x.id,
						files: { items: x.files.items },
						date: x.date,
						description: x.description,
						order: x.order,
						briefs: x.briefs,
					};

					tempBackground.splice(targetIndex + 1, 0, resultBG);
				}

				setBackground(tempBackground);
				//Added for previouseBackground (Search Functionality)
				setPreviousBackground(tempBackground);
				setSelectRow([]);

				// console.log("tempBackground", tempBackground);
				const result = tempBackground.map(({ id }, index) => ({
					id: id,
					order: index,
				}));

				if (result && result.length > 0) {
					await API.graphql({
						query: mBulkUpdateBackgroundOrder,
						variables: {
							arrangement: result,
						},
					});
				}

				autoAdjustAllRow();
			}, 1500);

			setShowDeleteButton(false);
			setPasteButton(false);
			setMoveButton(false);
			setcheckAllState(false);
			setSelectRow([]);
			setSelectedItems([]);
			setSelectedRowsBG([]);
			localStorage.removeItem("selectedRows");

			setHoldPaste(true);
			setalertMessage("Successfully copied rows. Click HERE to undo action");
			setShowToast(true);

			setTimeout(() => {
				setShowToast(false);
			}, 9000);
		} else if (action === "move") {
			//Old Implementation for Moving Rows
			//Go to line 1136 - handleMoveRow() for the new implementation
			let tempBackground = [...background];
			let arrFileResultBG = [];

			setCheckedState(new Array(background.length).fill(false));

			const storedItemRows = JSON.parse(localStorage.getItem("selectedRows"));
			var counter = 1;

			const tempBriefDetails = [{ id: briefId, name: briefName }];

			storedItemRows.map(async function (x) {
				const createBackgroundRow = await API.graphql({
					query: mCreateBackground,
					variables: {
						briefs: tempBriefDetails,
						description: x.details,
						comments: "",
						date: x.date,
						files: { items: [] },
						order: counter++,
					},
				});

				var arrResult = {
					createdAt: createBackgroundRow.data.backgroundCreate.createdAt,
					id: createBackgroundRow.data.backgroundCreate.id,
					files: { items: x.files.items },
					date: createBackgroundRow.data.backgroundCreate.date,
					description: createBackgroundRow.data.backgroundCreate.description,
					order: createBackgroundRow.data.backgroundCreate.order,
				};

				console.log(createBackgroundRow.data.backgroundCreate);

				bgInput.push({ id: createBackgroundRow.data.backgroundCreate.id });
				arrFileResultBG.push(...[arrResult]);

				const request = await API.graphql({
					query: BACKGROUNDFILE_TAG_MUTATION,
					variables: {
						backgroundId: createBackgroundRow.data.backgroundCreate.id,
						files: x.files.items.map((item) => ({
							id: item.id,
							name: item.name,
						})),
					},
				});

				API.graphql({
					query: mDeleteBackground,
					variables: {
						briefId: briefId,
						background: { id: x.id },
					},
				}).then(async (result) => {
					console.log(result);
					tempBackground = tempBackground.filter((obj) => obj.id !== x.id);

					// Activity Logging
					const params = {
						query: mCreateActivity,
						variables: {
							companyId: localStorage.getItem("companyId"),
							clientMatterId: matter_id,
							briefId: briefId,
							activity: `moved ${storedItemRows.length} background ${
								storedItemRows.length > 1 ? "rows" : "row"
							} order`,
							field: "Background",
							appModule: "BACKGROUND",
						},
					};
					const addActivity = await API.graphql(params).then((result) => {
						console.log("addActivity result", result);
					});

					setTimeout(async function () {
						sortByOrder(arrFileResultBG)
							.splice(0)
							.reverse()
							.map(async function (x) {
								var resultBG = {
									createdAt: x.createdAt,
									id: x.id,
									files: { items: x.files.items },
									date: x.date,
									description: x.description,
									order: x.order,
								};

								tempBackground.splice(targetIndex, 0, resultBG);

								setBackground(tempBackground);
								//Added for previouseBackground (Search Functionality)
								setPreviousBackground(tempBackground);
								setSelectRow([]);

								const result = tempBackground.map(({ id }, index) => ({
									id: id,
									order: index,
								}));

								await API.graphql({
									query: mBulkUpdateBackgroundOrder,
									variables: {
										arrangement: result,
									},
								});
							});

						setShowDeleteButton(false);
						setPasteButton(false);
						setMoveButton(false);
						setcheckAllState(false);
						setSelectRow([]);
						setSelectedItems([]);
						setSelectedRowsBG([]);
						localStorage.removeItem("selectedRows");

						//setHoldPaste(true);
						setalertMessage("Successfully moved rows");
						setShowToast(true);

						setTimeout(() => {
							setShowToast(false);
						}, 3000);

						autoAdjustAllRow();
					}, 1500);
				});
			});
		}
	};

	function undoDeleteRow() {
		setBackground(backgroundCopy);
		//Added for previouseBackground (Search Functionality)
		setPreviousBackground(backgroundCopy);
		clearTimeout(holdDeleteBrief);
		bool.current = false;
		setalertMessage(`Restoring Rows..`);
		setShowToast(true);
		const newArr = Array(background.length).fill(false);
		setCheckedState(newArr);
		setSelectedRowsBG([]);
		setSelectedItems([]);
		setcheckAllState(false);
		setHoldDelete(false);
		if (holdReducedTabsArrayCopy) {
			setReducedTabsArray(holdReducedTabsArrayCopy);
		}

		setTimeout(() => {
			setShowToast(false);
			// getBackground("Action Buttons undo action get background");
			autoAdjustAllRow();
			window.onbeforeunload = null;
		}, 2000);
	}
	function undoAction() {
		const newArr = Array(background.length).fill(false);
		setCheckedState(newArr);
		console.log("BI", bgInput);

		const deletedId = API.graphql({
			query: mDeleteBackground,
			variables: {
				briefId: briefId,
				background: bgInput,
			},
		});

		setSelectedRowsBG([]);
		setSelectedItems([]);
		setcheckAllState(false);
		setHoldPaste(false);
		setSelectRow([]);

		setTimeout(() => {
			setShowToast(false);

			getBackground("Undo Action get Background");
		}, 3000);
	}

	// const reOrderFiles = (array, tempBackground, targetIndex) => {
	//   const df = convertArrayToObject(array);

	//   tempBackground.splice(targetIndex + 1, 0, df.item);
	//   return setBackground(tempBackground);
	// };

	/*const handleBottomScroll = useCallback(() => {
    console.log("Reached bottom page " + Math.round(performance.now()));
    setTimeout(() => {
      setLoading(true);
    }, 200);
    setTimeout(() => {
      loadMoreBackground();
      setLoading(false);
    }, 400);
  });

  useBottomScrollListener(handleBottomScroll);*/

	const createObject = (array, key) => {
		const initialValue = {};
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			};
		}, initialValue);
	};

	const mUpdateMatterFileDesc = `
      mutation updateMatterFile ($id: ID, $details: String) {
        matterFileUpdate(id: $id, details: $details) {
          id
          details
        }
      }
  `;

	const mUpdateMatterFileDate = `
      mutation updateMatterFile ($id: ID, $date: AWSDateTime) {
        matterFileUpdate(id: $id, date: $date) {
          id
          date
        }
      }
  `;

	const handleSyncData = async (backgroundId, fileId) => {
		var filteredBackground = background.filter(function (item) {
			return item.id === backgroundId;
		});

		const dateRequest = API.graphql({
			query: mUpdateMatterFileDate,
			variables: {
				id: fileId,
				date:
					filteredBackground[0].date !== null &&
					filteredBackground[0].date !== "null" &&
					filteredBackground[0].date !== ""
						? moment
								.utc(moment(new Date(filteredBackground[0].date), "YYYY-MM-DD"))
								.toISOString()
						: null,
			},
		});

		if (!dateRequest) {
			console.log("Error", dateRequest);
		}

		const descRequest = API.graphql({
			query: mUpdateMatterFileDesc,
			variables: {
				id: fileId,
				details: filteredBackground[0].description,
			},
		});

		if (!descRequest) {
			console.log("Error", descRequest);
		}

		setalertMessage(`Successfully synced to File Bucket `);
		setShowToast(true);
		setTimeout(() => {
			setShowToast(false);
		}, 2000);
	};

	function utf8_to_b64(str) {
		return window.btoa(unescape(encodeURIComponent(str)));
	}

	//UPLOADING FILE THROUGH BG
	function attachFiles(itemid, index) {
		setShowUploadModal(true);
		setSelectedRowID(itemid);
		setSelectedIndex(index);
	}

	//single matter upload
	const mCreateMatterFile = `
  mutation createMatterFile ($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int) {
      matterFileCreate(matterId: $matterId, s3ObjectKey: $s3ObjectKey, size: $size, type: $type, name: $name, order: $order) {
        id
        name
        order
      }
    }
  `;

	var idTag = [];
	//UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
	const handleUploadLink = async (uf) => {
		await Promise.all(
			uf?.files.map(async (file) => {
				console.log("Uploaded file - ", file);
				const params = {
					query: mCreateActivity,
					variables: {
						companyId: localStorage.getItem("companyId"),
						clientMatterId: matter_id,
						briefId: briefId,
						activity: `added a background file named ${file.name}-(${file.size} byte)`,
						field: "File",
						appModule: "BACKGROUND",
						rowId: selectedRowId,
					},
				};
				const addActivity = await API.graphql(params).then((result) => {
					console.log("addActivity result", result);
				});
			})
		);
		console.log("handleUploadLink uf", uf);
		var uploadedFiles = uf.files.map((f) => ({ ...f, matterId: matterId }));

		//Add order to new files
		var sortedFiles = uploadedFiles?.sort(
			(a, b) => b.oderSelected - a.oderSelected
		);

		var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
		// console.log("SF",sortedFiles);
		console.log("AO", addOrder);

		//insert in matter file list
		bulkCreateMatterFile(addOrder);

		//set background content
		setTimeout(() => {
			const backgroundFilesOptReq = API.graphql({
				query: qlistBackgroundFiles,
				variables: {
					id: selectedRowId,
				},
			}).then((result) => {
				var newFilesResult = result.data.background.files.items.map(
					({ createdAt, id, name, description, order }) => ({
						createdAt: createdAt,
						id: id,
						name: name,
						description: description,
						order: order,
					})
				);

				// Sort the newFilesResult array by createdAt in descending order
				newFilesResult?.sort(
					(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
				);
				console.log(newFilesResult);

				var updateArrFiles = background.map((obj) => {
					if (obj.id === selectedRowId) {
						return { ...obj, files: { items: newFilesResult } };
					}
					return obj;
				});

				// Update order number based on uploaded file
				updateArrFiles.forEach((item) => {
					const orderFilesPerBackground = item?.files?.items?.map(
						({ id }, index) => ({
							id: id,
							order: index + 1,
						})
					);

					// For FE purposes: Update existing background array with the updated order data
					const orderFilesPerBackgroundFE = item?.files?.items?.map(
						(
							{
								id,
								details,
								gmailMessage,
								gmailMessageId,
								isGmailAttachment,
								isGmailPDF,
								name,
								s3ObjectKey,
							},
							index
						) => ({
							id: id,
							name: name,
							details: details,
							gmailMessage: gmailMessage,
							gmailMessageId: gmailMessageId,
							isGmailAttachment: isGmailAttachment,
							isGmailPDF: isGmailPDF,
							s3ObjectKey: s3ObjectKey,
							order: index + 1,
						})
					);

					if (
						orderFilesPerBackground !== undefined &&
						orderFilesPerBackground !== null
					) {
						item.files.items = orderFilesPerBackgroundFE;
						updateBackgroundFilesOrder(orderFilesPerBackground);
						console.log("Items", orderFilesPerBackground);
					}
				});

				console.log("Set New Background", updateArrFiles);
				setBackground(updateArrFiles);
				autoAdjustRowHeight(SelectedIndex);
			});
		}, 2000);

		setalertMessage(`File has been added! Go to File bucket`);
		setShowToast(true);
		setGoToFileBucket(true);

		handleModalClose();
		setTimeout(() => {
			setShowToast(false);
			setGoToFileBucket(false);
		}, 5000);
		//getBackground();
		return "done";
	};

	function createMatterFile(param) {
		//don't delete for single upload trial
		const request = API.graphql({
			query: mCreateMatterFile,
			variables: param,
		});

		console.log("result", request);
	}

	const mBulkCreateMatterFile = `
    mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
      matterFileBulkCreate(files: $files) {
        id
        name
        order
      }
    }
  `;

	async function bulkCreateMatterFile(param) {
		console.log("bulkCreateMatterFile");

		param.forEach(function (i) {
			delete i.oderSelected; // remove orderSelected
		});

		setTimeout(async () => {
			const request = await API.graphql({
				query: mBulkCreateMatterFile,
				variables: {
					files: param,
				},
			});

			console.log("result", request);

			if (request.data.matterFileBulkCreate !== null) {
				request.data.matterFileBulkCreate.map((i) => {
					return (idTag = [...idTag, { id: i.id }]);
				});
			}

			console.log("iDTag", idTag);

			const BACKGROUNDFILE_TAG_MUTATION = `
      mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
          backgroundFileTag(backgroundId: $backgroundId, files: $files) {
            id
          }
        }
      `;

			//append in existing
			const qlistBackgroundFiles = `
    query getBackgroundByID($id: ID) {
      background(id: $id) {
        id
        files {
          items {
            createdAt
            id
            details
            name
            order
          }
        }
        labels {
          items {
            id
            name
          }
        }
      }
    }`;

			let arrFiles = [];
			let arrFileResult = [];
			const seen = new Set();

			// console.log("MID/BID", background_id);

			const backgroundFilesOpt = await API.graphql({
				query: qlistBackgroundFiles,
				variables: {
					id: selectedRowId,
				},
			});

			console.log("CHECKDATA", backgroundFilesOpt);

			if (backgroundFilesOpt.data.background.files !== null) {
				arrFileResult = backgroundFilesOpt.data.background.files.items.map(
					({ id }) => ({
						id: id,
					})
				);

				idTag.push(...arrFileResult);
				console.log("updatedidtag", idTag);

				const filteredArr = idTag.filter((el) => {
					const duplicate = seen.has(el.id);
					seen.add(el.id);
					return !duplicate;
				});

				console.log("rowid", selectedRowId);

				API.graphql({
					query: BACKGROUNDFILE_TAG_MUTATION,
					variables: {
						backgroundId: selectedRowId,
						files: filteredArr,
					},
				});
			} else {
				API.graphql({
					query: BACKGROUNDFILE_TAG_MUTATION,
					variables: {
						backgroundId: selectedRowId,
						files: idTag,
					},
				});
			}
		}, 1000);
		//return request;
	}

	const handleKeyUp = (e) => {
		if (e.key === "Shift" && isShiftDown) {
			setIsShiftDown(false);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Shift" && !isShiftDown) {
			setIsShiftDown(true);
		}
	};

	const handleSelectItem = (e, index, selectedItems) => {
		console.log("test");
		console.log("HANDLE SELECT ITEM SELECTED: ", selectedItems);
		const { value } = e.target;
		const nextValue = getNextValue(value);
		console.log("VALUE: ", value);
		setSelectedItems(nextValue);

		setLastSelectedItem(value);
		autoAdjustRowHeight(index);
		if (nextValue.length > 0) {
			const isf1 = background.filter((item) => nextValue.includes(item.id));
			const xBackground = isf1.map(({ id, date, description, files }) => ({
				id,
				date,
				description: description,
				files,
			}));
			setSelectRow(xBackground);
			setShowDeleteButton(true);
			setSrcIndex(index);

			const ids = xBackground.map(({ id }) => ({
				id,
				fileName: "",
			}));
			setSelectedRowsBG(ids);
			selectedRowsBGPass = ids;
		} else {
			setShowDeleteButton(false);
			setSelectRow([]);
			setSrcIndex("");
			setSelectedRowsBG([]);
			selectedRowsBGPass = [];
		}
		//autoAdjustRowHeight(index);
	};

	const getNextValue = (value) => {
		const hasBeenSelected = !selectedItems.includes(value);

		if (isShiftDown) {
			const newSelectedItems = getNewSelectedItems(value);

			const selections = [...new Set([...selectedItems, ...newSelectedItems])];

			if (!hasBeenSelected) {
				return selections.filter((item) => !newSelectedItems.includes(item));
			}

			return selections;
		}

		// if it's already in there, remove it, otherwise append it
		return selectedItems.includes(value)
			? selectedItems.filter((item) => item !== value)
			: [...selectedItems, value];
	};

	const getNewSelectedItems = (value) => {
		const currentSelectedIndex = background.findIndex(
			(item) => item.id === value
		);
		const lastSelectedIndex = background.findIndex(
			(item) => item.id === lastSelectedItem
		);

		return background
			.slice(
				Math.min(lastSelectedIndex, currentSelectedIndex),
				Math.max(lastSelectedIndex, currentSelectedIndex) + 1
			)
			.map((item) => item.id);
	};

	useEffect(() => {
		document.addEventListener("keyup", handleKeyUp, false);
		document.addEventListener("keydown", handleKeyDown, false);

		return () => {
			document.removeEventListener("keyup", handleKeyUp);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyUp, handleKeyDown]);

	function clearFocus() {
		document.activeElement.blur();
		console.log("cleared focus");
	}
	const handleAddRow = async (item) => {
		try {
			const dateToday = moment
				.utc(moment(new Date(), "YYYY-MM-DD"))
				.toISOString();

			const tempBriefDetails = [{ id: briefId, name: briefName }];
			console.log("Delete Row", tempBriefDetails);

			const createBackgroundRow = await API.graphql({
				query: mCreateBackground,
				variables: {
					briefs: tempBriefDetails,
					description: "",
					comments: "",
					date: null,
					order: item.order + 1,
				},
			});

			if (createBackgroundRow) {
				const result = {
					createdAt: dateToday,
					id: createBackgroundRow.data.backgroundCreate.id,
					description: "",
					date: null,
					order: item.order + 1,
					files: { items: [] },
					labels: { items: [] },
					briefs: [{ id: briefId, name: briefName }],
				};

				setBackground((background) => sortByOrder(background.concat(result)));
				//Added for previouseBackground (Search Functionality)
				setPreviousBackground((background) =>
					sortByOrder(background.concat(result))
				);
				background.splice(item.order, 0, result);

				// Log Activity
				const params = {
					query: mCreateActivity,
					variables: {
						companyId: localStorage.getItem("companyId"),
						clientMatterId: matter_id,
						briefId: briefId,
						activity: "updated the background briefs",
						field: "Background",
						appModule: "BACKGROUND",
					},
				};
				const addActivity = await API.graphql(params).then((result) => {
					console.log("addActivity result", result);
				});

				const rowArrangement = background.map(({ id }, index) => ({
					id: id,
					order: index + 1,
				}));

				/** Start - Update background orders */
				updateBackgroundOrders(rowArrangement);

				// For FE purposes: Update existing background array with the updated order data
				background.forEach((item, index) => {
					item.order = index + 1;
				});

				setcheckAllState(false);
				setCheckedState(new Array(background.length).fill(false));
				setSelectedRowsBG([]);
				setShowDeleteButton(false);
				setBackground(background);
				//Added for previouseBackground (Search Functionality)
				setPreviousBackground(background);
				autoAdjustAllRow();
			}
		} catch (error) {
			console.error(error);
		}
	};

	function rowClicked(itemid) {
		const found = pastedRows.find(
			(element) => element.split("/")[0] === itemid
		);
		return found;
	}

	function rowOriginal(rowid) {
		const currRow = checkedRows.find(
			(element) => element.split("/")[0] === rowid
		);

		return currRow;
	}

	const hoverEnterPreviewFile = async (
		rowId,
		fileId,
		isGmailPDF,
		gmailMessageId
	) => {
		setSnippetId(rowId + "-" + fileId);
		setShow(true);

		if (gmailMessageId !== null && gmailMessageId !== "") {
			const params = {
				query: EMAIL_PREVIEW,
				variables: {
					id: gmailMessageId,
				},
			};

			await API.graphql(params).then(async (result) => {
				console.log("EMAIL_PREVIEW", result);

				const newPayload = await getEmailPayload(gmailMessageId);

				const parsedEmail = EmailParser(
					JSON.parse(newPayload.payload[0].content)
				);

				setPreview(parsedEmail);
				setFrom(result.data.gmailMessage?.from);
				setDateEmail(result.data.gmailMessage?.date);
				setSubject(result.data.gmailMessage?.subject);
				setTo(result.data.gmailMessage?.to);
				setCC(result.data.gmailMessage?.cc);

				const params = {
					query: qGetFileDownloadLink,
					variables: {
						id: rowId,
					},
				};

				await API.graphql(params).then(async (result) => {
					const { type, downloadURL, s3ObjectKey } = result.data.file;

					if (
						(type &&
							(type.includes("vnd.openxmlformats-officedocument") ||
								type.includes("application/msword"))) ||
						isMSdoc(s3ObjectKey)
					) {
						var encodedUrl = encodeURIComponent(downloadURL);
						var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
						setDownloadLink(documentViewerUrl);
						setIframeLoad(true);
					} else {
						setDownloadLink(downloadURL);
						setIframeLoad(true);
					}
				});
			});
		} else {
			const params = {
				query: qGetFileDownloadLink,
				variables: {
					id: rowId,
				},
			};

			await API.graphql(params).then(async (result) => {
				const { type, downloadURL, s3ObjectKey } = result.data.file;

				if (
					(type &&
						(type.includes("vnd.openxmlformats-officedocument") ||
							type.includes("application/msword"))) ||
					isMSdoc(s3ObjectKey)
				) {
					var encodedUrl = encodeURIComponent(downloadURL);
					var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
					setDownloadLink(documentViewerUrl);
					setIframeLoad(true);
				} else {
					setDownloadLink(downloadURL);
					setIframeLoad(true);
				}
			});
		}
	};

	const hoverClosePreviewFile = () => {
		setSnippetId(null);
		setShow(false);
		setDownloadLink("");
		setFrom(null);
		setDateEmail(null);
		setSubject(null);
		setTo(null);
		setCC(null);
		setPreview([]);
	};

	//labels
	const { matter_id } = useParams();

	const BACKGROUND_TABLE_QUERY = `
	query getBackgroundTable($clientMatterId: ID!) {
		backgroundTable(clientMatterId: $clientMatterId) {
			id
			columns {
			id
			accessorKey
			headerText
			enabled
			type
			optionsText
			order
			presets {
				id
				name
			}
			}
			createdAt
			updatedAt
		}
	}`;

	const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }
  `;

	const listBriefs = `
  query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          labelId
        }
      }
    }
  }
  `;

	const getLabels = async () => {
		//list all created labels in
		/*var labelsList = await API.graphql({
      query: listLabels,
      variables: {
        clientMatterId: matter_id //usage purposes in bg
      },
    });

    var temp = [];

    if(labelsList.data.clientMatter !== null){
      labelsList.data.clientMatter.labels.items.map(x=>{
        temp = [...temp, {label: x.name, value: x.id}]
      });

      setLabels(temp);
      console.log("labelslist", labelsList);
    }else{
      setLabels([]);
    }
    */

		//set list of briefs as options in labels
		var labelsList2 = [];

		const params = {
			query: listBriefs,
			variables: {
				id: matter_id,
				limit: 100,
				nextToken: null,
			},
		};

		await API.graphql(params).then((brief) => {
			const briefsList = brief.data.clientMatter.briefs.items;
			for (var i = 0; i < briefsList.length; i++) {
			labelsList2 = [
					...labelsList2,
					{ label: briefsList[i].name, value: briefsList[i].id },
				]; //name and briefid
			}

			if (isSiteDiary) {
				let labelsList3 = removeHBALabels(labelsList2);
				setBriefList(labelsList3);
				setLabels(labelsList3);
			} else {
				console.log(labelsList2);
				setBriefList(labelsList2);
				setLabels(labelsList2);
			}
		});
	};

	function removeHBALabels(objArray) {
    let removeLabels = [
			"Progress of Work",
      "Project Location",
      "Builder Details",
      "Contract Details",
    ]
    var result = objArray?.filter((obj) =>
      removeLabels.indexOf(obj.label) === -1
    );

    return result;
  }

	const tagLabel = async (evt, action, bgidd, content, briefs) => {
		const origBriefs = briefs
		console.log(action);
		if (action.action === "select-option") {
			// Update Briefs Row
			console.log(origBriefs)
			var updatedBriefsArr = evt.map(function (item) {
				return { id: item.value, name: item.label };
			});
			// Tag Briefs to the old background
			await API.graphql({
				query: mUpdateBrief,
				variables: {
					id: bgidd,
					briefs: updatedBriefsArr,
				},
			}).then(async (result) => {
				console.log("res tagged brief", result);
				const params = {
					query: mCreateActivity,
					variables: {
						companyId: localStorage.getItem("companyId"),
						clientMatterId: matter_id,
						briefId: briefId,
						activity: "updated the background briefs",
						field: "Brief",
						current: updatedBriefsArr,
						previous: origBriefs,
						appModule: "BACKGROUND",
						rowId: bgidd,
					},
				};
				const addActivity = await API.graphql(params).then((result) => {
					console.log("addActivity result", result);
				});
			});

			// Optimized version of the code
			//var filterBriefArr = evt.map(item => item.value).join('');
			const selectedItem = action.option;

			if (briefId !== selectedItem.value) {
				const tempBriefDetails = [
					{ id: selectedItem.value, name: selectedItem.label },
				];
				console.log(tempBriefDetails);

				const createBackgroundRow = await API.graphql({
					query: mCreateBackground,
					variables: {
						briefs: tempBriefDetails,
						comments: "",
						description: content.description,
						date: content.date,
					},
				});

				console.log(createBackgroundRow);

				// Tag Briefs to the new background row
				// console.log(updatedBriefsArr);
				// await API.graphql({
				//   query: mUpdateBrief,
				//   variables: {
				//     id: createBackgroundRow.data.backgroundCreate.id,
				//     briefs: updatedBriefsArr,
				//   },
				// }).then((result) => {
				//   console.log("res tagged brief", result);
				// });

				// Tag files to background

				const arrFileResult = content.files.items.map(({ id }) => ({
					id: id,
				}));

				await API.graphql({
					query: BACKGROUNDFILE_TAG_MUTATION,
					variables: {
						backgroundId: createBackgroundRow.data.backgroundCreate.id,
						files: arrFileResult,
					},
				}).then((result) => {
					console.log("res tagged files", result);
				});
			}
		} else if (action.action === "remove-value") {
			// Update Briefs Row
			var updatedBriefsArr = evt.map(function (item) {
				return { id: item.value, name: item.label };
			});

			await API.graphql({
				query: mUpdateBrief,
				variables: {
					id: bgidd,
					briefs: updatedBriefsArr,
				},
			}).then((result) => {
				console.log("res tagged brief", result);
			});
		} else if (action.action === "create-option") {
			console.log("Value for created labels", action.option.value);
			console.log("Value for created matter", matter_id);

			if (isSiteDiary) {
				let removeLabels = [
					"Progress of Work",
					"Project Location",
					"Builder Details",
					"Contract Details",
				]
				if (removeLabels.includes(action.option.label) || removeLabels.includes(action.option.value)) {
					alert("You can't put that as a label");
					return;
				}
			}
			await API.graphql({
				query: mCreateBrief,
				variables: {
					clientMatterId: matter_id,
					name: action.option.value,
					date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
					order: 0,
					columnIds: defaultColumnIds,
				},
			}).then(async (r) => {
				console.log("Create Labels - ", r);
				const newLabelId = r.data.briefCreate.id;
				const newLabelName = r.data.briefCreate.name;

				const tempBriefDetails = [{ id: newLabelId, name: newLabelName }];

				const backgroundTable = await API.graphql({
					query: BACKGROUND_TABLE_QUERY,
					variables: {
						clientMatterId: matter_id,
					},
				});

				if (backgroundTable?.data?.backgroundTable) {
					const { id, columns } = backgroundTable?.data?.backgroundTable;

					const updatedBriefs = columns.map((obj) => {
						if (parseInt(obj.accessorKey) !== 3) {
							const presetsToUse = [
								...obj.presets.filter((preset) => preset.id !== newLabelId),
								{ id: newLabelId, name: newLabelName },
							];

							return { ...obj, presets: presetsToUse };
						} else {
							return obj;
						}
					});

					const variables = {
						id,
						input: {
							columns: updatedBriefs,
						},
					};

					const updateBriefTable = await API.graphql({
						query: UPDATE_BACKGROUND_TABLE_MUTATION,
						variables,
					});

					console.log("updateBriefTable", updateBriefTable);
				}

				var updatedBriefsArr = evt.map(function (item) {
					return { id: item.value, name: item.label };
				});

				await API.graphql({
					query: mUpdateBrief,
					variables: {
						id: bgidd,
						briefs: updatedBriefsArr,
					},
				}).then((result) => {
					console.log("res tagged brief", result);
				});

				const createBackgroundRow = await API.graphql({
					query: mCreateBackground,
					variables: {
						briefs: tempBriefDetails,
						comments: "",
						description: content.description,
						date: content.date,
						files: content.files,
					},
				});

				// Tag Briefs to the new background row
				await API.graphql({
					query: mUpdateBrief,
					variables: {
						id: createBackgroundRow.data.backgroundCreate.id,
						briefs: updatedBriefsArr,
					},
				}).then((result) => {
					console.log("res tagged brief", result);
				});

				// Tag files to background

				const arrFileResult = content.files.items.map(({ id }) => ({
					id: id,
				}));

				await API.graphql({
					query: BACKGROUNDFILE_TAG_MUTATION,
					variables: {
						backgroundId: createBackgroundRow.data.backgroundCreate.id,
						files: arrFileResult,
					},
				}).then((result) => {
					console.log("res tagged files", result);
					getLabels();
				});
			});
		}
		// getBackground("Tag Label get Background");
	};

	const mCreateLabel = `
    mutation createLabel($clientMatterId: String, $name: String) {
        labelCreate(clientMatterId:$clientMatterId, name:$name) {
            id
            name
        }
    }
  `;

	const mCreateBrief = `
  mutation createLabel($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }
  `;

	const listLabels = `
  query listLabels($clientMatterId: ID) {
    clientMatter(id: $clientMatterId) {
      labels {
        items {
          id
          name
        }
      }
    }
  }
`;

	const mUpdateBrief = `
  mutation tagBriefToBackground($id: ID, $briefs: [BriefInput]) {
    backgroundUpdate(id: $id, briefs: $briefs) {
      id
    }
  }
  `;

	function getRowLabels(rowLabels) {
		// console.log("rowlabels", rowLabels);
		var temp = [];

		if (rowLabels) {
			if (rowLabels.length > 0) {
				rowLabels.map((x) => {
					temp = [...temp, { label: getMinimizedLabel(x.name), value: x.id }];
					//ADDED FUNCTION TO MINIMIZE LABEL
				});

				// console.log("tempp", temp);
				return temp;
			} else {
				return [];
			}
		} else {
			return [];
		}

		// }
	}

	//MINIMIZES LABEL NAME TO 22 CHARACTERS
	function getMinimizedLabel(inputLabel) {
		return inputLabel && inputLabel.length > 23
			? inputLabel.substring(0, 22) + "..."
			: inputLabel;
	}

	//MAKES DESC COLUMN FIXED SIZE DEPENDENT ON VIEW
	function getDescColWidth() {
		if (width > 965.263) {
			return width - 900.263;
		} else {
			return 200;
		}
	}

	const convertDate = (itemDate) => {
		if (itemDate !== null || itemDate !== "null" || itemDate !== "") {
			try {
				return new Date(moment.utc(new Date(itemDate)).local().format());
			} catch (error) {
				return null;
			}
		} else {
			return null;
		}
	};

	const scrollX = window.scrollX;
	const scrollY = window.scrollY;

	useLayoutEffect(() => {
		window.scrollTo(scrollX, scrollY);
	});

	// ChatGPT Function
	// const [engine, setEngine] = useState([{ value: "", label: "Select Engine" }]);
	const [loaderEmail, setLoaderEmail] = useState(null);

	const handleSavePromptDesc = async (id, val, index) => {
		const data = {
			description: val,
		};
		const success = await updateBackgroundDesc(id, data);
		if (success) {
			setalertMessage("Successfully updated.");
			setShowToast(true);

			const newArrDescription = background.map((bg) => {
				if (bg.id === id) {
					return { ...bg, description: val };
				}
				return bg;
			});

			setLoaderEmail(null);
			setBackground(newArrDescription);
			//Added for previouseBackground (Search Functionality)
			setPreviousBackground(newArrDescription);
			autoAdjustRowHeight(index);
		}
		setTimeout(() => {
			setShowToast(false);
		}, 1000);
	};

	const handleOpenAI = async (id, index, description, gmailMessageId) => {
		const origDescription = handleDescription(description, index);

		let promptEmail = await getEmailPayload(gmailMessageId);
		const parsedEmail = EmailParser(JSON.parse(promptEmail.payload[0].content));
		promptEmail = parsedEmail.text;

		const { REACT_APP_CHATGPT_API: apiKey } = process.env;
		const engine = "gpt-3.5-turbo-16k-0613";
		const max_tokens = 3000;
		const endpoint = `https://api.openai.com/v1/chat/completions`;
		const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
		const temperature = 0.2;

		if (promptEmail !== "") {
			setLoaderEmail(id);
			// Use template literals for instruction
			const MAX_SENTENCES = 5;
			const MAX_WORDS_PER_SENTENCE = 20;

			// Split the email into sentences
			const sentences = promptEmail.split(/[.!?]/);

			// Filter and limit the sentences
			const filteredSentences = sentences.filter(
				(sentence) => sentence.trim() !== ""
			); // Remove empty sentences

			// Join the filtered sentences to create the limited email content
			const limitedEmailContent = filteredSentences.join(". ");

			const instruction = `Summarize the email in one paragraph (max ${MAX_SENTENCES} sentences, ${MAX_WORDS_PER_SENTENCE} words each), excluding the signature of the original sender:\n\n${limitedEmailContent}`;

			try {
				const response = await fetch(endpoint, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${apiKey}`,
					},
					body: JSON.stringify({
						model: engine,
						messages: [{ role: "user", content: `${instruction}` }],
						max_tokens,
						stop,
						temperature,
					}),
				});

				if (!response.ok) {
					throw new Error(response.statusText);
				}

				const data = await response.json();
				console.log("Data", data);
				const choices = data.choices;
				console.log("Choices", choices);

				const message = choices[0]?.message?.content
					?.trim()
					.replace(/(\n)+$/, "")
					.replace(/\s+/g, " ");

				if (rightPanelItems?.length !== 0) {
					let clone = rightPanelItems;
					clone[0].description = message;
					setRightPanelItems(clone);
				}

				// Destructure choices directly in the function call
				handleSavePromptDesc(id, message, index).then(async () => {
					const params = {
						query: mCreateActivity,
						variables: {
							companyId: localStorage.getItem("companyId"),
							clientMatterId: matter_id,
							briefId: briefId,
							activity: "updated the background description",
							field: "Description",
							current: message,
							previous: origDescription,
							appModule: "BACKGROUND",
							rowId: id,
						},
					};
					const addActivity = await API.graphql(params).then((result) => {
						console.log("addActivity result", result);
					});
				});
			} catch (error) {
				console.error("Error", error);
			} finally {
				setLoaderEmail(null);
			}
		} else {
			setLoaderEmail(null);
		}
	};

	const convertHTMLEntity = function (text) {
		const sp = document.createElement("span");

		if (!text) return "";

		const output = text
			?.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
				sp.innerHTML = entity;
				return sp.innerText;
			})
			.replace(/<br ?\/?>/g, "\n")
			.replace(/(<([^>]+)>)/gi, "");

		return output;
	};

	const chronologicalOrder = (files) => {
		files?.sort((file1, file2) => {
			const number1 = parseInt(file1?.name?.match(/\d+/));
			const number2 = parseInt(file2?.name?.match(/\d+/));

			const noNumbers1 = isNaN(number1);
			const noNumbers2 = isNaN(number2);

			if (noNumbers1 && noNumbers2) {
				// If both file names have no numbers, sort them alphabetically
				return file1.name.localeCompare(file2.name);
			} else if (noNumbers1) {
				return 1;
			} else if (noNumbers2) {
				return -1;
			}

			return number1 - number2;
		});

		return files;
	};

	useEffect(() => {
		// if (columns && columns?.length > 0) {
		const sortedTaggedCols = sortByOrder(columns);
		console.log("tagged columns", sortedTaggedCols);
		// Check if the value of columns has changed
		if (JSON.stringify(sortedTaggedCols !== JSON.stringify(taggedColumns))) {
			setTaggedColumns(sortedTaggedCols);
		}
		// }

		fetchCompanyUsers();
	}, [columns, background, taggedColumns]);

	useEffect(() => {
		// Clone the background array
		const updatedBackground = cloneDeep(background);

		updatedBackground.forEach((item) => {
			const orderFilesPerBackground = item?.files?.items
				?.map(({ id }, index) => ({
					id: id,
					order: index + 1,
				}))
				?.sort((a, b) => a.order - b.order);

			const orderFilesPerBackgroundFE = item?.files?.items
				?.map(
					(
						{
							id,
							details,
							gmailMessage,
							gmailMessageId,
							isGmailAttachment,
							isGmailPDF,
							name,
							s3ObjectKey,
							order,
						},
						index
					) => ({
						id: id,
						name: name,
						details: details,
						gmailMessage: gmailMessage,
						gmailMessageId: gmailMessageId,
						isGmailAttachment: isGmailAttachment,
						isGmailPDF: isGmailPDF,
						s3ObjectKey: s3ObjectKey,
						order: index + 1,
					})
				)
				?.sort((a, b) => a.order - b.order);

			if (
				orderFilesPerBackground !== undefined &&
				orderFilesPerBackground !== null
			) {
				// Update item.files.items with the latest order
				item.files.items = orderFilesPerBackgroundFE;
				//updateBackgroundFilesOrder(orderFilesPerBackground);
			}
		});
	}, [background]);

	// QUICK SEARCH CODE BLOCKS
	var hitsPerRow = useRef([]);
	useEffect(() => {
		if (typeof qSearchValue !== "undefined" || qSearchValue != "") {
			let totalTemp = 0;
			let bgLen = background.length;
			hitsPerRow.current = [...Array(bgLen).keys()].map((i) => 0);
			//setTotalHits(0);
			for (let i = 0; i < bgLen; i++) {
				let currHitsLen = background[i]?.description?.match(
					new RegExp(qSearchValue, "g")
				)?.length;
				if (
					typeof currHitsLen != "undefined" &&
					qSearchValue != "" &&
					typeof qSearchValue !== "undefined"
				) {
					hitsPerRow.current[i] = currHitsLen;
					totalTemp += currHitsLen;
				}
			}
			setTotalHits(totalTemp);
			if (totalTemp > 0 && qSearchValue != "") {
				setQSearchPtr(1);
				prevRow.current = 0;
				// for (let j = 0; j < bgLen; j++) {
				//   if (hitsPerRow.current[j] > 0) {
				//     console.log("scroll",j);
				//     bindList?.current?.scrollToRow(j);
				//     break
				//   }
				// }
			}

			if (totalTemp == 0) {
				setQSearchPtr(0);
			}
			// if (totalHits == 0) {
			//   setQSearchPtr(0);
			// }
			//console.log(hitsPerRow.current);
		}
	}, [qSearchValue, background]);

	useEffect(() => {
		for (let j = 0; j < background.length; j++) {
			if (hitsPerRow.current[j] > 0) {
				console.log("scroll2", j);
				bindList?.current?.scrollToRow(j);
				break;
			}
		}
	}, [totalHits]);

	useEffect(() => {
		if (background.length > 0) {
			let subtotalTemp = qSearchPtr;
			let row = 0;
			if (qSearchPtr > 0) {
				for (let i = 0; i < hitsPerRow.current.length; i++) {
					subtotalTemp -= hitsPerRow.current[i];
					if (subtotalTemp <= 0) {
						row = i;
						inRow.current = [i, hitsPerRow.current[i] - subtotalTemp * -1 - 1];
						console.log("INROW=>", inRow.current);
						break;
					}
				}

				let duration = 3000;
				bindList?.current?.scrollToRow(row);
				setTimeout(() => {
					bindList.current?.scrollToRow(row);
				}, 1000);
				if (prevRow.current - row > 10) {
					setTimeout(() => {
						bindList.current?.scrollToRow(row);
					}, 2000);
				}

				let iter = Math.ceil(Math.abs(prevRow.current - row) / 30);
				for (let i = 0; i < iter; i++) {
					setTimeout(() => {
						bindList.current?.scrollToRow(row);
					}, duration);
					duration += 800;
				}
				prevRow.current = row;
			}
		}
	}, [qSearchPtr, hitsPerRow.current]);

	const handleDynamicChange = (e) => {
		setInputDynamic(e.target.value);
		console.log(e.target.value);
	};

	const updateDynamicData = async (type, accessorKey, value, row, id) => {
		console.log("ACCESSORKEY", accessorKey);
		console.log("TYPE", type);
		console.log("ROW", row);
		console.log("ID", id);
		console.log("VALUE", value);
		const tempParsed =
			typeof row.dynamicData === "string" && row.dynamicData !== null
				? JSON.parse(row.dynamicData)
				: row.dynamicData || {};

		const tempDynamic = { ...tempParsed, [accessorKey]: value };
		console.log("ROW", row);
		console.log("tempDynamic", tempDynamic);

		const data =
			type === "DATE" ? { date: moment.utc(value).toISOString() || null } : {};
		console.log("DATE", data);
		if (type === "DATE") {
			tempDynamic[accessorKey] = moment.utc(data.date).toISOString();
		}

		const clonedRow = { ...row, dynamicData: { ...tempDynamic } }; // Deep clone dynamicData

		const updateData = await API.graphql({
			query: mUpdateBackgroundDynamicData,
			variables: {
				id: id,
				dynamicData: JSON.stringify(clonedRow.dynamicData), // Pass cloned dynamicData
			},
		}).then((result) => {
			console.log(
				"SUCCESS CHANGING DYNAMIC DATA",
				JSON.parse(result.data.backgroundUpdate.dynamicData)
			);

			const updatedBackground = background.map((x) =>
				x.id === id ? { ...x, dynamicData: result.data.backgroundUpdate.dynamicData } : x
			);

			console.log("Updated background", updatedBackground);
			setBackground(updatedBackground);
		});
	};

	const [people, setPeople] = useState();
	const companyId = localStorage.getItem("companyId");
	const qGetTeams = `
  query getTeamsByCompany($id: String) {
    company(id: $id) {
      teams {
        items {
          id
          name
        }
      }
    }
  }
    `;

	const qGetTeamsWithMembers = `
  query getTeamMembers($id: ID) {
    team(id: $id) {
      id
      name
      members {
        items {
          user {
            id
            firstName
            lastName
            userType
            profilePicture
          }
        }
      }
    }
  }`;

	const COMPANY_USERS_QUERY = `
query companyUsers($id: String!){
  company(id: $id){
    users {
      items {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
}
`;

	async function fetchCompanyUsers() {
		//change the company id by the organzation you want to filter
		//if(companyId === "ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e"){ //develop companyid
		// if(companyId === "c45f62bb-e91d-482d-9ddc-48cfebbb22b1"){ //uat companyid
		if (companyId === "7a7d3c83-4cb3-49cb-acef-82b7b71054eb") {
			//master companyid
			const res = await API.graphql({
				query: qGetTeams,
				variables: {
					id: companyId,
				},
			});

			var teamNames = res.data.company.teams.items;
			var specialTeamId = "";

			/* Finding the team id of the team named "Operations" */
			for (var i = 0; i < teamNames.length; i++) {
				if (teamNames[i].name?.toLowerCase() === "operations") {
					specialTeamId = teamNames[i].id;
					break;
				} else {
					specialTeamId = "0"; //placeholder
				}
			}

			if (specialTeamId === "0") {
				const { data } = await API.graphql({
					query: COMPANY_USERS_QUERY,
					variables: {
						id: companyId,
					},
				});

				const usersFullNames = data.company.users.items.map(
					({ firstName, lastName, profilePicture }) => {
						return { firstName, lastName, profilePicture };
					}
				);
				setPeople(usersFullNames); //set regular list as options, null catcher
			} else {
				const res1 = await API.graphql({
					query: qGetTeamsWithMembers,
					variables: {
						id: specialTeamId,
					},
				});

				var members = res1.data.team.members.items;
				var memberArray = [];

				for (var j = 0; j < members.length; j++) {
					memberArray = [
						...memberArray,
						{
							firstName: members[j].user.firstName,
							lastName: members[j].user.lastName,
							profilePicture: members[j].user.profilePicture,
						},
					];
				}

				setPeople(memberArray); //filtered people
			}
		} else {
			const { data } = await API.graphql({
				query: COMPANY_USERS_QUERY,
				variables: {
					id: companyId,
				},
			});

			const usersFullNames = data.company.users.items.map(
				({ firstName, lastName, profilePicture }) => {
					return { firstName, lastName, profilePicture };
				}
			);
			setPeople(usersFullNames); //set regular list as options
		}
	}

	const qGetFilesByMatter = `
query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
  matterFiles(isDeleted: $isDeleted, matterId: $matterId, nextToken: $nextToken, limit: $limit, sortOrder: $sortOrder) {
    items {
      id
      name
      type
    }
  }
}
`;

	const [fileTypeCollection, setFileTypeCollection] = useState([]);

	let getMatterFiles = async () => {
		const params = {
			query: qGetFilesByMatter,
			variables: {
				matterId: matter_id,
				isDeleted: false,
				nextToken: null,
				sortOrder: "DATE_ASC",
			},
		};
		await API.graphql(params).then((files) => {
			let matterFilesList = files?.data?.matterFiles?.items;
			//console.log("matterFilesList: TEST", matterFilesList);
			setFileTypeCollection(matterFilesList);
		});
	};

	useEffect(() => {
		getMatterFiles();
	}, [background]);

	const StrictModeDroppable = ({ children, ...props }) => {
		const [enabled, setEnabled] = useState(false);
		useEffect(() => {
			const animation = requestAnimationFrame(() => setEnabled(true));
			return () => {
				cancelAnimationFrame(animation);
				setEnabled(false);
			};
		}, []);
		if (!enabled) {
			return null;
		}
		return <Droppable {...props}>{children}</Droppable>;
	};

	const filterConfig = {
		ignoreCase: true,
		ignoreAccents: false,
		trim: true,
		matchFrom: "any",
	};

	//Handles old data, which is when all the row data have a 0 value in order
	//Note: Client side only, the handleAddRow() handles all the DB mutations
	useEffect(() => {
		if (background.length > 0) {
			//console.log('bg not empty');
			if (background[0]?.order === 0) {
				background.forEach((item, index) => {
					item.order = index + 1;
				});
			}
		}
	}, [background]);

	const MyOption = (props) => {
		//console.log(props);
		if (props.children.includes('"')) {
			let rawInput = document.getElementById(
				"creatableSelect-" + currLabelCellSelected.current.split("-")[3]
			).lastChild.firstChild.lastChild.firstChild.value;
			let numMatch = 0;
			///console.log(props);
			//console.log(creatableSelectRef.current.props);
			//console.log(creatableSelectRef.current);
			for (let i = 0; i < props.options.length; i++) {
				if (
					(props.options[i].label,
					rawInput,
					props.options[i].label.toLowerCase().trim().includes(rawInput))
				) {
					numMatch += 1;
				}
			}
			return (
				//#deebff
				<div
					className={
						(props.isFocused ? "activeOption" : "") +
						" createOption items-center flex flex-row justify-between border-dashed border-2 rounded-md"
					}
				>
					<FcInfo size={20} className="ml-1" />
					<components.Option {...props}>
						<div className="createOptionText mx-1 flex flex-col">
							{numMatch === 1 && (
								<div className="text-xs">No results were found.</div>
							)}
							<div>
								Click to <span className="font-bold">add {props.value}</span> on
								the list
							</div>
						</div>
					</components.Option>
					<AiFillCloseCircle
						color="#2196f3"
						size={20}
						className="mr-1"
						onClick={(e) => {
							console.log(
								document.getElementById(
									"creatableSelect-" +
										currLabelCellSelected.current.split("-")[3]
								)
							);
							//document.getElementById("creatableSelect-"+currLabelCellSelected.current.split("-")[3]).lastChild.firstChild.lastChild.firstChild.clear();
							props.setValue(props.getValue());
						}}
					/>
				</div>
			);
		} else {
			return (
				<Draggable
					draggableId={props.innerProps.id}
					index={parseInt(props.innerProps.id.split("-")[4])}
				>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.dragHandleProps}
							{...provided.draggableProps}
						>
							<components.Option {...props}>
								<div className="flex flex-row">
									<div>
										<MdDragIndicator
											className="item-no mr-0 text-2xl text-gray-500 items-center	"
											size={20}
										/>
									</div>

									<span className="items-center	">{props.label}</span>
								</div>
							</components.Option>
						</div>
					)}
				</Draggable>
			);
		}
	};

	const MyMenuList = (props) => {
		return (
			<div>
				<DragDropContext
					onBeforeDragStart={() => {}}
					onDragEnd={handleDragEndPopup}
				>
					<Droppable isDropDisabled={false} droppableId={props.selectProps.id}>
						{(provided, snapshot) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								<components.MenuList {...props}></components.MenuList>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		);
	};

	const MyNoOptionsMessage = (props) => {
		return (
			<div
				className={
					"noOption items-center flex flex-row justify-between border-dashed border-2 rounded-md"
				}
			>
				<FcInfo size={20} className="ml-1" />
				<components.NoOptionsMessage {...props}>
					<div className="noOptionText mx-1">All labels have been selected</div>
				</components.NoOptionsMessage>
				<FcInfo size={20} className="mr-1" />
			</div>
		);
	};

	// Define your padding value for the bottom
	const paddingBottom = 0;

	// Handle Scrolling for saving description
	useEffect(() => {
		// set when checkbox selected count
		const countChecked = selectedItems.length;

		if (countChecked === 0) {
			setMoveButton(false);
			setPasteButton(false);
			autoAdjustAllRow();
		}
	}, [selectedItems]);
	const [focusedInput, setFocusedInput] = useState(null);

	useEffect(() => {
		const handleScroll = () => {
			if (focusedInput !== null) {
				const inputElement = document.getElementById(
					`${focusedInput}-description`
				);
				console.log(inputElement);
				if (inputElement) {
					inputElement.blur(); // Remove focus
				}
				setFocusedInput(null); // Clear the focused input
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [focusedInput]); ///INSERT SLATE FUNCTIONS HERE

	return (
		<>
			{/* Prevents user from going back while change is still being made. (MMA-1930)*/}
			<Prompt
				when={saving}
				message="You have unsaved changes. Are you sure you want to leave?"
			/>
			<div className="">
				<div className="-my-2 sm:-mx-6 lg:-mx-8">
					<div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="border-b border-gray-200 shadow align-center sm:rounded-lg"  style={{ overflowX: "auto" }} >
							{background?.length === 0 ? (
								<>
									<table className="min-w-full text-xs divide-y divide-gray-200 table-fixed">
										<thead className="z-20 bg-gray-100">
											<tr>
												<th className="w-5 px-2 py-4 text-center whitespace-nowrap">
													<Skeleton count={1} />
												</th>
												<th className="w-8 px-2 py-4 text-center whitespace-nowrap">
													<span className="">
														<Skeleton count={1} />
													</span>
												</th>
												<th className="w-5 px-2 py-4 text-center whitespace-nowrap">
													<Skeleton count={1} />
												</th>
												<th className="w-auto px-2 py-4 text-center whitespace-nowrap">
													<Skeleton count={1} />
												</th>
												<th className="w-auto px-2 py-4 text-center whitespace-nowrap">
													<Skeleton count={1} />
												</th>
											</tr>
										</thead>
										<tbody>
											{[...Array(4).keys()].map((data, index) => {
												return (
													<tr key={`skeleton-loader-${index}`}>
														<td className="w-5 p-2 align-top">
															<div className="align-top">
																<SkeletonTheme width={"40px"} height={"20px"}>
																	<p>
																		<Skeleton count={1} />
																	</p>
																</SkeletonTheme>
															</div>
														</td>
														<td className="w-8 p-2 align-top">
															<div>
																<SkeletonTheme height="40px" width="110px">
																	<p className="mb-2">
																		<Skeleton count={1} />
																	</p>
																</SkeletonTheme>
															</div>
														</td>
														<td className="w-5 p-2 align-top">
															<div className="align-top">
																<SkeletonTheme width={"40px"} height={"20px"}>
																	<p>
																		<Skeleton count={1} />
																	</p>
																</SkeletonTheme>
															</div>
														</td>
														<td className="w-full p-2 align-top">
															<div>
																<SkeletonTheme>
																	<p>
																		<Skeleton count={4} />
																	</p>
																</SkeletonTheme>
															</div>
														</td>
														<td className="w-auto p-2 align-top">
															<div>
																<SkeletonTheme height="40px" width="340px">
																	<p className="mb-2">
																		<Skeleton count={1} />
																	</p>
																	<p className="mb-2">
																		<Skeleton count={1} />
																	</p>
																</SkeletonTheme>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</>
							) : background?.length !== 0 ? (
								<>
									<div>
									<ScrollToTop
										smooth
										color="rgb(117, 117, 114);"
										style={{ padding: "0.4rem" }}
									/>
									{background !== null && background.length !== 0 ? (
										<DragDropContext onDragEnd={handleDragEnd}>
											<Droppable droppableId="tbl-backgrounds" type="PARENT">
												{(provider) => (
													<table
														id="actualTable"
														className="min-w-full divide-y divide-gray-200 table-auto"
														style={{
															overflow: "auto",
														}}
													>
														<tbody
															ref={provider.innerRef}
															{...provider.droppableProps}
															className="bg-white divide-y divide-gray-200"
															style={{
																width: "100%",
																height: "auto",
																overflow: "auto",
															}}
														>
															{

																background.map((item, index) => {
																	// const row = rows[virtualRow.index];
																	// const item = row.original;
																	// const index = row.index;
																	const style = '';

																	let itemBriefsArr = item.briefs?.map(
																		(item) => item.name
																	);

																	let itemBriefsM1;

																	let firstThreeLabels = [];
																	let theRestLabels = [];
																	if (item.briefs?.length > 3) {
																		theRestLabels = cloneDeep(
																			item.briefs
																		);
																		firstThreeLabels =
																			theRestLabels?.splice(0, 3);

																		itemBriefsM1 =
																			cloneDeep(itemBriefsArr);
																		itemBriefsM1.shift();
																		itemBriefsM1.shift();
																		itemBriefsM1.shift();
																		var itemBriefsHTML =
																			itemBriefsM1.join("<br/>");
																	}

																	let popupInputNode =
																		document.getElementById(
																			"creatableSelect-" + index
																		)?.lastChild?.firstChild?.lastChild
																			?.firstChild;
																	const dynamicData =
																		typeof item.dynamicData === "string"
																			? JSON.parse(item.dynamicData)
																			: item.dynamicData;

																	let fileList = [];
																	item?.files?.items?.forEach((row) => {
																		if (
																			row.gmailMessage?.gmailLabelIds?.includes(
																				"SENT"
																			)
																		) {
																			fileList.push("Sent");
																		} else if (
																			row.gmailMessage?.gmailLabelIds?.includes(
																				"INBOX"
																			)
																		) {
																			fileList.push("Inbox");
																		} else if (
																			row.gmailMessage === null ||
																			row.gmailMessageId === ""
																		) {
																			fileList.push("Uploaded");
																		}
																	});
																	fileList = fileList.filter(
																		(item, index) =>
																			fileList.indexOf(item) === index
																	);
																	//console.log("FILE LIST: ", fileList);
																	let gmailStatus = "";
																	for (
																		let i = 0;
																		i < fileList.length;
																		i++
																	) {
																		gmailStatus =
																			gmailStatus + fileList[i];
																		if (i !== fileList.length - 1) {
																			gmailStatus = gmailStatus + " / ";
																		}
																	}

																	return (
																			<div
																				style={{
																					...style,
																					width: "100%",
																					height: "100%",
																					paddingBottom:
																						index ===
																						background.length - 1
																							? `${paddingBottom}px`
																							: "0",
																				}}
																			>
																				{index === 0 && (
																					<thead
																						className={handleFontSize(
																							"bg-white-100 z-10",
																							fontSize
																						)}
																						style={{
																							position: "sticky",
																							zIndex: 10,
																							width: "100%",
																							color: "#8A8A8A",
																						}}
																						onClick={() =>
																							setIsActiveCell(null)
																						}
																					>
																						<tr
																							style={{
																								borderBottom:
																									"1px solid #E4E7E7",
																								borderTop:
																									"1px solid #E4E7E7",
																							}}
																						>
																							<th
																								className={handleFontSize(
																									"px-2 py-4 text-center whitespace-nowrap",
																									fontSize
																								)}
																								style={{
																									minWidth: "83.72px",
																									maxWidth: "83.72px", //ADDED TO MATCH COL WIDTH
																								}}
																								onClick={() => {
																									// console.log("Hello");
																									// console.log(bindList.current.props.scrollToIndex);
																									// bindList.current.scrollToRow(6);
																									console.log(
																										document.getElementById(
																											"tableInfoList"
																										).clientHeight
																									);
																								}}
																							>
																								No
																							</th>

																							{taggedColumns &&
																								taggedColumns?.map(
																									(column, index) => {
																										return parseInt(
																											column.accessorKey
																										) === 0 ? ( // Date Column
																											<>
																												{column.visible && (
																													<th
																														key={`column-header-${index}`}
																														className={handleFontSize(
																															"px-0 py-0 text-center whitespace-nowrap",
																															fontSize
																														)}
																														style={{
																															minWidth:
																																"120px",
																															maxWidth:
																																"120px",
																														}}
																													>
																														Date Paid
																													</th>
																												)}
																											</>
																										) : parseInt(
																												column.accessorKey
																											) === 2 ? ( // Description Column
																											<>
																												{column.visible && (
																													<th className="flex">
																														<div
																															className={handleFontSize(
																																"px-2 py-4 text-center whitespace-nowrap",
																																fontSize
																															)}
																															style={{
																																width:
																																	"100%",
																																minWidth:
																																	"100px",
																																overflow:
																																	"hidden",
																																textOverflow:
																																	"ellipsis",
																																// backgroundColor:
																																//   commentAccess
																																//     ? "green"
																																//     : "transparent", // apply background color conditionally
																															}}
																														>
																															Description of Work
																														</div>
																													</th>
																												)}
																											</>
																										) : (
																											// Dynamic Columns
																											!defaultColumnIds.includes(
																												column.accessorKey
																											) && (
																												<>
																													{/* Dynamic Columns */}
																													{!defaultColumnIds.includes(
																														column.accessorKey
																													) && (
																														<>
																															{column.header === "Cost Amount" ? (
																																// Render something specific when the header matches
																																<>
																																	<th
																																		colSpan="2" // Set colspan based on the header
																																		className={handleFontSize(
																																			"px-2 py-4 text-center whitespace-nowrap w-full",
																																			fontSize
																																		)}
																																	>
																																		Cost
																																	</th>
																																</>
																															) : column.header === "Provisional Amount" ? (
																																<>
																																<th
																																	colSpan="1" // Set colspan based on the header
																																	className={handleFontSize(
																																		"px-2 py-4 text-center whitespace-nowrap w-full",
																																		fontSize
																																	)}
																																>
																																	{briefName === "Budget" ? "Budget Amount" : "Provisional Amount"}
																																</th>
																																</>
																															) : column.header === "Description of Work" ? (
																																// Render something specific when the header matches
																																<></>
																															) : column.header === "Paid to Date Amount" ? (
																																// Render something specific when the header matches
																																<>
																																	<th
																																		colSpan="2" // Set colspan based on the header
																																		className={handleFontSize(
																																			"px-2 py-4 text-center whitespace-nowrap w-full",
																																			fontSize
																																		)}
																																	>
																																		Paid To Date
																																	</th>
																																</>
																															) : column.header === "Action" ? (
																																// Render something specific when the header matches
																																<></>
																															) : (
																																// Render normally for other headers
																																<>
																																	{column.visible && (
																																		<th
																																			className={handleFontSize(
																																				"px-2 py-4 text-center whitespace-nowrap w-full",
																																				fontSize
																																			)}
																																		>
																																			{column.header === "Variation Cost" ? "Variation Cost ($)" : column.header}
																																		</th>
																																	)}
																																</>
																															)}
																														</>
																													)}
																												</>
																											)
																										);
																									}
																								)}
																							<th className="px-2 py-4 text-center whitespace-nowrap w-full"></th>
																						</tr>
																					</thead>
																				)}

																				<Draggable
																					key={item.id + "-" + index}
																					draggableId={
																						item.id + "-" + index
																					}
																					index={index}
																					isDragDisabled={
																						!allowDragNDrop
																					}
																				>
																					{(provider, snapshot) => (
																						<tr
																							className={
																								selectRow.find(
																									(x) =>
																										x.id === item.id
																								)
																									? "bg-green-300"
																									: index ===
																										highlightRow - 1
																									? "bg-green-300"
																									: ""
																							}
																							onMouseEnter={() =>
																								setHoveredRow(item.id)
																							}
																							onMouseLeave={() =>
																								setHoveredRow(null)
																							}
																							index={index}
																							key={`row-${item.id}`}
																							{...provider.draggableProps}
																							ref={provider.innerRef}
																							style={{
																								...provider
																									.draggableProps.style,
																								backgroundColor:
																									justChangedLabel
																										? "rgba(255, 255, 239, 0.01)"
																										: snapshot.isDragging ||
																											item.id ===
																												selected
																										? "rgba(255, 255, 239, 0.767)"
																										: "",
																								top: "auto !important",
																								borderBottom:
																									"1px solid #E4E7E7",
																								borderTop:
																									"1px solid #E4E7E7",
																								height:
																									200 + (item.files.items.length * 25) + "px"
																							}}
																						>
																							<td
																								{...provider.dragHandleProps}
																								className="w-8 px-1 py-3 align-top"
																								style={{
																									minWidth: "83.72px", //added MMA-1022
																								}}
																							>
																								<div
																									className={`flex items-center
																											${
																												hoveredRow ===
																													item.id ||
																												selectRow.find(
																													(x) =>
																														x.id ===
																														item.id
																												)
																													? ""
																													: "justify-center"
																											}`}
																								>
																									{hoveredRow ===
																										item.id ||
																									selectRow.find(
																										(x) =>
																											x.id === item.id
																									) ? (
																										<div
																											className={`flex items-center`}
																										>
																											{allowDragNDrop && (
																												<MdDragIndicator
																													className="item-no mr-0 text-2xl text-gray-500"
																													onClick={() =>
																														handleChangeBackground(
																															item.id
																														)
																													}
																												/>
																											)}

																											<input
																												className="m-1 cursor-pointer"
																												onChange={
																													(e) =>
																														handleSelectItem(
																															e,
																															index
																														) //Overlapping files
																												}
																												onClick={() =>
																													setIsActiveCell(
																														null
																													)
																												}
																												type="checkbox"
																												checked={selectedItems.includes(
																													item.id
																												)}
																												value={item.id}
																												id={`item-${item.id}`}
																											/>
																										</div>
																									) : (
																										<></>
																									)}
																									<label
																										htmlFor="checkbox-1"
																										className={handleFontSize(
																											"font-medium text-gray-900 dark:text-gray-300 ml-1",
																											fontSize
																										)}
																									>
																										{index + 1}
																										{/* &nbsp;&mdash;&nbsp; {item.order} */}
																									</label>
																								</div>
																							</td>

																							{taggedColumns &&
																								taggedColumns?.map(
																									(column, key) => {
																										return parseInt( column.accessorKey ) === 0 ? ( // Date Column
																											<>
																												{column.visible && (
																													<td
																														className="py-3 align-top"
																														{...provider.dragHandleProps}
																														style={{
																															width:
																																"120px",
																															minWidth:
																																"120px",
																															// border:
																															//   isActiveCell === "Date" &&
																															//   selectedCell === item.id
																															//     ? "1px solid #1CC1E9"
																															//     : "" ,
																															WebkitBoxShadow:
																																isActiveCell ===
																																	"Date" &&
																																selectedCell ===
																																	item.id
																																	? "inset 0px 0px 0px 1px #1CC1E9"
																																	: "",
																															MozBoxShadow:
																																isActiveCell ===
																																	"Date" &&
																																selectedCell ===
																																	item.id
																																	? "inset 0px 0px 0px 1px #1CC1E9"
																																	: "",
																															boxShadow:
																																isActiveCell ===
																																	"Date" &&
																																selectedCell ===
																																	item.id
																																	? "inset 0px 0px 0px 1px #1CC1E9"
																																	: "",
																														}}
																														onClick={() =>
																															handleCell(
																																"Date",
																																item.id
																															)
																														}
																														onBlur={() =>
																															setIsActiveCell(
																																null
																															)
																														}
																													>
																														<div className="custom-datepicker-container">
																															<DatePicker
																																timeZoneOffsetInMinutes
																																utcOffset={0}
																																className={handleFontSize(
																																	"border-none bg-transparent outline-none py-2 px-1 mb-5 z-50 text-sm text-center",
																																	fontSize
																																)}
																																selected={
																																	item.date !== null &&
																																	item.date !== "" &&
																																	item.date[0] !== "+"
																																	? timezoneFormatter(
																																		item?.date,
																																		localStorage.getItem("preferredTimezone") == "null" ? momentTZ.tz.guess() : localStorage.getItem("preferredTimezone")
																																	)
																																		: null
																																}
																																dateFormat={datePickerDateFormatter(
																																	localStorage.getItem("userpreferredDateFormatType") == "null" ? "MM-DD-YYYY" : localStorage.getItem("userpreferredDateFormatType")
																																)}
																																placeholderText="No Date"
																																onChange={(
																																	selected
																																) => {
																																	handleChangeDate(
																																		selected,
																																		item.id,
																																		item.date,
																																		index
																																	);
																																}}
																																disabled={
																																	!allowEditDate
																																}
																																style={{
																																	borderColor:
																																		"white",
																																}}
																															/>
																														</div>
																													</td>
																												)}
																											</>
																										)
																										// : parseInt(
																										// 		column.accessorKey
																										// 	) === 1 ? (
																										// 	// Gmail Column
																										// 	<>
																										// 		{column.visible && (
																										// 			<td
																										// 				className="py-3 align-top"
																										// 				{...provider.dragHandleProps}
																										// 				style={{
																										// 					//added MMA-1022
																										// 					width:
																										// 						"70px",
																										// 					minWidth:
																										// 						"70px",
																										// 					textAlign:
																										// 						"center",
																										// 					WebkitBoxShadow:
																										// 						isActiveCell ===
																										// 							"Gmail" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					MozBoxShadow:
																										// 						isActiveCell ===
																										// 							"Gmail" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					boxShadow:
																										// 						isActiveCell ===
																										// 							"Gmail" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 				}}
																										// 				onClick={() =>
																										// 					handleCell(
																										// 						"Gmail",
																										// 						item.id
																										// 					)
																										// 				}
																										// 				onBlur={() =>
																										// 					handleCell(
																										// 						null,
																										// 						null
																										// 					)
																										// 				}
																										// 			>
																										// 				<p className="text-center break-words">
																										// 					{gmailStatus !==
																										// 					""
																										// 						? gmailStatus
																										// 						: item
																										// 								?.files
																										// 								?.items
																										// 								?.length ===
																										// 							0
																										// 						? "N/A"
																										// 						: "Uploaded"}
																										// 				</p>
																										// 			</td>
																										// 		)}
																										// 	</>
																										// )
																										: parseInt(
																												column.accessorKey
																											) === 2 ? (
																											// Description Column
																											<>
																												{column.visible && (
																													<td
																														{...provider.dragHandleProps}
																														className={
																															commentAccess
																																? "px-2 py-3 align-top place-items-center" //added w-full removed w-4/7 MMA-1022 commit latest
																																: "px-2 py-3 align-top place-items-center" //added w-full removed w-4/7 MMA-1022 commit latest
																														}
																														style={{
																															minWidth:
																																"700px",
																															maxWidth:
																																getDescColWidth(), //700px
																															// border:
																															//   isActiveCell === "Description" &&
																															//   selectedCell === item.id
																															//     ? "1px solid #1CC1E9"
																															//     : ""
																															WebkitBoxShadow:
																																isActiveCell ===
																																	"Description" &&
																																selectedCell ===
																																	item.id
																																	? "inset 0px 0px 0px 1px #1CC1E9"
																																	: "",
																															MozBoxShadow:
																																isActiveCell ===
																																	"Description" &&
																																selectedCell ===
																																	item.id
																																	? "inset 0px 0px 0px 1px #1CC1E9"
																																	: "",
																															boxShadow:
																																isActiveCell ===
																																	"Description" &&
																																selectedCell ===
																																	item.id
																																	? "inset 0px 0px 0px 1px #1CC1E9"
																																	: "",
																														}}
																														onClick={() => {
																															handleCell(
																																"Description",
																																item.id
																															);
																														}}
																														onBlur={() =>
																															handleCell(
																																null,
																																null
																															)
																														}
																													>
																														{/* <RFIEditor item={item} /> */}
																														{
																															loaderEmail ===
																															item.id ? (
																																<>
																																	<SkeletonTheme>
																																		<Skeleton
																																			count={
																																				1
																																			}
																																		/>
																																	</SkeletonTheme>
																																	<SkeletonTheme
																																		width={
																																			"150px"
																																		}
																																	>
																																		<Skeleton
																																			count={
																																				1
																																			}
																																			className="mt-1"
																																		/>
																																	</SkeletonTheme>
																																	<SkeletonTheme
																																		height={
																																			"10px"
																																		}
																																	>
																																		<Skeleton
																																			className="text-xs"
																																			count={
																																				1
																																			}
																																		/>
																																	</SkeletonTheme>
																																	<SkeletonTheme
																																		width={
																																			"150px"
																																		}
																																	>
																																		<Skeleton
																																			count={
																																				1
																																			}
																																			className="mt-1"
																																		/>
																																	</SkeletonTheme>
																																	<SkeletonTheme
																																		height={
																																			"38px"
																																		}
																																	>
																																		<Skeleton
																																			count={
																																				1
																																			}
																																		/>
																																	</SkeletonTheme>
																																</>
																															) : item &&
																																saveDescription &&
																																uploadedDocumentList ? (

																																<>
																																	<SlateMention
																																		initialContent={
																																			item?.description
																																		}
																																		handleSaveDesc={
																																			handleSaveDesc
																																		}
																																		handleChangeDesc={
																																			handleChangeDesc
																																		}
																																		handleDescContent={
																																			handleDescContent
																																		}
																																		setSaving={
																																			setSaving
																																		}
																																		description={
																																			item?.description
																																		}
																																		date={
																																			item?.date
																																		}
																																		id={
																																			item?.id
																																		}
																																		itemIndex={
																																			index
																																		}
																																		background={
																																			background
																																		}
																																		reducedTabsArray={
																																			reducedTabsArray
																																		}
																																		reduceUploadedFilesToArray={
																																			reduceUploadedFilesToArray
																																		}
																																		reducedUploadedFilesArray={
																																			reducedUploadedFilesArray
																																		}
																																		extractText={
																																			extractText
																																		}
																																		handleSaveTabElement={
																																			handleSaveTabElement
																																		}
																																	/>
																																</>
																															) : null
																															// (item && saveDescription && uploadedDocumentList) ?
																															//   <EditableBackgroundField item={item} save={saveDescription} uploadedDocumentList={uploadedDocumentList}/>
																															// : null
																														}
																													</td>
																												)}
																											</>
																										)
																										// : parseInt(
																										// 		column.accessorKey
																										// 	) === 3 ? (
																										// 	// Comments Column
																										// 	<>
																										// 		{column.visible && (
																										// 			<td
																										// 				{...provider.dragHandleProps}
																										// 				className={
																										// 					commentAccess
																										// 						? "px-2 py-3 align-top place-items-center"
																										// 						: "hidden px-2 py-3 align-top place-items-center"
																										// 				}
																										// 				style={{
																										// 					// backgroundColor: "green",
																										// 					width:
																										// 						"270px",
																										// 					minWidth:
																										// 						"270px",
																										// 					// border:
																										// 					//   isActiveCell === "Comment" &&
																										// 					//   selectedCell === item.id
																										// 					//     ? "1px solid #1CC1E9"
																										// 					//     : ""
																										// 					WebkitBoxShadow:
																										// 						isActiveCell ===
																										// 							"Comment" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					MozBoxShadow:
																										// 						isActiveCell ===
																										// 							"Comment" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					boxShadow:
																										// 						isActiveCell ===
																										// 							"Comment" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 				}}
																										// 				onClick={() =>
																										// 					handleCell(
																										// 						"Comment",
																										// 						item.id
																										// 					)
																										// 				}
																										// 				onBlur={() =>
																										// 					handleCell(
																										// 						null,
																										// 						null
																										// 					)
																										// 				}
																										// 			>
																										// 				<div
																										// 					className={handleFontSize(
																										// 						"p-2 w-full font-poppins rounded-sm",
																										// 						fontSize
																										// 					)}
																										// 					style={{
																										// 						cursor:
																										// 							"auto",
																										// 						outline:
																										// 							"none",
																										// 						// outlineColor:
																										// 						//  "rgb(204, 204, 204, 0.5)",
																										// 						// outlineWidth:
																										// 						//  "thin",
																										// 						minWidth:
																										// 							"100px",
																										// 						width:
																										// 							"100%",
																										// 					}}
																										// 					suppressContentEditableWarning
																										// 					onClick={() =>
																										// 						autoAdjustRowHeight(
																										// 							index
																										// 						)
																										// 					}
																										// 					dangerouslySetInnerHTML={{
																										// 						__html:
																										// 							handleComment(
																										// 								item.comments
																										// 							),
																										// 					}}
																										// 					onInput={() =>
																										// 						autoAdjustRowHeight(
																										// 							index
																										// 						)
																										// 					}
																										// 					onBlur={(
																										// 						e
																										// 					) =>
																										// 						handleSaveComment(
																										// 							e,
																										// 							item.comments,
																										// 							item.id,
																										// 							index
																										// 						)
																										// 					}
																										// 					contentEditable={
																										// 						allowEditComments
																										// 					}
																										// 				></div>
																										// 			</td>
																										// 		)}
																										// 	</>
																										// )
																										// : parseInt(
																										// 		column.accessorKey
																										// 	) === 4 ? (
																										// 	// Document Column
																										// 	<>
																										// 		{column.visible && (
																										// 			<td
																										// 				{...provider.dragHandleProps}
																										// 				className=" w-64 px-3 py-2 text-sm text-gray-500 align-top whitespace-nowrap" //added w-full and removed w-1/6 MMA-1022
																										// 				style={{
																										// 					// border:
																										// 					//   isActiveCell === "Document" &&
																										// 					//   selectedCell === item.id
																										// 					//     ? "1px solid #1CC1E9"
																										// 					//     : ""
																										// 					WebkitBoxShadow:
																										// 						isActiveCell ===
																										// 							"Document" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					MozBoxShadow:
																										// 						isActiveCell ===
																										// 							"Document" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					boxShadow:
																										// 						isActiveCell ===
																										// 							"Document" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 				}}
																										// 				onClick={() =>
																										// 					handleCell(
																										// 						"Document",
																										// 						item.id
																										// 					)
																										// 				}
																										// 				onFocus={() =>
																										// 					handleCell(
																										// 						"Document",
																										// 						item.id
																										// 					)
																										// 				}
																										// 				onBlur={() =>
																										// 					handleCell(
																										// 						null,
																										// 						null
																										// 					)
																										// 				}
																										// 			>
																										// 				{selectRow.find(
																										// 					(x) =>
																										// 						x.id ===
																										// 						item.id
																										// 				) && (
																										// 					<div className="separator">
																										// 						ROW
																										// 						SELECTED
																										// 					</div>
																										// 				)}
																										// 				{!activateButton ? (
																										// 					!activateButton &&
																										// 					selectRow.find(
																										// 						(x) =>
																										// 							x.id ===
																										// 							item.id
																										// 					) ? (
																										// 						<button></button>
																										// 					) : (
																										// 						<>
																										// 							<BackgroundIconContainer
																										// 								fileTypeCollection={
																										// 									fileTypeCollection
																										// 								}
																										// 								key={
																										// 									item.id
																										// 								}
																										// 								item={
																										// 									item
																										// 								}
																										// 								draggableType={
																										// 									item.id +
																										// 									"-" +
																										// 									index
																										// 								}
																										// 								columnIndex={
																										// 									index
																										// 								}
																										// 								chronologicalOrder={
																										// 									chronologicalOrder
																										// 								}
																										// 								iframeVariables={{
																										// 									index,
																										// 									show,
																										// 									snippetId,
																										// 									downloadLink,
																										// 									iframeLoad,
																										// 									setIframeLoad,
																										// 									previewAndDownloadPDF,
																										// 									previewAndDownloadFile,
																										// 									handleOpenAI,
																										// 									hoverClosePreviewFile,
																										// 									subject,
																										// 									from,
																										// 									moment,
																										// 									dateEmail,
																										// 									cc,
																										// 									preview,
																										// 									to,
																										// 									hoverEnterPreviewFile,
																										// 									hoverClosePreviewFile,
																										// 								}}
																										// 								fileBucketIds={{
																										// 									matterId:
																										// 										matterId,
																										// 									briefId:
																										// 										briefId,
																										// 									utf8_to_b64:
																										// 										utf8_to_b64,
																										// 									matter_name:
																										// 										matter_name,
																										// 									utf8_to_b64:
																										// 										utf8_to_b64,
																										// 									client_name:
																										// 										client_name,
																										// 									handleUploadLink,
																										// 									setSelectedRowID,
																										// 									setSelectedIndex,
																										// 								}}
																										// 								attachFiles={
																										// 									attachFiles
																										// 								}
																										// 								optionsFunctionVariables={{
																										// 									showModal,
																										// 									allowDeleteFiles,
																										// 									activateButton,
																										// 								}}
																										// 								showRightPanel={
																										// 									showRightPanel
																										// 								}
																										// 								setShowRightPanel={
																										// 									setShowRightPanel
																										// 								}
																										// 								rightPanelItems={
																										// 									rightPanelItems
																										// 								}
																										// 								setRightPanelItems={
																										// 									setRightPanelItems
																										// 								}
																										// 								fileView={
																										// 									fileView
																										// 								}
																										// 								background={
																										// 									background
																										// 								}
																										// 							/>
																										// 						</>
																										// 					)
																										// 				) : (
																										// 					<ManageFiles
																										// 						item={
																										// 							item
																										// 						}
																										// 						index={
																										// 							index
																										// 						}
																										// 						checkedRows={
																										// 							checkedRows
																										// 						}
																										// 						selectedRowsBGFiles={
																										// 							selectedRowsBGFiles
																										// 						}
																										// 						rowOriginal={
																										// 							rowOriginal
																										// 						}
																										// 						rowClicked={
																										// 							rowClicked
																										// 						}
																										// 						handleFilesCheckboxChange={
																										// 							handleFilesCheckboxChange
																										// 						}
																										// 						pasteFilestoBackground={
																										// 							pasteFilestoBackground
																										// 						}
																										// 					/>
																										// 				)}
																										// 				{item?.files
																										// 					?.items ===
																										// 					null ||
																										// 				item?.files
																										// 					?.items
																										// 					.length ===
																										// 					0 ? (
																										// 					<>
																										// 						{/** Add something here */}
																										// 					</>
																										// 				) : (
																										// 					<></>
																										// 				)}
																										// 			</td>
																										// 		)}
																										// 	</>
																										// ) : column.header ===
																										// 	"Label" ? (
																										// 	// Label Column
																										// 	<>
																										// 		{column.visible && (
																										// 			<td
																										// 				id={
																										// 					"label-cell-no-" +
																										// 					index
																										// 				}
																										// 				{...provider.dragHandleProps}
																										// 				className={handleFontSize(
																										// 					"px-2 py-2 text-center whitespace-nowrap w-full align-top",
																										// 					fontSize
																										// 				)}
																										// 				style={{
																										// 					// border:
																										// 					// isActiveCell === "Label" &&
																										// 					// selectedCell === item.id
																										// 					//   ? "1px solid #1CC1E9"
																										// 					//   : ""
																										// 					WebkitBoxShadow:
																										// 						isActiveCell ===
																										// 							"Label" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					MozBoxShadow:
																										// 						isActiveCell ===
																										// 							"Label" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 					boxShadow:
																										// 						isActiveCell ===
																										// 							"Label" &&
																										// 						selectedCell ===
																										// 							item.id
																										// 							? "inset 0px 0px 0px 1px #1CC1E9"
																										// 							: "",
																										// 				}}
																										// 				onMouseEnter={() => {
																										// 					currLabelCellSelected2.current =
																										// 						"label-cell-no-" +
																										// 						index;
																										// 				}}
																										// 				onMouseLeave={() => {
																										// 					currLabelCellSelected2.current =
																										// 						"out";
																										// 				}}
																										// 				onClick={() => {
																										// 					handleCell(
																										// 						"Label",
																										// 						item.id
																										// 					);

																										// 					setTimeout(
																										// 						() => {
																										// 							let cell =
																										// 								document.getElementById(
																										// 									"label-cell-no-" +
																										// 										index
																										// 								);
																										// 							let cellHeight =
																										// 								cell.offsetHeight;
																										// 							console.log(
																										// 								"cellHeight",
																										// 								cellHeight
																										// 							);
																										// 							try {
																										// 								let popup =
																										// 									document.getElementById(
																										// 										"label-popup-no-" +
																										// 											index
																										// 									);

																										// 								if (
																										// 									document.getElementById(
																										// 										"label-cell-interior-no-" +
																										// 											index
																										// 									)
																										// 										?.style
																										// 										.height <
																										// 									50
																										// 								) {
																										// 									document.getElementById(
																										// 										"label-cell-interior-no-" +
																										// 											index
																										// 									).style.height =
																										// 										cellHeight -
																										// 										24 +
																										// 										"px";
																										// 								}
																										// 								//popup.style.display = "flex";
																										// 								if (
																										// 									cellHeight >
																										// 									100
																										// 								) {
																										// 									popup.style.marginTop =
																										// 										(cellHeight -
																										// 											70) *
																										// 											-1 +
																										// 										"px";
																										// 								} else {
																										// 									popup.style.marginTop =
																										// 										"14px";
																										// 								}
																										// 								// let origClassList = Array.from(document.getElementById("label-cell-no-"+index)?.classList);

																										// 								// if (currLabelCellSelected.current == "label-cell-no-"+index) {
																										// 								//   if (origClassList.includes("selectedLabel")) {
																										// 								//     if (currLabelCellSelected2.current == "out" || currLabelCellSelected2.current != currLabelCellSelected.current) {
																										// 								//       document.getElementById(currLabelCellSelected.current).classList.remove("selectedLabel");
																										// 								//     }
																										// 								//   } else {
																										// 								//     document.getElementById(currLabelCellSelected.current).classList.add("selectedLabel");
																										// 								//   }
																										// 								// } else {
																										// 								//   if (currLabelCellSelected.current != 0) {
																										// 								//     document.getElementById(currLabelCellSelected.current).classList.remove("selectedLabel");
																										// 								//   }
																										// 								//   document.getElementById("label-cell-no-"+index).classList.add("selectedLabel");
																										// 								// }

																										// 								currLabelCellSelected.current =
																										// 									"label-cell-no-" +
																										// 									index;

																										// 								console.log(
																										// 									"CLICKED LABEL CELL"
																										// 								);
																										// 								//blockScroll();
																										// 								//console.log(document.getElementById("creatableSelect-"+index).lastChild.firstChild.lastChild);
																										// 								document
																										// 									.getElementById(
																										// 										"creatableSelect-" +
																										// 											index
																										// 									)
																										// 									.lastChild.firstChild.lastChild.firstChild.focus();
																										// 							} catch (error) {}
																										// 						},
																										// 						5
																										// 					);
																										// 				}}
																										// 				onBlur={() =>
																										// 					setIsActiveCell(
																										// 						null
																										// 					)
																										// 				}
																										// 			>
																										// 				{selectRow.find(
																										// 					(x) =>
																										// 						x.id ===
																										// 						item.id
																										// 				) ? (
																										// 					<div>
																										// 						ㅤ
																										// 					</div>
																										// 				) : (
																										// 					<>
																										// 						<div
																										// 							id={
																										// 								"label-cell-interior-no-" +
																										// 								index
																										// 							}
																										// 							className="w-60"
																										// 						>
																										// 							<div
																										// 								id={
																										// 									"label-cell-selected-no-" +
																										// 									index
																										// 								}
																										// 								className="pt-4 text-xs flex flex-wrap " //justify-center
																										// 							>
																										// 								{
																										// 									item.briefs ? (
																										// 										item
																										// 											.briefs
																										// 											.length !=
																										// 										0 ? (
																										// 											item
																										// 												.briefs
																										// 												.length <=
																										// 											3 ? (
																										// 												item.briefs.map(
																										// 													(
																										// 														elem,
																										// 														index
																										// 													) => {
																										// 														return (
																										// 															<span
																										// 																className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
																										// 																style={{
																										// 																	gap: "8px",
																										// 																	padding:
																										// 																		"4px 8px",
																										// 																	backgroundColor:
																										// 																		"#F3F3F3",
																										// 																	borderRadius:
																										// 																		"5px",
																										// 																}}
																										// 															>
																										// 																{
																										// 																	getRowLabels(
																										// 																		item.briefs
																										// 																	)[
																										// 																		index
																										// 																	]
																										// 																		.label
																										// 																}
																										// 															</span>
																										// 														);
																										// 													}
																										// 												)
																										// 											) : (
																										// 												<>
																										// 													{firstThreeLabels.map(
																										// 														(
																										// 															elem,
																										// 															index
																										// 														) => {
																										// 															return (
																										// 																<span
																										// 																	className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
																										// 																	style={{
																										// 																		gap: "8px",
																										// 																		padding:
																										// 																			"4px 8px",
																										// 																		backgroundColor:
																										// 																			"#F3F3F3",
																										// 																		borderRadius:
																										// 																			"5px",
																										// 																	}}
																										// 																>
																										// 																	{
																										// 																		getRowLabels(
																										// 																			firstThreeLabels
																										// 																		)[
																										// 																			index
																										// 																		]
																										// 																			.label
																										// 																	}
																										// 																</span>
																										// 															);
																										// 														}
																										// 													)}
																										// 													<span
																										// 														className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
																										// 														style={{
																										// 															gap: "8px",
																										// 															padding:
																										// 																"4px 8px",
																										// 															backgroundColor:
																										// 																"#F3F3F3",
																										// 															borderRadius:
																										// 																"5px",
																										// 														}}
																										// 														data-for={
																										// 															"extra-labels" +
																										// 															index
																										// 														}
																										// 														data-tip={
																										// 															itemBriefsHTML
																										// 														}
																										// 													>
																										// 														+
																										// 														{item
																										// 															.briefs
																										// 															.length -
																										// 															3}
																										// 													</span>
																										// 													<ReactTooltip
																										// 														id={
																										// 															"extra-labels" +
																										// 															index
																										// 														}
																										// 														html={
																										// 															true
																										// 														}
																										// 													/>
																										// 												</>
																										// 											)
																										// 										) : (
																										// 											<span>
																										// 												ㅤ
																										// 											</span>
																										// 										) // No labels
																										// 									) : (
																										// 										<span>
																										// 											ㅤ
																										// 										</span>
																										// 									) // No labels
																										// 								}
																										// 							</div>
																										// 						</div>
																										// 						{/* //// */}
																										// 						{isActiveCell ===
																										// 							"Label" &&
																										// 							selectedCell ===
																										// 								item.id && (
																										// 								<div
																										// 									id={
																										// 										"label-popup-no-" +
																										// 										index
																										// 									}
																										// 									className="bg-white -ml-6 w-72	label-popup flex flex-col p-1 border rounded-sm absolute justify-center flex-wrap	"
																										// 									style={{
																										// 										//marginLeft: "-48px",
																										// 										//marginTop: "14px",
																										// 										zIndex: 200,
																										// 										//marginTop: document.getElementById("actualTable").offsetHeight - style.top < 250 ? "-430px" : "",
																										// 										//height:  document.getElementById("actualTable").offsetHeight - style.top < 250 ? "430px" : "",
																										// 										//backgroundColor: document.getElementById("actualTable").offsetHeight - style.top < 250 ? "transparent" : "white",
																										// 										borderColor:
																										// 											document.getElementById(
																										// 												"actualTable"
																										// 											)
																										// 												.offsetHeight -
																										// 												style.top <
																										// 											250
																										// 												? "rgb(255, 255, 255)"
																										// 												: "rgb(209, 213, 219)",
																										// 									}}
																										// 								>
																										// 									{!activateButton ? (
																										// 										!activateButton &&
																										// 										selectRow.find(
																										// 											(
																										// 												x
																										// 											) =>
																										// 												x.id ===
																										// 												item.id
																										// 										) ? (
																										// 											<button></button>
																										// 										) : (
																										// 											<>
																										// 												{allowShowLabels && (
																										// 													<>
																										// 														<div
																										// 															className=""
																										// 															style={{
																										// 																marginTop:
																										// 																	document.getElementById(
																										// 																		"actualTable"
																										// 																	)
																										// 																		.offsetHeight -
																										// 																		style.top <
																										// 																	250
																										// 																		? "-430px"
																										// 																		: "",
																										// 															}}
																										// 															onKeyUp={() => {
																										// 																handleChangeLabel();
																										// 															}}
																										// 														>
																										// 															<CreatableSelect
																										// 																id={
																										// 																	"creatableSelect-" +
																										// 																	index
																										// 																}
																										// 																menuPortalTarget={
																										// 																	document.body
																										// 																}
																										// 																ref={
																										// 																	creatableSelectRef
																										// 																}
																										// 																//openMenuOnFocus
																										// 																menuIsOpen={
																										// 																	true
																										// 																}
																										// 																defaultValue={() =>
																										// 																	getRowLabels(
																										// 																		item.briefs
																										// 																			? item.briefs
																										// 																			: null
																										// 																	)
																										// 																}
																										// 																styles={{
																										// 																	container:
																										// 																		(
																										// 																			base
																										// 																		) => ({
																										// 																			...base,
																										// 																			zIndex:
																										// 																				"45",
																										// 																		}),
																										// 																	control:
																										// 																		(
																										// 																			base
																										// 																		) =>
																										// 																			//selectStyles(base),
																										// 																			({
																										// 																				border:
																										// 																					"none",
																										// 																				outline:
																										// 																					"none",
																										// 																				boxShadow:
																										// 																					"none",
																										// 																				borderLeft:
																										// 																					document.getElementById(
																										// 																						"actualTable"
																										// 																					)
																										// 																						.offsetHeight -
																										// 																						style.top <
																										// 																					250
																										// 																						? "1px solid rgb(209 213 219)"
																										// 																						: "",
																										// 																				borderRight:
																										// 																					document.getElementById(
																										// 																						"actualTable"
																										// 																					)
																										// 																						.offsetHeight -
																										// 																						style.top <
																										// 																					250
																										// 																						? "1px solid rgb(209 213 219)"
																										// 																						: "",
																										// 																				borderTop:
																										// 																					document.getElementById(
																										// 																						"actualTable"
																										// 																					)
																										// 																						.offsetHeight -
																										// 																						style.top <
																										// 																					250
																										// 																						? "1px solid rgb(209 213 219)"
																										// 																						: "",
																										// 																			}),
																										// 																	valueContainer:
																										// 																		(
																										// 																			base
																										// 																		) =>
																										// 																			//selectStyles(base)
																										// 																			({
																										// 																				...base,
																										// 																				flex: "none",
																										// 																				border:
																										// 																					"none",
																										// 																				outline:
																										// 																					"none",
																										// 																				boxShadow:
																										// 																					"none",
																										// 																				paddingLeft:
																										// 																					"1px",
																										// 																			}),
																										// 																	indicatorsContainer:
																										// 																		() => ({
																										// 																			display:
																										// 																				"none",
																										// 																		}),
																										// 																	menu: (
																										// 																		base
																										// 																	) => ({
																										// 																		...base,
																										// 																		marginTop:
																										// 																			"30px",
																										// 																		width:
																										// 																			document.getElementById(
																										// 																				"actualTable"
																										// 																			)
																										// 																				.offsetHeight -
																										// 																				style.top <
																										// 																			250
																										// 																				? "17.350rem"
																										// 																				: "18rem",
																										// 																		marginLeft:
																										// 																			document.getElementById(
																										// 																				"actualTable"
																										// 																			)
																										// 																				.offsetHeight -
																										// 																				style.top <
																										// 																			250
																										// 																				? "0.086rem"
																										// 																				: "-0.313rem",
																										// 																		border:
																										// 																			"none",
																										// 																		outline:
																										// 																			"none",
																										// 																		boxShadow:
																										// 																			"none",
																										// 																		borderLeft:
																										// 																			"1px solid rgb(209 213 219)",
																										// 																		borderRight:
																										// 																			"1px solid rgb(209 213 219)",
																										// 																		borderBottom:
																										// 																			"1px solid rgb(209 213 219)",
																										// 																		borderRadius:
																										// 																			"2px",
																										// 																		// display:
																										// 																		//   document.getElementById("label-popup-no-"+index).style.display == "none" ||
																										// 																		//   document.getElementById("label-popup-no-"+index).style.display.length == 0  ? "none" : "block"
																										// 																	}),
																										// 																	menuList:
																										// 																		(
																										// 																			base
																										// 																		) => ({
																										// 																			...base,
																										// 																			paddingBottom:
																										// 																				"0px",
																										// 																		}),
																										// 																	option:
																										// 																		(
																										// 																			base
																										// 																		) => ({
																										// 																			...base,
																										// 																			borderBottom:
																										// 																				"1px solid rgb(229 231 235)",
																										// 																		}),
																										// 																}}
																										// 																components={{
																										// 																	MenuList:
																										// 																		MyMenuList,
																										// 																	Option:
																										// 																		MyOption,
																										// 																	NoOptionsMessage:
																										// 																		MyNoOptionsMessage,
																										// 																}}
																										// 																filterOption={createFilter(
																										// 																	filterConfig
																										// 																)}
																										// 																maxMenuHeight={
																										// 																	222
																										// 																}
																										// 																options={
																										// 																	labels
																										// 																		? labels.filter(
																										// 																				(
																										// 																					elem
																										// 																				) => {
																										// 																					//console.log(elem);
																										// 																					//console.log(":", item.briefs);
																										// 																					//console.log(index,"Label:",elem.label,itemBriefsArr.indexOf(elem.label) > -1);
																										// 																					return (
																										// 																						item.briefs
																										// 																							?.map(
																										// 																								(
																										// 																									item
																										// 																								) =>
																										// 																									item.name
																										// 																							)
																										// 																							.indexOf(
																										// 																								elem.label
																										// 																							) <
																										// 																						0
																										// 																					);
																										// 																				}
																										// 																			)
																										// 																		: []
																										// 																}
																										// 																isClearable={
																										// 																	false
																										// 																}
																										// 																isSearchable
																										// 																openMenuOnClick={
																										// 																	true
																										// 																}
																										// 																isMulti
																										// 																onChange={(
																										// 																	e,
																										// 																	action
																										// 																) => {
																										// 																	console.log(
																										// 																		e
																										// 																	);
																										// 																	if (
																										// 																		e.length >
																										// 																		0
																										// 																	) {
																										// 																		item.briefs =
																										// 																			e.map(
																										// 																				(
																										// 																					sel
																										// 																				) => {
																										// 																					return {
																										// 																						id: sel.value,
																										// 																						name: sel.label,
																										// 																					};
																										// 																				}
																										// 																			);
																										// 																	} else {
																										// 																		item.briefs =
																										// 																			[];
																										// 																	}

																										// 																	tagLabel(
																										// 																		e,
																										// 																		action,
																										// 																		item.id,
																										// 																		item,
																										// 																		item.briefs
																										// 																	);
																										// 																	handleChangeLabel();
																										// 																}}
																										// 																onClick={(
																										// 																	e
																										// 																) => {
																										// 																	alert(
																										// 																		e
																										// 																	);
																										// 																}}
																										// 																isDisabled={
																										// 																	!allowEditLabels
																										// 																}
																										// 																placeholder=""
																										// 																className=" creatableSelect w-full text-sm bg-white outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring z-45"
																										// 															/>
																										// 															<div
																										// 																className="bg-white"
																										// 																style={{
																										// 																	borderLeft:
																										// 																		document.getElementById(
																										// 																			"actualTable"
																										// 																		)
																										// 																			.offsetHeight -
																										// 																			style.top <
																										// 																		250
																										// 																			? "1px solid rgb(209 213 219)"
																										// 																			: "",
																										// 																	borderRight:
																										// 																		document.getElementById(
																										// 																			"actualTable"
																										// 																		)
																										// 																			.offsetHeight -
																										// 																			style.top <
																										// 																		250
																										// 																			? "1px solid rgb(209 213 219)"
																										// 																			: "",
																										// 																}}
																										// 															>
																										// 																<p className="text-left text-xs text-gray-400 py-2 pl-3">
																										// 																	{!popupInputNode?.value
																										// 																		? "SELECT AN OPTION OR CREATE ONE"
																										// 																		: popupInputNode
																										// 																				?.value
																										// 																				?.length <
																										// 																			1
																										// 																		? "SELECT AN OPTION OR CREATE ONE"
																										// 																		: "RESULTS"}
																										// 																</p>
																										// 															</div>
																										// 														</div>
																										// 													</>
																										// 												)}
																										// 											</>
																										// 										)
																										// 									) : (
																										// 										<span></span>
																										// 									)}
																										// 								</div>
																										// 							)}
																										// 						{/* //// */}
																										// 					</>
																										// 				)}
																										// 			</td>
																										// 		)}
																										// 	</>
																										// )
																										: column.header ===
																											"Email Date" ? ( // Email Date Column
																											//MMA-1307: Add Email Date and Time Sent Column
																											<>
																												{column.visible && (
																													<td
																														className="py-3 align-top text-center"
																														{...provider.dragHandleProps}
																														style={{
																															width:
																																"120px",
																															minWidth:
																																"120px",
																														}}
																													>
																														{item?.files
																															?.items ===
																															null ||
																														item?.files
																															?.items
																															.length ===
																															0 ? (
																															<>
																																<div className="w-full">
																																	<p className="w-full">
																																		N/A{" "}
																																	</p>
																																</div>
																															</>
																														) : (
																															<>
																																{/* insert function here */}

																																{handleEmailDate(
																																	item
																																)}
																															</>
																														)}
																													</td>
																												)}
																											</>
																										) : (
																											// Dynamic Columns
																											<>
																												{column.visible && (
																												<td
																													className={handleFontSize(
																													"px-2 py-2 text-center whitespace-nowrap w-full align-top",
																													fontSize
																													)}
																												>
																													{column.header === "Cost Amount" ? (
																														<CustomColumn
																															columnType={column.type}
																															accessorKey={column.accessorKey}
																															setInputDynamic={setInputDynamic}
																															row={item}
																															updateDynamicData={updateDynamicData}
																															id={item.id}
																															value={
																															dynamicData && dynamicData[column.accessorKey] !== undefined
																																? dynamicData[column.accessorKey]
																																: null
																															}
																															people={people}
																														/>
																													) : null}
																												</td>
																												)}

																											</>
																										);
																									}
																								)}
																							<td
																								{...provider.dragHandleProps}
																							>
																								<div className="p-2 flex">
																									<button
																										onClick={() => {
																											clearFocus();
																											handleAddRow(
																												item
																											);
																										}}
																									>
																										<HiPlus
																											data-tip="Add row"
																											style={{
																												margin: "5px",
																												fontSize:
																													"1.5rem",
																												color:
																													"#A0AEC0",
																											}}
																										/>
																									</button>
																									{background?.length !== 1 && (
																										<button
																											onClick={() => showDeleteRowModal(item)}
																										>
																											<HiMinus
																												data-tip="Delete row"
																												style={{
																													margin: "5px",
																													fontSize: "1.5rem",
																													color: "#A0AEC0",
																												}}
																											/>
																											<ReactTooltip place="left" />
																										</button>
																									)}
																								</div>
																							</td>
																						</tr>
																					)}
																				</Draggable>
																				{pasteButton &&
																					selectRow.length > 0 && (
																						<tr
																							style={{
																								border:
																									"rgb(0, 204, 0) 2px dashed",
																								cursor: "pointer",
																							}}
																							onClick={() =>
																								handlePasteRow(
																									index,
																									"paste"
																								)
																							}
																							className="hover:bg-green-500 hover:text-white"
																						>
																							<td></td>
																							<td></td>
																							<td className="text-center">
																								<p>PASTE HERE</p>
																							</td>
																							<td></td>
																						</tr>
																					)}

																				{moveButton &&
																					selectRow.length > 0 && (
																						<tr
																							style={{
																								border:
																									"rgb(0, 204, 0) 2px dashed",
																								cursor: "pointer",
																							}}
																							onClick={() =>
																								handleMoveRow(index)
																							}
																							className="hover:bg-green-500 hover:text-white"
																						>
																							<td></td>
																							<td></td>
																							<td className="text-center">
																								<p>MOVE HERE</p>
																							</td>
																							<td></td>
																						</tr>
																					)}
																			</div>
																	);


																})
															}
														</tbody>
													</table>

												)}
											</Droppable>
										</DragDropContext>
									) : (
										<div className="left-0 p-5 px-5 py-1">
											<div className="grid w-full px-1 py-1 mb-6 h-42 justify-items-center">
												<NoResultState
													searchKey={searchDescription}
													message={
														"Check the spelling, try a more general term or look up a specific File."
													}
												/>
											</div>
										</div>
									)}
								</div>
							</>
							) : (
								<>
									<EmptyRow search={search} />
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{showUploadModal && (
				<UploadLinkModal
					title={""}
					handleSave={handleUploadLink}
					bucketName={matterId}
					handleModalClose={handleModalClose}
				/>
			)}
			{ShowModalParagraph && (
				<ModalParagraph
					setShowModalParagraph={setShowModalParagraph}
					getBackground={getBackground}
					paragraph={paragraph}
					setParagraph={setParagraph}
					setCheckedState={setCheckedState}
					background={backgroundDuplicate}
					setSelectedRowsBG={setSelectedRowsBG}
					setShowDeleteButton={setShowDeleteButton}
					API={API}
					matterId={matterId}
					setcheckAllState={setcheckAllState}
					briefId={briefId}
					briefName={briefName}
					bindList={bindList}
					cache={cache}
					setBackground={setBackground}
					setBgLoading={setBgLoading}
					setWait={setWait}
				/>
			)}
			{showRemoveFileModal && (
				<RemoveModal
					handleSave={handleDelete}
					handleModalClose={handleModalClose}
					selectedRowsBG={selectedFileBG}
				/>
			)}

			{showRemoveRowModal && (
				<RemoveFileModal
					handleSave={handleDeleteRow}
					handleModalClose={handleModalClose}
					selectedRowsBG={deleteRow}
				/>
			)}

			{showToast && (
				<div
					onClick={
						goToFileBucket
							? () =>
									(window.location = `${
										AppRoutes.FILEBUCKET
									}/${matterId}/000/?matter_name=${utf8_to_b64(
										matter_name
									)}&client_name=${utf8_to_b64(client_name)}`)
							: holdPaste
							? () => undoAction()
							: holdDeleteFile
							? () => undoDeleteFile()
							: holdDeleteRow
							? () => undoDeleteRow()
							: null
					}
				>
					<ToastNotification title={alertMessage} hideToast={hideToast} />
				</div>
			)}

			{showRightPanel && (
				<RightPanel
					showRightPanel={showRightPanel}
					setShowRightPanel={setShowRightPanel}
					rightPanelItems={rightPanelItems}
					setRightPanelItems={setRightPanelItems}
					handleOpenAI={handleOpenAI}
					previewAndDownloadFile={previewAndDownloadFile}
					fileTypeCollection={fileTypeCollection}
				/>
			)}
		</>
	);
};

// A box which displays the files as icons
function BackgroundIconContainer({
	fileTypeCollection,
	item,
	draggableType,
	columnIndex,
	chronologicalOrder,
	iframeVariables,
	fileBucketIds,
	attachFiles,
	optionsFunctionVariables,
	showRightPanel,
	setShowRightPanel,
	rightPanelItems,
	setRightPanelItems,
	fileView,
	background,
}) {
	const [openFilesPopup, setOpenFilesPopup] = useState(false);
	const iconContainerRef = useRef(null);
	const [uploadBtnClicked2, setUploadBtnClicked2] = useState(false);
	const popupRef = useRef(null);

	const fileFormatIcons = {
		default: <BsFillFileEarmarkFill className="text-blue-400" />,
		txt: <BsFillFileEarmarkFontFill />,
		pdf: <BsFileEarmarkPdfFill className="text-red-600" />,
		docx: <BsFillFileEarmarkWordFill className="text-blue-500" />,
		"vnd.openxmlformats-officedocument.wordprocessingml.document": (
			<BsFillFileEarmarkWordFill className="text-blue-500" />
		),
		xlsx: <BsFillFileEarmarkExcelFill className="text-green-500" />,
		"vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
			<BsFillFileEarmarkExcelFill className="text-green-500" />
		),
		pptx: <BsFillFileEarmarkPptFill className="text-orange-600" />,
		"vnd.openxmlformats-officedocument.presentationml.presentation": (
			<BsFillFileEarmarkPptFill className="text-orange-600" />
		),
		csv: <FaFileCsv className="text-green-400" />,
		png: <BsFillFileEarmarkImageFill className="text-blue-700" />,
		jpg: <BsFillFileEarmarkImageFill className="text-orange-700" />,
		jpeg: <BsFillFileEarmarkImageFill className="text-orange-700" />,
		mp4: <BsFillFileEarmarkPlayFill className="text-blue-400" />,
		mov: <BsFillFileEarmarkPlayFill className="text-blue-400" />,
		mp3: <BsFillFileEarmarkMusicFill />,
	};

	const fileType = (items) =>
		fileTypeCollection
			?.filter((fileItem) => fileItem.id === items.id)[0]
			?.type?.split("/")[1];

	const optionsRef = useRef(null);

	useEffect(() => {
		function isDescendant(parent, child) {
			let node = child;
			while (node !== null) {
				if (node === parent) {
					return true;
				}
				node = node.parentNode;
			}
			return false;
		}

		function handleClickOutside(event) {
			const isOptionsClick =
				optionsRef?.current && optionsRef?.current?.contains(event.target);
			const isInsidePopup =
				popupRef?.current && popupRef?.current.contains(event.target);
			const isInsideIconContainer = isDescendant(
				iconContainerRef?.current,
				event.target
			);
			if (!isOptionsClick && !isInsidePopup && !isInsideIconContainer) {
				setOpenFilesPopup(false);
			}
		}
		//document.addEventListener("mousedown", handleClickOutside);
		return () => {
			//document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [popupRef, optionsRef]);

	const {
		matterId,
		briefId,
		utf8_to_b64,
		matter_name,
		client_name,
		handleUploadLink,
		setSelectedRowID,
		setSelectedIndex,
	} = fileBucketIds;

	const uploadBtnRef = useRef(null);

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const { files } = e.dataTransfer;

		if (files && files.length) {
			console.log("Files uploaded are " + files);
			setOpenFilesPopup(true);
			setDirectDrop(files);
		}
	};
	const [directDrop, setDirectDrop] = useState([]);
	return (
		<div className="relative">
			{fileView === 1 ? (
				//List View
				<div
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					className="w-56 flex flex-col"
				>
					<div className="flex-1">
						<BackgroundFileList
							key={item.id}
							item={item}
							draggableType={draggableType}
							chronologicalOrder={chronologicalOrder}
							iframeVariables={iframeVariables}
							openFilesPopup={openFilesPopup}
							setOpenFilesPopup={setOpenFilesPopup}
							iconContainerRef={iconContainerRef}
							fileBucketIds={fileBucketIds}
							optionsFunctionVariables={optionsFunctionVariables}
							showRightPanel={showRightPanel}
							setShowRightPanel={setShowRightPanel}
							rightPanelItems={rightPanelItems}
							setRightPanelItems={setRightPanelItems}
							optionsRef={optionsRef}
							fileView={fileView}
						/>
					</div>
					<button
						ref={iconContainerRef}
						className="h-8 bg-cyan-400 rounded-md my-2 text-white"
						onClick={() => {
							setOpenFilesPopup(!openFilesPopup);
						}}
					>
						Upload
					</button>
					<span className="text-xs text-gray-400">
						{" "}
						or <b>Drag & Drop</b> to Upload
					</span>
				</div>
			) : (
				//Icons View
				<button
					ref={iconContainerRef}
					className="mt-3 "
					style={{ minWidth: `12rem` }}
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onClick={() => setOpenFilesPopup(!openFilesPopup)}
				>
					<div
						className={`p-3 flex border-2 ${
							openFilesPopup
								? "border-cyan-300"
								: "border-transparent hover:border-gray-200"
						}`}
						style={{
							overflow: "auto",
						}}
					>
						<ReactTooltip
							id="attachName"
							type="dark"
							place="top"
							effect="solid"
						/>
						<div className=" flex-1 ml-5 flex justify-center gap-3">
							{chronologicalOrder(item?.files?.items).map((items, index) =>
								items && items?.length !== 0 && index < 4 ? (
									<span key={index} data-tip={items.name} data-for="attachName">
										{
											//console.log(fileTypeCollection?.filter((ftc)=>ftc.id===items.id)[0]?.type?.split("/")[1])
										}
										{
											//File Icon Implementation

											fileFormatIcons[
												Object.keys(fileFormatIcons).filter((key) =>
													key.includes(fileType(items))
												).length
													? fileTypeCollection
															?.filter((ftc) => ftc.id === items.id)[0]
															?.type?.split("/")[1]
													: "default"
											]
										}
									</span>
								) : null
							)}
						</div>

						<div className=" w-7">
							{chronologicalOrder(item?.files?.items).length > 4 && (
								<span className="">
									+{chronologicalOrder(item?.files?.items).length - 4}
								</span>
							)}
						</div>
					</div>
					<span className="text-xs text-gray-400">
						{" "}
						<b>Drag & Drop</b> to Upload
					</span>
				</button>
			)}

			{openFilesPopup ? (
				<BackgroundFilePopup
					key={item.id + "_1"}
					item={item}
					draggableType={draggableType}
					columnIndex={columnIndex}
					chronologicalOrder={chronologicalOrder}
					iframeVariables={iframeVariables}
					openFilesPopup={openFilesPopup}
					setUploadBtnClicked2={setUploadBtnClicked2}
					setOpenFilesPopup={setOpenFilesPopup}
					iconContainerRef={iconContainerRef}
					fileBucketIds={fileBucketIds}
					attachFiles={attachFiles}
					optionsFunctionVariables={optionsFunctionVariables}
					showRightPanel={showRightPanel}
					setShowRightPanel={setShowRightPanel}
					rightPanelItems={rightPanelItems}
					setRightPanelItems={setRightPanelItems}
					fileView={fileView}
					background={background}
					directDrop={directDrop}
					setDirectDrop={setDirectDrop}
				/>
			) : null}
		</div>
	);
}

//Drop down for the upload button in List View
function BackgroundListUpload({
	popupRef,
	background,
	iframeVariables,
	fileBucketIds,
	item,
	columnIndex,
	attachFiles,
	setUploadBtnClicked2,
	setOpenFilesPopup,
	uploadBtnRef,
}) {
	const {
		matterId,
		briefId,
		utf8_to_b64,
		matter_name,
		client_name,
		handleUploadLink,
		setSelectedRowID,
		setSelectedIndex,
	} = fileBucketIds;

	useEffect(() => {
		function handleClickOutside(event) {
			const isUploadClick =
				uploadBtnRef?.current && uploadBtnRef?.current?.contains(event.target);
			const isInsidePopup =
				popupRef?.current && popupRef?.current.contains(event.target);
			if (!isUploadClick && !isInsidePopup) {
				setUploadBtnClicked2(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [popupRef, uploadBtnRef]);

	return (
		<div
			ref={popupRef}
			className={
				"absolute bg-white h-96 overflow-y-scroll p-3 w-80 flex flex-col gap-2 border rounded-md shadow-md z-50 cursor-default"
			}
			style={
				(background?.length === iframeVariables.index + 1 ||
					background?.length - 1 === iframeVariables.index + 1)
					? { transform: `translate(-30%, -120%)` }
					: { transform: `translate(-30%, 0%)` }
			}
		>
			<p className=" p-1 border-b-2 border-gray-200 text-xs font-semibold text-gray-300">
				UPLOAD DIRECTLY OR &nbsp;
				<button
					className=" font-semibold text-cyan-400 "
					onClick={() =>
						(window.location = `${
							AppRoutes.FILEBUCKET
						}/${matterId}/${briefId}/?matter_name=${utf8_to_b64(
							matter_name
						)}&client_name=${utf8_to_b64(client_name)}&background_id=${
							item.id
						}`)
					}
				>
					UPLOAD FROM FILE BUCKET
				</button>
			</p>
			<BackgroundPopupFileUpload
				handleUploadLink={handleUploadLink}
				matterId={matterId}
				item={item}
				columnIndex={columnIndex}
				attachFiles={attachFiles}
				setUploadBtnClicked2={setUploadBtnClicked2}
				setOpenFilesPopup={setOpenFilesPopup}
				setSelectedRowID={setSelectedRowID}
				setSelectedIndex={setSelectedIndex}
			/>
		</div>
	);
}

// Dropdown popup that appears when clicking on the file icons container
function BackgroundFilePopup({
	item,
	draggableType,
	columnIndex,
	chronologicalOrder,
	iframeVariables,
	openFilesPopup,
	setUploadBtnClicked2,
	setOpenFilesPopup,
	iconContainerRef,
	fileBucketIds,
	attachFiles,
	optionsFunctionVariables,
	showRightPanel,
	setShowRightPanel,
	rightPanelItems,
	setRightPanelItems,
	fileView,
	background,
	directDrop,
	setDirectDrop,
}) {
	const {
		matterId,
		briefId,
		utf8_to_b64,
		matter_name,
		client_name,
		handleUploadLink,
		setSelectedRowID,
		setSelectedIndex,
	} = fileBucketIds;

	const { hoverClosePreviewFile } = iframeVariables;

	const popupRef = useRef(null);
	const optionsRef = useRef(null);
	const [uploadBtnClicked, setUploadBtnClicked] = useState(false);

	useEffect(() => {
		function isDescendant(parent, child) {
			let node = child;
			while (node !== null) {
				if (node === parent) {
					return true;
				}
				node = node.parentNode;
			}
			return false;
		}

		function handleClickOutside(event) {
			const isOptionsClick =
				optionsRef?.current && optionsRef?.current?.contains(event.target);
			const isInsidePopup =
				popupRef?.current && popupRef?.current.contains(event.target);
			const isInsideIconContainer = isDescendant(
				iconContainerRef?.current,
				event.target
			);
			if (!isOptionsClick && !isInsidePopup && !isInsideIconContainer) {
				hoverClosePreviewFile();
				setOpenFilesPopup(false);
				setDirectDrop([]);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [popupRef, optionsRef]);

	const getItemStyle = (isDragging, draggableStyle) => ({
		background: isDragging ? "lightgreen" : "",
		...draggableStyle,
	});
	//MMA-2265: Returned Drag and Drop for File Upload
	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const { files } = e.dataTransfer;

		if (files && files.length) {
			console.log("Files uploaded are " + files);
			setUploadBtnClicked(true);
			setDropTrigger(files);
		}
	};

	const [dropTrigger, setDropTrigger] = useState([]);
	//end of MMA-2265: Returned Drag and Drop for File Upload
	return (
		<div
			ref={popupRef}
			className={"absolute bg-white h-auto p-3 w-80 flex flex-col gap-2 border rounded-md shadow-md z-50 cursor-default"}
			style={
				(background?.length === iframeVariables.index + 1 && background?.length > 2 ) ||
				(background?.length - 1 === iframeVariables.index + 1 && background?.length > 3)
					? { transform: `translate(-30%, -120%)` }
					: { transform: `translate(-30%, 0%)` }
			}
			//onClick={()=>{console.log("bgl",background?.length);console.log("bgl2",iframeVariables.index+1);}}
		>
			<p className=" p-1 border-b-2 border-gray-200 text-xs font-semibold text-gray-300">
				UPLOAD DIRECTLY OR &nbsp;
				<button
					className=" font-semibold text-cyan-400 "
					onClick={() =>
						(window.location = `${
							AppRoutes.FILEBUCKET
						}/${matterId}/${briefId}/?matter_name=${utf8_to_b64(
							matter_name
						)}&client_name=${utf8_to_b64(client_name)}&background_id=${
							item.id
						}`)
					}
				>
					UPLOAD FROM FILE BUCKET
				</button>
			</p>

			{uploadBtnClicked || fileView || directDrop.length > 0 ? (
				<BackgroundPopupFileUpload
					handleUploadLink={handleUploadLink}
					matterId={matterId}
					item={item}
					columnIndex={columnIndex}
					attachFiles={attachFiles}
					setUploadBtnClicked2={setUploadBtnClicked2}
					setOpenFilesPopup={setOpenFilesPopup}
					setSelectedRowID={setSelectedRowID}
					setSelectedIndex={setSelectedIndex}
					dropTrigger={dropTrigger}
					setDirectDrop={setDirectDrop}
					directDrop={directDrop}
				/>
			) : (
				<>
					<div className="flex-1 z-50">
						<BackgroundFileList
							key={item.id}
							item={item}
							draggableType={draggableType}
							chronologicalOrder={chronologicalOrder}
							iframeVariables={iframeVariables}
							openFilesPopup={openFilesPopup}
							setOpenFilesPopup={setOpenFilesPopup}
							iconContainerRef={iconContainerRef}
							optionsRef={optionsRef}
							fileBucketIds={fileBucketIds}
							optionsFunctionVariables={optionsFunctionVariables}
							showRightPanel={showRightPanel}
							setShowRightPanel={setShowRightPanel}
							rightPanelItems={rightPanelItems}
							setRightPanelItems={setRightPanelItems}
							fileView={fileView}
						/>
					</div>
					{/* MMA-2265 Drag and Drop File Upload */}
					<div
						ref={drop}
						onDrop={handleDrop}
						onDragOver={handleDragOver}
						className="justify-center items-center h-36 mx-auto border p-4 border-dashed rounded-md border-cyan-400"
					>
						<BiCloudUpload className="mx-auto" size={70} />
						<p className="mx-auto text-sm text-center  text-cyan-400 font-semibold">
							DRAG & DROP TO UPLOAD
						</p>
						{/* <input type="file" value=""/> */}
					</div>
					<button
						className=" w-min text-cyan-400"
						onClick={() => setUploadBtnClicked(true)}
					>
						+ Upload File
					</button>
				</>
			)}
		</div>
	);
}

function BackgroundPopupFileUpload({
	handleUploadLink,
	matterId,
	item,
	columnIndex,
	attachFiles,
	setUploadBtnClicked2,
	setOpenFilesPopup,
	setSelectedRowID,
	setSelectedIndex,
	dropTrigger,
	setDirectDrop,
	directDrop,
}) {
	const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; //list of rejected files

	const hiddenFileInput = useRef(null);
	const [selectedFiles, _setSelectedFiles] = useState([]);
	const [invalidFiles, setInvalidFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
	const [uploadStart, setUploadStart] = useState(false);

	const [percent, setPercent] = useState([]);

	const myCurrentRef = useRef(selectedFiles);
	const setSelectedFiles = (data) => {
		myCurrentRef.current = data;
		_setSelectedFiles(data);
	};

	useEffect(() => {
		var counter = 0;
		var dCounter = 0;
		if (dropTrigger.length > 0 && counter === 0) {
			onSelectFile(dropTrigger);
			counter = 1;
		}

		if (directDrop.length > 0 && dCounter === 0) {
			onSelectFile(directDrop);
			dCounter = 1;
		}
	}, []);
	const onSelectFile = (retrievedFiles) => {
		console.log("You are here " + retrievedFiles);
		if (!retrievedFiles || retrievedFiles.length === 0) {
			return;
		}
		const tempArr = [];

		[...retrievedFiles].forEach((file) => {
			var re = /(?:\.([^.]+))?$/;
			var ext = re.exec(file.name)[0];

			const result = rejectFiles.find((item) =>
				item.includes(re.exec(file.name)[0])
			);
			const fileSize = file.size;

			if (result || fileSize > 2147483648) {
				invalidFiles.push({
					data: file,
					url: URL.createObjectURL(file),
				});
			} else {
				tempArr.push({
					data: file,
					url: URL.createObjectURL(file),
				});
			}
		});

		setSelectedFiles([...myCurrentRef.current, ...tempArr]);
		console.log(selectedFiles);
	};

	var temp = [];

	const drop = useRef(null);
	//MMA-2265: Returned Drag and Drop for File Upload
	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const { files } = e.dataTransfer;

		if (files && files.length) {
			console.log("Files uploaded are " + files);
			onSelectFile(files);
		}
	};
	//end of MMA-2265
	const handleUpload = async (bucketName) => {
		setUploadStart(true);

		var tempArr = [];
		selectedFiles.map((uf) => {
			tempArr = [...tempArr, uf];
		});
		setSelectedFiles(tempArr);
		_setSelectedFiles(tempArr);
		var idxx = 0;
		tempArr.map(async (uf, index) => {
			if (uf.data.name.split(".").pop() == "docx") {
				var name = uf.data.name,
					size = uf.data.size,
					type =
						"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
					key = `${bucketName}/${Number(new Date())}${name
						?.replaceAll(/\s/g, "")
						.replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
					orderSelected = idxx,
					order = idxx;
			} else {
				var name = uf.data.name,
					size = uf.data.size,
					type = uf.data.type,
					key = `${bucketName}/${Number(new Date())}${name
						?.replaceAll(/\s/g, "")
						.replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
					orderSelected = idxx,
					order = idxx;
			}
			idxx = idxx + 1;

			try {
				await Storage.put(key, uf.data, {
					contentType: type,
					progressCallback(progress) {
						const progressInPercentage = Math.round(
							(progress.loaded / progress.total) * 100
						);
						//console.log(`Progress: ${progressInPercentage}%, ${uf.data.name}`);

						if (temp.length > selectedFiles.length) {
							for (var i = 0; i < selectedFiles.length; i++) {
								console.log(uf.data.name === temp[i].name);
								if (temp[i].name === uf.data.name) {
									temp[i].prog = progressInPercentage;
								}
							}
						} else {
							temp = [
								...temp,
								{ prog: progressInPercentage, name: uf.data.name },
							];
						}
						//console.log(temp);
						setPercent(temp);
					},
					errorCallback: (err) => {
						console.error("204: Unexpected error while uploading", err);
					},
				})
					.then((fd) => {
						var fileData = {
							s3ObjectKey: fd.key,
							size: parseInt(size),
							type: type,
							name: name.split(".").slice(0, -1).join("."),
							oderSelected: orderSelected,
							order: orderSelected,
						};

						setUploadedFiles((prevState) => ({
							files: [...prevState.files, fileData],
						}));
						setDirectDrop([]);
					})
					.catch((err) => {
						console.error("220: Unexpected error while uploading", err);
					});
			} catch (e) {
				const response = {
					error: e.message,
					errorStack: e.stack,
					statusCode: 500,
				};
				console.error("228: Unexpected error while uploading", response);
			}
		});
	};

	function handleClick(event) {
		hiddenFileInput.current.click();
	}

	function clickPopupUploadButton() {
		handleUpload(matterId);
		setSelectedRowID(item.id);
		setSelectedIndex(columnIndex);
	}

	useEffect(() => {
		const uploadAndCheck = async () => {
			if (
				uploadedFiles.files.length === selectedFiles.length &&
				selectedFiles.length !== 0
			) {
				const result = await handleUploadLink(uploadedFiles);
				if (result === "done") {
					setOpenFilesPopup(false);
					setUploadBtnClicked2(false);
				}
			}
		};

		uploadAndCheck();
	}, [selectedFiles, uploadedFiles]);

	return (
		<>
			<div className="max-h-40 overflow-y-scroll">
				{selectedFiles.map((file, index) => {
					return (
						<div key={index} className="flex justify-between items-center">
							<p className=" pr-4 w-full truncate">{file.data.name}</p>
							<CircularProgressbar
								value={percent[index] ? parseInt(percent[index].prog) : 0}
								text={
									percent[index] ? `${parseInt(percent[index].prog)}%` : "0%"
								}
								className="w-7 h-7"
							/>
						</div>
					);
				})}
			</div>

			<input
				onChange={(e) => onSelectFile(e.target.files)}
				ref={hiddenFileInput}
				type="file"
				multiple="multiple"
				hidden
			/>

			<div
				ref={drop}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				className="justify-center items-center h-36 mx-auto border p-4 border-dashed rounded-md border-cyan-400"
			>
				<BiCloudUpload className="mx-auto" size={70} />
				<p className="mx-auto text-sm text-center  text-cyan-400 font-semibold">
					DRAG & DROP TO UPLOAD
				</p>
				{/* <input type="file" value=""/> */}
			</div>

			<div className="flex gap-2">
				<button
					disabled={uploadStart}
					className={`p-2 flex-1 rounded-md text-white ${
						uploadStart
							? "bg-cyan-600 cursor-not-allowed"
							: "bg-cyan-400 hover:bg-cyan-300"
					} `}
					onClick={(e) => handleClick(e)}
				>
					Choose a file
				</button>
				{selectedFiles.length !== 0 && (
					<button
						disabled={uploadStart}
						className={`p-2 flex-1 rounded-md text-white ${
							uploadStart
								? "bg-cyan-600 cursor-wait"
								: "bg-cyan-400 hover:bg-cyan-300"
						} `}
						onClick={() => clickPopupUploadButton()}
					>
						Upload
					</button>
				)}
			</div>
		</>
	);
}

function BackgroundFileList({
	item,
	draggableType,
	chronologicalOrder,
	iframeVariables,
	openFilesPopup,
	setOpenFilesPopup,
	iconContainerRef,
	optionsRef,
	fileBucketIds,
	optionsFunctionVariables,
	showRightPanel,
	setShowRightPanel,
	rightPanelItems,
	setRightPanelItems,
	fileView,
}) {
	let idx = iframeVariables.index;

	return (
		<>
			{chronologicalOrder(item?.files?.items).map((items, index) => {
				const originalString = items?.name || ""; // Handle possible null or undefined
				const truncatedString = originalString.slice(0, 30);

				return (
					<div
						key={index}
						className="flex z-50 justify-between p-1 border-b-2 border-gray-100 border-dashed"
					>
						<p
							className=" w-full truncate"
							onClick={() => {
								setShowRightPanel(true);
								setRightPanelItems([item, idx, index, items.gmailMessageId]);
							}}
						>
							{truncatedString}
						</p>

						<BackgroundFilePopupOptions
							parentItem={item}
							items={items}
							optionsRef={optionsRef}
							optionsFunctionVariables={optionsFunctionVariables}
							iframeVariables={iframeVariables}
							fileView={fileView}
						/>

						<FilePreview
							item={item}
							items={items}
							iframeVariables={iframeVariables}
						/>
					</div>
				);
			})}
		</>
	);
}

function BackgroundFilePopupOptions({
	parentItem,
	items,
	optionsRef,
	optionsFunctionVariables,
	iframeVariables,
	fileView,
}) {
	const { show, hoverEnterPreviewFile, previewAndDownloadFile } =
		iframeVariables;

	const { showModal, allowDeleteFiles, activateButton } =
		optionsFunctionVariables;

	const qGetFileDownloadLink = `
    query getFileDownloadLink($id: ID) {
      file(id: $id) {
        downloadURL
        s3ObjectKey
        type
      }
    }`;

	function openUrlInIframe(url) {
		const newTab = window.open("", "_blank");
		if (newTab) {
			const iframe = document.createElement("iframe");
			iframe.src = url;
			iframe.style.width = "100%";
			iframe.style.height = "100%";
			iframe.style.border = "none";

			newTab.document.body.appendChild(iframe);
		} else {
			console.error("Failed to open new tab with iframe.");
		}
	}

	// Option Functions
	async function viewFile(items) {
		const params = {
			query: qGetFileDownloadLink,
			variables: {
				id: items.id,
			},
		};

		await API.graphql(params).then(async (result) => {
			const { type, downloadURL, s3ObjectKey } = result.data.file;

			if (
				(type &&
					(type.includes("vnd.openxmlformats-officedocument") ||
						type.includes("application/msword"))) ||
				isMSdoc(s3ObjectKey)
			) {
				let encodedUrl = encodeURIComponent(downloadURL);
				let documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
				window.open(documentViewerUrl);
			} else {
				openUrlInIframe(downloadURL);
			}
		});
	}

	function previewFile(item, items) {
		hoverEnterPreviewFile(
			items.id,
			item.id,
			items.isGmailPDF,
			items.gmailMessageId ? items.gmailMessageId : null
		);
	}

	async function downloadFile(items) {
		const params = {
			query: qGetFileDownloadLink,
			variables: {
				id: items.id,
			},
		};

		await API.graphql(params).then(async (result) => {
			const { downloadURL } = result.data.file;
			// console.log(downloadURL)
			// const regexPattern = /%20(\w+)\.(\w+)%3B/;
			// const match = downloadURL.match(regexPattern);
			// const filename = match[1] + '.' + match[2];
			// console.log(filename);

			// const url = window.URL.createObjectURL(
			//   new Blob([downloadURL]),
			// );

			// const link = document.createElement('a');
			// link.href = downloadURL;
			// link.setAttribute('download', downloadURL);

			window.open(downloadURL);
			//document.body.appendChild(link);

			//link.click();

			//document.body.removeChild(link);
		});
	}

	function deleteFile(item, items) {
		if (allowDeleteFiles && !activateButton) {
			showModal(items.id, item.id, items?.name);
		}
	}

	// Rendering of Options
	const [openOptionsPopup, setOpenOptionsPopup] = useState(false);

	const buttonLabels = [
		{
			name: "View",
			onclick: () => previewFile(parentItem, items),
			classname: "",
		},
		{
			name: "Preview on New Tab",
			onclick: () => viewFile(items),
			classname: "",
		},
		{ name: "Download", onclick: () => downloadFile(items), classname: "" },
		{
			name: "Delete",
			onclick: () => deleteFile(parentItem, items),
			classname: "text-red-500",
		},
	];

	useEffect(() => {
		function handleClickOutside(event) {
			if (optionsRef.current && !optionsRef.current.contains(event.target)) {
				setOpenOptionsPopup(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [optionsRef]);

	const conditionalClassNameFileView = fileView === 0 ? "w-1/2" : "w-1/4";

	return (
		<div className="relative inline-block ">
			<button
				className="p-1 rounded-full hover:bg-gray-200"
				onClick={() => setOpenOptionsPopup(!openOptionsPopup)}
			>
				<BsThreeDots className="text-gray-400" />
			</button>

			{openOptionsPopup && (
				<div
					ref={optionsRef}
					className={`absolute right-full p-2 bg-white rounded-md border shadow-lg z-50`}
					style={{
						minWidth: "150px", // Set a minimum width for the options popup
						paddingTop: "8px", // Add some padding at the top
					}}
				>
					{buttonLabels.map((item, index) => (
						<button
							key={index}
							className={`block w-full p-1 text-left border-b border-dashed ${item.classname} hover:bg-gray-200`}
							onClick={item.onclick}
						>
							{item.name}
						</button>
					))}
				</div>
			)}
		</div>
	);
}

function FilePreview({ item, items, iframeVariables }) {
	const {
		index,
		show,
		snippetId,
		downloadLink,
		iframeLoad,
		setIframeLoad,
		previewAndDownloadPDF,
		previewAndDownloadFile,
		handleOpenAI,
		hoverClosePreviewFile,
		subject,
		from,
		moment,
		dateEmail,
		cc,
		to,
		preview,
	} = iframeVariables;
	return (
		show &&
		snippetId === items.id + "-" + item.id && (
			<div
				className=" fixed right-0 z-50 max-w-4xl max-h-screen p-6 overflow-auto bg-white rounded shadow-2xl"
				style={{
					width: `35rem`,
					maxHeight: "450px",
					minHeight: "450px",
					//left: "48%",
					marginLeft: "0px",
				}}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex items-center justify-between">
					<div className="order-first">
						{items?.gmailMessageId && <img src={googleLogin} alt="" />}
					</div>
					<div className="space-x-4">
						{items.s3ObjectKey === null &&
						items.isGmailPDF &&
						items.isGmailAttachment ? (
							<button
								onClick={(e) =>
									previewAndDownloadPDF(
										items.id,
										items.gmailMessageId ? items.gmailMessageId : null,
										items.name
									)
								}
								className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
							>
								{`Download Email PDF`}
							</button>
						) : (
							<button
								onClick={(e) => previewAndDownloadFile(items.id)}
								className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
							>
								{`Preview on new tab`}
							</button>
						)}

						{items?.gmailMessageId && (
							<button
								className="px-4 py-2 text-xs font-bold text-white bg-green-500 rounded hover:bg-green-700"
								onClick={() => handleOpenAI(item.id, index, item.description)}
							>
								{`Summarize Emails`}
							</button>
						)}

						<button
							className="inline-flex px-4 py-2 text-xs font-bold text-white bg-red-500 rounded hover:bg-red-700"
							type="button"
							aria-expanded="false"
							onClick={hoverClosePreviewFile}
						>
							Close
							<MdClose
								size={11}
								style={{
									marginLeft: "5px",
									marginTop: "3px",
								}}
							/>
						</button>
					</div>
				</div>

				<div
					className="w-full h-full mt-2"
					style={{
						minHeight: "350px",
					}}
				>
					{items.isGmailPDF ? (
						<>
							<div id={"preview_" + items.gmailMessageId}>
								{subject !== null ? (
									<>
										<h2>
											<b>{item.subject}</b>
										</h2>
										<hr />
										<br />
										<p>{`From: ${from}`}</p>
										<p>
											{`Date: ${moment(dateEmail).format(
												"DD MMM YYYY, hh:mm A"
											)}`}
										</p>
										<p>{`Subject: ${subject}`}</p>
										<p>{`To: ${to}`}</p>
										{cc && <p>{`CC: ${cc}`}</p>}
										<p
											className="p-2 mt-8"
											dangerouslySetInnerHTML={{
												__html: preview.html,
											}}
										></p>
									</>
								) : (
									<>
										<SkeletonTheme width={"300px"}>
											<p>
												<Skeleton count={4} />
											</p>
										</SkeletonTheme>
										<p className="p-2 mt-8">
											<Skeleton count={15} />
										</p>
									</>
								)}
							</div>
						</>
					) : (
						<>
							{iframeLoad && (
								<SkeletonTheme height={"300px"} width={"100%"}>
									<p>
										<Skeleton count={1} />
									</p>
								</SkeletonTheme>
							)}
							<iframe
								title={items.name}
								src={downloadLink}
								style={{
									minHeight: "350px",
								}}
								className="w-full "
								onLoad={() => setIframeLoad(false)}
							></iframe>
						</>
					)}
				</div>
			</div>
		)
	);
}

function ManageFiles({
	item,
	index,
	checkedRows,
	selectedRowsBGFiles,
	rowOriginal,
	rowClicked,
	handleFilesCheckboxChange,
	pasteFilestoBackground,
}) {
	const [openDropdown, setOpenDropdown] = useState(false);
	const manageButtonRef = useRef(null);

	return (
		<>
			<span className="relative" ref={manageButtonRef}>
				{/* <ReactTooltip
      id="buttonPaste"
      type="dark"
      place="bottom"
      effect="float"
        />  */}
				<button
					className={
						checkedRows.length === 0 ||
						rowOriginal(item.id) ||
						rowClicked(item.id)
							? "w-60 bg-gray-300 border border-gray-500 text-gray-500 rounded-md py-2 px-4 mr-3 flex items-center justify-center  font-bold "
							: "w-60 bg-green-400 border border-transparent rounded-md py-2 px-4 mr-3 flex items-center justify-center  font-bold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					}
					onClick={
						rowOriginal(item.id) || checkedRows.length === 0
							? () => setOpenDropdown(!openDropdown)
							: () => {
									pasteFilestoBackground(item.id, index);
							  }
					}
					data-tip={"Cannot Paste Files here"}
					data-for="buttonPaste"
				>
					{" "}
					{checkedRows.length === 0
						? "Select a file"
						: rowOriginal(item.id)
						? "Copied"
						: rowClicked(item.id)
						? "Pasted"
						: "Paste"}
					&nbsp;
					<FaPaste />
				</button>
				{openDropdown ? (
					<ManageFilesDropdown
						setOpenDropdown={setOpenDropdown}
						manageButtonRef={manageButtonRef}
						item={item}
						index={index}
						selectedRowsBGFiles={selectedRowsBGFiles}
						handleFilesCheckboxChange={handleFilesCheckboxChange}
					/>
				) : null}
			</span>
		</>
	);
}

function ManageFilesDropdown({
	setOpenDropdown,
	manageButtonRef,
	item,
	index,
	selectedRowsBGFiles,
	handleFilesCheckboxChange,
}) {
	const dropdownRef = useRef(null);

	useEffect(() => {
		console.log("get me");
		function handleClickOutside(event) {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target) &&
				manageButtonRef.current &&
				!manageButtonRef.current.contains(event.target)
			) {
				setOpenDropdown(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	return (
		<div
			ref={dropdownRef}
			className="absolute flex flex-col z-50 w-full h-48 m-auto p-5 rounded-md bg-white shadow-md"
		>
			<p className="mb-2 py-1 border-b">Select Files to be Copied</p>

			<div className="w-full h-auto flex-1 flex flex-col gap-1 overflow-y-scroll">
				{item?.files?.items?.map((fileItem, index) => {
					return (
						<span key={index} className="flex gap-3 items-center">
							<input
								type="checkbox"
								onChange={(event) => {
									handleFilesCheckboxChange(
										event,
										fileItem.id + item.id,
										fileItem.id,
										item.id,
										fileItem?.name
									);
								}}
								checked={selectedRowsBGFiles.some(
									(item) => item.files === fileItem.id
								)}
							/>
							<p className=" truncate">{fileItem.name}</p>
						</span>
					);
				})}
			</div>
		</div>
	);
}

export default TableInfoBreakdown;
