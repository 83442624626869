import momentTZ from "moment-timezone";

/***************************************************************************/
/* Converts date and complete time to user's preferred timezone and format */
/* Example: 2023-24-12 12:00:00 AM                                         */
/***************************************************************************/

export function dateTimeFormatter(inputDate) {
  // Inject user's preferred timezone first before converting
  const convertedTimezone = timezoneFormatter(
    inputDate,
    localStorage.getItem("preferredTimezone") === "null" ? momentTZ.tz.guess() : localStorage.getItem("preferredTimezone")
  );

  // Check if inputDate is a valid date
  const parsedDate = new Date(convertedTimezone);

  if (isNaN(parsedDate.getTime())) {
    return "Invalid date or time";
  }

  // Get date components
  const hours = parsedDate.toLocaleString().split(", ")[1];
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[parsedDate.getMonth()];

  // Get user's preferred date format
  const desiredFormat =
    localStorage.getItem("userpreferredDateFormatType") === "null" ? "MM-DD-YYYY" : localStorage.getItem("userpreferredDateFormatType");

  let formattedDate;

  switch (desiredFormat) {
    case "MM-DD-YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate} ${hours}`;
    case "MM/DD/YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate} ${hours}`;
    case "MMM DD YYYY":
      formattedDate = desiredFormat
        .replace("MMM", monthName)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate} ${hours}`;
    case "DD-MM-YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate} ${hours}`;
    case "DD/MM/YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate} ${hours}`;
    case "DD MMM YYYY":
      formattedDate = desiredFormat
        .replace("MMM", monthName)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate} ${hours}`;
    default:
      return "Invalid date format";
  }
}

/************************************************************************************/
/* Converts date and time (without seconds) to user's preferred timezone and format */
/* Example: 2023-24-12 12:00 AM                                                     */
/************************************************************************************/

export function dateTimeNoSecondsFormatter(inputDate) {
  // Inject user's preferred timezone first before converting
  const convertedTimezone = timezoneFormatter(
    inputDate,
    localStorage.getItem("preferredTimezone") === "null" ? momentTZ.tz.guess() : localStorage.getItem("preferredTimezone")
  );

  // Check if inputDate is a valid date
  const parsedDate = new Date(convertedTimezone);

  if (isNaN(parsedDate.getTime())) {
    return "Invalid date or time";
  }

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Use 12-hour clock with AM/PM
  };

  // Get date components
  const hours = parsedDate.toLocaleTimeString(undefined, timeOptions);
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[parsedDate.getMonth()];

  // Get user's preferred date format
  const desiredFormat =
    localStorage.getItem("userpreferredDateFormatType") === "null" ? "MM-DD-YYYY" : localStorage.getItem("userpreferredDateFormatType");

  let formattedDate;

  switch (desiredFormat) {
    case "MM-DD-YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate}, ${hours}`;
    case "MM/DD/YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate}, ${hours}`;
    case "MMM DD YYYY":
      formattedDate = desiredFormat
        .replace("MMM", monthName)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate}, ${hours}`;
    case "DD-MM-YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate}, ${hours}`;
    case "DD/MM/YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate}, ${hours}`;
    case "DD MMM YYYY":
      formattedDate = desiredFormat
        .replace("MMM", monthName)
        .replace("DD", day)
        .replace("YYYY", year);
      return `${formattedDate}, ${hours}`;
    default:
      return "Invalid date format";
  }
}

/**************************************************************/
/* Converts date ONLY to user's preferred timezone and format */
/* Example: 2023-24-12                                        */
/**************************************************************/

export function dateFormatter(inputDate) {
  // Inject user's preferred timezone first before converting
  const convertedTimezone = timezoneFormatter(
    inputDate,
    localStorage.getItem("preferredTimezone") === "null" ? momentTZ.tz.guess() : localStorage.getItem("preferredTimezone")
  );

  // Check if inputDate is a valid date
  const parsedDate = new Date(convertedTimezone);

  if (isNaN(parsedDate.getTime())) {
    return "Invalid date or time";
  }

  // Get date components
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[parsedDate.getMonth()];

  // Get user's preferred date format
  const desiredFormat =
    localStorage.getItem("userpreferredDateFormatType") === "null" ? "MM-DD-YYYY" : localStorage.getItem("userpreferredDateFormatType");

  let formattedDate;

  switch (desiredFormat) {
    case "MM-DD-YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return formattedDate;
    case "MM/DD/YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return formattedDate;
    case "MMM DD YYYY":
      formattedDate = desiredFormat
        .replace("MMM", monthName)
        .replace("DD", day)
        .replace("YYYY", year);
      return formattedDate;
    case "DD-MM-YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return formattedDate;
    case "DD/MM/YYYY":
      formattedDate = desiredFormat
        .replace("MM", month)
        .replace("DD", day)
        .replace("YYYY", year);
      return formattedDate;
    case "DD MMM YYYY":
      formattedDate = desiredFormat
        .replace("MMM", monthName)
        .replace("DD", day)
        .replace("YYYY", year);
      return formattedDate;
    default:
      return "Invalid date format";
  }
}

/**************************************************************/
/* Converts date ONLY to user's preferred timezone and format */
/* Example: 12:00 PM                                          */
/**************************************************************/

export function timeFormatter(inputDate) {
  // Inject user's preferred timezone first before converting
  const convertedTimezone = timezoneFormatter(
    inputDate,
    localStorage.getItem("preferredTimezone") === "null" ? momentTZ.tz.guess() : localStorage.getItem("preferredTimezone")
  );

  // Check if inputDate is a valid date
  const parsedDate = new Date(convertedTimezone);

  if (isNaN(parsedDate.getTime())) {
    return "Invalid date or time";
  }

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Use 12-hour clock with AM/PM
  };

  // Get date components
  const hours = parsedDate.toLocaleTimeString(undefined, timeOptions);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[parsedDate.getMonth()];

  // Get user's preferred date format
  const desiredFormat =
    localStorage.getItem("userpreferredDateFormatType") === "null" ? "MM-DD-YYYY" : localStorage.getItem("userpreferredDateFormatType");

  switch (desiredFormat) {
    case "MM-DD-YYYY":
      return `${hours}`;
    case "MM/DD/YYYY":
      return `${hours}`;
    case "MMM DD YYYY":
      return `${hours}`;
    case "DD-MM-YYYY":
      return `${hours}`;
    case "DD/MM/YYYY":
      return `${hours}`;
    case "DD MMM YYYY":
      return `${hours}`;
    default:
      return "Invalid date format";
  }
}

/****************************************************/
/* Used by other functions, and other DatePickers   */
/* for the 'selected' prop                          */
/****************************************************/

export function timezoneFormatter(date, timezone) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: timezone,
    })
  );
}

export function datePickerDateFormatter(date) {
  // We just have to uncapitalize YYYY and DD to make it work
  return date.replace("YYYY", "yyyy").replace("DD", "dd");
}
