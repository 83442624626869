import { Switch } from "@headlessui/react";
import { API } from "aws-amplify";
import { useState, useEffect } from "react";
import { useBriefColumnContext } from "../context/BriefsColumnContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CreatableSelect from "react-select/creatable";

const UPDATE_BRIEF_TABLE_MUTATION = `
mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
  backgroundTableUpdate(id: $id, input: $input) {
    id
    columns {
      id
      accessorKey
      headerText
      type
      enabled
      optionsText
      order
      presets {
        id
        name
      }
    }
  }
}
`;

const UPDATE_BRIEF_PRESET_MUTATION = `
mutation updateBriefPresets($id: ID, $columnIds: [Int], $hasCommentAccess: Boolean) {
  briefUpdate(id: $id, columnIds: $columnIds, hasCommentAccess: $hasCommentAccess){
    id
  }
}
`;

const selectStyle = {
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 99999,
  }),
};

const BriefColumn = ({ briefs }) => {
  const [columns, setColumns] = useBriefColumnContext();
  const [presetsOption, setPresetsOption] = useState([]);
  const [query, setQuery] = useState("");
  const [settingsChanged, setSettingsChanged] = useState(false);

  const tableId = localStorage.getItem("background-table-id");
  useEffect(() => {
    getPresetsLibrary();
  }, []);

  useEffect(() => {
    if (columns.length === 0) {
      return;
    }

    const presets = [];
    const commentsColPresets = [];

    for (const item of briefs) {
      const preset = { id: item.id, name: item?.name };
      presets.push(preset);

      if (item.hasCommentAccess) {
        const commentPreset = { ...preset };
        commentsColPresets.push(commentPreset);
      }
    }

    // const defaultColumns = new Set([0, 1, 2, 3, 4]);
    const updatedColumns = columns.map((col) => {
      const key = parseInt(col.accessorKey);
      // if (defaultColumns.has(key)) {

      const defaultPresets = col.presets ? col.presets : presets;

      const presetsToUse = key === 3 ? commentsColPresets : defaultPresets;
      return { ...col, presets: presetsToUse };
      // }
      // return col;
    });

    console.group("useEffect");
    // console.log("columns", columns);
    console.log("updatedColumns", updatedColumns);
    console.groupEnd();

    if (!settingsChanged) {
      if (JSON.stringify(updatedColumns) !== JSON.stringify(columns)) {
        saveColumns(updatedColumns);
      }
    }
  }, [columns, briefs]);

  async function getPresetsLibrary() {
    // console.log("getPresetsLibrary()");
    setPresetsOption([]);

    const presetOpt = briefs
      .map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      })
      .sort((a, b) => a?.label?.localeCompare(b?.label));

    setPresetsOption(presetOpt);
    console.groupEnd();
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    width: "100%",
  });

  async function onDragEnd(result) {
    console.log("**********OnDrag");

    if (!result.destination) {
      return;
    }
    setColumns(
      arr_move(filteredColumns, result.source.index, result.destination.index)
    );

    console.log("result", result);

    const newColumnOrder = columns.map((column, index) => {
      column.order = index + 1;
      return column;
    });


    console.log("columns",columns);
    console.log("newColumnOrder",newColumnOrder);

    const columnsToSetApi = sortByAccesorKey(newColumnOrder).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    // console.log("columnsToSetApi", columnsToSetApi);

    if (columnsToSetApi.length > 0 && tableId) {
      const data = await API.graphql({
        query: UPDATE_BRIEF_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      }).then((result) => {
        const tableColumns = result?.data?.backgroundTableUpdate?.columns?.map(
          (c) => {
            if (c.type === "DROPDOWN") {
              return {
                accessorKey: c.accessorKey,
                header: c.headerText,
                type: c.type,
                enabled: c.enabled,
                order: c.order,
                presets: c.presets,
                optionsText: c.optionsText ? c.optionsText : [],
              };
            }
            return {
              accessorKey: c.accessorKey,
              header: c.headerText,
              type: c.type,
              order: c.order,
              presets: c.presets,
              enabled: c.enabled,
            };
          }
        );

        setColumns(tableColumns);
      });
    }
  }

  async function groupByPresetsId(arr) {
    // console.log("groupByPresetsId()");
    return arr.reduce((acc, curr) => {
      curr?.presets?.forEach((preset) => {
        if (!acc[preset.id]) {
          acc[preset.id] = [];
        }

        if (curr.accessorKey !== "") {
          acc[preset.id].push(parseInt(curr.accessorKey, 10));
        }
      });
      return acc;
    }, {});
  }

  async function updateRegisters(accessorKey, registers) {
    // console.log("updateRegisters()");
    setSettingsChanged(true);
    const presets = registers.map((item) => {
      const { value, label, ...rest } = item;
      return { id: value, name: label, ...rest };
    });

    const newColumnSettingsWithId = [];

    for (const column of columns) {
      if (column.accessorKey === accessorKey) {
        newColumnSettingsWithId.push({
          ...column,
          presets,
        });
      } else {
        newColumnSettingsWithId.push(column);
      }
    }

    const groupedPresets = await groupByPresetsId(newColumnSettingsWithId);

    const mappedPresets = Object.keys(groupedPresets).map((key) => ({
      presetId: key,
      columnIds: groupedPresets[key],
      hasCommentAccess: groupedPresets[key].includes(3) ? true : false,
    }));

    // console.log("mappedPresets", mappedPresets);

    const savePromises = mappedPresets.map(
      ({ columnIds, presetId, hasCommentAccess }) =>
        API.graphql({
          query: UPDATE_BRIEF_PRESET_MUTATION,
          variables: { columnIds, id: presetId, hasCommentAccess },
        })
    );

    Promise.all(savePromises)
      .then(() => getPresetsLibrary())
      .then(() => saveColumns(newColumnSettingsWithId))
      .catch((error) => console.error(error));
  }

  async function toggleColumn(accessorKey, open) {
    //setPreset("");
    const newColumnSettingsWithId = columns.map((column, index) => {
      if (column.accessorKey === accessorKey) {
        column.enabled = open;
      }
      return column;
    });

    // console.log("Columns ", columns);
    // console.log("newColumnSettingsWithId ", newColumnSettingsWithId);
    saveColumns(newColumnSettingsWithId);
  }

  async function saveColumns(newColumnSettingsWithId) {
    // console.log("saveColumns()", newColumnSettingsWithId);

    const columnsToSetApi = sortByAccesorKey(newColumnSettingsWithId).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    // console.log("To Update Cols ", columnsToSetApi);
    if (columnsToSetApi.length > 0 && tableId) {
      const data = await API.graphql({
        query: UPDATE_BRIEF_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      }).then((result) => {
        // console.log("Result Cols:", result?.data?.backgroundTableUpdate?.columns);

        const tableColumns = result?.data?.backgroundTableUpdate?.columns?.map(
          (c) => {
            if (c.type === "DROPDOWN") {
              return {
                accessorKey: c.accessorKey,
                header: c.headerText,
                type: c.type,
                enabled: c.enabled,
                order: c.order,
                presets: c.presets,
                optionsText: c.optionsText ? c.optionsText : [],
              };
            }
            return {
              accessorKey: c.accessorKey,
              header: c.headerText,
              type: c.type,
              order: c.order,
              presets: c.presets,
              enabled: c.enabled,
            };
          }
        );

        setColumns(tableColumns);
      });
    }
  }

  const filteredColumns =
    query === ""
      ? sortByOrder(columns)
      : sortByOrder(columns).filter((column) => {
          return column.header.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    // console.log("FILTEREDCOLUMNS", filteredColumns);
  }, [filteredColumns]);

  function sortByOrder(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.order - b.order);
    } else {
      sort = arr;
    }

    return sort;
  }

  function sortByAccesorKey(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.accessorKey - b.accessorKey);
    } else {
      sort = arr;
    }

    return sort;
  }

  function arr_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    // console.log("arr_move", arr);
    return arr;
  }

  function savedValues(vals) {
    if (!vals) {
      return;
    }

    const existingBriefIds = new Set(briefs.map((b) => b.id));

    return vals
      .filter(
        (preset) => preset.name !== null && existingBriefIds.has(preset.id)
      )
      .map((preset) => ({
        label: preset.name,
        value: preset.id,
      }))
      .sort((a, b) => a?.label?.localeCompare(b?.label));
  }

  return (
    <div className="mr-1">
      <input
        type="text"
        className="p-3 border w-full rounded-md ml-1"
        placeholder="Search Column"
        onChange={(event) => setQuery(event.target.value)}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="flex flex-col mt-6 space-y-5"
            >
              {filteredColumns &&
                sortByOrder(filteredColumns).map((column, index) => {
                  return (
                    <Draggable
                      key={column.accessorKey}
                      draggableId={column.accessorKey}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          key={column.accessorKey}
                          className="flex w-full"
                        >
                          <p className="truncate ml-2">{column.header}</p>
                          <div className={`ml-auto`}>
                            <Switch
                              onChange={(e) =>
                                toggleColumn(column.accessorKey, e)
                              }
                              checked={column.enabled}
                              className={`
                                  ${
                                    column.enabled
                                      ? "cursor-pointer bg-black"
                                      : "bg-gray-200"
                                  } 
                                  relative inline-flex h-5 w-10 items-center 
                                  rounded-full 
                                `}
                            >
                              <span
                                className={`${
                                  column.enabled
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block h-3 w-3 transform rounded-full bg-white`}
                              />
                            </Switch>
                          </div>

                          {/* {[0, 1, 2, 4].includes(
                              parseInt(column.accessorKey)
                            ) && (
                              <i className="w-64 z-auto ml-5 text-right text-gray-700">
                                All Briefs
                              </i>
                            )} */}
                          <CreatableSelect
                            key={`select-${column.accessorKey}`}
                            className={`max-w-md w-64 z-auto ml-5`}
                            value={savedValues(column?.presets)}
                            styles={selectStyle}
                            options={presetsOption}
                            // onChange={(e) => {
                            //   updateRegisters(column.accessorKey, e);
                            // }}
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions.map(
                                (option) => ({
                                  name: option.label,
                                  id: option.value,
                                })
                              );
                              updateRegisters(
                                column.accessorKey,
                                selectedValues
                              );
                            }}
                            placeholder="Select Brief"
                            isMulti={true}
                            openMenuOnClick={true}
                            openMenuOnFocus={true}
                            isValidNewOption={() => false}
                          />
                          <p></p>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default BriefColumn;
