import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import CreatableSelect from "react-select/creatable";
import {
  datePickerDateFormatter,
  timezoneFormatter,
} from "../../shared/userPreferredDateTime";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";

export default function Admin() {
  let history = useHistory();
  const [pagesFeatures, setPagesFeatures] = useState([]);
  const [newFeatures, setNewFeatures] = useState(pagesFeatures.map(() => ""));
  const [newPageName, setNewPageName] = useState("");
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));

  const [companyClientMatterBriefs, setCompanyClientMatterBriefs] = useState(
    []
  );

  // DATE FILTERING STATES
  const [startDate, setStartDate] = useState(
    new Date() || null
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate())) || null
  );

  const [oneMonth, setOneMonth] = useState();
  const [oneDay, setOneDay] = useState();
  const [threeDays, setThreeDays] = useState();
  const [sevenDays, setSevenDays] = useState();

  const [invalidState, setInvalidState] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);

  const dateOptions = [
    { value: oneDay, label: "Today" },
    { value: threeDays, label: "3 Days" },
    { value: sevenDays, label: "7 Days" },
    { value: oneMonth, label: "1 Month" },
];

  const [companyUsers, setCompanyUsers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [selectedOption, setSelectedOption] = useState("AUTH");

  const options = [
    { value: "TASKS", label: "TASKS" },
    { value: "INBOX", label: "INBOX" },
    { value: "BACKGROUND", label: "BACKGROUND" },
    { value: "AUTH", label: "AUTH" },
  ];


  const ACTIVITIES_QUERY = `
  query GetActivities($companyId: ID!, $appModule: AppModules, $startDate: AWSDate, $endDate: AWSDate){
    activities(companyId:$companyId, appModule: $appModule, endDate: $endDate, startDate: $startDate) {
      id
      companyId
      appModule
      field
      timeStamp
      activity
      previous
      current
      clientMatter{
        id
      }
      brief{
        id
        name
      }
      user{
        id
        firstName
        lastName
      }
    }
  }`;

  const PAGESFEATURES_QUERY = `
    query getPagesFeatures {
        pages {
            id
            name
            label
            features {
                id
                name
                label
            }
        }
    }`;

  const CREATEFEATURE_MUTATION = `
    mutation createFeature($page: PageInput, $name: String, $label: String) {
        featureCreate(name: $name, label: $label, page: $page){
            id
            name
            label
        }
    }`;

  const UPDATEPAGE_MUTATION = `
    mutation updatePage($id: ID, $features: [FeatureInput]) {
        pageUpdate(features: $features, id: $id) {
            id
        }
    }`;

  const CREATEPAGE_MUTATION = `
    mutation createPage($features: [FeatureInput], $label: String, $name: String, $route: String) {
        pageCreate(label: $label, name: $name, route: $route, features: $features) {
        id
        name
        label
        features {
        id
        }
    }
    }
  `;

  const COMPANY_CLIENTMATTER_BRIEFS = `query companyClientMatterBriefs($id: String) {
    company(id: $id) {
      clientMatters {
        items {
          id
          client {
            name
          }
          matter {
            name
          }
          briefs {
            items {
              id
              name
              hasCommentAccess
              columnIds
              phase
            }
          }
        }
      }
    }
  }
  `;

  const BG_TABLE_QUERY = `query backgroundTable ($clientMatterId: ID!) {
    backgroundTable(clientMatterId: $clientMatterId) {
      id
      columns {
        headerText
        presets {
          id
          name
        }
      }
    }
  }
  `;

  const COMPANY_USERS = `query companyUsers($id: String) {
    company(id: $id) {
      users {
        items {
          firstName
          lastName
          userType
          email
          hasPortalAccess
        }
      }
    }
  }
  `;
  // Handle change event
  const handleChange = (option) => {
    console.log(option.value);
    setSelectedOption(option);
  };

  const logActivitiesQuery = async (appModule) => {
    try {
      const variables = {
        companyId: companyId,
        appModule: appModule.value,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD')
      }

      console.log("variables", variables);
      const response = await API.graphql({
        query: ACTIVITIES_QUERY,
        variables
      });
      const activitiesData = response?.data?.activities;
      console.log("ACTIVITIES", activitiesData);
      setActivities(activitiesData);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  function formatDate(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  useEffect(() => {
    if (pagesFeatures.length === 0) {
      getCurrentSession();
    }
    logActivitiesQuery(selectedOption);
  }, [pagesFeatures, selectedOption, startDate, endDate]);

  const getCurrentSession = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const username = cognitoUser?.username;
      getPagesFeatures();
      /*
      if (checkEmailDomain(username, "lophils.com")) {
        getPagesFeatures();
      } else {
        // unauthorized access
        history.push(AppRoutes.DASHBOARD);
      }
      */
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (
      startDate !== null &&
      startDate !== "" &&
      endDate !== null &&
      endDate !== ""
    ) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        setInvalidDateRange(true);
        setInvalidState(true);
      } else {
        setInvalidDateRange(false);
        setInvalidState(false);
      }
    } else {
      setInvalidState(true);
    }
  }, [startDate, endDate])


  //* functions for date filtering

  const isEndDateOccupied = () => {
    if (endDate) {
        /* Calculate the difference in days between the previous startDate and endDate */
        const oneDay = 24 * 60 * 60 * 1000;
        const daysDifference = Math.floor((endDate - startDate) / oneDay);

        /* Returns previously selected preset */
        if (daysDifference === 0) return "Today";

        if (daysDifference === 3) return "3 Days";

        if (daysDifference === 7) return "7 Days";

        if (daysDifference > 25) return "1 Month";
    }
};

  const handleStartDateChange = (selected) => {
    if (endDate !== null) {
      /* Calculate the difference in days between the previous startDate and endDate */
      const oneDay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor((endDate - startDate) / oneDay);
      let newEndDate = new Date(selected.getTime());

      if (daysDifference > 7) newEndDate.setMonth(newEndDate.getMonth() + 1);
      else
        newEndDate = new Date(newEndDate.getTime() + daysDifference * oneDay);

      setEndDate(newEndDate);
    }

    /* Because .setMonth is needed and to avoid mutation
    and miscalculation, we must declare two of the same instance */
    const dependentTime = new Date(selected);
    const forMonth = new Date(selected);

    setStartDate(selected);

    /* Updates the options */
    setOneDay(new Date(dependentTime.setDate(dependentTime.getDate())));
    setThreeDays(new Date(dependentTime.setDate(dependentTime.getDate() + 3)));
    setSevenDays(new Date(dependentTime.setDate(dependentTime.getDate() + 4)));
    setOneMonth(new Date(dependentTime.setDate(dependentTime.getDate() + 23)));
  };
  console.log("Selected Start Date: ", startDate);
  console.log("Selected End Date: ", endDate);

  const handleEndDateChange = (selected) => {
    setEndDate(selected.value);
  };

  /* Styling for our Select component */
  const selectStyle = {
    option: (base, selectProps) => ({
      ...base,
      backgroundColor:
        selectProps.isFocused && !selectProps.isSelected
          ? "transparent"
          : base.backgroundColor,
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 40,
      margin: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(3600deg)",
      paddingRight: "0px",
      paddingLeft: "0px",
      color: "text-gray-600",
      display: "none",
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      padding: 0,
      margin: 0,
      height: 34,
      minHeight: 34,
      borderColor: "rgba(209, 213, 219, 1)",
    }),
    placeholder: (base, state) => ({
      ...base,
      fontSize: "0.76rem",
      paddingTop: "0.12rem",
      color: "#374151",
    }),
    menu: (base) => ({
      ...base,
      marginTop: 0,
      padding: 0,
      margin: 0,
      width: "max-content",
      minWidth: "100%",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "0.76rem",
    }),
  };

  const getPagesFeatures = async () => {
    try {
      const response = await API.graphql({ query: PAGESFEATURES_QUERY });
      setPagesFeatures(sortArrayByName(response?.data?.pages));
    } catch (error) {
      console.error("Error fetching pages features:", error);
    }
  };

  const getCompanyClientMatterBriefs = async (id) => {
    try {
      const { data } = await API.graphql({
        query: COMPANY_CLIENTMATTER_BRIEFS,
        variables: { id },
      });

      const clientMatters = data?.company?.clientMatters;
      if (!clientMatters) {
        throw new Error("No client matters found");
      }

      const cmIds = clientMatters.items.map((cm) => cm.id);

      const taggedBriefs = await Promise.allSettled(
        cmIds.map((cmId) => getBackgroundTable(cmId))
      );

      const mergedData = clientMatters.items.map((cm, index) => ({
        ...cm,
        columnTaggedBriefs:
          taggedBriefs[index].status === "fulfilled"
            ? taggedBriefs[index].value
            : [],
      }));

      var briefCtr = 0;
      const briefLoop = mergedData.map((b) => b.briefs.items);
      briefCtr = briefLoop.reduce((total, briefs) => total + briefs.length, 0);
      console.log("Total No. of Briefs", briefCtr);

      setCompanyClientMatterBriefs(mergedData);
    } catch (error) {
      console.error("Error fetching company client matter briefs:", error);
    }
  };

  const getCompanyUsers = async (id) => {
    try {
      const { data } = await API.graphql({
        query: COMPANY_USERS,
        variables: { id },
      });

      const compUsers = data?.company?.users?.items;
      if (!compUsers) {
        throw new Error("No users found");
      }

      console.log("company Users", compUsers);
      setCompanyUsers(compUsers);
    } catch (error) {
      console.error("Error fetching company users:", error);
    }
  };

  function checkEmailDomain(email, domain) {
    const emailParts = email.split("@");
    return (
      emailParts.length === 2 &&
      emailParts[1].toLowerCase() === domain.toLowerCase()
    );
  }

  function sortArrayByName(arr) {
    arr.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return arr;
  }

  const handleNewFeatureChange = (index, value) => {
    setNewFeatures((prevFeatures) => {
      const newFeatures = [...prevFeatures];
      newFeatures[index] = value;
      return newFeatures;
    });
  };

  const handleAddPage = async () => {
    // check if page already exists
    if (
      pagesFeatures.find(
        (page) => page.name.toUpperCase() === newPageName.toUpperCase()
      )
    ) {
      alert(`Page '${newPageName}' already exists`);
      return;
    }

    const createPage = await API.graphql({
      query: CREATEPAGE_MUTATION,
      variables: {
        name: newPageName
          .replace(/[^a-zA-Z ]/g, "")
          .replace(/\s+/g, "")
          .toUpperCase(),
        label: newPageName,
        route: newPageName.replace(/\s+/g, "-").toLowerCase(),
        features: [],
      },
    });

    const newPage = createPage?.data?.pageCreate;
    setPagesFeatures([...pagesFeatures, newPage]);
    setNewPageName("");
  };

  const handleSearchByCompanyId = async () => {
    if (!companyId) {
      return;
    }
    getCompanyClientMatterBriefs(companyId);
    getCompanyUsers(companyId);
  };

  const handleAddFeature = async (pageId, pageName, index) => {
    const newFeatureName = newFeatures[index].trim();
    if (newFeatureName) {
      const createFeature = await API.graphql({
        query: CREATEFEATURE_MUTATION,
        variables: {
          page: {
            id: pageId,
            name: pageName,
          },
          name: newFeatureName
            .replace(/[^a-zA-Z ]/g, "")
            .replace(/\s+/g, "")
            .toUpperCase(),
          label: newFeatureName,
        },
      });

      const newFeature = createFeature?.data?.featureCreate;

      const updatedPages = [...pagesFeatures];
      const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
      updatedPages[pageIndex].features.push(newFeature);
      setPagesFeatures(updatedPages);
      console.log(`Added feature "${newFeatureName}" to page ${pageId}`);
      setNewFeatures((prevFeatures) => {
        const newFeatures = [...prevFeatures];
        newFeatures[index] = "";
        return newFeatures;
      });

      const updatedFeatures = updatedPages[pageIndex].features;
      console.log("Updated Features", updatedFeatures);

      await API.graphql({
        query: UPDATEPAGE_MUTATION,
        variables: {
          features: updatedFeatures,
          id: pageId,
        },
      });
    }
  };

  const getBackgroundTable = async (clientMatterId) => {
    try {
      const response = await API.graphql({
        query: BG_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });
      console.log("getBackgroundTable", response?.data?.backgroundTable);
      return response?.data?.backgroundTable;
    } catch (error) {
      console.error("Error fetching pages features:", error);
    }
  };

  return (
    <div className="p-10">
      <div
        className={
          "p-5 m-15 relative sm:pl-20 flex flex-col min-w-0 break-words shadow-lg rounded bg-white"
        }
      >
        <div className="flex items-center whitespace-nowrap py-1">
          <label
            htmlFor="newPageName"
            className="block font-medium text-gray-700 mr-1"
          >
            New Page Name
          </label>
          <input
            id="newPageName"
            name="newPageName"
            type="text"
            className="w-full px-2 py-1 border rounded"
            placeholder="Enter new page name"
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
          />
          <button
            type="button"
            className="ml-2 px-4 py-1 text-white bg-blue-500 rounded"
            onClick={handleAddPage}
          >
            {`Add Page`}
          </button>
        </div>
      </div>

      <div
        className={
          "p-5 relative sm:pl-20 flex flex-col min-w-0 break-words shadow-lg rounded bg-white"
        }
      >
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-center font-bold">Page</th>
              <th className="px-4 py-2 text-center font-bold">Features</th>
              <th className="px-4 py-2 text-center font-bold">Action</th>
            </tr>
          </thead>
          <tbody>
            {pagesFeatures.map((page, index) => (
              <tr
                key={page.id}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className="px-4 py-2">{page.label}</td>
                <td className="px-4 py-2">
                  {page.features.length > 0 ? (
                    <ul>
                      {page.features.map((feature) => (
                        <li key={feature.id} className="list-decimal list-inside">
                          {feature.label}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td className="px-4 py-2">
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Enter new feature name"
                      value={newFeatures[index]}
                      onChange={(e) =>
                        handleNewFeatureChange(index, e.target.value)
                      }
                      className="w-full px-2 py-1 border rounded"
                    />
                    <button
                      className="ml-2 px-4 py-1 text-white bg-blue-500 rounded"
                      onClick={() =>
                        handleAddFeature(page.id, page.name, index)
                      }
                    >
                      Add
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <br />
      <br />
      <div
        className={
          "p-5 m-15 relative sm:pl-20 flex flex-col min-w-0 break-words shadow-lg rounded bg-white"
        }
      >
        <div className="flex items-center whitespace-nowrap py-1">
          <label
            htmlFor="companyId"
            className="block font-medium text-gray-700 mr-1"
          >
            Search Client Matter Briefs by Company ID
          </label>
          <input
            id="companyId"
            name="companyId"
            type="text"
            className="w-full px-2 py-1 border rounded"
            placeholder="Enter Company ID"
            value={companyId}
            onChange={(e) => setCompanyId(e.target.value)}
          />
          <button
            type="button"
            className="ml-2 px-4 py-1 text-white bg-blue-500 rounded"
            onClick={handleSearchByCompanyId}
          >
            {`Search`}
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="max-w-full table-auto divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  #
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client Matter ID
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client / Matter
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Briefs
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Columns Tagged Briefs
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {companyClientMatterBriefs.map((item, index) => (
                <tr
                  key={item.id}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                >
                  <td className="px-6 py-4 whitespace-nowrap vertical-align-top">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap vertical-align-top">
                    {item.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap vertical-align-top">
                    <>{item.client?.name}</>
                    <br />
                    <>{item.matter?.name}</>
                  </td>

                  <td className="px-6 py-4 vertical-align-top">
                    <table className="table-auto max-w-full divide-y divide-gray-200 border-1">
                      <thead>
                        <tr>
                          <th className="pb-1 text-center text-xs font-medium text-gray-400 w-1/10">
                            # {item.briefs?.items?.length}
                          </th>
                          <th className="pb-1 text-center text-xs font-medium text-gray-400 w-1/4">
                            ID
                          </th>
                          <th className="pb-1 text-center text-xs font-medium text-gray-400 w-1/4">
                            Name
                          </th>
                          <th className="pb-1 text-center text-xs font-medium text-gray-400 w-1/4">
                            Column IDs
                          </th>
                          <th className="pb-1 text-center text-xs font-medium text-gray-400 w-1/4">
                            Comment Access
                          </th>
                          <th className="pb-1 text-center text-xs font-medium text-gray-400 w-1/4">
                            Phase
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.briefs?.items?.map((brief, index) => (
                          <tr
                            key={brief?.id}
                            className={`${index % 2 === 0 ? "bg-blue-100" : "bg-yellow-100"
                              }`}
                          >
                            <td className="p-1 w-1/10 text-center">
                              <span className="mr-3 p-2">{index + 1}</span>
                            </td>
                            <td className="p-1 w-1/5 text-center whitespace-nowrap">
                              <span className="mr-3 p-2">
                                <i>{brief?.id}</i>
                              </span>
                            </td>
                            <td className="p-1 w-1/5">
                              <span className="mr-3 p-2 font-semibold">
                                {brief?.name}
                              </span>
                            </td>
                            <td className="p-1 w-1/5 text-center">
                              <span className="mr-3 p-2">
                                {brief?.columnIds ? (
                                  JSON.stringify(brief?.columnIds)
                                ) : (
                                  <span className="px-2 py-1 text-xs font-semibold text-red-800 bg-red-200 rounded-full">
                                    No Default Columns
                                  </span>
                                )}
                              </span>
                            </td>
                            <td className="p-1 w-1/5 text-center">
                              {brief?.hasCommentAccess ? (
                                <span className="px-2 py-1 text-xs font-semibold text-green-800 bg-green-200 rounded-full">
                                  Has Comment Access
                                </span>
                              ) : null}
                            </td>
                            <td className="p-1 w-1/5 text-center">
                              <span className="mr-3 p-2">
                                {brief?.phase ? (
                                  JSON.stringify(brief?.phase)
                                ) : (
                                  <span className="px-2 py-1 text-xs font-semibold text-red-800 bg-red-200 rounded-full">
                                    No phase for {brief?.name}
                                  </span>
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap vertical-align-top">
                    {item.columnTaggedBriefs ? (
                      item.columnTaggedBriefs.columns.map((c, index) => {
                        return (
                          <p key={`br-col-${index}`}>
                            <small className="font-bold">
                              {c.headerText}
                              <br />
                            </small>
                            {c.presets ? (
                              <ol
                                style={{
                                  listStyle: "none",
                                  display: "inline",
                                }}
                              >
                                {c.presets?.map((x, index) => {
                                  return (
                                    <li
                                      style={{ display: "inline" }}
                                      className="mr-2"
                                      key={`br-${index}`}
                                    >
                                      <small>{x?.name}</small>
                                    </li>
                                  );
                                })}
                              </ol>
                            ) : (
                              <small className="font-semibold text-red-800">
                                No Columns Presets
                              </small>
                            )}
                          </p>
                        );
                      })
                    ) : (
                      <span className="px-2 py-1 text-xs font-semibold text-red-800 bg-red-200 rounded-full">
                        No Columns Tagged Briefs
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className={
          "p-5 relative sm:pl-20 flex flex-col min-w-0 break-words shadow-lg rounded bg-white"
        }
      >
        <CreatableSelect
          isClearable
          onChange={handleChange}
          options={options}
          value={selectedOption}
          className="w-64 my-4"
        />
        <DatePicker
          className="w-64 rounded border border-gray-300 py-2 px-2.5 text-xs"
          placeholderText={"Start Date"}
          popperProps={{
            positionFixed: true,
          }}
          dateFormat={datePickerDateFormatter(
            localStorage.getItem("userpreferredDateFormatType")
          )}
          selected={
            startDate === null
              ? null
              : timezoneFormatter(
                startDate,
                localStorage.getItem("preferredTimezone")
              )
          }
          onChange={(selected) => {
            handleStartDateChange(selected);
            console.log("Start Date selected: ", startDate)
          }}
        />
        <Select
          styles={selectStyle}
          options={dateOptions}
          className="w-64 my-4"
          placeholder={endDate ? isEndDateOccupied() : null}
          isSearchable={false}
          isValidNewOption={() => false}
          onChange={(selected) => {
            handleEndDateChange(selected);
            console.log("End Date selected: ", endDate);
          }}
          components={{ IndicatorSeparator: () => null }}
        />
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-center font-bold">Module</th>
              <th className="px-4 py-2 text-center font-bold">Label/Brief</th>
              <th className="px-4 py-2 text-center font-bold">Submitted By</th>
              <th className="px-4 py-2 text-center font-bold">Date Time</th>
              <th className="px-4 py-2 text-center font-bold">Field</th>
              <th className="px-4 py-2 text-center font-bold">Activity</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity) => {
              return (
                <tr
                  className="border-b border-gray-200 hover:bg-gray-100"
                  key={activity.timeStamp}
                >
                  <td className="px-6 py-4">{activity.appModule}</td>
                  <td className="px-6 py-4">{activity.brief.name}</td>
                  <td className="px-6 py-4">
                    {activity.user.firstName} {activity.user.lastName}
                  </td>
                  <td className="px-6 py-4">
                    {formatDate(activity.timeStamp)}
                  </td>
                  <td className="px-6 py-4">{activity.field}</td>
                  <td className="px-6 py-4">
                    {activity.activity}{" "}
                    {activity.previous && activity.current !== null && (
                      <span>
                        {" "}
                        from {activity.previous} to {activity.current}{" "}
                      </span>
                    )}{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
