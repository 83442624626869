//Libraries
import { useParams } from "react-router-dom"
import { createContext, useReducer, useEffect } from "react"

//Reducers
import { appStateReducer } from "./appStateReducer"
import { formDataReducer } from "./formDataReducer"
import { modalStateReducer } from "./modalStateReducer"

//API
import { 
  mutationActionHandler,
  qGetFormById,
} from "../graphql/formAPI"


const initialState = {
  appState: {
    currentPage: 'start',
  },
  formData: {
    formDetails: {
      id: '',
      name: '',
    },
    pages: [],
    formAnswers: {},
  },
  modalState: {
    modalOpen: false,
    modalContent: null,
  },
};

const storeReducer = (state, action) => ({
  appState: appStateReducer(state.appState, action),
  formData: formDataReducer(state.formData, action),
  modalState: modalStateReducer(state.modalState, action),
});

export const FormAnswerContext = createContext();

export default function StoreProvider({ children }) {
  
  const [state, dispatch] = useReducer(storeReducer, initialState)

  const { companyId, id } = useParams()

  useEffect(() => {
    const fetchForm = async () => {
      console.log("FETCHING DATA")
      mutationActionHandler(
        {
          query: qGetFormById,
          variables: {
            id: id,
          }
        },
        (response)=>{
          console.log(response)
          const {id, name, pages} = response.data.customForm
          dispatch({
            type: 'SET_FORM_DETAILS',
            payload: {
              companyId: companyId,
              id: id,
              name: name,
            },
          })
          dispatch({
            type: 'SET_FORM_CONTENT',
            payload: pages
          })
          
          dispatch({
            type: 'INITIALIZE_ANSWER_STATE'
          })

        }
      )
    }

    fetchForm()
  }, []);

  //console.log(state)

  const contextValue = {
    state,
    dispatch,
  };

  return (
    <FormAnswerContext.Provider value={contextValue}>
      {children}
    </FormAnswerContext.Provider>
  );
}
