import { useState } from "react";
import { Auth } from "aws-amplify";
import "../../assets/styles/Auth.css";
import Button from "../../shared/Button";
import { MdCheck, MdOutlineClose } from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ProgressNotification from "./progress-notification";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";

export default function SignUp({
  user,
  setUser,
  AuthState,
  setNextAuthState,
  notifMessage,
  notifShow,
  notifVariant,
  setNotifMessage,
  setNotifShow,
  setNotifVariant,
  loading,
  setLoading,
}) {
  const [customCompanyName, setCustomCompanyName] = useState();

  // Added to fix bug where user can submit a white space as a name (MMA-2112)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [password, setPassword] = useState("");

  const [passwordReq, setPasswordReq] = useState({
    minimum: false,
    uppercase: false,
    lowercase: false,
    numeral: false,
    symbol: false,
  });

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { width, height } = useWindowDimensions();

  function handlePasswordVisibility(event) {
    event.preventDefault();
    setPasswordVisibility(!passwordVisibility);
  }

  async function handleSignUp(event) {
    event.preventDefault();
    setLoading(true);
    setNotifShow(false);
    try {
      const response = await Auth.signUp({
        username: event.target.email.value,
        password: event.target.password.value,
        attributes: {
          given_name: event.target.given_name.value,
          family_name: event.target.family_name.value,
          email: event.target.email.value,
          "custom:company_name": event.target.companyName.value,
        },
        autoSignIn: {
          enabled: true,
          clientMetaData: {
            sourceAuthState: "ConfirmSignUp",
          },
        },
      })
        .then((response) => {
          setLoading(false);
          console.log("SignUp", response.user);
          setUser(response.user);
          window.scrollTo(0, 0);
          setNextAuthState(AuthState.ConfirmSignUp);
        })
        .catch((error) => {
          console.log("error signing up:", error);

          const customErrorMessage = error?.message
            .replace("PreSignUp failed with error ", "")
            .replace(/\.*$/, ".")
            .trim();
          setLoading(false);
          setNotifMessage(customErrorMessage);
          setNotifVariant("error");
          setNotifShow(true);
          window.scrollTo(0, 0);

          // setTimeout(() => {
          //   setNotifShow(false)
          // }, 3000);
        });
    } catch (error) {
      console.error(error);
    }
  }

  function clean_up(v) {
    let c = v.replace(/\s+/g, " ");
    if (c === " ") {
      c = "";
    }

    return c;
  }

  // Password Requirement Function

  const minimumRegex = new RegExp("^(?=.{8,})");
  const lowerRegex = new RegExp("^(?=.*[a-z])");
  const upperRegex = new RegExp("^(?=.*[A-Z])");
  const numeralRegex = new RegExp("^(?=.*[0-9])");
  const symbolRegex = new RegExp("[^A-Za-z0-9]");

  function handlePasswordRequirements(event) {
    setPassword(event.target.value);
    let minimum = false;
    let lowercase = false;
    let uppercase = false;
    let numeral = false;
    let symbol = false;

    if (minimumRegex.test(event.target.value)) {
      console.log("MINIMUM TRUE!");
      minimum = true;
    }

    if (lowerRegex.test(event.target.value)) {
      lowercase = true;
    }

    if (upperRegex.test(event.target.value)) {
      uppercase = true;
    }

    if (numeralRegex.test(event.target.value)) {
      numeral = true;
    }

    if (symbolRegex.test(event.target.value)) {
      symbol = true;
    }

    setPasswordReq({
      minimum: minimum,
      lowercase: lowercase,
      uppercase: uppercase,
      numeral: numeral,
      symbol: symbol,
    });
  }

  function handleCheckmark(req) {
    return req === true ? (
      <MdCheck className="text-secondary w-3 flex-none" />
    ) : (
      <MdOutlineClose className="text-danger w-3 flex-none" />
    );
  }

  return (
    <>
      <div
        className="flex flex-col items-center md:items-start z-101 py-14 lg:px-10 fade-in-fwd w-full"
        // style={{ minWidth: width > 900 ? "32.5rem" : "auto" }}
      >
        <div className="flex flex-col gap-3 w-full">
          <h1 className="font-semibold text-gray-900 text-2xl md:text-3xl md:mx-0 mx-auto">
            Sign up
          </h1>
          <p className="font-normal text-base text-gray-500 md:mx-0 mx-auto">
            Start your 30-day free trial.
          </p>
        </div>
        {/* Input Fields */}
        <div className="mt-8 w-full">
          <form onSubmit={(event) => handleSignUp(event)}>
            <div
              className="flex flex-col gap-4 md:mx-0 mx-auto"
              style={{ maxWidth: "22.5rem" }}
            >
              <ProgressNotification
                className=""
                variant={notifVariant}
                message={notifMessage}
                show={notifShow}
              />
              <div className="flex flex-col gap-2">
                <label
                  for="signUp-given_name"
                  className="text-sm font-normal text-gray-700"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  id="signUp-given_name"
                  key="signUp-given_name"
                  name="given_name"
                  value={firstName}
                  className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                  onChange={(event) => {
                    // Prevents user from entering white space as a name (MMA-2112)
                    let i = clean_up(event.target.value);
                    setFirstName(i);
                  }}
                  required
                  placeholder="Enter your first name"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  for="signUp-family_name"
                  className="text-sm font-normal text-gray-700"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  id="signUp-family_name"
                  key="signUp-family_name"
                  name="family_name"
                  value={lastName}
                  className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                  onChange={(event) => {
                    let i = clean_up(event.target.value);
                    setLastName(i);
                  }}
                  required
                  placeholder="Enter your last name"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  for="signUn-companyName"
                  className="text-sm font-normal text-gray-700"
                >
                  Company Name *
                </label>
                <input
                  type="text"
                  id="signUn-companyName"
                  key="signUn-companyName"
                  name="companyName"
                  value={customCompanyName}
                  className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                  onChange={(event) => {
                    let i = clean_up(event.target.value);
                    setCustomCompanyName(i);
                    event.target.value = i;
                  }}
                  required
                  placeholder="Enter your company name"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  for="signUp-email"
                  className="text-sm font-normal text-gray-700"
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="signUp-email"
                  key="signUp-email"
                  name="email"
                  className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                  required
                  autoComplete="new-username"
                  placeholder="Enter your email"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  for="signUp-password"
                  className="text-sm font-normal text-gray-700"
                >
                  Password *
                </label>
                <div className="relative flex flex-col">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    id="signUp-password"
                    key="signUp-password"
                    name="password"
                    value={password}
                    onChange={(event) => handlePasswordRequirements(event)}
                    className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                    required
                    autoComplete="new-password"
                    placeholder="Create a password"
                  />
                  <button
                    className="absolute right-6 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e)}
                  >
                    {passwordVisibility ? (
                      <FaEyeSlash className="text-2xl text-hbGray" />
                    ) : (
                      <FaEye className="text-2xl text-hbGray" />
                    )}
                  </button>
                </div>
                {/* Password Requirements */}
                <div className="mt-4">
                  <ul className="ml-2">
                    <li className="text-xs flex items-center gap-2">
                      {handleCheckmark(passwordReq.minimum)}
                      Minimum of 8 characters
                    </li>
                    <li className="text-xs tracking-tight flex items-center gap-2">
                      {handleCheckmark(passwordReq.lowercase)}
                      Must include at least 1 lowercase character (a-z)
                    </li>
                    <li className="text-xs tracking-tight flex items-center gap-2">
                      {handleCheckmark(passwordReq.uppercase)}
                      Must include at least 1 uppercase character (A-Z)
                    </li>
                    <li className="text-xs tracking-tight flex items-center gap-2">
                      {handleCheckmark(passwordReq.numeral)}
                      Must include at least 1 numeral (0-9)
                    </li>
                    <li className="text-xs flex items-center gap-2">
                      {handleCheckmark(passwordReq.symbol)}
                      Must include at least 1 symbol
                    </li>
                  </ul>
                </div>
              </div>
              {/* Password Requirements
              <div className="mt-4">
                <ul className="ml-2">
                  <li className="text-base md:text-lg flex items-center gap-2">
                    {handleCheckmark(passwordReq.minimum)}
                    Minimum of 8 characters
                  </li>
                  <li className="text-base md:text-lg flex items-center gap-2">
                    {handleCheckmark(passwordReq.lowercase)}
                    Must include at least 1 lowercase character (a-z)
                  </li>
                  <li className="text-base md:text-lg flex items-center gap-2">
                    {handleCheckmark(passwordReq.uppercase)}
                    Must include at least 1 uppercase character (A-Z)
                  </li>
                  <li className="text-base md:text-lg flex items-center gap-2">
                    {handleCheckmark(passwordReq.numeral)}
                    Must include at least 1 numeral (0-9)
                  </li>
                  <li className="text-base md:text-lg flex items-center gap-2">
                    {handleCheckmark(passwordReq.symbol)}
                    Must include at least 1 symbol
                  </li>
                </ul>
              </div> */}
              {/* Register and Login */}
              <div className="flex flex-col gap-8 items-center">
                <div className="flex justify-center w-full">
                  <Button
                    type="submit"
                    variant="secondary"
                    block={"full"}
                    size={"xl"}
                    className="rounded-lg w-full"
                  >
                    <span className="relative text-base font-normal">
                      {loading ? (
                        <AiOutlineLoading className="absolute rotate-center -left-6 top-1" />
                      ) : null}
                      Create Account
                    </span>
                  </Button>
                </div>
                <div className="flex items-center">
                  <span className="text-base font-normal text-hbGray">
                    Already have an account?
                  </span>
                  <button
                    className="ml-1 text-base font-semibold text-green-800"
                    type="button"
                    onClick={() => {
                      setNotifShow(false);
                      setNextAuthState(AuthState.SignIn);
                    }}
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
