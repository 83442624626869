import { defaultPageState, defaultSectionState } from "./StoreProvider"

export const formDataReducer = (state, action) => {

  switch (action.type) {

    case 'SET_CATEGORY_DATA':
      console.log("Setting category data", action.payload)
      return {
        ...state,
        formCategories: [
          {id: 'all_category', name: 'All'},
          ...action.payload
        ]
      }

    case 'ADD_CATEGORY':
      return {
        ...state,
        formCategories: [
          ...state.formCategories,
          // {
          //   id: action.payload.id,
          //   name: action.payload.name
          // }
          action.payload
        ]
      }

    case 'DELETE_CATEGORY':
      return {
        ...state,
        formCategories: state.formCategories.filter((category)=>
          category.id !== action.payload
        )
      }
    
    case 'EDIT_CATEGORY':
      return {
        ...state,
        formCategories: state.formCategories.map((category)=>{
          if(category.id === action.payload.id)
            return{
              ...category,
              name: action.payload.name,
            }
          else
            return category
          
        })
      }

    case 'REFETCH_CATEGORY':
      return {
        ...state,
        formCategories: action.payload
      }


    case 'SET_FORM_DATA':
      console.log("Setting form data", action.payload)
      return {
        ...state,
        originalData: action.payload
      }


    case 'FETCH_CATEGRORIZED_FORM':
      console.log('Changing forms by category ', action.payload)
      return {
        ...state,
        categorizedData: (action.payload.id === 'all_category'? state.originalData : 
          state.originalData.filter((form)=>
            action.payload.customForms?.some((categoryForm)=> form.id === categoryForm.id )

          )
        )
      }

    case 'ADD_FORM':
      console.log('Adding new form', action.payload)
      return{
        ...state,
        originalData: [
          ...state.originalData,
          // {
          //   id: action.payload.id,
          //   name: action.payload.name,
          //   category: action.payload.category,
          //   submissions: '0',
          //   lastModified: new Date().toLocaleDateString()
          // },
          action.payload
        ]
      }

    case 'DELETE_FORM':
      console.log('Deleting form', action.payload)
      return{
        ...state,
        originalData: state.originalData.filter((form)=>
          form.id !== action.payload
        )
      }

    case 'EDIT_FORM':
      console.log('Editing form', action.payload)
      return{
        ...state,
        originalData: state.originalData.map((form)=>{
          if(form.id === action.payload.id)
            return{
              ...form,
              name: action.payload.name,
              //category: action.payload.category,
            }
          else
            return form
          
        })
      }

    case 'CHANGE_FORM_CATEGORY':
      console.log('Adding form to category ', action.payload)
      return{
        ...state,
        formCategories: state.formCategories.map((category)=>{
          if(category.id === action.payload.id)
            return{
              ...category,
              customForms: action.payload.customForms,
            }
          else
            return category
          
        })
      }

    // case 'CLEAR_CATEGORY_FORMDATA':
    //   console.log('Adding form to category ', action.payload)
    //   return{
    //     ...state,
    //     formCategories: state.formCategories.map((category)=>{
    //       if(category.id === action.payload.id)
    //         return{
    //           ...category,
    //           customForms: action.payload.customForms,
    //         }
    //       else
    //         return category
          
    //     })
    //   }

    case 'REFETCH_FORM':
      //console.log('Refetching form data ', action.payload)
      return {
        ...state,
        originalData: action.payload
      }

    case 'TEST':
      console.log('TEST ACTION') 
      return state 

    default:
      return state 
  }
} 

