import { Tab } from "@headlessui/react";
import ColumnSettingTab from "./ColumnSettingTab";
import NewColumnTab from "./NewColumnTab";

const SettingsBar = ({
  open,
  preset,
  setPreset,
  presetsLibrary,
  setPresetsLibary,
}) => {
  console.log("SettingsBar preset:", preset);
  return (
    <aside className={`${open ? "w-1/3" : "w-1 hidden"}`}>
      <Tab.Group as="div" className="w-full z-auto h-full flex flex-col">
        <Tab.List className="flex border-b text-gray-900 font-bold text-lg my-2 py-4 space-x-2 w-full">
          <Tab>
            {({ selected }) => (
              <button
                className={selected ? "border-b-2 border-gray-900 -my-4" : ""}
              >
                Settings
              </button>
            )}
          </Tab>
          <Tab>
            {({ selected }) => (
              <button
                className={selected ? "border-b-2 border-gray-900 -my-4" : ""}
              >
                New Column
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="h-0 flex flex-col flex-grow overflow-auto">
          <Tab.Panel>
            <ColumnSettingTab
              preset={preset}
              setPreset={setPreset}
              presetsLibrary={presetsLibrary}
              setPresetsLibary={setPresetsLibary}
            />
          </Tab.Panel>
          <Tab.Panel>
            <NewColumnTab />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </aside>
  );
};

export default SettingsBar;
