import { defaultPageState, defaultSectionState } from "./StoreProvider"

export const formDataReducer = (state, action) => {

  switch (action.type) {


    case 'TEST':
      console.log('TEST ACTION') 
      return state 

    default:
      return state 
  }
} 

