import React, { useEffect, useState, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import anime from "animejs";
import { BsFillPeopleFill } from "react-icons/bs";
import { API } from "aws-amplify";
import { RiNumbersFill } from "react-icons/ri";
import { MdDescription } from "react-icons/md";

export default function AddOtherDetails({
    unSavedEmails,
    close,
    webAssignee,
    people,
    assigneeId,
    assigneeVal,
    priorityVal,
    notesVal
  }) {
    const modalContainer = useRef(null);
    const modalContent = useRef(null);
    const [notesChanged ,setNotesChanged] = useState("")

    useEffect((e) => {
      console.log("thisssssss", people)
      anime({
        targets: modalContainer.current,
        opacity: [0, 1],
        duration: 100,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: modalContent.current,
            scale: [0.9, 1],
            opacity: [0, 1],
            duration: 100,
            easing: "easeInOutQuad",
          });
        },
      });
    }, []);

    const mSaveAssignees = `mutation saveGmailAssignee($id: String, $assignee: String) {
      gmailMessageUpdate(id: $id, assignee: $assignee) {
        id
      }
    }`;

    //assignee
    const saveAssignee = async (e, gmailId) => {
      let newPeople = "";
  
      if (e.length > 0) {
        for (var i = 0; i < e.length; i++) {
          newPeople = newPeople + `${e[i].label},`; //convert to string
        }
        newPeople = newPeople.substr(0, newPeople.length - 1); //remove last comma
      } else {
        newPeople = "";
      }
  
      unSavedEmails.map((x) => (x.id === gmailId ? (x.assignee = newPeople) : x));
  
      const result = await API.graphql({
        query: mSaveAssignees,
        variables: {
          id: gmailId,
          assignee: newPeople,
        },
      });
    };

    const getAssignee = (data) => {
      if (data) {
        if (data.length > 0 || data !== null || data !== undefined) {
          var people = [];
          //for some cases which returns: undefined,undefined
          try {
            people = data.split(",");
            var temp = [];
            people.map((x) => {
              temp = [...temp, { label: x, value: x }];
            });
            return temp;
          } catch (e) {
            console.error(e);
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    };

    //priority
    const mSavePriority = 
    `mutation updateGmailMessage($id: String, $priority: Int, $notes: String) {
      gmailMessageUpdate(id: $id, priority: $priority, notes: $notes) {
        id
      }
    }`;

    const savePriority = async (e, gmailId) => {
      let newPriority = parseInt(e.value, 10);

      unSavedEmails.map((x) => (x.id === gmailId ? (x.priority = newPriority) : x));

      const result = await API.graphql({
        query: mSavePriority,
        variables: {
          id: gmailId,
          priority: newPriority,
        },
      });
    };

    //notes
    const mSaveNotes = 
    `mutation updateGmailMessage($id: String, $priority: Int, $notes: String) {
      gmailMessageUpdate(id: $id, priority: $priority, notes: $notes) {
        id
      }
    }`;

    const saveNotes = async (gmailId) => {
      let newNotes = notesChanged;

      unSavedEmails.map((x) => (x.id === gmailId ? (x.notes = newNotes) : x));

      const result = await API.graphql({
        query: mSaveNotes,
        variables: {
          id: gmailId,
          notes: newNotes,
        },
      });

      console.log("save notes", result);
    };

    const handleChangeNotes = (e) => {
      setNotesChanged(e.target.innerHTML);
    }

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="p-5 flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen w-96 overflow-y-scroll"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-row">
            <button
              onClick={() => {
                close();
              }}
              className="ml-auto h-8 w-4 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300"
            >
              <svg
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z"
                  fill="#8A8A8A"
                />
              </svg>
            </button>
          </div>
          <p className="text-gray-900 text-lg font-semibold pb-4">
            Tasklist Options
          </p>
          <div className={"flex  items-center " }>
            <BsFillPeopleFill className=""/>
            <CreatableSelect
                    options={people}
                    defaultValue={() => getAssignee(assigneeVal) }
                    popperProps={{
                      positionFixed: true,
                    }}
                    openMenuOnClick={true}
                    noOptionsMessage={() => "No result found"}
                    isValidNewOption={() => false}
                    menuPlacement="bottom"
                    menuPortalTarget={document.body}
                    menuShouldScrollIntoView={true}
                    placeholder="Select Assignee"
                    isMulti
                    styles={{
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="ml-4 w-64 placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring"
                    onChange={(e) => saveAssignee(e, assigneeId)}
            />
          </div>
          <br/>
          <div className={"flex  items-center " }>
            <RiNumbersFill className=""/>
            <CreatableSelect
              options={[{value: 1, label: 1}, {value: 2, label: 2}, {value: 3, label: 3}]}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              defaultValue={priorityVal !== null ? {label: priorityVal, value: priorityVal} : {label: 1, value: 1}}
              className="ml-4 w-64 placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring"      
              noOptionsMessage={() =>"No result found"}
              isValidNewOption={() =>false}
              menuPlacement="top"
              menuPortalTarget={document.body}
              menuShouldScrollIntoView={true}
              placeholder="Select Priority"
              onChange={(e) => savePriority(e, assigneeId)}
            />
          </div>
          <br/>
          <div className={"flex  items-center " }>
            <MdDescription/>
            <div
              className={"p-2 ml-4 w-64 h-full font-poppins rounded-sm float-right whitespace-normal" }
              style={{
                border: "solid 1px #c4c4c4",
                cursor: "auto",
                outlineColor: "rgb(204, 204, 204, 0.5)",
                outlineWidth: "thin",
              }}
              suppressContentEditableWarning
              dangerouslySetInnerHTML={{
                __html: notesVal != null ? notesVal : "",
              }}
              onBlur={(e) => saveNotes(assigneeId)}
              onInput={(e) => handleChangeNotes(e)}
              contentEditable={true}
            ></div>
          </div>

          <button
                onClick={()=>{close()}}
                className={`ml-auto mt-8 mr-auto text-white font-bold p-2 lg:px-4 rounded flex gap-2 items-center justify-center bg-green-400`}
            >
                <span className="hidden lg:block font-bold">Save</span>
          </button>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>

    // <div>
    //   <div
    //     className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
    //     style={{ zIndex: 70 }}
    //   >
    //     <div className="relative w-full my-6 mx-auto max-w-md">
    //       <div className=" items-center border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      
    //         <div className="relative p-6 flex-auto items-center">     

    //             <CreatableSelect
    //                 // options={people}
    //                 // defaultValue={() => getAssignee(item.assignee) }
    //                 openMenuOnClick={true}
    //                 style={{ zIndex: 99 }}
    //                 noOptionsMessage={() => "No result found"}
    //                 isValidNewOption={() => false}
    //                 menuPlacement="bottom"
    //                 menuPortalTarget={document.body}
    //                 menuShouldScrollIntoView={true}
    //                 placeholder="Select Assignee"
    //                 isMulti
    //                 className="w-full placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring z-100"
    //                 // onChange={(e) => saveAssignee(e, item.id)}
    //             />
    //         </div>
    //         <button
    //             onClick={()=>close()}
    //             className={`ml-auto mt-8 mr-auto text-white font-bold p-2 lg:px-4 rounded flex gap-2 items-center justify-center bg-red-400`}
    //         >
    //             <span className="hidden lg:block font-bold">Close</span>
    //         </button>
    //       </div>
    //     </div>
        
    //   </div>
    //   <div
    //     className="opacity-25 fixed inset-0 bg-black"
    //     style={{ zIndex: 60 }}
    //   ></div>
    // </div>
  );
}
