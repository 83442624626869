import React, { useEffect, useRef, useState } from "react";
import { API } from "aws-amplify";
import AboutTab from "../contact-information-modal-tabs/about";
import ClientMatterTab from "../contact-information-modal-tabs/clientmatter";
import { FiEdit } from "react-icons/fi";
import TeamTab from "../contact-information-modal-tabs/team";
import anime from "animejs";
import Button from "../../../shared/Button";
import { isMobileDevice } from "../../../shared/mobileViewFunctions";
import { CgClose, CgTrash } from "react-icons/cg";
import ToastNotification from "../../toast-notification";

const ExitButton = ({ close }) => {
  return (
    <button
      onClick={() => {
        close();
      }}
      className="h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300 absolute top-5 right-5"
    >
      <CgClose />
    </button>
  );
};
const Tabs = [
  "Client",
  "Solicitor",
  "Barrister",
  "Expert",
  "Other Party",
  "Other Party Solicitor",
];

export default function MatterInformationModal({
  close,
  matter,
  width,
  setMatter,
  getMatters,
  matters,
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  const [isEditing, setisEditing] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [SelectedTab, setSelectedTab] = useState("Client");
  const [loaded, setLoaded] = useState(false);

  const mcreateClientMatterContacts = `
  mutation createClientMatterContacts($type: ContactType, $clientMatterId: String, $details: ContactDetailsInput) {
    clientMatterContactDetailsCreate(
      clientMatterId: $clientMatterId
      type: $type
      details: $details
    ) {
      id
    }
  }`;

  const mupdateClientMatterContacts = `
  mutation updateClientMatterContacts($id: ID, $details: ContactDetailsInput) {
    clientMatterContactDetailsUpdate(id: $id, details: $details) {
      id 
      name
      email
      number
    }
  }
  `;

  const mdeleteClientMatterContacts = `
  mutation deleteClientMatterContacts($id: ID) {
    clientMatterContactDetailsDelete(id: $id)
  }`;

  console.log("User Details", matter);

  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
    console.log("matter ", matter);
    //initContacts();
  }, []);

  useEffect(() => {
    console.log("Matters UEffect", matters);
    console.log("Current Matter ID", matter.id);
    console.log(
      "Get Matter index from list by ID: ",
      Array.from(matters).findIndex((e) => {
        return e.id === matter.id;
      })
    );
    setMatter(
      matters[
        Array.from(matters).findIndex((e) => {
          return e.id === matter.id;
        })
      ]
    );
  }, [matters]);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
        setErrorMessage("");
      }, 5000);
    }
  }, [showToast]);

  const updateClientMatterContacts = async (contactId, details, inputType) => {
    console.log("contactId", contactId);
    console.log("details", details);
    try {
      const updateClientMatter = await API.graphql({
        query: mupdateClientMatterContacts,
        variables: {
          id: contactId,
          details: details,
        },
      }).then((result) => {
        console.log("success", result);
        getMatters();
      });
    } catch {}
  };

  function cleanUpWhiteSpace(event) {
    let returnable = event.target.value.replace(/\s+/g, " ");
    if (returnable === " ") {
      returnable = "";
    }

    return returnable;
  }

  const isValidEmail = (email) => {
    // Regex pattern to validate email addresses
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email.target.value) && email.target.value !== "") {
      email.target.value = "";
      setShowToast(true);
      setErrorMessage("Please enter a valid email address.");
    }
    return email.target.value;
  };

  const isValidNumber = (number) => {
    const reg = new RegExp("^[0-9]+$");
    if (!reg.test(number.target.value) && number.target.value !== "") {
      number.target.value = "";
      setShowToast(true);
      setErrorMessage("Please enter a valid phone number.");
    }
    return number.target.value;
  };

  const handleChange = (event, contactId, inputType) => {
    event.preventDefault();
    const nameProps = event.target.name.toString();
    const details =
      event.target.name === "name"
        ? {
            name: event.target.value,
          }
        : event.target.name === "email"
        ? {
            email: isValidEmail(event),
          }
        : event.target.name === "number"
        ? {
            number: isValidNumber(event),
          }
        : null;
    console.log("Change contactId: ", contactId);
    console.log("Change details: ", details);
    updateClientMatterContacts(contactId, details, inputType);
  };

  async function addContact(ContactType) {
    const details = {
      name: "",
      number: "",
      email: "",
    };
    try {
      const createClientMatter = await API.graphql({
        query: mcreateClientMatterContacts,
        variables: {
          type: ContactType,
          clientMatterId: matter.id,
          details: details,
        },
      }).then((response) => {
        console.log("Create Contact: ", response);
        getMatters();
      });
    } catch {}
  }

  const deleteClientMatterContacts = async (contactId) => {
    try {
      const createClientMatter = await API.graphql({
        query: mdeleteClientMatterContacts,
        variables: {
          id: contactId,
        },
      }).then((response) => {
        console.log("Delete Matter: ", response);
      });
    } catch {}
    getMatters();
  };

  const handleDelete = (event, contactId) => {
    event.preventDefault();
    const deletePrompt = window.confirm(
      "Are you sure you want to delete this matter?"
    );
    if (deletePrompt) {
      deleteClientMatterContacts(contactId);
    }
  };

  const MiniNav = () => {
    return Tabs.map((tab, idx) => (
      <button
        key={idx}
        onClick={() => setSelectedTab(tab)}
        className={
          SelectedTab === tab
            ? "font-medium text-gray-900  cursor-pointer"
            : "font-medium text-gray-400  hover:text-black hover:border-cyan-500  cursor-pointer"
        }
      >
        {tab}
      </button>
    ));
  };

  const EditMatterButton = () => {
    return isMobileDevice(width) ? (
      <FiEdit onClick={() => setisEditing(true)} />
    ) : (
      <Button
        variant="primary"
        onClick={() => setisEditing(true)}
        className="ml-auto gap-2  px-4 py-1.5 "
      >
        Edit Matter <FiEdit />
      </Button>
    );
  };

  const CancelButton = () => {
    return (
      <Button
        variant="default"
        onClick={() => setisEditing(false)}
        className="ml-auto gap-2 px-4 py-1.5 "
      >
        Finish Editing <FiEdit />
      </Button>
    );
  };

  const initContacts = (currentMatter) => {
    const emptyArray = [];

    if (currentMatter.contacts.items.length === 0) {
      emptyArray.push("CLIENT");
      emptyArray.push("SOLICITOR");
      emptyArray.push("BARRISTER");
      emptyArray.push("EXPERT");
      emptyArray.push("OTHERPARTY");
      emptyArray.push("OTHERPARTY_SOLICITOR");
    }
    console.log(emptyArray);
    if (emptyArray.length > 0) {
      Promise.all(
        emptyArray.map((value) => createInitialContact(value, currentMatter.id))
      ).then(() => getMatters());
    }
    getMatters();
  };

  async function createInitialContact(ContactType, matterId) {
    const details = {
      name: "",
      number: "",
      email: "",
    };
    try {
      const createClientMatter = await API.graphql({
        query: mcreateClientMatterContacts,
        variables: {
          type: ContactType,
          clientMatterId: matterId,
          details: details,
        },
      }).then((response) => {
        console.log("Create Contact: ", response);
      });
    } catch {}
  }

  useEffect(() => {
    if (matter.contacts.items.length === 0) {
      initContacts(matter);
    }
  }, []);

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-black bg-opacity-60"
        style={{ zIndex: 1000 }}
      >
        <div
          ref={modalContent}
          className="p-10 flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen overflow-y-auto relative"
          onClick={(e) => e.stopPropagation()}
          style={{ zIndex: 1001 }}
        >
          {showToast && (
            <ToastNotification error={showToast} title={errorMessage} />
          )}
          {/*Header*/}
          <div className={`flex flex-row semi`}>
            <div className="font-medium text-base">Matter Information</div>
            <ExitButton close={close} />
          </div>
          <div className="">Edit Matter Contacts</div>

          {/*Profile*/}
          <div className="flex flex-row items-start py-8 gap-4">
            <div className={`flex flex-col justify-start gap-1 items-start`}>
              <div className="font-semibold text-xl">
                {matter.client.name}/{matter.matter.name}
              </div>
            </div>

            <div className="ml-auto">
              {isEditing ? <CancelButton /> : <EditMatterButton />}
            </div>
          </div>
          {/*MiniNav */}
          <div className="flex flex-col">
            <div className="flex flex-row gap-5 py-3">
              <MiniNav />
            </div>
            <div className="flex flex-row mb-4 w-full h-0.5">
              <div
                className={
                  SelectedTab === "Client"
                    ? "bg-cyan-500 w-12"
                    : "bg-gray-300 w-12"
                }
              ></div>
              <div
                className={
                  SelectedTab === "Solicitor"
                    ? "bg-cyan-500  w-20"
                    : "bg-gray-300  w-20"
                }
              ></div>
              <div
                className={
                  SelectedTab === "Barrister"
                    ? "bg-cyan-500  w-20"
                    : "bg-gray-300  w-20"
                }
              ></div>
              <div
                className={
                  SelectedTab === "Expert"
                    ? "bg-cyan-500  w-16"
                    : "bg-gray-300  w-16"
                }
              ></div>
              <div
                className={
                  SelectedTab === "Other Party"
                    ? "bg-cyan-500  w-24"
                    : "bg-gray-300  w-24"
                }
              ></div>
              <div
                className={
                  SelectedTab === "Other Party Solicitor"
                    ? "bg-cyan-500  w-40"
                    : "bg-gray-300  w-40"
                }
              ></div>
              <div className="bg-gray-300 w-60"></div>
            </div>
          </div>
          <div className="m-2">
            {SelectedTab === "Client" && (
              <div>
                {matter.contacts.items.length === 0 ? (
                  <>
                    <p className="font-normal">Name:</p>
                    <p className="font-normal">Number:</p>
                    <p className="font-normal">Email:</p>
                  </>
                ) : (
                  <>
                    {matter.contacts.items.map((contact) => (
                      <>
                        {contact.type === "CLIENT" ? (
                          <>
                            {contact.details.map((detail) => (
                              <>
                                <div className="flex w-full">
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Client Name:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="name"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.name}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Client Number:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="number"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.number}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Client Email:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="email"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.email}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-1/12 items-center justify-end pb-3">
                                    <Button
                                      disabled={!isEditing}
                                      variant="danger"
                                      onClick={(e) => {
                                        handleDelete(e, detail.id);
                                      }}
                                    >
                                      <CgTrash />
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            )}
            {SelectedTab === "Solicitor" && (
              <div>
                {matter.contacts.items.length === 0 ? (
                  <>
                    <p className="font-normal">Name:</p>
                    <p className="font-normal">Number:</p>
                    <p className="font-normal">Email:</p>
                  </>
                ) : (
                  <>
                    {matter.contacts.items.map((contact) => (
                      <>
                        {contact.type === "SOLICITOR" ? (
                          <>
                            {contact.details.map((detail) => (
                              <>
                                <div className="flex w-full">
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Solicitor Name:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="name"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.name}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Solicitor Number:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="number"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.number}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Solicitor Email:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="email"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.email}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-1/12 items-center justify-end pb-3">
                                    <Button
                                      disabled={!isEditing}
                                      variant="danger"
                                      onClick={(e) => {
                                        handleDelete(e, detail.id);
                                      }}
                                    >
                                      <CgTrash />
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            )}
            {SelectedTab === "Barrister" && (
              <div>
                {matter.contacts.items.length === 0 ? (
                  <>
                    <p className="font-normal">Name:</p>
                    <p className="font-normal">Number:</p>
                    <p className="font-normal">Email:</p>
                  </>
                ) : (
                  <>
                    {matter.contacts.items.map((contact) => (
                      <>
                        {contact.type === "BARRISTER" ? (
                          <>
                            {contact.details.map((detail) => (
                              <>
                                <div className="flex w-full">
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Barrister Name:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="name"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.name}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Barrister Number:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="number"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.number}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Barrister Email:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="email"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.email}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-1/12 items-center justify-end pb-3">
                                    <Button
                                      disabled={!isEditing}
                                      variant="danger"
                                      onClick={(e) => {
                                        handleDelete(e, detail.id);
                                      }}
                                    >
                                      <CgTrash />
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            )}
            {SelectedTab === "Expert" && (
              <div>
                {matter.contacts.items.length === 0 ? (
                  <>
                    <p className="font-normal">Name:</p>
                    <p className="font-normal">Number:</p>
                    <p className="font-normal">Email:</p>
                  </>
                ) : (
                  <>
                    {matter.contacts.items.map((contact) => (
                      <>
                        {contact.type === "EXPERT" ? (
                          <>
                            {contact.details.map((detail) => (
                              <>
                                <div className="flex w-full">
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Expert Name:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="name"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.name}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Expert Number:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="number"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.number}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Expert Email:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="email"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.email}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-1/12 items-center justify-end pb-3">
                                    <Button
                                      disabled={!isEditing}
                                      variant="danger"
                                      onClick={(e) => {
                                        handleDelete(e, detail.id);
                                      }}
                                    >
                                      <CgTrash />
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            )}
            {SelectedTab === "Other Party" && (
              <div>
                {matter.contacts.items.length === 0 ? (
                  <>
                    <p className="font-normal">Name:</p>
                    <p className="font-normal">Number:</p>
                    <p className="font-normal">Email:</p>
                  </>
                ) : (
                  <>
                    {matter.contacts.items.map((contact) => (
                      <>
                        {contact.type === "OTHERPARTY" ? (
                          <>
                            {contact.details.map((detail) => (
                              <>
                                <div className="flex w-full">
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Other Party Name:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="name"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.name}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Other Party Number:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="number"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.number}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Other Party Email:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="email"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.email}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-1/12 items-center justify-end pb-3">
                                    <Button
                                      disabled={!isEditing}
                                      variant="danger"
                                      onClick={(e) => {
                                        handleDelete(e, detail.id);
                                      }}
                                    >
                                      <CgTrash />
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            )}
            {SelectedTab === "Other Party Solicitor" && (
              <div>
                {matter.contacts.items.length === 0 ? (
                  <>
                    <p className="font-normal">Name:</p>
                    <p className="font-normal">Number:</p>
                    <p className="font-normal">Email:</p>
                  </>
                ) : (
                  <>
                    {matter.contacts.items.map((contact) => (
                      <>
                        {contact.type === "OTHERPARTY_SOLICITOR" ? (
                          <>
                            {contact.details.map((detail) => (
                              <>
                                <div className="flex w-full">
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Other Party Solicitor Name:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="name"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.name}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Other Party Solicitor Number:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="number"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.number}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-4/12">
                                    <label className="font-medium">
                                      Other Party Solicitor Email:
                                    </label>
                                    <input
                                      type="text"
                                      id={`${detail.id}-name`}
                                      key={`${detail.id}-name`}
                                      disabled={!isEditing}
                                      name="email"
                                      className="font-normal border-gray-300 border-2 rounded h-10 pl-1"
                                      defaultValue={detail.email}
                                      onChange={(event) => {
                                        // Prevents user from entering spaces (MMA-2083)
                                        let i = cleanUpWhiteSpace(event);
                                        event.target.value = i;
                                      }}
                                      onBlur={(e) => handleChange(e, detail.id)}
                                    ></input>
                                  </div>
                                  <div className="flex flex-col m-1 w-1/12 items-center justify-end pb-3">
                                    <Button
                                      disabled={!isEditing}
                                      variant="danger"
                                      onClick={(e) => {
                                        handleDelete(e, detail.id);
                                      }}
                                    >
                                      <CgTrash />
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="w-full flex justify-center p-2">
            <Button
              variant="primary-l"
              size="medium"
              disabled={!isEditing}
              onClick={() => {
                let type =
                  SelectedTab === "Client"
                    ? "CLIENT"
                    : SelectedTab === "Solicitor"
                    ? "SOLICITOR"
                    : SelectedTab === "Barrister"
                    ? "BARRISTER"
                    : SelectedTab === "Expert"
                    ? "EXPERT"
                    : SelectedTab === "Other Party"
                    ? "OTHERPARTY"
                    : SelectedTab === "Other Party Solicitor"
                    ? "OTHERPARTY_SOLICITOR"
                    : null;

                addContact(type);
              }}
            >
              Add Contact
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
