import React, { useState, useRef, useEffect } from "react";
import { API } from "aws-amplify";
import "react-datepicker/dist/react-datepicker.css";
import momentTZ from "moment-timezone";
import { dateFormatter } from "../../shared/userPreferredDateTime";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import { AiOutlineDownload } from "react-icons/ai";
import { PiChartLineUpBold } from "react-icons/pi";
import ProgressPhotosModal from "../hba-dashboard/right-panel-progress-photos.jsx";
import { Storage } from "aws-amplify";

/**
 * Component for rendering variations content
 * @param {Object} props - Props object
 * @param {Object} props.background - Background object
 * @param {Object} props.style - Style object
 * @param {boolean} props.readMoreState - Read more state
 * @param {function} props.isReadMoreExpanded - Function to check if read more is expanded
 * @param {function} props.setSaving - Function to set saving state
 * @param {function} props.getRowLabels - Function to get row labels
 * @param {function} props.handleDescContent - Function to handle description content
 * @param {function} props.handleReadMoreState - Function to handle read more state
 * @param {function} props.handleChangeDesc - Function to handle change in description
 * @param {function} props.handleSaveDesc - Function to handle saving description
 * @param {function} props.handleSnippetEmail - Function to handle snippet email
 * @param {Array} props.virtualRows - Array of virtual rows
 * @param {Array} props.rows - Array of rows
 */

const mCreateBackground = `
  mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
    backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
      id
      createdAt
      date
      description
      order
      briefs {
      id
      name
      }
    }
  }
`;

const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`
;

const BACKGROUNDFILE_TAG_MUTATION = `
  mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileTag(backgroundId: $backgroundId, files: $files) {
    id
    }
  }`
;

const mUpdateBackground = `
  mutation updateBackground($id: ID, $description: String, $date: AWSDateTime) {
    backgroundUpdate(id: $id, description: $description, date: $date) {
      id
      description
      date
    }
  }`
;

const mBulkCreateMatterFile = `
  mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
    matterFileBulkCreate(files: $files) {
      id
      name
      order
    }
  }`
;

const qlistBackgroundFiles = `
  query getBackgroundByID($id: ID) {
    background(id: $id) {
    id
    files {
      items {
      createdAt
      id
      details
      name
      order
      }
    }
    labels {
      items {
      id
      name
      }
    }
    }
  }`
  ;

  const qGetFileDownloadLink = `query getFileDownloadLink($id: ID) {
    file(id: $id) {
      name
      downloadURL
      s3ObjectKey
      type
    }
  }`;

const ProgressPhotosContentComponent = ({
  background,
  style,
  readMoreState,
  isReadMoreExpanded,
  setSaving,
  getRowLabels,
  handleDescContent,
  handleReadMoreState,
  handleChangeDesc,
  handleSaveDesc,
  handleSnippetEmail,
  virtualRows,
  rows,
  setShowToast,
  setalertMessage,
  setBackground,
  matterId,
  briefs,
}) => {
  const progressPhotoBrief = briefs.find((brief) => brief.name === "Progress Photos");

  const { width, height } = useWindowDimensions()
  const [openMoreLabels, setOpenMoreLabels] = useState(false);
  const [openMoreLabelsIndex, setOpenMoreLabelsIndex] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // PROGRESS PHOTOS MODAL
  const [showModal, setShowModal] = useState(false)
  const [uploadImage, setUploadImage] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [selectedFiles, _setSelectedFiles] = useState([]);
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [datePicked, setDatePicked] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedBackground, setSelectedBackground] = useState(null);

  // FILE UPLOAD STATE
  const [selectedRowId, setSelectedRowId] = useState("");

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);

  const myCurrentRef = useRef(selectedFiles);
  const setSelectedFiles = (data) => {
    myCurrentRef.current = data;
    _setSelectedFiles(data);
  };

  const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; //list of rejected files

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const file = files[0];
      if (!isImageFile(file.name)) {
        alert(
          `File ${file.name} is not an image. Only images can be uploaded.`
        );
        setImageURL(null); // Set image URL back to null
        return;
      }

      setShowModal(true);
      console.log("Images uploaded are " + file.name);
      setImageURL(URL.createObjectURL(file));
      setUploadImage(files);
    }
  };

  //Check if file is image file
  const isImageFile = (fileName) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp", ".svg", ".avif", ".apng"];
    const extension = fileName
      .toLowerCase()
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    return imageExtensions.includes("." + extension);
  };

  const onSelectFile = (retrievedFiles) => {
    console.log("You are here " + retrievedFiles);
    if (!retrievedFiles || retrievedFiles.length === 0) {
      return;
    }
    const tempArr = [];

    [...retrievedFiles].forEach((file) => {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];

      const result = rejectFiles.find((item) =>
        item.includes(re.exec(file.name)[0])
      );
      const fileSize = file.size;

      if (result || fileSize > 2147483648) {
        invalidFiles.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      } else {
        tempArr.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      }
    });

    setSelectedFiles([...myCurrentRef.current, ...tempArr]);
  };

  const uploadAndCheck = async () => {
    if (
      uploadedFiles.files.length === selectedFiles.length &&
      selectedFiles.length !== 0
    ) {
      const result = await handleUploadLink(uploadedFiles, progressPhotoBrief);
      console.log(result);
      //setProgressPhotosDone(true);
      if (result === "done") {
        setShowModal(false);
      }
    }
  };

  //UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
  const handleUploadLink = async (uf, brief) => {
    await Promise.all(
      uf?.files.map(async (file) => {
        // console.log("Uploaded file - ", file);
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matterId,
            briefId: brief?.id,
            activity: `added a background file named ${file.name}-(${file.size} byte)`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: selectedRowId,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          // console.log("addActivity result", result);
        });
      })
    );
    // console.log("handleUploadLink uf", uf);
    var uploadedFiles = uf.files.map((f) => ({ ...f, matterId: matterId }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
    // console.log("SF",sortedFiles);
    // console.log("AO", addOrder);

    //insert in matter file list
    bulkCreateMatterFile(addOrder);

    //set background content

    const backgroundFilesOptReq = API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: selectedRowId,
      },
    }).then((result) => {
      var newFilesResult = result.data.background.files.items.map(
        ({ createdAt, id, name, description, order }) => ({
          createdAt: createdAt,
          id: id,
          name: name,
          description: description,
          order: order,
        })
      );

      // Sort the newFilesResult array by createdAt in descending order
      newFilesResult?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      console.log(newFilesResult);

      var updateArrFiles = background.map((obj) => {
        if (obj.id === selectedRowId) {
          return { ...obj, files: { items: newFilesResult } };
        }
        return obj;
      });

      // Update order number based on uploaded file
      updateArrFiles.forEach((item) => {
        const orderFilesPerBackground = item?.files?.items?.map(
          ({ id }, index) => ({
            id: id,
            order: index + 1,
          })
        );

        // For FE purposes: Update existing background array with the updated order data
        const orderFilesPerBackgroundFE = item?.files?.items?.map(
          (
            {
              id,
              details,
              gmailMessage,
              gmailMessageId,
              isGmailAttachment,
              isGmailPDF,
              name,
              s3ObjectKey,
            },
            index
          ) => ({
            id: id,
            name: name,
            details: details,
            gmailMessage: gmailMessage,
            gmailMessageId: gmailMessageId,
            isGmailAttachment: isGmailAttachment,
            isGmailPDF: isGmailPDF,
            s3ObjectKey: s3ObjectKey,
            order: index + 1,
          })
        );

        if (
          orderFilesPerBackground !== undefined &&
          orderFilesPerBackground !== null
        ) {
          item.files.items = orderFilesPerBackgroundFE;
          // updateBackgroundFilesOrder(orderFilesPerBackground);
          console.log("Items", orderFilesPerBackground);
        }
      });

      console.log("Set New Background", updateArrFiles);
      setBackground(updateArrFiles);
    });

    setalertMessage(
      `Photo has been uploaded! View it at Progress Photos Background`
    );
    setShowToast(true);
    // setGoToFileBucket(true);

    if (uploadStart == false) {
      setTimeout(() => {
        window.location.reload();
        setShowToast(false);
      }, 1500);
    }
    //getBackground();
    return "done";
  };

  const handleUpload = async (bucketName) => {
    setUploadStart(true);
    if (!saveButtonClicked) {
      setSaveButtonClicked(true);
    }
    var tempArr = [];
    var temp = [];
    selectedFiles.forEach((uf) => {
      if (isImageFile(uf.data.name)) {
        tempArr = [...tempArr, uf];
        selectedBackground
          ? handleProgressPhotoUpdate({ datePicked, description })
          : handleProgressPhotoUpload({ datePicked, description });
      } else {
        alert(
          `File ${uf.data.name} is not an image. Only images can be uploaded.`
        );
        setImageURL(null); // Set image URL back to null
        setSaveButtonClicked(false);
        return;
      }
    });
    setSelectedFiles(tempArr);
    _setSelectedFiles(tempArr);
    var idxx = 0;
    tempArr.map(async (uf, index) => {
      if (uf.data.name.split(".").pop() == "docx") {
        var name = uf.data.name,
          size = uf.data.size,
          type =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          key = `${bucketName}/${Number(new Date())}${name
            ?.replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      } else {
        var name = uf.data.name,
          size = uf.data.size,
          type = uf.data.type,
          key = `${bucketName}/${Number(new Date())}${name
            ?.replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      }
      idxx = idxx + 1;

      try {
        await Storage.put(key, uf.data, {
          contentType: type,
          progressCallback(progress) {
            const progressInPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            //console.log(`Progress: ${progressInPercentage}%, ${uf.data.name}`);

            if (temp.length > selectedFiles.length) {
              for (var i = 0; i < selectedFiles.length; i++) {
                console.log(uf.data.name === temp[i].name);
                if (temp[i].name === uf.data.name) {
                  temp[i].prog = progressInPercentage;
                }
              }
            } else {
              temp = [
                ...temp,
                { prog: progressInPercentage, name: uf.data.name },
              ];
            }
            //console.log(temp);
          },
          errorCallback: (err) => {
            console.error("204: Unexpected error while uploading", err);
          },
        })
          .then((fd) => {
            var fileData = {
              s3ObjectKey: fd.key,
              size: parseInt(size),
              type: type,
              name: name.split(".").slice(0, -1).join("."),
              oderSelected: orderSelected,
              order: orderSelected,
            };

            setUploadedFiles((prevState) => ({
              files: [...prevState.files, fileData],
            }));
          })
          .catch((err) => {
            console.error("220: Unexpected error while uploading", err);
          });
      } catch (e) {
        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };
        console.error("228: Unexpected error while uploading", response);
      }
      setUploadStart(false);
    });
  };

  const handleProgressPhotoUpload = async ({ datePicked, description }) => {
    let uploadToBrief = await API.graphql({
      query: mCreateBackground,
      variables: {
        briefs: [
          {
            id: progressPhotoBrief.id,
            name: progressPhotoBrief.name,
          },
        ],
        description: description !== null ? description : "",
        comments: "",
        date: datePicked,
        order: 1,
      },
    });
    console.log("Uploaded to Brief is:", uploadToBrief);
    setSelectedRowId(uploadToBrief.data.backgroundCreate?.id);
  };

  const handleProgressPhotoUpdate = async ({ datePicked, description }) => {
    let updateBackground = await API.graphql({
      query: mUpdateBackground,
      variables: {
        id: selectedBackground.id,
        description: description !== null ? description : "",
        date: datePicked,
      },
    });
    setSelectedRowId(selectedBackground.id);
  };

  async function bulkCreateMatterFile(param) {
    console.log("bulkCreateMatterFile");
    let idTag = [];

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    setTimeout(async () => {
      const request = await API.graphql({
        query: mBulkCreateMatterFile,
        variables: {
          files: param,
        },
      });

      console.log("result", request);

      if (request.data.matterFileBulkCreate !== null) {
        request.data.matterFileBulkCreate.map((i) => {
          return (idTag = [...idTag, { id: i.id }]);
        });
      }

      console.log("iDTag", idTag);

      let arrFiles = [];
      let arrFileResult = [];
      const seen = new Set();

      // console.log("MID/BID", background_id);

      const backgroundFilesOpt = await API.graphql({
        query: qlistBackgroundFiles,
        variables: {
          id: selectedRowId,
        },
      });

      console.log("CHECKDATA", backgroundFilesOpt);

      if (backgroundFilesOpt.data.background.files !== null) {
        arrFileResult = backgroundFilesOpt.data.background.files.items.map(
          ({ id }) => ({
            id: id,
          })
        );

        idTag.push(...arrFileResult);
        console.log("updatedidtag", idTag);

        const filteredArr = idTag.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });

        console.log("rowid", selectedRowId);
        console.log("filteredArr", filteredArr);
        console.log("selectedRowId", selectedRowId);

        API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: selectedRowId,
            files: filteredArr,
          },
        });
      } else {
        API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: selectedRowId,
            files: idTag,
          },
        });
      }
    }, 1000);
    //return request;
  }

  useEffect(() => {
    var counter = 0;
    var dCounter = 0;
    if (uploadImage.length > 0 && counter === 0) {
      onSelectFile(uploadImage);
      counter = 1;
    }
  }, [uploadImage]);

  useEffect(() => {
    if (uploadStart == false) {
      uploadAndCheck();
    }
  }, [selectedFiles, uploadedFiles, uploadStart]);

  useEffect(async () => {
    if (selectedBackground) {
      setDescription(selectedBackground?.description);
      // setDatePicked(new Date(selectedBackground?.date));
      
      let progressPhotoFile = selectedBackground?.files?.items[0];
      if (progressPhotoFile) {
        let response = await API.graphql({
          query: qGetFileDownloadLink,
          variables: {
            id: progressPhotoFile.id,
          },
        });
        setImageURL(response?.data?.file?.downloadURL);
      } else {
        setImageURL(null)
      }
    }
  }, [selectedBackground])

  useEffect(() => {
    document.getElementById("root").style.overflow = showModal 
      ? "hidden"
      : "auto"

  }, [showModal])

  return (
    <div>
      <div>
        {virtualRows.map((virtualRow) => {
          const row = rows[virtualRow.index];
          const item = row.original;
          const index = row.index;

          const visibleFiles = item.files.items.slice(
            0,
            isExpanded ? undefined : 1
          );
          const hiddenFilesCount = item.files.items.length - visibleFiles.length;

          let fileList = [];
          item?.files?.items?.forEach((row) => {
            if (row.gmailMessage?.gmailLabelIds?.includes("SENT")) {
              fileList.push("Sent");
            } else if (row.gmailMessage?.gmailLabelIds?.includes("INBOX")) {
              fileList.push("Inbox");
            } else if (row.gmailMessage === null || row.gmailMessageId === "") {
              fileList.push("Uploaded");
            }
          });
          fileList = fileList.filter(
            (item, index) => fileList.indexOf(item) === index
          );
          //console.log("FILE LIST: ", fileList);
          let gmailStatus = "";
          for (let i = 0; i < fileList.length; i++) {
            gmailStatus = gmailStatus + fileList[i];
            if (i !== fileList.length - 1) {
              gmailStatus = gmailStatus + " / ";
            }
          }
          
          return (
            <div
              key={item.id}
              className="flex flex-row w-full px-5"
              style={{
                ...style,
                width: "100%",
                borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
                borderBottomStyle: "solid",
                paddingTop: index === 0 ? 0 : 20,
                paddingBottom: 20,
                maxHeight: "350px",
              }}

            >
              <p className="text-xs">{index + 1}</p>
              <div className="ml-2 overflow-y-auto">
                {/* Date */}
                <p className="text-xs pb-1">
                  {item.date !== null && item.date !== ""
                    ? dateFormatter(item.date)
                    : "No date"}
                </p>

                {/* Description */}
                <p
                  id={item.id + ".descM"}
                  className={`overflow-y-auto text-xs pb-1 ${
                    isReadMoreExpanded(item.id) ? "" : "line-clamp-6"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                  style={{
                    wordBreak: "break-word",
                    outlineColor: "rgb(204, 204, 204, 0.5)",
                    outlineWidth: "thin",
                  }}
                  contentEditable={true}
                  suppressContentEditableWarning
                  onClick={(event) => {
                    handleDescContent(event, item.description, item.id, index);
                    setSaving(true);
                  }}
                  onInput={(event) => handleChangeDesc(event, index)}
                  onBlur={(e) => {
                    handleSaveDesc(
                      e,
                      item.description,
                      item.date,
                      item.id,
                      index
                    );
                    setSaving(false);
                  }}
                ></p>

                {/* Files List */}
                <div className="text-left">
                  {visibleFiles.map((file, index) => (
                    <div key={file.id} className="items-left">
                      <button
                        className="font-extralight text-sm focus:text-cyan-400 focus:border-cyan-400 rounded-lg pt-1 pb-1 flex-1"
                        onClick={() => handleSnippetEmail(file)}
                      >
                        <span className="font-bold text-xs">
                          {file.name.length > 30
                            ? file.name.slice(0, 30) + "..."
                            : file.name}
                          &nbsp;
                        </span>
                        {/* <AiOutlineDownload className="text-sm cursor-pointer inline-block" /> */}
                      </button>
                      {index === 0 && hiddenFilesCount > 0 && (
                        <button
                          className="text-sm focus:text-cyan-400 focus:border-cyan-400 rounded-lg px-1 ml-2"
                          onClick={handleExpandToggle}
                        >
                          {isExpanded ? "-" : "+"} {hiddenFilesCount}
                        </button>
                      )}
                    </div>
                  ))}
                </div>

                {/* Labels List */}
                <div>
                  <div className="flex items-center mt-2">
                    <PiChartLineUpBold className="mr-2" />
                    <p className="text-xs break-words mr-2 rounded-full p-1 border-2 bg-gray-50 ">
                      {gmailStatus !== ""
                        ? gmailStatus
                        : item?.files?.items?.length === 0
                        ? "N/A"
                        : "Uploaded"}
                    </p>

                    <button
                      className="w-full"
                      onClick={(e) => {
                        setOpenMoreLabels(!openMoreLabels);
                        setOpenMoreLabelsIndex(index);
                      }}
                    >
                      <div
                        id={"label-cell-selected-no-" + index}
                        className="text-xs flex flex-wrap"
                      >
                        {item?.briefs &&
                          item?.briefs?.length !== 0 &&
                          item?.briefs?.map((elem, innerIndex) =>
                            innerIndex < 3 ? (
                              <span
                                key={innerIndex}
                                className="mx-1 text-orange-800 bg-orange-100 rounded-full text-xs mt-1"
                                style={{
                                  gap: "8px",
                                  padding: "4px 8px",
                                }}
                              >
                                {getRowLabels(item?.briefs)[innerIndex].label}
                              </span>
                            ) : null
                          )}
                        {item?.briefs?.length > 3 && (
                          <span
                            className="mx-1 text-orange-800 bg-orange-100 rounded-full text-xs mt-1"
                            style={{
                              gap: "8px",
                              padding: "4px 8px",
                            }}
                          >
                            +{item?.briefs?.length - 3}
                          </span>
                        )}
                      </div>
                    </button>
                  </div>

                  {openMoreLabels && openMoreLabelsIndex === index && (
                    // Move the modal outside of the button, but still inside the parent div
                    <div
                      key={index}
                      className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50"
                    >
                      <div
                        className="w-52 h-auto p-3 flex flex-col gap-1 rounded-md overflow-y-auto bg-white z-50"
                        style={{
                          boxShadow:
                            "0px 3px 38px 0px rgba(0,0,0,0.15), 0px 6px 12px 0px rgba(0,0,0,0.18)",
                          maxHeight: "12rem",
                        }}
                      >
                        <p className="px-1 py-1 text-xs text-gray-400 font-semibold border-b border-gray-500">
                          Labels
                        </p>
                        {item?.briefs &&
                          item?.briefs?.length !== 0 &&
                          item?.briefs?.map((elem, innerIndex) => (
                            <span
                              key={innerIndex}
                              className="text-s w-full py-1 border-b border-dashed border-gray-300 truncate"
                            >
                              {getRowLabels(item?.briefs)[innerIndex].label}
                            </span>
                          ))}
                        {/* Add a close button or any other UI elements as needed */}
                        <button
                          onClick={() => {
                            setOpenMoreLabels(false);
                            setOpenMoreLabelsIndex(null);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {visibleFiles?.length === 0 ? (<>
                  <div> 
                    <button
                      className="border border-blue-900 px-2 py-1 rounded-full mt-2 font-open-sans text-blue-900 bg-blue-200 text-xs font-normal"
                      onClick={(e) => {
                        setSelectedBackground(() => item);
                        setShowModal(true);
                      }}>
                      Upload
                    </button>
                  </div>
                </>) : (<></>)}
                
              </div>
            </div>
          );
        })}
      </div>
      {showModal && (
        <div
          className="outline-none focus:outline-none h-full fixed inset-0 z-50 flex font-open-sans"
          style={{ zIndex: 70 }}
        >
          <div className={`fixed right-0 h-full
            ${!isMobileDevice(width) ? "w-1/3" : "w-full"}
          `}>
            <div className="outline-none focus:outline-none relative w-full h-full rounded-sm border-0 bg-white shadow-lg flex flex-col justify-between content-between	overflow-y-auto">
                <ProgressPhotosModal
                  setShowRightPanel={setShowModal}
                  matterId={matterId}
                  uploadImage={uploadImage}
                  setUploadImage={setUploadImage}
                  imageURL={imageURL}
                  setImageURL={setImageURL}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  handleUpload={handleUpload}
                  datePicked={datePicked}
                  setDatePicked={setDatePicked}
                  setDescription={setDescription}
                  description={description}
                  saveButtonClicked={saveButtonClicked}
                  selectedBackground={selectedBackground}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
              />
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

export default ProgressPhotosContentComponent;
