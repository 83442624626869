import classNames from "classnames";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export default function ProgressNotification({
  className,
  variant,
  message,
  show,
  handleResend,
}) {
  // console.log("message", message);
  function handleClassName() {
    return classNames(className, {
      "flex items-center w-full self-start rounded-md p-4 text-base font-medium my-2 border-solid border inline fade-in-fwd": true,
      "bg-neutral-100": variant === "default",
      "bg-secondary-l text-secondary border-secondary": variant === "success",
      "bg-danger-l text-danger border-danger": variant === "error",
      hidden: show === false,
    });
  }

  return (
    <>
      <div className={handleClassName()} style={{ maxWidth: "500px" }}>
        {variant === "success" ? (
          <IoIosCheckmarkCircleOutline className="h-8 w-8 inline -mt-1 mr-1 flex-none" />
        ) : (
          <IoCloseCircleOutline className="h-8 w-8 inline -mt-1 mr-1 flex-none" />
        )}
        <div className="text-base font-medium">
          {message === "User is not confirmed." ? (
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
              onClick={handleResend}
            >
              Resend Verification
            </span>
          ) : (
            message
          )}
        </div>
      </div>
    </>
  );
}

ProgressNotification.defaultProps = {
  variant: "default",
  show: false,
};
