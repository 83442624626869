import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import {
  BsArrowDownUp,
  BsInfoCircle,
  BsExclamationCircleFill,
  BsFillClockFill,
  BsCheck2Circle,
} from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import Button from "../../shared/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import tasklistIcon from '../../assets/images/tasklist-icon.png';
import { FiEdit } from "react-icons/fi";
import { FaCircle } from "react-icons/fa";

export default function TaskList(props) {
  const history = useHistory();

  const { redirectUrl, taskListData } = props;

  function visitBrief() {
    history.push(redirectUrl);
  }

  const [taskCount, setTaskCount] = useState(null);
  const [oTasks, setOTasks] = useState(null);
  const [cTasks, setCTasks] = useState(null);

  useEffect(() => {
    if (taskCount === null) {
      getTaskCount();
    }
  }, [taskCount]);

  const TASKLIST_DATA_QUERY = `
    query getTaskLists(
      $companyId: ID!,
      $isDeleted: Boolean,
      $status: String,
      $dateSubmittedStart: String,
      $dateSubmittedEnd: String,
      $assignee: [String],
      $priority: Int, $limit: Int) {
      taskListData(
        companyId: $companyId
        assignee: $assignee
        dateSubmittedEnd: $dateSubmittedEnd
        dateSubmittedStart: $dateSubmittedStart
        isDeleted: $isDeleted
        status: $status
        priority: $priority
        limit: $limit
      ) {
        items {
          id
          data
          uniqueId
          clientMatterId
          gmailMessageId
          createdAt
          isDeleted
          updatedAt
        }
      }
    }
    `;

  const getTaskCount = async () => {
    var fname = localStorage.getItem("firstName");
    var lname = localStorage.getItem("lastName");
    var userId = localStorage.getItem("userId");
    var userHistory = JSON.parse(localStorage.getItem("history"));

    var companyId = localStorage.getItem("companyId")
      //console.log("TEST NAME", `${fname} ${lname}`);

      let assignees = [
        `${fname} ${lname}`,
          userId
      ]

      if (userHistory) {
        for (let pastUser of userHistory) {
          assignees.push(`${pastUser.firstName + " " + pastUser.lastName}`)
        }
      }
      
      //get 2 latest only
      let vars = { 
        companyId: companyId, 
        isDeleted: false, 
        assignee: assignees,
        dateSubmittedEnd: "", 
        dateSubmittedStart: "", 
        status: ""
      }

      console.log("varsss",vars)
      const { data } = await API.graphql({
        query: TASKLIST_DATA_QUERY,
        variables: vars,
      }).catch((e) => {
        console.error(e.errors[0].message);
      });
      
      const dataTasks = data.taskListData.items;

      setTaskCount(dataTasks?.length)

      var outstanding = [];
      var completed = [];

      dataTasks.map((x)=>{
        x.data = JSON.parse(x.data);
      })

      dataTasks.map((x)=>
      (x.data[7] === "Outstanding") ? outstanding = [...outstanding, x] 
        : (x.data[7] === "Completed") ? completed = [...completed, x] 
        : x
      )
      setOTasks(outstanding?.length)
      setCTasks(completed?.length)

      console.log("Tasklist inside", dataTasks)
  }

  return (
    <>
      <div
        className=" bg-white shadow rounded-lg border border-neutral-200 overflow-hidden"
      >
        <div className="w-full pt-6 px-8">
        <div className="relative">
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img src={tasklistIcon} alt="Task List Icon" className="pb-2 w-11 h-auto"/>
            </div>
            <div 
              className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
              onClick={visitBrief}
            >
              <FiEdit className="w-4 h-4"/>
            </div>
          </div>

          <div className="relative gap-1 z-20">
            <div className="justify-start items-center gap-2 pb-5">
              <div className="inline-flex">
                <div className="text-black text-lg font-open-sans font-semibold mr-1">
                  Task List
                </div>
                <BsInfoCircle className="text-xs" />
              </div>
              <div className="flex -mt-7">
                <p className="text-xs font-normal font-open-sans text-gray-600 pt-6 leading-tight"
                style={{ color: "#5A5A5A" }}>{taskCount} Tasks</p>
                <p className="px-1 flex text-xs font-normal font-open-sans text-gray-600 pt-6 leading-tight"
                  style={{ color: "#5A5A5A" }}>
                  <FaCircle size={6} className="text-orange-500 mt-1"/>
                  {oTasks} Outstanding
                </p>
                <p className="flex text-xs font-normal font-open-sans text-gray-500 pt-6 leading-tight"
                  style={{ color: "#5A5A5A" }}>
                  <FaCircle size={6} className="text-green-700 mt-1"/>
                  {cTasks} Completed
                </p>
              </div>
              <div
                className="w-full text-sm font-normal font-open-sans text-gray-600 pt-6 leading-tight"
                style={{ color: "#5A5A5A" }}
              >  
                {taskListData !== null && taskListData?.length > 0 ? 
                (<>
                  {taskListData.map((x)=>{
                    return(<>
                      <div className="border-b border-gray-200 py-3">
                        <div className="flex">
                          <p className=" font-semibold text-xs font-open-sans text-gray-600">
                            {(x.data[1] !== null || x.data[1] !== "undefined" || x?.data[1]) && x?.data[1]?.length > 12 ? x?.data[1]?.substring(0, 13) + ".." : "No Subject"}
                          </p>
                          <p className="ml-1 font-normal text-xs font-open-sans text-gray-600 rounded-xl border border-gray-300 bg-gray-100 px-1">P{x?.data[8]}</p>
                          <p className={x.data[7] === "Outstanding" ? 
                          "ml-1 flex font-normal text-xs font-open-sans text-orange-600 rounded-xl border border-orange-300 bg-orange-100 px-1":
                          "ml-1 flex font-normal text-xs font-open-sans text-green-600 rounded-xl border border-green-300 bg-green-100 px-1"}>
                            <FaCircle size={6} className={x?.data[7] === "Outstanding" ? "text-orange-500 mt-1" : "text-green-700 mt-1"}/> 
                            {x?.data[7]}
                          </p>
                        </div>
                        <div>
                          <p className="font-normal text-xs font-open-sans text-gray-600">{x?.data[6]}</p>
                        </div>

                      </div>
                    </>)
                  })}
                </>) 
                : 
                (<>
                  Organise tasks from emails, label them as completed or outstanding for streamlined project management.
                </>)}

              </div>
            </div>
          </div>
        </div>

        {/** End Header Variations */}

        
          
      </div>
    </>
  );
}
