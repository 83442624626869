
//Libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import Select from 'react-select'
import { Link } from 'react-router-dom'

//Icons
import { IconContext } from "react-icons"
import { AiFillFolder } from 'react-icons/ai'
import { BiTrashAlt } from 'react-icons/bi'
import { BsThreeDotsVertical, BsEye, BsLink45Deg,  } from 'react-icons/bs'
import { FaWpforms } from 'react-icons/fa'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { LiaEdit } from 'react-icons/lia'
import { MdFilterAlt, MdSort } from 'react-icons/md'
import { SiAddthis } from 'react-icons/si'
import { VscFiles } from 'react-icons/vsc'

//Local Hooks, Functions, Reducers
import StoreProvider from './reducers/StoreProvider'
import { FormDashboardContext } from "./reducers/StoreProvider"

//API and Async functions
import { 
  mutationActionHandler, 
  mCreateCategory,
  mUpdateCategory,
  mDeleteCategory,
  mUpdateCustomFormCategory,
  mCreateCustomForm,
  mUpdateCustomForm,
} from './graphql/queries'

export default function FormDashboard({key}){

  const topNavRef = useRef(null)
  const [currentTopNavHeight, setCurrentTopNavHeight] = useState(60)

  //For computing height of main content based on Top Navbar Height
  useEffect(() => {
    const topNavHeight = topNavRef?.current?.clientHeight;
    setCurrentTopNavHeight(topNavHeight)
  }, [topNavRef]);

  const handleFormLink = () => {
    console.log('Navigate to ', key)
  }

  return(
    <StoreProvider>
      <Modal />
      <div
        className=" h-screen max-h-screen flex flex-col bg-neutral-100 overflow-x-hidden"
        style={{
          margin:"0 0 0 76px"
        }}
      >
        <TopNav topNavRef={topNavRef}/>
        <div
          className='flex-1 w-full p-16 flex gap-5'
          style={{
            maxHeight: `calc(100vh - ${currentTopNavHeight}px)`
          }}
        >
          <Categories />
          <div
            className='flex-1 flex flex-col gap-3'
          >
            <ActionBar />
            <FormsContainer />

          </div>

        </div>
      </div>
    </StoreProvider>

  )
}


function TopNav({topNavRef}){
  const {state, dispatch} = useContext(FormDashboardContext)

  useEffect(()=>{
    console.log(state)
  },[state])

  return(
    <div
      ref={topNavRef}
      className="sticky z-30 top-0 w-full py-4 px-4 flex justify-between bg-white"
    >
      <div
        className="flex items-center gap-3"
      >
        <FaWpforms/>
        <p
          className="text-xl font-medium"
        >
          Forms
        </p>
      </div>

    </div>
  )
}

function Categories() {

  const {state, dispatch} = useContext(FormDashboardContext)
  const currentCategory = state.appState.currentCategory
  const formCategories = state.formData.formCategories

  const handleChangeCategory = (category) => {
    dispatch({
      type: 'CHANGE_CURRENT_CATEGORY',
      payload: category
    })
  }

  const handleAddCategoryModal = () => {
    dispatch({
      type:'OPEN_MODAL',
    })
    dispatch({
      type:'CHANGE_MODAL_CONTENT',
      payload: <CategoryModal action='add'/>
    })
  }

  const handleEditCategoryModal = (id, name) => {
    dispatch({
      type:'OPEN_MODAL',
    })
    dispatch({
      type:'CHANGE_MODAL_CONTENT',
      payload: <CategoryModal action='edit' id={id} name={name}/>
    })
  }

  const handleDeleteCategory = (id) => {
    if(id === currentCategory.id){
      handleChangeCategory(formCategories.find((category)=>category.id == 'all_category'))
    }
    mutationActionHandler(
      {
        query: mDeleteCategory,
        variables: {
          companyId: state.appState.companyId,
          id: id,
        },
      },
      (response)=>{
        console.log("Query response",response)
        dispatch({
          type:'DELETE_CATEGORY',
          payload: id
        })
      }
    )

  }

  function CategoryButton({category, active}) {

    return(
      <div
        className={`flex-1 px-2 py-1 flex justify-between text-gray-500 rounded cursor-pointer group hover:bg-gray-100 ${active && 'bg-gray-200'} `}
      >
        <div
          className='flex-1 flex items-center gap-2 overflow-hidden'
          onClick={()=>handleChangeCategory(category)}
        >
          <AiFillFolder className='flex-none text-xl'/>
          <p
            className='truncate text-base '
          >
            {category.name}
          </p>
        </div>

        {
          category.id !== 'all_category' &&
            <div className='flex items-center gap-2 opacity-0 group-hover:opacity-100'>
              <button
                onClick={()=>handleEditCategoryModal(category.id, category.name)}
              >
                <LiaEdit />
              </button>
              <button
                onClick={()=>handleDeleteCategory(category.id)}
              >
                <BiTrashAlt />
              </button>
            </div>
        }


      </div>
    )
  }


  // Magic button to clear the customForms data of the currentCategory
  // <button
  //   onClick={()=>
  //     mutationActionHandler(
  //       {
  //         query: mUpdateCustomFormCategory,
  //         variables: {
  //           companyId: state.appState.companyId,
  //           id: state.appState.currentCategory.id,
  //           customForms: []
  //         },
  //       },
  //       (response)=>{
  //         console.log("Cleared?",response)
  //       }
  //     )
  //   }
  // >Clear Category</button>
    
  return(
    <Card
      className='px-7 w-72 max-h-full'
    >
      <div
        className='max-h-full py-6 flex flex-col gap-5'
      >
        <div
          className='flex justify-between items-center'
        >
          <h2
            className='text-xl font-medium'
          >
            Categories
          </h2>
          <button
            onClick={()=>handleAddCategoryModal()}
          >
            <SiAddthis className='text-2xl text-neutral-500' />
          </button>
        </div>

        <div
          className='flex-1 flex flex-col gap-1 overflow-y-auto'
        >
          {
            formCategories.map((category, categoryIndex)=>
              <CategoryButton
                key={categoryIndex}
                category={category}
                active={category.id == currentCategory.id}
              />
            )
          }
        </div>

      </div>
    </Card>
  )
}

function ActionBar(){

  const {state, dispatch} = useContext(FormDashboardContext)

  const handleAddFormModal = () => {
    dispatch({
      type:'OPEN_MODAL',
    })
    dispatch({
      type:'CHANGE_MODAL_CONTENT',
      payload: <FormModal action='add' />
    })
  }


  const handleFilterModal = () => {
    dispatch({
      type:'OPEN_MODAL',
    })
    dispatch({
      type:'CHANGE_MODAL_CONTENT',
      payload: <FilterModal />
    })
  }
  
  const handleSortModal = () => {
    dispatch({
      type:'OPEN_MODAL',
    })
    dispatch({
      type:'CHANGE_MODAL_CONTENT',
      payload: <SortModal />
    })
  }

  const handleSearch = (searchQuery) => {
    dispatch({
      type: 'SET_SEARCH',
      payload: searchQuery
    })
  }


  return(
    <div
      className='w-full flex justify-between '
    >
      <div
        className='flex-1 flex items-center gap-4'
      >
        <input 
          type='search' 
          placeholder='Search...'
          className='p-2 w-full max-w-sm border border-gray-300 rounded-lg'
          onChange={(e)=>handleSearch(e.target.value)}
        />
        <IconContext.Provider value={{className: 'text-3xl text-gray-500'}}>
          <button
            className='grid place-content-center p-0.5 rounded-xl hover:bg-gray-200'
            onClick={handleSortModal}
          >
            <MdSort />
          </button>
          <button
            className='grid place-content-center p-0.5 rounded-xl hover:bg-gray-200'
            onClick={handleFilterModal}
          >
            <MdFilterAlt />
          </button>
        </IconContext.Provider>

      </div>
      <button
        className='p-2 text-base bg-green-500 text-white rounded'
        onClick={()=>handleAddFormModal()}
      >
        New Form
      </button>
    </div>
  )
}


// Filter Logic
//-------------------------------------
const applyCategory = (items, currentCategory, formCategories) => {
  console.log('Categories',formCategories)
  if (currentCategory.id === 'all_category') {
    return items
  }

  return items.filter((form)=>
    formCategories.find((category)=> 
      category?.id == currentCategory?.id
    ).customForms?.some((categoryForm)=>form.id === categoryForm.id)
  )

}

const applyFilters = (items, filters) => {
  if (filters.length === 0) {
    return items
  }
  
  return items.filter(item => {
    //Filter by number of submissions
    return filters.includes(item.category)
  });
}

const applySorting = (items, sorting) => {
  if (!sorting) {
    return items
  }

  switch(sorting){
    case 'name':
      return [...items].sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })

    case 'category':
      return [...items].sort((a, b) => {
        const categoryA = a.category.name.toUpperCase()
        const categoryB = b.category.name.toUpperCase()
        if (categoryA < categoryB) {
          return -1
        }
        if (categoryA > categoryB) {
          return 1
        }
        return 0
      })
  }

}

const applySearch = (items, searchQuery) => {

  if (searchQuery.trim() === '') {
    return items;
  }

  const lowerCaseQuery = searchQuery.toLowerCase()
  
  return items.filter(item => {

    //Search by name
    return item.name.toLowerCase().includes(lowerCaseQuery)
  })
}


function FormsContainer(){

  const {state, dispatch} = useContext(FormDashboardContext)
  const {currentCategory, formFilters, formSorting, formSearchQuery} = state.appState
  const { originalData, formCategories } = state.formData
  //const formItems = categorizedData || originalData

  //Apply filters
  const categorizedItems = applyCategory(originalData, currentCategory, formCategories)
  const filteredItems = applyFilters(categorizedItems, formFilters);
  const sortedItems = applySorting(filteredItems, formSorting);
  const searchedItems = applySearch(sortedItems, formSearchQuery);

  useEffect(()=>{
    dispatch({
      type: 'FETCH_CATEGRORIZED_FORM',
      payload: currentCategory,
    })
  },[currentCategory, originalData, formCategories])

  return(
    <div
      className='flex-1 flex flex-col gap-2 overflow-y-auto'
    >
      {
        searchedItems.map((formContent, formIndex)=>
          <FormCard 
            key={formIndex}
            formContent={formContent}
          />
        )
      }
    </div>
  )
}


function FormCard({formContent}) {
  const {state, dispatch} = useContext(FormDashboardContext)
  

  const handleCopyLinkClipboard = () => {
    const baseUrl = window.location.origin
    const formLink = `${baseUrl}/public/${state.appState.companyId}${formContent.route}`
    navigator.clipboard.writeText(formLink)
      .then(()=>{
        console.log(`Copied link to clipboard ${formLink}`)
      })
      .catch((err)=>{
        console.error(`Could not copy link: ${err}`)
      })
  }

  return(
    
      <Card
        className='p-5 flex justify-between text-left group hover:bg-gray-50 '
      >
        <Link to={formContent.route+'/form-builder'}>
          <div
            className='max-w-sm '
          >
            <h2
              className='text-xl font-medium truncate'
            >
              {formContent.name}
            </h2>
            <p
              className='flex gap-3 text-base text-gray-500 truncate'
            >
              <span
                className='text-base truncate'
                style={{
                  maxWidth: '10rem',
                }}
              >
              {formContent.category?.name || ''}
              </span>
              <span
                className='flex items-center'
              >
                <HiOutlineDocumentReport />{formContent.submissions || 0}
              </span>
            </p>
            <p
              className='text-gray-400'
            >
              {/* {`Last modified ${formContent.updatedAt}`} */}
              {`Created at ${formContent.createdAt}`}
            </p>
          </div>
        </Link>
        <div
          className='flex gap-5 items-start'
        >
          <div
            className='flex gap-3 opacity-0 group-hover:opacity-100'
          >
            <IconContext.Provider
              value={{className: 'text-2xl text-gray-500'}}
            >
              <button>
                <BsEye />
              </button>
              <button
                onClick={()=>handleCopyLinkClipboard()}
              >
                <BsLink45Deg />
              </button>
              <button>
                <VscFiles />
              </button>
            </IconContext.Provider>
          </div>

          <FormCardOptions formContent={formContent}/>

        </div>

      </Card>
  )
}

function FormCardOptions({formContent}){

  const [openOptions, setOpenOptions] = useState(false)
  const cardOptionsRef = useRef(null)

  return(
  <div
    className='relative'
  >
    <button
      ref={cardOptionsRef}
      onClick={()=>setOpenOptions(!openOptions)}
    >
      <BsThreeDotsVertical />
    </button>
    <AnimatePresence>
      {
        openOptions &&
          <FormCardOptionsDropdown
            formContent={formContent}
            cardOptionsRef={cardOptionsRef}
            setOpenOptions={setOpenOptions}
          />
      }
    </AnimatePresence>
  </div>
  )
}

function FormCardOptionsDropdown({
  formContent,
  cardOptionsRef,
  setOpenOptions
}){

  const dropdownRef = useRef(null)

  useEffect(()=>{
    const handleClickOutsie = (event) => {
      if(
        (dropdownRef.current && !dropdownRef.current.contains(event.target)) &&
        (cardOptionsRef.current && !cardOptionsRef.current.contains(event.target))
      )
      setOpenOptions(false)
    }
    document.addEventListener('mousedown', handleClickOutsie)

    return ()=>{
      document.removeEventListener('mousedown', handleClickOutsie)
    }
  },[dropdownRef])

  const {state, dispatch} = useContext(FormDashboardContext)

  const handleEditFormModal = () => {
    dispatch({
      type:'OPEN_MODAL',
    })
    dispatch({
      type:'CHANGE_MODAL_CONTENT',
      payload: <FormModal action='edit' formContent={formContent} />
    })
  }

  const handleDeleteForm = (id) => {
    dispatch({
      type: 'DELETE_FORM',
      payload: id
    })
  }
  

  function OptionsDropdownButton({onClick, label}){

    return(
      <button
        className='w-32 p-2 px-4 text-base text-left hover:bg-gray-100'
        onClick={onClick}
      >
        {label}
      </button>
    )
  }

  return(
    <motion.div
      initial={{height: 0}}
      animate={{height: 'auto'}}
      exit={{height: 0}}
      ref={dropdownRef}
      className='absolute z-20 right-0 bg-white rounded shadow-md overflow-hidden'
    >
      <div
        className='py-2 flex flex-col '
      >
        <OptionsDropdownButton 
          label='Edit' 
          onClick={()=>{
            handleEditFormModal()
            setOpenOptions(false)
          }}
        />
        <OptionsDropdownButton 
          label='Details'
        />
        <OptionsDropdownButton 
          label='Delete' 
          onClick={()=>{
            handleDeleteForm(formContent.id)
            setOpenOptions(false)
          }}
        />
      </div>
    </motion.div>
  )
}


//=============================
// Modal Components
//=============================

//Renders the main modal but has no content
function Modal(){

  const {state, dispatch} = useContext(FormDashboardContext)

  useEffect(() => {
    if (state.modalState.modalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [state.modalState.modalOpen]);

  const handleModalClose = () => {
    dispatch({
      type:'CHANGE_MODAL_OPEN',
      payload: false
    })
  }
  
  return(
    <AnimatePresence exitBeforeEnter>
      {
        state.modalState.modalOpen &&
          <motion.div
            className="fixed z-40 w-screen h-screen grid place-content-center"
          >
    
            {
              state.modalState.modalContent
            }
    
            <motion.div
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              className="absolute w-full h-full bg-black bg-opacity-40"
              onClick={handleModalClose}
            ></motion.div>
          </motion.div>
      }

    </AnimatePresence>
  )
}

// Each components render a different content for the modal

function CategoryModal({action, id, name}){
  const {state, dispatch} = useContext(FormDashboardContext)
  const [newName, setNewName] = useState(name || '')

  const actions = {
    add: {label: 'Add', function: ()=>handleAddCategory(newName)},
    edit: {label: 'Edit', function: ()=>handleEditCategory(id ,newName)},
  }

  const handleAddCategory = (categoryName) => {
    mutationActionHandler(
      {
        query: mCreateCategory,
        variables: {
          companyId: state.appState.companyId,
          name: categoryName,
        },
      },
      (response)=>{
        console.log("Query response",response)
        dispatch({
          type: 'ADD_CATEGORY',
          payload: response.data.customFormCategoryCreate
          // payload: {
          //   id: '',
          //   name: categoryName,
          // }
        })
      }
    )
    dispatch({
      type:'CLOSE_MODAL',
    })
  }
  
  const handleEditCategory = (id, categoryName) => {
    mutationActionHandler(
      {
        query: mUpdateCategory,
        variables: {
          companyId: state.appState.companyId,
          id: id,
          name: categoryName,
        },
      },
      (response)=>{
        console.log("Query response",response)
        dispatch({
          type:'EDIT_CATEGORY',
          payload: {
            id: id,
            name: categoryName,
          }
        })
      }
    )

    dispatch({
      type:'CLOSE_MODAL',
    })
  }

  return(
    <ModalCard
      className='w-screen max-w-md flex flex-col gap-3'
    >
      <label>
        Name: 
      </label>
      <input 
        type='text'
        className='p-2 w-full border rounded'
        placeholder='Input name here...'
        value={newName}
        onChange={(e)=>setNewName(e.target.value)}
      />
      <button
        className='p-2 bg-green-500 text-white rounded '
        onClick={actions[action].function}
      >
        {`${actions[action].label} Category`}
      </button>

    </ModalCard>
  )
}

function FilterModal(){
  const {state, dispatch} = useContext(FormDashboardContext)
  const [filterBy, setFilterBy] = useState([])

  const options = [
    {value: 'submissions', label: 'Submissions'},
  ]

  const handleFilter = (filterBy) => {
    dispatch({
      type: 'ADD_FILTER',
      payload: filterBy
    })
    dispatch({
      type:'CLOSE_MODAL',
    })
  }


  return(
    <ModalCard
      className='w-screen max-w-md flex flex-col gap-3'
    >
      <label>
        Category: 
      </label>
      <Select 
        defaultValue={''}
        placeholder='Select category...'
        options={options} 
        onChange={(e)=>setFilterBy(e.value)}
      />
      <button
        className='p-2 bg-green-500 text-white rounded '
        onClick={()=>handleFilter(filterBy)}
      >
        {`Apply Filter`}
      </button>

    </ModalCard>
  )
}

function SortModal(){
  const {state, dispatch} = useContext(FormDashboardContext)
  const [sortBy, setSortBy] = useState(state?.appState?.formSorting || null)

  const options = [
    {value: null, label: 'Clear Sort'},
    {value: 'name', label: 'Name'},
    {value: 'category', label: 'Category'},
    {value: 'submission', label: 'Submissions'},
    {value: 'lastModified', label: 'Last Modified'},
  ]

  const handleSort = (sortBy) => {
    dispatch({
      type: 'SET_SORTING',
      payload: sortBy
    })
    dispatch({
      type:'CLOSE_MODAL',
    })
  }

  return(
    <ModalCard
      className='w-screen max-w-md flex flex-col gap-3'
    >
      <label>
        Sort By: 
      </label>
      <Select 
        defaultValue={sortBy}
        placeholder='Select category...'
        options={options} 
        onChange={(e)=>setSortBy(e.value)}
      />
      <button
        className='p-2 bg-green-500 text-white rounded '
        onClick={()=>handleSort(sortBy)}
      >
        {`Apply Sort`}
      </button>

    </ModalCard>
  )
}


function FormModal({ action, formContent }){
  const {state, dispatch} = useContext(FormDashboardContext)

  const formCategory = formContent ? state.formData?.formCategories?.find((category)=> category.customForms?.some((categoryForm)=> categoryForm.id === formContent.id)) : ''
  const [newName, setNewName] = useState(formContent?.name || '')
  const [newCategory, setNewCategory] = useState({value: formCategory || '', label: formCategory?.name || ''})

  
  const options = 
    state.formData.formCategories
      .map((category)=>{
        return{
          value: category,
          label: category.name
        }
      })

  const actions = {
    add: {
      label: 'Add', 
      function: 
        ()=>handleAddForm({
          name: newName, 
          category: newCategory.value
        })
    },
    edit: {
      label: 'Edit', 
      function: 
        ()=>handleEditForm({
            id: formContent.id, 
            name: newName, 
            category: newCategory.value
          },
          formCategory
        )
    },
  }


  const handleAddForm = (form) => {

    mutationActionHandler(
      {
        query: mCreateCustomForm,
        variables: {
          companyId: state.appState.companyId,
          name: form.name,
        },
      },
      (response)=>{
        console.log("Query response",response)
        const responseForm = response.data.customFormCreate
        dispatch({
          type: 'ADD_FORM',
          payload: responseForm
          // {
          //   id: response.data.customFormCreate.id,
          //   name: form.name,
          //   category: form.category
          // }
        })

        console.log(form.category)

        if(form.category !== '' && form.category !== null){
          const newFormArray = [
            ...(form.category.customForms || []),
            { id: responseForm.id }
          ]
  
          mutationActionHandler(
            {
              query: mUpdateCustomFormCategory,
              variables: {
                companyId: state.appState.companyId,
                id: form.category.id,
                customForms: newFormArray
              },
            },
            (response)=>{
              console.log("Query response",response)
              dispatch({
                type: 'CHANGE_FORM_CATEGORY',
                payload: {
                  id: form.category.id,
                  customForms: newFormArray
                }
              })
            }
          )
        }

      }
    )
    dispatch({
      type:'CLOSE_MODAL',
    })
  }

  const handleEditForm = (form, currentFormCategory) => {
    mutationActionHandler(
      {
        query: mUpdateCustomForm,
        variables: {
          companyId: state.appState.companyId,
          id: form.id,
          name: form.name,
        },
      },
      (response)=>{
        console.log("Query response",response)
        dispatch({
          type: 'EDIT_FORM',
          payload: {
            id: form.id,
            name: form.name,
          }
        })
      }
    )

    console.log("Current Form Category",currentFormCategory)
    console.log("New Form Category", form?.category)
    if(currentFormCategory?.id !== form?.category.id){    

    //Remove form to previous category
      if(currentFormCategory !== undefined && currentFormCategory !== null){
        const removedFormArray = currentFormCategory.customForms.filter((customForm)=> customForm.id !== form.id)
        mutationActionHandler(
            {
              query: mUpdateCustomFormCategory,
              variables: {
                companyId: state.appState.companyId,
                id: currentFormCategory.id,
                customForms: removedFormArray
              },
            },
            (response) => {
              console.log("Query response",response)
              dispatch({
                type: 'CHANGE_FORM_CATEGORY',
                payload: {
                  id: currentFormCategory.id,
                  customForms: removedFormArray
                }
              })
            }
          )
      }


      //Add the form to the new category
      const newFormArray = [
        ...(form.category.customForms || []),
        { id: form.id }
      ]
      mutationActionHandler(
        {
          query: mUpdateCustomFormCategory,
          variables: {
            companyId: state.appState.companyId,
            id: form.category.id,
            customForms: newFormArray
          },
        },
        (response)=>{
          console.log("Query response",response)
          dispatch({
            type: 'CHANGE_FORM_CATEGORY',
            payload: {
              id: form.category.id,
              customForms: newFormArray
            }
          })
        }
      )
    }

    dispatch({
      type:'CLOSE_MODAL',
    })
  }
  

  return(
    <ModalCard
      className='w-screen max-w-md flex flex-col gap-3'
    >
      <label>
        Name: 
      </label>
      <input 
        type='text'
        className='p-2 w-full border rounded'
        placeholder='Input name here...'
        value={newName}
        onChange={(e)=>setNewName(e.target.value)}
      />
      <label>
        Category: 
      </label>
      <Select 
        defaultValue={newCategory}
        placeholder='Select category...'
        options={options} 
        onChange={(e)=>setNewCategory(e)}
      />
      <button
        className='p-2 bg-green-500 text-white rounded '
        onClick={actions[action].function}
      >
        {`${actions[action].label} Form`}
      </button>

    </ModalCard>
  )
}

//=============================
// Reusable Components
//=============================

function ModalCard({children, className, style}){

  return(
    <motion.div 
      initial={{scale: 0, opacity: 0, overflow: 'hidden'}}
      animate={{scale: 1, opacity: 1, overflow: 'visible'}}
      exit={{scale: 0, opacity: 0, overflow: 'hidden'}}
      className={`z-20 p-7 bg-white rounded-2xl shadow-lg overflow-hidden ${className}`}
      style={style}
    >
      {children}
    </motion.div>
  )
}

function Card({children, className}){


  return(
    <div
      className={`bg-white border rounded-lg ${className}`}
    >
      {children}
    </div>
  )
}