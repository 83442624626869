const convertHTMLEntity = function (text) {
  const sp = document.createElement("span");

  if (!text) return "";

  const output = text
    .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      sp.innerHTML = entity;
      return sp.innerText;
    })
    .replace(/<br ?\/?>/g, "\n")
    .replace(/(<([^>]+)>)/gi, "");

  return output;
};

export default convertHTMLEntity;
