import { API } from "aws-amplify";

/** Start Shared Background Mutations */
const mUpdateBackgroundOrder = `
  mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
    backgroundBulkUpdateOrders(arrangement: $arrangement) {
      id
      order
    }
  }`;

const mUpdateBulkMatterFileOrder = `
  mutation bulkUpdateMatterFileOrders($arrangement: [ArrangementInput]) {
    matterFileBulkUpdateOrders(arrangement: $arrangement) {
      id
      order
    }
  }`;

export async function updateBackgroundOrders(rowArrangement) {
    try {
        const result = await API.graphql({
        query: mUpdateBackgroundOrder,
        variables: {
            arrangement: rowArrangement,
        },
        });
    } catch(error) {
        console.error(error.message)
    }
}

export async function updateBackgroundFilesOrder(fileArrangement) {
    try {
        const result = await API.graphql({
        query: mUpdateBulkMatterFileOrder,
        variables: {
            arrangement: fileArrangement,
        },
        });
    } catch(error) {
        console.error(error.message)
    }
}