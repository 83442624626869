import React, { useEffect, useState, useRef } from "react";
import PhotoIcon from "../../assets/images/hba-photo-icon.svg";
import { FaFileContract } from "react-icons/fa6";
import UploadIcon from "../../assets/images/hba-upload-icon.svg";
import DatePicker from "react-datepicker";
import Button from "../../shared/Button";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/images/hba-calendar-icon.svg";
import ContractDetails from "./right-panel-contracts.jsx";
import BuilderDetails from "./right-panel-builders.jsx";
import ProgressPhotosModal from "./right-panel-progress-photos.jsx";

import momentTZ from "moment-timezone";
import {
  timezoneFormatter,
  datePickerDateFormatter,
} from "../../shared/userPreferredDateTime";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import uploadIcon from "../../assets/images/upload-icon.png";
import progressPhotoIcon from "../../assets/images/progressPhoto-icon.png";
import { IoCloseOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { HiOutlinePlusSm } from "react-icons/hi";
import TimePicker from "react-time-picker";

const RightPanel = ({
  modalChoicesEnum,
  setShowRightPanel,
  buildInfo,
  setBuildInfo,
  contractInfo,
  setContractInfo,
  whichModal,
  matterId,
  progressPhotoBrief,
  showRightPanel,
  uploadImage,
  setUploadImage,
  imageURL,
  setImageURL,
  handleDragOver,
  handleDrop,
  handleUploadLink,
  handleUpload,
  handleProgressPhotoUpload,
  datePicked,
  setDatePicked,
  logoURL,
  setLogoURL,
  clientMatterId,
  setDescription,
  description,
  saveButtonClicked,
  handleChangeGetStartedProgress,
  handleLogoDrop,
  builderLogo,
  setBuilderLogo,
  logo,
  setLogo,
  selectedFiles,
  // setSelectedFiles,
  // _setSelectedFiles
}) => {
  const { height, width } = useWindowDimensions();

  // CONTRACT DETAILS CONST
  const [contractStartDate, setContractStartDate] = useState(
    contractInfo?.contractStartDate
  );
  const [contractPrice, setContractPrice] = useState(
    contractInfo?.contractPrice
  );
  const [contractValue, setContractValue] = useState(
    contractInfo?.contractValue
  );

  const [logoId, setLogoId] = useState(null);

  // BUILDER DETAILS CONST
  const [builderName, setBuilderName] = useState(buildInfo?.builderName);
  const [builderContactName, setBuilderContactName] = useState(
    buildInfo?.builderContactName
  );
  const [builderContactEmail, setBuilderContactEmail] = useState(
    buildInfo?.builderContactEmail
  );
  const [builderContactNumber, setBuilderContactNumber] = useState(
    buildInfo?.builderContactNumber
  );

  return (
    <>
      <div
        className="outline-none focus:outline-none h-full fixed inset-0 z-50 flex font-open-sans"
        style={{ zIndex: 70 }}
      >
        <div
          className={`fixed right-0 h-full
          ${width > 844 ? "w-1/3" : "w-full"}
        `}
        >
          <div className="outline-none focus:outline-none relative w-full h-full rounded-sm border-0 bg-white shadow-lg flex flex-col justify-between content-between	overflow-y-auto">
            {whichModal === modalChoicesEnum.PROGRESS_PHOTO ? (
              <ProgressPhotosModal
                setShowRightPanel={setShowRightPanel}
                matterId={matterId}
                setUploadImage={setUploadImage}
                imageURL={imageURL}
                setImageURL={setImageURL}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                handleUpload={handleUpload}
                datePicked={datePicked}
                setDatePicked={setDatePicked}
                setDescription={setDescription}
                description={description}
                saveButtonClicked={saveButtonClicked}
                handleLogoDrop={handleLogoDrop}
                selectedFiles={selectedFiles}
                // setSelectedFiles={setSelectedFiles}
                // _setSelectedFiles={_setSelectedFiles}
              />
            ) : whichModal === modalChoicesEnum.CONTRACT_DETAILS ? (
              <>
                <ContractDetails
                  contractStartDate={contractStartDate}
                  setContractStartDate={setContractStartDate}
                  contractPrice={contractPrice}
                  setContractPrice={setContractPrice}
                  contractValue={contractValue}
                  setContractValue={setContractValue}
                  setShowRightPanel={setShowRightPanel}
                  setContractInfo={setContractInfo}
                  contractInfo={contractInfo}
                  clientMatterId={clientMatterId}
                  handleChangeGetStartedProgress={
                    handleChangeGetStartedProgress
                  }
                />

                {/* <div className="p-3 z-10">
                <div className="flex items-start justify-between w-full pl-2">
                  <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-yellow-100 rounded-3xl border-4 border-yellow-50">
                    <div className="text-justify text-yellow-600 text-lg font-normal leading-tight">
                      <FaFileContract />
                    </div>
                  </div>
                </div>

                <div className="items-start justify-between w-full py-2 px-2 flex relative">
                  <h3 className="pb-2 text-lg font-semibold text-gray-900">
                    Contract Details
                  </h3>
                </div>

                <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
                  <p className="pb-1  text-gray-900">Start Date</p>
                  <DatePicker
                    popperProps={{
                      positionFixed: true,
                    }}
                    placeholder="📆 Select Date"
                    className="border w-full rounded p-3 border-gray-400"
                    dateFormat={datePickerDateFormatter(
                      localStorage.getItem("userpreferredDateFormatType")
                    )}
                    selected={
                      contractStartDate
                        ? new Date(contractStartDate)
                        : null
                    }
                    placeholderText="Start Date"
                    onChange={(date) => setContractStartDate(date)}
                  />
                </div> */}

                {/* INPUT CONTRACT PRICE */}
                {/* <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
                  <p className="pb-1  text-gray-900">Contract Price</p>
                  <div className="justify-center items-center mx-auto">
                    <input
                      type="number"
                      defaultValue={contractPrice ? contractPrice : ""}
                      placeholder="Contract Price"
                      className="p-3 border rounded-md border-gray-400 w-full"
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        setContractPrice(e.target.value);
                      }}
                    ></input>
                  </div>
                </div> */}

                {/* INPUT CONTRACT VALUE */}
                {/* <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
                  <p className="pb-1  text-gray-900">Contract Value</p>
                  <div className="justify-center items-center mx-auto">
                    <input
                      type="number"
                      defaultValue={contractValue ? contractValue : ""}
                      placeholder="Contract Value"
                      className="p-3 border rounded-md border-gray-400 w-full"
                      style={{ resize: "none" }}
                      onChange={(e) => setContractValue(e.target.value)}
                    ></input>
                  </div>
                </div> */}
              </>
            ) : whichModal === modalChoicesEnum.BUILDER_DETAILS ? (
              <>
                <BuilderDetails
                  buildInfo={buildInfo}
                  setBuildInfo={setBuildInfo}
                  logoURL={logoURL}
                  setLogoURL={setLogoURL}
                  handleLogoDrop={handleLogoDrop}
                  handleDragOver={handleDragOver}
                  setShowRightPanel={setShowRightPanel}
                  clientMatterId={clientMatterId}
                  builderLogo={builderLogo}
                  setBuilderLogo={setBuilderLogo}
                  builderName={builderName}
                  setBuilderName={setBuilderName}
                  builderContactName={builderContactName}
                  setBuilderContactName={setBuilderContactName}
                  builderContactEmail={builderContactEmail}
                  setBuilderContactEmail={setBuilderContactEmail}
                  builderContactNumber={builderContactNumber}
                  setBuilderContactNumber={setBuilderContactNumber}
                  handleChangeGetStartedProgress={
                    handleChangeGetStartedProgress
                  }
                  setLogoId={setLogoId}
                  logoId={logoId}
                  logo={logo}
                  setLogo={setLogo}
                />
              </>
            ) : null}

            {/* CIRCLE DESIGN IN LOGO */}
            {width > 844 && (
              <div className="z-0">
                <div
                  className="absolute top-9 left-11 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(243, 244, 246)",
                    zIndex: 0,
                  }}
                ></div>
                <div
                  className="absolute top-9 left-11 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(243, 244, 246, 0.75)",
                  }}
                ></div>
                <div
                  className="absolute top-9 left-11 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(243, 244, 246, 0.50)",
                  }}
                ></div>
                <div
                  className="absolute top-9 left-11 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(243, 244, 246, 0.25)",
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="fixed inset-0 bg-black opacity-25"
        style={{ zIndex: 60 }}
      ></div>
    </>
  );
};

export default RightPanel;
