import React, { useState, useEffect, useRef, useReducer } from "react";
import anime from "animejs";
import { addClientMatter } from "../../dashboard/actions";
import { clientMatterReducers } from "../../dashboard/reducers";
import { initialState } from "../../dashboard/initialState";
import { toast } from "react-hot-toast";
import CreatableSelect from "react-select/creatable";
import { API } from "aws-amplify";
import { CgMathPlus } from "react-icons/cg";
export default function EmailModal({
  close,
  emailPreview
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);

  const [cmModal, setcmModal] = useState("");
  const [clientsOptions, setClientsOptions] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [inputSaveClient, setSaveClient] = useState("");
  const [inputClient, setInputClient] = useState("");

  const [mattersOptions, setMattersOptions] = useState();
  const [selectedMatter, setSelectedMatter] = useState();
  const [inputSaveMatter, setSaveMatter] = useState("");
  const [inputMatter, setInputMatter] = useState("");
  const [clientName, setclientName] = useState(null);
  const [matterName, setmatterName] = useState(null);
  const [show, setshow] = useState(false);
  const [matterlist, dispatch] = useReducer(clientMatterReducers, initialState);
  const [count, setcount] = useState(0);
  const companyId = localStorage.getItem("companyId");

  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);


  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="w-full opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="w-full flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen overflow-y-scroll"
          onClick={(e) => e.stopPropagation()}
        >
            <div className="flex flex-row">
                <div className="font-semibold text-gray-900 text-lg pb-2">
                    Email Preview
                </div>
            </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
