import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";
import { FaChevronLeft } from "react-icons/fa";

import FsLightbox from "fslightbox-react";

function PreviewTasks({
  close,
  selectedRows,
  screenshot
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  const [copy, setCopy] = useState("Copy to Clipboard");
  const [toggler, setToggler] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const copyToClipboard = async () => {
   // Handle copy to clipboard action
    var r = document.createRange();
    r.selectNode(document.getElementById("tasksCopy"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    
 

    setCopy("Copied!");

    setTimeout(function() {
        setCopy("Copy to Clipboard");
    }, 1000);
  };

  // Assuming selectedRows is an array of data
  const groupedData = selectedRows.reduce((result, cols) => {
    const key = cols[0]; // Assuming cols[0] is the value to group by
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(cols);
    return result;
  }, {});

  function stripHtmlTags(html) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || '';
  }

  return (
    <>
      <div
        ref={modalContainer}
        className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-60 z-50"
        onClick={() => {
          close(false);
        }}
      >
        <div
          ref={modalContent}
          className="max-h-screen w-full h-full flex flex-col bg-gray-100 rounded-xl opacity-0 scale-90"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-2 flex justify-between bg-white">
            <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                    close(false);
                  }}
            >
              <FaChevronLeft className="text-xs mr-4" />
              <span className="font-medium text-xs underline">
                Preview Task
              </span>
            </div>
            <div>
            <button
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover-bg-green-700 dark:focus:ring-green-800 w-48" // Change the width as per your requirement
                onClick={() => {
                    // Handle copy to clipboard action
                    copyToClipboard();
                }}
                >
                {copy}
            </button>
            </div>
          </div>
          <div
            className="overflow-y-auto p-12 z-50"
            style={{
              maxHeight: "calc(100% - 60px)",
              width: "100%",
            }}
          >
            <div className="p-2 bg-white" id="tasksCopy" >
                <div className="mb-6">
                  <p className="font-semibold mb-2" >A. MMA</p>
                  <div className="h-auto">
                    {screenshot.map((sc, idx) => (
                      <div key={idx}>
                        <span className="text-xs">{idx + 1} of {screenshot.length}</span>
                        <img
                          id={`screenshot-${idx}`}
                          className="cursor-pointer"
                          width={300}
                          src={sc}
                          onClick={() => {
                            setCurrentImageIndex(idx);
                            setSelectedImage(sc); // Set the selected image URL
                            setToggler(!toggler);// Toggle the lightbox
                          }}
                          alt={`Selected tasks screenshot ${idx}`}
                        />
                      </div>
                    ))}
                    <FsLightbox
                      toggler={toggler}
                      sources={selectedImage ? [selectedImage] : []} // Only open the selected image
                      onClose={() => {
                        setCurrentImageIndex(null);
                        setSelectedImage(null); // Clear the selected image
                      }}
                    />
      </div>

                </div>
                <div >
                    <p className="font-semibold mb-2">B. Lists of Tasks</p>
                    {Object.keys(groupedData).map((clientMatter, index) => (
                        <div key={index}>
                        <p className="text-xs mb-2" >{index+1}.&nbsp;&nbsp;{clientMatter}</p> {/** Client Matter */}
                        {groupedData[clientMatter].map((cols, subIndex) => {
                            const dueDate = new Date(cols[11]);
                            const formattedDueDate = dueDate.toLocaleDateString('en-AU', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            });

                            const subItemLetter = String.fromCharCode(97 + subIndex); // Convert index to letter

                            return (
                                <p key={subIndex} className="text-xs mb-2">&nbsp;&nbsp;&nbsp;&nbsp; {/** Apply Tailwind CSS indentation */}
                                    <span className="text-xs mr-1">{subItemLetter}.&nbsp;&nbsp;</span>
                                    {stripHtmlTags(cols[6])} - {/** Description */}
                                    P{cols[8]} - {/** Priority */}
                                    due {formattedDueDate} {/** Due Date */}
                                </p>
                            );
                        })}
                        </div>
                    ))}
                </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviewTasks;
