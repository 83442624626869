import React, { useState, useEffect, useRef, useReducer } from "react";
import anime from "animejs";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import { Base64 } from "js-base64";

var moment = require("moment");

function EmailPreviewModal({
  close,
  emailPreviewState,
  setemailPreviewState
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);

  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => {
          setemailPreviewState({
            preview: [],
            from: null,
            dateEmail: null,
            subject: null,
            to: null,
            cc: null
          })
          close(false)
        }}
        className="w-full opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="w-full max-w-4xl px-8 pt-6 pb-8 flex flex-col bg-white rounded-xl items-center justify-center opacity-0 scale-90"
          onClick={(e) => e.stopPropagation()}
          style={{
            maxHeight: "500px",
            height: "100%"
          }}
        >
            <div
              className="absolute rounded bg-white p-6 z-50 w-2/5 max-w-4xl overflow-y-auto"
              style={{
                height: "98%",
                width: "98%"
              }}
              // onClick={(e) => e.stopPropagation()}
            >
              {emailPreviewState.from ? (
                <>
                  <p>{`From: ${emailPreviewState.from}`}</p>
                  <p>
                    {`Date: ${moment(emailPreviewState.dateEmail).format(
                      "DD MMM YYYY, hh:mm A"
                    )}`}
                  </p>
                  <p>{`Subject: ${emailPreviewState.subject}`}</p>
                  <p>{`To: ${emailPreviewState.to}`}</p>
                  {emailPreviewState.cc && <p>{`CC: ${emailPreviewState.cc}`}</p>}

                  <p
                    className="mt-8 p-2"
                    dangerouslySetInnerHTML={{
                      __html: emailPreviewState.preview
                    }}
                  ></p>
                </>
              ) : (
                <>
                  <SkeletonTheme width={"40%"}>
                    <p>
                      <Skeleton count={4} />
                    </p>
                  </SkeletonTheme>
                  <p className="mt-8 p-2">
                    <Skeleton count={15} />
                  </p>
                </>
              )}
            </div>
          </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default EmailPreviewModal