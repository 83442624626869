import { useState } from "react";
import { Auth } from "aws-amplify";
import "../../assets/styles/Auth.css";
import Button from "../../shared/Button"
import { RiLockPasswordFill } from "react-icons/ri";
import { MdCheck, MdOutlineClose } from "react-icons/md"
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

import InputCode from "./input-code";
import ProgressNotification from "./progress-notification";

export default function ResetPassword({
  user,
  setUser,
  AuthState,
  setNextAuthState,
  notifMessage,
  notifShow,
  notifVariant,
  setNotifMessage,
  setNotifShow,
  setNotifVariant,
  loading,
  setLoading,
}) {
  const [password, setPassword] = useState("")

  const [code, setCode] = useState("");
  const [email, setEmail] = useState();
  const [codeSent, setCodeSent] = useState(false);

  const [passwordReq, setPasswordReq] = useState({
    "minimum": false,
    "uppercase": false,
    "lowercase": false,
    "numeral": false,
    "symbol": false,
  })

  const [passwordVisibility, setPasswordVisibility] = useState(false)

  function handlePasswordVisibility(event) {
    event.preventDefault()
    setPasswordVisibility(!passwordVisibility)
  }


  async function handleResetPassword(event) {
    event.preventDefault();
    setLoading(true)
    setNotifShow(false)
    console.log("user:", user)

    if (user?.code) {
      const resetPassword = await Auth.forgotPasswordSubmit(
        user.username,
        code,
        event.target.password.value
      )
        .then((data) => {
          console.log(data);
          setLoading(false)
          setNotifMessage("Successfully changed password")
          setNotifVariant("success")
          setNotifShow(true)
          window.scrollTo(0, 0);
          setTimeout(() => {
            setNextAuthState(AuthState.SignIn)
            setNotifShow(false)
          }, 2000);
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
          setNotifMessage(err.message)
          setNotifVariant("error")
          setNotifShow(true)
          window.scrollTo(0, 0);
        });
    }

    if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
      const resetPassword = await Auth.completeNewPassword(
        user, 
        event.target.password.value,
      )
        .then((user) => {
          console.log(user)
          setUser(user)
          setLoading(false)
          setNextAuthState(AuthState.SignedIn)
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setNotifMessage(err.message)
          setNotifVariant("error")
          setNotifShow(true)
          window.scrollTo(0, 0);
        })
    }
    
  }

  // Password Requirement Function

  const minimumRegex = new RegExp("^(?=.{8,})")
  const lowerRegex = new RegExp("^(?=.*[a-z])")
  const upperRegex = new RegExp("^(?=.*[A-Z])")
  const numeralRegex = new RegExp("^(?=.*[0-9])")
  const symbolRegex = new RegExp("[^A-Za-z0-9]")

  function handlePasswordRequirements(event) {
    setPassword(event.target.value)
    let minimum = false;
    let lowercase = false;
    let uppercase = false;
    let numeral = false;
    let symbol = false;
    
    if (minimumRegex.test(event.target.value)) {
      console.log("MINIMUM TRUE!")
      minimum = true
    }

    if (lowerRegex.test(event.target.value)) {
      lowercase = true
    }

    if (upperRegex.test(event.target.value)) {
      uppercase = true
    }

    if (numeralRegex.test(event.target.value)) {
      numeral = true
    }

    if (symbolRegex.test(event.target.value)) {
      symbol = true
    }

    setPasswordReq({
      minimum: minimum,
      lowercase: lowercase,
      uppercase: uppercase,
      numeral: numeral,
      symbol: symbol,
    })
    
  }

  function handleCheckmark(req) {
    return(
      req === true ? <MdCheck className="text-secondary w-5 flex-none"/> : <MdOutlineClose className="text-danger w-5 flex-none" />
    )
  }

  return (
    <>
      <div className="w-full mt-10 md:mt-0 h-full fade-in-fwd">
        {(user?.challengeName === "NEW_PASSWORD_REQUIRED") && (
          <div className="font-semibold text-hbBlue text-2xl md:text-3xl">
            NEW PASSWORD
          </div>
        )}
        {(user?.code) && (
          <div className="font-semibold text-hbBlue text-2xl md:text-3xl">
            FORGOT PASSWORD
          </div>
        )}
        <div>
          <div className="w-full mt-10">
            <form onSubmit={(event) => handleResetPassword(event)}>
              <div className="flex flex-col" style={{ maxWidth: "500px" }}>
              <ProgressNotification 
              className=""
              variant={notifVariant}
              message={notifMessage}
              show={notifShow}
              />
                {(user?.code) ? (
                  <>
                    <div className="flex flex-col mt-3">
                      <label
                        for="forgotPassword-email"
                        className="text-xl font-normal text-darkGray"
                      >
                        Please check your email
                      </label>
                      {/* <span>{`We've sent a code to ${email}`}</span> */}
                      <span>{`We've sent a code to your email.`}</span>
                      <div className="justify-center flex">
                        <InputCode
                          length={6}
                          label="Code Label"
                          onComplete={(code) => {
                            setCode(code);
                          }}
                          className="mx-1 w-10 md:w-12 md:h-16 text-center mt-2 h-12 py-2 border-4 border-neutral-100 bg-neutral-100 outline-none rounded-xl text-2xl text-hbGray font-bold placeholder-hbGray focus:outline-none focus:ring-2 ring-hbBlue"
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {/* <>
                  <div className="flex flex-col mt-3">
                    <label
                      for="forgotPassword-email"
                      className="text-xl font-normal text-darkGray"
                    >
                      Please check your email
                    </label>
                    <span>{`We've sent a code to ${user.username}`}</span>
                    <div className="justify-center flex">
                      <InputCode
                        length={6}
                        label="Code Label"
                        onComplete={(code) => {
                          setCode(code);
                        }}
                        className="mx-1 w-10 md:w-12 md:h-16 text-center mt-2 h-12 py-2 border-4 border-neutral-100 bg-neutral-100 outline-none rounded-xl text-2xl text-hbGray font-bold placeholder-hbGray focus:outline-none focus:ring-2 ring-hbBlue"
                      />
                    </div>
                  </div>
                </> */}
                <div className="flex flex-col mt-4">
                  <label
                    htmlFor="signIn-password"
                    className="text-xl font-normal text-darkGray"
                  >
                    Password
                  </label>
                  <div className="mt-2 relative flex flex-col">
                    <input
                      type={passwordVisibility ? "text" : "password"}
                      id="reset-password"
                      key="reset-password"
                      name="password"
                      value={password}
                      onChange={(event) => handlePasswordRequirements(event)}
                      placeholder="Password"
                      className="pl-14 md:pl-16 pr-14 h-14 md:h-16 py-2 border-4 border-neutral-100 outline-none rounded-xl text-base md:text-xl text-hbGray font-normal placeholder-gray-300 focus:outline-none focus:ring-2 ring-hbBlue"
                      //style={{paddingLeft: "72px"}}
                      required
                      autoComplete="new-password"
                    />
                    <label htmlFor="signIn-password">
                      <div className="absolute top-1/2 transform -translate-y-1/2 left-4 border-r-2 pr-2">
                        <RiLockPasswordFill className="text-2xl md:text-4xl text-hbGray" />
                      </div>
                    </label>
                    <button 
                      className="absolute right-6 top-1/2 transform -translate-y-1/2"
                      type="button" 
                      onClick={e => handlePasswordVisibility(e)}>
                      {passwordVisibility ? (
                        <FaEyeSlash className="text-2xl text-hbGray" />
                      ) : (
                        <FaEye className="text-2xl text-hbGray" />
                      )}
                    </button>
                  </div>
                </div>
                {/* Password Requirements */}
                <div className="mt-4">
                  <ul className="ml-2">
                    <li className="text-base md:text-lg flex items-center gap-2">
                      {handleCheckmark(passwordReq.minimum)}
                      Minimum of 8 characters
                    </li>
                    <li className="text-base md:text-lg flex items-center gap-2">
                      {handleCheckmark(passwordReq.lowercase)}
                      Must include at least 1 lowercase character (a-z)
                    </li>
                    <li className="text-base md:text-lg flex items-center gap-2">
                      {handleCheckmark(passwordReq.uppercase)}
                      Must include at least 1 uppercase character (A-Z)
                    </li>
                    <li className="text-base md:text-lg flex items-center gap-2">
                      {handleCheckmark(passwordReq.numeral)}
                      Must include at least 1 numeral (0-9)
                    </li>
                    <li className="text-base md:text-lg flex items-center gap-2">
                      {handleCheckmark(passwordReq.symbol)}
                      Must include at least 1 symbol
                    </li>
                  </ul>
                </div>

                {/* Buttons footer */}
                <div className="flex flex-col-reverse md:grid md:grid-cols-2 mt-14 md:mt-9 items-center">
                  <div className="block md:col-span-1 mt-3 md:mt-0">
                    <span className="text-base font-normal text-hbGray">
                      Back to
                    </span>
                    <button
                      className="ml-1 text-base font-normal text-hbBlue"
                      type="button"
                      onClick={() => {setNotifShow(false);setNextAuthState(AuthState.SignIn); } }
                    >
                      Login
                    </button>
                  </div>
                  <div className="w-full md:col-span-1">
                    <Button
                      type="submit"
                      variant="hbBlue"
                      block={true}
                      size={"xl"}
                      className="h-16 text-lg"
                      disabled={loading}
                    >
                      <span className="relative text-xl font-semibold">
                        {loading ? <AiOutlineLoading className="absolute rotate-center -left-6 top-1" /> : null}
                        CONFIRM
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
