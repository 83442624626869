import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { AiOutlineTags } from "react-icons/ai";
import { pageSelectedLabels } from "./index";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";

import "../../assets/styles/multiselect-custom.css";
import "../../assets/styles/filter-labels.css";
import Button from "../../shared/Button";
import { CgClose } from "react-icons/cg";
import moment from "moment";

let selectedFilters = [];
let filesToSend = [];
let filterOptions = [];

export default function FilterLabels({
	handleSave,
	handleModalClose,
	datePickerRef1,
	datePickerRef2,
	getMatterFiles,
	files,
	setFilterState,
	setFiles,
	matterFiles,
	setMatterFiles,
}) {
	const [DatePickerActive, setDatePickerActive] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [subStartDate, setSubStartDate] = useState(null);
	const [subEndDate, setSubEndDate] = useState(null);

	filterOptions = pageSelectedLabels;

	const handleFilter = async () => {
		let filteredFiles = files;
		console.log("Start Date:", startDate, "  sub start date:", subStartDate);
		console.log("End Date:", endDate, "  sub End Date:", subEndDate);

		console.log("Filtered Files:", filteredFiles);

		// Apply label filter if there are selected labels
		if (filesToSend.length > 0) {
			filteredFiles = filteredFiles.filter((file) => {
				return file.backgrounds?.items?.some((item) => {
					return item.briefs?.some((brief) => filesToSend.includes(brief.name));
				});
			});
		}

		// Apply date range filter if both start and end dates are selected
		if (startDate !== null && endDate !== null) {
			filteredFiles = filteredFiles.filter((data) => {
				var fileDate = new Date(data.date);
				var startDateRange = new Date(startDate);
				var endDateRange = new Date(endDate);
				return (
					fileDate >= startDateRange && fileDate <= endDateRange && fileDate
				);
			});
		}

		if (subStartDate !== null && subEndDate !== null) {
			filteredFiles = filteredFiles.filter((data) => {
				var fileDate = new Date(data.createdAt);
				var startDateRange = new Date(subStartDate);
				var endDateRange = new Date(subEndDate);
				console.log(
					"FILE DATE:",
					fileDate,
					"--Start Date:",
					startDateRange,
					"--End Date:",
					endDateRange
				);
				return (
					fileDate >= startDateRange && fileDate <= endDateRange && fileDate
				);
			});
		}
		console.log("FILES TO SEND: ", filesToSend);
		console.log("FILTERED FILES", filteredFiles);
		setFiles(filteredFiles);
		setMatterFiles(filteredFiles);
		handleSave(filteredFiles);
	};

	const handleFilterChange = (evt) => {
		filesToSend = evt; //filter Labels, send data to index
		selectedFilters = evt; //save for UI display of selected labels
	};

	const handleRemoveChange = (evt) => {
		filesToSend = evt; //filter Labels, send data to index
		selectedFilters = evt; //save for UI display of selected labels
	};

	function setDefault(data) {
		selectedFilters = [...selectedFilters, data[0]];
		filesToSend = [...selectedFilters, data[0]];
		return data;
	}

	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const subOnChange = (dates) => {
		const [start, end] = dates;
		setSubStartDate(start);
		setSubEndDate(end);
	};

	useEffect(() => {
		setStartDate(null);
		setEndDate(null);
		setSubEndDate(null);
		setSubEndDate(null);
	}, []);

	return (
		<>
			<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
				<div className="relative w-full max-w-lg mx-auto my-6">
					<div className="relative flex flex-col w-full p-10 bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
						<div className="flex items-start justify-between rounded-t">
							<h2 className="pb-2 text-lg font-semibold text-gray-900">
								Manage Filters
							</h2>
							<button
								className="flex flex-row items-center justify-center w-8 h-8 ml-auto bg-gray-100 rounded-full cursor-pointer hover:bg-gray-300"
								onClick={(e) => handleModalClose()}
							>
								<CgClose />
							</button>
						</div>

						<div className="px-5 py-1">
							<div className="relative flex-auto">
								<div className="flex flex-col items-start py-3">
									<div className="relative flex-auto w-full">
										<div className="text-sm font-medium text-gray-400">
											{`Contains Labels *`}
										</div>
										<div className="relative my-1">
											<Multiselect
												isObject={false}
												onRemove={(event) => handleRemoveChange(event)}
												onSelect={(event) => handleFilterChange(event)}
												options={filterOptions}
												selectedValues={
													selectedFilters.length > 0 ? selectedFilters : []
												} // Pass an empty array when no filters are selected
												showCheckbox
											/>
										</div>
									</div>
									<div className="pt-4 pb-1 w-full">
										<div className="pt-1 w-full">
											<div className="flex items-center gap-4 font-light w-full whitespace-nowrap">
												<span className="w-1/4">Date Received:</span>
												<div className="w-3/4">
													<DatePicker
														ref={datePickerRef1}
														onFocus={() => setDatePickerActive(true)}
														onBlur={() => setDatePickerActive(false)}
														selected={startDate}
														placeholderText="Date Received"
														onChange={onChange}
														startDate={startDate}
														endDate={endDate}
														selectsRange
														monthsShown={2}
														calendarClassName="lg:flex bg-white"
														weekDayClassName=""
														className={`p-2 bg-transparent w-full bg-gray-200 rounded-2xl `}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="pt-4 pb-1 w-full">
										<div className="pt-1 w-full">
											<div className="flex items-center gap-4 font-light w-full whitespace-nowrap justify-evenly">
												<span className="w-1/4">Date Submitted:</span>
												<div className="w-3/4">
													<DatePicker
														ref={datePickerRef2}
														onFocus={() => setDatePickerActive(true)}
														onBlur={() => setDatePickerActive(false)}
														selected={subStartDate}
														placeholderText="Date Submitted"
														onChange={subOnChange}
														startDate={subStartDate}
														endDate={subEndDate}
														selectsRange
														monthsShown={2}
														calendarClassName="lg:flex bg-white"
														weekDayClassName=""
														className={`p-2 bg-transparent w-full bg-gray-200 rounded-2xl `}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="flex items-center justify-center gap-2 p-6 rounded-b">
							<Button
								variant="danger"
								className="w-full py-3 px-7"
								onClick={(e) => {
									getMatterFiles();
									handleModalClose();
									setFilterState(false);
								}}
							>
								Clear Filters
							</Button>
							<Button
								variant="secondary"
								className="w-full px-4 py-3"
								onClick={(options) => handleFilter(options)}
							>
								Apply Filter &nbsp; <AiOutlineTags />
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="fixed inset-0 z-40 bg-black opacity-25"></div>
		</>
	);
}
