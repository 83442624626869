import React, { useEffect, useState } from "react";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import backButton from "../../assets/images/back-arrow.svg";
import arrowUp from "../../assets/images/arrow-up.svg";
import arrowDown from "../../assets/images/arrow-down.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import yellowBubbles from "../../assets/images/yellow-bubbles.png";
import warning from "../../assets/images/warning.png";
import { BsPencilSquare, BsCheckSquare, BsXSquare } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory } from "react-router-dom";
import { usePopper } from "react-popper";
import { FaChevronDown } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import publishImage from "../../assets/images/publish.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const qGetBriefDetails = `query brief($id: ID!) {
    brief(id: $id){
       backgroundTabTable {
        insertCoverPage
        tabTextLabel
        tabs {
          fileNumberText
          fileNameMarkdown
          tabNumber
		  fileIds
        }
      }
	  name
    }
  }`;

const mUpdateTabTable = `mutation update($id: ID!, $input: BriefBackgroundTabTableInput) {
	briefUpdate(id: $id, backgroundTabTable: $input) {
	  id
	}
  }`;

const mBackgroundTabsExport = `mutation backgroundTabsExport($briefId: ID!, $viaEmail: Boolean!) {
	backgroundTabsExport(briefId: $briefId, viaEmail: $viaEmail)
  }`;

const subOnBackgroundTabsExportResultPublished = `subscription onBackgroundTabsExportResultPublished( $executionArn: String) {
    onBackgroundTabsExportResultPublished(executionArn: $executionArn) {
      briefId
      executionArn
      exportDownloadUrl
    }
  }`;

function CreateDocumentPage() {
	const [showControlPanel, setShowControlPanel] = useState(true);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [tabTableDetails, setTabTableDetails] = useState([]);
	const [editableRow, setEditableRow] = useState([]);
	const [activeRowEdits, setActiveRowEdits] = useState({
		fileNumberText: null,
		fileNameMarkdown: null,
		tabNumber: null,
		fileIds: null,
	});
	const [markerNameEditable, setMarkerNameEditable] = useState(false);
	const [newMarkerLabel, setNewMarkerLabel] = useState(null);
	const [insertCoverPage, setInsertCoverPage] = useState(false);
	const [executionArn, setExecutionArn] = useState(null);
	const [generatedPdf, setGeneratedPdf] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [showEmailNotification, setShowEmailNotification] = useState(false);

	//Publish button states
	const [visibility, setVisibility] = useState("false");
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [arrowElement, setArrowElement] = useState(null);
	const [isDownloading, setDownloading] = useState(false);

	// console.log("Tab Table Details", tabTableDetails.backgroundTabTable);
	// console.log("New marker label", newMarkerLabel);
	const params = useParams();
	const { brief_id } = params;
	const history = useHistory();

	useEffect(() => {
		getBriefDetails();
		backgroundTabsExport({ viaEmail: false });
	}, []);

	useEffect(() => {
		if (tabTableDetails?.backgroundTabTable?.tabs) {
			let booleanCounter = [];
			for (let i = 0; i < tabTableDetails.backgroundTabTable.tabs.length; i++) {
				booleanCounter.push(false);
			}

			setEditableRow(booleanCounter);
		}

		setInsertCoverPage(tabTableDetails?.backgroundTabTable?.insertCoverPage);
	}, [tabTableDetails]);

	useEffect(() => {
		if (executionArn) {
			const params = {
				query: subOnBackgroundTabsExportResultPublished,
				variables: {
					executionArn: executionArn,
				},
			};

			const sub = API.graphql(params).subscribe({
				next: ({ value }) => {
					setGeneratedPdf(
						value.data.onBackgroundTabsExportResultPublished.exportDownloadUrl
					);
					setIsLoading(false);
				},
				error: (error) => console.warn(error),
			});

			return () => {
				sub.unsubscribe();
			};
		}
	}, [executionArn]);

	//API FUNCTIONS

	async function getBriefDetails() {
		const params = {
			query: qGetBriefDetails,
			variables: {
				id: brief_id,
			},
		};

		const result = await API.graphql(params);
		setTabTableDetails(result.data.brief);
	}

	async function backgroundTabsExport({ viaEmail }) {
		const params = {
			query: mBackgroundTabsExport,
			variables: {
				briefId: brief_id,
				viaEmail: viaEmail,
			},
		};

		try {
			const data = await API.graphql(params);
			setExecutionArn(
				JSON.parse(JSON.parse(data.data.backgroundTabsExport).body).executionArn
			);
		} catch (error) {
			console.error("Error updating table:", error);
		}
	}

	async function handlePublishDocument(e) {
		await backgroundTabsExport({ viaEmail: true });
		visibilityToggle(e);
		// toast.success(`The file will be shared to your registered email address.`);
		setShowEmailNotification(true);
	}

	async function updateTabTable() {
		const params = {
			query: mUpdateTabTable,
			variables: {
				id: brief_id,
				input: tabTableDetails.backgroundTabTable,
			},
		};

		try {
			const updateTable = await API.graphql(params);
			getBriefDetails();
		} catch (error) {
			console.error("Error updating table:", error);
		}
	}

	async function handleSaveChanges() {
		setIsLoading(true);
		setGeneratedPdf(null);
		setPageNumber(1);
		await updateTabTable();
		await getBriefDetails();
		await backgroundTabsExport({ viaEmail: false });
	}

	//ADMINISTRATIVE FUNCTIONS
	const saveEditToLocalState = (index) => {
		const tempBackgroundTabTable = {
			...tabTableDetails,
		};

		tempBackgroundTabTable.backgroundTabTable.tabs[index] = activeRowEdits;

		setTabTableDetails(tempBackgroundTabTable);
		toggleEditRow(index);
	};

	const toggleEditMarkerLabel = (e) => {
		if (markerNameEditable && newMarkerLabel) {
			const tempBackgroundTabTable = {
				...tabTableDetails,
			};

			tempBackgroundTabTable.backgroundTabTable.tabTextLabel = newMarkerLabel;
			setTabTableDetails(tempBackgroundTabTable);
		}

		setMarkerNameEditable(!markerNameEditable);
	};

	const toggleEditRow = (index, file) => {
		console.log("File", file);
		setEditableRow((prev) => {
			const updatedEditableRow = {};
			for (const key in prev) {
				updatedEditableRow[key] = false;
			}
			updatedEditableRow[index] = true;
			return updatedEditableRow;
		});

		setActiveRowEdits({
			fileNumberText: file?.fileNumberText?.replace(/<br>/g, ", "),
			fileNameMarkdown: file?.fileNameMarkdown?.split("<br>")[0],
			tabNumber: file?.tabNumber,
			fileIds: file?.fileIds,
		});
	};

	const toggleInsertCoverPage = () => {
		const updatedInsertCoverPage = !insertCoverPage;
		setInsertCoverPage(updatedInsertCoverPage);

		const tempBackgroundTabTable = {
			...tabTableDetails,
			backgroundTabTable: {
				...tabTableDetails.backgroundTabTable,
				insertCoverPage: updatedInsertCoverPage,
			},
		};

		setTabTableDetails(tempBackgroundTabTable);
	};

	const {
		styles: popStyles,
		attributes,
		state: popState,
		update: popUpdate,
	} = usePopper(referenceElement, popperElement, {
		modifiers: [
			{
				name: "arrow",
				options: { element: arrowElement, placement: "bottom" },
			},
			{
				name: "offset",
				options: {
					offset: [0, 5],
				},
			},
		],
	});

	const visibilityToggle = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setVisibility(!visibility);
		popUpdate();
	};

	function nextPage(event) {
		event.preventDefault();
		const pageVal = pageNumber + 1;
		if (pageVal <= numPages) {
			return setPageNumber(pageVal);
		}
		return;
	}

	function prevPage(event) {
		event.preventDefault();
		const pageVal = pageNumber - 1;
		if (pageVal > 0) {
			return setPageNumber(pageVal);
		}
		return;
	}

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	return (
		<>
			<div className="min-h-full ml-20 flex flex-col relative ">
				<div
					className="flex items-center justify-between px-4 py-5"
					style={{ backgroundColor: "#F9F9F9" }}>
					<div className="flex items-center gap-4">
						<button
							onClick={() => {
								history.goBack();
							}}>
							<img src={backButton} />
						</button>
						<div className=" text-lg font-semibold">
							{tabTableDetails?.name}
						</div>
					</div>
					<div>
						<div className="inline-flex rounded-md shadow-sm ml-2">
							<button
								onClick={(e) => {
									visibilityToggle(e);
								}}
								type="button"
								className="border-blue-300 border h-10 w-60 rounded-md"
								ref={setReferenceElement}>
								<div className="flex flex-row text-blue-300 h-full items-center">
									<div className=" pl-2">
										<img
											src={publishImage}
											alt=""
										/>
									</div>
									<div className="w-3/4 border-blue-400 items-center font-semibold">
										PUBLISH DOCUMENT
									</div>
									<div className="h-full border-blue-300 border-r"></div>
									<div className="flex w-1/4 justify-center">
										{isDownloading ? (
											<AiOutlineLoading3Quarters className="w-3 animate-spin" />
										) : (
											<FaChevronDown />
										)}
									</div>
								</div>
							</button>
						</div>

						<div
							className={`${
								visibility ? "hidden" : ""
							} w-64 h-14 shadow-md bg-white p-3 z-50`}
							ref={setPopperElement}
							style={popStyles.popper}
							{...attributes.popper}>
							<div>
								<div>
									<div className="flex text-sm h-8">
										<button
											className="w-full text-left hover:bg-blue-300"
											onClick={(e) => handlePublishDocument(e)}>
											PDF
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex p-4">
					<div
						className="w-1/2 flex flex-col"
						style={{ display: showControlPanel ? "flex" : "none" }}>
						<div
							className="flex flex-row h-14 justify-between"
							style={{ backgroundColor: "#F9F9F9" }}>
							<div className="pl-4 p-4 self-center flex-col text-base font-semibold">
								Control Panel
							</div>
							<div className="flex gap-4 items-center pr-4">
								<div className="flex gap-2 items-center">
									<div>Insert Cover Image</div>
									<Checkbox
										checked={insertCoverPage}
										onChange={() => {
											toggleInsertCoverPage();
										}}
									/>
								</div>
								<div className="pl-4  border-l border-gray-300">
									<button
										className="font-semibold"
										onClick={() => {
											handleSaveChanges();
										}}>
										Save
									</button>
								</div>
							</div>
						</div>

						<table>
							<thead>
								<tr
									style={{ backgroundColor: "#F9F9F9" }}
									className="border-b border-gray-300">
									<th className="w-2/12 text-left font-semibold py-4 pl-4">
										Item #
									</th>
									<th className="w-2/12 text-left font-semibold pl-4">
										File #
									</th>
									<th className="w-5/12 text-left font-semibold pl-4">
										File Name
									</th>
									<th className="w-2/12 text-left ">
										<div className="flex gap-2 relative">
											<button
												style={{
													position: "absolute",
													left: "-16px",
													top: "4px",
												}}
												onClick={() => {
													toggleEditMarkerLabel();
												}}>
												<BsPencilSquare className="text-xs" />
											</button>
											<div
												className={`font-semibold w-full ${
													markerNameEditable
														? "bg-white border-b border-gray-300"
														: ""
												}`}
												dangerouslySetInnerHTML={{
													__html:
														tabTableDetails?.backgroundTabTable?.tabTextLabel,
												}}
												contentEditable={markerNameEditable}
												onInput={(e) =>
													setNewMarkerLabel(e.currentTarget.innerText)
												}></div>
										</div>
									</th>
									<th className="w-1/12 font-semibold px-auto">Edit</th>
								</tr>
							</thead>
							<tbody>
								{tabTableDetails &&
									tabTableDetails?.backgroundTabTable?.tabs?.map(
										(file, index) => {
											return (
												<tr
													key={index}
													className={`align-top ${
														editableRow[index] ? "bg-gray-100" : ""
													} ${
														index !==
														tabTableDetails.backgroundTabTable.tabs.length - 1
															? "border-b border-dashed border-gray-300"
															: ""
													} `}>
													<td className="pl-4 py-2 leading-6">{index + 1}</td>
													<td
														className="pl-4 py-2 leading-6"
														dangerouslySetInnerHTML={{
															__html: editableRow[index]
																? file?.fileNumberText?.replace(/<br>/g, ", ")
																: file?.fileNumberText?.replace(
																		/<br>/g,
																		"<br/>"
																  ),
														}}></td>
													<td
														className="pl-4 py-2 leading-6 pr-12"
														dangerouslySetInnerHTML={{
															__html: editableRow[index]
																? file?.fileNameMarkdown?.split("<br>")[0]
																: file?.fileNameMarkdown?.replace(
																		/<br>/g,
																		"<br/>"
																  ),
														}}
														contentEditable={editableRow[index]}
														onBlur={(e) => {
															const updatedFileName = e.currentTarget.innerHTML;
															setActiveRowEdits((prev) => ({
																...prev,
																fileNumberText: file.fileNumberText.replace(
																	/<br>/g,
																	", "
																),
																fileNameMarkdown: updatedFileName,
																tabNumber: file.tabNumber,
															}));
														}}
														style={{
															whiteSpace: "nowrap", // Prevents text from wrapping
															overflow: "hidden", // Hides any content that overflows the cell
															maxWidth: "300px",
														}}></td>
													<td
														className="py-2 leading-6"
														dangerouslySetInnerHTML={{
															__html: `${tabTableDetails.backgroundTabTable.tabTextLabel} ${file.tabNumber}`,
														}}
														contentEditable={editableRow[index]}></td>
													<td className="pt-3 leading-6 flex items-center justify-center">
														{editableRow[index] ? (
															<div className="flex gap-2">
																<button
																	onClick={() => {
																		saveEditToLocalState(index);
																	}}>
																	<BsCheckSquare className="text-sm text-green-600" />
																</button>
																<button
																	onClick={() => {
																		setEditableRow((prev) => ({
																			...prev,
																			[index]: false,
																		}));
																	}}>
																	<BsXSquare className="text-sm text-red-600" />
																</button>
															</div>
														) : (
															<button
																onClick={() => {
																	toggleEditRow(index, file);
																}}>
																<BsPencilSquare className="text-sm" />
															</button>
														)}
													</td>
												</tr>
											);
										}
									)}
							</tbody>
						</table>
					</div>
					<div className={`${showControlPanel ? "w-1/2" : "w-full"} pl-4 flex`}>
						<div className=" relative border-l w-full">
							<button
								className=" absolute h-6 w-6 rounded-full border border-gray-300 -left-3 top-4 bg-white flex justify-center items-center"
								onClick={() => {
									setShowControlPanel(!showControlPanel);
								}}>
								<img
									src={arrowRight}
									alt=""
									className={`${
										showControlPanel ? "transform rotate-180" : ""
									}`}
								/>
							</button>

							<div className="flex flex-col">
								<div className="flex">
									<div className="w-1/3 py-4 pl-8 flex-col font-semibold text-base ">
										Viewer Panel
									</div>
									<div className="w-1/3 flex justify-center items-center justify-self-center">
										<div className="px-1">
											<button
												className="w-full"
												onClick={prevPage}>
												<img
													src={arrowUp}
													alt="UP"
												/>
											</button>
										</div>
										<div className=" px-1">
											<button
												className="w-full"
												onClick={nextPage}>
												<img
													src={arrowDown}
													alt="Down"
												/>
											</button>
										</div>
										<div className=" pl-4 flex">
											<input
												className=" w-7 text-center border-b border-gray-300"
												inputMode="numeric"
												pattern="\d*"
												value={pageNumber}
												min={1}
												max={numPages}
												onChange={(event) => {
													setPageNumber(Number(event.target.value));
												}}
											/>
											/
											<div className=" ml-2">
												{isLoading ? "TBD" : numPages}
											</div>
										</div>
									</div>
									<div className="w-1/3"></div>
								</div>

								<div className="p-8 flex justify-items-center w-full">
									<div
										style={{
											borderTop: "#E8E8E8 solid 18px",
											borderBottom: "#E8E8E8 solid 18px",
											borderRadius: "8px",
											maxWidth: !showControlPanel ? "800px" : "",
											marginLeft: "auto",
											marginRight: "auto",
											width: "100%",
											minHeight: "70vh",
										}}>
										{isLoading ? (
											<h1>Loading PDF...</h1>
										) : (
											<Document
												file={generatedPdf}
												onLoadSuccess={onDocumentLoadSuccess}>
												<Page pageNumber={pageNumber} />
											</Document>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Notification */}
				{showEmailNotification && (
					<div className="sticky bottom-0">
						<div
							className=" h-20  absolute bottom-4 right-4 rounded-lg p-4 flex"
							style={{
								width: "600px",
								backgroundColor: "#E49256",
							}}>
							<img
								src={warning}
								className="absolute bottom-14 z-10 h-10 left-6"
							/>
						</div>

						<div
							className=" h-20  absolute bottom-4 right-4 rounded-lg p-4 text-white flex"
							style={{
								width: "600px",
								backgroundColor: "#E49256",
								overflow: "hidden",
							}}>
							<div className="absolute right-2 top-2">
								<button
									onClick={() =>
										setShowEmailNotification(!showEmailNotification)
									}>
									<AiOutlineClose className="text-sm" />
								</button>
							</div>
							<div>
								<img
									src={yellowBubbles}
									className="absolute -left-4 -bottom-14"
								/>
							</div>

							<div className="pl-16">
								<h3 className="font-bold">System Notification</h3>
								<p>
									File has been successfully published and has been shared in
									your email.
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default CreateDocumentPage;
