import React ,{useState,useEffect,useRef} from "react";
import Spinner from "../../shared/Spinner";
import { HiMinus } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import axios from 'axios';

const ContentOutlining = ({
    isLoading,
    setIsLoading,
    setTextareas,
    textareas,
    setSystemInstruction,
    handleDeleteTextarea,
    systemInstruction,
    deleteIsClicked,
    researchResponse,
    phase,
    handleTextareaChange,
    postFile,
    file,
    setFile,
    handleOutlinePrompt,
    outline,
    setOutline,
    handleDrop,
}) => {
    useEffect(() => {
        if(textareas.length === 0){
            setTextareas([
                {
                    id: 1,
                    value: 'Help me create the content outline for a service page titled, "Construction Lawyer in Griffith, NSW" for my law firm website, Contracts Specialist, considering the documents I uploaded in your "Retrieval" function. In creating the content outline, I want you to: 1. tell me the intent, writing strategy, and word count for each section in the content outline; 2.include the keywords “Griffith (Central - South), Griffith (East) - Collina, Griffith (North), Griffith (West), Hanwood - Tharbogang and District” in the H1 Introduction, as Griffith’s surrounding suburbs; 3. include the following sections: i. About Our Law Firm ii. Our Services iii. Why Choose Us iv. Free 15-Minute Consultation v. Contact Information vi. Conclusion and CTA 4. not include any case studies or testimonials; 5. include the word count for each heading. The total word count of the page must be 500 to 800 words.',
                },
                ]);
            setSystemInstruction("You are an Australian copywriter and content strategist specialising in legal services of a construction lawyer in Australia. Your task is to craft engaging, informative, and SEO-optimized service pages for a law firm's website. Each page should focus on a specific construction legal service offered by the firm, with content tailored to appeal to potential clients in different regions of Australia. Emphasise the unique aspects of each service and how they address the specific legal needs of clients in these regions. Ensure that the tone is professional yet accessible, providing clear information about the services, their benefits, and how the firm can assist in legal matters.  Remember to align the content with the firm's brand voice and values.")
        }
    },[])
    const drop = useRef(null);
    const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
    const addPrompt = () => {
        setTextareas(prevTextareas => [
        ...prevTextareas,
        {
            id: prevTextareas.length+1,
            value: '',
        },
        ]);
    };

    const textareaStyle = {
        width: '75%',
        height: "80px",
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        resize: 'none',
      };

    return (
        <div className="flex flex-col justify-center items-center mt-8"
            style={{
                width:"55vw",
                height:"85vh",
                borderRadius: '8px',
                padding:"10px",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}
        >
            {isLoading && <Spinner />}
            <div
                className="justify-center items-center flex flex-col -mt-20 py-12"
            >
                <b style={{ 
                        fontSize: '40px',
                        fontStyle:"italic", 
                        color:"rgba(220, 87, 51, 1)" }}
                >
                    Outlining Phase
                </b>
            </div>
            <textarea
                placeholder="Sytem Instruction"
                className="mb-8"
                value={systemInstruction}
                style={textareaStyle}
                onChange={(e) => setSystemInstruction(e.target.value)}
            />
            {textareas.map(textarea => (
                <div key={textarea.id} className="justify-center items-center flex flex-row" style={{ width : "100%"}}>
                    <textarea
                        placeholder="Research Prompt"
                        value={textarea.value}
                        className="mb-8 mr-5"
                        onChange={(e) => handleTextareaChange(textarea.id, e.target.value)}
                        style={textareaStyle}
                    />
                    <button
                        onClick={() =>
                            handleDeleteTextarea(textarea.id)
                        }
                        className="-mt-9"
                        style={{
                            fontSize: '1.5rem',
                            color: !deleteIsClicked ? '#ffffff' : '#A0AEC0', 
                            backgroundColor: !deleteIsClicked ? '#E53E3E' : 'transparent', 
                            padding: '8px', 
                            borderRadius: '10%', 
                            cursor: 'pointer', 
                            }}
                    >
                        <HiMinus
                            data-tip="Delete Prompt"
                        />
                        <ReactTooltip/>
                    </button>
                </div>
            ))}
            <button
                className="w-min text-green-400 border my-2 border-green-400 px-4 py-2 rounded-md"
                onClick={addPrompt}
            >
                Add Prompt
            </button>
            
            {/* <textarea
                placeholder="Research Prompt"
                style={textareaStyle}
            />
            <button
                className="w-min text-green-400 border my-2 border-green-400 px-4 py-2 rounded-md"
            >
                Add Prompt
            </button> */}
            {/* <textarea
                placeholder="Header Prompt"
                style={textareaStyle}
            />
            <textarea
                placeholder="System Instruction"
                style={textareaStyle}
            />  */}
            <div
                ref={drop}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="flex justify-center items-center h-36 mx-auto border p-4 border-dashed rounded-md border-cyan-400 mb-3"
            >
                <p className="mx-auto text-sm text-center  text-cyan-400 font-semibold">
                    DRAG & DROP TO UPLOAD
                </p>
            </div>
            <button
                className="w-min text-cyan-400 border border-cyan-400 px-4 py-2 rounded-md"
                onClick={() => handleOutlinePrompt()}
            >
                Execute Prompt
                
            </button>
    </div>
    )
}
export default ContentOutlining;