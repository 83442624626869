const humaReadableFormat = function (str) {
  // change underscore `_` to space ` `
  // Capitalize each word

  return str
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default humaReadableFormat;
