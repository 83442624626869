import React from "react";
import Routes from "./Routes";
import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";

import "./assets/styles/styles.css";
import "./assets/styles/custom-styles.css";
// import { LoadingProvider } from "./components/authentication/authLoadingContext";

// const currentEnv = process.env.REACT_APP_DOMAIN;

// if (
//   currentEnv.includes("localhost:3000") ||
//   currentEnv.includes("develop.d3bhf42tem9b8e.amplifyapp.com")
// ) {
//   Amplify.configure(awsmobile);
// } else {
//   // For 2FA (OTP)
//   Amplify.configure({
//     ...awsmobile,
//     authenticationFlowType: "CUSTOM_AUTH",
//   });
// }

Amplify.configure({
  ...awsmobile,
  authenticationFlowType: "CUSTOM_AUTH",
});
function App() {
  return (
    // <LoadingProvider>
      <Routes />
    // </LoadingProvider>
  );
}
export default App;
