import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import config from "../../aws-exports";
import DatePicker from "react-datepicker";
import AssigneeTasksCountModal from "./assigneeTasksCountModal.jsx";
import PriorityTasksCountModal from "./priorityTasksCountModal.jsx";
import TasklistPresetsModal from "./tasklistPresetsModal.jsx";
import CreatableSelect from "react-select/creatable";
import {
  ColumnContextProvider,
  useColumnContext,
} from "./context/ColumnContext";
import Sidebar from "./Sidebar";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { API, Storage } from "aws-amplify";
import { BsCalendarCheck } from "react-icons/bs";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { IoIosOptions } from "react-icons/io";
import { FiArchive, FiArrowLeft } from "react-icons/fi";
import { TiTimes } from "react-icons/ti";
import { Tab } from "@headlessui/react";
import { DataTable } from "./Table";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import Button from "../../shared/Button";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import EmailPreviewModal from "./Table/email-preview-modal.jsx";
import PreviewTasks from "./Table/preview-tasks-modal.jsx";
import NoData from "../../assets/images/no-data.svg";
import BlankStateMobile from "../mobile-blank-state/index.jsx";
import html2canvas from "html2canvas";
import { useHistory } from "react-router-dom";

const restrictedUsers = new Set([
  "mmatest.khr+uat@gmail.com",
  "mmatest.khr+access@gmail.com",
  "meredith.ziegler@contractsspecialist.com.au",
]);

const TASK_LIST_DATA_QUERY = `
  query taskListData($companyId: ID!, $limit: Int, $nextToken: String) {
    taskListData(companyId: $companyId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        uniqueId
        clientMatterId
        gmailMessageId
        createdAt
        isDeleted
        updatedAt
        activity {
          field
          user {
            firstName
            lastName
            profilePictureKey
          }
        }
      nextToken
    }
  }
`;

const TASK_LIST_DATA_COUNT = `
  query taskListDataCount($companyId: ID!) {
    taskListDataCompletedCount(companyId: $companyId)
    taskListDataOutstandingCount(companyId: $companyId)
  }
`;

const TASK_LIST_DATA_SEARCH = `
  query taskListDataSearch($companyId: ID!, $query: String!) {
    taskListDataSearch(companyId: $companyId, query: $query) {
      id
      data
      uniqueId
      clientMatterId
      gmailMessageId
      createdAt
      isDeleted
      updatedAt
    }
    taskListDataCompletedCount(companyId: $companyId)
    taskListDataOutstandingCount(companyId: $companyId)
  }
`;

const TASK_LIST_DATA_CREATE = `
  mutation taskListDataCreate($companyId: ID!, $input: TaskListDataInput!) {
    taskListDataCreate(companyId: $companyId, input: $input) {
      data
      id
      createdAt
    }
  }
`;

const TASKLIST_DATA_COUNT = `
query getTaskListsCount($companyId: ID!) {
  taskListDataCount(companyId: $companyId) {
    status
    count
  }
}`;

const COMPANY_USERS_QUERY = `
query companyUsers($id: String!){
  company(id: $id){
    users {
      items {
        id
        firstName
        lastName
        profilePicture
        history
      }
    }
  }
}
`;

const COMPANY_NAME_QUERY = `
query companyName($companyId: String!){
  company(id: $companyId){
    name
  }
}
`;

/*
 * Got this query from the Gmail Integration page,
 * which has the latest version of this query
 * (MMA-2113)
 *
 */
const COMPANY_CLIENTMATTERS_QUERY = `
  query listClientMatters($id: String) {
    company(id: $id) {
      clientMatters (sortOrder: CREATED_DESC, isDeleted:false) {
        items {
          id
          createdAt
          client {
            id
            name
          }
          matter {
            id
            name
          }
          labels {
            items {
              id
              name
            }
          }
          briefs {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const qUserClientMatterAccess = `query getUserClientMatters($id: String, $companyId: String) {
  user(id: $id) {
    clientMatterAccess(companyId: $companyId) {
      items {
        id
        userType
        clientMatter {
          id
          client {
            id
            name
          }
          matter {
            id
            name
          }
        }
      }
    }
  }
}`;

const TASKLIST_DATA_QUERY = `
query getTaskLists(
  $companyId: ID!,
  $isDeleted: Boolean,
  $status: String,
  $dateSubmittedStart: String,
  $dateSubmittedEnd: String,
  $assignee: [String],
  $priority: Int,
  $limit: Int) {
  taskListData(
    companyId: $companyId
    assignee: $assignee
    dateSubmittedEnd: $dateSubmittedEnd
    dateSubmittedStart: $dateSubmittedStart
    isDeleted: $isDeleted
    status: $status
    priority: $priority
    limit: $limit
  ) {
    items {
      id
      data
      uniqueId
      clientMatterId
      gmailMessageId
      createdAt
      isDeleted
      updatedAt
      activity {
        field
        user {
          firstName
          lastName
          profilePictureKey
        }
      }
    }
  }
}
`;

const TASKLIST_PRESETS_QUERY = `query taskListPresets($companyId: ID) {
  taskListPresets(companyId: $companyId) {
    id
    columnIds
    label
    name
  }
}`;

const CREATE_TASKLIST_PRESET_MUTATION = `
  mutation createTasklistPresets($companyId: ID, $label: String, $columnIds: [Int]) {
    taskListPresetsCreate(
      companyId: $companyId
      label: $label
      columnIds: $columnIds
    ) {
      id
    }
  }`;

const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

const EMAIL_ATTTACHMENT = `
  query getFileAttachments($id: ID) {
    gmailMessage(id: $id) {
      attachments {
        items {
          id
          name
          details
          isDeleted
        }
      }
    }
  }
`;

const TaskList = () => {
  const [greeting, setGreeting] = useState("");
  const firstName = localStorage.getItem("firstName");
  const [open, setOpen] = useState(false);
  const [preset, setPreset] = useState("");
  const [presetsLibrary, setPresetsLibary] = useState([]);

  Storage.configure({
    region: config.aws_user_files_s3_bucket_region,
    bucket: config.aws_user_company_assets_s3_bucket,
    identityPoolId: config.aws_user_pools_id
  });

  // MMA-1303
  const [lastUpdatedPriorityValue, setLastUpdatedPriorityValue] = useState(3);
  const updatePriorityValue = async (newValue) => {
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        activity: `Updated Priority`,
        appModule: "TASKS",
        field: "Piority",
        previous: lastUpdatedPriorityValue,
        current: newValue,
        userId: localStorage.getItem("userId"),
      },
    };

    const addActivity = await API.graphql(params).then((result) => {
      console.log("addActivity result", result);
    });

    setLastUpdatedPriorityValue(newValue);
  };

  //mobile const
  const { height, width } = useWindowDimensions();
  const [contentHeight, setContentHeight] = useState();
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    //mobile
    if (width < 844) {
      var headerTag = document.getElementById("headerTag");
      setContentHeight(height - 40);
    }
  }, [height, width]);

  const contentDiv = {
    margin: "0 0 0 85px",
  };

  useEffect(() => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      setGreeting(`Good morning, ${firstName}`);
    } else if (curHr < 18) {
      setGreeting(`Good afternoon, ${firstName}`);
    } else {
      setGreeting(`Good evening, ${firstName}`);
    }

    // Add tracker for when page has been accessed.
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        activity: `page has been accessed.`,
        appModule: "TASKS",
        userId: localStorage.getItem("userId"),
      },
    };
    const addActivity = API.graphql(params).then((result) => {
      console.log("addActivity result", result);
    });
  }, []);

  return (
    <>
      {width > 844 ? (
        <>
          <ColumnContextProvider>
            <div className="flex h-screen" style={contentDiv}>
              <Sidebar
                open={open}
                setOpen={setOpen}
                preset={preset}
                setPreset={setPreset}
                presetsLibrary={presetsLibrary}
                setPresetsLibary={setPresetsLibary}
                // fetchDataWithFilter={fetchDataWithFilterBy("all")}
                // setFiltersModal={setFiltersModal(false)}
                // filterColumns={filterColumns()}
              />

              <main className="flex flex-col w-full h-full bg-white border-l">
                <div className="fixed flex flex-col w-full px-2 py-6 overflow-x-auto">
                  <button
                    style={{ zIndex: 9000 }}
                    className="fixed flex items-center justify-center w-6 h-6 -ml-5 bg-white border border-gray-200 rounded-full"
                    onClick={() => setOpen((b) => !b)}
                  >
                    {open ? (
                      <MdOutlineKeyboardArrowLeft />
                    ) : (
                      <MdOutlineKeyboardArrowRight />
                    )}
                  </button>
                </div>

                <Main
                  toggleSidebar={setOpen}
                  preset={preset}
                  setPreset={setPreset}
                  presetsLibrary={presetsLibrary}
                  setPresetsLibary={setPresetsLibary}
                  lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                  updatePriorityValue={updatePriorityValue}
                />
              </main>
            </div>
          </ColumnContextProvider>
        </>
      ) : (
        <>
          <ColumnContextProvider>
            <div
              className="h-screen p-1 py-5 -ml-1 overflow-y-hidden bg-white "
              // style={contentDiv}
            >
              {/* <Sidebar open={open} setOpen={setOpen} /> */}

              <main className="flex flex-col w-full h-full overflow-x-hidden overflow-y-hidden bg-white border-l ">
                <div className={"text-right"}>
                  <h1 id="headerTag" className="px-3 py-5 text-base font-bold">
                    TASKLIST DASHBOARD{" "}
                  </h1>
                </div>
                <div
                  className="flex flex-col h-full py-1 pt-2 pl-1 overflow-x-hidden font-sans rounded-lg test123"
                  style={{ height: width > 844 ? "auto" : contentHeight }}
                >
                  <Main
                    toggleSidebar={setOpen}
                    preset={preset}
                    setPreset={setPreset}
                    presetsLibrary={presetsLibrary}
                    setPresetsLibary={setPresetsLibary}
                    lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                    updatePriorityValue={updatePriorityValue}
                  />
                </div>
              </main>
            </div>
          </ColumnContextProvider>
        </>
      )}
    </>
  );
};

const Main = ({
  toggleSidebar,
  preset,
  setPreset,
  presetsLibrary,
  setPresetsLibary,
  lastUpdatedPriorityValue,
  updatePriorityValue,
}) => {
  const companyId = localStorage.getItem("companyId");

  const imageStates = useRef([]);

  const [allTasks, setaAllTasks] = useState([]);
  const [outstandingTasks, setOutstandingTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [archivedTasks, setArchivedTasks] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(1);

  const [columns] = useColumnContext();
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [completedCount, setCompletedCount] = useState(0);
  const [outstandingCount, setOutstandingCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [priorityFilter, setPriorityFilter] = useState("");
  const [filtersModal, setFiltersModal] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [DatePickerActive, setDatePickerActive] = useState(false);
  const [assignee, setAssignee] = useState({
    id: localStorage.getItem("userId"),
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    history: JSON.parse(localStorage.getItem("history")),
  });
  const [taskRegisterFilter, setTaskRegisterFilter] = useState("");

  //const [assignee, setAssignee] = useState("Adrian Barrister", "Adrian Client");
  const inputRef = useRef(null);

  const [startDateReceived, setStartDateReceived] = useState(null);
  const [endDateReceived, setEndDateReceived] = useState(null);
  const [status, setStatus] = useState("Outstanding");
  const [disabledStatus, setDisabledStatus] = useState(false);
  const [showAssigneeSummaryModal, setShowAssigneeSummaryModal] =
    useState(false);
  const [showPrioritySummaryModal, setShowPrioritySummaryModal] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [showTasklistPresetsModal, setShowTasklistPresetsModal] =
    useState(false);
  const [showEmailPreview, setshowEmailPreview] = useState(false);
  const [showPreviewTasks, setShowPreviewTasks] = useState(false);

  const [emailPreviewState, setemailPreviewState] = useState({
    preview: [],
    from: null,
    dateEmail: null,
    subject: null,
    to: null,
    cc: null,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [clearFilter, setClearFilter] = useState(false);

  const handleAssigneeSummaryClose = () => {
    setShowAssigneeSummaryModal(false);
  };

  const handlePrioritySummaryClose = () => {
    setShowPrioritySummaryModal(false);
  };

  const handleTasklistPresetsClose = () => {
    setShowTasklistPresetsModal(false);
  };

  const handleClearInput = () => {
    setQuery("");
    setSearchQuery("");
    fetchDataWithFilterBy("filters");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleTasklistPresetsChanges = (obj) => {
    // setPreset("");
    setPresetsLibary(obj);
  };

  let history = useHistory();

  /**
   * Refs for new columns green color
   */
  const colHeaderRefs = useRef([]);
  const colContentRefs = useRef([]);

  colHeaderRefs.current = columns.map(
    (_, i) => colHeaderRefs.current[i] ?? createRef()
  );
  colContentRefs.current = columns.map(
    (_, i) => colContentRefs.current[i] ?? createRef()
  );

  useEffect(() => {
    console.log("COLUMNS VALUE IS:", columns);
    setFilteredColumns(columns);
    filterColumns();
  }, [columns]);

  // primitive store
  const [people, setPeople] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [clientMatters, setClientMatters] = useState([]);
  const [filterClientMattersOptions, setFilterClientMattersOptions] = useState(
    []
  );
  const [filterClientMatters, setFilterClientMatters] = useState([]);
  const [searchHasAttachments, setSearchHasAttachments] = useState(false);
  const [teamAssignee, setTeamAssignee] = useState("");

  //MMA-2352 Added checkbox column
  const [selectedRows, setSelectedRows] = useState([]);
  const [isConverting, setIsConverting] = useState(false);
  const [selectedDivs, setSelectedDivs] = useState([]);
  const [tableHeader, setTableHeader] = useState();
  const [screenshot, setScreenshot] = useState([]);

  /** MOBILE CONST */
  const { height, width } = useWindowDimensions();

  const datePickerRef = useRef();

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const totalDBRowCount = completedCount + outstandingCount ?? 0;
  const totalFetched = allTasks.length;

  // Date Received Filter
  const onChangeDateReceived = (dates) => {
    const [start, end] = dates;
    setStartDateReceived(start);
    setEndDateReceived(end);
  };

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      console.log("scrolled");
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchTaskListData({});
        }
      }
    },
    [fetchTaskListData, isFetching, totalFetched, totalDBRowCount]
  );

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchDataWithFilterBy(""),
        fetchCompanyTeams(),
        fetchCompanyUsers(),
        fetchCompanyClientMatters(),
        getPresetsLibrary(),
      ]);
    };

    fetchData()
      .then(() => {
        // Find the preset in presetsLibrary with label "Task List"
        const taskListFilterAttr = presetsLibrary.find(
          (attr) => attr.label === "Task List"
        );

        // Retrieve the id of the taskListFilterAttr if it exists, otherwise set it to null
        const taskListFilterAttrId = taskListFilterAttr
          ? taskListFilterAttr.id
          : null;

        // If the taskListFilterAttrId exists, set the preset to that id
        if (taskListFilterAttrId) {
          setPreset(taskListFilterAttrId);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    document.body.style.cursor = isConverting ? "wait" : "default";
  }, [isConverting])

  useEffect(() => {
    //fetchTaskDataCount();
    console.log("allTasks", allTasks);
  }, [allTasks]);

  useEffect(() => {
    console.log("ASSIGNEE IS:", assignee);
  }, [assignee]);

  useEffect(() => {
    if (teamAssignee !== "") {
      //console.log("Team Assignee ID is: " + teamAssignee)
      setAssignee(null);
      fetchCompanyTeamUsers();
    } else {
      setTeamMembers([]);
    }
  }, [teamAssignee]);

  // useEffect(() => {
  // 	searchTasks(query);
  // }, [query]);

  useEffect(() => {
    console.log("TRIGGERING CLEAR FILTER");
    // Trigger filter on clear button click
    if (clearFilter) {
      console.log("clearFilter", clearFilter);
      setAssignee(null);
      setPriorityFilter("");
      setStartDateReceived(null);
      setEndDateReceived(null);
      setStartDate(null);
      setEndDate(null);
      setStatus("");
      setTeamAssignee("");
      setFiltersModal(false);
      setTaskRegisterFilter("");
      setLoading(true);
      setFilteredColumns(columns);
      setFilterClientMatters(null);
      // setTimeout(() => {
      // 	fetchDataWithFilterBy("");
      // 	setClearFilter(false);
      // 	setFiltersModal(false);
      // });
    }
  }, [clearFilter]);

  function filterColumns() {
    if (taskRegisterFilter == "") {
      setFilteredColumns(columns);
    } else {
      let newCol = columns.filter((item) => {
        if (item.presets) {
          for (const preset of item.presets) {
            if (preset.id == taskRegisterFilter) {
              return true;
            }
          }
        }
        return false;
      });
      setFilteredColumns(newCol);
    }
  }

  useEffect(() => {
    console.log("TRIGGERING FILTER MODAL");
    if (!filtersModal && clearFilter) {
      fetchDataWithFilterBy("");
      setClearFilter(false);
    }
  }, [filtersModal]);

  // useEffect(() => {
  // 	if(query == "" && searchQuery == ""){
  // 		fetchDataWithFilterBy("");
  // 	}
  // }, [activeTabIndex]);

  // function for generating uniqueID with prefix of company (first 3 letters of company) and prefix of client/matter (first 3 letters of client and matter)
  // returns new unique ID with prefix (ex: LOP-CLI/MAT-4)
  function generateUniqueID(companyName, clientMatter, nextCount) {
    let companyPrefix = companyName
      .replaceAll(" ", "")
      .substring(0, 3)
      .toUpperCase();
    let clientMatterSections = clientMatter
      .replaceAll(" ", "")
      .toUpperCase()
      .split("/");
    let clientPrefix = clientMatterSections[0].substring(0, 3);
    let matterPrefix = clientMatterSections[1].substring(0, 3);

    let newUniqueID = "";
    if (nextCount) {
      newUniqueID =
        companyPrefix +
        "-" +
        clientPrefix +
        "/" +
        matterPrefix +
        "-" +
        nextCount.toString();
    } else {
      newUniqueID =
        companyPrefix + "-" + clientPrefix + "/" + matterPrefix + "-0";
    }
    console.log("new unique id:", newUniqueID);
    return newUniqueID;
  }

  // useEffect(()=>{
  // 	console.log("FILTER VALUES - Client/matter:", filterClientMatters)
  // 	console.log("FILTER VALUES - Date submitted:", datePickerRef.current)
  // 	console.log("FILTER VALUES - Priority:", priorityFilter)
  // 	console.log("FILTER VALUES - Teams:", teamMembers)
  // 	console.log("FILTER VALUES - assignee:", assignee)
  // 	console.log("FILTER VALUES - Status:", status)
  // 	console.log("FILTER VALUES - task register filter:", taskRegisterFilter)
  // 	// if(teamMembers.length > 0){
  // 	// 	let teamString = "";
  // 	// 	teamMembers.map((user, index)=>{
  // 	// 		if(teamMembers.length == 1){
  // 	// 			teamString = user;
  // 	// 		}
  // 	// 		else if(index == teamMembers.length - 1){
  // 	// 			teamString = teamString.concat(user);
  // 	// 		}
  // 	// 		else{
  // 	// 			teamString = teamString.concat(user + ",");
  // 	// 		}
  // 	// 	})
  // 	// }
  // })

  function getTeamString() {
    if (teamMembers.length > 0) {
      let teamString = "";
      teamMembers.map((user, index) => {
        if (teamMembers.length == 1) {
          teamString = user;
        } else if (index == teamMembers.length - 1) {
          teamString = teamString.concat(user);
        } else {
          teamString = teamString.concat(user + ",");
        }
      });
      return teamString;
    }
    return "";
  }

  function getColumnView() {
    if (taskRegisterFilter.length > 0) {
      let temp = [];
      presetsLibrary.map((preset) => {
        if (preset.id == taskRegisterFilter) {
          temp.push({ value: preset.id, label: preset.label });
        }
      });
      return temp;
    }
    return [];
  }

  async function createTask(e) {
    e.preventDefault();
    
    let currentUser = {
      id: localStorage.getItem("userId"),
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
      history: JSON.parse(localStorage.getItem("history")),
    };

    const taskQuery = await API.graphql({
      query: TASKLIST_DATA_QUERY,
      variables: {
        companyId,
        isDeleted: false,
        assignee: getAssigneeFilter(currentUser),
      },
    });

    const nextUniqueId =
      taskQuery.data.taskListData.items.length !== 0
        ? parseInt(taskQuery.data.taskListData.items[0].uniqueId) + 1
        : 1;

    /*
			registers: []  - Task Registers array
			data is:
			0 - client/matter
			1 - email subject
			2 - sender email
			3 - date received
			4 - Preview Email
			5 - Attachments
			6 - description
			7 - status
			8 - priority
			9 - Assignees
			10 - Date Submitted
			11 - Due Date
			12 - Date Completed
			13 -
			*/

    let createdBy = columns.find((col) => col.header === "Task Created By");


    //KJMFEDITREWORK
    const createTaskResult = await API.graphql({
      query: TASK_LIST_DATA_CREATE,
      variables: {
        companyId,
        input: {
          isDeleted: false,
          priority: priorityFilter ? priorityFilter : 1,
          uniqueId: nextUniqueId,
          gmailMessageId: "0",
          clientMatterId: filterClientMatters ? filterClientMatters.value : "",
          dueDate: new Date(),
          data: JSON.stringify({
            0: filterClientMatters ? filterClientMatters.label : "",
            7: "Outstanding",
            8: priorityFilter ? priorityFilter : 1,
            9: teamMembers.length > 0 ? getTeamString() : currentUser.id,
            10: new Date(),
            11: new Date(),
            registers: getColumnView(),
            [createdBy.accessorKey]: currentUser.id,
          }),
        },
      },
    }).then(async (createTaskResult)=>{
      //Insert data in frontend to avoid calling all
      var insertDate = createTaskResult.data.taskListDataCreate.createdAt;
      var insertId = createTaskResult.data.taskListDataCreate.id;

      var newEntry = {
        id: insertId,
        createdAt: insertDate,
        updatedAt: insertDate,
        activity: [],
        isDeleted: false,
        priority: priorityFilter ? priorityFilter : 1,
        uniqueId: nextUniqueId.toString(),
        gmailMessageId: "0",
        clientMatterId: filterClientMatters ? filterClientMatters.value : "",
          7: "Outstanding",
          8: priorityFilter ? priorityFilter : 1,
          9: teamMembers.length > 0 ? getTeamString() : currentUser.id,
          10: insertDate,
          11: insertDate,
          registers: getColumnView(),
          [createdBy.accessorKey]: currentUser.id,
      };

      //update all tasks
      var allTasksCopy = allTasks;
      allTasksCopy = [newEntry, ...allTasksCopy]
      setaAllTasks(allTasksCopy)
      setAllCount(allTasksCopy?.length);
      //update outstanding tasks
      var outstandingTasksCopy = outstandingTasks;
      outstandingTasksCopy = [newEntry, ...outstandingTasksCopy]
      setOutstandingTasks(outstandingTasksCopy);
      setOutstandingCount(outstandingTasksCopy?.length);

      //then add activity in background
      const userId = localStorage.getItem("userId");
      const activityParams = {
        query: mCreateActivity,
        variables: {
          companyId: companyId,
          briefId: null,
          rowId: createTaskResult.data.taskListDataCreate.id,
          activity: `Created a task`,
          field: "Create a task",
          current: null,
          previous: null,
          appModule: "TASKS",
          userId,
        },
      };
      const addActivity = await API.graphql(activityParams).then((result) => {
        console.log("addActivity result", createTaskResult, "CREATE TASK");
      });
    });

    
  }

  async function searchTasks() {
    const { data } = await API.graphql({
      query: TASK_LIST_DATA_SEARCH,
      variables: {
        companyId,
        query: "*" + query + "*",
      },
    });

    const tasks = data?.taskListDataSearch
      ?.filter((a) => {
        return a.isDeleted === false;
      })
      ?.map(({ data, ...rest }) => ({
        ...rest,
        ...JSON.parse(data),
      }));
    console.log("Searched Data: ", data);

    setaAllTasks(tasks);
    setAllCount(tasks.length);

    const resultsOutstandingTasks = searchArray(tasks, "Outstanding");
    setOutstandingTasks(resultsOutstandingTasks);
    setOutstandingCount(resultsOutstandingTasks.length);

    const resultsCompletedTasks = searchArray(tasks, "Completed");
    setCompletedTasks(resultsCompletedTasks);
    setCompletedCount(resultsCompletedTasks.length);

    const resultsArchivedTasks = data?.taskListDataSearch
      ?.filter((a) => {
        return a.isDeleted === true;
      })
      ?.map(({ data, ...rest }) => ({
        ...rest,
        ...JSON.parse(data),
      }));
    setArchivedTasks(resultsArchivedTasks);
    setArchivedCount(resultsArchivedTasks.length);

    setSearchResults(tasks);
  }

  async function fetchTaskDataCount(_esQuery) {
    await delay(600);
    const { data } = await API.graphql({
      query: TASK_LIST_DATA_COUNT,
      variables: {
        companyId,
      },
    });

    console.log("count", data);

    setOutstandingCount(data.taskListDataOutstandingCount);
    setCompletedCount(data.taskListDataCompletedCount);
  }

  async function searchTasksQuery(_esQuery) {
    const { data } = await API.graphql({
      query: TASK_LIST_DATA_SEARCH,
      variables: {
        companyId,
        query: _esQuery,
      },
    }).then();

    return data?.taskListDataSearch?.map(({ data, ...rest }, i) => ({
      no: i + 1,
      ...rest,
      ...JSON.parse(data),
    }));
  }

  function updateDuplicateIDs(data) {
    // WIP
    /**
    console.group("updateDuplicateIDs");
    console.log("input", data);

    const uniqueIdCounts = {};
    let maxUniqueId = 0; // Track the maximum uniqueId

    // First, gather duplicates and update uniqueId if null
    const duplicates = data.filter((item) => {
      item.uniqueId = item.uniqueId || "0";

      if (uniqueIdCounts[item.uniqueId]) {
        uniqueIdCounts[item.uniqueId]++;
        return true; // Duplicate found
      } else {
        uniqueIdCounts[item.uniqueId] = 1;

        // Update maxUniqueId
        if (parseInt(item.uniqueId) > maxUniqueId) {
          maxUniqueId = parseInt(item.uniqueId);
        }

        return false; // No duplicate
      }
    });

    duplicates.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    console.log("duplicates", duplicates, "\n\n");

    const uniqueIdChanges = {};

    // Process duplicates and calculate updated uniqueId values
    duplicates.forEach((item, index) => {
      if (uniqueIdCounts[item.uniqueId] > 1) {
        // This is a duplicate with a duplicate uniqueId
        maxUniqueId += 1; // Increment the maximum uniqueId
        const newUniqueId = maxUniqueId.toString();
        uniqueIdChanges[item.uniqueId] = newUniqueId;

        console.log("maxUniqueId", maxUniqueId);
        console.log("Old unique id", uniqueIdChanges[item.uniqueId]);
        console.log("New unique id", newUniqueId);
      }
    });

    // Update the original data array
    data.forEach((item) => {
      if (uniqueIdChanges[item.uniqueId]) {
        item.uniqueId = uniqueIdChanges[item.uniqueId];
      }
    });

    console.log("updatedData", data);
    console.log("--- --- ---");
    console.groupEnd();
	*/
    return data;
  }

  // kjmfedit2
  async function fetchTaskListData({ resetData = false }) {
    setIsFetching(true);
    const { data } = await API.graphql({
      query: TASK_LIST_DATA_QUERY,
      variables: {
        companyId,
        isDeleted: false,
        nextToken: resetData ? null : nextToken,
      },
    });

    console.log("fetchTaskListData data", data);
    setStatus("");
    const dataTasks = data?.taskListData.items;
    const tasks = await appendCreatedBy(
      updateDuplicateIDs(dataTasks).map(({ data, ...rest }, i) => ({
        no: i + 1,
        ...rest,
        ...JSON.parse(data),
      }))
    );

    if (resetData) {
      setaAllTasks(tasks);
      if (activeTabIndex === 0) {
        setLoading(false);
      }
    } else {
      setaAllTasks((old) => [...old, ...tasks]);
      if (activeTabIndex === 0) {
        setLoading(false);
      }
    }
    setNextToken(data.taskListData.nextToken);
    setIsFetching(false);
  }

  function findObjectByName(arr, name) {
    return arr.find((obj) => obj.name === name);
  }

  async function fetchOutsandingTasks() {
    const data = await searchTasksQuery(
      "data.7:Outstanding AND isDeleted:false"
    );
    setOutstandingCount(data?.length);
    setOutstandingTasks(data);
    if (activeTabIndex === 1) {
      setLoading(false);
    }
  }

  async function fetchCompletedTasks() {
    const data = await searchTasksQuery("data.7:Completed AND isDeleted:false");
    setCompletedCount(data?.length);
    setCompletedTasks(data);
    if (activeTabIndex === 2) {
      setLoading(false);
    }
  }

  async function fetchArchivedTasks() {
    const data = await searchTasksQuery("isDeleted:true");
    setArchivedCount(data?.length);
    setArchivedTasks(data);
    if (activeTabIndex === 3) {
      setLoading(false);
    }
  }

  //To filter data by Assignees in the Team
  function filterDataByTeam(tasklist, team) {
    var tempData = [];
    var membersName = "";
    var taskAssignee = [];
    var sortedArray = [];
    var tasksArray = tasklist;
    var saveArray = [];

    //if looking for team members individually
    /* if (team !== ""){
      for(var i = 0; i < teamMembers.length; i++){
        membersName = teamMembers[i].user.firstName + ' ' + teamMembers[i].user.lastName;
        taskAssignee = [];
      //  console.log("Member is "+ memberName);
        for(var j = 0; j < tasksArray.length; j++){
          //console.log("Stored Member: " + memberName + ", Assigned Member: " + tasklist[j]["9"])
          taskAssignee = tasksArray[j]["9"].split(",")

        if (taskAssignee.includes(membersName)){
            tempData.push(tasksArray[j])
       //     console.log("Getting task: " + tasklist[j][0] + " of " + tasklist[j]["9"])
        }
          else {
          //  To avoid duplicates for multiple members
            saveArray.push(tasksArray[j]);
          }
        }
      tasksArray = saveArray;
      saveArray = [];
      }
     return tempData;
    }
    */

    //if specifically looks for the whole team
    if (team !== "") {
      //  console.log("Member is "+ memberName);
      for (var j = 0; j < tasksArray.length; j++) {
        if (!tasksArray[j]["9"]) {
          //		console.log("EMPTY:", tasksArray[j]["9"]);
        } else {
          taskAssignee = tasksArray[j]["9"].split(","); //turn assignee string to array
          sortedArray = taskAssignee.slice().sort();
          // console.log(
          // 	"SORTED ARRAY LENGTH:",
          // 	sortedArray.length,
          // 	" -- ",
          // 	teamMembers.length
          // );
          if (sortedArray.length === teamMembers.length) {
            console.log("ENTERED");
            let checkCounter = 0;
            for (let i = 0; i < sortedArray.length; i++) {
              //check if team size are the same
              if (sortedArray[i] === teamMembers[i]) {
                checkCounter++;
                if (checkCounter == sortedArray.length) {
                  //		console.log("SELECCTED TEAM");
                  tempData.push(tasksArray[j]);
                }
                //checks if the team members are the same
              } else {
                //			console.log("NOT SELECTED TEAM FOUND");
                saveArray.push(tasksArray[j]);
              }
            }
          } else {
            //  To avoid duplicates for multiple members
            saveArray.push(tasksArray[j]);
          }
        }
      }
      tasksArray = saveArray;
      saveArray = [];

      return tempData;
    } else {
      // for(var k= 0; k < tempData.length; k++)
      console.log("returning original ");
      // returns original task list;
      return tasklist;
    }
  }

  function getAssigneeFilter(assignee) {
    let assigneeConditions = [];

    if (assignee === "Unassigned") {
      assigneeConditions.push(assignee);
      return assigneeConditions;
    }
    
    assigneeConditions.push(assignee.id);
    assigneeConditions.push(`${assignee.firstName + " " + assignee.lastName}`);
    
    let assigneeHistory = assignee.history;

    if (assigneeHistory && assigneeHistory?.length > 0) {
      assigneeHistory.map((pastUser) => {
        assigneeConditions.push(`${pastUser.firstName + " " + pastUser.lastName}`);
      });
    }
    return assigneeConditions;
  }

  async function fetchDataWithFilterBy(action) {
    console.group("fetchDataWithFilterBy()")

    let vars = { companyId: companyId, isDeleted: false };
    // date submitted
    vars.dateSubmittedEnd = "";
    vars.dateSubmittedStart = "";
    if (startDate !== null && endDate !== null) {
      vars.dateSubmittedStart = incr_date(
        startDate.toISOString().split("T")[0]
      );
      vars.dateSubmittedEnd = incr_date(endDate.toISOString().split("T")[0]);
    }

    if (priorityFilter != "") {
      vars.priority = priorityFilter;
    }

    if (assignee && assignee !== "") {
      vars.assignee = getAssigneeFilter(assignee);
    }

    console.log("All data vars", vars)

    const { data } = await API.graphql({
      query: TASKLIST_DATA_QUERY,
      variables: vars,
    }).catch((e) => {
      console.error(e.errors[0].message);
    });

    console.log("All data", data)
    const dataTasks = data?.taskListData.items;

    const tasks = await appendCreatedBy(
      updateDuplicateIDs(dataTasks).map(({ data, ...rest }, i) => ({
        no: i + 1,
        ...rest,
        ...JSON.parse(data),
      }))
    );

    // for(var i = 0; i < tasks.length; i++){
    //   console.log("Tasks are: " + JSON.stringify(tasks[i]));
    // }

    var filteredAllTasks = filterDataByTeam(tasks, teamAssignee);

    console.log("ALL TASKS ARE:", tasks);
    //console.log("Filtered Tasks " + JSON.stringify(filteredAllTasks));

    // Client Matter Filter

    if (filterClientMatters !== null && filterClientMatters.length !== 0) {
      filteredAllTasks = filteredAllTasks.filter((obj) => {
        if (filterClientMatters.value === null) {
          // for (const key in obj) {
          //   console.log("SSS", key);
          //   if (obj.hasOwnProperty(key) && key === 0) {
          //     return false; // Exclude objects with property key 0
          //   }
          // }
          console.log("ASkia, ", obj[0]);
          if (obj.hasOwnProperty(0) && obj[0] !== "No selected Client/Matter") {
            return false;
          }
          return true; // Include objects with no property key 0
        } else {
          const propertyValue = obj[0];
          if (
            propertyValue &&
            filterClientMatters.label &&
            propertyValue
              .toLowerCase()
              .includes(filterClientMatters.label.toLowerCase())
          ) {
            return true; // Include objects with the search term word in the property at key 0
          }
          return false; // Exclude other objects
        }
      });
    }

    // Has Attachments Filter All Tasks
    if (searchHasAttachments === true) {
      checkAttachmentsForEmailObjects(filteredAllTasks)
        .then((results) => {
          console.log("Attachments results:", results);
          // For example, you might want to filter tasks based on attachments
          const tasksWithAttachments = filteredAllTasks.filter(
            (task, index) => results[index].hasAttachment
          );

          filteredAllTasks = filterDataByTeam(
            tasksWithAttachments,
            teamAssignee
          );
          console.log("Search After Attachments Filtering", filteredAllTasks); // Log the tasks after attachments filtering

          if (startDateReceived !== null && endDateReceived !== null) {
            // ... (existing date filtering code)
          } else {
            //setaAllTasks(tasksWithAttachments);
            setaAllTasks(filteredAllTasks);
            if (activeTabIndex === 0) {
              setLoading(false);
            }
            //setAllCount(tasksWithAttachments.length);
            setAllCount(filteredAllTasks.length);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("Search After No Attachments Filtering", filteredAllTasks); // Log the tasks without attachment filtering

      filteredAllTasks = filterDataByTeam(filteredAllTasks, teamAssignee);

      if (startDateReceived !== null && endDateReceived !== null) {
        // ... (existing date filtering code)
      } else {
        setaAllTasks(filteredAllTasks);
        if (activeTabIndex === 0) {
          setLoading(false);
        }
        setAllCount(filteredAllTasks.length);
      }
    }

    if (startDateReceived !== null && endDateReceived !== null) {
      let startDateReceivedFilter = new Date(
        incr_date(startDateReceived.toISOString().split("T")[0])
      );
      let endDateReceivedFilter = new Date(
        incr_date(endDateReceived.toISOString().split("T")[0])
      );

      setaAllTasks(
        filteredAllTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        })
      );
      if (activeTabIndex === 0) {
        setLoading(false);
      }
      setAllCount(
        filteredAllTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        }).length
      );
    } else {
      setaAllTasks(filteredAllTasks);
      if (activeTabIndex === 0) {
        setLoading(false);
      }
      setAllCount(filteredAllTasks.length);
    }

    if (taskRegisterFilter !== "") {
      setaAllTasks(
        filteredAllTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        })
      );
      setAllCount(
        filteredAllTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        }).length
      );
      if (activeTabIndex === 0) {
        setLoading(false);
      }
    }

    vars.status = "Outstanding";

    // Set Outstanding Tasks
    const dataOutstanding = await API.graphql({
      query: TASKLIST_DATA_QUERY,
      variables: vars,
    }).catch((e) => {
      console.error(e.errors[0].message);
    });

    console.log("Outstanding data", dataOutstanding)

    const dataOutstandingTasks = dataOutstanding?.data?.taskListData?.items;
    const tasksOutstanding = await appendCreatedBy(
      updateDuplicateIDs(dataOutstandingTasks).map(
        ({ data, ...rest }, i) => ({
          no: i + 1,
          ...rest,
          ...JSON.parse(data),
        })
      )
    );

    // Client Matter Filter Outstanding
    var filteredOutstandingTasks = filterDataByTeam(
      tasksOutstanding,
      teamAssignee
    );

    if (filterClientMatters !== null && filterClientMatters.length !== 0) {
      filteredOutstandingTasks = filteredOutstandingTasks.filter((obj) => {
        if (filterClientMatters.value === null) {
          // for (const key in obj) {
          //   if (obj.hasOwnProperty(key) && key === "0") {
          //     return false; // Exclude objects with property key 0
          //   }
          // }
          if (obj.hasOwnProperty(0) && obj[0] !== "No selected Client/Matter") {
            return false;
          }
          return true; // Include objects with no property key 0
        } else {
          const propertyValue = obj[0];
          if (
            propertyValue &&
            filterClientMatters.label &&
            propertyValue
              .toLowerCase()
              .includes(filterClientMatters.label.toLowerCase())
          ) {
            return true; // Include objects with the search term word in the property at key 0
          }
          return false; // Exclude other objects
        }
      });
    }

    // Has Attachments Filter Outstanding Tasks
    if (searchHasAttachments === true) {
      checkAttachmentsForEmailObjects(filteredOutstandingTasks)
        .then((results) => {
          console.log("Attachments results:", results);
          // For example, you might want to filter tasks based on attachments
          const tasksWithAttachments = filteredOutstandingTasks.filter(
            (task, index) => results[index].hasAttachment
          );

          filteredOutstandingTasks = tasksWithAttachments;
          console.log(
            "Search After Attachments Filtering",
            filteredOutstandingTasks
          ); // Log the tasks after attachments filtering

          if (startDateReceived !== null && endDateReceived !== null) {
            // ... (existing date filtering code)
          } else {
            setOutstandingTasks(tasksWithAttachments);
            if (activeTabIndex === 0) {
              setLoading(false);
            }
            setOutstandingCount(tasksWithAttachments.length);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log(
        "Search After No Attachments Filtering",
        filteredOutstandingTasks
      ); // Log the tasks without attachment filtering

      if (startDateReceived !== null && endDateReceived !== null) {
        // ... (existing date filtering code)
      } else {
        setOutstandingTasks(filteredOutstandingTasks);
        if (activeTabIndex === 0) {
          setLoading(false);
        }
        setOutstandingCount(filteredOutstandingTasks.length);
      }
    }

    if (startDateReceived !== null && endDateReceived !== null) {
      let startDateReceivedFilter = new Date(
        incr_date(startDateReceived.toISOString().split("T")[0])
      );
      let endDateReceivedFilter = new Date(
        incr_date(endDateReceived.toISOString().split("T")[0])
      );

      setOutstandingTasks(
        filteredOutstandingTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        })
      );
      if (activeTabIndex === 1) {
        setLoading(false);
      }

      setOutstandingCount(
        filteredOutstandingTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        }).length
      );
    } else {
      setOutstandingTasks(filteredOutstandingTasks);
      if (activeTabIndex === 1) {
        setLoading(false);
      }
      setOutstandingCount(filteredOutstandingTasks.length);
    }

    if (taskRegisterFilter !== "") {
      setOutstandingTasks(
        filteredOutstandingTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        })
      );

      setOutstandingCount(
        filteredOutstandingTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        }).length
      );
      if (activeTabIndex === 1) {
        setLoading(false);
      }
    }

    vars.status = "Completed";

    // Set Completed Tasks
    const dataCompleted = await API.graphql({
      query: TASKLIST_DATA_QUERY,
      variables: vars,
    }).catch((e) => {
      console.error(e.errors[0].message);
    });

    console.log("Completed data", dataCompleted);

    const dataCompletedTasks = dataCompleted?.data?.taskListData?.items;
    const tasksCompleted = await appendCreatedBy(
      updateDuplicateIDs(dataCompletedTasks).map(
        ({ data, ...rest }, i) => ({
          no: i + 1,
          ...rest,
          ...JSON.parse(data),
        })
      )
    );

    // Client Matter Filter Completed
    var filteredCompletedTasks = filterDataByTeam(tasksCompleted, teamAssignee);

    if (filterClientMatters !== null && filterClientMatters.length !== 0) {
      filteredCompletedTasks = filteredCompletedTasks.filter((obj) => {
        if (filterClientMatters.label === "No selected Client Matters") {
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && key === "0") {
              return false; // Exclude objects with property key 0
            }
          }
          return true; // Include objects with no property key 0
        } else {
          const propertyValue = obj[0];
          if (
            propertyValue &&
            filterClientMatters.label &&
            propertyValue
              .toLowerCase()
              .includes(filterClientMatters.label.toLowerCase())
          ) {
            return true; // Include objects with the search term word in the property at key 0
          }
          return false; // Exclude other objects
        }
      });
    }

    // Has Attachments Filter Completed Tasks
    if (searchHasAttachments === true) {
      checkAttachmentsForEmailObjects(filteredCompletedTasks)
        .then((results) => {
          console.log("Attachments results:", results);
          // For example, you might want to filter tasks based on attachments
          const tasksWithAttachments = filteredCompletedTasks.filter(
            (task, index) => results[index].hasAttachment
          );

          filteredCompletedTasks = filterDataByTeam(
            tasksWithAttachments,
            teamAssignee
          );
          console.log(
            "Search After Attachments Filtering",
            filteredCompletedTasks
          ); // Log the tasks after attachments filtering

          if (startDateReceived !== null && endDateReceived !== null) {
            // ... (existing date filtering code)
          } else {
            setCompletedTasks(tasksWithAttachments);
            if (activeTabIndex === 0) {
              setLoading(false);
            }
            setCompletedCount(tasksWithAttachments.length);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log(
        "Search After No Attachments Filtering",
        filteredCompletedTasks
      ); // Log the tasks without attachment filtering

      if (startDateReceived !== null && endDateReceived !== null) {
        // ... (existing date filtering code)
      } else {
        setCompletedTasks(filteredCompletedTasks);
        if (activeTabIndex === 0) {
          setLoading(false);
        }
        setCompletedCount(filteredCompletedTasks.length);
      }
    }

    if (startDateReceived !== null && endDateReceived !== null) {
      let startDateReceivedFilter = new Date(
        incr_date(startDateReceived.toISOString().split("T")[0])
      );
      let endDateReceivedFilter = new Date(
        incr_date(endDateReceived.toISOString().split("T")[0])
      );

      setCompletedTasks(
        filteredCompletedTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        })
      );
      if (activeTabIndex === 2) {
        setLoading(false);
      }
      setCompletedCount(
        filteredCompletedTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        }).length
      );
    } else {
      setCompletedTasks(filteredCompletedTasks);
      if (activeTabIndex === 2) {
        setLoading(false);
      }
      setCompletedCount(filteredCompletedTasks.length);
    }

    if (taskRegisterFilter !== "") {
      setCompletedTasks(
        filteredCompletedTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        })
      );
      setCompletedCount(
        filteredCompletedTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        }).length
      );
      if (activeTabIndex === 2) {
        setLoading(false);
      }
    }

    vars.status = "";
    vars.isDeleted = true;

    const dataArchived = await API.graphql({
      query: TASKLIST_DATA_QUERY,
      variables: vars,
    }).catch((e) => {
      console.error(e.errors[0].message);
    });

    const dataArchivedTasks = dataArchived?.data?.taskListData?.items;
    const tasksArchived = await appendCreatedBy(
      updateDuplicateIDs(dataArchivedTasks).map(
        ({ data, ...rest }, i) => ({
          no: i + 1,
          ...rest,
          ...JSON.parse(data),
        })
      )
    );

    // Client Matter Filter Completed
    var filteredArchivedTasks = filterDataByTeam(tasksArchived, teamAssignee);

    if (filterClientMatters !== null && filterClientMatters.length !== 0) {
      filteredArchivedTasks = filteredArchivedTasks.filter((obj) => {
        if (filterClientMatters.label === "No selected Client Matters") {
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && key === "0") {
              return false; // Exclude objects with property key 0
            }
          }
          return true; // Include objects with no property key 0
        } else {
          const propertyValue = obj[0];
          if (
            propertyValue &&
            filterClientMatters.label &&
            propertyValue
              .toLowerCase()
              .includes(filterClientMatters.label.toLowerCase())
          ) {
            return true; // Include objects with the search term word in the property at key 0
          }
          return false; // Exclude other objects
        }
      });
    }

    // Has Attachments Filter Completed Tasks
    if (searchHasAttachments === true) {
      checkAttachmentsForEmailObjects(filteredArchivedTasks)
        .then((results) => {
          console.log("Attachments results:", results);
          // For example, you might want to filter tasks based on attachments
          const tasksWithAttachments = filteredArchivedTasks.filter(
            (task, index) => results[index].hasAttachment
          );

          filteredArchivedTasks = filterDataByTeam(
            tasksWithAttachments,
            teamAssignee
          );
          console.log(
            "Search After Attachments Filtering",
            filteredArchivedTasks
          ); // Log the tasks after attachments filtering

          if (startDateReceived !== null && endDateReceived !== null) {
            // ... (existing date filtering code)
          } else {
            setArchivedTasks(filteredArchivedTasks);
            if (activeTabIndex === 0) {
              setLoading(false);
            }
            setArchivedCount(filteredArchivedTasks.length);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log(
        "Search After No Attachments Filtering",
        filteredArchivedTasks
      ); // Log the tasks without attachment filtering

      if (startDateReceived !== null && endDateReceived !== null) {
        // ... (existing date filtering code)
      } else {
        setArchivedTasks(filteredArchivedTasks);
        if (activeTabIndex === 0) {
          setLoading(false);
        }
        setArchivedCount(filteredArchivedTasks.length);
      }
    }

    if (startDateReceived !== null && endDateReceived !== null) {
      let startDateReceivedFilter = new Date(
        incr_date(startDateReceived.toISOString().split("T")[0])
      );
      let endDateReceivedFilter = new Date(
        incr_date(endDateReceived.toISOString().split("T")[0])
      );

      setArchivedTasks(
        filteredArchivedTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        })
      );
      if (activeTabIndex === 3) {
        setLoading(false);
      }

      setArchivedCount(
        filteredArchivedTasks.filter((a) => {
          var date = new Date(a[3]).getTime();
          return (
            date >= new Date(startDateReceivedFilter).getTime() &&
            date <= new Date(endDateReceivedFilter).getTime() + +86300000
          );
        }).length
      );
    } else {
      setArchivedTasks(filteredArchivedTasks);
      if (activeTabIndex === 3) {
        setLoading(false);
      }
      setArchivedCount(filteredArchivedTasks.length);
    }

    if (taskRegisterFilter !== "") {
      setArchivedTasks(
        filteredArchivedTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        })
      );
      setArchivedCount(
        filteredArchivedTasks.filter((task) => {
          if (task.hasOwnProperty("registers")) {
            for (var x = 0; x < task.registers.length; x++) {
              if (task.registers[x].value === taskRegisterFilter) {
                return true;
              }
            }
          }
        }).length
      );
      if (activeTabIndex === 3) {
        setLoading(false);
      }
    }
    console.groupEnd("fetchDataWithFilterBy()")
  }

  async function appendCreatedBy(tasks) {
    let appendedArr = []
    for (let task of tasks) {
      let action = task?.activity?.find((act) => 
        act.field === "Create a task" ||
        act.field === "Duplicate" ||
        act.field === "Saved Task"
      );

      if (!action) {
        appendedArr.push(task);
        continue;
      } 

      if (!action.user?.profilePictureKey) {
        appendedArr.push({
          ...task,
          createdBy: {
            ...action.user
          }
        });
        continue;
      }

      let profilePicture = imageStates.current.find((image) => image.key === action.user.profilePictureKey)
      if (!profilePicture) { 
        let obj = {
          key: action.user.profilePictureKey, 
          img: 
            await Storage.get(action.user.profilePictureKey, {
              download: false,
              expires: 3600 // URL expires in 1 hour 
            })
        }
        imageStates.current.push(obj)
        profilePicture = obj;
      }
      appendedArr.push({
        ...task,
        createdBy: {
          ...action.user,
            profilePicture: profilePicture.img
        }
      })
    }
    return appendedArr;
  }

  function incr_date(date_str) {
    var parts = date_str.split("-");
    var dt = new Date(
      parseInt(parts[0], 10), // year
      parseInt(parts[1], 10) - 1, // month (starts with 0)
      parseInt(parts[2], 10) // date
    );
    dt.setDate(dt.getDate() + 1);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
      parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
      parts[2] = "0" + parts[2];
    }
    return parts.join("-");
  }

  async function fetchDataWithFilter() {
    const query = endDate
      ? `data.8:${priorityFilter} AND data.10:>=${
          startDate.toISOString().split("T")[0]
        } AND data.10:<=${
          endDate.toISOString().split("T")[0]
        } AND isDeleted:false`
      : `data.8:${priorityFilter} AND data.10:>=${
          startDate.toISOString().split("T")[0]
        } AND data.10:<=${
          startDate.toISOString().split("T")[0]
        } AND isDeleted:false`;

    console.log(query);

    const { data } = await API.graphql({
      query: TASK_LIST_DATA_SEARCH,
      variables: {
        companyId,
        query,
      },
    }).catch((e) => console.error(e));


    console.log("DATA SEARCH COUNT:", data);

    const tasks = data.taskListDataSearch.map(({ data, ...rest }, i) => ({
      no: i + 1,
      ...rest,
      ...JSON.parse(data),
    }));
    setaAllTasks(tasks);
    setAllCount(tasks.length);
    if (activeTabIndex === 0) {
      setLoading(false);
    }
    setCompletedCount(data.taskListDataCompletedCount);
    setOutstandingCount(data.taskListDataOutstandingCount);
  }

  //get options by teams
  const qGetTeams = `
  query getTeamsByCompany($id: String) {
    company(id: $id) {
      teams {
        items {
          id
          name
        }
      }
    }
  }
    `;

  const qGetTeamsWithMembers = `
  query getTeamMembers($id: ID) {
    team(id: $id) {
      id
      name
      members {
        items {
          user {
            id
            firstName
            lastName
            userType
            profilePicture
            history
          }
        }
      }
    }
  }`;
  async function fetchCompanyTeams() {
    const res = await API.graphql({
      query: qGetTeams,
      variables: {
        id: companyId,
      },
    });

    var retrievedTeams = res.data.company.teams.items;
    //  console.log("teams are " + retrievedTeams);

    var tempTeams = [];

    //cleans ups array to not include null or only white space team names
    for (var k = 0; k < retrievedTeams.length; k++) {
      //  console.log("Teams are: " + retrievedTeams[k].name);
      if (retrievedTeams[k].name && retrievedTeams[k].name.trim() !== "") {
        tempTeams.push(retrievedTeams[k]);
        // console.log("Added team: " + retrievedTeams[k].name)
      }
    }

    setTeams(tempTeams);
  }

  async function fetchCompanyTeamUsers() {
    const res1 = await API.graphql({
      query: qGetTeamsWithMembers,
      variables: {
        id: teamAssignee,
      },
    });

    var members = res1.data.team.members.items;
    var memberArray = [];
    var sortedMembers = [];
    // for (var i = 0; i < members.length; i++){
    //   console.log("Team Member Number " + i + ": " + members[i].user.firstName + " " + members[i].user.lastName)
    // }
    for (var i = 0; i < members.length; i++) {
      memberArray.push(
        members[i].user.firstName + " " + members[i].user.lastName
      );
    }
    sortedMembers = memberArray.slice().sort();

    setTeamMembers(sortedMembers);
    console.log(JSON.stringify(sortedMembers));
  }
  async function fetchCompanyUsers() {
    //change the company id by the organzation you want to filter
    //if(companyId === "ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e"){ //develop companyid
    // if(companyId === "c45f62bb-e91d-482d-9ddc-48cfebbb22b1"){ //uat companyid
    if (companyId === "7a7d3c83-4cb3-49cb-acef-82b7b71054eb") {
      //master companyid
      const res = await API.graphql({
        query: qGetTeams,
        variables: {
          id: companyId,
        },
      });

      var teamNames = res.data.company.teams.items;
      var specialTeamId = "";

      /* Finding the team id of the team named "Operations" */
      for (var i = 0; i < teamNames.length; i++) {
        if (teamNames[i].name?.toLowerCase() === "operations") {
          specialTeamId = teamNames[i].id;
          break;
        } else {
          specialTeamId = "0"; //placeholder
        }
      }

      if (specialTeamId === "0") {
        const { data } = await API.graphql({
          query: COMPANY_USERS_QUERY,
          variables: {
            id: companyId,
          },
        });

        const usersFullNames = data.company.users.items.map(
          (p) => p
          // ({ firstName, lastName, profilePicture }) => {
          //   return { firstName, lastName, profilePicture };
          // }
        );
        setPeople(usersFullNames); //set regular list as options, null catcher
      } else {
        const res1 = await API.graphql({
          query: qGetTeamsWithMembers,
          variables: {
            id: specialTeamId,
          },
        });

        var members = res1.data.team.members.items;
        var memberArray = [];
        for (var j = 0; j < members.length; j++) {
          memberArray = [
            ...memberArray,
            {
              id: members[j].user.id,
              firstName: members[j].user.firstName,
              lastName: members[j].user.lastName,
              profilePicture: members[j].user.profilePicture,
              history: members[j].user.history
            },
          ];
        }

        setPeople(memberArray); //filtered people
      }
    } else {
      const { data } = await API.graphql({
        query: COMPANY_USERS_QUERY,
        variables: {
          id: companyId,
        },
      });

      const usersFullNames = data.company.users.items.map(
        (p) => p
        // ({ firstName, lastName, profilePicture }) => {
        //   return { firstName, lastName, profilePicture };
        // }
      );
      setPeople(usersFullNames); //set regular list as options
    }
  }

  const getUserClientMatterAccess = async () => {
    const userClientMatterAccess = await API.graphql({
      query: qUserClientMatterAccess,
      variables: {
        id: localStorage.getItem("userId"),
        companyId: localStorage.getItem("companyId"),
      },
    });

    const clientMatterIds =
      userClientMatterAccess.data.user.clientMatterAccess.items.map(
        (i) => i.clientMatter.id
      );

    return clientMatterIds;
  };

  async function fetchCompanyClientMatters() {
    //let result = [];
    const { data } = await API.graphql({
      query: COMPANY_CLIENTMATTERS_QUERY,
      variables: {
        id: companyId,
      },
    });

    /****
     * Replaced the old algorithm with an updated one
     * since the old one was not showing the updated
     * client matters list. I got this from the Gmail
     * Integration page (MMA-2113)
     ****/
    //MMA-2200 Improved error handling and sorting performance
    const clientMatterData = data?.company?.clientMatters?.items ?? null;
    if (clientMatterData !== null) {
      //const rawResult = result;
      const mappedClientMatter = clientMatterData.map(
        ({ id, client, matter }) => ({
          value: id,
          label: `${client?.name?.trim()}/${matter?.name?.trim()}`,
        })
      );

      const filteredClientMatter = mappedClientMatter?.filter(
        ({ label, value }) => label != null && value != null
      );

      if (typeof Intl.Collator !== "undefined") {
        console.log("Using Intl.Collator");
        const collator = new Intl.Collator();
        filteredClientMatter.sort((a, b) => collator.compare(a.label, b.label));
      } else {
        console.log(
          "Intl.Collator not supported in this version of your browser"
        );
        filteredClientMatter.sort((a, b) => a.label.localeCompare(b.label));
      }

      filteredClientMatter.push({
        value: null,
        label: "No selected Client/Matter",
      });

      //  // set ClientMatter List for each dropdown
      //  setClientMatters(cm.sort((a, b) => a.label.localeCompare(b.label)));

      //  // Set the filtered and sorted client matters with the blank option
      //  setFilterClientMattersOptions(
      //    cm.sort((a, b) => a.label.localeCompare(b.label))
      //  );

      // set ClientMatter List for each dropdown
      setClientMatters(filteredClientMatter);

      // Set the filtered and sorted client matters with the blank option
      setFilterClientMattersOptions(filteredClientMatter);
    }
  }

  function toggleFiltersModal() {
    if (filtersModal) {
      setFiltersModal(false);
    } else {
      setFiltersModal(true);
    }
  }

  async function getCounterOutstanding(queryOutstandingCounter) {
    const { data } = await API.graphql({
      query: TASK_LIST_DATA_SEARCH,
      variables: {
        companyId,
        query: queryOutstandingCounter,
      },
    });
    setOutstandingCount(data?.taskListDataSearch.length);
  }

  async function getCounterCompleted(queryCompletedCounter) {
    const { data } = await API.graphql({
      query: TASK_LIST_DATA_SEARCH,
      variables: {
        companyId,
        query: queryCompletedCounter,
      },
    });
    setCompletedCount(data?.taskListDataSearch.length);
  }

  async function getPresetsLibrary() {
    // console.group("getPresetsLibrary()");
    const { data } = await API.graphql({
      query: TASKLIST_PRESETS_QUERY,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    });

    console.log("taskListPresets", data);

    setPresetsLibary(data?.taskListPresets);
  }

  async function setDefaultPreset() {
    if (presetsLibrary) {
      // Find the preset in presetsLibrary with label "Task List"
      console.log("PRESET LIB:", presetsLibrary);
      const taskListFilterAttr = presetsLibrary.find(
        (attr) => attr.label === "Task List"
      );

      // Retrieve the id of the taskListFilterAttr if it exists, otherwise set it to null
      const taskListFilterAttrId = taskListFilterAttr
        ? taskListFilterAttr.id
        : null;

      // If the taskListFilterAttrId exists, set the preset to that id
      if (taskListFilterAttrId) {
        setPreset(taskListFilterAttrId);
      }
    }
  }

  async function createDefaultRegister() {
    /**
    const companyId = localStorage.getItem("companyId");
    const columnIds = [];
    // for (let i = 0; i < columns.length; i++) {
    //   columnIds.push(i);
    // }

    const { data } = await API.graphql({
      query: CREATE_TASKLIST_PRESET_MUTATION,
      variables: {
        companyId,
        label: "TASK LIST",
        columnIds,
      },
    });

    const { id } = data.taskListPresetsCreate;
    console.log("Newly Added ID is", id);
    await getPresetsLibrary();
    return id;
    */
  }

  const clearSearch = () => {
    setSearchQuery("");
    fetchDataWithFilterBy("filters");
  };

  const searchByContent = () => {
    // All tasks
    const resultsAllTasks = searchArray(allTasks, searchQuery);
    console.log("filtered", resultsAllTasks);
    setaAllTasks(resultsAllTasks);
    setAllCount(resultsAllTasks.length);

    // Outstanding tasks
    const resultsOutstandingTasks = searchArray(outstandingTasks, searchQuery);
    console.log("filteredOUT", resultsOutstandingTasks);
    setOutstandingTasks(resultsOutstandingTasks);
    setOutstandingCount(resultsOutstandingTasks.length);

    // Completed tasks
    const resultsCompletedTasks = searchArray(completedTasks, searchQuery);
    console.log("filteredCOMPLETED", resultsCompletedTasks);
    setCompletedTasks(resultsCompletedTasks);
    setCompletedCount(resultsCompletedTasks.length);

    // Archived tasks
    const resultsArchivedTasks = searchArray(archivedTasks, searchQuery);
    console.log("filteredCOMPLETED", resultsArchivedTasks);
    setArchivedTasks(resultsArchivedTasks);
    setArchivedCount(resultsArchivedTasks.length);
  };

  function searchArray(array, searchTerm) {
    return array.filter((item) => {
      for (const key in item) {
        const propertyValue = item[key];

        if (propertyValue !== null && typeof propertyValue === "object") {
          // Handle nested objects, like the "registers" array
          if (searchArray([propertyValue], searchTerm).length > 0) {
            return true;
          }
        } else if (
          propertyValue !== null &&
          typeof propertyValue === "string"
        ) {
          const lowerCaseValue = propertyValue.toLowerCase();
          const lowerCaseSearchTerm = searchTerm.toLowerCase();

          if (lowerCaseValue.includes(lowerCaseSearchTerm)) {
            return true;
          }
        } else if (
          propertyValue !== null &&
          typeof propertyValue === "number"
        ) {
          if (propertyValue === searchTerm) {
            return true;
          }
        }
      }
      return false;
    });
  }

  const handleSelectChangeCMFilter = (newValue) => {
    setFilterClientMatters(newValue);
  };

  async function hasAttachments(id) {
    const params = {
      query: EMAIL_ATTTACHMENT, // Replace with your actual query
      variables: {
        id: id,
      },
    };
    try {
      const response = await API.graphql(params);
      console.log("Response:", response.data); // Debugging line

      const result = response.data?.gmailMessage?.attachments?.items;
      console.log("Result:", result); // Debugging line

      if (result) {
        const filteredResult = result.filter(
          (u) => u.isDeleted !== true && u.details !== "EMAIL_PDF_VERSION"
        );
        console.log("Filtered Result:", filteredResult); // Debugging line

        return filteredResult.length > 0;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching attachments:", error);
      throw error;
    }
  }

  async function checkAttachmentsForEmailObjects(emailObjects) {
    const results = [];

    for (const emailObj of emailObjects) {
      const hasAttachment = await hasAttachments(emailObj.gmailMessageId);
      results.push({ gmailMessageId: emailObj.gmailMessageId, hasAttachment });
    }

    return results;
  }

  var moment = require("moment");

  //MMA-2353: Convert selected tasks to text and copy to clipboard

  /**
 * Converts selected divs to table format and creates screenshots of the tables.
 */
  async function convertDivsToTable() {
    const table = document.getElementById("table-selected");
    const processedImgs = [];

    // Set table style
    table.style.position = "absolute";
    table.style.zIndex = "-1";

    // Sort selectedDivs by id
    selectedDivs.sort((a, b) => {
      let fa = a.id,
        fb = b.id;

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    // Set table header style
    tableHeader.style["zIndex"] = "0";
    tableHeader.childNodes[0].childNodes.forEach((cell) => {
      cell.classList.add("p-2");
      cell.classList.add("h-16");
    });

    // Append table header to the table
    table.appendChild(tableHeader);

    var ctr = 0;
    var sum = 0;
    var arr = [];

    var tableMultiple = document.getElementById("table-multiple");
    var numTable = Math.ceil(selectedDivs.length / 100);
    var mulCtr = 0;

    // Loop through selected divs
    for (const divs of selectedDivs) {
      divs.className = "";
      divs.childNodes.forEach((div) => {
        // Set style for each cell
        div.classList.add("p-4");
      });

      // Get img elements
      let imgElements = divs.getElementsByTagName("img");
      
      // Converts the img source from s3 links to dataUrls
      await convertImagesToDataURLs(imgElements, processedImgs);

      // Check if multiple tables are needed
      if (numTable > 1 && sum > 99 && sum < selectedDivs.length) {
        if (ctr == 0) {
          var newTable = document.createElement("table");
          newTable.id = "tableMulti-" + mulCtr;
          tableMultiple.appendChild(newTable);
        }
        var selectedTable = document.getElementById(`tableMulti-${mulCtr}`);
        selectedTable.style.position = "absolute";
        selectedTable.style.zIndex = "-1";
        selectedTable.appendChild(divs);
        ctr++;
        if (ctr == 100 || sum == selectedDivs.length - 1) {
          await new Promise((resolve) => {
            html2canvas(selectedTable).then((canvas) => {
              selectedTable.innerHTML = "";
              arr.push(canvas.toDataURL("image/jpeg"));
              resolve();
            });
          });
          ctr = 0;
          mulCtr++;
        }
      } else {
        table.appendChild(divs);
        ctr++;
        if (ctr == selectedDivs.length || ctr == 100) {
          await new Promise((resolve) => {
            html2canvas(table).then((canvas) => {
              table.innerHTML = "";
              arr.push(canvas.toDataURL("image/jpeg"));
              resolve();
            });
          });
          ctr = 0;
        }
      }
      sum++;
    }

    // GARBAGE COLLECTING ALL THE PROCESSED IMAGES
    for (let processedImg of processedImgs) {
      URL.revokeObjectURL(processedImg.dataUrl)
    }

    setScreenshot(arr);
    return;
  }


  async function convertImagesToDataURLs (imgElements, processedImgs) {
    for (let img of imgElements) {
      let imgUrl = img.src;
      let isProcessed = processedImgs.find((e) => e.src === imgUrl);

      if (isProcessed) {
        img.src = isProcessed.dataUrl;
      } else {
        const response = await fetch(imgUrl, {
          mode: 'cors',
          headers: { "Cache-Control": 'no-cache' },
        }); // Replace with your image URL
        const blob = await response.blob();
        
        const dataUrl = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        processedImgs.push({
          src: img.src,
          dataUrl: dataUrl,
        });
        img.src = dataUrl;
      }
    }
  }

  async function convertTasksToText() {
    setIsConverting(true);
    setTimeout( async () => {
      await convertDivsToTable();
    }, 500);
    //added loading while converting text
    setIsConverting(false);
    setShowPreviewTasks(true);
  }

  const statusMap = {
    0: "",
    1: "Outstanding",
    2: "Completed",
    3: "Archived",
  };

  return (
    <>
      <div id="table-multiple">
        <table id="table-selected"></table>
      </div>
      {width > 844 ? (
        <>
          <div
            className="sticky flex flex-col items-center justify-center"
            style={{
              left: "0px",
              top: "140px",
              zIndex: DatePickerActive ? 10 : 0,
            }}
          ></div>
          {/* start of search bar web */}
          <div className="grid w-full grid-cols-2 gap-1">
            <Tab.Group
              selectedIndex={activeTabIndex}
              onChange={(index) => {
                setActiveTabIndex(index);
                if (index !== 0) {
                  setLoading(false);
                }
              }}
              className="sticky top-0 flex w-full mt-3 ml-2 overflow-x-scroll bg-white left-3"
              style={{ zIndex: 2 }}
            >
              <Tab.List className="flex w-full space-x-2">
                <Tab>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <div
                      onClick={(e) => {
                        setStatus("");
                        //fetchDataWithFilterBy("");
                      }}
                      className={`flex items-center ${
                        selected
                          ? "text-gray-900 border-b border-black p-2 font-semibold"
                          : "text-gray-500"
                      }`}
                    >
                      <p className={!selected ? "mr-2" : "mr-2 font-bold"}>
                        All Tasks
                      </p>
                      <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                        {allCount}
                      </p>
                    </div>
                  )}
                </Tab>
                <Tab>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <div
                      onClick={(e) => {
                        setStatus("Outstanding");
                        //fetchDataWithFilterBy("");
                      }}
                      className={`flex items-center ${
                        selected
                          ? "text-gray-900 border-b border-black p-2 font-semibold"
                          : "text-gray-500"
                      }`}
                    >
                      <p className={!selected ? "mr-2" : "mr-2 font-bold"}>
                        {" "}
                        Outstanding Tasks
                      </p>
                      <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                        {outstandingCount}
                      </p>
                    </div>
                  )}
                </Tab>
                <Tab>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      onClick={(e) => {
                        setStatus("Completed");
                        //fetchDataWithFilterBy("");
                      }}
                      className={`flex items-center ${
                        selected
                          ? "text-gray-900 border-b border-black p-2 font-semibold"
                          : "text-gray-500"
                      }`}
                    >
                      <p className={!selected ? "mr-2" : "mr-2 font-bold"}>
                        {" "}
                        Completed Tasks
                      </p>
                      <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                        {completedCount}
                      </p>
                    </button>
                  )}
                </Tab>
                <Tab>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      onClick={(e) => {
                        setStatus("Archived");
                        //fetchDataWithFilterBy("");
                      }}
                      className={`flex items-center ${
                        selected
                          ? "text-gray-900 border-b border-black p-2 font-semibold"
                          : "text-gray-500"
                      }`}
                    >
                      <p className={!selected ? "mx-2" : "mx-2 font-bold"}>
                        {" "}
                        <FiArchive />
                      </p>
                      <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                        {archivedCount}
                      </p>
                    </button>
                  )}
                </Tab>
              </Tab.List>
            </Tab.Group>

            <div
              className="sticky items-center inline"
              style={{ zIndex: 3, left: "13px" }}
            >
              <div
                onSubmit={(e) => e.preventDefault()}
                className="sticky flex items-center justify-center p-1 mt-1 space-x-1"
              >
                <div className="relative flex flex-wrap items-center flex-grow bg-white border rounded-md h-13">
                  <div className="flex justify-center w-10 pl-2 -mr-px">
                    <span className="flex items-center px-3 text-2xl leading-normal text-gray-600 bg-white border-0 rounded rounded-r-none">
                      <AiOutlineSearch />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="relative self-center flex-1 flex-grow flex-shrink w-px h-10 px-3 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light"
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchQuery(e.target.value);
                    }}
                    value={searchQuery}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        searchByContent(e);
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value === "") {
                        setSearchQuery(e.target.value);
                        fetchDataWithFilterBy("filters");
                      }
                    }}
                    placeholder="Search"
                  />
                  {searchQuery && (
                    <button
                      onClick={clearSearch}
                      className="absolute top-0 flex items-center h-full px-3 text-gray-400 right-10 hover:text-gray-600"
                    >
                      <span className="text-lg">×</span>
                    </button>
                  )}
                  <button
                    onClick={() => toggleFiltersModal()} // Make sure to define toggleFiltersModal function
                    className="absolute right-0 h-full px-3 py-3"
                  >
                    <IoIosOptions />
                  </button>
                </div>
                {filtersModal ? (
                  <div
                    className="absolute z-50 flex flex-col max-h-screen p-3 mt-3 bg-white border-gray-300 rounded-md shadow-2xl top-11"
                    style={{ width: "99%" }}
                  >
                    <div className="flex flex-row">
                      <h4 className="font-bold">Filter By:</h4>
                      <div
                        className="top-0 right-0 pb-3 pr-2 ml-auto cursor-pointer hover:text-red-500"
                        onClick={() => setFiltersModal(false)}
                      >
                        Close
                      </div>
                    </div>

                    <div className="pt-4 pb-1">
                      <div className="pt-1">
                        <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                          Client Matter:
                          <div className="col-span-2">
                            <CreatableSelect
                              options={filterClientMattersOptions}
                              className="flex-1"
                              styles={{
                                menuPortal: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              defaultValue={null}
                              value={filterClientMatters}
                              onChange={handleSelectChangeCMFilter}
                              placeholder="Filter Client/Matter"
                              menuPlacement="top"
                              menuPortalTarget={document.body}
                              isClearable
                              isValidNewOption={() => false}
                            />
                          </div>
                        </h5>
                      </div>
                    </div>

                    <div className="pt-4 pb-1">
                      <div className="pt-1">
                        <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                          Date Submitted:
                          <div className="col-span-2">
                            <DatePicker
                              ref={datePickerRef}
                              onFocus={() => setDatePickerActive(true)}
                              onBlur={() => setDatePickerActive(false)}
                              selected={startDate}
                              placeholderText="Date Submitted"
                              onChange={onChange}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              monthsShown={2}
                              calendarClassName="lg:flex w-max"
                              weekDayClassName=""
                              className={`p-2 w-full bg-gray-200 rounded-2xl`}
                            />
                          </div>
                        </h5>
                      </div>
                    </div>

                    <div className="pt-2">
                      <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                        {localStorage.getItem("userType") === "OWNER" ? (
                          <p
                            className="text-blue-500 underline cursor-pointer"
                            onClick={() => setShowPrioritySummaryModal(true)}
                          >
                            Priority:
                          </p>
                        ) : (
                          <p>Priority:</p>
                        )}
                        <select
                          onChange={(e) => {
                            setPriorityFilter(e.target.value);
                          }}
                          className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                          name="priority"
                          id="priority"
                        >
                          <option value="" disabled selected>
                            {priorityFilter === "" ? "All" : priorityFilter}
                          </option>
                          {[1, 2, 3].map((p) => (
                            <option value={p}>{p}</option>
                          ))}
                        </select>
                      </h5>
                    </div>

                    {(localStorage.getItem("userType") === "OWNER" ||
                      !restrictedUsers.has(localStorage.getItem("email"))) && (
                      <div className="pt-4">
                        <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                          Teams:
                          <select
                            onChange={(e) => {
                              setTeamAssignee(
                                e.target.value !== "" ? e.target.value : ""
                              );
                            }}
                            className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                            value={teamAssignee}
                          >
                            <option value="">No Team Selected</option>
                            {teams.map((team, i) => {
                              return (
                                <option value={team.id}>{team.name}</option>
                              );
                            })}
                          </select>
                        </h5>

                        <h5 className="grid items-center grid-cols-3 gap-4 pt-4 font-medium">
                          <p
                            className="text-blue-500 underline cursor-pointer"
                            onClick={() => setShowAssigneeSummaryModal(true)}
                          >
                            Assignee:
                          </p>

                          {teamAssignee !== "" ? (
                            <select
                              onChange={(e) => {
                                let value = e?.target?.value;

                                if (value === "Unassigned") {
                                  setAssignee(value);
                                } else {
                                  let person = people.find((p) => value === p.id)
                                  setAssignee(person);
                                }
                                // setAssignee(
                                //   e.target.value !== "" ? e.target.value : ""
                                // );
                              }}
                              className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                              value={`${typeof assignee === "object" ? assignee.id : assignee}`}
                              disabled
                            >
                              <option value="">Select All</option>
                              <option value="Unassigned">Unassigned</option>
                              {people.map((p, i) => {
                                let person = `${p.firstName} ${p.lastName}`;
                                return <option value={p.id}>{person}</option>;
                              })}
                            </select>
                          ) : (
                            <select
                              onChange={(e) => {
                                let value = e?.target?.value;

                                if (value === "Unassigned") {
                                  setAssignee(value);
                                } else {
                                  let person = people.find((p) => value === p.id)
                                  setAssignee(person);
                                }
                                // setAssignee(
                                //   e.target.value !== "" ? e.target.value : ""
                                // );
                              }}
                              className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                              value={`${typeof assignee === "object" ? assignee.id : assignee}`}
                            >
                              <option value="">Select All</option>
                              <option value="Unassigned">Unassigned</option>
                              {people.map((p, i) => {
                                let person = `${p.firstName} ${p.lastName}`;
                                return <option value={p.id}>{person}</option>;
                              })}
                            </select>
                          )}
                        </h5>
                      </div>
                    )}

                    <div className="pt-4 pb-1">
                      <div className="pt-1">
                        <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                          Date Received:
                          <div className="col-span-2">
                            <DatePicker
                              ref={datePickerRef}
                              onFocus={() => setDatePickerActive(true)}
                              onBlur={() => setDatePickerActive(false)}
                              selected={startDateReceived}
                              placeholderText="Date Received"
                              onChange={onChangeDateReceived}
                              startDate={startDateReceived}
                              endDate={endDateReceived}
                              selectsRange
                              monthsShown={2}
                              calendarClassName="lg:flex w-max"
                              weekDayClassName=""
                              className={`p-2 bg-transparent w-full bg-gray-200 p-1 rounded-2xl`}
                            />
                          </div>
                        </h5>
                      </div>
                    </div>

                    {activeTabIndex === 0 ? (
                      <div className="pt-1">
                        <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                          Status:
                          <select
                            onChange={(e) => {
                              setStatus(
                                e.target.value !== "" ? e.target.value : ""
                              );
                            }}
                            className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                            value={status}
                          >
                            <option value="">All</option>
                            <option value="Outstanding">Outstanding</option>
                            <option value="Completed">Completed</option>
                            <option value="Follow Up">Follow Up</option>
                          </select>
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="pt-4">
                      <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                        {localStorage.getItem("userType") === "OWNER" ? (
                          <>Column View:</>
                        ) : (
                          <>Column View:</>
                        )}
                        <select
                          onChange={(e) => {
                            console.log("COLUMN FILTER IS:", e.target.value);
                            setTaskRegisterFilter(e.target.value);
                          }}
                          className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                          value={taskRegisterFilter}
                        >
                          <option value="">- - - Please Select - - -</option>
                          {presetsLibrary?.map((p, i) => {
                            return <option value={p.id}>{p.label}</option>;
                          })}
                        </select>
                      </h5>
                    </div>

                    <div className="pt-4">
                      <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                        {localStorage.getItem("userType") === "OWNER" ? (
                          <>Has Attachments:</>
                        ) : (
                          <>Has Attachments:</>
                        )}
                        <input
                          aria-describedby="has-attachment-checkbox"
                          type="checkbox"
                          checked={searchHasAttachments}
                          onChange={(e) =>
                            setSearchHasAttachments(e.target.checked)
                          }
                          className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </h5>
                    </div>

                    <div className="pt-8">
                      <Button
                        variant="secondary"
                        className="float-right px-4 py-2"
                        onClick={(e) => {
                          fetchDataWithFilterBy("all");
                          setFiltersModal(false);
                          filterColumns();
                        }}
                      >
                        Apply Filters
                      </Button>

                      <Button
                        variant="danger"
                        className="float-right px-4 py-2 mr-1"
                        onClick={() => setClearFilter(true)}
                      >
                        Clear Filters
                      </Button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {searching ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setQuery("");
                      setSearching(false);
                      fetchTaskListData();
                    }}
                    className="flex items-center p-2 text-gray-400 bg-gray-300 rounded-full"
                  >
                    <AiOutlineClose />
                  </button>
                ) : isConverting && selectedRows.length > 0 ? (
                  <button
                    type="button"
                    onClick={() => {
                      // Handle the action for "Copy to Clipboard" here MMA-2351
                    }}
                    className="font-medium save-btn"
                  >
                    Converting Tasks To Text
                  </button>
                ) : selectedRows.length > 0 ? (
                  <button
                    type="button"
                    onClick={() => {
                      // Handle the action for "Copy to Clipboard" here MMA-2351
                      convertTasksToText();
                    }}
                    className="font-medium save-btn"
                  >
                    Copy Tasks to Clipboard
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => createTask(e)}
                    className="font-medium save-btn"
                  >
                    Create Task
                  </button>
                )}

                <div className="items-center justify-center hidden space-x-4">
                  <div className="flex p-4 space-x-2 bg-gray-200 rounded-2xl">
                    <DatePicker
                      ref={datePickerRef}
                      onFocus={() => setDatePickerActive(true)}
                      onBlur={() => setDatePickerActive(false)}
                      selected={startDate}
                      placeholderText="Today"
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      monthsShown={2}
                      calendarClassName="lg:flex bg-white"
                      weekDayClassName=""
                      className={`bg-transparent ${
                        endDate ? "visible" : "hidden"
                      }`}
                    />

                    <div
                      onClick={() => {
                        setEndDate(new Date());
                      }}
                      className={`${endDate ? "hidden" : "flex"}`}
                    >
                      <p className="mr-2">Today</p>
                      <BsCalendarCheck />
                    </div>

                    <select
                      onChange={(e) => {
                        console.log("Value", e.target.value);
                        setPriorityFilter(e.target.value);
                      }}
                      className="bg-transparent"
                      name="priority"
                      id="priority"
                    >
                      <option value="" disabled selected>
                        All
                      </option>
                      {[1, 2, 3].map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end of search bar web*/}
          <div className="h-0 mt-12 border-l rounded-lg">
            {activeTabIndex === 0 ? (
              <DataTable
                setemailPreviewState={setemailPreviewState}
                setshowEmailPreview={setshowEmailPreview}
                toggleSidebar={toggleSidebar}
                columns={filteredColumns}
                data={allTasks}
                setData={setaAllTasks}
                setAllCount={setAllCount}
                setOutstandingCount={setOutstandingCount}
                setCompletedCount={setCompletedCount}
                allCount={allCount}
                outstandingCount={outstandingCount}
                completedCount={completedCount}
                clientMatters={clientMatters}
                people={people}
                fetchTaskListData={fetchTaskListData}
                fetchCompanyClientMatters={fetchCompanyClientMatters} //MMA1060 Change
                fetchOutsandingTasks={fetchOutsandingTasks}
                fetchCompletedTasks={fetchCompletedTasks}
                fetchDataWithFilterBy={fetchDataWithFilterBy}
                activeTabIndex={activeTabIndex}
                loading={loading}
                setLoading={setLoading}
                taskRegisters={presetsLibrary}
                preset={preset}
                lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                updatePriorityValue={updatePriorityValue}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedDivs={selectedDivs}
                setSelectedDivs={setSelectedDivs}
                setTableHeader={setTableHeader}
                archivedCount={archivedCount}
                setArchivedCount={setArchivedCount}
                setaAllTasks={setaAllTasks}
                setOutstandingTasks={setOutstandingTasks}
                setCompletedTasks={setCompletedTasks}
                setArchivedTasks={setArchivedTasks}
                allTasks={allTasks}
                outstandingTasks={outstandingTasks}
                completedTasks={completedTasks}
                archivedTasks={archivedTasks}
              />
            ) : activeTabIndex === 1 ? (
              <DataTable
                setemailPreviewState={setemailPreviewState}
                setshowEmailPreview={setshowEmailPreview}
                toggleSidebar={toggleSidebar}
                columns={filteredColumns}
                data={outstandingTasks}
                setData={setOutstandingTasks}
                setAllCount={setAllCount}
                setOutstandingCount={setOutstandingCount}
                setCompletedCount={setCompletedCount}
                allCount={allCount}
                outstandingCount={outstandingCount}
                completedCount={completedCount}
                clientMatters={clientMatters}
                people={people}
                fetchTaskListData={fetchTaskListData}
                fetchCompanyClientMatters={fetchCompanyClientMatters} //MMA1060 Change
                fetchOutsandingTasks={fetchOutsandingTasks}
                fetchCompletedTasks={fetchCompletedTasks}
                fetchDataWithFilterBy={fetchDataWithFilterBy}
                activeTabIndex={activeTabIndex}
                loading={loading}
                setLoading={setLoading}
                taskRegisters={presetsLibrary}
                preset={preset}
                lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                updatePriorityValue={updatePriorityValue}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedDivs={selectedDivs}
                setSelectedDivs={setSelectedDivs}
                setTableHeader={setTableHeader}
                archivedCount={archivedCount}
                setArchivedCount={setArchivedCount}
                setaAllTasks={setaAllTasks}
                setOutstandingTasks={setOutstandingTasks}
                setCompletedTasks={setCompletedTasks}
                setArchivedTasks={setArchivedTasks}
                allTasks={allTasks}
                outstandingTasks={outstandingTasks}
                completedTasks={completedTasks}
                archivedTasks={archivedTasks}
              />
            ) : activeTabIndex === 2 ? (
              <DataTable
                setemailPreviewState={setemailPreviewState}
                setshowEmailPreview={setshowEmailPreview}
                toggleSidebar={toggleSidebar}
                columns={filteredColumns}
                data={completedTasks}
                setData={setCompletedTasks}
                setAllCount={setAllCount}
                setOutstandingCount={setOutstandingCount}
                setCompletedCount={setCompletedCount}
                allCount={allCount}
                outstandingCount={outstandingCount}
                completedCount={completedCount}
                clientMatters={clientMatters}
                people={people}
                fetchTaskListData={fetchTaskListData}
                fetchCompanyClientMatters={fetchCompanyClientMatters} //Jed Change
                fetchOutsandingTasks={fetchOutsandingTasks}
                fetchCompletedTasks={fetchCompletedTasks}
                fetchDataWithFilterBy={fetchDataWithFilterBy}
                activeTabIndex={activeTabIndex}
                loading={loading}
                setLoading={setLoading}
                taskRegisters={presetsLibrary}
                preset={preset}
                lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                updatePriorityValue={updatePriorityValue}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedDivs={selectedDivs}
                setSelectedDivs={setSelectedDivs}
                setTableHeader={setTableHeader}
                archivedCount={archivedCount}
                setArchivedCount={setArchivedCount}
                setaAllTasks={setaAllTasks}
                setOutstandingTasks={setOutstandingTasks}
                setCompletedTasks={setCompletedTasks}
                setArchivedTasks={setArchivedTasks}
                allTasks={allTasks}
                outstandingTasks={outstandingTasks}
                completedTasks={completedTasks}
                archivedTasks={archivedTasks}
              />
            ) : activeTabIndex === 3 ? (
              <DataTable
                setemailPreviewState={setemailPreviewState}
                setshowEmailPreview={setshowEmailPreview}
                toggleSidebar={toggleSidebar}
                columns={filteredColumns}
                data={archivedTasks}
                setData={setArchivedTasks}
                setAllCount={setAllCount}
                setOutstandingCount={setOutstandingCount}
                setCompletedCount={setCompletedCount}
                allCount={allCount}
                outstandingCount={outstandingCount}
                completedCount={completedCount}
                clientMatters={clientMatters}
                people={people}
                fetchTaskListData={fetchTaskListData}
                fetchCompanyClientMatters={fetchCompanyClientMatters}
                fetchOutsandingTasks={fetchOutsandingTasks}
                fetchCompletedTasks={fetchCompletedTasks}
                fetchDataWithFilterBy={fetchDataWithFilterBy}
                activeTabIndex={activeTabIndex}
                loading={loading}
                setLoading={setLoading}
                taskRegisters={presetsLibrary}
                preset={preset}
                lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                updatePriorityValue={updatePriorityValue}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedDivs={selectedDivs}
                setSelectedDivs={setSelectedDivs}
                setTableHeader={setTableHeader}
                archivedCount={archivedCount}
                setArchivedCount={setArchivedCount}
                setaAllTasks={setaAllTasks}
                setOutstandingTasks={setOutstandingTasks}
                setCompletedTasks={setCompletedTasks}
                setArchivedTasks={setArchivedTasks}
                allTasks={allTasks}
                outstandingTasks={outstandingTasks}
                completedTasks={completedTasks}
                archivedTasks={archivedTasks}
              />
            ) : null}
          </div>
          {showAssigneeSummaryModal && (
            <AssigneeTasksCountModal
              companyId={localStorage.getItem("companyId")}
              people={people}
              handleAssigneeSummaryClose={handleAssigneeSummaryClose}
            />
          )}

          {showPrioritySummaryModal && (
            <PriorityTasksCountModal
              companyId={localStorage.getItem("companyId")}
              handlePrioritySummaryClose={handlePrioritySummaryClose}
            />
          )}

          {showTasklistPresetsModal && (
            <TasklistPresetsModal
              companyId={localStorage.getItem("companyId")}
              columns={columns}
              presetsLibrary={presetsLibrary}
              handleTasklistPresetsClose={handleTasklistPresetsClose}
              handleTasklistPresetsChanges={handleTasklistPresetsChanges}
            />
          )}

          {/* end of web version */}
        </>
      ) : (
        //mobile version -kjmf
        <>
          {/* start of searchbar */}
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ position: "", top: "-8px", zIndex: 20 }}
            className=""
          >
            <div className="w-full flex justify-start items-start gap-5 px-1">
              <div className="self-stretch mb-6 flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch h-14 flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch text-gray-900 text-lg font-semibold font-open-sans leading-7">
                    Tasks
                  </div>
                  <div className="self-stretch text-slate-600 text-sm font-light font-open-sans leading-tight">
                    Organise tasks from emails, label them as completed or
                    outstanding for streamlined project management.
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              {/* <Button
								variant="secondary-l"
								size="xl"
								type="button"
								block="true"
								onClick={(e) => createTask(e)}
								className="h-10 mr-1 font-medium"
								>+</Button> */}

              <Button
                // variant="hbWhite"
                size="xl"
                type="button"
                block="true"
                onClick={() => history.goBack()}
                className="py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex"
              >
                <FiArrowLeft />
              </Button>

              <Button
                variant="hbBlue"
                size="xl"
                type="button"
                block="true"
                onClick={(e) => createTask(e)}
                className="py-2.5 px-2 bg-white text-white text-sm rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex"
              >
                <div className="font-semibold font-open-sans leading-tight">
                  Create New
                </div>
              </Button>
              <div
                className="relative flex flex-wrap items-center flex-grow h-10 bg-white border rounded-md "
                style={{ zIndex: 5 }}
              >
                <div className="flex justify-center w-10 -mr-px">
                  <span className="flex items-center px-1 text-2xl leading-normal text-gray-600 bg-white border-0 rounded rounded-r-none">
                    <AiOutlineSearch />
                  </span>
                </div>
                <input
                  ref={inputRef}
                  type="text"
                  className="relative self-center font-open-sans flex-1 flex-grow flex-shrink w-px h-8 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light"
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchQuery(e.target.value);
                  }}
                  value={searchQuery}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      searchByContent(e);
                    }
                  }}
                  onInput={(e) => {
                    if (e.target.value === "") {
                      setSearchQuery(e.target.value);
                      fetchDataWithFilterBy("filters");
                    }
                  }}
                  placeholder="Search anything..."
                />
                {searchQuery && (
                  <div className="flex justify-center w-10 pl-2 -mr-px">
                    <span
                      onClick={handleClearInput}
                      className="flex items-center px-3 text-2xl leading-normal text-gray-600 bg-white border-0 rounded rounded-r-none"
                    >
                      <TiTimes />
                    </span>
                  </div>
                )}
                <span
                  onClick={() => toggleFiltersModal()}
                  className="h-full p-3 px-3 transition duration-500 ease-in-out bg-gray-600 rounded-md cursor-pointer active:bg-gray-400"
                >
                  <IoIosOptions className="text-gray-50" />
                </span>
              </div>
            </div>
            {filtersModal ? (
              <div className="absolute z-50 flex flex-col max-h-screen p-3 mt-1 bg-white border-gray-300 rounded-md shadow-2xl">
                <div className="flex flex-row">
                  <h4 className="font-bold">Filter By:</h4>
                  <div
                    className="top-0 right-0 pb-3 pr-2 ml-auto cursor-pointer hover:text-red-500"
                    onClick={() => setFiltersModal(false)}
                  >
                    Close
                  </div>
                </div>

                <div className="pt-4 pb-1">
                  <div className="pt-1">
                    <h5 className="grid items-center grid-cols-3 gap-4 ">
                      Client Matter:
                      <div className="col-span-2">
                        <CreatableSelect
                          options={filterClientMattersOptions}
                          className="flex-1 p-2"
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          defaultValue={null}
                          value={filterClientMatters}
                          onChange={handleSelectChangeCMFilter}
                          placeholder="Filter Client/Matter"
                          menuPlacement="top"
                          menuPortalTarget={document.body}
                          isClearable
                          isValidNewOption={() => false}
                        />
                      </div>
                    </h5>
                  </div>
                </div>

                <div className="pt-4 pb-1">
                  <div className="pt-1">
                    <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                      Date Submitted:
                      <div className="col-span-2">
                        <DatePicker
                          ref={datePickerRef}
                          onFocus={() => setDatePickerActive(true)}
                          onBlur={() => setDatePickerActive(false)}
                          selected={startDate}
                          placeholderText="Date Submitted"
                          onChange={onChange}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          monthsShown={2}
                          calendarClassName="lg:flex bg-white"
                          weekDayClassName=""
                          className={`p-2 w-full bg-gray-200 rounded-2xl`}
                        />
                      </div>
                    </h5>
                  </div>
                </div>

                <div className="pt-2">
                  <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                    Priority:
                    <select
                      onChange={(e) => {
                        setPriorityFilter(e.target.value);
                      }}
                      className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                      name="priority"
                      id="priority"
                    >
                      <option value="" disabled selected>
                        {priorityFilter === "" ? "All" : priorityFilter}
                      </option>
                      {[1, 2, 3].map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  </h5>
                </div>

                {(localStorage.getItem("userType") === "OWNER" ||
                  !restrictedUsers.has(localStorage.getItem("email"))) && (
                  <div className="pt-4">
                    <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                      <p
                        className="text-blue-500 underline cursor-pointer"
                        onClick={() => setShowAssigneeSummaryModal(true)}
                      >
                        Assignee:
                      </p>

                      <select
                        onChange={(e) => {
                          let value = e?.target?.value;

                          if (value === "Unassigned") {
                            setAssignee(value);
                          } else {
                            let person = people.find((p) => value === p.id)
                            setAssignee(person);
                          }
                          // setAssignee(
                          //   e.target.value !== "" ? e.target.value : ""
                          // );
                        }}
                        className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                        value={`${typeof assignee === "object" ? assignee.id : assignee}`}
                      >
                        <option value="">Select All</option>
                        <option value="Unassigned">Unassigned</option>
                        {people.map((p, i) => {
                          let person = `${p.firstName} ${p.lastName}`;
                          return <option value={p.id}>{person}</option>;
                        })}
                      </select>
                    </h5>
                  </div>
                )}

                <div className="pt-4 pb-1">
                  <div className="pt-1">
                    <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                      Date Received:
                      <div className="col-span-2">
                        <DatePicker
                          ref={datePickerRef}
                          onFocus={() => setDatePickerActive(true)}
                          onBlur={() => setDatePickerActive(false)}
                          selected={startDateReceived}
                          placeholderText="Date Received"
                          onChange={onChangeDateReceived}
                          startDate={startDateReceived}
                          endDate={endDateReceived}
                          selectsRange
                          monthsShown={2}
                          calendarClassName="lg:flex bg-white"
                          weekDayClassName=""
                          className={`p-2 bg-transparent w-full bg-gray-200 p-1 rounded-2xl`}
                        />
                      </div>
                    </h5>
                  </div>
                </div>

                <div className="pt-4">
                  <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                    <p>Task Register: </p>
                    <select
                      onChange={(e) => {
                        setTaskRegisterFilter(e.target.value);
                      }}
                      className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                      value={taskRegisterFilter}
                    >
                      <option value="">- - - Please Select - - -</option>
                      {presetsLibrary?.map((p, i) => {
                        return <option value={p.id}>{p.label}</option>;
                      })}
                    </select>
                  </h5>
                </div>
                <div className="pt-4">
                  <h5 className="grid items-center grid-cols-3 gap-4 font-medium">
                    {localStorage.getItem("userType") === "OWNER" ? (
                      <>Has Attachments:</>
                    ) : (
                      <>Has Attachments:</>
                    )}
                    <input
                      aria-describedby="has-attachment-checkbox"
                      type="checkbox"
                      checked={searchHasAttachments}
                      onChange={(e) =>
                        setSearchHasAttachments(e.target.checked)
                      }
                      className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </h5>
                </div>

                <div className="pt-8">
                  <button
                    className="float-right px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
                    onClick={(e) => {
                      fetchDataWithFilterBy("all");
                      setFiltersModal(false);
                    }}
                  >
                    Apply Filters
                  </button>
                  <button
                    className="float-right px-4 py-2 mr-1 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                    onClick={() => setClearFilter(true)}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}

            {searching ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setQuery("");
                  setSearching(false);
                  fetchTaskListData();
                }}
                className="flex items-center p-2 text-gray-400 bg-gray-300 rounded-full"
              >
                <AiOutlineClose />
              </button>
            ) : (
              <></>
              // <button
              //   type="button"
              //   onClick={(e) => createTask(e)}
              //   className="font-medium save-btn"
              // >
              //   Create Task
              // </button>
            )}

            <div className="items-center justify-center hidden space-x-4">
              <div className="flex p-4 space-x-2 bg-gray-200 rounded-2xl">
                <DatePicker
                  ref={datePickerRef}
                  onFocus={() => setDatePickerActive(true)}
                  onBlur={() => setDatePickerActive(false)}
                  selected={startDate}
                  placeholderText="Today"
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}
                  calendarClassName="lg:flex bg-white"
                  weekDayClassName=""
                  className={`bg-transparent ${endDate ? "visible" : "hidden"}`}
                />

                <div
                  onClick={() => {
                    setEndDate(new Date());
                  }}
                  className={`${endDate ? "hidden" : "flex"}`}
                >
                  <p className="mr-2">Today</p>
                  <BsCalendarCheck />
                </div>

                <select
                  onChange={(e) => {
                    console.log("Value", e.target.value);
                    setPriorityFilter(e.target.value);
                  }}
                  className="bg-transparent"
                  name="priority"
                  id="priority"
                >
                  <option value="" disabled selected>
                    All
                  </option>
                  {[1, 2, 3].map((p) => (
                    <option value={p}>{p}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>
          {/* end of searchbar */}

          {loading ? (
            <>
              <div className="flex p-3">
                <div className="ml-1">
                  <SkeletonTheme width={"60px"} height={"30px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </div>
                <div className="ml-1">
                  <SkeletonTheme width={"140px"} height={"30px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </div>
                <div className="ml-1">
                  <SkeletonTheme width={"110px"} height={"30px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </div>
                <div className="ml-1">
                  <SkeletonTheme width={"55px"} height={"30px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </div>
              </div>
              <div className="mt-4">
                {[...Array(6).keys()].map((data, index) => {
                  return (
                    <div className="mb-2">
                      <div className="flex justify-between ">
                        <div className="mx-3">
                          <SkeletonTheme width={"80px"} height={"20px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="flex p-1 mr-1">
                          <div className="mx-1">
                            <SkeletonTheme width={"30px"} height={"30px"}>
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          </div>
                          <div className="mx-1">
                            <SkeletonTheme width={"30px"} height={"30px"}>
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          </div>
                        </div>
                      </div>
                      <div className="mx-5">
                        <SkeletonTheme width={"200px"} height={"15px"}>
                          <Skeleton count={5} />
                        </SkeletonTheme>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              {query === "" ? (
                <>
                  <select
                    value={activeTabIndex}
                    onChange={(event) => {
                      const indexValue = parseInt(event.target.value);
                      setStatus(statusMap[indexValue]);
                      setActiveTabIndex(indexValue);
                    }}
                    className="w-full p-2 mt-3 bg-transparent border rounded-lg"
                  >
                    <option value={0}>All Tasks ({allCount})</option>
                    <option value={1}>
                      Outstanding Tasks ({outstandingCount})
                    </option>
                    <option value={2}>
                      Completed Tasks ({completedCount})
                    </option>
                    <option value={3}>Archived Tasks ({archivedCount})</option>
                  </select>
                  {/* <Tab.Group
										selectedIndex={activeTabIndex}
										onChange={(index) => setActiveTabIndex(index)}
										className="top-0 flex w-full pl-3 mt-3 overflow-x-scroll bg-white "
										style={{ position: "", top: "32px", zIndex: 18 }}
									>
										<Tab.List className="flex w-full space-x-2">
											<Tab>
												{({ selected }) => (
													// Use the `selected` state to conditionally style the selected tab.
													<div
														onClick={(e) => {
															setStatus("");
															//fetchDataWithFilterBy("");
														}}
														className={`flex items-center ml-2 ${
															selected
																? "text-gray-900 border-b border-black p-2 font-semibold"
																: "text-gray-500"
														}`}
													>
														<p
															className={
																!selected
																	? "mr-2 uppercase text-xs font-bold"
																	: "mr-2 uppercase text-xs font-bold"
															}
														>
															All
														</p>
														<span className="px-1 py-0 text-xs text-center border-2 rounded-full bg-gray-50">
															{allCount}
														</span>
													</div>
												)}
											</Tab>
											<Tab>
												{({ selected }) => (
													// Use the `selected` state to conditionally style the selected tab.
													<div
														onClick={(e) => {
															setStatus("Outstanding");
															//fetchDataWithFilterBy("");
														}}
														className={`flex items-center ml-2 ${
															selected
																? "text-gray-900 border-b border-black p-2 font-semibold"
																: "text-gray-500"
														}`}
													>
														<p
															className={
																"mr-2 uppercase text-xs font-bold"
															}
														>
															{" "}
															Outstanding
														</p>
														<span className="px-1 py-0 text-xs text-center border-2 rounded-full bg-gray-50">
															{outstandingCount}
														</span>
													</div>
												)}
											</Tab>
											<Tab>
												{({ selected }) => (
													// Use the `selected` state to conditionally style the selected tab.
													<button
														onClick={(e) => {
															setStatus("Completed");
															//fetchDataWithFilterBy("");
														}}
														className={`flex items-center ml-2 ${
															selected
																? "text-gray-900 border-b border-black p-2 font-semibold"
																: "text-gray-500"
														}`}
													>
														<p
															className={
																"mr-2 uppercase text-xs font-bold"
															}
														>
															{" "}
															Completed
														</p>
														<span className="px-1 py-0 text-xs text-center border-2 rounded-full bg-gray-50">
															{completedCount}
														</span>
													</button>
												)}
											</Tab>
											<Tab>
												{({ selected }) => (
													// Use the `selected` state to conditionally style the selected tab.
													<button
														onClick={(e) => {
															setStatus("Archived");
															//fetchDataWithFilterBy("");
														}}
														className={`flex items-center ml-2 ${
															selected
																? "text-gray-900 border-b border-black p-2 font-semibold"
																: "text-gray-500"
														}`}
													>
														<p
															className={
																!selected
																	? "mx-2 uppercase text-xs"
																	: "mx-2 uppercase text-xs font-bold"
															}
														>
															{" "}
															<FiArchive />
														</p>
														<span className="px-1 py-0 text-xs text-center border-2 rounded-full bg-gray-50 min-w-5">
															{archivedCount}
														</span>
													</button>
												)}
											</Tab>
										</Tab.List>
									</Tab.Group> */}
                  <div className="h-0 mt-12 border-l rounded-lg test123">
                    {activeTabIndex === 0 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={allTasks}
                        setData={setaAllTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //MMA1060 Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : activeTabIndex === 1 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={outstandingTasks}
                        setData={setOutstandingTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //MMA1060 Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : activeTabIndex === 2 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={completedTasks}
                        setData={setCompletedTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //Jed Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : activeTabIndex === 3 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={archivedTasks}
                        setData={setArchivedTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //Jed Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchArchivedTasks={fetchArchivedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="grid w-full grid-cols-2 gap-1"> */}
                  <Tab.Group
                    selectedIndex={activeTabIndex}
                    onChange={(index) => setActiveTabIndex(index)}
                    className="sticky top-0 flex w-full pl-3 mt-3 overflow-x-scroll bg-white "
                    style={{ position: "sticky", top: "32px", zIndex: 18 }}
                  >
                    <Tab.List className="flex w-full space-x-2">
                      <Tab>
                        {({ selected }) => (
                          <>
                            <div
                              onClick={(e) => {
                                setStatus("");
                                //fetchDataWithFilterBy("");
                              }}
                              className={`flex items-center ${
                                selected
                                  ? "text-gray-900 border-b border-black p-2 font-semibold"
                                  : "text-gray-500"
                              }`}
                            >
                              <p
                                className={
                                  !selected ? "mr-2" : "mr-2 font-bold"
                                }
                              >
                                All
                              </p>
                              <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                                {allCount}
                              </p>
                            </div>
                          </>
                        )}
                      </Tab>
                      <Tab>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <div
                            onClick={(e) => {
                              setStatus("Outstanding");
                              //fetchDataWithFilterBy("");
                            }}
                            className={`flex items-center ${
                              selected
                                ? "text-gray-900 border-b border-black p-2 font-semibold"
                                : "text-gray-500"
                            }`}
                          >
                            <p
                              className={!selected ? "mr-2" : "mr-2 font-bold"}
                            >
                              {" "}
                              Outstanding
                            </p>
                            <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                              {outstandingCount}
                            </p>
                          </div>
                        )}
                      </Tab>
                      <Tab>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            onClick={(e) => {
                              setStatus("Completed");
                              //fetchDataWithFilterBy("");
                            }}
                            className={`flex items-center ${
                              selected
                                ? "text-gray-900 border-b border-black p-2 font-semibold"
                                : "text-gray-500"
                            }`}
                          >
                            <p
                              className={!selected ? "mr-2" : "mr-2 font-bold"}
                            >
                              {" "}
                              Completed
                            </p>
                            <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                              {completedCount}
                            </p>
                          </button>
                        )}
                      </Tab>
                      <Tab>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            onClick={(e) => {
                              setStatus("Archived");
                              //fetchDataWithFilterBy("");
                            }}
                            className={`flex items-center ${
                              selected
                                ? "text-gray-900 border-b border-black p-2 font-semibold"
                                : "text-gray-500"
                            }`}
                          >
                            <p
                              className={!selected ? "mr-2" : "mr-2 font-bold"}
                            >
                              {" "}
                              Archived
                            </p>
                            <p className="px-2 font-normal text-gray-900 bg-gray-300 rounded-full">
                              {archivedCount}
                            </p>
                          </button>
                        )}
                      </Tab>
                    </Tab.List>
                  </Tab.Group>
                  <div className="h-0 mt-12 border-l rounded-lg test123">
                    {activeTabIndex === 0 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={allTasks}
                        setData={setaAllTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //MMA1060 Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : activeTabIndex === 1 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={outstandingTasks}
                        setData={setOutstandingTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //MMA1060 Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : activeTabIndex === 2 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={completedTasks}
                        setData={setCompletedTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //Jed Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : activeTabIndex === 3 ? (
                      <DataTable
                        setemailPreviewState={setemailPreviewState}
                        setshowEmailPreview={setshowEmailPreview}
                        toggleSidebar={toggleSidebar}
                        columns={filteredColumns}
                        data={archivedTasks}
                        setData={setArchivedTasks}
                        setAllCount={setAllCount}
                        setOutstandingCount={setOutstandingCount}
                        setCompletedCount={setCompletedCount}
                        allCount={allCount}
                        outstandingCount={outstandingCount}
                        completedCount={completedCount}
                        clientMatters={clientMatters}
                        people={people}
                        fetchTaskListData={fetchTaskListData}
                        fetchCompanyClientMatters={fetchCompanyClientMatters} //Jed Change
                        fetchOutsandingTasks={fetchOutsandingTasks}
                        fetchCompletedTasks={fetchCompletedTasks}
                        fetchArchivedTasks={fetchArchivedTasks}
                        fetchDataWithFilterBy={fetchDataWithFilterBy}
                        activeTabIndex={activeTabIndex}
                        loading={loading}
                        setLoading={setLoading}
                        taskRegisters={presetsLibrary}
                        preset={preset}
                        lastUpdatedPriorityValue={lastUpdatedPriorityValue}
                        updatePriorityValue={updatePriorityValue}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedDivs={selectedDivs}
                        setSelectedDivs={setSelectedDivs}
                        setTableHeader={setTableHeader}
                        archivedCount={archivedCount}
                        setArchivedCount={setArchivedCount}
                        setaAllTasks={setaAllTasks}
                        setOutstandingTasks={setOutstandingTasks}
                        setCompletedTasks={setCompletedTasks}
                        setArchivedTasks={setArchivedTasks}
                        allTasks={allTasks}
                        outstandingTasks={outstandingTasks}
                        completedTasks={completedTasks}
                        archivedTasks={archivedTasks}
                      />
                    ) : null}
                  </div>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    className="sticky flex items-center justify-center hidden p-1 mt-1 space-x-1"
                    style={{ position: "sticky", top: "-8px", zIndex: 20 }}
                  >
                    <div className="relative flex flex-wrap items-center flex-grow bg-white border rounded-md h-13">
                      <div className="flex justify-center w-10 pl-2 -mr-px">
                        <span className="flex items-center px-3 text-2xl leading-normal text-gray-600 bg-white border-0 rounded rounded-r-none">
                          <AiOutlineSearch />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="relative self-center flex-1 flex-grow flex-shrink w-px h-10 px-3 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light"
                        onChange={(e) => {
                          e.preventDefault();
                          setQuery(e.target.value);
                        }}
                        value={query}
                        placeholder="Search"
                      />
                      <span
                        onClick={() => toggleFiltersModal()}
                        className="h-full p-3 px-3 transition duration-500 ease-in-out bg-gray-600 rounded-md cursor-pointer active:bg-gray-400"
                      >
                        <IoIosOptions className="text-gray-50" />
                      </span>
                    </div>

                    {filtersModal ? (
                      <>
                        <div className="absolute z-50 flex flex-col w-full max-h-screen p-3 mt-3 bg-white border-gray-300 rounded-md shadow-2xl top-11">
                          <div className="flex flex-row">
                            <h4 className="font-bold">Filter By:</h4>
                            <div
                              className="top-0 right-0 pb-3 pr-2 ml-auto cursor-pointer hover:text-red-500"
                              onClick={() => setFiltersModal(false)}
                            >
                              Close
                            </div>
                          </div>

                          <div className="pt-4 pb-1">
                            <div className="pt-1">
                              <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                                Date Submitted:
                                <div className="col-span-2">
                                  <DatePicker
                                    ref={datePickerRef}
                                    onFocus={() => setDatePickerActive(true)}
                                    onBlur={() => setDatePickerActive(false)}
                                    selected={startDate}
                                    placeholderText="Date Submitted"
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    monthsShown={2}
                                    calendarClassName="lg:flex bg-white"
                                    weekDayClassName=""
                                    className={`p-2 bg-transparent w-full bg-gray-200 rounded-2xl`}
                                  />
                                </div>
                              </h5>
                            </div>
                          </div>

                          <div className="pt-2">
                            <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                              Priority:
                              <select
                                onChange={(e) => {
                                  setPriorityFilter(e.target.value);
                                }}
                                className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                                name="priority"
                                id="priority"
                              >
                                <option value="" disabled selected>
                                  All
                                </option>
                                {[1, 2, 3].map((p) => (
                                  <option value={p}>{p}</option>
                                ))}
                              </select>
                            </h5>
                          </div>

                          {(localStorage.getItem("userType") === "OWNER" ||
                            !restrictedUsers.has(
                              localStorage.getItem("email")
                            )) && (
                            <div className="pt-4">
                              <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                                <p
                                  className="text-blue-500 underline cursor-pointer"
                                  onClick={() =>
                                    setShowAssigneeSummaryModal(true)
                                  }
                                >
                                  Assignee:
                                </p>

                                <select
                                  onChange={(e) => {
                                    let value = e?.target?.value;

                                    if (value === "Unassigned") {
                                      setAssignee(value);
                                    } else {
                                      let person = people.find((p) => value === p.id)
                                      setAssignee(person);
                                    }
                                    // setAssignee(
                                    //   e.target.value !== "" ? e.target.value : ""
                                    // );
                                  }}
                                  className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                                  value={`${typeof assignee === "object" ? assignee.id : assignee}`}
                                  >
                                  <option value="">Select All</option>
                                  <option value="Unassigned">Unassigned</option>
                                  {people.map((p, i) => {
                                    let person = `${p.firstName} ${p.lastName}`;
                                    return (
                                      <option value={p.id}>{person}</option>
                                    );
                                  })}
                                </select>
                              </h5>
                            </div>
                          )}

                          <div className="pt-4 pb-1">
                            <div className="pt-1">
                              <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                                Date Received:
                                <div className="col-span-2">
                                  <DatePicker
                                    ref={datePickerRef}
                                    onFocus={() => setDatePickerActive(true)}
                                    onBlur={() => setDatePickerActive(false)}
                                    selected={startDateReceived}
                                    placeholderText="Date Received"
                                    onChange={onChangeDateReceived}
                                    startDate={startDateReceived}
                                    endDate={endDateReceived}
                                    selectsRange
                                    monthsShown={2}
                                    calendarClassName="lg:flex bg-white"
                                    weekDayClassName=""
                                    className={`p-2 bg-transparent w-full bg-gray-200 p-1 rounded-2xl`}
                                  />
                                </div>
                              </h5>
                            </div>
                          </div>

                          <div className="pt-1">
                            <h5 className="grid items-center grid-cols-3 gap-4 font-light">
                              Status:
                              <select
                                onChange={(e) => {
                                  setStatus(
                                    e.target.value !== "" ? e.target.value : ""
                                  );
                                }}
                                className="w-full col-span-2 p-2 bg-transparent border rounded-lg"
                                value={status}
                                disabled={disabledStatus ? true : false}
                              >
                                <option value="">All</option>
                                <option value="Outstanding">Outstanding</option>
                                <option value="Completed">Completed</option>
                                <option value="Follow Up">Follow Up</option>
                                <option value="Archived">Archived</option>
                              </select>
                            </h5>
                          </div>

                          <div className="pt-8">
                            <button
                              className="float-right px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
                              onClick={(e) => {
                                fetchDataWithFilterBy("all");
                                setFiltersModal(false);
                              }}
                            >
                              Apply Filters
                            </button>
                            <button
                              className="float-right px-4 py-2 mr-1 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                              onClick={() => setClearFilter(true)}
                            >
                              Clear Filters
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {searching ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setQuery("");
                          setSearching(false);
                          fetchTaskListData();
                        }}
                        className="flex items-center p-2 text-gray-400 bg-gray-300 rounded-full"
                      >
                        <AiOutlineClose />
                      </button>
                    ) : selectedRows.length > 0 ? (
                      <button
                        type="button"
                        onClick={() => {
                          // Handle the action for "Copy to Clipboard" here
                        }}
                        className="font-medium save-btn"
                      >
                        Copy Tasks to Clipboard
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={(e) => createTask(e)}
                        className="font-medium save-btn"
                      >
                        Create Task
                      </button>
                    )}

                    <div className="flex items-center justify-center hidden space-x-4">
                      <div className="flex p-4 space-x-2 bg-gray-200 rounded-2xl">
                        <DatePicker
                          ref={datePickerRef}
                          onFocus={() => setDatePickerActive(true)}
                          onBlur={() => setDatePickerActive(false)}
                          selected={startDate}
                          placeholderText="Today"
                          onChange={onChange}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          monthsShown={2}
                          calendarClassName="lg:flex bg-white"
                          weekDayClassName=""
                          className={`bg-transparent ${
                            endDate ? "visible" : "hidden"
                          }`}
                        />

                        <div
                          onClick={() => {
                            setEndDate(new Date());
                          }}
                          className={`${endDate ? "hidden" : "flex"}`}
                        >
                          <p className="mr-2">Today</p>
                          <BsCalendarCheck />
                        </div>

                        <select
                          onChange={(e) => {
                            console.log("Value", e.target.value);
                            setPriorityFilter(e.target.value);
                          }}
                          className="bg-transparent"
                          name="priority"
                          id="priority"
                        >
                          <option value="" disabled selected>
                            All
                          </option>
                          {[1, 2, 3].map((p) => (
                            <option value={p}>{p}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </form>
                  {/* </div> */}
                  {/* </div> */}

                  {/* {searchResults.length > 0 ? (
										<div className="h-0 mt-12 border-l rounded-lg ">
											<DataTable
												setemailPreviewState={setemailPreviewState}
												setshowEmailPreview={setshowEmailPreview}
												toggleSidebar={toggleSidebar}
												columns={filteredColumns}
												data={searchResults}
												clientMatters={clientMatters}
												people={people}
												fetchCompanyClientMatters={fetchCompanyClientMatters} //MMA1060 Change
												taskRegisters={presetsLibrary}
												preset={preset}
												lastUpdatedPriorityValue={lastUpdatedPriorityValue}
												updatePriorityValue={updatePriorityValue}
												selectedRows={selectedRows}
												setSelectedRows={setSelectedRows}
												selectedDivs={selectedDivs}
												setSelectedDivs={setSelectedDivs}
												setTableHeader={setTableHeader}
											/>
										</div>
									) : (
										<div className="mx-1 mt-4 bg-gray-200 rounded-md">
											<BlankStateMobile
												header={"No matching records found."}
												content={
													"Try adjusting your filters or keywords to find a match."
												}
												svg={NoData}
											/>
										</div>
									)} */}
                </>
              )}
            </>
          )}
        </>
      )}

      {showAssigneeSummaryModal && (
        <AssigneeTasksCountModal
          companyId={localStorage.getItem("companyId")}
          people={people}
          handleAssigneeSummaryClose={handleAssigneeSummaryClose}
        />
      )}

      {showPrioritySummaryModal && (
        <PriorityTasksCountModal
          companyId={localStorage.getItem("companyId")}
          handlePrioritySummaryClose={handlePrioritySummaryClose}
        />
      )}

      {showEmailPreview && (
        <EmailPreviewModal
          close={setshowEmailPreview}
          emailPreviewState={emailPreviewState}
          setemailPreviewState={setemailPreviewState}
        />
      )}

      {showPreviewTasks && (
        <PreviewTasks
          close={setShowPreviewTasks}
          selectedRows={selectedRows}
          screenshot={screenshot}
        />
      )}
    </>
  );
};

export default TaskList;
