import React from "react";
import { useEffect, useState } from "react";

function Switch({
  default_access,
  user_access_id,
  user_access,
  row_index,
  user_type,
  updateTaggedPages,
  switchIsClicked,
  disabledSwitch,
  setDisabledSwitch,
}) {
  const pageIsFound = user_access
    .map((p) => p.id)
    .find((page) => page === default_access.id && page);

  const [isChecked, setisChecked] = useState(pageIsFound !== undefined);
  const [accessPages, setAccessPages] = useState(user_access);

  const handleChange = (s) => {
    const accessPageCopy = user_access;
    var newAccessPageSet;
    if (isChecked) {
      newAccessPageSet = accessPageCopy.filter(function (value) {
        return value.id !== default_access.id;
      });
    } else {
      newAccessPageSet = [
        ...accessPageCopy,
        {
          id: default_access.id,
          features: default_access.features.map((d) => {
            return { id: d.id };
          }),
        },
      ];
    }
    setAccessPages(newAccessPageSet);
    setisChecked((prev) => !prev);
    setDisabledSwitch(true);
    switchIsClicked(!isChecked, default_access.id);
  };

  // const handleClick = (is_checked, page_id) => {
  //   switchIsClicked(is_checked, page_id);
  // };

  useEffect(() => {
    if (accessPages !== user_access)
      updateTaggedPages(user_access_id, accessPages, user_type);
  }, [accessPages]);

  return (
    <>
      <div className="relative inline-block w-8 mr-2 align-middle select-none transition duration-200 ease-in">
        <input
          type="checkbox"
          name={row_index}
          id={row_index}
          className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer disabled:opacity-75"
          checked={isChecked}
          onChange={handleChange.bind(this)}
          disabled={disabledSwitch}
        />
        <label className="toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer"></label>
      </div>
    </>
  );
}

export default Switch;
