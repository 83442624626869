import React, { useEffect, useRef } from "react";
import anime from "animejs";
import { CgCheck, CgClose, CgCopy, CgBell } from "react-icons/cg";

const MobileNotificationModal = ({ close, confirm, cancel, title, body }) => {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);

  const CopyIcon = () => {
    return (
      <div className="flex w-10 h-10 bg-gray-800 rounded-full justify-center items-center">
        <CgCopy size={25} className="text-white" />
      </div>
    );
  };

  const Warning = () => {
    return (
      <div className="flex w-10 h-10 bg-gray-800 rounded-full justify-center items-center">
        <CgBell size={25} className="text-white" />
      </div>
    )
  }

  useEffect(() => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);
  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="outline-none focus:outline-none fixed inset-0  z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-60 opacity-0"
      >
        <div
          ref={modalContent}
          className="no-scrollbar flex h-auto max-h-96 w-5/6 scale-90 flex-col overflow-y-scroll rounded-lg bg-white p-4 opacity-0"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="block m-auto mb-5">
            {
              title === "Warning" ? <Warning /> : <CopyIcon/>
            }
          </div>
          <div className="block text-center pb-5 text-lg font-bold text-gray-900">
            {title}
          </div>
          <div className="pb-5 text-center">
            <p>{body}</p>
          </div>
          <div className="w-full flex flex-row justify-center gap-1">
            {
              title === "Warning" ? null : <button
              onClick={() => {
                close();
                cancel();
              }}
              className="px-2 w-32 py-1 flex flex-row font-medium text-md justify-center items-center bg-white rounded-md gap-2 border border-gray-300 hover:bg-gray-700 hover:text-white"
            >
              Cancel <CgClose />
            </button>
            }
            <button
              onClick={() => {
                confirm();
                close();
              }}
              className="px-2 w-32 py-1 flex flex-row font-medium text-md justify-center
                        items-center text-white bg-green-500 rounded-md gap-2 hover:bg-white
                        hover:text-green-500 border border-green-500"
            >
              Confirm <CgCheck />
            </button>
          </div>
        </div>
      </div>

      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

export default MobileNotificationModal;
