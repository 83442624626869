import React, { useState, useEffect, useRef } from "react";
import { Base64 } from "js-base64";
import "../../assets/styles/BackgroundPage.css";
import "../../assets/styles/Mobile.css";
import "../../assets/styles/BlankState.css";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import Button from "../../shared/Button";
import { MdArrowBackIos } from "react-icons/md";

var moment = require("moment");

function MobileEmailPreview({
  show,
  setShow,
  snippetId,
  gmailMessageId,
  preview,
  from,
  dateEmail,
  subject,
  to,
  cc,
  downloadLink,
  setDownloadLink,
  selectedFile,
  setSelectedFile,
  previewAndDownloadFile,
  previewAndDownloadPDF,
  handleClose,
}) {
  const [iframeLoad, setIframeLoad] = useState(true);

  return (
    <>
      <div>
        <div
          className="justify-center items-center flex-none overflow-x-hidden overflow-y-hidden max-h-screen fixed inset-0 outline-none focus:outline-none"
          style={{ zIndex: 70 }}
        >
          <div
            className="w-full mx-auto max-w-md h-screen max-h-screen pt-16"
            onClick={(e) => handleClose()}
          >
            <div
              className="items-center max-h-full min-h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Heading Div */}
              <div className="w-full p-4" id="emailHeader">
                {/* Button Divs */}
                <div className="flex justify-between">
                  <Button
                    className="border-gray-300 mb-2"
                    onClick={(e) => handleClose()}
                  >
                    <MdArrowBackIos className="text-xl float-left" />
                    Back
                  </Button>
                  {subject || iframeLoad === false ? (
                    <>
                      {selectedFile.s3ObjectKey === null &&
                      selectedFile.isGmailPDF &&
                      selectedFile.isGmailAttachment ? (
                        <Button
                          onClick={(e) =>
                            previewAndDownloadPDF(
                              selectedFile.id,
                              selectedFile.gmailMessageId,
                              selectedFile.name
                            )
                          }
                          variant="primary-l"
                          className="bg-blue-500 hover:bg-blue-700 text-white"
                        >
                          Download Email PDF
                        </Button>
                      ) : (
                        <Button
                          onClick={(e) =>
                            previewAndDownloadFile(selectedFile.id)
                          }
                          variant="primary-l"
                          className="bg-blue-500 hover:bg-blue-700 text-white"
                        >
                          Preview on new tab
                        </Button>
                      )}
                    </>
                  ) : (
                    <SkeletonTheme height={"32px"} width={"150px"}>
                      <Skeleton count={1} />
                    </SkeletonTheme>
                  )}
                </div>
                {selectedFile.isGmailPDF ? (
                  <>
                    <div>
                      {subject && subject !== undefined ? (
                        <>
                          <p>{`From: ${from}`}</p>
                          <p>
                            {`Date: ${moment(dateEmail).format(
                              "DD MMM YYYY, hh:mm A"
                            )}`}
                          </p>
                          <p className="font-bold">{`Subject: ${subject}`}</p>
                          <p>{`To: ${to}`}</p>
                          {cc && <p>{`CC: ${cc}`}</p>}
                        </>
                      ) : (
                        <Skeleton count={4} />
                      )}
                    </div>
                  </>
                ) : null}
              </div>
              {/* Email Body */}
              {selectedFile.isGmailPDF ? (
                <>
                  <div className="overflow-x-auto test123 overflow-y-auto w-full">
                    {subject && subject !== undefined ? (
                      <p
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: preview,
                        }}
                      ></p>
                    ) : (
                      <Skeleton count={25} />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="overflow-x-auto overflow-y-auto w-full h-full">
                    {iframeLoad && (
                      <SkeletonTheme height={"500px"} width={"100%"}>
                        <p>
                          <Skeleton count={1} />
                        </p>
                      </SkeletonTheme>
                    )}
                    {downloadLink && (
                      <iframe
                        title={selectedFile.name}
                        src={downloadLink}
                        style={{
                          minHeight: "500px",
                        }}
                        className="w-full min-h-full"
                        onLoad={() => setIframeLoad(false)}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="opacity-25 fixed inset-0 bg-black"
          style={{ zIndex: 60 }}
          onClick={() => setShow(false)}
        ></div>
      </div>
    </>
  );
}

export default MobileEmailPreview;
