const homeOwnerTileList = [
  "Builder Details",
  "Contract Details",
  "Progress Photos",
  "Project Location",
  "Progress of work",
  "Invoices",
  "Variations",
  "Contracts",
  "Site Diary",
  "Task List",
  "Provisional Sum",
  // "Budget",
  "Prime Cost",
  "Development Approvals",
  "Tenders",
  "Building Permits",
  "Warranties and Certifications",
  // "Work Safety Compliance",
  "Defects List",
  "Insurance",
  "Certificates",
  "Email Integration",
];

const ownerTileList = [
  "Builder Details",
  // "Contract Details",
  "Progress Photos",
  "Project Location",
  "Progress of work",
  "Invoices",
  "Variations",
  "Contracts",
  "Site Diary",
  "Task List",
  "Budget",
  "Provisional Sum",
  "Prime Cost",
  "Development Approvals",
  "Tenders",
  "Building Permits",
  "Warranties and Certifications",
  "Work Safety Compliance",
  "Defects List",
  "Insurance",
  "Certificates",
  "Email Integration",
];

const architectSuperintendentTileList = [
  "Builder Details",
  "Contract Details",
  "Progress Photos",
  "Project Location",
  "Progress of work",
  "Invoices",
  "Variations",
  "Contracts",
  "Site Diary",
  "Task List",
  "Provisional Sum",
  // "Budget",
  "Prime Cost",
  "Development Approvals",
  "Tenders",
  "Building Permits",
  "Warranties and Certifications",
  "Work Safety Compliance",
  "Defects List",
  "Insurance",
  "Certificates",
  "Email Integration",
];

export const getBriefListPerPersona = (activeBriefs) => {
  let hbaUserCategories = [
    "HBA_HOMEOWNER",
    "OWNERBUILDER",
    "HBA_ARCHITECT_SUPERINTENDENT",
  ];

  //MMA 3144
  // Automatically set to HOMEOWNERBUILDER for old portal that dont have userCategory in localStorage
  let userPersonaType =
    localStorage.getItem("userCategory") &&
    localStorage.getItem("userCategory") !== "null" &&
    localStorage.getItem("userCategory") !== null
      ? localStorage.getItem("userCategory")
      : hbaUserCategories[0];

  console.group("getBriefListPerPersona()");
  console.log("Automatically set value", localStorage.getItem("userCategory"));
  console.log("Filter Briefs for: ", userPersonaType);
  console.groupEnd();
  switch (userPersonaType) {
    case "HBA_HOMEOWNER": {
      return activeBriefs.filter((item) => homeOwnerTileList.includes(item));
    }
    case "OWNERBUILDER": {
      return activeBriefs.filter((item) => ownerTileList.includes(item));
    }
    case "HBA_ARCHITECT_SUPERINTENDENT": {
      return activeBriefs.filter((item) =>
        architectSuperintendentTileList.includes(item)
      );
    }
    default: {
      break;
    }
  }
};

export const getBriefListPerPersonaDropDown = (options) => {
  let hbaUserCategories = [
    "HBA_HOMEOWNER",
    "OWNERBUILDER",
    "HBA_ARCHITECT_SUPERINTENDENT",
  ];

  //MMA 3144
  // Automatically set to HOMEOWNERBUILDER for old portal that dont have userCategory in localStorage
  let userPersonaType =
    localStorage.getItem("userCategory") &&
    localStorage.getItem("userCategory") !== "null" &&
    localStorage.getItem("userCategory") !== null
      ? localStorage.getItem("userCategory")
      : hbaUserCategories[0];

  console.group("getBriefListPerPersona()");
  console.log("Automatically set value", localStorage.getItem("userCategory"));
  console.log("Filter Briefs for: ", userPersonaType);
  console.groupEnd();
  switch (userPersonaType) {
    case "HBA_HOMEOWNER": {
      return options.filter((item) => homeOwnerTileList.includes(item.label));
    }
    case "OWNERBUILDER": {
      return options.filter((item) => ownerTileList.includes(item.label));
    }
    case "HBA_ARCHITECT_SUPERINTENDENT": {
      return options.filter((item) =>
        architectSuperintendentTileList.includes(item.label)
      );
    }
    default: {
      break;
    }
  }
};
