import "../form-builder/ScrollBar.css";
import { useRef, useState, useEffect, useContext } from "react";
import StoreProvider from "./reducers/StoreProvider";
import { FormAnswerContext } from "./reducers/StoreProvider";
import { SinglePageArea, FormCard } from "../form-shared-components/SharedPageArea";
import { 
  mutationActionHandler,
  mSubmitForm,
  mFetchSubmissions
} from "./graphql/formAPI";

export default function FormQuestionnaire({ key }) {

  const handleFormLink = () => {
    console.log("Navigate to ", key);
  };

  return (
    <StoreProvider>
      <div
        className="min-h-screen bg-neutral-100"
      >
        <PageManager />
      </div>
    </StoreProvider>
  );
}

function PageManager() {
  const { state, dispatch } = useContext(FormAnswerContext)
  const { pages, answers } = state?.formData

  const pageSwitcher = (currentPage) => {
    if(currentPage === 'start'){
      return <StartPage />
    }else if(currentPage === 'end'){
      return <EndPage />
    }else{
      
      return (
        pages.length ?
        <SinglePageArea 
          pageContent={pages[currentPage - 1]} 
          stateContext={{state, dispatch}} 
        /> : null
      )
    }
  }

  return (
    <div className="w-full py-14 flex flex-col items-center gap-4">
      {pageSwitcher(state.appState.currentPage)}
      <ActionButtons />
    </div>
  );
}

function ActionButtons() {
  const { state, dispatch } = useContext(FormAnswerContext)
  const { formDetails, pages, formAnswers } = state?.formData

  const handleSwitchPage = (page) =>{
    dispatch({
      type: 'SWITCH_PAGE',
      payload: page
    })
  }

  const handleFormSubmit = () => {
    mutationActionHandler(
      {
        query: mSubmitForm,
        variables: {
          companyId: formDetails?.companyId,
          formId: formDetails?.id,
          data: JSON.stringify(formAnswers)
        }
      },
      (response)=>{
        console.log('Submitted successfully', response)
        handleSwitchPage('end')
      }
    )
  }

  const buttonSwitcher = (currentPage) => {
    if(currentPage === 'start'){
      return (
        <>
          <div></div>
          <StyledButton 
            type='primary' 
            label='Start' 
            onClick={()=>handleSwitchPage(1)}
          />
        </>
      )
    }else if(currentPage !== 'end'){
      
      return (
        <>
          <StyledButton 
            type='secondary' 
            label='Back' 
            onClick={()=>handleSwitchPage(currentPage === 1 ? 'start' : currentPage - 1)}
          />

          {
            currentPage === pages.length ?
              <StyledButton 
                type='primary' 
                label='Submit' 
                onClick={()=>handleFormSubmit()}
              />
              :
              <StyledButton 
                type='secondary' 
                label='Next' 
                onClick={()=>handleSwitchPage(currentPage + 1)}
              />
          }
        </>
      )
    }
  }

  return (
    <div 
      className="w-full flex justify-between "
      style={{
        maxWidth: "50rem",
      }}
    >
      {buttonSwitcher(state.appState.currentPage)}
    </div>
  )
}

function StartPage() {

  return(
    <FormCard>
     <HeaderText>
        Start the form
      </HeaderText>
    </FormCard>
  )
}

function EndPage() {
  const { state, dispatch } = useContext(FormAnswerContext)
  const { companyId, id } = state?.formData.formDetails


  mutationActionHandler(
    {
      query: mFetchSubmissions,
      variables: {
        companyId: companyId,
        formId: id,
      }
    },
    (response)=>{ 
      console.log('Fetching submissions', response)
    }
  )

  return(
    <FormCard>
      <HeaderText>
        Thank you for your answer
      </HeaderText>
      <p>Your response has been recorded</p>
    </FormCard>
  )
}


//===================================
// Reusable Components
//===================================

function StyledButton({type, label, className, onClick}){

  const buttonStyles = {
    primary: 'bg-green-500 text-white',
    secondary: 'bg-gray-200',
  }

  return(
    <button
      className={`py-2 px-6 font-normal text-base rounded-md ${buttonStyles[type]} ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

function HeaderText({children, className}) {

  return(
    <h1
      className={` font-medium text-xl ${className}`}
    >
      {children}
    </h1>
  )
}