import React from "react";
import { useEffect } from "react";
import { BsMicrosoft } from "react-icons/bs";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginRequest } from "../../outlook-config-v2";
import { clearStorage } from "../../shared/utils/storageUtils";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

var momentTZ = require("moment-timezone");

const removeRefreshTokenGmail = `
mutation removeRefreshToken($email: String) {
  gmailDisconnect(email: $email) {
    id
  }
}
`;

const removeRefreshTokenOutlook = `
mutation removeRefreshToken($email: String!, $companyId: String!) {
  outlookDisconnect(companyId: $companyId, email: $email) {
    id
  }
}
`;

const qGetCompanyGmailToken = `
  query getCompanyGmailToken($companyId: String) {
    company(id: $companyId) {
      gmailToken {
        id
        refreshToken
        isOutOfSync
      }
    }
  }`;
const saveRefreshToken = `
mutation connectToOutlook($companyId: ID, $email: String, $code: String, $userTimeZone: String) {
  outlookConnect(
      email: $email
      companyId: $companyId
      code: $code
      userTimeZone: $userTimeZone
  ) {
      id
      refreshToken
      userId
      companyId
      updatedAt
      userTimeZone
  }
}
`;
const OutlookSignIn = ({
  setConnectedOutlookAccount,
  connectedOutlookAccount,
  isOutlookIntegrated,
  getSavedAuthToken,
}) => {
  const companyId = localStorage.getItem("companyId");
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLogin = () => {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    if (process.env.REACT_APP_DOMAIN !== "https://portal.homebuilding.app") {
      instance
        .loginPopup({
          ...loginRequest,
          redirectUri: "/inbox",
        })
        .then((response) => {
          if (response) {
            if (response.account !== null) {
              handleLoginResponse(response);
            } else {
              throw new Error("account in response is null");
            }
          }
        })
        .catch((error) => console.log("ERROR!!!", error));
    } else {
      instance.loginRedirect({
        ...loginRequest,
        redirectUri: "/inbox",
      });
    }
  };

  useEffect(() => {
    // instance.enableAccountStorageEvents();
    // const callbackId = instance.addEventCallback((message) => {
    //   // This will be run every time an event is emitted after registering this callback
    //   console.log("OUTLOOK: EVENT!", message);
    //   if (message.eventType === EventType.LOGIN_SUCCESS) {
    //     const result = message.payload;
    //     console.log("OUTLOOK: I LOGGED IN!!! SUCCESSFULLY!!!")
    //   }
    //   if (message.eventType === EventType.LOGOUT_SUCCESS) {
    //     if (activeAccount) clearStorage(activeAccount);
    //     localStorage.removeItem("connectedOutlookAccount");
    //     console.log("OUTLOOK: LOGGED OUT SUCCESSFULLY!!")
    //   }
    // });

    // return () => {
    //   // This will be run on component unmount
    //   if (callbackId) {
    //     instance.removeEventCallback(callbackId);
    //   }
    // }
    if (process.env.REACT_APP_DOMAIN !== "https://portal.homebuilding.app")
      return;

    instance
      .handleRedirectPromise()
      .then((response) => {
        console.log("OUTLOOK: REDIRECT", response);
        if (response !== null) {
          if (response.account != null) {
            // currentAccount.setCurrentAccount(s.account.username, s.account.homeAccountId, s.account.tenantId);
            console.log("OUTLOOK: LOGIN SUCCESS");
            handleLoginResponse(response);
          }
        }
      })
      .catch((a) => {
        console.log("OUTLOOK: ERROR ", a);
      });
  }, []);

  const handleLogout = async () => {
    await disconnectEmail(connectedOutlookAccount, true);

    if (activeAccount) clearStorage(activeAccount);
    localStorage.removeItem("connectedOutlookAccount");
    window.location.reload();

    // if (process.env.REACT_APP_DOMAIN !== "https://portal.homebuilding.app") {
    //   instance.logoutPopup({
    //     mainWindowRedirectUri: '/inbox', // redirects the top level app after logout
    //     //account: instance.getActiveAccount(),
    //   }).then(()=>{
    //     if (activeAccount) clearStorage(activeAccount);
    //     localStorage.removeItem("connectedOutlookAccount");
    //   }).catch((error) => console.log("LOGOUT ERROR!!!", error));
    // } else {
    //   instance.logoutRedirect({
    //     // account: instance.getActiveAccount(),
    //     postLogoutRedirectUri: '/inbox'
    //   });
    // }
  };

  const history = useHistory();

  const handleLoginResponse = async (response) => {
    console.log("OUTLOOK LOGIN RESPONSE", response);

    document.body.style.cursor = "wait";
    checkConnectedGmail(); //DISCONNECT CONNECTED GMAIL IF IT EXISTS

    const account = response?.account;
    localStorage.setItem("connectedOutlookAccount", account.username);
    setConnectedOutlookAccount(account.username);

    const params = {
      query: saveRefreshToken,
      variables: {
        companyId: localStorage.getItem("companyId"),
        email: response.account.username,
        code: response.accessToken,
        userTimeZone: momentTZ.tz.guess(),
      },
    };

    await API.graphql(params).then((result) => {
      const data = result.data.outlookConnect;
      console.log("OUTLOOK CONNECT RESULT!", result);
      document.body.style.cursor = "default";
      // window.location.reload();
      getSavedAuthToken();
    });
  };

  const disconnectEmail = async (connectedEmail, isDisconnectingOutlook) => {
    const params = {
      query: isDisconnectingOutlook
        ? removeRefreshTokenOutlook
        : removeRefreshTokenGmail,
      variables: isDisconnectingOutlook
        ? {
            email: connectedEmail,
            companyId: localStorage.getItem("companyId"),
          }
        : {
            email: connectedEmail,
          },
    };

    console.log("disconnecting", connectedEmail, params);
    const success = await API.graphql(params);
    console.log("disconnecting success", success);
  };

  const checkConnectedGmail = async () => {
    const params = {
      query: qGetCompanyGmailToken,
      variables: {
        companyId: companyId,
      },
    };

    await API.graphql(params).then((result) => {
      console.log("Result:", result);

      const email = result.data.company.gmailToken.id; //connected gmail address

      if (email) {
        disconnectEmail(email, false);
        localStorage.removeItem("connectedGmailAccount");
      }
    });
  };
  return (
    <>
      <button
        className="mt-2 inline-block px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        onClick={(e) => (isOutlookIntegrated ? handleLogout() : handleLogin())}
      >
        <div className="flex items-center font-medium">
          {isOutlookIntegrated ? (
            <>
              <BsMicrosoft className="mr-2" />
              Sign Out
            </>
          ) : (
            <>
              <BsMicrosoft className="mr-2" />
              Continue with microsoft
            </>
          )}
        </div>
      </button>
    </>
  );
};

export default OutlookSignIn;
