/* External Components/Packages */
import React, { createContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { API } from "aws-amplify";
// import { ListUsers, GetUserCM } from "../../shared/graphql/queries";
// import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Storage } from "aws-amplify";
import ProgressBar from "@ramonak/react-progress-bar";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";

/* Shared Components */
import ToastNotification from "../toast-notification";
import {
  setDefaultColumnBriefs,
  createContractBackground,
  createDefaultBriefs,
} from "../../shared/HBA-Functions.jsx";
import Button from "../../shared/Button";
import RightPanel from "../hba-dashboard/right-panel.jsx";
import {
  // timezoneFormatter,
  // datePickerDateFormatter,
  dateFormatter,
} from "../../shared/userPreferredDateTime";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import { AppRoutes } from "../../constants/AppRoutes";

/* Assets & Icons */

import * as IoIcons from "react-icons/io5";
import * as FaIcons from "react-icons/fa";
import * as LiaIcons from "react-icons/lia";
import contractsIcon from "../../assets/images/contracts-icon-blue.png";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { BiArrowToTop } from "react-icons/bi";
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Mobile.css";

import ProgressPhotos from "../hba-dashboard/progress-photos.jsx";
import ProgressOfWork from "../hba-dashboard/progress-of-work.jsx";
import Invoices from "../hba-dashboard/invoices.jsx";
import Contracts from "../hba-dashboard/contracts.jsx";
import Variations from "../hba-dashboard/variations.jsx";
import ProvisionalSum from "../hba-dashboard/provisional-sum.jsx";
import PrimeCost from "../hba-dashboard/prime-cost.jsx";
import Budget from "../hba-dashboard/budget.jsx";
import ProjectLocation from "../hba-dashboard/project-location";
import SiteDiary from "../hba-dashboard/sitediary.jsx";
import TaskList from "../hba-dashboard/tasklist.jsx";
import EmailInteg from "../hba-dashboard/email-integration-tile.jsx";
import DynamicTiles from "../hba-dashboard/dynamic-tiles.jsx";

export const MatterContext = createContext();

export default function Dashboard({
  tilesDisplaySettings,
  archivedBriefsList,
  setVisibleBriefsList,
  visibleBriefsList,
  initBriefDisplay,
  defaultPhases
}) {
  let history = useHistory();
  const { id } = useParams();
  const [matterId, setMatterId] = useState("");

  const [matter_name, setMatterName] = useState(null);
  const [client_name, setClientName] = useState(null);
  var clientMatterId = "";

  const { displayInbox, displayTasklist } = tilesDisplaySettings;

  console.log("visibleBriefsList: ---->", visibleBriefsList);
  console.log("defaultPhases: ---->", defaultPhases);
  const propertyPhotoRef = useRef(null);
  const propertyDetailsRef = useRef(null);
  const completionDateRef = useRef(null);
  const invoicesRef = useRef(null);
  const variationsRef = useRef(null);
  const provisionalSumRef = useRef(null);
  const budgetRef = useRef(null);
  const uploadContractsRef = useRef(null);
  const primeCostRef = useRef(null);
  const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");
  const [userInfo, setuserInfo] = useState(null);
  const [defaultList, setDefaultList] = useState(null);
  var moment = require("moment");
  const [background, setBackground] = useState([]);
  const [showBackgrounds, setShowBackgrounds] = useState(false);
  const [showBriefId, setShowBriefId] = useState(null);
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);

  const [hideChecklist, setHideChecklist] = useState(true);
  const [contractBrief, setContractBrief] = useState({});
  const [progressPhotoBrief, setProgressPhotoBrief] = useState({});
  const [projectLocationBrief, setProjectLocationBrief] = useState(null);
  const [projectLocationPhoto, setProjectLocationPhoto] = useState(null);

  const [buildInfo, setBuildInfo] = useState(null);
  const [contractInfo, setContractInfo] = useState(null);

  const [contractTC, setContractTC] = useState(null);
  const [archiDrawings, setArchiDrawings] = useState(null);
  const [engDrawings, setEngDrawings] = useState(null);
  const [specifications, setSpecifications] = useState(null);
  const [buildInsurance, setBuildInsurance] = useState(null);

  // Get Started States
  const [getStartedProgress, setGetStartedProgress] = useState({
    builderDetails: false,
    contractDetails: false,
    propertyPhoto: false,
    propertyDetails: false,
    completionDate: false,
    invoices: false,
    variations: false,
    contracts: false,
  });
  const [phases, setPhases] = useState([]);
  const [activePhase, setActivePhase] = useState();

  const [headerRead, setHeaderRead] = useState(false);

  // File Upload States
  const [showUploadModal, setShowUploadModal] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [isToastError, setIsToastError] = useState(false);
  const [fileTypeCollection, setFileTypeCollection] = useState([]);

  const [logoURL, setLogoURL] = useState(null);
  const [builderLogo, setBuilderLogo] = useState(null);

  //Progress Photos States
  // const [selectedFiles, _setSelectedFiles] = useState([]);
  const selectedFiles = useRef([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [datePicked, setDatePicked] = useState(new Date());
  const [description, setDescription] = useState("");
  const [progressPhotosDetails, setProgressPhotosDetails] = useState([]);
  const [progressPhotoBriefID, setProgressPhotoBriefID] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [dynamicTileRows, setDynamicTileRows] = useState([]);

  const [isInitDone, setIsInitDone] = useState(false);
  const defaultColumnIds = [0, 1, 2, 3, 4];

  const fileList = [
    "Contract Terms And Conditions",
    "Architectural Drawings",
    "Engineer Drawings",
    "Specifications",
    "Builder's Insurance",
  ];

  // RIGHT MODAL PROPERTIES
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [modalState, setModalState] = useState(null);
  const modalChoicesEnum = {
    BUILDER_DETAILS: "BUILDER_DETAILS",
    CONTRACT_DETAILS: "CONTRACT_DETAILS",
    PROGRESS_PHOTO: "PROGRESS_PHOTO",
  };

  var domain = window.location.hostname;

  // useEffect(() => {
  //   console.log("Get Started Progress", getStartedProgress);
  // }, [getStartedProgress]);

  useEffect(() => {
    var status = localStorage.getItem("onboardingProgress");
    // console.log(status);
    // console.log(defaultList);

    if (defaultList) {
      let buildersIdx;
      let contractsIdx;

      for (let x = 0; x < defaultList.length; x++) {
        let item = defaultList[x];
        if (item.name === "Builder Details") {
          buildersIdx = x;
          // If contractsIdx has already been found, break out of the loop
          if (contractsIdx !== undefined) break;
        } else if (item.name === "Contract Details") {
          contractsIdx = x;
          // If buildersIdx has already been found, break out of the loop
          if (buildersIdx !== undefined) break;
        }
      }

      getPhasesLibrary();
    }

    // if (isInitDone == true && defaultList){
    //   var contractDetailsOnboarding;
    //   var builderDetailsOnboarding;

    //   if(defaultList[contractsIdx]){
    //     contractDetailsOnboarding = defaultList[contractsIdx].backgrounds.items.length == 0 ? false : true;
    //   } else {
    //     contractDetailsOnboarding = true;
    //   }

    //   if(defaultList[buildersIdx]){
    //     builderDetailsOnboarding = defaultList[buildersIdx].backgrounds.items.length == 0 ? false : true;
    //   } else {
    //     builderDetailsOnboarding = true;
    //   }
    // }

    // console.log(buildersIdx, builderDetailsOnboarding);
    // console.log(contractsIdx, contractDetailsOnboarding);

    // if (
    //   status == "false" ||
    //   contractDetailsOnboarding == false ||
    //   builderDetailsOnboarding == false
    // ) {
    //   console.log("REDIRECT TO GETTING STARTED");
    //   history.push(AppRoutes.GETTINGSTARTED);
    // }
  }, [isInitDone]);

  async function getPhasesLibrary() {
    setPhases(defaultPhases);
    resetPhases();
  }

  const resetPhases = () => {
    setActivePhase("");

    const activeBriefs = Array.from(
      new Set(defaultList?.map((item) => item.name))
    );
    activeBriefs.push("Builder Details");
    activeBriefs.push("Contract Details");
    setVisibleBriefsList(activeBriefs);


  };

  useEffect(() => {
    //force reload to rerender localStorage
    if (
      localStorage.getItem("onboardingProgress") == "true" &&
      localStorage.getItem("userProgress") == "true"
    ) {
      localStorage.setItem("userProgress", "false");
      window.location.reload();
    }
  }, []);

  // useEffect(() => {
  //   if (redirectDashboard === false) {
  //     if (hbaDomains.includes(domain)) {
  //       console.log("Valid access"); //HBA
  //       setRedirectDashboard(true);
  //     } else if (obaDomains.includes(domain)) {
  //       history.push(AppRoutes.OBADASHBOARD); //HBA
  //     } else {
  //       history.push(AppRoutes.DASHBOARD); //MMA
  //     }
  //   }
  // }, [redirectDashboard]);

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  //QUERIES
  const qGetBackgroundFilesbyBriefID = `
	query getBackgroundFilesByBriefID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
		brief(id: $id) {
		id
		name
		backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
			items {
			id
			description
			date
			createdAt
			order
			files {
				items {
				id
				name
				details
				isGmailPDF
				isGmailAttachment
				s3ObjectKey
				order
				createdAt
				downloadURL
				}
			}
			}
			nextToken
		}
		}
	}`;

  const qGetBackgroundsByBriefId = `query getBriefByID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
    brief(id: $id) {
      id
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          files {
            items {
              id
            }
          }
        }
      }
    }
  }
  `;

  const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
    clientMatter(id: $id) {
      briefs{
        items {
          id
          name
          userTypeAccess
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          backgrounds(limit: 50, sortOrder: ORDER_ASC) {
            items {
              id
              description
              date
              dynamicData
              files {
                items {
                  id
                  name
                }
              }
            }
          }
          hasCommentAccess
          columnIds
          assistantId
        }
      }
    }
  }`;

  const FILE_DOWNLOAD_LINK_QUERY = `
        query getFileDownloadLink($id: ID) {
          file(id: $id) {
            downloadURL
            s3ObjectKey
            type
          }
        }
    `;

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
        query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
        brief(id: $id) {
        id
        backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
            items {
            id
            description
            comments
            date
            createdAt
            order
            dynamicData
            files {
                items {
                id
                name
                }
            }
            }
            nextToken
        }
        }
    }
    `;

  const qlistBackgroundFiles = `
					query getBackgroundByID($id: ID) {
					  background(id: $id) {
						id
						files {
						  items {
							createdAt
							id
							details
							name
							order
						  }
						}
						labels {
						  items {
							id
							name
						  }
						}
					  }
					}`;

  const BACKGROUND_TABLE_QUERY = `
					query getBackgroundTable($clientMatterId: ID!) {
						backgroundTable(clientMatterId: $clientMatterId) {
						  id
						  columns {
							id
							accessorKey
							headerText
							enabled
							type
							optionsText
							order
							presets {
							  id
							  name
							}
						  }
						  createdAt
						  updatedAt
						}
					}`;

  const qGetFilesByMatter = `
					query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
					  matterFiles(isDeleted: $isDeleted, matterId: $matterId, nextToken: $nextToken, limit: $limit, sortOrder: $sortOrder) {
						items {
						  id
						  name
						  type
						}
					  }
					}
					`;

  //MUTATIONS
  // const addClientq = `
  //       mutation addClient($companyId: String, $name: String) {
  //           clientCreate(companyId:$companyId, name:$name) {
  //               id
  //               name
  //           }
  //       }
  //       `;

  // const addMatterq = `
  //       mutation addMatter($companyId: String, $name: String) {
  //           matterCreate(companyId:$companyId, name:$name) {
  //               id
  //               name
  //           }
  //       }
  //       `;

  // const createClientMatterq = `
  //       mutation createClientMatter($companyId: String, $userId: ID, $client: ClientInput, $matter:MatterInput) {
  //         clientMatterCreate(companyId: $companyId, userId:$userId, client: $client, matter:$matter) {
  //           id
  //         }
  //       }
  //       `;

  const CREATE_BRIEF_MUTATION = `
        mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int], $assistantId: String) {
          briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds, assistantId: $assistantId) {
            id
            name
            date
            createdAt,
            assistantId,
            order
            columnIds
          }
        }
        `;

  const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

  const mBulkCreateMatterFile = `
    mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
      matterFileBulkCreate(files: $files) {
        id
        name
        order
      }
    }
  `;

  const BACKGROUNDFILE_TAG_MUTATION = `
  mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
	  backgroundFileTag(backgroundId: $backgroundId, files: $files) {
		id
	  }
	}
  `;

  const mCreateBackground = `
  mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
	backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
	  id
	  createdAt
	  date
	  description
	  order
	  briefs {
		id
		name
	  }
	}
  }
	`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
	mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
	  backgroundTableUpdate(id: $id, input: $input) {
		id
		columns {
		  id
		  accessorKey
		  headerText
		  type
		  enabled
		  optionsText
		  order
		  presets {
			id
			name
		  }
		}
	  }
	}
	`;

  const BACKGROUND_TABLE_CREATE = `
    mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
      backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
        }
        createdAt
        updatedAt
      }
    }
    `;

  const UPDATE_BRIEF_PRESET_MUTATION = `
  	mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    	briefUpdate(id: $id, columnIds: $columnIds){
				id
    }
  }
  `;

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);

  //FUNCTIONS
  useEffect(() => {
    if (userInfo === null) {
      let ls = {
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        company: localStorage.getItem("company"),
        userType: localStorage.getItem("userType"),
        // access: JSON.parse(localStorage.getItem("access")),
      };
      setuserInfo(ls);
      console.log("userInfo", ls);
    }
  }, [userInfo]);

  const qgetClientMatterById = `
  query clientMatterById($id: ID) {
    clientMatter (id: $id) {
      id
      client {
        name
        id
      }
      matter {
        name
        id
      }
      description
      matterNumber
    }
  }`;

  useEffect(async () => {
    console.log("DEFAULT LIST IS:", defaultList);
    if (defaultList === null) {
      const clientMatterParams = {
        query: qgetClientMatterById,
        variables: {
          id: id,
        },
      };

      const result = await API.graphql(clientMatterParams);
      clientMatterId = result.data.clientMatter.id;
      setMatterName(result.data.clientMatter.matter.name);
      setClientName(result.data.clientMatter.client.name);

      setMatterId(result.data.clientMatter.matter.id);
      // await getBriefs();
      //JUST DISPLAY BACKGROUNDS
      // console.log("will enter here", clientMatterId);

      const params = {
        query: BRIEFS_QUERY,
        variables: {
          id: id,
        },
      };

      await API.graphql(params).then(async (brief) => {
        let promisesArr = [];

        let currentDate = moment
          .utc(moment(new Date(), "YYYY-MM-DD"))
          .toISOString();

        var briefsList = brief?.data?.clientMatter?.briefs?.items;
        console.log("Default Briefs", briefsList);

        let {
          Builder_Details,
          Contract_Details,
          Variations,
          Provisional_Sum,
          Budget,
          Invoices,
          Progress_Photos,
          Progress_of_work,
          Project_Location,
          Site_Diary,
          Prime_Cost,
          Tenders,
          Certificates,
          Insurance,
          Defects_List,
          Development_Approvals,
          Building_Permits,
          Warranties_and_Certifications,
          Work_Safety_Compliance,
          Contracts,
        } = await createDefaultBriefs(clientMatterId, currentDate, briefsList);

        if (Contracts) {
          await createContractBackground(Contracts);
        }

        if (
          Builder_Details ||
          Contract_Details ||
          Variations ||
          Provisional_Sum ||
          Budget ||
          Invoices ||
          Progress_Photos ||
          Progress_of_work ||
          Project_Location ||
          Site_Diary ||
          Prime_Cost ||
          Tenders ||
          Certificates ||
          Insurance ||
          Defects_List ||
          Development_Approvals ||
          Building_Permits ||
          Warranties_and_Certifications ||
          Work_Safety_Compliance ||
          Contracts
        ) {
          window.location.reload();
        }

        const progressPhotoBrief = briefsList.find(
          (item) => item.name === "Progress Photos"
        );

        const projectLocationBrief = briefsList.find(
          (item) => item.name === "Project Location"
        );

        const contractDetailsBrief = briefsList?.find(
          (brief) => brief.name === "Contract Details"
        );

        const builderDetailsBrief = briefsList?.find(
          (brief) => brief.name === "Builder Details"
        );

        const contractsBrief = briefsList?.find(
          (brief) => brief.name === "Contracts"
        );

        if (progressPhotoBrief) {
          promisesArr.push(initProgressPhotoBrief(progressPhotoBrief));
        }

        if (projectLocationBrief) {
          promisesArr.push(initProjectLocationBrief(projectLocationBrief));
        }

        if (contractDetailsBrief) {
          promisesArr.push(initContractDetailsBrief(contractDetailsBrief));
        }

        if (builderDetailsBrief) {
          promisesArr.push(initBuilderDetailsBrief(builderDetailsBrief));
        }

        if (contractsBrief) {
          setContractBrief(contractsBrief);
        }

        console.log("\n\nBriefs List: ", briefsList);
        initBriefDisplay();

        setDefaultList(briefsList);
        setDefaultBriefColumnIds(sortByOrder(briefsList), clientMatterId);
      });
      //end
    }
    setIsInitDone(true);
  }, []);

  // const createBackground = async (brief) => {
  //   await API.graphql({
  //     query: mCreateBackground,
  //     variables: {
  //       briefs: [
  //         {
  //           id: brief?.id,
  //           name: brief?.name,
  //         },
  //       ],
  //     },
  //   });
  // };

  // const createInitialBrief = async (briefName, date, order, columnIds) => {
  //   if (archivedBriefsList.includes(briefName)) {
  //     console.log(briefName, "already exists, present in Archived Briefs");
  //     return false;
  //   } else {
  //     console.log("Create intial brief for", briefName);
  //     return await API.graphql({
  //       query: CREATE_BRIEF_MUTATION,
  //       variables: {
  //         clientMatterId: clientMatterId,
  //         // assistantId: assistantId,
  //         name: briefName,
  //         date: date,
  //         order: order,
  //         columnIds: columnIds,
  //       },
  //     });
  //   }
  // };

  const initProgressPhotoBrief = async (progressPhotoBrief) => {
    if (!progressPhotoBrief) {
      console.log("Progress Photo Brief does not Exists");
      return;
    }

    setProgressPhotoBrief(progressPhotoBrief);
    setProgressPhotoBriefID(progressPhotoBrief?.id);
    setProgressPhotosDetails(progressPhotoBrief);

    const response = await API.graphql({
      query: qGetBackgroundFilesbyBriefID,
      variables: {
        id: progressPhotoBrief.id,
        limit: null,
        nextToken: null,
        sortOrder: "DATE_DESC",
      },
    });
    let brief = response.data;

    // const filteredItems = brief.backgrounds.items.filter(item =>
    // 	item.files.items && item.files.items.length > 0
    // );

    let retrievedRow = brief.brief.backgrounds.items;

    // Filter the retrievedRow array to only contain rows with images
    if (retrievedRow.length > 0) {
      const filteredItems = retrievedRow
        .filter((item) => {
          // Check if at least one file in the files array is an image
          return item.files.items.some((file) => isImageFile(file.s3ObjectKey));
        })
        .sort((a, b) => a.order - b.order);

      setPhotos(filteredItems);
      // console.log("YOU ARE HERE " + filteredItems)
      // console.log("YOU ARE HERE " + JSON.stringify(retrievedRow))
    }
  };

  const initProjectLocationBrief = async (projectLocationBrief) => {
    let rowArray = [];

    if (!projectLocationBrief) {
      console.log("Project Location Brief does not Exist");
      return;
    }

    const response = await API.graphql({
      query: qGetBackgroundFilesbyBriefID,
      variables: {
        id: projectLocationBrief.id,
        limit: null,
        nextToken: null,
        sortOrder: "DATE_DESC",
      },
    });
    let brief = response?.data?.brief;
    setProjectLocationBrief(brief);

    // const filteredItems = brief.backgrounds.items.filter(item =>
    // 	item.files.items && item.files.items.length > 0
    // );
    if (brief.backgrounds?.items?.length > 0) {
      rowArray.push(brief.backgrounds.items);
      console.log("setting project location photo", brief.backgrounds.items[0]);
      setProjectLocationPhoto(brief.backgrounds.items[0]);
    }
  };

  const initContractDetailsBrief = async (contractDetailsBrief) => {
    if (!contractDetailsBrief) {
      console.log("Contract Details Brief does not Exist");
      return;
    }

    let unparsedData = contractDetailsBrief?.backgrounds?.items;

    const parsedData = unparsedData?.map((item) => {
      if (item?.dynamicData) {
        try {
          const dynamicDataObject = JSON.parse(item.dynamicData);
          if (typeof dynamicDataObject === "object") {
            const dynamicDataArray = Object.keys(dynamicDataObject).map(
              (key) => ({
                id: key,
                value: dynamicDataObject[key],
              })
            );

            return {
              ...item,
              dynamicData: dynamicDataArray,
            };
          } else {
            console.warn(
              "dynamicData is not a valid JSON object:",
              dynamicDataObject
            );
          }
        } catch (error) {
          console.error("Error parsing dynamicData:", error);
        }
      } else {
        console.warn("dynamicData is missing or null for the item:", item);
      }
    });

    // Filter out items with dynamicData errors
    const validParsedData = parsedData.filter((item) => item);

    if (validParsedData !== "undefined" && validParsedData.length !== 0) {
      // let contractInfoTemp = {};
      // let dynamicDataTemp = validParsedData[0]?.dynamicData;

      // let contractStartDate = dynamicDataTemp.find((e) => e.id === "16");
      // if (contractStartDate) {
      //   contractInfoTemp.contractStartDate = contractStartDate.value;
      // }

      // let contractPrice = dynamicDataTemp.find((e) => e.id === "17");
      // if (contractPrice) {
      //   contractInfoTemp.contractPrice = contractPrice.value;
      // }

      // let contractValue = dynamicDataTemp.find((e) => e.id === "18");
      // if (contractValue) {
      //   contractInfoTemp.contractValue = contractValue.value;
      // }

      // setContractInfo(contractInfoTemp);
      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      if (backgroundTable?.data?.backgroundTable) {
        let contractInfo = {
          contractStartDate: null,
          contractPrice: null,
          contractValue: null,
        };

        let backgroundTableInfo =
          backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
            if (obj.headerText == "Start Date") {
              console.log("Start Date", obj.accessorKey);
              contractInfo.contractStartDate = getValueById(
                parsedData,
                obj.accessorKey
              );
            }

            if (obj.headerText == "Contract Price") {
              console.log("Contract Price", obj.accessorKey);
              contractInfo.contractPrice = getValueById(
                parsedData,
                obj.accessorKey
              );
            }

            if (obj.headerText == "Contract Value") {
              console.log("Contract Value", obj.accessorKey);
              contractInfo.contractValue = getValueById(
                parsedData,
                obj.accessorKey
              );
            }

            return getValueById(parsedData, obj.accessorKey);
          });

        setContractInfo(contractInfo);
      } else {
        setContractInfo(false);
      }
    } else {
      setContractInfo(false);
    }
  };

  const initBuilderDetailsBrief = async (builderDetailsBrief) => {
    if (!builderDetailsBrief) {
      console.log("Builder Details Brief does not Exist");
      return;
    }

    let unparsedData = builderDetailsBrief?.backgrounds?.items;
    console.log("getFiles", unparsedData);

    const parsedData = unparsedData?.map((item) => {
      let arrFileResult = item.files.items.map(({ id }) => {
        previewOnlyFile(id)
          .then((fileData) => {
            // Do something with the fileData
            console.log("preview link", fileData.downloadURL);
            // buildInfo.builderLogo = fileData.downloadURL;
            setBuilderLogo(fileData.downloadURL);
          })
          .catch((error) => {
            // Handle any errors
            console.log("error");
          });
      });

      if (item?.dynamicData) {
        try {
          const dynamicDataObject = JSON.parse(item.dynamicData);
          if (typeof dynamicDataObject === "object") {
            const dynamicDataArray = Object.keys(dynamicDataObject).map(
              (key) => ({
                id: key,
                value: dynamicDataObject[key],
              })
            );

            return {
              ...item,
              dynamicData: dynamicDataArray,
            };
          } else {
            console.warn(
              "dynamicData is not a valid JSON object:",
              dynamicDataObject
            );
          }
        } catch (error) {
          console.error("Error parsing dynamicData:", error);
        }
      } else {
        console.warn("dynamicData is missing or null for the item:", item);
      }
    });

    // Filter out items with dynamicData errors
    const validParsedData = parsedData.filter((item) => item);
    if (validParsedData !== "undefined" && validParsedData.length !== 0) {
      let buildInfoTemp = {};
      // let dynamicDataTemp = validParsedData[0]?.dynamicData;

      // const response = await API.graphql({
      //     query: BACKGROUND_TABLE_QUERY,
      //     variables: {
      //       clientMatterId: clientMatterId,
      //     },
      //   });

      // const startColumn = response?.data?.backgroundTable?.columns?.find((col) => {
      //   return col.headerText === "Builder Name";
      // })
      // let startIndex = parseInt(startColumn.id);

      // let builderName = dynamicDataTemp.find((e) => e.id === (startIndex).toString());
      // if (builderName) {
      //   buildInfoTemp.builderName = builderName.value;
      // }

      // // let builderLogo = dynamicDataTemp.find((e) =>
      // //   e.id === (startIndex + 1).toString()
      // // );
      // // if (builderLogo) {
      // //   buildInfoTemp.builderLogo = builderLogo.value;
      // // }

      // let builderContactName = dynamicDataTemp.find((e) => e.id === (startIndex + 2).toString());
      // if (builderContactName) {
      //   buildInfoTemp.builderContactName = builderContactName.value;
      // }

      // let builderContactEmail = dynamicDataTemp.find((e) => e.id === (startIndex + 3).toString());
      // if (builderContactEmail) {
      //   buildInfoTemp.builderContactEmail = builderContactEmail.value;
      // }

      // let builderContactNumber = dynamicDataTemp.find((e) => e.id === (startIndex + 4).toString());
      // if (builderContactNumber) {
      //   buildInfoTemp.builderContactNumber = builderContactNumber.value;
      // }

      // setBuildInfo(buildInfoTemp);

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      console.log("builder details bg", backgroundTable);
      if (backgroundTable?.data?.backgroundTable) {
        let backgroundTableInfo =
          backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
            if (obj.headerText == "Builder Name") {
              console.log("Builder Name", obj.accessorKey);
              buildInfoTemp.builderName = getValueById(
                parsedData,
                obj.accessorKey
              );
            }

            // if ( obj.headerText == "Builder Logo" ) {
            //   console.log("Builder Logo", obj.accessorKey);
            //   buildInfo.builderLogo = getValueById(parsedData, obj.accessorKey);
            // }

            if (obj.headerText == "Builder Contact Name") {
              console.log("Builder Contact Name", obj.accessorKey);
              buildInfoTemp.builderContactName = getValueById(
                parsedData,
                obj.accessorKey
              );
            }

            if (obj.headerText == "Builder Contact Email") {
              console.log("Builder Contact Email", obj.accessorKey);
              buildInfoTemp.builderContactEmail = getValueById(
                parsedData,
                obj.accessorKey
              );
            }

            if (obj.headerText == "Builder Contact Number") {
              console.log("Builder Contact Number", obj.accessorKey);
              buildInfoTemp.builderContactNumber = getValueById(
                parsedData,
                obj.accessorKey
              );
            }

            return getValueById(parsedData, obj.accessorKey);
          });

        console.log("BUILD INFOS: ", buildInfoTemp);
        setBuildInfo(buildInfoTemp);
      } else {
        setBuildInfo(false);
      }
    } else {
      setBuildInfo(false);
    }
  };

  const handleChangeGetStartedProgress = (getStartedProgressKey) => {
    if (
      getStartedProgress[getStartedProgressKey] !== undefined &&
      getStartedProgress[getStartedProgressKey] === false
    ) {
      const newProgressObject = {
        ...getStartedProgress,
        [getStartedProgressKey]: true,
      };
      console.log("newProgressObject", newProgressObject);
      setGetStartedProgress(newProgressObject);
      console.log("this");
      //localStorage.setItem('getStartedCompletion', JSON.stringify(newProgressObject))
    }
  };

  //Check if file is image file
  const isImageFile = (fileName) => {
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".webp",
      ".svg",
      ".avif",
      ".apng",
    ];
    const extension = fileName
      .toLowerCase()
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    return imageExtensions.includes("." + extension);
  };

  const previewOnlyFile = (id) => {
    console.log("preview and download file: ", id);
    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    return API.graphql(params).then((result) => {
      return result.data.file; // Assuming the data structure returned has a "file" property
    });
  };

  const sortByOrder = (arr) => {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }
    return sort;
  };

  const getBackgrounds = async (id, state) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });

    if (backgroundOpt.data.brief.backgrounds.items !== null) {
      var result = backgroundOpt.data.brief.backgrounds.items.map(
        ({ id, description, date, createdAt, order, files, dynamicData }) => ({
          createdAt: createdAt,
          id: id,
          description: description,
          date: date,
          order: order,
          files: files,
          dynamicData: dynamicData,
        })
      );
      console.log("Background Data", result);
      setBackground(sortByOrder(result));
      setShowBackgrounds(state);
      setShowBriefId(id);
    }
  };

  let getMatterFiles = async () => {
    const params = {
      query: qGetFilesByMatter,
      variables: {
        matterId: matterId,
        isDeleted: false,
        nextToken: null,
        sortOrder: "DATE_ASC",
      },
    };
    await API.graphql(params).then((files) => {
      let matterFilesList = files?.data?.matterFiles?.items;
      setFileTypeCollection(matterFilesList);
    });
  };

  useEffect(() => {
    getMatterFiles();
  }, [matterId]);

  async function bulkCreateMatterFile(param, bgId) {
    console.log("bulkCreateMatterFile", bgId, param);
    let idTag = [];

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    console.log("result", request);

    if (request.data.matterFileBulkCreate !== null) {
      request.data.matterFileBulkCreate.map((i) => {
        return (idTag = [...idTag, { id: i.id }]);
      });
    }

    console.log("iDTag", idTag);

    // let arrFiles = [];
    // let arrFileResult = [];
    // const seen = new Set();

    // // console.log("MID/BID", background_id);

    // const backgroundFilesOpt = await API.graphql({
    //   query: qlistBackgroundFiles,
    //   variables: {
    //     id: bgId,
    //   },
    // });

    // console.log("CHECKDATA", backgroundFilesOpt);

    // if (backgroundFilesOpt.data.background.files !== null) {
    //   arrFileResult = backgroundFilesOpt.data.background.files.items.map(
    //     ({ id }) => ({
    //       id: id,
    //     })
    //   );

    //   idTag.push(...arrFileResult);
    //   console.log("updatedidtag", idTag);

    //   const filteredArr = idTag.filter((el) => {
    //     const duplicate = seen.has(el.id);
    //     seen.add(el.id);
    //     return !duplicate;
    //   });

    //   console.log("rowid", bgId);

    //   API.graphql({
    //     query: BACKGROUNDFILE_TAG_MUTATION,
    //     variables: {
    //       backgroundId: bgId,
    //       files: filteredArr,
    //     },
    //   });
    // } else {
    await API.graphql({
      query: BACKGROUNDFILE_TAG_MUTATION,
      variables: {
        backgroundId: bgId,
        files: idTag,
      },
    });
    // }
    //return request;
  }

  const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; //list of rejected files

  useEffect(() => {
    var counter = 0;
    var dCounter = 0;
    if (uploadImage.length > 0 && counter === 0) {
      onSelectFile(uploadImage);
      counter = 1;
    }
  }, [uploadImage]);

  // useEffect(() => {
  //   console.log("Selected files are:" + JSON.stringify(selectedFiles.current[0]));
  //   console.log("Rejected files are:" + JSON.stringify(invalidFiles[0]));
  // }, [selectedFiles, invalidFiles]);
  const onSelectFile = (retrievedFiles) => {
    console.log("You are here " + retrievedFiles);
    if (!retrievedFiles || retrievedFiles.length === 0) {
      return;
    }
    const tempArr = [];

    [...retrievedFiles].forEach((file) => {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];

      const result = rejectFiles.find((item) =>
        item.includes(re.exec(file.name)[0])
      );
      const fileSize = file.size;

      if (result || fileSize > 2147483648) {
        invalidFiles.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      } else {
        tempArr.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      }
    });

    selectedFiles.current = [...selectedFiles.current, ...tempArr];
  };

  // const myCurrentRef = useRef(selectedFiles);
  // const setSelectedFiles = (data) => {
  //   myCurrentRef.current = data;
  //   _setSelectedFiles(data);
  // };

  const handleUpload = async (bucketName) => {
    console.group("handleUpload");
    console.log("UPLOADING TO: ", bucketName);

    setUploadStart(true);
    if (!saveButtonClicked) {
      setSaveButtonClicked(true);
    }
    try {
      if (!selectedFiles.current || !Array.isArray(selectedFiles.current)) {
        console.error("Invalid or missing selectedFiles array");
        return;
      }

      var tempArr = [];
      var temp = [];
      var idxx = 0;

      for (let uf of selectedFiles.current) {
        if (isImageFile(uf.data.name)) {
          let bgId = await handleProgressPhotoUpload({
            datePicked,
            description,
          });
          tempArr = [...tempArr, { uf, bgId }];
        } else {
          alert(
            `File ${uf.data.name} is not an image. Only images can be uploaded.`
          );
          setImageURL(null); // Set image URL back to null
          setSaveButtonClicked(false);
          return;
        }
      }

      selectedFiles.current = tempArr.map((file) => file.uf);

      console.log("Uploading selectedFiles", selectedFiles.current);
      for (const file of tempArr) {
        const { uf, bgId } = file;
        if (!uf || !uf.data) {
          console.error("Invalid or missing data for file:", uf);
          continue; // Skip to the next iteration
        }

        if (uf.data.name.split(".").pop() == "docx") {
          var name = uf.data.name,
            size = uf.data.size,
            type =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            key = `${bucketName}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        } else {
          var name = uf.data.name,
            size = uf.data.size,
            type = uf.data.type,
            key = `${bucketName}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        }
        idxx = idxx + 1;

        try {
          await Storage.put(key, uf.data, {
            contentType: type,
            progressCallback(progress) {
              const progressInPercentage = Math.round(
                (progress.loaded / progress.total) * 100
              );

              if (temp.length > selectedFiles.current.length) {
                for (var i = 0; i < selectedFiles.current.length; i++) {
                  if (temp[i].name === uf.data.name) {
                    temp[i].prog = progressInPercentage;
                  }
                }
              } else {
                temp = [
                  ...temp,
                  { prog: progressInPercentage, name: uf.data.name },
                ];
              }
              console.log(temp);
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          })
            .then(async (fd) => {
              var fileData = {
                s3ObjectKey: fd.key,
                size: parseInt(size),
                type: type,
                name: name.split(".").slice(0, -1).join("."),
                oderSelected: orderSelected,
                order: orderSelected,
              };

              await handleUploadLink(
                {
                  files: [fileData],
                },
                progressPhotoBrief,
                bgId
              );
              // setUploadedFiles((prevState) => ({
              //   files: [...prevState.files, fileData],
              // }));

              selectedFiles.current = [];
            })
            .catch((err) => {
              console.error("Unexpected error while uploading", err);
            });
        } catch (e) {
          const response = {
            error: e.message,
            errorStack: e.stack,
            statusCode: 500,
          };
          console.error("Unexpected error while uploading", response);
        }
      }
    } catch (error) {
      console.error("Unexpected error in handleUpload", error);
    }

    setShowUploadModal(null);

    await triggerToast(
      `Photo has been uploaded! View it at Progress Photos Background`,
      false
    );

    if (uploadStart == false) {
      window.location.reload();
    }

    console.groupEnd("handleUpload");
  };

  useEffect(() => {
    console.log(uploadStart);
  }, [uploadStart]);

  // const uploadAndCheck = async () => {
  //   if (
  //     uploadedFiles.files.length === selectedFiles.current.length &&
  //     selectedFiles.current.length !== 0
  //   ) {
  //     const result = await handleUploadLink(uploadedFiles, progressPhotoBrief);
  //     console.log(result);
  //     //setProgressPhotosDone(true);
  //     if (result === "done") {
  //       setShowRightPanel(false);
  //       setModalState(null);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (uploadStart == false) {
  //     uploadAndCheck();
  //   }
  // }, [selectedFiles, uploadedFiles, uploadStart]);

  const [logo, setLogo] = useState([]);
  const handleLogoDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const areAllFilesImages = Array.from(files).every((file) =>
        /\.(jpg|jpeg|png|gif)$/i.test(file.name)
      );

      if (areAllFilesImages) {
        setShowRightPanel(true);
        setModalState(modalChoicesEnum.BUILDER_DETAILS);
        console.log("Images uploaded are " + files[0]);
        // setBuilderLogo(URL.createObjectURL(files[0]));
        setLogo(files);
        setLogoURL(URL.createObjectURL(files[0]));
      } else {
        alert("Please drop only image files (JPEG, PNG, GIF, etc.).");
      }
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const file = files[0];
      if (!isImageFile(file.name)) {
        alert(
          `File ${file.name} is not an image. Only images can be uploaded.`
        );
        setImageURL(null); // Set image URL back to null
        return;
      }

      setShowRightPanel(true);
      setModalState(modalChoicesEnum.PROGRESS_PHOTO);
      console.log("Images uploaded are " + file.name);
      setImageURL(URL.createObjectURL(file));
      setUploadImage(files);
    }
  };

  const handleProgressPhotoUpload = async ({ datePicked, description }) => {
    let uploadToBrief = await API.graphql({
      query: mCreateBackground,
      variables: {
        briefs: [
          {
            id: progressPhotoBrief.id,
            name: progressPhotoBrief.name,
          },
        ],
        description: description !== null ? description : "",
        comments: "",
        date: datePicked,
        order: 1,
      },
    });
    console.log("Uploaded to Brief is:", uploadToBrief);
    return uploadToBrief.data.backgroundCreate?.id;
  };
  //UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
  const handleUploadLink = async (uf, brief, bgId) => {
    console.log("handleUploadLink");
    await Promise.all(
      uf?.files.map(async (file) => {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: id,
            briefId: brief?.id,
            activity: `added a background file named ${file.name}-(${file.size} byte)`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: bgId,
          },
        };
        console.log("add activity params", params);
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });
      })
    );
    var uploadedFiles = uf.files.map((f) => ({
      ...f,
      matterId: id,
    }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
    console.log("SF", sortedFiles);
    console.log("AO", addOrder);

    //insert in matter file list
    await bulkCreateMatterFile(addOrder, bgId);

    //set background content

    const backgroundFilesOptReq = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: bgId,
      },
    }).then((result) => {
      var newFilesResult = result.data.background.files.items.map(
        ({ createdAt, id, name, description, order }) => ({
          createdAt: createdAt,
          id: id,
          name: name,
          description: description,
          order: order,
        })
      );

      // Sort the newFilesResult array by createdAt in descending order
      newFilesResult?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      // console.log(newFilesResult);

      var updateArrFiles = background.map((obj) => {
        if (obj.id === bgId) {
          return { ...obj, files: { items: newFilesResult } };
        }
        return obj;
      });

      // Update order number based on uploaded file
      updateArrFiles.forEach((item) => {
        const orderFilesPerBackground = item?.files?.items?.map(
          ({ id }, index) => ({
            id: id,
            order: index + 1,
          })
        );

        // For FE purposes: Update existing background array with the updated order data
        const orderFilesPerBackgroundFE = item?.files?.items?.map(
          (
            {
              id,
              details,
              gmailMessage,
              gmailMessageId,
              isGmailAttachment,
              isGmailPDF,
              name,
              s3ObjectKey,
            },
            index
          ) => ({
            id: id,
            name: name,
            details: details,
            gmailMessage: gmailMessage,
            gmailMessageId: gmailMessageId,
            isGmailAttachment: isGmailAttachment,
            isGmailPDF: isGmailPDF,
            s3ObjectKey: s3ObjectKey,
            order: index + 1,
          })
        );

        if (
          orderFilesPerBackground !== undefined &&
          orderFilesPerBackground !== null
        ) {
          item.files.items = orderFilesPerBackgroundFE;
          // updateBackgroundFilesOrder(orderFilesPerBackground);
          console.log("Items", orderFilesPerBackground);
        }
      });

      console.log("Set New Background", updateArrFiles);
      setBackground(updateArrFiles);
    });

    // setGoToFileBucket(true);
  };
  async function triggerToast(message, isError) {
    setalertMessage(message);
    setShowToast(true);
    setIsToastError(isError);
    // setGoToFileBucket(true);

    await new Promise((resolve) => {
      setTimeout(() => {
        setShowToast(false);
        resolve();
      }, 1500);
    });
  }

  function handleHideChecklist() {
    if (
      Array.isArray(contractTC) &&
      Array.isArray(archiDrawings) &&
      Array.isArray(engDrawings) &&
      Array.isArray(specifications) &&
      Array.isArray(buildInsurance)
    ) {
      console.log(
        "CHECKING IS:",
        contractTC?.length > 0 &&
          archiDrawings?.length > 0 &&
          engDrawings?.length > 0 &&
          specifications?.length > 0 &&
          buildInsurance?.length > 0
      );
      setHideChecklist(
        contractTC?.length > 0 &&
          archiDrawings?.length > 0 &&
          engDrawings?.length > 0 &&
          specifications?.length > 0 &&
          buildInsurance?.length > 0
      );
      setLoading(false);
    } else {
      setTimeout(() => {
        if (fileCounter.current < 5) {
          setLoading(false);
        }
      }, 1000);
      setHideChecklist(false);
    }
  }

  const fileCounter = useRef(0);
  useEffect(() => {
    if (contractBrief && Object.keys(contractBrief).length > 0) {
      if (
        contractBrief.backgrounds &&
        contractBrief.backgrounds.hasOwnProperty("items")
      ) {
        fileList.forEach(async (file, index) => {
          if (contractBrief.backgrounds.items[index]) {
            let backgroundFilesOpt = await API.graphql({
              query: qlistBackgroundFiles,
              variables: {
                id: contractBrief.backgrounds.items[index].id,
              },
            });

            // let rowFiles = backgroundFilesOpt.data.background?.files?.items;
            // let tempFiles = fileTypeCollection.filter((fileCollection) => {
            // 	for (const rowFile of rowFiles) {
            // 		if (rowFile.id == fileCollection?.id) {
            // 			return true;
            // 		}
            // 	}
            // 	return false;
            // });

            let tempFiles = backgroundFilesOpt.data.background?.files?.items;
            // console.log("FILE IS:", file);
            // console.log("FILE LIST IS:", fileList[0]);
            console.log("BACKGROUND FILE IS:", backgroundFilesOpt);
            // console.log("ROW FILES ARE:", rowFiles);
            console.log("TEMP FILES ARE:", tempFiles);
            if (file == fileList[0]) {
              setContractTC(tempFiles);
            } else if (file == fileList[1]) {
              setArchiDrawings(tempFiles);
            } else if (file == fileList[2]) {
              setEngDrawings(tempFiles);
            } else if (file == fileList[3]) {
              setSpecifications(tempFiles);
            } else if (file == fileList[4]) {
              setBuildInsurance(tempFiles);
            }
            fileCounter.current++;
          }
        });
      }
    }
  }, [contractBrief]);

  useEffect(() => {
    handleHideChecklist();
  }, [contractTC, archiDrawings, engDrawings, specifications, buildInsurance]);

  // useEffect(() => {
  //   console.log("HELLO HERE:", !hideChecklist && !loading);
  //   console.log("CHECKLIST HIDE ISL", hideChecklist);
  //   console.log("LOADING IS:", loading);
  // }, [hideChecklist, loading]);

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    console.log("SETTING DEFAULT COLS", briefs);

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        console.log("columnIds", columnIds);
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);

      console.log("updatedBriefIds", updatedBriefIds);
      const newDefaultColumn = updatedBriefIds?.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      // const newDefaultArrayColumnBriefs = [
      // 	...new Set([...newDefaultColumn, ...briefIds]),
      // ];

      // const newDefaultColumnBriefs = Array.from(
      // 	new Set(newDefaultArrayColumnBriefs.map(JSON.stringify)),
      // 	JSON.parse
      // );

      // if (updatedBriefIds?.length !== 0) {
      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      console.log("Background Table for", clientMatterId, backgroundTable);

      if (backgroundTable?.data?.backgroundTable) {
        await setDefaultColumnBriefs(
          backgroundTable?.data?.backgroundTable,
          briefs
        );
      } else {
        console.log("Create Background Table for ", clientMatterId);

        const initialPresets = [];
        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          initialPresets.push(preset);
        }

        let backgroundCreate = await API.graphql({
          query: BACKGROUND_TABLE_CREATE,
          variables: {
            clientMatterId: clientMatterId,
            initialPresets: initialPresets,
          },
        });
        await setDefaultColumnBriefs(
          backgroundCreate?.data?.backgroundTableCreate,
          briefs
        );
      }
      // }
    } catch (error) {
      console.error(error);
    }
  }

  function getValueById(data, id) {
    const item = data[0]; // Assuming there's only one item in the array (only 1 row per brief)
    const dynamicData = item?.dynamicData;
    const matchingObject = dynamicData?.find((obj) => obj.id === id);

    if (matchingObject) {
      return matchingObject.value;
    } else {
      return null; // ID not found
    }
  }

  function visitBrief(briefId, name) {
    // history.push(`${AppRoutes.BACKGROUND}/${id}`);
    const back_id = "3";

    if (name !== "Progress of work") {
      history.push(
        `${
          AppRoutes.BACKGROUND
        }/${back_id}/${id}/${briefId}/?matter_name=${utf8_to_b64(
          matter_name
        )}&client_name=${utf8_to_b64(client_name)}`
      );
    }
  }

  useEffect(() => {
    console.log("DEFAULT LIST IS:", defaultList);
  }, [defaultList]);

  // BUILDER DETAILS AND CONTRACT DETAILS PANEL
  function formatCurrency(amount, locale = "en-US", currency = "USD") {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    });
    return formatter.format(amount);
  }

  // "Prime Cost";
  //   "";
  //   "Contract Details";
  //   "Builder Details";
  //   "Contracts";
  //   "Progress of work";
  //   "Budget";
  //   "Progress Photos";
  //   "Provisional Sum";
  //   "Invoices";
  //   "Site Diary";
  //   "Variations";

  function countProgress(obj) {
    // console.log("countProgress ---> obj", obj);

    if (!visibleBriefsList.includes("Builder Details"))
      delete obj.builderDetails;

    if (!visibleBriefsList.includes("Contract Details"))
      delete obj.contractDetails;

    if (!visibleBriefsList.includes("Project Location"))
      delete obj.propertyDetails;

    if (!visibleBriefsList.includes("Contracts")) delete obj.contracts;

    if (!visibleBriefsList.includes("Progress of work"))
      delete obj.completionDate;

    if (!visibleBriefsList.includes("Progress Photos"))
      delete obj.propertyPhoto;

    if (!visibleBriefsList.includes("Invoices")) delete obj.invoices;

    if (!visibleBriefsList.includes("Variations")) delete obj.variations;

    const progressCount = Object.keys(obj).reduce(
      (count, key) => (obj[key] === true ? count + 1 : count),
      0
    );
    //console.log("Progress Bar", progressCount);
    const totalProgress = Object.keys(obj).length;
    //console.log("Total Progress", totalProgress);
    const percentage =
      totalProgress > 0 ? (progressCount / totalProgress) * 100 : 0;

    return percentage.toFixed(2);
  }

  useEffect(() => {
    if (defaultList) {
      // Used checking if a file is present in the Contracts table
      const brief_id = defaultList?.find(
        (item) => item.name === "Contracts"
      )?.id;
      const getMatterFiles = async () => {
        try {
          const params = {
            query: qGetBackgroundsByBriefId,
            variables: {
              id: brief_id,
              nextToken: null,
              sortOrder: "DATE_ASC",
              limit: 1000,
            },
          };
          const brief = await API.graphql(params);
          const briefItemsList = brief?.data?.brief?.backgrounds?.items;

          console.log("briefItemsList", briefItemsList);

          if (briefItemsList?.length > 0) {
            const checkIfContracts = briefItemsList.every(
              (item) => item?.files.items.length > 0
            );
            return checkIfContracts;
          } else {
            return false;
          }
          // console.log('Get Started Contract',briefItemsList)
          // console.log('Check if all has files',checkIfContracts)
          // return checkIfContracts;
        } catch (err) {
          console.error(err);
          return false;
        }
      };

      const initializeGetStartedProgress = async () => {
        const contractStatus = await getMatterFiles();
        const propertyPhotoStatus =
          localStorage.getItem("propertyPhoto") === "useExample" ? true : false;

        //console.log('Get Started Contract ',contractStatus)
        const getStartedCompletion = {
          // builderDetails: buildInfoStatus,
          // contractDetails: contractInfoStatus,
          propertyPhoto: propertyPhotoStatus
            ? propertyPhotoStatus
            : (defaultList &&
                defaultList?.find((item) => item.name === "Progress Photos")
                  ?.backgrounds?.items?.length !== 0) ||
              false,
          propertyDetails:
            (defaultList &&
              defaultList?.find((item) => item.name === "Project Location")
                ?.backgrounds?.items?.length !== 0) ||
            false,
          completionDate:
            (defaultList &&
              defaultList?.find((item) => item.name === "Progress of work")
                ?.backgrounds?.items?.length !== 0) ||
            false,
          invoices:
            (defaultList &&
              defaultList?.find((item) => item.name === "Invoices")?.backgrounds
                ?.items?.length !== 0) ||
            false,
          variations:
            (defaultList &&
              defaultList?.find((item) => item.name === "Variations")
                ?.backgrounds?.items?.length !== 0) ||
            false,
          contracts: contractStatus,
        };
        // console.log(getStartedCompletion, "Current Progress");
        setGetStartedProgress((obj) => ({
          ...obj,
          ...getStartedCompletion,
        }));
      };
      console.log("project location", defaultList);
      initializeGetStartedProgress();
    }
  }, [defaultList]);

  useEffect(() => {
    const buildInfoStatus =
      buildInfo &&
      !Object.values(buildInfo)?.every((info) => info === null || info === "");
    const contractInfoStatus =
      contractInfo &&
      !Object.values(contractInfo)?.every(
        (info) => info === null || info === ""
      );

    if (buildInfoStatus && !getStartedProgress.builderDetails) {
      setGetStartedProgress((obj) => ({
        ...obj,
        builderDetails: buildInfoStatus,
      }));
    }
    if (contractInfoStatus && !getStartedProgress.contractDetails) {
      setGetStartedProgress((obj) => ({
        ...obj,
        contractDetails: contractInfoStatus,
      }));
    }
  }, [buildInfo, contractInfo]);
  // END OF BUILDER DETAILS AND CONTRACT DETAILS PANEL

  // Get Dynamic Data of Rows on Tiles
  const excludeFixedTiles = [
    "Builder Details",
    "Contract Details",
    "Progress Photos",
    "Progress of work",
    "Project Location",
    "Contracts",
    "Site Diary",
    "Provisional Sum",
    "Budget",
    "Invoices",
    "Variations",
    "Prime Cost",
  ];

  useEffect(() => {
    if (defaultList && defaultList.length > 0) {
      // Check if defaultList is not null and not empty
      const filteredDefaultList = defaultList.filter(
        (item) =>
          !excludeFixedTiles.includes(item) && !item.name.startsWith("_")
      );

      filteredDefaultList.forEach((item) => {
        API.graphql({
          query: BACKGROUND_BY_BRIEF_ID_QUERY,
          variables: {
            id: item.id,
            nextToken: null,
            sortOrder: "ORDER_ASC",
            limit: 5,
          },
        })
          .then((backgroundOpt) => {
            if (backgroundOpt.data.brief.backgrounds?.items !== null) {
              var result = backgroundOpt.data.brief.backgrounds?.items.map(
                ({
                  id,
                  description,
                  date,
                  createdAt,
                  order,
                  files,
                  dynamicData,
                }) => ({
                  createdAt: createdAt,
                  id: id,
                  description: description,
                  date: date,
                  order: order,
                  files: files,
                  dynamicData: dynamicData,
                })
              );

              setDynamicTileRows((prevState) => ({
                ...prevState,
                [item.id]: sortByOrder(result),
              }));
            }
          })
          .catch((error) => {
            console.error("Error fetching dynamic backgrounds:", error);
          });
      });
    }
  }, [defaultList]); // Run the effect when defaultList changes

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Set loading to false after 1 second

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('load', handleLoad);
    };
  }, [defaultPhases]);

  return userInfo ? (
    <>
      {showToast && (
        <ToastNotification title={alertMessage} error={isToastError} />
      )}
      <div>
        <ScrollToTop
          smooth
          component={
            <BiArrowToTop
              style={{ color: "white", display: "block", margin: "auto" }}
            />
          }
          className="sm:hidden scrollButton"
          style={{ borderRadius: "50%" }}
        />

        {/* MAIN DIV */}
        <div className="flex-none p-0 bg-white h-auto">
          <div className="flex flex-col gap-8 font-open-sans bg-white rounded-lg">
            {/* HEADER */}
            {/* <div className="overflow-hidden bg-gray-100 border flex rounded-2xl">
							<div className="flex-1 pl-8 py-14">
								<div className="flex flex-col items-start justify-start w-full h-auto gap-3">
									<div className="text-lg font-semibold text-gray-900">
										{`Good day, ${userInfo.firstName}!`}
									</div>
								</div>
							</div>
							<div className="flex-1">
								<img
									className="h-full"
									src={dashboardGradient}
									alt="rightside-illustration"
								/>
							</div>
						</div> */}
            {/* END OF HEADER */}
            {width > 844 ? (
              // web main header
              <div
                className={`overflow-hidden bg-gradient-to-r from-white via-gray-100 to-blue-100 flex flex-row justify-between items-end rounded-2xl p-12`}
              >
                <div className="w-auto">
                  {/* CIRCLE DESIGN IN LOGO */}
                  <div className="relative">
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.75)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.50)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.25)",
                      }}
                    ></div>
                  </div>

                  <p className="font-semibold text-base mb-4 relative">
                    Welcome to your home construction dashboard!<br></br>
                  </p>
                  <p className="mb-2 relative">
                    You have{" "}
                    <span className="text-red-500 font-semibold">
                      {Object.keys(getStartedProgress).length -
                        Object.keys(getStartedProgress).reduce(
                          (count, key) =>
                            getStartedProgress[key] === true
                              ? count + 1
                              : count,
                          0
                        )}
                    </span>{" "}
                    outstanding actions to complete. Make sure to complete{" "}
                    <br></br>
                    the 'Getting Started with HBA' steps located at the top of
                    your page to <br></br>
                    optimise your experience.
                  </p>
                  <div className="relative">
                    <ProgressBar
                      completed={countProgress(getStartedProgress)}
                      bgColor="#1e3a8a"
                      height="10px"
                      animateOnRender={true}
                      labelSize="10px"
                      labelAlignment="outside"
                      labelClassName="text-black ml-2"
                      customLabel={`${countProgress(
                        getStartedProgress
                      )}% Completed`}
                      width="73%"
                    />
                  </div>
                </div>
                <div className="flex items-end">
                  {visibleBriefsList.includes("Builder Details") ? (
                    <div className="w-52 justify-start ">
                      {(buildInfo && buildInfo?.builderName) ||
                      buildInfo?.builderContactName ||
                      buildInfo?.builderContactEmail ||
                      buildInfo?.builderContactNumber ||
                      builderLogo ? (
                        <>
                          {builderLogo ? (
                            <img
                              src={builderLogo}
                              className="w-5 h-5 mb-2"
                              alt="logo"
                            ></img>
                          ) : (
                            <FaIcons.FaSquare className="mb-2" />
                          )}
                          <p className="font-semibold mb-2 text-xs">
                            {buildInfo?.builderName
                              ? `${buildInfo?.builderName}`
                              : ""}
                          </p>
                          <p className="text-xs">
                            {buildInfo?.builderContactName
                              ? `${buildInfo?.builderContactName}`
                              : ""}
                          </p>
                          <p className="text-xs">
                            {buildInfo?.builderContactEmail
                              ? `${buildInfo?.builderContactEmail}`
                              : ""}
                          </p>
                          <p className="text-xs">
                            {buildInfo?.builderContactNumber
                              ? `${buildInfo?.builderContactNumber}`
                              : ""}
                          </p>
                          <span
                            className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.BUILDER_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Edit
                          </span>
                        </>
                      ) : (
                        <>
                          <p className="text-xs text-gray-400">
                            Help us keep track of <br></br>
                            your building progress by <br></br>
                            providing us with your builder's <br></br>
                            contact details
                          </p>
                          <span
                            className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.BUILDER_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Add
                          </span>
                        </>
                      )}
                    </div>
                  ) : null}

                      <div>
                        {isLoading ? (
                          null
                        ) : (
                          <div>
                            {visibleBriefsList.includes("Contract Details") ? (
                              <div className="ml-8">
                                {contractInfo?.contractStartDate ||
                                contractInfo?.contractPrice ||
                                contractInfo?.contractValue ? (
                                  <>
                                    <LiaIcons.LiaFileContractSolid className="mb-4" />
                                    <div className="flex">
                                      <div>
                                        <p className="text-xs font-semibold">
                                          Contract Start Date:{" "}
                                        </p>
                                        {/* <p className="text-xs font-semibold">
                                          Days to Completion:{" "}
                                        </p> */}
                                        <p className="text-xs font-semibold">
                                          Contract Price:{" "}
                                        </p>
                                        {/* <p className="text-xs font-semibold">
                                          Contract Value:{" "}
                                        </p> */}
                                      </div>
                                      <div className="ml-4">
                                        <p className="text-xs text-right">
                                          {contractInfo.contractStartDate
                                            ? dateFormatter(
                                                contractInfo.contractStartDate
                                              )
                                            : "Day Month Year"}
                                        </p>
                                        {/* <p className="text-xs text-right">509</p> */}
                                        <p className="text-xs text-right">
                                          {contractInfo.contractPrice &&
                                          contractInfo.contractPrice !== null
                                            ? formatCurrency(contractInfo.contractPrice)
                                            : formatCurrency(0)}
                                        </p>
                                        {/* <p className="text-xs text-right">
                                          {contractInfo.contractPrice && contractInfo.contractValue !== null
                                            ? formatCurrency(contractInfo.contractValue)
                                            : formatCurrency(0)}
                                        </p> */}
                                      </div>
                                    </div>

                                    <span
                                      className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                                      onClick={(e) => {
                                        setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                                        setShowRightPanel(true);
                                      }}
                                    >
                                      Edit
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <p className="text-xs text-gray-400">
                                      Provide your contract details to <br></br>
                                      allow us to assist you in tracking <br></br>
                                      important milestones and dates in <br></br>
                                      your construction journey
                                    </p>
                                    <span
                                      className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                                      onClick={(e) => {
                                        setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                                        setShowRightPanel(true);
                                      }}
                                    >
                                      Add
                                    </span>
                                  </>
                                )}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                </div>
              </div>
            ) : (
              //mobile main header
              <div
                className={`overflow-hidden bg-gradient-to-r from-white via-gray-100 to-blue-100 flex flex-col rounded-2xl p-8`}
              >
                <div className="w-auto">
                  {/* CIRCLE DESIGN IN LOGO */}
                  <div className="relative">
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.75)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.50)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.25)",
                      }}
                    ></div>
                  </div>

                  <p className="font-semibold text-base mb-2 relative font-open-sans">
                    Welcome to your home construction dashboard!<br></br>
                  </p>
                  <p className="mb-2 relative text-xs font-open-sans font-medium">
                    You have{" "}
                    <span className="text-red-500 text-xs font-bold">
                      {Object.keys(getStartedProgress).length -
                        Object.keys(getStartedProgress).reduce(
                          (count, key) =>
                            getStartedProgress[key] === true
                              ? count + 1
                              : count,
                          0
                        )}
                    </span>{" "}
                    outstanding actions to complete. Tackling this task will
                    provide you with a clearer, more organised view of your
                    project's progress.
                  </p>
                  <div className="relative">
                    <ProgressBar
                      completed={countProgress(getStartedProgress)}
                      bgColor="#1e3a8a"
                      height="8px"
                      animateOnRender={true}
                      labelSize="10px"
                      labelAlignment="outside"
                      labelClassName="text-black ml-2 text-xs font-normal font-open-sans"
                      customLabel={`${countProgress(
                        getStartedProgress
                      )}% Completed`}
                      width="60%"
                    />
                  </div>
                </div>
                {!headerRead ? (
                  //read more button
                  <Button
                    variant={"hbBlue"}
                    className="py-2 cursor-pointer mt-8"
                    block={"full"}
                    onClick={() => setHeaderRead(!headerRead)}
                  >
                    <div className="flex items-center">
                      <span className="font-open-sans">Read more</span>
                      <FaChevronRight className="ml-2 text-sm" />
                    </div>
                  </Button>
                ) : (
                  <div className="flex flex-col mt-8">
                    <div className=" ">
                      {(buildInfo && buildInfo?.builderName) ||
                      buildInfo?.builderContactName ||
                      buildInfo?.builderContactEmail ||
                      buildInfo?.builderContactNumber ||
                      builderLogo ? (
                        <>
                          {builderLogo ? (
                            <img
                              src={builderLogo}
                              className="w-7 h-7"
                              alt="logo"
                            ></img>
                          ) : (
                            <FaIcons.FaSquare className="" />
                          )}
                          <p className="font-semibold mt-2 text-xs mb-1 font-open-sans">
                            {buildInfo?.builderName
                              ? `${buildInfo?.builderName}`
                              : ""}
                          </p>
                          <p className="text-xs mb-1 font-normal font-open-sans">
                            {buildInfo?.builderContactName
                              ? `${buildInfo?.builderContactName}`
                              : ""}
                          </p>
                          <p className="text-xs mb-1 font-normal font-open-sans">
                            {buildInfo?.builderContactEmail
                              ? `${buildInfo?.builderContactEmail}`
                              : ""}
                          </p>
                          <p className="text-xs mb-1 font-normal font-open-sans">
                            {buildInfo?.builderContactNumber
                              ? `${buildInfo?.builderContactNumber}`
                              : ""}
                          </p>

                          <span
                            className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.BUILDER_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Edit
                          </span>
                        </>
                      ) : (
                        <div className="flex flex-row justify-between font-open-sans items-center">
                          <p className="text-xs font-normal text-gray-500 font-open-sans">
                            Help us keep track of your building progress by
                            providing us with your builder's contact details
                          </p>
                          <Button
                            variant={"hbBlue"}
                            className="cursor-pointer text-xs px-4 py-2 rounded-lg ml-6"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.BUILDER_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      {contractInfo?.contractStartDate ||
                      contractInfo?.contractPrice ||
                      contractInfo?.contractValue ? (
                        <div>
                          <img src={contractsIcon} alt="Contracts Icon" />
                          <div className="flex flex-row justify-between mt-2">
                            <div>
                              <p className="text-xs mb-1 font-semibold">
                                Contract Start Date:
                              </p>
                              {/* <p className="text-xs mb-1 font-semibold">
                                Days to Completion:
                              </p> */}
                              <p className="text-xs mb-1 font-semibold">
                                Contract Price:
                              </p>
                              {/* <p className="text-xs mb-1 font-semibold">
                                Contract Value:
                              </p> */}
                            </div>
                            <div className="ml-4">
                              <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                {contractInfo.contractStartDate
                                  ? dateFormatter(
                                      contractInfo.contractStartDate
                                    )
                                  : "Day Month Year"}
                              </p>
                              {/* <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                509
                              </p> */}
                              <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                {contractInfo.contractPrice !== null
                                  ? formatCurrency(contractInfo.contractPrice)
                                  : formatCurrency(0)}
                              </p>
                              {/* <p className="text-xs font-normal text-right font-open-sans">
                                {contractInfo.contractValue !== null
                                  ? formatCurrency(contractInfo.contractValue)
                                  : formatCurrency(0)}
                              </p> */}
                            </div>
                          </div>

                          <span
                            className="text-xs text-blue-900 font-medium cursor-pointer hover:text-blue-400"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Edit
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-row justify-between font-open-sans items-center">
                          <p className="text-xs font-normal text-gray-500 font-open-sans">
                            Provide your contract details to allow us to assist
                            you in tracking important milestones and dates in
                            your construction journey
                          </p>
                          <Button
                            variant={"hbBlue"}
                            className="cursor-pointer text-xs px-4 py-2 rounded-lg ml-6"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                    <Button
                      variant={"hbBlue"}
                      className="py-2 cursor-pointer mt-8"
                      block={"full"}
                      onClick={() => setHeaderRead(!headerRead)}
                    >
                      <div className="flex items-center">
                        <span className="font-open-sans">Read less</span>
                        <FaChevronDown className="ml-2 text-sm" />
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              // end of mobile main header
            )}

            {/* TABS */}
            <div className="w-full pb-3 pt-3 overflow-x-scroll overflow-y-hidden h-11 p-1 bg-gray-200 rounded-lg border border-gray-200 justify-start items-center gap-2 inline-flex">
              <div
                className={`px-2 py-2 hover:bg-white hover:shadow rounded-md ${
                  !activePhase ? "bg-white shadow" : ""
                } justify-center items-center gap-1 flex`}
              >
                <div
                  className={
                    "text-blue-950 text-sm font-normal font-open-sans leading-tight"
                  }
                >
                  <span onClick={() => resetPhases()}>
                    {isMobileDevice(width) ? "All" : "All Phases"}
                  </span>
                </div>
              </div>

              {phases.map((e) => {
                return (
                  <div
                    key={`phase-${e.name}`}
                    className={`px-2 py-2 hover:bg-white hover:shadow rounded-md ${
                      activePhase === e.label ? "bg-white shadow" : ""
                    } justify-center items-center gap-1 flex`}
                  >
                    <div className="text-blue-950 text-sm font-normal font-open-sans leading-tight">
                      <span
                        className="block"
                        onClick={() => setActivePhase(e.label)}
                      >
                        {isMobileDevice(width) && e.label === "Pre Construction"
                          ? "PreConstruction"
                          : e.label}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* END OF TABS */}

            {/* BRIEF TILES */}
            {defaultList && !loading ? (
              <>
                <div
                  id="hba-tiles"
                  className={`grid ${
                    isMobileDevice(width)
                      ? "grid-cols-1 px-1 py-1"
                      : `md:grid-cols-${
                          activePhase === "Pre Construction" ||
                          activePhase === "Handover"
                            ? "3"
                            : "4"
                        } gap-4 px-3 py-3`
                  } bg-gray-100 border`}
                >
                  {/* 1ST COLUMN: ALL PHASES */}
                  <div
                    id="1st-all-phases"
                    className={
                      activePhase === "" ? "col-span-1 row-span-1" : "hidden"
                    }
                  >
                    {/* 1. Email Integration */}
                    {displayInbox ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}

                    {/* 2. Site Diary */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Site Diary" &&
                        visibleBriefsList.includes("Site Diary")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <SiteDiary
                              briefId={item.id}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${3}/${id}/${
                                item.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(
                                client_name
                              )}&url=hba`}
                              getBackgrounds={getBackgrounds}
                              background={background}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Tasklist */}
                    {displayTasklist ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <TaskList redirectUrl={AppRoutes.TASKS} />
                      </div>
                    ) : null}

                    {/* 4. Progress of Work */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Progress of work" &&
                        visibleBriefsList.includes("Progress of work")
                      ) {
                        return (
                          <div
                            key={`brief-${item.id}`}
                            className={"col-span-1 row-span-1 mb-4"}
                            data-info={item.id}
                          >
                            <div onClick={() => visitBrief(item.id, item.name)}>
                              <ProgressOfWork
                                items={item}
                                completionDateRef={completionDateRef}
                                redirectUrl={`${
                                  AppRoutes.BACKGROUND
                                }/${3}/${id}/${
                                  item.id
                                }/?matter_name=${utf8_to_b64(
                                  matter_name
                                )}&client_name=${utf8_to_b64(client_name)}`}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 5. Contracts */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Contracts" &&
                        visibleBriefsList.includes("Contracts")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <div className="rounded cursor-pointer w-full">
                              <Contracts
                                visitBrief={visitBrief}
                                uploadContractsRef={uploadContractsRef}
                                briefDetails={defaultList.filter((item) => {
                                  return item?.name == "Contracts";
                                })}
                                isComplete={
                                  contractTC?.length > 0 &&
                                  archiDrawings?.length > 0 &&
                                  engDrawings?.length > 0 &&
                                  specifications?.length > 0 &&
                                  buildInsurance?.length > 0
                                }
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* END OF 1ST COLUMN: ALL PHASES */}

                  {/* 1ST COLUMN: PRE-CONSTRUCTION */}
                  <div
                    id="1st-pre-construction"
                    className={
                      activePhase === "Pre Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                              id={
                                activePhase === "Pre Construction"
                                  ? "ProjectLocation"
                                  : "pre-construction-project-location"
                              }
                              ref={propertyDetailsRef}
                            >
                              <ProjectLocation
                                projectLocationBrief={projectLocationBrief}
                                setProjectLocationBrief={
                                  setProjectLocationBrief
                                }
                                userInfo={userInfo}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                                invalidFiles={invalidFiles}
                                rejectFiles={rejectFiles}
                                clientMatterId={id}
                                projectLocationPhoto={projectLocationPhoto}
                                triggerToast={triggerToast}
                                modalChoicesEnum={modalChoicesEnum}
                                setModalState={setModalState}
                                setShowRightPanel={setShowRightPanel}
                                isInitDone={isInitDone}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Budget */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Budget" &&
                        visibleBriefsList.includes("Budget")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            key={`brief-${item.id}`}
                            data-info={item.id}
                          >
                            <Budget
                              briefId={item.id}
                              // provisionalSumRef={provisionalSumRef}
                              budgetRef={budgetRef}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${3}/${id}/${
                                item.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(client_name)}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Contracts */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Contracts" &&
                        visibleBriefsList.includes("Contracts")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <div className="rounded cursor-pointer w-full">
                              <Contracts
                                visitBrief={visitBrief}
                                uploadContractsRef={uploadContractsRef}
                                briefDetails={defaultList.filter((item) => {
                                  return item?.name == "Contracts";
                                })}
                                isComplete={
                                  contractTC?.length > 0 &&
                                  archiDrawings?.length > 0 &&
                                  engDrawings?.length > 0 &&
                                  specifications?.length > 0 &&
                                  buildInsurance?.length > 0
                                }
                                onClick={() => {
                                  console.log("clicked");
                                }}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* END OF 1ST COLUMN: PRE-CONSTRUCTION */}

                  {/* 1ST COLUMN: CONSTRUCTION */}
                  <div
                    id="1st-construction"
                    className={
                      activePhase === "Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Email Integration */}
                    {displayInbox ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}

                    {/* 2. Site Diary */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Site Diary" &&
                        visibleBriefsList.includes("Site Diary")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <SiteDiary
                              briefId={item.id}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${3}/${id}/${
                                item.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(
                                client_name
                              )}&url=hba`}
                              getBackgrounds={getBackgrounds}
                              background={background}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Tasklist */}
                    {displayTasklist ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <TaskList redirectUrl={AppRoutes.TASKS} />
                      </div>
                    ) : null}

                    {/* 4. Defects list */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            item.name === "Defects List" && (
                              <div
                                key={item.id}
                                className={"col-span-1 row-span-1 mb-4"}
                                onClick={() => visitBrief(item.id, item.name)}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 1ST COLUMN: CONSTRUCTION */}

                  {/* 1ST COLUMN: HANDOVER */}
                  <div
                    id="1st-handover"
                    className={
                      activePhase === "Handover"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                              id={
                                activePhase === "Handover"
                                  ? "ProjectLocation"
                                  : "handover-project-location"
                              }
                              ref={propertyDetailsRef}
                            >
                              <ProjectLocation
                                projectLocationBrief={projectLocationBrief}
                                setProjectLocationBrief={
                                  setProjectLocationBrief
                                }
                                userInfo={userInfo}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                                invalidFiles={invalidFiles}
                                rejectFiles={rejectFiles}
                                clientMatterId={id}
                                projectLocationPhoto={projectLocationPhoto}
                                triggerToast={triggerToast}
                                modalChoicesEnum={modalChoicesEnum}
                                setModalState={setModalState}
                                setShowRightPanel={setShowRightPanel}
                                isInitDone={isInitDone}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Email Integration */}
                    {displayInbox ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/* END OF 1ST COLUMN: HANDOVER */}

                  {/* 2ND AND 3RD COLUMN: ALL PHASES */}
                  {/* THIS IS SHARED FOR ALL PHASES AND CONSTRUCTION */}
                  <div
                    id="2nd-all-phases-construction"
                    className={
                      activePhase === "" || activePhase === "Construction"
                        ? "col-span-1 md:col-span-2"
                        : "hidden"
                    }
                  >
                    {/* 1. Progress photos (Stretch) */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Progress Photos" &&
                        visibleBriefsList.includes("Progress Photos")
                      ) {
                        return (
                          <div
                            className="col-span-3 md:col-span-3 sm:col-span-1 row-span-1"
                            ref={propertyPhotoRef}
                            id={
                              activePhase === "" ||
                              activePhase === "Construction"
                                ? "ProgressPhoto"
                                : "all-phases-construction-progress-photos"
                            }
                          >
                            <ProgressPhotos
                              //visitBrief={visitBrief}
                              progressPhotosDetails={progressPhotosDetails}
                              setShowRightPanel={setShowRightPanel}
                              setModalState={setModalState}
                              modalChoicesEnum={modalChoicesEnum}
                              progressPhotoBrief={progressPhotoBrief}
                              handleUploadLink={handleUploadLink}
                              handleProgressPhotoUpload={
                                handleProgressPhotoUpload
                              }
                              matterId={matterId}
                              uploadImage={uploadImage}
                              setUploadImage={setUploadImage}
                              imageURL={imageURL}
                              setImageURL={setImageURL}
                              datePicked={datePicked}
                              setDatePicked={setDatePicked}
                              description={description}
                              setDescription={setDescription}
                              uploadedFiles={uploadedFiles}
                              setUploadedFiles={setUploadedFiles}
                              invalidFiles={invalidFiles}
                              setInvalidFiles={setInvalidFiles}
                              selectedFiles={selectedFiles}
                              // _setSelectedFiles={_setSelectedFiles}
                              uploadStart={uploadStart}
                              setUploadStart={setUploadStart}
                              photos={photos}
                              setPhotos={setPhotos}
                              handleChangeGetStartedProgress={
                                handleChangeGetStartedProgress
                              }
                              handleUpload={handleUpload}
                              rejectFiles={rejectFiles}
                              saveButtonClicked={saveButtonClicked}
                              clientMatterId={id}
                              isImageFile={isImageFile}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${3}/${id}/${
                                items.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(client_name)}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    <div className="grid sm:grid-cols-2 gap-4">
                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 2.1 Invoices */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Invoices" &&
                            visibleBriefsList.includes("Invoices")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                <div
                                  onClick={(e) =>
                                    visitBrief(items.id, items.name)
                                  }
                                >
                                  <Invoices
                                    items={items}
                                    invoicesRef={invoicesRef}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${3}/${id}/${
                                      items.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}

                        {/* 2.2 Budget */}
                        {defaultList?.map((item) => {
                          if (
                            item.name === "Budget" &&
                            visibleBriefsList.includes("Budget")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${item.id}`}
                                data-info={item.id}
                              >
                                <Budget
                                  briefId={item.id}
                                  // provisionalSumRef={provisionalSumRef}
                                  budgetRef={budgetRef}
                                  redirectUrl={`${
                                    AppRoutes.BACKGROUND
                                  }/${3}/${id}/${
                                    item.id
                                  }/?matter_name=${utf8_to_b64(
                                    matter_name
                                  )}&client_name=${utf8_to_b64(client_name)}`}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>

                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 3.1 Variations */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Variations" &&
                            visibleBriefsList.includes("Variations")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                <div
                                  onClick={(e) =>
                                    visitBrief(items.id, items.name)
                                  }
                                >
                                  <Variations
                                    briefId={items.id}
                                    variationsRef={variationsRef}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${3}/${id}/${
                                      items.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}

                        {/* 3.2 Provisional Sum */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Provisional Sum" &&
                            visibleBriefsList.includes("Provisional Sum")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                <div
                                  onClick={(e) =>
                                    visitBrief(items.id, items.name)
                                  }
                                >
                                  <ProvisionalSum
                                    briefId={items.id}
                                    provisionalSumRef={provisionalSumRef}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${3}/${id}/${
                                      items.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}

                        {/* 3.3 Prime Cost */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Prime Cost" &&
                            visibleBriefsList.includes("Prime Cost")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                <div
                                  onClick={(e) =>
                                    visitBrief(items.id, items.name)
                                  }
                                >
                                  <PrimeCost
                                    briefId={items.id}
                                    primeCostRef={primeCostRef}
                                    briefName={items.name}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${3}/${id}/${
                                      items.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </div>
                  {/* END OF 2ND AND 3RD COLUMN: ALL PHASES AND CONSTRUCTION */}

                  {/* 2ND COLUMN: PRE CONSTRUCTION */}
                  <div
                    id="2nd-pre-construction"
                    className={
                      activePhase === "Pre Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Email Integration */}
                    {displayInbox ? (
                      <div className={"col-span-1  row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}

                    {/* 2. Tasklist */}
                    {displayTasklist ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <TaskList redirectUrl={AppRoutes.TASKS} />
                      </div>
                    ) : null}

                    {/* 3. Developmental approvals */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            item.name === "Development Approvals" && (
                              <div
                                key={item.id}
                                className={"col-span-1 row-span-1 mb-4"}
                                onClick={() => visitBrief(item.id, item.name)}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 2ND COLUMN: PRE CONSTRUCTION */}

                  {/* 2ND COLUMN: HANDOVER */}
                  <div
                    id="2nd-handover"
                    className={
                      activePhase === "Handover"
                        ? "col-span-3 sm:col-span-2 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Progress photos (Stretch) */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Progress Photos" &&
                        visibleBriefsList.includes("Progress Photos")
                      ) {
                        return (
                          <div
                            className="col-span-3 md:col-span-3 sm:col-span-1 row-span-1"
                            ref={propertyPhotoRef}
                            id={
                              activePhase === "Handover"
                                ? "ProgressPhoto"
                                : "handover-progress-photos"
                            }
                          >
                            <ProgressPhotos
                              //visitBrief={visitBrief}
                              progressPhotosDetails={progressPhotosDetails}
                              setShowRightPanel={setShowRightPanel}
                              setModalState={setModalState}
                              modalChoicesEnum={modalChoicesEnum}
                              progressPhotoBrief={progressPhotoBrief}
                              handleUploadLink={handleUploadLink}
                              handleProgressPhotoUpload={
                                handleProgressPhotoUpload
                              }
                              matterId={matterId}
                              uploadImage={uploadImage}
                              setUploadImage={setUploadImage}
                              imageURL={imageURL}
                              setImageURL={setImageURL}
                              datePicked={datePicked}
                              setDatePicked={setDatePicked}
                              description={description}
                              setDescription={setDescription}
                              uploadedFiles={uploadedFiles}
                              setUploadedFiles={setUploadedFiles}
                              invalidFiles={invalidFiles}
                              setInvalidFiles={setInvalidFiles}
                              selectedFiles={selectedFiles}
                              // _setSelectedFiles={_setSelectedFiles}
                              uploadStart={uploadStart}
                              setUploadStart={setUploadStart}
                              photos={photos}
                              setPhotos={setPhotos}
                              handleChangeGetStartedProgress={
                                handleChangeGetStartedProgress
                              }
                              handleUpload={handleUpload}
                              rejectFiles={rejectFiles}
                              saveButtonClicked={saveButtonClicked}
                              clientMatterId={id}
                              isImageFile={isImageFile}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${3}/${id}/${
                                items.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(client_name)}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    <div className="grid sm:grid-cols-2 gap-4">
                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 2.1 Defects List */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name === "Defects List" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                    onClick={() =>
                                      visitBrief(item.id, item.name)
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}

                        {/* 2.2 Insurance */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name === "Insurance" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                    onClick={() =>
                                      visitBrief(item.id, item.name)
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}
                      </div>

                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 3.1. Waranties and certifications */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name ===
                                  "Warranties and Certifications" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                    onClick={() =>
                                      visitBrief(item.id, item.name)
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}

                        {/* 3.2 certificates */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name === "Certificates" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                    onClick={() =>
                                      visitBrief(item.id, item.name)
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}
                      </div>
                    </div>
                  </div>
                  {/* END OF 2ND COLUMN: HANDOVER */}

                  {/* 3RD COLUMN: PRE CONSTRUCTION */}
                  <div
                    id="3rd-pre-construction"
                    className={
                      activePhase === "Pre Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Other dynamic tiles */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            (item.name === "Tenders" ||
                              item.name === "Building Permits" ||
                              item.name === "Insurance") && (
                              <div
                                key={item.id}
                                className={"col-span-1 row-span-1 mb-4"}
                                onClick={() => visitBrief(item.id, item.name)}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 3RD COLUMN: PRE CONSTRUCTION */}

                  {/* 4TH COLUMN: ALL PHASES */}
                  <div
                    id="4th-all-phases"
                    className={
                      activePhase === "" ? "col-span-1 row-span-1" : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                              id={
                                activePhase === ""
                                  ? "ProjectLocation"
                                  : "all-phases-project-location"
                              }
                              ref={propertyDetailsRef}
                            >
                              <ProjectLocation
                                projectLocationBrief={projectLocationBrief}
                                setProjectLocationBrief={
                                  setProjectLocationBrief
                                }
                                userInfo={userInfo}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                                invalidFiles={invalidFiles}
                                rejectFiles={rejectFiles}
                                clientMatterId={id}
                                projectLocationPhoto={projectLocationPhoto}
                                triggerToast={triggerToast}
                                modalChoicesEnum={modalChoicesEnum}
                                setModalState={setModalState}
                                setShowRightPanel={setShowRightPanel}
                                isInitDone={isInitDone}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Other dynamic tiles */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) && (
                              <div
                                key={item.id}
                                className={
                                  "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                }
                                onClick={() => visitBrief(item.id, item.name)}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 4TH COLUMN: ALL PHASES */}

                  {/* 4TH COLUMN: CONSTRUCTION */}
                  <div
                    id="4th-construction"
                    className={
                      activePhase === "Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                              id={
                                activePhase === "Construction"
                                  ? "ProjectLocation"
                                  : "construction-project-location"
                              }
                              ref={propertyDetailsRef}
                            >
                              <ProjectLocation
                                projectLocationBrief={projectLocationBrief}
                                setProjectLocationBrief={
                                  setProjectLocationBrief
                                }
                                userInfo={userInfo}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                                invalidFiles={invalidFiles}
                                rejectFiles={rejectFiles}
                                clientMatterId={id}
                                projectLocationPhoto={projectLocationPhoto}
                                triggerToast={triggerToast}
                                modalChoicesEnum={modalChoicesEnum}
                                setModalState={setModalState}
                                setShowRightPanel={setShowRightPanel}
                                isInitDone={isInitDone}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Progress of Work */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Progress of work" &&
                        visibleBriefsList.includes("Progress of work")
                      ) {
                        return (
                          <div
                            key={`brief-${item.id}`}
                            className={"col-span-1 row-span-1 mb-4"}
                            data-info={item.id}
                          >
                            <div onClick={() => visitBrief(item.id, item.name)}>
                              <ProgressOfWork
                                items={item}
                                completionDateRef={completionDateRef}
                                redirectUrl={`${
                                  AppRoutes.BACKGROUND
                                }/${3}/${id}/${
                                  item.id
                                }/?matter_name=${utf8_to_b64(
                                  matter_name
                                )}&client_name=${utf8_to_b64(client_name)}`}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Other dynamic tiles */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            (item.name === "Work Safety Compliance" ||
                              item.name ===
                                "Warranties and Certifications") && (
                              <div
                                key={item.id}
                                className={
                                  "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                }
                                onClick={() => visitBrief(item.id, item.name)}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}

                    {/* 4. Contracts */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Contracts" &&
                        visibleBriefsList.includes("Contracts")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <div className="rounded cursor-pointer w-full">
                              <Contracts
                                visitBrief={visitBrief}
                                uploadContractsRef={uploadContractsRef}
                                briefDetails={defaultList.filter((item) => {
                                  return item?.name == "Contracts";
                                })}
                                isComplete={
                                  contractTC?.length > 0 &&
                                  archiDrawings?.length > 0 &&
                                  engDrawings?.length > 0 &&
                                  specifications?.length > 0 &&
                                  buildInsurance?.length > 0
                                }
                                onClick={() => {
                                  console.log("clicked");
                                }}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* END OF 4TH COLUMN: CONSTRUCTION */}
                </div>
              </>
            ) : (
              <>
                <div className="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
                  {[...Array(12).keys()].map((data, index) => {
                    return (
                      <div
                        className="col-span-1 sm:col-span-1 row-span-1 bg-gray-100 rounded-lg p-5 hover:shadow-md transition-shadow h-56"
                        key={`loading-${index}`}
                      >
                        <div className="z-20">
                          <div className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none z-20">
                            <div className="dropdown">
                              <button
                                className={
                                  "bg-gray-100 p-0.5 sm:p-0 text-gray-300 sm:text-gray-300 font-semibold rounded inline-flex "
                                }
                                style={{
                                  borderRadius: "50%",
                                }}
                              >
                                <IoIcons.IoEllipsisVertical />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-1">
                              <SkeletonTheme width={"48px"} height={"48px"}>
                                <Skeleton circle={true} />
                              </SkeletonTheme>
                            </div>
                            <br />
                            <br />

                            <div className="col-span-3 grid place-self-end">
                              <Skeleton count={1} />
                            </div>
                          </div>
                          <h4
                            tabIndex="0"
                            className="focus:outline-none text-gray-800 dark:text-gray-100 font-semibold sm:font-bold"
                          >
                            <SkeletonTheme width={"300px"}>
                              <Skeleton count={2} />
                            </SkeletonTheme>
                          </h4>
                          <p
                            tabIndex="0"
                            className="focus:outline-none text-gray-400 sm:text-gray-800 dark:text-gray-100 text-sm mb-3"
                          >
                            <SkeletonTheme width={"250px"}>
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {/* END OF BRIEF TILES */}
          </div>

          {showRightPanel ? (
            <RightPanel
              modalChoicesEnum={modalChoicesEnum}
              setShowRightPanel={setShowRightPanel}
              buildInfo={buildInfo}
              setBuildInfo={setBuildInfo}
              contractInfo={contractInfo}
              setContractInfo={setContractInfo}
              whichModal={modalState}
              progressPhotoBrief={progressPhotoBrief}
              matterId={matterId}
              uploadImage={uploadImage}
              setUploadImage={setUploadImage}
              imageURL={imageURL}
              setImageURL={setImageURL}
              handleUploadLink={handleUploadLink}
              handleProgressPhotoUpload={handleProgressPhotoUpload}
              handleUpload={handleUpload}
              datePicked={datePicked}
              setDatePicked={setDatePicked}
              description={description}
              setDescription={setDescription}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              logoURL={logoURL}
              setLogoURL={setLogoURL}
              clientMatterId={id}
              saveButtonClicked={saveButtonClicked}
              handleChangeGetStartedProgress={handleChangeGetStartedProgress}
              handleLogoDrop={handleLogoDrop}
              builderLogo={builderLogo}
              setBuilderLogo={setBuilderLogo}
              logo={logo}
              setLogo={setLogo}
              selectedFiles={selectedFiles}
              // setSelectedFiles={setSelectedFiles}
              // _setSelectedFiles={_setSelectedFiles}
            />
          ) : (
            <></>
          )}
        </div>
        {/* END OF MAIN DIV */}
      </div>
    </>
  ) : null;
}
