import React, { useState, useMemo } from 'react';

const API_KEY = 'tfp_34qzHEBNTyL7SVoNXkYd1UJK2ihwV95wQdSC3mGCeb2u_eorKoyyWWCs1'; // TypeForm API key
const FORM_ID = 'lCZ3yLlH'; // TypeForm form ID

const TypeFormPOC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `https://api.typeform.com/forms/${FORM_ID}/responses?query=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Error searching submissions');
      }

      const data = await response.json();
      setSearchResults(data.items);
      console.log(data.items);
    } catch (error) {
      console.error('Error searching submissions:', error);
    }
  };

  const generateColumns = (searchResults) => {
    const columns = [
      {
        Header: 'Landing ID',
        accessor: (row) => row.landing_id,
      },
    ];

    if (searchResults.length > 0) {
      const firstSubmission = searchResults[0];
      const { answers } = firstSubmission;

      answers.forEach((answer) => {
        if (answer.type === 'text') {
          columns.push({
            Header: answer.field.ref,
            accessor: (row) =>
              row.answers.find((a) => a.field.ref === answer.field.ref)?.text || '',
          });
        } else if (answer.type === 'email') {
          columns.push({
            Header: answer.field.ref,
            accessor: (row) =>
              row.answers.find((a) => a.field.ref === answer.field.ref)?.email || '',
          });
        } else if (answer.type === 'choice') {
          columns.push({
            Header: answer.field.ref,
            accessor: (row) =>
              row.answers.find((a) => a.field.ref === answer.field.ref)?.choice.label || '',
          });
        } else if (answer.type === 'date') {
          columns.push({
            Header: answer.field.ref,
            accessor: (row) =>
              row.answers.find((a) => a.field.ref === answer.field.ref)?.date || '',
          });
        }
      });
    }

    return columns;
  };

  const columns = useMemo(() => generateColumns(searchResults), [searchResults]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">TypeForm Search Submissions</h1>
      <form onSubmit={handleFormSubmit} className="flex mb-4">
        <input
          type="text"
          placeholder="Search results..."
          value={searchTerm}
          onChange={handleInputChange}
          className="px-4 py-2 border border-gray-300 rounded-l"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600"
        >
          Search
        </button>
      </form>
      <div className="table-container overflow-x-auto">
        <table className="w-full border border-gray-300">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.accessor}
                  className="bg-gray-200 px-4 py-2 text-left font-bold"
                >
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {searchResults.map((row) => (
              <tr key={row.response_id}>
                {columns.map((column) => (
                  <td
                    key={`${row.response_id}-${column.accessor}`}
                    className="border-t border-gray-300 px-4 py-2"
                  >
                    {typeof column.accessor === 'function' ? column.accessor(row) : ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TypeFormPOC;
