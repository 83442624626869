import { Auth } from "aws-amplify";
import "../../assets/styles/Auth.css";
import toast from "react-hot-toast";

import InputCode from "./input-code";

export default function Otp({
  user,
  // setUser,
  AuthState,
  setNextAuthState,
  // notifMessage,
  // notifShow,
  // notifVariant,
  // setNotifMessage,
  // setNotifShow,
  // setNotifVariant,
  // loading,
  // setLoading,
}) {

  async function handleVerifyOtp(otp) {
    try {
      const data = await Auth.sendCustomChallengeAnswer(user, otp);
      if (data.signInUserSession) {
        setNextAuthState(AuthState.SignedIn);
      } else {
        toast.error("Invalid OTP. Please enter the correct one", "");
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <div className="w-full mt-10 md:mt-0 h-full fade-in-fwd">
      <div className="w-full mt-10">
        <p className="text-xl mb-2 font-bold">{`Approve sign in request.`}</p>
        {/* <p className="text-lg font-bold">{`We just emailed you.`}</p> */}
        <p className="text-sm">{`Please enter the two-factor authentication code we emailed you to continue.`}</p>
        <p className="text-sm font-normal">{user.username}</p>
        <InputCode
          length={6}
          label="Code Label"
          onComplete={handleVerifyOtp}
          className="mx-1 w-10 md:w-12 md:h-16 text-center mt-8 h-12 py-2 border-4 border-neutral-100 bg-neutral-100 outline-none rounded-xl text-2xl text-hbGray font-bold placeholder-hbGray focus:outline-none focus:ring-2 ring-hbBlue"
        />
      </div>
    </div>
  );
}
