import { API } from "aws-amplify";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
// import GmailIntegration from "../authentication/email-integration-authentication";
import GoogleSignIn from "../authentication/email-integration-authentication-v2";
import ActionButtons from "./action-buttons";
import FiltersModal from "./filters-modal";
import { debounce } from "lodash";

import { RiFilter2Fill } from "react-icons/ri";

// import SavingModal from "./saving-state";
import googleLogin from "../../assets/images/google-login.png";
import ToastNotification from "../toast-notification";
import AddOtherDetails from "./other-details";
import TableArchivedInfo from "./table-info-archived";
import TableSavedInfo from "./table-info-saved";
import TableUnsavedInfo from "./table-info-unsaved";
import TabsRender from "./tabs";
// import { useIdleTimer } from "react-idle-timer";
import { AiFillFolderAdd, AiOutlineMail } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { CgChevronLeft } from "react-icons/cg";
import { IoIosSearch } from "react-icons/io";
// import { IoOptionsOutline } from "react-icons/io5";
import { RiMailCheckLine } from "react-icons/ri";
import {
  // isMobileDevice,
  useWindowDimensions,
} from "../../shared/mobileViewFunctions";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import EmailPreview from "./EmailPreview";
import AddClientMatter from "./addClientMatterModal";
// import { MdClear } from "react-icons/md";
// import { FaUsers } from "react-icons/fa";

import { useHistory, useLocation } from "react-router-dom";
import Button from "../../shared/Button";
// import { Switch } from "@headlessui/react";
import AddContactModal from "../contacts/add-contact-modal";
// import DropdownMenu from "react-overlays/cjs/DropdownMenu";
import EmailParser from "../../shared/EmailParser";
import Spinner from "../../shared/Spinner";
import AccessControl from "../../shared/accessControl";
import usePrevious from "../../shared/usePrevious";
import Compose from "./compose-modal";
import "./scrollbar.css";

//OUTLOOK IMPORTS
import { loginRequest } from "../../outlook-config-v2";
import OutlookConfig from "../authentication/outlook-instance-v2";
import OutlookSignIn from "../authentication/outlook-integration-authentication-v2";
// import OutlookSignIn from "../authentication/outlook-integration-authentication";
// import OutlookConfig from "../authentication/outlook-instance";
// import { scopes } from "../../outlook-config";
import { useMsal } from "@azure/msal-react";

import { ListClientMattersWithLabels } from "../../shared/graphql/queries";
import { render } from "@headlessui/react/dist/utils/render";
import MobileNotificationModal from "./mobile-notification-modal";
import InboxFilter from "./inbox-filter";
import { SidebarData } from "../sidebar/SidebarData";

// TANSTACK VIRTUALIZED
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  // useSortBy,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

var momentTZ = require("moment-timezone");
const userTimeZone = momentTZ.tz.guess();

//QUERIES
const qOutlookMessages = `
query qOutlookMessages($id: String, $email: String, $startDate: String, $endDate: String, $userTimeZone: String, $sender: String, $recipient: String, $isDeleted: Boolean, $isSaved: Boolean, $limit: Int, $nextToken: String, $subject: String, $folder: String, $hasAttachments: Boolean, $search: String){
  company(id: $id) {
    outlookMessages(
      email: $email,
      startDate: $startDate,
      endDate: $endDate,
      userTimeZone: $userTimeZone,
      sender: $sender,
      recipient: $recipient,
      isDeleted: $isDeleted,
      isSaved: $isSaved,
      limit: $limit,
      subject: $subject,
      folder: $folder,
      hasAttachments: $hasAttachments,
      nextToken: $nextToken,
      search: $search,
      ) {
      items {
        assignee
        bcc
        cc
        attachments {
          items {
            id
            details
            name
            s3ObjectKey
            size
            type
            isDeleted
            isInline
            labels {
              items {
                id
                name
              }
            }
          }
        }
        date
        description
        from
        outlookLabelIds
        id
        internalDate
        notes
        priority
        receivedAt
        snippet
        subject
        to
        threadId
        labels {
          items {
            id
            name
          }
        }
        taskList {
          id
          isDeleted
        }
        clientMatters {
          items {
            id
            client {
              id
              name
            }
            matter {
              id
              name
            }
          }
        }
      }
      nextToken
      count
    }
  }
}`;
const qGmailMessagesbyCompany = `
  query gmailMessagesByCompany($id: String, $isDeleted: Boolean, $isSaved: Boolean, $limit: Int, $nextToken: String, $email: String, $startDate: String, $endDate: String, $userTimeZone: String, $sender: String, $recipient: String, $subject: String, $folder: String, $hasAttachments: Boolean, $search: String) {
    company(id: $id) {
      gmailMessages(
        isDeleted: $isDeleted
        isSaved: $isSaved
        limit: $limit
        nextToken: $nextToken
        email: $email
        startDate: $startDate
        endDate: $endDate
        userTimeZone: $userTimeZone
        sender: $sender,
        recipient: $recipient,
        subject: $subject,
        folder: $folder,
        hasAttachments: $hasAttachments,
        search: $search,
      ) {
        items {
          id
          from
          to
          cc
          bcc
          subject
          date
          snippet
          labels {
            items {
              id
              name
            }
          }
          gmailLabelIds
          description
          taskList {
            id
            isDeleted
          }
          clientMatters {
            items {
              id
              client {
                id
                name
              }
              matter {
                id
                name
              }
              contacts {
                items {
                  type
                  details {
                    id
                    email
                    name
                    number
                  }
                }
              }
            }
          }
          attachments {
            items {
              id
              details
              name
              s3ObjectKey
              size
              type
              labels {
                items {
                  id
                  name
                }
              }
              isDeleted
            }
          }
          assignee
          priority
          notes
          receivedAt
          internalDate
        }
        nextToken
        count
      }
    }
  }`;

const qGetCompanyEmailToken = `
  query getCompanyEmailToken($companyId: String) {
    company(id: $companyId) {
      gmailToken {
        id
        refreshToken
        isOutOfSync
      }
      outlookToken {
        email
        refreshToken
        isOutOfSync
      }
    }
  }`;

const qUserClientMatterAccess = `query getUserClientMatters($id: String, $companyId: String) {
    user(id: $id) {
      clientMatterAccess(companyId: $companyId) {
        items {
          id
          userType
          clientMatter {
            id
            client {
              id
              name
            }
            matter {
              id
              name
            }
          }
        }
      }
    }
  }`;

const COMPANY_CLIENTMATTERS_QUERY = `
  query listClientMatters($companyId: String) {
    company(id: $companyId) {
      clientMatters (sortOrder: CREATED_DESC, isDeleted:false) {
        items {
          id
          createdAt
          client {
            id
            name
          }
          matter {
            id
            name
          }
          labels {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
  `;

const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
  gmailMessage(id: $id) {
    id
    payload {
      content
    }
    threadId
  }
}
`;

const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

const qListClientMatters = `
  query companyClientMatterContacts($companyId: String, $limit: Int, $nextToken: String) {
    company(id: $companyId) {
      clientMatters(limit: $limit, nextToken: $nextToken, sortOrder: CREATED_DESC, isDeleted:false) {
        nextToken
        items {
          id
          client {
            name
          }
          matter {
            name
          }
          contacts {
            items {
              type
              details {
                id
                email
                name
                number
              }
            }
          }
        }
      }
    }
  }`;

const COMPANY_USERS_QUERY = `
  query companyUsers($id: String!){
    company(id: $id){
      users {
        items {
          id
          firstName
          lastName
          profilePicture
        }
      }
    }
  }
  `;

const qGetTeams = `
    query getTeamsByCompany($id: String) {
      company(id: $id) {
        teams {
          items {
            id
            name
          }
        }
      }
    }
      `;

const qGetTeamsWithMembers = `
    query getTeamMembers($id: ID) {
      team(id: $id) {
        id
        name
        members {
          items {
            user {
              id
              firstName
              lastName
              userType
              profilePicture
            }
          }
        }
      }
    }`;

// Functions from Task page
const TASKLIST_PRESETS_QUERY = `
  query taskListPresets($companyId: ID) {
    taskListPresets(companyId: $companyId) {
      id
      columnIds
      label
      name
    }
  }`;
const CREATE_TASKLIST_PRESET_MUTATION = `
  mutation createTasklistPresets($companyId: ID, $label: String, $columnIds: [Int]) {
    taskListPresetsCreate(
      companyId: $companyId
      label: $label
      columnIds: $columnIds
    ) {
      id
    }
  }`;
const UPDATETASKLISTPRESET_MUTATION = `
  mutation updateTasklistPresets($columnIds: [Int], $id: ID) {
    taskListPresetsUpdate(id: $id, columnIds: $columnIds) {
      id
    }
  }`;

const TASK_LIST_TABLE_QUERY = `
  query taskListTable($companyId: ID!) {
    taskListTable(companyId: $companyId) {
      id
      columns {
        id
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          label
        }
      }
    }
  }
`;
//CSS INDEX PAGEsavedEMails
const contentDiv = {
  margin: "0 0 0 82px",
};

const Inbox = () => {
  const location = useLocation();

  const [accessedEmail, setAccessedEmail] = useState(
    localStorage.getItem("accessedEmail")
  );
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [isModal, setIsModal] = useState(false);
  const [selectedRawHtml, setSelectedRawHtml] = useState("");
  const companyId = localStorage.getItem("companyId");
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  const [openTab, setOpenTab] = React.useState(1);

  const [connectedEmail, setConnectedEmail] = useState(
    localStorage.getItem("connectedGmailAccount")
  );
  const [connectedOutlookAccount, setConnectedOutlookAccount] = useState(
    localStorage.getItem("connectedOutlookAccount")
  );
  const [companyIntegratedEmail, setCompanyIntegratedEmail] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [isOutOfSync, setIsOutOfSync] = useState(false);
  const [renderGmailButton, setRenderGmailButton] = useState(false);

  const [unSavedEmails, setUnsavedEmails] = useState([]);
  const [savedEmails, setSavedEmails] = useState([]);
  const [unSavedForTaskListCtr, setUnsavedForTaskListCtr] = useState(0);
  const [savedForTaskListCtr, setSavedForTaskListCtr] = useState(5);
  const [archivedEmails, setArchivedEmails] = useState([]);

  //Current Email Counts
  const [unSavedEmailsCount, setUnsavedEmailsCount] = useState(0);
  const [savedEmailsCount, setSavedEmailsCount] = useState(0);
  const [archivedEmailsCount, setArchivedEmailsCount] = useState(0);
  const [doneAllocatingEmails, setDoneAllocatingEmails] = useState(false);

  // //Previous Email Counts
  // const prevUnSavedEmailsCount = usePrevious(unSavedEmailsCount);
  // const prevSavedEmailsCount = usePrevious(savedEmailsCount);
  // const prevArchivedEmailsCount = usePrevious(archivedEmailsCount);

  const [unsavedNextToken, setUnsavedVnextToken] = useState(null);
  const [savedNextToken, setSavedVnextToken] = useState(null);
  const [matterList, setMatterList] = useState(null);
  const [selectedUnsavedItems, setSelectedUnsavedItems] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [selectedSavedItems, setSelectedSavedItems] = useState([]);
  const [selectedArchivedItems, setSelectedArchivedItems] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [pendingMessage, setpendingMessage] = useState("");
  const [maxLoadingSavedEmail, setMaxLoadingSavedEmail] = useState(false);
  const [maxLoadingUnSavedEmail, setMaxLoadingUnSavedEmail] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [labelsList, setLabelsList] = useState([]);
  const [showFiltersModal, setshowFiltersModal] = useState(false);
  const [attachmentIsDeleted, setAttachmentIsDeleted] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [lastCounter, setLastCounter] = useState(null);
  const [emailFilters, setEmailFilters] = useState(
    location?.state?.selectedEmail
      ? {
          startDate: new Date(location?.state?.selectedEmail.date),
          endDate: new Date(
            new Date(location?.state?.selectedEmail.date).setDate(
              new Date().getDate()
            )
          ),
        }
      : {
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate())),
        }
  );
  // FOR TESTING BULK EMAILS
  // const [emailFilters, setEmailFilters] = useState({
  //     startDate: new Date(new Date().setMonth(new Date().getMonth() - 5)),
  //     endDate: new Date(new Date().setDate(new Date().getDate())),
  //   }
  // );
  const [previousUnsavedEmails, setPreviousUnsavedEmails] = useState([]);
  const [previousSavedEmails, setPreviousSavedEmails] = useState([]);
  const [previousArchivedEmails, setPreviousArchivedEmails] = useState([]);
  const [selectedTaskList, setSelectedTaskList] = useState(
    Array.isArray(JSON.parse(localStorage.getItem("modifiedTaskNotes")))
      ? JSON.parse(localStorage.getItem("modifiedTaskNotes"))
      : []
  );
  const [isArchive, setIsArchive] = useState(false);

  const [taskRegisterList, setTaskRegisterList] = useState([]);

  const [list, setList] = useState([]);
  const [taskListColumns, setTaskListColumns] = useState([]);

  // useEffect(() => {
  //   getTaskRegisterList();
  // }, []);

  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  useEffect(async () => {
    getTaskRegisterList();
    await getTaskListColumns();
    // .then(() => {
    //   const storedTaskRegisterIds = JSON.parse(localStorage.getItem("taskRegisterIds"));
    //   const defaultTaskRegisterIds = Array.isArray(storedTaskRegisterIds) ? storedTaskRegisterIds :
    //     (taskRegisterList ? [taskRegisterList.find(register => register?.label.toLowerCase().replace(/ /g, "") === "tasklist")] : []);

    //   setSelectedTaskRegister(defaultTaskRegisterIds);
    //   console.log("bermonth", defaultTaskRegisterIds);
    // });
  }, []);

  async function getTaskListColumns() {
    const { data } = await API.graphql({
      query: TASK_LIST_TABLE_QUERY,
      variables: {
        companyId,
      },
    });

    //check if company has table
    let columns = data?.taskListTable?.columns;
    if (columns) {
      console.log("Tasklist table columns ", columns);
      setTaskListColumns(columns);
    }
  }
  // // MMA-1981: Task Register States
  const storedTaskRegisterIdsString =
    localStorage.getItem("gmailTaskRegisters");
  const storedTaskRegisterIds = storedTaskRegisterIdsString
    ? JSON.parse(storedTaskRegisterIdsString)
    : [];

  const defaultTaskRegisterIds = Array.isArray(storedTaskRegisterIds)
    ? storedTaskRegisterIds
    : taskRegisterList
    ? [
        taskRegisterList.find(
          (register) =>
            register?.label.toLowerCase().replace(/ /g, "") === "tasklist"
        ),
      ]
    : [];

  const [selectedTaskRegister, setSelectedTaskRegister] = useState(
    defaultTaskRegisterIds
  );

  // console.log("get updated selected register", selectedTaskRegister);

  // //Added fix for MMA-2551: Sir John's request on search bar state
  const [saveProgress, setSaveProgress] = useState(false);

  //Loading of client matter ui
  const [loadingCM, setLoadingCM] = useState(false);

  function setSavingProgress(emailList) {
    if (emailList.length === 0 && searchGmail !== "") {
      //Added timeout so the fetched emails will not include the ones that are just saved
      document.body.style.cursor = "wait";
      console.log(
        "With Filters emailList.length is " +
          emailList.length +
          " saveProgress is " +
          saveProgress
      );
      setTimeout(() => {
        setSearchGmail("");
        handleExecuteFilter(emailFilters);
        setSaveProgress(false);
        document.body.style.cursor = "default";
      }, 500);
    } else {
      setSaveProgress(false);
    }
  }

  useEffect(() => {
    if (saveProgress === true) {
      //If all the selected filtered emails are saved, remove search query and fetch data again. If not all are saved, keep the search query.
      // console.log("With filters saveProgress first is " + saveProgress)
      switch (openTab) {
        case 1:
          setSavingProgress(unSavedEmails);
          break;
        case 2:
          setSavingProgress(savedEmails);
          break;
        case 3:
          setSavingProgress(archivedEmails);
          break;
        default:
          break;
      }
    }
  }, [saveProgress, unSavedEmails, savedEmails, archivedEmails]);

  const fetchList = async () => {
    setLoadingCM(true);

    // const clientMatter = await API.graphql({
    //   query: qListClientMatters,
    //   variables: {
    //     companyId: companyId,
    //     //limit: "100",
    //     nextToken: nextToken,
    //   },
    // }).then((clientMatter) => {
    //   console.log("clientMatter", clientMatter);

    //   if (clientMatter !== null || clientMatter !== undefined) {
    //     setList(clientMatter.data.company.clientMatters.items);

    //     var matterList = clientMatter?.data?.company?.clientMatters?.items?.map(
    //       ({ id, client, matter }) => ({
    //         value: id,
    //         label: client?.name + "/" + matter?.name,
    //       })
    //     );

    //     var cm = matterList?.filter(function (el) {
    //       return el.label != null && el.value != null;
    //     });

    //     if (cm) {
    //       setTimeout(() => {
    //         setMatterList(cm.sort((a, b) => a.label.localeCompare(b.label)));
    //         setLoadingCM(false);
    //       }, 1000);
    //     }
    //   }
    // });

    try {
      const response = await ListClientMattersWithLabels(companyId);

      console.log("clientMatter", response);

      if (response) {
        setList(response.data.company.clientMatters.items);

        const matterList = transformMatterList(
          response.data.company.clientMatters.items
        );

        const filteredMatters = filterMatters(matterList);

        if (filteredMatters.length > 0) {
          setMatterList(
            filteredMatters.sort((a, b) => a.label.localeCompare(b.label))
          );
        }
      }
    } catch (error) {
      console.error("Error fetching client matters:", error);
    } finally {
      setLoadingCM(false);
    }
  };

  function transformMatterList(items) {
    return items
      .map(({ id, client, matter }) => ({
        value: id,
        label: (client?.name ?? "") + "/" + (matter?.name ?? ""),
      }))
      .filter(({ label, value }) => label && value);
  }

  function filterMatters(matterList) {
    return matterList.filter((el) => el.label != null && el.value != null);
  }

  //GSB MMA 3163: Set limit with fetchList function call that causes unlimited call
  const counterRef = useRef(0); // Ref initialied to value = 0
  const limit = 10; // fetchList function call is limited to 10
  useEffect(() => {
    console.log(
      "Called fetchCompanyClientMatters() with Counter: " +
        counterRef.current +
        " & Limit: " +
        limit
    );
    if (!list.length && matterList === null && counterRef.current < limit) {
      fetchCompanyClientMatters();
      counterRef.current++;
    }
  }, [list.length]);

  useEffect(() => {
    var labelList = [];
    for (var i = 0; i < list.length; i++) {
      labelList.push({
        cmid: list[i]?.id,
        labelsExtracted: list[i]?.labels?.items,
      });
    }

    setLabelsList(labelList);
  }, [list]);

  async function getTaskRegisterList() {
    try {
      const { data } = await API.graphql({
        query: TASKLIST_PRESETS_QUERY,
        variables: {
          companyId: localStorage.getItem("companyId"),
        },
      });

      const presetOpt = data?.taskListPresets.map((x) => {
        return {
          label: x.label,
          value: x.id,
        };
      });

      setTaskRegisterList(presetOpt);
      console.log("Task Registers List", presetOpt);
    } catch (error) {
      console.log("No Task Registers Found");
    }
  }

  async function createNewRegister(label) {
    const companyId = localStorage.getItem("companyId");
    const { data } = await API.graphql({
      query: CREATE_TASKLIST_PRESET_MUTATION,
      variables: {
        companyId: companyId,
        label: label,
        columnIds: null,
      },
    });

    try {
      const result = await API.graphql({
        query: UPDATETASKLISTPRESET_MUTATION,
        variables: {
          id: data?.taskListPresetsCreate?.id,
        },
      });
      console.log("New Task Register Created", result);
    } catch (error) {
      console.error(error);
    }
  }

  // End of MMA-1981

  const hideToast = () => {
    setShowToast(false);
  };
  const [waitUnSaved, setWaitUnSaved] = useState(false);
  const [waitSaved, setWaitSaved] = useState(false);
  const [waitArchived, setWaitArchived] = useState(false);
  const [showDetailsCopy, setShowDetailsCopy] = useState(false);
  const [searchGmail, setSearchGmail] = useState("");
  const [searchGmailTrigger, setSearchGmailTrigger] = useState();
  const [allTasks, setaAllTasks] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [tasklistGmailIds, setTasklistGmailIds] = useState([]);
  const bindList = useRef(null);

  //additional web functions
  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const [otherDetailsId, setOtherDetailsId] = useState("");
  const [webAssignee, setWebAssignee] = useState("");
  const [assigneeId, setAssigneeId] = useState("");
  const [assigneeVal, setAssigneeVal] = useState([]);
  const [priorityVal, setPriorityVal] = useState(null);
  const [notesVal, setNotesVal] = useState(null);

  /** MOBILE CONST */
  const { height } = useWindowDimensions();
  const [contentHeight, setContentHeight] = useState();
  const [show, setShow] = useState(false);

  const [showPreview, setShowPreview] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [emailInfo, setEmailInfo] = useState([]);
  const [displayImageAttachments, setDisplayImageAttachments] = useState();

  const [showCreateClientMatter, setShowCreateClientMatter] = useState(false);
  const [showMobileNotificationModal, setShowMobileNotificationModal] =
    useState(false);
  const [selectAllCheck, setSelectAllCheck] = useState(false);

  const [copyMore20, setCopyMore20] = useState(false);

  const [mobileLoading, setMobileLoading] = useState(true);

  const [toggleSent, settoggleSent] = useState(
    location?.state?.sent ? true : false
  );

  const [showAddContactModal, setshowAddContactModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(-1);
  const [scrollIndex, setScrollIndex] = useState(-1);
  const [contactEmailList, setContactEmailList] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);
  // MMA-1902 Scroll Fix
  const [scrollPosition, setScrollPosition] = useState(-1);
  const [recordScroll, setRecordScroll] = useState(true);
  const handleScrollPosition = (scrollValue) => {
    // if (!recordScroll) {
    //   setScrollPosition(scrollValue)
    //   console.log("rscroll true. setting new scroll value from", source, scrollValue)
    // } else {
    //   if (scrollValue !== 0 && scrollValue !== 2880) {
    //     setScrollPosition(scrollValue)
    //     console.log("rscroll false. setting new scroll value from", source, scrollValue)
    //   }
    // }
    setScrollPosition(scrollValue);
    console.log("setting new scroll value from", scrollValue);
  };

  const handleScrollIndex = (index) => {
    console.log(index, "<- Posistion");
    setScrollIndex(index);
  };

  const handleSelectedEmail = (index) => {
    setSelectedEmail(index);
  };

  //Copy functions
  const [showCopy, setShowCopy] = useState(true);
  const [pastedIds, setPastedIds] = useState([]);
  const [copyId, setCopyId] = useState("");

  //TaskList
  const [clicked, setClicked] = useState(false);
  const [showAssignee, setShowAssignee] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [emailContentOpen, setEmailContentOpen] = useState(false);
  const [allowComposeEmail, setAllowComposeEmail] = useState(false);

  const featureAccessFilters = async () => {
    const inboxAccess = await AccessControl("INBOX");
    const inboxFeatures = inboxAccess?.data?.features;
    console.log("inboxAccess:", inboxAccess);
    if (inboxAccess?.status !== "restrict") {
      setAllowComposeEmail(inboxFeatures?.includes("COMPOSEEMAIL"));
    } else {
      console.log(inboxAccess?.message);
    }
  };

  //Compose
  const [isReply, setIsReply] = useState(false);
  const [subject, setSubject] = useState("");
  const [selectedIsReplyDetails, setSelectedIsReplyDetails] = useState({});
  const [selectedThreadId, setSelectedThreadId] = useState("");
  const [selectedMessageId, setSelectedMessageId] = useState("");
  const [threadId, setThreadId] = useState("");
  const [isUpdatedClientMatter, setIsUpdatedClientMatter] = useState(false);

  const [hasSearchVal, setHasSearchVal] = useState(false);
  const stagedEdits = useRef(null);

  // useEffect(() => {
  //   console.log("LOCATION INIT STATE!", location.state);
  //   // if (isMobileDevice(width)) {
  //   settoggleSent(location.state?.sent);
  //   // }
  // }, []);

  useEffect(() => {
    console.log("Applying Feature Access Filters");
    featureAccessFilters();
  }, []);

  //Compose Functions
  const handleCloseCompose = () => {
    setIsModal(false);
    setSubject("");
    setThreadId("");
    setSelectedThreadId("");
    setIsReply(false);
    setSelectedIsReplyDetails({});
  };

  // OUTLOOK
  const [isOutlookIntegrated, setIsOutlookIntegrated] = useState(null); // TRUE IF OUTLOOK IS LOGGED IN, FALSE IF GMAIL

  const handleComposeUnsavedTabs = (
    isUpdated,
    modal,
    isreply,
    selectUnsavedEmailsDetails,
    selectedThreadId,
    selectedMessageId,
    rawHtml
  ) => {
    setIsModal(modal);
    setIsReply(isreply);

    setIsUpdatedClientMatter(isUpdated);
    setSelectedRawHtml(rawHtml);
    setSubject(selectUnsavedEmailsDetails.subject);
    setSelectedIsReplyDetails(selectUnsavedEmailsDetails);
    setSelectedThreadId(selectedThreadId);
    setSelectedMessageId(selectedMessageId);
    console.log(selectedThreadId, " <- Thread id");
    console.log(selectedMessageId, " <- Message id");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function showInbox() {
    setShowContent(true);
  }

  let history = useHistory();

  const handleClick = () => {
    setClicked(!clicked);
    setShowAddButton(!showAddButton);
  };

  var width = window.innerWidth;

  // useEffect(() => {
  //   if (matterList?.length === 0) {
  //     fetchCompanyClientMatters();
  //   }
  // }, []);

  useEffect(() => {
    console.log("Handle the initial sign-in state.");

    const gScope = [
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/gmail.labels",
        "https://www.googleapis.com/auth/gmail.modify",
        "https://www.googleapis.com/auth/gmail.send",
        "https://www.googleapis.com/auth/gmail.compose",
      ],
      gClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID,
      gParams = {
        clientId: gClientId,
        scope: gScope.join(" "),
      };

    /*gapi.client.init(gParams).then(function () {
      const auth2Instance = window.gapi.auth2.getAuthInstance();
      const isSignedIn = auth2Instance.isSignedIn.get();
      if (isSignedIn) {
        const authCurrentUser = auth2Instance.currentUser.get().wt.cu;

        if (authCurrentUser === companyIntegratedEmail) {
          setConnectedEmail(authCurrentUser);
          localStorage.setItem("connectedGmailAccount", authCurrentUser);
        }
      } else {
        localStorage.removeItem("connectedGmailAccount");
        setConnectedEmail(null);
      }
      getSavedAuthToken();
    }).catch((err) => {
      console.error("Error initing auth2", err);
      localStorage.removeItem("connectedGmailAccount");
    });*/

    getSavedAuthToken();
    console.log("CONNECTED EMAIL", connectedEmail);

    console.log("UseEffect: Refresh Token", refreshToken);
    console.log("UseEffect: isOutOfSync", isOutOfSync);
  }, [refreshToken, isOutOfSync]);

  useEffect(() => {
    // for mobile
    width = window.innerWidth;
    if (width < 844) {
      const headerTag = document.getElementById("headerTag");
      setContentHeight(height - headerTag?.offsetHeight);
    }
  }, [height, window.innerWidth, unSavedEmails, savedEmails, archivedEmails]);

  const fetchData = async (action) => {
    console.group("fetchData()");
    // Update searching while using all clear function
    try {
      if (action === "clear") {
        handleExecuteFilter(emailFilters);
      } else if (searchGmail && searchGmail !== "") {
        console.log("Start Searching...");
        // await filterRecord(searchGmail);
        handleExecuteFilter(emailFilters);
        setIsArchive(false);
      } else {
        // if (isArchive) {
        //   setIsArchive(false);
        //   console.log(
        //     "Already Proccessed the email in Table Info Unsaved .jsx File"
        //   );
        // } else {
        handleExecuteFilter(emailFilters);
        // console.log("Fetching Unsaved Emails");
        // await getUnSavedEmails(emailFilters);

        // console.log("Fetching Saved Emails");
        // await getSavedEmails(emailFilters);

        // //MMA-2202 - Gmail Integration: The page refreshes after unarchiving email(s)
        // // Commented out to prevent refreshing of whole Archived Email page when refetching
        // console.log("Fetching Archived Emails");
        // await getArchivedEmails(emailFilters);

        //   }
      }
    } catch (error) {
      console.error("Error:", error);
      await getUnSavedEmails(emailFilters);
      await getSavedEmails(emailFilters);
      await getArchivedEmails(emailFilters);
    }
    console.groupEnd("fetchData()");
  };

  // useEffect(() => {
  //   /* MMA-1839: I saw a pattern wherein allocated emails will only be transferred once
  //     the search icon is clicked. By using a usePrevious hook, we can properly determine
  //     if the length of emails have changed. If it has, then we can call the fetchData(),
  //     which in turn replicates the same behavior as clicking the search icon.
  //   */

  //   if (!doneAllocatingEmails && isOutlookIntegrated !== null) {
  //     if (width > 844) {
  //       //fetchData();
  //     }
  //   }
  // }, [
  //   unSavedEmailsCount,
  //   savedEmailsCount,
  //   archivedEmailsCount,
  //   doneAllocatingEmails,
  //   isOutlookIntegrated,
  // ]);

  // useEffect(() => {
  //   //Updates the email counts when they are already fetched.
  //   if (
  //     unSavedEmailsCount !== prevUnSavedEmailsCount ||
  //     savedEmailsCount !== prevSavedEmailsCount ||
  //     archivedEmailsCount !== prevArchivedEmailsCount ||
  //     saveLoading
  //   ) {
  //     setUnsavedEmailsCount(unSavedEmails?.length);
  //     setSavedEmailsCount(savedEmails?.length);
  //     setArchivedEmailsCount(archivedEmails?.length);
  //   }
  // }, [unSavedEmails, savedEmails, archivedEmails]);

  const getSavedAuthToken = async () => {
    // Check if theres an outlook token in DB
    const emailParams = {
      query: qGetCompanyEmailToken,
      variables: {
        companyId: companyId,
      },
    };

    const emailResult = await API.graphql(emailParams);
    console.log("Email Auth Result:", emailResult.data.company);

    const gmailToken = emailResult.data.company.gmailToken;
    if (gmailToken.id) {
      saveAccountState(gmailToken);
      localStorage.setItem("connectedGmailAccount", gmailToken.id);
      setIsOutlookIntegrated(false);
      // console.log("GMAIL TOKEN!", gmailToken.id);
      // console.log("CONNECTEDEMAIL", connectedEmail);
      setConnectedEmail(gmailToken.id);
      setRenderGmailButton(true);
      return;
    }
    setRenderGmailButton(true);
    const outlookToken = emailResult.data.company.outlookToken;

    if (outlookToken.email) {
      saveAccountState(outlookToken);
      localStorage.setItem("connectedOutlookAccount", outlookToken.email);
      setIsOutlookIntegrated(true);
      setConnectedOutlookAccount(outlookToken.email);
      return;
    }
  };

  const saveAccountState = async (gmailToken) => {
    const gmailTokenId = isOutlookIntegrated ? gmailToken.email : gmailToken.id;
    const gmailRefreshToken = gmailToken.refreshToken;
    const gmailIsOutOfSync =
      gmailToken.isOutOfSync === null || gmailToken.isOutOfSync === undefined
        ? false
        : gmailToken.isOutOfSync;

    if (connectedEmail !== gmailTokenId) {
      console.group("Connected Email != Company-Integrated Email");
      console.log("Connected Email:", connectedEmail);
      console.log("Company-Integrated Email:", gmailTokenId);
      console.groupEnd();
    }

    // Define a flag to determine whether state updates are necessary
    let shouldUpdateState = false;

    // Check if the current state needs to be updated
    if (width < 844 && gmailIsOutOfSync) {
      if (companyIntegratedEmail !== null || refreshToken !== null) {
        shouldUpdateState = true;
      }
    } else {
      if (
        companyIntegratedEmail !== gmailTokenId ||
        refreshToken !== gmailRefreshToken
      ) {
        shouldUpdateState = true;
      }
    }

    // Update state only if necessary
    if (shouldUpdateState) {
      setCompanyIntegratedEmail(
        width < 844 && gmailIsOutOfSync ? null : gmailTokenId
      );
      setRefreshToken(
        width < 844 && gmailIsOutOfSync ? null : gmailRefreshToken
      );
      setIsOutOfSync(gmailIsOutOfSync);
    }

    console.groupEnd();
  };

  useEffect(() => {
    if (isOutlookIntegrated !== null) {
      handleExecuteFilter(emailFilters);
      setEmailContentOpen(false);

      if (location?.state?.sent !== toggleSent)
        history.replace({
          ...location,
          state: { sent: toggleSent },
        });
    }
  }, [toggleSent]);

  useEffect(() => {
    if (emailContentOpen === false) {
      setSelectedThreadId("");
    }
  }, [emailContentOpen]);
  useEffect(() => {
    if (location?.state?.sent !== toggleSent)
      settoggleSent(location?.state?.sent);
  }, [location.state]);

  // const getUnSavedEmails = async (filters, batchSize = 50, action) => {
  //   try {
  //     console.log("Search Unsaved Filters:", filters.subject);

  //     const filterSubject = filters?.subject;
  //     const filterHasAttachments = filters?.hasAttachments;

  //     if (action !== "clear") {
  //       /** Remove this to prevent refreshing list after saving emails from mobile */
  //       if (width > 844) {
  //         setWaitUnSaved(true);
  //       }
  //     }

  //     let result;
  //     let emailList = [];
  //     let nextToken = null;
  //     let hasMoreData = true;

  //     while (hasMoreData) {
  //       // Continue until there is no more data
  //       const params = {
  //         query: isOutlookIntegrated
  //           ? qOutlookMessages
  //           : qGmailMessagesbyCompany,
  //         variables: {
  //           id: companyId,
  //           isSaved: false,
  //           isDeleted: false,
  //           email: isOutlookIntegrated
  //             ? connectedOutlookAccount
  //             : localStorage.getItem("connectedGmailAccount"),
  //           userTimeZone: userTimeZone,
  //           startDate:
  //             filters.startDate != null
  //               ? momentTZ(filters.startDate, userTimeZone).format("YYYY-MM-DD")
  //               : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
  //           endDate:
  //             filters.endDate != null
  //               ? momentTZ(filters.endDate, userTimeZone).format("YYYY-MM-DD")
  //               : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
  //           sender: filters?.sender,
  //           recipient: filters?.recipient,
  //           nextToken: nextToken, // Pass the nextToken for pagination
  //         },
  //       };

  //       result = await API.graphql(params);

  //       const fetchedEmails = isOutlookIntegrated
  //         ? result.data?.company?.outlookMessages?.items || []
  //         : result.data?.company?.gmailMessages?.items || [];

  //       nextToken = isOutlookIntegrated
  //         ? result?.data?.company?.outlookMessages?.nextToken
  //         : result?.data?.company?.gmailMessages?.nextToken;

  //       if (!nextToken) {
  //         hasMoreData = false; // No more data to fetch
  //       }

  //       // Append the new data to the existing emailList
  //       emailList = [...emailList, ...fetchedEmails];
  //     }

  //     // let filteredEmails = isOutlookIntegrated
  //     //   ? emailList?.filter((om) =>
  //     //     toggleSent
  //     //       ? om.outlookLabelIds?.includes("SENT")
  //     //       : om.outlookLabelIds?.includes("INBOX")
  //     //   )
  //     //   : emailList?.filter((gm) =>
  //     //     toggleSent
  //     //       ? gm.gmailLabelIds?.includes("SENT")
  //     //       : gm.gmailLabelIds?.includes("INBOX")
  //     // );

  //     const labelFilter = toggleSent ? "SENT" : "INBOX";
  //     let filteredEmails = isOutlookIntegrated
  //       ? emailList?.filter((om) => om.outlookLabelIds?.includes(labelFilter))
  //       : emailList?.filter((gm) => gm.gmailLabelIds?.includes(labelFilter));

  //     if (filterSubject) {
  //       filteredEmails = filteredEmails?.filter((x) =>
  //         x.subject.toLowerCase().includes(filterSubject.toLowerCase())
  //       );
  //     }

  //     if (filterHasAttachments) {
  //       filteredEmails = filteredEmails?.filter(
  //         (obj) =>
  //           obj.attachments.items &&
  //           obj.attachments.items.length &&
  //           obj.attachments.items.some(
  //             (attachment) => attachment.details !== "EMAIL_PDF_VERSION"
  //           )
  //       );
  //     }

  //     setUnsavedEmails(filteredEmails);
  //     setPreviousUnsavedEmails(filteredEmails);
  //     setLastCounter(
  //       emailList?.filter((gm) =>
  //         isOutlookIntegrated
  //           ? gm.outlookLabelIds?.includes(labelFilter)
  //           : gm.gmailLabelIds?.includes(labelFilter)
  //       ).length
  //     );
  //     setMobileLoading(false);

  //     if (action !== "clear") {
  //       setWaitUnSaved(false);
  //     }
  //   } catch (error) {
  //     console.log("Error fetching emails:", error);
  //   }
  // };

  const getUnSavedEmails = async (filters, batchSize = 50, action) => {
    console.log("getting emails", filters);

    //GBS MMA 3129: Instantly set loader while proccessing filter of unsaved emails
    setWaitUnSaved(true);
    setMobileLoading(true);

    try {
      console.log("Search Unsaved Filters:", filters);

      const filterSubject = filters?.subject;
      const filterHasAttachments = filters?.hasAttachments;
      const labelFilter = toggleSent ? "SENT" : "INBOX";

      let emailList = [];
      if (action && action === "RESET") {
        unsavedCurrToken.current = null;
      } else {
        emailList = [...unSavedEmails];
      }

      let searchTrimmed = searchGmail?.trim() || "";

      let currToken = unsavedCurrToken.current;
      // Continue until there is no more data
      const params = {
        query: isOutlookIntegrated ? qOutlookMessages : qGmailMessagesbyCompany,
        variables: {
          id: companyId,
          isSaved: false,
          isDeleted: false,
          email: isOutlookIntegrated
            ? connectedOutlookAccount
            : localStorage.getItem("connectedGmailAccount"),
          userTimeZone: userTimeZone,
          startDate:
            filters.startDate != null
              ? momentTZ(filters.startDate, userTimeZone).format("YYYY-MM-DD")
              : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
          endDate:
            filters.endDate != null
              ? momentTZ(filters.endDate, userTimeZone).format("YYYY-MM-DD")
              : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
          folder: labelFilter,
          ...(filters?.sender ? { sender: filters.sender } : {}),
          ...(filters?.recipient ? { recipient: filters.recipient } : {}),
          ...(filterSubject ? { subject: filterSubject } : {}),
          ...(filterHasAttachments ? { hasAttachments: true } : {}),
          ...(currToken ? { nextToken: currToken } : {}),
          ...(searchTrimmed ? { search: searchTrimmed } : {}),
          limit: batchSize,
        },
      };

      await API.graphql(params).then((result) => {
        let emails = isOutlookIntegrated
          ? result?.data?.company?.outlookMessages
          : result?.data?.company?.gmailMessages;

        const fetchedEmails = emails?.items || [];

        // Append the new data to the existing emailList
        emailList = [...emailList, ...fetchedEmails];

        if (action && action === "RESET") {
          setUnsavedEmailsCount(emails?.count ? emails.count : 0);
        }

        unsavedCurrToken.current = emails?.nextToken ? emails.nextToken : null;

        setUnsavedEmails(emailList);
        setPreviousUnsavedEmails(emailList);
        setLastCounter(
          emailList?.filter((gm) =>
            isOutlookIntegrated
              ? gm.outlookLabelIds?.includes(labelFilter)
              : gm.gmailLabelIds?.includes(labelFilter)
          ).length
        );
        setMobileLoading(false);

        if (action !== "clear") {
          setWaitUnSaved(false);
        }
      });
    } catch (error) {
      console.log("Error fetching emails:", error);
    }
  };

  /**
   * Retrieves saved emails based on specified filters.
   *
   * @param {Object} filters - An object containing filter criteria for email retrieval.
   */
  // const getSavedEmails = async (filters) => {
  //   // Log the subject of the filters for debugging purposes
  //   console.log("Search Saved Filters:", filters.subject);

  //   // Extract filter properties from the filters object
  //   const filterSubject = filters?.subject;
  //   const filterHasAttachments = filters?.hasAttachments;
  //   const labelFilter = toggleSent ? "SENT" : "INBOX";

  //   // Set the loading state to true to indicate that email retrieval is in progress
  //   setWaitSaved(true);

  //   // Construct the GraphQL query parameters
  //   const params = {
  //     query: isOutlookIntegrated ? qOutlookMessages : qGmailMessagesbyCompany,
  //     variables: {
  //       id: companyId,
  //       isSaved: true,
  //       isDeleted: false,
  //       email: isOutlookIntegrated
  //         ? connectedOutlookAccount
  //         : localStorage.getItem("connectedGmailAccount"),
  //       userTimeZone: userTimeZone,
  //       startDate:
  //         filters.startDate != null
  //           ? momentTZ(filters.startDate, userTimeZone).format("YYYY-MM-DD")
  //           : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
  //       endDate:
  //         filters.endDate != null
  //           ? momentTZ(filters.endDate, userTimeZone).format("YYYY-MM-DD")
  //           : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
  //       folder: labelFilter.toLowerCase(),
  //       ...(filters?.sender ? { sender: filters.sender } : {}),
  //       ...(filters?.recipient ? { recipient: filters.recipient } : {}),
  //       ...(filterSubject ? { subject: filterSubject } : {}),
  //       ...(filterHasAttachments ? { hasAttachments: true } : {}),
  //     },
  //   };

  //   // Execute the GraphQL query using the Amplify API
  //   await API.graphql(params).then((result) => {
  //     let emails = result?.data?.company;
  //     // Set the loading state to false as email retrieval is complete
  //     setWaitSaved(false);

  //     // Extract email list from the GraphQL query result
  //     const emailList = isOutlookIntegrated
  //       ? emails?.outlookMessages?.items
  //       : emails?.gmailMessages?.items;

  //     // Set the next token for pagination
  //     setSavedVnextToken(
  //       isOutlookIntegrated
  //         ? emails?.outlookMessages?.nextToken
  //         : emails?.gmailMessages?.nextToken
  //     );

  //     // Set the state with the filtered emails
  //     setSavedEmails(emailList);
  //     setPreviousSavedEmails(emailList);
  //     setMobileLoading(false);
  //   });
  // };

  const getSavedEmails = async (filters, batchSize = 50, action) => {
    // Log the subject of the filters for debugging purposes
    console.log("Search Saved Filters:", filters.subject);

    // Extract filter properties from the filters object
    const filterSubject = filters?.subject;
    const filterHasAttachments = filters?.hasAttachments;
    const labelFilter = toggleSent ? "SENT" : "INBOX";

    let emailList = [];
    if (action && action === "RESET") {
      savedCurrToken.current = null;
    } else {
      emailList = [...savedEmails];
    }

    let searchTrimmed = searchGmail?.trim() || "";

    let currToken = savedCurrToken.current;
    // Set the loading state to true to indicate that email retrieval is in progress
    setWaitSaved(true);

    // Construct the GraphQL query parameters
    const params = {
      query: isOutlookIntegrated ? qOutlookMessages : qGmailMessagesbyCompany,
      variables: {
        id: companyId,
        isSaved: true,
        isDeleted: false,
        email: isOutlookIntegrated
          ? connectedOutlookAccount
          : localStorage.getItem("connectedGmailAccount"),
        userTimeZone: userTimeZone,
        startDate:
          filters.startDate != null
            ? momentTZ(filters.startDate, userTimeZone).format("YYYY-MM-DD")
            : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
        endDate:
          filters.endDate != null
            ? momentTZ(filters.endDate, userTimeZone).format("YYYY-MM-DD")
            : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
        folder: labelFilter.toLowerCase(),
        ...(filters?.sender ? { sender: filters.sender } : {}),
        ...(filters?.recipient ? { recipient: filters.recipient } : {}),
        ...(filterSubject ? { subject: filterSubject } : {}),
        ...(filterHasAttachments ? { hasAttachments: true } : {}),
        ...(currToken ? { nextToken: currToken } : {}),
        ...(searchTrimmed ? { search: searchTrimmed } : {}),
        limit: batchSize,
      },
    };

    // Execute the GraphQL query using the Amplify API
    await API.graphql(params).then((result) => {
      let emails = isOutlookIntegrated
        ? result?.data?.company?.outlookMessages
        : result?.data?.company?.gmailMessages;

      // Set the loading state to false as email retrieval is complete
      setWaitSaved(false);

      // Extract email list from the GraphQL query result
      emailList = [...emailList, ...(emails?.items || [])];

      if (action && action === "RESET") {
        setSavedEmailsCount(emails?.count ? emails.count : 0);
      }

      // Set the next token for pagination
      setSavedVnextToken(emails?.nextToken);
      savedCurrToken.current = emails?.nextToken ? emails.nextToken : null;

      // Set the state with the filtered emails
      setSavedEmails(emailList);
      setPreviousSavedEmails(emailList);
      setMobileLoading(false);
    });
  };

  // const getArchivedEmails = async (filters) => {
  //   console.log("Search Archived Filters:", filters.subject);
  //   const filterSubject = filters?.subject;
  //   const filterHasAttachments = filters?.hasAttachments;
  //   const labelFilter = toggleSent ? "SENT" : "INBOX";
  //   setWaitArchived(true);
  //   const params = {
  //     query: isOutlookIntegrated ? qOutlookMessages : qGmailMessagesbyCompany,
  //     variables: {
  //       id: companyId,
  //       isSaved: false,
  //       isDeleted: true,
  //       email: isOutlookIntegrated ? connectedOutlookAccount : connectedEmail,
  //       userTimeZone: userTimeZone,
  //       startDate:
  //         filters.startDate != null
  //           ? momentTZ(filters.startDate, userTimeZone).format("YYYY-MM-DD")
  //           : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
  //       endDate:
  //         filters.endDate != null
  //           ? momentTZ(filters.endDate, userTimeZone).format("YYYY-MM-DD")
  //           : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
  //       folder: labelFilter.toLowerCase(),
  //       ...(filters?.sender ? { sender: filters.sender } : {}),
  //       ...(filters?.recipient ? { recipient: filters.recipient } : {}),
  //       ...(filterSubject ? { subject: filterSubject } : {}),
  //       ...(filterHasAttachments ? { hasAttachments: true } : {}),
  //     },
  //   };

  //   await API.graphql(params).then((result) => {
  //     setWaitArchived(false);
  //     let emails = result.data?.company;
  //     const emailList = isOutlookIntegrated
  //       ? emails?.outlookMessages?.items
  //       : emails?.gmailMessages?.items;

  //     setArchivedEmails(emailList);
  //     setPreviousArchivedEmails(emailList);
  //     setMobileLoading(false);
  //   });
  // };

  const getArchivedEmails = async (filters, batchSize = 50, action) => {
    console.log("Search Archived Filters:", filters.subject);

    const filterSubject = filters?.subject;
    const filterHasAttachments = filters?.hasAttachments;
    const labelFilter = toggleSent ? "SENT" : "INBOX";

    let emailList = [];
    if (action && action === "RESET") {
      archivedCurrToken.current = null;
    } else {
      emailList = [...archivedEmails];
    }

    let searchTrimmed = searchGmail?.trim() || "";

    let currToken = archivedCurrToken.current;

    setWaitArchived(true);
    const params = {
      query: isOutlookIntegrated ? qOutlookMessages : qGmailMessagesbyCompany,
      variables: {
        id: companyId,
        isSaved: false,
        isDeleted: true,
        email: isOutlookIntegrated ? connectedOutlookAccount : connectedEmail,
        userTimeZone: userTimeZone,
        startDate:
          filters.startDate != null
            ? momentTZ(filters.startDate, userTimeZone).format("YYYY-MM-DD")
            : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
        endDate:
          filters.endDate != null
            ? momentTZ(filters.endDate, userTimeZone).format("YYYY-MM-DD")
            : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
        folder: labelFilter.toLowerCase(),
        ...(filters?.sender ? { sender: filters.sender } : {}),
        ...(filters?.recipient ? { recipient: filters.recipient } : {}),
        ...(filterSubject ? { subject: filterSubject } : {}),
        ...(filterHasAttachments ? { hasAttachments: true } : {}),
        ...(currToken ? { nextToken: currToken } : {}),
        ...(searchTrimmed ? { search: searchTrimmed } : {}),
        limit: batchSize,
      },
    };

    await API.graphql(params).then((result) => {
      setWaitArchived(false);
      let emails = isOutlookIntegrated
        ? result.data?.company?.outlookMessages
        : result.data?.company?.gmailMessages;

      emailList = [...emailList, ...(emails?.items || [])];

      if (action && action === "RESET") {
        setArchivedEmailsCount(emails?.count ? emails.count : 0);
      }

      archivedCurrToken.current = emails?.nextToken ? emails.nextToken : null;

      setArchivedEmails(emailList);
      setPreviousArchivedEmails(emailList);
      setMobileLoading(false);
    });
  };

  let result = [];

  const getUserClientMatterAccess = async () => {
    const userClientMatterAccess = await API.graphql({
      query: qUserClientMatterAccess,
      variables: {
        id: localStorage.getItem("userId"),
        companyId: localStorage.getItem("companyId"),
      },
    });

    const clientMatterIds =
      userClientMatterAccess.data.user.clientMatterAccess.items.map(
        (i) => i.clientMatter.id
      );

    return clientMatterIds;
  };

  //2579
  async function fetchCompanyClientMatters() {
    await fetchList();
    //   // let result = [];
    //   console.log(list);
    //   if(list){
    //     setMatterList(list);
    //   }
    // const { data } = await API.graphql({
    //   query: COMPANY_CLIENTMATTERS_QUERY,
    //   variables: {
    //     companyId: companyId,
    //   },
    // });
    // const { data } = await ListClientMattersWithLabels(companyId);
    // if (data.company.clientMatters && data.company.clientMatters.items !== null) {
    //   result = data.company.clientMatters.items;
    //   const rawResult = result;
    //   result = result.map(({ id, client, matter }) => ({
    //     value: id,
    //     label: client?.name + "/" + matter?.name,
    //   }));
    //   var cm = result?.filter(function (el) {
    //     return el.label != null && el.value != null;
    //   });
    //   console.log("COUNT CM", cm);
    //   // Check if cm is not undefined or null before proceeding
    //   if (cm) {
    //     // set ClientMatter List for each dropdown
    //     setMatterList(cm.sort((a, b) => a.label - b.label));
    //     // Store labelslist for dropdown options
    //     var store = [];
    //     for (var i = 0; i < rawResult.length; i++) {
    //       store.push({
    //         cmid: rawResult[i]?.id,
    //         labelsExtracted: rawResult[i]?.labels?.items,
    //       });
    //     }
    //     setLabelsList(store);
    //   }
    // }
  }

  const getMatterList = async () => {
    const clientMattersOpt = await API.graphql({
      query: COMPANY_CLIENTMATTERS_QUERY,
      variables: {
        companyId: companyId,
      },
    });

    console.log("getMatterList", clientMattersOpt);

    if (clientMattersOpt.data.company.clientMatters.items !== null) {
      result = clientMattersOpt.data.company.clientMatters.items;

      console.log("ClientMatterList", result);

      result = result.map(({ id, client, matter }) => ({
        value: id,
        label: client?.name + "/" + matter?.name,
      }));

      var filtered = result?.filter(function (el) {
        return el.label != null && el.value != null;
      });

      console.log("FilteredClientMatter", filtered);

      setMatterList(filtered?.sort((a, b) => a.label - b.label));

      var store = [];
      for (
        var i = 0;
        i < clientMattersOpt.data.company.clientMatters.items.length;
        i++
      ) {
        store = [
          ...store,
          {
            cmid: clientMattersOpt?.data?.company?.clientMatters?.items[i]?.id,
            labelsExtracted:
              clientMattersOpt?.data?.company?.clientMatters?.items[i]?.labels
                ?.items,
          },
        ];
      }

      setLabelsList(store);
    }
  };

  const handleExecuteFilter = async (filters) => {
    console.group("handleExecuteFilter");
    console.log("handleExecuteFilter", filters);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }

    if (filters) {
      //console.log("With Filters", filters);
      let promisesArr = [];
      setEmailFilters({
        startDate: filters.startDate,
        endDate: filters.endDate,
        subject: filters.subject,
        sender: filters.sender,
        recipient: filters.recipient,
        hasAttachments: filters.hasAttachments,
      });

      setUnsavedEmails([]);
      setUnsavedVnextToken(null);
      promisesArr.push(getUnSavedEmails(filters, 50, "RESET"));

      setSavedEmails([]);
      setSavedVnextToken(null);
      promisesArr.push(getSavedEmails(filters, 50, "RESET"));

      setArchivedEmails([]);
      promisesArr.push(getArchivedEmails(filters, 50, "RESET"));

      await Promise.all(promisesArr);
    } else {
      // console.log("Without Filters", filters);
      // Reset / Clear Filters
      const defaultFilter = {
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate())),
      };
      setEmailFilters(defaultFilter);

      await Promise.all([
        getUnSavedEmails(defaultFilter, 50, "RESET"),
        getSavedEmails(defaultFilter, 50, "RESET"),
        getArchivedEmails(defaultFilter, 50, "RESET"),
      ]);
    }
    setSaveLoading(false);
    setshowFiltersModal(false);
    console.groupEnd("handleExecuteFilter");
  };

  const handleFiltersModalClose = () => {
    setshowFiltersModal(false);
  };

  const closeEmailModal = () => {
    setShowPreview(false);
    handleCloseCompose();
  };

  function sortByDate(arr) {
    let sort;
    if (arr) {
      sort = arr.sort((a, b) => b.receivedAt - a.receivedAt);
    } else {
      sort = arr;
    }
    return sort;
  }

  const handleSearchGmailChange = (e, actions) => {
    console.log("handleSearchGmailChange()", e.target.value, actions);

    setSearchGmail(e.target.value);
    setSearchGmailTrigger(actions);
  };

  const handleShowCreateClientMatter = (st) => {
    setShowCreateClientMatter(st);
  };

  useEffect(() => {
    if (
      searchGmailTrigger === "enter" ||
      searchGmailTrigger === "clear" ||
      searchGmailTrigger === "pasted"
    ) {
      debouncedFetchData();
      console.log(
        "searchGmail changed via enter key, call debouncedFetchData()",
        searchGmail
      );
    }

    console.log("searchGmailTrigger", searchGmailTrigger);
  }, [searchGmail, searchGmailTrigger]);

  // // This function retrieves the email payload associated with a given messageId.
  // const getEmailPayload = async (messageId) => {
  //   // Define the GraphQL query parameters.
  //   const params = {
  //     query: qGetMessagePayload, // Assuming qGetMessagePayload is defined elsewhere
  //     variables: {
  //       id: messageId, // The messageId to fetch the payload for
  //     },
  //   };

  //   try {
  //     // Make a GraphQL API request to retrieve email data.
  //     const result = await API.graphql(params);

  //     // Initialize an array to store email payload content.
  //     const payloadArray = [];

  //     // Set the threadId using the result if available.
  //     setThreadId(result?.data?.gmailMessage.threadId);

  //     // Iterate through payload data and parse each content.
  //     result?.data?.gmailMessage.payload?.forEach((data, index) => {
  //       try {
  //         // Parse the email content and push it to the payload array.
  //         const content = EmailParser(JSON.parse(data?.content));
  //         payloadArray.push({ content });
  //       } catch (error) {
  //         // Handle parsing errors (e.g., invalid JSON content).
  //         console.log("Cannot parse email content:", error);
  //       }
  //     });

  //     // Return the email payload as an array of content objects.
  //     return { payload: payloadArray };
  //   } catch (error) {
  //     // Handle GraphQL API request or other errors.
  //     console.error("Failed to fetch email payload:", error);

  //     // Rethrow the error to be handled further up the call stack.
  //     throw error;
  //   }
  // };

  // const filterRecord = async (v) => {
  //   try {
  //     /*const [unSavedEmails, savedEmails, archivedEmails] = await Promise.all([
  //       processEmails(previousUnsavedEmails),
  //       processEmails(previousSavedEmails),
  //       processEmails(previousArchivedEmails),
  //     ]);*/

  //     filterAndSetEmails(previousUnsavedEmails, 1, isArchive, v.trim());
  //     filterAndSetEmails(previousSavedEmails, 2, isArchive, v.trim());
  //     filterAndSetEmails(previousArchivedEmails, 3, isArchive, v.trim());
  //   } catch (error) {
  //     console.error("Error processing emails:", error);
  //   }
  // };

  // const processEmails = async (emails) => {
  //   return Promise.all(
  //     emails.map(async (x) => {
  //       if (x.id !== null) {
  //         try {
  //           const emailPayload = await getEmailPayload(x.id);
  //           const textContent = emailPayload.payload[0].content.text
  //             .replace(/\s{2,}/g, " ")
  //             .trim();
  //           return { ...x, emailPayload: textContent };
  //         } catch (error) {
  //           return x;
  //         }
  //       }
  //       return x;
  //     })
  //   );
  // };

  // const filterAndSetEmails = (emails, openTab, isArchive, v) => {
  //   console.log("filterAndSetEmails()", emails, openTab, isArchive, v);
  //   const filteredEmails = emails.filter((x) => {
  //     // console.log("ITEM SEARCH", x);
  //     // Check if the subject, email payload, sender, recipient, to, cc, bcc exists and convert it to lowercase
  //     const subject = x.subject ? x.subject.toLowerCase() : "";
  //     //const emailPayload = x.emailPayload ? x.emailPayload.toLowerCase() : "";
  //     const snippet = x.snippet ? x.snippet.toLowerCase() : "";
  //     const from = x.from ? x.from.toLowerCase() : "";
  //     const recipient = x.recipient ? x.recipient.toLowerCase() : "";
  //     const to = x.to ? x.to.toLowerCase() : "";
  //     const cc = x.cc ? x.cc.toLowerCase() : "";
  //     const bcc = x.bcc ? x.bcc.toLowerCase() : "";

  //     // Check if the subject, email payload, sender, recipient, to, cc, bcc contains the search input
  //     return (
  //       subject.includes(v.toLowerCase()) ||
  //       //emailPayload.includes(v.toLowerCase()) ||
  //       snippet.includes(v.toLowerCase()) ||
  //       from.includes(v.toLowerCase()) ||
  //       recipient.includes(v.toLowerCase()) ||
  //       to.includes(v.toLowerCase()) ||
  //       cc.includes(v.toLowerCase()) ||
  //       bcc.includes(v.toLowerCase())
  //     );
  //   });

  //   if (!isArchive) {
  //     console.log("Filtered Email", filteredEmails);
  //     if (openTab === 1) {
  //       setUnsavedEmails(filteredEmails);
  //       setWaitUnSaved(false);
  //     } else if (openTab === 2) {
  //       setSavedEmails(filteredEmails);
  //       setWaitSaved(false);
  //     } else if (openTab === 3) {
  //       setArchivedEmails(filteredEmails);
  //       setWaitArchived(false);
  //     }
  //   } else {
  //     if (openTab === 1) {
  //       setUnsavedEmails(emails);
  //       setWaitUnSaved(false);
  //     } else if (openTab === 2) {
  //       setSavedEmails(emails);
  //       setWaitSaved(false);
  //     } else if (openTab === 3) {
  //       setArchivedEmails(emails);
  //       setWaitArchived(false);
  //     }
  //   }
  // };

  /**
   * const filterRecord = (v) => {
    // Unsaved Emails Filter
    const unprocessedUnsavedEmails = previousUnsavedEmails;
    const addPayloadToUnsavedEmails = Promise.all(
      unprocessedUnsavedEmails.map(async (x) => {
        if (x.id !== null) {
          try {
            const emailPayload = await getEmailPayload(x.id);
            console.log("emailPayload", emailPayload);
            const textContent = "" + emailPayload.payload[0].content.text + "'";
            const cleanedTextContent = textContent
              .replace(/\s{2,}/g, " ")
              .trim();
            return { ...x, emailPayload: cleanedTextContent }; // Add cleaned text content to the current object
          } catch (error) {
            return x;
          }
        }
        return x;
      })
    );

    addPayloadToUnsavedEmails
      .then((updatedEmails) => {
        // After updating unSavedEmails array, filter it based on whether any of its objects contain a string that matches the search input ("v")
        const filteredEmails = updatedEmails?.filter((x) => {
          console.log("xxxxx - UNSAVED", x.emailPayload);
          // Check if the subject, email payload, sender,recipient, to, cc, bcc exists and convert it to lowercase

          const subject = x.subject ? x.subject.toLowerCase() : "";
          // const snippet = x.snippet ? x.snippet.toLowerCase() : "";

          const emailPayload = x.emailPayload
            ? x.emailPayload.toLowerCase()
            : "";
          const from = x.from ? x.from.toLowerCase() : "";
          const recipient = x.recipient ? x.recipient.toLowerCase() : "";
          const to = x.to ? x.to.toLowerCase() : "";
          const cc = x.cc ? x.cc.toLowerCase() : "";
          const bcc = x.bcc ? x.bcc.toLowerCase() : "";

          // Check if the subject, email payload, sender,recipient, to, cc, bcc contains the search input
          return (
            subject?.includes(v.toLowerCase()) ||
            // snippet?.includes(v.toLowerCase()) ||
            emailPayload?.includes(v.toLowerCase()) ||
            from?.includes(v.toLowerCase()) ||
            recipient?.includes(v.toLowerCase()) ||
            to?.includes(v.toLowerCase()) ||
            cc?.includes(v.toLowerCase()) ||
            bcc?.includes(v.toLowerCase())
          );
        });
        // Set the "unsavedEmails" state variable to the filtered array of email objects
        if (!isArchive) {
          setUnsavedEmails(filteredEmails);
        }

        if (isArchive) {
          setUnsavedEmails(unSavedEmails);
        }

        if (openTab === 1) {
          setWaitUnSaved(false);
        }
      }).catch((error) => {
        console.error("Error processing unSavedEmails:", error); // Log an error if there was a problem updating or filtering the unSavedEmails array
      });

    // Search Saved Emails
    // Filter the "savedEmails" array based on whether any of its objects contain a string that matches the search input ("v")
    const unprocessedSavedEmails = previousSavedEmails;
    const addPayloadToSavedEmails = Promise.all(
      unprocessedSavedEmails.map(async (x) => {
        if (x.id !== null) {
          try {
            const emailPayload = await getEmailPayload(x.id);
            console.log("emailPayload", emailPayload);
            const textContent = "" + emailPayload.payload[0].content.text + "'";
            const cleanedTextContent = textContent
              .replace(/\s{2,}/g, " ")
              .trim();
            return { ...x, emailPayload: cleanedTextContent }; // Add cleaned text content to the current object
          } catch (error) {
            return x;
          }
        }
        return x;
      })
    );

    addPayloadToSavedEmails
      .then((updatedSavedEmails) => {
        // After updating unSavedEmails array, filter it based on whether any of its objects contain a string that matches the search input ("v")
        const filteredSavedEmails = updatedSavedEmails.filter((x) => {
          console.log("xxxxx - SAVED", x.emailPayload);
          // Check if the subject, email payload, sender,recipient, to, cc, bcc exists and convert it to lowercase

          const subject = x.subject ? x.subject.toLowerCase() : "";
          // const snippet = x.snippet ? x.snippet.toLowerCase() : "";

          const emailPayload = x.emailPayload
            ? x.emailPayload.toLowerCase()
            : "";
          const from = x.from ? x.from.toLowerCase() : "";
          const recipient = x.recipient ? x.recipient.toLowerCase() : "";
          const to = x.to ? x.to.toLowerCase() : "";
          const cc = x.cc ? x.cc.toLowerCase() : "";
          const bcc = x.bcc ? x.bcc.toLowerCase() : "";

          // Check if the subject, email payload, sender,recipient, to, cc, bcc contains the search input
          return (
            subject.includes(v.toLowerCase()) ||
            // snippet.includes(v.toLowerCase()) ||
            emailPayload.includes(v.toLowerCase()) ||
            from.includes(v.toLowerCase()) ||
            recipient.includes(v.toLowerCase()) ||
            to.includes(v.toLowerCase()) ||
            cc.includes(v.toLowerCase()) ||
            bcc.includes(v.toLowerCase())
          );
        });
        // Set the "unsavedEmails" state variable to the filtered array of email objects
        if (!isArchive) {
          setSavedEmails(filteredSavedEmails);
        }

        if (isArchive) {
          setSavedEmails(savedEmails);
        }

        if (openTab === 2) {
          setWaitSaved(false);
        }
      })
      .catch((error) => {
        console.error("Error processing savedEmails:", error); // Log an error if there was a problem updating or filtering the unSavedEmails array
      });


    // Search Archived Emails
    // Filter the "archivedEmails" array based on whether any of its objects contain a string that matches the search input ("v")
    const unprocessedArchivedEmails = previousArchivedEmails;
    const addPayloadToArchivedEmails = Promise.all(
      unprocessedArchivedEmails.map(async (x) => {
        if (x.id !== null) {
          try {
            const emailPayload = await getEmailPayload(x.id);
            console.log("emailPayload", emailPayload);
            const textContent = "" + emailPayload.payload[0].content.text + "'";
            const cleanedTextContent = textContent
              .replace(/\s{2,}/g, " ")
              .trim();
            return { ...x, emailPayload: cleanedTextContent }; // Add cleaned text content to the current object
          } catch (error) {
            return x;
          }
        }
        return x;
      })
    );

    addPayloadToArchivedEmails
      .then((updatedArchivedEmails) => {
        // After updating unSavedEmails array, filter it based on whether any of its objects contain a string that matches the search input ("v")
        const filteredArchivedEmails = updatedArchivedEmails.filter((x) => {
          console.log("xxxxx - ARCHIVED", x.emailPayload);
          // Check if the subject, email payload, sender,recipient, to, cc, bcc exists and convert it to lowercase

          const subject = x.subject ? x.subject.toLowerCase() : "";
          // const snippet = x.snippet ? x.snippet.toLowerCase() : "";

          const emailPayload = x.emailPayload
            ? x.emailPayload.toLowerCase()
            : "";
          const from = x.from ? x.from.toLowerCase() : "";
          const recipient = x.recipient ? x.recipient.toLowerCase() : "";
          const to = x.to ? x.to.toLowerCase() : "";
          const cc = x.cc ? x.cc.toLowerCase() : "";
          const bcc = x.bcc ? x.bcc.toLowerCase() : "";

          // Check if the subject, email payload, sender,recipient, to, cc, bcc contains the search input
          return (
            subject.includes(v.toLowerCase()) ||
            // snippet.includes(v.toLowerCase()) ||
            emailPayload.includes(v.toLowerCase()) ||
            from.includes(v.toLowerCase()) ||
            recipient.includes(v.toLowerCase()) ||
            to.includes(v.toLowerCase()) ||
            cc.includes(v.toLowerCase()) ||
            bcc.includes(v.toLowerCase())
          );
        });
        // Set the "unsavedEmails" state variable to the filtered array of email objects
        if (!isArchive) {
          setArchivedEmails(filteredArchivedEmails);
        }

        if (isArchive) {
          setArchivedEmails(archivedEmails);
        }

        if (openTab === 3) {
          setWaitArchived(false);
        }
      })
      .catch((error) => {
        console.error("Error processing archivedEmails:", error); // Log an error if there was a problem updating or filtering the unSavedEmails array
      });
  }; */

  ///////////////////////////////////////////////////////////////////////////
  // Comment or uncomment the code block below AND the useIdleTimer import statement to toggle session timeout

  //SESSION TIMEOUT (UNSTABLE/WILL CHANGE SOON)
  // var timeoutId, timeoutNotif;

  // const handleOnAction = (event) => {
  //   if (showSessionTimeout) {
  //     localStorage.removeItem("userId");
  //     localStorage.removeItem("email");
  //     localStorage.removeItem("firstName");
  //     localStorage.removeItem("lastName");
  //     localStorage.removeItem("userType");
  //     localStorage.removeItem("company");
  //     localStorage.removeItem("companyId");
  //     // localStorage.removeItem("access");
  //   }

  //   clearTimeout(timeoutId);
  //   clearTimeout(timeoutNotif);
  // };

  // const handleOnIdle = (event) => {
  //   timeoutId = setTimeout(() => {
  //     // timeoutNotif = setTimeout(() => {
  //     //   setShowSessionTimeout(true);
  //     // }, 1000 * 300); //5 minutes to confirm

  //     // if (
  //     //   alert(
  //     //     "Session will expire in 5 minutes. Please confirm that you are still active before the session expires"
  //     //   )
  //     // ) {
  //     //   clearTimeout(timeoutNotif);
  //     //   clearTimeout(timeoutId);
  //     // }
  //     setShowSessionTimeout(true);
  //   }, 60000 * 120); //2 hours before session timeout
  // };

  // useIdleTimer({
  //   timeout: 60 * 40,
  //   onAction: handleOnAction,
  //   onIdle: handleOnIdle,
  //   debounce: 1000,
  // });

  const selectAll = (e) => {};

  /*
   * @ PARAMS @
   *
   * e: event
   * bypassClientMatter: if clientMatter should be accounted for when checking items
   *
   * NOTE: bypassClientMatter is usually ticked true inside actionButtons component only
   */

  const selectAllUnsaved = (e, bypassClientMatter) => {
    if (bypassClientMatter) {
      const { id, checked } = e.target;

      if (selectAllCheck) {
        setSelectAllCheck(false);
        setSelectedUnsavedItems([]);
      } else {
        setSelectAllCheck(true);

        if (!checked) {
          setSelectedUnsavedItems([]);
        } else {
          console.log(e);
          let temp = [];

          unSavedEmails.map((x) => {
            x.clientMatters.items.length > 0 && (temp = [...temp, x.id]);
          });

          setSelectedUnsavedItems(temp);
          console.log(selectedUnsavedItems);
        }
      }
    } else {
      const { id, checked } = e.target;

      if (selectAllCheck) {
        setSelectAllCheck(false);
        setSelectedUnsavedItems([]);
      } else {
        setSelectAllCheck(true);

        if (!checked) {
          setSelectedUnsavedItems([]);
        } else {
          console.log(e);
          let temp = [];

          unSavedEmails.map((x) => {
            x.clientMatters.items.length > 0 && (temp = [...temp, x.id]);
          });

          setSelectedUnsavedItems(temp);
          console.log(selectedUnsavedItems);
        }
      }
    }
  };

  const selectAllSaved = (e) => {
    const { id, checked } = e.target;

    if (selectAllCheck) {
      setSelectAllCheck(false);
      setSelectedSavedItems([]);
    } else {
      setSelectAllCheck(true);

      if (!checked) {
        setSelectedSavedItems([]);
      } else {
        let temp = [];

        savedEmails.map((x) => {
          x.clientMatters.items.length > 0 && (temp = [...temp, x.id]);
        });

        setSelectedSavedItems(temp);
        console.log(selectedSavedItems);
      }
    }
  };

  const selectAllArchived = (e) => {
    const { id, checked } = e.target;

    if (selectAllCheck) {
      setSelectAllCheck(false);
      setSelectedArchivedItems([]);
    } else {
      setSelectAllCheck(true);

      if (!checked) {
        setSelectedArchivedItems([]);
      } else {
        let temp = [];

        /*
         * MODIFIED THIS BECAUSE I THINK IT IS UNNECESSARY TO
         * CHECK IF AN EMAIL HAS A CLIENTMATTER INSIDE ARCHIVE PAGE.
         * IF I MADE A MISTAKE HERE, AND YOU WANT TO REVERT TO
         * THE OLD VERSION OF THE CODE BELOW, HERE IT IS:
         *
         * archivedEmails.map((x) => {
         *    x.clientMatters.items.length > 0 && (temp = [...temp, x.id]);
         * });
         */

        archivedEmails.map((x) => {
          temp = [...temp, x.id];
        });

        setSelectedArchivedItems(temp);
        console.log(selectedArchivedItems);
      }
    }
  };

  //assignee modal functions
  useEffect(() => {
    if (people.length === 0) {
      fetchCompanyUsers();
    }

    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        activity: `page has been accessed.`,
        appModule: "INBOX",
        userId: localStorage.getItem("userId"),
      },
    };
    const addActivity = API.graphql(params).then((result) => {
      console.log("addActivity result", result);
    });

    // List default items for mobile 1 time only
    // if (width < 844) {
    // fetchData();
    //}
  }, []);

  useEffect(() => {
    if (isOutlookIntegrated !== null) fetchData();
  }, [isOutlookIntegrated]);
  const [people, setPeople] = useState([]);

  async function fetchCompanyUsers() {
    var assignees = [];
    if (companyId === "7a7d3c83-4cb3-49cb-acef-82b7b71054eb") {
      //lop company id
      //master companyid
      const res = await API.graphql({
        query: qGetTeams,
        variables: {
          id: companyId,
        },
      });

      var teamNames = res.data.company.teams.items;
      var specialTeamId = "";

      /* Finding the team id of the team named "Operations" */
      for (var i = 0; i < teamNames.length; i++) {
        if (teamNames[i].name?.toLowerCase() === "operations") {
          specialTeamId = teamNames[i].id;
          break;
        } else {
          specialTeamId = "0"; //placeholder
        }
      }

      if (specialTeamId === "0") {
        const { data } = await API.graphql({
          query: COMPANY_USERS_QUERY,
          variables: {
            id: companyId,
          },
        });

        data.company.users.items.map(
          (x) =>
            (assignees = [
              ...assignees,
              {
                label: `${x.firstName} ${x.lastName}`,
                value: `${x.firstName} ${x.lastName}`,
              },
            ])
        );

        setPeople(assignees); //set regular list as options, null catcher
        console.log("peoplelist", assignees);
      } else {
        const res1 = await API.graphql({
          query: qGetTeamsWithMembers,
          variables: {
            id: specialTeamId,
          },
        });

        var members = res1.data.team.members.items;

        for (var j = 0; j < members.length; j++) {
          assignees = [
            ...assignees,
            {
              label: `${members[j].user.firstName} ${members[j].user.lastName}`,
              value: `${members[j].user.firstName} ${members[j].user.lastName}`,
            },
          ];
        }

        setPeople(assignees); //filtered people
        console.log("peoplelist", assignees);
      }
    } else {
      const { data } = await API.graphql({
        query: COMPANY_USERS_QUERY,
        variables: {
          id: companyId,
        },
      });

      data.company.users.items.map(
        (x) =>
          (assignees = [
            ...assignees,
            {
              label: `${x.firstName} ${x.lastName}`,
              value: `${x.firstName} ${x.lastName}`,
            },
          ])
      );

      setPeople(assignees); //set regular list as options
      console.log("peoplelist", assignees);
    }
  }

  const handleFilter = () => {
    console.log("HANDLE SEARCH TRIGGERED AFTER CHANGING CLIENT/MATTER");
    //filterRecord(searchGmail, openTab);
  };

  // Will change toggle button
  // const [changeButton, setChangeButton] = useState("inbox");

  // const handleChangeButton = (e, button) => {
  //   if (changeButton !== null && changeButton.style !== undefined) {
  //     changeButton.style.color = "#6b7280";
  //   }
  //   e.currentTarget.style.color = "black";

  //   setChangeButton(button);
  // };

  // MMA-1941
  // Handle resizes with mobile view (when keyboard or navigation bar appears)
  const [componentBottom, setComponentBottom] = useState(0);
  const handleResizeAndFocus = () => {
    const extraHeight = window.outerHeight - window.innerHeight;
    // console.log(
    //   "heights. innerHeight: " +
    //     window.innerHeight +
    //     " outerHeight: " +
    //     window.outerHeight
    // );
    // console.log("changing height. extra: ", extraHeight);
    setComponentBottom(extraHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeAndFocus);
    return () => {
      window.removeEventListener("resize", handleResizeAndFocus);
    };
  }, []);

  // Debounce the fetchData function to avoid unnecessary API calls while typing
  const debouncedFetchData = debounce(fetchData, 300);

  // TANSTACK VIRTUALIZED
  const tableContainerRef = useRef(null);

  const table = useReactTable({
    data:
      openTab === 1
        ? unSavedEmails
        : openTab === 2
        ? savedEmails
        : openTab === 3
        ? archivedEmails
        : [],
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    parentRef: tableContainerRef,
    // Added condition for loading
    //getItemKey: (index) => (data.length !== 0 ? data[index]?.id : index),
    count: rows.length,
    overscan: width < 844 ? rows.length : 5,
    // estimateSize: useCallback(() => 310 , []), // THIS IS AN ESTIMATE FOR WEB, ADD CONDITION FOR MOBILE
    estimateSize: useCallback(() => (width > 844 ? 81 : 310), []), // THIS IS AN ESTIMATE FOR WEB, ADD CONDITION FOR MOBILE
    //initialOffset: 0,
    //getTotalSize: () => height,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  const totalSize = rowVirtualizer.getTotalSize();

  const isFetchingNextPage = useRef(false);

  const unsavedCurrToken = useRef(null);
  const savedCurrToken = useRef(null);
  const archivedCurrToken = useRef(null);

  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse();

    if (
      !lastItem ||
      width < 844 ||
      !(lastItem.index >= rows.length - 1) ||
      isFetchingNextPage.current
    ) {
      return;
    }

    debouncedFetchNextData(50);
  }, [virtualRows]);

  const handleFetchNextData = async (batchSize) => {
    if (isFetchingNextPage.current) return;

    switch (openTab) {
      case 1:
        if (unsavedCurrToken.current) {
          isFetchingNextPage.current = true;
          getUnSavedEmails(emailFilters, batchSize).then(() => {
            isFetchingNextPage.current = false;
          });
        }
        break;
      case 2:
        if (savedCurrToken.current) {
          isFetchingNextPage.current = true;
          getSavedEmails(emailFilters, batchSize).then(() => {
            isFetchingNextPage.current = false;
          });
        }
        break;
      case 3:
        if (archivedCurrToken.current) {
          isFetchingNextPage.current = true;
          getArchivedEmails(emailFilters, batchSize).then(() => {
            isFetchingNextPage.current = false;
          });
        }
        break;
      default:
        break;
    }
  };

  const debouncedFetchNextData = debounce(handleFetchNextData, 300);

  const vTablePaddingTop =
    virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const vTablePaddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  // useEffect(() => {
  //   // Update virtual rows when data or other dependencies change
  //   rowVirtualizer.getVirtualItems();
  // }, [virtualRows, /* other dependencies */]);

  // FOR TESTING RENDER TIME
  // const aveTime = useRef(1);

  // useLayoutEffect(() => {
  //   const startTime = new Date();
  //   return () => {
  //       const endTime = new Date();
  //       const timeRendered = endTime - startTime;
  //       aveTime.current = ((aveTime.current * 4 + timeRendered) / 5);
  //       console.log("TEST RENDER TIME:", timeRendered, " AVERAGE: ", aveTime.current); // Expect this to be a positive number
  //   }
  // }, [unSavedEmails]);

  //   useEffect(() => {
  //   const interval = setInterval(() => {
  //     setUnsavedEmails((emails) => [...emails]);
  //   }, 1000)

  //   return () => clearInterval(interval)
  // }, [])

  return (
    <>
      {width > 844 ? (
        //WEB VERSION//
        <>
          {isOutlookIntegrated !== null && !isOutOfSync ? (
            // (refreshToken &&
            //   connectedEmail &&
            //   companyIntegratedEmail &&
            //   connectedEmail === companyIntegratedEmail)
            <div
              className=" relative flex min-w-0 flex-col break-words"
              style={contentDiv}
            >
              <div
                id="header"
                style={{
                  width: "100%",
                  // width: width * 0.93,
                  // paddingRight:
                  //   width > 1020
                  //     ? "0px"
                  //     : width > 920
                  //       ? width / 10 + "px"
                  //       : width / 5 + "px",
                }}
                className="z-20 border-gray-300 border-b flex grid-cols-1 items-center justify-between gap-2 py-2 md:grid-cols-8 overflow-x-auto"
              >
                <div className="top-0 z-10 col-span-3 flex items-center gap-2 bg-white py-0 xl:col-span-4 ">
                  <div
                    onClick={() => history.replace(dashboardPath)}
                    className="w-8 cursor-pointer pl-5 pr-3"
                  >
                    <CgChevronLeft />
                  </div>
                  <div>
                    {/* NOTE:NAVLIST */}
                    <div className="inline-flex justify-center items-center">
                      <div className="flex gap-5 px-1">
                        <button
                          className="inline-flex items-center gap-1 border-none text-lg"
                          style={{
                            color: toggleSent ? "#6b7280" : "black",
                          }}
                          onClick={(e) => {
                            settoggleSent(false);
                          }}
                        >
                          <AiOutlineMail />
                          <span
                            style={{
                              color: toggleSent ? "#6b7280" : "black",
                              display:
                                window.innerHeight > 1200 ? "none" : "flex",
                            }}
                          >
                            Inbox
                          </span>
                        </button>
                        <button
                          className="inline-flex items-center gap-1 border-none text-lg"
                          style={{
                            color: toggleSent ? "black" : "#6b7280",
                          }}
                          onClick={(e) => {
                            settoggleSent(true);
                          }}
                        >
                          <RiMailCheckLine />
                          <span
                            style={{
                              color: toggleSent ? "black" : "#6b7280",
                              display:
                                window.innerHeight > 1200 ? "none" : "flex",
                            }}
                          >
                            Sent
                          </span>
                        </button>
                      </div>
                      <TabsRender
                        // color="gray"
                        setThreadId={setThreadId}
                        setIsReply={setIsReply}
                        setIsModal={setIsModal}
                        openTab={openTab}
                        setOpenTab={setOpenTab}
                        unSavedEmails={unSavedEmails}
                        savedEmails={savedEmails}
                        archivedEmails={archivedEmails}
                        unSavedEmailsCount={unSavedEmailsCount}
                        savedEmailsCount={savedEmailsCount}
                        archivedEmailsCount={archivedEmailsCount}
                        selectedUnsavedItemsCtr={selectedUnsavedItems.length}
                        selectedSavedItemsCtr={selectedSavedItems.length}
                        selectedArchivedItemsCtr={selectedArchivedItems.length}
                        unSavedForTaskListCtr={unSavedForTaskListCtr}
                        savedForTaskListCtr={savedForTaskListCtr}
                        toggleSent={toggleSent}
                        settoggleSent={settoggleSent}
                        showDetailsCopy={showDetailsCopy}
                        setShowDetailsCopy={setShowDetailsCopy}
                        setEmailContentOpen={setEmailContentOpen}
                      />
                      {/* <div className="flex gap-2 pt-2">
                        <input
                          type="checkbox"
                          onClick={
                            openTab === 1
                              ? (e) => selectAllUnsaved(e, true)
                              : openTab === 2
                                ? (e) => selectAllSaved(e)
                                : (e) => selectAllArchived(e)
                          }
                          checked={selectAllCheck ? true : false}
                          className="flex-shrink-0"
                        />
                        <span className="w-20 text-xs font-semibold text-gray-500">
                          {selectAllCheck ? "Deselect All" : "Select All"}
                        </span>
                      </div> */}

                      {/* <Switch
                        checked={toggleSent}
                        onChange={() => settoggleSent(!toggleSent)}
                        className={`${
                          toggleSent ? "cursor-pointer bg-black" : "bg-black"
                        } relative inline-flex h-5 w-8 items-center rounded-full`}
                      >
                        <span
                          className={`${
                            toggleSent ? "translate-x-4" : "translate-x-1"
                          } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                        />
                      </Switch> */}
                    </div>
                    {/* <div className="flex items-center gap-3 text-gray-500">
                      <BiEnvelope />
                      <p className="font-semibold">
                        {toggleSent ? "Manage sent messages" : "Manage inbox"}
                      </p>
                    </div> */}
                  </div>
                </div>

                <div className="col-span-5 flex w-7/12 xl:col-span-4 ">
                  <div className="hidden searchBar relative justify-between flex-row items-center rounded-md border bg-white">
                    <div className="flex flex-row">
                      <div className="flex w-8 justify-center pl-2">
                        <span className="flex items-center rounded rounded-r-none border-0 bg-white px-2 text-xl leading-normal text-gray-600">
                          <IoIosSearch
                            className="cursor-pointer"
                            onClick={(e) => fetchData()}
                          />
                        </span>
                      </div>

                      <div
                        className="flex flex-row "
                        style={{ width: width * 0.16 }}
                      >
                        <div className="flex justify-center ">
                          <input
                            type="search"
                            id="SearchD"
                            rows={1}
                            style={{
                              whiteSpace: "nowrap",
                              resize: "none",
                              cursor: "default",
                              width: width * 0.158,
                            }}
                            className="flex outline-none overflow-y-hidden overflow-x-scroll border-grey-light rounded rounded-l-none border-0 pl-1 pr-3 text-sm leading-normal h-10"
                            placeholder="Type to search all emails ..."
                            value={searchGmail}
                            onSubmitEditing={(e) =>
                              handleSearchGmailChange(e, "enter")
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSearchGmailChange(e, "enter");
                              }
                            }}
                            
                            onInput={(e) => {
                              if (e.target.value === "") {
                                setHasSearchVal(false);
                                handleSearchGmailChange(e, "clear");
                              } else {
                                setHasSearchVal(true);
                                handleSearchGmailChange(e, "typed");
                                // debouncedFetchData();
                              }
                            }}
                            onPaste={(e) =>
                              handleSearchGmailChange(e, "pasted")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row">
                      {/* <div className="flex w-8 justify-center pl-2 mr-1"
                      onClick={() => {
                        document.getElementById("SearchD").value = "";
                        fetchData("clear");
                        setHasSearchVal(false);
                        //document.getElementById("SearchD").value = width;
                      }}
                      >
                        <span className="flex items-center rounded rounded-r-none border-0 bg-white px-2 text-xl leading-normal text-gray-600">
                          <MdClear
                            id="mdclearB"
                            className="md-clear cursor-pointer"
                            color="blue"
                            style={{
                              display: hasSearchVal ? "flex" : "none"
                            }}
                          />
                        </span>
                      </div> */}

                      <div className="mr-1">
                        <Button
                          variant="default-s"
                          size="xs"
                          onClick={() => setshowFiltersModal(true)}
                          className="px-3 py-3"
                        >
                          <RiFilter2Fill size={20} />
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="ml-5 flex flex-grow justify-end	">
                    <div className="flex ">
                      {/* {!isOutlookIntegrated && renderGmailButton && (
                      <GoogleSignIn
                        refreshToken={refreshToken}
                        setRefreshToken={setRefreshToken}
                        isOutOfSync={isOutOfSync}
                        setIsOutOfSync={setIsOutOfSync}
                        connectedEmail={connectedEmail}
                        setConnectedEmail={setConnectedEmail}
                        isOutlookIntegrated={isOutlookIntegrated}
                        connectedOutlookAccount={connectedOutlookAccount}
                        getSavedAuthToken={getSavedAuthToken}
                      />
                    )}
                    {isOutlookIntegrated && (
                      <OutlookSignIn
                        isOutlookIntegrated={isOutlookIntegrated}
                        connectedOutlookAccount={connectedOutlookAccount}
                        setConnectedOutlookAccount={setConnectedOutlookAccount}
                        getSavedAuthToken={getSavedAuthToken}
                      />
                    )} */}
                    </div>

                    {/* {renderGmailButton && (
                      <GmailIntegration
                        refreshToken={refreshToken}
                        isOutOfSync={isOutOfSync}
                      />
                    )}*/}
                  </div>
                  <div
                    // style={{
                    //   width:
                    //     width > 1300 || window.innerHeight > 1800
                    //       ? "20px"
                    //       : width > 1120
                    //       ? "50px"
                    //       : width > 920
                    //       ? "70px"
                    //       : "90px",
                    // }}
                    style={{
                      // width: width > 1300 || window.innerHeight > 1800 ? "20px" :
                      //   width > 1200 ? "40px" : width > 1120 ? "50px" : width > 920 ? "70px" : "90px"
                      width:
                        width > 1300
                          ? width / 50 + "px"
                          : width > 1190
                          ? width / 35 + "px"
                          : width > 1020
                          ? width / 20 + "px"
                          : width / 10 + "px",
                    }}
                  >
                    ㅤ
                  </div>
                </div>
              </div>
              {/* new div */}
              <div className="bg-neutral-100">
                {/* <TabsRender
                  color="gray"
                  setThreadId={setThreadId}
                  setIsReply={setIsReply}
                  openTab={openTab}
                  setOpenTab={setOpenTab}
                  unSavedEmails={unSavedEmails}
                  savedEmails={savedEmails}
                  archivedEmails={archivedEmails}
                  selectedUnsavedItemsCtr={selectedUnsavedItems.length}
                  selectedSavedItemsCtr={selectedSavedItems.length}
                  selectedArchivedItemsCtr={selectedArchivedItems.length}
                  unSavedForTaskListCtr={unSavedForTaskListCtr}
                  savedForTaskListCtr={savedForTaskListCtr}
                  toggleSent={toggleSent}
                  settoggleSent={settoggleSent}
                /> */}
                {/* Select all check box  */}
                {/* {!isOutOfSync && (
                  <div className="flex float-right py-1 ml-3">
                    <input
                      type="checkbox"
                      onClick={
                        openTab === 1
                          ? (e) => selectAllUnsaved(e)
                          : openTab === 2
                          ? (e) => selectAllSaved(e)
                          : (e) => selectAllArchived(e)
                      }
                      checked={selectAllCheck ? true : false}
                    />
                    <p className="ml-2 text-xs font-semibold">SELECT ALL</p>
                  </div>
                )} */}
                <div className="flex m-10 mb-0">
                  <div className="w-full">
                    <div className="z-20 border-gray-300 border flex items-center justify-between gap-2 bg-white py-0 rounded-t-xl">
                      <div className="flex-auto">
                        <InboxFilter
                          executeFilter={handleExecuteFilter}
                          closeFiltersModal={handleFiltersModalClose}
                          currentFilter={emailFilters}
                          setMobileLoading={setMobileLoading}
                          // search={setSearchGmail}
                          connectedEmail={connectedEmail}
                          connectedOutlookAccount={connectedOutlookAccount}
                          isOutlookIntegrated={isOutlookIntegrated} //OUTLOOK
                          selectAllUnsaved={selectAllUnsaved}
                          selectAllSaved={selectAllSaved}
                          openTab={openTab}
                          selectAllArchived={selectAllArchived}
                          selectAllCheck={selectAllCheck}
                          allowComposeEmail={allowComposeEmail}
                          setIsModal={setIsModal}
                          setIsReply={setIsReply}
                          fetchData={fetchData}
                          width={width}
                          searchGmail={searchGmail}
                          handleSearchGmailChange={handleSearchGmailChange}
                          setHasSearchVal={setHasSearchVal}
                          debouncedFetchData={debouncedFetchData}
                          setshowFiltersModal={setshowFiltersModal}
                          setEmailContentOpen={setEmailContentOpen}
                          handleShowCreateClientMatter={
                            handleShowCreateClientMatter
                          }
                        />
                        <div className="tab-content tab-space">
                          {openTab === 1 ? (
                            <div
                              className={openTab === 1 ? "block" : "hidden"}
                              id="link1"
                            >
                              <TableUnsavedInfo
                                setUnsavedEmailsCount={setUnsavedEmailsCount}
                                setSavedEmailsCount={setSavedEmailsCount}
                                setArchivedEmailsCount={setArchivedEmailsCount}
                                taskListColumns={taskListColumns}
                                history={history}
                                location={location}
                                setShowMobileNotificationModal={
                                  setShowMobileNotificationModal
                                }
                                copyMore20={copyMore20}
                                setCopyMore20={setCopyMore20}
                                isArchive={isArchive}
                                setIsArchive={setIsArchive}
                                handleComposeUnsavedTabs={
                                  handleComposeUnsavedTabs
                                }
                                contactEmailList={contactEmailList}
                                setContactEmailList={setContactEmailList}
                                selectedUnsavedItems={selectedUnsavedItems}
                                setSelectedUnsavedItems={
                                  setSelectedUnsavedItems
                                }
                                selectedArchivedItems={selectedArchivedItems}
                                setSelectedArchivedItems={
                                  setSelectedArchivedItems
                                }
                                unSavedEmails={unSavedEmails}
                                setUnsavedEmails={setUnsavedEmails}
                                matterList={matterList}
                                maxLoadingUnSavedEmail={maxLoadingUnSavedEmail}
                                emailFilters={emailFilters}
                                getUnSavedEmails={getUnSavedEmails}
                                labelsList={labelsList}
                                waitUnSaved={waitUnSaved}
                                sortByDate={sortByDate}
                                connectedEmail={connectedEmail}
                                userTimeZone={userTimeZone}
                                momentTZ={momentTZ}
                                qGmailMessagesbyCompany={
                                  qGmailMessagesbyCompany
                                }
                                setAttachmentIsDeleted={setAttachmentIsDeleted}
                                attachmentIsDeleted={attachmentIsDeleted}
                                setAttachmentId={setAttachmentId}
                                attachmentId={attachmentId}
                                lastCounter={lastCounter}
                                setSelectedTaskList={setSelectedTaskList}
                                selectedTaskList={selectedTaskList}
                                selectedTaskRegister={selectedTaskRegister}
                                setSelectedTaskRegister={
                                  setSelectedTaskRegister
                                }
                                taskRegisterList={taskRegisterList}
                                setTaskRegisterList={setTaskRegisterList}
                                onCreateNewOption={createNewRegister}
                                show={show}
                                setShow={setShow}
                                setEmailInfo={setEmailInfo}
                                setShowToast={setShowToast}
                                setResultMessage={setResultMessage}
                                setUnsavedForTaskListCtr={
                                  setUnsavedForTaskListCtr
                                }
                                displayImageAttachments={
                                  displayImageAttachments
                                }
                                bindList={bindList}
                                isOutOfSync={isOutOfSync}
                                showOtherDetails={showOtherDetails}
                                setShowOtherDetails={setShowOtherDetails}
                                otherDetailsId={otherDetailsId}
                                setOtherDetailsId={setOtherDetailsId}
                                setWebAssignee={setWebAssignee}
                                saveLoading={saveLoading}
                                assigneeId={assigneeId}
                                setAssigneeId={setAssigneeId}
                                assigneeVal={assigneeVal}
                                setAssigneeVal={setAssigneeVal}
                                mobileLoading={mobileLoading}
                                setMobileLoading={setMobileLoading}
                                priorityVal={priorityVal}
                                setPriorityVal={setPriorityVal}
                                notesVal={notesVal}
                                setNotesVal={setNotesVal}
                                handleFilter={handleFilter}
                                searchGmail={searchGmail}
                                setshowAddContactModal={setshowAddContactModal}
                                fetchCompanyClientMatters={
                                  fetchCompanyClientMatters
                                }
                                setEmailContentOpen={setEmailContentOpen}
                                emailContentOpen={emailContentOpen}
                                setShowPreview={setShowPreview}
                                toggleSent={toggleSent}
                                allowComposeEmail={allowComposeEmail}
                                setSaveLoading={setSaveLoading}
                                archivedEmails={archivedEmails}
                                setArchivedEmails={setArchivedEmails}
                                showCopy={showCopy}
                                setShowCopy={setShowCopy}
                                showDetailsCopy={showDetailsCopy}
                                setShowDetailsCopy={setShowDetailsCopy}
                                pastedIds={pastedIds}
                                setPastedIds={setPastedIds}
                                copyId={copyId}
                                setCopyId={setCopyId}
                                reloadKey={reloadKey}
                                setReloadKey={setReloadKey}
                                key={reloadKey}
                                onScroll={handleScrollPosition}
                                scrollPosition={scrollPosition}
                                recordScroll={recordScroll}
                                setRecordScroll={setRecordScroll}
                                isOutlookIntegrated={isOutlookIntegrated}
                                refreshToken={refreshToken}
                                setRefreshToken={setRefreshToken}
                                setIsOutOfSync={setIsOutOfSync}
                                setConnectedEmail={setConnectedEmail}
                                connectedOutlookAccount={
                                  connectedOutlookAccount
                                }
                                tasklistGmailIds={tasklistGmailIds}
                                setTasklistGmailIds={setTasklistGmailIds}
                                unSavedForTaskListCtr={unSavedForTaskListCtr}
                                savedForTaskListCtr={savedForTaskListCtr}
                                getArchivedEmails={getArchivedEmails}
                                setDoneAllocatingEmails={
                                  setDoneAllocatingEmails
                                }
                                setpendingMessage={setpendingMessage}
                                setPreviousUnsavedEmails={
                                  setPreviousUnsavedEmails
                                }
                                setPreviousSavedEmails={setPreviousSavedEmails}
                                setPreviousArchivedEmails={
                                  setPreviousArchivedEmails
                                }
                                setSearchGmail={setSearchGmail}
                                selectAllArchived={selectAllArchived}
                                selectAllSaved={selectAllSaved}
                                selectAllUnsaved={selectAllUnsaved}
                                setDisplayImageAttachments={
                                  setDisplayImageAttachments
                                }
                                selectAllCheck={selectAllCheck}
                                setSelectAllCheck={setSelectAllCheck}
                                handleExecuteFilter={handleExecuteFilter}
                                savedEmails={savedEmails}
                                setSavedEmails={setSavedEmails}
                                getSavedEmails={getSavedEmails}
                                selectedSavedItems={selectedSavedItems}
                                setSelectedSavedItems={setSelectedSavedItems}
                                openTab={openTab}
                                fetchData={fetchData}
                                setWaitUnSaved={setWaitUnSaved}
                                setWaitSaved={setWaitSaved}
                                setWaitArchived={setWaitArchived}
                                setOpenTab={setOpenTab}
                                // COMPOSE FUNCTIONS
                                isUpdatedClientMatter={isUpdatedClientMatter}
                                setIsUpdatedClientMatter={
                                  setIsUpdatedClientMatter
                                }
                                setSelectedRawHtml={setSelectedRawHtml}
                                setSubject={setSubject}
                                setSelectedIsReplyDetails={
                                  setSelectedIsReplyDetails
                                }
                                setSelectedThreadId={setSelectedThreadId}
                                setSelectedMessageId={setSelectedMessageId}
                                saveProgress={saveProgress}
                                setSaveProgress={setSaveProgress}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                loadingCM={loadingCM}
                              />
                            </div>
                          ) : openTab === 2 ? (
                            <div
                              className={openTab === 2 ? "block" : "hidden"}
                              id="link2"
                            >
                              <TableSavedInfo
                                setUnsavedEmailsCount={setUnsavedEmailsCount}
                                setSavedEmailsCount={setSavedEmailsCount}
                                setArchivedEmailsCount={setArchivedEmailsCount}
                                taskListColumns={taskListColumns}
                                selectedSavedItems={selectedSavedItems}
                                setSelectedSavedItems={setSelectedSavedItems}
                                savedEmails={savedEmails}
                                setSavedEmails={setSavedEmails}
                                matterList={matterList}
                                maxLoadingSavedEmail={maxLoadingSavedEmail}
                                waitSaved={waitSaved}
                                sortByDate={sortByDate}
                                setAttachmentIsDeleted={setAttachmentIsDeleted}
                                attachmentIsDeleted={attachmentIsDeleted}
                                setSelectedTaskList={setSelectedTaskList}
                                selectedTaskList={selectedTaskList}
                                tasklistGmailIds={tasklistGmailIds}
                                setTasklistGmailIds={setTasklistGmailIds}
                                show={show}
                                setShow={setShow}
                                setShowToast={setShowToast}
                                setResultMessage={setResultMessage}
                                setSavedForTaskListCtr={setSavedForTaskListCtr}
                                isOutOfSync={isOutOfSync}
                                mobileLoading={mobileLoading}
                                setMobileLoading={setMobileLoading}
                                toggleSent={toggleSent}
                                setShowPreview={setShowPreview}
                                taskRegisterList={taskRegisterList}
                                isOutlookIntegrated={isOutlookIntegrated}
                                emailFilters={emailFilters}
                                searchGmail={searchGmail}
                                setShowCopy={setShowCopy}
                                showDetailsCopy={showDetailsCopy}
                                setShowDetailsCopy={setShowDetailsCopy}
                                showCopy={showCopy}
                                selectedTaskRegister={selectedTaskRegister}
                                setSelectedTaskRegister={
                                  setSelectedTaskRegister
                                }
                                displayImageAttachments={
                                  displayImageAttachments
                                }
                                setSaveLoading={setSaveLoading}
                                archivedEmails={archivedEmails}
                                setArchivedEmails={setArchivedEmails}
                                unSavedEmails={unSavedEmails}
                                setUnsavedEmails={setUnsavedEmails}
                                getUnSavedEmails={getUnSavedEmails}
                                selectedUnsavedItems={selectedUnsavedItems}
                                setSelectedUnsavedItems={
                                  setSelectedUnsavedItems
                                }
                                selectedArchivedItems={selectedArchivedItems}
                                setSelectedArchivedItems={
                                  setSelectedArchivedItems
                                }
                                savedForTaskListCtr={savedForTaskListCtr}
                                getArchivedEmails={getArchivedEmails}
                                setDoneAllocatingEmails={
                                  setDoneAllocatingEmails
                                }
                                setpendingMessage={setpendingMessage}
                                setPreviousUnsavedEmails={
                                  setPreviousUnsavedEmails
                                }
                                setPreviousSavedEmails={setPreviousSavedEmails}
                                setPreviousArchivedEmails={
                                  setPreviousArchivedEmails
                                }
                                setSearchGmail={setSearchGmail}
                                selectAllArchived={selectAllArchived}
                                selectAllSaved={selectAllSaved}
                                selectAllUnsaved={selectAllUnsaved}
                                setDisplayImageAttachments={
                                  setDisplayImageAttachments
                                }
                                selectAllCheck={selectAllCheck}
                                setSelectAllCheck={setSelectAllCheck}
                                handleExecuteFilter={handleExecuteFilter}
                                fetchData={fetchData}
                                setWaitUnSaved={setWaitUnSaved}
                                setWaitSaved={setWaitSaved}
                                setWaitArchived={setWaitArchived}
                                setOpenTab={setOpenTab}
                                openTab={openTab}
                                saveProgress={saveProgress}
                                setSaveProgress={setSaveProgress}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                              />
                            </div>
                          ) : (
                            <div
                              className={openTab === 3 ? "block" : "hidden"}
                              id="link3"
                            >
                              <TableArchivedInfo
                                setUnsavedEmailsCount={setUnsavedEmailsCount}
                                setSavedEmailsCount={setSavedEmailsCount}
                                setArchivedEmailsCount={setArchivedEmailsCount}
                                taskListColumns={taskListColumns}
                                selectedArchivedItems={selectedArchivedItems}
                                setSelectedArchivedItems={
                                  setSelectedArchivedItems
                                }
                                archivedEmails={archivedEmails}
                                matterList={matterList}
                                waitArchived={waitArchived}
                                sortByDate={sortByDate}
                                setAttachmentIsDeleted={setAttachmentIsDeleted}
                                attachmentIsDeleted={attachmentIsDeleted}
                                show={show}
                                setShow={setShow}
                                setShowToast={setShowToast}
                                setResultMessage={setResultMessage}
                                setEmailInfo={setEmailInfo}
                                setEmailContent={setEmailContent}
                                setShowPreview={setShowPreview}
                                isOutOfSync={isOutOfSync}
                                mobileLoading={mobileLoading}
                                setMobileLoading={setMobileLoading}
                                toggleSent={toggleSent}
                                isOutlookIntegrated={isOutlookIntegrated}
                                selectedUnsavedItems={selectedUnsavedItems}
                                setSelectedUnsavedItems={
                                  setSelectedUnsavedItems
                                }
                                getUnSavedEmails={getUnSavedEmails}
                                unSavedEmails={unSavedEmails}
                                setUnsavedEmails={setUnsavedEmails}
                                saveLoading={saveLoading}
                                setSaveLoading={setSaveLoading}
                                selectedTaskRegister={selectedTaskRegister}
                                setSelectedTaskRegister={
                                  setSelectedTaskRegister
                                }
                                displayImageAttachments={
                                  displayImageAttachments
                                }
                                setEmailContentOpen={setEmailContentOpen}
                                showDetailsCopy={showDetailsCopy}
                                setShowDetailsCopy={setShowDetailsCopy}
                                showCopy={showCopy}
                                setShowCopy={setShowCopy}
                                searchGmail={searchGmail}
                                emailFilters={emailFilters}
                                unSavedForTaskListCtr={unSavedForTaskListCtr}
                                savedForTaskListCtr={savedForTaskListCtr}
                                getArchivedEmails={getArchivedEmails}
                                setDoneAllocatingEmails={
                                  setDoneAllocatingEmails
                                }
                                setpendingMessage={setpendingMessage}
                                setPreviousUnsavedEmails={
                                  setPreviousUnsavedEmails
                                }
                                setPreviousSavedEmails={setPreviousSavedEmails}
                                setPreviousArchivedEmails={
                                  setPreviousArchivedEmails
                                }
                                setSearchGmail={setSearchGmail}
                                selectAllArchived={selectAllArchived}
                                selectAllSaved={selectAllSaved}
                                selectAllUnsaved={selectAllUnsaved}
                                setDisplayImageAttachments={
                                  setDisplayImageAttachments
                                }
                                selectAllCheck={selectAllCheck}
                                setSelectAllCheck={setSelectAllCheck}
                                handleExecuteFilter={handleExecuteFilter}
                                getSavedEmails={getSavedEmails}
                                openTab={openTab}
                                fetchData={fetchData}
                                setWaitUnSaved={setWaitUnSaved}
                                setWaitSaved={setWaitSaved}
                                setWaitArchived={setWaitArchived}
                                setOpenTab={setOpenTab}
                                selectedSavedItems={selectedSavedItems}
                                setSelectedSavedItems={setSelectedSavedItems}
                                tasklistGmailIds={tasklistGmailIds}
                                setTasklistGmailIds={setTasklistGmailIds}
                                setSelectedTaskList={setSelectedTaskList}
                                selectedTaskList={selectedTaskList}
                                taskRegisterList={taskRegisterList}
                                savedEmails={savedEmails}
                                setSavedEmails={setSavedEmails}
                                setArchivedEmails={setArchivedEmails}
                                saveProgress={saveProgress}
                                setSaveProgress={setSaveProgress}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right hand screen */}
                </div>
              </div>
            </div>
          ) : (
            <div className="relative flex min-h-screen min-w-0 flex-col break-words rounded p-5 sm:pl-24">
              <img
                src={googleLogin}
                alt=""
                style={{ width: "450px", height: "auto" }}
                className="fixed bottom-0 -right-10 object-cover opacity-40 sm:opacity-100"
              />
              <div className="flex flex-col pt-24 sm:pt-12">
                <h5 className="text-2xl font-bold text-black">
                  AFFIDAVITS & RFI
                </h5>
                <div className="my-5 text-xl font-normal text-black">
                  Looks like you're not yet connected with your Gmail/Outlook
                  Account
                </div>
                <div className="text-lg font-medium text-gray-400">
                  Lets make your trip fun and simple
                </div>
                <br />
                {renderGmailButton && (
                  <GoogleSignIn
                    refreshToken={refreshToken}
                    setRefreshToken={setRefreshToken}
                    isOutOfSync={isOutOfSync}
                    setIsOutOfSync={setIsOutOfSync}
                    connectedEmail={connectedEmail}
                    setConnectedEmail={setConnectedEmail}
                    isOutlookIntegrated={isOutlookIntegrated}
                    connectedOutlookAccount={connectedOutlookAccount}
                    getSavedAuthToken={getSavedAuthToken}
                  />
                )}
                <div className="w-54">
                  <OutlookSignIn
                    isOutlookIntegrated={isOutlookIntegrated}
                    connectedOutlookAccount={connectedOutlookAccount}
                    setConnectedOutlookAccount={setConnectedOutlookAccount}
                    getSavedAuthToken={getSavedAuthToken}
                  />
                </div>
                {/* {renderGmailButton && (
                    <GmailIntegration
                      refreshToken={refreshToken}
                      isOutOfSync={isOutOfSync}
                    />
                  )}*/}
              </div>
            </div>
          )}

          {showFiltersModal && (
            <FiltersModal
              executeFilter={handleExecuteFilter}
              closeFiltersModal={handleFiltersModalClose}
              currentFilter={emailFilters}
              setMobileLoading={setMobileLoading}
              // search={setSearchGmail}
              connectedEmail={connectedEmail}
              connectedOutlookAccount={connectedOutlookAccount}
              isOutlookIntegrated={isOutlookIntegrated} //OUTLOOK
              width={width}
              setEmailContentOpen={setEmailContentOpen}
            />
          )}

          {/* {saveLoading && <SavingModal />} */}
          {saveLoading && <ToastNotification title={pendingMessage} />}
          {showToast && resultMessage && (
            <ToastNotification title={resultMessage} hideToast={hideToast} />
          )}
          {showSessionTimeout && <SessionTimeout />}

          {showOtherDetails && (
            <AddOtherDetails
              unSavedEmails={unSavedEmails}
              close={() => setShowOtherDetails(false)}
              webAssignee={webAssignee}
              people={people}
              assigneeId={assigneeId}
              assigneeVal={assigneeVal}
              priorityVal={priorityVal}
              notesVal={notesVal}
            />
          )}

          {showAddContactModal && (
            <AddContactModal
              close={() => {
                setshowAddContactModal(false);
                setEmailInfo([]);
              }}
              setalertMessage={setResultMessage}
              setShowToast={setShowToast}
              width={width}
              emailInfo={emailInfo}
            />
          )}
        </>
      ) : (
        //MOBILE VERSION -kjmf//
        <>
          {isOutlookIntegrated !== null ||
          (refreshToken &&
            connectedEmail &&
            companyIntegratedEmail &&
            connectedEmail === companyIntegratedEmail) ? (
            <div
              className="h-screen w-screen overflow-hidden overflow-y-auto"
              onScroll={(e) => {
                let el = e.target;
                if (el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
                  debouncedFetchNextData(25);
                }
              }}
            >
              <div className="h-screen w-screen flex flex-col overscroll-y-auto bg-gray-100 px-1 pt-5">
                <div className="items-right text-right flex flex-col justify-center px-7">
                  <div className="text-base font-bold">
                    {isOutlookIntegrated ? "OUTLOOK" : "GMAIL"} INTEGRATION
                  </div>
                  <div className="inline-flex items-center justify-end">
                    <h1 className="font-semibold text-gray-500">
                      {toggleSent ? "SENT" : "INBOX"}
                    </h1>
                  </div>
                </div>
                <div
                  id="headerTag"
                  className={
                    "flex flex-col sticky z-10 top-0 rounded-lg bg-gray-100 pt-5 font-sans nav-pane"
                  }
                >
                  <div className="flex flex-col">
                    <div
                      className={`col-span-5 flex xl:col-span-4 gap-1 items-center ${
                        show ? "hidden" : ""
                      }`}
                    >
                      {/* Add Client Matter Button Mobile */}
                      <button
                        className="flex justify-center rounded-md bg-gray-600 px-3 h-11 text-white items-center"
                        onClick={() => setShowCreateClientMatter(true)}
                      >
                        <AiFillFolderAdd />
                      </button>
                      {/* Compose Button Mobile */}
                      {allowComposeEmail && (
                        <button
                          onClick={() => {
                            setIsModal(true);
                            setIsReply(false);
                          }}
                          className="save-btn flex justify-center rounded-md bg-green-600 px-3 h-11 text-white items-center"
                        >
                          <BiPencil />
                        </button>
                      )}
                      {/* Search Bar Mobile */}
                      <div className="relative flex flex-grow flex-wrap items-center rounded-md border bg-white">
                        <div className="-mr-px flex w-10 justify-center pl-2">
                          <span className="flex items-center rounded rounded-r-none -ml-2 border bg-blue-200 border-blue-500 border-1 px-2 py-2 text-2xl leading-normal text-gray-600">
                            <IoIosSearch
                              onClick={(e) => fetchData()}
                              className="cursor-pointer "
                            />
                          </span>
                        </div>
                        <input
                          id="SearchM"
                          type="search"
                          className="outline-none border-grey-light relative h-9 w-px flex-1 flex-shrink flex-grow self-center rounded rounded-l-none border-0 pl-1 text-sm leading-normal"
                          placeholder="Type to search all emails ..."
                          value={searchGmail}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchGmailChange(e, "enter");
                            }
                          }}
                          returnKeyType="next"
                          // onSubmitEditing={(e) =>
                          //   handleSearchGmailChange(e, "enter")
                          // }
                          // onChange={(e) => {
                          //   if (e.target.value === "") {
                          //     setHasSearchVal(false);
                          //     handleSearchGmailChange(e, "clear");
                          //   } else {
                          //     setHasSearchVal(true);
                          //     handleSearchGmailChange(e, "typed");
                          //   }
                          // }}
                          // onPaste={(e) => handleSearchGmailChange(e, "pasted")}

                          onInput={(e) => {
                            if (e.target.value === "") {
                              setHasSearchVal(false);
                              handleSearchGmailChange(e, "clear");
                            } else {
                              setHasSearchVal(true);
                              handleSearchGmailChange(e, "typed");
                            }
                          }}
                          
                          onPaste={(e) =>
                            handleSearchGmailChange(e, "pasted")
                          }
                          style={{ maxWidth: "100%" }} // Set the maximum width here
                        />
                        {/* <div
                            className="flex w-8 justify-center mr-1"
                            onClick={() => {
                              document.getElementById("SearchM").value = "";
                              fetchData("clear");
                              setHasSearchVal(false);
                            }}
                            style={{
                              display: hasSearchVal ? "flex" : "none"
                            }}
                          >
                            <span className="flex items-center rounded rounded-r-none border-0 bg-white px-2 text-xl leading-normal text-gray-600">
                              <MdClear
                                className="md-clear cursor-pointer"
                                color="blue"
                              />
                            </span>
                          </div> */}
                        <Button
                          variant="default-s"
                          size="xs"
                          onClick={() => setshowFiltersModal(true)}
                          className="px-3 py-3"
                        >
                          <RiFilter2Fill size={20} />
                        </Button>
                      </div>
                    </div>

                    {/* tabs div */}
                    {show ? null : (
                      <TabsRender
                        setThreadId={setThreadId}
                        setIsReply={setIsReply}
                        setIsModal={setIsModal}
                        color="gray"
                        openTab={openTab}
                        setOpenTab={setOpenTab}
                        unSavedEmails={unSavedEmails}
                        savedEmails={savedEmails}
                        unSavedEmailsCount={unSavedEmailsCount}
                        savedEmailsCount={savedEmailsCount}
                        archivedEmailsCount={archivedEmailsCount}
                        saveLoading={saveLoading}
                        archivedEmails={archivedEmails}
                        className="z-20"
                        selectedUnsavedItemsCtr={selectedUnsavedItems.length}
                        selectedSavedItemsCtr={selectedSavedItems.length}
                        selectedArchivedItemsCtr={selectedArchivedItems.length}
                        unSavedForTaskListCtr={unSavedForTaskListCtr}
                        savedForTaskListCtr={savedForTaskListCtr}
                        setShowDetailsCopy={setShowDetailsCopy}
                      />
                    )}
                    {/* end of tabs div */}
                    {!isOutOfSync && (
                      <>
                        <div className={!showCopy ? "py-3" : ""}></div>
                        <div
                          className={
                            !showCopy
                              ? "absolute bottom-0 left-2 right-2"
                              : "float-right ml-3 flex py-1"
                          }
                        >
                          {showCopy === false ? (
                            <></>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                onClick={
                                  openTab === 1
                                    ? (e) => selectAllUnsaved(e, true)
                                    : openTab === 2
                                    ? (e) => selectAllSaved(e)
                                    : (e) => selectAllArchived(e)
                                }
                                checked={selectAllCheck ? true : false}
                              />
                              <p className="ml-2 text-xs font-semibold">
                                SELECT ALL
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="contentDiv flex h-full flex-col rounded-lg font-sans"
                  style={{ height: contentHeight }}
                >
                  {/* body proper */}

                  <div
                    className={` w-fit relative mb-2 flex min-w-0 flex-col break-words bg-white ${
                      show ? "z-50" : ""
                    }`}
                  >
                    <div className="flex-auto">
                      <div className="tab-content tab-space">
                        {openTab === 1 ? (
                          <div
                            className={openTab === 1 ? "block" : "hidden"}
                            id="link1"
                          >
                            <TableUnsavedInfo
                              setUnsavedEmailsCount={setUnsavedEmailsCount}
                              setSavedEmailsCount={setSavedEmailsCount}
                              setArchivedEmailsCount={setArchivedEmailsCount}
                              taskListColumns={taskListColumns}
                              history={history}
                              location={location}
                              setShowMobileNotificationModal={
                                setShowMobileNotificationModal
                              }
                              copyMore20={copyMore20}
                              vTablePaddingTop={vTablePaddingTop}
                              vTablePaddingBottom={vTablePaddingBottom}
                              virtualRows={virtualRows}
                              rowVirtualizer={rowVirtualizer}
                              rows={rows}
                              totalSize={totalSize}
                              tableContainerRef={tableContainerRef}
                              setCopyMore20={setCopyMore20}
                              isArchive={isArchive}
                              setIsArchive={setIsArchive}
                              scrollIndex={scrollIndex}
                              handleScrollIndex={handleScrollIndex}
                              handleSelectedEmail={handleSelectedEmail}
                              handleComposeUnsavedTabs={
                                handleComposeUnsavedTabs
                              }
                              selectedUnsavedItems={selectedUnsavedItems}
                              setSelectedUnsavedItems={setSelectedUnsavedItems}
                              selectedArchivedItems={selectedArchivedItems}
                              setSelectedArchivedItems={
                                setSelectedArchivedItems
                              }
                              unSavedEmails={unSavedEmails}
                              setUnsavedEmails={setUnsavedEmails}
                              matterList={matterList}
                              maxLoadingUnSavedEmail={maxLoadingUnSavedEmail}
                              emailFilters={emailFilters}
                              getUnSavedEmails={getUnSavedEmails}
                              labelsList={labelsList}
                              waitUnSaved={waitUnSaved}
                              sortByDate={sortByDate}
                              connectedEmail={connectedEmail}
                              userTimeZone={userTimeZone}
                              momentTZ={momentTZ}
                              contactEmailList={contactEmailList}
                              setContactEmailList={setContactEmailList}
                              qGmailMessagesbyCompany={qGmailMessagesbyCompany}
                              setAttachmentIsDeleted={setAttachmentIsDeleted}
                              attachmentIsDeleted={attachmentIsDeleted}
                              setAttachmentId={setAttachmentId}
                              attachmentId={attachmentId}
                              lastCounter={lastCounter}
                              setSelectedTaskList={setSelectedTaskList}
                              selectedTaskList={selectedTaskList}
                              setSelectedTaskRegister={setSelectedTaskRegister}
                              selectedTaskRegister={selectedTaskRegister}
                              taskRegisterList={taskRegisterList}
                              setTaskRegisterList={setTaskRegisterList}
                              onCreateNewOption={createNewRegister}
                              show={show}
                              setShow={setShow}
                              setEmailInfo={setEmailInfo}
                              setShowToast={setShowToast}
                              setResultMessage={setResultMessage}
                              setUnsavedForTaskListCtr={
                                setUnsavedForTaskListCtr
                              }
                              displayImageAttachments={displayImageAttachments}
                              bindList={bindList}
                              isOutOfSync={isOutOfSync}
                              showOtherDetails={showOtherDetails}
                              setShowOtherDetails={setShowOtherDetails}
                              otherDetailsId={otherDetailsId}
                              setOtherDetailsId={setOtherDetailsId}
                              setWebAssignee={setWebAssignee}
                              saveLoading={saveLoading}
                              assigneeId={assigneeId}
                              setAssigneeId={setAssigneeId}
                              assigneeVal={assigneeVal}
                              setAssigneeVal={setAssigneeVal}
                              mobileLoading={mobileLoading}
                              setMobileLoading={setMobileLoading}
                              priorityVal={priorityVal}
                              setPriorityVal={setPriorityVal}
                              notesVal={notesVal}
                              setNotesVal={setNotesVal}
                              handleFilter={handleFilter}
                              searchGmail={searchGmail}
                              setshowAddContactModal={setshowAddContactModal}
                              fetchCompanyClientMatters={
                                fetchCompanyClientMatters
                              }
                              setShowPreview={setShowPreview}
                              setEmailContent={setEmailContent}
                              toggleSent={toggleSent}
                              allowComposeEmail={allowComposeEmail}
                              setSaveLoading={setSaveLoading}
                              archivedEmails={archivedEmails}
                              setArchivedEmails={setArchivedEmails}
                              showCopy={showCopy}
                              setShowCopy={setShowCopy}
                              pastedIds={pastedIds}
                              setPastedIds={setPastedIds}
                              copyId={copyId}
                              setCopyId={setCopyId}
                              reloadKey={reloadKey}
                              setReloadKey={setReloadKey}
                              key={reloadKey}
                              onScroll={handleScrollPosition}
                              scrollPosition={scrollPosition}
                              recordScroll={recordScroll}
                              setRecordScroll={setRecordScroll}
                              isOutlookIntegrated={isOutlookIntegrated}
                              // COMPOSE FUNCTIONS
                              isUpdatedClientMatter={isUpdatedClientMatter}
                              setIsUpdatedClientMatter={
                                setIsUpdatedClientMatter
                              }
                              setSelectedRawHtml={setSelectedRawHtml}
                              setSubject={setSubject}
                              setSelectedIsReplyDetails={
                                setSelectedIsReplyDetails
                              }
                              setSelectedThreadId={setSelectedThreadId}
                              setSelectedMessageId={setSelectedMessageId}
                              saveProgress={saveProgress}
                              setSaveProgress={setSaveProgress}
                              loadingCM={loadingCM}
                            />
                          </div>
                        ) : openTab === 2 ? (
                          <div
                            className={openTab === 2 ? "block" : "hidden"}
                            id="link2"
                          >
                            <TableSavedInfo
                              setUnsavedEmailsCount={setUnsavedEmailsCount}
                              setSavedEmailsCount={setSavedEmailsCount}
                              setArchivedEmailsCount={setArchivedEmailsCount}
                              taskListColumns={taskListColumns}
                              vTablePaddingTop={vTablePaddingTop}
                              vTablePaddingBottom={vTablePaddingBottom}
                              virtualRows={virtualRows}
                              rowVirtualizer={rowVirtualizer}
                              rows={rows}
                              totalSize={totalSize}
                              tableContainerRef={tableContainerRef}
                              selectedSavedItems={selectedSavedItems}
                              setSelectedSavedItems={setSelectedSavedItems}
                              savedEmails={savedEmails}
                              setSavedEmails={setSavedEmails}
                              matterList={matterList}
                              maxLoadingSavedEmail={maxLoadingSavedEmail}
                              waitSaved={waitSaved}
                              sortByDate={sortByDate}
                              setAttachmentIsDeleted={setAttachmentIsDeleted}
                              attachmentIsDeleted={attachmentIsDeleted}
                              setSelectedTaskList={setSelectedTaskList}
                              selectedTaskList={selectedTaskList}
                              tasklistGmailIds={tasklistGmailIds}
                              setTasklistGmailIds={setTasklistGmailIds}
                              show={show}
                              setEmailInfo={setEmailInfo}
                              setShow={setShow}
                              setShowToast={setShowToast}
                              setResultMessage={setResultMessage}
                              setSavedForTaskListCtr={setSavedForTaskListCtr}
                              isOutOfSync={isOutOfSync}
                              mobileLoading={mobileLoading}
                              setMobileLoading={setMobileLoading}
                              toggleSent={toggleSent}
                              setShowPreview={setShowPreview}
                              setEmailContent={setEmailContent}
                              taskRegisterList={taskRegisterList}
                              // COMPOSE FUNCTIONS
                              isUpdatedClientMatter={isUpdatedClientMatter}
                              setIsUpdatedClientMatter={
                                setIsUpdatedClientMatter
                              }
                              setSelectedRawHtml={setSelectedRawHtml}
                              setSubject={setSubject}
                              setSelectedIsReplyDetails={
                                setSelectedIsReplyDetails
                              }
                              setSelectedThreadId={setSelectedThreadId}
                              setSelectedMessageId={setSelectedMessageId}
                              saveProgress={saveProgress}
                              setSaveProgress={setSaveProgress}
                            />
                          </div>
                        ) : (
                          <div
                            className={openTab === 3 ? "block" : "hidden"}
                            id="link3"
                          >
                            <TableArchivedInfo
                              setUnsavedEmailsCount={setUnsavedEmailsCount}
                              setSavedEmailsCount={setSavedEmailsCount}
                              setArchivedEmailsCount={setArchivedEmailsCount}
                              taskListColumns={taskListColumns}
                              selectedArchivedItems={selectedArchivedItems}
                              setSelectedArchivedItems={
                                setSelectedArchivedItems
                              }
                              archivedEmails={archivedEmails}
                              matterList={matterList}
                              waitArchived={waitArchived}
                              sortByDate={sortByDate}
                              setAttachmentIsDeleted={setAttachmentIsDeleted}
                              attachmentIsDeleted={attachmentIsDeleted}
                              show={show}
                              showcopy={showCopy}
                              setShow={setShow}
                              setShowToast={setShowToast}
                              setResultMessage={setResultMessage}
                              setEmailInfo={setEmailInfo}
                              setEmailContent={setEmailContent}
                              setShowPreview={setShowPreview}
                              isOutOfSync={isOutOfSync}
                              mobileLoading={mobileLoading}
                              setMobileLoading={setMobileLoading}
                              scrollPosition={scrollPosition}
                              onScroll={handleScrollPosition}
                              isOutlookIntegrated={isOutlookIntegrated}
                              // COMPOSE FUNCTIONS
                              isUpdatedClientMatter={isUpdatedClientMatter}
                              setIsUpdatedClientMatter={
                                setIsUpdatedClientMatter
                              }
                              setSelectedRawHtml={setSelectedRawHtml}
                              setSubject={setSubject}
                              setSelectedIsReplyDetails={
                                setSelectedIsReplyDetails
                              }
                              setSelectedThreadId={setSelectedThreadId}
                              setSelectedMessageId={setSelectedMessageId}
                              saveProgress={saveProgress}
                              setSaveProgress={setSaveProgress}
                              vTablePaddingTop={vTablePaddingTop}
                              vTablePaddingBottom={vTablePaddingBottom}
                              virtualRows={virtualRows}
                              rowVirtualizer={rowVirtualizer}
                              rows={rows}
                              totalSize={totalSize}
                              tableContainerRef={tableContainerRef}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* end of body proper */}
                </div>
                {/* action buttons floating*/}
                <div
                  style={{
                    position: "fixed",
                    bottom: `${componentBottom}`,
                    left: "0",
                  }}
                  className={
                    selectedUnsavedItems.length > 0 ||
                    selectedSavedItems.length > 0 ||
                    selectedArchivedItems.length > 0
                      ? "bot-0 fixed left-0 right-0 z-40"
                      : "bot-0 fixed left-0 right-0 z-40 hidden"
                  }
                >
                  <ActionButtons
                    setUnsavedEmailsCount={setUnsavedEmailsCount}
                    setSavedEmailsCount={setSavedEmailsCount}
                    setArchivedEmailsCount={setArchivedEmailsCount}
                    taskListColumns={taskListColumns}
                    handleScrollIndex={handleScrollIndex}
                    selectedEmail={selectedEmail}
                    selectedUnsavedItems={selectedUnsavedItems}
                    setSelectedUnsavedItems={setSelectedUnsavedItems}
                    selectedSavedItems={selectedSavedItems}
                    setSelectedSavedItems={setSelectedSavedItems}
                    selectedArchivedItems={selectedArchivedItems}
                    setSelectedArchivedItems={setSelectedArchivedItems}
                    taskRegisterList={taskRegisterList}
                    openTab={openTab}
                    fetchData={fetchData}
                    setWaitUnSaved={setWaitUnSaved}
                    setWaitSaved={setWaitSaved}
                    setWaitArchived={setWaitArchived}
                    setOpenTab={setOpenTab}
                    getUnSavedEmails={getUnSavedEmails}
                    getSavedEmails={getSavedEmails}
                    unSavedEmails={unSavedEmails}
                    setUnsavedEmails={setUnsavedEmails}
                    savedEmails={savedEmails}
                    setSavedEmails={setSavedEmails}
                    setResultMessage={setResultMessage}
                    setShowToast={setShowToast}
                    setDoneAllocatingEmails={setDoneAllocatingEmails}
                    saveLoading={saveLoading}
                    setSaveLoading={setSaveLoading}
                    sortByDate={sortByDate}
                    getArchivedEmails={getArchivedEmails}
                    archivedEmails={archivedEmails}
                    setArchivedEmails={setArchivedEmails}
                    setSelectedTaskList={setSelectedTaskList}
                    selectedTaskList={selectedTaskList}
                    selectedTaskRegister={selectedTaskRegister}
                    setSelectedTaskRegister={setSelectedTaskRegister}
                    tasklistGmailIds={tasklistGmailIds}
                    setTasklistGmailIds={setTasklistGmailIds}
                    unSavedForTaskListCtr={unSavedForTaskListCtr}
                    setUnsavedForTaskListCtr={setUnsavedForTaskListCtr}
                    savedForTaskListCtr={savedForTaskListCtr}
                    bindList={bindList}
                    displayImageAttachments={displayImageAttachments}
                    setDisplayImageAttachments={setDisplayImageAttachments}
                    selectAllCheck={selectAllCheck}
                    setSelectAllCheck={setSelectAllCheck}
                    setpendingMessage={setpendingMessage}
                    setEmailContentOpen={setEmailContentOpen}
                    showCopy={showCopy}
                    setShowCopy={setShowCopy}
                    searchGmail={searchGmail}
                    setSearchGmail={setSearchGmail}
                    isOutlookIntegrated={isOutlookIntegrated}
                    setPreviousUnsavedEmails={setPreviousUnsavedEmails}
                    setPreviousSavedEmails={setPreviousSavedEmails}
                    setPreviousArchivedEmails={setPreviousArchivedEmails}
                    emailFilters={emailFilters}
                    handleExecuteFilter={handleExecuteFilter}
                    setSaveProgress={setSaveProgress}
                    vTablePaddingTop={vTablePaddingTop}
                    vTablePaddingBottom={vTablePaddingBottom}
                    virtualRows={virtualRows}
                    rows={rows}
                    tableContainerRef={tableContainerRef}
                    rowVirtualizer={rowVirtualizer}
                    table={table}
                    stagedEdits={stagedEdits}
                  />
                </div>
                {/* end of action buttons floating*/}
              </div>
            </div>
          ) : (
            <>
              <div className="relative flex min-h-screen min-w-0 flex-col break-words rounded p-5 sm:pl-24">
                <img
                  src={googleLogin}
                  alt=""
                  style={{ width: "450px", height: "auto" }}
                  className="fixed bottom-0 -right-10 object-cover opacity-40 sm:opacity-100"
                />
                <div className="flex flex-col pt-24 sm:pt-12">
                  <h5 className="text-2xl font-bold text-black">
                    AFFIDAVITS & RFI
                  </h5>
                  <div className="my-5 text-xl font-normal text-black">
                    Looks like you're not yet connected with your Gmail/Outlook
                    Account
                  </div>
                  <div className="text-lg font-medium text-gray-400">
                    Lets make your trip fun and simple
                  </div>
                  <br />
                  {renderGmailButton && (
                    <GoogleSignIn
                      refreshToken={refreshToken}
                      setRefreshToken={setRefreshToken}
                      isOutOfSync={isOutOfSync}
                      setIsOutOfSync={setIsOutOfSync}
                      connectedEmail={connectedEmail}
                      setConnectedEmail={setConnectedEmail}
                      isOutlookIntegrated={isOutlookIntegrated}
                      connectedOutlookAccount={connectedOutlookAccount}
                      getSavedAuthToken={getSavedAuthToken}
                    />
                  )}
                  <div className="w-54">
                    <OutlookSignIn
                      isOutlookIntegrated={isOutlookIntegrated}
                      connectedOutlookAccount={connectedOutlookAccount}
                      setConnectedOutlookAccount={setConnectedOutlookAccount}
                      getSavedAuthToken={getSavedAuthToken}
                    />
                  </div>
                  {/* {renderGmailButton && (
                  <GmailIntegration
                    refreshToken={refreshToken}
                    isOutOfSync={isOutOfSync}
                  />
                )}*/}
                  {isOutOfSync === true ? (
                    <div className=" pt-14 text-xs ">
                      <p className=" border-2 border-gray-200 rounded-lg p-4">
                        An error has occurred. Please re-login your account to
                        continue syncing emails.
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}

          {showFiltersModal && (
            <FiltersModal
              executeFilter={handleExecuteFilter}
              closeFiltersModal={handleFiltersModalClose}
              currentFilter={emailFilters}
              setMobileLoading={setMobileLoading}
              // search={setSearchGmail}
              connectedEmail={connectedEmail}
              connectedOutlookAccount={connectedOutlookAccount} //OUTLOOK
              isOutlookIntegrated={isOutlookIntegrated} //OUTLOOK
              width={width}
            />
          )}

          {saveLoading && <Spinner />}

          {showPreview && (
            <EmailPreview
              handleComposeUnsavedTabs={handleComposeUnsavedTabs}
              allowComposeEmail={allowComposeEmail}
              emailContent={emailContent}
              setEmailContent={setEmailContent}
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              emailInfo={emailInfo}
              setEmailInfo={setEmailInfo}
              setShow={setShow}
              closeEmailModal={closeEmailModal}
              isUpdatedClientMatter={isUpdatedClientMatter}
            />
          )}

          {showMobileNotificationModal && (
            <MobileNotificationModal
              title="Copy"
              body="You are copying onto more than 20 emails. Do you want to continue?"
              confirm={() => setCopyMore20(true)}
              cancel={() => setCopyMore20(false)}
              close={() => setShowMobileNotificationModal(false)}
            />
          )}
          {showAddContactModal && (
            <AddContactModal
              close={() => {
                setshowAddContactModal(false);
                setEmailInfo([]);
              }}
              setalertMessage={setResultMessage}
              setShowToast={setShowToast}
              width={width}
              emailInfo={emailInfo}
              fromInboxMobile={true}
              contactEmailList={contactEmailList}
              setContactEmailList={setContactEmailList}
            />
          )}
          {/* end of modals */}
        </>
      )}

      {showCreateClientMatter && (
        <AddClientMatter
          close={() => setShowCreateClientMatter(false)}
          clientMatters={matterList}
          fetchCompanyClientMatters={fetchCompanyClientMatters}
        />
      )}

      <Compose
        className="z-50"
        isReply={isReply}
        setIsReply={setIsReply}
        selectedIsReplyDetails={selectedIsReplyDetails}
        reSubject={subject}
        onClose={() => handleCloseCompose()}
        from={connectedEmail}
        refreshToken={refreshToken}
        visible={isModal}
        threadId={selectedThreadId}
        messageId={selectedMessageId}
        selectedRawHtml={selectedRawHtml}
        setSelectedRawHtml={setSelectedRawHtml}
        width={width}
        isUpdatedClientMatter={isUpdatedClientMatter}
        isOutlookIntegrated={isOutlookIntegrated}
      />
    </>
  );
};

const OutlookWrapper = () => {
  return (
    <OutlookConfig>
      <Inbox />
    </OutlookConfig>
  );
};
export { OutlookWrapper as Inbox };
