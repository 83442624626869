import React, { useEffect, useState, useRef } from "react";
// import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { TfiClose } from "react-icons/tfi";
import Confetti from "react-confetti";
import { API } from "aws-amplify";

import builder from "../../assets/images/getting-started-icons/builder.png";
import completion from "../../assets/images/getting-started-icons/completion.png";
import contractDetails from "../../assets/images/getting-started-icons/contract-details.png";
import invoices from "../../assets/images/getting-started-icons/invoices.png";
import progressPhoto from "../../assets/images/getting-started-icons/progress-photo.png";
import variations from "../../assets/images/getting-started-icons/variations.png";
import propertyDetails from "../../assets/images/getting-started-icons/property-details.png";
import contracts from "../../assets/images/getting-started-icons/contracts.png";

// import DatePicker from "react-datepicker";
// import { Link, useHistory } from "react-router-dom";
// import Button from "../../shared/Button";
// import { datePickerDateFormatter } from "../../shared/userPreferredDateTime";

const qGetBackgroundsByBriefId = `query getBriefByID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
  brief(id: $id) {
    id
    backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
      items {
        id
        files {
          items {
            id
          }
        }
      }
    }
  }
}
`;

export default function GetStartedTile({
  matter_id,
  showPanel,
  setShowPanel,
  scrollToSection,
  getStartedStates,
  infoDetails,
  infoRefs,
  infoModal,
  isInitDone,
  setTilePlulse,
}) {
  // const history = useHistory();
  // const [background, setBackground] = useState(items?.backgrounds);

  const { getStartedProgress, setGetStartedProgress } = getStartedStates;

  const { buildInfo, contractInfo, defaultList } = infoDetails;

  const {
    propertyDetailsRef,
    propertyPhotoRef,
    completionDateRef,
    invoicesRef,
    variationsRef,
    uploadContractsRef,
  } = infoRefs;

  const { modalChoicesEnum, setShowRightPanel, setModalState } = infoModal;

  const buildInfoStatus =
    buildInfo &&
    !Object.values(buildInfo)?.every((info) => info === null || info === "");
  const contractInfoStatus =
    contractInfo &&
    !Object.values(contractInfo)?.every((info) => info === null || info === "");

  useEffect(() => {
    if (defaultList) {
      // Used checking if a file is present in the Contracts table
      const brief_id = defaultList?.find(
        (item) => item.name === "Contracts"
      )?.id;
      const getMatterFiles = async () => {
        try {
          const params = {
            query: qGetBackgroundsByBriefId,
            variables: {
              id: brief_id,
              nextToken: null,
              sortOrder: "DATE_ASC",
              limit: 1000,
            },
          };
          const brief = await API.graphql(params);
          const briefItemsList = brief?.data?.brief?.backgrounds?.items;
          const checkIfContracts = briefItemsList.every(
            (item) => item?.files.items.length > 0
          );
          // console.log('Get Started Contract',briefItemsList)
          // console.log('Check if all has files',checkIfContracts)
          return checkIfContracts;
        } catch (err) {
          console.error(err);
          return false;
        }
      };

      const initializeGetStartedProgress = async () => {
        const contractStatus = await getMatterFiles();
        const propertyPhotoStatus =
          localStorage.getItem("propertyPhoto") === "useExample" ? true : false;

        //console.log('Get Started Contract ',contractStatus)
        const getStartedCompletion = {
          // builderDetails: buildInfoStatus,
          // contractDetails: contractInfoStatus,
          propertyPhoto: propertyPhotoStatus
            ? propertyPhotoStatus
            : (defaultList &&
                defaultList?.find((item) => item.name === "Progress Photos")
                  ?.backgrounds?.items?.length !== 0) ||
              false,
          propertyDetails:
            (defaultList &&
              defaultList?.find((item) => item.name === "Project Location")
                ?.backgrounds?.items?.length !== 0) ||
            false,
          completionDate:
            (defaultList &&
              defaultList?.find((item) => item.name === "Progress of work")
                ?.backgrounds?.items?.length !== 0) ||
            false,
          invoices:
            (defaultList &&
              defaultList?.find((item) => item.name === "Invoices")?.backgrounds
                ?.items?.length !== 0) ||
            false,
          variations:
            (defaultList &&
              defaultList?.find((item) => item.name === "Variations")
                ?.backgrounds?.items?.length !== 0) ||
            false,
          contracts: contractStatus,
        };
        console.log(getStartedCompletion, "Current Progress");
        setGetStartedProgress((obj) => ({
          ...obj,
          ...getStartedCompletion,
        }));
      };
      console.log("project location", defaultList);
      initializeGetStartedProgress();
    }
  }, [defaultList]);

  useEffect(() => {
    if (buildInfoStatus && !getStartedProgress.builderDetails) {
      setGetStartedProgress((obj) => ({
        ...obj,
        builderDetails: buildInfoStatus,
      }));
    }
    if (contractInfoStatus && !getStartedProgress.contractDetails) {
      setGetStartedProgress((obj) => ({
        ...obj,
        contractDetails: contractInfoStatus,
      }));
    }
  }, [buildInfo, contractInfo]);

  const getStartedDetails = [
    {
      text: "Add Builder Details",
      icon: builder,
      iconColor: "#4F7A21",
      //bgColor: "bg-red-100 border-red-50",
      active: getStartedProgress.builderDetails || buildInfoStatus,
      modal: modalChoicesEnum.BUILDER_DETAILS,
    },
    {
      text: "Add Contract Details",
      icon: contractDetails,
      iconColor: "#DC6803",
      //bgColor: "bg-yellow-100 border-yellow-50",
      active: getStartedProgress.contractDetails || contractInfoStatus,
      modal: modalChoicesEnum.CONTRACT_DETAILS,
    },
    {
      text: "Upload First Property Photo",
      icon: progressPhoto,
      iconColor: "#0E9384",
      //bgColor: "bg-green-100 border-green-50",
      active: getStartedProgress.propertyPhoto, //(defaultList && defaultList?.find((item)=>item.name === 'Progress Photos')?.backgrounds?.items?.length !== 0) || false,
      reference: propertyPhotoRef,
    },
    {
      text: "Update Property Details",
      icon: propertyDetails,
      iconColor: "#0086C9",
      // bgColor: {
      //   backgroundColor: "#EAECF5",
      //   border: "3.67px #F8F9FC solid",
      // },
      active: getStartedProgress.propertyDetails, //(defaultList && defaultList?.find((item)=>item.name === 'Progress Photos')?.backgrounds?.items?.length !== 0) || false,
      reference: propertyDetailsRef,
    },
    {
      text: "Add Completion Date",
      icon: completion,
      iconColor: "#7839EE",
      // bgColor: "bg-blue-100 border-blue-50",
      active: getStartedProgress.completionDate, //(defaultList && defaultList?.find((item)=>item.name === 'Progress of work')?.backgrounds?.items?.length !== 0) || false,
      reference: completionDateRef,
    },
    {
      text: "Upload Invoices",
      icon: invoices,
      iconColor: "#E31B54",
      // bgColor: "bg-purple-100 border-purple-50",
      active: getStartedProgress.invoices, //(defaultList && defaultList?.find((item)=>item.name === 'Invoices')?.backgrounds?.items?.length !== 0) || false,
      reference: invoicesRef,
    },
    {
      text: "Add Variations or Change Requests",
      icon: variations,
      iconColor: "#BA24D5",
      // bgColor: "bg-pink-100 border-pink-50",
      active: getStartedProgress.variations, //(defaultList && defaultList?.find((item)=>item.name === 'Variations')?.backgrounds?.items?.length !== 0 ) || false,
      reference: variationsRef,
    },
    {
      text: "Upload Contracts",
      icon: contracts,
      iconColor: "#E62E05",
      // bgColor: "bg-orange-100 border-orange-50",
      active: getStartedProgress.contracts, //(defaultList && defaultList?.find((item)=>item.name === 'Contracts')?.backgrounds?.items?.length !== 0) || false,
      reference: uploadContractsRef,
    },
  ];

  // let userPersonaType = localStorage.getItem("userCategory");

  // if (userPersonaType !== "OWNERBUILDER") {
  //   getStartedDetails.unshift({
  //     text: "Add Contract Details",
  //     icon: contractDetails,
  //     iconColor: "#DC6803",
  //     //bgColor: "bg-yellow-100 border-yellow-50",
  //     active: getStartedProgress.contractDetails || contractInfoStatus,
  //     modal: modalChoicesEnum.CONTRACT_DETAILS,
  //   });
  // }

  // if (userPersonaType !== "HBA_HOMEOWNER") {
  //   getStartedDetails.unshift({
  //     text: "Add Builder Details",
  //     icon: builder,
  //     iconColor: "#4F7A21",
  //     //bgColor: "bg-red-100 border-red-50",
  //     active: getStartedProgress.builderDetails || buildInfoStatus,
  //     modal: modalChoicesEnum.BUILDER_DETAILS,
  //   });
  // }

  const [activeIndex, setActiveIndex] = useState(
    getStartedDetails.findIndex((item) => !item.active)
  );

  useEffect(() => {
    const newIndex = getStartedDetails.findIndex((item) => !item.active);
    let hasShownWelcomeBanner =
      JSON.parse(localStorage.getItem("hasShownWelcomeBanner")) ?? [];
    let userId = localStorage.getItem("userId");
    if (newIndex !== -1) {
      setActiveIndex(newIndex);
    } else {
      if (isInitDone) {
        if (!hasShownWelcomeBanner.includes(userId)) {
          setShowPanel(true);
          hasShownWelcomeBanner.push(userId);
          localStorage.setItem(
            "hasShownWelcomeBanner",
            JSON.stringify(hasShownWelcomeBanner)
          );
          setActiveIndex(-1);
          const timeoutId = setTimeout(() => {
            setShowPanel(false);
          }, 6000);

          return () => clearTimeout(timeoutId);
        } else {
          setShowPanel(false);
        }
      }
    }
  }, [getStartedProgress, isInitDone]);

  const [confettiSourcePositionLeft, setConfettiSourcePositionLeft] = useState({
    x: 0,
    y: 0,
    h: 50,
  });
  const [confettiSourcePositionRight, setConfettiSourcePositionRight] =
    useState({ x: 0, y: 0, h: 50 });
  const updateConfettiSourcePositions = () => {
    const windowWidth = window.innerWidth;
    const halfWindowWidth = windowWidth / 2;

    // Left side
    const confettiXLeft = halfWindowWidth / 2;
    setConfettiSourcePositionLeft({ x: confettiXLeft + 100, y: 45, h: 50 });

    // Right side
    const confettiXRight = halfWindowWidth + halfWindowWidth / 2;
    setConfettiSourcePositionRight({ x: confettiXRight - 300, y: 45, h: 50 });
  };

  useEffect(() => {
    updateConfettiSourcePositions();

    window.addEventListener("resize", updateConfettiSourcePositions);

    return () => {
      window.removeEventListener("resize", updateConfettiSourcePositions);
    };
  }, []);

  return (
    <div
      className={`overflow-hidden relative w-full p-8 rounded-2xl border flex-col justify-start items-start gap-4 inline-flex transition-all
         ${activeIndex === -1 ? "" : "border-red-600"}
         `}
      style={{
        backgroundColor: activeIndex === -1 ? "#E7EBEF" : "#FFFBFA",
        height: activeIndex === -1 ? "90px" : "230px",
        display: showPanel ? "block" : "none",
      }}
    >
      {activeIndex === -1 ? (
        <>
          <div className="w-full text-center text-xl font-semibold">
            <Confetti
              recycle={false}
              numberOfPieces={160}
              confettiSource={confettiSourcePositionLeft}
              drawShape={(ctx) => {
                ctx.beginPath();
                ctx.moveTo(2, 0);
                ctx.lineTo(10, 24);
                ctx.lineTo(18, 0);
                ctx.lineTo(2, 0);
                ctx.fillStyle = "#6495ED";
                ctx.fill();
                ctx.closePath();
              }}
            />
            <Confetti
              recycle={false}
              numberOfPieces={160}
              confettiSource={confettiSourcePositionLeft}
              drawShape={(ctx) => {
                ctx.beginPath();
                ctx.arc(12, 12, 5, 0, 2 * Math.PI);
                ctx.fillStyle = "#FF00FF";
                ctx.fill();
                ctx.closePath();
              }}
            />
            Welcome to your Home Building App
            <Confetti
              recycle={false}
              numberOfPieces={160}
              confettiSource={confettiSourcePositionRight}
              drawShape={(ctx) => {
                ctx.beginPath();
                ctx.moveTo(2, 0);
                ctx.lineTo(10, 24);
                ctx.lineTo(18, 0);
                ctx.lineTo(2, 0);
                ctx.fillStyle = "#6495ED";
                ctx.fill();
                ctx.closePath();
              }}
            />
            <Confetti
              recycle={false}
              numberOfPieces={160}
              confettiSource={confettiSourcePositionRight}
              drawShape={(ctx) => {
                ctx.beginPath();
                ctx.arc(12, 12, 5, 0, 2 * Math.PI);
                ctx.fillStyle = "#FF00FF";
                ctx.fill();
                ctx.closePath();
              }}
            />
          </div>
        </>
      ) : (
        <>
          {/* CIRCLE DESIGN IN LOGO */}
          <CircleBackdropDesign />

          <div className="w-full relative">
            <div className="h-7 flex justify-between items-center gap-4">
              <div className="text-black font-semibold text-lg leading-7">
                Get started with HBA
              </div>
              <div className="flex" onClick={() => setShowPanel(false)}>
                <div className="bg-white shadow flex justify-center items-center gap-2 cursor-pointer">
                  <div className="w-5 h-5">
                    <TfiClose size={12} className="mx-auto mt-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-7 justify-between items-center gap-4 grid grid-cols-4 gap-y-6 ">
            {getStartedDetails.map(
              (
                { text, icon, iconColor, bgColor, active, modal, reference },
                index
              ) => (
                <TileElement
                  key={index}
                  text={text}
                  icon={icon}
                  iconColor={iconColor}
                  bgColor={bgColor}
                  active={active}
                  modal={modal}
                  reference={reference}
                  setModalState={setModalState}
                  setShowRightPanel={setShowRightPanel}
                  scrollToSection={scrollToSection}
                  activeBlinker={activeIndex === index}
                  setTilePlulse={setTilePlulse}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  );
}

function TileElement({
  text,
  icon,
  iconColor,
  bgColor,
  active,
  activeBlinker,
  modal,
  reference,
  setModalState,
  setShowRightPanel,
  scrollToSection,
  setTilePlulse,
}) {
  // const elementStyle = {
  //   active: {
  //     class: "bg-hbBlue border-hbBlue ",
  //     style: {},
  //     iconClass: "text-white",
  //   },
  //   default: {
  //     class: typeof bgColor === "string" ? bgColor : "",
  //     style: typeof bgColor === "object" ? bgColor : {},
  //     //iconClass: typeof iconColor === "string" ? iconColor : "",
  //     iconStyle: typeof iconColor === "object" ? iconColor : {},
  //   },
  // };
  //mma-2786
  return (
    <div
      className="z-10 grow shrink basis-0 self-stretch justify-start items-center gap-3 flex cursor-pointer"
      onClick={() => {
        if (reference) {
          console.log("Reference:", reference);
          // scrollToSection(reference);
          if (text === "Upload Invoices") {
            scrollToSection(reference, "Invoices");
            setTilePlulse("Invoices");
          } else if (text === "Upload Contracts") {
            scrollToSection(reference, "Contracts");
            setTilePlulse("Contracts");
          } else if (text === "Add Variations or Change Requests") {
            scrollToSection(reference, "Variations");
            setTilePlulse("Variations");
          } else if (text === "Update Property Details") {
            scrollToSection(reference, "ProjectLocation");
            setTilePlulse("ProjectLocation");
          } else if (text === "Upload First Property Photo") {
            scrollToSection(reference, "ProgressPhoto");
            setTilePlulse("ProgressPhoto");
          } else if (text === "Add Completion Date") {
            scrollToSection(reference, "ProgressOfWork");
            setTilePlulse("ProgressOfWork");
          }
        } else {
          setShowRightPanel(true);
          setModalState(modal);
        }
      }}
    >
      <div className="relative w-10 h-10">
        <div
          className={`absolute z-20 p-2.5 w-10 h-10 rounded-lg border border-opacity-60 backdrop-blur-lg justify-center items-center bg-white flex bg-opacity-60`}
          style={{ backdropFilter: "blur(8px)" }}
        >
          <img src={icon} alt="tile icon" />
        </div>
        <div
          className={`w-10 h-10 absolute rounded-lg
          `}
          style={{
            transform: "rotate(15deg) translate(0.2vw, -0.8vh)",
            backgroundColor: active ? "#133352" : iconColor,
          }}
        />
      </div>

      <div>
        <span
          className={`font-open-sans text-base font-normal leading-normal text-black ${
            active ? "line-through" : null
          }`}
        >
          {text}
        </span>
      </div>

      {activeBlinker && (
        <div className="w-7 h-7 relative rounded-3xl">
          <div className="w-3 h-3 left-[8.51px] top-[8.51px] absolute bg-neutral-50 rounded-full" />
          <div className="w-4 h-4 left-[6.08px] top-[6.08px] absolute bg-red-600 rounded-full" />
          <div className="w-4 h-4 left-[6.08px] top-[6.08px] absolute bg-red-600 rounded-full animate-ping" />
        </div>
      )}
    </div>
  );
}

function CircleBackdropDesign() {
  return (
    <>
      <div className="absolute z-0">
        <div
          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
          style={{
            borderColor: "rgb(254, 228, 226, 0.85)",
          }}
        ></div>
        <div
          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
          style={{
            borderColor: "rgb(254, 228, 226, 0.65)",
          }}
        ></div>
        <div
          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
          style={{
            borderColor: "rgb(254, 228, 226, 0.45)",
          }}
        ></div>
        <div
          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
          style={{
            borderColor: "rgb(254, 228, 226, 0.25)",
          }}
        ></div>
      </div>
    </>
  );
}

{
  /* <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex">
          <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-red-100 rounded-3xl border-4 border-red-50 justify-center items-center flex">
            <div className="text-justify text-red-600 text-lg font-normal leading-tight">
              <LuHardHat />
            </div>
          </div>
          <div className="text-black text-base font-normal  leading-normal">
            Add Builder Details
          </div>
          <div className="w-7 h-7 relative rounded-3xl">
            <div className="w-3 h-3 left-[8.51px] top-[8.51px] absolute bg-neutral-50 rounded-full" />
            <div className="w-4 h-4 left-[6.08px] top-[6.08px] absolute bg-red-600 rounded-full" />
            <div className="w-4 h-4 left-[6.08px] top-[6.08px] absolute bg-red-600 rounded-full animate-ping" />
          </div>
        </div>
        <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex"
             onClick={() =>{
              setModalState(modalChoicesEnum.CONTRACT_DETAILS);
              setShowRightPanel(true);
             }}
        >
          <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-yellow-100 rounded-3xl border-4 border-yellow-50 justify-center items-center flex">
            <div className="text-justify text-yellow-600 text-lg font-normal leading-tight">
              <FaFileContract />
            </div>
          </div>

          <div className="text-black text-base font-normal  leading-normal">
            Add Contract Details
          </div>
        </div>
        <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex"
             onClick={() => {
               scrollToSection(propertyPhotoRef);
             }}
        >
          <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-green-100 rounded-3xl border-4 border-green-50 justify-center items-center flex">
            <div className="text-justify text-green-600 text-lg font-normal leading-tight">
              <FaImages />
            </div>
          </div>
          <div className="text-black text-base font-normal  leading-normal" >
            Upload First Property Photo
          </div>
        </div>
        <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex"
             onClick={() => {
               scrollToSection(propertyDetailsRef);
             }}
        >
          <div
            className="pl-3 pr-2.5 pt-2.5 pb-3 rounded-3xl border-4 justify-center items-center flex"
            style={{
              backgroundColor: "#EAECF5",
              border: "3.67px #F8F9FC solid",
            }}
          >
            <div
              className="text-justify text-lg font-normal leading-tight"
              style={{ color: "#3E4784" }}
            >
              <HiOutlineHome />
            </div>
          </div>

          <div className="text-black text-base font-normal leading-normal">
            Update Property Details
          </div>
        </div>

        <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex"
             onClick={() => {
              scrollToSection(completionDateRef);
            }}
        >
          <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-blue-100 rounded-3xl border-4 border-blue-50 justify-center items-center flex">
            <div className="text-justify text-blue-500 text-lg font-normal leading-tight">
              <FaRegCalendar />
            </div>
          </div>
          <div className="text-black text-base font-normal leading-normal">
            Add Completion Date
          </div>
        </div>
        <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex"
             onClick={() => {
              scrollToSection(invoicesRef);
            }}
        >
          <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-purple-100 rounded-3xl border-4 border-purple-50 justify-center items-center flex">
            <div className="text-justify text-purple-600 text-lg font-normal leading-tight">
              <FaFileInvoiceDollar />
            </div>
          </div>

          <div className="text-black text-base font-normal leading-normal">
            Upload Invoices
          </div>
        </div>
        <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex"
             onClick={() => {
              scrollToSection(variationsRef);
            }}
        >
          <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-pink-100 rounded-3xl border-4 border-pink-50 justify-center items-center flex">
            <div className="text-justify text-pink-600 text-lg font-normal leading-tight">
              <BsArrowDownUp />
            </div>
          </div>
          <div className="text-black text-base font-normal leading-normal">
            Add Variations or Change Requests
          </div>
        </div>
        <div className="grow shrink basis-0 self-stretch justify-start items-center gap-3 flex"
             onClick={() => {
              scrollToSection(uploadContractsRef);
            }}
        >
          <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-orange-100 rounded-3xl border-4 border-orange-50 justify-center items-center flex">
            <div className="text-justify text-orange-600 text-lg font-normal leading-tight">
              <FaRegHandshake />
            </div>
          </div>

          <div className="text-black text-base font-normal leading-normal">
            Upload Contracts
          </div>
        </div> */
}
