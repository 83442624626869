import { Base64 } from "js-base64";

/**
export default function EmailParser(response) {
    console.log("EmailParser()", response)

    function decode(input) {
      // const text = new Buffer.from(input, 'base64').toString('ascii')
      // return decodeURIComponent(escape(text))
      return Base64.decode(input.replace(/-/g, '+').replace(/_/g, '/'))
    }
  
    function decode_alternative(input) {
      // this way does not escape special "B" characters
      // const text = Buffer.from(input, 'base64').toString('ascii')
      // return decodeURIComponent(escape(text))
  
      return Base64.decode(input.replace(/-/g, '+').replace(/_/g, '/'))
    }

    function convertToHtml(input) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(input, 'text/html');
      return htmlDoc.documentElement.innerHTML;
    }
  
    const result = {
     text: '',
     html: '',
     attachments: []
    }
  
    let parts = [response]
    console.log("parts", parts)
  
    while (parts.length) {
      let part = parts.shift()
      console.log("part", part)
  
      if (part.parts)
        parts = parts.concat(part.parts)
  
      if (part.mimeType === 'text/plain')
        if (result.text === '' && part.body?.data) {
          result.text = decode(part?.body?.data)
        }
        
  
      if (part.mimeType === 'text/html')
        if (result.html === '' && part.body?.data) {
          result.html = decode(part?.body?.data)
        }
  
      if (part.body.attachmentId) {
        result.attachments.push({
          'partId': part.partId,
          'mimeType': part.mimeType,
          'filename': part.filename,
          'body': part.body
        })
      }
    }
    console.log("\n\n - - - email parse: ", result)
    if (result.html === '') {
      result.html = convertToHtml(result.text.replace(/\n/g, '<br>'));
    }
    return result
  }
  */

export default function EmailParser(response) {
  // console.log("\n\n - - - - -  - - - \nINPUT\n", response);
  function decode(input) {
    return Base64.decode(input.replace(/-/g, "+").replace(/_/g, "/"));
  }

  const result = {
    text: "",
    html: "",
    attachments: [],
    messageId: "",
    references: "",
    inReplyTo: "",
  };

  function processParts(parts) {
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (part.parts) {
        processParts(part.parts);
      }

      if (part.mimeType === "text/plain") {
        if (result.text === "" && part.body?.data) {
          result.text = decode(part.body.data);
        }
      }

      if (part.mimeType === "text/html") {
        if (result.html === "" && part.body?.data) {
          result.html = decode(part.body.data);
        }
      }

      if (part?.headers && part?.headers.length > 0) {
        part.headers?.map((header, index) => {
          if (header.name?.toLowerCase() === "message-id") {
            result.messageId = header.value;
          }

          if (header.name?.toLowerCase() === "references") {
            result.references = header.value;
          }

          if (header.name?.toLowerCase() === "in-reply-to") {
            result.inReplyTo = header.value;
          }
        });
      }

      if (part.body?.attachmentId) {
        result.attachments.push({
          partId: part.partId,
          mimeType: part.mimeType,
          filename: part.filename,
          body: part.body,
        });
      }
    }
  }

  processParts([response]);

  if (result.html === "") {
    result.html = result.text.replace(/\n/g, "<br>");
  }

  return result;
}
