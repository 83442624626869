import React, { useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import { FaSort } from "react-icons/fa";
import { alphabetArray } from "../../../constants/Alphabet";
import { RestoreUser } from "../../../shared/graphql/mutations";
import ToastNotification from "../../toast-notification";
export default function ArchivedUsersTab({
  isMobileDevice,
  width,
  getMobileContentHeight,
  loading,
  ArchivedUsers,
  setAlphabetArrayState,
  alphabetArrayState,
  archivedUsersAlphabet,
  setArchivedUsersAlphabet,
  handleInitials,
  getContacts,
  TeamOptionsCheck,
  ascDesc,
  setAscDesc,
  CgSortAz,
  CgSortZa,
  sortBy,
  refLetters,
  shortcutSelected,
  rows,
  humaReadableFormat,
  getAccessStatusDisplay,
  setArchivedUsers,
  setContactList,
  setSortBy,
}) {
  // console.log(
  //   "ArchivedUsers",
  //   ArchivedUsers,
  //   "archivedUsersAlphabet",
  //   archivedUsersAlphabet
  // );
  const hideToast = () => {
    setShowToast(false);
  };
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [errortoast, seterrortoast] = useState(null);

  const [isSortByCompany, setIsSortByCompany] = useState(false);
  const [isSortByUserType, setIsSortByUserType] = useState(false);
  const [isSortByFirstName, setIsSortByFirstName] = useState(false);

  const handleSortByName = () => {
    let sortedList = [...ArchivedUsers];

    if (ascDesc === null) {
      // descending
      sortedList = sortedList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
      archivedUsersAlphabet.sort().reverse();
      setAlphabetArrayState(alphabetArrayState.reverse());
      setIsSortByUserType(true);
      setIsSortByCompany(true);
      setAscDesc(true);
    } else if (ascDesc === true) {
      sortedList = sortedList.sort((a, b) =>
        b.firstName.localeCompare(a.firstName)
      );
      archivedUsersAlphabet.sort();
      alphabetArray.sort();
      setAscDesc(null);
      // hide 2 buttons
      setIsSortByUserType(false);
      setIsSortByCompany(false);
    }

    setArchivedUsers(sortedList);
    setSortBy("firstName");
    setIsSortByFirstName(false);
  };

  const handleSortByCompanyName = () => {
    let sortedList = [...ArchivedUsers];
    if (ascDesc === null) {
      // ascending
      sortedList = sortedList.sort((a, b) => {
        if (!a.businessName && !b.businessName) return 0;
        if (!a.businessName) return 1;
        if (!b.businessName) return -1;
        return a.businessName.localeCompare(b.businessName);
      });
      setAscDesc(true);
      // hide 2 buttons
      setIsSortByUserType(true);
      setIsSortByFirstName(true);
      setSortBy("company");
    } else if (ascDesc === true) {
      // descending

      sortedList = sortedList.sort((a, b) => {
        if (!a.businessName && !b.businessName) return 0;
        if (!a.businessName) return 1;
        if (!b.businessName) return -1;
        return b.businessName.localeCompare(a.businessName);
      });
      setAscDesc(false);
      // hide 2 buttons
      setIsSortByUserType(true);
      setIsSortByFirstName(true);
      setSortBy("company");
    } else if (!ascDesc) {
      setAscDesc(null);
      sortedList = sortedList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
      archivedUsersAlphabet.sort();
      alphabetArray.sort();
      setIsSortByUserType(false);
      setIsSortByFirstName(false);
      handleSortByName();
    }

    setArchivedUsers(sortedList);
    setIsSortByCompany(false);
  };

  const handleSortByUserType = () => {
    let sortedList = [...ArchivedUsers];

    if (ascDesc === null) {
      sortedList = sortedList.sort((a, b) =>
        a.userType.localeCompare(b.userType)
      );

      setAscDesc(true);
      // hide 2 buttons
      setIsSortByCompany(true);
      setIsSortByFirstName(true);
      setSortBy("userType");
    } else if (ascDesc === true) {
      // descending
      sortedList = sortedList.sort((a, b) =>
        b.userType.localeCompare(a.userType)
      );
      setAscDesc(false);

      // hide 2 buttons
      setIsSortByFirstName(true);
      setIsSortByCompany(true);
      setSortBy("userType");
    } else if (!ascDesc) {
      setAscDesc(null);
      sortedList = sortedList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
      archivedUsersAlphabet.sort();
      alphabetArray.sort();
      setIsSortByFirstName(false);
      setIsSortByCompany(false);
      handleSortByName();
    }

    setArchivedUsers(sortedList);
    setIsSortByUserType(false);
  };

  async function restoreContact(userId) {
    const request = await RestoreUser(
      userId,
      localStorage.getItem("companyId")
    );

    if (request && request?.data?.userSoftDeleteOrRestore === true) {
      let userContact = ArchivedUsers.find((x) => x.id === userId);
      setArchivedUsers((contacts) =>
        contacts.filter((contact) => contact.id !== userContact.id)
      );
      setContactList((contacts) => [...contacts, userContact]);

      setalertMessage(`Contact Restored Sucessfully`);

      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        // getContacts();
      }, 5000);
    } else {
      setalertMessage(`Cannot Delete Contact. Please contact support`);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }

  return isMobileDevice(width) ? (
    <>
      {/* MOBILE TABLE */}
      <div>
        <div
          className="mt-1 overflow-y-auto"
          style={{
            height: getMobileContentHeight(),
          }}
        >
          {loading
            ? [...Array(5).keys()].map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <div>
                      <div className="flex flex-row justify-center items-center">
                        <SkeletonTheme width={"30px"} height={"30px"}>
                          <Skeleton count={1} />
                        </SkeletonTheme>
                        <div
                          className="w-full border-b-2 border-dashed"
                          style={{
                            height: "1px",
                          }}
                        ></div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="ml-5 rounded-lg px-2 py-2 gap-3 hover:shadow-md active:shadow-md transition-shadow">
                          <SkeletonTheme width={"100%"} height={"70px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                          <SkeletonTheme width={"100%"} height={"70px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                          <SkeletonTheme width={"100%"} height={"70px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            : archivedUsersAlphabet.map((letter, idx) => (
                <div key={"a" + letter}>
                  <div className="flex flex-row justify-center items-center">
                    <p className="pr-2 font-medium">{letter}</p>
                    <div
                      className="w-full border-b-2 border-dashed"
                      style={{
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div className="flex flex-col gap-2">
                    {ArchivedUsers &&
                      ArchivedUsers.map(
                        (archived, index) =>
                          archived.firstName.charAt(0) === letter && (
                            <div
                              //   onClick={() => handleEditModal(contact)}
                              key={letter + "" + index}
                              className="ml-5 bg-gray-100 rounded-lg px-2 py-2 gap-3 hover:shadow-md active:shadow-md transition-shadow"
                              style={{
                                display: "grid",
                                gridTemplateColumns: "32px minmax(0, 1fr) 18px",
                              }}
                            >
                              {console.log(archived)}
                              <div
                                className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center"
                                style={{ flexShrink: "0" }}
                              >
                                {archived.profilePicture === null ? (
                                  <>
                                    {handleInitials(
                                      archived.firstName,
                                      archived.lastName
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="rounded-full w-8 h-8"
                                    src={archived.profilePicture}
                                    alt={archived.name}
                                  />
                                )}
                              </div>
                              <div>
                                <p className="font-medium break-words">
                                  {archived.firstName} {archived.lastName}{" "}
                                </p>
                                <p className="text-cyan-400 font-medium break-words">
                                  {archived.userType}
                                </p>
                                <p className="text-xs break-words">
                                  {archived.email}
                                </p>
                                {archived.teams?.items.map((t) => (
                                  <div
                                    key={t.id}
                                    className="flex items-center py-1 break-words"
                                  >
                                    <TeamOptionsCheck t={t}>
                                      <p className="font-semibold text-xs rounded-full bg-gray-200 px-2 py-1 break-words whitespace-normal">
                                        {t.name}
                                      </p>
                                    </TeamOptionsCheck>
                                  </div>
                                ))}{" "}
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  ) : (
    <div className="w-full py-2">
      {/* DESKTOP VIEW */}
      <table
        className={
          loading
            ? "w-full text-left border-separate border-spacing-y-3"
            : "w-full text-left "
        }
      >
        {/* headers */}
        <thead className="sticky bg-white z-10" style={{ top: "74px" }}>
          <tr>
            <th className="p-2">
              <div className="flex items-center gap-x-1">
                Name
                {ascDesc === null ? (
                  <CgSortZa
                    onClick={handleSortByName}
                    className={`${
                      isSortByFirstName ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                ) : ascDesc === true ? (
                  <CgSortAz
                    onClick={handleSortByName}
                    className={`${
                      isSortByFirstName ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                ) : (
                  <CgSortZa
                    onClick={handleSortByName}
                    className={`${
                      isSortByFirstName ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                )}
              </div>
            </th>
            <th className="p-2">Email</th>
            <th className="p-2">Team</th>
            <th className="p-2">
              <div className="flex items-center gap-x-1">
                User Type
                {/* {IsSortedReverse ? (
                          <CgSortAz
                            onClick={handleSortByUserType}
                            className={`${
                              sortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : (
                          <CgSortZa
                            onClick={handleSortByUserType}
                            className={`${
                              sortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        )} */}
                {ascDesc === null ? (
                  <FaSort
                    onClick={handleSortByUserType}
                    className={`${
                      isSortByUserType ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                ) : ascDesc === true ? (
                  <CgSortZa
                    onClick={handleSortByUserType}
                    className={`${
                      isSortByUserType ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                ) : (
                  <CgSortAz
                    onClick={handleSortByUserType}
                    className={`${
                      isSortByUserType ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                )}
              </div>
            </th>
            <th className="p-2">
              <div className="flex items-center gap-x-1">
                Company{" "}
                {/* {IsSortedReverse ? (
                          <CgSortAz
                            onClick={handleSortByCompanyName}
                            className={`${
                              sortByCompanyName ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : (
                          <CgSortZa
                            onClick={handleSortByCompanyName}
                            className={`${
                              sortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        )} */}
                {ascDesc === null ? (
                  <FaSort
                    onClick={handleSortByCompanyName}
                    className={`${
                      isSortByCompany ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                ) : ascDesc === true ? (
                  <CgSortZa
                    onClick={handleSortByCompanyName}
                    className={`${
                      isSortByCompany ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                ) : (
                  <CgSortAz
                    onClick={handleSortByCompanyName}
                    className={`${
                      isSortByCompany ? "hidden" : ""
                    } text-xl cursor-pointer hover:text-gray-500`}
                  />
                )}
              </div>
            </th>
            <th className="p-2 w-20 " />
          </tr>
        </thead>
        {/* content Changes here*/}
        <tbody className="relative">
          {loading ? (
            [...Array(5).keys()].map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td className="pt-5 px-2">
                      <SkeletonTheme width={"24px"} height={"24px"}>
                        <Skeleton count={1} />
                      </SkeletonTheme>
                    </td>
                  </tr>
                  <tr className="mb-1">
                    <td className="pl-2 py-2  -pr-2">
                      <div className="flex items-center gap-x-2 mb-1">
                        <div className="rounded-full">
                          <SkeletonTheme width={"2rem"} height={"2rem"}>
                            <Skeleton className="rounded-full" count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-36 mr-3">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-56 mr-3">
                        <SkeletonTheme height={"30px"}>
                          <Skeleton count={1} />
                        </SkeletonTheme>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-32 mr-2">
                        <SkeletonTheme height={"30px"}>
                          <Skeleton className="rounded-3xl" count={1} />
                        </SkeletonTheme>
                      </div>
                    </td>
                    <td className="pl-1 ">
                      <div className="w-36">
                        <div className="w-2/3">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton className="rounded-3xl" count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-28">
                        <div className="w-10/12">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-28 mr-10">
                        <div className="w-10/12">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-center gap-x-5 mr-3">
                        <div className="w-28 h-10">
                          <SkeletonTheme height={"100%"} width="110%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-8 h-8">
                          <SkeletonTheme height={"90%"} width="90%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-8 h-8">
                          <SkeletonTheme height={"90%"} width="90%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-2 py-2 mb-1 -pr-2">
                      <div className="flex items-center gap-x-2 mb-1">
                        <div className="rounded-full">
                          <SkeletonTheme width={"2rem"} height={"2rem"}>
                            <Skeleton className="rounded-full" count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-36 mr-3">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-56 mr-3">
                        <SkeletonTheme height={"30px"}>
                          <Skeleton count={1} />
                        </SkeletonTheme>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-32 mr-2">
                        <SkeletonTheme height={"30px"}>
                          <Skeleton className="rounded-3xl" count={1} />
                        </SkeletonTheme>
                      </div>
                    </td>
                    <td className="pl-1 ">
                      <div className="w-36">
                        <div className="w-2/3">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton className="rounded-3xl" count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-28">
                        <div className="w-10/12">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-28 mr-10">
                        <div className="w-10/12">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-center gap-x-5 mr-3">
                        <div className="w-28 h-10">
                          <SkeletonTheme height={"100%"} width="110%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-8 h-8">
                          <SkeletonTheme height={"90%"} width="90%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-8 h-8">
                          <SkeletonTheme height={"90%"} width="90%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="pl-2 py-2 mb-1 -pr-2">
                      <div className="flex items-center gap-x-2 mb-1">
                        <div className="rounded-full">
                          <SkeletonTheme width={"2rem"} height={"2rem"}>
                            <Skeleton className="rounded-full" count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-36 mr-3">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-56 mr-3">
                        <SkeletonTheme height={"30px"}>
                          <Skeleton count={1} />
                        </SkeletonTheme>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-32 mr-2">
                        <SkeletonTheme height={"30px"}>
                          <Skeleton className="rounded-3xl" count={1} />
                        </SkeletonTheme>
                      </div>
                    </td>
                    <td className="pl-1 ">
                      <div className="w-36">
                        <div className="w-2/3">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton className="rounded-3xl" count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-28">
                        <div className="w-10/12">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                    <td className="pl-1">
                      <div className="w-28 mr-10">
                        <div className="w-10/12">
                          <SkeletonTheme height={"30px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-center gap-x-5 mr-3">
                        <div className="w-28 h-10">
                          <SkeletonTheme height={"100%"} width="110%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-8 h-8">
                          <SkeletonTheme height={"90%"} width="90%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="w-8 h-8">
                          <SkeletonTheme height={"90%"} width="90%">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
          ) : sortBy === "firstName" ? (
            archivedUsersAlphabet.map((letter, idx) => (
              <>
                <tr
                  ref={(el) => (refLetters.current[idx] = el)} //added div useRef
                  id={letter}
                  key={letter}
                  className=""
                >
                  <td className="pt-4 px-2">
                    <div className="flex items-center gap-x-2">
                      <p
                        className={`${
                          shortcutSelected == letter
                            ? "text-cyan-500 font-bold"
                            : "text-gray-700 font-semibold"
                        }  text-lg `}
                      >
                        {letter}
                      </p>
                    </div>
                  </td>
                </tr>
                {ArchivedUsers &&
                  ArchivedUsers.map(
                    (archived, index) =>
                      archived.firstName.charAt(0) === letter && (
                        <tr
                          ref={archived.isNewlyAdded ? rows : null}
                          key={archived.id}
                          className={
                            archived.isNewlyAdded
                              ? "opacity-100 bg-cyan-100"
                              : "stripe opacity-100"
                          }
                        >
                          <td className="p-2 max-w-min">
                            <div className="flex items-center gap-x-2 ">
                              <span>
                                <div className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center">
                                  {archived.profilePicture === null ? (
                                    <>
                                      {handleInitials(
                                        archived.firstName,
                                        archived.lastName
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="rounded-full w-8 h-8"
                                      src={archived.profilePicture}
                                    />
                                  )}
                                </div>
                              </span>
                              <p className="font-semibold max-w-40 min-w-min">
                                {archived.firstName} {archived.lastName}
                              </p>
                            </div>
                          </td>
                          <td className="p-2 max-w-28 min-w-min">
                            {archived.email}
                          </td>
                          <td className="p-2 max-w-40 min-w-min">
                            {archived.teams?.items.map((t) => (
                              <div
                                key={t.id}
                                className="flex items-center py-1"
                              >
                                <TeamOptionsCheck t={t}>
                                  <p className="font-semibold text-xs rounded-full bg-gray-200 px-1 py-1">
                                    {t.name}
                                  </p>
                                </TeamOptionsCheck>
                              </div>
                            ))}{" "}
                          </td>
                          <td className="p-2 max-w-44 min-w-min">
                            <div className="flex items-center gap-x-2 ">
                              <p className="font-semibold text-xs rounded-full bg-blue-100 px-2 py-1">
                                {humaReadableFormat(
                                  archived.userType
                                    .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
                                    .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
                                )}
                              </p>
                            </div>
                          </td>
                          <td className="p-2 max-w-32 min-w-min">
                            {archived.businessName
                              ? archived.businessName
                              : "N/A"}
                          </td>
                          <td className="p-2 max-w-44 min-w-min">
                            <button
                              onClick={() => restoreContact(archived.id)}
                              className="h-10 font-semibold justify-center items-center text-white bg-green-500 rounded-md text-xs cursor-pointer border border-green-500 hover:bg-white hover:text-green-500"
                              style={{ width: "120px" }}
                            >
                              Restore
                            </button>
                          </td>
                        </tr>
                      )
                  )}
              </>
            ))
          ) : sortBy === "userType" ? (
            // : archivedUsersAlphabet.map((letter, idx) => (
            //     <>
            //       <tr
            //         ref={(el) => (refLetters.current[idx] = el)} //added div useRef
            //         id={letter}
            //         key={letter}
            //         className=""
            //       >
            //         <td className="pt-4 px-2">
            //           <div className="flex items-center gap-x-2">
            //             <p
            //               className={`${
            //                 shortcutSelected == letter
            //                   ? "text-cyan-500 font-bold"
            //                   : "text-gray-700 font-semibold"
            //               }  text-lg `}
            //             >
            //               {letter}
            //             </p>
            //           </div>
            //         </td>
            //       </tr>
            <>
              <td className="pt-10 px-2">
                <div className="flex items-center gap-x-2"></div>
              </td>
              {ArchivedUsers &&
                ArchivedUsers.map(
                  (archived, index) => (
                    // archived.firstName.charAt(0) === letter && (
                    <tr
                      ref={archived.isNewlyAdded ? rows : null}
                      key={archived.id}
                      className={
                        archived.isNewlyAdded
                          ? "opacity-100 bg-cyan-100"
                          : "stripe opacity-100"
                      }
                    >
                      <td className="p-2 max-w-min">
                        <div className="flex items-center gap-x-2 ">
                          <span>
                            <div className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center">
                              {archived.profilePicture === null ? (
                                <>
                                  {handleInitials(
                                    archived.firstName,
                                    archived.lastName
                                  )}
                                </>
                              ) : (
                                <img
                                  className="rounded-full w-8 h-8"
                                  src={archived.profilePicture}
                                />
                              )}
                            </div>
                          </span>
                          <p className="font-semibold max-w-40 min-w-min">
                            {archived.firstName} {archived.lastName}
                          </p>
                        </div>
                      </td>
                      <td className="p-2 max-w-28 min-w-min">
                        {archived.email}
                      </td>
                      <td className="p-2 max-w-40 min-w-min">
                        {archived.teams?.items.map((t) => (
                          <div key={t.id} className="flex items-center py-1">
                            <TeamOptionsCheck t={t}>
                              <p className="font-semibold text-xs rounded-full bg-gray-200 px-1 py-1">
                                {t.name}
                              </p>
                            </TeamOptionsCheck>
                          </div>
                        ))}{" "}
                      </td>
                      <td className="p-2 max-w-44 min-w-min">
                        <div className="flex items-center gap-x-2 ">
                          <p className="font-semibold text-xs rounded-full bg-blue-100 px-2 py-1">
                            {humaReadableFormat(
                              archived.userType
                                .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
                                .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
                            )}
                          </p>
                        </div>
                      </td>
                      <td className="p-2 max-w-32 min-w-min">
                        {archived.businessName ? archived.businessName : "N/A"}
                      </td>
                      <td className="p-2 max-w-44 min-w-min">
                        <button
                          onClick={() => restoreContact(archived.id)}
                          className="h-10 font-semibold justify-center items-center text-white bg-green-500 rounded-md text-xs cursor-pointer border border-green-500 hover:bg-white hover:text-green-500"
                          style={{ width: "120px" }}
                        >
                          Restore
                        </button>
                      </td>
                    </tr>
                  )
                  // )
                )}
            </>
          ) : (
            sortBy === "company" && (
              // : archivedUsersAlphabet.map((letter, idx) => (
              //     <>
              //       <tr
              //         ref={(el) => (refLetters.current[idx] = el)} //added div useRef
              //         id={letter}
              //         key={letter}
              //         className=""
              //       >
              //         <td className="pt-4 px-2">
              //           <div className="flex items-center gap-x-2">
              //             <p
              //               className={`${
              //                 shortcutSelected == letter
              //                   ? "text-cyan-500 font-bold"
              //                   : "text-gray-700 font-semibold"
              //               }  text-lg `}
              //             >
              //               {letter}
              //             </p>
              //           </div>
              //         </td>
              //       </tr>
              <>
                <td className="pt-10 px-2">
                  <div className="flex items-center gap-x-2"></div>
                </td>
                {ArchivedUsers &&
                  ArchivedUsers.map(
                    (archived, index) => (
                      // archived.firstName.charAt(0) === letter && (
                      <tr
                        ref={archived.isNewlyAdded ? rows : null}
                        key={archived.id}
                        className={
                          archived.isNewlyAdded
                            ? "opacity-100 bg-cyan-100"
                            : "stripe opacity-100"
                        }
                      >
                        <td className="p-2 max-w-min">
                          <div className="flex items-center gap-x-2 ">
                            <span>
                              <div className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center">
                                {archived.profilePicture === null ? (
                                  <>
                                    {handleInitials(
                                      archived.firstName,
                                      archived.lastName
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="rounded-full w-8 h-8"
                                    src={archived.profilePicture}
                                  />
                                )}
                              </div>
                            </span>
                            <p className="font-semibold max-w-40 min-w-min">
                              {archived.firstName} {archived.lastName}
                            </p>
                          </div>
                        </td>
                        <td className="p-2 max-w-28 min-w-min">
                          {archived.email}
                        </td>
                        <td className="p-2 max-w-40 min-w-min">
                          {archived.teams?.items.map((t) => (
                            <div key={t.id} className="flex items-center py-1">
                              <TeamOptionsCheck t={t}>
                                <p className="font-semibold text-xs rounded-full bg-gray-200 px-1 py-1">
                                  {t.name}
                                </p>
                              </TeamOptionsCheck>
                            </div>
                          ))}{" "}
                        </td>
                        <td className="p-2 max-w-44 min-w-min ">
                          <div className="flex items-center gap-x-2 ">
                            <p className="font-semibold text-xs rounded-full bg-blue-100 px-2 py-1">
                              {humaReadableFormat(
                                archived.userType
                                  .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
                                  .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
                              )}
                            </p>
                          </div>
                        </td>
                        <td className="p-2 max-w-32 min-w-min">
                          {archived.businessName
                            ? archived.businessName
                            : "N/A"}
                        </td>
                        <td className="p-2 max-w-44 min-w-min">
                          <button
                            onClick={() => restoreContact(archived.id)}
                            className="h-10 font-semibold justify-center items-center text-white bg-green-500 rounded-md text-xs cursor-pointer border border-green-500 hover:bg-white hover:text-green-500"
                            style={{ width: "120px" }}
                          >
                            Restore
                          </button>
                        </td>
                      </tr>
                    )
                    // )
                  )}
              </>
            )
          )}
        </tbody>
      </table>

      {showToast && (
        <ToastNotification
          title={alertMessage}
          error={errortoast}
          hideToast={hideToast}
        />
      )}
    </div>
  );
}
