import { useState } from "react";
import { Auth } from "aws-amplify";
import "../../assets/styles/Auth.css";
import Button from "../../shared/Button"
import { AiFillMail, AiOutlineLoading } from "react-icons/ai";
import InputCode from "./input-code";

import ProgressNotification from "./progress-notification";
import ToastNotification from "../toast-notification";


export default function ForgotPassword({
  user,
  setUser,
  AuthState,
  setNextAuthState,
  notifMessage,
  notifShow,
  notifVariant,
  setNotifMessage,
  setNotifShow,
  setNotifVariant,
  loading,
  setLoading,
}) {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const hideToast = () => {setShowToast(false)};

  async function handleForgotPassword(event) {
    event.preventDefault();
    if (codeSent === false) {
      const forgotPassword = await Auth.forgotPassword(event.target.email.value)
        .then((data) => {
          setNotifShow(false)
          console.log(data);
          setEmail(event.target.email.value);
          setCodeSent(true);
          setUser({
            username: event.target.email.value,
            code: true,
          });
          setNextAuthState(AuthState.ResetPassword);
        })
        .catch((err) => {
          // setResultMessage("User not registered")
          // setShowToast(true);

          // setTimeout(() => {
          //   setShowToast(false);
          // }, 3000);

          setLoading(false)
          setNotifMessage("User Not Registered")
          setNotifVariant("error")
          setNotifShow(true)

          // setNotifMessage(err.message)
          // setNotifVariant("error")
          // console.log(err)
        });
    }
    if (codeSent === true) {
      if (code !== "") {
        
      }
    }
  }

  return (
    <>
      <div className="w-full mt-10 md:mt-0 h-full fade-in-fwd">
        <div className="font-semibold text-hbBlue text-2xl md:text-3xl">
          FORGOT PASSWORD
        </div>
        <div>
          <div className="w-full mt-10">
          <ProgressNotification 
            className=""
            variant={notifVariant}
            message={notifMessage}
            show={notifShow}
          />
            <form onSubmit={(event) => handleForgotPassword(event)}>
              <div className="flex flex-col" style={{ maxWidth: "500px" }}>
                {/* {!codeSent ? ( */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="signIn-email"
                      className="text-xl font-normal text-darkGray"
                    >
                      Email Address
                    </label>
                    {/* <div
                    className="absolute left-9 flex"
                    style={{ top: "475px" }}
                  >
                    <AiFillMail
                      className="relative top-1 text-hbGray"
                      size={36}
                    />
                    <div className="border-l-2 h-7 border-hbGray relative top-2 left-1"></div>
                  </div> */}
                    <div className="mt-2 relative flex flex-col">
                      <input
                        type="email"
                        id="forgotPassword-email"
                        key="forgotPassword-email"
                        name="email"
                        placeholder="example@gmail.com"
                        className="pl-14 md:pl-16 pr-3 h-14 md:h-16 py-2 border-4 border-neutral-100 outline-none rounded-xl text-base md:text-xl text-hbGray font-normal placeholder-gray-300 focus:outline-none focus:ring-2 ring-hbBlue"
                        required
                      />
                      <label htmlFor="signIn-email">
                        <div className="absolute top-1/2 transform -translate-y-1/2 left-4 border-r-2 pr-2">
                          <AiFillMail className="text-2xl md:text-4xl text-hbGray" />
                        </div>
                      </label>
                    </div>
                  </div>

                <div className="flex flex-col-reverse md:grid md:grid-cols-2 mt-14 md:mt-9 items-center">
                  <div className="block md:col-span-1 mt-3 md:mt-0">
                    <span className="text-base font-normal text-hbGray">
                      Back to
                    </span>
                    <button
                      className="ml-1 text-base font-normal text-hbBlue"
                      type="button"
                      onClick={() => {setNotifShow(false);setNextAuthState(AuthState.SignIn);}}
                    >
                      Login
                    </button>
                  </div>
                  <div className="w-full md:col-span-1">
                    <Button
                      type="submit"
                      variant="hbBlue"
                      block={true}
                      size={"xl"}
                      className="h-16 text-lg"
                      disabled={loading}
                    >
                      <span className="relative text-xl font-semibold">
                        {loading ? <AiOutlineLoading className="absolute rotate-center -left-6 top-1" /> : null}
                        SEND CODE
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        
      </div>


      {showToast && (
        <ToastNotification
          title={resultMessage}
          hideToast={hideToast}
          error={"Error"}
        />
      )}
    </>
  );
}
