import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { CgLogOut } from "react-icons/cg";
import { FaThList } from "react-icons/fa";
import { HiChevronDoubleRight } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import Sidebar from ".";
import { SidebarData } from "./SidebarData";
import { useHistory } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import "../../assets/styles/Inbox.css";
import ToastNotification from "../toast-notification";
import { AppRoutes } from "../../constants/AppRoutes";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";

import CreatePortalModal from "../authentication/create-portal-modal";

function MinimizedBar({
  sidebar,
  userInfo,
  setuserInfo,
  showSidebar,
  signOut,
  assigneeInitials,
  location,
  showInbox,
  showUserTypeAccess,
  showContacts,
  showTasks,
  clickLogout,
  profilePic,
  setCompanyName,
  companyName,
  showInboxSublist,
  setShowInboxSublist,
  setIsInitializingPortal,
}) {
  const [showcreateportal, setshowcreateportal] = useState(false);
  const [showPortals, setShowPortals] = useState(false);
  const [userpages, setuserpages] = useState([]);
  const [defaultCompany, setdefaultCompany] = useState({
    companyId: "",
    company: "",
    userType: "",
  });
  const [user, setuser] = useState({
    firstName: "",
    lastName: "",
  });
  const [showToast, setshowToast] = useState({
    toast: false,
    error: null,
    message: "",
  });
  const [organisationslist, setorganisationslist] = useState([]);
  const companyId = localStorage.getItem("companyId");
  const [error, setError] = useState(null);
  let history = useHistory();

  // MMA - 2004
  // Hide the floating side bar if gmail page reached the sticky navigation
  //

  const domain = window.location.hostname;

  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  const hbaFontClass =
    hbaDomains.includes(domain) || obaDomains.includes(domain)
      ? "font-open-sans"
      : "";

  const [isButtonVisible, setButtonVisible] = useState(true);

  // Function to handle scroll event
  const handleScroll = () => {
    // Check if the nav-pane is sticky (top offset is 0)
    const navPane = document.querySelector(".nav-pane");
    if (navPane && window.pageYOffset === navPane.offsetTop) {
      setButtonVisible(false); // Hide the floating button when the nav-pane is sticky
    } else {
      setButtonVisible(true); // Show the floating button when the nav-pane is not sticky
    }
  };

  // Attach scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // initDefaultCompany()
    getUser();
    const ac = new AbortController();
    return () => ac.abort();
  }, []);

  useEffect(() => {
    getCompanyName();
  }, [companyId, organisationslist]);

  const USER_DETAILS_QUERY = `
    query user($id: String) {
      user(id: $id) {
        company {
          id
          name
        }
        email
        firstName
        lastName
        userType
        profilePicture
        hasPortalAccess
        organisations {
          items {
            company {
              id
              name
              representative {
                id
                firstName
                lastName
              }
            }
            userType
            hasPortalAccess
            isDeleted
            userCategory
          }
        }
      }
    }
  `;

  const DEFAULT_PAGES_QUERY = `query getDefaults {
    pages {
      id
      name
      features {
        id
        name
      }
    }
  }`;

  let getUser = async () => {
    try {
      const dafaults = await API.graphql({
        query: DEFAULT_PAGES_QUERY,
      });

      const pages = dafaults?.data?.pages;
      setuserpages(pages);

      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((cognitoUser) => {
        var userId = cognitoUser.attributes["sub"];
        var params = {
          query: USER_DETAILS_QUERY,
          variables: {
            id: userId,
          },
        };
        API.graphql(params).then((userInfo) => {
          const userData = userInfo.data;
          console.log("USERDATA AUTH", userData);
          const orgs = userData?.user?.organisations?.items;
          const accessibleOrgs = orgs
            .filter((item) => item?.isDeleted !== true)
            .sort(
              (a, b) =>
                new Date(a.company.createdAt) - new Date(b.company.createdAt)
            );

          setorganisationslist(accessibleOrgs);

          console.log("userinfo", userInfo.data);
        });
      });
    } catch (e) {
      const error = e.errors;
      setError(error ? error[0].message : error);
    }
  };

  //SELECTING COMPANY
  const USER_ACCESS_QUERY = `
  query getPagesAndAccess($companyId: String, $userType: UserType) {
    companyAccessType(companyId: $companyId, userType: $userType) {
      id
      access {
        id
        name
        features {
          id
          name
        }
      }
    }
  }
  `;

  const UPDATE_ACCESS_MUTATION = `
  mutation updateCompanyAccessType($id: String, $access: [AccessInput]) {
    companyAccessTypeUpdate(
      id: $id
      access: $access
    ) {
      id
      access {
        id
        features {
          id
        }
      }
    }
  }`;

  async function updateCompanyAccessType(id, access) {
    try {
      await API.graphql({
        query: UPDATE_ACCESS_MUTATION,
        variables: {
          id,
          access,
        },
      });
    } catch (e) {
      setError(e.errors[0].message);
    }
  }

  const selectCompany = (cmpId, cmpName, userType, userCategory) => {
    if (cmpId == defaultCompany.companyId) {
      portalSwitch(userCategory);
    } else {
      setSelectedCompanyAsDefault(cmpId, cmpName, userType, userCategory);
    }
  };

  const setSelectedCompanyAsDefault = (
    cmpId,
    cmpName,
    userType,
    userCategory
  ) => {
    // const hbaUserCategories = ["HBA_ARCHITECT_SUPERINTENDENT", "HBA_HOMEOWNER"];

    localStorage.setItem("companyId", cmpId);
    localStorage.setItem("company", cmpName);
    localStorage.setItem("userType", userType);
    if (hbaDomains.includes(domain)) {
      localStorage.setItem("userCategory", userCategory);
    }
    setCompanyName(cmpName);

    var params = {
      query: USER_ACCESS_QUERY,
      variables: {
        companyId: cmpId,
        userType: userType,
      },
    };

    API.graphql(params).then(async (ua) => {
      if (ua.data.companyAccessType.length !== 0) {
        const userAccessId = ua.data.companyAccessType[0].id;
        const userAccess = ua.data.companyAccessType[0].access;

        const stringifyAllAccess = JSON.stringify(userpages);
        const stringifyUserAccess = JSON.stringify(userAccess);
        localStorage.setItem("access", stringifyUserAccess);

        if (userType === "OWNER") {
          if (stringifyAllAccess !== stringifyUserAccess) {
            console.log("Current Owner has limited access");
            const updateAccess = await updateCompanyAccessType(
              userAccessId,
              userpages
            );

            if (updateAccess) {
              // history.push(AppRoutes.DASHBOARD);
              // history.push(dashboardPath);
              portalSwitch(userCategory);
            } else {
              console.log("ERROR UPDATE ACCESS CHECK", updateAccess);
            }
          } else {
            //CURRENT END POINT OF FUNCTION
            // history.push(AppRoutes.DASHBOARD);
            // history.push(dashboardPath);
            portalSwitch(userCategory);
          }
        } else {
          console.log("ERROR COMPANY USERTYPE CHECK");
        }

        // history.push(AppRoutes.DASHBOARD);
        // history.push(dashboardPath);
        portalSwitch(userCategory);
      } else {
        history.push(AppRoutes.SIGNOUT);
      }
    });
  };

  function portalSwitch(userCategory) {
    // OWNERBUILDER or MMA Portal to /dashboard
    // HBA_ARCHITECT_SUPERINTENDENT or HBA_HOMEOWNER or HBA Portal to /hba-dashboard
    console.group("Switch Company");
    console.log("Persona is ", userCategory);

    const hbaUserCategories = ["HBA_ARCHITECT_SUPERINTENDENT", "HBA_HOMEOWNER"];

    let dashboardRoute;

    if (hbaDomains.includes(domain)) {
      if (!userCategory) {
        console.log("Old accounts (should be redirected to HBA dashboard)");
        dashboardRoute = AppRoutes.HBADASHBOARD;
      } else {
        if (!hbaUserCategories.includes(userCategory)) {
          console.log("Redirect to OWNERBUILDER dashboard");
          dashboardRoute = AppRoutes.DASHBOARD;
        }
      }
    } else if (obaDomains.includes(domain)) {
      history.push(AppRoutes.OBADASHBOARD); // OBA
    } else {
      dashboardRoute = AppRoutes.DASHBOARD; // MMA
    }

    history.push(dashboardRoute);
  }

  /*
   * PORTED OVER FROM LOCALSTORAGE TO THIS BECAUSE THE FORMER CAUSES
   * ISSUES WHEREIN THE INVITED USER SOMETIMES GETS NULL AS ITS
   * SELECTED COMPANY NAME
   * (MMA-1936)
   */
  const getCompanyName = async () => {
    if (
      typeof Storage === void 0 ||
      localStorage.getItem("company") === undefined ||
      localStorage.getItem("company")?.trim() === null ||
      localStorage.getItem("company")?.trim() === "null" ||
      localStorage.getItem("company") === "null" ||
      localStorage.getItem("company")?.trim() === "" ||
      !localStorage.getItem("company")
    ) {
      let company = "";
      for (let i = 0; i < organisationslist.length; i++) {
        if (organisationslist[i].company.id === companyId) {
          company = organisationslist[i].company.name;
          localStorage.setItem("company", company);
          setCompanyName(company);
        }
      }
      return;
    } else {
      //Added this to avoid delay in displaying the company name
      //when it is already stored in the localStorage
      setCompanyName(localStorage.getItem("company"));
    }
  };

  // console.log(companyName);

  return (
    <>
      {!sidebar && isButtonVisible && (
        <div
          className="flex sm:hidden fixed w-9 h-9 bg-white rounded-full shadow-md"
          style={{ left: "25px", top: "33px", zIndex: "11" }}
        >
          <FaThList
            data-tip="Expand Menu"
            onClick={showSidebar}
            style={{ color: "var(--mysteryGrey)", margin: "auto" }}
          />
        </div>
      )}

      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="sidebar-collapsed sidebar hidden sm:grid">
          <div className="main-grid">
            <div className="logo-grid-collapsed">
              <Link
                onMouseDown={() => ReactTooltip.show()}
                className="nav-item-collapsed nav-item"
                to={dashboardPath}
              >
                <img
                  className="rounded-md object-scale-down h-8 w-8"
                  src={process.env.REACT_APP_HEADER_ICON}
                  alt="logo"
                />
              </Link>
              <button>
                <HiChevronDoubleRight
                  data-for="expand"
                  data-tip="Expand Menu"
                  onClick={showSidebar}
                  style={{ color: "var(--mysteryGrey)" }}
                />
                <ReactTooltip
                  place="right"
                  className={hbaFontClass}
                  effect="solid"
                  id="expand"
                />
              </button>
            </div>
            <ul className="nav-menus">
              {userInfo &&
                SidebarData.sort((a, b) => a.order - b.order).map(
                  (item, index) => {
                    const navClass = [];

                    navClass.push(
                      location.pathname === item.path ? "active-page" : ""
                    );

                    navClass.push(
                      item.name === "USERTYPEACCESS"
                        ? "hidden lg:block"
                        : "sm:block"
                    );

                    return item.name === "INBOX" && showInbox ? (
                      <li className={navClass.join(" ")} key={index}>
                        <a
                          data-tip={item.title}
                          data-for={item.title}
                          className="nav-item-collapsed nav-item"
                          // onMouseDown={() => ReactTooltip.show()}
                          onClick={() => {
                            showSidebar(true);
                            setShowInboxSublist(true);
                          }}
                        >
                          {item.icon}
                        </a>
                        <ReactTooltip
                          className={hbaFontClass}
                          id={item.title}
                          place="right"
                          effect="solid"
                        />
                      </li>
                    ) : item.name === "DASHBOARD" ||
                      item.name === "ACCOUNTSETTINGS" ||
                      (item.name === "USERTYPEACCESS" && showUserTypeAccess) ||
                      (item.name === "CONTACTS" && showContacts) ||
                      (item.name === "TASKS" && showTasks) ? (
                      <li className={navClass.join(" ")} key={index}>
                        <Link
                          data-tip={item.title}
                          data-for={item.title}
                          // onMouseDown={() => ReactTooltip.show()}
                          className="nav-item-collapsed nav-item"
                          to={item.path}
                          onClick={() => setShowInboxSublist(false)}
                        >
                          {item.icon}
                        </Link>
                        <ReactTooltip
                          className={hbaFontClass}
                          id={item.title}
                          place="right"
                          effect="solid"
                        />
                      </li>
                    ) : null;
                  }
                )}
            </ul>
            <hr />

            <button
              className="logout-btn logout-btn-collapsed"
              onClick={clickLogout}
            >
              <CgLogOut
                data-for="logout"
                data-tip="Logout"
                style={{ color: "var(--mysteryGrey)" }}
                className=""
              />
              <ReactTooltip
                id="logout"
                effect="solid"
                place="right"
                className={hbaFontClass}
              />
            </button>
          </div>
          <div>
            <div
              className="flex bg-green-400 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center mb-1 cursor-pointer"
              data-tip={companyName}
              data-for="company"
              onClick={() => {
                setShowPortals(!showPortals);
                getUser();
              }}
            >
              <p className="font-medium">{companyName[0]}</p>
              <ReactTooltip
                id="company"
                effect="solid"
                place="right"
                className={hbaFontClass}
              />
            </div>

            {showPortals === true ? (
              <>
                <div className="w-18 bg-gray-200 rounded-md fixed ml-10 -mt-40 h-48 overflow-y-scroll test123 p-3">
                  {organisationslist?.map((org, idx) => {
                    if (org.hasPortalAccess === false) {
                      return (
                        <>
                          <div
                            className={
                              localStorage?.getItem("companyId") ===
                              org.company.id
                                ? "flex bg-green-400 text-white w-12 h-12 rounded-full text-md font-medium justify-center items-center mb-1 cursor-pointer"
                                : "flex bg-blue-400 text-white w-12 h-12 rounded-full text-md font-medium justify-center items-center mb-1 cursor-pointer"
                            }
                            data-tip={org.company.name}
                            onClick={() => {
                              setshowToast({
                                toast: true,
                                error: true,
                                message:
                                  "You do not have an access in this portal",
                              });
                              setTimeout(() => {
                                setshowToast({
                                  toast: false,
                                  error: null,
                                  message: "",
                                });
                              }, 2500);
                            }}
                          >
                            <p className="font-medium">{org.company.name[0]}</p>
                          </div>
                          <ReactTooltip className={hbaFontClass} />
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div
                            className={
                              companyId === org.company.id
                                ? "flex bg-green-400 text-white w-12 h-12 rounded-full text-md font-medium justify-center items-center mb-1 cursor-pointer"
                                : "flex bg-blue-400 text-white w-12 h-12 rounded-full text-md font-medium justify-center items-center mb-1 cursor-pointer"
                            }
                            data-tip={org.company.name}
                            onClick={() => {
                              selectCompany(
                                org.company.id,
                                org.company.name,
                                org.userType,
                                org.userCategory
                              );
                            }}
                          >
                            <p className="font-medium">{org.company.name[0]}</p>
                          </div>
                          <ReactTooltip className={hbaFontClass} />
                        </>
                      );
                    }
                  })}
                  <>
                    <div
                      className={
                        "flex bg-gray-600 text-white w-12 h-12 rounded-full text-md font-medium justify-center items-center mb-1 cursor-pointer"
                      }
                      data-tip={"Create New"}
                      onClick={() => {
                        setShowPortals(false);
                        setshowcreateportal(true);
                      }}
                    >
                      +
                    </div>
                    <ReactTooltip className={hbaFontClass} />
                  </>
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center"
              data-tip={`${userInfo?.firstName} ${userInfo?.lastName}`}
              data-for="user"
            >
              {userInfo?.profilePicture === "null" ||
              userInfo?.profilePicture === null ? (
                <>{assigneeInitials.toUpperCase()}</>
              ) : (
                <img
                  alt={`${userInfo?.firstName} ${userInfo?.lastName}`}
                  className="rounded-full w-8 h-8"
                  src={profilePic}
                />
              )}
            </div>
            <ReactTooltip
              id="user"
              effect="solid"
              place="right"
              className={hbaFontClass}
            />

            {/* {userInfo && (
              <div className="avatar-grid-collapsed">
                <div
                  className="avatar"
                  data-tip={`${userInfo.firstName} ${userInfo.lastName}`}
                >
                  {assigneeInitials.toUpperCase()}
                </div>
              </div>
            )} */}
          </div>
        </div>
        {sidebar && (
          <Sidebar
            showSidebar={showSidebar}
            userInfo={userInfo}
            clickLogout={clickLogout}
          />
        )}
        {showToast.toast &&
          (showToast.error ? (
            <ToastNotification
              title={showToast.message}
              error={showToast.error}
            />
          ) : (
            <ToastNotification title={showToast.message} />
          ))}

        {showcreateportal && (
          <CreatePortalModal
            close={() => setshowcreateportal(false)}
            setshowToast={(e) => setshowToast(e)}
            organisationslist={organisationslist}
            setIsInitializingPortal={setIsInitializingPortal}
          />
        )}
      </IconContext.Provider>
    </>
  );
}

export default MinimizedBar;
