import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
// import CreatableSelect from "react-select/creatable";
// import ToastNotification from "../toast-notification";
import { API } from "aws-amplify";
import "../../assets/styles/AccountSettings.css";
import Select from "react-dropdown-select";
import { AiOutlineTags } from "react-icons/ai";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { CgChevronLeft } from "react-icons/cg";

import AddLabelModal from "./add-label-modal";
import EditLabelModal from "./edit-label-modal";
import RemoveLabelModal from "./remove-label-modal";
import RemoveLabelModalNew from "./remove-label-modal-new";
import Button from "../../shared/Button";
import SearchBar from "./search-bar";
import NoResultState from "../no-result-state";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import { HiOutlineTrash } from "react-icons/hi";
import Switch from "./switch";

export default function Labels() {
  const { matter_id } = useParams();
  let history = useHistory();

  var moment = require("moment");

  useEffect(() => {
    getBriefs(); //Briefs==labels
    getUserTypes(); //LEGAL ADMIN, OWNER, ETCH
  }, []);

  const contentDiv = {
    marginLeft: "65px",
  };

  const mainGrid = {
    display: "grid",
    gridtemplatecolumn: "1fr auto",
  };

  const [showAddLabelModal, setshowAddLabelModal] = useState(false);
  const [showEditLabelModal, setshowEditLabelModal] = useState(false);

  const [showRemoveLabelModal, setshowRemoveLabelModal] = useState(false);
  const [labels, setLabels] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [searchLabels, setSearchLabels] = useState();

  const [labelName, setLabelName] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const listBriefs = `
    query getBriefsByClientMatter($id: ID) {
      clientMatter(id: $id) {
        briefs {
          items {
            id
            name
            hasCommentAccess
            userTypeAccess
            date
            order
            createdAt
            createdBy {
              id
              firstName
              lastName
            }
            updatedAt
            updatedBy {
              id
              firstName
              lastName
            }
            backgrounds(limit: 50, sortOrder: ORDER_ASC) {
              items {
                description
              }
            }

          }
        }
      }
    }
    `;

  const mCreateLabel = `
      mutation createLabel($clientMatterId: String, $name: String) {
          labelCreate(clientMatterId:$clientMatterId, name:$name) {
              id
              name
          }
      }
    `;

  const mCreateBrief = `
    mutation MyMutation($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
      briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
        id
        name
        date
        createdAt
      }
    }
    `;

  const mAddUserAcces = `
    mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
      briefAccessTypeTag(briefId:$briefId, userType:$userType) {
        id
      }
    }
    `;

  const mUpdateBriefCommentAccess = `mutation updateBriefComments($id: ID, $hasCommentAccess: Boolean) {
      briefUpdate(id: $id, hasCommentAccess: $hasCommentAccess){
        id
      }
    }`;

  //add user acc
  const handleAddUserAccess = async (e, dataId, dataInfo) => {
    console.log("event", e);
    console.log("dataInfo", dataInfo);
    var bId = dataId;
    var temp = [];

    e.map((item) => (temp = [...temp, item.label]));

    const params = {
      query: mAddUserAcces,
      variables: {
        briefId: bId,
        userType: temp,
      },
    };

    await API.graphql(params).then((ua) => {
      console.log("uaa", ua);
    });
  };

  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  //get default val
  const defaultUserTypeVal = (data) => {
    const labelsData = data.userTypeAccess;
    if (labelsData !== null || labelsData.length === 0) {
      let labelsDataUnique = labelsData.filter(onlyUnique);

      var temp = [];
      labelsDataUnique.map((item) =>
        item !== null ? (temp = [...temp, { label: item, value: item }]) : temp
      );
      if (
        !temp.some((labels) => labels.label.includes("LEGALADMIN")) &&
        !temp.some((labels) => labels.label.includes("LAWYER"))
      ) {
        temp = [
          ...temp,
          { label: "LEGALADMIN", value: "LEGALADMIN" },
          { label: "LAWYER", value: "LAWYER" },
        ];
        handleAddUserAccess(temp, data.id, data);
      }
      return temp;
    } else {
      return [];
    }
  };

  const getBriefs = async () => {
    console.log("matterid", matter_id);
    const params = {
      query: listBriefs,
      variables: {
        id: matter_id,
      },
    };

    await API.graphql(params).then((brief) => {
      const labelsList = brief.data.clientMatter.briefs.items;
      console.log("allitems", labelsList);
      setLabels(labelsList);
    });
  };

  //GET USER TYPES
  const qListUserTypes = `
      query getDefaultUserTypes {
      defaultUserType
    }
    `;

  const getUserTypes = async () => {
    const params = {
      query: qListUserTypes,
    };

    await API.graphql(params).then((userTypes) => {
      if (userTypes.data.defaultUserType) {
        var prev = [];
        userTypes.data.defaultUserType.map((userType) => {
          let oUserType = {
            value: userType,
            label: userType,
          };

          if (oUserType.label === "OWNER") {
            prev = [...prev];
          } else {
            prev = [...prev, oUserType];
          }

          // setUserTypes((prev) => [...prev, oUserType]);
          setUserTypes(prev);
        });
      }
    });
  };

  const handleModalClose = () => {
    setshowAddLabelModal(false);
    setshowEditLabelModal(false);
    setshowRemoveLabelModal(false);
  };

  const handleAddLabel = () => {
    handleModalClose();
  };

  const handleEditLabel = () => {
    handleModalClose();
  };

  const handleRemoveLabel = () => {
    handleModalClose();
  };

  const handleDelete = (id) => {
    /* if(window.confirm("Are you sure you want to remove this label?")){
        const mSoftDeleteBrief = `
        mutation softDeleteBriefById($id: ID) {
          briefSoftDelete(id: $id) {
            id
          }
        }

        `;

        const deletedId = API.graphql({
          query: mSoftDeleteBrief,
          variables: {
            id: id,
          },
        });

        var temp = labels.filter((item)=>item.id !== id);
        setLabels(temp);
      } */

    const mSoftDeleteBrief = `
        mutation softDeleteBriefById($id: ID) {
          briefSoftDeleteOrRestore(id: $id, isDeleted: true)
        }

        `;

    API.graphql({
      query: mSoftDeleteBrief,
      variables: {
        id: id,
      },
    });

    var temp = labels.filter((item) => item.id !== id);
    setLabels(temp);

    handleModalClose();
  };

  const handleChangeLabelName = (e) => {
    console.log("changed to", e.currentTarget.textContent);
    setLabelName(e.currentTarget.textContent);
  };

  const handleSaveLabelName = async (e, name, id) => {
    const mUpdateBriefName = `mutation updateBriefName($id: ID, $name: String) {
        briefUpdate(id: $id, name: $name) {
          id
        }
      }`;

    let ln = labelName;

    if (labelName.length === 0) {
      alert("Please Input Label Name. Changes Will not be saved");
    } else {
      const request = await API.graphql({
        query: mUpdateBriefName,
        variables: {
          id: id,
          name: ln,
        },
      });

      console.log("success", request);
    }
  };

  const handleSaveBrief = async (briefname) => {
    console.log("matterid", matter_id);
    console.log("briefname", briefname);

    let counter = 1;

    labels.map((brief) => {
      const brName = brief.name.split("(")[0];

      if (brName.trim().toLowerCase() === briefname.trim().toLowerCase()) {
        counter++;

        console.log(counter, " - ", brief.name);
      }
    });

    if (counter !== 1) {
      briefname = briefname + " (" + counter + ")";
      //console.log(briefname);
    }

    // alert(briefname);
    const params = {
      query: mCreateBrief,
      variables: {
        clientMatterId: matter_id,
        name: briefname,
        date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
        order: 0,
        columnIds: [0, 1, 2, 3, 4],
      },
    };

    await API.graphql(params).then((brief) => {
      const getID = brief.data.briefCreate.id;

      const autoTagUserAccess = API.graphql({
        query: mAddUserAcces,
        variables: {
          briefId: getID,
          userType: ["LEGALADMIN", "LAWYER"],
        },
      });

      // Add new labls when brief is created
      const createLabel = API.graphql({
        query: mCreateLabel,
        variables: {
          clientMatterId: matter_id,
          name: briefname,
        },
      });

      console.log("createLabel", createLabel);
      if (createLabel.data == null) {
        console.error("Failed to create label");
      }
      console.groupEnd();

      handleModalClose();
      setTimeout(() => {
        getBriefs();
      }, 3000);
    });
  };

  useEffect(() => {
    if (searchLabels !== undefined) {
      filterLabels(searchLabels);
    }
  }, [searchLabels]);

  const handleSearch = (e) => {
    console.log(e);
    setSearchLabels(e);
  };

  const filterLabels = (v) => {
    if (v === "") {
      getBriefs();
    } else {
      const filterRecord = labels.filter((x) => {
        return x.name?.toLowerCase().includes(v.toLowerCase());
      });
      setLabels(filterRecord);
    }
  };

  const updateCommentAccess = async (id, isChecked) => {
    console.log(isChecked);
    await API.graphql({
      query: mUpdateBriefCommentAccess,
      variables: {
        id: id,
        hasCommentAccess: isChecked,
      },
    }).then((res) => {
      console.log(res);
    });
  };

  const { height, width } = useWindowDimensions();

  return (
    <>
      <div
        className={
          "p-5 relative flex flex-col min-w-0 break-words shadow-lg rounded bg-gray-100 sm:bg-white"
        }
        style={
          !isMobileDevice(width)
            ? contentDiv
            : {
                marginLeft: "0px",
              }
        }
      >
        <div
          className="flex flex-col"
          style={{
            paddingLeft: isMobileDevice(width) ? "20px" : "0",
          }}
        >
          <div
            className="sticky py-4 flex items-center gap-2  z-10"
            style={{
              paddingBottom: isMobileDevice(width) ? "0" : "20px",
            }}
          >
            <div
              onClick={() => history.goBack()}
              className="w-8 py-5 cursor-pointer"
            >
              <CgChevronLeft
                style={{
                  visibility: isMobileDevice(width) ? "hidden" : "visible",
                  pointerEvents: isMobileDevice(width) ? "none" : "auto",
                }}
              />
            </div>
            <div className="w-full">
              <p
                className="flex flex-col w-full text-right"
                style={{
                  textAlign: isMobileDevice(width) ? "right" : "left",
                }}
              >
                <span className="text-lg font-bold">Manage User Access</span>
              </p>
              <div className="p-2 left-0">
                <div
                  style={{
                    display: isMobileDevice(width) ? "none" : "block",
                  }}
                >
                  <span className="text-sm font-medium inline-flex -ml-3 ">
                    <AiOutlineTags /> &nbsp; MANAGE LABELS
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobileDevice(width) ? (
          <div className="pb-2 flex flex-row">
            {" "}
            {/* MOBILE VIEW */}
            <Button
              onClick={() => setshowAddLabelModal(true)}
              variant="secondary-l"
              className="font-medium h-10 mr-1 justify-center items-center inline-flex flex-1 py-1 px-1 rounded-md text-md border-solid border font-medium shadow-md focus:ring focus:no-outline transition-colors text-secondary border-secondary bg-secondary-l  hover:text-secondary-l hover:bg-secondary focus:ring-secondary py-2 px-4 text-xl font-semibold"
            >
              +
            </Button>
            <SearchBar className="w-full" handleChange={handleSearch} />
          </div>
        ) : (
          <div className="p-1 left-0 py-4">
            {" "}
            {/* DESKTOP VIEW */}
            <div className="grid grid-rows grid-flow-col">
              <div className="col-span-7 ml-1">
                {/* <button
                        // onClick={handleAddRow}
                        type="button"
                        className="bg-green-100 hover:bg-green-100 text-green-500 text-sm py-1 px-4 rounded inline-flex items-center border border-green-500 shadow focus:ring mx-2"
                        onClick={() => setshowAddLabelModal(true)}
                    >
                        ADD NEW LABEL &nbsp; <HiOutlinePlusCircle/>
                    </button> */}
                <Button
                  onClick={() => setshowAddLabelModal(true)}
                  variant="secondary-l"
                  className="py-1 px-4 mx-2"
                >
                  ADD NEW LABEL &nbsp; <HiOutlinePlusCircle />
                </Button>
              </div>
              <SearchBar handleChange={handleSearch} />
            </div>
          </div>
        )}
        {/* BODY */}
        {labels.length !== 0 &&
        (searchLabels !== undefined || searchLabels !== "") ? (
          isMobileDevice(width) ? (
            <div className="w-full h-full bg-white rounded-lg py-5">
              {" "}
              {/* MOBILE VIEW */}
              <div className="overflow-y-auto" style={{ height: height - 203 }}>
                {labels.map((data) => (
                  <div
                    className="m-5 mt-0 p-5 bg-gray-100 rounded-lg text-gray-800"
                    id={data.id}
                  >
                    <div className="font-medium flex justify-between w-full">
                      <div className="w-2/3">
                        <p>
                          <span
                            suppressContentEditableWarning={true}
                            style={{
                              cursor: "auto",
                              outlineColor: "rgb(204, 204, 204, 0.5)",
                              outlineWidth: "thin",
                            }}
                            // data-info={item.id}
                            // onMouseOver={handleMouseOver}
                            // onMouseOut={handleMouseOut}
                            // onClick={(e) =>
                            //   handleNameContent(e, item.name, item.id)
                            // }
                            contentEditable={true}
                            tabIndex="0"
                            onInput={(e) => handleChangeLabelName(e)}
                            onBlur={(e) =>
                              handleSaveLabelName(e, data.name, data.id)
                            }
                            className="inline items-center max-w-sm focus:outline-none text-gray-800 dark:text-gray-100 mb-1"
                            dangerouslySetInnerHTML={{
                              __html: data.name,
                            }}
                          />
                        </p>
                      </div>

                      <Button
                        onClick={() => {
                          setDeleteId(data.id);
                          setshowRemoveLabelModal(true);
                        }}
                        className={
                          data.name === "BACKGROUND" ||
                          data.name === "Background"
                            ? "hidden"
                            : "py-1 px-4 mx-2"
                        }
                        variant="danger-l"
                      >
                        <HiOutlineTrash className="mr-1" />
                      </Button>
                    </div>
                    <p className="text-cyan-400 font-medium">
                      User type access:
                    </p>
                    {/* <CreatableSelect
                      defaultValue={() => defaultUserTypeVal(data)}
                      menuPlacement={"bottom"}
                      isMulti
                      isClearable
                      options={userTypes}
                      isSearchable
                      openMenuOnClick={true}
                      onChange={(e) => handleAddUserAccess(e, data.id, data)}
                      placeholder="Select User Type"
                      className="w-full placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring z-100"
                    /> */}
                    <Select
                      values={defaultUserTypeVal(data)}
                      placement="bottom"
                      color="#8a8a8a"
                      multi
                      clearable
                      options={userTypes}
                      searchable
                      keepSelectedInList={true}
                      onChange={(selectedOption) =>
                        handleAddUserAccess(selectedOption, data.id, data)
                      }
                      placeholder="Select User Type"
                      className="w-full placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring z-100"
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="p-5 left-0">
              {" "}
              {/* DESKTOP VIEW */}
              <table className="min-w-full divide-y divide-gray-200 border flex-1">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-5 text-left text-xs font-medium text-gray-500 tracking-wider w-2/6"
                    >
                      Labels
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-5 text-left text-xs font-medium text-gray-500 tracking-wider w-2/6"
                    >
                      User Type Access
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-5 text-left text-xs font-medium text-gray-500 tracking-wider w-2/6 hidden"
                    >
                      Turn On Comments Column
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-5 text-left text-xs font-medium text-gray-500 tracking-wider w-1/6"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {labels.map((data, index) => (
                    <tr key={data.id}>
                      <td className="px-6 py-4 whitespace-nowrap w-98">
                        {/* <p className="font-semibold">{data.name}</p> */}
                        <div className="">
                          <p
                            suppressContentEditableWarning={true}
                            style={{
                              cursor: "auto",
                              outlineColor: "rgb(204, 204, 204, 0.5)",
                              outlineWidth: "thin",
                            }}
                            // data-info={item.id}
                            // onMouseOver={handleMouseOver}
                            // onMouseOut={handleMouseOut}
                            // onClick={(e) =>
                            //   handleNameContent(e, item.name, item.id)
                            // }
                            contentEditable={true}
                            tabIndex="0"
                            onInput={(e) => handleChangeLabelName(e)}
                            onBlur={(e) =>
                              handleSaveLabelName(e, data.name, data.id)
                            }
                            className="hidden sm:inline-flex items-center focus:outline-none text-gray-800 dark:text-gray-100 font-bold mb-1 w-96 max-w-md"
                            dangerouslySetInnerHTML={{
                              __html: data.name,
                            }}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap w-20">
                        {/* <CreatableSelect
                          defaultValue={() => defaultUserTypeVal(data)}
                          menuPlacement={"bottom"}
                          isMulti
                          isClearable
                          options={userTypes}
                          isSearchable
                          openMenuOnClick={true}
                          onChange={(e) =>
                            handleAddUserAccess(e, data.id, data)
                          }
                          placeholder="Select User Type"
                          className="w-full placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring z-100"
                        /> */}
                        <Select
                          color="#8a8a8a"
                          values={defaultUserTypeVal(data)}
                          placement="bottom"
                          multi
                          clearable
                          options={userTypes}
                          searchable
                          closeOnSelect={false}
                          onChange={(selectedOption) =>
                            handleAddUserAccess(selectedOption, data.id, data)
                          }
                          placeholder="Select User Type"
                          className="w-full placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring z-100"
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap w-20 hidden">
                        <Switch
                          row_index={index}
                          row_id={data.id}
                          defaultAccess={data.hasCommentAccess}
                          updateCommentAccess={updateCommentAccess}
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap w-20">
                        {/* <button
                                    type="button"
                                    className={data.name === "BACKGROUND" || data.name === "Background" ? "hidden" : "bg-red-100 hover:bg-red-100 text-red-500 text-sm py-1 px-4 rounded-3xl inline-flex items-center border-0 shadow focus:ring mx-2"}
                                    onClick={() => handleDelete(data.id)}
                                >
                                    REMOVE
                                </button> */}
                        <Button
                          onClick={() => {
                            setDeleteId(data.id);
                            setshowRemoveLabelModal(true);
                          }}
                          className={
                            data.name === "BACKGROUND" ||
                            data.name === "Background"
                              ? "hidden"
                              : "py-1 px-4 mx-2"
                          }
                          variant="danger-l"
                        >
                          REMOVE
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : (
          <div className="p-5 px-5 py-1 left-0">
            <div className="w-full h-42 mb-6 py-1 px-1 grid justify-items-center">
              <NoResultState
                searchKey={searchLabels}
                message={
                  "Check the spelling, try a more general term or look up a specific File."
                }
              />
            </div>
          </div>
        )}
      </div>

      {showAddLabelModal && (
        <AddLabelModal
          handleSave={handleSaveBrief}
          handleModalClose={handleModalClose}
          labels={labels}
        />
      )}

      {showEditLabelModal && (
        <EditLabelModal
          handleSave={handleEditLabel}
          handleModalClose={handleModalClose}
        />
      )}

      {showRemoveLabelModal && (
        <RemoveLabelModalNew
          handleSave={() => handleDelete(deleteId)}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
}
