import { useEffect } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
const CircularChart = ({
  type,
  totalItems = 0,
  total = 0,
  within = 0,
  exceeded = 0,
  width,
}) => {
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const randomizeChartId = randomIntFromInterval(0, 1000);

  useEffect(() => {
    console.log(
      "Type: ",
      type,
      "Total Items: ",
      totalItems,
      "Total: ",
      total,
      "Within: ",
      within,
      "Exceed: ",
      exceeded,
      width
    );
  }, []);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const formatAmountTo3Digits = (amount) => {
    let formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(amount);

    let split = formatted.split(",");
    var symbol;
    switch (split.length) {
      case 0:
        symbol = "";
        break;
      case 1:
        symbol = "";
        break;
      case 2:
        symbol = "K";
        break;
      case 3:
        symbol = "M";
        break;
      case 4:
        symbol = "B";
        break;
      case 5:
        symbol = "T";
        break;
      default:
        symbol = ` × 10 ^${(split.length - 1) * 3}`;
    }

    return `${split[0]}${symbol}`;
  };

  const GradientSVG = ({ startColor, endColor, idCSS, rotation }) => {
    let gradientTransform = `rotate(${rotation})`;

    return (
      <svg style={{ height: 0, width: 0 }}>
        <defs>
          <linearGradient id={idCSS} gradientTransform={gradientTransform}>
            <stop offset="0%" stopColor={startColor} />
            <stop offset="100%" stopColor={endColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  return width > 844 ? (
    <>
      {/* DESKTOP VIEW OF BUDGET */}
      <div className="px-2 m-auto" style={{ maxWidth: "400px" }}>
        {/* GRADIENT COMPONENTS */}
        <GradientSVG
          startColor="#F49062"
          endColor="#FD371F"
          idCSS={`${randomizeChartId.toString()}_outercircle`}
          rotation={45}
        />
        <GradientSVG
          startColor="#FFD439"
          endColor="#FF7A00"
          idCSS={`${randomizeChartId.toString()}_middlecircle`}
          rotation={45}
        />
        <GradientSVG
          startColor="#43CBFF"
          endColor="#9708CC"
          idCSS={`${randomizeChartId.toString()}_innercircle`}
          rotation={45}
        />
        <CircularProgressbarWithChildren
          value={totalItems > 0 ? exceeded : 0}
          maxValue={totalItems > 0 ? totalItems : 1}
          styles={buildStyles({
            pathColor: `url(#${randomizeChartId.toString()}_outercircle)`,
            trailColor: "rgb(243 244 246)",
          })}
        >
          {/* CIRCULAR CHART COMPONENTS */}
          <div style={{ padding: "9%" }} className="w-full">
            <CircularProgressbarWithChildren
              value={totalItems > 0 ? within : 0}
              maxValue={totalItems > 0 ? totalItems : 1}
              styles={buildStyles({
                pathColor: `url(#${randomizeChartId.toString()}_middlecircle)`,
                trailColor: "rgb(243 244 246)",
              })}
            >
              <div style={{ padding: "9.4%" }} className="w-full">
                <CircularProgressbarWithChildren
                  value={totalItems > 0 ? totalItems : 0}
                  maxValue={totalItems > 0 ? totalItems : 1}
                  // text={`${paidInvoices}/${background?.items?.length}`}
                  styles={buildStyles({
                    pathColor: `url(#${randomizeChartId.toString()}_innercircle)`,
                    trailColor: "rgb(243 244 246)",
                  })}
                >
                  <div className="flex flex-col text-center gap-0">
                    <span className="font-semibold" style={{ fontSize: 18 }}>
                      {formatAmount(total)}
                    </span>
                    <span className="px-4 text-center" style={{ fontSize: 10 }}>
                      Total{" "}
                      {type === "Budget"
                        ? "Budget"
                        : type === "Provisional Sum"
                        ? "Approved Provisional"
                        : type === "Prime Cost"
                        ? "Allowance"
                        : ""}
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </CircularProgressbarWithChildren>
      </div>

      {/* TILES COMPONENTS */}
      <div
        className="grid w-full gap-x-1 pt-4 auto-rows-fr"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(130px, 1fr))",
        }}
      >
        <div className="leftSide flex gap-2">
          <div className="group flex w-full flex-col">
            <div
              className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2"
              style={{
                minwidth: "5.5em",
                maxWidth: "8.5em",
              }}
            >
              <div
                className=" w-1/2 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #F97794, #623AA2)",
                }}
              />
              <p className="font-medium text-sm">{totalItems}</p>
              <p
                className="font-normal  block m-auto truncate text-gray-600"
                style={{
                  minWidth: "20%",
                  fontSize: "0.6vw",
                  maxWidth: "83%",
                }}
              >
                Total
                <br />
                Items
              </p>
            </div>

            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #F97794, #623AA2)",
              }}
            />
          </div>

          <div className="group flex w-full flex-col">
            <div
              className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2"
              style={{
                minwidth: "5.5em",
                maxWidth: "8.5em",
              }}
            >
              <div
                className=" w-1/2 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #43CBFF, #9708CC)",
                }}
              />
              <p className="font-medium text-sm">
                {formatAmountTo3Digits(total)}
              </p>
              <p
                className="font-normal  block m-auto truncate text-gray-600"
                style={{
                  minWidth: "20%",
                  fontSize: "0.6vw",
                  maxWidth: "83%",
                }}
              >
                Total
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #43CBFF, #9708CC)",
              }}
            />
          </div>
        </div>
        <div className="rightSide flex gap-2">
          <div className="group flex w-full flex-col">
            <div
              className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2"
              style={{
                minwidth: "5.5em",
                maxWidth: "8.5em",
              }}
            >
              <div
                className=" w-1/2 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #FFD439, #FF7A00)",
                }}
              />
              <p className="font-medium text-sm">{within}</p>
              <p
                className="font-normal  block m-auto truncate text-gray-600"
                style={{
                  minWidth: "20%",
                  fontSize: "0.6vw",
                  maxWidth: "83%",
                }}
              >
                Within
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #FFD439, #FF7A00)",
              }}
            />
          </div>
          <div className="group flex w-full flex-col">
            <div
              className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2"
              style={{
                minwidth: "5.5em",
                maxWidth: "8.5em",
              }}
            >
              <div
                className=" w-1/2 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #F49062, #FD371F)",
                }}
              />
              <p className="font-medium text-sm">{exceeded}</p>
              <p
                className="font-normal  block m-auto truncate text-gray-600"
                style={{
                  minWidth: "20%",
                  fontSize: "0.6vw",
                  maxWidth: "83%",
                }}
              >
                Exceeded
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #F49062, #FD371F)",
              }}
            />
          </div>
        </div>
      </div>

      {/* <div
        className="grid w-full gap-x-1 pt-4 auto-rows-fr	"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(130px, 1fr))",
        }}
      >
        <div className="flex gap-1">
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #F97794, #623AA2)",
                }}
              />
              <p className="font-medium text-sm">{totalItems}</p>
              <p className="font-normal text-xs text-gray-600">
                Total
                <br />
                Items
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #F97794, #623AA2)",
              }}
            />
          </div>
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #43CBFF, #9708CC)",
                }}
              />
              <p className="font-medium text-sm">
                {formatAmountTo3Digits(total)}
              </p>
              <p className="font-normal text-xs text-gray-600">
                Total
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #43CBFF, #9708CC)",
              }}
            />
          </div>
        </div>
        <div className="flex gap-1">
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #FFD439, #FF7A00)",
                }}
              />
              <p className="font-medium text-sm">{within}</p>
              <p className="font-normal text-xs text-gray-600">
                Within
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #FFD439, #FF7A00)",
              }}
            />
          </div>
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #F49062, #FD371F)",
                }}
              />
              <p className="font-medium text-sm">{exceeded}</p>
              <p className="font-normal text-xs text-gray-600">
                Exceeded
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #F49062, #FD371F)",
              }}
            />
          </div>
        </div>
      </div> */}
    </>
  ) : (
    <>
      {/* MOBILE VIEW OF BUDGET */}
      <div className="w-full px-5">
        {/* GRADIENT COMPONENTS */}
        <GradientSVG
          startColor="#F49062"
          endColor="#FD371F"
          idCSS="budget_outercircle"
          rotation={45}
        />
        <GradientSVG
          startColor="#FFD439"
          endColor="#FF7A00"
          idCSS="budget_middlecircle"
          rotation={45}
        />
        <GradientSVG
          startColor="#43CBFF"
          endColor="#9708CC"
          idCSS="budget_innercircle"
          rotation={45}
        />
        <CircularProgressbarWithChildren
          value={totalItems > 0 ? exceeded : 0}
          maxValue={totalItems > 0 ? totalItems : 1}
          styles={buildStyles({
            pathColor: "url(#budget_outercircle)",
            trailColor: "rgb(243 244 246)",
          })}
        >
          {/* CIRCULAR CHART COMPONENTS */}
          <div style={{ padding: "9%" }} className="w-full">
            <CircularProgressbarWithChildren
              value={totalItems > 0 ? within : 0}
              maxValue={totalItems > 0 ? totalItems : 1}
              styles={buildStyles({
                pathColor: "url(#budget_middlecircle)",
                trailColor: "rgb(243 244 246)",
              })}
            >
              <div style={{ padding: "9.4%" }} className="w-full">
                <CircularProgressbarWithChildren
                  value={totalItems > 0 ? totalItems : 0}
                  maxValue={totalItems > 0 ? totalItems : 1}
                  // text={`${paidInvoices}/${background?.items?.length}`}
                  styles={buildStyles({
                    pathColor: "url(#budget_innercircle)",
                    trailColor: "rgb(243 244 246)",
                  })}
                >
                  <div className="flex flex-col text-center gap-0">
                    <span className="font-semibold text-lg">
                      {formatAmount(total)}
                    </span>
                    <span className="px-4 text-center text-base">
                      Total{" "}
                      {type === "Budget"
                        ? "Budget"
                        : type === "Provisional Sum"
                        ? "Approved Provisional"
                        : type === "Prime Cost"
                        ? "Allowance"
                        : ""}
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </CircularProgressbarWithChildren>
      </div>

      {/* TILES COMPONENTS */}
      <div
        className="grid w-full gap-x-3 pt-4 auto-rows-fr	"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
        }}
      >
        <div className="flex gap-3">
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #F97794, #623AA2)",
                }}
              />
              <p className="font-open-sans font-medium text-lg">{totalItems}</p>
              <p className="font-open-sans font-normal text-sm text-gray-600">
                Total
                <br />
                Items
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #F97794, #623AA2)",
              }}
            />
          </div>
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #43CBFF, #9708CC)",
                }}
              />
              <p className="font-open-sans font-medium text-lg">
                {formatAmountTo3Digits(total)}
              </p>
              <p className="font-open-sans font-normal text-sm text-gray-600">
                Total
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Approved Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #43CBFF, #9708CC)",
              }}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #FFD439, #FF7A00)",
                }}
              />
              <p className="font-open-sans font-medium text-lg">{within}</p>
              <p className="font-open-sans font-normal text-sm text-gray-600">
                Within
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Approved Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #FFD439, #FF7A00)",
              }}
            />
          </div>
          <div className="group w-full flex flex-col">
            <div className="flex-grow bg-gray-100 group-hover:bg-gray-200 rounded-lg text-center flex flex-col py-2 px-2">
              <div
                className="w-7 block mx-auto rounded-xl h-2 mb-2"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #F49062, #FD371F)",
                }}
              />
              <p className="font-open-sans font-medium text-lg">{exceeded}</p>
              <p className="font-open-sans font-normal text-sm text-gray-600">
                Exceeded
                <br />
                {type === "Budget"
                  ? "Budget"
                  : type === "Provisional Sum"
                  ? "Approved Provisional"
                  : type === "Prime Cost"
                  ? "Allowance"
                  : ""}
              </p>
            </div>
            <div
              className="opacity-0 group-hover:opacity-100 w-full rounded-xl h-2 mt-1"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #F49062, #FD371F)",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CircularChart;
