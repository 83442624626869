import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useForm,
} from "react";
import { API } from "aws-amplify";
import { BiPencil } from "react-icons/bi";
import { IoIosSearch } from "react-icons/io";
import ReactDatePicker from "react-datepicker";
import { AiOutlinePlus } from "react-icons/ai";
import DatePicker from "react-datepicker";
import Button from "../../shared/Button";
import { CgCheck, CgClose } from "react-icons/cg";
import { AiFillFolderAdd } from "react-icons/ai";
import {
  datePickerDateFormatter,
  dateFormatter,
  timezoneFormatter,
} from "../../shared/userPreferredDateTime";
import Select, { components } from "react-select";
// import Select from "react-select/creatable";

const SENDER_BY_COMPANY_QUERY = `query getEmailSenderByCompany($companyId: String, $email: String) {
  gmailMessages(companyId: $companyId, email: $email) {
    from
  }
}`;

const RECIPIENT_BY_COMPANY_QUERY = `query getEmailSenderByCompany($companyId: String, $email: String) {
  gmailMessages(companyId: $companyId, email: $email) {
    recipient
    to
    cc
    bcc
  }
}`;

const SENDER_BY_EMAIL_OUTLOOK_QUERY = `query SENDER_BY_EMAIL_OUTLOOK_QUERY($email: String!) {
  outlookMessages(email: $email) {
    from
  }
}`;

const RECIPIENT_BY_EMAIL_OUTLOOK_QUERY = `query RECIPIENT_BY_EMAIL_OUTLOOK_QUERY($email: String!) {
  outlookMessages(email: $email) {
    to
    cc
    bcc
  }
}`;

const companyId = localStorage.getItem("companyId");

const InboxFilter = (props) => {
  const [startDate, setStartDate] = useState(
    props.currentFilter.startDate || null
  );
  const [endDate, setEndDate] = useState(props.currentFilter.endDate || null);
  const [invalidState, setInvalidState] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [searchSubject, setSearchSubject] = useState(
    props.currentFilter.subject || ""
  );
  const connectedEmail = props.connectedEmail;

  //Sender Filter
  const [searchSender, setSearchSender] = useState(
    props.currentFilter.sender || ""
  );
  const [selectedSender, setSelectedSender] = useState(null);
  const [senderList, setSenderList] = useState([]);

  //Recipient Filter
  const [searchRecipient, setSearchRecipient] = useState(
    props.currentFilter.recipient || ""
  );
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [recipientList, setRecipientList] = useState([]);

  const [searchHasAttachments, setSearchHasAttachments] = useState(
    props.currentFilter.hasAttachments || ""
  );

  //Date Range Filter
  const [oneMonth, setOneMonth] = useState();
  const [oneDay, setOneDay] = useState();
  const [threeDays, setThreeDays] = useState();
  const [sevenDays, setSevenDays] = useState();

  /*
      const [searchContent, setSearchContent] = useState("");
      const [searchAttachment, setSearchAttachment] = useState("");
      */

  useEffect(() => {
    /* Sets initial values of dateOptions */
    let dependentTime = new Date(startDate);
    let forMonth = new Date(startDate);
    setOneDay(new Date(dependentTime.setDate(dependentTime.getDate())));
    setThreeDays(new Date(dependentTime.setDate(dependentTime.getDate() + 3)));
    setSevenDays(new Date(dependentTime.setDate(dependentTime.getDate() + 4)));
    setOneMonth(new Date(forMonth.setMonth(forMonth.getMonth() + 1)));
  }, []);

  const [isSelectedDate, setIsSelectedDate] = useState(false);

  /* Instantiate options for 'To' dropdown */
  const dateOptions = [
    { value: oneDay, label: "Today" },
    { value: threeDays, label: "3 Days" },
    { value: sevenDays, label: "7 Days" },
    { value: oneMonth, label: "1 Month" },
  ];

  /* Styling for our Select component */

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {props.isSelected ? (
        <p className="flex flex-row justify-between ">
          {props.data.label} <CgCheck className=" text-green-500" />
        </p>
      ) : (
        <p className="flex flex-row justify-between ">{props.data.label} </p>
      )}
    </Option>
  );

  const selectStyle = {
    option: (base, selectProps) => ({
      ...base,
      color: "black",
      backgroundColor: selectProps.isSelected
        ? "rgba(229, 231, 235)"
        : selectProps.isFocused && !selectProps.isSelected
        ? "rgb(243 244 246)"
        : base.backgroundColor,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(3600deg)",
      paddingRight: "0px",
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      fontSize: "0.72rem",
      color: "text-gray-500",
    }),
    control: (base, state) => ({
      ...base,
      "&:hover": {
        borderColor: "rgb(243 244 246)",
      },
      backgroundColor: state.isFocused ? "rgba(249, 250, 251)" : "white",
      boxShadow: "none",
      minHeight: "0px",
      borderWidth: "2px",
      width: "6.2rem",
      height: "1.75rem",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      borderRadius: "0.5rem",
      borderColor: "rgb(243 244 246)",
    }),
    placeholder: (base, state) => ({
      ...base,
      fontSize: "0.90rem",
      color: "text-gray-500",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 200,
      margin: 0,
      width: "6.2rem",
    }),
  };

  /* Checks if endDate is previously modified in the current filter */
  const isEndDateOccupied = () => {
    if (endDate) {
      /* Calculate the difference in days between the previous startDate and endDate */
      const oneDay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor((endDate - startDate) / oneDay);

      /* Returns previously selected preset */
      if (daysDifference === 0) return "Today";

      if (daysDifference === 3) return "3 Days";

      if (daysDifference === 7) return "7 Days";

      if (daysDifference > 25) return "1 Month";
    }
  };

  // useEffect(() => {
  //   handleSave();
  // },[endDate, startDate])

  const handleStartDateChange = (selected) => {
    if (endDate !== null) {
      /* Calculate the difference in days between the previous startDate and endDate */
      const oneDay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor((endDate - startDate) / oneDay);
      let newEndDate = new Date(selected.getTime());

      if (daysDifference > 7) newEndDate.setMonth(newEndDate.getMonth() + 1);
      else
        newEndDate = new Date(newEndDate.getTime() + daysDifference * oneDay);

      setEndDate(newEndDate);
    }

    /* Because .setMonth is needed and to avoid mutation
        and miscalculation, we must declare two of the same instance */
    const dependentTime = new Date(selected);
    const forMonth = new Date(selected);

    setStartDate(selected);

    /* Updates the options */
    setOneDay(new Date(dependentTime.setDate(dependentTime.getDate())));
    setThreeDays(new Date(dependentTime.setDate(dependentTime.getDate() + 3)));
    setSevenDays(new Date(dependentTime.setDate(dependentTime.getDate() + 4)));
    setOneMonth(new Date(forMonth.setMonth(forMonth.getMonth() + 1)));
  };

  const handleEndDateChange = (selected) => {
    setEndDate(selected);
  };

  //   const { handleSubmit } = useForm();

  const handleModalClose = () => {
    props.closeFiltersModal();
  };

  const handleClearFilter = () => {
    props.executeFilter(null);
  };

  const handleSave = () => {
    let filters = {
      startDate: startDate,
      endDate: endDate,
      subject: searchSubject,
      sender: searchSender,
      recipient: searchRecipient,
      hasAttachments: searchHasAttachments,
    };
    props.setMobileLoading(true);
    props.executeFilter(filters);
    setIsSelectedDate(false);
  };

  useEffect(() => {
    if (
      startDate !== null &&
      startDate !== "" &&
      endDate !== null &&
      endDate !== ""
    ) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        setInvalidDateRange(true);
        setInvalidState(true);
      } else {
        setInvalidDateRange(false);
        setInvalidState(false);
      }
    } else {
      setInvalidState(true);
    }
  }, [startDate, endDate, senderList, recipientList]);

  useEffect(() => {
    if (senderList.length === 0 && recipientList.length === 0) {
      if (props.isOutlookIntegrated) getOutlookEmail();
      else {
        getEmailSenderByCompany();
        getEmailRecipientByCompany();
      }
    }
  }, []);

  const getEmailSenderByCompany = async () => {
    const params = {
      query: SENDER_BY_COMPANY_QUERY,
      variables: {
        companyId: companyId,
        email: connectedEmail,
      },
    };

    const { data } = await API.graphql(params);

    if (data) {
      console.log("GMAIL SENDER", data);
      const arrSenders = data.gmailMessages;

      const uniqueFromItems = await extractUniqueSenderEmails(arrSenders);

      setSenderList(uniqueFromItems);

      const defaultSender = uniqueFromItems?.find(
        (item) => item.value === searchSender
      );

      setSelectedSender(defaultSender);
    }
  };

  const getEmailRecipientByCompany = async () => {
    const params = {
      query: RECIPIENT_BY_COMPANY_QUERY,
      variables: {
        companyId: companyId,
        email: connectedEmail,
      },
    };

    const { data } = await API.graphql(params);

    if (data) {
      console.log("GMAIL RECIPIENTS", data);
      const arrRecipients = data.gmailMessages;

      const uniqueRecipientItems = await extractUniqueRecipientEmails(
        arrRecipients
      );
      console.log("GMAIL RECIPIENTS UNIQUE", uniqueRecipientItems);
      setRecipientList(uniqueRecipientItems);

      const defaultRecipient = uniqueRecipientItems?.find(
        (item) => item.value === searchRecipient
      );

      setSelectedRecipient(defaultRecipient);
    }
  };

  function extractUniqueSenderEmails(emails) {
    if (!emails || emails.length === 0) return [];

    const uniqueEmails = {};

    emails.forEach((item) => {
      const from = item.from;
      if (from) {
        const email =
          from.match(
            /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
          )?.[0] || from;
        const name = from.match(/^(.*?)(?=\s<|\s*$)/)?.[1]?.trim() || email;

        uniqueEmails[email] = name.replace('"', "").replace(/['"]+/g, "");
      }
    });

    const result = Object.entries(uniqueEmails).map(([email, name]) => ({
      label: name === email ? name : `${name} <${email}>`,
      value: email,
    }));

    result.sort((a, b) => a.label.localeCompare(b.label));
    return result;
  }

  function extractUniqueRecipientEmails(emails) {
    if (!emails || emails.length === 0) return [];

    // CHECK IF LIST OF RECIPIENTS ARE IN OUTLOOK FORMAT
    const mergedRecipients = props.isOutlookIntegrated
      ? [
          ...new Set(
            emails.flatMap((obj) =>
              Object.values(obj)
                .flat()
                .filter((val) => typeof val === "string" && val.includes("@"))
            )
          ),
        ]
      : [
          ...new Set(
            emails.flatMap((obj) =>
              Object.values(obj).filter(
                (val) => typeof val === "string" && val.includes("@")
              )
            )
          ),
        ];

    const flatRecipients = mergedRecipients.flatMap((item) => item.split(","));
    const uniqueEmails = {};

    flatRecipients.forEach((item) => {
      const em = item;
      if (em) {
        const email =
          em.match(
            /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
          )?.[0] || em;
        const name = em.match(/^(.*?)(?=\s<|\s*$)/)?.[1]?.trim() || email;

        uniqueEmails[email] = name.replace('"', "").replace(/['"]+/g, "");
      }
    });

    const result = Object.entries(uniqueEmails).map(([email, name]) => ({
      label: name === email ? name : `${name} <${email}>`,
      value: email,
    }));

    result.sort((a, b) => a.label.localeCompare(b.label));

    return result;
  }

  //OUTLOOK FUNCTIONS
  const getOutlookEmail = async () => {
    const OUTLOOK_SENDERS = {
      query: SENDER_BY_EMAIL_OUTLOOK_QUERY,
      variables: {
        //accessToken: token,
        email: props.connectedOutlookAccount,
      },
    };
    const OUTLOOK_RECIPIENTS = {
      query: RECIPIENT_BY_EMAIL_OUTLOOK_QUERY,
      variables: {
        //accessToken: token,
        email: props.connectedOutlookAccount,
      },
    };

    const senders = await API.graphql(OUTLOOK_SENDERS);
    const recipients = await API.graphql(OUTLOOK_RECIPIENTS);

    // SENDERS
    if (senders.data.outlookMessages) {
      const arrSenders = senders.data.outlookMessages;
      console.log("ARR SENDERS", arrSenders);
      const uniqueFromItems = await extractUniqueSenderEmails(arrSenders);

      setSenderList(uniqueFromItems);

      const defaultSender = uniqueFromItems?.find(
        (item) => item.value === searchSender
      );

      setSelectedSender(defaultSender);
    }

    if (recipients.data.outlookMessages) {
      const arrRecipients = recipients.data.outlookMessages;
      const uniqueRecipientItems = await extractUniqueRecipientEmails(
        arrRecipients
      );
      setRecipientList(uniqueRecipientItems);

      const defaultRecipient = uniqueRecipientItems?.find(
        (item) => item.value === searchRecipient
      );

      setSelectedRecipient(defaultRecipient);
    }
  };

  return (
    <div className="rounded-t-xl px-3 py-4 flex justify-between w-full bg-white">
      <div className="gap-2 inline-flex">
        {/* Select All Check Button */}
        <div className="flex p-1 ">
          <input
            type="checkbox"
            onClick={
              props.openTab === 1
                ? (e) => props.selectAllUnsaved(e, true)
                : props.openTab === 2
                ? (e) => props.selectAllSaved(e)
                : (e) => props.selectAllArchived(e)
            }
            checked={props.selectAllCheck ? true : false}
            className=" flex-shrink-0 block m-auto h-4 w-4"
          />
          {/* <span className="w-20 block m-auto text-xs font-semibold text-gray-500">
                          {props.selectAllCheck ? "Deselect All" : "Select All"}
                        </span> */}
        </div>

        {/* Add Client Matter Button */}
        <button
          className="flex justify-center rounded-md border-2 border-gray-200 px-2 text-gray-600 items-center"
          onClick={() => props.handleShowCreateClientMatter(true)}
          title="Create Client/Matter"
        >
          <AiFillFolderAdd />
        </button>

        {props.allowComposeEmail && (
          <div className="flex items-center">
            <div
              onClick={() => {
                props.setIsModal(true);
                // props.setIsReply(false);
              }}
              className="gap-2 flex cursor-pointer justify-center border-2 border-gray-200 rounded-md bg-white text-gray-500 hover:bg-gray-100"
              title="Compose Email"
            >
              <div>
                <span className="flex flex-row pl-2 py-1 pr-5 gap-2 items-center leading-normal text-gray-500">
                  <BiPencil /> Compose
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-2 items-center justify-start pr-2">
        {/* Search bar */}
        <div className="searchBar relative justify-between flex flex-row items-center rounded-md border bg-gray-50 h-6">
          <div className="flex flex-row">
            <div
              className="flex flex-row justify-between pl-2"
              // style={{ width: props.width * 0.25 }}
            >
              <div className="flex justify-center ">
                <input
                  type="search"
                  // id="SearchD"
                  rows={1}
                  style={{
                    whiteSpace: "nowrap",
                    resize: "none",
                    width: "100%",
                    cursor: "default",
                    width: props.width * 0.158,
                  }}
                  className="flex outline-none overflow-y-hidden overflow-x-scroll border-grey-light bg-gray-50 rounded rounded-l-none border-0 pr-3 text-sm leading-normal"
                  placeholder="Search this page"
                  value={props.searchGmail}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      props.fetchData();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      props.setHasSearchVal(false);
                      props.handleSearchGmailChange(e, "clear");
                    } else {
                      props.setHasSearchVal(true);
                      props.handleSearchGmailChange(e, "typed");
                    }
                  }}
                  onInput={(e) => {
                    if (e.target.value === "") {
                      props.setHasSearchVal(false);
                      props.handleSearchGmailChange(e, "clear");
                    } else {
                      props.setHasSearchVal(true);
                      props.handleSearchGmailChange(e, "pasted");
                    }
                  }}
                />
              </div>
              <div className="flex w-5 justify-center">
                <span className="flex items-center rounded rounded-r-none border-0 bg-gray-50 pl-0 text-xl leading-normal text-gray-600">
                  <IoIosSearch
                    className="cursor-pointer"
                    onClick={(e) => props.fetchData()}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          {/* <ReactDatePicker
            className="p-2 w-24 border-2 text h-6 rounded-lg text-black border-gray-100"
            placeholderText="From"
          /> */}
          <DatePicker
            popperProps={{
              positionFixed: true,
            }}
            className="p-2 w-26 border-2 text h-7 rounded-lg text-black border-gray-100"
            dateFormat={datePickerDateFormatter(
              localStorage.getItem("userpreferredDateFormatType")
            )}
            defaultShow={true}
            selected={
              startDate &&
              timezoneFormatter(
                startDate,
                localStorage.getItem("preferredTimezone")
              )
            }
            placeholderText={"From"}
            onChange={(selected) => {
              handleStartDateChange(selected);
              setIsSelectedDate(true);
              console.log("Start Date selected: ", startDate);
            }}
          />
        </div>
        <div className="flex flex-row gap-2">
          <Select
            isSearchable={false}
            options={dateOptions}
            className="p-0"
            isValidNewOption={() => false}
            styles={selectStyle}
            onChange={(selected) => {
              handleEndDateChange(selected.value);
              setIsSelectedDate(true);
              console.log("End Date selected: ", endDate);
            }}
            components={{ Option: IconOption, IndicatorSeparator: () => null }}
            defaultValue={{ value: oneDay, label: "Today" }}
          />
          {/* <ReactDatePicker
            className="p-2 w-24 border-2 h-6 rounded-lg border-gray-100"
            placeholderText="To"
          /> */}
        </div>
        <div className="flex items-center">
          <div
            onClick={() => props.setshowFiltersModal(true)}
            className="gap-2 flex cursor-pointer justify-center border-2 h-7 border-gray-200 rounded-md bg-white text-gray-500 hover:bg-gray-100"
          >
            <span className="flex flex-row px-2 gap-2 items-center leading-normal text-gray-500">
              More <AiOutlinePlus />
            </span>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              handleSave();
              props.setEmailContentOpen(false);
            }}
            className={
              isSelectedDate
                ? "p-1 px-2 text-green-400  rounded-lg "
                : "p-1 px-2 text-gray-500  rounded-lg"
            }
          >
            Save Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default InboxFilter;
