import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { BsChevronDown, BsFillPersonFill, BsTrash } from "react-icons/bs";
import { RiText } from "react-icons/ri";
import {
  FaCalendarDay,
  FaCheckSquare,
  FaHashtag,
  FaPhoneAlt,
} from "react-icons/fa";

import { Dialog } from "@headlessui/react";
import { API } from "aws-amplify";
import { useColumnContext } from "../context/ColumnContext";
import { min } from "moment-timezone";

const mCreateActivity = `
mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
	activity: $activity
	briefId: $briefId
	clientMatterId: $clientMatterId
	companyId: $companyId
	previous: $previous
	field: $field
	current: $current
	appModule: $appModule
	rowId: $rowId
  ) {
	id
  }
}`;


const UPDATE_TASK_LIST_TABLE_MUTATION = `
mutation taskListTableUpdate(
    $id: ID!
    $input: TaskListTableInput!
    ) {
  taskListTableUpdate(
    id: $id
    input: $input
  ) {
    id
    columns {
      id
      accessorKey
      headerText
      type
      enabled
      optionsText
      order
      presets {
        id
        label
      }
    }
  }
}
`;

const NewColumnTab = () => {
  const [selectedPrimitiveName, setSelectedPrimitiveName] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const primitives = [
    {
      name: "Dropdown",
      icon: <BsChevronDown />,
    },
    {
      name: "Text",
      icon: <RiText />,
    },
    {
      name: "Textarea",
      icon: <RiText />,
    },
    {
      name: "Date",
      icon: <FaCalendarDay />,
    },
    {
      name: "Number",
      icon: <FaHashtag />,
    },
    {
      name: "Checkbox",
      icon: <FaCheckSquare />,
    },
    {
      name: "People",
      icon: <BsFillPersonFill />,
    },
    {
      name: "Phone",
      icon: <FaPhoneAlt />,
    },
  ];
  const filteredPrimitives =
    query === ""
      ? primitives
      : primitives.filter((primitive) => {
          return primitive.name.toLowerCase().includes(query.toLowerCase());
        });

  async function selectColumnPrimitiveNameToCreate(name) {
    setSelectedPrimitiveName(name);
    setIsOpen(true);
  }

  return (
    <div className="mr-3">
      <input
        type="text"
        className="p-3 border w-full rounded-md"
        placeholder="Search Column"
        onChange={(event) => setQuery(event.target.value)}
      />
      <ul className="flex flex-col mt-6 space-y-5">
        {filteredPrimitives.map((primitive) => (
          <li
            key={primitive.name}
            onClick={() => selectColumnPrimitiveNameToCreate(primitive.name)}
            className="flex w-full items-center justify-center cursor-pointer"
          >
            <p>{primitive.name}</p>
            <i className="ml-auto">{primitive.icon}</i>
          </li>
        ))}
      </ul>

      <Dialog
        open={isOpen}
        onClose={() => null}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex min-h-screen items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="relative mx-auto max-w-xl rounded bg-white">
            <Dialog.Title className="flex items-center border-b p-2">
              <PrimitiveIcon name={selectedPrimitiveName} />
              <p className="ml-2 text-lg font-medium">
                {selectedPrimitiveName}
              </p>
            </Dialog.Title>

            <div className="p-5">
              <div className="flex flex-col">
                <PrimitiveForm
                  setIsOpen={setIsOpen}
                  name={selectedPrimitiveName}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "white",
  width: "100%",
});

const PrimitiveForm = ({ name, setIsOpen }) => {
  const optionsSchema = z.object({
    content: z.string().min(1),
  });
  const formSchema = z.object({
    headerText: z.string().min(1),
    options: z.array(optionsSchema),
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      options: [
        {
          content: "Option",
        },
        {
          content: "Option",
        },
        {
          content: "Option",
        },
      ],
    },
  });
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "options",
  });
  const [columns, setColumns] = useColumnContext();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const onSubmit = async (data) => {
    setDisableSubmit(true);

    if (columns.find((col) => col.header === data.headerText)) {
      setDisableSubmit(false);

      setError("headerText", {
        type: "custom",
        message: "Column name already exist.",
      });
      return;
    }

    if (name === "Dropdown" && data.options) {
      setDisableSubmit(true);

      const options = data.options.map((opt) => opt.content);
      //check duplicate
      if (options.some((item, idx) => options.indexOf(item) !== idx)) {
        setDisableSubmit(false);
        setError("options", {
          type: "custom",
          message: "Options have duplicate values.",
        });
        return;
      }
    }

    await addNewColumn(name, data);
    setIsOpen(false);
    setDisableSubmit(true);
  };

  async function addNewColumn(type, formData) {
    const tableId = localStorage.getItem("task-list-table-id");
    const columnsToSet = sortByAccesorKey(columns).map((c) => {
      //delete c.id;
      //delete c.accessorKey;
      c.headerText = c.header;
      delete c.header;
      return c;
    });
    
    if (columnsToSet.length > 0) {
      const { data } = await API.graphql({
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: [
              ...columnsToSet,
              {
                headerText: formData.headerText,
                type: type.toUpperCase(),
                enabled: true,
                optionsText:
                  type === "Dropdown"
                    ? formData.options.map((opt) => opt.content)
                    : [],
                order: columns.length + 1,
              },
            ],
          },
        },
      });

      const tableColumns = sortByOrder(data.taskListTableUpdate.columns).map(
        (c) => {
          if (c.type === "DROPDOWN") {
            return {
              accessorKey: c.id,
              header: c.headerText,
              type: c.type,
              enabled: c.enabled,
              order: c.order,
              presets: c.presets,
              optionsText: c.optionsText ? c.optionsText : [],
            };
          }
          return {
            accessorKey: c.id,
            header: c.headerText,
            type: c.type,
            order: c.order,
            presets: c.presets,
            enabled: c.enabled,
          };
        }
      );

      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: null,
          briefId: null,
          activity: `added ${type} column`,
          field: "Sidebar",
          appModule: "TASKS",
          previous: null,
          current: null
        },
      };
  
      const addActivity = await API.graphql(params).then((result) =>
        console.log(`added column ${type}`, result)
      );

      setColumns(tableColumns);
      setDisableSubmit(false);
      setIsOpen(false);
    }
  }

  async function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  }

  function sortByAccesorKey(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.accessorKey - b.accessorKey);
    } else {
      sort = arr;
    }

    return sort;
  }

  function sortByOrder(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.order - b.order);
    } else {
      sort = arr;
    }

    return sort;
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <Dialog.Description className="mb-4">
        <div className="bg-gray-100 h-32 w-full rounded-lg"></div>
        <p className="text-sm text-gray-900">
          <PrimitiveDescription name={name} />
        </p>
      </Dialog.Description>
      <div className="flex gap-2">
        <div className="flex flex-col">
          <label className="text-xs">
            <i className="text-red-700">*</i>Column Name
          </label>
          <input
            {...register("headerText")}
            className="border rounded-md p-2"
            type="text"
            placeholder="Column Name"
          />
          {errors.headerText && errors.headerText.message}
        </div>
        <div className="flex flex-col w-full">
          <label className="text-xs">Field Type</label>
          <select className="border rounded-md p-2" disabled>
            <option value={name}>{name}</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col">
        {name === "Dropdown" && (
          <>
            {errors.options && errors.options.message}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {fields.map((field, index) => (
                      <Draggable
                        key={field.id}
                        draggableId={field.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="mt-2"
                          >
                            <label className="text-xs">{`Option ${
                              index + 1
                            }`}</label>
                            <div className="flex items-center gap-2">
                              <input
                                key={field.id}
                                {...register(`options.${index}.content`)}
                                type="text"
                                className="w-full border rounded-md p-2"
                                defaultValue={field.content}
                              />
                              <BsTrash
                                onClick={(e) => {
                                  e.preventDefault();
                                  remove(index);
                                }}
                                className="ml-auto"
                              />
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <div className="flex mt-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  append({ content: "Option" });
                }}
                className="text-primary"
              >
                Add new option +
              </button>
            </div>
          </>
        )}
        <div className="flex mt-5 w-full">
          <div className="ml-auto flex space-x-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <input
              type="submit"
              value="Add Column"
              className="save-btn cursor-pointer"
              disabled={disableSubmit ? true : false}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const PrimitiveIcon = ({ name }) => {
  switch (name) {
    case "Dropdown":
      return <BsChevronDown />;

    case "Text":
      return <RiText />;

    case "Textarea":
      return <RiText />;

    case "Date":
      return <FaCalendarDay />;

    case "Number":
      return <FaHashtag />;

    case "Checkbox":
      return <FaCheckSquare />;

    case "People":
      return <BsFillPersonFill />;
    case "Phone":
      return <FaPhoneAlt />;
    default:
      return null;
  }
};

const PrimitiveDescription = ({ name }) => {
  switch (name) {
    case "Dropdown":
      return "Use dropdowns to give consistent options";

    case "Text":
      return "Use dropdowns to give consistent options";

    case "Date":
      return "Use dropdowns to give consistent options";

    case "Number":
      return "Use dropdowns to give consistent options";

    case "Checkbox":
      return "Use dropdowns to give consistent options";

    case "People":
      return "Use dropdowns to give consistent options";
    case "Phone":
      return "Use dropdowns to give consistent options";
    default:
      return null;
  }
};

export default NewColumnTab;
