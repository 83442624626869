import { useState } from "react";
import { Auth, Hub } from "aws-amplify";
import "../../assets/styles/Auth.css";
import Button from "../../shared/Button";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillMail, AiOutlineLoading } from "react-icons/ai";
import InputCode from "./input-code";

import ProgressNotification from "./progress-notification";

export default function ConfirmSignUp({
  user,
  setUser,
  AuthState,
  setNextAuthState,
  notifMessage,
  notifShow,
  notifVariant,
  setNotifMessage,
  setNotifShow,
  setNotifVariant,
  loading,
  setLoading,
}) {
  const [code, setCode] = useState();
  console.log("USER", user);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setNotifShow(false);

    //This checks for empty or incomplete code
    if (!code || code.length !== 6) {
      setLoading(false);
      setNotifMessage("Invalid verification code, please try again.");
      setNotifVariant("error");
      setNotifShow(true);
      window.scrollTo(0, 0);
      return;
    }

    try {
      const verifyUser = await Auth.confirmSignUp(user.username, code).then(
        (user) => {
          console.log("verify user", user);
          setLoading(false);
          setNotifMessage("Successfully signed up");
          setNotifVariant("success");
          setNotifShow(true);
          window.scrollTo(0, 0);
          listenToAutoSignInEvent();
        }
      );
    } catch (error) {
      console.error("error confirming sign up", error);
      setLoading(false);
      setNotifMessage(error.message);
      setNotifVariant("error");
      setNotifShow(true);
      window.scrollTo(0, 0);
    }
  }

  function listenToAutoSignInEvent() {
    Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      if (event === "autoSignIn") {
        const user = payload.data;
        setLoading(false);
        setUser(user);
        setNextAuthState(AuthState.SignedIn);
      } else if (event === "autoSignIn_failure") {
        setUser();
        setLoading(false);
        setNextAuthState(AuthState.SignIn);
      }
    });
  }

  return (
    <>
      <div className="fade-in-fwd mt-10 h-full w-full md:mt-0">
        <div className="text-2xl font-semibold text-hbBlue md:text-3xl">
          Verify Account
        </div>
        <div>
          <div className="mt-10 w-full">
            <ProgressNotification
              className=""
              variant={notifVariant}
              message={notifMessage}
              show={notifShow}
            />
            <form onSubmit={(event) => handleSubmit(event)}>
              <div className="flex flex-col" style={{ maxWidth: "500px" }}>
                <div className="flex flex-col">
                  <label
                    for="forgotPassword-email"
                    className="text-xl font-normal text-darkGray"
                  >
                    Please check your email
                  </label>
                  <span>{`We've sent a code to ${user.username}`}</span>
                  <div className="flex justify-center">
                    <InputCode
                      length={6}
                      label="Code Label"
                      onComplete={(code) => {
                        setCode(code);
                      }}
                      className="outline-none focus:outline-none mx-1 mt-2 h-12 w-10 rounded-xl border-4 border-neutral-100 bg-neutral-100 py-2 text-center text-2xl font-bold text-hbGray placeholder-hbGray ring-hbBlue focus:ring-2 md:h-16 md:w-12"
                    />
                  </div>
                </div>

                <div className="mt-14 flex flex-col-reverse items-center md:mt-9 md:grid md:grid-cols-2">
                  <div className="mt-3 block md:col-span-1 md:mt-0">
                    <button
                      className="ml-1 text-base font-normal text-hbBlue"
                      type="button"
                      onClick={() => {setNotifShow(false);setNextAuthState(AuthState.SignIn)}}
                    >
                      Back to Login
                    </button>
                  </div>
                  <div className="w-full md:col-span-1">
                    <Button
                      type="submit"
                      variant="hbBlue"
                      block={true}
                      size={"xl"}
                      className="h-16 text-lg"
                      disabled={loading}
                    >
                      <span className="relative text-xl font-semibold">
                        {loading ? (
                          <AiOutlineLoading className="rotate-center absolute -left-6 top-1" />
                        ) : null}
                        VERIFY
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
