export const MATTER_REQUEST = "MATTER_REQUEST";
export const MATTER_SUCCESS = "MATTER_SUCCESS";
export const MATTER_ERROR = "MATTER_ERROR";
export const SEARCH_MATTER_REQUEST = "SEARCH_MATTER_REQUEST";
export const SEARCH_MATTER_SUCCESS = "SEARCH_MATTER_SUCCESS";
export const SEARCH_MATTER_ERROR = "SEARCH_MATTER_ERROR";
export const CREATE_MATTER_REQUEST = "CREATE_MATTER_REQUEST";
export const CREATE_MATTER_SUCCESS = "CREATE_MATTER_SUCCESS";
export const CREATE_MATTER_ERROR = "CREATE_MATTER_ERROR";
export const DELETE_MATTER_REQUEST = "DELETE_MATTER_REQUEST";
export const DELETE_MATTER_SUCCESS = "DELETE_MATTER_SUCCESS";
export const DELETE_MATTER_ERROR = "DELETE_MATTER_ERROR";
export const CREATE_MATTER_DUPLICATE = "CREATE_MATTER_DUPLICATE"; //mma1010
export const HIDETOAST = "HIDETOAST";
