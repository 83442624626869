//Libraries
import { useParams } from "react-router-dom"
import { createContext, useReducer, useEffect } from "react"

//Reducers
import { appStateReducer } from "./appStateReducer"
import { formDataReducer } from "./formDataReducer"
import { modalStateReducer } from "./modalStateReducer"
import { newRuleStateReducer } from "./newRuleStateReducer"

//API
import { 
  mutationActionHandler,
  qGetFormById
} from "../graphql/formAPI"

export const defaultSectionState = {
  id: '',
  order: '',
  question: "",
  sublabel: "",
  optionsType: "RADIOBUTTON",
  options: [
    "",
    "",
    "",
  ],
  properties: {
    isRequired: false,
    isVisible: true,
  },
};


export const defaultPageState = {
  id: '',
  order: 1,
  name: '',
  description: '',
  sections: [],
};

const initialState = {
  appState: {
    companyId: localStorage.getItem('companyId') || null,
    activeTab: "edit",
    currentPage: 1,
    settings: {
      currentTab: "notifications",
      other: "",
    },
  },
  formData: {
    formDetails: {
      id: '',
      name: '',
    },
    pageType: "multiple",
    emailNotification: false,
    pages: [],
    conditions: [],
    formAnswers: {},
  },
  modalState: {
    modalOpen: false,
    modalContent: null,
  },
  newRuleState: {
    newRuleOpen: false,
    newRuleContent: null,
  },
};

const storeReducer = (state, action) => ({
  appState: appStateReducer(state.appState, action),
  formData: formDataReducer(state.formData, action),
  modalState: modalStateReducer(state.modalState, action),
  newRuleState: newRuleStateReducer(state.newRuleState, action),
});

export const FormBuilderContext = createContext();

export default function StoreProvider({ children }) {
  
  const [state, dispatch] = useReducer(storeReducer, initialState)

  const { id } = useParams()

  useEffect(() => {
    const fetchForm = async () => {
      mutationActionHandler(
        {
          query: qGetFormById,
          variables: {
            id: id,
          }
        },
        (response)=>{
          console.log(response)
          const {id, name, pages} = response.data.customForm
          dispatch({
            type: 'SET_FORM_DETAILS',
            payload: {
              id: id,
              name: name,
            },
          })
          dispatch({
            type: 'SET_FORM_CONTENT',
            payload: pages
          })
        }
      )
    }

    fetchForm()
  }, []);

  //console.log(state)

  const contextValue = {
    state,
    dispatch,
  };

  return (
    <FormBuilderContext.Provider value={contextValue}>
      {children}
    </FormBuilderContext.Provider>
  );
}
