import React, { useState, useEffect, useReducer } from "react";
import { API } from "aws-amplify";
import { Questions } from "./questions.js";
import MultiStepForm from "./multi-step-form.jsx";
import ToastNotification from "../../toast-notification";
import HBAImage from "./hba.png";
import { AppRoutes } from "../../../constants/AppRoutes";
import {
  setDefaultColumnBriefs,
  createContractBackground,
  createDefaultBriefs,
} from "../../../shared/HBA-Functions.jsx";
// import { useAuthLoading } from "../authLoadingContext";

// temporary hide onboarding
import { useHistory } from "react-router-dom";
// import { hbaDomains, obaDomains } from "../../../constants/AppDomains";
// import { AppRoutes } from "../../../constants/AppRoutes";

const CREATE_CLIENT_MUTATION = `mutation addClient($companyId: String, $name: String) {
  clientCreate(companyId:$companyId, name:$name) {
    id
    name
  }
}`;

const CREATE_MATTER_MUTATION = `mutation addMatter($companyId: String, $name: String) {
  matterCreate(companyId:$companyId, name:$name) {
    id
    name
  }
}`;

const CREATE_CLIENTMATTER_MUTATION = `mutation createClientMatter($companyId: String, $userId: ID, $client: ClientInput, $matter:MatterInput) {
  clientMatterCreate(companyId: $companyId, userId:$userId, client: $client, matter:$matter) {
    id
  }
}`;

const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int], $assistantId: String, $phase: [Phase]) {
  briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds, assistantId: $assistantId, phase: $phase) {
    id
    name
    date
    createdAt,
    assistantId,
    order
    columnIds
  }
}`;

const CLIENTMATTER_QUERY = `query listClientMatters($companyId: String) {
  company(id: $companyId) {
    clientMatters (sortOrder: CREATED_DESC, isDeleted:false) {
      items {
        id
        client {
          name
        }
        matter {
          name
        }
      }
    }
  }
}`;

const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
	backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
    id
    createdAt
    date
    description
    order
    briefs {
      id
      name
    }
	}
}`;

const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs{
      items {
        id
        name
        userTypeAccess
        date
        order
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        backgrounds(limit: 50, sortOrder: ORDER_ASC) {
          items {
            id
            description
            date
            dynamicData
            files{
              items{
                id
                name
              }
            }
          }
        }
        hasCommentAccess
        columnIds
        assistantId
      }
    }
  }
}`;

const UPDATE_BRIEF_PRESET_MUTATION = `mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
  briefUpdate(id: $id, columnIds: $columnIds){
    id
  }
}`;

const BACKGROUND_TABLE_QUERY = `query getBackgroundTable($clientMatterId: ID!) {
  backgroundTable(clientMatterId: $clientMatterId) {
    id
    columns {
      id
      accessorKey
      headerText
      enabled
      type
      optionsText
      order
      presets {
        id
        name
      }
    }
    createdAt
    updatedAt
  }
}`;

const BACKGROUND_TABLE_CREATE = `mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
  backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
    id
    columns {
      id
      accessorKey
      headerText
      enabled
      type
      optionsText
      order
    }
    createdAt
    updatedAt
  }
}`;

const UPDATE_BACKGROUND_TABLE_MUTATION = `mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
  backgroundTableUpdate(id: $id, input: $input) {
    id
    columns {
      id
      accessorKey
      headerText
      type
      enabled
      optionsText
      order
      presets {
        id
        name
      }
    }
  }
}`;

const qGetBackgroundFilesbyBriefID = `
	query getBackgroundFilesByBriefID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
		brief(id: $id) {
		id
		name
		backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
			items {
			id
			description
			date
			createdAt
			order
			files {
				items {
				id
				name
				details
				isGmailPDF
				isGmailAttachment
				s3ObjectKey
				order
				createdAt
				downloadURL
				}
			}
			}
			nextToken
		}
		}
	}`;

const initialStates = Questions.reduce(
  (header, page) => {
    header[`page_${page.order}`] = {
      headerText: page.mainHeader,
      type: page.type,
      questions: page.questions,
      order: page.order,
      style: page.style,
      subheader: page.subheader,
      category: page.category,
      redirectTo: page.redirectTo,
      // answers: ansTemp ? JSON.parse(ansTemp) : [],
      properties: page.properties,
      answers: [],
      image: page.image,
    };
    return header;
  },
  {
    Progress: "page 1",
  }
);

const updateAnswers = (state, action) => {
  if (
    state[action.page].answers.length > 0 &&
    state[action.page].answers.some(
      (item) => item.order === action.payload.order
    )
  ) {
    return (() => {
      if (
        action.inputType !== "input" &&
        action.inputType !== "textarea" &&
        action.inputType !== "datepicker" &&
        action.inputType !== "uploadBox" &&
        action.inputType !== "dropdown"
      ) {
        return {
          ...state[action.page],
          answers: state[action.page].answers.filter(
            (item) =>
              item.order !== action.payload.order &&
              !item?.answer?.includes("Skip")
          ),
        };
      } else {
        return {
          ...state[action.page],
          answers: state[action.page].answers.map((item) => {
            if (item.order === action.payload.order) {
              console.log("here", action.payload);
              return action.payload;
            }
            console.log("reached", item);
            return item;
          }),
        };
      }
    })(action.payload);
  } else {
    return (() => {
      if (
        typeof eventAnswer === "string" &&
        (action.payload.answer?.includes("Skip") ||
          state[action.page].answers.some((x) => x?.answer?.includes("Skip")))
      ) {
        return {
          answers: [action.payload],
        };
      } else {
        return {
          ...state[action.page],
          answers: [...state[action.page].answers, action.payload],
        };
      }
    })(action.payload);
  }
};

function reducer(state, action) {
  switch (action.type) {
    /* ACTIONS FOR STATE */
    case "RESET_STATE": {
      return action.payload;
    }
    case "CHANGE_STATE": {
      let newState = action.payload;
      localStorage.setItem(
        "main_onboarding_state",
        btoa(encodeURIComponent(JSON.stringify(newState)))
      );
      return newState;
    }

    /* ACTIONS FOR PROGRESS */
    case "CHANGE_PROGRESS": {
      return { ...state, Progress: action.payload };
    }

    /* ACTIONS FOR EMAIL */
    case "CHANGE_EMAIL": {
      return { ...state, Email: action.payload };
    }

    case "CHANGE_MAIN_ANSWER": {
      // console.log("ANSWER PAYLOAD ", action.payload);
      // console.log("CONDITIONAL ", state[action.page].answers);
      let newState = {
        ...state,
        [action.page]: {
          ...state[action.page],
          answers: [action.payload],
        },
      };

      localStorage.setItem(
        "main_onboarding_state",
        btoa(encodeURIComponent(JSON.stringify(newState)))
      );
      return newState;
    }
    case "CHANGE_MAIN_ANSWER_MULTIPLE": {
      // console.log("MULTIPLE ANSWER PAYLOAD ", action.payload);
      // console.log(
      //   "CONDITIONAL ",
      //   state[action.page].answers.some(
      //     (item) => item.answer === action.payload.answer
      //   )
      // );

      var temp;

      //Check if action is meant to delete item in object answer
      if (
        action.payload.answer.isRemove &&
        action.payload.answer.isRemove !== undefined &&
        action.payload.answer.isRemove === true
      ) {
        //Get details of file to remove in object
        const toRemoveFileName = action.payload.answer.fileName;
        const toRemoveFileKey = action.payload.answer.fileKey;
        let ActionPayloadAnswers = {
          ...action.payload.answer,
        };

        //delete unnecessary keys in object after cloning
        delete ActionPayloadAnswers.fileName;
        delete ActionPayloadAnswers.isRemove;
        delete ActionPayloadAnswers.fileKey;

        //Filter for objects
        Object.filter = (obj, predicate) =>
          Object.fromEntries(Object.entries(obj).filter(predicate));
        var filteredActionAnswer = Object.filter(
          ActionPayloadAnswers,
          ([fileItem, item]) => fileItem !== toRemoveFileKey
        );

        //Upon Filtering arrange the names of answer files
        var arrangeNamingConvention = {};
        Object.keys(filteredActionAnswer).forEach(function (key, index) {
          arrangeNamingConvention[`file_${index + 1}`] =
            filteredActionAnswer[key];
        });

        //Populated finished filtering & pass it updateState function
        let filteredAction = {
          ...action,
          payload: {
            ...action.payload,
            answer: {
              ...arrangeNamingConvention,
            },
          },
        };

        temp = updateAnswers(state, filteredAction);
      }

      //Check if action is doesnt want to delete item in object answer
      if (
        !action.payload.answer.isRemove &&
        action.payload.answer.isRemove === undefined
      ) {
        temp = updateAnswers(state, action);
      }

      let newState = {
        ...state,
        [action.page]: temp,
      };

      if (action.inputType !== "uploadBox") {
        localStorage.setItem(
          "main_onboarding_state",
          btoa(encodeURIComponent(JSON.stringify(newState)))
        );
      }
      return newState;
    }
    case "CHANGE_COREQUISITE_ANSWER": {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          answers: state[action.page].answers.map((answer) => {
            if (answer.order === action.answerIndex) {
              return {
                ...answer,
                corequisite: Array.isArray(answer.corequisite)
                  ? answer.corequisite.some(
                      (x) => x.order === action.payload.order
                    )
                    ? answer.corequisite.map((corequisiteAnswer) => {
                        if (corequisiteAnswer.order === action.payload.order) {
                          return action.payload;
                        }
                        return corequisiteAnswer;
                      }) // Already exists, don't change
                    : [...answer.corequisite, action.payload]
                  : [action.payload], // Initialize as an array if not already
              };
            }
            return answer; // Keep other answers unchanged
          }),
        },
      };
    }
    case "CHANGE_MAIN_OPTIONS": {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          options: [...action.payload],
        },
      };
    }
    default:
      return state;

    /* case "CHANGE_MAIN_OPTIONS": {
      return state.map((pageItem) => {
        if (pageItem.order === action.page) {
          // Update the options for the matching page
          return {
            ...pageItem,
            options: [...action.payload],
          };
        }
        return pageItem; // Return unchanged items
      });
    } */
  }
}

export default function GettingStarted() {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const page = state?.Progress;
  const pageURL = window.location.href.split("/");
  const pageNumber = pageURL[pageURL.length - 1];
  const [clientMatterId, setClientMatterId] = useState(null);
  const [defaultList, setDefaultList] = useState(null);
  const defaultColumnIds = [0, 1, 2, 3, 4];

  const [builderDetailsBrief, setBuilderDetailsBrief] = useState();
  const [contractDetailsBrief, setContractDetailsBrief] = useState();
  const [variationsBrief, setVariationsBrief] = useState();
  const [provisionalSumBrief, setProvisionalSumBrief] = useState();
  const [budgetBrief, setBudgetBrief] = useState();
  const [invoicesBrief, setInvoicesBrief] = useState();
  const [progressOfWorkBrief, setProgressOfWorkBrief] = useState();
  const [progressPhotoBrief, setProgressPhotoBrief] = useState();
  const [projectLocationBrief, setProjectLocationBrief] = useState();
  const [contractsBrief, setContractsBrief] = useState();
  const [contractsBriefFiles, setContractsBriefFiles] = useState();
  const [siteDiaryBrief, setSiteDiaryBrief] = useState();

  const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");
  const moment = require("moment");
  const currentDate = moment(new Date(), "YYYY-MM-DD").toISOString();

  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // temporary hide onboarding
  // const domain = window.location.hostname;
  let history = useHistory();

  // const { stopAuthLoading } = useAuthLoading();

  // console.log(history);

  const fileList = [
    "Contract Terms And Conditions",
    "Architectural Drawings",
    "Engineer Drawings",
    "Specifications",
    "Builder's Insurance",
  ];

  // useEffect(() => {
  //   localStorage.setItem("onboardingProgress", "false");
  //   // console.log(localStorage.getItem("onboardingProgress"));
  // });

  useEffect(() => {
    console.log("PAGE NUMBER", pageNumber);
    //if (parseInt(pageNumber) === 1) {
    getClientMatters();
    // stopAuthLoading();
    //}
    getProgress();
  }, []);

  const getProgress = () => {
    let mainState = localStorage.getItem("main_onboarding_state");
    if (!mainState) {
      localStorage.setItem(
        "main_onboarding_state",
        btoa(encodeURIComponent(JSON.stringify(state)))
      );
    } else {
      let newState = decodeURIComponent(atob(mainState));
      dispatch({
        type: "CHANGE_STATE",
        payload: {
          ...JSON.parse(newState),
        },
      });
    }
  };

  const getClientMatters = async () => {
    const clientMatters = await API.graphql({
      query: CLIENTMATTER_QUERY,
      variables: {
        companyId: companyId,
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    const clientMattersResult =
      clientMatters?.data?.company?.clientMatters?.items;
    console.log("clientMattersResult", clientMattersResult);
    if (clientMattersResult.length === 0) {
      localStorage.removeItem("answerpage_1");
      localStorage.removeItem("answerpage_2");
      localStorage.removeItem("answerpage_3");
      localStorage.removeItem("answerpage_4");
      localStorage.removeItem("answerpage_5");
      localStorage.removeItem("clientMatterId");
      console.log("No Client Matters, ");
      console.log("Proceed to generating default client matter");
      createClient().then((client) => {
        createMatter().then((matter) => {
          createClientMatter(client, matter).then((cmID) => {
            setClientMatterId(cmID);
            localStorage.setItem("clientMatterId", cmID);
            createDefaultBackground(cmID);
          });
        });
      });
    } else {
      console.log(localStorage.getItem("answerpage_1"));
      console.log(localStorage.getItem("clientMatterId"));
      console.log("clientMattersResult", clientMattersResult);
      if (clientMattersResult[0].client.name == "Default") {
        console.log(clientMattersResult[0].id);
        setClientMatterId(clientMattersResult[0].id);
        localStorage.setItem("clientMatterId", clientMattersResult[0].id);
        console.log(clientMatterId);

        const params = {
          query: BRIEFS_QUERY,
          variables: {
            id: clientMattersResult[0].id,
          },
        };

        console.log(params);

        await API.graphql(params).then(async (brief) => {
          const briefsList = brief?.data?.clientMatter?.briefs?.items;
          console.log("briefsList from up", briefsList);
          setDefaultList(briefsList);
          //setContractDetailsBrief(briefsList[0]);
          setDefaultBriefColumnIds(
            sortByOrder(briefsList),
            clientMattersResult[0].id
          );

          console.log(defaultList);
          console.log(briefsList);

          const progressPhotoIndex = briefsList.findIndex(
            (item) => item.name === "Progress Photos"
          );
          let rowArray = [];
          if (progressPhotoIndex !== -1) {
            setProgressPhotoBrief(briefsList[progressPhotoIndex]);

            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[progressPhotoIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;

            // const filteredItems = brief.backgrounds.items.filter(item =>
            // 	item.files.items && item.files.items.length > 0
            // );

            let retrievedRow = brief.brief.backgrounds.items;

            // Filter the retrievedRow array to only contain rows with images
            if (retrievedRow.length > 0) {
              const filteredItems = retrievedRow.filter((item) => {
                // Check if at least one file in the files array is an image
                return item.files.items.some((file) =>
                  isImageFile(file.s3ObjectKey)
                );
              });

              //setPhotos(filteredItems);
              // console.log("YOU ARE HERE " + filteredItems)
              // console.log("YOU ARE HERE " + JSON.stringify(retrievedRow))
            }
          }

          const projectLocationIndex = briefsList.findIndex(
            (item) => item.name === "Project Location"
          );
          if (projectLocationIndex === -1) {
            // ADDED TEMPORARY CODE TO ADD BRIEF TO ONGOING TESTING ACCOUNTS
            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                name: "Project Location",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
                phase:[
                  "PRECONSTRUCTION", "CONSTRUCTION", "HANDOVER"
                ]
              },
            });
            const params = {
              query: BRIEFS_QUERY,
              variables: {
                id: clientMatterId,
              },
            };

            await API.graphql(params).then((brief) => {
              const briefsList = brief?.data?.clientMatter?.briefs?.items;
              const projectLocationIndex = briefsList.findIndex(
                (item) => item.name === "Project Location"
              );
              setProjectLocationBrief(briefsList[projectLocationIndex]);
            });
          } else {
            setProjectLocationBrief(briefsList[projectLocationIndex]);
            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[projectLocationIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;

            // const filteredItems = brief.backgrounds.items.filter(item =>
            // 	item.files.items && item.files.items.length > 0
            // );
            if (brief.brief.backgrounds?.items?.length > 0) {
              rowArray.push(brief.brief.backgrounds.items);
              console.log(
                "setting project location photo",
                brief.brief.backgrounds.items[0]
              );
              //setProjectLocationPhoto(brief.brief.backgrounds.items[0]);
            }
          }

          setContractsBrief(briefsList[0]);
          const response = await API.graphql({
            query: qGetBackgroundFilesbyBriefID,
            variables: {
              id: briefsList[0].id,
              limit: null,
              nextToken: null,
              sortOrder: "ORDER_ASC",
            },
          });
          console.log(response.data);

          const contractFiles = [];
          fileList.map((file, index) => {
            contractFiles.push({
              id: response.data.brief.backgrounds.items[index]?.id,
              order: response.data.brief.backgrounds.items[index]?.order,
            });
          });
          setContractsBriefFiles(contractFiles);

          setContractDetailsBrief(briefsList[7]);
          setProgressOfWorkBrief(briefsList[3]);
          setBuilderDetailsBrief(briefsList[8]);

          setSiteDiaryBrief(briefsList[1]);
          setProgressPhotoBrief(briefsList[4]);
          setInvoicesBrief(briefsList[5]);
          setVariationsBrief(briefsList[6]);
        });
      }
    }
  };

  const isImageFile = (fileName) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = fileName
      .toLowerCase()
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    return imageExtensions.includes("." + extension);
  };

  const createClient = async () => {
    const createClient = await API.graphql({
      query: CREATE_CLIENT_MUTATION,
      variables: {
        companyId: companyId,
        name: "Default",
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    console.log("Default Client Created:", createClient.data.clientCreate);
    return createClient.data.clientCreate;
  };

  const createMatter = async () => {
    const companyId = localStorage.getItem("companyId");
    const createMatter = await API.graphql({
      query: CREATE_MATTER_MUTATION,
      variables: {
        companyId: companyId,
        name: "Default",
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    console.log("Default Matter Created:", createMatter.data.matterCreate);
    return createMatter.data.matterCreate;
  };

  const createClientMatter = async (client, matter) => {
    const createClientMatter = await API.graphql({
      query: CREATE_CLIENTMATTER_MUTATION,
      variables: {
        companyId: companyId,
        userId: userId,
        client: client,
        matter: matter,
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    console.log(
      "Default Client / Matter Created:",
      createClientMatter.data.clientMatterCreate.id
    );
    return createClientMatter.data.clientMatterCreate.id;
  };

  //BACKGROUNDS: VARIATIONS, PROGRESS CLAIMS, PROGRESS PHOTOS, DELAY CLAIMS, CONTRACTS, PROJECT LOCATION
  const createDefaultBackground = async (clientMatterId) => {
    console.group("createDefaultBackground");
    console.log("CREATING DEFAULT BACKGROUND", clientMatterId);
    let {
      Builder_Details,
      Contract_Details,
      Variations,
      Provisional_Sum,
      Budget,
      Invoices,
      Progress_Photos,
      Progress_of_work,
      Project_Location,
      Site_Diary,
      // Prime_Cost,
      // Tenders,
      // Certificates,
      // Insurance,
      // Defects_List,
      // Development_Approvals,
      // Building_Permits,
      // Warranties_and_Certifications,
      // Work_Safety_Compliance,
      Contracts,
    } = await createDefaultBriefs(clientMatterId, currentDate);

    setBuilderDetailsBrief(Builder_Details);
    setContractDetailsBrief(Contract_Details);
    setVariationsBrief(Variations);
    setProvisionalSumBrief(Provisional_Sum);
    setBudgetBrief(Budget);
    setInvoicesBrief(Invoices);
    setProgressPhotoBrief(Progress_Photos);
    setProgressOfWorkBrief(Progress_of_work);
    setProjectLocationBrief(Project_Location);
    setSiteDiaryBrief(Site_Diary);
    setContractsBrief(Contracts);

    let files = await createContractBackground(Contracts);

    setContractsBriefFiles(files);

    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then((brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      console.log("briefsList", briefsList);
      setDefaultList(briefsList);
      setDefaultBriefColumnIds(sortByOrder(briefsList), clientMatterId);
    });

    // temporary hide onboarding
    // setTimeout(() => {
    //   if (obaDomains.includes(domain)) {
    //     history.push(AppRoutes.OBADASHBOARD); //OBA
    //   } else if (hbaDomains.includes(domain)) {
    //     history.push(AppRoutes.HBADASHBOARD); //HBA
    //   } else {
    //     history.push(AppRoutes.DASHBOARD); //MMA
    //   }
    // }, 1000);
  };

  const sortByOrder = (arr) => {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }
    return sort;
  };

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    console.log("SETTING DEFAULT COLS", briefs);

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        console.log("columnIds", columnIds);
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);

      console.log("updatedBriefIds", updatedBriefIds);
      const newDefaultColumn = updatedBriefIds?.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      console.log("Background Table for", clientMatterId, backgroundTable);

      if (backgroundTable?.data?.backgroundTable) {
        await setDefaultColumnBriefs(
          backgroundTable?.data?.backgroundTable,
          briefs
        );
      } else {
        console.log("Create Background Table for ", clientMatterId);

        const initialPresets = [];
        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          initialPresets.push(preset);
        }

        let backgroundCreate = await API.graphql({
          query: BACKGROUND_TABLE_CREATE,
          variables: {
            clientMatterId: clientMatterId,
            initialPresets: initialPresets,
          },
        });
        await setDefaultColumnBriefs(
          backgroundCreate?.data?.backgroundTableCreate,
          briefs
        );
      }
      // }
    } catch (error) {
      console.error(error);
    }
  }

  // async function setDefaultColumnBriefs(backgroundTables, briefs) {
  //   console.group("setDefaultColumnBriefs");
  //   try {
  //     const { id, columns } = backgroundTables;

  //     let invoicePreset;
  //     let variationPreset;
  //     let builderDetailsPreset;
  //     let provisionalSumPreset;
  //     let contractDetailsPreset;
  //     let budgetPreset;
  //     let primeCostPreset;

  //     console.log("Current columns columns", columns);
  //     console.log("defaultColumnIds:", defaultColumnIds);

  //     if (id) {
  //       let presets = [];
  //       const commentsColPresets = [];

  //       for (const item of briefs) {
  //         console.log("ITEM IS:", item);
  //         const preset = { id: item.id, name: item.name };
  //         if (item.name == "Invoices") {
  //           invoicePreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Variations") {
  //           variationPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Provisional Sum") {
  //           provisionalSumPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Prime Cost") {
  //           primeCostPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Budget") {
  //           budgetPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Builder Details") {
  //           builderDetailsPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Contract Details") {
  //           contractDetailsPreset = { id: item.id, name: item.name };
  //         }

  //         presets.push(preset);

  //         if (item.hasCommentAccess) {
  //           const commentPreset = { ...preset };
  //           commentsColPresets.push(commentPreset);
  //         }
  //       }
  //       console.log("presets", presets);
  //       let isDefaultPresent = false;
  //       let isLabelPresent = false;
  //       let isInvoicePresent = false;
  //       let isVariationPresent = false;
  //       let isProvisionalSumPresent = false;
  //       let isBuilderDetailsPresent = false;
  //       let isContractDetailsPresent = false;
  //       let isBudgetPresent = false;
  //       let isPrimeCostPresent = false;
  //       let breakDownPresetList;

  //       // Filters out null columns
  //       let filteredColumns = [];
  //       columns.map((obj) => {
  //         if (obj.headerText === null) return false;

  //         // If column is not duplicate
  //         if (!filteredColumns.some((e) => e.headerText === obj.headerText)) {
  //           // Remove null and undefined presets
  //           let filteredCol = {...obj}
  //           if (filteredCol?.presets) {
  //             filteredCol.presets = obj.presets.filter(
  //               (preset) => preset !== null && preset !== undefined
  //             );
  //           }

  //           filteredColumns.push(filteredCol);
  //         }
  //       });

  //       console.log("filteredColumns", filteredColumns)
  //       let defaultCols = [
  //         filteredColumns.find((col) => col.headerText === "Date"),
  //         filteredColumns.find((col) => col.headerText === "Gmail"),
  //         filteredColumns.find((col) => col.headerText === "Description of Background"),
  //         filteredColumns.find((col) => col.headerText === "Comments"),
  //         filteredColumns.find((col) => col.headerText === "Document"),
  //       ].filter((col) => col !== undefined && col !== null && col);

  //       // Sorts column with default column containing their default IDs.
  //       let sortedColumns = [
  //         ...defaultCols,
  //         ...filteredColumns.filter((col) =>
  //           !defaultCols.some((defaultCol) =>
  //               col.headerText === defaultCol.headerText)).sort((a, b) =>
  //                 a.order - b.order
  //               )
  //       ]

  //       console.log("sortedColumns", sortedColumns);
  //       let reorganizedColumns = sortedColumns.map((col, index) => ({
  //           ...col,
  //           accessorKey: index.toString(),
  //           id: index.toString(),
  //           order: index + 1,
  //         })
  //       )

  //       console.log("reorganizedColumns", reorganizedColumns)
  //       const defaultColumns = reorganizedColumns.map((obj) => {
  //         var tag;
  //         if (
  //           obj.headerText == "Date" ||
  //           obj.headerText == "Gmail" ||
  //           obj.headerText == "Description of Background" ||
  //           obj.headerText == "Comments" ||
  //           obj.headerText == "Document"
  //         ) {
  //           isDefaultPresent = true;
  //         }
  //         if (obj.headerText == "Label") {
  //           isLabelPresent = true;
  //         }
  //         if (
  //           obj.headerText == "Claim Status" ||
  //           obj.headerText == "Payment Status" ||
  //           obj.headerText == "Claim Amount ($)" ||
  //           obj.headerText == "Due Date for Claim"
  //         ) {
  //           tag = "Invoices";
  //           isInvoicePresent = true;
  //         }
  //         if (
  //           obj.headerText == "Variation Status" ||
  //           obj.headerText == "Variation Cost" ||
  //           obj.headerText == "Variation Cost ($)"
  //         ) {
  //           tag = "Variations";
  //           isVariationPresent = true;
  //         }
  //         if (
  //           obj.headerText == "Provisional Amount" ||
  //           obj.headerText == "Cost Amount" ||
  //           obj.headerText == "Description of Work" ||
  //           obj.headerText == "Paid to Date Amount" ||
  //           obj.headerText == "Action"
  //         ) {
  //           tag = "Provisional Sum";
  //           isProvisionalSumPresent = true;
  //         }
  //         if (
  //           obj.headerText == "Builder Name" ||
  //           obj.headerText == "Builder Logo" ||
  //           obj.headerText == "Builder Contact Name" ||
  //           obj.headerText == "Builder Contact Email" ||
  //           obj.headerText == "Builder Contact Number"
  //         ) {
  //           tag = "Builder Details";
  //           isBuilderDetailsPresent = true;
  //         }

  //         if (
  //           obj.headerText == "Start Date" ||
  //           obj.headerText == "Contract Price" ||
  //           obj.headerText == "Contract Value"
  //         ) {
  //           isContractDetailsPresent = true;
  //           tag = "Contract Details";
  //         }

  //         console.log("obj.presets", obj.preset);
  //         breakDownPresetList = presets.filter((preset) =>
  //           preset.name.includes("_")
  //         );
  //         console.log("Break Down Preset List:", breakDownPresetList);
  //         return {
  //           ...obj,
  //           presets:
  //             obj.headerText == "Label"
  //               ? presets?.length
  //                 ? presets.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       preset.name !== "Builder Details" &&
  //                       preset.name !== "Variations" &&
  //                       preset.name !== "Provisional Sum" &&
  //                       preset.name !== "Budget" &&
  //                       preset.name !== "Prime Cost" &&
  //                       !preset.name.includes("_")
  //                   )
  //                 : []
  //               : obj.headerText === "Cost Amount"
  //               ? [...obj.presets, ...breakDownPresetList]
  //               : obj.headerText == "Gmail" || obj.headerText === "Email Date"
  //               ? presets?.length
  //                 ? presets.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       preset.name !== "Builder Details" &&
  //                       !preset.name.includes("_")
  //                   )
  //                 : []
  //               : isVariationPresent ||
  //                 isInvoicePresent ||
  //                 isProvisionalSumPresent ||
  //                 isPrimeCostPresent
  //               ? obj.presets
  //               : isBuilderDetailsPresent || isContractDetailsPresent
  //               ? obj.presets.filter((preset) => preset.name === tag)
  //               : obj.headerText === "Description of Background" ||
  //                 obj.headerText === "Date"
  //               ? presets?.length
  //                 ? presets.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       preset.name !== "Builder Details"
  //                   )
  //                 : []
  //               : obj.headerText === "Document"
  //               ? presets?.length
  //                 ? presets?.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       !preset.name.includes("_")
  //                   )
  //                 : []
  //               : presets,
  //         };
  //       });
  //       console.log("Default Columns:", defaultColumns);

  //       // console.log("INVOICE PRESET:", invoicePreset);

  //       let newColumns = [...defaultColumns];

  //       console.log("isInvoicePresent", isInvoicePresent);
  //       console.log("isVariationPresent", isVariationPresent);
  //       console.log("isBuilderDetailsPresent", isBuilderDetailsPresent);
  //       console.log("isContractDetailsPresent", isContractDetailsPresent);
  //       console.log("isProvisionalSumPresent", isProvisionalSumPresent);
  //       console.log("isBudgetPresent", isBudgetPresent);
  //       console.log("isPrimeCostPresent", isPrimeCostPresent);

  //       if (
  //         !isDefaultPresent ||
  //         !isLabelPresent ||
  //         !isInvoicePresent ||
  //         !isVariationPresent ||
  //         !isProvisionalSumPresent ||
  //         !isBuilderDetailsPresent ||
  //         !isContractDetailsPresent ||
  //         !isPrimeCostPresent
  //       ) {
  //         if (!isDefaultPresent) {
  //           let newColumnsAdjusted = newColumns.map((col) => ({
  //             ...col,
  //             id: (parseInt(col.id) + 5).toString(),
  //             accessorKey: (parseInt(col.accessorKey) + 5).toString(),
  //             order: col.order + 5,
  //           }));

  //           newColumns = [
  //             {
  //               accessorKey: "0",
  //               id: "0",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   preset.name !== "Builder Details"
  //               ),
  //               headerText: "Date",
  //               type: "DATE",
  //               enabled: true,
  //               order: 0,
  //             },
  //             {
  //               accessorKey: "1",
  //               id: "1",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   preset.name !== "Builder Details" &&
  //                   !preset.name.includes("_")
  //               ),
  //               headerText: "Gmail",
  //               type: "READONLY",
  //               enabled: true,
  //               order: 1,
  //             },
  //             {
  //               accessorKey: "2",
  //               id: "2",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   preset.name !== "Builder Details" &&
  //                   !preset.name.includes("_")
  //               ),
  //               headerText: "Description of Background",
  //               type: "TEXTAREA",
  //               enabled: true,
  //               order: 2,
  //             },
  //             {
  //               accessorKey: "3",
  //               id: "3",
  //               presets: commentsColPresets,
  //               headerText: "Comments",
  //               type: "TEXTAREA",
  //               enabled: true,
  //               order: 3,
  //             },
  //             {
  //               accessorKey: "4",
  //               id: "4",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   !preset.name.includes("_")
  //               ),
  //               headerText: "Document",
  //               type: "READONLY",
  //               enabled: true,
  //               order: 4,
  //             },
  //             ...newColumnsAdjusted,
  //           ];
  //         }
  //         if (!isLabelPresent) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: presets,
  //               headerText: "Label",
  //               type: "READONLY",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //           ];
  //         }
  //         if (
  //           !isInvoicePresent &&
  //           !isVariationPresent &&
  //           !isProvisionalSumPresent &&
  //           !isPrimeCostPresent
  //         ) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [invoicePreset],
  //               headerText: "Due Date for Claim",
  //               type: "DATE",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [invoicePreset],
  //               headerText: "Claim Status",
  //               type: "DROPDOWN",
  //               enabled: true,
  //               optionsText: ["Claimed", "Unclaimed"],
  //               order: newColumns.length + 1,
  //             },
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [invoicePreset],
  //               headerText: "Payment Status",
  //               type: "DROPDOWN",
  //               enabled: true,
  //               optionsText: ["Paid", "Unpaid"],
  //               order: newColumns.length + 2,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 1).toString(),
  //               id: (newColumns.length + 1).toString(),
  //               presets: [invoicePreset],
  //               headerText: "Claim Amount ($)",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 3,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 2).toString(),
  //               id: (newColumns.length + 2).toString(),
  //               presets: [variationPreset],
  //               headerText: "Variation Status",
  //               type: "DROPDOWN",
  //               enabled: true,
  //               optionsText: ["Approved", "For Approval"],
  //               order: newColumns.length + 4,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 3).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [variationPreset],
  //               headerText: "Variation Cost ($)",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 5,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 4).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //               headerText: "Provisional Amount",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 6,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 5).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //               headerText: "Cost Amount",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 7,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 6).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //               headerText: "Description of Work",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 8,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 7).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               // to add budget preset
  //               presets: [
  //                 provisionalSumPreset,
  //                 variationPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //               ],
  //               headerText: "Paid to Date Amount",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 9,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 8).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               // to add budget preset
  //               presets: [
  //                 provisionalSumPreset,
  //                 variationPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //               ],
  //               headerText: "Action",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 10,
  //             },
  //           ];
  //         }
  //         if (!isBuilderDetailsPresent) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Name",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 1).toString(),
  //               id: (newColumns.length + 1).toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Contact Name",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 1,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 2).toString(),
  //               id: (newColumns.length + 2).toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Contact Email",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 2,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 3).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Contact Number",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 3,
  //             },
  //           ];
  //         }
  //         if (!isContractDetailsPresent) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [contractDetailsPreset],
  //               headerText: "Start Date",
  //               type: "DATE",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 1).toString(),
  //               id: (newColumns.length + 1).toString(),
  //               presets: [contractDetailsPreset],
  //               headerText: "Contract Price",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 1,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 2).toString(),
  //               id: (newColumns.length + 2).toString(),
  //               presets: [contractDetailsPreset],
  //               headerText: "Contract Value",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 2,
  //             },
  //           ];
  //         }
  //       } else {
  //         //Filter Columns & set it to default HBA presets
  //         let filterBriefs = [];
  //         defaultColumns.map((obj) => {
  //           if (
  //             obj.headerText == "Claim Status" ||
  //             obj.headerText == "Payment Status" ||
  //             obj.headerText == "Claim Amount ($)" ||
  //             obj.headerText == "Due Date for Claim"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [invoicePreset],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Variation Status" ||
  //             obj.headerText == "Variation Cost" ||
  //             obj.headerText == "Variation Cost ($)"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [variationPreset],
  //             });
  //           }

  //           if (obj.headerText == "Cost Amount") {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [
  //                 provisionalSumPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //                 ...breakDownPresetList,
  //               ],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Provisional Amount" ||
  //             obj.headerText == "Description of Work"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Builder Name" ||
  //             obj.headerText == "Builder Logo" ||
  //             obj.headerText == "Builder Contact Name" ||
  //             obj.headerText == "Builder Contact Email" ||
  //             obj.headerText == "Builder Contact Number"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [builderDetailsPreset],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Start Date" ||
  //             obj.headerText == "Contract Price" ||
  //             obj.headerText == "Contract Value"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [contractDetailsPreset],
  //             });
  //           }

  //           if (
  //             obj.headerText === "Paid to Date Amount" ||
  //             obj.headerText === "Action"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               // to add budget preset
  //               presets: [
  //                 provisionalSumPreset,
  //                 variationPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //               ],
  //             });
  //           }

  //           if (
  //             obj.headerText !== "Start Date" &&
  //             obj.headerText !== "Contract Price" &&
  //             obj.headerText !== "Contract Value" &&
  //             obj.headerText !== "Claim Status" &&
  //             obj.headerText !== "Payment Status" &&
  //             obj.headerText !== "Claim Amount ($)" &&
  //             obj.headerText !== "Due Date for Claim" &&
  //             obj.headerText !== "Variation Status" &&
  //             obj.headerText !== "Variation Cost" &&
  //             obj.headerText !== "Variation Cost ($)" &&
  //             obj.headerText !== "Description of Work" &&
  //             obj.headerText !== "Provisional Amount" &&
  //             obj.headerText !== "Cost Amount" &&
  //             obj.headerText !== "Paid to Date Amount" &&
  //             obj.headerText !== "Action" &&
  //             obj.headerText !== "Builder Name" &&
  //             obj.headerText !== "Builder Logo" &&
  //             obj.headerText !== "Builder Contact Name" &&
  //             obj.headerText !== "Builder Contact Email" &&
  //             obj.headerText !== "Builder Contact Number" &&
  //             obj.headerText !== "Start Date" &&
  //             obj.headerText !== "Contract Price" &&
  //             obj.headerText !== "Contract Value"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //             });
  //           }
  //         });
  //         console.log("filtered Briefs:", filterBriefs);
  //         newColumns = filterBriefs.map(col => ({
  //           ...col,
  //           presets: col.presets.filter(preset =>
  //             preset !== undefined && preset !== null && preset)
  //         }));
  //       }

  //       console.log(id, "new columns:", newColumns);

  //       const variables = {
  //         id,
  //         input: {
  //           columns: newColumns,
  //         },
  //       };

  //       console.log("VARIABLE IS:", variables);

  //       const updateBriefTable = await API.graphql({
  //         query: UPDATE_BACKGROUND_TABLE_MUTATION,
  //         variables,
  //       });

  //       console.log("updateBriefTable", updateBriefTable);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     console.groupEnd("setDefaultColumnBriefs");
  //   }
  //   console.groupEnd("setDefaultColumnBriefs");
  // }

  // async function setDefaultColumnBriefs(backgroundTables, briefs) {
  //   console.group("setDefaultColumnBriefs");
  //   try {
  //     const { id, columns } = backgroundTables;

  //     console.log("Current columns columns", columns);
  //     console.log("defaultColumnIds:", defaultColumnIds);

  //     if (!id) return; // If no id, exit function

  //     const presets = [];
  //     const commentsColPresets = [];

  //     let invoicePreset, variationPreset, builderDetailsPreset, contractDetailsPreset;

  //     briefs.forEach(({ id, name, hasCommentAccess }) => {
  //       // console.log("ITEM IS:", item);
  //       const preset = { id, name };
  //       presets.push(preset);

  //       if (name === "Invoices") invoicePreset = preset;
  //       if (name === "Variations") variationPreset = preset;
  //       if (name === "Builder Details") builderDetailsPreset = preset;
  //       if (name === "Contract Details") contractDetailsPreset = preset;

  //       if (hasCommentAccess) {
  //         commentsColPresets.push({ ...preset });
  //       }
  //     });

  //     console.log("presets", presets);

  //     const tagMap = {
  //       "Claim Status": "Invoices",
  //       "Payment Status": "Invoices",
  //       "Claim Amount ($)": "Invoices",
  //       "Due Date for Claim": "Invoices",
  //       "Variation Status": "Variations",
  //       "Variation Cost": "Variations",
  //       "Builder Name": "Builder Details",
  //       "Builder Logo": "Builder Details",
  //       "Builder Contact Name": "Builder Details",
  //       "Builder Contact Email": "Builder Details",
  //       "Builder Contact Number": "Builder Details",
  //       "Start Date": "Contract Details",
  //       "Contract Price": "Contract Details",
  //       "Contract Value": "Contract Details",
  //     };

  //     const isPresentMap = {
  //       Invoices: false,
  //       Variations: false,
  //       "Builder Details": false,
  //       "Contract Details": false,
  //     };

  //     const newColumns = columns.map((obj) => {
  //       const tag = tagMap[obj.headerText];
  //       if (tag) isPresentMap[tag] = true;

  //       return {
  //         ...obj,
  //         presets: isPresentMap[tag] ? obj.presets : presets.filter(p => p.name !== "Contract Details" && p.name !== "Builder Details"),
  //       };
  //     });

  //     console.log('Default Briefs:', newColumns);

  //     // Add missing columns if necessary...

  //     console.log(id, "new columns:", newColumns);

  //     const variables = {
  //       id,
  //       input: {
  //         columns: newColumns,
  //       },
  //     };

  //     console.log("VARIABLE IS:", variables);

  //     const updateBriefTable = await API.graphql({
  //       query: UPDATE_BACKGROUND_TABLE_MUTATION,
  //       variables,
  //     });

  //     console.log("updateBriefTable", updateBriefTable);
  //   } catch (error) {
  //     console.error(error);
  //     console.groupEnd("setDefaultColumnBriefs");
  //   }
  //   console.groupEnd("setDefaultColumnBriefs");
  // }

  const dataWrapper = {
    questions: Questions,
    initialStates: initialStates,
    state: state,
    dispatch: dispatch,
    reducer: reducer,
    builderDetailsBrief: builderDetailsBrief,
    contractDetailsBrief: contractDetailsBrief,
    variationsBrief: variationsBrief,
    invoicesBrief: invoicesBrief,
    progressOfWorkBrief: progressOfWorkBrief,
    progressPhotoBrief: progressPhotoBrief,
    projectLocationBrief: projectLocationBrief,
    contractsBrief: contractsBrief,
    contractsBriefFiles: contractsBriefFiles,
    siteDiaryBrief: siteDiaryBrief,
    page: page,
    pageNumber: pageNumber,
    clientMatterId: clientMatterId,
    showToast: showToast,
    setShowToast: setShowToast,
    setAlertMessage: setAlertMessage,
  };

  const [saving, setSaving] = useState(false);

  const [forceRedirect, setForceRedirect] = useState(
    localStorage.getItem("onboardingProgress")
  );

  useEffect(() => {
    //localStorage.setItem("onboardingProgress", "false");
    console.log(
      "onboardingProgress",
      localStorage.getItem("onboardingProgress")
    );
    if (forceRedirect === true) {
      setForceRedirect(true);
      console.log("done woth onboarding");
      history.push(`${AppRoutes.HBADASHBOARD}`);
    }
  }, [forceRedirect]);

  return (
    <div className="flex flex-col gap-5 py-5 relative h-full">
      <div className="mx-auto w-3/4 px-8">
        <img
          className="object-scale-down h-12 w-32"
          src={process.env.REACT_APP_HEADER_LOGO}
          alt="app-logo"
        />
      </div>
      {/* {clientMatterId && <MultiStepForm dataWrapper={dataWrapper} />} */}
      <MultiStepForm dataWrapper={dataWrapper} />

      {showToast && (
        <div className="absolute">
          <ToastNotification title={alertMessage} error={true} />
        </div>
      )}
    </div>
  );
}
