
export const appStateReducer = (state, action) => {
  switch (action.type) {
    
    case 'CHANGE_TAB':
      return {
        ...state,
        activeTab: action.payload
      };

    case 'CHANGE_SETTINGS_TAB':
      return {
        ...state,
        settings: {
          ...state.settings,
          currentTab: action.payload
        }
      };
      
    case "SWITCH_PAGE":
      return {
          ...state,
          currentPage: action.payload
      }

    default:
      return state;
  }
}