import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { BsInfoCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

import { datePickerDateFormatter } from "../../shared/userPreferredDateTime";
import MonthYearDatePicker from "../../shared/MonthYearDatePicker";
import ToastNotification from "../toast-notification";
import progressOfWorkIcon from "../../assets/images/progressOfWork-icon.png";

const moment = require("moment");

const CREATE_BACKGROUND_DATE_MUTATION = `
  mutation createBackground($date: AWSDateTime, $order: Int = 1, $briefs: [BriefInput]) {
    backgroundCreate(date: $date, order: $order, briefs: $briefs) {
      id
    }
  }`;

const UPDATE_BACKGROUND_DATE_MUTATION = `
  mutation updateBackground($id: ID, $date: AWSDateTime) {
    backgroundUpdate(id: $id, date: $date) {
      id
      date
    }
  }
`;

export default function ProgressOfWork({
  items,
  redirectUrl,
  completionDateRef,
  handleChangeGetStartedProgress,
}) {
  const history = useHistory();
  const brief = items;

  const [completionDate, setCompletionDate] = useState();
  const [hasBackgroundDate, setHasBackgroundDate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const hideToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    if (completionDate) {
      const backgrounds = brief.backgrounds?.items;

      if (backgrounds.length !== 0) {
        updateBackgroundDate(backgrounds[0]?.id, completionDate);
      } else {
        createBackgroundDate(items, completionDate);
        handleChangeGetStartedProgress("completionDate");
      }
    }
  }, [completionDate]);

  useEffect(() => {
    const backgrounds = brief?.backgrounds?.items ?? [];

    if (backgrounds.length !== 0 && backgrounds[0]?.date) {
      setCompletionDate(backgrounds[0].date);
      setHasBackgroundDate(true);
    } else {
      setHasBackgroundDate(false);
    }
  }, [brief]);

  function visitBrief() {
    history.push(redirectUrl);
  }

  async function createBackgroundDate(brief, completionDate) {
    try {
      await API.graphql({
        query: CREATE_BACKGROUND_DATE_MUTATION,
        variables: {
          briefs: [{ name: brief.name, id: brief.id }],
          date:
            completionDate !== null
              ? moment.utc(completionDate).toISOString()
              : null,
        },
      });
      setHasBackgroundDate(true);
    } catch (e) {
      console.error(e.errors[0].message);
    }
  }

  async function updateBackgroundDate(id, completionDate) {
    try {
      await API.graphql({
        query: UPDATE_BACKGROUND_DATE_MUTATION,
        variables: {
          id: id,
          date:
            completionDate !== null
              ? moment.utc(completionDate).toISOString()
              : null,
        },
      });
      setHasBackgroundDate(true);
    } catch (e) {
      console.error(e.errors[0].message);
    }
  }

  const handleToastNotification = () => {
    setResultMessage("Details successfully updated");
    setShowToast(true);

    setTimeout(() => {
      hideToast();
    }, 1500);
  };

  return (
    <>
      <div
        className=" bg-white shadow rounded-lg border border-neutral-200 overflow-hidden"
        ref={completionDateRef}
        id={"ProgressOfWork"}
      >
        <div className="w-full pt-6 px-8">
          <div className="relative">
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className="z-10 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img
                src={progressOfWorkIcon}
                alt="Progress of Work Icon"
                className="pb-2 w-11 h-auto"
              />
            </div>
            {hasBackgroundDate && (
              <div
                className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
                onClick={visitBrief}
              >
                <FiEdit className="w-4 h-4" />
              </div>
            )}
          </div>

          <div className="gap-1 z-20 relative">
            <div className="justify-start items-center gap-2 pb-5">
              <div className="inline-flex">
                <div className="text-black text-lg font-open-sans font-semibold mr-1">
                  Progress of work
                </div>
                {hasBackgroundDate ? (
                  <div>
                    <BsInfoCircle className="text-xs" />
                  </div>
                ) : null}
              </div>

              {hasBackgroundDate ? (
                <>
                  <div className=" text-cyan-900 text-sm font-open-sans font-semibold pt-1 pb-8">
                    Project Completion Date
                  </div>
                  <div className="w-1/4 md:w-1/3 lg:w-1/4 leading-tight"></div>
                  <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                    <div className="px-4 py-6 bg-gray-100 rounded-2xl flex-col justify-start items-start gap-1 flex">
                      <div className="self-stretch text-red-500 text-xs font-semibold font-open-sans leading-none">
                        {new Date(completionDate).toLocaleDateString("en-US", {
                          weekday: "long",
                        })}
                      </div>
                      <div className="self-stretch text-black text-lg font-semibold font-open-sans leading-7">
                        {new Date(completionDate).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="w-full text-sm font-normal font-open-sans text-gray-600 pt-6 leading-tight"
                  style={{ color: "#5A5A5A" }}
                >
                  Check the current status of the construction and see the
                  projected completion date. Stay informed about each stage of
                  the building process.
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex-col justify-start items-start">
          <div className="w-full justify-center items-center">
            <div className="w-full flex-col justify-start items-start gap-1.5">
              {hasBackgroundDate ? null : (
                <div className="w-full px-4 py-4 border-t border-neutral-200">
                  <MonthYearDatePicker
                    popperProps={{
                      positionFixed: true,
                    }}
                    popperPlacement="top-start"
                    className="border w-full rounded-lg text-sm p-3 font-open-sans border-gray-300 "
                    dateFormat={datePickerDateFormatter(
                      localStorage.getItem("userpreferredDateFormatType") ==
                        "null"
                        ? "MM-DD-YYYY"
                        : localStorage.getItem("userpreferredDateFormatType")
                    )}
                    selected={completionDate}
                    // placeholderText="Completion Date"
                    placeholderText={
                      localStorage.getItem("userpreferredDateFormatType") ==
                      "null"
                        ? "MM-DD-YYYY"
                        : localStorage.getItem("userpreferredDateFormatType")
                    }
                    onCalendarClose={(selected) => {
                      setCompletionDate(selected);
                      handleToastNotification();
                    }}
                    shouldCloseOnSelect={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showToast ? (
        <ToastNotification
          title={resultMessage}
          error={
            resultMessage === "Details successfully updated" ? false : true
          }
        />
      ) : null}
    </>
  );
}
