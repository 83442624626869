import React ,{useState,useEffect} from "react";
import Research from "./research";
import ResearchOutput from "./research-ouput";
import ContentOutlining from "./content-outlining";
import OutlineOutput from "./outline-output";
import axios from 'axios';


// In-progress component to expedite content production for marketing through 3 phases:
// Research
// Content Outlining and Drafting
// SEO Optimization

const ContentMachine = () => {
    const [textareas, setTextareas] = useState([
        {
            id: 1,
            value: 'Below are the questions I want you to research: 1. I want you to identify the top 5 populated areas within Griffith, New South Wales, Australia 2. I want you to identify the top 2 ranking search result URLs for each of these keyword phrases: Criminal lawyer Griffith Family lawyer Griffith Property lawyer Griffith',
        },
        {
            id: 2,
            value: 'Identify and suggest an image of the location, activity or event that Griffith is famous for. This will be the unique information for the service page. Also indicate a short description and alt text suggestion.'
        }
    ]);

    const [systemInstruction, setSystemInstruction] = useState("I want you to act as an expert researcher. Our main objective is to create service page targeting specific location in Australia. The following questions will be used later when i ask you.");
    const [phase,setPhase] = useState("research");
    const [conversationHistory, setConversationHistory] = useState("");
    const [response,setResponse] = useState("");
    const [imgURL,setImgURL] = useState(null);
    const [isLoading,setIsLoading] = useState(false); 
    const [deleteIsClicked,setDeletedIsClicked] = useState(false);
    const [outline, setOutline] = useState("");
    const [file,setFile] = useState([]);
    // const [assistantsId,setAssistantsId] = useState();
    // const [threadId,setThreadId] = useState();
    const additionalInstruction = "Always enumerate links using - not numbers, never use placeholders .";

    const postFile = async (uploadedFile) => {
        try {
          const formData = new FormData();
          formData.append('file', uploadedFile);
          formData.append('purpose', 'assistants');
    
          const fileResponse = await axios.post(
            "https://api.openai.com/v1/files",
            formData,  // sending FormData directly
            {
              headers: {
                "Content-Type": "multipart/form-data", // Removed this line
                Authorization: `Bearer ${process.env.REACT_APP_CHATGPT_API}`
              }
            },
          );
          console.log("file is", fileResponse.data);
          setFile(prevFileId => [...prevFileId,fileResponse.data.id]);
        } catch (error) {
          console.error("Error getting completion:", error);
        }
    };
    const handleTextareaChange = (id, value) => {
        setTextareas(prevTextareas =>
        prevTextareas.map(textarea =>
            textarea.id === id ? { ...textarea, value } : textarea
        )
        );
    };

    const containsImageKeywords = (inputString) => {
        const keywords = ['images', 'image', 'photos', 'photo', 'picture', 'pictures'];
        const regex = new RegExp(keywords.join('|'), 'i'); 
    
        return regex.test(inputString);
    };
    textareas.map((text) => console.log("Textttttt",text.value));
    console.log(textareas);

    const makeAPICall = async (
        prompt,
        systemInstruction,
    ) => {
        const api_key = process.env.REACT_APP_CHATGPT_API;
        const client = axios.create({
            headers:{
                Authorization: "Bearer " + api_key,
            },
        });

        let containsImages = containsImageKeywords(prompt);
    
        if(containsImages){
            let newPrompt = conversationHistory + prompt;
            generateImage(newPrompt);
            console.log(imgURL);
        }
        const params = {
            model : "gpt-4",
            messages : [
                {
                    "role": "system", 
                    "content": systemInstruction
                },
                {
                    "role": "user",
                    "content":prompt
                }
            ],
            max_tokens: 4000,
            temperature: 0.5,
        };

        await client.post("https://api.openai.com/v1/chat/completions",params)
        .then( (result) => {
            console.log(result.data.choices[0].message.content);
            setConversationHistory(
                (previousConvo) => previousConvo + "\n" + result.data.choices[0].message.content + "\n"
            );
            setResponse((previousResponse) => previousResponse + "\n" +result.data.choices[0].message.content +"\n");
        }).catch((error) => {
            console.log(error);
        })
    }
    const generateImage = async (prompt) => {
        const api_key = process.env.REACT_APP_CHATGPT_API;
        const client = axios.create({
            headers:{
                Authorization: "Bearer " + api_key,
            },
        });

        const params =  {
            model: "dall-e-3",
            prompt:prompt,
            size:"1024x1024",
            quality:"standard",
            n: 1
       };

       await client.post("https://api.openai.com/v1/images/generations",params)
        .then( (result) => {
            console.log(result.data);
            setImgURL(result.data.data[0].url);

        }).catch((error) => {
            console.log(error);
        })
    }
    const handleResearchPrompt = () => {
        setIsLoading(true)
        setResponse("");
        setImgURL(null);
        textareas.map(async (text) => {
            if(text.value){
                console.log(text.value);
                let propmtWithHistory = conversationHistory + text.value;
                let newInstruction = systemInstruction + additionalInstruction;
                await makeAPICall(propmtWithHistory ,newInstruction);
            }
        });
        setTimeout( () => {
            setIsLoading(false)
          }, 25000); 
        
    }
    // useEffect(()=>{
    //     if(threadId !== undefined && assistantsId !== undefined){
    //         console.log(threadId);
    //         console.log(assistantsId);
    //     }
    // },[threadId,assistantsId])
    const handleDeleteTextarea = (id) => {
        const updatedTextareas = textareas.filter((textarea) => textarea.id !== id);
    
        setTextareas(updatedTextareas);
      };

      function escapeDoubleQuotes(inputString) {
        return inputString.replace(/""/g, '\\"');
    }
      const outlineAPICall = async ({
        prompt,
        systemInstruction,
        files
    }) => {
        console.log(prompt);
        // Still in progress
        // try {
        //     await createThread(prompt, systemInstruction, files);
        //     await createAssistants(files);
        // } catch (error) {
        //     console.error("Error in outlineAPICall:", error);
        // }
        const api_key = process.env.REACT_APP_CHATGPT_API;
        const client = axios.create({
            headers:{
                Authorization: "Bearer " + api_key,
            },
        });
        const params = {
            model : "gpt-4-1106-preview",
            messages : [
                {
                    "role": "system", 
                    "content": systemInstruction
                },
                {
                    "role": "user",
                    "content":prompt,
                }
            ],
            max_tokens: 4000,
            temperature: 0.5
        };

        await client.post("https://api.openai.com/v1/chat/completions",params)
        .then( (result) => {
            console.log(result.data.choices[0].message.content);
            setConversationHistory(
                (previousConvo) => previousConvo + "\n" + result.data.choices[0].message.content + "\n"
            );
            setOutline(result.data.choices[0].message.content);
        }).catch((error) => {
            console.log(error);
        })
}
    // const createThread = async (prompt, systemInstruction, files) => {
    //     try {
    //         const api_key = process.env.REACT_APP_CHATGPT_API;
    //         const client = axios.create({
    //             headers: {
    //                 Authorization: "Bearer " + api_key,
    //                 "OpenAI-Beta": "assistants=v1"
    //             },
    //         });

    //         const params = {
    //             "messages": [
    //                 {
    //                     "role": "user",
    //                     "content": prompt,
    //                     "file_ids": [files]
    //                 }
    //             ]
    //         };

    //         const result = await client.post("https://api.openai.com/v1/threads", params);
    //         const thread = result.data.id;
    //         console.log("Thread created with ID:", threadId);
    //         setThreadId(thread);
    //     } catch (error) {
    //         console.error("Error creating thread:", error);
    //     }
    // }
    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        const { files } = e.dataTransfer;
    
        if (files.length > 0) {
          const file1 = files[0];
    
          console.log(file1);
          await postFile(file1);
        }
      };
    // const createAssistants = async (files) => {
    //     try {
    //         const api_key = process.env.REACT_APP_CHATGPT_API;
    //         const client = axios.create({
    //             headers: {
    //                 Authorization: "Bearer " + api_key,
    //                 "OpenAI-Beta": "assistants=v1"
    //             },
    //         });
    
    //         const params = {
    //             instructions: systemInstruction,
    //             model: "gpt-4-1106-preview",
    //             tools: [{ "type": "code_interpreter" }],
    //             file_ids: [files]
    //         };
    
    //         const result = await client.post("https://api.openai.com/v1/assistants", params);
    //         const assistantsId = result.data.id;
    //         console.log("Assistants created with ID:", assistantsId);
    //         setAssistantsId(assistantsId);
    //     } catch (error) {
    //         console.error("Error creating assistants:", error);
    //     }
    // }
    const handleOutlinePrompt = async () => {
        setIsLoading(true);
    
        let assistantsId;
        let threadId;
        const api_key = process.env.REACT_APP_CHATGPT_API;
        const client = axios.create({
            headers: {
                Authorization: "Bearer " + api_key,
                "OpenAI-Beta": "assistants=v1"
            },
        });
        // createAssistants(file);
        textareas.map(async (text) => {
            let prompt;
            if (text.value) {
                prompt = escapeDoubleQuotes(text.value);
            }
            try {
        
                const params = {
                    instructions: systemInstruction,
                    model: "gpt-4-1106-preview",
                    tools: [{ "type": "code_interpreter" }],
                    file_ids: file
                };
        
                const result = await client.post("https://api.openai.com/v1/assistants", params);
                assistantsId = result.data.id;
                console.log("Assistants created with ID:", assistantsId);
                // setAssistantsId(assistantsId);
            } catch (error) {
                console.error("Error creating assistants:", error);
            }
            try {
                const result = await client.post("https://api.openai.com/v1/threads");
                console.log("Thread created with ID:", threadId);
                threadId = result.data.id;
            } catch (error) {
                console.error("Error creating thread:", error);
            }
            try {
                console.log(prompt);
                console.log(assistantsId)
                console.log(threadId)
                console.log(file)
                const params = {
                    "role":"user",
                    "content":prompt,
                    "assistant_id":assistantsId,
                    "file_ids": file
                };
                const response = await client.post(`https://api.openai.com/v1/threads/${threadId}/messages`,params);
                console.log("Message:", response);
                setOutline(response.data[0]?.content[0]?.text?.value);
            } catch (error) {
                console.error("Error creating Message:", error);
            }
        });
    
        setTimeout(() => {
            setIsLoading(false);
        }, 65000);
    };


    const textareaStyle = {
        width: '75%',
        height: "80px",
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        marginBottom: '10px',
        boxSizing: 'border-box',
        resize: 'none',
      };
    return (

        <div
             className="justify-center items-center mt-8 flex flex-col z-50"
             style={{
                width:"100%",
            }}
        >
            {
               phase === "research" && response === "" ? (
                <Research 
                    setTextareas={setTextareas}
                    textareas={textareas}
                    handleTextareaChange={handleTextareaChange}
                    handleResearchPrompt={handleResearchPrompt}
                    systemInstruction={systemInstruction}
                    setSystemInstruction={setSystemInstruction}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    handleDeleteTextarea={handleDeleteTextarea}
                    deleteIsClicked={deleteIsClicked}
                    setDeleteIsClicked={setDeletedIsClicked} 
                />
            ) : (
                phase === "research" && response !== "" ? (
                    <ResearchOutput
                        setPhase={setPhase}
                        response={response}
                        textareas={textareas}
                        setTextareas={setTextareas}
                        handleTextareaChange={handleTextareaChange}
                        imgURL={imgURL}
                        handleResearchPrompt={handleResearchPrompt}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        handleDeleteTextarea={handleDeleteTextarea}
                        setDeleteIsClicked={setDeletedIsClicked}
                    />
                ) : (
                    phase === "outline" && outline === "" ? (
                        <ContentOutlining 
                            textareas={textareas}
                            setTextareas={setTextareas}
                            phase={phase}
                            handleDeleteTextarea={handleDeleteTextarea}
                            researchResponse={response}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setSystemInstruction={setSystemInstruction}
                            systemInstruction={systemInstruction}
                            deleteIsClicked={deleteIsClicked}
                            handleTextareaChange={handleTextareaChange}
                            file={file}
                            setFile={setFile}
                            postFile={postFile}
                            handleOutlinePrompt={outlineAPICall}
                            outline={outline}
                            setOutline={setOutline}
                            handleDrop={handleDrop}
                        />
                    ) : (
                        phase === "outline" && outline !== "" ? (
                            <OutlineOutput
                                textareas={textareas}
                                setTextareas={setTextareas}
                                phase={phase}
                                handleDeleteTextarea={handleDeleteTextarea}
                                researchResponse={response}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                setSystemInstruction={setSystemInstruction}
                                systemInstruction={systemInstruction}
                                deleteIsClicked={deleteIsClicked}
                                handleTextareaChange={handleTextareaChange}
                                file={file}
                                setFile={setFile}
                                postFile={postFile}
                                outline={outline}
                                handleOutlinePrompt={handleOutlinePrompt}
                            />
                        ) : null
                    )
                )
            )
            }

            {/* <div className="flex flex-col justify-center items-center mt-8"
                style={{
                    width:"65vw",
                    height:"75vh"
                }}
             >
                <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    className="justify-center items-center h-36 mx-auto my-8 border p-4 border-dashed rounded-md border-cyan-400"
                    style={{
                        width:"15vw",
                        height:"10vh"
                    }}
                >
                    <p className="mx-auto text-sm text-center  text-cyan-400 font-semibold">
                        DRAG & DROP CSV FILE
                    </p>
                </div>
                {textareas.map(textarea => (
                    <div key={textarea.id} className="justify-center items-center flex flex-col" style={{ width : "100%"}}>
                        <textarea
                            placeholder="Research Prompt"
                            value={textarea.value}
                            onChange={(e) => handleTextareaChange(textarea.id, e.target.value)}
                            style={textareaStyle}
                        />
                    </div>
                ))}
                <button
                    className="w-min text-green-400 border my-2 border-green-400 px-4 py-2 rounded-md"
                    onClick={addPrompt}
                >
                    Add Prompt
                </button>
                {/* <textarea
                    placeholder="Research Prompt"
                    style={textareaStyle}
                />
                <button
                    className="w-min text-green-400 border my-2 border-green-400 px-4 py-2 rounded-md"
                >
                    Add Prompt
                </button>
                <textarea
                    placeholder="Sytem Instruction"
                    style={textareaStyle}
                />
                 <textarea
                    placeholder="Header Prompt"
                    style={textareaStyle}
                />
                <textarea
                    placeholder="System Instruction"
                    style={textareaStyle}
                />
                <button
                    className="w-min text-cyan-400 border border-cyan-400 px-4 py-2 rounded-md"
                    onClick={() => setButtonClicked(true)}
                >
                    Generate Article
                </button>
            </div> */}
        </div>
    )
}
export default ContentMachine;
