import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { BsShareFill } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import Button from "../../shared/Button";
import Select from "react-dropdown-select";
import { result } from "lodash";

const ShareLinkButton = (props) => {
  const [copied, setCopied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [briefs, setBriefs] = useState([]);

  useEffect(() => {
    getBriefs();
    getUserTypes(); //LEGAL ADMIN, OWNER, ETCH
  }, []);

  const mAddUserAcces = `
    mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
      briefAccessTypeTag(briefId:$briefId, userType:$userType) {
        id
      }
    }
    `;

  const mCreateActivity = `
    mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
      activityCreate(
        activity: $activity
        briefId: $briefId
        clientMatterId: $clientMatterId
        companyId: $companyId
        previous: $previous
        field: $field
        current: $current
        appModule: $appModule
        rowId: $rowId
      ) {
        id
      }
    }`;

  const qListUserTypes = `
      query getDefaultUserTypes {
      defaultUserType
    }
    `;

  const listBriefs = `
    query getBriefsByClientMatter($id: ID) {
      clientMatter(id: $id) {
        briefs {
          items {
            id
            name
            hasCommentAccess
            userTypeAccess
            date
            order
            createdAt
            createdBy {
              id
              firstName
              lastName
            }
            updatedAt
            updatedBy {
              id
              firstName
              lastName
            }
            backgrounds(limit: 50, sortOrder: ORDER_ASC) {
              items {
                description 
              }
            }
            
          }
        }
      }
    }
    `;

  const getBriefs = async () => {
    const params = {
      query: listBriefs,
      variables: {
        id: props.matter_id,
      },
    };

    await API.graphql(params).then((brief) => {
      const matterFilesList = brief.data.clientMatter.briefs.items.filter(
        (x) => x.id === props.briefId
      );
      setBriefs(matterFilesList);
    });
  };

  const getUserTypes = async () => {
    const userTypeParams = {
      query: qListUserTypes,
    };

    await API.graphql(userTypeParams).then((userTypes) => {
      if (userTypes.data.defaultUserType) {
        var prev = [];
        userTypes.data.defaultUserType.map((userType) => {
          let oUserType = {
            value: userType,
            label: userType,
          };

          if (oUserType.label === "OWNER") {
            prev = [...prev];
          } else {
            prev = [...prev, oUserType];
          }
          setUserTypes(prev);
        });
      }
    });
  };

  //add user acc
  const handleAddUserAccess = async (e, dataId) => {
    
    try {
      console.log("event", e);
      var bId = dataId;
      var temp = [];

      const origData = e.slice();
      console.log(origData)

      e.map((item) => (temp = [...temp, item.label]));

      var updatedUserTypeAccessArr = e.map(function (item) {
				return { value: item.value, label: item.label };
			});

      console.log("updatedUserTypeAccessArr", updatedUserTypeAccessArr)


      const activityParams = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: props.matter_id,
          briefId: props.briefId,
          activity: `added user access`,
          field: "Background",
          current: updatedUserTypeAccessArr,
          previous: origData,
          appModule: "BACKGROUND",
        },
      };

      const addActivity = await API.graphql(activityParams).then((result) =>
        console.log("ADD ACTIVITY", result)
      );

      const params = {
        query: mAddUserAcces,
        variables: {
          briefId: bId,
          userType: temp,
        },
      };

      const ua = await API.graphql(params);
      console.log("uaa", ua);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href + "&shared=true");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  //get default val
  const defaultUserTypeVal = (data) => {
    const labelsData = data.userTypeAccess;
    if (labelsData !== null && labelsData.length > 0) {
      // Change the logical operator to '&&'
      let labelsDataUnique = labelsData.filter(onlyUnique);

      var temp = [];
      labelsDataUnique.map((item) =>
        item !== null ? (temp = [...temp, { label: item, value: item }]) : temp
      );
      if (
        !temp.some((labels) => labels.label.includes("LEGALADMIN")) &&
        !temp.some((labels) => labels.label.includes("LAWYER"))
      ) {
        temp = [
          ...temp,
          { label: "LEGALADMIN", value: "LEGALADMIN" },
          { label: "LAWYER", value: "LAWYER" },
        ];
        handleAddUserAccess(temp, data.id, data);
      }
      return temp;
    } else {
      return [];
    }
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <Button
          type="button"
          className="h-9 w-16 py-2 px-2 ml-1 mb-1"
          onClick={toggleDropdown}
        >
          <BsShareFill />
        </Button>
      </div>
      {showDropdown && (
        <div className="origin-top-right z-50 absolute right-0 mt-2 w-fit md:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-4">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="text-gray-600 font-semibold mb-4">
                Share to User Types
              </div>
              {briefs.map((data) => (
                <Select
                  values={defaultUserTypeVal(data)}
                  placement="bottom"
                  color="#8a8a8a"
                  multi
                  clearable
                  options={userTypes}
                  searchable
                  keepSelectedInList={true}
                  onChange={(selectedOption) =>
                    handleAddUserAccess(selectedOption, data.id)
                  }
                  placeholder="Select User Type"
                  className="w-full placeholder-blueGray-300 text-blueGray-600 text-xs bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring z-100"
                />
              ))}
              <div className="text-xs text-gray-400 mt-1 mb-8">
                User types will be able to see this background.
              </div>
              <div className="flex justify-center">
                <Button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-48 text-left focus:outline-none"
                  onClick={handleClick}
                >
                  <FaLink />
                  &nbsp;&nbsp;{copied ? "Link Copied!" : "Copy Link"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareLinkButton;
