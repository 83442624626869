
export const appStateReducer = (state, action) => {
  switch (action.type) {

    case 'CHANGE_CURRENT_CATEGORY':
      console.log('Changing current category ', action.payload)
      return {
        ...state,
        currentCategory: action.payload
      };


    case 'ADD_FILTER':
      return {
        ...state,
        formFilters: [
          ...state.formFilters,
          action.payload
        ]
      };

    case 'REMOVE_FILTER':
      return {
        ...state,
        formFilters: state.formFilters.filter((filter)=>
          filter.type !== action.payload
        )
      };

    case 'SET_SORTING':
      return {
        ...state,
        formSorting: action.payload
      };

    case 'SET_SEARCH':
      return {
        ...state,
        formSearchQuery: action.payload
      };



    default:
      return state;
  }
}