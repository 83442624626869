import { defaultPageState, defaultSectionState } from "./StoreProvider";

export const formDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_DETAILS":
      return setFormDetails(state, action)

    case "SET_FORM_CONTENT":
      return setFormContent(state, action)

    case "INITIALIZE_ANSWERS_STATE":
      return initializeAnswerState(state, action)

    case "CHANGE_PAGE_TYPE":
      return changePageType(state, action);

    case "CHANGE_EMAIL_NOTIFICATION":
      return changeEmailNotification(state, action);

    case "ADD_PAGE":
      return addPage(state, action);

    case "CHANGE_HEADER_TITLE":
      return changeHeaderTitle(state, action);

    case "CHANGE_HEADER_DESCRIPTION":
      return changeHeaderDescription(state, action);

    case "DELETE_PAGE":
      return deletePage(state, action)

    case "REORDER_PAGES":
      return reorderPages(state, action)

    case "ADD_SECTION":
      return addSection(state, action);

    case "DELETE_SECTION":
      return deleteSection(state, action);

    case "REORDER_SECTIONS":
      return reorderSections(state, action)

    case "COPY_SECTION":
      return copySection(state, action);

    case "CHANGE_SECTION_QUESTION":
      return changeSectionQuestion(state, action);

    case "CHANGE_SECTION_SUBLABEL":
      return changeSectionSublabel(state, action);

    case "CHANGE_OPTION_TYPE":
      return changeOptionType(state, action);

    case "INITIALIZE_OPTIONS_COUNT":
      return initializeOptionsCount(state, action);

    case "INITIALIZE_PROPERTIES":
      return initializeProperties(state, action);

    case "ADD_OPTION":
      return addOption(state, action);

    case "DELETE_OPTION":
      return deleteOption(state, action);

    case "CHANGE_OPTION_TEXT":
      return changeOptionText(state, action);

    case "TOGGLE_OPTION":
      return toggleOption(state, action);

    // case 'TOGGLE_PROPERTY':
    //   return toggleProperty(state, action)

    case "CHANGE_PROPERTY_VALUE":
      return changePropertyValue(state, action);

    //For Form Preview temporary answers state
    case "SET_ANSWER_SINGLE":
      return setAnswerSingle(state, action)

    case "SET_ANSWER_MULTIPLE":
      return setAnswerMultiple(state, action)

    case "SET_ANSWER_TOGGLE":
      return setAnswerToggle(state, action)

    case "TEST":
      console.log("TEST ACTION");
      return state;

    default:
      return state;
  }
};

/** Initialize form details
 * @param {string} action.payload - formId, formName
 */
const setFormDetails = (state, action) => {
  return{
    ...state,
    formDetails: action.payload
  }
}

/** Initialize form content
 * @param {string} action.payload - pages
 */
const setFormContent = (state, action) => {
  console.log('Initializing Form Content ',action.payload)
  const transformedData = {
    pages: action.payload.items.map((page) => ({
      id: page.id,
      order: page.order,
      name: page.name,
      description: page.description,
      sections: page.fields.items.map((field) => ({
        id: field.id,
        order: field.order,
        question: field.headerText,
        sublabel: field.subText,
        optionsType: field.type,
        options: field.options,
        properties: field.properties
      })),
    })),
  };

  transformedData.pages.sort((a, b) => a.order - b.order)

  transformedData.pages.forEach((page) => {
    page.sections.sort((a, b) => a.order - b.order);
  })
  
  return{
    ...state,
    pages: transformedData.pages
  }
}


const initializeAnswerState = (state, action) => {

  const newAnswers = {}

  state.pages.forEach((page)=>{
    const pageKey = `page_${page.order}`
    newAnswers[pageKey] = {
      name: page.name,
      description: page.description,
    }
    page.sections.forEach((section)=>{
      const sectionKey = `section_${section.order}`
      newAnswers[pageKey][sectionKey] = {
        question: section.question,
        sublabel: section.sublabel,
        optionsType: section.optionsType,
        answers: []
      }
    })
  })

  return{
    ...state,
    formAnswers: newAnswers
  }
}


/** Change the Form page type
 * @param {string} action.payload - Page type value
 */
const changePageType = (state, action) => {
  return {
    ...state,
    pageType: action.payload,
  };
};


/** Change the Form page type
 * @param {string} action.payload - Page type value
 */
const changeEmailNotification = (state, action) => {
  return {
    ...state,
    emailNotification: action.payload,
  };
};


/**
 *  Adds a new page
 * @param {number} action.pageId - The id from the API response
 * @param {number} action.pageOrder - The order from the API response
 */
const addPage = (state, action) => {
  const newPage = { 
    ...defaultPageState,
    id: action.pageId,
    order: action.pageOrder
  };

  return {
    ...state,
    pages: [...state.pages, newPage]

  };

};


/** Change the text value of the header title
 * @param {number} action.pageId - The order property of the current page
 * @param {string} action.payload - Text value for the header title of a page
 */
const changeHeaderTitle = (state, action) => {
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          name: action.payload
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/** Change the text value of the header description
 * @param {number} action.pageId - The order property of the current page
 * @param {string} action.payload - Text value for the header description of a page
 */
const changeHeaderDescription = (state, action) => {
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          description: action.payload
        };
      } else {
        return pageItem;
      }
    }),
  };
};


const deletePage = (state, action) => {

  return {
    ...state,
    pages: state.pages.filter((page)=> page.id !== action.pageId)
  };
}


const reorderPages = (state, action) => {
  console.log('Changing page order')
  return {
    ...state,
    pages: state.pages.map((page)=>{
      const newArrangement = action.payload.find((newPage) => newPage.id === page.id);
      if (newArrangement) {
        return {
          ...page,
          order: newArrangement.order,
        };
      }
      return page;

    })
  }
}


/**
 * Adds a new section in a given page
 * @param {number} action.pageId - The id of the current page
 * @param {number} action.sectionId - The id from the API response
 * @param {number} action.sectionOrder - The order from the API response
 */
const addSection = (state, action) => {
  const newSection = { 
    ...defaultSectionState,
    id: action.sectionId,
    order: action.sectionOrder,
   }

  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        //newSection.order = pageItem.sections.length + 1;
        return {
          ...pageItem,
          sections: [...pageItem.sections, newSection],
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/**
 * Deletes a section in a given page
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 */
const deleteSection = (state, action) => {
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections
            .filter((sectionItem) => sectionItem.id !== action.sectionId)
            .map((item, index) => {
              return {
                ...item,
                order: index + 1,
              };
            }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};


const reorderSections = (state, action) => {

  console.log('Changing section order')
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections
            .map((section, sectionIndex) => {
              const newArrangement = action.payload.find((newSection) => newSection.id === section.id);
              if (newArrangement) {
                return {
                  ...section,
                  order: newArrangement.order,
                };
              }
              return section;
            }),
        };
      } else {
        return pageItem;
      }
    }),
  }
}

// const newArrangement = action.payload.find((newPage) => newPage.id === page.id);
// if (newArrangement) {
//   return {
//     ...page,
//     order: newArrangement.order,
//   };
// }
// return page;


/**
 * Copies the contents of the specified section and creates a new section out of it
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 */
const copySection = (state, action) => {
  const sectionCopy = state.pages
    .find((pageItem) => pageItem.id === action.pageId)
    .sections.find((sectionItem) => sectionItem.id === action.sectionId);
  const newSectionCopy = { ...sectionCopy };

  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        newSectionCopy.order = pageItem.sections.length + 1;
        return {
          ...pageItem,
          sections: [...pageItem.sections, newSectionCopy],
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/**
 * Changes the question text value in a section
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {string} action.payload - The text value of the question in the specified section
 */
const changeSectionQuestion = (state, action) => {
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                question: action.payload,
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/**
 * Changes the sublabel text value in a section
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {string} action.payload - The text value of the sublabel in the specified section
 */
const changeSectionSublabel = (state, action) => {
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                sublabel: action.payload,
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/**
 * Changes the option type of the section
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {string} action.payload.optionsType - The optionsType value of the specified input type
 */
const changeOptionType = (state, action) => {
  const {optionsType, defaultOptions, properties} = action.payload

  // const constructNewProperties = () => {
  //   const newProperties = {
  //     ...defaultSectionState.properties
  //   };
  //   properties.forEach((property) => {
  //     if(property ){
  //       newProperties[property] = null;
  //     }
  //   });
  //   return newProperties;
  // };

  console.log("Change Option Type", action);
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                optionsType: optionsType,
                options: defaultOptions,
                properties: properties

              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/**
 * Populates the input type with the specified number of options
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {number} action.payload - The number of initial options
 */
const initializeOptionsCount = (state, action) => {
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                options: Array(action.payload)
                  .fill(0)
                  .map((_, index) => ({
                    order: index + 1,
                    label: "",
                  })),
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/**
 * Populates the properties with the specified property keys in an array
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {number} action.payload - Array of property keys from inputType obj
 */
const initializeProperties = (state, action) => {
  const constructNewProperties = () => {
    const newProperties = {
      required: false, //{checked: false, value: ''}
      hidden: false, //{checked: false, value: ''}
    };
    action.payload.forEach((property) => {
      newProperties[property] = false; //{checked: false, value: ''}
    });
    return newProperties;
  };

  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                properties: constructNewProperties(),
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};


/**
 * Adds an option to a specified section
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 */
const addOption = (state, action) => {
  console.log("Add Option", action);
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                options: [
                  ...sectionItem.options,
                  ''
                  //{ order: sectionItem.options.length + 1, label: "" },
                ],
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};

/**
 * Deletes an option in a specified section and then renumber it
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {number} action.optionOrder - The order property of the current option
 */
const deleteOption = (state, action) => {
  console.log("Delete Option", action);
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                options: sectionItem.options
                  .filter(
                    (optionItem, optionIndex) => optionIndex !== action.optionOrder
                  )
                  // .map((item, index) => {
                  //   return {
                  //     ...item,
                  //     order: index + 1,
                  //   };
                  // }),
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};

/**
 * Changes the text value of the given option
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {number} action.optionOrder - The order property of the current option
 * @param {string} action.payload - The text value of the option
 */
const changeOptionText = (state, action) => {
  console.log("Change Option Text", action);
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                options: sectionItem.options.map((optionItem, optionIndex) => {
                  if (optionIndex === action.optionOrder) {
                    return action.payload
                  } else {
                    return optionItem;
                  }
                }),
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};

/**
 * Toggle the value of options that have checkboxes
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {number} action.payload - The new array for the options
 */
const toggleOption = (state, action) => {
  console.log("Toggling Option");
  // const checkExistingOption = (sectionItem) => {
  //   const optionExists = sectionItem.options.some(
  //     (optionItem) => optionItem.order === action.payload.order
  //   );

  //   return optionExists
  //     ? sectionItem.options.filter(
  //         (optionItem) => optionItem.order !== action.payload.order
  //       )
  //     : [
  //         ...sectionItem.options,
  //         {
  //           order: action.payload.order,
  //           label: action.payload.label,
  //         },
  //       ];
  // }

  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                options: action.payload,
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};

// /**
//  * Toggle property checkbox state
//  * @param {number} action.pageId - The id property of the current page
//  * @param {number} action.sectionId - The id property of the current section
//  * @param {string} action.propertyKey - The key of the property
//  * @param {string | number} action.payload - New checked state of the property
//  */
// const toggleProperty = (state, action) => {
//   console.log('Toggling Property')
//   return {
//     ...state,
//     pages: state.pages.map((pageItem) => {
//       if (pageItem.id === action.pageId) {
//         return {
//           ...pageItem,
//           sections: pageItem.sections.map((sectionItem) => {
//             if (sectionItem.id === action.sectionId) {
//               return {
//                 ...sectionItem,
//                 properties: {
//                   ...sectionItem.properties,
//                   [action.propertyKey]: {
//                     ...sectionItem.properties[action.propertyKey],
//                     checked: action.payload
//                   }
//                 }
//               }
//             } else {
//               return sectionItem
//             }
//           })
//         }
//       } else {
//         return pageItem
//       }
//     })
//   }
// }

/**
 * Change the value of a property
 * @param {number} action.pageId - The id property of the current page
 * @param {number} action.sectionId - The id property of the current section
 * @param {string} action.propertyKey - The key of the property
 * @param {string | number} action.payload - New value of the property
 */
const changePropertyValue = (state, action) => {
  console.log("Changing Property Value");
  return {
    ...state,
    pages: state.pages.map((pageItem) => {
      if (pageItem.id === action.pageId) {
        return {
          ...pageItem,
          sections: pageItem.sections.map((sectionItem) => {
            if (sectionItem.id === action.sectionId) {
              return {
                ...sectionItem,
                properties: {
                  ...sectionItem.properties,
                  [action.propertyKey]: action.payload,
                },
              };
            } else {
              return sectionItem;
            }
          }),
        };
      } else {
        return pageItem;
      }
    }),
  };
};

// For Form Preview temporary answers state
const setAnswerSingle = (state, action) => {
  console.log('Setting single answer', action)

  return {
    ...state,
    formAnswers: {
      [`page_${action.pageOrder}`]: {
        ...state.formAnswers[`page_${action.pageOrder}`],
        [`section_${action.sectionOrder}`]: {
          ...state.formAnswers[`page_${action.pageOrder}`][`section_${action.sectionOrder}`],
          answers: [action.payload]
        }


      }
    }
  }
}

const setAnswerMultiple = (state, action) => {

  return state
}

const setAnswerToggle = (state, action) => {

  return state
}