import React, { useState, useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { RiFileInfoLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import Button from "../../shared/Button"
import { CgClose } from "react-icons/cg";

export default function CreateBriefsModal(props) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [submitState, setSubmitState] = useState(true);
  const [title, setTitle] = useState(null);
  const [searchFile, setSearchFile] = useState();

  const handleModalClose = () => {
    props.handleModalClose();
  };

  const handleSave = (data) => {
    console.log(data);

    let briefName = data.briefName;
    props.handleSave(briefName);
  };

  const handleTextChange = (e) => {
    console.log("handleSearchFileChange()", e.target.value);

    if (e.target.value === "" || e.target.value === null) {
      setSubmitState(true);
    } else {
      setSubmitState(false);
    }
  };

  // const filterRecord = (e) => {

  //   if(e.target.value === "" || e.target.value === null){
  //     setSubmitState(true);
  //   }else{
  //     setSubmitState(false);
  //   }
  // }
  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10">
            <div className="flex items-start justify-between  rounded-t">
              <h3 className="font-semibold text-gray-900 text-lg pb-2">
                Create New Background
              </h3>
              <button
                className="ml-auto h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300"
                onClick={handleModalClose}
              >
                <CgClose />
              </button>
            </div>
            <div className="relative py-4 flex-auto p-1">
              <div className="text-sm font-medium text-gray-400">
                {`Background Title *`}
              </div>
              <div className="flex flex-col py-1">
                <div className="absolute pin-r pin-t mt-2 mr-5 ml-2 text-purple-lighter">
                  <RiFileInfoLine className="text-gray-400" />
                </div>
                <input
                  type="text"
                  className="rounded-md p-2 border border-gray-300 outline-0 pl-8 w-full"
                  {...register("briefName", { required: true })}
                  onChange={handleTextChange}
                />
              </div>
              {errors.briefName?.type === "required" && (
                <small className="text-red-400">
                  Background title is required
                </small>
              )}
            </div>
            <div className="flex items-center justify-center p-3 rounded-b ">
              <Button
                size="medium"
                classname="px-6 mr-1 mr-auto ml-auto gap-1 "
                variant="secondary"
                type="submit"
                disabled={submitState}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </form>
  );
}
