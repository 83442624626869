import { createContext, useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { hbaDomains, obaDomains } from "../../../constants/AppDomains";

const ColumnContext = createContext();

const TASK_LIST_TABLE_QUERY = `
query taskListTable($companyId: ID!) {
  taskListTable(companyId: $companyId) {
    id
    columns {
      id
      headerText
      type
      enabled
      optionsText
      order
      presets {
        id
        label
      }
    }
  }
}
`;

const TASK_LIST_TABLE_CREATE = `
mutation taskListTable($companyId: ID!) {
  taskListTableCreate(companyId: $companyId) {
      id
      columns {
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          label
        }
      }
      createdAt
      updatedAt
    }
}
`;

const mCreateActivity = `mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
    activity: $activity
    briefId: $briefId
    clientMatterId: $clientMatterId
    companyId: $companyId
    previous: $previous
    field: $field
    current: $current
    appModule: $appModule
    rowId: $rowId
  ) {
    id
  }
}`;

const TASK_LIST_DATA_CREATE = `
  mutation taskListDataCreate($companyId: ID!, $input: TaskListDataInput!) {
    taskListDataCreate(companyId: $companyId, input: $input) {
      data
      id
      createdAt
    }
  }`;

const UPDATE_TASK_LIST_TABLE_MUTATION = `
  mutation taskListTableUpdate($id: ID! $input: TaskListTableInput!) {
    taskListTableUpdate(
      id: $id
      input: $input
    ) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          label
        }
      }
    }
  }`;
export const ColumnContextProvider = ({ children }) => {
  const companyId = localStorage.getItem("companyId");
  const [columns, setColumns] = useState([]);

  async function fetchTable() {
    console.group("fetchTable")
    const { data } = await API.graphql({
      query: TASK_LIST_TABLE_QUERY,
      variables: {
        companyId,
      },
    });

    var tableColumns = [];
    var newColumns = [];
    //check if company has table
    if (data.taskListTable) {
      tableColumns = data.taskListTable?.columns;

      const taskTableId = data.taskListTable.id;
      setTableId(taskTableId);

      tableColumns.map((col) => {
        if (!newColumns.includes(col)) newColumns.push({...col, ...{
          accessorKey: col.id,
          order: parseInt(col.order),
        }})
      });

      console.log("tableColumns", {...tableColumns})
      await addMissingColumns(taskTableId, tableColumns, newColumns)

      let formattedColumns = newColumns.map((col) => ({
        ...{
          accessorKey: col.id,
          type: col.type,
          enabled: col.enabled,
          presets: col.presets,
          order: col.order
        },
        ...((col.headerText === "Client/Matter" && hbaDomains.includes(window.location.hostname)) ? 
          { header: "Project Name"} : { header: col.headerText }),
        ...(col.type === "DROPDOWN" ? 
          { optionsText: col.optionsText ? col.optionsText : [] } : {})
      }));

      setColumns(formattedColumns);
    } else {
      const { data } = await API.graphql({
        query: TASK_LIST_TABLE_CREATE,
        variables: {
          companyId,
        },
      });

      //set table id to localStorage
      const taskTableId = data.taskListTableCreate.id;
      tableColumns = data.taskListTableCreate.columns;
      newColumns = data.taskListTableCreate.columns;
      setTableId(taskTableId);
      console.log("tableColumns", {...tableColumns})
      // ADDS MISSING BY IF COLUMN DOES NOT EXIST
      await addMissingColumns(taskTableId, tableColumns, newColumns);

      // create single onboarding task
      const createTaskResult = await API.graphql({
        query: TASK_LIST_DATA_CREATE,
        variables: {
          companyId,
          input: {
            uniqueId: 1,
            isDeleted: false,
            dueDate: new Date(),
            priority: 1,
            gmailMessageId: "0",
            data: JSON.stringify({
              7: "Outstanding",
              8: 1,
              9: localStorage.getItem("userId"),
              10: new Date(),
              11: new Date(),
              14: localStorage.getItem("userId")
            }),
          },
        },
      });

      const activityParams = {
        query: mCreateActivity,
        variables: {
          companyId: companyId,
          briefId: null,
          rowId: createTaskResult.data.taskListDataCreate.id,
          activity: `Created a task`,
          field: "Create a task",
          current: null,
          previous: null,
          appModule: "TASKS",
          userId: localStorage.getItem("userId"),
        },
      };
  
      const addActivity = await API.graphql(activityParams).then((result) => {
        console.log("addActivity result", createTaskResult, "CREATE TASK");
      });
      window.location.reload();
    }
    console.groupEnd("fetchTable")
  }

  async function addMissingColumns(id, tableColumns, newColumns) {
    if (!tableColumns.find(item => item.headerText === 'Task Registers')) {
      // MMA-1981 - Add new column for Task Registers
      console.log("Adding Task Register Column")

      newColumns.push({
        id: `${newColumns.length}`,
        accessorKey: `${newColumns.length}`,
        headerText: 'Task Registers',
        type: 'DROPDOWN',
        enabled: true,
        order: newColumns.length + 1,
        presets: null,
      })
    }

    if (!tableColumns.find(item => item.headerText === 'Task Created By')) {
      // MMA-1100 - Add new column for who created the task
      console.log("Adding Task Register Column")

      newColumns.push({
        id: `${newColumns.length}`,
        accessorKey: `${newColumns.length}`,
        headerText: 'Task Created By',
        type: 'PEOPLE',
        enabled: true,
        order: newColumns.length + 1,
        presets: null,
      })
    }

    // SAVE MISSING COLUMNS
    if (tableColumns.length !== newColumns.length) {
      console.log("Updating columns to include new cols", newColumns)
      const params = {
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: id,
          input: {
            columns: newColumns.map((col) => {
              delete col.id
              return col
            }),
          },
        },
      }
      console.log("newColumns", {...newColumns})

      const updateBriefTable = await API.graphql(params);
    }
  }

  function setTableId(id) {
    return localStorage.setItem("task-list-table-id", id);
  }

  useEffect(() => {
    fetchTable();
  }, []);

  return (
    <ColumnContext.Provider value={[columns, setColumns]}>
      {children}
    </ColumnContext.Provider>
  );
};

export function useColumnContext() {
  return useContext(ColumnContext);
}
