import React from "react";
import { useState } from "react";

function BriefSwitch({
  brief,
  accessType,
  disabledSwitch,
  setDisabledSwitch,
  row_index,
  updateTaggedBriefs,
}) {
  const [isChecked, setisChecked] = useState(
    brief.userTypeAccess.find((access) => access.value === accessType) !==
      undefined
  );

  const handleChangeBriefAccess = (s) => {
    let newAccessBriefs = [...brief.userTypeAccess];

    if (isChecked) {
      newAccessBriefs = newAccessBriefs.filter(
        (briefAccess) => briefAccess.value !== accessType
      );
    } else {
      newAccessBriefs.push({
        label: accessType,
        value: accessType,
      });
    }
    updateTaggedBriefs(brief.id, newAccessBriefs);
    setisChecked((prev) => !prev);
    setDisabledSwitch(true);
  };

  return (
    <>
      <div className="relative inline-block w-8 mr-2 align-middle select-none transition duration-200 ease-in">
        <input
          type="checkbox"
          name={row_index}
          id={row_index}
          className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer disabled:opacity-75"
          checked={isChecked}
          onChange={handleChangeBriefAccess.bind(this)}
          disabled={disabledSwitch}
        />
        <label className="toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer"></label>
      </div>
    </>
  );
}

export default BriefSwitch;
