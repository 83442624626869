import React, { useState, useEffect, useRef } from "react";
import { CgTrash } from "react-icons/cg";
import Select from "react-select";
import { API } from "aws-amplify";
import Button from "../../shared/Button";
import { isMobileDevice } from "../../shared/mobileViewFunctions";
import anime from "animejs";
// import { format } from "prettier";
import { CgClose } from "react-icons/cg";
import { RiAddCircleFill } from "react-icons/ri";
import { MdAdd } from "react-icons/md";

import { GetUserCM } from "../../shared/graphql/queries";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function AddTeamModal({
  close,
  setTeamList,
  TeamList,
  getContacts,
  setShowBurst,
  getTeams,
  UserTypes,
  CompanyUsers,
  tagTeamMember,
  setisLoading,
  width,
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  const [isDisabled, setisDisabled] = useState(false);
  const [IsHovering, setIsHovering] = useState(false);
  const [TeamName, setTeamName] = useState("");
  const [FinalData, setFinalData] = useState([]);
  const [Options, setOptions] = useState([]);

  const mCreateTeam = `
 mutation createTeam($companyId: ID, $name: String) {
  teamCreate(companyId: $companyId, name: $name){
    id
  }
}
  `;

  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const handleDelete = (index) => {
    setInputData(InputData.filter((_, idx) => idx !== index));
  };

  const [InputData, setInputData] = useState([
    {
      id: uuidv4(),
      firstName: "",
      lastName: "",
      userType: "",
      userId: "",
    },
  ]);

  const validate = (obj) => {
    if (obj.firstName && obj.lastName && obj.userType && TeamName) {
      return true;
    } else return false;
  };

  useEffect(() => {
    //console.log("Company users", CompanyUsers);
    setOptions(CompanyUsers);
  }, [CompanyUsers]);

  useEffect(() => {
    console.log("Options:", Options);
  }, [Options]);

  useEffect(() => {
    const validations = InputData.map((input) => validate(input));
    let oFinal = InputData.map((input) => {
      let final = {
        userId: input.userId,
        userType: input.userType,
      };

      return final;
    });

    setFinalData(oFinal);
    setisDisabled(validations.includes(false));

    let data = InputData.map((input) => {
      return input.userId;
    });

    console.log("Company users", CompanyUsers);
    setOptions(CompanyUsers.filter((x) => !data.includes(x.id)));
  }, [InputData, TeamName]);

  useEffect(() => {
    console.log("FinalData", FinalData);
  }, [FinalData]);

  const handleOnChange = (e, i, property) => {
    const { value } = e.target;
    const list = [...InputData];
    list[i][property] = typeof value === "object" ? value.value : value;
    setInputData(list);
  };

  const buildName = (value) => {
    console.log(value.split(" "));
    let arr = value.split(" ");
    if (arr.length > 2) {
      return {
        firstName: arr[0] + " " + arr[1],
        lastName: arr[2],
      };
    } else {
      return {
        firstName: arr[0],
        lastName: arr[1],
      };
    }
  };

  const handleSelectChange = async (e, val, i, property) => {
    const list = [...InputData];

    if (property === "name") {
      let name = buildName(e.value);
      list[i]["firstName"] = name.firstName;
      list[i]["lastName"] = name.lastName;
      list[i]["userId"] = e.id;
      console.log("ID", e.id);
    } else {
      list[i][property] = e.value;
    }
    if (!list[i].clientMatterAccess) {
      const response = await GetUserCM(e.id, localStorage.getItem("companyId"));
      console.log("Handle select", response.data);

      list[i].clientMatterAccess = response?.data?.user?.clientMatterAccess;
      list[i].userType = response?.data?.user?.userType;
    }
    setInputData(list);
  };

  const generateFinalObj = (state) => {
    return {
      id: uuidv4(),
      teamName: toTitleCase(TeamName),
      members: InputData,
      isNewlyAdded: state,
    };
  };

  const handleAddTeam = async () => {
    console.group("Handle add team");
    console.log("Company ID", localStorage.getItem("companyId"));
    setisLoading(true);
    const request = await API.graphql({
      query: mCreateTeam,
      variables: {
        //$companyId: ID, $name: String
        companyId: localStorage.getItem("companyId"),
        name: toTitleCase(TeamName),
      },
    });

    console.log("mCreateTeam", request);

    if (request) {
      await tagTeamMember(request.data.teamCreate.id, FinalData);
      await getTeams();
      getContacts();
      setTimeout(() => {
        setisLoading(false);
        setShowBurst(true);
        setTimeout(() => {
          setShowBurst(false);
        }, 2000);
      }, 3000);
    } else {
      alert("Failed to add teams");
    }
    console.groupEnd();
  };

  const generateFinal = async () => {
    setTeamList(TeamList.concat(generateFinalObj(true)));
    setShowBurst(true);
    setTimeout(() => {
      setTeamList(TeamList.concat(generateFinalObj(false)));
      setShowBurst(false);
    }, 3000);
  };

  const handleSubmit = () => {
    generateFinal();
  };
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    function handleZoomChange() {
      const newZoomLevel = Math.round(window.devicePixelRatio * 100) / 100;
      setZoomLevel(newZoomLevel);
    }

    window.addEventListener("resize", handleZoomChange);
    handleZoomChange();

    return () => {
      window.removeEventListener("resize", handleZoomChange);
    };
  }, []);

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="p-10 flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen overflow-y-auto relative"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-row">
            <div className="font-semibold text-gray-900 text-lg pb-2">
              Add Team
            </div>
            <button
              onClick={() => {
                close();
              }}
              className="h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300 absolute top-5 right-5"
            >
              <CgClose />
            </button>
          </div>
          <div className="text-gray-900 pb-2">
            Get everyone working in one place by adding them to a team.
          </div>
          <div>
            <div className="flex flex-col p-1 w-full">
              <div className="text-sm font-medium text-gray-400">
                {`Team Name`}
              </div>
              <input
                name={`teamName`}
                type="text"
                value={TeamName}
                placeholder={`What's your team called?`}
                className="rounded-md p-2 w-full border border-gray-300 outline-0"
                onChange={(e) => setTeamName(e.target.value)}
                required
              />
            </div>
          </div>
          {InputData.map((x, i) => (
            <div className="flex flex-col">
              <div
                className={
                  "flex " + (isMobileDevice(width) ? "flex-col" : "flex-row")
                }
              >
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Member`} {i + 1}
                  </div>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      container: (base) => ({
                        ...base,
                        zIndex: "99999",
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (base, state) => ({
                        ...base,
                        maxHeight: zoomLevel >= 1.5 ? "12rem" : null,
                        overflowY:
                          state.selectProps.menuIsOpen && zoomLevel >= 1.5
                            ? "hidden"
                            : null,
                      }),
                    }}
                    name={`name`}
                    options={Options}
                    type="text"
                    value={{
                      value: x.firstName + " " + x.lastName,
                      label: x.firstName + " " + x.lastName,
                    }}
                    onChange={(e) => handleSelectChange(e, x, i, `name`)}
                    className={
                      "rounded-md focus:border-gray-100 text-gray-400 " +
                      (isMobileDevice(width) ? "w-full" : "w-80")
                    }
                  />
                </div>
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`User Type`}
                  </div>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      container: (base) => ({
                        ...base,
                        zIndex: "99999",
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      // menu: (base, state) => ({
                      //   ...base,
                      //   maxHeight: zoomLevel >= 1.5 ? "12rem" : null,
                      //   overflowY:
                      //     state.selectProps.menuIsOpen && zoomLevel >= 1.5
                      //       ? "auto"
                      //       : null,
                      // }),
                    }}
                    name={`userType`}
                    options={UserTypes}
                    type="text"
                    value={{
                      value: x.userType,
                      label: x.userType,
                    }}
                    onChange={(e) => handleSelectChange(e, x, i, `userType`)}
                    className={
                      "rounded-md focus:border-gray-100 text-gray-400 " +
                      (isMobileDevice(width) ? "w-full" : "w-80")
                    }
                  />
                </div>

                <div className="flex flex-col p-1">
                  {isMobileDevice(width) ? (
                    <></>
                  ) : (
                    <div className="opacity-0">1</div>
                  )}
                  {InputData.length > 1 && (
                    <CgTrash
                      className="border border-gray-200 text-4xl p-2 cursor-pointer hover:bg-gray-100"
                      color={`lightcoral`}
                      onClick={() => handleDelete(i)}
                    />
                  )}
                  {isMobileDevice(width) ? (
                    <div className="opacity-0">1</div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ))}

          <button
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => {
              setisDisabled(true);
              setInputData([
                ...InputData,
                {
                  id: uuidv4(),
                  firstName: "",
                  lastName: "",
                  userType: "",
                  userId: "",
                },
              ]);
            }}
            className={
              "m-2 my-3 font-medium gap-1 mr-auto flex flex-row justify-center items-center text-md text-cyan-500 hover:text-cyan-300 cursor-pointer"
            }
          >
            Add More
            <RiAddCircleFill />
          </button>
          <div className="mr-auto ml-auto">
            <Button
              variant="secondary"
              disabled={isDisabled}
              onClick={() => {
                //generateFinal();
                //handleSubmit();
                handleAddTeam();
                close();
              }}
              className="p-2 pl-5 pr-5  ml-auto gap-1 "
            >
              Create Team
              <MdAdd className="h-6 w-6" />
            </Button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
