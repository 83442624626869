import React, { useState, useEffect, useRef } from "react";
import CreatableSelect, { components } from 'react-select';
import { FiX } from "react-icons/fi";
import { People } from "./people";

export const PeopleSelect = ({ 
  setInputContacts, 
  ContactList, 
  UserTypesList, 
  contactLoading,
}) => {
  // States
  const [UserSelectStates, setUserSelectStates] = useState([]);
  const [InputText, setInputText] = useState("")
  const [CurrentSelected, setCurrentSelected] = useState({});
  const [ContactsAdded, setContactsAdded] = useState([]);
  const [MenuOpen, setMenuOpen] = useState(false);
  const [ChildMenuOpen, setChildMenuOpen] = useState(false);
  const selectRef = useRef(null);
  const selectEl = selectRef.current;

  // State Management
  const handleClick = (e, props) => {
    const curr = UserSelectStates.find(obj => obj.id === props.id)
    if (curr.userTypeSelected === null) {
      e.stopPropagation();

      setCurrentSelected(props)
      setInputText(props.value)
    } else {
      setContactsAdded((prev) => [...prev, {...CurrentSelected, type: curr.userTypeSelected}])

      // Reset all states after adding a contact
      setInputText("")
      setCurrentSelected({})
      setUserSelectStates(
        ContactList.map((contact) => (
          { id: contact.id, isButtonClicked: false, userTypeSelected: null }
        ))
      )
      setChildMenuOpen(false)

      // Close menu
      setMenuOpen(false)
      selectEl.blur();
    }
  }
  const handleDelete = (props) => {
    setContactsAdded(ContactsAdded.filter(contact => props.id !== contact.id));
  }
  const handleOpenMenu = () => {
    if (!MenuOpen) {
      setMenuOpen(true)
      // selectEl.focus();
    }
  }
  const keepMenuOpen = (menuOpen) => {
    setChildMenuOpen(menuOpen);
  }
  const handleCloseMenu = () => {
    if (!ChildMenuOpen) {
      if (MenuOpen) {
        setInputText("")
        setCurrentSelected({})
        setUserSelectStates(
          ContactList.map((contact) => (
            { id: contact.id, isButtonClicked: false, userTypeSelected: null }
          ))
        )
  
        // Close menu
        setMenuOpen(false)
        // selectEl.blur();
      }
    }
  }
  const handleUserSelect = (id) => {
    setUserSelectStates(prev =>
      prev.map(obj =>
        obj.id === id ? { ...obj, isButtonClicked: !obj.isButtonClicked } : obj
      )
    )
  }
  const handleUserTypeSelect = (id, type) => {
    setUserSelectStates(prev =>
      prev.map(obj =>
        obj.id === id ? { ...obj, userTypeSelected: type } : obj
      )
    )
  }

  useEffect(() => {
    setUserSelectStates(
      ContactList.map((contact) => (
        { id: contact.id, isButtonClicked: false, userTypeSelected: null }
      ))
    )
  }, [ContactList]);

  // Styles for React-Select
  const controlStyles = {
    // control: (baseStyles) => ({
    //   ...baseStyles,
    //   padding: "4px 7px 4px 7px",
    //   borderRadius: "8px",
    // }),
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "8px",
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: "185px",
      "::-webkit-scrollbar": {
        width: "16px",
      },
      "::-webkit-scrollbar-thumb": {
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: "9999px",
        backgroundColor: "#EAECF0",
      },
      "::-webkit-scrollbar-thumb:hover": {
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: "9999px",
        backgroundColor: "#888",
      },
    }),
    option: (baseStyles) => ({
      ...baseStyles,
      padding: "0px"
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      display: "flex",
      padding: "4px 10px",
      alignItems: "center",
      borderRadius: "16px",
      backgroundColor: "#F2F4F7",
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      padding: "8px"
    })
  };
    
  // Custom Components
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex" onClick={(e) => handleClick(e, props.data)}>
          <People 
            name={props.data.label} 
            options={UserTypesList} 
            profilePicture={props.data.profilePicture}
            handleOpen={keepMenuOpen}

            id={props.data.id}
            userSelectStates={UserSelectStates.find(state => state.id === props.data.id)}
            handleUserSelect={(id) => handleUserSelect(id)}
            handleUserTypeSelect={(id, type) => handleUserTypeSelect(id, type)}
          />
        </div>
      </components.Option>
    )
  }
  const MultiValueLabel = (props) => {
    const contact = ContactsAdded.find(contact => contact.value === props.data.value)

    return (
      <components.MultiValueLabel {...props}>
        <div className="flex gap-2 items-center">
          <div className="text-slate-700 text-xs">{contact.label}</div>
          <div className={`w-2 h-2 rounded-full ${contact.type.color}`}></div>
        </div>
      </components.MultiValueLabel>
    )
  }
  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <FiX className="text-slate-700" size={18} onClick={() => handleDelete(props.data)}/>
      </components.MultiValueRemove>
    );
  };
  const ValueContainer = ({children, ...props}) => {
    return (
      <components.ValueContainer {...props}>
        {React.Children.map(children, child =>
          child && child.type !== components.Placeholder ? child : null
        )}
        {
          ContactsAdded.length !== 0 && <components.Placeholder {...props} isFocused={props.isFocused}>
            Add more people...
          </components.Placeholder>
        }
      </components.ValueContainer>
    );
  };
  const Input = (props) => {
    return (
      InputText ? <components.Input {...props} value={InputText}/> : <components.Input {...props} />
    )
  }
  
  return (
    <div className="w-full flex flex-col gap-1.5">
      <div className="text-gray-700 text-sm">People</div>
      <CreatableSelect 
        isMulti
        isClearable
        isSearchable={false}
        placeholder=""
        ref={selectRef}
        isLoading={contactLoading}
        menuIsOpen={MenuOpen}
        styles={controlStyles}
        inputValue={InputText}
        options={ContactList}
        values={ContactsAdded}
        onFocus={() => {
          handleOpenMenu()
        }}
        onBlur={() => {
          handleCloseMenu()
        }}
        onMenuClose={setInputContacts(ContactsAdded)}
        onInputChange={(e, action) => {
            if (action.action === "input-change") setInputText(e.value)
          }}
        className="relative bg-white rounded border-0 shadow focus:outline-none focus:ring ring-blue-500 w-full"
        components={{ 
          Option, 
          MultiValueLabel, 
          MultiValueRemove, 
          // ValueContainer,
          Input,
          ClearIndicator: () => null
        }}
      />
    </div>
  );
};