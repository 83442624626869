import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import bookImg from "../../assets/images/book.svg";
import { AppRoutes } from "../../constants/AppRoutes";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";

const mUpdateTabTable = `mutation update($id: ID!, $input: BriefBackgroundTabTableInput) {
	briefUpdate(id: $id, backgroundTabTable: $input) {
	  id
	}
  }`;

function CreateDocument({
	reducedTabsArray,
	reducedUploadedFilesArray,
	background,
}) {
	let history = useHistory();
	const params = useParams();
	const { back_id, matter_id, brief_id } = params;

	const handleClick = async () => {
		await updateTabTable();
		history.push(
			`${AppRoutes.BACKGROUND}/${back_id}/${matter_id}/${brief_id}/create-document`
		);
	};

	function generateTabTableData(array) {
		const individualTabs = [];

		array?.forEach((item) => {
			if (item?.tabFiles) {
				item.tabFiles.forEach((file) => {
					if (file?.fileIds && file?.fileIds.length > 0) {
						individualTabs.push(file?.fileIds);
					}
				});
			}
		});

		const result = individualTabs.map((item) => {
			const getFileNumber = () => {
				const fileNames = item?.map((itemId) => {
					const matchedFile = reducedUploadedFilesArray.find(
						(file) => file.id === itemId
					);
					return matchedFile
						? `${matchedFile.backgroundIndex}.${matchedFile.order}`
						: null;
				});

				return fileNames ? fileNames.join("<br>") : null;
			};
			const getFileName = () => {
				const fileNames = item?.map((itemId) => {
					const matchedFile = reducedUploadedFilesArray.find(
						(file) => file.id === itemId
					);
					return matchedFile ? matchedFile.name : null;
				});

				return fileNames ? fileNames.join("<br>") : null;
			};

			const getTabNumber = () => {
				function arraysEqual(a, b) {
					if (a === b) return true;
					if (a === undefined || b === undefined) return false;
					if (a === null || b === null) return false;
					if (a?.length !== b?.length) return false;

					for (let i = 0; i < a?.length; i++) {
						if (!b.includes(a[i])) return false;
					}

					return true;
				}

				const matchingKey = [];
				for (const obj of reducedTabsArray) {
					for (const key in obj) {
						if (arraysEqual(obj[key], item)) {
							matchingKey.push(key);
						}
					}
				}

				return +matchingKey[0];
			};

			const getFileIds = () => {
				const fileIds = item;
				return fileIds;
			};

			return {
				fileNumberText: getFileNumber(),
				fileNameMarkdown: getFileName(),
				tabNumber: getTabNumber(),
				fileIds: getFileIds(),
			};
		});

		return result;
	}

	let tabsArray = generateTabTableData(background);

	const updateTabTable = async () => {
		const params = {
			query: mUpdateTabTable,
			variables: {
				id: brief_id,
				input: {
					insertCoverPage: false,
					tabTextLabel: "Tab",
					tabs: tabsArray,
				},
			},
		};

		try {
			const updateTable = await API.graphql(params);
			console.log("Update result:", updateTable);
		} catch (error) {
			console.error("Error updating table:", error);
		}
	};

	return (
		<>
			<div className=" inline-flex bg-black text-white rounded ml-2 border-black">
				<button
					className="w-48 h-9"
					onClick={() => {
						handleClick();
					}}>
					<div className="flex justify-evenly">
						<div>Create Document</div>
						<div className="flex">
							<img
								src={bookImg}
								alt=""
							/>
						</div>
					</div>
				</button>
			</div>
		</>
	);
}

export default CreateDocument;
