import React, { useState, useEffect, useRef } from "react";
import { alphabetArray } from "../../../constants/Alphabet";
import { CgTrash, CgSortAz, CgSortZa } from "react-icons/cg";
import { FaEdit } from "react-icons/fa";
import BlankStateTeams from "./blank-state";
import DeleteModal from "../delete-modal";
import burst from "../../../assets/images/celebratory_burst.gif";
import TeamsEditModal from "./teams-edit-modal";
import { API } from "aws-amplify";
import Loading from "../../loading/loading";
import {
  isMobileDevice,
  useWindowDimensions,
} from "../../../shared/mobileViewFunctions";
import { GetTeamCM } from "../../../shared/graphql/queries";

const qGetTeamWithMembers = `
  query getTeamMembers($id: ID, $companyId: String) {
  team(id: $id) {
    id
    name
    members {
      items {
        userType
        user {
          id
          firstName
          lastName
          userType
          profilePicture
          clientMatterAccess(companyId: $companyId) {
            items {
              id
              userType
              clientMatter {
                id
                client {
                  id
                  name
                }
                matter {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    clientMatters {
      items {
        id
        client {
          name
        }
        matter {
          name
        }
      }
    }
  }
}`;

export default function TeamsTab({
  teams,
  setAlphabetArrayState,
  alphabetArrayState,
  teamsAlphabet,
  setTeamsAlphabet,
  ascDesc,
  setAscDesc,
  setSortBy,
  sortBy,
  shortcutSelected,
  refLetters,
  ContactList,
  setContactList,
  ShowBurst,
  getTeams,
  setalertMessage,
  setShowToast,
  UserTypes,
  CompanyUsers,
  isLoading,
  setisLoading,
  tagTeamMember,
  getMobileContentHeight,
}) {
  const [IsSortedReverse, setIsSortedReverse] = useState(false);
  const [TeamList, setTeamList] = useState(teams);
  // const [Alphabet, setAlphabet] = useState([]);
  const [ShowDeleteModal, setShowDeleteModal] = useState(false);
  const [ShowEditModal, setShowEditModal] = useState(false);
  const [CurrentTeam, setCurrentTeam] = useState();
  const [ToDeleteID, setToDeleteID] = useState();
  const { height, width } = useWindowDimensions();

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  useEffect(() => {
    console.log("INSIDE TAB", teams);
    setTeamList(teams);
  }, [teams]);

  useEffect(() => {
    console.log("UserTypes", UserTypes);
  }, [UserTypes]);

  useEffect(() => {
    setTeamsAlphabet(
      TeamList.map((team) => team.name[0])
        .filter(onlyUnique)
        .sort((a, b) => a.localeCompare(b))
    );
  }, [TeamList]);

  // useEffect(() => {
  //   alphabetArray.sort((a, b) => a.localeCompare(b));
  //   setAscDesc(null);
  //   return () => {
  //     setAscDesc(null);
  //     alphabetArray.sort((a, b) => a.localeCompare(b));
  //   };
  // }, []);

  const handleSortByName = () => {
    console.log(ascDesc);
    if (ascDesc === null) {
      // descending
      setTeamList((prev) => prev.sort((a, b) => a.name.localeCompare(b.name)));
      setTeamsAlphabet((prev) => prev.sort().reverse());

      //Side panel
      setAlphabetArrayState(alphabetArrayState.reverse());
      setAscDesc(true);
      console.log(alphabetArrayState);
    } else if (ascDesc === true) {
      setTeamList((prev) => prev.sort((a, b) => b.name.localeCompare(a.name)));
      setTeamsAlphabet((prev) => prev.sort());
      alphabetArray.sort();
      setAscDesc(null);
      // hide 2 buttons
      console.log(alphabetArrayState);
    }
  };

  // const RenderSort = ({ sortBy }) => {
  //   return (
  //     <>
  //       {IsSortedReverse ? (
  //         <CgSortAz
  //           onClick={() => {
  //             setIsSortedReverse(!IsSortedReverse);
  //             handleSort(IsSortedReverse, sortBy);
  //           }}
  //           className="text-xl cursor-pointer hover:text-gray-500"
  //         />
  //       ) : (
  //         <CgSortZa
  //           onClick={() => {
  //             setIsSortedReverse(!IsSortedReverse);
  //             handleSort(IsSortedReverse, sortBy);
  //           }}
  //           className="text-xl cursor-pointer hover:text-gray-500"
  //         />
  //       )}
  //     </>
  //   );
  // };

  async function handleSettingTeam(teamId) {
    setShowEditModal(true);
    // let params = {
    //   query: qGetTeamWithMembers,
    //   variables: {
    //     id: teamId,
    //     companyId: localStorage.getItem("companyId"),
    //   },
    // };
    // await API.graphql(params).then((team) => {
    await GetTeamCM(teamId, localStorage.getItem("companyId")).then((team) => {
      console.log(team.data.team);
      if (team.data.team) {
        let temp = {
          id: team.data.team.id,
          name: team.data.team.name,
          members: team.data.team.members,
          clientMatters: team.data.team.clientMatters,
        };
        console.log("SETTING TEAM", temp);
        setCurrentTeam(temp);
      }
    });
  }

  const handleInitials = (firstName, lastName) => {
    var assigneeInitials = "";
    if (
      `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}` ===
      "john dela cruz"
    ) {
      assigneeInitials = "JDC";
    } else {
      assigneeInitials += firstName ? firstName?.charAt(0) : "";
      assigneeInitials += lastName ? lastName?.charAt(0) : "";
    }
    return assigneeInitials.toUpperCase();
  };

  return (
    <>
      {ShowBurst && (
        <div className="absolute z-100">
          <img src={burst} width="1720" height="980" alt="" />
        </div>
      )}
      {isLoading ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <Loading />
        </div>
      ) : TeamList.length === 0 ? (
        <BlankStateTeams />
      ) : isMobileDevice(width) ? (
        <>
          {/* MOBILE VIEW */}
          <div className="pt-0 bg-white rounded-lg w-full">
            <div
              className="mt-1"
              style={{
                height: getMobileContentHeight(),
                overflowY: "scroll",
              }}
            >
              {teamsAlphabet.map((letter, idx) => (
                <div key={"c" + letter}>
                  <div className="flex flex-row justify-center items-center">
                    <p className="pr-2 font-medium">{letter}</p>
                    <div
                      className="w-full border-b-2 border-dashed"
                      style={{
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div className="flex flex-col gap-3">
                    {TeamList &&
                      TeamList.map(
                        (team, index) =>
                          team.name.charAt(0) === letter && (
                            <div
                              className="bg-gray-100 rounded-lg px-4 py-3 flex flex-row gap-3"
                              style={{
                                marginLeft: "17.76px",
                              }}
                              key={"c" + letter + index}
                            >
                              <div>
                                <p className="font-medium">{team.name} </p>
                                <p>{team.members?.items?.length} members</p>
                              </div>
                              <div className="ml-auto flex gap-x-2 items-start">
                                <button className="w-max font-semibold text-gray-500 rounded-full hover:bg-gray-200">
                                  <FaEdit
                                    onClick={() => {
                                      handleSettingTeam(team.id);
                                    }}
                                  />
                                </button>
                                <button className=" text-red-400 w-max font-semibold rounded-full hover:bg-gray-200">
                                  <CgTrash
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setToDeleteID(team.id);
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="w-full py-2">
          {" "}
          {/* DESKTOP VIEW */}
          <table className="w-full text-left">
            {/* headers */}
            <thead className="sticky top-20 bg-white z-10">
              <tr>
                <th className="p-2">
                  <div className="flex items-center gap-x-2">
                    Name{" "}
                    {ascDesc === null ? (
                      <CgSortZa
                        onClick={handleSortByName}
                        className={` text-xl cursor-pointer hover:text-gray-500`}
                      />
                    ) : ascDesc === true ? (
                      <CgSortAz
                        onClick={handleSortByName}
                        className={` text-xl cursor-pointer hover:text-gray-500`}
                      />
                    ) : (
                      <CgSortZa
                        onClick={handleSortByName}
                        className={` text-xl cursor-pointer hover:text-gray-500`}
                      />
                    )}
                  </div>
                </th>
                <th className="p-2 text-right">Members</th>
                <th className="p-2 w-20 " />
              </tr>
            </thead>
            {/* content */}
            <tbody className="relative">
              {teamsAlphabet.map((letter, idx) => (
                <>
                  <tr
                    ref={(el) => (refLetters.current[idx] = el)}
                    id={letter}
                    key={letter}
                    className=""
                  >
                    <td className="pt-4 px-2">
                      <div className="flex items-center gap-x-2">
                        <p
                          className={`${
                            shortcutSelected === letter
                              ? "text-cyan-500 font-bold"
                              : "text-gray-700 font-semibold"
                          }  text-lg `}
                        >
                          {letter}
                        </p>
                      </div>
                    </td>
                  </tr>

                  {TeamList &&
                    TeamList.map(
                      (team, index) =>
                        team.name.charAt(0) === letter && (
                          <tr key={team.id} className={"stripe opacity-100"}>
                            <td className="p-2">
                              <div className="flex items-center gap-x-2 ">
                                <span>{team.name}'s Team</span>
                                <span className="text-xs rounded-full bg-gray-200 font-medium p-1">
                                  {team?.members?.items?.length} members
                                </span>
                              </div>
                            </td>
                            <td className="p-2 text-right">
                              <div className="relative text-right left-0">
                                <span className="flex justify-end">
                                  {/* We have no members in backend teams yet replace with dummy

                                  {new Array(5).fill(0).map((x, i) => (
                                    <img
                                      alt={``}
                                      className="absolute rounded-full w-8 h-8 border-2 border-white"
                                      style={{
                                        zIndex: i,
                                        right: `${i * 25}px`,
                                        top: "-15px",
                                      }}
                                      src={`https://i.pravatar.cc/70?img=${i}`}
                                    />
                                  ))}
                                  */}

                                  {team.members?.items?.length === 0 ? (
                                    <div>No member found</div>
                                  ) : (
                                    team.members.items.map((x, i) => (
                                      // <img
                                      //   alt={``}
                                      //   className="absolute rounded-full w-8 h-8 border-2 border-white"
                                      //   style={{
                                      //     zIndex: i,
                                      //     right: `${i * 25}px`,
                                      //     top: "-15px",
                                      //   }}
                                      //   src={`https://i.pravatar.cc/70?img=${i}`}
                                      // />
                                      <div
                                        className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center"
                                        style={{ flexShrink: "0" }}
                                      >
                                        {x.user.profilePicture === null ? (
                                          <>
                                            {handleInitials(
                                              x.user.firstName,
                                              x.user.lastName
                                            )}
                                          </>
                                        ) : (
                                          <img
                                            className="rounded-full w-8 h-8"
                                            src={x.user.profilePicture}
                                          />
                                        )}
                                      </div>
                                    ))
                                  )}
                                </span>
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="flex items-center gap-x-2">
                                <button className="p-3 w-max font-semibold text-gray-500 rounded-full hover:bg-gray-200">
                                  <FaEdit
                                    onClick={() => {
                                      handleSettingTeam(team.id);
                                    }}
                                  />
                                </button>
                                <button className="p-3 text-red-400 w-max font-semibold rounded-full hover:bg-gray-200">
                                  <CgTrash
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setToDeleteID(team.id);
                                    }}
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                    )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {ShowDeleteModal && (
        <DeleteModal
          close={() => setShowDeleteModal(false)}
          toDeleteid={ToDeleteID}
          setContactList={setTeamList}
          ContactList={TeamList}
          isTeam={true}
          getTeams={getTeams}
          setalertMessage={setalertMessage}
          setShowToast={setShowToast}
          setisLoading={setisLoading}
        />
      )}
      {ShowEditModal && (
        <TeamsEditModal
          close={() => {
            setShowEditModal(false);
            setCurrentTeam();
          }}
          toDeleteid={`test`}
          setTeamList={setTeamList}
          TeamList={TeamList}
          CurrentTeam={CurrentTeam}
          CompanyUsers={CompanyUsers}
          UserTypes={UserTypes}
          setisLoading={setisLoading}
          tagTeamMember={tagTeamMember}
          getTeams={getTeams}
          setalertMessage={setalertMessage}
          setShowToast={setShowToast}
          width={width}
        />
      )}
    </>
  );
}
