import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { GrClose } from "react-icons/gr";
import Loading from "../loading/loading";
import { RiFileInfoLine, RiFilterFill } from "react-icons/ri";
import DatePicker from "react-datepicker";

export default function PriorityTasksCountModal(props) {
  const [summary, setSummary] = useState(null);
  const [filterBy, setFilterBy] = useState("default");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [invalidState, setInvalidState] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [displayDateColumn, setDisplayDateColumn] = useState(false);
  var moment = require("moment");
  
  useEffect(() => {
    if (summary === null) {
      getSummary();
    }
    console.log("Summary:", summary);
  }, [summary]);

  useEffect(() => {
    if (
      startDate !== null &&
      startDate !== "" &&
      endDate !== null &&
      endDate !== ""
    ) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        setInvalidDateRange(true);
        setInvalidState(true);
      } else {
        setInvalidDateRange(false);
        setInvalidState(false);
      }
    } else {
      setInvalidState(false);
    }
  });

  const handleModalClose = () => {
    props.handlePrioritySummaryClose();
  };

  const COUNT_PRIORITY_TASKS_QUERY = `
  query countPriorityTasks($companyId: ID!) {
    priorityTasksCount(companyId: $companyId) {
      level
      completed
      outstanding
      followUp
      deleted
    }
  }
`;

  const COUNT_PRIORITY_TASKS_BY_DATE_QUERY = `
  query countPriorityTasks($companyId: ID!, $dateSubmittedStart:String, $dateSubmittedEnd: String) {
    priorityTasksCount(
      companyId: $companyId
      dateSubmittedEnd: $dateSubmittedEnd
      dateSubmittedStart: $dateSubmittedStart
    ) {
      level
      completed
      outstanding
      followUp
      deleted
      dateSubmitted
    }
  }
  `;

  const getSummary = async () => {
    var query = COUNT_PRIORITY_TASKS_QUERY;

    const variables = {
      companyId: props.companyId,
    };

    if (filterBy === "date") {
      variables.dateSubmittedStart = startDate.toISOString().split("T")[0];
      variables.dateSubmittedEnd = endDate.toISOString().split("T")[0];
      query = COUNT_PRIORITY_TASKS_BY_DATE_QUERY;
      setDisplayDateColumn(true);
    } else {
      setDisplayDateColumn(false);
    }
    const params = {
      query,
      variables,
    };

    await API.graphql(params).then((response) => {
      let priorityTasks = response.data.priorityTasksCount;

      if (filterBy === "date") {
        priorityTasks?.sort((a, b) =>
          a.dateSubmitted.localeCompare(b.dateSubmitted)
        );
      } else {
        priorityTasks?.sort((a, b) => a.level.localeCompare(b.level));
      }

      setSummary(priorityTasks);
    });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">Summary of Priorities</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleModalClose}
              >
                <GrClose />
              </button>
            </div>
            <div className="flex flex-row p-3">
              <div className="mt-2 mr-2">
                <b>Filter By</b>
              </div>
              <div className="mr-3">
                <select
                  onChange={(e) => {
                    setFilterBy(e.target.value);
                  }}
                  className="p-2 rounded-lg border bg-transparent w-full col-span-2"
                >
                  <option value={"default"} selected>
                    Default
                  </option>
                  {/* <option value={"assignee"}>
                    Assignee
                  </option> */}
                  <option value={"date"}>Date</option>
                </select>
              </div>
              {filterBy === "date" && (
                <div>
                  <div className="grid grid-cols-4 gap-4">
                    <div>
                      <div className="relative">
                        <div className="absolute pin-r pin-t mt-4 mr-5 ml-2 text-purple-lighter">
                          <RiFileInfoLine />
                        </div>
                        <DatePicker
                          popperProps={{
                            positionFixed: true,
                          }}
                          className="border w-full rounded text-xs py-2 px-1 border-gray-300"
                          dateFormat="dd MMM yyyy"
                          selected={
                            startDate === null ? null : new Date(startDate)
                          }
                          placeholderText="Start Date"
                          onChange={(selected) => setStartDate(selected)}
                        />
                      </div>
                      <i className="text-xs">From</i>
                    </div>
                    <div>
                      <div className="relative">
                        <div className="absolute pin-r pin-t mt-4 mr-5 ml-2 text-purple-lighter">
                          <RiFileInfoLine />
                        </div>
                        <DatePicker
                          popperProps={{
                            positionFixed: true,
                          }}
                          className="border w-full rounded text-xs py-2 px-1 border-gray-300"
                          dateFormat="dd MMM yyyy"
                          selected={endDate === null ? null : new Date(endDate)}
                          placeholderText="End Date"
                          onChange={(selected) => setEndDate(selected)}
                        />
                      </div>
                      <i className="text-xs">To</i>
                    </div>
                    <div>
                      <button
                        className={`flex-1 text-white active:bg-emerald-600 font-bold uppercase text-xs p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex bg-green-500`}
                        onClick={() => {
                          getSummary();
                        }}
                        disabled={invalidState}
                      >
                        Filter <RiFilterFill />
                      </button>
                    </div>
                  </div>
                  {invalidDateRange && (
                    <div className="pt-1">
                      <small className="text-red-400">
                        End date should not be earlier than start date.
                      </small>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col h-screen" style={{ height: "50vh" }}>
              {summary ? (
                <div className="flex-grow overflow-auto">
                  <table className="relative w-full">
                    <thead>
                      <tr>
                        {displayDateColumn && (
                          <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold">
                            Date
                          </th>
                        )}
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold">
                          Priority Level
                        </th>
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold whitespace-nowrap">
                          Total Tasks
                        </th>
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold">
                          Completed
                        </th>
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold">
                          Outstanding
                        </th>
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold whitespace-nowrap">
                          Follow Up
                        </th>
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold">
                          Deleted
                        </th>
                      </tr>
                    </thead>
                    <tbody className="px-6 py-3">
                      {summary.length > 0 ? (
                        <>
                          {summary.map((item, idx) => (
                            <tr key={idx}>
                              {displayDateColumn && (
                                <td className="px-6 py-2 text-center whitespace-nowrap">
                                  {item.dateSubmitted !== ""
                                    ? moment(item.dateSubmitted).format(
                                        "DD MMM YYYY"
                                      )
                                    : null}
                                </td>
                              )}
                              <td className="px-6 py-2 text-center">
                                {item.level}
                              </td>
                              <td className="px-6 py-2 text-center">
                                {item.outstanding +
                                  item.completed +
                                  item.followUp +
                                  item.deleted}
                              </td>
                              <td className="px-6 py-2 text-center">
                                {item.completed}
                              </td>
                              <td className="px-6 py-2 text-center">
                                {item.outstanding}
                              </td>
                              <td className="px-6 py-2 text-center">
                                {item.followUp}
                              </td>
                              <td className="px-6 py-2 text-center">
                                {item.deleted}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td className="px-6 py-2" colSpan={3}>
                              No records found.
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
