import { createContext, useEffect, useReducer } from 'react'

import { appStateReducer } from './appStateReducer'
import { formDataReducer } from './formDataReducer'
import { modalStateReducer } from './modalStateReducer'

import { API } from "aws-amplify"
import { 
  mutationActionHandler, 
  qListActiveFormsByCompany,
  qListCategoriesByCompany,
} from '../graphql/queries'

const formCategories = [
  {id: 'all_category', name: 'All'},
  // {id: 'cat_1', name: '2020 Forms'},
  // {id: 'cat_2', name: 'Testing'},
  // {id: 'cat_3', name: 'Worksheets'},

]

// const formItems = [
//   {
//     id: 'form_1',
//     name:'Form 3',
//     category: {id: 'all_category', name: 'All'},
//     submissions: '0',
//     lastModified: '11/11/11'
//   },
//   {
//     id: 'form_2',
//     name:'Form 1',
//     category: {id: 'cat_1', name: '2020 Forms'},
//     submissions: '6',
//     lastModified: '11/11/11'
//   },
//   {
//     id: 'form_3',
//     name:'Form 2',
//     category: {id: 'cat_1', name: '2020 Forms'},
//     submissions: '6',
//     lastModified: '11/11/11'
//   }
//   ,
//   {
//     id: 'form_4',
//     name:'Above and Below',
//     category: {id: 'cat_1', name: '2020 Forms'},
//     submissions: '6',
//     lastModified: '11/11/11'
//   }
// ]

const initialState = {
  appState:{
    companyId: localStorage.getItem('companyId') || null,
    currentCategory: {id: 'all_category', name: 'All'},
    formFilters: [],
    formSorting: null,
    formSearchQuery: '',
  },
  formData:{
    formCategories: [...formCategories],
    //originalData: [...formItems],
    originalData: [],
    categorizedData: null,
  },
  modalState:{
    modalOpen: false,
    modalContent: null,
  }
}

const storeReducer = (state, action) => ({
  appState: appStateReducer(state.appState, action),
  formData: formDataReducer(state.formData, action),
  modalState: modalStateReducer(state.modalState, action),
})

export const FormDashboardContext = createContext()


export default function StoreProvider({children}){
  const [state, dispatch] = useReducer(storeReducer, initialState)

  
  useEffect(() => {
    const fetchForms = async () => {
      mutationActionHandler(
        {
          query: qListActiveFormsByCompany,
          variables: {
            companyId: state.appState.companyId,
          },
        },
        (response)=>
          //console.log("Query response",response)
          dispatch({
            type: 'SET_FORM_DATA',
            payload: response.data.customFormsByCompany,
          })
      )
    }

    const fetchCategories = async () => {
      mutationActionHandler(
        {
          query: qListCategoriesByCompany,
          variables: {
            companyId: state.appState.companyId,
          },
        },
        (response)=>
          //console.log("Query response",response)
          dispatch({
            type: 'SET_CATEGORY_DATA',
            payload: response.data.customFormCategoriesByCompany,
          })
      )
    }

    fetchCategories()
    fetchForms()
  }, []);

  const contextValue = {
    state,
    dispatch,
  };

  return(
    <FormDashboardContext.Provider value={contextValue}>
      {children}
    </FormDashboardContext.Provider>
  )
}