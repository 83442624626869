import React, { useState, useEffect, useRef, Fragment } from "react";
// import { Base64 } from "js-base64";
import "../../../assets/styles/Inbox.css";
import Button from "../../../shared/Button"
import { MdArrowBackIos } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { Menu, Transition } from "@headlessui/react";
import { SkeletonTheme } from "react-loading-skeleton";
import { GrDocumentPdf } from "react-icons/gr"
import { MdAttachFile, MdPictureAsPdf } from "react-icons/md"
import classNames from "classnames";
import {useWindowDimensions} from "../../../shared/mobileViewFunctions";

var moment = require("moment")

function MobileEmailPreview({
  show,
  setShow,
  snippetId,
  gmailMessageId,
  preview,
  from,
  dateEmail,
  subject,
  to,
  cc,
  handleClose,
  handleDownloadLink,
  id,
  getAttachments,
  attachments,
  handleDownloadAttachment,
  }) {

  const { height, width } = useWindowDimensions();
  
  return(
    <>
    <div>
      <div
        className="justify-center items-center flex-none overflow-x-hidden overflow-y-hidden max-h-screen fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: 70 }}
      >
        <div className="w-full mx-auto max-w-md h-screen max-h-screen pt-16" onClick={(e) => handleClose()}>
          <div className="items-center max-h-full min-h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4" onClick={(e) => e.stopPropagation()}>
            {/* Heading Div */}
            <div className="flex flex-col w-full p-4" id="emailHeader"> 
              <div className="flex justify-between">
                <Button className="border-gray-300 mb-2" onClick={(e) => handleClose()}><MdArrowBackIos className="text-xl float-left" />Back</Button>
                <div>
                  <Button className="ml-2"variant="danger-l" onClick={e => handleDownloadLink(id, gmailMessageId, subject)}><MdPictureAsPdf /></Button>
                  <Menu
                    as="div"
                    className="relative inline-block text-left"
                    onFocus={(e) => {
                      getAttachments(gmailMessageId);
                    }}
                  >
                    <div>
                      <Menu.Button className="ml-2">
                        <Button variant="primary-l"><MdAttachFile /></Button>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 max-w-md origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        style={{width: `${width-64}px`}}>
                        <div className="py-1">
                          {attachments.length !== 0 ? (
                            attachments?.map((item) => (
                              <Menu.Item>
                                {({ active }) => (
                                  <span
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm cursor-pointer border-b-2 border-gray-100 border-b-gray-800 hover:text-blue-400"
                                    )}
                                    onClick={(e) => {
                                      handleDownloadAttachment(item.id);
                                    }}
                                  >
                                    {item.name > 10
                                      ? item.name.substring(0, 10)
                                      : item.name}
                                  </span>
                                )}
                              </Menu.Item>
                            ))
                          ) : (
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm cursor-pointer"
                                  )}
                                >
                                  No Attachments for this Task
                                </span>
                              )}
                            </Menu.Item>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  
                </div>
                
              </div>
              <div>
                {(subject && (subject !== undefined)) ? (
                  <>
                    <p>{`From: ${from}`}</p>
                    <p>
                      {`Date: ${moment(
                        dateEmail
                      ).format(
                        "DD MMM YYYY, hh:mm A"
                      )}`}
                    </p>
                    <p className="font-bold">{`Subject: ${subject}`}</p>
                    <p>{`To: ${to}`}</p>
                    {cc && (
                      <p>{`CC: ${cc}`}</p>
                    )}
                  </>
                ) : (
                  <p><Skeleton count={5} /></p>
                )}
                
              </div>
            </div>
            {/* Email Body */}
            <div className="overflow-x-auto test123 overflow-y-auto w-full">
              {(subject) ? (
                <>
                  <p
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: preview,
                    }}
                  ></p>
                </>) : (
                  <>
                   <Skeleton count={25} />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>  
      <div
        className="opacity-25 fixed inset-0 bg-black"
        style={{ zIndex: 60 }}
        onClick={() => setShow(false)}
      ></div>
    </div>
    </>
  )
}

export default MobileEmailPreview;
