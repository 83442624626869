import React from "react";

export const PulsingBorderDiv = ({ children }) => {
  return (
    <div className="mb-2 w-full relative">
      {children}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute inset-0 border-8 border-red-900 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border-2 border-red-800 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border border-red-700 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border-2 border-red-600 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border-8 border-red-500 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border-2 border-red-400 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border border-red-300 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border-2 border-red-200 rounded-lg animate-pulse"></div>
        <div className="absolute inset-0 border-8 border-red-100 rounded-lg animate-pulse"></div>
      </div>
    </div>
  );
};

export const PulsingBorderDivRelative = () => {
  return (
    <div className="w-full h-full absolute inset-0 pointer-events-none z-10">
      <div className="absolute inset-0 border-8 border-red-900 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border-2 border-red-800 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border border-red-700 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border-2 border-red-600 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border-8 border-red-500 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border-2 border-red-400 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border border-red-300 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border-2 border-red-200 rounded-lg animate-pulse"></div>
      <div className="absolute inset-0 border-8 border-red-100 rounded-lg animate-pulse"></div>
    </div>
  );
};
