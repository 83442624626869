import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";

// Custom React DatePicker with header that can be clicked to show months and years
const MonthYearDatePicker = (props) => {
  const [renderId, setRenderId] = useState(null);
  const [startDate, setStartDate] = useState(props.selected
      ? props.selected
      : null
    );

  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [showYearPicker, setShowYearPicker] = useState(false);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  // Switching back from Year Picker causes the internal date to be January 1
  // and the year that is picked. i.e, if the user selected December 3 beforehand then 
  // picked 2026 in year picker, then the user's date should be December 3, 2026 not 
  // January 1, 2026. The user needs to retain the month of the current input when
  // changing years.

  // This is due to DatePicker's inability to rerender when switching showYearPicker's
  // value. As a solution, trigger a rerender when changing showYearPicker's value.

  const ref = useRef();

  function triggerRerender() {
    let length = 5;
    const uniqueId = [...Array(length)].map(() => Math.random().toString(36)[2]).join('');
    setRenderId(uniqueId);
  }

  useEffect(() => {
    if(renderId) {
      ref.current.setOpen(true);
    }
  }, [renderId])

  // END OF RERENDER FUNCTIONS

  return (
    <DatePicker
      shouldCloseOnSelect={ 
        (!showMonthPicker && !showYearPicker)
      }
      {...props}
      ref={ref}
      key={renderId}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        prevYearButtonDisabled,
        nextMonthButtonDisabled,
        nextYearButtonDisabled
      }) => (
        <div
          className="flex justify-between items-center px-3"
        > 
          <div>
            <button
              className={"font-normal text-xs outline-none uppercase px-1 py-0.5" + (showMonthPicker 
                  ? " bg-gray-600 text-white rounded-lg" 
                  : ""
                )
              } 
              onClick={() => {
                if (showYearPicker) {
                  setShowYearPicker(false);
                  setShowMonthPicker(true);
                } else {
                  setShowMonthPicker((e) => !e)
                }
              }}
            >
              {date 
                ? months[date.getMonth()] 
                : months[new Date().getMonth()]}
            </button>

            <button
              className={"font-normal text-xs outline-none uppercase -ml-0.5 px-1 py-0.5" + (showYearPicker 
                  ? " bg-gray-600 text-white rounded-lg" 
                  : ""
                )
              } 
              onClick={() => {         
                if (showMonthPicker) {
                  setShowMonthPicker(false)
                  setShowYearPicker(true);
                } else {
                  setShowYearPicker((e) => !e)
                }
              }}
            >
              {date 
                ? date.getFullYear()
                : new Date().getFullYear()}
            </button>
          </div>

          <div className="flex text-gray-400">
            <button 
              className="font-extrabold text-xl hover:text-gray-600"
              onClick={showYearPicker ? decreaseYear : decreaseMonth} 
              disabled={showYearPicker ? prevYearButtonDisabled : prevMonthButtonDisabled}
            >
              <TiChevronLeft/>
            </button>
            <button 
              className="font-extrabold text-xl hover:text-gray-600"              
              onClick={showYearPicker ? increaseYear : increaseMonth } 
              disabled={showYearPicker ? nextYearButtonDisabled : nextMonthButtonDisabled}
            >
              <TiChevronRight/>
            </button>
          </div>
        </div>
      )}
      
      showMonthYearPicker={showMonthPicker}
      showYearPicker={showYearPicker}

      selected={startDate}
      onChange={(date) => {
        if (showYearPicker && date) {
          setStartDate((currDate) => {
            let newDate = currDate 
              ? new Date(currDate.setFullYear(date.getFullYear()))
              : new Date(new Date().setFullYear(date.getFullYear()));
            return newDate;
          });
          setShowYearPicker(false);
          triggerRerender(); // Trigger rerender when chosing a year in year picker
        } else if (showMonthPicker && date) {
          setStartDate((currDate) => {
            let newDate = currDate 
              ? new Date(currDate.setMonth(date.getMonth()))
              : new Date(new Date().setMonth(date.getMonth()));
            return newDate;
          });
          setShowMonthPicker(false);
        } else {
          setStartDate(date);
        }
      }}
      onSelect={() => {
        if (props.onChange) props.onChange(startDate);
        if (props.onSelect) props.onSelect(startDate);           
      }}
      onCalendarClose={() => {
        if (props.onChange) props.onChange(startDate);
        if (props.onCalendarClose) props.onCalendarClose(startDate);           
      }}
    />
  );
}

export default MonthYearDatePicker;