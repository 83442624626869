
export const appStateReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_CURRENT_CATEGORY':
      return {
        ...state,
        currentCategory: action.payload
      };



    default:
      return state;
  }
}