import React, { useState, useRef } from "react";

export default function InputCode({
  length,
  loading,
  onComplete,
  className,
  style,
}) {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);

  // Every key press
  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  // For backspaces
  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  const fillValues = (value) =>
    new Array(length).fill("").map((data, index) => value[index] ?? "");

  // For pasting
  const onPaste = (e, slot) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text");
    const nextValues = pastedValue.slice(0, length);
    if (/[^0-9]/.test(nextValues)) return;
    const newCode = fillValues(nextValues);
    setCode(newCode);
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  return (
    <div className="code-input">
      <div className="code-input">
        {code.map((num, idx) => {
          return (
            <input
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
              className={className}
              onPaste={onPaste}
              style={style}
            />
          );
        })}
      </div>
    </div>
  );
}
