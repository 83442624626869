import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { RiFileInfoLine, RiFilterFill, RiFilterOffLine } from "react-icons/ri";
import { HiSwitchHorizontal } from "react-icons/hi";
// import { pageSelectedLabels } from "./index";
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import "../../assets/styles/multiselect-custom.css";
import "../../assets/styles/filter-labels.css";
import Select from "react-select";

let selectedFilters = [];

export default function FiltersModal(props) {
  const {
    endDate,
    startDate,
    setEndDate,
    setStartDate,
    filteredLabels,
    setFilteredLabels,
    labels,
    invalidState,
    setInvalidState,
    invalidDateRange,
    setInvalidDateRange,
    handleDateFilterModal,
    showDateFilter,
    setShowDateFilter,
    handleSave,
    handleClearFilter,
    getBackground,
    handleFilterLabels,
    gmailFilterProp,
    setgmailFilterProp,
    // briefs,
    // setPreset,
    // preset,
    // columns,
    // setColumns,
    // tableId,
    // setTableId,
  } = props;

  //   const [startDate, setStartDate] = useState(
  //     props.currentFilter.startDate || null
  //   );
  //   const [endDate, setEndDate] = useState(props.currentFilter.endDate || null);
  //   const [invalidState, setInvalidState] = useState(true);
  //   const [invalidDateRange, setInvalidDateRange] = useState(false);
  //   const [search, setSearch] = useState(props.search || "");

  //   const { handleSubmit } = useForm();

  //   const handleModalClose = () => {
  //     props.closeFiltersModal();
  //   };

  //   const handleClearFilter = () => {
  //     props.executeFilter(null);
  //   };

  //   const handleSave = () => {
  //     let filters = {
  //       startDate: startDate,
  //       endDate: endDate,
  //       searchFilters: search,
  //     };

  //     props.executeFilter(filters);
  //   };

  //labels
  const [gmailFilter, setgmailFilter] = useState({
    value: gmailFilterProp,
    label:
      gmailFilterProp.charAt(0).toUpperCase() + gmailFilterProp.substring(1),
  });

  //options containing possible values for the Gmail column
  const gmailFilterOptions = [
    { value: "all", label: "All" },
    { value: "sent", label: "Sent" },
    { value: "inbox", label: "Inbox" },
    { value: "uploaded", label: "Uploaded" },
  ];

  selectedFilters = filteredLabels;

  const handleFilterChange = (evt) => {
    setFilteredLabels(evt); //save for UI display of selected labels
  };

  const handleRemoveChange = (evt) => {
    setFilteredLabels(evt); //save for UI display of selected labels
  };

  useEffect(() => {
    // console.log("selected Label", filteredLabels);
    if (
      (startDate !== null &&
        startDate !== "" &&
        endDate !== null &&
        endDate !== "") ||
      (filteredLabels !== null && filteredLabels !== [])
    ) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        setInvalidDateRange(true);
        setInvalidState(true);
      } else {
        setInvalidDateRange(false);
        setInvalidState(false);
      }
    } else {
      setInvalidState(true);
    }
  });

  //only filter by gmail when filter button has been pressed
  const gmailFilterProcess = () => {
    console.log("SETTING GMAIL FILTER PROP:", gmailFilter.value);
    setgmailFilterProp(gmailFilter.value);
  };

  useEffect(() => {
    console.log("GMAIL FILTER IS:", gmailFilter);
  }, [gmailFilter]);

  useEffect(() => {
    console.log("LABELS FILTER IS:", filteredLabels);
  }, [filteredLabels]);

  useEffect(() => {
    console.log("START DATE IS:", startDate);
    console.log("END DATE IS:", endDate);
  }, [startDate, endDate]);

  const filterCleared = () => {
    console.log("CLEARED FILTER");
    setStartDate(null);
    setEndDate(null);
    setFilteredLabels([]);
    setgmailFilter("all");
    handleClearFilter();
  };

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: 70 }}
      >
        <div className="relative w-full my-6 mx-auto max-w-md">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-xl font-semibold">Manage Filters</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowDateFilter(false)}
              >
                <GrClose />
              </button>
            </div>

            <div className="flex p-6 flex-col gap-6">
              <div>
                <p className="font-bold text-sm">DATE RANGE </p>
                <div className="grid grid-cols-2 gap-4 pt-2">
                  <div>
                    <p className="text-sm font-semibold text-gray-400">From</p>
                    <div className="relative mt-2">
                      <div className="absolute pin-r pin-t mt-4 mr-5 ml-2 text-purple-lighter">
                        <RiFileInfoLine />
                      </div>
                      <DatePicker
                        popperProps={{
                          positionFixed: true,
                        }}
                        className="border w-full rounded text-xs py-2 px-1 border-gray-300"
                        dateFormat="dd MMM yyyy"
                        selected={
                          startDate === null ? null : new Date(startDate)
                        }
                        placeholderText="Start Date"
                        onChange={(selected) => setStartDate(selected)}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="text-sm font-semibold text-gray-400">To</p>
                      <div className="relative mt-2">
                        <div className="absolute pin-r pin-t mt-4 mr-5 ml-2 text-purple-lighter">
                          <RiFileInfoLine />
                        </div>
                        <DatePicker
                          popperProps={{
                            positionFixed: true,
                          }}
                          className="border w-full rounded text-xs py-2 px-1 border-gray-300"
                          dateFormat="dd MMM yyyy"
                          selected={endDate === null ? null : new Date(endDate)}
                          placeholderText="End Date"
                          onChange={(selected) => setEndDate(selected)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {invalidDateRange && (
                  <div className="pt-1">
                    <small className="text-red-400">
                      End date should not be earlier than start date.
                    </small>
                  </div>
                )}
              </div>
              <div>
                <p className="font-bold text-sm">LABELS</p>
                <div className="relative my-2">
                  <Multiselect
                    isObject={false}
                    onRemove={(event) => handleRemoveChange(event)}
                    onSelect={(event) => handleFilterChange(event)}
                    options={labels}
                    selectedValues={selectedFilters}
                    showCheckbox
                    placeholder="Filter by Label..."
                  />
                </div>
              </div>
              <div>
                <p className="font-bold text-sm">GMAIL</p>
                <div className="relative my-2">
                  <Select
                    name="gmailFilter"
                    value={gmailFilter}
                    options={gmailFilterOptions}
                    onChange={(e) => setgmailFilter(e)}
                    defaultValue={gmailFilterOptions[0]}
                    placeholder="Filter by Gmail..."
                  >
                    {/* <option value="all">All</option>
										<option value="inbox">Inbox</option>
										<option value="sent">Sent</option> */}
                  </Select>
                </div>
              </div>
              <div className="flex px-14 gap-2">
                <button
                  className="flex-1 text-white justify-center active:bg-emerald-600 font-bold uppercase text-xs px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none flex bg-danger flex-nowrap whitespace-nowrap border
                    transition-colors hover:text-danger hover:bg-danger-l hover:border-danger"
                  type="button"
                  onClick={() => filterCleared()}
                >
                  Clear Filters <RiFilterOffLine />
                </button>
                <button
                  className={`flex-1 text-white justify-center font-bold uppercase text-xs px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none flex border transition-colors
                    ${
                      invalidState
                        ? "bg-gray-500"
                        : "bg-green-500 hover:text-secondary hover:bg-secondary-l hover:border- hover:border-secondary"
                    }`}
                  type="submit"
                  disabled={invalidState}
                  onClick={() => {
                    gmailFilterProcess();
                    handleSave();
                  }}
                >
                  Filter <RiFilterFill />
                </button>
              </div>
            </div>
            {/* <div className="p-4 hidden">
              <div className="flex">
                <p className="font-bold text-sm ml-2">COLUMN VIEW</p>
              </div>
              <div className="relative my-2">
                <select
                  onChange={(e) => {
                    setPreset(e.target.value !== "" ? e.target.value : "");
                  }}
                  className="p-2 rounded-lg border bg-transparent w-full col-span-2"
                  value={preset}
                >
                  <option value="">- - - Please Select - - -</option>
                  {briefs?.map((p, i) => {
                    return <option value={p.id}>{p.name}</option>;
                  })}
                </select>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 bg-black"
        style={{ zIndex: 60 }}
      ></div>
    </>
  );
}
