import React from 'react';
import Button from './Button';
import {
  MdOutlineCheckCircleOutline,
  MdSave,
  MdWarningAmber,
  MdPermIdentity,
} from "react-icons/md"
import {
  HiOutlineTrash,
} from "react-icons/hi"
// import { GetUserCM, GetTeam, GetTeamCM, ListUsers, ListTeams, ListClientMatters, ListArchivedClientMatters, ListTeamsCM, ListClients, ListMatters } from "./graphql/queries"


function StyleGuide() {
  async function handleTest() {
    // const user = await GetUserCM("af73f4e8-a94a-40c6-9424-91833a936980", "ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // const users = await ListUsers("ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("styleguide user", user)
    // const teamCM = await GetTeamCM("b840828e-1756-4c7b-bcf8-30709b8a2ab1", "ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("styleguide teamCM", teamCM)
    // const teamnoCM = await GetTeam("b840828e-1756-4c7b-bcf8-30709b8a2ab1")
    // console.log("styleguide teamnoCM", teamnoCM)
    // const listTeams = await ListTeams("ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("ListTeams", listTeams)
    // const listTeams = await ListTeamsCM("ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("ListTeams", listTeams)
    // const listCM = await ListClientMatters("ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("ListClientMatters", listCM)
    // const listArchivedCM = await ListArchivedClientMatters("ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("ListArchivedClientMatters", listArchivedCM)
    // const listClients = await ListClients("ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("listClients", listClients)
    // const listMatters = await ListMatters("ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e")
    // console.log("listMatters", listMatters)
  }


  return(
    <>
    <div className="mt-10 container mx-auto cursor-auto select-auto">
      <div className="text-3xl font-medium mb-2">Buttons</div>
      {/* Buttons Text Only */}
      <div className="p-4 container rounded-lg border-gray-200 border mb-4 cursor-default">
        <div className="text-lg font-medium mb-2">Button Text</div>
        <hr className="border-gray-200 mb-2" />
        <div className="">
          <div className="mb-2 grid grid-cols-12">
            <div className="text-md font-medium col-span-2">Solid</div>
            <div className="inline col-span-10">
              <Button
                variant="default-s"
                href="#"
                className="mr-2 px-2"
                onClick={() => handleTest()}>Default</Button>
              <Button
                variant="primary"
                href="#"
                className="mr-2 px-2">Primary</Button>
              <Button
                variant="secondary"
                href="#"
                className="mr-2 px-2">Secondary</Button>
              <Button
                variant="warning"
                href="#"
                className="mr-2 px-2">Warning</Button>
              <Button
                variant="danger"
                href="#"
                className="mr-2 px-2">Danger</Button>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Solid Disabled</span>
            <div className="inline col-span-10">
              <Button
                variant="default-s"
                href="#"
                disabled={true}
                className="mr-2 px-2">Default</Button>
              <Button
                variant="primary"
                href="#"
                disabled={true}
                className="mr-2 px-2">Primary</Button>
              <Button
                variant="secondary"
                href="#"
                disabled={true}
                className="mr-2 px-2">Secondary</Button>
              <Button
                variant="warning"
                href="#"
                disabled={true}
                className="mr-2 px-2">Warning</Button>
              <Button
                variant="danger"
                href="#"
                disabled={true}
                className="mr-2 px-2">Danger</Button>
            </div>
          </div>
          <div className="mb-2 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Light</span>
            <div className="inline col-span-10">
              <Button
                href="#"
                className="mr-2 px-2">Default</Button>
              <Button
                variant="primary-l"
                href="#"
                className="mr-2 px-2">Primary</Button>
              <Button
                variant="secondary-l"
                href="#"
                className="mr-2 px-2">Secondary</Button>
              <Button
                variant="warning-l"
                href="#"
                className="mr-2 px-2">Warning</Button>
              <Button
                variant="danger-l"
                href="#"
                className="mr-2 px-2">Danger</Button>
            </div>
          </div>
          <div className="mb-2 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Light Disabled</span>
            <div className="inline col-span-10">
              <Button
                href="#"
                disabled={true}
                className="mr-2 px-2">Default</Button>
              <Button
                variant="primary-l"
                href="#"
                disabled={true}
                className="mr-2 px-2">Primary</Button>
              <Button
                variant="secondary-l"
                href="#"
                disabled={true}
                className="mr-2 px-2">Secondary</Button>
              <Button
                variant="warning-l"
                href="#"
                disabled={true}
                className="mr-2 px-2">Warning</Button>
              <Button
                variant="danger-l"
                href="#"
                disabled={true}
                className="mr-2 px-2">Danger</Button>
            </div>
          </div>
          {/* <div>
            <span className="text-md font-medium">Normal</span>
            <div className="ml-40 inline">
              <Button
                href="#"
                className="mr-2">Default</Button>
              <Button
                variant="primary-l"
                href="#"
                className="mr-2">Primary</Button>
              <Button
                variant="secondary-l"
                href="#"
                className="mr-2">Secondary</Button>
              <Button
                variant="warning-l"
                href="#"
                className="mr-2">Warning</Button>
              <Button
                variant="danger-l"
                href="#"
                className="mr-2">Danger</Button>
            </div>
          </div> */}
        </div>
      </div>
    {/* Buttons with Icons */}
      <div className="p-4 container rounded-lg border-gray-200 border mb-4 cursor-default">
        <div className="text-lg font-medium mb-2">Buttons with Icons</div>
        <hr className="border-gray-200 mb-2" />
        <div className="">
          <div className="mb-2 grid grid-cols-12">
            <div className="text-md font-medium col-span-2">Solid</div>
            <div className="inline col-span-10">
              <Button
                variant="default-s"
                href="#"
                className="mr-2 px-2"><MdPermIdentity className="mr-1" />Default</Button>
              <Button
                variant="primary"
                href="#"
                className="mr-2 px-2"><MdOutlineCheckCircleOutline className="mr-1" />Confirm</Button>
              <Button
                variant="secondary"
                href="#"
                className="mr-2 px-2"><MdSave className="mr-1" />Save</Button>
              <Button
                variant="warning"
                href="#"
                className="mr-2 px-2"><MdWarningAmber className="mr-1" />Warning</Button>
              <Button
                variant="danger"
                href="#"
                className="mr-2 px-2"><HiOutlineTrash className="mr-1" />Delete</Button>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Solid Disabled</span>
            <div className="inline col-span-10">
              <Button
                variant="default-s"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdPermIdentity className="mr-1" />Default</Button>
              <Button
                variant="primary"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdOutlineCheckCircleOutline className="mr-1" />Confirm</Button>
              <Button
                variant="secondary"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdSave className="mr-1" />Save</Button>
              <Button
                variant="warning"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdWarningAmber className="mr-1" />Warning</Button>
              <Button
                variant="danger"
                href="#"
                disabled={true}
                className="mr-2 px-2"><HiOutlineTrash className="mr-1" />Delete</Button>
            </div>
          </div>
          <div className="mb-2 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Light</span>
            <div className="inline col-span-10">
              <Button
                href="#"
                className="mr-2 px-2"><MdPermIdentity className="mr-1" />Default</Button>
              <Button
                variant="primary-l"
                href="#"
                className="mr-2 px-2"><MdOutlineCheckCircleOutline className="mr-1" />Confirm</Button>
              <Button
                variant="secondary-l"
                href="#"
                className="mr-2 px-2"><MdSave className="mr-1" />Save</Button>
              <Button
                variant="warning-l"
                href="#"
                className="mr-2 px-2"><MdWarningAmber className="mr-1" />Warning</Button>
              <Button
                variant="danger-l"
                href="#"
                className="mr-2 px-2"><HiOutlineTrash className="mr-1" />Delete</Button>
            </div>
          </div>
          <div className="mb-2 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Light Disabled</span>
            <div className="inline col-span-10">
              <Button
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdPermIdentity className="mr-1" />Default</Button>
              <Button
                variant="primary-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdOutlineCheckCircleOutline className="mr-1" />Confirm</Button>
              <Button
                variant="secondary-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdSave className="mr-1" />Save</Button>
              <Button
                variant="warning-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdWarningAmber className="mr-1" />Warning</Button>
              <Button
                variant="danger-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><HiOutlineTrash className="mr-1" />Delete</Button>
            </div>
          </div>
          {/* <div>
            <span className="text-md font-medium">Normal</span>
            <div className="ml-40 inline">
              <Button
                href="#"
                className="mr-2">Default</Button>
              <Button
                variant="primary-l"
                href="#"
                className="mr-2">Primary</Button>
              <Button
                variant="secondary-l"
                href="#"
                className="mr-2">Secondary</Button>
              <Button
                variant="warning-l"
                href="#"
                className="mr-2">Warning</Button>
              <Button
                variant="danger-l"
                href="#"
                className="mr-2">Danger</Button>
            </div>
          </div> */}
        </div>
      </div>
    {/* Icons Only */}
      <div className="p-4 container rounded-lg border-gray-200 border mb-4 cursor-default">
        <div className="text-lg font-medium mb-2">Icons Only</div>
        <hr className="border-gray-200 mb-2" />
        <div className="">
          <div className="mb-2 grid grid-cols-12">
            <div className="text-md font-medium col-span-2">Solid</div>
            <div className="inline col-span-10">
              <Button
                variant="default-s"
                href="#"
                className="mr-2 px-2"><MdPermIdentity /></Button>
              <Button
                variant="primary"
                href="#"
                className="mr-2 px-2"><MdOutlineCheckCircleOutline /></Button>
              <Button
                variant="secondary"
                href="#"
                className="mr-2 px-2"><MdSave /></Button>
              <Button
                variant="warning"
                href="#"
                className="mr-2 px-2"><MdWarningAmber /></Button>
              <Button
                variant="danger"
                href="#"
                className="mr-2 px-2"><HiOutlineTrash /></Button>
            </div>
          </div>
          <div className="mb-6 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Solid Disabled</span>
            <div className="inline col-span-10">
              <Button
                variant="default-s"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdPermIdentity /></Button>
              <Button
                variant="primary"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdOutlineCheckCircleOutline /></Button>
              <Button
                variant="secondary"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdSave /></Button>
              <Button
                variant="warning"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdWarningAmber /></Button>
              <Button
                variant="danger"
                href="#"
                disabled={true}
                className="mr-2 px-2"><HiOutlineTrash /></Button>
            </div>
          </div>
          <div className="mb-2 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Light</span>
            <div className="inline col-span-10">
              <Button
                href="#"
                className="mr-2 px-2"><MdPermIdentity /></Button>
              <Button
                variant="primary-l"
                href="#"
                className="mr-2 px-2"><MdOutlineCheckCircleOutline /></Button>
              <Button
                variant="secondary-l"
                href="#"
                className="mr-2 px-2"><MdSave /></Button>
              <Button
                variant="warning-l"
                href="#"
                className="mr-2 px-2"><MdWarningAmber /></Button>
              <Button
                variant="danger-l"
                href="#"
                className="mr-2 px-2"><HiOutlineTrash /></Button>
            </div>
          </div>
          <div className="mb-2 grid grid-cols-12">
            <span className="text-md font-medium w-52 col-span-2">Light Disabled</span>
            <div className="inline col-span-10">
              <Button
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdPermIdentity /></Button>
              <Button
                variant="primary-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdOutlineCheckCircleOutline /></Button>
              <Button
                variant="secondary-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdSave /></Button>
              <Button
                variant="warning-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><MdWarningAmber /></Button>
              <Button
                variant="danger-l"
                href="#"
                disabled={true}
                className="mr-2 px-2"><HiOutlineTrash /></Button>
            </div>
          </div>
          {/* <div>
            <span className="text-md font-medium">Normal</span>
            <div className="ml-40 inline">
              <Button
                href="#"
                className="mr-2">Default</Button>
              <Button
                variant="primary-l"
                href="#"
                className="mr-2">Primary</Button>
              <Button
                variant="secondary-l"
                href="#"
                className="mr-2">Secondary</Button>
              <Button
                variant="warning-l"
                href="#"
                className="mr-2">Warning</Button>
              <Button
                variant="danger-l"
                href="#"
                className="mr-2">Danger</Button>
            </div>
          </div> */}
        </div>
      </div>

      <div>
        <h1 className="text-3xl font-medium">API</h1>
      </div>
      <div className="flex mb-10">
        <table className="border-collapse border border-gray-300 table-auto flex-1">
          <thead className="border-gray-200">
            <tr className=" text-left">
              <th className="font-medium text-lg p-2 border border-gray-300 ">Name</th>
              <th className="font-medium text-lg p-2 border border-gray-300 ">Type</th>
              <th className="font-medium text-lg p-2 border border-gray-300 ">Default</th>
              <th className="font-medium text-lg p-2 border border-gray-300 ">Description</th>
            </tr>
          </thead>
          <tbody className="border border-gray-300">
            <tr className="bg-gray-100">
              <td className="text-lg p-2 border border-gray-300 ">href</td>
              <td className="text-lg p-2 border border-gray-300 ">string</td>
              <td className="text-lg p-2 border border-gray-300 "></td>
              <td className="text-lg p-2 border border-gray-300 ">{"Providing a href will render an "}<span className="bg-primary-l text-lg">{"<a>"}</span>{" element, styled as a button."}</td>
            </tr>
            <tr>
              <td className="text-lg p-2 border border-gray-300 ">size</td>
              <td className="text-lg p-2 border border-gray-300 "><span className="bg-primary-l text-lg">'small'</span> | <span className="bg-primary-l text-lg">'large'</span></td>
              <td className="text-lg p-2 border border-gray-300 "><span className="bg-primary-l text-lg">'small'</span></td>
              <td className="text-lg p-2 border border-gray-300 ">{"Specifies a large or small button."}</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-lg p-2 border border-gray-300 ">block</td>
              <td className="text-lg p-2 border border-gray-300 ">boolean</td>
              <td className="text-lg p-2 border border-gray-300 "><span className="bg-primary-l text-lg">false</span></td>
              <td className="text-lg p-2 border border-gray-300 ">{"Indicate if the button will take full-width."}</td>
            </tr>
            <tr>
              <td className="text-lg p-2 border border-gray-300 ">className</td>
              <td className="text-lg p-2 border border-gray-300 ">string</td>
              <td className="text-lg p-2 border border-gray-300 "></td>
              <td className="text-lg p-2 border border-gray-300 ">{"Specifies additional classNames to customize the button using Tailwind CSS utilities"}</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-lg p-2 border border-gray-300 ">variant</td>
              <td className="text-lg p-2 border border-gray-300 ">string</td>
              <td className="text-lg p-2 border border-gray-300 "><span className="bg-primary-l text-lg">'default'</span></td>
              <td className="text-lg p-2 border border-gray-300 ">One or more button variant combinations <br /><br /> buttons may be one of a variety of visual variants such as: <br /><br />
                <span className="bg-primary-l text-lg">'primary'</span>{" | "}
                <span className="bg-primary-l text-lg">'secondary'</span>{" | "}
                <span className="bg-primary-l text-lg">'warning'</span>{" | "}
                <span className="bg-primary-l text-lg">'danger'</span>{" | "}
                <span className="bg-primary-l text-lg">'default'</span></td>
            </tr>
            <tr>
              <td className="text-lg p-2 border border-gray-300 ">onClick</td>
              <td className="text-lg p-2 border border-gray-300 ">function</td>
              <td className="text-lg p-2 border border-gray-300 "></td>
              <td className="text-lg p-2 border border-gray-300 ">{"Modifies the onClick event of the button"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    </>
  )
}

export default StyleGuide;