export const mmaDomains = [
  // "localhost",
  "develop.d3bhf42tem9b8e.amplifyapp.com", // MMA's Dev website
  "mma-uat.d3jteiyknoq4lq.amplifyapp.com", // MMA's UAT website
  "portal.makemyaffidavit.com", // MMA's production website
];

export const hbaDomains = [
  // "localhost",
  "hba-uat.d3pz5ozavnp2zv.amplifyapp.com", // HBA's UAT website
  "portal.homebuilding.app", // HBA's production website
];

export const obaDomains = [
  // "localhost",
  "oba-uat.d3jteiyknoq4lq.amplifyapp.com", // OBA's UAT website
  "portal.ownerbuilder.app", // OBA's production website
];