import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";
import { API } from "aws-amplify";
import { CgClose, CgTrash } from "react-icons/cg";
import { ArchiveUser } from "../../shared/graphql/mutations";
export default function DeleteModal({
  close,
  toDeleteid,
  userEmail,
  userCompanyId,
  setContactList,
  ContactList,
  getContacts,
  setalertMessage,
  setShowToast,
  isTeam,
  getTeams,
  setisLoading,
  setArchivedUsers,
}) {
  function StopPropagate(e) {
    e.stopPropagation();
  }

  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const TrashIcon = () => {
    return (
      <div className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center">
        <CgTrash size={25} className="text-white" />
      </div>
    );
  };

  const Cancel = () => {
    const [isHover, setisHover] = useState(false);
    return (
      <button
        onClick={() => close()}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className="px-16 py-1 flex flex-row font-medium text-md justify-center items-center bg-white rounded-md gap-2 border border-gray-300 hover:bg-gray-700 hover:text-white"
      >
        Cancel <CgClose />
      </button>
    );
  };

  const mDeleteTeam = `mutation deleteTeam($id: ID) {
  teamDelete(id: $id) {
    id
  }
}`;

  const handleDelete = async () => {
    close();
    if (isTeam) {
      await deleteTeam(toDeleteid);
    } else {
      await archiveUser(toDeleteid, userCompanyId);
    }
  };

  const deleteTeam = async (id) => {
    const request = await API.graphql({
      query: mDeleteTeam,
      variables: {
        id: id,
      },
    });

    console.log("mDeleteTeam", request);

    if (request) {
      var contactsCopy = ContactList;
      contactsCopy.map((x, index) =>
        x.id === id ? contactsCopy.splice(index, 1) : x
      );

      setContactList(contactsCopy);
      //setisLoading(true);
      //await getTeams();
      setalertMessage(`Team Deleted Sucessfully`);
      setShowToast(true);
      //setTimeout(() => {
      //setisLoading(false);
      //}, 1500);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    } else {
      setalertMessage(`Cannot Delete Team. Please contact support`);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  };

  async function archiveUser(userId, companyId) {
    const request = await ArchiveUser(userId, companyId);

    if (request && request?.data?.userSoftDeleteOrRestore === true) {
      let userContact = ContactList.find((x) => x.id === userId);

      setContactList((contacts) =>
        contacts.filter((contact) => contact.id !== userContact.id)
      );
      setArchivedUsers((contacts) => [...contacts, userContact]);

      setalertMessage(`Contact Archived Sucessfully`);

      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        // getContacts();
      }, 5000);
    } else {
      setalertMessage(`Cannot Archive Contact. Please contact support`);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }

  const Delete = () => {
    const [isHover, setisHover] = useState(false);

    return (
      <button
        onClick={() => handleDelete()}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className="px-16 py-1 flex flex-row font-medium text-md justify-center
          items-center text-white bg-red-500 rounded-md gap-2 hover:bg-white
          hover:text-red-500 border border-red-500"
      >
        Delete
        <CgTrash />
      </button>
    );
  };

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="px-8 pt-6 pb-8 flex flex-col bg-white rounded-2xl items-center justify-center opacity-0 scale-90"
          onClick={StopPropagate}
        >
          <TrashIcon />
          <div className="font-semibold text-lg pb-3">Delete</div>
          <div className="text-sm">
            Once confirmed, this action will never be undone.
          </div>
          <div className="text-sm pb-8">Do you want to continue?</div>
          <div className="flex flex-row gap-3">
            <Cancel />
            <Delete />
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
