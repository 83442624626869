// Local Components/Files
import "./ScrollBar.css";
import FormEdit from "./form-builder-edit";
import FormPreview from "./form-builder-preview";

//Libraries
import { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Select from "react-select";
import { Link } from "react-router-dom"

//Icons
import { IconContext } from "react-icons";
import { IoChevronBackOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { BsFillBellFill } from "react-icons/bs";
import { TbChecks } from "react-icons/tb";
import { FaInfoCircle } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";

//Local Hooks, Functions, Reducers
import StoreProvider from "./reducers/StoreProvider";
import { FormBuilderContext } from "./reducers/StoreProvider";

// For Navigation, immutable
const navigationTabs = {
  edit: { label: "Edit", component: <FormEdit /> },
  preview: { label: "Preview", component: <FormPreview /> },
};

export default function FormBuilder() {
  useEffect(() => {
    document.body.classList.add("scroll");

    return () => {
      document.body.classList.remove("scroll");
    };
  });

  return (
    <StoreProvider>
      <Modal />
      <div
        className="form-builder-page min-h-screen bg-neutral-100"
        style={{
          margin: "0 0 0 76px",
        }}
      >
        <TopNav />
        <MainNavigation />
      </div>
    </StoreProvider>
  );
}

function MainNavigation() {
  const { state, dispatch } = useContext(FormBuilderContext);

  return navigationTabs[state.appState.activeTab].component;
}

function TopNav() {
  const { state, dispatch } = useContext(FormBuilderContext);
  const { activeTab } = state.appState;
  const { name } = state.formData.formDetails;

  const handleChangeTab = () => {
    if (activeTab == "edit") {
      dispatch({
        type: "CHANGE_TAB",
        payload: "preview",
      })
      dispatch({
        type: "INITIALIZE_ANSWERS_STATE",
      })
    } else if (activeTab == "preview") {
      dispatch({
        type: "CHANGE_TAB",
        payload: "edit",
      })
    }
  }

  const handleOpenSettings = () => {
    dispatch({
      type: "OPEN_MODAL",
    });
    dispatch({
      type: "CHANGE_MODAL_CONTENT",
      payload: <SettingsModal />,
    });
  };

  return (
    <div className="sticky z-30 top-0 w-full py-4 px-4 flex justify-between bg-white">
      <div className="flex items-center gap-3">
        <Link to={'/form-dashboard'}>
          <IoChevronBackOutline />
        </Link>
        <p className="text-xl font-medium">{name}</p>
      </div>

      <div className="flex items-center gap-3">
        <p className="text-base font-medium">Preview Form</p>
        <ToggleSwitch
          active={activeTab == "preview"}
          onClick={() => handleChangeTab()}
        />
      </div>

      <div className="flex gap-3">
        <button
          className="px-1 bg-white rounded-md"
          onClick={handleOpenSettings}
        >
          <CiSettings className="text-3xl text-gray-500" />
        </button>
        <button className="py-2 w-20 bg-white border rounded-md font-medium">
          Delete
        </button>
        <button
          className="py-2 w-20 bg-green-500 rounded-md text-white font-medium"
          onClick={() => console.log(state)}
        >
          Save
        </button>
      </div>
    </div>
  );
}

function ToggleSwitch({ active, onClick }) {
  return (
    <div
      className={`flex-none w-12 p-0.5 flex items-center rounded-full cursor-pointer duration-300 ease-in-out 
        ${active ? "bg-green-100" : "bg-gray-200"}`}
      onClick={onClick}
    >
      <div
        className={`h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out 
          ${active ? "bg-green-500 transform translate-x-6" : "bg-gray-400"}`}
      ></div>
    </div>
  );
}

function Modal() {
  const { state, dispatch } = useContext(FormBuilderContext);

  useEffect(() => {
    if (state.modalState.modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [state.modalState.modalOpen]);

  const handleModalClose = () => {
    dispatch({
      type: "CHANGE_MODAL_OPEN",
      payload: false,
    });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {state.modalState.modalOpen && (
        <motion.div className="fixed z-40 w-screen h-screen grid place-content-center">
          {state.modalState.modalContent}

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute w-full h-full bg-black bg-opacity-40"
            onClick={handleModalClose}
          ></motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function SettingsModal() {
  const settingsTab = {
    notifications: {
      label: "Notifications",
      icon: <BsFillBellFill />,
      component: <SettingsNotifications />,
    },
    conditions: {
      label: "Conditions",
      icon: <TbChecks />,
      component: <SettingsConditions handleNewRuleOpen={handleNewRuleOpen} />,
    },
  };

  const settingsTabKeys = Object.keys(settingsTab);

  const { state, dispatch } = useContext(FormBuilderContext);
  const settingsState = state.appState.settings;

  const handleModalClose = () => {
    dispatch({
      type: "CLOSE_MODAL",
    });
  };

  const handleChangeSettingsTab = (currentTab) => {
    dispatch({
      type: "CHANGE_SETTINGS_TAB",
      payload: currentTab,
    });
  };

  /* New Rule Tab functions */
  function handleNewRuleOpen() {
    dispatch({
      type: "OPEN_NEW_RULE",
    });
  }

  function handleNewRuleClose() {
    dispatch({
      type: "CLOSE_NEW_RULE",
    });
  }

  function SettingsButtonTab({ settingsTabKey, icon, label, active }) {
    return (
      <button
        className={`w-full p-2 rounded-md flex gap-2 items-center ${
          active && "bg-darkGray text-white "
        }`}
        onClick={() => handleChangeSettingsTab(settingsTabKey)}
      >
        {icon}
        <span className="font-normal">{label}</span>
      </button>
    );
  }

  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      className="z-20 max-w-5xl p-7 bg-white rounded-2xl shadow-lg overflow-hidden relative"
      style={{
        height: "33rem",
      }}
    >
      <div>
        <header className="flex items-center">
          <h3 className="text-lg font-medium tracking-wide">Settings</h3>
          <button
            onClick={handleModalClose}
            className="bg-gray-200 opacity-70 rounded-full p-2 mr-2 ml-auto hover:opacity-100 hover:bg-gray-400"
          >
            <RxCross2 className="opacity-80 hover:opacity-100" />
          </button>
        </header>
        <div
          className="w-full h-full flex py-6 gap-6"
          style={{
            minWidth: "41rem",
          }}
        >
          <div className="flex flex-col gap-2 items-start">
            <IconContext.Provider value={{ className: "text-base" }}>
              {settingsTabKeys.map((settingsTabKey) => (
                <SettingsButtonTab
                  key={settingsTabKey}
                  settingsTabKey={settingsTabKey}
                  icon={settingsTab[settingsTabKey].icon}
                  label={settingsTab[settingsTabKey].label}
                  active={settingsTabKey == settingsState.currentTab}
                />
              ))}
            </IconContext.Provider>
          </div>
          <hr className="border-gray-400 border opacity-40 h-full" />
          {settingsTab[settingsState.currentTab].component}
        </div>
      </div>
      <div className="w-full h-full">
        <AnimatePresence exitBeforeEnter>
          {state?.newRuleState?.newRuleOpen && (
            <>
              <motion.section
                key="new-rule-bg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.2 }}
                exit={{ opacity: 0 }}
                className="w-1/2 bg-black opacity-20 absolute top-0 left-0 h-full"
                onClick={handleNewRuleClose}
              ></motion.section>
              <NewRuleTab
                state={state}
                dispatch={dispatch}
                handleNewRuleClose={handleNewRuleClose}
              />
            </>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

function NewRuleTab({ state, dispatch, handleNewRuleClose }) {
  return (
    <motion.section
      key="new-rule"
      initial={{ x: "50%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      className="w-1/2 bg-white shadow-xl absolute top-0 right-0 h-full p-7"
    >
      <header className="flex items-center">
        <h3 className="text-lg font-medium tracking-wide">New Rule</h3>
        <button
          onClick={handleNewRuleClose}
          className="bg-gray-200 opacity-70 rounded-full p-2 mr-2 ml-auto hover:opacity-100 hover:bg-gray-400"
        >
          <RxCross2 className="opacity-80 hover:opacity-100" />
        </button>
      </header>
      <div className="w-full h-full flex flex-col py-6 gap-3">
        <span className="flex flex-col gap-1 ">
          <p className="text-gray-500 font-semibold text-xs">IF</p>
          <Select />
        </span>
        <span className="flex flex-col gap-1">
          <p className="text-gray-500 font-semibold text-xs">STATE</p>
          <Select />
        </span>
        <span className="flex flex-col gap-1">
          <p className="text-gray-500 font-semibold text-xs">VALUE</p>
          <Select />
        </span>
        <hr className="border" />
        <span className="flex flex-col gap-1">
          <p className="text-gray-500 font-semibold text-xs">THEN</p>
          <Select />
        </span>
        <span className="flex flex-col gap-1">
          <p className="text-gray-500 font-semibold text-xs">PAGE</p>
          <Select />
        </span>
        <div className="flex gap-3">
          <button className="py-2 w-full bg-white border rounded-md text-black font-medium">
            Delete
          </button>
          <button className="py-2 w-full bg-green-600 rounded-md text-white font-medium">
            Add
          </button>
        </div>
      </div>
    </motion.section>
  );
}

function SettingsNotifications() {
  const { state, dispatch } = useContext(FormBuilderContext);
  const emailNotif = state.formData.emailNotification;

  const handleCheckboxChange = () => {
    dispatch({
      type: "CHANGE_EMAIL_NOTIFICATION",
      payload: !emailNotif,
    });
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-3">
        <input
          type="checkbox"
          checked={emailNotif}
          onChange={handleCheckboxChange}
          className="mb-auto cursor-pointer mt-1"
        />

        <div className="flex flex-col gap-2">
          <p className="font-medium text-sm">
            Send email notification after submission
          </p>
          <div className="flex gap-2 items-center w-full border-dashed border-yellow-400 border-2 rounded-md bg-orange-50 px-2 py-1.5 text-yellow-400 font-medium">
            <FaInfoCircle />
            <p className="font-medium">
              Form must have an email field to send email notifications
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function SettingsConditions({ handleNewRuleOpen }) {
  return (
    <div className="flex-1 flex flex-col gap-3">
      <div className="w-full flex justify-between">
        <div className="flex items-center gap-1">
          <input type="checkbox" />
          <p>Select All</p>
        </div>

        <div className="flex items-center gap-1">
          <button
            className="p-1 flex items-center gap-1 border rounded"
            onClick={handleNewRuleOpen}
          >
            Add
          </button>
          <button className="p-1 flex items-center gap-1 border rounded">
            Delete
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="p-3 flex items-start gap-3 border rounded">
          <input type="checkbox" className="mt-1" />

          <div className="flex-1">
            <p>Hatdog</p>
            <p>Style</p>
          </div>

          <span>icon</span>
        </div>
      </div>
    </div>
  );
}
