import { Tab } from "@headlessui/react";
import BriefColumn from "./columns";
import NewColumnTab from "./NewColumn";

const SettingsBar = ({ open, briefs, matterId }) => {
  return (
    
    <Tab.Group>
      <Tab.List className="flex border-b  text-gray-900 font-bold text-lg my-2 py-4 space-x-2 w-full">
        <Tab>
          {({ selected }) => (
            <button
              className={selected ? "border-b-2 border-gray-900 -my-4" : ""}
            >
              Settings
            </button>
          )}
        </Tab>
        <Tab>
          {({ selected }) => (
            <button
              className={selected ? "border-b-2 border-gray-900 -my-4" : ""}
            >
              New Column
            </button>
          )}
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <BriefColumn briefs={briefs} />
        </Tab.Panel>
        <Tab.Panel>
          <NewColumnTab matterId={matterId} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
    
  );
};

export default SettingsBar;
