import { useContext, useState, useEffect, createContext } from "react";
import Select from "react-select";

const FormAnswerContext = createContext(null)

function SinglePageArea({ pageContent, stateContext }) {
  const { id, order, name, description, sections } = pageContent;

  return (
    <FormAnswerContext.Provider value={stateContext}>
      <FormCard>
        <div className="flex flex-col gap-8">
          <div>
            <Heading>{name}</Heading>
            <Paragraph>{description}</Paragraph>
          </div>
          {sections.map((sectionContent, sectionIndex) => (
            <SectionArea key={sectionIndex} pageOrder={order} sectionContent={sectionContent} />
          ))}
        </div>
      </FormCard>
    </FormAnswerContext.Provider>
  );
}

function SectionArea({ pageOrder, sectionContent }) {
  const { order, question, sublabel, optionsType, options, properties } =
    sectionContent;

  return (
    <div className="flex flex-col gap-4">
      <div>
        <SubHeading>
          {question}
          {properties?.required && <span className="text-red-500">{` *`}</span>}
        </SubHeading>
        {sublabel !== "" && <Paragraph>{sublabel}</Paragraph>}
      </div>

      <FormInputTypeFactory
        pageOrder={pageOrder}
        sectionOrder={order}
        optionsType={optionsType}
        options={options}
      />
    </div>
  );
}




const handleAnswerSingle = (
  pageOrder,
  sectionOder,
  payload,
  dispatch,
) => {

  dispatch({
    type: 'SET_ANSWER_SINGLE',
    pageOrder: pageOrder,
    sectionOrder: sectionOder,
    payload: payload
  })

}

const handleAnswerMultiple = () => {

}

const handleAnswerToggle = () => {
  
}


/**
 * @param {Event} data - Event object
 * @param {String} regex - Regular expression
 * @returns function
 * */

const isDataValid = (data, regex, func) => {
  if (regex.test(data.target.value) || data.target.value === "") {
    return func();
  }
};

const inputTypes = {
  TEXT: { component: ShortText },
  TEXTAREA: { component: LongText },
  RADIOBUTTON: { component: RadioButtons },
  CHECKBOX: { component: Checkboxes },
  DROPDOWN: { component: DropdownList },
  LINEARSCALE: { component: LinearScale },
  TITLE: { component: TextHeader },
  NAME: { component: NameText },
  ADDRESS: { component: AddressText },
  PHONE: { component: PhoneNumber },
  EMAIL: { component: EmailAddress },
  DATETIME: { component: DateTimePicker },
  DATE: { component: DatePicker },
  TIME: { component: TimePicker },
  NUMBER: { component: NumberPicker },
  FILEUPLOAD: { component: FileUpload },
  SIGNATURE: { component: Signature },
};

function FormInputTypeFactory({
  pageOrder,
  sectionOrder,
  optionsType,
  options,
}) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { pages, formAnswers } = state?.formData


  const InputComponent = inputTypes[optionsType].component;

  return (
    <InputComponent
      pageOrder={pageOrder}
      sectionOrder={sectionOrder}
      optionsType={optionsType}
      options={options}
      sectionAnswers={formAnswers[`page_${pageOrder}`][`section_${sectionOrder}`]}
    />
  );
}

//================================================
// Component Factory
//================================================

function TextHeader({ pageOrder, sectionOrder, options }) {
  return <div></div>;
}

function ShortText({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers

  return (
    <FormTextInput
      className="w-1/2"
      placeholder={options[0]}
      saveChange={(e)=>handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)}
      value={answers[0]}
    />
  );
}

function LongText({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers

  return (
    <FormTextArea
      className=""
      placeholder={options[0]}
      saveChange={(e) => handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)}
      value={answers[0]}
      rows={3}
    />
  );
}

function RadioButtons({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers

  return (
    <fieldset className="flex flex-col gap-3">
      {options.map((option, optionIndex) => (
        <div
          key={`radio_${sectionOrder}_${optionIndex}`}
          className="flex items-center gap-3"
        >
          <input
            value={option}
            type="radio"
            name={`radio_${sectionOrder}`}
            onChange={(e) => handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)}
            checked={answers[0] === option}
          />
          <Label>{option}</Label>
        </div>
      ))}
    </fieldset>
  );
}

function Checkboxes({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers

  return (
    <fieldset className="flex flex-col gap-3">
      {options.map((option, optionIndex) => (
        <div
          key={`checkbox${sectionOrder}_${optionIndex}`}
          className="flex items-center gap-3"
        >
          <input
            value={option}
            type="checkbox"
            name={`checkbox_${sectionOrder}`}
            onChange={(e) => {}}
            checked={false}
          />
          <Label>{option}</Label>
        </div>
      ))}
    </fieldset>
  );
}

function DropdownList({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers

  const selectOptions = options.map((option) => {
    return {
      value: option,
      label: option,
    };
  });

  const selectStyle = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <div
      style={{
        maxWidth: "16rem",
      }}
    >
      <Select
        placeholder="Select an Option"
        options={selectOptions}
        onChange={(e) => handleAnswerSingle(pageOrder, sectionOrder, e.value, dispatch)}
        value={{label: answers[0], value: answers[0]}}
        styles={selectStyle}
      />
    </div>
  );
}

function LinearScale({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers
  
  return (
    <fieldset className="relative max-w-2xl flex justify-between gap-3">
      <div className="absolute w-full px-3.5 pt-1">
        <hr className="w-full border-2 border-gray-300" />
      </div>

      {options.map((option, optionIndex) => (
        <div
          key={`scale_${sectionOrder}_${optionIndex}`}
          className="z-10 flex flex-col items-center gap-3"
        >
          <input
            type="radio"
            className=""
            value={option}
            name={`scale_${sectionOrder}`}
            onChange={(e) => handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)}
            checked={answers[0] === option}
          />
          <Label>{optionIndex + 1}</Label>
          <Label>{option}</Label>
        </div>
      ))}
    </fieldset>
  );
}

function NameText({ pageOrder, sectionOrder, options, optionsType, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const [tempAnswer, setTempAnswer] = useState("");

  const dataEncapsulation = {
    tempAnswer: tempAnswer,
    setTempAnswer: setTempAnswer,
    optionsType: optionsType,
    sectionOrder: sectionOrder,
  };

  return (
    <div className="grid grid-cols-2 gap-6 max-w-xl">
      {[...options]
        .sort((a, b) => a.order - b.order)
        .map((option, optionIndex) => (
          <TextWithLabel
            key={`${sectionOrder}_${optionIndex}`}
            label={option.label}
            order={option.order}
            dataEncapsulation={dataEncapsulation}
          />
        ))}
    </div>
  );
}

/**
 * It is bad practice to instantiate a component inside of a component.
 * It causes rendering issues.
 **/
function TextWithLabel({
  label,
  className,
  order,
  dataEncapsulation: { tempAnswer, setTempAnswer, optionsType, sectionOrder },
}) {
  const { state, dispatch } = useContext(FormAnswerContext);

  return (
    <div className="flex-1 flex flex-col gap-1">
      <label className="text-gray-400 text-xs font-medium">{label}</label>
      <FormTextInput
        className={className}
        placeholder={label}
        value={''}
        saveChange={(e) => {}}
      />
    </div>
  );
}

function AddressText({ pageOrder, sectionOrder, options, optionsType, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const [tempAnswer, setTempAnswer] = useState("");

  const dataEncapsulation = {
    tempAnswer: tempAnswer,
    setTempAnswer: setTempAnswer,
    optionsType: optionsType,
    sectionOrder: sectionOrder,
  };

  return (
    <div className="flex flex-col gap-4">
      <TextWithLabel
        label="Street Address"
        order={options[0].order}
        dataEncapsulation={dataEncapsulation}
      />
      <TextWithLabel
        label="Street Address Line 2"
        order={options[1].order}
        dataEncapsulation={dataEncapsulation}
      />
      <div className="flex gap-4 max-w-lg">
        <TextWithLabel
          label="City"
          order={options[2].order}
          className="flex-auto"
          dataEncapsulation={dataEncapsulation}
        />
        <TextWithLabel
          label="State/Province"
          order={options[3].order}
          className="flex-auto"
          dataEncapsulation={dataEncapsulation}
        />
      </div>
      <TextWithLabel
        label="Postal/Zip Code"
        order={options[4].order}
        className="max-w-min"
        dataEncapsulation={dataEncapsulation}
      />
    </div>
  );
}

function PhoneNumber({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers
  

  /* onChange arguments */
  const regex = /^\d+$/;
  const onChange = (e) =>handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)

  return (
    <FormTextInput
      className=""
      style={{
        maxWidth: "16rem",
      }}
      placeholder={"(000) 000-000"}
      value={answers[0]}
      saveChange={(e) => isDataValid(e, regex, () => onChange(e))}
    />
  );
}

function EmailAddress({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers
  

  /* onChange arguments */
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const onChange = (e) => handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)

  return (
    <FormTextInput
      className=""
      style={{
        maxWidth: "16rem",
      }}
      placeholder={"Email"}
      value={answers[0]}
      saveChange={(e) => handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)}
    />
  );
}

function DateTimePicker({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const [tempAnswer, setTempAnswer] = useState("");

  console.log(state.formData.answers);

  return (
    <FormDateInput
      className=""
      style={{
        maxWidth: "16rem",
      }}
      placeholder="MM/DD/YYYY HH:MM"
      value={''}
      onChange={(e) => {}}
      type={"datetime-local"}
    />
  );
}

function DatePicker({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const [tempAnswer, setTempAnswer] = useState("");

  console.log(state.formData.answers);

  return (
    <FormDateInput
      className=""
      style={{
        maxWidth: "16rem",
      }}
      placeholder="MM/DD/YYYY"
      value={''}
      onChange={(e) => {}}
      type={"date"}
    />
  );
}

function TimePicker({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers
  
  return (
    <FormTextInput
      className=""
      style={{
        maxWidth: "16rem",
      }}
      placeholder="HH:MM"
      value={answers[0]}
      saveChange={(e) => handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)}
    />
  );
}

function NumberPicker({ pageOrder, sectionOrder, options, sectionAnswers }) {
  const { state, dispatch } = useContext(FormAnswerContext);
  const { answers } = sectionAnswers
  
  return (
    <FormTextInput
      className=""
      style={{
        maxWidth: "16rem",
      }}
      placeholder="Number"
      value={answers[0]}
      saveChange={(e) => handleAnswerSingle(pageOrder, sectionOrder, e.target.value, dispatch)}
    />
  );
}

function FileUpload({ pageOrder, sectionOrder, options, sectionAnswers }) {
  return <div></div>;
}

function Signature({ pageOrder, sectionOrder, options, sectionAnswers }) {
  return <div className="w-full h-44 border rounded-md"></div>;
}

//===============================================
//Reusable Styled Elements
//===============================================
function Heading({ children }) {
  return <h2 className="text-gray-700 text-xl font-semibold">{children}</h2>;
}

function SubHeading({ children }) {
  return <h4 className="text-base font-medium">{children}</h4>;
}

function Paragraph({ children }) {
  return <p className="text-gray-500">{children}</p>;
}

function Label({ children }) {
  return <label className="font-normal">{children}</label>;
}

//===============================================
//Reusable Components
//===============================================
function FormCard({ children }) {
  return (
    <div
      className="w-full p-10 flex flex-col gap-5  bg-white border rounded-lg"
      style={{
        maxWidth: "50rem",
      }}
    >
      {children}
    </div>
  );
}

function FormTextInput({ className, style, placeholder, value, saveChange}) {
  // Added this local state to prevent the input from returning a blank value
  const [inputValue, setInputValue] = useState(value);

  useEffect(()=>{
    setInputValue(value)
  },[value])

  return (
    <input
      className={`p-2 border rounded-md ${className}`}
      style={style}
      type="text"
      placeholder={placeholder}
      value={inputValue || ""}
      onBlur={saveChange}
      onChange={(e) => setInputValue(e.target.value)}
      
    />
  );
}

function FormTextArea({ placeholder, value, saveChange, rows }) {
  // Added this local state to prevent the input from returning a blank value
  const [inputValue, setInputValue] = useState(value);

  useEffect(()=>{
    setInputValue(value)
  },[value])
  
  return (
    <textarea
      className="p-2 border rounded-md resize-none"
      type="text"
      placeholder={placeholder}
      value={inputValue || ""}
      onBlur={saveChange}
      onChange={(e) => setInputValue(e.target.value)}
      rows={rows || 2}
    />
  );
}

function FormDateInput({
  className,
  style,
  placeholder,
  value,
  onChange,
  type,
}) {
  return (
    <input
      className={`p-2 border rounded-md ${className}`}
      style={style}
      type={"text"}
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
      onFocus={(e) => (e.target.type = type)}
      onBlur={(e) => (e.target.type = "text")}
    />
  );
}

export {
  SinglePageArea,
  SectionArea,
  FormInputTypeFactory,
  TextHeader,
  ShortText,
  LongText,
  RadioButtons,
  Checkboxes,
  DropdownList,
  LinearScale,
  NameText,
  AddressText,
  PhoneNumber,
  EmailAddress,
  DateTimePicker,
  DatePicker,
  TimePicker,
  NumberPicker,
  FileUpload,
  Signature,
  Heading,
  SubHeading,
  Paragraph,
  Label,
  FormCard,
  FormTextInput,
  FormTextArea,
};
