import React, { useState, useEffect, useRef } from "react";
import { Base64 } from "js-base64";
import "../../assets/styles/BackgroundPage.css";
import "../../assets/styles/Mobile.css";
import "../../assets/styles/BlankState.css";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import Button from "../../shared/Button"
import { MdArrowBackIos } from "react-icons/md";
import {
    // GrDocument,
    GrDocumentExcel,
    GrDocumentImage,
    GrDocumentPdf,
    GrDocumentText,
    GrDocumentTxt,
    GrDocumentWord,
} from "react-icons/gr";
import { FaRegFileAudio, FaRegFileVideo, FaSort } from "react-icons/fa";
import { AiFillTags, AiOutlineDownload } from "react-icons/ai";
import { API, Auth, Storage } from "aws-amplify";
import isMSdoc from "../../shared/msFileTypeChecker";
import html2pdf from "html2pdf.js";
import googleLogin from "../../assets/images/gmail-print.png";
import config from "../../aws-exports";

var moment = require("moment")

function MobileDescriptionModal({
  setreadmoreModal,
  setreadmoreModalData,
  readmoreModalData
}) {

  const data = readmoreModalData[0]

  const [from, setFrom] = useState(null);
  const [dateEmail, setDateEmail] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCC] = useState(null);

  const getFileType = (type) => {
    if (type !== null) {
      return type.split("/").slice(0, -1).join("/");
    }
  };

  const qGetFileDownloadLink = `
  query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
    gmailMessage(id: $id) {
      payload {
        content
      }
    }
  }
  `;

  const previewAndDownloadFile = async (id) => {
    console.log("preview and download file: ", id);
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      console.log("preview: ", result.data.file);
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
        console.log("downloadURL", downloadURL);
      }
    });
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const getEmailPayload = async (messageId) => {
    console.log("getEmailPayload()");
    const params = {
      query: qGetMessagePayload,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);

    return result?.data?.gmailMessage;
  };

  const previewAndDownloadPDF = async (id, gmailMessageId, subjectTitle) => {
    // For PDF creation

    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: config.aws_user_gmail_attachments_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    var opt = {
      margin: [30, 50, 30, 50],
      filename: subjectTitle,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        dpi: 96,
        scale: 1,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: "#FFF",
      },
      jsPDF: { unit: "pt", format: "a4", orientation: "p" },
      pagebreak: {
        before: ".page-break",
        mode: ["avoid-all", "css", "legacy"],
      },
    };

    var content = `<span>`;
    content += `<img src=${googleLogin} alt="" /><hr></hr>`;
    content += `<h2><b>${subjectTitle}</b></h2><hr></hr><br />`;
    content += `<p>From: ${from}</p>`;
    content += `<p>Date: ${moment(dateEmail).format(
      "DD MMM YYYY, hh:mm A"
    )}</p>`;
    content += `<p>To: ${to}</p>`;
    content += cc ? `<p>CC: ${cc}</p>` : "";
    content += `</span>`;

    const emailPayload = await getEmailPayload(gmailMessageId);

    if (emailPayload) {
      const htmlContent = emailPayload.payload
        .map((em) => em.content)
        .join("")
        .split('data":"')
        .pop()
        .split('"}')[0];

      content += Base64.decode(htmlContent).replace("body{color:", "");

      await html2pdf()
        .from(content)
        .set(opt)
        .toPdf()
        .output("datauristring")
        .then(function (pdfAsString) {
          const preBlob = dataURItoBlob(pdfAsString);
          const file = new File([preBlob], subjectTitle, {
            type: "application/pdf",
          });
          console.log(file);

          var key = `${gmailMessageId}/${Number(new Date())}${file.name
              .replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            type = "application/pdf",
            size = file.size;

          // put objects to s3
          try {
            Storage.put(key, file, {
              contentType: type,
              progressCallback(progress) {
                console.log(progress);
              },
              errorCallback: (err) => {
                console.error("204: Unexpected error while uploading", err);
              },
            })
              .then((fd) => {
                const mUpdateMatterFileObjectKey = `
                    mutation updateMatterFile ($id: ID, $s3ObjectKey: String, $size: Int, $type: String) {
                      matterFileUpdate(id: $id, s3ObjectKey: $s3ObjectKey, size: $size, type: $type) {
                        id
                      }
                    }
                `;

                const params = {
                  query: mUpdateMatterFileObjectKey,
                  variables: {
                    id: id,
                    s3ObjectKey: fd.key,
                    size: parseInt(size),
                    type: type,
                  },
                };

                API.graphql(params).then(async (res) => {
                  console.log("Get Details", res);
                  const params = {
                    query: qGetFileDownloadLink,
                    variables: {
                      id: id,
                    },
                  };
                  await API.graphql(params).then(async (result) => {
                    const { type, downloadURL, s3ObjectKey } = result.data.file;
                    window.open(downloadURL);
                  });
                });
              })
              .catch((err) => {
                console.error("Unexpected error while uploading", err);
              });
          } catch (e) {
            const response = {
              error: e.message,
              errorStack: e.stack,
              statusCode: 500,
            };
            console.error("Unexpected error while uploading", response);
          }
        });
    }
  };
  
  return(
    <>
    <div>
      <div
        className="justify-center items-center flex-none overflow-x-hidden overflow-y-hidden max-h-screen fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: 70 }}
      >
        <div className="w-full mx-auto max-w-md h-screen max-h-screen pt-16" onClick={(e) => {  }}>
          <div className="items-center max-h-full min-h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4" onClick={(e) => e.stopPropagation()}>
            {/* Heading Div */}
            <div className="w-full p-4" id="emailHeader"> 
              {/* Button Divs */}
              <div className="flex justify-between"> 
                <Button className="border-gray-300 mb-2" onClick={(e) => { 
                    setreadmoreModal(false) 
                    setreadmoreModalData([])
                }}><MdArrowBackIos className="text-xl float-left" />Back</Button> 
              </div>
              <div>
                <p className="font-bold">{data.name}</p>
                <p>Date: {moment(
                    data.createdAt
                  ).format(
                    "DD MMM YYYY, hh:mm A"
                  )}</p>
              </div>
            </div>
            {/* Email Body */}
            <div className="overflow-y-auto">
                <p 
                    dangerouslySetInnerHTML={{__html: data.details}}
                    className="pl-4 pr-4"
                ></p>
            </div>
            {data.type == null? null : (
                <div className="pt-5 w-full pl-4 pr-5">
                    <p className="font-bold pb-3">Attachment</p>
                    <div>
                        {data.type == null? null : (
                            data?.backgrounds?.items[0]?.files?.items?.length == 0? null : (
                                <div className="flex align-center gap-2">
                                    {getFileType(
                                        data.type
                                    ) ===
                                    "image" ? (
                                        <GrDocumentImage className="text-1xl flex-none" />
                                    ) : getFileType(
                                        data.type
                                    ) ===
                                        "audio" ? (
                                        <FaRegFileAudio className="text-1xl flex-none" />
                                    ) : getFileType(
                                        data.type
                                    ) ===
                                        "video" ? (
                                        <FaRegFileVideo className="text-1xl flex-none" />
                                    ) : getFileType(
                                        data.type
                                    ) ===
                                        "text" ? (
                                        <GrDocumentTxt className="text-1xl flex-none" />
                                     ) : getFileType(
                                        data.type
                                    ) ===
                                        "application" &&
                                        data.type
                                        .split(
                                        "."
                                    )
                                        .pop() ===
                                        "sheet" ? (
                                        <GrDocumentExcel className="text-1xl flex-none" />
                                    ) : getFileType(
                                        data.type
                                    ) ===
                                        "application" &&
                                        data.type
                                        .split(
                                            "."
                                        )
                                        .pop() ===
                                        "document" ? (
                                        <GrDocumentWord className="text-1xl flex-none" />
                                    ) : getFileType(
                                        data.type
                                    ) ===
                                        "application" &&
                                        data.type
                                        .split(
                                        "."
                                    )
                                        .pop() ===
                                        "text" ? (
                                        <GrDocumentText className="text-1xl flex-none" />
                                    ) : getFileType(
                                        data.type
                                    ) ===
                                        "application" ? (
                                        <GrDocumentPdf className="text-1xl flex-none" />
                                    ) : (
                                        <GrDocumentText className="text-1xl flex-none" />
                                    )}
                                    <p>{data.name}</p>
                                    {data.s3ObjectKey === null &&
                                      data.isGmailPDF ? (
                                        <>
                                          <AiOutlineDownload
                                            className="ml-1 flex-none text-cyan-400 text-base cursor-pointer text-lg"
                                            onClick={() =>
                                              {
                                                previewAndDownloadPDF(
                                                    data.id,
                                                    data.gmailMessageId,
                                                    data.name
                                                )
                                              }
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <AiOutlineDownload
                                            className="ml-1 flex-none text-cyan-400 text-base cursor-pointer text-lg"
                                            onClick={() =>
                                              {
                                                previewAndDownloadFile(data.id)
                                              }
                                            }
                                          />
                                        </>
                                      )}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}
          </div>
        </div>
      </div>  
      <div
        className="opacity-25 fixed inset-0 bg-black"
        style={{ zIndex: 60 }}
        onClick={() => {  }}
      ></div>
    </div>
    </>
  )
}

export default MobileDescriptionModal;
