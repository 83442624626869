import React, { useEffect, useRef, useState } from "react";
import Button from "../../shared/Button";
import UploadImage from "../../assets/images/upload-photo.svg";
import { API } from "aws-amplify";
import dummyBg from "../../assets/images/project-location-dummy-photos/dummy-background.png";
import { FiEdit } from "react-icons/fi";
import { Storage } from "aws-amplify";
import { BiCloudUpload } from "react-icons/bi";
import uploadIcon from "../../assets/images/upload-icon.png";
import PropertyDetailsModal from "./property-details-modal";
import { set } from "react-hook-form";
import { IoIosCloseCircle } from "react-icons/io";
import DeleteMatterModal from "../dashboard/delete-matters-modal";

const USER_DETAILS_QUERY = `
query user($id: String) {
  user(id: $id) {
    company {
      id
      name
    }
    email
    firstName
    lastName
    userType
    profilePicture
    hasPortalAccess
    organisations {
      items {
        company {
          id
          name
          representative {
            id
            firstName
            lastName
          }
        }
        userType
        hasPortalAccess
        isDeleted
      }
    }
  }
}
`;

const mgetUserById = `
    query user($id: String) {
      user(id: $id) {
        profilePictureKey
        profilePicture
      }
    }
  `;

const BACKGROUNDFILE_TAG_MUTATION = `
mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
  backgroundFileTag(backgroundId: $backgroundId, files: $files) {
  id
  }
}
`;

const UNTAG_BACKGROUND_FILE_MUTATION = `
mutation untagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
  backgroundFileUntag(backgroundId: $backgroundId, files: $files) {
    id
  }
}
`;
const mUpdateBackgroundDesc = `
mutation updateBackground($id: ID, $description: String) {
  backgroundUpdate(id: $id, description: $description) {
    id
    description
  }
}`;

const qlistBackgroundFiles = `
query getBackgroundByID($id: ID) {
  background(id: $id) {
  id
  files {
    items {
      createdAt
      id
      s3ObjectKey
      details
      name
      order
    }
  }
  labels {
    items {
    id
    name
    }
  }
  }
}`;

const mCreateActivity = `
mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
    activity: $activity
    briefId: $briefId
    clientMatterId: $clientMatterId
    companyId: $companyId
    previous: $previous
    field: $field
    current: $current
    appModule: $appModule
    rowId: $rowId
  ) {
    id
  }
}`;

const mBulkCreateMatterFile = `
mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
  matterFileBulkCreate(files: $files) {
    id
    name
    order
  }
}
`;
const mCreateBackground = `
mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
  id
  createdAt
  date
  description
  order
  briefs {
  id
  name
  }
  files {
    items {
    id
    name
    details
    isGmailPDF
    isGmailAttachment
    s3ObjectKey
    order
    createdAt
    downloadURL
    }
  }
}
}
`;

export default function ProjectLocation({
  userInfo,
  projectLocationBrief,
  setProjectLocationBrief,
  invalidFiles,
  rejectFiles,
  triggerToast,
  projectLocationPhoto,
  handleChangeGetStartedProgress,
  modalChoicesEnum,
  setModalState,
  isInitDone,
  clientMatterId,
  setDefaultList,
  setProjectLocationPhoto
}) {
  const [profilePic, setProfilePic] = useState(null);
  const [projectPhotoURL, setProjectPhotoURL] = useState("");
  const [uploadedImg, setUploadedImg] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [projectAddress, setProjectAddress] = useState("");
  const [showExample, setShowExample] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [userData, setUserData] = useState(null);
  const [background, setBackground] = useState([]);
  const selectedFiles = useRef([]);
  // const [selectedFiles, _setSelectedFiles] = useState([]);
  // const [selectedRowId, setSelectedRowId] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [tempProjectPhotoURL, setTempProjectPhotoURL] = useState("");
  const [tempAddress, setTempAddress] = useState("");

  var width = window.innerWidth;
  // const myCurrentRef = useRef(selectedFiles);
  // const setSelectedFiles = (data) => {
  //   myCurrentRef.current = data;
  //   _setSelectedFiles(data);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    var params = {
      query: USER_DETAILS_QUERY,
      variables: {
        id: userInfo.userId,
      },
    };
    await API.graphql(params).then((userInfo) => {
      const userDataObj = userInfo.data;
      setUserData(userDataObj);
      setProfilePic(userDataObj?.user?.profilePicture);
    });
  }, []);

  useEffect(() => {
    if (showEdit) {
      setTempAddress(projectAddress);
      setTempProjectPhotoURL(projectPhotoURL);
    } else {
      setTempAddress("");
      setTempProjectPhotoURL("");
    }
  }, [showEdit]);

  const qGetBackgroundFilesbyBriefID = `query getBackgroundFilesByBriefID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
    brief(id: $id) {
      id
      name
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          description
          date
          createdAt
          order
          files {
            items {
              id
              name
              details
              isGmailPDF
              isGmailAttachment
              s3ObjectKey
              order
              createdAt
              downloadURL
            }
          }
        }
        nextToken
      }
    }
  }`;

  const handleInitials = (firstName, lastName) => {
    const fullName = `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`;
    let assigneeInitials = "";

    if (fullName === "john dela cruz") {
      assigneeInitials = "JDC";
    } else {
      assigneeInitials += firstName ? firstName.charAt(0) : "";
      assigneeInitials += lastName ? lastName.charAt(0) : "";
    }

    return assigneeInitials.toUpperCase();
  };

  function handleViewExample() {
    setShowExample((prevState) => !prevState);
  }

  // function handleUseExample(showSampleImage) {
  //   setShowExample(false);
  //   setShowEdit(false);
  //   setProjectAddress("45 Francis Street Castle Hill NSW 2154 AU");
  //   // handleChangeGetStartedProgress('propertyDetails')
  //   if (showSampleImage) {
  //     setProjectPhotoURL(dummyBg);
  //   }
  // }

  const convertToFile = async (staticFile) => {
    // Fetch the static file using the URL
    const response = await fetch(staticFile);
    // Convert the response to a blob
    const blob = await response.blob();
    // Create a File object from the blob
    const file = new File([blob], "filename.png", { type: "image/png" });
    return file;
  };

  async function handleUseExamplev2() {
    const imgFile = await convertToFile(dummyBg);
    handleUploadDummyImage([imgFile]);
    handleSave("45 Francis Street Castle Hill NSW 2154 AU", dummyBg);
  }

  const handleUploadDummyImage = (files) => {
    if (!files || !files.length) return;

    var file = files[0];
    console.log("RETRIEVED FILE", file);
    if (!file.type.includes("image")) {
      window.alert("Please input an image file");
      return;
    }

    let objUrl = URL.createObjectURL(file);

    const tempArr = [];

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[0];

    const result = rejectFiles.find((item) =>
      item.includes(re.exec(file.name)[0])
    );
    const fileSize = file.size;

    if (result || fileSize > 2147483648) {
      invalidFiles.push({
        data: file,
        url: objUrl,
      });
    } else {
      tempArr.push({
        data: file,
        url: objUrl,
      });
    }

    selectedFiles.current = [...selectedFiles.current, ...tempArr];
  };

  const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
    clientMatter(id: $id) {
      briefs {
        items {
          id
          name
          userTypeAccess
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          backgrounds(limit: 50, sortOrder: ORDER_ASC) {
            items {
              id
              description
              date
              dynamicData
              files {
                items {
                  id
                  name
                }
              }
            }
          }
          hasCommentAccess
          columnIds
          assistantId
        }
      }
    }
  }`;

  async function handleSave(newAddress, newPhoto) {
    try {
      console.group("handleSave()");
      console.log("newAddress", newAddress);
      console.log("currAddress", projectAddress);
      console.log("newPhoto", newPhoto);
      console.log("currPhoto", projectPhotoURL);
      console.log("selectedFiles", selectedFiles.current);

      // if (!projectPhotoURL) {
      //   window.alert("No Project Photo Found");
      //   return;
      // }
      let promisesArr = [];
      if (!newAddress || newAddress === "") {
        throw new Error("Please input the Project Address");
        // window.alert("Please input the Project Address");
        // return;
      }

      // If there are no changes in newAddress
      if (newAddress === projectAddress && newPhoto === projectPhotoURL) {
        setIsSaving(false);
        setShowExample(false);
        setShowEdit(false);
        return;
      }

      // Get the first background ID or null if not found
      const bgId =
        projectLocationBrief.backgrounds?.items[0]?.id ||
        (await (async () => {
          let createdBG = await handleProjectLocationUpload({
            description: tempAddress,
          });
          handleChangeGetStartedProgress("propertyDetails");
          return createdBG;
        })());

      console.log(bgId, `Background ID: ${bgId}`);

      // If the address has changed, update the project location address
      if (newAddress !== projectAddress) {
        console.log(`Updated Project Location Address to ${newAddress}`);
        const params = {
          query: mUpdateBackgroundDesc,
          variables: {
            id: bgId,
            description: newAddress,
          },
        };
        promisesArr.push(API.graphql(params));
        setProjectAddress(newAddress);

        const params1 = {
          query: BRIEFS_QUERY,
          variables: {
            id: clientMatterId,
          },
        };
  
        await API.graphql(params1).then(async (brief) => {
          const briefsList = brief?.data?.clientMatter?.briefs?.items;
          //setDefaultList(briefsList);

          const projectLocationIndex = briefsList.findIndex(
            (item) => item.name === "Project Location"
          );

          if (projectLocationIndex !== -1) {
            setProjectLocationBrief(briefsList[projectLocationIndex]);
            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[projectLocationIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;

            if (brief.brief.backgrounds?.items?.length > 0) {
              setProjectLocationPhoto(brief.brief.backgrounds?.items[0]);
              console.log("photo", brief.brief.backgrounds?.items[0])
            }
          }
          console.log("updated")
        });
      }

      // If project photo is changed
      if (newPhoto !== projectPhotoURL) {
        console.log("Updated Project Location Photo");
        const uploadNewPhoto = async () => {
          setProjectPhotoURL(newPhoto);
          setShowExample(false);
          await handleUpload(clientMatterId, bgId);
        };

        const response = await API.graphql({
          query: qlistBackgroundFiles,
          variables: {
            id: bgId,
          },
        });

        let files = response.data.background.files.items;

        if (files.length > 0) {
          if (newPhoto !== null && newPhoto !== "") {
            promisesArr.push(uploadNewPhoto());
          } else {
            promisesArr.push(handleRemove(files, bgId));
            setProjectPhotoURL("");
          }
        } else {
          if (newPhoto !== null && newPhoto !== "") {
            promisesArr.push(uploadNewPhoto());
          }
        }
        console.log("Updated Photo");

        
      }

      await Promise.all(promisesArr);

      triggerToast(`Project Location has been updated!`, false);
      setIsSaving(false);
      setShowExample(false);
      setShowEdit(false);
      console.groupEnd("handleSave()");

      if (newPhoto !== projectPhotoURL) {
        window.location.reload();
      }
  
    } catch (e) {
      console.log("ERROR", e);
      triggerToast(`Project Location has been updated!`, false);
      setIsSaving(false);
    }
  }

  useEffect(() => {
    document.body.style.cursor = isSaving ? "wait" : "default";
  }, [isSaving]);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEdit(true);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    handleNewPropertyPhoto(files);
  };

  function handleClick(event) {
    hiddenPhotoInput.current.click();
  }

  const handleNewPropertyPhoto = (files) => {
    if (!files || !files.length) return;
    console.log("FILES!!!!", files);

    var file = files[0];
    console.log("RETRIEVED FILE", file);
    if (!file.type.includes("image")) {
      window.alert("Please input an image file");
      return;
    }

    let objUrl = URL.createObjectURL(file);
    console.log("THIS IS THE URL!", file);
    setTempProjectPhotoURL(objUrl);

    const tempArr = [];

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[0];

    const result = rejectFiles.find((item) =>
      item.includes(re.exec(file.name)[0])
    );
    const fileSize = file.size;

    if (result || fileSize > 2147483648) {
      invalidFiles.push({
        data: file,
        url: objUrl,
      });
    } else {
      tempArr.push({
        data: file,
        url: objUrl,
      });
    }

    selectedFiles.current = [...selectedFiles.current, ...tempArr];
    setShowEdit(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    handleNewPropertyPhoto(files);
  };

  const handleUpload = async (bucketName, bgId) => {
    console.group("handleUpload Project Location");
    console.log("UPLOADING TO", bucketName);
    try {
      if (!selectedFiles.current || !Array.isArray(selectedFiles.current)) {
        console.error("Invalid or missing selectedFiles array");
        return;
      }

      var tempArr = [];
      var temp = [];
      var idxx = 0;

      selectedFiles.current.forEach((uf) => {
        if (uf && uf.data) {
          tempArr = [...tempArr, uf];
        } else {
          console.error(
            "Invalid or missing data for file in selectedFiles:",
            uf
          );
        }
      });

      selectedFiles.current = tempArr;

      console.log("Uploading selectedFiles", selectedFiles.current);
      for (const uf of tempArr) {
        if (!uf || !uf.data) {
          console.error("Invalid or missing data for file:", uf);
          continue; // Skip to the next iteration
        }

        if (uf.data.name.split(".").pop() == "docx") {
          var name = uf.data.name,
            size = uf.data.size,
            type =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            key = `${bucketName}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        } else {
          var name = uf.data.name,
            size = uf.data.size,
            type = uf.data.type,
            key = `${bucketName}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        }
        idxx = idxx + 1;

        try {
          await Storage.put(key, uf.data, {
            contentType: type,
            progressCallback(progress) {
              const progressInPercentage = Math.round(
                (progress.loaded / progress.total) * 100
              );

              if (temp.length > selectedFiles.current.length) {
                for (var i = 0; i < selectedFiles.current.length; i++) {
                  if (temp[i].name === uf.data.name) {
                    temp[i].prog = progressInPercentage;
                  }
                }
              } else {
                temp = [
                  ...temp,
                  { prog: progressInPercentage, name: uf.data.name },
                ];
              }
              console.log(temp);
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          })
            .then(async (fd) => {
              var fileData = {
                s3ObjectKey: fd.key,
                size: parseInt(size),
                type: type,
                name: name.split(".").slice(0, -1).join("."),
                oderSelected: orderSelected,
                order: orderSelected,
              };

              await handleUploadLink(
                {
                  files: [fileData],
                },
                projectLocationBrief,
                bgId
              );
              // setUploadedFiles((prevState) => ({
              //   files: [...prevState.files, fileData],
              // }));

              selectedFiles.current = [];
            })
            .catch((err) => {
              console.error("Unexpected error while uploading", err);
            });
        } catch (e) {
          const response = {
            error: e.message,
            errorStack: e.stack,
            statusCode: 500,
          };
          console.error("Unexpected error while uploading", response);
        }
      }
    } catch (error) {
      console.error("Unexpected error in handleUpload", error);
    }
    console.groupEnd("handleUpload Project Location");
  };

  const handleRemove = async (files, bgId) => {
    console.log("Files to remove", files);
    // await Promise.all(files.map(async (file) => {
    //   Storage.remove(file.s3ObjectKey);
    // }))

    await API.graphql({
      query: UNTAG_BACKGROUND_FILE_MUTATION,
      variables: {
        backgroundId: bgId,
        files: files.map((file) => ({ id: file.id })),
      },
    });
    console.log("Done untagging files");
  };

  // const uploadAndCheck = async () => {
  //   if (
  //     uploadedFiles.files.length === selectedFiles.current.length &&
  //     selectedFiles.current.length !== 0
  //   ) {
  //     const result = await handleUploadLink(uploadedFiles, projectLocationBrief);
  //   }
  // };

  // useEffect(() => {
  //   console.log("uploadedFiles", uploadedFiles)
  //   uploadAndCheck();
  // }, [uploadedFiles]);

  const handleProjectLocationUpload = async ({ description }) => {
    let uploadToBrief = await API.graphql({
      query: mCreateBackground,
      variables: {
        briefs: [
          {
            id: projectLocationBrief.id,
            name: projectLocationBrief.name,
          },
        ],
        description: "",
        comments: "",
        date: new Date(),
        order: 1,
      },
    });
    // setSelectedRowId(uploadToBrief.data.backgroundCreate?.id);
    setProjectAddress(description);

    setProjectLocationBrief((brief) => {
      brief.backgrounds.items[0] = uploadToBrief.data.backgroundCreate;
      return brief;
    });
    return uploadToBrief.data.backgroundCreate?.id;
  };

  const handleUploadLink = async (uf, brief, bgId) => {
    await Promise.all(
      uf?.files.map(async (file) => {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: clientMatterId,
            briefId: brief?.id,
            activity: `added a background file named ${file.name}-(${file.size} byte)`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: bgId,
          },
        };
        console.log("add activity params", params);
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });
      })
    );
    var uploadedFiles = uf.files.map((f) => ({
      ...f,
      matterId: clientMatterId,
    }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
    console.log("SF", sortedFiles);
    console.log("AO", addOrder);

    //insert in matter file list
    await bulkCreateMatterFile(addOrder, bgId);

    //set background content

    const backgroundFilesOptReq = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: bgId,
      },
    }).then((result) => {
      var newFilesResult = result.data.background.files.items.map(
        ({ createdAt, id, name, description, order }) => ({
          createdAt: createdAt,
          id: id,
          name: name,
          description: description,
          order: order,
        })
      );

      // Sort the newFilesResult array by createdAt in descending order
      newFilesResult?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      // console.log(newFilesResult);

      var updateArrFiles = background.map((obj) => {
        if (obj.id === bgId) {
          return { ...obj, files: { items: newFilesResult } };
        }
        return obj;
      });

      // Update order number based on uploaded file
      updateArrFiles.forEach((item) => {
        const orderFilesPerBackground = item?.files?.items?.map(
          ({ id }, index) => ({
            id: id,
            order: index + 1,
          })
        );

        // For FE purposes: Update existing background array with the updated order data
        const orderFilesPerBackgroundFE = item?.files?.items?.map(
          (
            {
              id,
              details,
              gmailMessage,
              gmailMessageId,
              isGmailAttachment,
              isGmailPDF,
              name,
              s3ObjectKey,
            },
            index
          ) => ({
            id: id,
            name: name,
            details: details,
            gmailMessage: gmailMessage,
            gmailMessageId: gmailMessageId,
            isGmailAttachment: isGmailAttachment,
            isGmailPDF: isGmailPDF,
            s3ObjectKey: s3ObjectKey,
            order: index + 1,
          })
        );

        if (
          orderFilesPerBackground !== undefined &&
          orderFilesPerBackground !== null
        ) {
          item.files.items = orderFilesPerBackgroundFE;
          // updateBackgroundFilesOrder(orderFilesPerBackground);
          // console.log("Items", orderFilesPerBackground);
        }
      });

      // console.log("Set New Background", updateArrFiles);
      setBackground(updateArrFiles);
    });
  };

  async function bulkCreateMatterFile(param, bgId) {
    // console.log("bulkCreateMatterFile");
    let idTag = [];

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    // console.log("result", request);

    if (request.data.matterFileBulkCreate !== null) {
      request.data.matterFileBulkCreate.map((i) => {
        return (idTag = [...idTag, { id: i.id }]);
      });
    }

    console.log("iDTag", idTag);

    // let arrFiles = [];
    // let arrFileResult = [];
    // const seen = new Set();

    // console.log("MID/BID", background_id);

    // let PARAMS = {
    //   query: qlistBackgroundFiles,
    //   variables: {
    //     id: selectedRowId,
    //   },
    // };
    // console.log("RUNNING BG LIST QUERY", PARAMS);
    // const backgroundFilesOpt = await API.graphql(PARAMS);

    // console.log("CHECKDATA", backgroundFilesOpt);

    // if (backgroundFilesOpt.data.background.files !== null) {
    //   arrFileResult = backgroundFilesOpt.data.background.files.items.map(
    //     ({ id }) => ({
    //       id: id,
    //     })
    //   );

    //   idTag.push(...arrFileResult);
    //   console.log("updatedidtag", idTag);

    //   const filteredArr = idTag.filter((el) => {
    //     const duplicate = seen.has(el.id);
    //     seen.add(el.id);
    //     return !duplicate;
    //   });

    //   console.log("rowid", selectedRowId);

    //   API.graphql({
    //     query: BACKGROUNDFILE_TAG_MUTATION,
    //     variables: {
    //       backgroundId: selectedRowId,
    //       files: filteredArr,
    //     },
    //   });
    // } else {
    const response = await API.graphql({
      query: BACKGROUNDFILE_TAG_MUTATION,
      variables: {
        backgroundId: bgId,
        files: idTag,
      },
    });
    console.log("BACKGROUNDFILE_TAG_MUTATION", response);
    // }
    //return request;
  }

  useEffect(() => {
    if (!isInitDone) return;

    if (
      projectLocationBrief &&
      projectLocationBrief !== undefined &&
      projectLocationBrief !== null &&
      projectLocationBrief.backgrounds.items.length > 0
    ) {
      setProjectAddress(projectLocationBrief.backgrounds.items[0].description);
      handleChangeGetStartedProgress("propertyDetails");
      setShowEdit(false);
    } else if (!showEdit) {
      if (width > 844) setShowEdit(true);
    }
  }, [projectLocationBrief, isInitDone]);

  useEffect(() => {
    if (
      projectLocationPhoto &&
      projectLocationPhoto !== undefined &&
      projectLocationPhoto !== null &&
      projectLocationPhoto.files.items.length > 0
    ) {
      setProjectPhotoURL(projectLocationPhoto.files.items[0].downloadURL);
      setShowEdit(false);
    }
  }, [projectLocationPhoto]);

  const hiddenPhotoInput = useRef(null);

  const handlePhotoUpload = (e) => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      const areAllFilesImages = Array.from(selectedFiles).every((file) =>
        /\.(jpg|jpeg|png|gif)$/i.test(file.name)
      );

      if (areAllFilesImages) {
        setTempProjectPhotoURL(URL.createObjectURL(selectedFiles[0]));
        handleNewPropertyPhoto(selectedFiles);
        // console.log(selectedFiles);
      } else {
        alert("Please select only image files (JPEG, PNG, GIF, etc.).");
        e.target.value = null;
      }
    } else {
      alert("Please select one file.");
    }
  };

  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const handleModalClose = () => {
    setshowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    handleModalClose();
    selectedFiles.current = [];
    setUploadedImg(null);
    setTempProjectPhotoURL("");
  };

  return (
    <>
      <div
        // onDrop={showEdit ? handleDrop : null}
        // onDragOver={showEdit ? handleDragOver : null}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="w-full"
      >
        {showExample ? (
          <div className="rounded-t-md w-full h-60">
            <img
              src={dummyBg}
              className="h-full rounded-t-md w-full object-cover"
              alt={`${dummyBg}`}
            />
          </div>
        ) : (showEdit ? tempProjectPhotoURL === "" : projectPhotoURL === "") ? (
          <>
              <div className="text-center flex items-center content-center m-auto bg-gray-200 rounded-t-md h-60">
                <div className="text-center flex flex-col font-sans items-center w-full justify-center">
                  <img alt="uploadImg" src={UploadImage} className="mb-3" />
                  <p className="text-md font-bold font-open-sans">
                    <span 
                      className={
                        showEdit 
                        ? "text-hbBlue font-semibold cursor-pointer hover:underline font-open-sans" 
                        : "text-hbBlue font-semibold font-open-sans"
                      }
                      onClick={showEdit ? handleClick : console.log("clicked")}
                    >
                      Ready to add your dream home?
                    </span>
                  </p>
                  <p className="text-xs font-normal" style={{ color: "gray" }}>
                    Drag photos or videos anywhere
                  </p>
                </div>
              </div>
            <input
              type="file"
              ref={hiddenPhotoInput}
              onChange={(e) => {
                handlePhotoUpload(e);
              }}
              value=""
              className="hidden"
              multiple={false}
            />
          </>
        ) : (
          <div className="rounded-t-md w-full h-60 relative">
            <img
              src={
                showEdit && width > 844 ? tempProjectPhotoURL : projectPhotoURL
              }
              className="h-full rounded-t-md w-full object-cover"
              alt={`${
                showEdit && width > 844 ? tempProjectPhotoURL : projectPhotoURL
              }`}
            />
            {/* kjmfedit */}
            {showEdit && (
              <div
                className="absolute top-4 right-4 bg-hbLightGray w-4 h-4 rounded-full cursor-pointer"
                // onClick={(e) => {
                //   selectedFiles.current = [];
                //   setUploadedImg(null);
                //   setTempProjectPhotoURL("");
                // }}
                onClick={(e) => setshowDeleteModal(true)}
              >
                <IoIosCloseCircle
                  className="absolute top-2 right-2 text-hbBlue hover:cursor-pointer hover:text-red-500 text-3xl"
                  style={{
                    transform: "translate(50%, -50%)",
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* <div
        className="flex bg-gray-200 text-hbBlue w-14 h-14 rounded-full text-xl font-medium justify-center items-center -mb-4"
        style={{ flexShrink: "0", transform: "translate( 10px, -50%)" }}
      > */}
      {/* KJMFEDIT */}
      {/* {!showExample ? (
          handleInitials(userInfo.firstName, userInfo.lastName)
        ) : (
          (userData && userData.user.profilePicture === null) ? (
            <>{handleInitials(userInfo.firstName, userInfo.lastName)}</>
          ) : (
            <img
              src={profilePic}
              alt={`${userInfo.firstName} ${userInfo.lastName}`}
              className="w-14 h-14 rounded-full object-cover"
            />
          )
        )} */}

      <div
        className="relative flex -mt-5 ml-5 bg-gray-500 text-white w-12 h-12 rounded-full text-md font-medium justify-center items-center"
        data-tip={`${userInfo?.firstName} ${userInfo?.lastName}`}
      >
        {profilePic ? (
          <img
            alt={`${userInfo?.firstName} ${userInfo?.lastName}`}
            className="rounded-full w-12 h-12"
            src={profilePic}
          />
        ) : (
          <>{handleInitials(userInfo.firstName, userInfo.lastName)}</>
        )}
      </div>
      {/* </div> */}
      <div className="px-5 pt-0 pb-3">
        {showExample ? (
          <>
            <div>
              <p className=" flex items-center justify-between ">
                <span className="text-base w-48 font-semibold font-sans text-black">
                  45 Francis Street Castle Hill NSW 2154 AU
                </span>
              </p>
            </div>
            <div className="flex my-2 justify-between gap-2">
              <>
                <Button
                  variant={"hbGray"}
                  className="py-2 font-sans mt-3 font-bold cursor-pointer border-none bg-hbLightGray  text-hbBlue"
                  block={"full"}
                  onClick={handleViewExample}
                >
                  Back
                </Button>
                <Button
                  variant={"hbBlue"}
                  className="py-2 font-sans mt-3 cursor-pointer"
                  block={"full"}
                  onClick={() => {
                    handleUseExamplev2();
                    // handleUseExample(true);
                    // localStorage.setItem("showSampleImage", true);
                  }}
                >
                  Use Example
                </Button>
              </>
            </div>
          </>
        ) : showEdit ? (
          <div className="flex flex-col">
            <label
              htmlFor="address"
              className="text-sm mb-3 text-gray-400 font-normal "
            >
              Property Address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              className="rounded-md p-2 border border-gray-300 outline-0"
              placeholder="Property Address"
              onChange={(e) => setTempAddress(e.target.value)}
              value={tempAddress}
            />
            <div className="flex gap-4">
              <Button
                className="py-2 mt-3 cursor-pointer bg-hbLightGray border border-hbLightGray text-hbBlue shadow-none hover:text-white"
                block={"full"}
                onClick={() => handleViewExample()}
              >
                View Example
              </Button>
              <Button
                variant={isSaving ? "hbBlue-l" : "hbBlue"}
                className="py-2 mt-3 cursor-pointer"
                block={"full"}
                disabled={isSaving}
                onClick={() => {
                  setIsSaving(true);
                  handleSave(tempAddress, tempProjectPhotoURL);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {projectAddress === "" ? (
              <div className="flex gap-4">
                <Button
                  className="py-2 mt-3 cursor-pointer bg-hbLightGray border border-hbLightGray text-hbBlue shadow-none hover:text-white"
                  block={"full"}
                  onClick={() => handleViewExample()}
                >
                  View Example
                </Button>
                <Button
                  variant={"hbBlue"}
                  className="py-2 mt-3 cursor-pointer"
                  block={"full"}
                  onClick={() => {
                    setShowEdit(true);
                  }}
                >
                  Update
                </Button>
              </div>
            ) : (
              <div className=" flex items-center justify-between pr-3">
                <span className="text-base w-48 font-semibold font-sans text-black">
                  {projectAddress}
                </span>
                <div
                  className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
                  onClick={() => setShowEdit(true)}
                >
                  <FiEdit className="h-4 w-4 cursor-pointer" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {width < 844 && showEdit && (
        <PropertyDetailsModal
          setShowEdit={setShowEdit}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          projectPhotoURL={projectPhotoURL}
          handleSave={handleSave}
          setProjectAddress={setProjectAddress}
          tempAddress={tempAddress}
          setTempAddress={setTempAddress}
          tempProjectPhotoURL={tempProjectPhotoURL}
          setTempProjectPhotoURL={setTempProjectPhotoURL}
          handleFileUpload={handleFileUpload}
        />
      )}

      {showDeleteModal && (
        <DeleteMatterModal
          handleSave={handleDeleteModal}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
}
