import React from "react";
import "../../assets/styles/Inbox.css";
import Button from "../../shared/Button";

var moment = require("moment");

function UserPreview({
  userShow,
  setUserShow,
  userPreview,
  setUserPreview,
  assigneeInitials,
  userInitial,
}) {
  function handleClose() {
    setUserShow(false);
  }

  return (
    <>
      <div>
        <div
          className="justify-center items-center flex-none overflow-x-hidden overflow-y-hidden max-h-screen fixed inset-0 outline-none focus:outline-none overscroll-none"
          style={{ zIndex: 70 }}
        >
          <div
            className="w-full mx-auto max-w-md h-screen max-h-screen pt-64 "
            onClick={(e) => handleClose()}
          >
            <div
              className="items-center max-h-full min-h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4 overscroll-none"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Content */}
              <div className="w-full p-2 flex justify-between items-center">
                <h2 className="text-lg font-bold">Users</h2>
                <Button className="px-4 py-2" onClick={() => handleClose()}>
                  Close
                </Button>
              </div>
              <div className="overflow-scroll w-full">
                {userPreview.map((user, index) => {
                  const assigneeInitials = userInitial(user);
                  return (
                    <div className="w-full" key={`profile-pic-${index}`}>
                      <div className={"flex py-2 px-4 items-center "}>
                        <div
                          className="mr-2 bg-gray-500 flex flex-none text-white text-md font-medium w-8 h-8 rounded-full justify-center items-center cursor-default "
                          data-tip
                          data-for={`${user.id}-tooltip`}
                        >
                          {user.profilePicture === null ? (
                            <>{assigneeInitials.toUpperCase()}</>
                          ) : (
                            <img
                              className="rounded-full w-8 h-8"
                              src={user.profilePicture}
                            />
                          )}
                        </div>
                        <span className="font-base">{`${user.firstName} ${user.lastName}`}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="opacity-25 fixed inset-0 bg-black"
          style={{ zIndex: 60 }}
        ></div>
      </div>
    </>
  );
}

export default UserPreview;
