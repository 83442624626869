import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import momentTZ from "moment-timezone";
import { dateFormatter } from "../../shared/userPreferredDateTime";
import { AiOutlineDownload } from "react-icons/ai";
import { PiChartLineUpBold } from "react-icons/pi";

/**
 * Component for rendering variations content
 * @param {Object} props - Props object
 * @param {Object} props.background - Background object
 * @param {Object} props.style - Style object
 * @param {boolean} props.readMoreState - Read more state
 * @param {function} props.isReadMoreExpanded - Function to check if read more is expanded
 * @param {function} props.setSaving - Function to set saving state
 * @param {function} props.getRowLabels - Function to get row labels
 * @param {function} props.handleDescContent - Function to handle description content
 * @param {function} props.handleReadMoreState - Function to handle read more state
 * @param {function} props.handleChangeDesc - Function to handle change in description
 * @param {function} props.handleSaveDesc - Function to handle saving description
 * @param {function} props.handleSnippetEmail - Function to handle snippet email
 * @param {Array} props.virtualRows - Array of virtual rows
 * @param {Array} props.rows - Array of rows
 */
const ProgressOfWorkContentComponent = ({
  background,
  style,
  readMoreState,
  isReadMoreExpanded,
  setSaving,
  getRowLabels,
  handleDescContent,
  handleReadMoreState,
  handleChangeDesc,
  handleSaveDesc,
  handleSnippetEmail,
  virtualRows,
  rows,
}) => {
  const [openMoreLabels, setOpenMoreLabels] = useState(false);
  const [openMoreLabelsIndex, setOpenMoreLabelsIndex] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const item = row.original;
        const index = row.index;

        const visibleFiles = item.files.items.slice(
          0,
          isExpanded ? undefined : 1
        );
        const hiddenFilesCount = item.files.items.length - visibleFiles.length;

        let fileList = [];
        item?.files?.items?.forEach((row) => {
          if (row.gmailMessage?.gmailLabelIds?.includes("SENT")) {
            fileList.push("Sent");
          } else if (row.gmailMessage?.gmailLabelIds?.includes("INBOX")) {
            fileList.push("Inbox");
          } else if (row.gmailMessage === null || row.gmailMessageId === "") {
            fileList.push("Uploaded");
          }
        });
        fileList = fileList.filter(
          (item, index) => fileList.indexOf(item) === index
        );
        //console.log("FILE LIST: ", fileList);
        let gmailStatus = "";
        for (let i = 0; i < fileList.length; i++) {
          gmailStatus = gmailStatus + fileList[i];
          if (i !== fileList.length - 1) {
            gmailStatus = gmailStatus + " / ";
          }
        }

        return (
          <div
            key={item.id}
            className="flex flex-row w-full px-5"
            style={{
              ...style,
              width: "100%",
              borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
              borderBottomStyle: "solid",
              paddingTop: index === 0 ? 0 : 20,
              paddingBottom: 20,
              maxHeight: "350px",
            }}
          >
            <p className="text-xs">{index + 1}</p>
            <div className="ml-2 overflow-y-auto">
              {/* Date */}
              <p className="text-xs pb-1">
                {item.date !== null && item.date !== ""
                  ? dateFormatter(item.date)
                  : "No date"}
              </p>

              {/* Description */}
              <p
                id={item.id + ".descM"}
                className={`overflow-y-auto text-xs pb-1 ${
                  isReadMoreExpanded(item.id) ? "" : "line-clamp-6"
                }`}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
                style={{
                  wordBreak: "break-word",
                  outlineColor: "rgb(204, 204, 204, 0.5)",
                  outlineWidth: "thin",
                }}
                contentEditable={true}
                suppressContentEditableWarning
                onClick={(event) => {
                  handleDescContent(event, item.description, item.id, index);
                  setSaving(true);
                }}
                onInput={(event) => handleChangeDesc(event, index)}
                onBlur={(e) => {
                  handleSaveDesc(
                    e,
                    item.description,
                    item.date,
                    item.id,
                    index
                  );
                  setSaving(false);
                }}
              ></p>

              {/* Files List */}
              <div className="text-left">
                {visibleFiles.map((file, index) => (
                  <div key={file.id} className="items-left">
                    <button
                      className="font-extralight text-sm focus:text-cyan-400 focus:border-cyan-400 rounded-lg pt-1 pb-1 flex-1"
                      onClick={() => handleSnippetEmail(file)}
                    >
                      <span className="font-bold text-xs">
                        {file.name.length > 30
                          ? file.name.slice(0, 30) + "..."
                          : file.name}
                        &nbsp;
                      </span>
                      {/* <AiOutlineDownload className="text-sm cursor-pointer inline-block" /> */}
                    </button>
                    {index === 0 && hiddenFilesCount > 0 && (
                      <button
                        className="text-sm focus:text-cyan-400 focus:border-cyan-400 rounded-lg px-1 ml-2"
                        onClick={handleExpandToggle}
                      >
                        {isExpanded ? "-" : "+"} {hiddenFilesCount}
                      </button>
                    )}
                  </div>
                ))}
              </div>

              {/* Labels List */}
              <div>
                <div className="flex items-center mt-2">
                  <PiChartLineUpBold className="mr-2" />
                  <p className="text-xs break-words mr-2 rounded-full p-1 border-2 bg-gray-50 ">
                    {gmailStatus !== ""
                      ? gmailStatus
                      : item?.files?.items?.length === 0
                      ? "N/A"
                      : "Uploaded"}
                  </p>

                  <button
                    className="w-full"
                    onClick={(e) => {
                      setOpenMoreLabels(!openMoreLabels);
                      setOpenMoreLabelsIndex(index);
                    }}
                  >
                    <div
                      id={"label-cell-selected-no-" + index}
                      className="text-xs flex flex-wrap"
                    >
                      {item?.briefs &&
                        item?.briefs?.length !== 0 &&
                        item?.briefs?.map((elem, innerIndex) =>
                          innerIndex < 3 ? (
                            <span
                              key={innerIndex}
                              className="mx-1 text-blue-600 bg-blue-50 rounded-full text-xs mt-1"
                              style={{
                                gap: "8px",
                                padding: "4px 8px",
                              }}
                            >
                              {getRowLabels(item?.briefs)[innerIndex].label}
                            </span>
                          ) : null
                        )}
                      {item?.briefs?.length > 3 && (
                        <span
                          className="mx-1 text-blue-600 bg-blue-50 rounded-full text-xs mt-1"
                          style={{
                            gap: "8px",
                            padding: "4px 8px",
                          }}
                        >
                          +{item?.briefs?.length - 3}
                        </span>
                      )}
                    </div>
                  </button>
                </div>

                {openMoreLabels && openMoreLabelsIndex === index && (
                  // Move the modal outside of the button, but still inside the parent div
                  <div
                    key={index}
                    className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50"
                  >
                    <div
                      className="w-52 h-auto p-3 flex flex-col gap-1 rounded-md overflow-y-auto bg-white z-50"
                      style={{
                        boxShadow:
                          "0px 3px 38px 0px rgba(0,0,0,0.15), 0px 6px 12px 0px rgba(0,0,0,0.18)",
                        maxHeight: "12rem",
                      }}
                    >
                      <p className="px-1 py-1 text-xs text-gray-400 font-semibold border-b border-gray-500">
                        Labels
                      </p>
                      {item?.briefs &&
                        item?.briefs?.length !== 0 &&
                        item?.briefs?.map((elem, innerIndex) => (
                          <span
                            key={innerIndex}
                            className="text-s w-full py-1 border-b border-dashed border-gray-300 truncate"
                          >
                            {getRowLabels(item?.briefs)[innerIndex].label}
                          </span>
                        ))}
                      {/* Add a close button or any other UI elements as needed */}
                      <button
                        onClick={() => {
                          setOpenMoreLabels(false);
                          setOpenMoreLabelsIndex(null);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressOfWorkContentComponent;
