import axios from "axios";
const curUrl = window.location.href;

const refreshTokenSyncEndpoints = [
  {
    url: curUrl.includes("localhost")
      ? "localhost"
      : "develop.d3bhf42tem9b8e.amplifyapp.com", // MMA's Localhost && Dev website
    endpoint:
      "https://kjh3fh72ydbaevxvzzotmh5loi0kyswa.lambda-url.ap-southeast-1.on.aws/", //`${process.env.REACT_APP_GMAIL_REFRESH_TOKEN_SYNC_MMA_DEV_ENDPOINT}`, // Functional URL
  },
  {
    url: "mma-uat.d3jteiyknoq4lq.amplifyapp.com", // MMA's UAT website
    endpoint:
      "https://42avwjqytqn6azfvlmqm3bcapu0jpxnx.lambda-url.ap-southeast-1.on.aws/", // `${process.env.REACT_APP_GMAIL_REFRESH_TOKEN_SYNC_MMA_UAT_ENDPOINT}`, // Functional URL
  },
  // {
  //   url: "portal.makemyaffidavit.com", // MMA's production website
  //   endpoint: "" // `${process.env.REACT_APP_GMAIL_REFRESH_TOKEN_SYNC_MMA_PROD_ENDPOINT}`, // Functional URL
  // },
  // {
  //   url: "hba-uat.d3pz5ozavnp2zv.amplifyapp.com", // HBA's UAT website
  //   endpoint: "" //`${process.env.REACT_APP_GMAIL_REFRESH_TOKEN_SYNC_HBA_UAT_ENDPOINT}`, // Functional URL
  // },
  // {
  //   url: "portal.homebuilding.app", // HBA's production website
  //   endpoint: "" //`${process.env.REACT_APP_GMAIL_REFRESH_TOKEN_SYNC_HBA_PROD_ENDPOINT}`, // Functional URL
  // },
];

export const refreshTokenSync = async (email, latestRefreshToken) => {
  const data = {
    email: email,
    latestRefreshToken: latestRefreshToken,
  };
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // collate functional urls & avoid multiple calls on same functional url
  var triggeredEndpoint = [];
  for (const domain of refreshTokenSyncEndpoints) {
    if (!curUrl.includes(domain.url)) {
      if (!triggeredEndpoint.includes(domain.endpoint)) {
        await axios
          .post(`${domain.endpoint}`, JSON.stringify(data), options)
          .then((res) => {
            console.log("Refresh Token Update Result: ", res);
          })
          .catch((err) => {
            console.log("Error Refresho Token Update", err);
          });
        triggeredEndpoint = [...triggeredEndpoint, domain.endpoint];
      }
    }
  }
};
