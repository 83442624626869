import React, { useState, useEffect } from "react";
import ToastNotification from "../toast-notification";
import "../../assets/styles/ContactAccess.css";
import { Info } from "./info";
import Switch from "./switch";
import BriefSwitch from "./switchBriefs";
import { LockAccess } from "./lock";
//import { PageList } from "./page-list";
import { API } from "aws-amplify";
import { AiFillCloseCircle } from "react-icons/ai";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import humaReadableFormat from "../../shared/humanReadableFormat";
import { FaBook, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { RiHome6Line } from "react-icons/ri";
// import Button from "../../shared/Button";
import { MdNavigateNext } from "react-icons/md";
import "./userTypeAccess.css";
import { SidebarData } from "../../components/sidebar/SidebarData";

function UserTypeAccess(props) {
  const title = "All changes has been saved!";

  const [showToast, setShowToast] = useState(false);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [defaultPages, setDefaultPages] = useState(null);
  const [defaultBriefs, setDefaultBriefs] = useState(null);
  const [userAccessSwitch, setUserAccessSwitch] = useState(null);
  const [switchIsTriggered, setSwitchIsTriggered] = useState(null);
  const [lockIsTriggered, setLockIsTriggered] = useState(null);
  const [disabledSwitch, setDisabledSwitch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openTab, setOpenTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagesAndFeatures, setPagesAndFeatures] = useState(null);
  const [briefs, setBriefs] = useState(null);

  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  const APP_DEFAULTS_QUERY = `query getDefaults {
    pages {
      id
      name
      features {
        id
        name
      }
    }
    defaultUserType
  }`;

  const USER_ACCESS_QUERY = `
  query getPagesAndAccess($companyId: String) {
    pages {
      id
      name
      label
      route
      features {
        id
        label
      }
    }
    companyAccessType(companyId: $companyId) {
      id
      userType
      access {
        id
        features {
          id
        }
      }
    }
  }
`;

  const UPDATE_ACCESS_MUTATION = `
  mutation updateCompanyAccessType($id: String, $access: [AccessInput]) {
    companyAccessTypeUpdate(
      id: $id
      access: $access
    ) {
      id
      access {
        id
        features {
          id
        }
      }
    }
  }`;

  const CREATE_ACCESS_MUTATION = `mutation createCompanyAccessType($companyId: String, $userType: [UserType], $access: [AccessInput]){
    companyAccessTypeCreate(
      companyId: $companyId
      userType: $userType
      access: $access
    ) {
      id
    }
  }`;

  const listBriefs = `
    query getBriefsByClientMatter($id: ID) {
      clientMatter(id: $id) {
        briefs {
          items {
            id
            name
            userTypeAccess
          }
        }
      }
    }
    `;

  const listClientMatters = `query listClientMatters($companyId: String) {
    company(id: $companyId) {
      clientMatters(sortOrder: CREATED_DESC, isDeleted: false) {
        items {
          id
          client {
            id
            name
          }
          matter {
            id
            name
          }
        }
      }
    }
  }`;

  const mBriefAddUserAccess = `
    mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
      briefAccessTypeTag(briefId:$briefId, userType:$userType) {
        id
      }
    }
  `;

  const hideToast = () => {
    setShowToast(false);
  };

  const lockIsClicked = (isChecked, feature_id) => {
    setLockIsTriggered({
      feature_id: feature_id,
      is_checked: isChecked,
    });
  };

  const switchIsClicked = (isChecked, page_id) => {
    setSwitchIsTriggered({
      page_id: page_id,
      is_checked: isChecked,
    });
  };

  const lockChanged = (id, access, userType) => {
    updateTaggedFeature(id, access, userType);
  };

  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  const getBriefs = async () => {
    const clientMattersOpt = await API.graphql({
      query: listClientMatters,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    });

    let result = clientMattersOpt.data.company.clientMatters.items;

    //Check if default client matter is existing
    let defaultCM = result.find(
      (x) => x?.client?.name === "Default" && x?.matter?.name === "Default"
    );

    if (!defaultCM) {
      setDefaultBriefs([]);
      return;
    }
    const params = {
      query: listBriefs,
      variables: {
        id: defaultCM.id,
      },
    };

    await API.graphql(params).then((brief) => {
      const labelsList = brief.data.clientMatter.briefs.items;
      // setLabels(labelsList);
      const defaultLabelsList = labelsList.map((brief) => {
        brief.userTypeAccess = defaultUserTypeVal(brief);
        return brief;
      });

      setDefaultBriefs(defaultLabelsList);
      setBriefs(defaultLabelsList);
    });
  };

  //get default val
  const defaultUserTypeVal = (data) => {
    const labelsData = data.userTypeAccess;
    if (labelsData !== null || labelsData.length === 0) {
      let labelsDataUnique = labelsData.filter(onlyUnique);

      var temp = [];
      labelsDataUnique.map((item) =>
        item !== null ? (temp = [...temp, { label: item, value: item }]) : temp
      );
      if (
        !temp.some((labels) => labels.label.includes("LEGALADMIN")) &&
        !temp.some((labels) => labels.label.includes("LAWYER"))
      ) {
        temp = [
          ...temp,
          { label: "LEGALADMIN", value: "LEGALADMIN" },
          { label: "LAWYER", value: "LAWYER" },
        ];
        handleAddUserAccess(temp, data.id);
      }
      return temp;
    } else {
      return [];
    }
  };

  //add user acc
  const handleAddUserAccess = async (e, dataId) => {
    var bId = dataId;
    var temp = [];

    e.map((item) => temp.push(item.value));

    const params = {
      query: mBriefAddUserAccess,
      variables: {
        briefId: bId,
        userType: temp,
      },
    };

    await API.graphql(params).then((ua) => {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    });
  };
  const updateTaggedFeature = (id, new_access, user_type) => {
    userAccessSwitch.map((page) => {
      if (page.userType === user_type) {
        return (
          new_access !== undefined &&
          page.access.map((p) => {
            if (p.id === new_access.id) {
              return new_access;
            }
            return p;
          })
        );
      }
      return page;
    });

    lockIsTriggered !== null &&
      switchIsTriggered === null &&
      updatePageAccess(id, userAccessSwitch);
  };

  const updateTaggedPages = (id, new_access, user_type) => {
    var removeSelectedTypeAccess = userAccessSwitch.filter(function (value) {
      return value.userType !== user_type;
    });

    var newAccessPageSet = [
      ...removeSelectedTypeAccess,
      {
        id: id,
        userType: user_type,
        access: new_access,
      },
    ];

    newAccessPageSet = newAccessPageSet.sort((a, b) =>
      a.userType.localeCompare(b.userType)
    );

    const firstItem = "OWNER";
    newAccessPageSet = newAccessPageSet.sort((x, y) => {
      return x.userType === firstItem ? -1 : y.userType === firstItem ? 1 : 0;
    });

    switchIsTriggered !== null && updatePageAccess(id, newAccessPageSet);

    setUserAccessSwitch(newAccessPageSet);
    setTableHeaders(newAccessPageSet.map((h) => h.userType));
  };

  const updateTaggedBriefs = async (id, new_access) => {
    await handleAddUserAccess(new_access, id);
    setDefaultBriefs((briefs) =>
      briefs.map((brief) => {
        if (brief.id === id) {
          brief.userTypeAccess = new_access;
        }
        return brief;
      })
    );
    setDisabledSwitch(false);
  };

  const contentDiv = {
    margin: "0 0 0 65px",
  };

  useEffect(() => {
    if (defaultPages === null && userAccessSwitch === null) {
      getPageAccess();
      getBriefs();
    }
  }, [defaultPages, userAccessSwitch]);

  useEffect(() => {
    // console.log("Loading: ", loading);
  }, [loading]);

  let getPageAccess = async () => {
    setLoading(true);

    // Fetching default values from the API using GraphQL
    const dafaults = await API.graphql({
      query: APP_DEFAULTS_QUERY, // The query for fetching default usertypes and pages values
    });

    const appDefaultPages = dafaults?.data?.pages; // Extracting default 'pages'
    const appDefaultUserType = dafaults?.data?.defaultUserType; // Extracting default 'User Types'

    await API.graphql({
      query: USER_ACCESS_QUERY,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    }).then(async (p) => {
      const { pages, companyAccessType } = p.data;

      // Mapping company access types to extract 'userType' property from each item
      const mappedCompanyAccessTypes = companyAccessType.map((i) => i.userType);

      // Filtering 'appDefaultUserType' array to find missing user types that are not included in 'mappedCompanyAccessTypes'
      const missingUserTypes = appDefaultUserType.filter(
        (item) => !mappedCompanyAccessTypes.includes(item)
      );

      // Checking if there are any missing user types
      if (missingUserTypes?.length > 0) {
        // Saving missing user types
        const saveMissingUserTypes = await API.graphql({
          query: CREATE_ACCESS_MUTATION,
          variables: {
            companyId: localStorage.getItem("companyId"),
            access: appDefaultPages,
            userType: missingUserTypes,
          },
        });

        // Checking if saving missing user types was successful
        if (saveMissingUserTypes) {
          getPageAccess(); // Refetching page access
        }
      }

      // console.log("listofpages", pages);
      // console.log("companyAccessType:", companyAccessType);
      setDefaultPages(pages);
      setPagesAndFeatures(pages);

      var userAccess = companyAccessType.sort((a, b) =>
        a.userType.localeCompare(b.userType)
      );

      // const firstItem = "OWNER";
      // userAccess = userAccess.sort((x, y) => {
      //   return x.userType === firstItem ? -1 : y.userType === firstItem ? 1 : 0;
      // });

      userAccess = userAccess.filter((obj) => obj.userType !== "OWNER");

      setUserAccessSwitch(userAccess);
      setTableHeaders(userAccess.map((h) => h.userType));
    });
    setLoading(false);
  };

  let updatePageAccess = async (id, userAccess) => {
    if (id !== undefined) {
      await updateCompanyAccessType(id, userAccess).then(() => {});
    }
  };

  async function updateCompanyAccessType(id, access) {
    try {
      var userAccess = access.filter(function (value) {
        return value.id === id;
      });

      userAccess = userAccess.map((a) => a.access);

      const request = await API.graphql({
        query: UPDATE_ACCESS_MUTATION,
        variables: {
          id: id,
          access: userAccess[0],
        },
      }).then((response) => {
        setShowToast(true);
        setDisabledSwitch(false);
        setTimeout(() => {
          setShowToast(false);
        }, 4000);
      });
    } catch (e) {
      console.error(e.errors[0].message);
    }
  }
  const [showRestriction, setShowRestriction] = useState(true);

  const close = () => {
    setShowRestriction(false);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value) {
      if (openTab === 0) {
        handlePagesAndFeaturesSearch(value);
      } else if (openTab === 1) {
        handleTilesSearch(value);
      }
    } else {
      setPagesAndFeatures(defaultPages);
      setBriefs(defaultBriefs);
    }
  };

  const handlePagesAndFeaturesSearch = (term) => {
    const matches = [];
    defaultPages.forEach((page) => {
      const isPageMatch = page.label.toLowerCase().includes(term.toLowerCase());
      const matchingFeatures = page.features.filter((feature) =>
        feature.label.toLowerCase().includes(term.toLowerCase())
      );

      if (isPageMatch || matchingFeatures.length > 0) {
        const pageData = { ...page };
        if (!isPageMatch) {
          pageData.features = matchingFeatures;
        }
        matches.push(pageData);
      }
    });

    console.log("search matches ", matches);
    setPagesAndFeatures(matches);
  };

  const handleTilesSearch = (term) => {
    const lowercasedTerm = term.toLowerCase();

    const matches = defaultBriefs.filter((brief) =>
      brief.name.toLowerCase().includes(lowercasedTerm)
    );

    console.log("search matches briefs", matches);
    setBriefs(matches);
  };

  return (
    <>
      {showToast && <ToastNotification title={title} hideToast={hideToast} />}
      {loading ? (
        <>
          <div className="p-5" style={contentDiv}>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  {showRestriction && (
                    <div className="w-full flex" onClick={() => close()}>
                      <Info
                        title="Page Restriction"
                        message="This page allows you to turn on/off the flags so you can restrict the features and pages every user types has an access to."
                      />
                      <button className="-ml-7 -mt-12">
                        <AiFillCloseCircle />
                      </button>
                    </div>
                  )}
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full border-separate">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="sr-only">Page/Feature Name</span>
                          </th>
                          {tableHeaders !== null
                            ? tableHeaders.map((header, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className="px-6 py-3 text-center text-sm font-semibold text-gray-500 uppercase tracking-wider"
                                >
                                  <Skeleton height={"20px"}>
                                    <SkeletonTheme />
                                  </Skeleton>
                                </th>
                              ))
                            : null}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {pagesAndFeatures !== null
                          ? pagesAndFeatures.map((page, page_index) => (
                              <React.Fragment key={page_index}>
                                <tr key={`${page.id}_${page_index}`}>
                                  <td className="px-6 py-4 whitespace-nowrap font-medium">
                                    <Skeleton height={"20px"}>
                                      <SkeletonTheme />
                                    </Skeleton>
                                  </td>
                                  {userAccessSwitch !== null &&
                                    userAccessSwitch.map(
                                      (user_access, access_index) => (
                                        <td
                                          key={access_index}
                                          className="px-6 py-4 whitespace-nowrap w-44 place-items-center text-center"
                                        >
                                          <Skeleton height={"20px"}>
                                            <SkeletonTheme />
                                          </Skeleton>
                                        </td>
                                      )
                                    )}
                                </tr>

                                {page.features.map((data, index) => (
                                  <tr key={`${data.id}_${index}`}>
                                    <td className="px-10 py-4 whitespace-nowrap text-sm text-gray-500 ">
                                      <Skeleton height={"20px"} width={"190px"}>
                                        <SkeletonTheme />
                                      </Skeleton>
                                    </td>
                                    {userAccessSwitch !== null
                                      ? userAccessSwitch.map(
                                          (access, index) => (
                                            <td
                                              key={`${access.id}_${index}`}
                                              className="px-6 py-2 whitespace-nowrap w-44 place-items-center text-center"
                                            >
                                              <Skeleton height={"20px"}>
                                                <SkeletonTheme />
                                              </Skeleton>
                                            </td>
                                          )
                                        )
                                      : null}
                                  </tr>
                                ))}
                              </React.Fragment>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="p-5" style={contentDiv}>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="pt-6 px-6 pb-0">
                    <div className="flex items-center gap-3 mt-2 text-gray-500">
                      <Link to={dashboardPath}>
                        <div className="flex items-center gap-3">
                          <RiHome6Line />
                        </div>
                      </Link>
                      <span>
                        <MdNavigateNext className=" text-gray-300 font-bold" />
                      </span>
                      <Link to="#">
                        <p className="font-semibold ">User Type Access</p>
                      </Link>
                      <FaBook className="sm:hidden" />
                      <span>
                        <MdNavigateNext className=" text-gray-300 font-bold" />
                      </span>
                      <Link to="#">
                        <span className="px-2 py-1 shadow rounded-md font-semibold bg-white">
                          {openTab === 0 ? "Settings" : "Manage User Access"}
                        </span>
                      </Link>
                      <FaBook className="sm:hidden" />
                    </div>
                    <div className="flex flex-col py-6">
                      <p className="font-semibold text-2xl font-open-sans">
                        User Type Access
                      </p>
                      <p className="text-gray-600 font-open-sans">
                        Manage your user types and their access permission here.
                      </p>
                    </div>

                    {/* Buttons for Settings & Manage User Access filter */}
                    <div className="bg-gray-100 mb-4 rounded-xl p-2">
                      <button
                        className={`px-4 py-2 text-sm font-medium rounded-lg ${
                          openTab === 0
                            ? "bg-white text-gray-600"
                            : "text-gray-400"
                        }`}
                        onClick={() => {
                          setOpenTab(0);
                          setSearchTerm("");
                        }} // Settings
                      >
                        Settings
                      </button>
                      <button
                        className={`px-4 py-2 text-sm font-medium rounded-lg ${
                          openTab === 1
                            ? "bg-white text-gray-600"
                            : "text-gray-400"
                        }`}
                        onClick={() => {
                          setOpenTab(1);
                          setSearchTerm("");
                        }} // Manage User Access
                      >
                        Manage User Access
                      </button>
                    </div>

                    {/* Commented not in Latest revamp
                  {showRestriction && (
                    <div className="w-full flex" onClick={() => close()}>
                      <Info
                        title="Page Restriction"
                        message="This page allows you to turn on/off the flags so you can restrict the features and pages every user types has an access to."
                      />
                      <button className="-ml-7 -mt-12">
                        <AiFillCloseCircle />
                      </button>
                    </div>
                  )} */}

                    {/* Search Bar & Buttons for adding a new access or filtering  */}
                    <div className="flex justify-between">
                      <div className="flex shadow font-normal rounded-xl border-2 p-0.5 text-xs w-1/5">
                        <FaSearch className=" mx-2 my-2 text-gray-400" />
                        <input
                          type={"text"}
                          className=" text-sm font-open-sans text-gray-600 w-full p-2 outline-none"
                          placeholder="Search for access"
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </div>

                      {/* <div className="flex items-center gap-2 justify-between">
                      <button
                        className="flex items-center text-sm justify-center gap-2 px-3 py-0.5 h-8 font-medium text-white bg-green-600 border border-green-600 rounded-lg shadow hover:bg-white hover:text-green-600"
                        // onClick={() => setShowCreateMatterModal(true)}
                      >
                        <CgMathPlus />
                        Add New
                      </button>
                      <button
                        className="flex items-center text-sm justify-center gap-2 px-3 py-0.5 h-8 font-medium bg-white text-gray-600 border border-gray-600 rounded-lg shadow hover:bg-green-600 hover:text-white"
                        // onClick={() => setShowCreateMatterModal(true)}
                      >
                        <MdFilterList text- />
                        Filter
                      </button>
                  </div> */}
                    </div>
                  </div>
                  {/* Having a blocker with border raduis to follow up po */}
                  <div className="userTypeAccessTable px-6 py-2 overflow-hidden ">
                    <table className="min-w-full min-h-full">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                          >
                            <span> {openTab === 0 ? "Page" : " Tiles"} </span>
                            {/* <span className="sr-only">Page/Feature Name</span> */}
                          </th>
                          {tableHeaders !== null
                            ? tableHeaders.map((header, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className="px-6 py-3 text-center text-sm font-semibold text-gray-500 tracking-wider"
                                >
                                  {humaReadableFormat(
                                    header
                                      .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
                                      .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
                                  )}
                                </th>
                              ))
                            : null}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {openTab === 0 && pagesAndFeatures !== null
                          ? pagesAndFeatures.map((page, page_index) => (
                              <React.Fragment key={page_index}>
                                <tr key={`${page.id}_${page_index}`}>
                                  <td className="px-6 py-4 whitespace-nowrap font-medium">
                                    {page.label}
                                  </td>
                                  {userAccessSwitch !== null &&
                                    userAccessSwitch.map(
                                      (user_access, access_index) => (
                                        <td
                                          key={access_index}
                                          className="px-6 py-4 whitespace-nowrap w-44 place-items-center text-center"
                                        >
                                          <Switch
                                            default_access={page}
                                            user_access_id={user_access.id}
                                            user_access={user_access.access}
                                            user_type={user_access.userType}
                                            updateTaggedPages={
                                              updateTaggedPages
                                            }
                                            switchIsClicked={switchIsClicked}
                                            disabledSwitch={disabledSwitch}
                                            setDisabledSwitch={
                                              setDisabledSwitch
                                            }
                                          />
                                        </td>
                                      )
                                    )}
                                </tr>

                                {page.features.map((data, index) => (
                                  <tr key={`${data.id}_${index}`}>
                                    <td className="px-10 py-4 whitespace-nowrap text-sm text-gray-500 ">
                                      {data.label}
                                    </td>
                                    {userAccessSwitch !== null
                                      ? userAccessSwitch.map(
                                          (access, index) => (
                                            <td
                                              key={`${access.id}_${index}`}
                                              className="px-6 py-2 whitespace-nowrap w-44 place-items-center text-center"
                                            >
                                              <LockAccess
                                                user_access_id={access.id}
                                                feature_id={data.id}
                                                default_features={page}
                                                feature_access={access.access}
                                                row_index={index}
                                                user_type={access.userType}
                                                lockChanged={lockChanged}
                                                lockIsClicked={lockIsClicked}
                                                switchIsTriggered={
                                                  switchIsTriggered
                                                }
                                                disabledSwitch={disabledSwitch}
                                                setDisabledSwitch={
                                                  setDisabledSwitch
                                                }
                                              />
                                            </td>
                                          )
                                        )
                                      : null}
                                  </tr>
                                ))}
                              </React.Fragment>
                            ))
                          : openTab === 1 && briefs !== null
                          ? briefs.map((brief, brief_index) => (
                              <React.Fragment key={brief_index}>
                                <tr key={`${brief.id}_${brief_index}`}>
                                  <td className="px-6 py-4 whitespace-nowrap font-medium">
                                    {brief.name}
                                  </td>
                                  {tableHeaders !== null &&
                                    tableHeaders.map(
                                      (userType, userType_index) => (
                                        <td
                                          key={userType_index}
                                          className="px-6 py-4 whitespace-nowrap w-44 place-items-center text-center"
                                        >
                                          <BriefSwitch
                                            brief={brief}
                                            accessType={userType}
                                            updateTaggedBriefs={
                                              updateTaggedBriefs
                                            }
                                            disabledSwitch={disabledSwitch}
                                            setDisabledSwitch={
                                              setDisabledSwitch
                                            }
                                            row_index={userType_index}
                                          />
                                        </td>
                                      )
                                    )}
                                </tr>
                              </React.Fragment>
                            ))
                          : null}

                        {openTab === 0 &&
                        pagesAndFeatures.length === 0 &&
                        searchTerm !== "" ? (
                          <tr>
                            <td
                              colSpan={tableHeaders.length + 1}
                              className="px-6 py-4 whitespace-nowrap w-44 place-items-center text-center"
                            >
                              No result found.
                            </td>
                          </tr>
                        ) : null}

                        {openTab === 1 &&
                        briefs.length === 0 &&
                        searchTerm !== "" ? (
                          <tr>
                            <td
                              colSpan={tableHeaders.length + 1}
                              className="px-6 py-4 whitespace-nowrap w-44 place-items-center text-center"
                            >
                              No result found.
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UserTypeAccess;
