import { createContext, useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
const ColumnContext = createContext();

const BACKGROUND_TABLE_QUERY = `
    query getBackgroundTable($clientMatterId: ID!) {
        backgroundTable(clientMatterId: $clientMatterId) {
          id
          columns {
            id
            accessorKey
            headerText
            enabled
            type
            optionsText
            order
            presets {
              id
              name
            }
          }
          createdAt
          updatedAt
        }
    }`;

const BACKGROUND_TABLE_CREATE = `
    mutation createBackgroundTable($clientMatterId: ID!) {
      backgroundTableCreate(clientMatterId: $clientMatterId) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
        }
        createdAt
        updatedAt
      }
    }
    `;

export const BriefsColumnContextProvider = ({ children, matterId }) => {
  const [columns, setColumns] = useState([]);

  const fetchTable = async () => {
    const data = await API.graphql({
      query: BACKGROUND_TABLE_QUERY,
      variables: {
        clientMatterId: matterId,
      },
    }).then((result) => {
      console.log("BACKGROUND_TABLE_COLUMNS", result);
      if (result.data.backgroundTable) {
        const tableColumns = result.data.backgroundTable?.columns?.map((c) => {
          if (c.type === "DROPDOWN") {
            return {
              accessorKey: c.id,
              header: c.headerText,
              type: c.type,
              enabled: c.enabled,
              order: c.order,
              presets: c.presets,
              optionsText: c.optionsText ? c.optionsText : [],
            };
          }
          return {
            accessorKey: c.id,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            presets: c.presets,
            order: c.order,
          };
        });

        const backgroundTableId = result.data.backgroundTable?.id;
        setTableId(backgroundTableId);
        setColumns(tableColumns);
      } else {
        createTable();
      }
    });
  };

  function setTableId(id) {
    console.log("setTableId", id);
    return localStorage.setItem("background-table-id", id);
  }

  const createTable = async () => {
    const data = await API.graphql({
      query: BACKGROUND_TABLE_CREATE,
      variables: {
        clientMatterId: matterId,
      },
    }).then((result) => {
      console.log("INTIAL BACKGROUND_TABLE_CREATE", result);
      if (result.data.backgroundTableCreate) {
        const tableColumns = result.data.backgroundTableCreate?.columns.map(
          (c) => {
            if (c.type === "DROPDOWN") {
              return {
                accessorKey: c.id,
                header: c.headerText,
                type: c.type,
                enabled: c.enabled,
                order: c.order,
                presets: c.presets,
                optionsText: c.optionsText ? c.optionsText : [],
              };
            }
            return {
              accessorKey: c.id,
              header: c.headerText,
              type: c.type,
              enabled: c.enabled,
              presets: c.presets,
              order: c.order,
            };
          }
        );

        const backgroundTableId = result.data.backgroundTable?.id;
        setTableId(backgroundTableId);
        setColumns(tableColumns);
      }
    });
  };

  useEffect(() => {
    fetchTable();
  }, []);

  return (
    <ColumnContext.Provider value={[columns, setColumns]}>
      {children}
    </ColumnContext.Provider>
  );
};

export function useBriefColumnContext() {
  return useContext(ColumnContext);
}
