import React, { useEffect, useRef, useState } from "react";
import TableInfoBreakdown from "./table-info-breakdown.jsx";
import CompactModalBreakdown from "./compact-modal-breakdown.jsx";
import { MdClose } from "react-icons/md";

const AddPaymentModal = ({
  isOpen,
  onClose,
  children,
  backgroundDuplicate,
  background,
  files,
  wait,
  setIdList,
  setBackground,
  previousBackground,
  setPreviousBackground,
  checkAllState,
  setcheckAllState,
  checkedState,
  setCheckedState,
  settotalChecked,
  search,
  getId,
  setId,
  getBackground,
  matterId,
  selectedRowsBG,
  setSelectedRowsBG,
  ShowModalParagraph,
  setShowModalParagraph,
  paragraph,
  setParagraph,
  ascDesc,
  setShowDeleteButton,
  activateButton,
  setSelectedRowsBGFiles,
  selectedRowsBGFiles,
  setSelectedId,
  selectRow,
  setSelectRow,
  pasteButton,
  setSrcIndex,
  client_name,
  matter_name,
  setPasteButton,
  setLoading,
  sortByOrder,
  SortBydate,
  briefId,
  briefName,
  searchDescription,
  selectedItems,
  setSelectedItems,
  setHoldDelete,
  highlightRow,
  pastedRows,
  setPastedRows,
  checkedRows,
  setCheckedRows,
  setbindList,
  setCache,
  setMoveButton,
  moveButton,
  fontSize,
  bgLoading,
  setBgLoading,
  allowEditDate,
  allowEditComments,
  allowEditLabels,
  allowShowLabels,
  commentAccess,
  allowDeleteFiles,
  allowDragNDrop,
  uploadedDocumentList,
  columns,
  qSearchValue,
  pressedQSearch,
  totalHits,
  setTotalHits,
  qSearchPtr,
  setQSearchPtr,
  inRow,
  reducedTabsArray,
  setReducedTabsArray,
  reduceTabFilesToArray,
  reducedUploadedFilesArray,
  reduceUploadedFilesToArray,
  currLabelCellSelected,
  currLabelCellSelected2,
  handleChangeLabel,
  justChangedLabel,
  allowScroll,
  showRightPanel,
  setShowRightPanel,
  fileView,
  setWait,
  isSiteDiary,
  modalBriefName,
  // TANSTACK VIRTUALIZED
  virtualRows,
  rows,
}) => {
  const modalRef = useRef(null);

  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [openItemID, setOpenItemID] = useState("");
  const [openItems, setOpenItems] = useState([]);

  useEffect(() => {
    const modal = modalRef.current;

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // onClose();
      }
    };

    const handleClickOutside = (event) => {
      if (modal && !modal.contains(event.target)) {
        // onClose();
      }
    };
    console.log("Modal Brief name:", modalBriefName);
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-hidden flex justify-center items-center">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={onClose}
          ></div>
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg overflow-hidden"
            style={
              modalBriefName !== "Budget"
                ? {
                    minHeight: "90vh",
                    maxHeight: "90vh",
                    maxWidth: "75vw",
                    minWidth: "75vw",
                  }
                :  {
                    minHeight: isOpenPayment ? "90vh" : "90vh",
                    maxHeight: isOpenPayment ? "90vh" : "90vh",
                    maxWidth: "40vw",
                    minWidth: "40vw",
                  }
            }
          >
            <div
              className="overflow-x-hidden"
              style={{
                minHeight: "calc(95vh - 3.5rem)",
                maxHeight: "calc(95vh - 3.5rem)",
              }}
            >
              {modalBriefName !== "Budget" ? (
                <>
                  {/* Close button */}
                  <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer mr-6 mt-2 font-semibold"
                    onClick={onClose}
                  >
                    Close
                  </button>
                  {children}
                  <TableInfoBreakdown
                    allowScroll={allowScroll}
                    backgroundDuplicate={backgroundDuplicate}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    client_name={client_name}
                    matter_name={matter_name}
                    wait={wait}
                    setPasteButton={setPasteButton}
                    setIdList={setIdList}
                    background={background}
                    uploadedDocumentList={uploadedDocumentList}
                    ShowModalParagraph={ShowModalParagraph}
                    setShowModalParagraph={setShowModalParagraph}
                    files={files}
                    setBackground={setBackground}
                    previousBackground={previousBackground}
                    setPreviousBackground={setPreviousBackground}
                    checkAllState={checkAllState}
                    setcheckAllState={setcheckAllState}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    settotalChecked={settotalChecked}
                    setId={setId}
                    getId={getId}
                    search={search}
                    matterId={matterId}
                    getBackground={getBackground}
                    selectedRowsBG={selectedRowsBG}
                    setSelectedRowsBG={setSelectedRowsBG}
                    paragraph={paragraph}
                    setParagraph={setParagraph}
                    setShowDeleteButton={setShowDeleteButton}
                    activateButton={activateButton}
                    setSelectedRowsBGFiles={setSelectedRowsBGFiles}
                    selectedRowsBGFiles={selectedRowsBGFiles}
                    setSelectedId={setSelectedId}
                    selectedId={[]}
                    pasteButton={pasteButton}
                    checkDate={[]}
                    checkDesc={[]}
                    checkDocu={[]}
                    pageSize={[]}
                    pageSizeConst={[]}
                    selectRow={selectRow}
                    setSelectRow={setSelectRow}
                    setSrcIndex={setSrcIndex}
                    newRow={[]}
                    setNewRow={[]}
                    loading={[]}
                    setLoading={setLoading}
                    maxLoading={[]}
                    sortByOrder={sortByOrder}
                    SortBydate={SortBydate}
                    searchDescription={searchDescription}
                    holdDelete={[]}
                    setHoldDelete={setHoldDelete}
                    setTargetRow={[]}
                    targetRow={[]}
                    highlightRow={highlightRow}
                    setHighlightRow={[]}
                    pastedRows={pastedRows}
                    setPastedRows={setPastedRows}
                    checkedRows={checkedRows}
                    setCheckedRows={setCheckedRows}
                    setbindList={setbindList}
                    setCache={setCache}
                    setMoveButton={setMoveButton}
                    moveButton={moveButton}
                    fontSize={fontSize}
                    bgLoading={bgLoading}
                    setBgLoading={setBgLoading}
                    allowEditDate={allowEditDate}
                    allowEditDescription={[]}
                    allowEditComments={allowEditComments}
                    allowEditLabels={allowEditLabels}
                    allowEditFiles={[]}
                    allowShowLabels={allowShowLabels}
                    headerHeight={[]}
                    commentAccess={commentAccess}
                    gmailFilter={[]}
                    allowDeleteFiles={allowDeleteFiles}
                    allowDragNDrop={allowDragNDrop}
                    columns={columns}
                    setActiveColumns={[]}
                    qSearchValue={qSearchValue}
                    pressedQSearch={pressedQSearch}
                    totalHits={totalHits}
                    setTotalHits={setTotalHits}
                    qSearchPtr={qSearchPtr}
                    setQSearchPtr={setQSearchPtr}
                    inRow={inRow}
                    reducedTabsArray={reducedTabsArray}
                    setReducedTabsArray={setReducedTabsArray}
                    reduceTabFilesToArray={reduceTabFilesToArray}
                    reducedUploadedFilesArray={reducedUploadedFilesArray}
                    reduceUploadedFilesToArray={reduceUploadedFilesToArray}
                    currLabelCellSelected={currLabelCellSelected}
                    currLabelCellSelected2={currLabelCellSelected2}
                    handleChangeLabel={handleChangeLabel}
                    justChangedLabel={justChangedLabel}
                    setJustChangedLabel={[]}
                    showRightPanel={showRightPanel}
                    setShowRightPanel={setShowRightPanel}
                    fileView={fileView}
                    setWait={setWait}
                    isSiteDiary={isSiteDiary}
                    briefId={briefId}
                    briefName={briefName}
                    // TANSTACK VIRTUALIZED
                    virtualRows={virtualRows}
                    rows={rows}
                  />
                </>
              ) : (
                <>
                  {/* Close button */}
                  <button
                    className="absolute top-5 right-4 text-gray-500 hover:text-gray-700 cursor-pointer font-semibold"
                    onClick={onClose}
                  >
                    <MdClose size={25} />
                  </button>
                  <CompactModalBreakdown
                    allowScroll={allowScroll}
                    backgroundDuplicate={backgroundDuplicate}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    client_name={client_name}
                    matter_name={matter_name}
                    wait={wait}
                    setPasteButton={setPasteButton}
                    setIdList={setIdList}
                    background={background}
                    uploadedDocumentList={uploadedDocumentList}
                    ShowModalParagraph={ShowModalParagraph}
                    setShowModalParagraph={setShowModalParagraph}
                    files={files}
                    setBackground={setBackground}
                    previousBackground={previousBackground}
                    setPreviousBackground={setPreviousBackground}
                    checkAllState={checkAllState}
                    setcheckAllState={setcheckAllState}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    settotalChecked={settotalChecked}
                    setId={setId}
                    getId={getId}
                    search={search}
                    matterId={matterId}
                    getBackground={getBackground}
                    selectedRowsBG={selectedRowsBG}
                    setSelectedRowsBG={setSelectedRowsBG}
                    paragraph={paragraph}
                    setParagraph={setParagraph}
                    setShowDeleteButton={setShowDeleteButton}
                    activateButton={activateButton}
                    setSelectedRowsBGFiles={setSelectedRowsBGFiles}
                    selectedRowsBGFiles={selectedRowsBGFiles}
                    setSelectedId={setSelectedId}
                    selectedId={[]}
                    pasteButton={pasteButton}
                    checkDate={[]}
                    checkDesc={[]}
                    checkDocu={[]}
                    pageSize={[]}
                    pageSizeConst={[]}
                    selectRow={selectRow}
                    setSelectRow={setSelectRow}
                    setSrcIndex={setSrcIndex}
                    newRow={[]}
                    setNewRow={[]}
                    loading={[]}
                    setLoading={setLoading}
                    maxLoading={[]}
                    sortByOrder={sortByOrder}
                    SortBydate={SortBydate}
                    searchDescription={searchDescription}
                    holdDelete={[]}
                    setHoldDelete={setHoldDelete}
                    setTargetRow={[]}
                    targetRow={[]}
                    highlightRow={highlightRow}
                    setHighlightRow={[]}
                    pastedRows={pastedRows}
                    setPastedRows={setPastedRows}
                    checkedRows={checkedRows}
                    setCheckedRows={setCheckedRows}
                    setbindList={setbindList}
                    setCache={setCache}
                    setMoveButton={setMoveButton}
                    moveButton={moveButton}
                    fontSize={fontSize}
                    bgLoading={bgLoading}
                    setBgLoading={setBgLoading}
                    allowEditDate={allowEditDate}
                    allowEditDescription={[]}
                    allowEditComments={allowEditComments}
                    allowEditLabels={allowEditLabels}
                    allowEditFiles={[]}
                    allowShowLabels={allowShowLabels}
                    headerHeight={[]}
                    commentAccess={commentAccess}
                    gmailFilter={[]}
                    allowDeleteFiles={allowDeleteFiles}
                    allowDragNDrop={allowDragNDrop}
                    columns={columns}
                    setActiveColumns={[]}
                    qSearchValue={qSearchValue}
                    pressedQSearch={pressedQSearch}
                    totalHits={totalHits}
                    setTotalHits={setTotalHits}
                    qSearchPtr={qSearchPtr}
                    setQSearchPtr={setQSearchPtr}
                    inRow={inRow}
                    reducedTabsArray={reducedTabsArray}
                    setReducedTabsArray={setReducedTabsArray}
                    reduceTabFilesToArray={reduceTabFilesToArray}
                    reducedUploadedFilesArray={reducedUploadedFilesArray}
                    reduceUploadedFilesToArray={reduceUploadedFilesToArray}
                    currLabelCellSelected={currLabelCellSelected}
                    currLabelCellSelected2={currLabelCellSelected2}
                    handleChangeLabel={handleChangeLabel}
                    justChangedLabel={justChangedLabel}
                    setJustChangedLabel={[]}
                    showRightPanel={showRightPanel}
                    setShowRightPanel={setShowRightPanel}
                    fileView={fileView}
                    setWait={setWait}
                    isSiteDiary={isSiteDiary}
                    briefId={briefId}
                    briefName={briefName}
                    onClose={onClose}
                    isOpenPayment={isOpenPayment}
                    setIsOpenPayment={setIsOpenPayment}
                    openItemID={openItemID}
                    setOpenItemID={setOpenItemID}
                    setOpenItems={setOpenItems}
                    openItems={openItems}
                    // TANSTACK VIRTUALIZED
                    virtualRows={virtualRows}
                    rows={rows}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPaymentModal;
