import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import AccessControl from "../../shared/accessControl";
import "../../assets/styles/SideNavigation.css";
import ExtendedBar from "./ExtendedBar";
import MinimizedBar from "./MinimizedBar";
import { AppRoutes } from "../../constants/AppRoutes";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";

const Navigation = () => {
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [showInbox, setShowInbox] = useState(false);
  const [showUserTypeAccess, setShowUserTypeAccess] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);
  const [userInfo, setuserInfo] = useState(null);
  const [userHasPortalAccess, setUserHasPortalAccess] = useState(null);
  const [warningPrompt, setWarningPrompt] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const { width, height } = useWindowDimensions();
  const [isInitializingPortal, setIsInitializingPortal] = useState(false);
  const [showInboxSublist, setShowInboxSublist] = useState(
    location.pathname === "/inbox"
  );
  //To manage and maintain companyName state var between MinimizedBar and ExtendedBar
  const [companyName, setCompanyName] = useState("");

  const mgetUserById = `
    query user($id: String) {
      user(id: $id) {
        profilePictureKey
        profilePicture
      }
    }
  `;

  const mCreateActivity = `
mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
	activity: $activity
	briefId: $briefId
	clientMatterId: $clientMatterId
	companyId: $companyId
	previous: $previous
	field: $field
	current: $current
	appModule: $appModule
	rowId: $rowId
  ) {
	id
  }
}`;

  const getUserById = async (userId) => {
    await API.graphql({
      query: mgetUserById,
      variables: {
        id: userId,
      },
    }).then((response) => {
      console.log("response from getuser ", response);
      const { profilePicture } = response?.data?.user;
      if (profilePicture) {
        setProfilePic(profilePicture);
      }

      if (localStorage.getItem("hasPortalAccess") === "false") {
        setWarningPrompt(
          "Your access to this portal has been revoked. Kindly contact your Admin to grant you access."
        );
        history.push(AppRoutes.SIGNOUT);
      }
    });
  };

  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    // featureAccessFilters();
    if (warningPrompt !== "") {
      alert(warningPrompt);
    }
  }, [warningPrompt]);

  useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      signOut();
    } else {
      if (userInfo === null) {
        let ls = {
          userId: localStorage.getItem("userId"),
          email: localStorage.getItem("email"),
          firstName: localStorage.getItem("firstName"),
          lastName: localStorage.getItem("lastName"),
          company: localStorage.getItem("company"),
          userType: localStorage.getItem("userType"),
          // access: JSON.parse(localStorage.getItem("access")),
          profilePicture: localStorage.getItem("profilePicture"),
        };

        setuserInfo(ls);
      }

      getUserById(localStorage.getItem("userId"));
    }

    if (userInfo) {
      featureAccessFilters();
    }
  }, [userInfo]);

  let history = useHistory();

  const clickLogout = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to logout?")) {
      setSidebar(false);
      const activityParams = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: `User signed out`,
          field: "Authentication",
          appModule: "AUTH",
          previous: null,
          current: null,
        },
      };

      const addActivity = await API.graphql(activityParams).then((result) =>
        console.log(`ADD ACTIVITY RESULT`, result)
      );
      signOut();
    } else {
      return false;
    }
  };

  const signOut = () => {
    history.push(AppRoutes.SIGNOUT);
  };

  const featureAccessFilters = async () => {
    console.group("featureAccessFilters()");
    const currentPage = "/" + location?.pathname.split("/")[1];
    const [
      inboxAccess,
      contactsAccess,
      tasksAccess,
      fileBucketAccess,
      backgroundAccess,
      mattersOverviewAccess,
      mattersRFIAccess,
      userTypeAccess,
      companyDetailsAccess,
    ] = await Promise.all([
      AccessControl("INBOX"),
      AccessControl("CONTACTS"),
      AccessControl("TASKS"),
      AccessControl("FILEBUCKET"),
      AccessControl("BACKGROUND"),
      AccessControl("MATTERSOVERVIEW"),
      AccessControl("MATTERSRFI"),
      AccessControl("USERTYPEACCESS"),
      AccessControl("SHOWCOMPANYDETAILS"),
    ]);

    if (inboxAccess.status === "restrict") {
      if (currentPage === AppRoutes.INBOX) {
        setWarningPrompt(inboxAccess.message);
        history.push(AppRoutes.DASHBOARD);
      }
    } else {
      setShowInbox(true);
    }

    if (contactsAccess.status === "restrict") {
      if (currentPage === AppRoutes.CONTACTS) {
        setWarningPrompt(contactsAccess.message);
        history.push(AppRoutes.DASHBOARD);
      }
    } else {
      setShowContacts(true);
    }

    if (tasksAccess.status === "restrict") {
      if (currentPage === AppRoutes.TASKS) {
        setWarningPrompt(tasksAccess.message);
        history.push(AppRoutes.DASHBOARD);
      }
    } else {
      setShowTasks(true);
    }

    if (fileBucketAccess.status === "restrict") {
      if (currentPage === AppRoutes.FILEBUCKET) {
        setWarningPrompt(fileBucketAccess.message);
        history.push(AppRoutes.DASHBOARD);
      }
    }

    // Disable restrict page and use in feature update
    // if (mattersOverviewAccess.status === "restrict") {
    //   if (currentPage === AppRoutes.MATTERSOVERVIEW) {
    //     setWarningPrompt(mattersOverviewAccess.message);
    //     history.push(AppRoutes.DASHBOARD);
    //   }
    // }

    if (mattersRFIAccess.status === "restrict") {
      // console.log("mattersRFIAccess", mattersRFIAccess);
      // console.log(AppRoutes);
      // console.log("currentPage", location);
      // console.log();

      if (
        currentPage === AppRoutes.MATTERSRFI ||
        currentPage === AppRoutes.RFIPAGE
      ) {
        setWarningPrompt(mattersRFIAccess.message);
        history.push(AppRoutes.DASHBOARD);
      }
    }

    if (backgroundAccess.status === "restrict") {
      if (
        currentPage === AppRoutes.BACKGROUND ||
        currentPage === AppRoutes.BRIEFS
      ) {
        setWarningPrompt(backgroundAccess.message);
        history.push(AppRoutes.DASHBOARD);
      }
    }

    if (userTypeAccess.status === "restrict") {
      if (currentPage === AppRoutes.USERTYPEACCESS) {
        setWarningPrompt(userTypeAccess.message);
        history.push(AppRoutes.DASHBOARD);
      }
    } else {
      setShowUserTypeAccess(true);
    }

    if (companyDetailsAccess.status !== "restrict") setShowCompanyDetails(true);

    console.groupEnd("featureAccessFilters()");
  };

  var assigneeInitials = "";
  if (
    userInfo &&
    `${userInfo?.firstName?.toLowerCase()} ${userInfo?.lastName?.toLowerCase()}` ===
      "john dela cruz"
  ) {
    assigneeInitials = "JDC";
  } else {
    assigneeInitials += userInfo?.firstName
      ? userInfo.firstName?.charAt(0)
      : "";
    assigneeInitials += userInfo?.lastName ? userInfo.lastName?.charAt(0) : "";
  }

  return (
    <>
      {!sidebar ? (
        <MinimizedBar
          sidebar={sidebar}
          userInfo={userInfo}
          setuserInfo={setuserInfo}
          showSidebar={showSidebar}
          signOut={signOut}
          assigneeInitials={assigneeInitials}
          location={location}
          showInbox={showInbox}
          showUserTypeAccess={showUserTypeAccess}
          showContacts={showContacts}
          showTasks={showTasks}
          clickLogout={clickLogout}
          profilePic={profilePic}
          setCompanyName={setCompanyName}
          companyName={companyName}
          showInboxSublist={showInboxSublist}
          setShowInboxSublist={setShowInboxSublist}
          setIsInitializingPortal={setIsInitializingPortal}
        />
      ) : (
        <ExtendedBar
          sidebar={sidebar}
          userInfo={userInfo}
          setuserInfo={setuserInfo}
          showSidebar={showSidebar}
          showInbox={showInbox}
          showUserTypeAccess={showUserTypeAccess}
          showContacts={showContacts}
          showTasks={showTasks}
          showCompanyDetails={showCompanyDetails}
          clickLogout={clickLogout}
          assigneeInitials={assigneeInitials}
          location={location}
          profilePic={profilePic}
          companyName={companyName}
          setCompanyName={setCompanyName}
          showInboxSublist={showInboxSublist}
          setShowInboxSublist={setShowInboxSublist}
          setIsInitializingPortal={setIsInitializingPortal}
        />
      )}
      {isInitializingPortal && (
        <div className="fixed flex w-screen h-screen left-0 top-0 right-0 bottom-0 z-50 cursor-wait">
          <div className="absolute w-full h-full bg-black opacity-60 z-0" />
          <div
            className={`m-auto text-white z-10 font-medium text-center ${
              isMobileDevice(width) ? "text-md" : "text-4xl"
            }`}
          >
            Initializing New Portal...
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
