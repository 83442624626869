import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Select from "react-select";
import { RiFileInfoLine, RiFilterFill, RiFilterOffLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Button from "../../shared/Button";
import { CgClose } from "react-icons/cg";
import { right } from "@popperjs/core";
import {
    BsFillFileEarmarkFill,
    BsFillFileEarmarkFontFill,
    BsFileEarmarkPdfFill,
    BsFillFileEarmarkExcelFill,
    BsFillFileEarmarkWordFill,
    BsFillFileEarmarkSlidesFill,
    BsFillFileEarmarkImageFill,
    BsFillFileEarmarkPlayFill,
    BsFillFileEarmarkMusicFill,
  } from "react-icons/bs";
  import { FaFileCsv} from "react-icons/fa"

const RightPanel = ({
    showRightPanel,
    setShowRightPanel,
    rightPanelItems,
    setRightPanelItems,
    handleOpenAI,
    previewAndDownloadFile,
    fileTypeCollection
}) => {
 


  useEffect(() => {
    if (rightPanelItems !== null){
        console.log("items in right", rightPanelItems);
    }
  }, []);


  const fileId = rightPanelItems


  function FileFormat(){
    const fileFormatIcons = {
        default: <BsFillFileEarmarkFill className="text-blue-400" size={40}/>,
        txt: <BsFillFileEarmarkFontFill/>,
        pdf: <BsFileEarmarkPdfFill className="text-red-600" size={40}/>,
        docx: <BsFillFileEarmarkWordFill className="text-blue-500" size={40}/>,
        xlsx: <BsFillFileEarmarkExcelFill className="text-green-500" size={40}/>,
        pptx: <BsFillFileEarmarkSlidesFill className="text-orange-600" size={40}/>,
        csv: <FaFileCsv className="text-green-400" size={40}/>,
        png: <BsFillFileEarmarkImageFill className="text-blue-700" size={40}/>,
        jpeg: <BsFillFileEarmarkImageFill className="text-orange-700" size={40}/>,
        mp4: <BsFillFileEarmarkPlayFill className="text-blue-400" size={40}/>,
        mov: <BsFillFileEarmarkPlayFill className="text-blue-400" size={40}/>,
        mp3: <BsFillFileEarmarkMusicFill size={40}/>
      }
    
      const fileType  = (items) => fileTypeCollection?.filter((fileItem)=>fileItem.id===rightPanelItems[0]?.files?.items[rightPanelItems[2]]?.id)[0]?.type?.split("/")[1];

      return(
        <>
            {//File Icon Implementation
            fileFormatIcons[
                Object.keys(fileFormatIcons).filter((key)=>key.includes(fileType(rightPanelItems[0]?.files.items))).length?
                fileTypeCollection?.filter((ftc)=>ftc.id===rightPanelItems[0]?.files?.items[rightPanelItems[2]]?.id)[0]?.type?.split("/")[1]:
                'default'
            ]
            }
        </>
      );
  }


  return (
    <>
      <div
        className="outline-none focus:outline-none h-full fixed inset-0 z-50 flex"
        style={{ zIndex: 70 }}
      >
        <div className="fixed right-0 w-1/4 h-full">
          <div className="outline-none focus:outline-none relative w-full h-full rounded-sm border-0 bg-white p-3 shadow-lg">
            <div className="flex items-start justify-between w-full">
              <h3 className="pb-2 text-lg font-semibold text-gray-900">
                Email Details
              </h3>
              <button
                className="ml-auto mt-2 flex h-4 w-4 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-100 hover:bg-gray-300"
                onClick={()=>setShowRightPanel(false)}
              >
                <CgClose />
              </button>
            </div>   
            <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <div className=" items-start justify-between w-full py-2 px-2 flex">
                <FileFormat
                className="w-1/5"
                size={20}
                />
                <div className="w-4/5">
                    <p className="text-xs font-semibold text-gray-900">
                        {rightPanelItems[0]?.files?.items[rightPanelItems[2]]?.name}
                    </p>
                    <h7 className="text-xs font-normal text-blue-400 cursor-pointer"
                        onClick={()=>previewAndDownloadFile(rightPanelItems[0]?.files?.items[rightPanelItems[2]]?.id)}
                    >
                        Preview File In New Tab
                    </h7>
                </div>
              

            </div> 
            <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <div class="items-start justify-between w-full py-2 px-2 h-3/4 overflow-y-scroll overflow-x-hidden">
                {/* {rightPanelItems !== null && (rightPanelItems)} */}
                {
                    rightPanelItems[0].description
                }
            </div>
            <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
            {rightPanelItems[3] && (
              <div className=" -mt-8 w-full py-2 px-2 fixed right-0 bottom-0">
                  <Button
                    size="xs"
                    variant="primary"
                    className="mr-1 mb-1 px-3 py-3 float-right"
                    type="button"
                    onClick={() =>
                      handleOpenAI(
                          rightPanelItems[0].id,
                          rightPanelItems[1],
                          rightPanelItems[0].description,
                          rightPanelItems[3]
                      )
                    }
                  >
                    Summarize Email
                  </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="fixed inset-0 bg-black opacity-25"
        style={{ zIndex: 60 }}
      ></div>
    </>
  );
}

export default RightPanel;