import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import ClientMatterSelect from "./clientMatterSelect";
import Button from "../../../shared/Button"
import { ListClientMatters } from "../../../shared/graphql/queries";

const qClientMatters = `
  query listClientMatters($companyId: String) {
    company(id: $companyId) {
      clientMatters (sortOrder: CREATED_DESC, isDeleted:false) {
        items {
          id
          createdAt
          client {
            id
            name
          }
          matter {
            id
            name
          }
        }
      }
    }
  }
`;

const mTagClientMatter = `
  mutation tagUserClientMatterAccess($clientMatterAccess: [UserClientMatterAccessInput], $userId: ID, $companyId: ID) {
    userClientMatterTag(userId: $userId, clientMatterAccess: $clientMatterAccess, companyId: $companyId) {
      id
    }
  }
`;

const qGetCompanyUsers = `
  query getCompanyUsers($id: String) {
  company(id: $id) {
    id
    users {
      items {
        id
        firstName
        lastName
        email
        profilePicture
        clientMatterAccess(companyId: $id) {
          items {
            id
            userType
            clientMatter {
              id
              client {
                id
                name
              }
              matter {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}`;

const qGetTeamsWithMembers = `
  query getTeamMembers($id: ID, $companyId: String) {
  team(id: $id) {
    id
    name
    members {
      items {
        user {
          id
          firstName
          lastName
          userType
          profilePicture
        }
      }
    }
    clientMatters {
      items {
        id
        client {
          name
        }
        matter {
          name
        }
      }
    }
  }
}`;

const userClientMatter =
`clientMatterAccess(companyId: $companyId) {
  items {
    id
    userType
    clientMatter {
      id
      client {
        id
        name
      }
      matter {
        id
        name
      }
    }
  }
}`;

export default function TeamClientMatter({
  CurrentTeam,
  isEditing,
  inputCMList,
  setInputCMList,
  handleSubmit,
  loading,
  setLoading,
}) {
  const [teamInfo, setTeamInfo] = useState()
  const [clientMatterList, setClientMatterList] = useState([])
  const [options, setOptions] = useState([])

  const companyId = localStorage.getItem("companyId");

  useEffect(() => {
    getClientMatter(companyId)
    setTeamInfo(CurrentTeam)
  }, [])

  async function getClientMatter(companyId) {
    // const response = await API.graphql({
    //   query: qClientMatters,
    //   variables: {
    //     companyId: companyId,
    //   }
    // })
    const response = await ListClientMatters(companyId)
    .then((result) => {
      console.log("getClientMatter", result.data.company.clientMatters?.items)
      setClientMatterList(result.data.company.clientMatters?.items)
      setOptions(result.data.company.clientMatters?.items)
    })
  }
  
  // async function getTeamInfo(id) {
  //   const response = await API.graphql({
  //     query: qGetTeamsWithMembers,
  //     variables: {
  //       id: id,
  //       companyId: companyId,
  //     }
  //   }).then((result) => {
  //     console.log("getTeamInfo", result.data?.team)
  //     setTeamInfo(result.data?.team)
  //   })
  // }

  const formattedOptions = formatOptions();

  function formatOptions() {
    var cmList = []
    for (var i = 0; i < options?.length; i++) {
      cmList = [
        ...cmList,
        {
          label: `${options[i]?.client?.name} / ${options[i]?.matter?.name}`,
          value: options[i]?.id,
        }
      ]
    }
    return cmList;
  }

  useEffect(() => {
    console.log("inputCMList", inputCMList)
    const newOptions = clientMatterList.filter(item => {
      const inputIds = inputCMList.map(inputItem => inputItem.id);
      return !inputIds.includes(item.id);
    })
    console.log("newOptions", newOptions)
    setOptions(newOptions)
  }, [inputCMList])

  function handleTagAllClientMatter(e) {
    setLoading(true)
    setInputCMList(clientMatterList)
    // setTimeout(handleSubmit(), 1500)
  }

  return(
    <>
      <div className="overflow-y-scroll">
        {isEditing && (
          <Button 
            variant="primary"
            className="mb-4"
            size="medium"
            onClick={(e) => {
              handleTagAllClientMatter(e)
            }}
            disabled={loading}
          >
            Tag all Client / Matter
          </Button>
        )}
        <form>
          {inputCMList?.map((item, index) => {
            return(
              <ClientMatterSelect
              key={item?.id}
              options={formattedOptions}
              name={index}
              disabled={!isEditing}
              item={item}
              index={index}
              inputCMList={inputCMList}
              setInputCMList={setInputCMList}
              clientMatterList={clientMatterList}
            />
            )
          })}
        </form>
      </div>
    </>
  )
}