export const newRuleStateReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_NEW_RULE_OPEN":
      return {
        ...state,
        newRuleOpen: action.payload,
      };

    case "OPEN_NEW_RULE":
      return {
        ...state,
        newRuleOpen: true,
      };

    case "CLOSE_NEW_RULE":
      return {
        ...state,
        newRuleOpen: false,
        newRuleContent: null,
      };

    case "CHANGE_NEW_RULE_CONTENT":
      return {
        ...state,
        newRuleContent: action.payload,
      };

    default:
      return state;
  }
};
