import { API } from "aws-amplify";
import { DefaultPhases } from "../constants/Phases";

const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
    id
    columns {
      id
      accessorKey
      headerText
      type
      enabled
      optionsText
      order
      presets {
      id
      name
      }
    }
    }
  }
`;

const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int], $assistantId: String, $phase: [Phase]) {
  briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds, assistantId: $assistantId, phase: $phase) {
    id
    name
    date
    createdAt,
    assistantId,
    order
    columnIds
  }
}`;

const mAddUserAcces = `
  mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
    briefAccessTypeTag(briefId:$briefId, userType:$userType) {
      id
    }
  }
  `;

const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
	backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
    id
    createdAt
    date
    description
    order
    briefs {
      id
      name
    }
	}
}`;

const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs {
      items {
        id
        name
      }
    }
  }
}`;

const BRIEF_ACCESS_TYPE = `
query getBriefByID($id: ID) {
  brief(id: $id) {
    id
    userTypeAccess
  }
}`;

const mCreateLabel = `mutation createLabel($clientMatterId: String, $name: String) {
  labelCreate(clientMatterId:$clientMatterId, name:$name) {
      id
      name
  }
}`;

const qListClientMatterLabels = `
  query clientMatterById($id: ID) {
    clientMatter (id: $id) {
      id
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

const ARCHIVED_BRIEFS_QUERY = `query getArchivedBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs(isDeleted: true){
      items {
        id
        name
      }
    }
  }
}`;
const defaultColumnIds = [0, 1, 2, 3];
const defaultUserAccessValues = [
  {
    label: "LEGALADMIN",
    value: "LEGALADMIN",
  },
  {
    label: "LAWYER",
    value: "LAWYER",
  },
];

const defaultBriefs = [
  "Variations",
  "Provisional Sum",
  "Budget",
  "Invoices",
  "Progress Photos",
  "Progress of work",
  "Project Location",
  "Site Diary",
  "Prime Cost",
  "Tenders",
  "Certificates",
  "Insurance",
  "Defects List",
  "Development Approvals",
  "Building Permits",
  "Warranties and Certifications",
  "Work Safety Compliance",
  "Contracts",
];

const defaultBriefsWithBackgrounds = ["Builder Details", "Contract Details"];
export async function setDefaultColumnBriefs(backgroundTables, briefs) {
  console.group("setDefaultColumnBriefs");
  console.log(backgroundTables, briefs);

  try {
    const { id, columns } = backgroundTables;

    let invoicePreset;
    let variationPreset;
    let builderDetailsPreset;
    let provisionalSumPreset;
    let contractDetailsPreset;
    let budgetPreset;
    let primeCostPreset;

    console.log("Current columns columns", columns);
    console.log("defaultColumnIds:", defaultColumnIds);

    if (id) {
      let presets = [];
      const commentsColPresets = [];

      for (const item of briefs) {
        console.log("ITEM IS:", item);
        const preset = { id: item.id, name: item.name };
        if (item.name === "Invoices") {
          invoicePreset = { id: item.id, name: item.name };
        }
        if (item.name === "Variations") {
          variationPreset = { id: item.id, name: item.name };
        }
        if (item.name === "Provisional Sum") {
          provisionalSumPreset = { id: item.id, name: item.name };
        }
        if (item.name === "Prime Cost") {
          primeCostPreset = { id: item.id, name: item.name };
        }
        if (item.name === "Budget") {
          budgetPreset = { id: item.id, name: item.name };
        }
        if (item.name === "Builder Details") {
          builderDetailsPreset = { id: item.id, name: item.name };
        }
        if (item.name === "Contract Details") {
          contractDetailsPreset = { id: item.id, name: item.name };
        }

        presets.push(preset);

        if (item.hasCommentAccess) {
          const commentPreset = { ...preset };
          commentsColPresets.push(commentPreset);
        }
      }
      console.log("presets", presets);
      let isDefaultPresent = false;
      let isLabelPresent = false;
      let isInvoicePresent = false;
      let isVariationPresent = false;
      let isProvisionalSumPresent = false;
      let isBuilderDetailsPresent = false;
      let isContractDetailsPresent = false;
      // let isBudgetPresent = false;
      // let isPrimeCostPresent = false;
      let breakDownPresetList;

      // Filters out null columns
      let filteredColumns = [];
      columns.map((obj) => {
        if (obj.headerText === null) return false;

        // If column is not duplicate
        if (!filteredColumns.some((e) => e.headerText === obj.headerText)) {
          // Remove null and undefined presets
          let filteredCol = { ...obj };
          if (filteredCol?.presets) {
            filteredCol.presets = obj.presets.filter(
              (preset) => preset !== null && preset !== undefined
            );
          }

          filteredColumns.push(filteredCol);
        }
      });

      console.log("filteredColumns", filteredColumns);
      let defaultCols = [
        filteredColumns.find((col) => col.headerText === "Date"),
        filteredColumns.find((col) => col.headerText === "Gmail"),
        filteredColumns.find(
          (col) => col.headerText === "Description of Background"
        ),
        filteredColumns.find((col) => col.headerText === "Comments"),
        filteredColumns.find((col) => col.headerText === "Document"),
      ].filter((col) => col !== undefined && col !== null && col);

      // Sorts column with default column containing their default IDs.
      let sortedColumns = [
        ...defaultCols,
        ...filteredColumns
          .filter(
            (col) =>
              !defaultCols.some(
                (defaultCol) => col.headerText === defaultCol.headerText
              )
          )
          .sort((a, b) => a.order - b.order),
      ];

      console.log("sortedColumns", sortedColumns);
      let reorganizedColumns = sortedColumns.map((col, index) => ({
        ...col,
        accessorKey: index.toString(),
        id: index.toString(),
        order: index + 1,
      }));

      console.log("reorganizedColumns", reorganizedColumns);
      const defaultColumns = reorganizedColumns.map((obj) => {
        var tag;
        if (
          obj.headerText === "Date" ||
          obj.headerText === "Gmail" ||
          obj.headerText === "Description of Background" ||
          obj.headerText === "Comments" ||
          obj.headerText === "Document"
        ) {
          isDefaultPresent = true;
        }
        if (obj.headerText === "Label") {
          isLabelPresent = true;
        }
        if (
          obj.headerText === "Claim Status" ||
          obj.headerText === "Payment Status" ||
          obj.headerText === "Claim Amount ($)" ||
          obj.headerText === "Due Date for Claim"
        ) {
          tag = "Invoices";
          isInvoicePresent = true;
        }
        if (
          obj.headerText === "Variation Status" ||
          obj.headerText === "Variation Cost" ||
          obj.headerText === "Variation Cost ($)"
        ) {
          tag = "Variations";
          isVariationPresent = true;
        }
        if (
          obj.headerText === "Provisional Amount" ||
          obj.headerText === "Cost Amount" ||
          obj.headerText === "Description of Work" ||
          obj.headerText === "Paid to Date Amount" ||
          obj.headerText === "Action"
        ) {
          tag = "Provisional Sum";
          isProvisionalSumPresent = true;
        }
        if (
          obj.headerText === "Builder Name" ||
          obj.headerText === "Builder Logo" ||
          obj.headerText === "Builder Contact Name" ||
          obj.headerText === "Builder Contact Email" ||
          obj.headerText === "Builder Contact Number"
        ) {
          tag = "Builder Details";
          isBuilderDetailsPresent = true;
        }

        if (
          obj.headerText === "Start Date" ||
          obj.headerText === "Contract Price" ||
          obj.headerText === "Contract Value"
        ) {
          isContractDetailsPresent = true;
          tag = "Contract Details";
        }

        console.log("obj.presets", obj.preset);
        breakDownPresetList = presets.filter((preset) =>
          preset.name.includes("_")
        );
        console.log("Break Down Preset List:", breakDownPresetList);
        return {
          ...obj,
          presets:
            obj.headerText === "Label"
              ? presets?.length
                ? presets.filter(
                    (preset) =>
                      preset.name !== "Contract Details" &&
                      preset.name !== "Builder Details" &&
                      preset.name !== "Variations" &&
                      preset.name !== "Provisional Sum" &&
                      preset.name !== "Budget" &&
                      preset.name !== "Prime Cost" &&
                      !preset.name.includes("_")
                  )
                : []
              : obj.headerText === "Cost Amount"
              ? [...obj.presets, ...breakDownPresetList]
              : obj.headerText === "Gmail" || obj.headerText === "Email Date"
              ? presets?.length
                ? presets.filter(
                    (preset) =>
                      preset.name !== "Contract Details" &&
                      preset.name !== "Builder Details" &&
                      !preset.name.includes("_")
                  )
                : []
              : isVariationPresent ||
                isInvoicePresent ||
                isProvisionalSumPresent
              ? // isPrimeCostPresent
                obj.presets
              : isBuilderDetailsPresent || isContractDetailsPresent
              ? obj.presets.filter((preset) => preset.name === tag)
              : obj.headerText === "Description of Background" ||
                obj.headerText === "Date"
              ? presets?.length
                ? presets.filter(
                    (preset) =>
                      preset.name !== "Contract Details" &&
                      preset.name !== "Builder Details"
                  )
                : []
              : obj.headerText === "Document"
              ? presets?.length
                ? presets?.filter(
                    (preset) =>
                      preset.name !== "Contract Details" &&
                      !preset.name.includes("_")
                  )
                : []
              : presets,
        };
      });
      console.log("Default Columns:", defaultColumns);

      // console.log("INVOICE PRESET:", invoicePreset);

      let newColumns = [...defaultColumns];

      console.log("isInvoicePresent", isInvoicePresent);
      console.log("isVariationPresent", isVariationPresent);
      console.log("isBuilderDetailsPresent", isBuilderDetailsPresent);
      console.log("isContractDetailsPresent", isContractDetailsPresent);
      console.log("isProvisionalSumPresent", isProvisionalSumPresent);
      // console.log("isBudgetPresent", isBudgetPresent);
      // console.log("isPrimeCostPresent", isPrimeCostPresent);

      if (
        !isDefaultPresent ||
        !isLabelPresent ||
        !isInvoicePresent ||
        !isVariationPresent ||
        !isProvisionalSumPresent ||
        !isBuilderDetailsPresent ||
        !isContractDetailsPresent
        // !isPrimeCostPresent
      ) {
        if (!isDefaultPresent) {
          let newColumnsAdjusted = newColumns.map((col) => ({
            ...col,
            id: (parseInt(col.id) + 5).toString(),
            accessorKey: (parseInt(col.accessorKey) + 5).toString(),
            order: col.order + 5,
          }));

          newColumns = [
            {
              accessorKey: "0",
              id: "0",
              presets: presets.filter(
                (preset) =>
                  preset.name !== "Contract Details" &&
                  preset.name !== "Builder Details"
              ),
              headerText: "Date",
              type: "DATE",
              enabled: true,
              order: 0,
            },
            {
              accessorKey: "1",
              id: "1",
              presets: presets.filter(
                (preset) =>
                  preset.name !== "Contract Details" &&
                  preset.name !== "Builder Details" &&
                  !preset.name.includes("_")
              ),
              headerText: "Gmail",
              type: "READONLY",
              enabled: true,
              order: 1,
            },
            {
              accessorKey: "2",
              id: "2",
              presets: presets.filter(
                (preset) =>
                  preset.name !== "Contract Details" &&
                  preset.name !== "Builder Details" &&
                  !preset.name.includes("_")
              ),
              headerText: "Description of Background",
              type: "TEXTAREA",
              enabled: true,
              order: 2,
            },
            {
              accessorKey: "3",
              id: "3",
              presets: commentsColPresets,
              headerText: "Comments",
              type: "TEXTAREA",
              enabled: true,
              order: 3,
            },
            {
              accessorKey: "4",
              id: "4",
              presets: presets.filter(
                (preset) =>
                  preset.name !== "Contract Details" &&
                  !preset.name.includes("_")
              ),
              headerText: "Document",
              type: "READONLY",
              enabled: true,
              order: 4,
            },
            ...newColumnsAdjusted,
          ];
        }
        if (!isLabelPresent) {
          newColumns = [
            ...newColumns,
            {
              accessorKey: newColumns.length.toString(),
              id: newColumns.length.toString(),
              presets: presets,
              headerText: "Label",
              type: "READONLY",
              enabled: true,
              optionsText: [],
              order: newColumns.length,
            },
          ];
        }
        if (
          !isInvoicePresent &&
          !isVariationPresent &&
          !isProvisionalSumPresent
          // !isPrimeCostPresent
        ) {
          newColumns = [
            ...newColumns,
            {
              accessorKey: newColumns.length.toString(),
              id: newColumns.length.toString(),
              presets: [invoicePreset],
              headerText: "Due Date for Claim",
              type: "DATE",
              enabled: true,
              optionsText: [],
              order: newColumns.length,
            },
            {
              accessorKey: newColumns.length.toString(),
              id: newColumns.length.toString(),
              presets: [invoicePreset],
              headerText: "Claim Status",
              type: "DROPDOWN",
              enabled: true,
              optionsText: ["Claimed", "Unclaimed"],
              order: newColumns.length + 1,
            },
            {
              accessorKey: newColumns.length.toString(),
              id: newColumns.length.toString(),
              presets: [invoicePreset],
              headerText: "Payment Status",
              type: "DROPDOWN",
              enabled: true,
              optionsText: ["Paid", "Unpaid"],
              order: newColumns.length + 2,
            },
            {
              accessorKey: (newColumns.length + 1).toString(),
              id: (newColumns.length + 1).toString(),
              presets: [invoicePreset],
              headerText: "Claim Amount ($)",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 3,
            },
            {
              accessorKey: (newColumns.length + 2).toString(),
              id: (newColumns.length + 2).toString(),
              presets: [variationPreset],
              headerText: "Variation Status",
              type: "DROPDOWN",
              enabled: true,
              optionsText: ["Approved", "For Approval"],
              order: newColumns.length + 4,
            },
            {
              accessorKey: (newColumns.length + 3).toString(),
              id: (newColumns.length + 3).toString(),
              presets: [variationPreset],
              headerText: "Variation Cost ($)",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 5,
            },
            {
              accessorKey: (newColumns.length + 4).toString(),
              id: (newColumns.length + 3).toString(),
              presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
              headerText: "Provisional Amount",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 6,
            },
            {
              accessorKey: (newColumns.length + 5).toString(),
              id: (newColumns.length + 3).toString(),
              presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
              headerText: "Cost Amount",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 7,
            },
            {
              accessorKey: (newColumns.length + 6).toString(),
              id: (newColumns.length + 3).toString(),
              presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
              headerText: "Description of Work",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 8,
            },
            {
              accessorKey: (newColumns.length + 7).toString(),
              id: (newColumns.length + 3).toString(),
              // to add budget preset
              presets: [
                provisionalSumPreset,
                variationPreset,
                budgetPreset,
                primeCostPreset,
              ],
              headerText: "Paid to Date Amount",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 9,
            },
            {
              accessorKey: (newColumns.length + 8).toString(),
              id: (newColumns.length + 3).toString(),
              // to add budget preset
              presets: [
                provisionalSumPreset,
                variationPreset,
                budgetPreset,
                primeCostPreset,
              ],
              headerText: "Action",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 10,
            },
          ];
        }
        if (!isBuilderDetailsPresent) {
          newColumns = [
            ...newColumns,
            {
              accessorKey: newColumns.length.toString(),
              id: newColumns.length.toString(),
              presets: [builderDetailsPreset],
              headerText: "Builder Name",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: newColumns.length,
            },
            {
              accessorKey: (newColumns.length + 1).toString(),
              id: (newColumns.length + 1).toString(),
              presets: [builderDetailsPreset],
              headerText: "Builder Contact Name",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 1,
            },
            {
              accessorKey: (newColumns.length + 2).toString(),
              id: (newColumns.length + 2).toString(),
              presets: [builderDetailsPreset],
              headerText: "Builder Contact Email",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 2,
            },
            {
              accessorKey: (newColumns.length + 3).toString(),
              id: (newColumns.length + 3).toString(),
              presets: [builderDetailsPreset],
              headerText: "Builder Contact Number",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 3,
            },
          ];
        }
        if (!isContractDetailsPresent) {
          newColumns = [
            ...newColumns,
            {
              accessorKey: newColumns.length.toString(),
              id: newColumns.length.toString(),
              presets: [contractDetailsPreset],
              headerText: "Start Date",
              type: "DATE",
              enabled: true,
              optionsText: [],
              order: newColumns.length,
            },
            {
              accessorKey: (newColumns.length + 1).toString(),
              id: (newColumns.length + 1).toString(),
              presets: [contractDetailsPreset],
              headerText: "Contract Price",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 1,
            },
            {
              accessorKey: (newColumns.length + 2).toString(),
              id: (newColumns.length + 2).toString(),
              presets: [contractDetailsPreset],
              headerText: "Contract Value",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: newColumns.length + 2,
            },
          ];
        }
      } else {
        //Filter Columns & set it to default HBA presets
        let filterBriefs = [];
        defaultColumns.map((obj) => {
          if (
            obj.headerText === "Claim Status" ||
            obj.headerText === "Payment Status" ||
            obj.headerText === "Claim Amount ($)" ||
            obj.headerText === "Due Date for Claim"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [invoicePreset],
            });
          }

          if (
            obj.headerText === "Variation Status" ||
            obj.headerText === "Variation Cost" ||
            obj.headerText === "Variation Cost ($)"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [variationPreset],
            });
          }

          if (obj.headerText === "Cost Amount") {
            filterBriefs.push({
              ...obj,
              presets: [
                provisionalSumPreset,
                budgetPreset,
                primeCostPreset,
                ...breakDownPresetList,
              ],
            });
          }

          if (
            obj.headerText === "Provisional Amount" ||
            obj.headerText === "Description of Work"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
            });
          }

          if (
            obj.headerText === "Builder Name" ||
            obj.headerText === "Builder Logo" ||
            obj.headerText === "Builder Contact Name" ||
            obj.headerText === "Builder Contact Email" ||
            obj.headerText === "Builder Contact Number"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [builderDetailsPreset],
            });
          }

          if (
            obj.headerText === "Start Date" ||
            obj.headerText === "Contract Price" ||
            obj.headerText === "Contract Value"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [contractDetailsPreset],
            });
          }

          if (
            obj.headerText === "Paid to Date Amount" ||
            obj.headerText === "Action"
          ) {
            filterBriefs.push({
              ...obj,
              // to add budget preset
              presets: [
                provisionalSumPreset,
                variationPreset,
                budgetPreset,
                primeCostPreset,
              ],
            });
          }

          if (
            obj.headerText !== "Start Date" &&
            obj.headerText !== "Contract Price" &&
            obj.headerText !== "Contract Value" &&
            obj.headerText !== "Claim Status" &&
            obj.headerText !== "Payment Status" &&
            obj.headerText !== "Claim Amount ($)" &&
            obj.headerText !== "Due Date for Claim" &&
            obj.headerText !== "Variation Status" &&
            obj.headerText !== "Variation Cost" &&
            obj.headerText !== "Variation Cost ($)" &&
            obj.headerText !== "Description of Work" &&
            obj.headerText !== "Provisional Amount" &&
            obj.headerText !== "Cost Amount" &&
            obj.headerText !== "Paid to Date Amount" &&
            obj.headerText !== "Action" &&
            obj.headerText !== "Builder Name" &&
            obj.headerText !== "Builder Logo" &&
            obj.headerText !== "Builder Contact Name" &&
            obj.headerText !== "Builder Contact Email" &&
            obj.headerText !== "Builder Contact Number" &&
            obj.headerText !== "Start Date" &&
            obj.headerText !== "Contract Price" &&
            obj.headerText !== "Contract Value"
          ) {
            filterBriefs.push({
              ...obj,
            });
          }
        });
        console.log("filtered Briefs:", filterBriefs);
        newColumns = filterBriefs.map((col) => ({
          ...col,
          presets: col.presets.filter(
            (preset) => preset !== undefined && preset !== null && preset
          ),
        }));
      }
      console.log(id, "new columns:", newColumns);

      const variables = {
        id,
        input: {
          columns: newColumns,
        },
      };

      console.log("VARIABLE IS:", variables);

      const updateBriefTable = await API.graphql({
        query: UPDATE_BACKGROUND_TABLE_MUTATION,
        variables,
      });

      console.log("updateBriefTable", updateBriefTable);
    }
  } catch (error) {
    console.error(error);
    console.groupEnd("setDefaultColumnBriefs");
  }
  console.groupEnd("setDefaultColumnBriefs");
}

export async function createDefaultBriefs(
  clientMatterId,
  currentDate,
  briefsList
) {
  try {
    console.group("createDefaultBackground");
    console.log("CREATING DEFAULT BRIEFS FOR CM_ID", clientMatterId);
    console.log("CURRENT BRIEFSLIST", briefsList);

    let briefs = {};
    let promisesArr = [];
    let currentBriefs = new Map();

    // FETCHES CURRENT BRIEF
    let appendedBriefs = await fetchBriefs(clientMatterId, briefsList);

    if (appendedBriefs) {
      for (let brief of appendedBriefs) {
        currentBriefs.set(brief.name, brief);
      }
    }
    console.log("appendedBriefs", appendedBriefs);

    // Creates Default Briefs and sets Default Access
    for (let defaultBrief of defaultBriefs) {
      if (!currentBriefs.get(defaultBrief)) {
        promisesArr.push(
          createBrief(defaultBrief, clientMatterId, currentDate, briefs).then(
            (response) =>
              Promise.all([
                handleAddUserAccess(
                  defaultUserAccessValues,
                  response?.data?.briefCreate.id
                ),
                handleBriefLabel(clientMatterId, defaultBrief),
              ])
          )
        );
      }
    }
    // Creates Brief, Background, and sets Default Access
    for (let defaultBrief of defaultBriefsWithBackgrounds) {
      if (!currentBriefs.get(defaultBrief)) {
        promisesArr.push(
          createBrief(defaultBrief, clientMatterId, currentDate, briefs).then(
            (response) =>
              Promise.all([
                createBackground(response?.data?.briefCreate),
                handleAddUserAccess(
                  defaultUserAccessValues,
                  response?.data?.briefCreate.id
                ),
                handleBriefLabel(clientMatterId, defaultBrief),
              ])
          )
        );
      }
    }

    if (briefsList) {
      const response = await API.graphql({
        query: qListClientMatterLabels,
        variables: {
          id: clientMatterId,
        },
      });

      let currLabels = response?.data?.clientMatter?.labels?.items;
      console.log("CURRENT LABELS", currLabels);
      for (let currBrief of briefsList) {
        promisesArr.push(
          handleBriefLabel(clientMatterId, currBrief.name, currLabels)
        );
        promisesArr.push(
          handleAddUserAccess(defaultUserAccessValues, currBrief.id, currBrief)
        );
        if (defaultBriefsWithBackgrounds.includes(currBrief.name)) {
          if (!(currBrief?.backgrounds?.items?.length > 0)) {
            console.log("Creating background for existing brief", currBrief);
            promisesArr.push(createBackground(currBrief));
          }
        }
      }
    }

    console.log("promisesArr", promisesArr.length);
    await Promise.all(promisesArr);
    console.log("created briefs", briefs);

    console.groupEnd("createDefaultBackground");
    return briefs;
  } catch (err) {
    console.log(err);
  }
}

// export async function createDefaultBriefs(
//   clientMatterId,
//   currentDate,
//   briefsList
// ) {
//   console.group("createDefaultBackground");
//   console.log("CREATING DEFAULT BRIEFS FOR CM_ID", clientMatterId);
//   console.log("CURRENT BRIEFSLIST", briefsList);

//   let briefs = {};
//   let promisesArr = [];
//   let currentBriefs = new Map();

//   // FETCHES CURRENT BRIEF
//   let appendedBriefs = await fetchBriefs(clientMatterId, briefsList);

//   if (appendedBriefs) {
//     for (let brief of appendedBriefs) {
//       currentBriefs.set(brief.name, brief);
//     }
//   }
//   console.log("appendedBriefs", appendedBriefs);

//   // VARIATIONS
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Variations")) {
//     promisesArr.push(
//       createBrief("Variations", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // PROVISIONAL SUM
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Provisional Sum")) {
//     promisesArr.push(
//       createBrief("Provisional Sum", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // BUDGET
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Budget")) {
//     promisesArr.push(
//       createBrief("Budget", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // INVOICES
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Invoices")) {
//     promisesArr.push(
//       createBrief("Invoices", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // PROGRESS PHOTOS
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Progress Photos")) {
//     promisesArr.push(
//       createBrief("Progress Photos", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // PROGRESS OF WORK
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Progress of work")) {
//     promisesArr.push(
//       createBrief("Progress of work", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // PROJECT LOCATION
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Project Location")) {
//     promisesArr.push(
//       createBrief("Project Location", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }
//   // SITE DIARY
//   if (!currentBriefs.get("Site Diary")) {
//     promisesArr.push(
//       createBrief("Site Diary", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // Prime Cost
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Prime Cost")) {
//     promisesArr.push(
//       createBrief("Prime Cost", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // Tenders
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Tenders")) {
//     promisesArr.push(
//       createBrief("Tenders", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // Certificates
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Certificates")) {
//     promisesArr.push(
//       createBrief("Certificates", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // Insurance
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Insurance")) {
//     promisesArr.push(
//       createBrief("Insurance", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // Defects List
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Defects List")) {
//     promisesArr.push(
//       createBrief("Defects List", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // Development Approvals
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Development Approvals")) {
//     promisesArr.push(
//       createBrief(
//         "Development Approvals",
//         clientMatterId,
//         currentDate,
//         briefs
//       ).then((response) =>
//         handleAddUserAccess(
//           defaultUserAccessValues,
//           response?.data?.briefCreate.id
//         )
//       )
//     );
//   }

//   // Building Permits
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Building Permits")) {
//     promisesArr.push(
//       createBrief("Building Permits", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   // Warranties and Certifications
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Warranties and Certifications")) {
//     promisesArr.push(
//       createBrief(
//         "Warranties and Certifications",
//         clientMatterId,
//         currentDate,
//         briefs
//       ).then((response) =>
//         handleAddUserAccess(
//           defaultUserAccessValues,
//           response?.data?.briefCreate.id
//         )
//       )
//     );
//   }

//   // Work Safety Compliance
//   // Creates Brief and sets Default Access
//   if (!currentBriefs.get("Work Safety Compliance")) {
//     promisesArr.push(
//       createBrief(
//         "Work Safety Compliance",
//         clientMatterId,
//         currentDate,
//         briefs
//       ).then((response) =>
//         handleAddUserAccess(
//           defaultUserAccessValues,
//           response?.data?.briefCreate.id
//         )
//       )
//     );
//   }

//   // BUILDER DETAILS
//   // Creates Brief, Background, and sets Default Access
//   if (!currentBriefs.get("Builder Details")) {
//     promisesArr.push(
//       createBrief("Builder Details", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           Promise.all([
//             createBackground(response?.data?.briefCreate),
//             handleAddUserAccess(
//               defaultUserAccessValues,
//               response?.data?.briefCreate.id
//             ),
//           ])
//       )
//     );
//   }

//   // CONTRACT DETAILS
//   // Creates Brief, Background, and sets Default Access
//   if (!currentBriefs.get("Contract Details")) {
//     promisesArr.push(
//       createBrief("Contract Details", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           Promise.all([
//             createBackground(response?.data?.briefCreate),
//             handleAddUserAccess(
//               defaultUserAccessValues,
//               response?.data?.briefCreate.id
//             ),
//           ])
//       )
//     );
//   }

//   // CONTRACTS
//   if (!currentBriefs.get("Contracts")) {
//     promisesArr.push(
//       createBrief("Contracts", clientMatterId, currentDate, briefs).then(
//         (response) =>
//           handleAddUserAccess(
//             defaultUserAccessValues,
//             response?.data?.briefCreate.id
//           )
//       )
//     );
//   }

//   console.log("promisesArr", promisesArr.length);
//   await Promise.all(promisesArr);
//   console.log("created briefs", briefs);

//   console.groupEnd("createDefaultBackground");
//   return briefs;
// }

export async function createContractBackground(contractsBrief) {
  console.group("createContractBackground");
  const fileList = [
    "Contract Terms And Conditions",
    "Architectural Drawings",
    "Engineer Drawings",
    "Specifications",
    "Builder's Insurance",
  ];

  const contractFiles = [];

  await Promise.all(
    fileList.map(async (file, index) => {
      try {
        const createContractBackground = await createBackground(
          contractsBrief,
          file,
          index + 1
        );

        console.log("createContractBackground", createContractBackground);

        // Store the result in the array
        contractFiles.push({
          id: createContractBackground?.data?.backgroundCreate?.id,
          order: createContractBackground?.data?.backgroundCreate?.order,
        });
      } catch (error) {
        // Handle errors if any
        console.error("Error in GraphQL query:", error);
      }
    })
  );

  var files = contractFiles.sort((a, b) => a?.order - b?.order);

  console.log("Contract Files", files);
  console.groupEnd("createContractBackground");

  return files;
}

async function createBrief(briefName, clientMatterId, date, briefs) {
  const phasesWithBrief = findPhasesWithBrief(DefaultPhases, briefName);
  let response = await API.graphql({
    query: CREATE_BRIEF_MUTATION,
    variables: {
      clientMatterId: clientMatterId,
      // assistantId: assistantId,
      name: briefName,
      date: date,
      order: 0,
      columnIds: defaultColumnIds,
      phase: phasesWithBrief || [],
    },
  });

  briefs[briefName.replace(/ /g, "_")] = response?.data?.briefCreate;
  return response;
}

async function createBackground(brief, description, order) {
  let params = {
    query: mCreateBackground,
    variables: {
      briefs: [
        {
          id: brief?.id,
          name: brief?.name,
        },
      ],
      ...(description && { description }), // Add description property conditionally
      ...(order && { order }), // Add order property conditionally
    },
  };
  return API.graphql(params);
}

async function fetchBriefs(clientMatterId, briefs) {
  try {
    var appendedBriefs;

    if (briefs) {
      let archivedBriefs = await API.graphql({
        query: ARCHIVED_BRIEFS_QUERY,
        variables: { id: clientMatterId },
      });

      appendedBriefs = [
        ...briefs,
        ...(archivedBriefs?.data?.clientMatter?.briefs?.items || []),
      ];
    } else {
      let [activeBriefs, archivedBriefs] = await Promise.all([
        API.graphql({
          query: BRIEFS_QUERY,
          variables: { id: clientMatterId },
        }),
        API.graphql({
          query: ARCHIVED_BRIEFS_QUERY,
          variables: { id: clientMatterId },
        }),
      ]);

      appendedBriefs = [
        ...(activeBriefs?.data?.clientMatter?.briefs?.items || []),
        ...(archivedBriefs?.data?.clientMatter?.briefs?.items || []),
      ];
    }

    return appendedBriefs;
  } catch (error) {
    console.error("Error fetching briefs:", error);
    throw error;
  }
}

function findPhasesWithBrief(defaultPhases, briefName) {
  // Filter the phases where the defaultBriefs array contains the briefName
  const matchingPhases = defaultPhases.filter((phase) =>
    phase.defaultBriefs.includes(briefName)
  );

  // Map the filtered phases to get their names
  return matchingPhases.map((phase) => phase.name);
}

//add user access
async function handleAddUserAccess(e, briefId, brief) {
  if (brief) {
    let currAccess = brief.userTypeAccess;

    if (currAccess?.constructor === Array) {
      return;
    }

    let param = {
      query: BRIEF_ACCESS_TYPE,
      variables: {
        briefId: briefId,
        userType: temp,
      },
    };
    let response = await API.graphql(param);
    currAccess = response?.data?.brief?.userTypeAccess;
  }

  var temp = e.map((item) => item.label);

  const params = {
    query: mAddUserAcces,
    variables: {
      briefId: briefId,
      userType: temp,
    },
  };

  await API.graphql(params).then((ua) => {
    console.log("uaa", ua);
  });
}

async function handleBriefLabel(cmId, briefName, cmLabels) {
  // If label already exists, exit function
  if (cmLabels && cmLabels.find((label) => label.name === briefName)) {
    return;
  }

  console.log("Adding new label to", briefName);
  const createLabelParams = {
    query: mCreateLabel,
    variables: {
      clientMatterId: cmId,
      name: briefName,
    },
  };

  const result = await API.graphql(createLabelParams);
}
