import React, {
	useMemo,
	useCallback,
	useRef,
	useEffect,
	useState,
} from "react";
import { Editor, Transforms, Range, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { Portal } from "./portal-component";
import "../../assets/styles/PopperTab.css";
import Mention from "./mention-element";

const SlateMention = ({
	initialContent,
	handleSaveDesc,
	handleChangeDesc,
	handleDescContent,
	setSaving,
	description,
	date,
	id,
	itemIndex,
	background,
	reducedTabsArray,
	reducedUploadedFilesArray,
	reduceUploadedFilesToArray,
	extractText,
	handleSaveTabElement,
}) => {
	const ref = useRef(null);
	const editableRef = useRef();
	const [target, setTarget] = useState();
	const [index, setIndex] = useState(0);
	const [search, setSearch] = useState("");
	const [holdDescription, setHoldDescription] = useState("");
	const [savingCurrentDesc, setSavingCurrentDesc] = useState(null);
	const backgroundTabFiles = background[itemIndex]?.tabFiles;
	const [focused, setFocused] = useState(false);

	let mentionMenuOpen = document.querySelector(".mention-menu");

	useEffect(() => {
		if (!savingCurrentDesc) {
			return null;
		}

		if (savingCurrentDesc) {
			if (holdDescription === savingCurrentDesc.target.innerText) {
				return null;
			} else {
				handleSaveDesc(savingCurrentDesc, description, date, id, itemIndex);
				setSaving(false);
			}

			setSavingCurrentDesc(null);
		}
	}, [savingCurrentDesc]);

	useEffect(() => {
		if (target && chars?.length > 0) {
			const el = ref.current;
			const domRange = ReactEditor.toDOMRange(editor, target);
			const rect = domRange.getBoundingClientRect();
			el.style.top = `${rect.top + window.pageYOffset + 24}px`;
			el.style.left = `${rect.left + window.pageXOffset}px`;
		}
	}, [chars?.length, editor, index, search, target]);

	const renderElement = useCallback(
		(props) => (
			<Element
				{...props}
				rowId={id}
				itemIndex={itemIndex}
				background={background}
				reducedUploadedFilesArray={reducedUploadedFilesArray}
				reduceUploadedFilesToArray={reduceUploadedFilesToArray}
			/>
		),
		[]
	);

	const editor = useMemo(
		() => withMentions(withReact(withHistory(createEditor()))),
		[]
	);
	
	// useMemo(() => {
	// Transforms.select(editor, { offset: 0, path: [0, 0] });
	// }, []);

	const chars = CHARACTERS.filter((c) =>
		c?.toLowerCase()?.startsWith(search?.toLowerCase())
	)?.slice(0, 10);

	function arraysEqual(a, b) {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a?.length !== b?.length) return false;

		for (let i = 0; i < a.length; i++) {
			if (!b.includes(a[i])) return false;
		}

		return true;
	}

	function findKeyWithExactArray(targetArray, arrayOfObjects) {
		for (const obj of arrayOfObjects) {
			const key = Object.keys(obj)[0];
			const arrayInObj = obj[key];

			const fileIdsEqual = arraysEqual(arrayInObj, targetArray);

			if (fileIdsEqual) {
				return key;
			}
		}

		return null;
	}
	// FIX FOR MMA-2295: Double click to edit when using MacOS touchpad
	// Forces the Editable to be focused and cursor to be placed.
	function handleDescFocus() {

		//focused is to ensure that user can move text cursor after initial click
		if (!focused) {
			Transforms.select(editor, Editor.end(editor, []));
			setFocused(true);
		}
		console.log("Now editing");
	}

	const onKeyDown = useCallback(
		(event) => {
			const { selection } = editor;

			const [start] = Range.edges(selection);
			const wordBefore = Editor.before(editor, start, { unit: "word" });
			const beforeRange =
				wordBefore && Editor?.range(editor, wordBefore, start);
			const beforeText = beforeRange && Editor.string(editor, beforeRange);
			const after = Editor.after(editor, start);
			const afterRange = Editor?.range(editor, start, after);
			const afterText = Editor.string(editor, afterRange);

			function processBackspaceOrDelete(
				event,
				editor,
				selection,
				start,
				backgroundTabFiles,
				reducedTabsArray,
				afterText,
				beforeText
			) {
				// if (mentionMenuOpen) {
				// 	event.preventDefault();
				// 	console.log("Do nothing");
				// 	mentionInputRef.current.focus();
				// } else {
				if (selection && Range.isCollapsed(selection)) {
					const isBackspace = event.key === "Backspace";
					const isDelete = event.key === "Delete";

					if ((isBackspace && !beforeText) || (isDelete && !afterText)) {
						event.preventDefault();

						const relativeText = isBackspace
							? Editor.before(editor, start)
							: Editor.after(editor, start);
						if (relativeText) {
							Transforms.select(
								editor,
								Editor.range(editor, relativeText, relativeText)
							);
						}
					}
				}
				// }
			}

			if (event.key === "Backspace" || event.key === "Delete") {
				processBackspaceOrDelete(
					event,
					editor,
					selection,
					start,
					backgroundTabFiles,
					reducedTabsArray,
					afterText,
					beforeText
				);
			}

			if (target && chars?.length > 0) {
				switch (event.key) {
					case "Tab":
					case "Enter":
						event.preventDefault();
						Transforms.select(editor, target);
						insertMention(editor, chars[index], itemIndex, background);
						setTarget(null);

						handleSaveTabElement(event, description, date, id, itemIndex);
						break;
					case "Escape":
						event.preventDefault();
						setTarget(null);
						break;
				}
			}
		},
		[chars, editor, index, target]
	);

	let localTabCounter = 0;

	const createMentionElement = (() => {
		return (text) => {
			const arrayToFind = backgroundTabFiles?.[localTabCounter]?.["fileIds"];

			const foundKey = findKeyWithExactArray(arrayToFind, reducedTabsArray);

			const mentionPatterns = ["@Tab"];
			for (const pattern of mentionPatterns) {
				if (text === pattern) {
					const character = foundKey ? `Tab(${foundKey})` : `Tab`;
					localTabCounter++;
					return {
						type: "mention",
						character,
						children: [{ text: "" }],
						tabId: `${id}@${localTabCounter - 1}`,
					};
				}
			}

			text = text?.trimStart();
			return { text };
		};
	})();

	const initialChildren = () => {
		if (!initialContent) {
			return [{ text: "" }];
		}

		if (initialContent.startsWith("Enter some text")) {
			return [{ text: "" }];
		}

		const modifiedContent = initialContent?.replace(
			/@Tab\s*\([^)]*\)/gi,
			"@Tab"
		);
		//added to extract html
		extractText(modifiedContent);

		const segments = modifiedContent?.split(/(@Tab)/);
		const children = segments?.map((segment) => createMentionElement(segment));

		//added to remove html elements -kjmf
		if (children[0]?.text !== null || children[0]?.text !== undefined) {
			children[0].text = extractText(children[0]?.text);
			children[0].text = children[0]?.text?.replace(/\&nbsp;/g, "");
		}
		return children;
	};

	return (
		<Slate
			editor={editor}
			initialValue={[
				{
					type: "paragraph",
					children: initialChildren() || [{ text: "" }],
				},
			]}
			// onClick={(event, editor, next) => {
			// 	editor.focus();
			// 	return;
			// }}
			onFocus={(event, editor, next) => {
				editor.focus();
				return;
			}}
			onChange={(e) => {
				const { selection } = editor;

				if (selection && Range.isCollapsed(selection)) {
					const [start] = Range.edges(selection);
					const wordBefore = Editor.before(editor, start, { unit: "word" });
					const before = wordBefore && Editor.before(editor, wordBefore);
					const beforeRange = before && Editor?.range(editor, before, start);
					const beforeText = beforeRange && Editor.string(editor, beforeRange);
					const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
					const after = Editor.after(editor, start);
					const afterRange = Editor?.range(editor, start, after);
					const afterText = Editor.string(editor, afterRange);
					const afterMatch = afterText.match(/^(\s|$)/);

					if (beforeMatch && afterMatch) {
						setTarget(beforeRange);
						setSearch(beforeMatch[1]);
						setIndex(0);
						return;
					}
				}
				setTarget(null);
			}}>
			<div
				ref={editableRef}
				onClick={handleDescFocus}
				// onClick={() => ReactEditor.focus(editor)}
			>
				<Editable
					style={{
						maxWidth: "100%",
						minHeight: "40px",
					}}
					readOnly={mentionMenuOpen}
					renderElement={renderElement}
					onKeyDown={onKeyDown}
					placeholder="Enter some text..."
					className="px-2 py-1 rounded-md cursor-text min-w-3xl max-w-3xl border border-gray-300 bg-white"
					onClick={(e) => {
						setHoldDescription(e.currentTarget.innerText);
					}}
					onInput={(e) => {
						handleChangeDesc(e, index);
					}}
					onFocus={(e) => {
						// Apply focused styles here, including existing styles
						e.currentTarget.style.backgroundColor = "white";
						// e.currentTarget.style.zIndex = "9999"; // Commenting this out as this causes Bug #21 reported on October 19 2023
						e.currentTarget.style.position = "relative";
					}}
					onBlur={(e) => {
						setSavingCurrentDesc(e);
					}}
				/>
			</div>
			{target && chars?.length > 0 && (
				<Portal>
					<div
						ref={ref}
						style={{
							top: "-9999px",
							left: "-9999px",
							position: "absolute",
							zIndex: 1,
							padding: "3px",
							background: "white",
							borderRadius: "4px",
							boxShadow: "0 1px 5px rgba(0,0,0,.2)",
							backgroundColor: "white",
							zIndex: "999",
						}}
						data-cy="mentions-portal">
						{chars.map((char, i) => (
							<div
								key={char}
								style={{
									padding: "1px 3px",
									borderRadius: "3px",
									background: i === index ? "#B4D5FF" : "transparent",
								}}>
								{char}
							</div>
						))}
					</div>
				</Portal>
			)}
		</Slate>
	);
};

const withMentions = (editor) => {
	const { isInline, isVoid, markableVoid } = editor;

	editor.isInline = (element) => {
		return element.type === "mention" ? true : isInline(element);
	};

	editor.isVoid = (element) => {
		return element.type === "mention" ? true : isVoid(element);
	};

	editor.markableVoid = (element) => {
		return element.type === "mention" || markableVoid(element);
	};

	return editor;
};

const insertMention = (editor, character) => {
	// console.log("Character", character);
	const mention = {
		type: "mention",
		character: `${character}`,
		children: [{ text: `${character}` }],
	};

	Transforms.insertNodes(editor, mention);
	Transforms.move(editor);
};

const Element = (props) => {
	const {
		attributes,
		children,
		element,
		rowId,
		itemIndex,
		background,
		reducedUploadedFilesArray,
		inputRef,
	} = props;

	switch (element?.type) {
		case "mention":
			return (
				<Mention
					{...props}
					rowId={rowId}
					mentionElementId={element.tabId}
					itemIndex={itemIndex}
					background={background}
					reducedUploadedFilesArray={reducedUploadedFilesArray}
					inputRef={inputRef}
				/>
			);
		default:
			return <p {...attributes}>{children}</p>;
	}
};

const CHARACTERS = ["Tab"];

export default SlateMention;
