import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>{process.env.REACT_APP_HEADER_TITLE}</title>
      <meta
        name="og:description"
        content={process.env.REACT_APP_HEADER_DESCRIPTION}
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content={process.env.REACT_APP_HEADER_LOGO}
        data-react-helmet="true"
      ></meta>
      <meta
        name="msapplication-TileImage"
        content={process.env.REACT_APP_HEADER_FAVICON}
        data-react-helmet="true"
      ></meta>
      <link
        rel="icon"
        href={process.env.REACT_APP_HEADER_FAVICON}
        sizes="16x16"
        data-react-helmet="true"
      />
      <link
        rel="icon"
        href={process.env.REACT_APP_HEADER_FAVICON}
        sizes="32x32"
        data-react-helmet="true"
      />
      <link
        rel="icon"
        href={process.env.REACT_APP_HEADER_FAVICON}
        sizes="192x192"
        data-react-helmet="true"
      />
      <link
        rel="apple-touch-icon-precomposed"
        href={process.env.REACT_APP_HEADER_FAVICON}
        data-react-helmet="true"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={process.env.REACT_APP_HEADER_FAVICON}
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={process.env.REACT_APP_HEADER_FAVICON}
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={process.env.REACT_APP_HEADER_FAVICON}
      ></link>
    </Helmet>

      <App />

    <Toaster position="top-right" reverseOrder={false} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
