
export const modalStateReducer = (state, action) => {
    switch (action.type) {

      case 'CHANGE_MODAL_OPEN':
        return {
          ...state,
          modalOpen: action.payload
        };

      case 'OPEN_MODAL':
        return {
          ...state,
          modalOpen: true
        };

      case 'CLOSE_MODAL':
        return {
          ...state,
          modalOpen: false,
          modalContent: null,
        };

      case 'CHANGE_MODAL_CONTENT':
        return {
          ...state,
          modalContent: action.payload
          };
      
      default:
        return state;
    }
  
  }