import { API, Auth } from "aws-amplify";
import React, { useEffect, useRef, useState, Fragment } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
// import { useIdleTimer } from "react-idle-timer";
import ReactTooltip from "react-tooltip";
import {
  getBgList,
  getArchivedBgList,
} from "../../shared/graphql/Briefs/queries";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { FaBook, FaTachometerAlt, FaUserCircle } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { BiArrowToTop } from "react-icons/bi";
import { AiFillEye, AiOutlineDownload } from "react-icons/ai";
import { Menu, Tab, Transition } from "@headlessui/react";
import { MdRestore } from "react-icons/md";
import { HiOutlinePlusCircle, HiUserGroup } from "react-icons/hi";
import { AppRoutes } from "../../constants/AppRoutes";
import BlankState from "../dynamic-blankstate";
import BlankStateMobile from "../mobile-blank-state";
import CreateBriefsModal from "./create-brief-modal";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import ToastNotification from "../toast-notification";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import isMSdoc from "../../shared/msFileTypeChecker";
import BlankList from "../../assets/images/RFI_Blank_List.svg";
import Illustration from "../../assets/images/no-data.svg";
import "../../assets/styles/Briefs.css";
import "../../assets/styles/Mobile.css";
import Button from "../../shared/Button";
import AccessControl from "../../shared/accessControl";
import UserPreview from "./UserPreview";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import BriefColumn from "./Sidebar/columns";
import { BriefsColumnContextProvider } from "./context/BriefsColumnContext";
import SettingsBar from "./Sidebar";
import NewColumnTab from "./Sidebar/NewColumn";
import cloneDeep from "lodash/cloneDeep";
import { dateTimeNoSecondsFormatter } from "../../shared/userPreferredDateTime";
import RestoreBriefModal from "./restore-brief-modal";

export default function ArchivedBriefs() {
  const { matter_id } = useParams();

  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [briefName, setBriefName] = useState("");
  const [briefId, setBriefId] = useState("");
  const [validationAlert, setValidationAlert] = useState("");
  const [showColumn, setShowColumn] = useState(false);
  const [showBName, setShowBame] = useState(true);
  const [showDate, setShowDate] = useState(true);
  const [showTag, setShowTag] = useState(true);
  const [userTypes, setUserTypes] = useState([]);

  const [briefs, setBriefs] = useState(null);
  const [showCreateBriefsModal, setshowCreateBriefsModal] = useState(false);
  const [loading, setLoading] = useState(true);

  let history = useHistory();
  const bool = useRef(false);
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringId, setIsHoveringId] = useState(null);
  const [showRestoreBrief, setshowRestoreBrief] = useState(false);
  const [restoreBriefId, setRestoreBriefId] = useState(null);

  const [BriefsCopy, setBriefsCopy] = useState();
  const [showBackgrounds, setShowBackgrounds] = useState(false);
  const [showBriefId, setShowBriefId] = useState(null);
  const [background, setBackground] = useState([]);
  const [users, setUsers] = useState([]);
  const [userShow, setUserShow] = useState(false);
  const [userPreview, setUserPreview] = useState([]);

  const [allowOpenLabelsLibrary, setAllowOpenLabelsLibrary] = useState(false);
  const [allowViewUsersWhoHaveAccess, setAllowViewUsersWhoHaveAccess] =
    useState(false);
  const [allowAddBackground, setAllowAddBackground] = useState(false);
  const [allowDeleteBackground, setAllowDeleteBackground] = useState(false);
  const [allowShowHideColumns, setAllowShowHideColumns] = useState(false);
  const [accessStatus, setAccessStatus] = useState(true);
  const [accessMessage, setAccessMessage] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [indivAccess, setIndivAccess] = useState(0);
  const [passMattersOverview, setPassMattersOverview] = useState(false);

  var activeUserType = localStorage.getItem("userType");
  const defaultColumnIds = [0, 1, 2, 3, 4];
  // const handleBlankStateClick = () => {
  //   // console.log("Blank State Button was clicked!");
  //   setshowCreateRFIModal(true);
  // };

  const hideToast = () => {
    setShowToast(false);
  };

  var moment = require("moment");

  const DEFAULT_USERTYPES_QUERY = `
    query getDefaultUserTypes {
    defaultUserType
  }`;

  const CREATE_BRIEF_MUTATION = `
  mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }
  `;

  const CREATE_LABEL_MUTATION = `
    mutation createLabel($clientMatterId: String, $name: String) {
        labelCreate(clientMatterId:$clientMatterId, name:$name) {
            id
            name
        }
    }
  `;

  const qgetClientMatterById = `
  query clientMatterById($id: ID) {
    clientMatter (id: $id) {
      id
      client {
        name
        id
      }
      matter {
        name
        id
      }
      description
      matterNumber
    }
  }`;

  const UPDATE_BRIEF_NAME_MUTATION = `mutation updateBriefName($id: ID, $name: String) {
    briefUpdate(id: $id, name: $name) {
      id
    }
  }`;

  // const qBriefByName = `
  // query getBriefByName($clientMatterId: ID, $name: String) {
  //   briefByName(clientMatterId: $clientMatterId, name: $name) {
  //     id
  //     labelId
  //   }
  // }
  // `;

  const BACKGROUND_BY_BRIEF_ID_QUERY = `query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
    brief(id: $id) {
      id
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          description
          comments
          date
          createdAt
          order
          files {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
    }
  }`;

  const FILE_DOWNLOAD_LINK_QUERY = `
  query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const USER_ACCESS_QUERY = `
  query companyUsers($companyId: String) {
    company(id: $companyId) {
      name
      users {
        items {
          id
          firstName
          lastName
          profilePicture
          clientMatterAccess(companyId: $companyId) {
            items {
              userType
              clientMatter {
                id
              }
            }
          }
        }
      }
    }
  }
  `;

  const UPDATE_BRIEF_PRESET_MUTATION = `
  mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    briefUpdate(id: $id, columnIds: $columnIds){
      id
    }
  }
`;

  const BACKGROUND_TABLE_QUERY = `
  query getBackgroundTable($clientMatterId: ID!) {
      backgroundTable(clientMatterId: $clientMatterId) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
          presets {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }
  `;

  const BACKGROUND_TABLE_CREATE = `
    mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
      backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
        }
        createdAt
        updatedAt
      }
    }
    `;

  const mCreateActivity = `
    mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
      activityCreate(
        activity: $activity
        briefId: $briefId
        clientMatterId: $clientMatterId
        companyId: $companyId
        previous: $previous
        field: $field
        current: $current
        appModule: $appModule
        rowId: $rowId
      ) {
        id
      }
    }`;

  const UPDATE_BRIEF_TABLE_MUTATION = `
      mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
        backgroundTableUpdate(id: $id, input: $input) {
          id
          columns {
            id
            accessorKey
            headerText
            type
            enabled
            optionsText
            order
            presets {
              id
              name
            }
          }
        }
      }
      `;

  const defaultUserAccessValues = [
    {
      label: "LEGALADMIN",
      value: "LEGALADMIN",
    },
    {
      label: "LAWYER",
      value: "LAWYER",
    },
  ];

  const getUserTypes = async () => {
    try {
      const params = {
        query: DEFAULT_USERTYPES_QUERY,
      };

      const userTypes = await API.graphql(params);
      const defaultUserType = userTypes?.data?.defaultUserType;

      if (defaultUserType) {
        setUserTypes(defaultUserType);
      }
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  const getBriefs = async () => {
    setLoading(true);
    console.log("getBriefs()");
    console.log("matter_id:", matter_id);
    await getArchivedBgList(matter_id).then(async (briefsList) => {
      try {
        console.log("briefsList", briefsList);
        if (briefsList.length === 0) {
          setBriefs([]);
          console.log("briefList is null", briefsList);
        } else {
          console.log("Successfully set BRIEFS", briefsList);
          // MMA-1700 Background: Manage User Access needs to be triggered for the users to see the content of the briefs
          briefsList.map((brief, index) => {
            let briefPermissions = brief.userTypeAccess;
            if (briefPermissions.length === 0) {
              handleAddUserAccess(defaultUserAccessValues, brief.id, brief);
            }
          });

          console.log("Brief List items:", briefsList);

          setBriefs(sortByOrder(briefsList));
          setBriefsCopy(sortByOrder(briefsList));
          setDefaultBriefColumnIds(sortByOrder(briefsList));

          // Filter userTypeAccess based on activeUserType
          // const filteredAccess = briefsList.filter((item) =>
          //   item.userTypeAccess.includes(activeUserType)
          // );

          // if (
          //   filteredAccess?.length === 1 &&
          //   backPage !== "true" &&
          //   filteredAccess.length > 0
          // ) {
          //   console.log("BriefsList", filteredAccess);
          //   const back_id = '0';
          //   filteredAccess.map((brief) => {
          //     history.push(
          //       `${AppRoutes.BACKGROUND}/${back_id}/${matter_id}/${
          //         brief.id
          //       }/?matter_name=${utf8_to_b64(
          //         matter_name
          //       )}&client_name=${utf8_to_b64(client_name)}`
          //     );
          //   });
          // }
        }
      } catch (e) {
        console.error("ERROR at getqwithteams", e);
      }
    });
    // const params = {
    //   query: BRIEFS_QUERY,
    //   variables: {
    //     id: matter_id,
    //     // limit: 50, // for background
    //     // nextToken: null,
    //   },
    // };

    // await API.graphql(params).then((brief) => {
    //   const briefsList = brief?.data?.clientMatter?.briefs?.items;
    //   console.log("briefsList", briefsList);
    //   setBriefs(sortByOrder(briefsList));
    //   setBriefsCopy(sortByOrder(briefsList));
    //   setDefaultBriefColumnIds(sortByOrder(briefsList));
    // });
    setLoading(false);
  };

  async function setDefaultBriefColumnIds(briefs) {
    const savePromises = [];
    const briefIds = [];

    for (const { columnIds, id } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        console.log("columnIds", columnIds);
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);

      console.log("updatedBriefIds", updatedBriefIds);
      const newDefaultColumn = updatedBriefIds.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const newDefaultArrayColumnBriefs = [
        ...new Set([...newDefaultColumn, ...briefIds]),
      ];

      const newDefaultColumnBriefs = Array.from(
        new Set(newDefaultArrayColumnBriefs.map(JSON.stringify)),
        JSON.parse
      );

      if (updatedBriefIds?.length !== 0) {
        const backgroundTable = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        console.log("Background Table for", matter_id, backgroundTable);

        if (backgroundTable?.data?.backgroundTable) {
          await setDefaultColumnBriefs(
            backgroundTable.data.backgroundTable,
            briefs
          );
        } else {
          console.log("Create Background Table for ", matter_id);

          const initialPresets = [];
          for (const item of briefs) {
            const preset = { id: item.id, name: item.name };
            initialPresets.push(preset);
          }

          await API.graphql({
            query: BACKGROUND_TABLE_CREATE,
            variables: {
              clientMatterId: matter_id,
              initialPresets: initialPresets,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function setDefaultColumnBriefs(
    backgroundTables,
    briefs,
    modifiedBriefName
  ) {
    console.group("setDefaultColumnBriefs");
    try {
      const { id, columns } = backgroundTables;

      let invoicePreset;
      let variationPreset;
      let provisionalSumPreset;
      let builderDetailsPreset;
      let contractDetailsPreset;
      let breakdownPreset;
      let primeCostPreset;
      let budgetPreset;

      console.log("Current columns columns", columns);
      console.log("defaultColumnIds:", defaultColumnIds);

      if (id) {
        let presets = [];
        const commentsColPresets = [];

        for (const item of briefs) {
          console.log("ITEM IS:", item);
          const preset = { id: item.id, name: item.name };
          if (item.name == "Invoices") {
            invoicePreset = { id: item.id, name: item.name };
          }
          if (item.name == "Variations") {
            variationPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Provisional Sum") {
            provisionalSumPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Builder Details") {
            builderDetailsPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Prime Cost") {
            primeCostPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Budget") {
            budgetPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Contract Details") {
            contractDetailsPreset = { id: item.id, name: item.name };
          }
          if (item.name == modifiedBriefName) {
            breakdownPreset = { id: item.id, name: item.name };
          }

          presets.push(preset);

          if (item.hasCommentAccess) {
            const commentPreset = { ...preset };
            commentsColPresets.push(commentPreset);
          }
        }

        console.log("presets", presets);
        console.log("breakdownPreset:", breakdownPreset); // Log breakdownPreset

        let isInvoicePresent = false;
        let isVariationPresent = false;
        let isProvisionalSumPresent = false;
        let isBuilderDetailsPresent = false;
        let isContractDetailsPresent = false;
        // let isBudgetPresent = false;
        // let isPrimeCostPresent = false;
        let isBreakdownPresent = false;

        const defaultBriefs = columns.map((obj) => {
          var tag;
          if (
            obj.headerText == "Claim Status" ||
            obj.headerText == "Payment Status" ||
            obj.headerText == "Claim Amount ($)" ||
            obj.headerText == "Due Date for Claim"
          ) {
            tag = "Invoices";
            isInvoicePresent = true;
          }
          if (
            obj.headerText == "Variation Status" ||
            obj.headerText == "Variation Cost" ||
            obj.headerText == "Variation Cost ($)"
          ) {
            tag = "Variations";
            isVariationPresent = true;
          }

          if (
            obj.headerText == "Provisional Amount" ||
            obj.headerText == "Cost Amount" ||
            obj.headerText == "Description of Work" ||
            obj.headerText == "Paid to Date Amount" ||
            obj.headerText == "Action"
          ) {
            tag = "Provisional Sum";
            isProvisionalSumPresent = true;
          }

          if (obj.headerText == "Cost Amount") {
            tag = modifiedBriefName;
            isBreakdownPresent = true;
          }

          if (
            obj.headerText == "Builder Name" ||
            obj.headerText == "Builder Logo" ||
            obj.headerText == "Builder Contact Name" ||
            obj.headerText == "Builder Contact Email" ||
            obj.headerText == "Builder Contact Number"
          ) {
            tag = "Builder Details";
            isBuilderDetailsPresent = true;
          }

          if (
            obj.headerText == "Start Date" ||
            obj.headerText == "Contract Price" ||
            obj.headerText == "Contract Value"
          ) {
            isContractDetailsPresent = true;
            tag = "Contract Details";
          }

          console.log("obj.presets", obj.preset);
          const breakDownPresetList = presets.filter((preset) =>
            preset.name.includes("_")
          );
          console.log("Break Down Preset List:", breakDownPresetList);
          return {
            ...obj,
            presets:
              obj.headerText == "Label"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details" &&
                        preset.name !== "Variations" &&
                        preset.name !== "Provisional Sum" &&
                        preset.name !== "Budget" &&
                        !preset.name.includes("_")
                    )
                  : []
                : obj.headerText === "Cost Amount"
                ? [...obj.presets, ...breakDownPresetList]
                : obj.headerText == "Gmail" || obj.headerText === "Email Date"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details" &&
                        !preset.name.includes("_")
                    )
                  : []
                : isVariationPresent ||
                  isInvoicePresent ||
                  isProvisionalSumPresent
                ? obj.presets
                : isBuilderDetailsPresent || isContractDetailsPresent
                ? obj.presets.filter((preset) => preset.name === tag)
                : obj.headerText === "Description of Background" ||
                  obj.headerText === "Date"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details"
                    )
                  : []
                : obj.headerText === "Document"
                ? presets?.length
                  ? presets?.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        !preset.name.includes("_")
                    )
                  : []
                : presets,
          };
        });
        console.log("Default Briefs:", defaultBriefs);

        let newColumns = [...defaultBriefs];
        // setUpdatedColumns([...defaultBriefs]);
        const breakDownPresetList = presets.filter((preset) =>
          preset.name.includes("_")
        );
        console.log("isInvoicePresent", isInvoicePresent);
        console.log("isVariationPresent", isVariationPresent);
        console.log("isBuilderDetailsPresent", isBuilderDetailsPresent);
        console.log("isContractDetailsPresent", isContractDetailsPresent);
        console.log("isProvisionalSumPresent", isProvisionalSumPresent);
        console.log("isBreakdownPresent", isBreakdownPresent);

        console.log("Default Briefs", defaultBriefs);

        //Filter Columns & set it to default HBA presets
        let filterBriefs = [];
        defaultBriefs.map((obj) => {
          if (
            obj.headerText == "Claim Status" ||
            obj.headerText == "Payment Status" ||
            obj.headerText == "Claim Amount ($)" ||
            obj.headerText == "Due Date for Claim"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [invoicePreset],
            });
          }

          if (
            obj.headerText == "Variation Status" ||
            obj.headerText == "Variation Cost" ||
            obj.headerText == "Variation Cost ($)"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [variationPreset],
            });
          }

          if (obj.headerText == "Cost Amount") {
            filterBriefs.push({
              ...obj,
              presets: [
                provisionalSumPreset,
                budgetPreset,
                primeCostPreset,
                ...breakDownPresetList,
              ],
            });
          }

          if (
            obj.headerText == "Provisional Amount" ||
            obj.headerText == "Description of Work"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
            });
          }

          if (
            obj.headerText == "Builder Name" ||
            obj.headerText == "Builder Logo" ||
            obj.headerText == "Builder Contact Name" ||
            obj.headerText == "Builder Contact Email" ||
            obj.headerText == "Builder Contact Number"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [builderDetailsPreset],
            });
          }

          if (
            obj.headerText == "Start Date" ||
            obj.headerText == "Contract Price" ||
            obj.headerText == "Contract Value"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [contractDetailsPreset],
            });
          }

          if (
            obj.headerText === "Paid to Date Amount" ||
            obj.headerText === "Action"
          ) {
            filterBriefs.push({
              ...obj,
              // to add budget preset
              presets: [
                provisionalSumPreset,
                variationPreset,
                budgetPreset,
                primeCostPreset,
              ],
            });
          }

          if (
            obj.headerText !== "Start Date" &&
            obj.headerText !== "Contract Price" &&
            obj.headerText !== "Contract Value" &&
            obj.headerText !== "Claim Status" &&
            obj.headerText !== "Payment Status" &&
            obj.headerText !== "Claim Amount ($)" &&
            obj.headerText !== "Due Date for Claim" &&
            obj.headerText !== "Variation Status" &&
            obj.headerText !== "Variation Cost" &&
            obj.headerText !== "Variation Cost ($)" &&
            obj.headerText !== "Description of Work" &&
            obj.headerText !== "Provisional Amount" &&
            obj.headerText !== "Cost Amount" &&
            obj.headerText !== "Paid to Date Amount" &&
            obj.headerText !== "Action" &&
            obj.headerText !== "Builder Name" &&
            obj.headerText !== "Builder Logo" &&
            obj.headerText !== "Builder Contact Name" &&
            obj.headerText !== "Builder Contact Email" &&
            obj.headerText !== "Builder Contact Number" &&
            obj.headerText !== "Start Date" &&
            obj.headerText !== "Contract Price" &&
            obj.headerText !== "Contract Value"
          ) {
            filterBriefs.push({
              ...obj,
            });
          }
        });
        console.log("filtered Briefs:", filterBriefs);
        newColumns = filterBriefs;
        // }

        console.log(id, "new columns:", newColumns);

        const variables = {
          id,
          input: {
            columns: newColumns,
          },
        };

        console.log("VARIABLE IS:", variables);

        const updateBriefTable = await API.graphql({
          query: UPDATE_BACKGROUND_TABLE_MUTATION,
          variables,
        });

        console.log("updateBriefTable Columns", newColumns);

        // setUpdatedColumns(newColumns); // remove on parameters
      }
    } catch (error) {
      console.error(error);
      console.groupEnd("setDefaultColumnBriefs");
    }
    console.groupEnd("setDefaultColumnBriefs");
  }

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  useEffect(() => {
    if (briefs === null) {
      getBriefs();
    }
    featureAccessFilters();
    allowViewUsersWhoHaveAccess && getUsers();
  }, [allowViewUsersWhoHaveAccess]);

  useEffect(() => {
    if (userTypes.length === 0) {
      getUserTypes();
    }

    console.log("userTypes:", userTypes);
  }, [userTypes]);
  useEffect(() => {
    fetchTable();
    // Add tracker for when page has been accessed.
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: matter_id,
        activity: `page has been accessed.`,
        appModule: "BRIEFS",
        userId: localStorage.getItem("userId"),
      },
    };
    const addActivity = API.graphql(params).then((result) => {
      console.log("addActivity page access recorded", result);
    });
  }, []);

  const fetchTable = async () => {
    const data = await API.graphql({
      query: BACKGROUND_TABLE_QUERY,
      variables: {
        clientMatterId: matter_id,
      },
    }).then((result) => {
      let columnNames = result?.data?.backgroundTable?.columns?.map(
        (elem) => elem.headerText
      );
      let columnOrder = result?.data?.backgroundTable?.columns?.map((elem) =>
        parseInt(elem.order)
      );
      console.log("BACKGROUND_TABLE_COLUMNS", columnNames);
      console.log(
        "is Label present?",
        columnNames?.indexOf("Label") > -1 ? "YES" : "NO"
      );
      if (result.data.backgroundTable) {
        const tableColumns = result.data.backgroundTable?.columns?.map((c) => {
          if (c.type === "DROPDOWN") {
            return {
              accessorKey: c.id,
              header: c.headerText,
              type: c.type,
              enabled: c.enabled,
              order: c.order,
              presets: c.presets,
              optionsText: c.optionsText ? c.optionsText : [],
            };
          }
          return {
            accessorKey: c.id,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            presets: c.presets,
            order: c.order,
          };
        });

        const backgroundTableId = result.data.backgroundTable?.id;
        setTableId(backgroundTableId);

        if (columnNames.indexOf("Label") > -1) {
          // if (columnOrder[columnNames.indexOf("Label")] > columnOrder[columnNames.indexOf("Document")]) {
          //   switchLabelColumn(tableColumns, result.data.backgroundTable.id);
          // }
        } else {
          addLabelColumn(tableColumns, result.data.backgroundTable.id);
        }
      }
    });
  };

  function setTableId(id) {
    console.log("setTableId", id);
    return localStorage.setItem("background-table-id", id);
  }

  function sortByAccesorKey(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.accessorKey - b.accessorKey);
    } else {
      sort = arr;
    }

    return sort;
  }

  async function addLabelColumn(columns, tableId) {
    var tableColumns;
    var type = "READONLY";
    var formData = {
      headerText: "Label",
    };
    console.group("addLabelColumn()");
    let presetsFromFirst = sortByAccesorKey(columns)[0].presets;
    //const tableId = localStorage.getItem("task-list-table-id");
    const columnsToSet = sortByAccesorKey(columns).map((c) => {
      //delete c.id;
      //delete c.accessorKey;
      c.headerText = c.header;
      delete c.header;
      return c;
    });

    console.log("formData", formData);
    console.log("columnsToSet", columnsToSet);

    const data = await API.graphql({
      query: UPDATE_BACKGROUND_TABLE_MUTATION,
      variables: {
        id: tableId,
        input: {
          columns: [
            ...columnsToSet,
            {
              headerText: formData.headerText,
              accessorKey: columnsToSet.length.toString(),
              type: type.toUpperCase(),
              enabled: true,
              optionsText:
                type === "Dropdown"
                  ? formData.options.map((opt) => opt.content)
                  : [],
              order: columns.length + 1,
              presets: presetsFromFirst,
            },
          ],
        },
      },
    })
      .then((result) => {
        console.log("SUCCESS ADDING COLUMN", result);
        tableColumns = sortByOrder(
          result.data.backgroundTableUpdate.columns
        ).map((c) => {
          if (c.type === "DROPDOWN") {
            return {
              accessorKey: c.id,
              header: c.headerText,
              type: c.type,
              enabled: c.enabled,
              order: c.order,
              presets: c.presets,
              optionsText: c.optionsText ? c.optionsText : [],
            };
          }
          return {
            accessorKey: c.id,
            header: c.headerText,
            type: c.type,
            order: c.order,
            enabled: c.enabled,
            presets: c.presets,
          };
        });

        console.log("tableColumns:", tableColumns);
      })
      .then(() => {
        switchLabelColumn(tableColumns, tableId);
      });
    console.groupEnd();
  }

  async function switchLabelColumn(columns, tableId) {
    console.log("**********switchLabelColumn");

    console.log(columns);

    var newColumns = cloneDeep(columns);
    var newColumnNames = newColumns.map((elem) => elem.header);
    var idxDocument = newColumnNames.indexOf("Document");
    var idxLabel = newColumnNames.indexOf("Label");

    var popped = cloneDeep(newColumns[idxLabel]);
    newColumns.splice(idxLabel, 1);
    newColumns.splice(idxDocument, 0, popped);

    console.log(newColumns);

    const newColumnOrder = newColumns.map((column, index) => {
      column.order = index + 1;
      return column;
    });

    const columnsToSetApi = sortByAccesorKey(newColumnOrder).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    // console.log("columnsToSetApi", columnsToSetApi);

    if (columnsToSetApi.length > 0 && tableId) {
      const data = await API.graphql({
        query: UPDATE_BRIEF_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      }).then((result) => {
        const tableColumns = result?.data?.backgroundTableUpdate?.columns?.map(
          (c) => {
            if (c.type === "DROPDOWN") {
              return {
                accessorKey: c.accessorKey,
                header: c.headerText,
                type: c.type,
                enabled: c.enabled,
                order: c.order,
                presets: c.presets,
                optionsText: c.optionsText ? c.optionsText : [],
              };
            }
            return {
              accessorKey: c.accessorKey,
              header: c.headerText,
              type: c.type,
              order: c.order,
              presets: c.presets,
              enabled: c.enabled,
            };
          }
        );
      });
    }
  }

  const mAddUserAcces = `
    mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
      briefAccessTypeTag(briefId:$briefId, userType:$userType) {
        id
      }
    }
    `;

  //add user acc
  const handleAddUserAccess = async (e, dataId, dataInfo) => {
    console.log("event", e);
    console.log("dataInfo", dataInfo);
    var bId = dataId;
    var temp = [];

    e.map((item) => (temp = [...temp, item.label]));

    const params = {
      query: mAddUserAcces,
      variables: {
        briefId: bId,
        userType: temp,
      },
    };

    await API.graphql(params).then((ua) => {
      console.log("uaa", ua);
    });
  };

  // const handleSaveBrief = async (briefname) => {
  //   console.log("matterid", matter_id);
  //   console.log("briefname", briefname);

  //   let counter = 1;

  //   briefs.map((brief) => {
  //     const brName = brief.name.split("(")[0];

  //     if (brName.trim().toLowerCase() === briefname.trim().toLowerCase()) {
  //       counter++;

  //       console.log(counter, " - ", brief.name);
  //     }
  //   });

  //   if (counter !== 1) {
  //     briefname = briefname + " (" + counter + ")";
  //     //console.log(briefname);
  //   }

  //   // alert(briefname);
  //   console.log("****briefname:", briefname);
  //   const params = {
  //     query: CREATE_BRIEF_MUTATION,
  //     variables: {
  //       clientMatterId: matter_id,
  //       name: briefname,
  //       date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
  //       order: 0,
  //       columnIds: defaultColumnIds,
  //     },
  //   };
  //   console.log("****params:", params);

  //   await API.graphql(params).then(async (brief) => {
  //     const createdBriefId = brief.data.briefCreate.id;

  //     const backgroundTable = await API.graphql({
  //       query: BACKGROUND_TABLE_QUERY,
  //       variables: {
  //         clientMatterId: matter_id,
  //       },
  //     });
  //     console.log("***********backgroundTable:\n", backgroundTable);

  //     if (backgroundTable?.data?.backgroundTable) {
  //       const { id, columns } = backgroundTable?.data?.backgroundTable;

  //       const updatedBriefs = columns.map((obj) => {
  //         if (parseInt(obj?.accessorKey) !== 3 && obj?.presets !== null &&
  //               obj.headerText == "Gmail" ||
  //               obj.headerText == "Label" ||
  //               obj.headerText === "Email Date" ||
  //               obj.headerText === "Description of Background" ||
  //               obj.headerText === "Document" ||
  //               obj.headerText === "Date") {
  //           const presetsToUse = [
  //             ...obj?.presets?.filter((preset) => preset?.id !== createdBriefId),
  //             { id: createdBriefId, name: briefname },
  //           ];

  //           console.log('Created Presets for new Brief:',presetsToUse)

  //           return { ...obj, presets: presetsToUse };
  //         } else {
  //           return obj;
  //         }
  //       });

  //       console.log('Updated Briefs:',updatedBriefs)

  //       const variables = {
  //         id,
  //         input: {
  //           columns: updatedBriefs,
  //         },
  //       };

  //       const updateBriefTable = await API.graphql({
  //         query: UPDATE_BACKGROUND_TABLE_MUTATION,
  //         variables,
  //       });

  //       console.log("updateBriefTable", updateBriefTable);
  //     }

  //     // Add new labls when brief is created
  //     const createLabel = await API.graphql({
  //       query: CREATE_LABEL_MUTATION,
  //       variables: {
  //         clientMatterId: matter_id,
  //         name: briefname,
  //       },
  //     }).then(async (createLabelResponse) => {
  //       console.log("createLabel", createLabelResponse.data.labelCreate.id);
  //       if (createLabelResponse.data == null) {
  //         console.error("Failed to create label");
  //       } else {
  //         if (userTypes.includes(briefname.toUpperCase())) {
  //           console.log("BRIEF NAME", true);
  //           var bId = createdBriefId;
  //           var temp = ["LEGALADMIN", "LAWYER"];
  //           var tempFinal = temp.includes(briefname.toUpperCase())
  //             ? temp
  //             : ["LEGALADMIN", "LAWYER", briefname.toUpperCase()];

  //           const params = {
  //             query: mAddUserAcces,
  //             variables: {
  //               briefId: bId,
  //               userType: tempFinal,
  //             },
  //           };

  //           await API.graphql(params)
  //             .then((ua) => {
  //               console.log("uaa BRIEF", ua);
  //             })
  //             .then(() => {
  //               handleModalClose();
  //               setShowToast(true);
  //               setTimeout(() => {
  //                 setShowToast(false);
  //                 getBriefs();
  //                 const back_id = '0';
  //                 history.push(
  //                   `${
  //                     AppRoutes.BACKGROUND
  //                   }/${back_id}/${matter_id}/${createdBriefId}/?matter_name=${utf8_to_b64(
  //                     matter_name
  //                   )}&client_name=${utf8_to_b64(client_name)}`
  //                 );
  //               }, 3000);
  //             });
  //         } else {
  //           console.log("BRIEF NAME", false);
  //           handleModalClose();
  //           setShowToast(true);
  //           setTimeout(() => {
  //             setShowToast(false);
  //             getBriefs();
  //             const back_id = '0';
  //             history.push(
  //               `${
  //                 AppRoutes.BACKGROUND
  //               }/${back_id}/${matter_id}/${createdBriefId}/?matter_name=${utf8_to_b64(
  //                 matter_name
  //               )}&client_name=${utf8_to_b64(client_name)}`
  //             );
  //           }, 3000);
  //         }
  //       }
  //       console.groupEnd();
  //     });

  //     // MMA-1700 Background: Manage User Access needs to be triggered for the users to see the content of the briefs
  //     handleAddUserAccess(defaultUserAccessValues, createdBriefId, brief.data);
  //   });
  // };

  const handleModalClose = () => {
    setshowCreateBriefsModal(false);
    setshowRestoreBrief(false);
    setRestoreBriefId(null);
  };

  // const mainGrid = {
  //   display: "grid",
  //   gridtemplatecolumn: "1fr auto",
  // };

  const searchText = (val) => {
    console.group("Searching...");

    if (val.length > 0) {
      const filterResult = BriefsCopy.filter((item) => {
        let briefNameSearch = item.name
          .toLowerCase()
          .includes(val.toLowerCase());

        let backgroundDescriptionSearch = item.backgrounds?.items.some((x) => {
          return (
            x.description &&
            x.description.toLowerCase().includes(val.toLowerCase())
          );
        });

        return briefNameSearch || backgroundDescriptionSearch;
      });

      console.log("filterResult", filterResult);
      console.groupEnd();

      setBriefs(sortByOrder(filterResult));
    } else {
      setBriefs([...BriefsCopy]);
    }
  };

  const handleSearchChange = (e) => {
    //console.log("L114" + e.target.value);
    searchText(e.target.value);

    //setSearchTable(e.target.value);
  };

  // const style = {
  //   paddingLeft: "0rem",
  // };

  // const test = utf8_to_b64('0');

  // function visitBrief(id) {
  //   // history.push(`${AppRoutes.BACKGROUND}/${id}`);
  //   const back_id = '0';
  //   history.push(
  //     `${AppRoutes.BACKGROUND}/${back_id}/${matter_id}/${id}/?matter_name=${utf8_to_b64(
  //       matter_name)}&client_name=${utf8_to_b64(client_name)}&back=${test}`
  //   );
  // }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function b64_to_utf8(str) {
    try {
      // Convert base64 to percent-encoded string
      const percentEncodedStr = atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("");

      // Decode percent-encoded string to utf-8
      return decodeURIComponent(percentEncodedStr);
    } catch (e) {
      console.error("Error decoding base64 string", e);
      return null;
    }
  }

  const m_name = getParameterByName("matter_name");
  const c_name = getParameterByName("client_name");
  const matter_name = b64_to_utf8(m_name);
  const client_name = b64_to_utf8(c_name);

  const backPage = getParameterByName("back_page");

  // const handleNameContent = (e, name, id) => {
  //   if (!validationAlert) {
  //     setBriefName(!name ? "" : name);
  //     setBriefId(id);
  //     setValidationAlert("");
  //   } else {
  //     setBriefName("");
  //   }
  // };

  // const handleOnChangeBiefName = (e) => {
  //   setBriefName(e.currentTarget.textContent);
  // };

  // const handleSaveBriefName = (e, name, id) => {
  //   const originalString = briefName.replace(/(<([^>]+)>)/gi, "");
  //   const final = originalString.replace(/\&nbsp;/g, " ");

  //   const updateName = briefs.map((x) => {
  //     if (x.id === id) {
  //       return {
  //         ...x,
  //         name: e.target.innerHTML,
  //       };
  //     }
  //     return x;
  //   });
  //   setBriefs(sortByOrder(updateName));

  //   if (briefName.length <= 0) {
  //     setValidationAlert("Brief Name is required");
  //   } else if (briefName === name) {
  //     setValidationAlert("");
  //     const data = {
  //       id,
  //       name: e.target.innerHTML,
  //     };
  //     const success = updateBriefName(data);
  //     if (success) {
  //       setalertMessage(`Successfully updated ${final} `);
  //       setShowToast(true);

  //       setTimeout(() => {
  //         setShowToast(false);
  //         setalertMessage("");
  //       }, 1000);
  //     }
  //   } else {
  //     setValidationAlert("");
  //     const data = {
  //       id,
  //       name: e.target.innerHTML,
  //     };

  //     const success = updateBriefName(data);
  //     if (success) {
  //       setalertMessage(`Successfully updated ${final} `);
  //       setShowToast(true);
  //       setTimeout(() => {
  //         setShowToast(false);
  //         setalertMessage("");
  //       }, 1000);
  //     }
  //   }
  // };

  async function updateBriefName(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: UPDATE_BRIEF_NAME_MUTATION,
          variables: {
            id: data.id,
            name: data.name,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const formatDisplayDate = (val) => {
    let date = new Date(val);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    // const time = date.toUTCString().split(" ")[4];
    const time = date.toLocaleString("default", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return day + " " + month + " " + year + " " + time;
  };

  const handleColumn = () => {
    if (!showColumn) {
      setShowColumn(true);
    } else {
      setShowColumn(false);
    }
  };

  const handleDetails = () => {
    if (!showDetails) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  };

  const handleChecBName = () => {
    if (showBName) {
      setShowBame(false);
    } else {
      setShowBame(true);
    }
  };

  const handleCheckDate = () => {
    if (showDate) {
      setShowDate(false);
    } else {
      setShowDate(true);
    }
  };

  const handleCheckTag = () => {
    if (showTag) {
      setShowTag(false);
    } else {
      setShowTag(true);
    }
  };

  const hoverRef = React.useRef(null);

  const handleMouseOver = (event) => {
    setIsHovering(true);
    setIsHoveringId(event.target.dataset.info);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setIsHoveringId(null);
  };

  const handleShowRemoveModal = (briefId, briefName) => {
    setshowRestoreBrief(true);
    setRestoreBriefId(briefId);
    setBriefName(briefName);
  };

  const handleRestore = (id) => {
    const mSoftDeleteBrief = `
      mutation softDeleteBriefById($id: ID) {
        briefSoftDeleteOrRestore(id: $id, isDeleted: false)
      }
      `;

    const deletedId = API.graphql({
      query: mSoftDeleteBrief,
      variables: {
        id: id,
      },
    });

    setalertMessage(`Successfully Restored.`);
    setShowToast(true);
    setshowRestoreBrief(false);
    localStorage.setItem("hasRestored", true);
    setTimeout(() => {
      setShowToast(false);
      setRestoreBriefId(null);
      getBriefs();
    }, 2000);
  };

  const checkFormat = (str) => {
    var check = str;
    check = check.replace("%20", " "); //returns my_name
    return check;
  };

  const handleActivity = (item) => {
    var str = "Created";

    const { createdAt, createdBy, updatedAt, updatedBy } = item;

    if (createdBy && createdBy.id !== "") {
      str += ` by ${createdBy.firstName} `;
    }
    if (createdAt) {
      str += ` on ${dateTimeNoSecondsFormatter(item.createdAt)} `;
    }

    if ((updatedBy && updatedBy.id !== "") || updatedAt) {
      str += ` / Updated`;
      if (updatedBy && updatedBy.id !== "") {
        str += ` by ${updatedBy.firstName} `;
      }
      if (updatedAt) {
        str += ` on ${dateTimeNoSecondsFormatter(item.updatedAt)} `;
      }
    }

    // return str?.replace(/\s\s/g, " ");
    return str.trim();
  };

  const [contentHeight, setContentHeight] = useState();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isAllFilesSelectedButton, setIsAllFilesSelectedButton] =
    useState(true);
  const { height, width } = useWindowDimensions();

  function countLines(tag) {
    var divHeight = tag.offsetHeight;
    var lineHeight = parseInt(
      window.getComputedStyle(tag).getPropertyValue("line-height")
    );
    var lines = Math.round(divHeight / lineHeight);
    return lines;
  }

  function handleScrollEvent(e) {
    const top = e.target.scrollTop > 20;
    if (top) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }
  function handleScrollToTop() {
    let d = document.getElementById("mobileContent");
    d.scrollTo(0, 0);
  }

  const getBackgrounds = async (id, state) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });

    if (backgroundOpt.data.brief.backgrounds.items !== null) {
      var result = backgroundOpt.data.brief.backgrounds.items.map(
        ({ id, description, date, createdAt, order, files }) => ({
          createdAt: createdAt,
          id: id,
          description: description,
          date: date,
          order: order,
          files: files,
        })
      );

      setBackground(sortByOrder(result));
      setShowBackgrounds(state);
      setShowBriefId(id);
    }
  };

  const previewAndDownloadFile = async (id) => {
    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  const sortByOrder = (arr) => {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }

    return sort;
  };

  function checkAccess(utArr) {
    var check = utArr.includes(activeUserType);
    return check;
  }

  useEffect(() => {
    let temp = 0;
    if (briefs != null) {
      briefs.map((item) => {
        if (checkAccess(item.userTypeAccess)) {
          temp++;
        }
      });
      if (temp === 0) {
        setAccessMessage(
          "Sorry, There are no backgrounds that can be accessed by your user type. Kindly contact the Owner to grant you access."
        );
      }
      setIndivAccess(temp);
    }

    console.log("useEffect() briefs", briefs);
  }, [briefs]);

  useEffect(() => {
    console.log("INDIV ACCESS", indivAccess);
  }, [indivAccess]);

  const featureAccessFilters = async () => {
    const labelsLibraryAccess = await AccessControl("LABELS");
    const briefAccess = await AccessControl("BRIEFS");
    const briefFeatures = briefAccess?.data?.features;

    if (labelsLibraryAccess.status !== "restrict") {
      setAllowOpenLabelsLibrary(true);
    } else {
      console.log(labelsLibraryAccess.message);
    }

    if (briefAccess.status !== "restrict") {
      setAllowViewUsersWhoHaveAccess(
        briefFeatures.includes("VIEWUSERSWHOHAVEACCESS")
      );
      setAllowAddBackground(briefFeatures.includes("ADDBACKGROUND"));
      setAllowDeleteBackground(briefFeatures.includes("DELETEBACKGROUND"));
      setAllowShowHideColumns(briefFeatures.includes("HIDESHOWCOLUMNS"));
    } else {
      setAccessStatus(false);
      setAccessMessage(briefAccess.message);
      console.log("RESTRICTED: ", briefAccess.status);
      console.log(briefAccess.message);
    }

    var { data, status, message } = await AccessControl("MATTERSOVERVIEW");
    const featuresList = data?.features;
    if (status !== "restrict") {
      setPassMattersOverview(true);
    } else {
      setPassMattersOverview(false);
    }
  };

  async function getUsers() {
    const users = await API.graphql({
      query: USER_ACCESS_QUERY,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    }).then((users) => {
      console.log("users: ", users.data.company.users.items);
      getBriefUsers(matter_id, users.data.company.users.items);
    });
  }

  function getBriefUsers(clientmatterId, users) {
    const filterByClientMatter = users.filter(
      (users) =>
        users.clientMatterAccess &&
        users.clientMatterAccess.items &&
        users.clientMatterAccess.items.some(
          (clientMatterAccess) =>
            clientMatterAccess.clientMatter &&
            clientMatterAccess.clientMatter.id === clientmatterId
        )
    );
    const filterByAccess = filterByClientMatter.filter((user) => {
      user.clientMatterAccess.items = user.clientMatterAccess.items.filter(
        (clientMatterAccess) =>
          clientMatterAccess.clientMatter.id === clientmatterId
      );
      return user.clientMatterAccess.items.length > 0;
    });
    setUsers(filterByAccess);
    console.log("filteredUser", filterByAccess);
  }

  // Function for User Access Profile Pictures

  function briefUserAccess(users, userTypeAccess) {
    return users.filter((users) =>
      users.clientMatterAccess.items.some(
        (clientMatterAccess) =>
          userTypeAccess.includes(clientMatterAccess.userType) ||
          clientMatterAccess.userType === "OWNER"
      )
    );
  }

  function userInitial(user) {
    var assigneeInitials = "";
    if ((user.firstName + user.lastName).toLowerCase() === "john dela cruz") {
      assigneeInitials = "JDC";
    } else {
      assigneeInitials += user.firstName ? user.firstName?.charAt(0) : "";
      assigneeInitials += user.lastName ? user.lastName?.charAt(0) : "";
    }
    return assigneeInitials;
  }

  function handleUserPreview(user, userTypeAccess) {
    const mobileUsers = briefUserAccess(user, userTypeAccess);
    setUserPreview(mobileUsers);
    setUserShow(true);
  }

  const [client, setClient] = useState("");
  const [clientID, setClientID] = useState("");
  const [matter, setMatter] = useState("");
  const [matterID, setMatterID] = useState("");
  const [matterNumber, setMatterNumber] = useState("");

  useEffect(() => {
    getClientMatterById(matter_id);
  }, []);

  const getClientMatterById = async (clientmatter_id) => {
    console.log("ClientMatter ID", clientmatter_id);
    const response = await API.graphql({
      query: qgetClientMatterById,
      variables: {
        id: clientmatter_id,
      },
    }).then((response) => {
      console.log("response from getClientMatter ", response);
      setMatterNumber(response.data.clientMatter.matterNumber);
      setClient(response.data.clientMatter.client.name);
      setClientID(response.data.clientMatter.client.id);
      setMatter(response.data.clientMatter.matter.name);
      setMatterID(response.data.clientMatter.matter.id);
    });
  };

  return (
    <>
      <div
        className={
          "bg-gray-100 p-5 min-h-screen relative flex flex-col min-w-0 break-words sm:min-h-0 sm:mb-6 sm:shadow-lg sm:rounded sm:bg-white contentDiv " +
          (!userShow ? "overflow-show" : "")
        }
      >
        <div className="relative pt-3 sm:p-0 sm:flex-grow sm:flex-1">
          <div className="flex flex-col">
            <div className="sticky top-0 z-10 items-center hidden gap-2 py-4 pl-12 bg-white sm:pl-0 sm:flex">
              <div
                onClick={() => {
                  passMattersOverview
                    ? history.replace(`/matters-overview/${matter_id}`)
                    : history.replace("/dashboard");
                }}
                className="w-8 py-5 cursor-pointer"
              >
                <CgChevronLeft />
              </div>
              <div>
                <p className="flex flex-col">
                  <span className="text-lg text-black font-bold">
                    Archived Background Page
                  </span>
                  <span className=" text-grey-600">
                    {client_name} - {matter_name}
                  </span>
                </p>
                <div className="flex items-center gap-3 mt-2 text-gray-500">
                  <Link to="/dashboard">
                    <div className="flex items-center gap-3">
                      <FaTachometerAlt />
                      <p className="hidden font-semibold sm:block ">
                        Dashboard
                      </p>
                    </div>
                  </Link>
                  <span>/</span>
                  <Link
                    to={`${
                      AppRoutes.FILEBUCKET
                    }/${matter_id}/000/?matter_name=${utf8_to_b64(
                      matter_name
                    )}&client_name=${utf8_to_b64(client_name)}`}
                  >
                    <p className="font-semibold ">File Bucket</p>
                  </Link>
                  <FaBook className="sm:hidden" />
                  <span>/</span>
                  <Link
                    to={`${
                      AppRoutes.BRIEFS
                    }/${matter_id}/?matter_name=${utf8_to_b64(
                      matter
                    )}&client_name=${utf8_to_b64(client)}`}
                  >
                    <p className="font-semibold ">Background</p>
                  </Link>
                  <FaBook className="sm:hidden" />
                  <span>/</span>
                  <div>
                    <span className="hidden px-2 text-black font-bold cursor-pointer sm:block">
                      Archived Background
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="sticky flex items-center justify-end gap-4 sm:hidden">
              <div className="text-right text-gray-700">
                <p className="text-lg font-bold">Background Page</p>
                <p className="text-lg font-bold text-gray-600">
                  {client_name}/{matter_name}
                </p>
              </div>
              <div>
                <div
                  onClick={() => history.replace("/dashboard")}
                  className="flex items-center justify-center w-10 h-10 rounded-full shadow"
                >
                  <CgChevronRight size={24} />
                </div>
              </div>
            </div>
          </div>
          {/* <MobileHeader
            height={height}
            width={width}
            matter_name={matter_name}
            client_name={client_name}
            setContentHeight={setContentHeight}
          /> */}
          {/* <div className="sm:px-0">
            <nav
              aria-label="Breadcrumb"
              style={style}
              className="mb-5 ml-14 sm:mb-0 sm:ml-0 sm:mt-4"
            >
              <ol
                role="list"
                className="flex px-0 items-left sm:space-x-2 lg:px-6 lg:max-w-7xl lg:px-8"
              >
                <li>
                  <Link
                    className="text-xs font-medium text-gray-400 uppercase sm:normal-case sm:text-sm sm:mr-2 sm:text-gray-900"
                    to={`${AppRoutes.DASHBOARD}`}
                  >
                    Dashboard
                  </Link>
                </li>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-5 text-gray-300"
                >
                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                </svg>
                <li className="text-sm">
                  <span className="text-xs font-medium text-gray-700 underline uppercase sm:px-1 sm:flex sm:normal-case sm:text-sm underline-offset-4 sm:no-underline sm:text-gray-500">
                    <AiOutlineFolderOpen className="hidden sm:inline-block" />
                    <span className="hidden sm:inline">&nbsp;&nbsp;</span>
                    Background
                    <span className="hidden font-medium sm:inline">
                      &nbsp;Page
                    </span>{' '}
                  </span>
                </li>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-5 text-gray-300 sm:hidden"
                >
                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                </svg>
                <li className="text-sm sm:hidden">
                  <Link
                    aria-current="page"
                    className="text-xs font-medium text-gray-400 uppercase sm:normal-case sm:text-sm sm:mr-2 sm:text-gray-900"
                    to={`${
                      AppRoutes.FILEBUCKET
                    }/${matter_id}/000/?matter_name=${utf8_to_b64(
                      matter_name
                    )}&client_name=${utf8_to_b64(client_name)}`}
                  >
                    File Bucket
                  </Link>
                </li>
              </ol>
            </nav>
          </div> */}

          <div className=" sm:mt-7 mb-2">
            <div className="flex sm:block">
              <input
                type="search"
                placeholder="Search ..."
                onChange={handleSearchChange}
                className="relative float-right w-full px-3 py-3 pl-5 my-5 text-sm bg-white border-0 rounded shadow outline-none sm:my-0 placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring sm:w-3/12 "
              />
            </div>
          </div>
        </div>

        {loading ? (
          <>
            <div className="w-full p-1 md:auto">
              <div className="flex flex-col p-1 py-5 bg-white border-b rounded-lg sm:block sm:shadow sm:overflow-hidden sm:border-gray-200 sm:mt-5 ">
                <div className="flex">
                  <div className="mb-1 ml-5">
                    <Skeleton className="w-20 md:w-20">
                      <SkeletonTheme />
                    </Skeleton>
                  </div>
                  <div className="mb-1 ml-1">
                    <Skeleton className="w-20 md:w-20">
                      <SkeletonTheme />
                    </Skeleton>
                  </div>
                </div>
                {[...Array(5).keys()].map((data, index) => {
                  return (
                    <div
                      key={`skeleton-loader-${index}`}
                      className="h-20 p-5 mx-5 mb-5 bg-gray-100 border-black rounded-md"
                    >
                      <div className="flex justify-between">
                        <div>
                          <div className="">
                            <Skeleton className="w-60 md:w-60">
                              <SkeletonTheme />
                            </Skeleton>
                          </div>
                          <div className="">
                            <Skeleton className="w-60 md:w-80">
                              <SkeletonTheme />
                            </Skeleton>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="mr-5">
                            <Skeleton
                              width={"40px"}
                              height={"40px"}
                              className="invisible rounded-full md:visible"
                            >
                              <SkeletonTheme />
                            </Skeleton>
                          </div>
                          <div className="flex">
                            <Skeleton
                              width={"20px"}
                              height={"20px"}
                              className="invisible rounded-full md:visible"
                            >
                              <SkeletonTheme />
                            </Skeleton>
                            <Skeleton
                              width={"20px"}
                              height={"20px"}
                              className="rounded-full"
                            >
                              <SkeletonTheme />
                            </Skeleton>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className={"inline"}>
                {showColumn && (
                  <div className="z-50 float-left w-1/3 p-5 border-r-2">
                    <BriefsColumnContextProvider matterId={matter_id}>
                      {/* <SettingsBar open={showColumn} /> */}

                      <SettingsBar briefs={briefs} matterId={matter_id} />
                    </BriefsColumnContextProvider>
                  </div>
                )}
                <div className={showColumn ? "w-2/3 float-right" : ""}>
                  {(accessStatus && indivAccess !== 0) ||
                  activeUserType === "OWNER" ? (
                    <>
                      {briefs === null ||
                      briefs === undefined ||
                      briefs.length === 0 ? (
                        <div>
                          <div
                            className="flex items-center w-full bg-white border-gray-200 rounded-lg sm:flex-none h-42 sm:bg-gray-100 sm:border sm:mb-6 sm:py-1 sm:px-1"
                            style={{
                              height: width > 640 ? "auto" : contentHeight,
                            }}
                          >
                            {width > 640 ? (
                              <BlankState
                                displayText={
                                  "There are no items to show in this view"
                                }
                                noLink={true}
                                // txtLink={"add new Background"}
                                iconDisplay={BlankList}
                                handleClick={() =>
                                  setshowCreateBriefsModal(true)
                                }
                              />
                            ) : (
                              <BlankStateMobile
                                header={
                                  "There are no items to show in this view."
                                }
                                content={
                                  "Any added files in the desktop will appear here"
                                }
                                svg={Illustration}
                              />
                            )}
                          </div>
                        </div>
                      ) : briefs.length === 0 ? (
                        <div className="left-0 sm:p-5 sm:px-5 sm:py-1 sm:mt-5">
                          <div
                            className="flex items-center w-full bg-white border-gray-200 rounded-lg sm:flex-none h-42 sm:bg-gray-100 sm:border sm:mb-6 sm:py-1 sm:px-1"
                            style={{
                              height: width > 640 ? "auto" : contentHeight,
                            }}
                          >
                            {width > 640 ? (
                              <BlankState
                                displayText={
                                  "There are no items to show in this view"
                                }
                                txtLink={"add new Background"}
                                iconDisplay={BlankList}
                                handleClick={() =>
                                  setshowCreateBriefsModal(true)
                                }
                              />
                            ) : (
                              <BlankStateMobile
                                header={
                                  "There are no items to show in this view."
                                }
                                content={
                                  "Any added files in the desktop will appear here"
                                }
                                svg={Illustration}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="flex flex-col w-full py-5 bg-white border-b rounded-lg sm:block sm:shadow sm:overflow-hidden sm:border-gray-200 sm:my-5 sm:p-0"
                          style={{
                            height: width > 640 ? "auto" : contentHeight,
                          }}
                        >
                          <div className="flex items-stretch mx-5 border-b border-gray-200 sm:hidden">
                            <button
                              className={
                                (isAllFilesSelectedButton
                                  ? "border-black"
                                  : "border-white") +
                                " border-b-2 py-2 font-semibold"
                              }
                            >
                              All Files
                            </button>
                            <button
                              className={
                                (!isAllFilesSelectedButton
                                  ? "border-black"
                                  : "border-white") +
                                " ml-5 border-b-2 py-2 font-semibold"
                              }
                            >
                              Brief
                            </button>
                          </div>
                          <div
                            id="mobileContent"
                            onScroll={(e) => handleScrollEvent(e)}
                            className="px-5 overflow-y-auto sm:px-0 h-min"
                            style={{ scrollBehavior: "smooth" }}
                          >
                            {showScrollButton ? (
                              <>
                                <div
                                  className="flex scrollButtonInner"
                                  onClick={() => handleScrollToTop()}
                                >
                                  <BiArrowToTop
                                    style={{
                                      color: "white",
                                      display: "block",
                                      margin: "auto",
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {briefs
                              .filter((item) => !item.name.startsWith("_")) // add this filter when the budget, provisional and variation is ready
                              .map((item) => {
                                const briefUsers = briefUserAccess(
                                  users,
                                  item.userTypeAccess
                                );
                                return (
                                  <div
                                    className={
                                      checkAccess(item.userTypeAccess) ===
                                        true || activeUserType === "OWNER"
                                        ? "w-90 bg-gray-100 rounded-lg border border-gray-200 mt-5 py-3 px-5 sm:py-4 sm:px-4 sm:m-2 hover:border-black"
                                        : "hidden"
                                    }
                                    key={`brief-${item.id}`}
                                    data-info={item.id}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                  >
                                    {/* <button onClick={()=>console.log(item.userTypeAccess)}>Test</button> */}
                                    <div className="flex sm:block">
                                      <div
                                        className="grid flex-auto grid-cols-4 gap-4"
                                        // onClick={() => visitBrief(item.id)}
                                      >
                                        <div
                                          className={`col-span-4 sm:col-span-2 ${
                                            !showBName && `py-2 px-2 mb-2`
                                          } ${!showDate && `py-1 px-1 mb-2`}`}
                                        >
                                          {showBName && (
                                            <>
                                              <div
                                                className="inline-flex w-full"
                                                data-info={item.id}
                                                onMouseOver={handleMouseOver}
                                                onMouseOut={handleMouseOut}
                                              >
                                                <p
                                                  suppressContentEditableWarning={
                                                    true
                                                  }
                                                  style={{
                                                    cursor: "auto",
                                                    outlineColor:
                                                      "rgb(90, 90, 90, 0.5)",
                                                    outlineWidth: "thin",
                                                  }}
                                                  data-info={item.id}
                                                  // onMouseOver={handleMouseOver}
                                                  // onMouseOut={handleMouseOut}
                                                  // onClick={(e) =>
                                                  //   handleNameContent(
                                                  //     e,
                                                  //     item.name,
                                                  //     item.id
                                                  //   )
                                                  // }
                                                  // onClickCapture={(e) =>
                                                  //   e.stopPropagation()
                                                  // }
                                                  contentEditable={false}
                                                  tabIndex="0"
                                                  // onInput={(e) =>
                                                  //   handleOnChangeBiefName(e)
                                                  // }
                                                  // onBlur={(e) =>
                                                  //   handleSaveBriefName(
                                                  //     e,
                                                  //     item.name,
                                                  //     item.id
                                                  //   )
                                                  // }
                                                  className="items-center hidden mb-1 font-bold text-gray-800 sm:inline-flex focus:outline-none dark:text-gray-500"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.name,
                                                  }}
                                                />
                                                <p className="items-center mb-1 font-semibold text-gray-800 sm:hidden line-clamp-1 focus:outline-none dark:text-gray-500">
                                                  {item.name}
                                                </p>
                                                {/* {isHovering &&
                                                isHoveringId === item.id && (
                                                  <div className="hidden sm:block">
                                                    <MdEdit
                                                      className="inline-flex items-center ml-2 mr-1 text-blue-500 hover:text-blue-300"
                                                      onClickCapture={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                    />
                                                  </div>
                                                )} */}
                                              </div>
                                            </>
                                          )}
                                          <div className="flex items-center col-span-4">
                                            <div
                                              className="inline-flex mr-2 sm:hidden"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleUserPreview(
                                                  users,
                                                  item.userTypeAccess
                                                );
                                              }}
                                            >
                                              {/* Added for mobile version */}
                                              <Button
                                                className="relative flex items-center justify-center w-8 h-8 text-xs font-medium text-white rounded-full cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  handleUserPreview(
                                                    users,
                                                    item.userTypeAccess
                                                  );
                                                }}
                                              >
                                                <HiUserGroup />
                                                {/* {`${briefUsers.length}`} */}
                                              </Button>
                                            </div>
                                            {showDate && (
                                              <p
                                                data-info={item.id}
                                                onMouseOver={handleMouseOver}
                                                onMouseOut={handleMouseOut}
                                                tabIndex="0"
                                                className="text-xs text-gray-400 sm:inline focus:outline-none dark:text-gray-100"
                                              >
                                                {allowViewUsersWhoHaveAccess &&
                                                  handleActivity(item)}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex items-center font-bold sm:hidden text">
                                        {/* Added for mobile version */}
                                        <FiChevronRight />
                                      </div>
                                      <div>
                                        {showTag && (
                                          <div className="hidden float-right mr-16 -mt-10 overflow-visible sm:inline-flex">
                                            {briefUsers.map((user, index) => {
                                              const assigneeInitials =
                                                userInitial(user);
                                              var excessUsers = [];
                                              if (width > 1024) {
                                                if (briefUsers.length > 9) {
                                                  excessUsers =
                                                    briefUsers.slice(
                                                      9,
                                                      briefUsers.length
                                                    );
                                                }
                                              } else {
                                                if (briefUsers.length > 4) {
                                                  excessUsers =
                                                    briefUsers.slice(
                                                      4,
                                                      briefUsers.length
                                                    );
                                                }
                                              }
                                              return (
                                                <>
                                                  {/* For screen responsiveness */}
                                                  {width > 1024 ? (
                                                    index < 9 ? (
                                                      <>
                                                        {/* <div
                                                        className="bg-gray-500 flex text-white text-md font-medium w-10 h-10 rounded-full justify-center items-center -mx-0.5 cursor-default ring ring-gray-100 transform hover:-translate-y-2 transition-transform"
                                                        data-tip
                                                        data-for={`${item.id}-${user.id}-tooltip`}
                                                        key={`assignee-${item.id}-${user.id}`}
                                                      >
                                                        {user.profilePicture ===
                                                        null ? (
                                                          <>
                                                            {assigneeInitials.toUpperCase()}
                                                          </>
                                                        ) : (
                                                          <img
                                                            className="w-10 h-10 rounded-full"
                                                            src={
                                                              user.profilePicture
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                      <ReactTooltip
                                                        id={`${item.id}-${user.id}-tooltip`}
                                                        place={"bottom"}
                                                        scrollHide
                                                      >
                                                        {user.firstName +
                                                          " " +
                                                          user.lastName}
                                                      </ReactTooltip> */}
                                                      </>
                                                    ) : index === 9 ? (
                                                      <>
                                                        {/* <Menu
                                                      as="div"
                                                      className="relative inline-block text-left"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      {({ open }) => (
                                                        <>
                                                          <Menu.Button
                                                            className={
                                                              "relative flex bg-gray-400 text-white text-md font-medium w-10 h-10 rounded-full text-md justify-center items-center cursor-pointer ring " +
                                                              (open
                                                                ? "ring-primary"
                                                                : "ring-gray-100")
                                                            }
                                                          >
                                                            <div className="relative flex items-center justify-center w-10 h-10 font-medium text-white bg-gray-400 rounded-full cursor-pointer text-md">
                                                              {`${excessUsers.length}+`}
                                                            </div>
                                                          </Menu.Button>
                                                          <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                          >
                                                            <Menu.Items className="absolute right-0 z-20 my-4 origin-top-right bg-white rounded-md shadow-lg cursor-default w-52 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                              <div className="">
                                                                {excessUsers.map(
                                                                  (
                                                                    excessUsers
                                                                  ) => {
                                                                    return (
                                                                      <Menu.Item>
                                                                        {({
                                                                          active,
                                                                        }) => (
                                                                          <div
                                                                            className={
                                                                              "flex py-2 px-4 items-center " +
                                                                              (active
                                                                                ? "bg-gray-100"
                                                                                : null)
                                                                            }
                                                                          >
                                                                            <div
                                                                              className="flex items-center justify-center flex-none w-8 h-8 mr-2 font-medium text-white bg-gray-500 rounded-full cursor-default text-md "
                                                                              data-tip
                                                                              data-for={`${item.id}-${excessUsers.id}-tooltip`}
                                                                            >
                                                                              {excessUsers.profilePicture ===
                                                                              null ? (
                                                                                <>
                                                                                  {assigneeInitials.toUpperCase()}
                                                                                </>
                                                                              ) : (
                                                                                <img
                                                                                  className="w-8 h-8 rounded-full"
                                                                                  src={
                                                                                    excessUsers.profilePicture
                                                                                  }
                                                                                />
                                                                              )}
                                                                            </div>
                                                                            <span className="font-base">{`${excessUsers.firstName} ${excessUsers.lastName}`}</span>
                                                                          </div>
                                                                        )}
                                                                      </Menu.Item>
                                                                    );
                                                                  }
                                                                )}
                                                              </div>
                                                            </Menu.Items>
                                                          </Transition>
                                                        </>
                                                      )}
                                                    </Menu> */}
                                                      </>
                                                    ) : null
                                                  ) : // Screen <= 1024
                                                  index < 4 ? (
                                                    <>
                                                      {/* <div
                                                      className="bg-gray-500 flex text-white text-md font-medium w-10 h-10 rounded-full justify-center items-center -mx-0.5 cursor-default ring ring-gray-100 transform hover:-translate-y-2 transition-transform"
                                                      data-tip
                                                      data-for={`${item.id}-${user.id}-tooltip`}
                                                    >
                                                      {user.profilePicture ===
                                                      null ? (
                                                        <>
                                                          {assigneeInitials.toUpperCase()}
                                                        </>
                                                      ) : (
                                                        <img
                                                          className="w-10 h-10 rounded-full"
                                                          src={
                                                            user.profilePicture
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                    <ReactTooltip
                                                      id={`${item.id}-${user.id}-tooltip`}
                                                      place={"left"}
                                                      scrollHide
                                                    >
                                                      {user.firstName +
                                                        " " +
                                                        user.lastName}
                                                    </ReactTooltip> */}
                                                    </>
                                                  ) : index === 4 ? (
                                                    <>
                                                      {/* <Menu
                                                    as="div"
                                                    className="relative inline-block text-left"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                    }}
                                                  >
                                                    {({ open }) => (
                                                      <>
                                                        <Menu.Button
                                                          className={
                                                            "relative flex bg-gray-400 text-white text-md font-medium w-10 h-10 rounded-full text-md justify-center items-center cursor-pointer ring " +
                                                            (open
                                                              ? "ring-primary"
                                                              : "ring-gray-100")
                                                          }
                                                        >
                                                          <div className="relative flex items-center justify-center w-10 h-10 font-medium text-white bg-gray-400 rounded-full cursor-pointer text-md">
                                                            {`${excessUsers.length}+`}
                                                          </div>
                                                        </Menu.Button>
                                                        <Transition
                                                          as={Fragment}
                                                          enter="transition ease-out duration-100"
                                                          enterFrom="transform opacity-0 scale-95"
                                                          enterTo="transform opacity-100 scale-100"
                                                          leave="transition ease-in duration-75"
                                                          leaveFrom="transform opacity-100 scale-100"
                                                          leaveTo="transform opacity-0 scale-95"
                                                        >
                                                          <Menu.Items className="absolute right-0 z-20 mt-2 origin-top-right bg-white rounded-md shadow-lg cursor-default w-52 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="">
                                                              {excessUsers.map(
                                                                (
                                                                  excessUsers
                                                                ) => {
                                                                  return (
                                                                    <Menu.Item>
                                                                      {({
                                                                        active,
                                                                      }) => (
                                                                        <div
                                                                          className={
                                                                            "flex py-2 px-6 items-center " +
                                                                            (active
                                                                              ? "bg-gray-100"
                                                                              : null)
                                                                          }
                                                                        >
                                                                          <div
                                                                            className="flex items-center justify-center flex-none w-8 h-8 mr-2 font-medium text-white bg-gray-500 rounded-full cursor-default text-md "
                                                                            data-tip
                                                                            data-for={`${item.id}-${excessUsers.id}-tooltip`}
                                                                          >
                                                                            {excessUsers.profilePicture ===
                                                                            null ? (
                                                                              <>
                                                                                {assigneeInitials.toUpperCase()}
                                                                              </>
                                                                            ) : (
                                                                              <img
                                                                                className="w-8 h-8 rounded-full"
                                                                                src={
                                                                                  excessUsers.profilePicture
                                                                                }
                                                                              />
                                                                            )}
                                                                          </div>
                                                                          <span className="font-base">{`${excessUsers.firstName} ${excessUsers.lastName}`}</span>
                                                                        </div>
                                                                      )}
                                                                    </Menu.Item>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          </Menu.Items>
                                                        </Transition>
                                                      </>
                                                    )}
                                                  </Menu> */}
                                                    </>
                                                  ) : null}
                                                </>
                                              );
                                            })}
                                          </div>
                                        )}

                                        <div
                                          className={
                                            width > 640
                                              ? "sm:inline-flex float-right -mt-8 ml-4"
                                              : "sm:inline-flex float-right mt-4 ml-4"
                                          }
                                        >
                                          {allowDeleteBackground && (
                                            <Button
                                              onClick={(e) =>
                                                handleShowRemoveModal(
                                                  item.id,
                                                  item.name
                                                )
                                              }
                                              variant={"secondary-l"}
                                              className="font-semibold px-2 flex gap-1"
                                            >
                                              <p>Restore Brief</p>
                                              <MdRestore
                                                size={20}
                                                className="inline-flex items-center float-right ml-auto text-gray-500 outline-none text-md hover:text-gray-700 focus:outline-none"
                                              />
                                            </Button>
                                          )}

                                          {/* {showBackgrounds &&
                                        showBriefId === item.id ? (
                                          <BsFillCaretUpFill
                                            className="inline-flex items-center float-right ml-auto text-gray-500 outline-none text-md hover:text-gray-900 focus:outline-none"
                                            onClick={(e) =>
                                              getBackgrounds(item.id, false)
                                            }
                                          />
                                        ) : (
                                          <BsFillCaretDownFill
                                            className="inline-flex items-center float-right ml-auto text-gray-500 outline-none text-md hover:text-gray-900 focus:outline-none"
                                            onClick={(e) =>
                                              getBackgrounds(item.id, true)
                                            }
                                          />
                                        )} */}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {showBackgrounds &&
                                      showBriefId === item.id ? (
                                        <>
                                          <table className="min-w-full mt-4 text-xs divide-y divide-gray-200 table-auto">
                                            <thead className="z-10 font-black text-white bg-gray-800">
                                              <tr>
                                                <th className="w-5 px-1 py-2 text-center whitespace-nowrap">
                                                  Item No.
                                                </th>
                                                <th className="w-8 px-1 py-2 text-center whitespace-nowrap">
                                                  Date &nbsp;
                                                </th>
                                                <th className="w-auto px-1 py-2 text-center whitespace-nowrap">
                                                  Description of Background
                                                </th>
                                                <th className="w-auto px-1 py-2 text-center whitespace-nowrap">
                                                  Document
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                              {background.length > 0 ? (
                                                background
                                                  .slice(0, 10)
                                                  .map((item, index) => {
                                                    return (
                                                      <tr
                                                        index={index}
                                                        key={`bg-row-${item.id}-${index}`}
                                                      >
                                                        <td
                                                          className="w-8 px-1 py-3 text-center align-top"
                                                          style={{
                                                            minWidth: "83.72px",
                                                          }}
                                                        >
                                                          {index + 1}
                                                        </td>

                                                        <td
                                                          className="w-auto max-w-xs px-1 py-3 align-top"
                                                          style={{
                                                            minWidth:
                                                              "161.95px",
                                                          }}
                                                        >
                                                          <div>
                                                            {item.date !==
                                                              null &&
                                                            item.date !==
                                                              "null" &&
                                                            item.date !== ""
                                                              ? moment(
                                                                  item.date
                                                                ).format(
                                                                  "DD MMM YYYY"
                                                                )
                                                              : null}
                                                          </div>
                                                        </td>

                                                        <td className="w-full px-1 py-1 align-top place-items-center">
                                                          <div
                                                            className="w-full p-2 rounded-sm font-poppins"
                                                            style={{
                                                              cursor: "auto",
                                                              outlineColor:
                                                                "rgb(204, 204, 204, 0.5)",
                                                              outlineWidth:
                                                                "thin",
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                item.description,
                                                            }}
                                                          ></div>
                                                        </td>

                                                        <td
                                                          className="w-full px-1 py-1 text-sm text-gray-500 align-top whitespace-nowrap"
                                                          style={{
                                                            minWidth: "340px",
                                                          }}
                                                        >
                                                          {item.files.items ===
                                                            null ||
                                                          item.files.items
                                                            .length === 0 ? (
                                                            <>
                                                              {/** Add something here */}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div>
                                                                <div
                                                                  style={{
                                                                    overflow:
                                                                      "auto",
                                                                  }}
                                                                >
                                                                  <ReactTooltip
                                                                    id="attachName"
                                                                    type="dark"
                                                                    place="bottom"
                                                                    effect="solid"
                                                                  />

                                                                  {item.files.items.map(
                                                                    (
                                                                      items,
                                                                      index
                                                                    ) =>
                                                                      items &&
                                                                      items.length !==
                                                                        0 && (
                                                                        <span
                                                                          data-tip={
                                                                            items.name
                                                                          }
                                                                          key={`attachment-${index}`}
                                                                          data-for="attachName"
                                                                        >
                                                                          <p>
                                                                            {items.name !==
                                                                              null &&
                                                                              items.name !==
                                                                                "" && (
                                                                                <span
                                                                                  className="text-blue-500 underline align-middle cursor-pointer hover:text-blue-800 hover:underline"
                                                                                  onClick={() =>
                                                                                    previewAndDownloadFile(
                                                                                      items.id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  {items.name.substring(
                                                                                    0,
                                                                                    25
                                                                                  )}
                                                                                </span>
                                                                              )}
                                                                            &nbsp;
                                                                            <AiOutlineDownload
                                                                              className="inline-block mx-1 text-2xl text-blue-400 cursor-pointer"
                                                                              onClick={() =>
                                                                                previewAndDownloadFile(
                                                                                  items.id
                                                                                )
                                                                              }
                                                                            />
                                                                          </p>
                                                                        </span>
                                                                      )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </td>
                                                      </tr>
                                                    );
                                                  })
                                              ) : (
                                                <>
                                                  <tr>
                                                    <td
                                                      colspan="4"
                                                      className="p-2 text-center"
                                                    >
                                                      No backgrounds available
                                                    </td>
                                                  </tr>
                                                </>
                                              )}
                                            </tbody>
                                          </table>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="flex items-center w-full bg-white border-gray-200 rounded-lg sm:flex-none h-42 sm:bg-gray-100 sm:border sm:mb-6 sm:py-1 sm:px-1"
                        style={{ height: width > 640 ? "auto" : contentHeight }}
                      >
                        {width > 640 ? (
                          <BlankState
                            displayText={accessMessage}
                            noLink={true}
                            //txtLink={"add new Background"}
                            iconDisplay={BlankList}
                            handleClick={() => setshowCreateBriefsModal(true)}
                          />
                        ) : (
                          <BlankStateMobile
                            header={accessMessage}
                            svg={Illustration}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {userShow && (
          <UserPreview
            userShow={userShow}
            setUserShow={setUserShow}
            userPreview={userPreview}
            setUserPreview={setUserPreview}
            userInitial={userInitial}
          />
        )}
      </div>

      {/* {showCreateBriefsModal && (
        <CreateBriefsModal
          handleSave={handleSaveBrief}
          handleModalClose={handleModalClose}
        />
      )} */}
      {showToast && (
        <ToastNotification title={alertMessage} hideToast={hideToast} />
      )}
      {showSessionTimeout && <SessionTimeout />}

      {showRestoreBrief && (
        <RestoreBriefModal
          briefName={briefName}
          handleSave={handleRestore}
          handleModalClose={handleModalClose}
          restoreBriefId={restoreBriefId}
        />
      )}
    </>
  );
}
