import React, { useEffect, forwardRef, useRef, useState } from "react";
import config from "../../aws-exports";
import { API, Storage, a, input } from "aws-amplify";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {
  timezoneFormatter,
  datePickerDateFormatter,
} from "../../shared/userPreferredDateTime";
import "./onboarding.css";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import _ from "lodash";

const choicesContainerStyle = {
  list: "flex flex-col gap-3 max-w-xl",
  listHorizontal: "flex flex-wrap gap-3 gap-y-5 max-w-xl",
  discombobulated: "flex flex-row gap-2 flex-wrap justify-center max-w-5xl",
  grid: "flex flex-wrap justify-center gap-3 mx-auto",
  upload: "",
  scale: "",
  none: "",
};

const Choices = ({ children, className, style, preset, action }) => (
  <>
    {style === "discombobulated" && (
      <span
        className="mb-6 mx-auto text-center text-black font-normal cursor-pointer hover:text-green-600"
        onClick={action}
      >
        Select All
      </span>
    )}

    {style === "grid" && preset === "multiple" && (
      <span className="mb-6 text-center text-black font-normal">
        Select all that apply
      </span>
    )}

    <div
      className={`${choicesContainerStyle[style]} ${className || ""}`}
      style={{
        maxWidth: style == "grid" ? "60rem" : "",
      }}
    >
      {children}
    </div>
  </>
);

/**
 * @param {string} style - Determines the class name to be used
 * @param {string} type - Determine what type of element to render
 * @param {string} sub - The subtext of the item
 * @param {string} value - The answer of the item
 * @param {boolean} active - If the item is active, it will be highlighted
 * @param {object} linearScale - The linear scale object
 * @param {function} action - The onClick function of the item
 * @param {string} className - The additional class name of the item
 * @param {string} preset - The preset of the item (multiple, single, etc.)
 * @param {string} children - The optionText of the item
 * @returns {JSX.Element}
 */
const Item = ({
  tempStore,
  children,
  className,
  action,
  style,
  preset,
  type,
  sub,
  active,
  value,
  linearScale,
  formData,
}) => {
  return (
    <>
      {typeFactory("option", type, {
        tempStore,
        children,
        className,
        action,
        style,
        preset,
        type,
        sub,
        active,
        value,
        linearScale,
        formData,
      })}
    </>
  );
};

const Corequisite = ({
  active,
  children,
  style,
  action,
  value,
  type,
  configuration,
}) => {
  return (
    <React.Fragment key={style + children}>
      {active &&
        typeFactory("corequisite", type, {
          children,
          action,
          style,
          type,
          active,
          value,
          configuration,
        })}
    </React.Fragment>
  );
};

Choices.Corequisite = Corequisite;
Choices.Item = Item;

export default Choices;

//=======================================================
// Input Types Components
//=======================================================

const typeFactory = (optionType, type, props) => {
  const inputTypes = {
    button: <ButtonChoice props={props} />,
    text: <TextInput optionType={optionType} props={props} />,
    longText: <LongText optionType={optionType} props={props} />,
    "linear-scale": <LinearScale props={props} />,
    uploadBox: <UploadBox props={props} />,
    datepicker: <PickDater props={props} />,
    separateDatepicker: <SeparatePickDater props={props} />,
    summary: <Table props={props} />,
  };

  return inputTypes[type];
};

function ButtonChoice({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
  } = props;

  const choicesItemStyle = {
    list: `px-4 py-2.5 text-base font-semibold rounded-lg text-white max-w-xl
      ${
        active
          ? "bg-green-800"
          : "text-green-800 bg-green-50 hover:bg-green-800 hover:text-white"
      }`,

    grid: `${
      type !== "text" && type !== "longText"
        ? "w-56 h-52 flex flex-col rounded-2xl gap-3 items-center justify-center hover:bg-green-100 " +
          (active ? "bg-green-100" : "bg-gray-100")
        : "w-56 h-52 rounded-2xl items-center justify-center"
    }`,

    discombobulated: `px-4 py-2.5 bg-green-50 rounded items-center gap-2.5 inline-flex text-black 
    ${
      active
        ? "bg-green-700 text-white"
        : "hover:bg-green-700 hover:text-white text-black"
    }`,
    listHorizontal: `px-4 py-2.5 text-base w-full font-semibold rounded-lg text-white max-w-xl
    ${
      active
        ? "bg-green-800"
        : "text-green-800 bg-green-50 hover:bg-green-800 hover:text-white"
    }`,
  };

  return (
    <button
      className={`${choicesItemStyle[style]} ${className || ""}`}
      onClick={action}
    >
      {style === "grid" ? (
        <div
          className="flex-col items-center gap-2 flex"
          style={{ pointerEvents: "none" }}
        >
          <span className="text-center text-gray-900 text-lg font-semibold">
            {children}
          </span>
          {sub && (
            <span className="text-center text-gray-500 text-sm font-normal">
              {sub}
            </span>
          )}
        </div>
      ) : style === "discombobulated" ? (
        <span
          className="text-base font-normal"
          style={{ pointerEvents: "none" }}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </button>
  );
}

function TextInput({ optionType, props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
  } = props;

  return (
    <div
      className={
        "flex flex-col gap-2 " + (style === "listHorizontal" ? "flex-grow" : "")
      }
    >
      {optionType === "corequisite" ? (
        <span className="text-slate-700 text-sm">{children}</span>
      ) : (
        <span className="text-slate-700 text-base">{children}</span>
      )}
      <input
        className={`px-4 py-2.5 text-base rounded-lg border`}
        placeholder="Enter a description..."
        type="text"
        onChange={action}
        value={value || ""}
      />
    </div>
  );
}

function LongText({ optionType, props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
  } = props;

  return (
    <div className="flex flex-col gap-2">
      {optionType === "corequisite" ? (
        <span className="text-slate-700 text-sm">{children}</span>
      ) : (
        <span className="text-slate-700 text-base">{children}</span>
      )}
      <textarea
        className={
          "px-4 py-2.5 text-base rounded-lg border resize-none " +
          (style === "grid" ? "h-full w-full" : "w-full h-36")
        }
        placeholder="Enter a description..."
        type="text"
        onChange={action}
        value={value || ""}
      />
    </div>
  );
}

function LinearScale({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
  } = props;

  const sliderLabel = [
    {
      mainText: "Very Little Impact",
      subText: "My work was hardly affected",
    },
    {
      mainText: "Minor Impact",
      subText:
        "There was a slight change, but my work remained mostly unaffected",
    },
    {
      mainText: "Moderate Impact",
      subText:
        "The changes had a noticeable impact on my work, but I could adapt",
    },
    {
      mainText: "Substantial Impact",
      subText:
        "The changes significantly influenced my work and required adjustments",
    },
    {
      mainText: "Significant Impact",
      subText:
        "The changes had a major impact on my work, leading to significant adjustments",
    },
  ];

  const urgency = linearScale.urgency;
  const minRange = linearScale?.choice.minRange;
  const maxRange = linearScale?.choice.maxRange;
  /**
   * Calculates the position of the selector, tooltip, and progress bar
   * @param {number} urgencyRange - The urgency range
   * @returns {number} - Returns the position of the selector, tooltip, and progress bar
   */

  const calculatePos = (urgencyRange) => {
    return Number(((urgencyRange - 1) * 100) / (maxRange - minRange));
  };
  const selectorStyle = {
    left: `calc(${calculatePos(urgency)}% + (${
      -4 - calculatePos(urgency) * 0.25
    }px))`,
  };
  const tooltipStyle = {
    left: `calc(${calculatePos(urgency)}% + (${
      7 - calculatePos(urgency) * 0.25
    }px))`,
  };
  const progressStyle = {
    width: `calc(${calculatePos(urgency)}% + (${
      2 - calculatePos(urgency) * 0.25
    }px))`,
  };

  return (
    <div className="flex flex-col items-center gap-7 w-full">
      <div className="flex-none w-full flex flex-col gap-2 relative">
        <div
          style={selectorStyle}
          className="w-9 h-9 px-3 py-2 bg-gray-900 rounded-lg flex-col inline-flex justify-center absolute bottom-7"
        >
          <div className="text-center text-white text-xs font-semibold">
            {linearScale.urgency}
          </div>
        </div>
        <div
          style={tooltipStyle}
          className="urgencyTooltip w-3.5 h-3.5 bg-gray-900 rounded-sm absolute bottom-6"
        ></div>
        <input
          className="urgencySlider"
          type="range"
          value={linearScale.urgency}
          min={minRange}
          max={maxRange}
          step="1"
          onChange={action}
        />
        <div style={progressStyle} className="urgencyProgress"></div>
      </div>
      <div
        className="grid justify-items-center gap-5 text-center"
        style={{
          gridTemplateColumns: `repeat(${
            maxRange - (minRange - 1)
          }, minmax(0, 1fr))`,
          width: "119%",
        }}
      >
        {sliderLabel.map((label, index) => (
          <div>
            <p className=" text-lg font-semibold">{label.mainText}</p>
            <span className=" text-gray-500">{label.subText}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function UploadBox({ props }) {
  const {
    tempStore,
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    uploadBox,
  } = props;

  const [file, setFile] = useState([]);
  const [answerFile, setAnswerFile] = useState({});
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState([]);

  useEffect(() => {
    if (value === false || value === null || value === undefined) {
      setAnswerFile({});
      return;
    }
    console.log("VALUE ", value);
    setAnswerFile(value);
    console.log("Upload Value", value);
  }, [value]);

  const handleUpload = async () => {
    console.log(file);
    const keyArray = await handleUploadToS3(file);

    // action({
    //   inputType: 'uploadBox',
    //   uploadAnswer: keyArray
    // });
    const answerFileLength = Object.keys(answerFile).length;
    const newFileObject = keyArray.reduce(
      (acc, curr, index) => (
        (acc[`file_${answerFileLength + index + 1}`] = curr), acc
      ),
      {}
    );
    action({ ...answerFile, ...newFileObject });
  };

  const handleUploadToS3 = async (file) => {
    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: config.aws_user_company_assets_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    if (file.length === 0) {
      return;
    }

    const progressArray = Array(file.length).fill(0);
    setUploadPercent(progressArray);

    let fileLinks = [];

    for (let i = 0; i < file.length; i++) {
      let fileItem = file[i];
      console.log("File Name: ", fileItem.name);
      console.log("File Type: ", fileItem.type);
      console.log("File Item: ", fileItem);

      let name = fileItem.name,
        key = `${tempStore.companyId}/onboarding-files/${Number(
          new Date()
        )}${name
          .replaceAll(/\s/g, "")
          .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`;

      await Storage.put(key, fileItem, {
        contentType: fileItem.type,
        progressCallback(progress) {
          const percentage = (progress.loaded / progress.total) * 100;
          progressArray[i] = percentage;
          setUploadPercent([...progressArray]);
        },
      })
        .then((result) => {
          console.log(result);
          setFile((prevFile) => prevFile.filter((prev) => fileItem !== prev));
          const removedPercent = [...uploadPercent];
          removedPercent.splice(i, 1);
          setUploadPercent(removedPercent);
          fileLinks.push({
            key: key,
            name: fileItem.name,
            type: fileItem.type,
            size: fileItem.size,
          });
        })
        .catch((err) => console.log(err));
    }
    setUploading(false);
    return fileLinks;
  };

  useEffect(() => {
    if (file.length !== 0 && !uploading) {
      handleUpload();
      setUploading(true);
    }
  }, [file]);

  const uploadButtonRef = useRef(null);

  const handleUploadClick = () => {
    uploadButtonRef.current.click();
  };

  const handleSelectFileUpload = (e) => {
    setFile((prevFile) => [...prevFile, ...e.target.files]);
  };

  const handleDropFileUpload = (newFile) => {
    setFile((prevFile) => [...prevFile, ...newFile]);
  };

  const uploadDropArea = useRef(null);

  useEffect(() => {
    const instance = uploadDropArea.current;

    instance.addEventListener("dragover", handleDragOver);
    instance.addEventListener("drop", handleDrop);
    instance.addEventListener("dragenter", handleDragEnter);
    instance.addEventListener("dragleave", handleDragLeave);

    return () => {
      instance.removeEventListener("dragover", handleDragOver);
      instance.removeEventListener("drop", handleDrop);
      instance.removeEventListener("dragenter", handleDragEnter);
      instance.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    console.log(files);
    if (files && files.length) {
      handleDropFileUpload(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== uploadDropArea.current) setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === uploadDropArea.current) setDragging(false);
  };

  const draggingStyle = {
    true: {
      dropArea: "bg-gray-100 border-2 ",
    },
    false: {
      dropArea: "border-2",
    },
  };

  const fileProperties = {
    acceptedFileTypes: ["PNG", "JPG", "PDF"],
    maxSize: "20mb",
  };

  return (
    <div className="flex flex-col w-full gap-3 py-3 bg-white rounded-md">
      <p className=" text-md text-gray-400 font-medium">Supporting Documents</p>
      <div
        ref={uploadDropArea}
        className={`flex flex-col items-center gap-3 w-full py-5 rounded-lg ${draggingStyle[dragging].dropArea}`}
      >
        <div className="grid place-content-center p-2 rounded-full bg-gray-100">
          <HiOutlineLightningBolt size={"1.5rem"} className="text-gray-600" />
        </div>

        <p className="text-gray-400">
          <button
            onClick={handleUploadClick}
            className="text-green-600 font-medium"
          >
            Click to upload
          </button>
          {` or drag and drop`}
        </p>

        <p className="text-gray-400">
          {`
            ${fileProperties.acceptedFileTypes.map((types, index) =>
              index !== fileProperties.acceptedFileTypes.length - 1
                ? ` ${types}`
                : ` or ${types}`
            )}
             (Max ${fileProperties.maxSize})
          `}
        </p>

        <input
          ref={uploadButtonRef}
          type="file"
          onChange={(e) => handleSelectFileUpload(e)}
          multiple
          hidden
        />
      </div>
      <div className="flex flex-col gap-2">
        {answerFile &&
          Object.keys(answerFile)?.map((fileKey, fileKeyIndex) => (
            <UploadFileCard
              key={fileKeyIndex}
              fileItem={answerFile[fileKey]}
              uploadProgress={100}
            />
          ))}
        {file &&
          file?.map((fileItem, fileIndex) => (
            <UploadFileCard
              key={fileIndex}
              fileItem={fileItem}
              uploadProgress={uploadPercent[fileIndex]}
            />
          ))}
      </div>
    </div>
  );
}

function UploadFileCard({ fileItem, uploadProgress }) {
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const progressStyle = {
    uploading: {
      border: "border-gray-100",
      checkmark: false,
    },
    done: {
      border: "border-green-500",
      checkmark: true,
    },
  };

  return (
    <div
      className={`flex gap-4 w-full p-3 rounded-md border-2 ${
        progressStyle[uploadProgress == 100 ? "done" : "uploading"].border
      } `}
    >
      <div className="w-8 h-8 grid place-content-center rounded-full bg-green-100">
        <HiOutlineLightningBolt size={"1.2rem"} className="text-green-600" />
      </div>

      <div className="flex-1 flex flex-col gap-1">
        <p>{fileItem?.name}</p>
        <p>{formatBytes(fileItem?.size)}</p>
        <ProgressBar percentage={uploadProgress} />
      </div>

      <div className="grid place-content-center w-5 h-5 border-2 rounded-full">
        {progressStyle[uploadProgress == 100 ? "done" : "uploading"]
          .checkmark && (
          <IoCheckmarkCircleSharp size={"1.4rem"} className="text-green-600" />
        )}
      </div>
    </div>
  );
}

function ProgressBar({ percentage }) {
  const progressCalculator = () => {
    return (percentage ? percentage.toFixed(0) : 0) + "%";
  };

  return (
    <div className="flex items-center gap-4 w-full">
      <div className="flex-1 flex items-center h-2 rounded-lg bg-gray-300">
        <div
          className="bg-green-600 h-2 rounded-lg"
          style={{ width: progressCalculator() }}
        ></div>
      </div>
      <div className="">{progressCalculator()}</div>
    </div>
  );
}

function PickDater({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
  } = props;

  const handleDateFormat = () => {
    if (configuration?.isDatePickerMonthYear) {
      return "MM/yyyy";
    }
    return datePickerDateFormatter(
      localStorage.getItem("userpreferredDateFormatType") || "MM/dd/yyyy"
    );
  };

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div
      className="relative px-4 text-base w-full"
      style={{ paddingTop: "1.4rem", paddingBottom: "1.4rem" }}
    >
      <CiCalendar
        style={{ zIndex: 2, top: "20%" }}
        className="absolute left-3 w-6 h-6 text-gray-400 opacity-70"
      />
      <input
        value={value}
        className="pl-12 pr-4 h-full absolute top-0 left-0 text-base rounded-lg w-full border"
        wrapperClassName="h-full"
        onClick={onClick}
        ref={ref}
        onChange={onChange}
        placeholder="Select a date"
        style={{ zIndex: 1 }}
      />
    </div>
  ));

  return (
    <div className={`${choicesContainerStyle[style]}`}>
      <span className="text-slate-700 text-base">{children}</span>
      <DatePicker
        id="date-picker"
        customInput={<CustomInput />}
        selected={value && timezoneFormatter(value)}
        showMonthYearPicker={configuration?.isDatePickerMonthYear}
        dateFormat={handleDateFormat()}
        onChange={action}
      />
      {/* <DatePicker
        className="px-4 py-2.5 text-base rounded-lg w-full border"
        selected={new Date()}
        showMonthYearPicker
        dateFormat="MM/yyyy"
      /> */}
    </div>

    /* <div className={`${choicesContainerStyle[style]}`}>
      <span className="text-slate-700 text-base">{children}</span>
      <div className="flex items-center border rounded-lg w-full">
        <span className="px-4">
          <CiCalendar className="w-6 h-6 text-gray-500" />
        </span>
        <span className="w-full">
          <DatePicker
            className="py-2.5 text-base rounded-lg w-full"
            wrapperClassName="w-full"
            placeholderText="Select a date"
            selected={value && timezoneFormatter(value)}
            showMonthYearPicker={configuration?.isDatePickerMonthYear}
            dateFormat={handleDateFormat()}
            onChange={action}
          />
        </span>
      </div>
    </div> */
  );
}

function SeparatePickDater({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
  } = props;

  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [notification, setNotification] = useState(false);
  const [yearsOption, setYearsOption] = useState(
    Array.apply(0, Array(100)).map((day, index) => {
      return {
        value: moment().year() + 10 - index,
        label: moment().year() + 10 - index,
      };
    })
  );
  const [monthsOption, setMonthsOption] = useState(
    moment.months().map((month, index) => {
      return {
        value: index,
        label: month,
      };
    })
  );
  const [daysOption, setDaysOption] = useState(
    Array.apply(0, Array(31)).map((day, index) => {
      return {
        value: index + 1,
        label: index + 1,
      };
    })
  );

  useEffect(() => {
    if (
      (typeof value === "string" || (selectedYear && selectedMonth)) &&
      new Date(value) != "Invalid Date"
    ) {
      const newValue = new Date(value);
      const year = selectedYear || moment(newValue).year(),
        month = selectedMonth || moment(newValue).month();

      let numOfDays = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth();
      setDaysOption(
        Array.apply(0, Array(numOfDays)).map((day, index) => {
          return {
            value: index + 1,
            label: index + 1,
          };
        })
      );
    }
  }, [selectedYear, selectedMonth]);

  const handleDateChange = (selectedYear, selectedMonth, selectedDay) => {
    /* Checks if value exists to jump in to the statement
     * without evaluating the other conditions
     */
    if (
      value ||
      /* 0 is a number, and it is a valid month (January) */
      (typeof selectedYear === "number" &&
        typeof selectedMonth === "number" &&
        typeof selectedDay === "number")
    ) {
      const year = selectedYear || moment(value).year(),
        month =
          selectedMonth === 0 ? 0 : selectedMonth || moment(value).month(),
        day =
          selectedDay ||
          (new Date(value) != "Invalid Date" ? moment(value).date() : 1); // 1 is the default day

      return action(
        moment([year, month, day])._d == "Invalid Date"
          ? handleInvalidDate()
          : moment([year, month, day])._d.toString()
      );
    }
  };

  const handleInvalidDate = () => {
    // Reset their state to avoid getting invalid date after another attempt
    setSelectedDay();
    setSelectedMonth();
    setSelectedYear();

    // Show notification
    setNotification(true);
    setTimeout(() => {
      setNotification(false);
    }, 3000);
    return "";
  };

  const customStyling = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: "0.5rem",
      paddingBottom: "0.19rem",
      paddingTop: "0.19rem",
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      display: "none",
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontWeight: "400",
      opacity: "0.8",
    }),
  };

  const selectDefaultValue = (option, dateType) => {
    if (
      value &&
      (typeof value === "string" || new Date(value) instanceof Date)
    ) {
      return option[option?.findIndex((obj) => obj.value === dateType)];
    }
    return null;
  };

  return (
    <section className="flex flex-col gap-3 w-full">
      <span>{children}</span>
      <div className={`${choicesContainerStyle[style]} w-full`}>
        <div style={{ flex: 1 }}>
          <Select
            key={
              selectDefaultValue(yearsOption, moment(new Date(value)).year()) ||
              "yearsOption"
            }
            placeholder="Year"
            options={yearsOption}
            onChange={(e) => {
              setSelectedYear(e.value);
              handleDateChange(e.value, selectedMonth, selectedDay);
            }}
            styles={customStyling}
            defaultValue={selectDefaultValue(
              yearsOption,
              moment(new Date(value)).year()
            )}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Select
            key={selectDefaultValue(
              monthsOption,
              moment(new Date(value)).month() || "monthsOption"
            )}
            placeholder="Month"
            options={monthsOption}
            onChange={(e) => {
              setSelectedMonth(e.value);
              handleDateChange(selectedYear, e.value, selectedDay);
            }}
            styles={customStyling}
            defaultValue={selectDefaultValue(
              monthsOption,
              moment(new Date(value)).month()
            )}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Select
            key={
              selectDefaultValue(daysOption, moment(new Date(value)).date()) ||
              "daysOption"
            }
            placeholder="Day"
            options={daysOption}
            onChange={(e) => {
              setSelectedDay(e.value);
              handleDateChange(selectedYear, selectedMonth, e.value);
            }}
            styles={customStyling}
            defaultValue={selectDefaultValue(
              daysOption,
              moment(new Date(value)).date()
            )}
          />
        </div>
      </div>
      {notification && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="w-full px-3 py-4 flex items-center bg-gradient-to-r to-red-300 from-red-200 rounded-xl shadow gap-5"
        >
          <BsFillInfoCircleFill className="w-4 h-4" />
          <h1 className="font-medium">Invalid Date</h1>
        </motion.div>
      )}
    </section>
  );
}

const Table = ({ props }) => {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
    formData,
  } = props;

  const { width } = useWindowDimensions();

  function getDescColWidth() {
    if (width > 965.263) {
      return width - 900.263;
    } else {
      return 200;
    }
  }

  const newFormData = [];

  /* Remove email and progress from formData and reconstruct
   * data to maintain order
   **/
  for (let data in formData) {
    if (data !== "Email" && data !== "Progress") {
      newFormData.push(formData[data]);
    }
  }
  newFormData.sort((a, b) => a?.order - b?.order);

  return (
    <div
      className="overflow-y-scroll no-scrollbar flex flex-col border-2 border-gray-300 shadow-lg rounded-lg"
      style={{ maxHeight: "30rem" }}
    >
      {/* START OF TABLE */}
      {width > 860 && (
        /* DESKTOP VERSION */
        <DesktopTable
          formData={newFormData}
          getDescColWidth={getDescColWidth}
        />
      )}
    </div>
  );
};

/* DESKTOP VERSION */
function DesktopTable({ formData, getDescColWidth }) {
  return (
    <div className={"flex h-full w-full flex-col gap-1"}>
      <table className="min-w-full divide-y divide-gray-200 rounded-md text-xs shadow">
        <thead className="z-20 rounded-tl-md bg-white border-b border-gray-300 shadow-sm sticky top-0">
          <tr>
            <th className="w-2/12 whitespace-nowrap rounded-tl-md px-0 py-0 text-center font-medium text-gray-400">
              No.
            </th>
            <th className="whitespace-nowrap px-2 py-4 text-center font-medium text-gray-400">
              Description of Background
            </th>
            <th className="w-3/12 whitespace-nowrap rounded-tr-md px-2 py-4 text-center xl:w-2/12 font-medium text-gray-400">
              Label
            </th>
          </tr>
        </thead>
        <tbody>
          {console.log(formData)}
          {Object.keys(formData).length > 0
            ? Object.entries(formData).map((key, value) => (
                <tr key={value} className="border-b-2">
                  <td className="align-top">
                    <div className="flex flex-row items-center justify-center gap-2 py-5">
                      <div className="flex flex-row items-center">
                        <span
                          className="text-sm font-medium"
                          style={{ marginTop: "0.07rem" }}
                        >
                          {value + 1}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    className="h-full p-3 text-justify align-top"
                    // style={{
                    //   minWidth: getDescColWidth(),
                    //   maxWidth: getDescColWidth(),
                    // }}
                  >
                    <div
                      className="w-full rounded-md p-2.5 flex flex-col gap-3"
                      style={{
                        cursor: "auto",
                        outlineColor: "rgb(204, 204, 204, 0.5)",
                        outlineWidth: "thin",
                        width: "100%",
                      }}
                      suppressContentEditableWarning
                    >
                      <section className="w-full flex flex-col gap-2">
                        <h1 className="font-medium text-gray-500 opacity-80">
                          Question:
                        </h1>
                        <p>{key[1]?.headerText}</p>
                      </section>
                      <section className="w-full flex flex-col gap-2">
                        <h1 className="font-medium text-gray-500 opacity-80">
                          Answer:
                        </h1>
                        <p>
                          {key[1]?.answers?.map((answer) =>
                            answer.answer instanceof Date
                              ? answer.answer.toDateString()
                              : answer.answer
                          )}{" "}
                        </p>
                      </section>
                    </div>
                  </td>
                  <td className="flex flex-col gap-2 px-3 py-3">
                    <span
                      className="bg-gray-100 rounded-md text-xs font-normal px-2 py-1"
                      style={{
                        width: "fit-content",
                      }}
                    >
                      Mirrored Email Page
                    </span>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}
