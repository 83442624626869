

export const formDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_DETAILS":
      return setFormDetails(state, action)

    case "SET_FORM_CONTENT":
      return setFormContent(state, action)

    case "INITIALIZE_ANSWER_STATE":
      return initializeAnswerState(state, action)

    case "SET_ANSWER_SINGLE":
      return setAnswerSingle(state, action)

    case "SET_ANSWER_MULTIPLE":
      return setAnswerMultiple(state, action)

    case "SET_ANSWER_TOGGLE":
      return setAnswerToggle(state, action)

    default:
      return state;
  }
}
  
/** Initialize form details
 * @param {string} action.payload - formId, formName
 */
const setFormDetails = (state, action) => {
  return{
    ...state,
    formDetails: action.payload
  }
}

/** Initialize form content
 * @param {string} action.payload - pages
 */
const setFormContent = (state, action) => {
  console.log('Initializing Form Content ',action.payload)
  const transformedData = {
    pages: action.payload.items.map((page) => ({
      id: page.id,
      order: page.order,
      name: page.name,
      description: page.description,
      sections: page.fields.items.map((field) => ({
        id: field.id,
        order: field.order,
        question: field.headerText,
        sublabel: field.subText,
        optionsType: field.type,
        options: field.options,
        properties: field.properties
      })),
    })),
  };

  transformedData.pages.sort((a, b) => a.order - b.order)

  transformedData.pages.forEach((page) => {
    page.sections.sort((a, b) => a.order - b.order);
  })
  
  return{
    ...state,
    pages: transformedData.pages
  }
}

const initializeAnswerState = (state, action) => {

  const newAnswers = {}

  state.pages.forEach((page)=>{
    const pageKey = `page_${page.order}`
    newAnswers[pageKey] = {
      name: page.name,
      description: page.description,
    }
    page.sections.forEach((section)=>{
      const sectionKey = `section_${section.order}`
      newAnswers[pageKey][sectionKey] = {
        question: section.question,
        sublabel: section.sublabel,
        optionsType: section.optionsType,
        answers: []
      }
    })
  })

  return{
    ...state,
    formAnswers: newAnswers
  }
}


const setAnswerSingle = (state, action) => {
  console.log('Setting single answer', action)

  return {
    ...state,
    formAnswers: {
      [`page_${action.pageOrder}`]: {
        ...state.formAnswers[`page_${action.pageOrder}`],
        [`section_${action.sectionOrder}`]: {
          ...state.formAnswers[`page_${action.pageOrder}`][`section_${action.sectionOrder}`],
          answers: [action.payload]
        }


      }
    }
  }
}

const setAnswerMultiple = (state, action) => {

  return state
}

const setAnswerToggle = (state, action) => {

  return state
}