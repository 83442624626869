export const appStateReducer = (state, action) => {
    switch (action.type) {
      case "SWITCH_PAGE":
        return {
            ...state,
            currentPage: action.payload
        }
  
      default:
        return state;
    }
  }