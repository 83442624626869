import { API } from "aws-amplify";

const UPDATE_USER_MUTATION = `
  mutation updateUser($contactNumber: String, $email: AWSEmail!, $firstName: String, $id: ID!, $lastName: String, $profilePicture: String, $userType: UserType, $businessName: String, $hasPortalAccess: Boolean, $company: CompanyInput) {
    userUpdate(
      contactNumber: $contactNumber
      firstName: $firstName
      id: $id
      lastName: $lastName
      profilePicture: $profilePicture
      userType: $userType
      email: $email
      businessName: $businessName
      hasPortalAccess: $hasPortalAccess
      company: $company
    ) {
      id
      hasPortalAccess
    }
  }
`;

// const DELETE_USER_MUTATION = `mutation deleteUser($companyId: ID, $email: AWSEmail, $id: ID) {
//     userDelete(id: $id, companyId: $companyId, email: $email) {
//         id
//     }
// }`;

const SOFTDELETE_RESTORE_USER_MUTATION = `mutation softDeleteUser($id: ID, $companyId: ID, $isDeleted: Boolean) {
  userSoftDeleteOrRestore(id: $id, companyId: $companyId, isDeleted: $isDeleted)
}
`;

export const mOutlookSendEmail = `mutation MyOutlookSendEmail(
  $to: [AWSEmail]!,
  $cc: [AWSEmail],
  $bcc: [AWSEmail],
  $subject: String!,
  $html: String!,
  $attachments: [OutlookSendEmailAttachmentsInput],
  $refreshToken: String!,
  $inReplyTo: String,
){
  outlookSendEmail(
    to: $to
    cc: $cc
    bcc: $bcc
    subject: $subject
    attachments: $attachments
    html: $html
    refreshToken: $refreshToken
    inReplyTo: $inReplyTo
  ){
    statusCode
    message
    errMsg
    messageId
    threadId
  }
}`;

export const mOutlookDraftEmail = `mutation MyOutlookDraftEmail(
  $to: [AWSEmail]!,
  $cc: [AWSEmail],
  $bcc: [AWSEmail],
  $subject: String!,
  $html: String!,
  $attachments: [OutlookSendEmailAttachmentsInput],
  $refreshToken: String!,
  $inReplyTo: String,
  $isDraft: Boolean,
  $forwardEmail: String,
){
  outlookSendEmail(
    to: $to
    cc: $cc
    bcc: $bcc
    subject: $subject
    attachments: $attachments
    html: $html
    refreshToken: $refreshToken
    inReplyTo: $inReplyTo
    forwardEmail: $forwardEmail
    isDraft: $isDraft
  ){
    statusCode
    message
    errMsg
  }
}`;

export const mOutlookForwardEmail = `mutation MyOutlookForwardEmail(
  $subject: String!,
  $to: [AWSEmail]!,
  $cc: [AWSEmail]
  $bcc: [AWSEmail]
  $html: String!,
  $attachments: [OutlookSendEmailAttachmentsInput],
  $refreshToken: String!,
  $forwardEmail: String
){
  outlookSendEmail(
    subject: $subject
    to: $to
    cc: $cc
    bcc: $bcc
    html: $html
    attachments: $attachments
    refreshToken: $refreshToken
    forwardEmail: $forwardEmail
  ){
    statusCode
    message
    errMsg
    messageId
    threadId
  }
}`;
// ------ USER SECTION ------
export async function UpdateUser(
  id,
  email,
  firstName,
  lastName,
  contactNumber,
  profilePicture,
  userType,
  businessName,
  hasPortalAccess,
  companyId
) {
  try {
    const users = await API.graphql({
      query: UPDATE_USER_MUTATION,
      variables: {
        contactNumber: contactNumber,
        firstName: firstName,
        id: id,
        lastName: lastName,
        profilePicture: profilePicture,
        userType: userType,
        email: email,
        businessName: businessName,
        hasPortalAccess: hasPortalAccess,
        company: {
          id: companyId,
        },
      },
    });
    return users;
  } catch (error) {
    console.error(error.message);
    console.error(`Parameters must be in this order!\n`);
  }
}

/**
export async function DeleteUser(companyId, email, userId) {
  try {
    const result = await API.graphql({
      query: DELETE_USER_MUTATION,
      variables: {
        companyId: companyId,
        email: email,
        id: userId,
      },
    });
    return result;
  } catch (error) {
    console.error(error.message);
  }
}
*/

export async function ArchiveUser(userId, companyId) {
  try {
    const result = await API.graphql({
      query: SOFTDELETE_RESTORE_USER_MUTATION,
      variables: {
        companyId: companyId,
        id: userId,
        isDeleted: true,
      },
    });
    return result;
  } catch (error) {
    console.error(error.message);
  }
}

export async function RestoreUser(userId, companyId) {
  try {
    const result = await API.graphql({
      query: SOFTDELETE_RESTORE_USER_MUTATION,
      variables: {
        companyId: companyId,
        id: userId,
        isDeleted: false,
      },
    });
    return result;
  } catch (error) {
    console.error(error.message);
  }
}
