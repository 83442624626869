import React, { useState, useRef, useEffect } from "react";
import { useSelected, useFocused } from "slate-react";
import { usePopper } from "react-popper";
import { FaSearch } from "react-icons/fa";
import { BsFillTrashFill } from "react-icons/bs";
import { API } from "aws-amplify";

const BACKGROUND_UPDATE_MUTATION = `
mutation backgroundUpdateV2($id: ID, $input: BackgroundInput) {
  backgroundUpdateV2(id: $id, input: $input) {
    tabFiles {
      fileIds
    }
  }
}
`;

const mUpdateBackgroundDesc = `
mutation updateBackground($id: ID, $description: String) {
  backgroundUpdate(id: $id, description: $description) {
	id
	description
  }
}
`;

const Mention = ({
	attributes,
	children,
	element,
	mentionElementId,
	itemIndex,
	background,
	reducedUploadedFilesArray,
}) => {
	const [visibility, setVisibility] = useState(true);
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [arrowElement, setArrowElement] = useState(null);
	const [selectedFiles, setSelectedFiles] = useState(null);
	const [activeTab, setActiveTab] = useState(0);
	const [filteredAllFiles, setFilteredAllFiles] = useState([]);
	const [filteredAttachedFiles, setFilteredAttachedFiles] = useState([]);
	const [holdSelectedFiles, setHoldSelectedFiles] = useState([]);
	const [isForDeletion, setIsForDeletion] = useState(false);

	const inputRef = useRef();

	const selected = useSelected();
	const focused = useFocused();
	const allFilesArray = [...reducedUploadedFilesArray];
	const mentionLocalIndex = mentionElementId?.split("@")[1];
	const localTabFiles =
		background[itemIndex].tabFiles?.[mentionLocalIndex]?.fileIds;
	const localFiles = background[itemIndex].files?.items;

	useEffect(() => {
		if (localFiles) {
			setFilteredAttachedFiles(localFiles);
		}
	}, [localFiles]);

	useEffect(() => {
		setSelectedFiles(localTabFiles);
		setHoldSelectedFiles(localTabFiles);
		const newFilesList = removeUploadedFileDuplicates(
			reducedUploadedFilesArray
		);

		setFilteredAllFiles(newFilesList);
	}, []);

	// useEffect(() => {
	// 	const handleClickOutside = (event) => {
	// 		if (!popperElement?.current?.contains(event.target)) {
	// 			setVisibility(true);
	// 		}
	// 	};
	// 	document.addEventListener("click", handleClickOutside);

	// 	return () => {
	// 		// document.removeEventListener("click", handleClickOutside);
	// 		visibilityToggle();
	// 	};
	// }, []);

	//used for deletion
	async function updateBackgroundDesc(id, description) {
		return new Promise((resolve, reject) => {
			try {
				const request = API.graphql({
					query: mUpdateBackgroundDesc,
					variables: {
						id: id,
						description: description,
					},
				});
				resolve(request);
			} catch (e) {
				reject(e.errors[0].message);
			}
		});
	}

	//used for updating / posting tab file details
	async function updateBackgroundTabFiles(id, tabFiles) {
		await API.graphql({
			query: BACKGROUND_UPDATE_MUTATION,
			variables: {
				id,
				input: {
					tabFiles,
				},
			},
		});
	}

	const sortAllFiles = (array) => {
		return array.sort((a, b) => {
			if (a.backgroundIndex === b.backgroundIndex) {
				return a.order - b.order;
			}
			return a.backgroundIndex - b.backgroundIndex;
		});
	};

	const sortAttachedFiles = (array) => {
		return array.sort((a, b) => a.order - b.order);
	};

	const removeUploadedFileDuplicates = (array) => {
		const uniqueArrays = new Set(array?.map(JSON.stringify));
		return Array?.from(uniqueArrays)?.map(JSON.parse);
	};

	const arraysEqual = (a, b) => {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a?.length !== b?.length) return false;

		for (let i = 0; i < a?.length; i++) {
			if (!b.includes(a[i])) return false;
		}

		return true;
	};

	const visibilityToggle = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (visibility) {
			setActiveTab(0);
		}

		if (!visibility) {
			let indexTabFiles = background[itemIndex]?.tabFiles;
			let newTabFiles = [];

			if (indexTabFiles) {
				if (mentionLocalIndex) {
					indexTabFiles[mentionLocalIndex] = {
						fileIds: selectedFiles,
					};
				} else {
					let newTabInfo = { fileIds: selectedFiles };
					indexTabFiles.push(newTabInfo);
				}
			} else {
				let newTabInfo = { fileIds: selectedFiles };
				newTabFiles.push(newTabInfo);
			}

			await updateBackgroundTabFiles(
				background[itemIndex]?.id,
				indexTabFiles ? indexTabFiles : newTabFiles
			);

			if (!arraysEqual(holdSelectedFiles, selectedFiles)) {
				window.location.reload();
			}
		}

		setVisibility(!visibility);
	};

	const deleteMention = async (e) => {
		let indexTabFiles = background[itemIndex]?.tabFiles;
		const filteredTabFiles = indexTabFiles?.filter(
			(item, index) => index.toString() !== mentionLocalIndex
		);

		if (mentionLocalIndex) {
			function replaceTabWithSpace(n, arr) {
				let tabCount = 0;
				let newArr = arr;

				for (let i = 0; i < arr.length; i++) {
					if (arr[i] === "@Tab") {
						if (tabCount.toString() === n) {
							newArr[i + 1] = arr[i + 1].trimStart();
							newArr[i] = "";
							break;
						}

						tabCount++;
					}
				}

				return newArr;
			}

			const modifiedDescription = replaceTabWithSpace(
				mentionLocalIndex,
				background[itemIndex]?.description.replace(/\n/g, "").split(/(@Tab)/)
			).join("");

			await updateBackgroundTabFiles(
				background[itemIndex]?.id,
				filteredTabFiles
			);

			await updateBackgroundDesc(
				background[itemIndex]?.id,
				modifiedDescription
			);
		}

		if (!mentionLocalIndex) {
			function replaceLastTabWithSpace(arr) {
				let newArr = arr;

				for (let i = arr.length - 1; i >= 0; i--) {
					if (arr[i] === "@Tab") {
						newArr[i + 1] = arr[i + 1].trimStart();
						arr[i] = "";
						break;
					}
				}

				return newArr;
			}

			const modifiedDescription = replaceLastTabWithSpace(
				background[itemIndex]?.description.split(/(@Tab)/)
			).join("");

			await updateBackgroundDesc(
				background[itemIndex]?.id,
				modifiedDescription
			);
		}

		setVisibility(true);
		setIsForDeletion(true);

		window.location.reload();
	};

	const handleFilesSearch = (e) => {
		const query = e.target.value.toLowerCase();

		if (filteredAllFiles) {
			const tempFilteredAllFiles = allFilesArray?.filter((file) =>
				file?.name?.toLowerCase()?.includes(query)
			);
			setFilteredAllFiles(tempFilteredAllFiles);
		}

		if (filteredAttachedFiles) {
			const tempFilteredAttachedFiles = localFiles?.filter((file) =>
				file?.name?.toLowerCase()?.includes(query)
			);
			setFilteredAttachedFiles(tempFilteredAttachedFiles);
		}
	};

	// const popperRef = useRef();

	const handleCheckboxClick = (fileId) => {
		setSelectedFiles((prevSelectedFiles) => {
			if (!prevSelectedFiles) {
				return [fileId];
			}
			if (prevSelectedFiles?.includes(fileId)) {
				return prevSelectedFiles.filter((id) => id !== fileId);
			} else {
				return [...prevSelectedFiles, fileId];
			}
		});
	};

	const style = {
		padding: "3px 3px 2px",
		margin: "0 1px",
		verticalAlign: "baseline",
		display: "inline-block",
		borderRadius: "4px",
		backgroundColor: "#E3FAFF",
		color: "#1CC1E9",
		fontSize: "0.9em",
		boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
	};

	const {
		styles: popStyles,
		attributes: popAttributes,
		state: popState,
		update: popUpdate,
		setOptions,
	} = usePopper(referenceElement, popperElement, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "arrow",
				options: { element: arrowElement, placement: "bottom" },
			},
			{
				name: "offset",
				options: {
					offset: [0, 5],
				},
			},
		],
	});

	return (
		<>
			<span
				ref={setReferenceElement}
				className={`mention-element ${isForDeletion ? "hidden invisible" : ""}`}
				onClick={(e) => {
					visibilityToggle(e, itemIndex);
				}}>
				<span
					className="relative cursor-pointer block"
					type="button"
					{...attributes}
					contentEditable={false}
					data-cy={`mention-${element.character.replace(" ", "-")}`}
					style={style}>
					@{element.character}
					{children}
				</span>
			</span>
			{!visibility && allFilesArray && (
				<div
					className={`mention-menu ${
						visibility ? "hidden invisible" : ""
					} w-80 shadow-md bg-white p-3 z-50 max-h-54`}
					ref={setPopperElement}
					style={popStyles.popper}
					{...popAttributes.popper}
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<div className="flex justify-between items-center">
						<div className="flex font-normal tracking-wider text-xs mb-4 mt-2 w-full">
							<FaSearch className="mr-1 text-gray-400" />
							<input
								type={"text"}
								className="font-normal tracking-wider text-xs h-5 searchPlaceholder w-full p-2"
								placeholder="Search"
								ref={inputRef}
								onChange={handleFilesSearch}
							/>
						</div>
						<div>
							<button
								onClick={(e) => {
									deleteMention(e);
								}}>
								<BsFillTrashFill className="text-red-600" />
							</button>
						</div>
					</div>
					<div>
						<p style={{ fontSize: "2px", color: "white" }}>~start~</p>
						<div className="flex gap-4 mb-4">
							<button
								onClick={() => {
									setActiveTab(0);
								}}
								className={`text-xs uppercase font-semibold ${
									activeTab === 0
										? "border-b border-1 border-black"
										: "text-gray-400 border-1 border-b border-white"
								} `}>
								Attached Files
							</button>
							<button
								onClick={() => {
									setActiveTab(1);
								}}
								className={`text-xs uppercase font-semibold ${
									activeTab === 1
										? "border-b border-1 border-black"
										: "text-gray-400 border-1 border-b border-white"
								} `}>
								All Files
							</button>
						</div>
						{activeTab === 0 && (
							<div className="max-h-40 overflow-y-auto">
								{filteredAttachedFiles?.length === 0 ? (
									<p>No matching results...</p>
								) : (
									<div>
										{sortAttachedFiles(filteredAttachedFiles).map(
											(file, index) => {
												return (
													<div
														className="mb-2"
														key={index}>
														<input
															className=""
															type="checkbox"
															id={file.id}
															name={file.name}
															value={file.name}
															defaultChecked={selectedFiles?.includes(file.id)}
															onChange={() => {
																handleCheckboxClick(file.id);
															}}
														/>
														<label
															className="ml-4"
															htmlFor={file.id}>
															<span className="font-semibold">
																{itemIndex + 1}
															</span>
															{"."}
															<span className="font-semibold">
																{file.order}
															</span>
															{". "}
															{file.name}
														</label>
													</div>
												);
											}
										)}
									</div>
								)}
							</div>
						)}
						{activeTab === 1 && (
							<div className="max-h-40 overflow-y-auto">
								{filteredAllFiles?.length === 0 ? (
									<p>No matching results...</p>
								) : (
									<div>
										{sortAllFiles(filteredAllFiles)?.map((file, index) => {
											return (
												<div
													className="mb-2"
													key={index}>
													<input
														className=""
														type="checkbox"
														id={file.id}
														name={file.name}
														value={file.name}
														defaultChecked={selectedFiles?.includes(file.id)}
														onChange={() => {
															handleCheckboxClick(file.id);
														}}
													/>
													<label
														className="ml-4"
														htmlFor={file.id}>
														<span className="font-semibold">
															{file.backgroundIndex === 0
																? file.backgroundIndex + 1
																: file.backgroundIndex}
														</span>
														{"."}
														<span className="font-semibold">{file.order}</span>
														{". "}
														{file.name}
													</label>
												</div>
											);
										})}
									</div>
								)}
							</div>
						)}
						<p style={{ fontSize: "2px", color: "white" }}>~end~</p>
					</div>
				</div>
			)}
		</>
	);
};

export default Mention;
