import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import propertyDetailsIcon from '../../assets/images/propertyDetails-icon.png';
import { IoCloseOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import UploadIcon from "../../assets/images/hba-upload-icon.svg";
import Button from "../../shared/Button";


export default function UploadFilesModal({
  handleDrop,
  handleDragOver,
  handleSave,
  handleFileUpload,
  handleUploadModalClose,
  pageName,
  uploadURL,
  matter_id,
}) {
  const hiddenFileInput = useRef(null);

  return (<>
    <div
        className="outline-none focus:outline-none h-full fixed inset-0 z-50 flex font-open-sans"
        style={{ zIndex: 70 }}
      >
      <div className="fixed right-0 h-full w-full">
        <div className="outline-none focus:outline-none relative w-full h-full rounded-sm border-0 bg-white shadow-lg flex flex-col justify-between content-between	overflow-y-auto">
          <div className="flex justify-between p-5">
            <div className="flex items-center w-full pl-2">
              <img src={propertyDetailsIcon} alt="Progress Photos Icon" className="pb-2"/>
              <h3 className="pb-2 text-lg font-semibold text-gray-900 pl-2">
                {pageName}
              </h3>
            </div>
            <IoCloseOutline
                className="text-2xl text-gray-500"
                onClick={() => {
                    handleUploadModalClose(false)
                }}
            />
          </div>
          <div className="overflow-y-auto h-full flex flex-col">  
          <div className="items-start justify-between w-full py-2 px-5 flex flex-col">
            <p className="pb-1 font-open-sans font-normal text-gray-900">{pageName} Upload</p>
            <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="overflow-hidden flex justify-center flex-col items-center h-52 mx-auto border w-full px-3 rounded-xl border-gray-300 content-center"
            >
                <>
                <img src={UploadIcon} className="mb-2" alt="icon" />
                <div className="flex flex-col justify-center text-center items-center">
                    <p className="text-sm text-center font-open-sans">
                    <span
                        className="text-hbBlue font-semibold cursor-pointer hover:underline font-open-sans"
                        onClick={() => hiddenFileInput.current.click()}
                    >
                        Click to upload
                    </span>
                    {" "} or drag and drop
                    </p>
                    <p className="text-gray-400 font-normal font-open-sans mx-auto">
                    PDF, Docs, SVG, PNG, JPG or GIF &#40;max: 160x160px&#41;
                    </p>
                    <input
                    onChange={(e) => {
                        handleFileUpload(e);
                    }}
                    ref={hiddenFileInput}
                    type="file"
                    multiple={false}
                    hidden
                    />
                </div>
                </>
                {uploadURL && (
                <div className="mt-3">
                    <p className="text-sm text-gray-700 font-open-sans">Upload file:</p>
                    <span className="text-blue-500 hover:underline font-open-sans">
                    {uploadURL}
                    </span>
                </div>
                )}
            </div>
            </div>

            
            {/* <div className=" items-start justify-between w-full py-2 px-5 mb-2">
              <p className="pb-1 font-open-sans font-normal text-gray-900">Description</p>
                <input 
                  className="flex flex-row justify-between items-center border w-full rounded-lg py-2 px-3 border-gray-300"
                  type="text"
                  value={tempAddress}
                  onChange={(e) => {
                    setTempAddress(e.target.value);
                  }}
                />
            </div> */}
            <div className="flex flex-grow items-end">
              <div className="w-full pt-5 border-t">
                <div className="p-5 pt-0 flex w-full gap-x-2">
                  <Button
                    className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
                    block={"full"}
                    onClick={() => {
                        handleUploadModalClose(false)
                    }}
                  >
                    <span className="h-fit w-fit font-semibold"> Cancel </span>
                  </Button>

                  <Button
                    id="saveButton"
                    variant="hbBlue"
                    className="py-2 cursor-pointer shadow-none"
                    block={"full"}
                    onClick={(e) => {
                      handleSave(matter_id);
                    }}
                    // disabled={!imageURL || saveButtonClicked || description === ""}
                  >
                    <span className="h-fit w-fit font-semibold"> Save </span>
                  </Button>  
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
    </div>
    <div
        className="fixed inset-0 bg-black opacity-25 overflow-hidden"
        style={{ zIndex: 60 }}
      ></div>
  </>)
}
