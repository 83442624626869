import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  createContext,
} from "react";
// import Select from "react-select";
import anime from "animejs";
import {
  addClientMatter,
  // deleteMatterClient,
  // getMatterList,
  // searchMatterClient,
  // isDupe,
} from "../dashboard/actions";
// import { ClientMatters } from "../dashboard/matters-list";
import { clientMatterReducers } from "../dashboard/reducers";
import { initialState } from "../dashboard/initialState";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { API } from "aws-amplify";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
// import { CgMathPlus } from "react-icons/cg";
import "../../assets/styles/custom-styles.css";
// import ToastNotification from "../toast-notification";
// import Creatable, { useCreatable } from "react-select/creatable";

export const MatterContext = createContext();
export default function AddClientMatterModal({
  close,
  fetchCompanyClientMatters,
  //   setValueCopy,
  clientMatters,
}) {
  const [matterlist, dispatch] = useReducer(clientMatterReducers, initialState);
  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  // const selectInputClientRef = useRef();
  // const selectInputMatterRef = useRef();
  // const [cmModal, setcmModal] = useState("");
  const [clientsOptions, setClientsOptions] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [inputSaveClient, setSaveClient] = useState("");
  const [inputClient, setInputClient] = useState("");

  const [mattersOptions, setMattersOptions] = useState();
  const [selectedMatter, setSelectedMatter] = useState();
  const [inputSaveMatter, setSaveMatter] = useState("");
  const [inputMatter, setInputMatter] = useState("");
  const [clientName, setclientName] = useState(null);
  const [matterName, setmatterName] = useState(null);
  // const [show, setshow] = useState(false);
  // const [count, setcount] = useState(0);
  const companyId = localStorage.getItem("companyId");
  // const userId = localStorage.getItem("userId");
  const [operationsTeams, setOperationsTeams] = useState(null);
  const { height, width } = useWindowDimensions();

  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });

    //Get operations teams
    getTeams();
  }, []);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const listClient = `query listClient($companyId: String) {
    company(id: $companyId) {
      clients {
        items {
          id
          name
        }
      }
    }
  }`;

  const listMatter = `query listMatter($companyId: String) {
    company(id: $companyId) {
      matters {
        items {
          id
          name
        }
      }
    }
  }`;

  const addClient = `mutation addClient($companyId: String, $name: String) {
    clientCreate(companyId:$companyId, name:$name) {
      id
      name
    }
  }`;

  const addMatter = `mutation addMatter($companyId: String, $name: String) {
    matterCreate(companyId:$companyId, name:$name) {
      id
      name
    }
  }`;

  //kjmf - autotag newly created clientmatter to ops team//
  const qGetTeams = `query getTeamsByCompany($id: String) {
    company(id: $id) {
      teams {
        items {
          id
          name
        }
      }
    }
  }`;

  const mTeamClientMatterTag = `mutation teamClientMatterTage($teamId: ID, $clientMatters: [ID]) {
    teamClientMatterTag(teamId: $teamId, clientMatters: $clientMatters) {
      id
    }
  }`;

  //get team members
  const qGetTeamsWithMembers = `query getTeamMembers($id: ID, $companyId: String) {
    team(id: $id) {
      id
      name
      members {
        items {
          userType
          user {
            id
            firstName
            lastName
            userType
            profilePicture
            clientMatterAccess(companyId: $companyId) {
              items {
                id
                userType
                clientMatter {
                  id
                  client {
                    id
                    name
                  }
                  matter {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      clientMatters {
        items {
          id
          client {
            name
          }
          matter {
            name
          }
        }
      }
    }
  }`;

  //tagging per user mutation
  const mTagClientMatter = `
    mutation tagUserClientMatterAccess($clientMatterAccess: [UserClientMatterAccessInput], $userId: ID, $companyId: ID) {
      userClientMatterTag(userId: $userId, clientMatterAccess: $clientMatterAccess, companyId: $companyId) {
        id
      }
    }
  `;

  const addClients = async (data) => {
    let result;

    const addedClientList = await API.graphql({
      query: addClient,
      variables: {
        companyId: companyId,
        name: data,
      },
    });

    result = [addedClientList.data.clientCreate].map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    setclientName(result[0]);
  };

  const addMatters = async (data) => {
    let result;

    const addedMatterList = await API.graphql({
      query: addMatter,
      variables: {
        companyId: companyId,
        name: data,
      },
    });

    result = [addedMatterList.data.matterCreate].map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    setmatterName(result[0]);
  };

  const Clients = async () => {
    let result;

    const clientsOpt = await API.graphql({
      query: listClient,
      variables: {
        companyId: companyId,
      },
    });

    if (clientsOpt.data.company.clients.items !== null) {
      result = clientsOpt.data.company.clients.items.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }

    var filtered = result?.filter(function (el) {
      return el.label != null && el.value != null;
    });

    setClientsOptions(filtered?.sort((a, b) => a.label - b.label));
  };

  const Matters = async () => {
    let result;

    const mattersOpt = await API.graphql({
      query: listMatter,
      variables: {
        companyId: companyId,
      },
    });

    if (mattersOpt.data.company.matters.items !== null) {
      result = mattersOpt.data.company.matters.items.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }

    var filtered = result?.filter(function (el) {
      return el.label != null && el.value != null;
    });

    setMattersOptions(filtered?.sort((a, b) => a.label - b.label));
  };

  const handleNewMatter = async () => {
    let client = {
        id: clientName.value,
        name: clientName.label,
      },
      matter = {
        id: matterName.value,
        name: matterName.label,
      };

    console.log("LIST items", clientMatters);

    let compare = `${clientName?.label}/${matterName?.label}`;
    const duplicate = clientMatters.some((matter) => {
      if (matter?.label === compare) {
        return true;
      } else {
        return false;
      }
    });

    //Add toast + refresh data
    if (duplicate) {
      toast.error(
        `Client (${client.name}) and Matter (${matter.name}) already exists`
      );
      close();
    } else {
      //Removes the onSubmit delay of the modal
      //Don't move this any lower to avoid the onSubmit delay bug
      close();
      const newClientMatterId = await addClientMatter(
        client,
        matter,
        companyId,
        localStorage.getItem("userId"),
        dispatch
      );

      //Tag newly created client to Operations Teams
      handleTaggingToOperations(operationsTeams?.id, newClientMatterId);

      //   setValueCopy(newClientMatterId);
      //setcmModal("");
      toast.success(
        `Successfully added Client (${clientName?.label}) and Matter (${matterName?.label})`
      );

      // Assuming Clients(), Matters(), and fetchCompanyClientMatters() return Promises,
      // we can await them sequentially or use Promise.all to execute them in parallel.
      try {
        await Promise.all([
          Clients(),
          Matters(),
          fetchCompanyClientMatters()
        ]);
      } catch (error) {
        // Handle errors from the Promises here, if necessary
        console.error('An error occurred while updating data:', error);
      }

      // Ensuring close is called after all operations have completed.
      close();
    }
  };

  // function removeDuplicateObjects(array) {
  //   const uniqueObjects = [];
  //   const usedIds = [];

  //   array.forEach((obj) => {
  //     if (!usedIds.includes(obj.id)) {
  //       uniqueObjects.push(obj);
  //       usedIds.push(obj.id);
  //     }
  //   });

  //   return uniqueObjects;
  // }

  const handleClientChanged = (newValue) => {
    if (newValue?.__isNew__) {
      addClients(newValue.label);
    } else {
      setclientName(newValue);
    }
  };

  const handleMatterChanged = (newValue) => {
    if (newValue?.__isNew__) {
      addMatters(newValue.label);
    } else {
      setmatterName(newValue);
    }
  };

  useEffect(() => {
    Clients();
    Matters();
  }, [companyId, dispatch, clientName, matterName]);

  const getTeams = async () => {
    let params = {
      query: qGetTeams,
      variables: {
        id: companyId,
      },
    };

    await API.graphql(params).then(async (teams) => {
      try {
        //console.log("teams", teams);
        if (teams.data.company == null) {
          //console.log("teamlist is null", teams);
        } else {
          let TeamList = teams.data.company.teams.items;
          if (TeamList.length > 0 && TeamList !== null) {
            let operations = TeamList.filter((team) => {
              return team.name === "Operations";
            });
            if (operations.length > 0) {
              setOperationsTeams(operations[0]);
            } else {
              //console.log("Client/Matter is not tagged. There are no teams named 'Operations'");
            }
          }
        }
      } catch (e) {
        console.error("ERROR at getqwithteams", e);
      }
    });
  };

  async function tagClientMatterPerTeam(id, cmIds) {
    const params = {
      query: mTeamClientMatterTag,
      variables: {
        teamId: id,
        clientMatters: cmIds,
      },
    };

    const response = await API.graphql(params).then((response) => {
      //console.log("tagClientMatter", response);
    });
  }

  const handleTaggingToOperations = async (id, newClientMatterId) => {
    const params = {
      query: qGetTeamsWithMembers,
      variables: {
        id: id,
        companyId: localStorage.getItem("companyId"),
      },
    };

    try {
      // Getting all client matters in Operations
      const response = await API.graphql(params);
      const CurrentTeam = response?.data?.team;

      let filtered = CurrentTeam?.clientMatters?.items?.map((item) => {
        return item.id;
      });

      if (!Array.isArray(filtered)) {
        filtered = [];
      }

      // Tag all the client matters along with the new client matter per team
      tagClientMatterPerTeam(operationsTeams?.id, [
        ...filtered,
        newClientMatterId,
      ]);

      // Getting all client matters per user in Operations + tagging per user
      getTeamsWithMembers(operationsTeams?.id, newClientMatterId);
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  //get team members + tag per member
  async function getTeamsWithMembers(teamId, newClientMatterId) {
    const params = {
      query: qGetTeamsWithMembers,
      variables: {
        id: teamId,
        companyId: localStorage.getItem("companyId"),
      },
    };

    const response = await API.graphql(params);
    const CurrentTeam = response.data?.team;
    //console.log("team members", CurrentTeam?.members?.items);

    const updatedMembers = await Promise.all(
      CurrentTeam?.members?.items.map(async (item) => {
        const cmAccessParam = item.user?.clientMatterAccess?.items.map((x) => ({
          clientMatterId: x.clientMatter?.id,
          userType: x.userType,
        }));

        const newData = {
          clientMatterId: newClientMatterId,
          userType: item.user.userType,
        };
        cmAccessParam.push(newData);

        const tagParams = {
          query: mTagClientMatter,
          variables: {
            clientMatterAccess: cmAccessParam,
            userId: item.user.id,
            companyId: localStorage.getItem("companyId"),
          },
        };

        const result = await API.graphql(tagParams);
        //console.log("newly-tagged client matter", result);

        return result;
      })
    );

    // Do something with updatedMembers if needed
    // ...

    // End of autotagging to operation
  }

  // const customContentRenderer = ({ props, state }) =>
  //     <div>
  //       Selected <b>{state.values.length}</b> out of{" "}
  //       <b>{props.options.length}</b>
  //     </div>
  //   ;

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="outline-none focus:outline-none fixed inset-0  z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-60 opacity-0"
      >
        <div
          ref={modalContent}
          className="no-scrollbar flex h-auto max-h-96 w-5/6 scale-90 flex-col overflow-y-scroll rounded-lg bg-white p-10 opacity-0"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-row">
            <div className="pb-5 text-lg font-semibold text-gray-900">
              Create New Client Matter
            </div>
            <button
              onClick={() => {
                close();
              }}
              className="ml-auto flex h-8 w-8 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-100 hover:bg-gray-300"
            >
              <svg
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z"
                  fill="#8A8A8A"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              width > 844
                ? "flex flex-row items-center gap-4 "
                : "flex flex-col items-center "
            }
          >
            <div className="mt-2 w-full ">
              <CreatableSelect
                options={clientsOptions}
                menuPlacement="top"
                isClearable
                isSearchable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                onChange={handleClientChanged}
                value={selectedClient}
                className="focus:outline-none relative w-full rounded border-0 bg-white shadow ring-blue-500 focus:ring"
                placeholder={
                  inputSaveClient !== null && inputSaveClient !== ""
                    ? inputSaveClient
                    : "Client"
                }
                inputValue={inputClient}
                onInputChange={setInputClient}
                onMenuClose={() => setSaveClient(inputClient)}
                onFocus={(e) => {
                  setInputClient(inputSaveClient);
                  setSaveClient("");
                }}
                blurInputOnSelect
              />
            </div>
            <div className="mt-2 w-full">
              <CreatableSelect
                options={mattersOptions}
                isClearable
                isSearchable
                onChange={handleMatterChanged}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                menuPlacement="top"
                value={selectedMatter}
                className="focus:outline-none relative w-full rounded border-0 bg-white shadow ring-blue-500 focus:ring"
                placeholder={
                  inputSaveMatter !== null && inputSaveMatter !== ""
                    ? inputSaveMatter
                    : "Matter"
                }
                inputValue={inputMatter}
                onInputChange={setInputMatter}
                onMenuClose={() => setSaveMatter(inputMatter)}
                onFocus={() => {
                  setInputMatter(inputSaveMatter);
                  setSaveMatter("");
                }}
                blurInputOnSelect
              />
            </div>
          </div>

          <button
            disabled={matterName === null || clientName === null}
            className={`ml-auto mt-8 mr-auto flex items-center justify-center gap-2 rounded p-2 font-bold text-white lg:px-4 ${
              matterName === null || clientName === null
                ? "cursor-not-allowed bg-green-500 opacity-60"
                : "bg-green-600"
            }`}
            type="submit"
            onClick={() => {
              handleNewMatter();
            }}
          >
            <span className="hidden font-bold lg:block">Add</span>
            <span className="font-semibold md:hidden">Create</span>
          </button>
        </div>
      </div>

      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}
