import React, { useState, useEffect } from "react";
import { GoogleLogout } from "react-google-login";
import { API } from "aws-amplify";
import jwt_decode from "jwt-decode";
import { FcGoogle } from "react-icons/fc";
import { refreshTokenSync } from "../../constants/RefreshTokenSync";

var momentTZ = require("moment-timezone");

const GoogleSignIn = (props) => {
  const companyId = localStorage.getItem("companyId");

  const gScope = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/gmail.labels",
    "https://www.googleapis.com/auth/gmail.modify",
    "https://www.googleapis.com/auth/gmail.send",
    "https://www.googleapis.com/auth/gmail.compose",
  ];

  const saveRefreshToken = `
    mutation connectToGmail($companyId: ID, $email: String, $userId: ID, $code: String, $userTimeZone: String) {
        gmailConnectFromCode(
            email: $email
            userId: $userId
            companyId: $companyId
            code: $code
            userTimeZone: $userTimeZone
        ) {
            id
            refreshToken
            userId
            companyId
            updatedAt
            isOutOfSync
        }
    }
    `;

  // OUTLOOK QUERIES
  const removeRefreshTokenOutlook = `
    mutation removeRefreshToken($email: String!, $companyId: String!) {
      outlookDisconnect(companyId: $companyId, email: $email) {
        id
      }
    }
  `;

  const qGetCompanyOutlookToken = `
  query getCompanyOutlookToken($companyId: String) {
    company(id: $companyId) {
      outlookToken {
        email
      }
    }
  }`;

  let client;

  function initClient() {
    // const redirectUri = "http://localhost:3000/oauth2callback";

    client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: gScope.join(" "),
      ux_mode: "popup",
      // redirect_uri: redirectUri,
      callback: handleCallBackResponseCode,
    });
  }

  function getAuthCode() {
    client.requestCode();
  }

  const handleCallBackResponseCode = async (response) => {
    const params = {
      query: saveRefreshToken,
      variables: {
        companyId: localStorage.getItem("companyId"),
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("connectedGmailAccount"),
        code: response.code,
        userTimeZone: momentTZ.tz.guess(),
      },
    };

    // console.log("Params", {
    //   companyId: localStorage.getItem("companyId"),
    //   userId: localStorage.getItem("userId"),
    //   email: localStorage.getItem("connectedGmailAccount"),
    //   code: response.code,
    //   userTimeZone: momentTZ.tz.guess()
    // });

    try {
      const r = await API.graphql(params);
      console.log("Response: ", r);
      const gmailToken = r.data.gmailConnectFromCode;

      if (gmailToken) {
        const gmailRefreshToken = gmailToken.refreshToken;
        const gmailIsOutOfSync =
          gmailToken.isOutOfSync === null ||
          gmailToken.isOutOfSync === undefined
            ? false
            : gmailToken.isOutOfSync;

        props.setRefreshToken(gmailRefreshToken);
        props.setIsOutOfSync(gmailIsOutOfSync);

        localStorage.setItem(
          "connectedGmailAccount",
          localStorage.getItem("connectedGmailAccount")
        );
        console.log("Connected to Gmail");

        // Comment for release
        // await refreshTokenSync(gmailToken?.id, gmailToken?.refreshToken);     
        window.location.reload();
        props.getSavedAuthToken();
      } else {
        window.location.reload();
        console.log("Not connected");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  useEffect(() => {
    initClient();
  }, []);

  const handleCallBackResponse = async (response) => {
    console.log("Encoded JWT", response.credential);
    var userObject = jwt_decode(response.credential);
    console.log(userObject);
    const currentConnected = localStorage.getItem("connectedGmailAccount");

    //MMA 1013

    //When connectedGmailAccount is different from response credential email
    //Will force sign out/ remove the refresh token in our MMA app
    // if (currentConnected && currentConnected !== userObject.email) {
    //   handleSignOut();
    //   localStorage.setItem("connectedGmailAccountName", userObject.name);
    //   localStorage.setItem("connectedGmailAccount", userObject.email);
    //   localStorage.setItem("accessedEmail", userObject.email);
    //   props.setConnectedEmail(userObject.email);

    //   getAuthCode();
    // } else {

    //   localStorage.setItem("connectedGmailAccountName", userObject.name);
    //   localStorage.setItem("connectedGmailAccount", userObject.email);
    //   localStorage.setItem("accessedEmail", userObject.email);
    //   props.setConnectedEmail(userObject.email);

    //   getAuthCode();
    // }

    // TEMPORARY SOLUTION
    checkConnectedOutlook(); //DISCONNECT CONNECTED OUTLOOK IF IT EXISTS

    localStorage.setItem("connectedGmailAccountName", userObject.name);
    localStorage.setItem("connectedGmailAccount", userObject.email);
    localStorage.setItem("accessedEmail", userObject.email);
    props.setConnectedEmail(userObject.email);

    getAuthCode();
  };

  const checkConnectedOutlook = async () => {
    const params = {
      query: qGetCompanyOutlookToken,
      variables: {
        companyId: companyId,
      },
    };

    await API.graphql(params).then((result) => {
      console.log("Result:", result);

      const email = result.data.company.outlookToken.email; // connected outlook email

      if (email) {
        disconnectOutlook(email, removeRefreshTokenOutlook);
        localStorage.removeItem("connectedOutlookAccount");
      }
    });
  };

  const disconnectOutlook = async (connectedEmail, query) => {
    const params = {
      query: query,
      variables: {
        email: connectedEmail,
        companyId: companyId,
      },
    };
    const success = await API.graphql(params);
  };

  function handleSignOut(event) {
    const removeRefreshToken = `
    mutation removeRefreshToken($email: String) {
      gmailDisconnect(email: $email) {
        id
      }
    }
    `;
    const params = {
      query: removeRefreshToken,
      variables: {
        email: localStorage.getItem("connectedGmailAccount"),
      },
    };

    API.graphql(params).then(() => {
      localStorage.removeItem("connectedGmailAccount");
      window.location.reload();
    });
  }

  function handleForceSignOut(event) {
    const removeRefreshToken = `
    mutation removeRefreshToken($email: String) {
      gmailDisconnect(email: $email) {
        id
      }
    }
    `;
    const params = {
      query: removeRefreshToken,
      variables: {
        email: localStorage.getItem("connectedGmailAccount"),
      },
    };

    API.graphql(params).then(() => {
      console.log("Forced SignOut");
    });
  }

  useEffect(() => {
    /* global google */
    const params = {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallBackResponse,
      scope: gScope.join(" "),
    };
    console.log(params);
    google.accounts.id.initialize(params);
    //google.accounts.id.prompt();

    google.accounts.id.renderButton(document.getElementById("signInBtn"), {
      theme: "outline",
      size: "large",
    });
  }, []);

  let actionSate = "";

  const connectedState = (
      // <div
      //   className="flex items-center justify-center px-4 py-2 text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      //   onClick={(e) => handleSignOut(e)}
      // >
      <div
        className="cursor-pointer flex px-2 py-2 text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        style={{ flexDirection: "row" }}
        onClick={(e) => handleSignOut(e)}
      >
        <div className="flex items-center">
          <FcGoogle />
        </div>
        <div className="flex font-medium ml-2">Sign Out</div>
        {/* Sign Out - {localStorage.getItem("connectedGmailAccount")} */}
      </div>
      //</div>
    ),
    notConnectedState = <div id="signInBtn" className="flex flex-auto"></div>,
    outOfSyncState = (
      <>
        <i className="block text-red-500 mt-4 mb-2">
          An error has occurred. Please re-login your account to continue
          syncing emails.
          <br />
        </i>
        {notConnectedState}
      </>
    );

  if (props.isOutlookIntegrated !== null) {
    if (props.isOutOfSync || props.isOutlookIntegrated) {
      actionSate = outOfSyncState;
    } else {
      actionSate = connectedState;
    }
  } else {
    actionSate = notConnectedState;
  }
  return <div>{actionSate}</div>;
};

export default GoogleSignIn;
