import { useContext } from "react";
import Select from "react-select";

import { FormBuilderContext } from "./reducers/StoreProvider";
import { SinglePageArea } from "../form-shared-components/SharedPageArea";

//================================================
// Main Form Preview Component
//================================================

export default function FormPreview() {
  const { state, dispatch } = useContext(FormBuilderContext)
  const { currentPage } = state.appState
  const { pages } = state.formData

  return (
    <div className="w-full py-14 flex flex-col items-center gap-4">
      {
        pages.length ?
          <SinglePageArea 
            pageContent={pages[currentPage - 1]} 
            stateContext={{state, dispatch}} 
          /> : null
      }
      <ActionButtons />
    </div>
  );
}

function ActionButtons() {
  const { state, dispatch } = useContext(FormBuilderContext)
  const { currentPage } = state.appState
  const { pages } = state?.formData

  const handleSwitchPage = (page) =>{
    dispatch({
      type: 'SWITCH_PAGE',
      payload: page
    })
  }

  return (
    <div 
      className="w-full flex justify-between "
      style={{
        maxWidth: "50rem",
      }}
    >
      <StyledButton 
        type='secondary' 
        label='Back' 
        onClick={()=>currentPage === 1 ?  null : handleSwitchPage(currentPage - 1)}
      />
      <StyledButton 
        type='secondary' 
        label='Next' 
        onClick={()=>currentPage === pages.length ?  null : handleSwitchPage(currentPage + 1)}
      />
    </div>
  )
}


function StyledButton({type, label, className, onClick}){

  const buttonStyles = {
    primary: 'bg-green-500 text-white',
    secondary: 'bg-gray-200',
  }

  return(
    <button
      className={`py-2 px-6 font-normal text-base rounded-md ${buttonStyles[type]} ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}