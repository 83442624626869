import "../../assets/styles/Dashboard.css";
import { API, Auth } from "aws-amplify";
import { FaTimes, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { BsArrowUpRight } from "react-icons/bs";
import { HiDotsHorizontal } from "react-icons/hi";
import * as IoIcons from "react-icons/io5";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { AppRoutes } from "../../constants/AppRoutes";
import { Link } from "react-router-dom";
import Loading from "../loading/loading";
import { MatterContext } from "./index";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import AccessControl from "../../shared/accessControl";
import DefaultBackground from "../../assets/images/default-clientmatter-background.png";
import {
  dateFormatter,
  timezoneFormatter,
} from "../../shared/userPreferredDateTime";
// TANSTACK VIRTUALIZED
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  // useSortBy,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

export function ClientMatters() {
  const setshowDeleteModal = (displayStatus, id) => {
    onShowDeleteModal(displayStatus, id);
  };

  const {
    clientMatter,
    view,
    onShowDeleteModal,
    allowDeleteMatter,
    allowViewCreateUpdateMatter,
    allowOpenMatter,
    allowOpenFileBucket,
    allowOpenBackground,
    allowOpenRFI,
    allowOpenLabelsLibrary,
    loading,
    error,
    clientMatterListDateSorted,
    isClientMatterFiltered,
    activeButton,
  } = useContext(MatterContext);
  const [passMattersOverview, setPassMattersOverview] = useState(false);

  var activeUserType = localStorage.getItem("userType");

  const { height, width } = useWindowDimensions();

  let sortedMatter = [];

  if (activeButton === "View All") {
    sortedMatter = [...clientMatter].sort((a, b) => {
      const dateA = a?.createdAt ? new Date(a?.createdAt) : null;
      const dateB = b?.createdAt ? new Date(b?.createdAt) : null;
      return dateB - dateA;
    });
  } else {
    sortedMatter = getItemsOpenedInTheLast30Days([...clientMatter]).sort((a, b) => {
      const dateA = a?.lastOpenedAt ? new Date(a?.lastOpenedAt) : null;
      const dateB = b?.lastOpenedAt ? new Date(b?.lastOpenedAt) : null;
      return dateB - dateA;
    });
  }

  // TANSTACK VIRTUALIZED
  // const tileContainerRef = useRef(null);

  // const tile = useReactTable({
  //   data: sortedMatter,
  //   enableRowSelection: true,
  //   getCoreRowModel: getCoreRowModel(),
  //   getSortedRowModel: getSortedRowModel(),
  // });

  // const { rows } = tile.getRowModel();

  // const rowVirtualizer = useVirtualizer({
  //   getScrollElement: () => tileContainerRef.current,
  //   parentRef: tileContainerRef,
  //   count: rows.length,
  //   overscan: rows.length,
  //   estimateSize: useCallback(() => 81, []), // THIS IS AN ESTIMATE FOR WEB, ADD CONDITION FOR MOBILE
  // });

  // const virtualRows = rowVirtualizer.getVirtualItems();

  // const totalSize = rowVirtualizer.getTotalSize();

  // const vTablePaddingTop =
  //   virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  // const vTablePaddingBottom =
  //   virtualRows.length > 0
  //     ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
  //     : 0;

  function getItemsOpenedInTheLast30Days(items) {
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // Compute 30 days in milliseconds
    const thirtyDaysAgo = new Date(Date.now() - thirtyDaysInMillis);

    return items.filter(item => {
        if (item.lastOpenedAt === null) return false; // Skip items never opened
        const lastOpenedDate = new Date(item.lastOpenedAt);
        return lastOpenedDate >= thirtyDaysAgo;
    });
}

  const [showDropdown, setShowDropdown] = useState(
    Array(sortedMatter.length).fill(false)
  );
  const dropdownRefs = useRef([]);

  const [downloadLinks, setDownloadLinks] = useState(new Map());
  const [previousSortedMatter, setPreviousSortedMatter] = useState(null);

  const hasProjectLocation = (item) => {
    if (item?.briefs && item.briefs.length > 0) {
      for (const brief of item.briefs) {
        if (brief.name === "Project Location") {
          return true;
        }
      }
    }
    return false;
  };

  const getAttachedFileForProjectLocation = (item) => {
    if (hasProjectLocation(item)) {
      const projectLocationBriefs = item.briefs.filter(
        (brief) => brief.name === "Project Location"
      );
      const fileIds = [];
      projectLocationBriefs.forEach((projectLocationBrief) => {
        if (
          projectLocationBrief &&
          projectLocationBrief.backgrounds &&
          projectLocationBrief.backgrounds.items
        ) {
          projectLocationBrief.backgrounds.items.forEach((background) => {
            if (background.files && background.files.items) {
              background.files.items.forEach((file) => {
                if (file.id) {
                  fileIds.push(file.id);
                }
              });
            }
          });
        }
      });
      return fileIds.length > 0 ? fileIds : null;
    }
    return null;
  };

  const previewAndDownloadFile = async (id) => {
    if (id === null) {
      throw new Error("ID cannot be null. Please provide a valid ID.");
    }

    const FILE_DOWNLOAD_LINK_QUERY = `
    query getFileDownloadLink($id: ID) {
      file(id: $id) {
        downloadURL
        s3ObjectKey
        type
      }
    }`;

    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    try {
      const result = await API.graphql(params);
      let { downloadURL, s3ObjectKey } = result.data.file;
      return downloadURL;
    } catch (error) {
      console.error("Error fetching download URL:", error);
      throw error;
    }
  };

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      // Check if sortedMatter has changed since the last render
      if (sortedMatter && sortedMatter.length > 0) {
        let mattersWithoutPhoto = sortedMatter.filter(
          (item) => !downloadLinks.has(item.id)
        );

        const clientMatterAppended = await Promise.all(
          mattersWithoutPhoto.map(async (item) => {
            const projectLocationBackground =
              getAttachedFileForProjectLocation(item);
            // console.log("projectLocationBackground", projectLocationBackground)
            if (
              projectLocationBackground &&
              projectLocationBackground.length > 0
            ) {
              try {
                const link = await previewAndDownloadFile(
                  projectLocationBackground.join(", ")
                );
                return { id: item.id, downloadLink: link };
              } catch (error) {
                console.error("Error fetching download URL:", error);
                return { id: item.id, downloadLink: null };
              }
            }
            return { id: item.id, downloadLink: null };
          })
        );

        if (isMounted) {
          // setDownloadLinks(links.filter(link => link !== null));
          let filteredLinks = clientMatterAppended.filter(
            (item) => item !== undefined && item !== null
          );

          if (filteredLinks.length > 0) {
            setDownloadLinks((links) => {
              let newMap = new Map(links);
              filteredLinks.forEach((item) => {
                newMap.set(item.id, item.downloadLink);
              });
              return newMap;
            });
          }
          // Update previousSortedMatter with the current sortedMatter
          setPreviousSortedMatter(sortedMatter);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [clientMatter]);

  useEffect(() => {
    function handleClickOutside(event) {
      for (let i = 0; i < dropdownRefs.current.length; i++) {
        if (
          dropdownRefs.current[i] &&
          dropdownRefs.current[i].contains(event.target)
        ) {
          // Click inside dropdown, do nothing
          return;
        }
      }
      // Click outside dropdown, close all dropdowns
      setShowDropdown(Array(sortedMatter.length).fill(false));
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortedMatter]);

  const handleDotClick = (index) => {
    const newDropdownState = [...showDropdown];
    newDropdownState[index] = !newDropdownState[index];
    setShowDropdown(newDropdownState);
  };

  // console.log("List of Client/Matters", clientMatter);

  const currentUserEmail = localStorage.getItem("email");

  function userAccessibleMatter(clientMatterId) {
    var arrEmails = [
        "mmatest.khr+uat@gmail.com",
        "mmatest.khr+access@gmail.com",
        "meredith.ziegler@contractsspecialist.com.au",
      ],
      arrClientMatters = [
        "d9f93246-e0dd-49d1-9dd8-f148df2f30bb",
        "95813381-661c-4512-89dc-e1f2fe4181bb",
      ]; // Deanna Spicer/Dwyer Building

    if (
      arrClientMatters.includes(clientMatterId) &&
      arrEmails.includes(currentUserEmail)
    ) {
      return false;
    } else {
      return true;
    }
  }

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
  const [isActive, setIsActive] = useState([]);

  function handleActiveState(item, bool) {
    if (bool) {
      if (
        isActive.find((temp) => {
          return temp === item;
        }) === undefined
      ) {
        setIsActive([...isActive, item]);
      }
    } else {
      setIsActive((current) =>
        current.filter((id) => {
          return id !== item;
        })
      );
    }
  }

  function checkIsActive(item) {
    return (
      isActive.find((temp) => {
        return temp === item;
      }) !== undefined
    );
  }

  useEffect(() => {
    featureAccessFilters();
  }, []);

  const featureAccessFilters = async () => {
    var { data, status, message } = await AccessControl("MATTERSOVERVIEW");
    const featuresList = data?.features;
    if (status !== "restrict") {
      setPassMattersOverview(true);
    } else {
      setPassMattersOverview(false);
    }

    // Check access control for "BACKGROUND" feature
    //const backgroundAccess = await AccessControl("BACKGROUND");
    //if (backgroundAccess.status !== "restrict") {
    //setAllowOpenBackground(true); // Allow opening "BACKGROUND"
    //} else {
    //console.log(backgroundAccess.message); // Log access control message
    //}
  };

  return (
    <>
      {view === "grid" || width < 640 ? (
        <>
          {isClientMatterFiltered ? (
            loading ? (
              <>
                {/* <Loading content={'Please wait...'} /> */}
                {[...Array(16).keys()].map((data, index) => {
                  return (
                    <div
                      className="w-full bg-gray-100 rounded-lg p-5 hover:shadow-md transition-shadow"
                      key={`loading-${index}`}
                    >
                      <div className="z-20">
                        <div className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none z-20">
                          <div className="dropdown">
                            <button
                              className={
                                "bg-gray-100 p-0.5 sm:p-0 text-gray-300 sm:text-gray-300 font-semibold rounded inline-flex "
                              }
                              style={{
                                borderRadius: "50%",
                              }}
                            >
                              <IoIcons.IoEllipsisVertical />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4
                          tabIndex="0"
                          className="focus:outline-none text-gray-800 dark:text-gray-100 font-semibold sm:font-bold"
                        >
                          <SkeletonTheme width={"200px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </h4>
                        <p
                          tabIndex="0"
                          className="focus:outline-none text-gray-400 sm:text-gray-800 dark:text-gray-100 text-sm mb-3"
                        >
                          <SkeletonTheme width={"200px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </p>
                        <br />
                        <div className="grid grid-cols-4 gap-4">
                          <div className="col-span-1">
                            <SkeletonTheme width={"32px"} height={"32px"}>
                              <Skeleton circle={true} />
                            </SkeletonTheme>
                          </div>

                          <div className="col-span-3 grid place-self-end">
                            <Skeleton count={1} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : clientMatterListDateSorted.length <= 0 ? (
              <span>No result found.</span>
            ) : (
              <>
                {clientMatterListDateSorted.map(
                  (item, index) =>
                    userAccessibleMatter(item.id) && (
                      <Link
                        // to={
                        //   allowOpenMatter
                        //     ? `${AppRoutes.MATTERSOVERVIEW}/${item.id}`
                        //     : `#`
                        // }
                        to={
                          passMattersOverview
                            ? `${AppRoutes.MATTERSOVERVIEW}/${item.id}`
                            : `${AppRoutes.BRIEFS}/${
                                item.id
                              }/?matter_name=${utf8_to_b64(
                                item?.matter?.name
                              )}&client_name=${utf8_to_b64(item?.client?.name)}`
                        }
                      >
                        <div
                          className="w-full h-full bg-gray-100 rounded-lg p-5 hover:shadow-md transition-shadow"
                          key={item.id}
                        >
                          <div className="z-20">
                            {allowOpenFileBucket ||
                            allowOpenBackground ||
                            allowOpenRFI ||
                            allowOpenLabelsLibrary ||
                            allowDeleteMatter ? (
                              <div className="ml-auto bg-transparent border-0 text-black opacity-4 float-right font-semibold outline-none focus:outline-none z-20">
                                {allowDeleteMatter && (
                                  <button
                                    className="hover:bg-gray-200"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <FaTimes
                                      className="h-4 w-4 text-gray-600"
                                      onClick={() =>
                                        setshowDeleteModal(true, item.id)
                                      }
                                    />
                                  </button>
                                )}
                              </div>
                            ) : null}
                            <div>
                              <h4
                                tabIndex="0"
                                className="focus:outline-none text-gray-800 dark:text-gray-100 font-semibold sm:font-bold"
                              >
                                {item?.matter?.name}
                              </h4>
                              <p
                                tabIndex="0"
                                className="focus:outline-none text-gray-400 sm:text-gray-800 dark:text-gray-100 text-sm mb-3"
                              >
                                {item?.client?.name}
                              </p>
                              <br />
                              <div className="grid grid-cols-4 gap-4">
                                <div className="col-span-1">
                                  <img
                                    className="relative inline object-cover w-8 h-8 border-2 border-white rounded-full"
                                    src={
                                      item.substantially_responsible
                                        .profile_picture
                                    }
                                    alt={item.substantially_responsible.name}
                                    title={item.substantially_responsible.name}
                                  />
                                </div>

                                <div className="col-span-3 grid place-self-end">
                                  {allowViewCreateUpdateMatter && (
                                    <p
                                      tabIndex="0"
                                      className="focus:outline-none text-gray-500 dark:text-gray-100 text-xs text-right"
                                    >
                                      {item.matterNumber !== null ? (
                                        <div className="hidden">
                                          <span
                                            style={{
                                              fontSize: "0.9em",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {item.matterNumber}
                                            {/* {index} */}

                                            <br />
                                          </span>
                                        </div>
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "0.9em",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {item.client && item.matter ? (
                                            <div className="hidden">
                                              <>
                                                {item.client.name
                                                  .substring(0, 3)
                                                  .toUpperCase()}
                                                -{item.client.id.split("-")[0]}/
                                                {item.matter.id.split("-")[0]}
                                              </>
                                            </div>
                                          ) : (
                                            <div className="hidden">
                                              <span>
                                                Client or Matter doesn't exist
                                                please contact your
                                                administrator.
                                              </span>
                                            </div>
                                          )}
                                          {/* {index} */}

                                          <br />
                                        </span>
                                      )}

                                      {item.updatedAt !== null && (
                                        <>
                                          {`Updated on ${dateFormatter(
                                            item.updatedAt
                                          )}`}
                                          <br />
                                        </>
                                      )}
                                      {item.lastOpenedAt !== null && (
                                        <>
                                          {`Last Opened on ${dateFormatter(
                                            item.lastOpenedAt
                                          )}`}
                                          <br />
                                        </>
                                      )}
                                      {`Created on ${dateFormatter(
                                        item.createdAt,
                                        "dd mmm yyyy"
                                      )}`}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                )}
              </>
            )
          ) : loading ? (
            <>
              {[...Array(16).keys()].map((data, index) => {
                return (
                  <div className="w-full bg-white rounded-lg overflow-hidden shadow-lg p-4">
                    <div className="relative w-full h-64 bg-gray-200"></div> {/* Placeholder for image */}
                    <div className="absolute bottom-0 left-0 right-0 text-white p-4">
                      <div className="flex justify-between items-center text-xs text-white">
                        <div className="text-xs">
                          <Skeleton width={80} height={10} />
                          <Skeleton width={80} height={10} />
                        </div>
                        <div className="flex items-center space-x-1">
                          <Skeleton circle={true} width={24} height={24} />
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="mt-1 flex items-center justify-between">
                        <div>
                          <Skeleton width={150} height={16} />
                          <Skeleton width={100} height={12} />
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-gray-500 rounded-full w-5 h-5">
                            <Skeleton width={20} height={20} />
                          </div>
                          <div className="text-gray-500 rounded-full w-5 h-5">
                            <Skeleton width={20} height={20} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : sortedMatter.length <= 0 ? (
            <span>No result found.</span>
          ) : (
            <>
              {/* {virtualRows.map((virtualRow) => { // Use virtualRows instead of sortedMatter */}
                {/* const row = rows[virtualRow.index];
                const item = row.original;
                const index = row.index; */}

              {sortedMatter.map((item, index) => { // Use virtualRows instead of sortedMatter
                const timeAgo = () => {
                  let updatedAt = item?.lastOpenedAt
                    ? new Date(item?.lastOpenedAt)
                    : new Date();
                  const now = new Date();
                  const diffMs = now - updatedAt;
                  const diffMinutes = Math.round(diffMs / (1000 * 60));

                  if (diffMinutes < 60) {
                    return `${diffMinutes} minute${
                      diffMinutes !== 1 ? "s" : ""
                    } ago`;
                  } else if (diffMinutes < 24 * 60) {
                    const diffHours = Math.floor(diffMinutes / 60);
                    return `${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`;
                  } else {
                    const diffDays = Math.floor(diffMinutes / (24 * 60));
                    return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
                  }
                };

                if (userAccessibleMatter(item.id)) {
                  return (
                    <div className="relative" key={item.id}>
                      <div className="rounded-lg overflow-hidden shadow-lg max-w-sm relative">
                        {/* Image container */}
                        <div className="relative">
                          <div className="relative w-full h-64">
                            {downloadLinks.get(item.id) ? (
                              <img
                                src={downloadLinks.get(item.id)}
                                alt="Modern House"
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <img
                                src={DefaultBackground}
                                alt="Default Background"
                                className="z-0 absolute w-full h-full object-cover"
                              />
                            )}
                          </div>
                          {/* Transparent overlay */}
                          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white p-4">
                            <div className="flex justify-between items-center text-xs text-white">
                              <div className="text-xs">
                                <p className="p-1">
                                  Last opened:&nbsp;
                                  {item?.lastOpenedAt
                                    ? dateFormatter(item.lastOpenedAt)
                                    : "Never"}
                                </p>
                                <p className="p-1">
                                  Created:&nbsp;
                                  {item?.createdAt
                                    ? dateFormatter(item.createdAt)
                                    : "N/A"}
                                </p>
                                {/* <p>
                                                    {downloadLinks[index]}
                                                  </p> */}
                              </div>
                              <div className="flex items-center space-x-1">
                                <img
                                  className="relative inline object-cover w-8 h-8 border-2 border-white rounded-full"
                                  src={
                                    item.substantially_responsible
                                      .profile_picture
                                  }
                                  alt={item.substantially_responsible.name}
                                  title={item.substantially_responsible.name}
                                />
                              </div>
                            </div>
                          </div>
                          {/* End of Transparent overlay */}
                        </div>
                        {/* End of Image container */}
                        <div className="p-4">
                          <div className="mt-1 flex items-center justify-between">
                            <div>
                              <div className="text-lg font-semibold text-gray-800">
                                {item?.matter?.name} &amp; {item?.client?.name}
                              </div>
                              <div className="text-xs text-gray-500">
                                Edited {timeAgo()}
                              </div>
                            </div>
                            <div className="flex items-center space-x-2 relative">
                              <div className="text-gray-500 rounded-full w-5 h-5 flex items-center justify-center">
                                <Link
                                  to={
                                    passMattersOverview
                                      ? `${AppRoutes.MATTERSOVERVIEW}/${item.id}`
                                      : `${AppRoutes.BRIEFS}/${
                                          item.id
                                        }/?matter_name=${utf8_to_b64(
                                          item?.matter?.name
                                        )}&client_name=${utf8_to_b64(
                                          item?.client?.name
                                        )}`
                                  }
                                >
                                  <BsArrowUpRight />
                                </Link>
                              </div>
                              <div
                                className="text-gray-500 rounded-full w-5 h-5 flex items-center justify-center cursor-pointer"
                                onClick={() => handleDotClick(index)}
                              >
                                <HiDotsHorizontal />
                              </div>
                              {showDropdown[index] && (
                                <div
                                  className="absolute w-48 bg-white border border-gray-200 rounded-md shadow-lg z-20 right-0 mb-28"
                                  ref={(el) =>
                                    (dropdownRefs.current[index] = el)
                                  }
                                >
                                  <div
                                    className="py-1"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="options-menu"
                                  >
                                    <Link
                                      to={
                                        passMattersOverview
                                          ? `${AppRoutes.MATTERSOVERVIEW}/${item.id}`
                                          : `${AppRoutes.BRIEFS}/${
                                              item.id
                                            }/?matter_name=${utf8_to_b64(
                                              item?.matter?.name
                                            )}&client_name=${utf8_to_b64(
                                              item?.client?.name
                                            )}`
                                      }
                                    >
                                      <div className="flex items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer w-full">
                                        <span style={{ marginRight: "0.5rem" }}>
                                          <MdEdit />
                                        </span>
                                        Edit
                                      </div>
                                    </Link>
                                    <div
                                      className="flex items-center justify-start px-4 py-2 text-sm text-red-500 hover:bg-gray-100 cursor-pointer w-full"
                                      onClick={(e) =>
                                        setshowDeleteModal(true, item.id)
                                      }
                                    >
                                      <span style={{ marginRight: "0.5rem" }}>
                                        <FaTrash />
                                      </span>
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null; // If userAccessibleMatter(item.id) is false, return null
              })}
            </>
          )}
        </>
      ) : (
        <>
          {/* mobile view */}
          {isClientMatterFiltered ? (
            loading ? (
              <Loading content={"Please wait..."} />
            ) : clientMatterListDateSorted.length <= 0 ? (
              <span>No result found.</span>
            ) : (
              <>
                {clientMatterListDateSorted.map((item) => (
                  <div
                    className="w-full h-42 bg-gray-100 rounded-lg border border-gray-200 py-5 px-4 hover:shadow-md transition-shadow"
                    key={item.id}
                  >
                    <div>
                      {allowOpenFileBucket ||
                      allowOpenRFI ||
                      allowOpenBackground ||
                      allowOpenLabelsLibrary ||
                      allowDeleteMatter ? (
                        <div className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                          <div className="dropdown relative">
                            <button
                              className={
                                "bg-gray-100 p-0.5 sm:p-0 text-gray-400 sm:text-gray-700 font-semibold rounded inline-flex "
                              }
                              style={{
                                backgroundColor:
                                  checkIsActive(item.id) && width < 640
                                    ? "rgb(31 41 55)"
                                    : "",
                                borderRadius:
                                  checkIsActive(item.id) && width < 640
                                    ? "50%"
                                    : "",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleActiveState(item.id, true);
                              }}
                            >
                              <IoIcons.IoEllipsisVertical
                                className={
                                  checkIsActive(item.id) && width < 640
                                    ? "text-white"
                                    : ""
                                }
                              />
                            </button>
                            <ul
                              className={
                                (checkIsActive(item.id) && width < 640
                                  ? "block"
                                  : "hidden") +
                                " sm:absolute text-gray-700 p-4 sm:p-2 font-semibold shadow-md z-50 dropDownCSS w-max"
                              }
                              style={{
                                left: "-140px",
                              }}
                            >
                              {allowOpenRFI ? (
                                <li className="p-2">
                                  <Link
                                    to={`${AppRoutes.MATTERSRFI}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      item?.matter?.name
                                    )}&client_name=${utf8_to_b64(
                                      item?.client?.name
                                    )}`}
                                  >
                                    RFI List
                                  </Link>
                                </li>
                              ) : null}
                              {allowOpenFileBucket ? (
                                <li className="p-2">
                                  <Link
                                    to={`${AppRoutes.FILEBUCKET}/${
                                      item.id
                                    }/000/?matter_name=${utf8_to_b64(
                                      item?.matter?.name
                                    )}&client_name=${utf8_to_b64(
                                      item?.client?.name
                                    )}`}
                                  >
                                    File Bucket
                                  </Link>
                                </li>
                              ) : null}
                              {allowOpenBackground ? (
                                <li className="px-2 sm:p-2 rounded-lg flex">
                                  <Link
                                    className="py-4 sm:p-0 border-b w-full sm:border-0"
                                    to={`${AppRoutes.BRIEFS}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      item?.matter?.name
                                    )}&client_name=${utf8_to_b64(
                                      item?.client?.name
                                    )}`}
                                  >
                                    Background Page
                                  </Link>
                                </li>
                              ) : null}
                              {allowOpenLabelsLibrary ? (
                                <li className="p-2">
                                  <Link to={`${AppRoutes.LABELS}/${item.id}`}>
                                    Manage User Access
                                  </Link>
                                </li>
                              ) : null}
                              {allowDeleteMatter && (
                                <li
                                  className="p-2 cursor-pointer"
                                  onClick={() =>
                                    setshowDeleteModal(true, item.id)
                                  }
                                >
                                  Delete
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                      {/* <Link to={redirectToBackground}> */}

                      <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-2">
                          <Link
                            // to={
                            //   allowOpenMatter
                            //     ? `${AppRoutes.MATTERSOVERVIEW}/${item.id}`
                            //     : `#`
                            // }
                            to={`${AppRoutes.MATTERSOVERVIEW}/${item.id}`}
                          >
                            <h4
                              tabIndex="0"
                              className="focus:outline-none text-gray-800 dark:text-gray-100 font-bold"
                            >
                              {item?.matter?.name}
                            </h4>
                            <p
                              tabIndex="0"
                              className="focus:outline-none text-gray-600 font-medium mb-4 text-xs"
                            >
                              {item?.client?.name}
                            </p>
                          </Link>

                          {allowViewCreateUpdateMatter && (
                            <p
                              tabIndex="0"
                              className="focus:outline-none text-gray-500 dark:text-gray-100 text-xs"
                            >
                              {`Created on ${dateFormatter(item.createdAt)}`}
                              {item.updatedAt !== null && (
                                <>
                                  {` / Updated on ${dateFormatter(
                                    item.updatedAt
                                  )}`}
                                  <br />
                                </>
                              )}
                              {item.lastOpenedAt !== null && (
                                <>
                                  {` / Last Opened on ${dateFormatter(
                                    item.lastOpenedAt
                                  )}`}
                                  <br />
                                </>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )
          ) : loading ? (
            <Loading content={"Please wait..."} />
          ) : sortedMatter.length <= 0 ? (
            <span>No result found.</span>
          ) : (
            <>
              {sortedMatter.map((item) => (
                <div
                  className="w-full h-42 bg-gray-100 rounded-lg border border-gray-200 py-5 px-4 hover:shadow-md transition-shadow"
                  key={item.id}
                >
                  <div>
                    {allowOpenFileBucket ||
                    allowOpenRFI ||
                    allowOpenBackground ||
                    allowOpenLabelsLibrary ||
                    allowDeleteMatter ? (
                      <div className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                        <div className="dropdown relative">
                          <button
                            className={
                              "bg-gray-100 p-0.5 sm:p-0 text-gray-400 sm:text-gray-700 font-semibold rounded inline-flex "
                            }
                            style={{
                              backgroundColor:
                                checkIsActive(item.id) && width < 640
                                  ? "rgb(31 41 55)"
                                  : "",
                              borderRadius:
                                checkIsActive(item.id) && width < 640
                                  ? "50%"
                                  : "",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleActiveState(item.id, true);
                            }}
                          >
                            <IoIcons.IoEllipsisVertical
                              className={
                                checkIsActive(item.id) && width < 640
                                  ? "text-white"
                                  : ""
                              }
                            />
                          </button>
                          <ul
                            className={
                              (checkIsActive(item.id) && width < 640
                                ? "block"
                                : "hidden") +
                              " sm:absolute text-gray-700 p-4 sm:p-2 font-semibold shadow-md z-50 dropDownCSS w-max"
                            }
                            style={{
                              left: "-140px",
                            }}
                          >
                            {allowOpenRFI ? (
                              <li className="p-2">
                                <Link
                                  to={`${AppRoutes.MATTERSRFI}/${
                                    item.id
                                  }/?matter_name=${utf8_to_b64(
                                    item?.matter?.name
                                  )}&client_name=${utf8_to_b64(
                                    item?.client?.name
                                  )}`}
                                >
                                  RFI List
                                </Link>
                              </li>
                            ) : null}
                            {allowOpenFileBucket ? (
                              <li className="p-2">
                                <Link
                                  to={`${AppRoutes.FILEBUCKET}/${
                                    item.id
                                  }/000/?matter_name=${utf8_to_b64(
                                    item?.matter?.name
                                  )}&client_name=${utf8_to_b64(
                                    item?.client?.name
                                  )}`}
                                >
                                  File Bucket
                                </Link>
                              </li>
                            ) : null}
                            {allowOpenBackground ? (
                              <li className="px-2 sm:p-2 rounded-lg flex">
                                <Link
                                  className="py-4 sm:p-0 border-b w-full sm:border-0"
                                  to={`${AppRoutes.BRIEFS}/${
                                    item.id
                                  }/?matter_name=${utf8_to_b64(
                                    item?.matter?.name
                                  )}&client_name=${utf8_to_b64(
                                    item?.client?.name
                                  )}`}
                                >
                                  Background Page
                                </Link>
                              </li>
                            ) : null}
                            {allowOpenLabelsLibrary ? (
                              <li className="p-2">
                                <Link to={`${AppRoutes.LABELS}/${item.id}`}>
                                  Manage User Access
                                </Link>
                              </li>
                            ) : null}
                            {allowDeleteMatter && (
                              <li
                                className="p-2 cursor-pointer"
                                onClick={() =>
                                  setshowDeleteModal(true, item.id)
                                }
                              >
                                Delete
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                    {/* <Link to={redirectToBackground}> */}

                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-2">
                        <Link
                          // to={
                          //   allowOpenMatter
                          //     ? `${AppRoutes.MATTERSOVERVIEW}/${item.id}`
                          //     : `#`
                          // }
                          to={`${AppRoutes.MATTERSOVERVIEW}/${item.id}`}
                        >
                          <h4
                            tabIndex="0"
                            className="focus:outline-none text-gray-800 dark:text-gray-100 font-bold"
                          >
                            {item?.matter?.name}
                          </h4>
                          <p
                            tabIndex="0"
                            className="focus:outline-none text-gray-600 font-medium mb-4 text-xs"
                          >
                            {item?.client?.name}
                          </p>
                        </Link>

                        {allowViewCreateUpdateMatter && (
                          <p
                            tabIndex="0"
                            className="focus:outline-none text-gray-500 dark:text-gray-100 text-xs"
                          >
                            {`Created on ${dateFormatter(item.createdAt)}`}
                            {item.updatedAt !== null && (
                              <>
                                {` / Updated on ${dateFormatter(
                                  item.updatedAt
                                )}`}
                                <br />
                              </>
                            )}
                            {item.lastOpenedAt !== null && (
                              <>
                                {` / Last Opened on ${dateFormatter(
                                  item.lastOpenedAt
                                )}`}
                                <br />
                              </>
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}
