import HBAImage from "./new-hba.png";
import HBAArchitect from "./usertypes/img-architect.png";
import HBAHomeBuilder from "./usertypes/img-homebuilder.png";
import HBAOwnerBuilder from "./usertypes/img-ownerbuilder.png";

export const Questions = [
  {
    mainHeader: "Welcome to Home Building App",
    subheader:
      "Let's set up your personalized dashboard to manage your dream home project.",
    order: 1,
    redirectTo: 2,
    category: "Introductory Screen",
    type: "none",
    // style: 'grid'
    properties: {
      isRequired: false,
      isSkippable: false,
    },
    image: HBAImage,
  },
  {
    mainHeader:
      "Before we set up your dashboard, could you tell us more about your role?",
    subheader:
      "We’ll streamline your setup experience based on your selection.",
    questions: [
      {
        question: "I’m an Architect Superintendent",
        subText:
          "I am responsible for administering the construction contract.",
        icons: HBAArchitect,
        type: "button",
        order: 1,
      },
      {
        question: "I’m a Homeowner",
        subText:
          "I am the owner of the property undergoing construction or renovation.",
        icons: HBAHomeBuilder,
        type: "button",
        order: 2,
      },
      {
        question: "I’m an Owner-Builder",
        subText:
          "I am directly involved in managing and executing the construction project.",
        icons: HBAOwnerBuilder,
        type: "button",
        order: 3,
      },
    ],
    order: 2,
    redirectTo: 3,
    category: "About User Types",
    type: "single",
    style: "grid",
    properties: {
      isRequired: true,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Where are you on your home building journey? Choose one:",
    questions: [
      {
        question: "Still planning",
        type: "button",
        order: 1,
      },
      {
        question: "Have started construction",
        type: "button",
        order: 2,
      },
    ],
    order: 3,
    redirectTo: 4,
    category: "About Project Status",
    type: "single",
    style: "list",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Have you secured your property location?",
    questions: [
      {
        question: "Yes, I have the location",
        type: "button",
        order: 1,
      },
      {
        question: "No, still looking",
        type: "button",
        order: 2,
      },
    ],
    order: 4,
    redirectTo: 5,
    category: "About Property Address",
    type: "single",
    style: "list",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Have you teamed up with a builder?",
    questions: [
      {
        question: "Yes, I have a builder",
        type: "button",
        order: 1,
      },
      {
        question: "No, still choosing",
        type: "button",
        order: 2,
      },
    ],
    order: 5,
    redirectTo: 6,
    category: "About Builders",
    type: "single",
    style: "list",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Do you have a signed construction contract?",
    questions: [
      {
        question: "Yes, I have a contract signed",
        type: "button",
        order: 1,
      },
      {
        question: "No, not yet",
        type: "button",
        order: 2,
      },
    ],
    order: 6,
    redirectTo: 7,
    category: "About Contracts",
    type: "single",
    style: "list",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Enter your property address here:",
    questions: [
      {
        type: "text",
        subheader: "This is the location where your house will be built.",
        placeHolder: "123 Smith Street Sydney, NSW 2000",
        order: 1,
      },
    ],
    order: 7,
    redirectTo: 8,
    category: "Property Address",
    type: "multiple",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Share your contract details:",
    questions: [
      {
        question: "When is the start date of the contract?",
        placeHolder: "Start Date",
        type: "datepicker",
        subheader:
          "This is the first day that work will be performed on site. ",
        order: 1,
      },
      {
        question: "What is the contract price?",
        placeHolder: "Contract Price",
        type: "number",
        subheader:
          "This is the original price indicated in the contract, without variations.",
        order: 2,
      },
    ],
    order: 8,
    redirectTo: 9,
    category: "Contract Details",
    type: "multiple",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "When is the Completion Date under the contract?",
    questions: [
      {
        placeHolder: "Completion Date",
        type: "datepickerToggleable",
        subheader:
          "This is the date when the project is scheduled to be completed as per your contract. ",
        order: 1,
        toggleText: "I don't have a target completion date yet.",
      },
    ],
    order: 9,
    redirectTo: 10,
    category: "Project Completion Date",
    type: "multiple",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Share your builder's details:",
    questions: [
      {
        question: "Who is your builder?",
        placeHolder: "Builder's Name",
        type: "text",
        subheader:
          "Please provide the name of the company or individual responsible for construction.",
        order: 1,
      },
      {
        question: "Who is your main point of contact at the building company?",
        placeHolder: "Builder Representative's Name",
        type: "text",
        subheader:
          "This could be a project manager or a representative you'll be in direct contact with.",
        order: 2,
      },
      {
        question: "What's their email address?",
        placeHolder: "Email Address",
        type: "email",
        order: 3,
      },
      {
        question: "What's their phone number?",
        placeHolder: "Phone Number",
        type: "number",
        order: 4,
      },
    ],
    order: 10,
    redirectTo: 11,
    category: "Builder Details",
    type: "multiple",
    style: "list",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Upload your signed contract here:",
    subheader:
      "Don't worry if you don't have them right now — you can always add them later.",
    questions: [
      { type: "uploadBox", placeHolder: "Contract", order: 1 },
      { type: "uploadBox", placeHolder: "Architectural Drawings", order: 2 },
      { type: "uploadBox", placeHolder: "Engineer Drawings", order: 3 },
      { type: "uploadBox", placeHolder: "Specifications", order: 4 },
      { type: "uploadBox", placeHolder: "Builder's Insurance", order: 5 },
    ],
    order: 11,
    redirectTo: "end",
    category: "Contracts",
    type: "multiple",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
];
