import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { GrClose } from "react-icons/gr";
import Loading from "../loading/loading";
import Multiselect from "multiselect-react-dropdown";
import { RiAddCircleLine } from "react-icons/ri";
import Button from "../../shared/Button";

export default function TasklistPresetsModal(props) {
  console.log("props", props);

  const companyId = props.companyId;
  const columns = props.columns;
  const presetsLibrary = sortArrayByLabel(props.presetsLibrary);

  const [presets, setPresets] = useState(presetsLibrary);

  const UPDATETASKLISTPRESET_MUTATION = `mutation updateTasklistPresets($columnIds: [Int], $id: ID) {
    taskListPresetsUpdate(id: $id, columnIds: $columnIds) {
      id
    }
  }`;

  const handleModalClose = () => {
    props.handleTasklistPresetsClose();
  };

  /**
   * Handles changes to a task list preset by making an API request to update the database and updating the presets library.
   * @param {string} id - the id of the preset being updated
   * @param {Array} evt - an array of column accessor keys
   */
  const handleChange = async (id, evt) => {
    console.group("handleChange()");

    // Map the column accessor keys to an array of integers
    const columnIds = evt.map(({ accessorKey }) => parseInt(accessorKey, 10));

    try {
      // Make an API request to update the task list preset in the database
      await API.graphql({
        query: UPDATETASKLISTPRESET_MUTATION,
        variables: { columnIds, id },
      });

      // Update the presets library with the new columnIds property
      const updatedPresets = updateObjectInArray(
        presetsLibrary,
        "id",
        id,
        "columnIds",
        columnIds
      );

      // Set the presets state to the updated presets array
      setPresets(sortArrayByLabel(updatedPresets));

      // Call a function passed down as props to update the parent component's state with the new presets library
      props.handleTasklistPresetsChanges(updatedPresets);
    } catch (error) {
      // Log any errors to the console
      console.error(error);
    }
  };

  /**
   * Updates an object with a given key-value pair in an array of objects.
   * @param {Array} array - the array of objects to search and update
   * @param {String} key - the key to search for in each object
   * @param {Any} value - the value to match against the key
   * @param {String} updatedKey - the key to update in the matched object
   * @param {Any} updatedValue - the new value to set for the updatedKey
   * @returns {Array} - a new array with the matching object updated
   */
  const updateObjectInArray = (array, key, value, updatedKey, updatedValue) => {
    // Find the index of the first object with the specified key-value pair
    const index = array.findIndex((obj) => obj[key] === value);
    // If no object matches, return the original array
    if (index === -1) {
      return array;
    }
    // Create a copy of the matching object with the updated key-value pair
    const updatedObj = { ...array[index], [updatedKey]: updatedValue };
    // Return a new array with the updated object replacing the old one
    return [...array.slice(0, index), updatedObj, ...array.slice(index + 1)];
  };

  /**
   * Gets an array of columns for a given task list preset.
   * @param {Array} preset - an array of integers representing column ids
   */
  const getSelectedColumns = (preset) => {
    console.log("getSelectedColumns()");

    // Create a new Set with the values from the preset array
    const presetColumnIds = new Set(preset);

    // Filter the columns array for any columns whose accessorKey is in the presetColumnIds Set
    return columns.filter((column) =>
      presetColumnIds.has(parseInt(column.accessorKey, 10))
    );
  };

  function sortArrayByLabel(arr) {
    arr.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return arr;
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">Registers</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleModalClose}
              >
                <GrClose />
              </button>
            </div>
            {/* <div className="flex flex-row-reverse p-3">
              <div className="mr-3">
                <Button
                  // onClick={() => setShowModalParagraph(true)}
                  className="py-2 px-4 ml-2 mb-1"
                  variant="secondary"
                >
                  Add Register
                  <RiAddCircleLine className=" h-4 w-4 ml-1" />
                </Button>
              </div>
            </div> */}
            
            <div className="flex flex-col h-screen" style={{ height: "50vh" }}>
              {presets ? (
                <div className="flex-grow overflow-auto">
                  <table className="relative w-full">
                    <thead>
                      <tr>
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold">
                          Register
                        </th>
                        <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold whitespace-nowrap">
                          Columns
                        </th>
                        {/* <th className="sticky top-0 px-6 py-3 bg-gray-300 font-semibold"></th> */}
                      </tr>
                    </thead>
                    <tbody className="px-6 py-3">
                      {presets.length > 0 ? (
                        <>
                          {presets.map((item, idx) => (
                            <tr key={idx}>
                              <td className="px-6 py-2 text-left">
                                {item.label}
                              </td>
                              <td className="px-6 py-2 text-center">
                                <Multiselect
                                  displayValue="header"
                                  onRemove={(event) =>
                                    handleChange(item.id, event)
                                  }
                                  onSelect={(event) =>
                                    handleChange(item.id, event)
                                  }
                                  // options={columns?.map((l) => l.header)}
                                  options={columns}
                                  selectedValues={getSelectedColumns(
                                    item.columnIds
                                  )}
                                  showCheckbox
                                  hidePlaceholder
                                />
                              </td>
                              {/* <td className="px-6 py-2 text-center">x</td> */}
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td className="px-6 py-2" colSpan={3}>
                              No records found.
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
