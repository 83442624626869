import DatePicker from "react-datepicker";
import CreatableSelect from "react-select/creatable";
import ReactTooltip from "react-tooltip";
import { BiPencil } from "react-icons/bi";
import { useState } from "react";
import { API } from "aws-amplify";

const CustomColumn = ({
  columnType,
  accessorKey,
  setInputDynamic,
  value,
  row,
  updateDynamicData,
  options,
  id,
  people,
  fieldStyle
}) => {
  const [editablePeople, setEditablePeople] = useState(false);
  const parsePeople = (fullName) => {
    if (!fullName) {
      return "";
    }

    const nameObject = people.find(
      (u) => `${u.firstName} ${u.lastName}` === fullName
    );

    return nameObject ? nameObject : "";
  };

  var moment = require("moment");
  if (columnType === "TEXTAREA") {
    return (
      <textarea
        defaultValue={value}
        rows={2}
        className={`border-none p-2 resize-none`}
        suppressContentEditableWarning={true}
        contentEditable={true}
        onInput={(e) => {
          setInputDynamic(e.target.value);
        }}
        onBlur={(e) => {
          console.log("KEY", accessorKey);
          console.log("VALUE", e.target.value);
          console.log("ROW", row);
          updateDynamicData(columnType, accessorKey, e.target.value, row, id);
        }}
      />
    );
  } else if (columnType === "READONLY") {
    return (
      <>
        <p>{value}</p>
      </>
    );
  } else if (columnType === "DROPDOWN") {
    return (
      <select
        defaultValue={value}
        suppressContentEditableWarning={true}
        contentEditable={true}
        //defaultValue={value}
        onChange={(e) =>
          updateDynamicData(columnType, accessorKey, e.target.value, row, id)
        }
        className={value === "Approved" ? "p-2 rounded-lg border bg-transparent border-green-500 text-green-500 max-w-xs inline-block" : value === "For Approval" ? "p-2 rounded-lg border bg-transparent border-gray-500 text-gray-500 max-w-xs inline-block" : "p-2 rounded-lg border bg-transparent max-w-xs inline-block" }
      >
        <option value={""}>Select</option>
        {options.map((opt, i) => {
          return (
            <option key={`${i}_${opt}`} value={opt}>
              {opt}
            </option>
          );
        })}
      </select>
    );
  } else if (columnType === "DATE") {
    if (value && moment(value, moment.ISO_8601, true).isValid()) {
      return (
        <DatePicker
          selected={new Date(moment.utc(new Date(value)).local().format())}
          className="border w-40 rounded py-2 px-1 border-gray-300 mb-5 z-50"
          dateFormat="dd MMM yyyy"
          placeholderText="No Date"
          onChange={(e) => {
            console.log("DATEPICKER", e);
            updateDynamicData(columnType, accessorKey, e, row, id);
          }}
        />
      );
    } else {
      return (
        <DatePicker
          className="border w-40 rounded py-2 px-1 border-gray-300 mb-5 z-50"
          dateFormat="dd MMM yyyy"
          placeholderText="No Date"
          onChange={(e) => {
            console.log("DATEPICKER", e);
            updateDynamicData(columnType, accessorKey, e, row, id);
          }}
        />
      );
    }    
  } else if (columnType === "CHECKBOX") {
    return (
      <input
        checked={value !== null ? value : false}
        className="cursor-pointer m-1 self-center"
        type="checkbox"
        onChange={(e) => {
          console.log("CHECK", e.target.checked);
          updateDynamicData(columnType, accessorKey, e.target.checked, row, id);
        }}
      />
    );
  } else if (columnType === "TEXT") {
    return (
      <input
        className={`p-2 ${fieldStyle}`}
        key={accessorKey}
        type="text"
        defaultValue={value}
        onInput={(e) => {
          setInputDynamic(e.target.value);
        }}
        onBlur={(e) => {
          console.log("KEY", accessorKey);
          console.log("VALUE", e.target.value);
          console.log("ROW", row);
          updateDynamicData(columnType, accessorKey, e.target.value, row, id);
        }}
      />
    );
  } else if (columnType === "NUMBER" || columnType === "PHONE") {
    return (
      <div className="relative flex mt-1">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-500">$</span>
        <input
          defaultValue={value}
          type="number"
          className="p-2 pl-8 w-28 border border-gray-300 bg-white rounded-md"
          onInput={(e) => {
            setInputDynamic(e.target.value);
          }}
          onBlur={(e) => {
            console.log("KEY", accessorKey);
            console.log("VALUE", e.target.value);
            console.log("ROW", row);
            updateDynamicData(columnType, accessorKey, e.target.value, row, id);
          }}
          placeholder="0.00"
        />
      </div>
    );
  } else if (columnType === "PEOPLE") {
    let x = value ? value.split(",") : [];

    /* Filtering out undefined values from an array. */
    const y = x.filter((element) => {
      return element !== undefined && element !== "undefined";
    });

    /* Trimming the whitespace from each element in the array. */
    const data = y.map((x) => x.trim());
    if (!editablePeople) {
      return (
        <div className="flex justify-center">
          {data.map((x, index) => {
            const assignee = parsePeople(x);

            var assigneeInitials = "";
            if (x.toLowerCase() === "john dela cruz") {
              assigneeInitials = "JDC";
            } else {
              assigneeInitials += assignee.firstName
                ? assignee.firstName?.charAt(0)
                : "";
              assigneeInitials += assignee.lastName
                ? assignee.lastName?.charAt(0)
                : "";
            }

            return (
              assignee && (
                <div className="flex" key={index}>
                  <div
                    className="flex bg-gray-500 text-white w-10 h-10 rounded-full text-md font-medium justify-center items-center"
                    data-tip={assignee.firstName + " " + assignee.lastName}
                  >
                    {assignee.profilePicture === null ? (
                      <>{assigneeInitials.toUpperCase()}</>
                    ) : (
                      <img
                        className="rounded-full w-10 h-10"
                        src={assignee.profilePicture}
                      />
                    )}
                  </div>
                  <ReactTooltip globalEventOff="scroll" scrollHide />
                  {/* <div
                      className="inline-block p-2 border-2 bg-gray-200 mx-0.5 rounded-full w-10 h-10 items-center uppercase justify-center text-center font-semibold cursor-pointer"
                      data-tip={assignee.firstName + " " + assignee.lastName}
                    >
                      {assigneeInitials.toUpperCase()}
                    </div> */}
                </div>
              )
            );
          })}

          <BiPencil
            //key={row.id + "-" + column.id}
            className="text-green-700 inline-block p-2 cursor-pointer hover:bg-black hover:bg-opacity-5 w-10 h-10 rounded-full items-center"
            onClick={() => {
              setEditablePeople(true);
              ReactTooltip.hide();
            }}
          />
        </div>
      );
    }

    var arrPeople = value?.split(",");

    return (
      <CreatableSelect
        isMulti
        autoFocus
        options={
          people &&
          people.map((p) => {
            let item = `${p.firstName} ${p.lastName}`;
            return {
              value: item,
              label: item,
            };
          })
        }
        className="w-64"
        defaultValue={
          arrPeople &&
          arrPeople.map((p) => {
            return {
              value: p,
              label: p,
            };
          })
        }
        onBlur={() => {
          setEditablePeople(false);
        }}
        onChange={(e) => {
          const input = e.map((x) => x.value).join(",");
          console.log("INPUT", input);
          updateDynamicData(columnType, accessorKey, input, row, id);
          console.log("SELECT", e);
        }}
        placeholder="Select People"
      />
    );
  }
};

export default CustomColumn;
