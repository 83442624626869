
import { API } from "aws-amplify"

export const mutationActionHandler = async (params, dispatch) =>{

  console.log('Initiating ', params)

  try {
    const response = await API.graphql(params)
    console.log('Response from API ', response)
    if(response) {
      console.log('Success ', response)
      const newState = dispatch(response)
    } else{
      console.error('Mutation error ', response.errors)
    }

  } catch (err) {

    console.error('General Error ',err.errors)
  }

}
  


export const qListActiveFormsByCompany = `query getCustomFormsByCompany($companyId: ID!, $isDeleted: Boolean = false) {
  customFormsByCompany(companyId: $companyId, isDeleted: $isDeleted) {
    id
    name
    description
    route
    isDeleted
    isPublished
    createdAt
    updatedAt
  }
}`


export const mCreateCustomForm = `mutation createCustomForm($companyId: ID!, $name: String) {
  customFormCreate(companyId: $companyId, name: $name) {
    id
    name
    description
    route
    isDeleted
    isPublished
    createdAt
    updatedAt
  }
}`


export const mUpdateCustomForm = `mutation updateCustomForm($companyId: ID!, $id: ID, $name: String, $description: String) {
  customFormUpdate(
    id: $id
    companyId: $companyId
    description: $description
    name: $name
  ) {
    id
  }
}`


export const qListCategoriesByCompany = `query getFormCategoryByCompany($companyId: ID!) {
  customFormCategoriesByCompany(companyId: $companyId) {
    id
    companyId
    name
    description
    createdAt
    updatedAt
    customForms {
      id
    }
  }
}`


export const mCreateCategory = `mutation createCustomFormCategory($companyId: ID!, $name: String) {
  customFormCategoryCreate(companyId: $companyId, name: $name) {
    id
    companyId
    name
    description
    createdAt
    updatedAt
    customForms {
      id
    }
  }
}`


export const mUpdateCustomFormCategory = `mutation updateCustomFormCategory($companyId: ID! , $id: ID! , $customForms: [CustomFormInput]) {
  customFormCategoryUpdate(
    id: $id
    companyId: $companyId
    customForms: $customForms
  ) {
    id
  }
}`


export const mUpdateCategory = `mutation updateCustomFormCategory($companyId: ID! , $id: ID!, $description: String, $name: String) {
  customFormCategoryUpdate(
    id: $id
    companyId: $companyId
    description: $description
    name: $name
  ) {
    id
  }
}`


export const mDeleteCategory = `mutation deleteCustomFormCategory($id: ID!, $companyId:ID!) {
  customFormCategoryDelete(id: $id, companyId: $companyId)
}`


export const qListForm = `query getCustomFormsByCompany($companyId: ID!, $isDeleted: Boolean = false) {
  customFormsByCompany(companyId: $companyId, isDeleted: $isDeleted) {
    id
    companyId
    name
    description
    route
    isPublished
    isDeleted
    createdAt
    updatedAt
    pages(limit: 100) {
      items {
        id
        name
        order
        section(limit: 100) {
          items {
            id
            name
            order
            fields(limit: 100) {
              items {
                id
                type
                headerText
                order
                options {
                  items {
                    optionText
                    order
                  }
                }
                properties {
                  items {
                    defaultValue
                    isRequired
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`