import React, { useEffect, useRef } from "react";
import { GrClose } from "react-icons/gr";
import { RiFileInfoLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import anime from "animejs";
import Button from "../../shared/Button"
import { CgClose } from "react-icons/cg";

export default function CreateRFIModal(props) {
  const modalOverlay = useRef(null);
  const modalContent = useRef(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleModalClose = () => {
    props.handleModalClose();
  };

  const handleSave = (data) => {
    console.log(data);

    let rfiName = data.rfiName;
    props.handleSave(rfiName);
  };

  useEffect((e) => {
    anime({
      targets: modalOverlay.current,
      opacity: [0, 1],
      duration: 200,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 200,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div
        ref={modalOverlay}
        className="fixed inset-0 z-40 bg-black bg-opacity-60 opacity-0"
      ></div>
      <div
        ref={modalContent}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-full my-6 mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10">
            <div className="flex items-start justify-between  rounded-t">
              <h3 className="font-semibold text-gray-900 text-lg pb-2">
                Create RFI
              </h3>
              <button
                className="ml-auto h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300"
                onClick={handleModalClose}
              >
                <CgClose />
              </button>
            </div>
            <div className="relative py-4 flex-auto p-1">
              <div className="text-sm font-medium text-gray-400">
                {`RFI Name *`}
              </div>
              <div className="flex flex-col py-1">
                <div className="absolute pin-r pin-t mt-2 mr-5 ml-2 text-purple-lighter">
                  <RiFileInfoLine className="text-gray-400" />
                </div>
                <input
                  type="text"
                  className="rounded-md p-2 border border-gray-300 outline-0 pl-8 w-full"
                  {...register("rfiName", { required: true })}
                />
              </div>
              {errors.rfiName?.type === "required" && (
                <small className="text-red-400">RFI name is required</small>
              )}
            </div>
            <div className="flex items-center justify-center p-3 rounded-b">
              {/* <button
                className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Create
              </button> */}
              <Button
                variant="secondary"
                size="medium"
                className="px-6 py-3"
                type="submit"
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
