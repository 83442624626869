import React, { Component } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { API } from "aws-amplify";

var momentTZ = require("moment-timezone");

class GmailIntegration extends Component {
  constructor(props) {
    console.log("GmailIntegration: ", props);
    super(props);

    this.state = {
      loggedInAccount: localStorage.getItem("connectedGmailAccount") || null,
      refreshToken: props.refreshToken || null,
      isOutOfSync: props.isOutOfSync,
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  async login(response) {
    try {
      const saveRefreshToken = `
      mutation connectToGmail($companyId: ID, $email: String, $userId: ID, $code: String, $userTimeZone: String) {
        gmailConnectFromCode(
          email: $email
          userId: $userId
          companyId: $companyId
          code: $code
          userTimeZone: $userTimeZone
        ) {
          id
          refreshToken
          userId
          companyId
          updatedAt
          isOutOfSync
        }
      }
      `;

      setTimeout(() => {
        const isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
        console.log("Get signed in details", isSignedIn);
        if (isSignedIn) {
          const authCurrentUser = window.gapi.auth2
            .getAuthInstance()
            .currentUser.get().wt.cu;

          console.log("authCurrentUser: ", authCurrentUser);

          const params = {
            query: saveRefreshToken,
            variables: {
              companyId: localStorage.getItem("companyId"),
              userId: localStorage.getItem("userId"),
              email: authCurrentUser,
              code: response.code,
              userTimeZone: momentTZ.tz.guess(),
            },
          };

          console.log("Params", params);

          API.graphql(params).then((r) => {
            console.log("Response: ", r);
            const gmailToken = r.data.gmailConnectFromCode;

            if (gmailToken) {
              const gmailTokenId = gmailToken.id; // user email address
              const gmailRefreshToken = gmailToken.refreshToken;
              const gmailIsOutOfSync =
                gmailToken.isOutOfSync === null ||
                gmailToken.isOutOfSync === undefined
                  ? false
                  : gmailToken.isOutOfSync;

              this.setState((state) => ({
                loggedInAccount: gmailTokenId,
                refreshToken: gmailRefreshToken,
                isOutOfSync: gmailIsOutOfSync,
              }));
              localStorage.setItem("connectedGmailAccount", authCurrentUser);
              window.location.reload();
            } else {
              console.log("MMA Error: Unable to sign in.");
              window.location.reload();
            }
          });
        } else {
          console.log("API Error: Unable to sign in. Please sign in again.");
        }
      }, 1000);
    } catch (e) {
      console.log("saveRefreshToken Error:", e);
    }
  }

  logout(response) {
    this.setState((state) => ({
      loggedInAccount: null,
      refreshToken: null,
      isOutOfSync: false,
    }));

    const removeRefreshToken = `
    mutation removeRefreshToken($email: String) {
      gmailDisconnect(email: $email) {
        id
      }
    }
    `;
    const params = {
      query: removeRefreshToken,
      variables: {
        email: localStorage.getItem("connectedGmailAccount"),
      },
    };

    API.graphql(params).then(() => {
      localStorage.removeItem("connectedGmailAccount");
      window.location.reload();
    });
  }

  handleLoginFailure(response) {
    console.error("handleLoginFailure:", response);
  }

  handleLogoutFailure(response) {
    //console.error("handleLogoutFailure", response);
  }

  render() {
    console.group("Logged In State");
    console.log("loggedInAccount:", this.state.loggedInAccount);
    console.log("refreshToken:", this.state.refreshToken);
    console.log("isOutOfSync:", this.state.isOutOfSync);
    console.groupEnd();

    let actionSate = "";
    const gScope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/gmail.labels",
      "https://www.googleapis.com/auth/gmail.modify",
      "https://www.googleapis.com/auth/gmail.send",
      "https://www.googleapis.com/auth/gmail.compose",
    ];

    const connectedState = (
        <GoogleLogout
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText={"Signout - " + this.state.loggedInAccount}
          onLogoutSuccess={this.logout}
          onFailure={this.handleLogoutFailure}
        ></GoogleLogout>
      ),
      notConnectedState = (
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Login with Google"
          onSuccess={this.login}
          onFailure={this.handleLoginFailure}
          cookiePolicy={"single_host_origin"}
          responseType="code"
          approvalPrompt="force"
          prompt="consent"
          access_type="offline"
          scope={gScope.join(" ")}
        />
      ),
      outOfSyncState = (
        <>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Login with Google"
            onSuccess={this.login}
            onFailure={this.handleLoginFailure}
            cookiePolicy={"single_host_origin"}
            responseType="code"
            approvalPrompt="force"
            prompt="consent"
            access_type="offline"
            scope={gScope.join(" ")}
          />
        </>
      );

    if (
      this.state.loggedInAccount &&
      this.state.loggedInAccount !== "null" &&
      this.state.refreshToken
    ) {
      if (this.state.isOutOfSync) {
        actionSate = outOfSyncState;
      } else {
        actionSate = connectedState;
      }
    } else {
      actionSate = notConnectedState;
    }

    return <div>{actionSate}</div>;
  }
}

export default GmailIntegration;
