import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";
import { AiOutlineQuestion } from "react-icons/ai";

function PortalAccessConfirmationModal({
  close,
  prompt,
  data,
  removePortalAccess,
  allowPortalAccess,
  resendPortalAccess,
}) {
  function StopPropagate(e) {
    e.stopPropagation();
  }

  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const TrashIcon = () => {
    return (
      <div className="w-10 h-10 bg-gray-800 rounded-full flex justify-center items-center">
        <AiOutlineQuestion size={25} className="text-white" />
      </div>
    );
  };

  const Cancel = () => {
    const [isHover, setisHover] = useState(false);
    return (
      <button
        onClick={() => close()}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className="px-16 py-1 flex flex-row font-medium text-md justify-center items-center bg-white rounded-md gap-2 border border-gray-300 hover:bg-gray-700 hover:text-white"
      >
        Cancel
      </button>
    );
  };

  const Remove = () => {
    const [isHover, setisHover] = useState(false);

    return (
      <button
        onClick={() => {
          removePortalAccess(data);
          close();
        }}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className="px-16 py-1 flex flex-row font-medium text-md justify-center
              items-center text-white bg-red-500 rounded-md gap-2 hover:bg-white
              hover:text-red-500 border border-red-500"
      >
        Remove Access
      </button>
    );
  };

  const Allow = () => {
    const [isHover, setisHover] = useState(false);

    return (
      <button
        onClick={() => {
          allowPortalAccess(data);
          close();
        }}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className="px-16 py-1 flex flex-row font-medium text-md justify-center
              items-center text-white bg-green-500 rounded-md gap-2 hover:bg-white
              hover:text-green-500 border border-green-500"
      >
        Give Access
      </button>
    );
  };

  const Resend = () => {
    const [isHover, setisHover] = useState(false);

    return (
      <button
        onClick={() => {
          resendPortalAccess(data);
          close();
        }}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className="px-16 py-1 flex flex-row font-medium text-md justify-center
              items-center text-white bg-orange-500 rounded-md gap-2 hover:bg-white
              hover:text-orange-500 border border-orange-500"
      >
        Resend Invite
        {/* <CgTrash /> */}
      </button>
    );
  };

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="px-8 pt-6 pb-8 flex flex-col bg-white rounded-2xl items-center justify-center opacity-0 scale-90"
          onClick={StopPropagate}
        >
          <TrashIcon />
          <div className="font-semibold text-lg pb-3">
            {prompt == "remove"
              ? "Remove Portal Access"
              : prompt == "allow"
              ? "Give Portal Access"
              : prompt == "resend"
              ? "Resend Invitation"
              : "..."}
          </div>
          <div className="text-sm">
            {prompt == "remove"
              ? "Once confirmed, the user will not have access to portal until given."
              : prompt == "allow"
              ? "Once confirmed, the user will receive an email invitation"
              : prompt == "resend"
              ? "Once confirmed, the user will receive an email invitation"
              : "..."}
          </div>
          <div className="text-sm pb-8">Do you want to continue?</div>
          <div className="flex flex-row gap-3">
            <Cancel />
            {prompt == "remove" ? (
              <Remove />
            ) : prompt == "allow" ? (
              <Allow />
            ) : prompt == "resend" ? (
              <Resend />
            ) : (
              "..."
            )}
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default PortalAccessConfirmationModal;
