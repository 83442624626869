import React, { useEffect, useRef, useState } from "react";
import { Quill } from "react-quill";
import "./styles.css";
import QuillMention from "quill-mention";

import PdfIcon from "../../../assets/images/pdf.png";
import ImgIcon from "../../../assets/images/img.png";
import DocsIcon from "../../../assets/images/docs.png";
import PptIcon from "../../../assets/images/ppt.png";
import XlsIcon from "../../../assets/images/xls.png";
import HtmlIcon from "../../../assets/images/html.png";
import AudioIcon from "../../../assets/images/audio.png";
import VideoIcon from "../../../assets/images/video.png";
import TextIcon from "../../../assets/images/text.png";
import UnknownFileIcon from "../../../assets/images/Unknown.png";

const QuillEditor = ({
  isReply,
  composeType,
  selectedRawHtml,
  mentionData,
  emailInfo,
  htmlMessage,
  handleMessage,
  setForwardDetails,
  setHtmlMessage,
  placeholder,
  isSendingEmail,
  attachments,
  setAttachments,
  inputRef,
  setEditBcc,
  setEditCc,
  setEditTo,
  cc,
  bcc,
  setIsCc,
  setIsBcc,
  isOutlookIntegrated,
}) => {
  const [quill, setQuill] = useState();
  const [toolbar, setToolbar] = useState();
  const editor = useRef(null);
  const [isVisibleFwd, setIsVisibleFwd] = useState(false);
  const [isVisibleReply, setIsVisibleReply] = useState(false);

  var moment = require("moment");

  const searchMembers = async (searchTerm, renderList) => {
    const res = await new Promise((res) => {
      const filtered = mentionData.filter((item) =>
        item.value.toLocaleLowerCase().includes(searchTerm)
      );
      console.log(filtered, "<- Filtered");
      res(filtered);
    });
    renderList(res);
  };

  const config = {
    placeholder: placeholder,
    theme: "snow",
    modules: {
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: searchMembers,
        isolateCharacter: true,
        allowInlineMentionChar: false,
      },
      toolbar: [
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", { color: [] }, { background: [] }],
      ],
    },
    formats: [
      "mention",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bold",
      "bullet",
      "indent",
      "link",
      "color",
      "background",
    ],
  };

  const setDefaultContent = () => {
    if (quill) {
      quill.root.innerHTML = htmlMessage;
    }
    const QuillData = document.getElementsByClassName("ql-editor");
    const editorElement = Array.from(QuillData)[0];
    editorElement.style.height = "auto";
    editorElement.style.paddingBottom = "10px";
    editorElement.style.minHeight = "80%";
    editorElement.style.paddingBottom = "10px";
  };

  const onSelectionChange = (range) => {
    const toolbarElement = toolbar.container;
    const QuillData = document.getElementsByClassName("ql-editor");
    const editorElement = Array.from(QuillData)[0];
    if (range) {
      if (range.length === 0) {
        setEditBcc(false);
        setEditCc(false);
        setEditTo(false);
        if (cc.length === 0) {
          setIsCc(false);
        }
        if (bcc.length === 0) {
          setIsBcc(false);
        }
        hideInactiveToolbar();
      } else {
        const { bottom, right, top, left } = quill.getBounds(range);
        const toolbarButtonElements =
          document.getElementsByClassName("ql-formats");

        const tbntarr = Array.from(toolbarButtonElements);
        if (tbntarr.length > 0) {
          tbntarr.map((i) => {
            i.style.display = "flex";
          });
        }
        editorElement.style.paddingBottom = "10px";
        editorElement.style.paddingBottom = "10px";
        editorElement.style.height = "auto";
        editorElement.style.minHeight = "80%";
        toolbarElement.style.display = "flex";
        toolbarElement.style.flexWrap = "nowrap";
        toolbarElement.style.justifyContent = "center";
        toolbarElement.style.visibility = "visible";
        toolbarElement.style.position = "absolute";
        toolbarElement.style.backgroundColor = "white";
        toolbarElement.style.zIndex = "120";
        toolbarElement.style.border = "none";
        toolbarElement.style.boxShadow = `rgba(0, 0, 0, 0.35) 0px 5px 15px`;
        toolbarElement.style.bottom = `15%`;
        toolbarElement.style.left = "50%";
        toolbarElement.style.transform = "translateX(-50%)";
        setIsVisibleFwd(false);
      }
    } else {
      hideInactiveToolbar();
    }
  };

  const hideInactiveToolbar = () => {
    const element = toolbar?.container;
    const QuillData = document.getElementsByClassName("ql-editor");
    const editorElement = Array.from(QuillData)[0];
    const isToolbarFocused =
      element &&
      (element.contains(document.activeElement) ||
        element === document.activeElement);
    if (!isToolbarFocused) {
      element.style.visibility = "hidden";
      element.style.display = "none";
      editorElement.style.height = "80%";
      editorElement.style.paddingBottom = "12px";
    }

    if (composeType.label === "Forward" && isReply) {
      setIsVisibleFwd(true);
    }

    if (composeType.label === "Reply" && isReply) {
      setIsVisibleReply(true);
    }
  };

  const isOnForward = () => {
    const QuillContainer = document.getElementsByClassName("ql-container");
    const containerElement = Array.from(QuillContainer)[0];
    if (composeType.value === 2) {
      containerElement.style.height = "auto";
      containerElement.style.minHeight = "60%";
      containerElement.style.paddingTop = "10px";
      containerElement.style.paddingBottom = "10px";
    }
  };

  const isOnReply = () => {
    const QuillContainer = document.getElementsByClassName("ql-container");
    const containerElement = Array.from(QuillContainer)[0];
    if (composeType.value === 1) {
      containerElement.style.height = "auto";
      containerElement.style.minHeight = "60%";
      containerElement.style.paddingTop = "10px";
      containerElement.style.paddingBottom = "10px";
    }
  };

  const removeAttachmentItem = (file, index, inputRef) => {
    var newArrayAttachments = attachments.filter(function (item, index) {
      return item.filename !== file.filename;
    });
    setAttachments(newArrayAttachments);
    inputRef.current.value = "";
  };

  const applyBoldStyle = () => {
    var elements = document.getElementsByTagName("b");

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      var innerTags = element.querySelectorAll("i, u");

      for (var j = 0; j < innerTags.length; j++) {
        var innerTag = innerTags[j];
        innerTag.style.fontWeight = "bold";
      }
    }
  };

  const processEmailHtml = (emailHtml) => {
    var tempElement = document.createElement("div");
    tempElement.innerHTML = emailHtml;
    applyBoldStyle(tempElement);
    var processedHtml = tempElement.innerHTML;

    // Use the processed HTML as needed
    return processedHtml;
  };

  const handleChange = (value, delta, source) => {
    if (source === "user") {
      const mentionElements = document.getElementsByClassName("mention");
      const marr = Array.from(mentionElements);
      if (marr.length > 0) {
        marr.map((i) => {
          const s = i.children[0];
          if (s) {
            s.children[0].innerHTML = "";
          }
        });
      }
      const QuillData = document.getElementsByClassName("ql-editor");
      const QuillValue = Array.from(QuillData)[0];
      const html = QuillValue.innerHTML;
      const text = QuillValue.innerText;
      handleMessage({ html, text });
    }
  };

  const loadForwardDetails = () => {
    if (!htmlMessage.includes("Forwarded message")) {
      const to = emailInfo?.to;
      const from = emailInfo?.from;
      const FromName = from.substring(0, from.lastIndexOf("<"));
      const FromEmail = from.includes("<")
        ? from.substring(from.lastIndexOf("<") + 1, from.lastIndexOf(">"))
        : from;
      const date = moment(emailInfo?.date).format(isOutlookIntegrated
        ? "DD MMMM YYYY HH:mm"
        : "ddd, MMM D, YYYY, [at] hh:mm A"
      );
      const subject = emailInfo?.subject;
      console.log(to, from, date, subject);
      let filterTo = to.split(",");
      let filteredToText = "";
      if (isOutlookIntegrated) {
        let arrOfTo = Array.from(to.matchAll(/([^<]+)<([^>]+)>/g), match => ({ name: match[1].trim(), email: match[2].trim() }));
        let formattedArr = arrOfTo.map((e) => (`${e.name} &lt;<a href="mailto:${e.email}" target="_blank">${e.email}</a>&gt;`))
        filteredToText = formattedArr.join(', ');
      } else {
        filterTo.map((item, index, { length }) => {
          let t = item;
          const sep = index + 1 === length ? "" : ",";
          filteredToText =
            filteredToText + t.replace("<", "&lt;").replace(">", "&gt;") + sep;
          console.log(t.replace("<", "&lt;").replace(">", "&gt;"), "<- replaced");
        });
      }
      console.log(filteredToText, "<- final");
      console.log(
        `---------- Forwarded message --------- \n From: ${from} \n Date: ${date} \n Subject: ${subject} \n To: ${to}`
      );
      // let ForwardDetails = `<div>HELLO!!! ${from}</div>`;
      let ForwardDetailsGmail = `<div dir="ltr" class="gmail_attr">---------- Forwarded message ---------</div><div>From: <strong class="gmail_sendername" dir="auto">${FromName}</strong> <span dir="auto">&lt;<a href="mailto:${FromEmail}">${FromEmail}</a>&gt;</span></div><div>Date: ${date}</div><div>Subject: ${subject}</div><div>To: ${filteredToText}</div>`;
      let ForwardDetailsOutlook = `<hr style="display:inline-block;width:98%"><div><strong>From:</strong>&nbsp;${FromName}&lt;<a href="mailto:${FromEmail}" target="_blank">${FromEmail}</a>&gt;</div><div><strong>Sent:</strong>&nbsp;${date}</div><div><strong>To:</strong>&nbsp;${filteredToText};</div><div><strong>Subject:</strong>&nbsp;${subject}</div><div>&nbsp;</div>`
      
      setForwardDetails(isOutlookIntegrated ? ForwardDetailsOutlook : ForwardDetailsGmail);
      setHtmlMessage(`<br> <br> ${ForwardDetailsGmail}`);
    }
  };

  useEffect(() => {
    const q = new Quill(editor?.current, config);
    const t = q.getModule("toolbar");
    t.container.style.display = "none";

    var Block = Quill.import("blots/block");
    Block.tagName = "DIV";
    setQuill(q);
    setToolbar(t);
    Quill.register(Block, true);
    Quill.register("modules/mention", QuillMention);
  }, [mentionData, editor]);

  useEffect(() => {
    if (isReply && quill) {
      if (composeType.label === "Forward") {
        loadForwardDetails();
        setIsVisibleFwd(true);
        isOnForward();
        if (quill) {
          quill.root.innerHTML = htmlMessage;
        }
      }
      if (composeType.label === "Reply") {
        isOnReply();
        setIsVisibleReply(true);
        setDefaultContent();
      }
    }

    if (!isReply && quill) {
      if (composeType.label === "Reply") {
        isOnReply();
        setIsVisibleReply(false);
        setDefaultContent();
      }
      if (composeType.label === "New Email") {
        // isOnReply();
        // setIsVisibleReply(false);
        setDefaultContent();
      }
    }
  }, [composeType, isReply, quill]);

  useEffect(() => {
    if (toolbar && toolbar.container && quill) {
      toolbar.container.style.display = "none";
      quill.on("selection-change", onSelectionChange);
      quill.on("text-change", handleChange);
    }
  }, [toolbar, quill, editor]);

  useEffect(() => {
    if (quill && isSendingEmail) {
      quill.disable();
    }
  }, [isSendingEmail]);

  return (
    <div className=" relative bg-white" style={{ height: "25rem" }}>
      <div
        role="application"
        id="quill"
        ref={editor}
        style={{
          border: "none",
          overflowX: "hidden",
          overflowY: "hidden",
          position: "relative",
        }}
      />
      {isVisibleFwd && composeType.label === "Forward" ? (
        <div className="mx-2">
          <div
            dangerouslySetInnerHTML={{
              __html: processEmailHtml(selectedRawHtml),
            }}
          ></div>
        </div>
      ) : null}
      {isVisibleReply && composeType.label === "Reply" ? (
        <div className="mx-2">
          {`On ${moment(emailInfo?.date).format(
            "ddd, MMM D, YYYY, [at] hh:mmA"
          )} ${emailInfo.from.substring(
            0,
            emailInfo.from.lastIndexOf("<")
          )} <${emailInfo.from.substring(
            emailInfo.from.lastIndexOf("<") + 1,
            emailInfo.from.lastIndexOf(">")
          )}> wrote:`}
          <blockquote
            className="gmail_quote"
            style={{
              margin: "0px 0px 0px 0.8ex",
              borderLeft: "1px solid rgb(204, 204, 204)",
              paddingLeft: "1ex",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: processEmailHtml(selectedRawHtml),
              }}
            ></div>
          </blockquote>
        </div>
      ) : null}

      {/* PDF Preview Div Container */}
      {attachments.length > 0 ? (
        <div className="w-full h-auto test123 border-t-2 border-b-gray-100 m-1">
          {attachments.map((file, index) => {
            return (
              <div
                key={index}
                className="flex m-2 p-2 bg-white items-center justify-between"
              >
                <div className="gap-2 flex items-center">
                  <span>
                    {file.filename.substring(
                      file.filename.lastIndexOf(".") + 1,
                      file.filename.length
                    ) === "pdf" ? (
                      <img className="w-8 h-8" src={PdfIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "png" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "jpg" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "jpeg" ? (
                      <img className="w-8 h-8" src={ImgIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "doc" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "docx" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "odt" ? (
                      <img className="w-8 h-8" src={DocsIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "ppt" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "odp" ? (
                      <img className="w-8 h-8" src={PptIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "xls" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "xlsx" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "ods" ? (
                      <img className="w-8 h-8" src={XlsIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "html" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "htm" ? (
                      <img className="w-8 h-8" src={HtmlIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "mp3" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "wav" ? (
                      <img className="w-8 h-8" src={AudioIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "avi" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "mpg" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "mpeg" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "mov" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "mp4" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "3gp" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "mkv" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "flv" ? (
                      <img className="w-8 h-8" src={VideoIcon} alt="" />
                    ) : file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "txt" ||
                      file.filename.substring(
                        file.filename.lastIndexOf(".") + 1,
                        file.filename.length
                      ) === "rtf" ? (
                      <img className="w-8 h-8" src={TextIcon} alt="" />
                    ) : (
                      <img className="w-8 h-8" src={UnknownFileIcon} alt="" />
                    )}
                  </span>
                  <span className="font-semibold truncate w-52 mx-1 my-2 ">
                    {file.filename.substring(
                      0,
                      file.filename.lastIndexOf(".")
                    ) || file.filename}
                  </span>
                  <span className="mx-1 my-2">
                    <div className="relative pt-1">
                      <div className="flex gap-1 mb-2 items-center justify-between">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200">
                          {file.percent === "100%" ? "Uploaded" : "Uploading"}
                          </span>
                        </div>
                        <div className="text-right">
                          <span className="text-xs font-semibold w-11 inline-block text-gray-600">
                            {file.percent}
                          </span>
                        </div>
                      </div>
                      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                        <div style={{ width: `${file.percent}` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                      </div>
                    </div>
                  </span>
                </div>
                <button
                  disabled={isSendingEmail}
                  className="cursor-pointer"
                  onClick={() => {
                    console.log(file, "file");
                    removeAttachmentItem(file, index, inputRef);
                  }}
                >
                  <span className="font-semibold text-red-400">Remove</span>
                </button>
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="footer p-6"></div>
    </div>
  );
};

export default QuillEditor;
