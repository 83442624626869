import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../../outlook-config-v2";
const OutlookConfig = ({ children }) => {
  const msalInstance = new PublicClientApplication(msalConfig);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default OutlookConfig;
