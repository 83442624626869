import { createContext, useReducer } from 'react'

import { appStateReducer } from './appStateReducer'
import { formDataReducer } from './formDataReducer'
import { modalStateReducer } from './modalStateReducer'


const formItems = [
  {
    key: 'aw',
    name:'Form 1',
    category: 'All',
    submissions: '0',
    lastModified: '11/11/11'
  },
  {
    key: 'aw',
    name:'Form 2',
    category: '2020 Forms',
    submissions: '6',
    lastModified: '11/11/11'
  }
]

const initialState = {
  appState:{
    
  },
  formData:{
    originalData: formItems,
  },
  modalState:{
    modalOpen: false,
    modalContent: null,
  }
}

const storeReducer = (state, action) => ({
  appState: appStateReducer(state.appState, action),
  formData: formDataReducer(state.formData, action),
  modalState: modalStateReducer(state.modalState, action),
})

export const FormDetailsContext = createContext()


export default function StoreProvider({children}){
  const [state, dispatch] = useReducer(storeReducer, initialState)

  const contextValue = {
    state,
    dispatch,
  };

  return(
    <FormDetailsContext.Provider value={contextValue}>
      {children}
    </FormDetailsContext.Provider>
  )
}