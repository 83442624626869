import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Button from "../../shared/Button";
import MonthYearDatePicker from "../../shared/MonthYearDatePicker";
import { API } from "aws-amplify";
import { FaFileContract } from "react-icons/fa6";
import {
  timezoneFormatter,
  datePickerDateFormatter,
} from "../../shared/userPreferredDateTime";
import ToastNotification from "../toast-notification";
import contractsIcon from "../../assets/images/contractDetails-icon.png";
import uploadIcon from "../../assets/images/upload-icon.png";
import { IoCloseOutline } from "react-icons/io5";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import { FaChevronDown } from "react-icons/fa";

export default function ContractDetails({
  contractStartDate,
  setContractStartDate,
  contractPrice,
  setContractPrice,
  contractValue,
  setContractValue,
  setShowRightPanel,
  setContractInfo,
  clientMatterId,
  handleChangeGetStartedProgress,
  contractInfo,
}) {
  var moment = require("moment");
  const [backgroundList, setBackgroundList] = useState([]);
  const [backgroundTableCols, setBackgroundTableCols] = useState([]);
  // const [column, setcolumn] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const hideToast = () => {
    setShowToast(false);
  };

  // Query Mutation
  const BACKGROUND_TABLE_QUERY = `
    query getBackgroundTable($clientMatterId: ID!) {
        backgroundTable(clientMatterId: $clientMatterId) {
            id
            columns {
            id
            accessorKey
            headerText
            enabled
            type
            optionsText
            order
            presets {
                id
                name
            }
            }
            createdAt
            updatedAt
        }
    }`;

  const mCreateBackground = `
    mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
      backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
        id
        createdAt
        date
        description
        order
      }
    }
    `;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
    mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
        backgroundTableUpdate(id: $id, input: $input) {
        id
        columns {
            id
            accessorKey
            headerText
            type
            enabled
            optionsText
            order
            presets {
            id
            name
            }
        }
        }
    }
    `;

  const BACKGROUND_TABLE_CREATE = `
        mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
        backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
            id
            columns {
            id
            accessorKey
            headerText
            enabled
            type
            optionsText
            order
            }
            createdAt
            updatedAt
        }
        }
        `;

  const UPDATE_BRIEF_PRESET_MUTATION = `
        mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
            briefUpdate(id: $id, columnIds: $columnIds){
                    id
        }
    }
    `;

  const BRIEFS_QUERY = `
        query getBriefsByClientMatter($id: ID) {
        clientMatter(id: $id) {
            briefs{
                items {
                    id
                    name
                    userTypeAccess
                    date
                    order
                    createdAt
                    createdBy {
                        id
                        firstName
                        lastName
                    }
                    updatedAt
                    updatedBy {
                        id
                        firstName
                        lastName
                    }
                    backgrounds(limit: 50, sortOrder: ORDER_ASC) {
                        items {
                            id
                            description
                            date
                            dynamicData
                        }
                    }
                    hasCommentAccess
                    columnIds
                }
            }
        }
        }
    `;

  const CREATE_BRIEF_MUTATION = `
        mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
            briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
            id
            name
            date
            createdAt
            order
            columnIds
            }
        }
    `;

  const mUpdateBackgroundDynamicData = `
        mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
            backgroundUpdate(id: $id, dynamicData: $dynamicData) {
            id
            dynamicData
            }
        }
        `;

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    getBriefs();
  }, []);

  const defaultColumnIds = [0, 1, 2, 3, 4];

  const getUpdatedBrief = async () => {
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      const contractDetailsBrief = briefsList.find(
        (brief) => brief.name === "Contract Details"
      );
      setBackgroundList(contractDetailsBrief.backgrounds.items);
    });
  };

  const handleAddRow = async (briefId, briefName) => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: briefId, name: briefName }];
      console.log(tempBriefDetails);

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
        },
      });

      console.log("created new row", createBackgroundRow);
      getBriefs();
    } catch (error) {
      console.error(error);
    }
  };

  const getBriefs = async () => {
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      const contractDetailsBrief = briefsList?.find(
        (brief) => brief.name === "Contract Details"
      );

      if (contractDetailsBrief) {
        setBackgroundList(contractDetailsBrief?.backgrounds?.items);

        let unparsedData = contractDetailsBrief?.backgrounds?.items;

        const parsedData = unparsedData?.map((item) => {
          if (item.dynamicData) {
            const dynamicDataObject = JSON.parse(item.dynamicData);
            const dynamicDataArray = Object.keys(dynamicDataObject).map(
              (key) => ({
                id: key,
                value: dynamicDataObject[key],
              })
            );
            return {
              ...item,
              dynamicData: dynamicDataArray,
            };
          }
        });

        if (parsedData[0] !== undefined && parsedData[0].length !== 0) {
          // let contractInfoTemp = {};
          // let dynamicDataTemp = parsedData[0]?.dynamicData;

          // let contractStartDate = dynamicDataTemp.find((e) =>
          //   e.id === '16'
          // );
          // if (contractStartDate) {
          //   contractInfoTemp.contractStartDate = contractStartDate.value;
          // }

          // let contractPrice = dynamicDataTemp.find((e) =>
          //   e.id === '17'
          // );
          // if (contractPrice) {
          //   contractInfoTemp.contractPrice = contractPrice.value;
          // }

          // let contractValue = dynamicDataTemp.find((e) =>
          //   e.id === '18'
          // );
          // if (contractValue) {
          //   contractInfoTemp.contractValue = contractValue.value;
          // }

          // setContractInfo(contractInfoTemp);

          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId,
            },
          });

          if (backgroundTable?.data?.backgroundTable) {
            let contractInfo = {
              contractStartDate: null,
              contractPrice: null,
              contractValue: null,
            };

            let backgroundTableInfo =
              backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
                if (obj.headerText == "Start Date") {
                  console.log("Start Date", obj.accessorKey);
                  setContractStartDate(
                    getValueById(parsedData, obj.accessorKey)
                  );
                  let contractStartDate = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                  contractInfo.contractStartDate = contractStartDate;
                  allDynamicData.current[obj.accessorKey] = contractStartDate;
                }

                if (obj.headerText == "Contract Price") {
                  console.log("Contract Price", obj.accessorKey);
                  setContractPrice(getValueById(parsedData, obj.accessorKey));
                  let contractPrice = getValueById(parsedData, obj.accessorKey);
                  contractInfo.contractPrice = contractPrice;
                  allDynamicData.current[obj.accessorKey] = contractPrice;
                }

                if (obj.headerText == "Contract Value") {
                  console.log("Contract Value", obj.accessorKey);
                  setContractValue(getValueById(parsedData, obj.accessorKey));
                  let contractValue = getValueById(parsedData, obj.accessorKey);
                  contractInfo.contractValue = contractValue;
                  allDynamicData.current[obj.accessorKey] = contractValue;
                }

                return getValueById(parsedData, obj.accessorKey);
              });

            console.log("INFOS: ", contractInfo);
            setContractInfo(contractInfo);
          }
        }
      }

      if (!contractDetailsBrief) {
        let createContractDetails = await API.graphql({
          query: CREATE_BRIEF_MUTATION,
          variables: {
            clientMatterId: clientMatterId,
            name: "Contract Details",
            date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
            order: 0,
            columnIds: defaultColumnIds,
          },
        });

        const briefCreateData = createContractDetails?.data?.briefCreate;

        handleAddRow(briefCreateData.id, briefCreateData.name);

        console.log("return ->", briefCreateData.id);

        getBriefs();
      } else {
        console.log("has briefs", briefsList);
        setDefaultBriefColumnIds(briefsList, clientMatterId);
      }
    });
  };

  // Get a value by ID based on columns
  function getValueById(data, id) {
    const item = data[0]; // Assuming there's only one item in the array (only 1 row per brief)
    const dynamicData = item?.dynamicData;
    const matchingObject = dynamicData?.find((obj) => obj.id === id);

    if (matchingObject) {
      return matchingObject.value;
    } else {
      return null; // ID not found
    }
  }

  // Add condition
  const handleSaveContractInfo = (header, val) => {
    console.log("handleSaveContractInfo");
    backgroundTableCols.map(async (obj) => {
      // list all dynamic cols

      if (obj.headerText === "Start Date" && obj.headerText === header) {
        // await updateDynamicData(obj.type, 16, val, backgroundList[0], backgroundList[0].id)
        await updateDynamicData(
          obj.type,
          obj.accessorKey,
          val,
          backgroundList[0],
          backgroundList[0].id
        );
      } else if (
        obj.headerText === "Contract Price" &&
        obj.headerText === header
      ) {
        // await updateDynamicData(obj.type, 17, val, backgroundList[0], backgroundList[0].id)
        await updateDynamicData(
          obj.type,
          obj.accessorKey,
          val,
          backgroundList[0],
          backgroundList[0].id
        );
      } else if (
        obj.headerText === "Contract Value" &&
        obj.headerText === header
      ) {
        // await updateDynamicData(obj.type, 18, val, backgroundList[0], backgroundList[0].id)
        await updateDynamicData(
          obj.type,
          obj.accessorKey,
          val,
          backgroundList[0],
          backgroundList[0].id
        );
      }
    });
  };

  const handleToastNotification = () => {
    setResultMessage("Details successfully updated");
    setShowToast(true);

    setTimeout(() => {
      hideToast();
    }, 1500);
  };

  const defaultUserAccessValues = [
    {
      label: "LEGALADMIN",
      value: "LEGALADMIN",
    },
    {
      label: "LAWYER",
      value: "LAWYER",
    },
  ];

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);
      const newDefaultColumn = updatedBriefIds.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      if (backgroundTable?.data?.backgroundTable) {
        // await setDefaultColumnBriefs(
        //   backgroundTable.data.backgroundTable,
        //   briefs
        // );
        setBackgroundTableCols(backgroundTable?.data?.backgroundTable?.columns);
        // const taggedTableColumns =
        //   backgroundTable?.data?.backgroundTable?.columns
        //     .map((c) => {
        //       let columnObj;
        //       if (c.type === "DROPDOWN") {
        //         columnObj = {
        //           accessorKey: c.id,
        //           header: c.headerText,
        //           type: c.type,
        //           enabled: c.enabled,
        //           order: c.order,
        //           presets: c.presets,
        //           optionsText: c.optionsText ? c.optionsText : [],
        //         };
        //       } else {
        //         columnObj = {
        //           accessorKey: c.id,
        //           header: c.headerText,
        //           type: c.type,
        //           enabled: c.enabled,
        //           presets: c.presets,
        //           order: c.order,
        //         };
        //       }

        //       if (columnObj.presets && parseInt(columnObj.accessorKey) !== 3) {
        //         if (columnObj.presets.length === 0) {
        //           return columnObj;
        //         }
        //         if (
        //           columnObj.presets.some(
        //             (preset) => preset.name === "Contract Details"
        //           )
        //         ) {
        //           return columnObj;
        //         }
        //       }
        //     })
        //     .filter((obj) => obj !== undefined);

        // const columnIds = taggedTableColumns.map((obj) =>
        //   parseInt(obj.accessorKey)
        // );

        // const mergedWithDefaultCols = columnIds;

        // // Exclude disabled/hidden columns on the briefs settings
        // // Unticks Email Date column on first load:

        // const filteredTaggedCols = taggedTableColumns
        //   .filter(
        //     (obj) =>
        //       mergedWithDefaultCols.includes(parseInt(obj.accessorKey)) &&
        //       obj.enabled
        //   )
        //   .map((column) => ({
        //     ...column,
        //     visible: column.header === "Email Date" ? false : true,
        //   }));

        // setcolumn(filteredTaggedCols);
      } else {
        // const initialPresets = [];
        // for (const item of briefs) {
        //   const preset = { id: item.id, name: item.name };
        //   initialPresets.push(preset);
        // }

        // let backgroundCreate = await API.graphql({
        //   query: BACKGROUND_TABLE_CREATE,
        //   variables: {
        //     clientMatterId: clientMatterId,
        //     initialPresets: initialPresets,
        //   },
        // });

        // await setDefaultColumnBriefs(
        //   backgroundCreate.data.backgroundTableCreate,
        //   briefs
        // );
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function setDefaultColumnBriefs(backgroundTables, briefs) {
    console.group("setDefaultColumnBriefs contract details");
    try {
      const { id, columns } = backgroundTables;
      let contractDetailsPreset;
      if (id) {
        let presets = [];
        const commentsColPresets = [];

        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          if (item.name == "Contract Details") {
            contractDetailsPreset = { id: item.id, name: item.name };
          }

          presets.push(preset);

          if (item.hasCommentAccess) {
            const commentPreset = { ...preset };
            commentsColPresets.push(commentPreset);
          }
        }

        let isContractDetailsPresent = false;

        // set updated columns to get the customised columns
        // setBackgroundTableCols(columns);

        const defaultBriefs = columns.map((obj) => {
          console.log("columns", obj, obj.id);
          console.log("defaultColumnIds:", defaultColumnIds);

          if (
            obj.headerText == "Start Date" ||
            obj.headerText == "Contract Price" ||
            obj.headerText == "Contract Value"
          ) {
            isContractDetailsPresent = true;
          }

          let tag = "Contract Details";

          if (
            obj.headerText == "Variation Status" ||
            obj.headerText == "Variation Cost" ||
            obj.headerText == "Variation Cost ($)"
          ) {
            tag = "Variations";
          } else if (
            obj.headerText == "Claim Status" ||
            obj.headerText == "Payment Status" ||
            obj.headerText == "Claim Amount ($)" ||
            obj.headerText == "Due Date for Claim"
          ) {
            tag = "Invoices";
          }

          return {
            ...obj,
            presets:
              obj.headerText == "Start Date" ||
              obj.headerText == "Contract Price" ||
              obj.headerText == "Contract Value"
                ? obj.presets.filter((preset) => preset.name === tag)
                : obj.headerText == "Gmail" ||
                  obj.headerText == "Label" ||
                  obj.headerText === "Email Date"
                ? obj?.presets?.filter(
                    (preset) =>
                      preset.name !== "Variations" &&
                      preset.name !== "Invoices" &&
                      preset.name !== "Contract Details"
                  ) ?? []
                : obj.headerText == "Gmail" ||
                  obj.headerText == "Label" ||
                  obj.headerText === "Email Date" ||
                  obj.headerText === "Description of Background" ||
                  obj.headerText === "Date" ||
                  obj.headerText === "Document"
                ? obj?.presets?.filter(
                    (preset) => preset.name !== "Contract Details"
                  ) ?? []
                : obj.presets,
          };
        });

        let newColumns;

        console.log("isContractDetailsPresent:", isContractDetailsPresent);
        if (!isContractDetailsPresent) {
          newColumns = [
            ...defaultBriefs,
            {
              accessorKey: defaultBriefs.length.toString(),
              id: defaultBriefs.length.toString(),
              presets: contractDetailsPreset,
              headerText: "Start Date",
              type: "DATE",
              enabled: true,
              optionsText: [],
              order: defaultBriefs.length,
            },
            {
              accessorKey: (defaultBriefs.length + 1).toString(),
              id: (defaultBriefs.length + 1).toString(),
              presets: contractDetailsPreset,
              headerText: "Contract Price",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: defaultBriefs.length + 1,
            },
            {
              accessorKey: (defaultBriefs.length + 2).toString(),
              id: (defaultBriefs.length + 2).toString(),
              presets: contractDetailsPreset,
              headerText: "Contract Value",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: defaultBriefs.length + 2,
            },
          ];
        } else {
          newColumns = defaultBriefs;
        }

        console.log(id, "new columns:", newColumns);

        const variables = {
          id,
          input: {
            columns: newColumns,
          },
        };

        setBackgroundTableCols(newColumns);

        console.log("VARIABLE ARE:", variables);

        const updateBriefTable = await API.graphql({
          query: UPDATE_BACKGROUND_TABLE_MUTATION,
          variables,
        });

        console.log("updateBriefTable", updateBriefTable);
      }
    } catch (error) {
      console.error(error);
      console.groupEnd("setDefaultColumnBriefs contract details");
    }
    console.groupEnd("setDefaultColumnBriefs contract details");
  }

  const allDynamicData = useRef({});

  const updateDynamicData = async (type, accessorKey, value, row, id) => {
    console.log("ACCESSORKEY", accessorKey);
    console.log("TYPE", type);
    console.log("ROW", row);
    console.log("ID", id);
    console.log("VALUE", value);
    const tempParsed =
      typeof row.dynamicData === "string" && row.dynamicData !== null
        ? JSON.parse(row.dynamicData)
        : row.dynamicData || {};

    const tempDynamic = { ...tempParsed, [accessorKey]: value };
    // console.log("ROW", row);
    // console.log("tempDynamic", tempDynamic);

    const data =
      type === "DATE" ? { date: moment.utc(value).toISOString() || null } : {};
    console.log("DATE", data);
    if (type === "DATE") {
      tempDynamic[accessorKey] = moment.utc(data.date).toISOString();
    }

    const tempRow = { ...row, dynamicData: tempDynamic };
    allDynamicData.current = {
      ...allDynamicData.current,
      [accessorKey]: tempRow.dynamicData[accessorKey],
    };
    // setAllDynamicData((prevState) => ({ ...prevState, [accessorKey]: tempRow.dynamicData[accessorKey] }) );
    // console.log("TEMPORARY ROW", tempRow);

    // getUpdatedBrief();
  };

  const handleSaveInfo = async (id) => {
    setShowRightPanel(false);
    
    let contractInfo = {
      contractStartDate,
      contractPrice,
      contractValue,
    };

    console.log("Saving Contract Details Dynamic Data", allDynamicData.current);
    const updateData = await API.graphql({
      query: mUpdateBackgroundDynamicData,
      variables: {
        id: id,
        dynamicData: JSON.stringify(allDynamicData.current),
      },
    }).then((result) => {
      if (allDynamicData.current.dynamicData !== undefined) {
        handleChangeGetStartedProgress("contractDetails");
      }
      console.log("Dynamic Data", allDynamicData.current);
      console.log(
        "SUCCESS CHANGING DYNAMIC DATA",
        JSON.parse(result.data.backgroundUpdate.dynamicData)
      );
    });

    console.log("INFOS: ", contractInfo);
    setContractInfo(contractInfo);
    
  };

  return (
    <>
      {width > 844 ? (
        <div className="p-3 z-10">
          <div className="flex items-start justify-between w-full pl-2">
            <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-yellow-100 rounded-3xl border-4 border-yellow-50">
              <div className="text-justify text-yellow-600 text-lg font-normal leading-tight">
                <FaFileContract />
              </div>
            </div>
          </div>

          <div className="items-start justify-between w-full py-2 px-2 flex relative font-open-sans">
            <h3 className="pb-2 text-lg font-semibold text-gray-900">
              Contract Details
            </h3>
          </div>

                <div className="items-start justify-between w-full py-1 px-2 mb-2 relative font-open-sans">
                    <p className="pb-1  text-gray-900">Start Date</p>
                    <MonthYearDatePicker
                    popperProps={{
                        positionFixed: true,
                    }}
                    className="border w-full rounded p-3 border-gray-400"
                    dateFormat={datePickerDateFormatter(
                        localStorage.getItem("userpreferredDateFormatType") == "null" 
                            ? "MM-DD-YYYY" : 
                            localStorage.getItem("userpreferredDateFormatType")
                    )}
                    selected={
                        contractStartDate
                        ? new Date(contractStartDate)
                        : null
                    }
                    placeholderText={
                        localStorage.getItem("userpreferredDateFormatType") == "null" 
                            ? "MM-DD-YYYY" 
                            : localStorage.getItem("userpreferredDateFormatType")
                    }
                    onChange={(date) => {
                        setContractStartDate(date)
                        handleSaveContractInfo("Start Date", date);
                        handleToastNotification();
                    }}
                    />
                </div>

          {/* INPUT CONTRACT PRICE */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">Contract Price</p>
            <div className="justify-center items-center mx-auto">
              <input
                type="number"
                defaultValue={contractPrice ? contractPrice : ""}
                placeholder="Contract Price"
                className="p-3 border rounded-md border-gray-400 w-full"
                style={{ resize: "none" }}
                onChange={(e) => {
                  setContractPrice(e.target.value);
                  handleSaveContractInfo("Contract Price", e.target.value);
                }}
                onBlur={() => {
                  handleToastNotification();
                }}
              ></input>
            </div>
          </div>

          {/* INPUT CONTRACT VALUE */}
          {/* <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative font-open-sans">
            <p className="pb-1  text-gray-900">Contract Value</p>
            <div className="justify-center items-center mx-auto">
              <input
                type="number"
                defaultValue={contractValue ? contractValue : ""}
                placeholder="Contract Value"
                className="p-3 border rounded-md border-gray-400 w-full"
                style={{ resize: "none" }}
                onChange={(e) => {
                  setContractValue(e.target.value);
                  handleSaveContractInfo("Contract Value", e.target.value);
                }}
                onBlur={() => {
                  handleToastNotification();
                }}
              ></input>
            </div>
          </div> */}
          <div className="p-4 gap-x-2 flex border-t">
            <Button
              className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
              block={"full"}
              onClick={() => {
                handleSaveInfo(backgroundList[0]?.id);
              }}
            >
              <span className="h-fit w-fit font-semibold"> Close </span>
            </Button>
          </div>
        </div>
      ) : (
        //mobile contract details
        <div className="z-10">
          <div className="p-3 pt-4">
            <div className="flex justify-between pb-3">
              <div className="flex items-center w-full pl-2">
                <img src={contractsIcon} alt="Builder' Icon" className="pb-2" />
                <h3 className="pb-2 text-lg font-semibold text-gray-900 pl-2">
                  Contract Details
                </h3>
              </div>
              <IoCloseOutline
                className="text-2xl text-gray-500"
                onClick={() => setShowRightPanel(false)}
              />
            </div>

                <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative font-open-sans">
                    <p className="pb-1 font-open-sans font-normal text-gray-900">Start Date</p>
                    <div className="flex flex-row justify-between items-center border w-full rounded-lg py-2 px-3 border-gray-300">
                      <MonthYearDatePicker
                        popperProps={{
                            positionFixed: true,
                        }}
                        className="w-full font-open-sans" 
                        dateFormat={datePickerDateFormatter(
                            localStorage.getItem("userpreferredDateFormatType") == "null" ? "MM-DD-YYYY" : localStorage.getItem("userpreferredDateFormatType")
                        )}
                        selected={
                            contractStartDate
                            ? new Date(contractStartDate)
                            : null
                        }
                        placeholderText={
                            localStorage.getItem("userpreferredDateFormatType") == "null" 
                                ? "MM-DD-YYYY" 
                                : localStorage.getItem("userpreferredDateFormatType")
                        }                        onChange={(date) => {
                            setContractStartDate(date)
                            handleSaveContractInfo("Start Date", date);
                            handleToastNotification();
                        }}
                      />
                      <FaChevronDown className="text-gray-500 text-sm z-100 float-right" />
                    </div>
                    
                </div>

            {/* INPUT CONTRACT PRICE */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                Contract Price
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  type="number"
                  defaultValue={contractPrice ? contractPrice : ""}
                  placeholder="Price"
                  className="py-2 px-3 border rounded-lg border-gray-300 w-full font-open-sans"
                  style={{ resize: "none" }}
                  onChange={(e) => {
                    setContractPrice(e.target.value);
                    handleSaveContractInfo("Contract Price", e.target.value);
                  }}
                  onBlur={() => {
                    handleToastNotification();
                  }}
                ></input>
              </div>
            </div>

            {/* INPUT CONTRACT VALUE */}
            {/* <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative font-open-sans">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                Contract Value
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  type="number"
                  defaultValue={contractValue ? contractValue : ""}
                  placeholder="Value"
                  className="py-2 px-3 border rounded-lg border-gray-300 w-full font-open-sans"
                  style={{ resize: "none" }}
                  onChange={(e) => {
                    setContractValue(e.target.value);
                    handleSaveContractInfo("Contract Value", e.target.value);
                  }}
                  onBlur={() => {
                    handleToastNotification();
                  }}
                ></input>
              </div>
            </div> */}
          </div>
          <div className="fixed p-4 px-6 gap-x-4 flex bottom-0 border-t w-full justify-center">
            <Button
              className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
              block={"full"}
              onClick={() => {
                setShowRightPanel(false);
              }}
            >
              <span className="h-fit w-fit font-medium font-open-sans">
                {" "}
                Cancel{" "}
              </span>
            </Button>
            <Button
              id="saveButton"
              variant="hbBlue"
              className="py-2 cursor-pointer shadow-none"
              block={"full"}
              onClick={(e) => {
                handleSaveInfo(backgroundList[0]?.id);
              }}
            >
              <span className="h-fit w-fit font-medium font-open-sans">
                {" "}
                Save{" "}
              </span>
            </Button>
          </div>
        </div>
      )}

      {showToast ? (
        <ToastNotification
          title={resultMessage}
          error={
            resultMessage === "Details successfully updated" ? false : true
          }
        />
      ) : null}
    </>
  );
}
