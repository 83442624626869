import React, { useEffect, useRef, useState } from "react";
import { API } from "aws-amplify";
import { IoSave } from "react-icons/io5";
import { CgAdd, CgTrash } from "react-icons/cg";
// import Loading from "../../loading/loading";
import { toast } from "react-hot-toast";
// import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import { GetUserCM, ListClientMatters } from "../../../shared/graphql/queries";
import cloneDeep from "lodash/cloneDeep";

const query_GetUserTypeList = `query getCompanyAccessType($companyId: String) {
    companyAccessType(companyId: $companyId) {
      userType
    }
}`;

const mTagAllClientMatter = `mutation tagAllClientMatterToUser($companyId: ID, $userId: ID, $userType: UserType) {
	userClientMatterTagAll(companyId: $companyId, userId: $userId, userType: $userType) {
		id
	}
}`;

export default function ClientMatterTab({
  close,
  isEditing,
  clientmatter,
  UserClientMatter,
  setUserClientMatter,
  originaluserClientMatter,
  setoriginaluserClientMatter,
  user,
  setalertMessage,
  setShowToast,
  seterrortoast,
  companyId,
  handleSave,
  UserType,
  isSaving,
  setIsSaving,
}) {
  const uuidv4 = (e) => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const [isLoading, setisLoading] = useState(true);
  const [ClientMatterList, setClientMatterList] = useState([]);
  const [UserTypeList, setUserTypeList] = useState([]);
  const [cmOptions, setCmOptions] = useState([]);
  const [showCM, setShowCM] = useState(false);

  const [cmloading, setcmloading] = useState(true);

  const companyName = localStorage.getItem("company");

  useEffect(() => {
    // Create an asynchronous function that we can await on.
    const initializeData = async () => {
      try {
        // Await on all the promises to be resolved.
        await Promise.all([
          getClientMatterList(),
          getUserClientMatter(),
          getUserTypeList()
        ]);
        // setShowCM is called after all above promises have resolved.
        setShowCM(true);
      } catch (error) {
        console.error(error);
      }
    };

    // Call the asynchronous initialization function.
    initializeData();
  }, []);


  // if the user cancelled editing, reset UserClientMatter to the original
  useEffect(() => {
    if (!isEditing) {
      //To avoid overriding the originaluserClientMatter state variable
      let originalData = cloneDeep(originaluserClientMatter);
      setUserClientMatter(originalData);
    }
  }, [isEditing]);

  const getClientMatterList = async () => {
    try {
      const { data } = await ListClientMatters(companyId);
      // Use optional chaining and nullish coalescing to provide a default empty array.
      const clientMattersList = data?.company?.clientMatters?.items ?? [];

      // Assuming setClientMatterList and setCmOptions are setState actions.
      if (clientMattersList.length > 0) {
        setClientMatterList(clientMattersList);

        const options = clientMattersList.map((x) => ({
          label: `${x?.client?.name}/${x?.matter?.name}`,
          value: x?.id,
        }));

        setCmOptions(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   console.log("CompanyID: ", localStorage.getItem("companyId"));
  //   console.log("Company Name Variable: ", companyName);
  //   console.log("Company Name: ", localStorage.getItem("company"));
  // }, []);

  const getUserTypeList = async (e) => {
    console.log("getUserTypeList()");
    // The function first calls a GraphQL query query_GetUserTypeList with a variable companyId.
    // The response data is destructured to get only the data property, which contains an array of companyAccessType objects.
    // The companyAccessType objects have two properties: userType and value.
    // Then, the code creates a new array by mapping each object in data.companyAccessType to a new object having only label and value properties.
    // Lastly, the userTypeList array is logged to the console and set to the state using setUserTypeList method.
    // If the data.companyAccessType array is empty, then an empty array is assigned to userTypeList.
    const { data } = await API.graphql({
      query: query_GetUserTypeList,
      variables: {
        companyId,
      },
    });

    const userTypeList = (data?.companyAccessType || []).map((type) => ({
      label: type.userType,
      value: type.userType,
    }));

    setUserTypeList(userTypeList);
  };

  const getUserClientMatter = async (e) => {
    console.log("getUserClientMatter()");
    const { data } = await GetUserCM(user.id, companyId);
    // const { data } = await API.graphql({
    //   query: qUserClientMatter,
    //   variables: {
    //     companyId: companyId,
    //     userId: user.id,
    //   },
    // });

    setIsSaving(false);

    const clientMatters = data?.user?.clientMatterAccess?.items ?? [];

    const userClientMatter = clientMatters.map(
      ({ userType, clientMatter }) => ({
        userType,
        clientMatter,
      })
    );

    console.log("GET USER CLIENT MATTER:", userClientMatter);

    setUserClientMatter(userClientMatter);

    //To make a deep copy of the userClientMatter, and later enable cancelling of changes.
    setoriginaluserClientMatter(cloneDeep(userClientMatter));
    setcmloading(false);
  };

  // const selectRef = useRef();

  const handleDelete = (index, cm) => {
    var newArr = UserClientMatter.filter((x, idx) => idx !== index);
    let newOpts = selectedCmList.filter((x, idx) => x !== cm);

    setUserClientMatter(newArr);
    setcmloading(false);
  };

  const tagUserType = (evt, index) => {
    let temp = [...UserClientMatter];

    // this is for isMulti
    //let updatedUserType = evt.map(function (item) { return item.value; });
    //temp[index].userType = updatedUserType.join('');

    temp[index].userType = evt.value;

    setUserClientMatter(temp);
    setcmloading(false);
  };

  /* useEffect(() => {
    let tempClientMatterList = [];
    UserClientMatter.map((item, i) => {
      tempClientMatterList = ClientMatterList.filter(
        (y) => y.id != item.clientMatter.id
      );
    });
    setClientMatterList(tempClientMatterList);
  }, []); */
  const [selectedIdList, setSelectedIdList] = useState([]);
  const [selectedCmList, setSelectedCmList] = useState([]);

  const handleTagAllClientMatter = async () => {
    try {
      const params = {
        companyId,
        userId: user.id,
        userType: user.userType,
      };

      const { data } = await API.graphql({
        query: mTagAllClientMatter,
        variables: params,
      });

      console.log(data);

      toast.success("All Client/Matter have been saved.");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    }

    close();
  };

  const handleTagMore = (e) => {
    e.preventDefault();
    componentDidMount();

    console.log("CLIENTMATTER LIST: ", ClientMatterList);
    console.log("SELECTED ID LIST", selectedIdList);

    let tempClientMatterId = "";
    let tempClientName = "";
    let tempMatterName = "";
    let tempClientId = "";
    let tempMatterId = "";

    for (let i = 0; i < ClientMatterList.length; i++) {
      const { id, client, matter } = ClientMatterList[i];

      if (!selectedIdList.includes(id)) {
        console.log("DETECTED CONDITION", id);

        tempClientMatterId = id;
        tempClientName = client?.name;
        tempMatterName = matter?.name;
        tempClientId = client?.id;
        tempMatterId = matter?.id;

        break;
      }
    }

    console.log(
      "\n\n\n\nTEMPCLIENTMATTERID: ",
      tempClientName !== "" ? "NO" : tempClientName
    );

    if (tempClientName !== "" && tempMatterName !== "") {
      setUserClientMatter([
        ...UserClientMatter,
        {
          id: uuidv4(),
          userType: user.userType,
          clientMatter: {
            id: tempClientMatterId,
            client: {
              name: tempClientName,
              id: tempClientId,
            },
            matter: {
              name: tempMatterName,
              id: tempMatterId,
            },
          },
        },
      ]);
    } else {
      setalertMessage("All Client/Matter were already tagged.");
      seterrortoast(true);
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
        seterrortoast(null);
      }, 3000);
    }

    setcmloading(false);
  };

  function getCmVal(itemId) {
    if (itemId) {
      var tempVar;
      UserClientMatter.map((x) =>
        x.clientMatter?.id === itemId
          ? (tempVar = `${x.clientMatter?.client?.name}/${x.clientMatter?.matter?.name}`)
          : x
      );
      return [{ label: tempVar, value: itemId }];
    } else {
      return null;
    }
  }

  function filterOptions() {
    var usercm = UserClientMatter;
    var allcm = cmOptions;
    var newOptions = [];

    var newArr = allcm.filter((n) =>
      usercm.map((x) => !x.id.includes(n.value))
    );

    for (var i = 0; i < allcm.length; i++) {
      var temp = usercm.map((x) => x.id.includes(allcm[i].value));
    }

    return newArr;
  }

  const cmEndRef = React.createRef();

  const scrollToBottom = () => {
    cmEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function componentDidMount() {
    scrollToBottom();
  }

  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    function handleZoomChange() {
      const newZoomLevel = Math.round(window.devicePixelRatio * 100) / 100;
      setZoomLevel(newZoomLevel);
    }

    window.addEventListener("resize", handleZoomChange);
    handleZoomChange();

    return () => {
      window.removeEventListener("resize", handleZoomChange);
    };
  }, []);

  useEffect(() => {
    console.log("USER CLIENT MATTER FROM USE EFFECT:", UserClientMatter);
    //setSelectedCmList([]);
    let temp = [];
    let tempIdList = [];
    UserClientMatter?.map((item, index) => {
      tempIdList.push(item.clientMatter.id);

      temp.push(
        `${item.clientMatter?.client?.name}/${item.clientMatter?.matter?.name}`
      );
      setSelectedIdList(tempIdList);
      setSelectedCmList(temp);
      //console.log("Selected Options: ", selectedCmList);
      //console.log("Current Option: ", cmOptions[index]);
    });
    // console.log("Selected ID", selectedIdList);
  }, [UserClientMatter]);

  // useEffect(() => {
  // 	if (isEditing) {
  // 		UserClientMatter.map((item) => {
  // 			item.userType = UserType.value;
  // 		});
  // 	}
  // }, [isEditing, UserType]);

  return showCM ? (
    <>
      {/* {UserTypeList?.length < 1 || UserClientMatter?.length < 1 ? (
        <Loading />
      ) : (
        <>
      */}
      <div className="flex flex-col gap-2 flex-1 overflow-y-auto h-full z-40">
        {UserClientMatter?.length === 0 ? (
          cmloading ? (
            <>
              <SkeletonTheme width={"150px"} height={"25px"}>
                <Skeleton count={1} />
              </SkeletonTheme>
              <div className="flex gap-2 justify-end">
                <SkeletonTheme width={"270px"} height={"40px"}>
                  <Skeleton count={5} />
                </SkeletonTheme>
                <SkeletonTheme width={"270px"} height={"40px"}>
                  <Skeleton count={5} />
                </SkeletonTheme>
              </div>
            </>
          ) : (
            <p className="mt-5 text-center">
              No Client/Matter Access assigned to this User. <br /> Please add a
              new access using the &apos;Edit Contact&apos; button above.
            </p>
          )
        ) : (
          <>
            <p>
              You have{" "}
              <span className="font-black text-blue-500">
                {UserClientMatter?.length}
              </span>{" "}
              Matters on your list.
            </p>

            <div className="inline-block">
              {isEditing ? (
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  onClick={(e) => {
                    handleTagAllClientMatter();
                  }}
                >
                  Tag All Client/Matter
                </button>
              ) : null}
            </div>

            {UserClientMatter.map((item, index) => (
              // console.log("Selected Options: ", selectedCmList);

              <React.Fragment key={index}>
                {UserClientMatter.includes(item) === true && (
                  <div
                    key={index}
                    className="flex flex-col gap-2  md:flex-row md:items-center"
                    // className="inline-flex w-full gap-2 flex"
                  >
                    <div className="flex flex-col gap-2 p-1 w-full justify-center md:flex-row ">
                      <div className="w-full h-auto">
                        <Select
                          menuPortalTarget={document.body}
                          name="client"
                          key={`clientMatter::${index}`}
                          options={cmOptions.map((cm_option, index) => ({
                            label: cm_option.label,
                            value: cm_option.value,
                            isDisabled: selectedCmList.includes(
                              cm_option.label
                            ),
                          }))}
                          value={{
                            label: `${item.clientMatter?.client?.name} / ${item.clientMatter?.matter?.name}`,
                            value: item.clientMatter?.id,
                          }}
                          maxMenuHeight={150}
                          isSearchable={true}
                          isClearable={false}
                          isDisabled={!isEditing}
                          onChange={(selectedOption) => {
                            console.log(selectedOption);
                            let temp = [...UserClientMatter];
                            console.log("temp", temp);

                            if (!selectedOption) {
                              console.log("Value undefined");
                            } else {
                              temp[index].clientMatter = ClientMatterList.find(
                                (item) => item.id === selectedOption.value
                              );

                              setUserClientMatter(temp);
                            }
                            setcmloading(false);
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "100%",
                              border: "1px solid #ccc", // Adjust the border style
                            }),
                            // Add other custom styles as needed
                            container: (base) => ({
                              ...base,
                              zIndex: "99999",
                            }),
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          className="w-full"
                        />
                      </div>
                      {/* <select
                      key={`clientMatter::${index}`}
                      className={`border-2 ${
                        isEditing ? "border-cyan-500" : "border-gray-200"
                      } rounded-md w-full focus:border-gray-100 text-gray-400 p-2 `}
                      name="client"
                      value={item.clientMatter.id}
                      disabled={!isEditing}
                      ref={selectRef}
                      onChange={(e) => {
                        let temp = [...UserClientMatter];

                        temp[index].clientMatter = ClientMatterList.find(
                          (item) => item.id === e.target.value
                        );

                        setUserClientMatter(temp);
                      }}
                      onLoad={(e) => {
                        let temp = [...UserClientMatter];

                        temp[index].clientMatter = ClientMatterList.find(
                          (item) => item.id === e.target.value
                        );

                        setUserClientMatter(temp);
                      }}
                    > */}
                      {/* {item.clientMatter.id === undefined } */}
                      {/* <option disabled value={""}>
                        Select Client/Matter
                      </option> */}
                      {/* // Not-Optimized version (2023-03-04) */}
                      {/* {ClientMatterList &&
                        ClientMatterList.map((y) => {
                          if (y.id === item.clientMatter.id) {
                            return (
                              <option value={y.id}>
                                {y.client.name} / {y.matter.name}
                              </option>
                            );
                          }
                          if (
                            item.clientMatter.id !== null &&
                            !selectedIdList.includes(y.id)
                          ) {
                            return (
                              <option value={y.id}>
                                {y.client.name} / {y.matter.name}
                              </option>
                            );
                          }
                        })}


                      {ClientMatterList &&

                        ClientMatterList.map((y) => {
                          If id matches, or selectedIdList includes the id, then show option.
                          if (
                            y?.id === item?.clientMatter?.id ||
                            selectedIdList.includes(y?.id)
                          ) {
                            return (
                              <option value={y?.id}>
                                {y?.client?.name} / {y?.matter?.name}
                              </option>
                            );
                          }
                          return null;
                        })}
                    </select> */}
                      {/* <select
                          className="border-2 w-full border-gray-200 rounded-md p-2 focus:border-gray-100 text-gray-400"
                          name="type"
                          disabled={!isEditing}
                          value={item.userType}
                          onChange={(e) => {
                            let temp = [...UserClientMatter];

                            temp[index].userType = e.target.value;

                            setUserClientMatter(temp);
                          }}
                        >
                          <option disabled value={""}>
                            Select User Type
                          </option>
                          {UserTypeList &&
                            UserTypeList.map((y, j) => {
                              return (
                                <option key={`usertype::${j}`} value={y.userType}>
                                  {y.userType}
                                </option>
                              );
                            })}
                          </select> */}

                      {/* <CreatableSelect
                          defaultValue={() => [
                            { label: item.userType, value: item.userType },
                          ]}
                          menuPortalTarget={document.body}
                          styles={{
                            control: (styles, { isDisabled }) => {
                              return {
                                ...styles,
                                cursor: isDisabled ? "not-allowed" : "default",
                                backgroundColor: "white",
                                color: "black",
                              };
                            },
                            container: (base) => ({
                              ...base,
                              zIndex: "99999",
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          options={UserTypeList}
                          isClearable={false}
                          isSearchable
                          openMenuOnClick={true}
                          //isMulti
                          //onChange={(
                           //   e,
                          //    action
                          //  ) => {
                          //    tagLabel(
                          //      e,
                           //     action,
                            //    item.id,
                           //     item
                           //   );
                         //   }}
                          onChange={(e) => {
                            tagUserType(e, index);
                          }}
                          onClick={(e) => {
                            alert(e);
                          }}
                          isDisabled={!isEditing}
                          placeholder="Select Usertypes"
                          className="w-1/2 placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring"
                        /> */}
                      <div className="w-full">
                        <Select
                          menuPortalTarget={document.body}
                          options={UserTypeList.map((userType) => ({
                            label: userType.label,
                            value: userType.value,
                          }))}
                          value={{
                            label: item.userType,
                            value: item.userType,
                          }}
                          isSearchable={true}
                          isClearable={false}
                          isDisabled={!isEditing}
                          onChange={(selectedOption) => {
                            console.log("selected Option", selectedOption);
                            if (!selectedOption) {
                              console.log("No Usertype Value");
                            } else {
                              const selectedUserType = selectedOption.value;
                              tagUserType(selectedOption, index);
                              console.log(
                                "Selected User Type:",
                                selectedUserType,
                                "Index:",
                                index
                              );
                            }
                          }}
                          onMenuOpen={() => {
                            document.body.style.overflow = "hidden";
                          }}
                          onMenuClose={() => {
                            document.body.style.overflow = "hidden";
                          }}
                          maxMenuHeight={150} // This limits the dropdown height
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "white",
                              color: "black",
                              width: "100%",
                            }),
                            // Add other custom styles as needed
                            container: (base) => ({
                              ...base,
                              zIndex: "99999",
                            }),
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </div>

                    {isEditing && (
                      <button
                        className=" border border-gray-200 text-4xl rounded p-2 cursor-pointer hover:bg-gray-100 w-9 md:h-10 "
                        onClick={() =>
                          handleDelete(
                            index,
                            `${item.clientMatter?.client?.name}/${item.clientMatter?.matter?.name}`
                          )
                        }
                      >
                        <CgTrash color={`lightcoral`} />
                      </button>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
            <div ref={cmEndRef} className="mb-10" />
          </>
        )}

        {/* action buttons */}

        {!isEditing && UserClientMatter?.length > 0 && (
          <p className="text-xs text-gray-400 mt-5">
            Click the &apos;Edit Contact&apos; button above to tag, untag, or
            change client/matter access
          </p>
        )}
      </div>

      {isEditing && (
        <div className="flex gap-2 mt-8">
          {/* add more button */}
          <button
            className="flex gap-2 items-center px-3 py-2 border-cyan-500 border-2 w-full justify-center text-cyan-500 rounded-md cursor-pointer"
            onClick={handleTagMore}
          >
            Add More{" "}
            <span>
              <CgAdd />
            </span>
          </button>

          {/* save button */}
          {isSaving ? (
            <button className="flex gap-2 items-center px-3 py-2 opacity-50 border-gray-500 border-2 w-full justify-center text-gray-500 rounded-md cursor-not-allowed">
              Saving...
            </button>
          ) : (
            <button
              onClick={() => {
                handleSave();
                setIsSaving(true);
              }}
              disabled={isSaving}
              className="flex gap-2 items-center px-3 py-2 border-green-500 border-2 w-full justify-center text-green-500 rounded-md cursor-pointer"
            >
              Save
              <IoSave />
            </button>
          )}
        </div>
      )}
    </>
  ) : (
    //)}
    /** </> */
    <>
      <SkeletonTheme width={"150px"} height={"25px"}>
        <Skeleton count={1} />
      </SkeletonTheme>
      <div className="flex gap-2 justify-end">
        <SkeletonTheme width={"270px"} height={"40px"}>
          <Skeleton count={5} />
        </SkeletonTheme>
        <SkeletonTheme width={"270px"} height={"40px"}>
          <Skeleton count={5} />
        </SkeletonTheme>
      </div>
    </>
  );
}
