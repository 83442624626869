import React, { useState, useEffect, useRef } from "react";
import { alphabetArray } from "../../../constants/Alphabet";
import { API } from "aws-amplify";

import { FaEdge, FaEdit } from "react-icons/fa";
import { CgSortAz, CgSortZa } from "react-icons/cg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function MattersTab({
  matters,
  setShowEditMatterModal,
  setSelectedMatter,
  getMatters,
  ascDesc,
  setAscDesc,
  alphabetArrayState,
  setAlphabetArrayState,
  setAddedMatter,
  addedMatter,
  refLetters,
  shortcutSelected,
  skeletonLoad,
  setMatters,
  setSkeletonLoad,
  sortBy,
  setSortBy,
  mattersAlphabet,
  setMattersAlphabet,
}) {
  const companyId = localStorage.getItem("companyId");

  const mcreateClientMatterContacts = `
  mutation createClientMatterContacts($type: ContactType, $clientMatterId: String, $details: ContactDetailsInput) {
    clientMatterContactDetailsCreate(
      clientMatterId: $clientMatterId
      type: $type
      details: $details
    ) {
      id
    }
  }`;

  useEffect(() => {
    if (!skeletonLoad) {
      setSkeletonLoad(true);
      setTimeout(() => {
        setSkeletonLoad(false);
      }, 150);
    }
  }, []);

  const initContacts = (currentMatter) => {
    const emptyArray = [];

    if (currentMatter.contacts.items.length === 0) {
      emptyArray.push("CLIENT");
      emptyArray.push("SOLICITOR");
      emptyArray.push("BARRISTER");
      emptyArray.push("EXPERT");
      emptyArray.push("OTHERPARTY");
      emptyArray.push("OTHERPARTY_SOLICITOR");
    }
    console.log(emptyArray);
    if (emptyArray.length > 0) {
      Promise.all(
        emptyArray.map((value) => createInitialContact(value, currentMatter.id))
      ).then(() => getMatters());
    }
    getMatters();
  };

  async function createInitialContact(ContactType, matterId) {
    const details = {
      name: "",
      number: "",
      email: "",
    };
    try {
      const createClientMatter = await API.graphql({
        query: mcreateClientMatterContacts,
        variables: {
          type: ContactType,
          clientMatterId: matterId,
          details: details,
        },
      }).then((response) => {
        console.log("Create Contact: ", response);
      });
    } catch {}
  }

  const [isSortedByClient, setIsSortedByClient] = useState(false);
  const [isSortedByMatter, setIsSortedByMatter] = useState(false);

  const handleSortByClient = () => {
    if (ascDesc === null) {
      setMatters(
        matters.sort((a, b) => a.client?.name?.localeCompare(b.client?.name))
      );
      console.log("Client Desc", matters);
      setAscDesc(true);
      setMattersAlphabet(
        matters
          .map((item) => item?.client?.name?.[0])
          .filter(onlyUnique)
          .sort((a, b) => b.localeCompare(a))
      );
      setAlphabetArrayState(alphabetArrayState.reverse());
      setIsSortedByMatter(true);
    } else if (ascDesc === true) {
      setMatters(
        matters.sort((a, b) => a.client?.name?.localeCompare(b.client?.name))
      );
      console.log("Client asc", matters);
      setMattersAlphabet(
        matters
          .map((item) => item?.client?.name?.[0])
          .filter(onlyUnique)
          .sort((a, b) => a.localeCompare(b))
      );
      setAlphabetArrayState(alphabetArrayState.sort());
      setAscDesc(null);
      setIsSortedByMatter(false);
    }

    setSortBy("Client");
    console.log("Client", sortBy);
    setIsSortedByClient(false);
  };

  const handleSortByMatter = () => {
    if (ascDesc === null) {
      setMatters(
        matters.sort((a, b) => a.matter?.name?.localeCompare(b.matter?.name))
      );
      setMattersAlphabet(
        matters
          .map((item) => item?.matter?.name?.[0])
          .filter(onlyUnique)
          .sort((a, b) => b.localeCompare(a))
      );
      console.log(mattersAlphabet.sort((a, b) => b.localeCompare(a)));
      setAscDesc(true);
      setAlphabetArrayState(alphabetArrayState.reverse());
      setIsSortedByClient(true);
    } else if (ascDesc === true) {
      setMatters(
        matters.sort((a, b) => a.matter?.name?.localeCompare(b.matter?.name))
      );
      setMattersAlphabet(
        matters
          .map((item) => item?.matter?.name?.[0])
          .filter(onlyUnique)
          .sort((a, b) => a.localeCompare(b))
      );
      console.log(mattersAlphabet.sort((a, b) => a.localeCompare(b)));
      setAlphabetArrayState(alphabetArrayState.sort());
      setAscDesc(null);
      setIsSortedByClient(false);
    }
    setSortBy("Matter");
    console.log("Matter", sortBy);
    setIsSortedByMatter(false);
  };

  console.log(ascDesc);

  // const handleSort = (sortedReverse, sortBy) => {
  //   console.log(matters);
  //   if (sortedReverse) {
  //     if (sortBy === "name") {
  //       setAlphabetArrayState(alphabetArrayState.sort());
  //       console.log("mattersAlphabet Array State", alphabetArrayState, sortBy);
  //       setMatters(
  //         matters.sort((a, b) =>
  //           sortBy === "Matter"
  //             ? a.matter?.name.localeCompare(b.matter?.name)
  //             : a.client?.name.localeCompare(b.client?.name)
  //         )
  //       );

  //       setMattersAlphabet(mattersAlphabet.sort());
  //     }
  //   } else {
  //     if (sortBy === "name") {
  //       setAlphabetArrayState(alphabetArrayState.reverse());
  //       setMatters(
  //         matters
  //           .sort((a, b) =>
  //             sortBy === "Client"
  //               ? a.matter?.name.localeCompare(b.matter?.name)
  //               : a.client?.name.localeCompare(b.client?.name)
  //           )
  //           .reverse()
  //       );
  //       setMattersAlphabet(mattersAlphabet.sort().reverse());
  //     }
  //   }
  // };

  // const RenderSort = ({ sortBy }) => {
  //   return (
  //     <>
  //       {IsSortedReverse ? (
  //         <CgSortAz
  //           onClick={() => {
  //             setIsSortedReverse(!IsSortedReverse);
  //             handleSort(IsSortedReverse, sortBy);
  //           }}
  //           className="text-xl cursor-pointer hover:text-gray-500"
  //         />
  //       ) : (
  //         <CgSortZa
  //           onClick={() => {
  //             setIsSortedReverse(!IsSortedReverse);
  //             handleSort(IsSortedReverse, sortBy);
  //           }}
  //           className="text-xl cursor-pointer hover:text-gray-500"
  //         />
  //       )}
  //     </>
  //   );
  // };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  useEffect(() => {
    if (sortBy === "Matter") {
      setMattersAlphabet(
        matters
          .map((matter) => matter?.matter?.name?.[0])
          .filter(onlyUnique)
          .sort((a, b) => a.localeCompare(b))
      );
    } else {
      setMattersAlphabet(
        matters
          .map((matter) => matter?.client?.name[0])
          .filter(onlyUnique)
          .sort((a, b) => a.localeCompare(b))
      );
    }
  }, []);

  /* useEffect(() => {
    
    initContacts(matters[0]);
  }, [matters]); */

  // useEffect(() => {
  //   alphabetArray.sort((a, b) => a.localeCompare(b));
  //   setAscDesc(null);
  //   return () => {
  //     setAscDesc(null);
  //     alphabetArray.sort((a, b) => a.localeCompare(b));
  //   };
  // }, []);

  return (
    <>
      <>
        <div className="w-full py-2 mr-5">
          <table className="pr-10 overflow-x-auto block table-fixed">
            <thead className="sticky bg-white z-10">
              <tr>
                <th>
                  <div className="py-2 w-36  mr-20 font-normal flex justify-start">
                    Client
                    {ascDesc === null ? (
                      <CgSortZa
                        onClick={handleSortByClient}
                        className={`${
                          isSortedByClient ? "hidden" : ""
                        } text-xl cursor-pointer hover:text-gray-500`}
                      />
                    ) : ascDesc === true ? (
                      <CgSortAz
                        onClick={handleSortByClient}
                        className={`${
                          isSortedByClient ? "hidden" : ""
                        } text-xl cursor-pointer hover:text-gray-500`}
                      />
                    ) : (
                      <CgSortZa
                        onClick={handleSortByClient}
                        className={`${
                          isSortedByClient ? "hidden" : ""
                        } text-xl cursor-pointer hover:text-gray-500`}
                      />
                    )}
                  </div>
                </th>
                <th>
                  <div className="py-2 w-36 mr-20 font-normal flex justify-start ">
                    Matter{" "}
                    {ascDesc === null ? (
                      <CgSortZa
                        onClick={handleSortByMatter}
                        className={`${
                          isSortedByMatter ? "hidden" : ""
                        } text-xl cursor-pointer hover:text-gray-500`}
                      />
                    ) : ascDesc === true ? (
                      <CgSortAz
                        onClick={handleSortByMatter}
                        className={`${
                          isSortedByMatter ? "hidden" : ""
                        } text-xl cursor-pointer hover:text-gray-500`}
                      />
                    ) : (
                      <CgSortZa
                        onClick={handleSortByMatter}
                        className={`${
                          isSortedByMatter ? "hidden" : ""
                        } text-xl cursor-pointer hover:text-gray-500`}
                      />
                    )}
                  </div>
                </th>
                <th>
                  <div className="py-2 w-36 mr-20 font-normal flex justify-start">
                    Client Details
                  </div>
                </th>
                <th>
                  <div className="py-2 w-36 mr-20 font-normal flex justify-start">
                    Solicitor Details
                  </div>
                </th>
                <th>
                  <div className="py-2 w-36 mr-20 font-normal flex justify-start">
                    Barrister Details
                  </div>
                </th>
                <th>
                  <div className="py-2 w-36 mr-20 font-normal flex justify-start">
                    Expert Details
                  </div>
                </th>
                <th>
                  <div className="py-2 w-36 mr-20 font-normal flex justify-start">
                    Other Party Details
                  </div>
                </th>
                <th>
                  <div className="py-2 w-40 mr-24 font-normal flex justify-start">
                    Other Party Solicitor Details
                  </div>
                </th>
                <th>
                  <div className="py-2 w-20 mx-7 font-normal flex justify-start">
                    Edit
                  </div>
                </th>
              </tr>
            </thead>

            {skeletonLoad ? (
              <>
                <tbody>
                  {[...Array(8).keys()].map((item, index) => (
                    <>
                      <tr className="stripe opacity-100 break-words">
                        {[...Array(7).keys()].map((i) => (
                          <>
                            <td className="p-2">
                              <p className="font-medium text-center">
                                <SkeletonTheme>
                                  <Skeleton />
                                </SkeletonTheme>
                                <SkeletonTheme>
                                  <Skeleton />
                                </SkeletonTheme>
                                <SkeletonTheme>
                                  <Skeleton />
                                </SkeletonTheme>
                              </p>
                            </td>
                          </>
                        ))}
                      </tr>
                    </>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                <tbody>
                  {mattersAlphabet.map((letter, idx) => (
                    <>
                      <tr
                        ref={(el) => (refLetters.current[idx] = el)}
                        id={letter}
                        key={letter}
                        className=""
                      >
                        <td className="pt-4 px-2">
                          <div className="flex items-center gap-x-2">
                            <p
                              className={`${
                                shortcutSelected === letter
                                  ? "text-cyan-500 font-bold"
                                  : "text-gray-700 font-semibold"
                              }  text-lg `}
                            >
                              {letter}
                            </p>
                          </div>
                        </td>
                      </tr>
                      {matters &&
                        matters.map(
                          (item, index) =>
                            item.matter?.name?.charAt(0) === letter && (
                              <tr className="stripe opacity-100 break-words">
                                <td>
                                  <p className="font-medium text-center">
                                    {item.client?.name}
                                  </p>
                                </td>
                                <td>
                                  <p className="font-medium text-center">
                                    {item.matter?.name}
                                  </p>
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "CLIENT" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <div className="my-2">
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </div>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "SOLICITOR" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "BARRISTER" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "EXPERT" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "OTHERPARTY" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type ===
                                          "OTHERPARTY_SOLICITOR" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <div className="pl-6">
                                    <button
                                      className="p-3 w-max font-semibold text-gray-500 rounded-full hover:bg-gray-200"
                                      onClick={() => {
                                        setShowEditMatterModal(true);
                                        console.log("Matter", item);
                                        setSelectedMatter(item);
                                      }}
                                    >
                                      <FaEdit />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                        )}

                      {matters &&
                        matters.map(
                          (item, index) =>
                            item.client?.name.charAt(0) === letter && (
                              <tr className="stripe opacity-100 break-words">
                                <td>
                                  <p className="font-medium text-center">
                                    {item.client?.name}
                                  </p>
                                </td>
                                <td>
                                  <p className="font-medium text-center">
                                    {item.matter?.name}
                                  </p>
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "CLIENT" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <div className="my-2">
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </div>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "SOLICITOR" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "BARRISTER" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "EXPERT" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type === "OTHERPARTY" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {item.contacts.items.length === 0 ? (
                                    <>
                                      <p className="font-medium">Name:</p>
                                      <p className="font-medium">Number:</p>
                                      <p className="font-medium">Email:</p>
                                    </>
                                  ) : (
                                    <>
                                      {item.contacts.items.map((contact) => (
                                        <>
                                          {contact.type ===
                                          "OTHERPARTY_SOLICITOR" ? (
                                            <>
                                              {contact.details
                                                .slice(0, 3)
                                                .map((detail) => (
                                                  <>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Name:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.name}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Number:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.number}
                                                      </p>
                                                    </div>
                                                    <div className="flex">
                                                      <p className="font-medium">
                                                        Email:
                                                      </p>
                                                      <p className="font-normal ml-1">
                                                        {detail.email}
                                                      </p>
                                                    </div>
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <div className="pl-6">
                                    <button
                                      className="p-3 w-max font-semibold text-gray-500 rounded-full hover:bg-gray-200"
                                      onClick={() => {
                                        setShowEditMatterModal(true);
                                        console.log("Matter", item);
                                        setSelectedMatter(item);
                                      }}
                                    >
                                      <FaEdit />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                        )}
                    </>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </>
    </>
  );
}
