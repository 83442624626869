import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import momentTZ from "moment-timezone";
import { dateFormatter } from "../../shared/userPreferredDateTime"; // Replace with the correct path
import { AiOutlineDownload } from "react-icons/ai";

const MobileTableInfo = ({
  background,
  style,
  readMoreState,
  isReadMoreExpanded,
  setSaving,
  getRowLabels,
  handleDescContent,
  handleReadMoreState,
  handleChangeDesc,
  handleSaveDesc,
  handleSnippetEmail,
  virtualRows,
  rows,
}) => {
  const [openMoreLabels, setOpenMoreLabels] = useState(false);
  const [openMoreLabelsIndex, setOpenMoreLabelsIndex] = useState(null);

  return (
    <div>
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const item = row.original;
        const index = row.index;

        return (
          <div
            key={item.id}
            className="flex flex-row w-full px-5"
            style={{
              ...style,
              width: "100%",
              borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
              borderBottomStyle: "dashed",
              paddingTop: index === 0 ? 0 : 20,
              paddingBottom: 20,
              maxHeight: "350px",
            }}
          >
            <p className="font-semibold text-cyan-400">{index + 1}</p>
            <div className="ml-2 overflow-y-auto">
              {/* Date */}
              <p className="font-medium text-cyan-400">
                {item.date !== null && item.date !== ""
                  ? dateFormatter(item.date)
                  : "No date"}
              </p>
              {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
              <p
                id={item.id + ".desc"}
                className="absolute text-red-200 invisible pointer-events-none opacity-0"
                style={{
                  top: -10000,
                  zIndex: -1000,
                  marginRight: "20px",
                  wordBreak: "break-word",
                }}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></p>

              {/* Description */}
              <p
                id={item.id + ".descM"}
                className={`overflow-y-auto ${
                  !isReadMoreExpanded(item.id) ? "line-clamp-6" : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
                style={{
                  wordBreak: "break-word",
                  outlineColor: "rgb(204, 204, 204, 0.5)",
                  outlineWidth: "thin",
                }}
                contentEditable={true}
                suppressContentEditableWarning
                onClick={(event) => {
                  handleDescContent(event, item.description, item.id, index);
                  setSaving(true);
                }}
                onFocus={() => {
                  setSaving(true);
                  //To auto expand the div when editing
                  if (readMoreState.includes(item.id) === false) {
                    handleReadMoreState(item.id);
                  }
                }}
                onInput={(event) => handleChangeDesc(event, index)}
                onBlur={(e) => {
                  handleSaveDesc(
                    e,
                    item.description,
                    item.date,
                    item.id,
                    index
                  );
                  setSaving(false);
                }}
              ></p>

              {/* Read More Button */}
              {item.description?.length > 70 && (
                <button
                  id={item.id + ".descButton"}
                  className="text-cyan-400 mb-2"
                  onClick={() => {
                    handleReadMoreState(item.id);
                  }}
                >
                  {isReadMoreExpanded(item.id)
                    ? "read less..."
                    : "read more..."}
                </button>
              )}

              {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
              <p
                id={item.id + ".files"}
                className="absolute text-red-200 invisible pointer-events-none opacity-0 break-words"
                style={{
                  top: -10000,
                  zIndex: -1000,
                  marginRight: "20px",
                  lineHeight: "30px",
                  wordBreak: "break-word",
                }}
              >
                {item.files.items.map((file) => (
                  <button
                    key={file.id}
                    className="font-extralight text-sm text-red-400 border rounded-lg px-2 mr-2 my-1"
                    onClick={() => handleSnippetEmail(file)}
                  >
                    {file.name}&nbsp;
                    <AiOutlineDownload className="text-gray-400 text-sm cursor-pointer inline-block" />
                  </button>
                ))}
              </p>

              {/* Files List */}
              <p
                className={
                  (isReadMoreExpanded(item.id) ? "" : "line-clamp-1") +
                  " break-words"
                }
                style={{
                  lineHeight: "30px",
                  wordBreak: "break-word",
                }}
              >
                {item.files.items.map((file) => (
                  <button
                    key={file.id}
                    className="font-extralight text-sm focus:text-cyan-400 focus:border-cyan-400 text-gray-400 border rounded-lg px-2 mr-2 my-1"
                    onClick={() => handleSnippetEmail(file)}
                  >
                    {file.name}&nbsp;
                    <AiOutlineDownload className="text-sm cursor-pointer inline-block" />
                  </button>
                ))}
              </p>

              {/* Labels List */}
              <div>
                <button
                  className="w-full"
                  onClick={(e) => {
                    setOpenMoreLabels(!openMoreLabels);
                    setOpenMoreLabelsIndex(index);
                  }}
                >
                  <div
                    id={"label-cell-selected-no-" + index}
                    className="pt-3 text-xs flex flex-wrap justify-center"
                  >
                    {item?.briefs &&
                      item?.briefs?.length !== 0 &&
                      item?.briefs?.map((elem, innerIndex) =>
                        innerIndex < 3 ? (
                          <span
                            key={innerIndex}
                            className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
                            style={{
                              gap: "8px",
                              padding: "4px 8px",
                              backgroundColor: "#F3F3F3",
                              borderRadius: "5px",
                            }}
                          >
                            {getRowLabels(item?.briefs)[innerIndex].label}
                          </span>
                        ) : null
                      )}
                    {item?.briefs?.length > 3 && (
                      <span
                        className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
                        style={{
                          gap: "8px",
                          padding: "4px 8px",
                          backgroundColor: "#F3F3F3",
                          borderRadius: "5px",
                        }}
                      >
                        +{item?.briefs?.length - 3}
                      </span>
                    )}
                  </div>
                </button>

                {openMoreLabels && openMoreLabelsIndex === index && (
                  // Move the modal outside of the button, but still inside the parent div
                  <div
                    key={index}
                    className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50"
                  >
                    <div
                      className="w-52 h-auto p-3 flex flex-col gap-1 rounded-md overflow-y-auto bg-white z-50"
                      style={{
                        boxShadow:
                          "0px 3px 38px 0px rgba(0,0,0,0.15), 0px 6px 12px 0px rgba(0,0,0,0.18)",
                        maxHeight: "12rem",
                      }}
                    >
                      <p className="px-1 py-1 text-xs text-gray-400 font-semibold border-b border-gray-500">
                        Labels
                      </p>
                      {item?.briefs &&
                        item?.briefs?.length !== 0 &&
                        item?.briefs?.map((elem, innerIndex) => (
                          <span
                            key={innerIndex}
                            className="text-s w-full py-1 border-b border-dashed border-gray-300 truncate"
                          >
                            {getRowLabels(item?.briefs)[innerIndex].label}
                          </span>
                        ))}
                      {/* Add a close button or any other UI elements as needed */}
                      <button
                        onClick={() => {
                          setOpenMoreLabels(false);
                          setOpenMoreLabelsIndex(null);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileTableInfo;
