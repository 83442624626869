/* External Components/Packages */
import React, { useEffect, useRef, useState } from "react";
import { API, Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import ProgressBar from "@ramonak/react-progress-bar";
import { CellMeasurerCache } from "react-virtualized";

/* Shared Components */
import { AppRoutes } from "../../constants/AppRoutes";
import AccessControl from "../../shared/accessControl";
import { dateTimeNoSecondsFormatter } from "../../shared/userPreferredDateTime";
import isMSdoc from "../../shared/msFileTypeChecker";
import {
  PulsingBorderDiv,
  PulsingBorderDivRelative,
} from "../../shared/PulsingBorderDiv";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import {
  setDefaultColumnBriefs,
  createContractBackground,
  createDefaultBriefs,
} from "../../shared/HBA-Functions.jsx";
import Button from "../../shared/Button";
import { dateFormatter } from "../../shared/userPreferredDateTime";
import ToastNotification from "../toast-notification";
import RightPanel from "./right-panel";
import ProgressPhotos from "./progress-photos.jsx";
import ProgressOfWork from "./progress-of-work.jsx";
import Invoices from "./invoices.jsx";
import Contracts from "./contracts.jsx";
import Variations from "./variations.jsx";
import ProvisionalSum from "./provisional-sum.jsx";
import PrimeCost from "./prime-cost.jsx";
import Budget from "./budget.jsx";
import ProjectLocation from "./project-location";
import GetStartedTile from "./get-started-tile.jsx";
import SiteDiary from "./sitediary.jsx";
import DynamicTiles from "./dynamic-tiles.jsx";
import TaskList from "./tasklist.jsx";
import EmailInteg from "./email-integration-tile.jsx";
import { DefaultPhases } from "../../constants/Phases";

/* Assets & Icons */
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import * as IoIcons from "react-icons/io5";
import * as FaIcons from "react-icons/fa";
import * as LiaIcons from "react-icons/lia";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { BiArrowToTop } from "react-icons/bi";
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Mobile.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/styles/BoxPulse.css";
import contractsIcon from "../../assets/images/contracts-icon-blue.png";
import { getBriefListPerPersona } from "../../constants/PersonaTiles.jsx";
import { hbaDomains } from "../../constants/AppDomains.jsx";

export default function HBADashboard() {
  let history = useHistory();
  const propertyPhotoRef = useRef(null);
  const propertyDetailsRef = useRef(null);
  const completionDateRef = useRef(null);
  const invoicesRef = useRef(null);
  const variationsRef = useRef(null);
  const provisionalSumRef = useRef(null);
  const primeCostRef = useRef(null);
  const budgetRef = useRef(null);
  const uploadContractsRef = useRef(null);
  const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");
  const [userInfo, setuserInfo] = useState(null);
  const [defaultList, setDefaultList] = useState(null);
  var moment = require("moment");
  const [background, setBackground] = useState([]);
  const [showBackgrounds, setShowBackgrounds] = useState(false);
  const [showBriefId, setShowBriefId] = useState(null);
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);

  const [hideChecklist, setHideChecklist] = useState(true);
  var clientMatterId = "";
  const [matterId, setMatterId] = useState("");
  const [matter_id, setMatter_Id] = useState("");
  const [contractBrief, setContractBrief] = useState({});
  const [progressPhotoBrief, setProgressPhotoBrief] = useState({});
  const [projectLocationBrief, setProjectLocationBrief] = useState(null);
  const [projectLocationPhoto, setProjectLocationPhoto] = useState(null);
  // const [briefs, setBriefs] = useState(null);
  // const [BriefsCopy, setBriefsCopy] = useState();
  // const [numCompletedActions, setNumCompletedActions] = useState(0);
  const [buildInfo, setBuildInfo] = useState(null);
  const [contractInfo, setContractInfo] = useState(null);
  const [contractTC, setContractTC] = useState(null);
  const [archiDrawings, setArchiDrawings] = useState(null);
  const [engDrawings, setEngDrawings] = useState(null);
  const [specifications, setSpecifications] = useState(null);
  const [buildInsurance, setBuildInsurance] = useState(null);
  const [tilePulse, setTilePlulse] = useState(null);

  // Get Started States
  const [getStartedProgress, setGetStartedProgress] = useState({
    builderDetails: false,
    contractDetails: false,
    propertyPhoto: false,
    propertyDetails: false,
    completionDate: false,
    invoices: false,
    variations: false,
    contracts: false,
  });

  // useEffect(() => {
  //   // console.log"Get Started Progress", getStartedProgress);
  // }, [getStartedProgress]);

  // File Upload States
  const [showUploadModal, setShowUploadModal] = useState(null);
  // const [dropTrigger, setDropTrigger] = useState([]);
  // const [directDrop, setDirectDrop] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [isToastError, setIsToastError] = useState(false);
  const [SelectedIndex, setSelectedIndex] = useState(0);
  const [fileTypeCollection, setFileTypeCollection] = useState([]);
  const [logoURL, setLogoURL] = useState(null);
  const [builderLogo, setBuilderLogo] = useState(null);

  //Progress Photos States
  // const [selectedFiles, _setSelectedFiles] = useState([]);
  const selectedFiles = useRef([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [datePicked, setDatePicked] = useState(new Date());
  const [description, setDescription] = useState("");
  const [progressPhotosDetails, setProgressPhotosDetails] = useState([]);
  const [progressPhotoBriefID, setProgressPhotoBriefID] = useState(null);
  const [photos, setPhotos] = useState([]);

  const [isInitDone, setIsInitDone] = useState(false);
  const [dynamicTileRows, setDynamicTileRows] = useState([]);

  const [phases, setPhases] = useState([]);
  const [activePhase, setActivePhase] = useState();
  const [visibleBriefsList, setVisibleBriefsList] = useState([]);

  const defaultColumnIds = [0, 1, 2, 3, 4];

  const fileList = [
    "Contract Terms And Conditions",
    "Architectural Drawings",
    "Engineer Drawings",
    "Specifications",
    "Builder's Insurance",
  ];

  //Tasklist data
  const [taskListData, settTaskListData] = useState(null);

  //FORCE REDIRECTION TO HBA DASHBOARD
  // const [redirectDashboard, setRedirectDashboard] = useState(false);

  // RIGHT MODAL PROPERTIES
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [modalState, setModalState] = useState(null);
  const modalChoicesEnum = {
    BUILDER_DETAILS: "BUILDER_DETAILS",
    CONTRACT_DETAILS: "CONTRACT_DETAILS",
    PROGRESS_PHOTO: "PROGRESS_PHOTO",
  };

  const [isInitializingPortal, setIsInitializingPortal] = useState(true);

  var domain = window.location.hostname;

  const [showInbox, setShowInbox] = useState(false);
  const [showTasklist, setShowTasklist] = useState(false);
  const [matter_name, setMatterName] = useState(null);
  const [client_name, setClientName] = useState(null);

  useEffect(() => {
    featureAccessFilters();
  }, []);

  useEffect(() => {
    var status = localStorage.getItem("onboardingProgress");

    let buildersIdx;
    let contractsIdx;

    defaultList?.forEach((item, x) => {
      if (item.name === "Builder Details") {
        buildersIdx = x;
      } else if (item.name === "Contract Details") {
        contractsIdx = x;
      }
    });

    if (isInitDone == true && defaultList) {
      var contractDetailsOnboarding;
      var builderDetailsOnboarding;

      if (defaultList[contractsIdx]) {
        contractDetailsOnboarding =
          defaultList[contractsIdx].backgrounds?.items.length == 0
            ? false
            : true;
      } else {
        contractDetailsOnboarding = true;
      }

      if (defaultList[buildersIdx]) {
        builderDetailsOnboarding =
          defaultList[buildersIdx].backgrounds?.items.length == 0
            ? false
            : true;
      } else {
        builderDetailsOnboarding = true;
      }
    }

    // console.logbuildersIdx, builderDetailsOnboarding);
    // console.logcontractsIdx, contractDetailsOnboarding);

    //uncomment for redirection -KJMF
    // if (
    //   status == "false" ||
    //   contractDetailsOnboarding == false ||
    //   builderDetailsOnboarding == false
    // ) {
    //   // console.log"REDIRECT TO GETTING STARTED");
    //   history.push(AppRoutes.GETTINGSTARTED);
    // }
  }, [isInitDone]);

  useEffect(() => {
    //force reload to rerender localStorage
    if (
      localStorage.getItem("onboardingProgress") == "true" &&
      localStorage.getItem("userProgress") == "true"
    ) {
      localStorage.setItem("userProgress", "false");
      window.location.reload();
    }
  }, []);

  // useEffect(() => {
  //   // console.logdatePicked);
  // }, [datePicked]);

  useEffect(() => {
    if (defaultList) {
      getPhasesLibrary();
    }
  }, [defaultList]);

  // useEffect(() => {
  //   if (activePhase) {
  //     updateBriefsListByPhase();
  //   }
  // }, [activePhase]);

  const resetPhases = () => {
    setActivePhase("");

    const currUrl = window.location.hostname;
    const activeBriefs = Array.from(
      new Set(defaultList?.map((item) => item.name))
    );
    activeBriefs.push("Builder Details");
    activeBriefs.push("Contract Details");

    //MMA 3144
    // HBA Domains, Automatically set to HOMEOWNERBUILDER for old portal that dont have userCategory in localStorage
    if (hbaDomains.includes(currUrl)) {
      let filteredBriefsPerPersona;
      filteredBriefsPerPersona = getBriefListPerPersona(activeBriefs);
      setVisibleBriefsList(filteredBriefsPerPersona);
    } else {
      setVisibleBriefsList(activeBriefs);
    }
  };
  async function getPhasesLibrary() {
    setPhases(DefaultPhases);
    resetPhases();
  }

  const updateBriefsListByPhase = () => {
    const activePhaseObj = phases.filter((x) => x.label === activePhase);
    const defaultBriefsForPhase = activePhaseObj[0].defaultBriefs;

    const activeBriefs = defaultList
      .filter((x) => defaultBriefsForPhase.includes(x.name))
      .map((item) => item.name);

    activeBriefs.push("Builder Details");
    activeBriefs.push("Contract Details");
    setVisibleBriefsList(activeBriefs);

    setShowInbox(defaultBriefsForPhase.includes("Email Integration"));
    setShowTasklist(defaultBriefsForPhase.includes("Task List"));
  };
  // useEffect(() => {
  //   if (redirectDashboard === false) {
  //     if (hbaDomains.includes(domain)) {
  //       // console.log"Valid access"); //HBA
  //       setRedirectDashboard(true);
  //     } else if (obaDomains.includes(domain)) {
  //       history.push(AppRoutes.OBADASHBOARD); //HBA
  //     } else {
  //       history.push(AppRoutes.DASHBOARD); //MMA
  //     }
  //   }
  // }, [redirectDashboard]);

  function countProgress(obj) {
    if (!visibleBriefsList.includes("Builder Details"))
      delete obj.builderDetails;

    if (!visibleBriefsList.includes("Contract Details"))
      delete obj.contractDetails;

    if (!visibleBriefsList.includes("Project Location"))
      delete obj.propertyDetails;

    if (!visibleBriefsList.includes("Contracts")) delete obj.contracts;

    if (!visibleBriefsList.includes("Progress of work"))
      delete obj.completionDate;

    if (!visibleBriefsList.includes("Progress Photos"))
      delete obj.propertyPhoto;

    if (!visibleBriefsList.includes("Invoices")) delete obj.invoices;

    if (!visibleBriefsList.includes("Variations")) delete obj.variations;

    const progressCount = Object.keys(obj).reduce(
      (count, key) => (obj[key] === true ? count + 1 : count),
      0
    );
    // console.log"Progress Bar", progressCount);
    const totalProgress = Object.keys(obj).length;
    // console.log"Total Progress", totalProgress);
    const percentage =
      totalProgress > 0 ? (progressCount / totalProgress) * 100 : 0;

    return percentage.toFixed(2);
  }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const backPage = getParameterByName("back_page");

  //QUERIES
  const qGetBackgroundFilesbyBriefID = `query getBackgroundFilesByBriefID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
    brief(id: $id) {
      id
      name
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          description
          date
          createdAt
          order
          files {
            items {
              id
              name
              details
              isGmailPDF
              isGmailAttachment
              s3ObjectKey
              order
              createdAt
              downloadURL
            }
          }
        }
        nextToken
      }
    }
  }`;

  const TASKLIST_DATA_QUERY = `query getTaskLists($companyId: ID!, $isDeleted: Boolean, $status: String, $dateSubmittedStart: String, $dateSubmittedEnd: String, $assignee: [String], $priority: Int, $limit: Int) {
    taskListData(
      companyId: $companyId
      assignee: $assignee
      dateSubmittedEnd: $dateSubmittedEnd
      dateSubmittedStart: $dateSubmittedStart
      isDeleted: $isDeleted
      status: $status
      priority: $priority
      limit: $limit
    ) {
      items {
        id
        data
        uniqueId
        clientMatterId
        gmailMessageId
        createdAt
        isDeleted
        updatedAt
      }
    }
  }`;

  const listClientMatters = `query listClientMatters($companyId: String) {
    company(id: $companyId) {
      clientMatters(sortOrder: CREATED_DESC, isDeleted: false) {
        items {
          id
          createdAt
          updatedAt
          lastOpenedAt
          description
          matterNumber
          client {
            id
            name
          }
          matter {
            id
            name
          }
        }
      }
    }
  }`;

  const listClient = `query listClient($companyId: String) {
    company(id: $companyId) {
      clients {
        items {
          id
          name
        }
      }
    }
  }`;

  const listMatter = `query listMatter($companyId: String) {
    company(id: $companyId) {
      matters {
        items {
          id
          name
        }
      }
    }
  }`;

  const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
    clientMatter(id: $id) {
      briefs {
        items {
          id
          name
          userTypeAccess
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          backgrounds(limit: 50, sortOrder: ORDER_ASC) {
            items {
              id
              description
              date
              dynamicData
              files {
                items {
                  id
                  name
                }
              }
            }
          }
          hasCommentAccess
          columnIds
          assistantId
        }
      }
    }
  }`;

  const FILE_DOWNLOAD_LINK_QUERY = `query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const BACKGROUND_BY_BRIEF_ID_QUERY = `query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
    brief(id: $id) {
      id
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          description
          comments
          date
          createdAt
          order
          dynamicData
          files {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
    }
  }`;

  const qlistBackgroundFiles = `query getBackgroundByID($id: ID) {
    background(id: $id) {
      id
      files {
        items {
          createdAt
          id
          details
          name
          order
        }
      }
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

  const BACKGROUND_TABLE_QUERY = `query getBackgroundTable($clientMatterId: ID!) {
    backgroundTable(clientMatterId: $clientMatterId) {
      id
      columns {
        id
        accessorKey
        headerText
        enabled
        type
        optionsText
        order
        presets {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }`;

  const qGetFilesByMatter = `query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
    matterFiles(
      isDeleted: $isDeleted
      matterId: $matterId
      nextToken: $nextToken
      limit: $limit
      sortOrder: $sortOrder
    ) {
      items {
        id
        name
        type
      }
    }
  }`;

  //MUTATIONS
  const mUpdateBackgroundDesc = `mutation updateBackground($id: ID, $description: String) {
    backgroundUpdate(id: $id, description: $description) {
      id
      description
    }
  }`;

  const mUpdateBackgroundDate = `mutation updateBackground($id: ID, $date: AWSDateTime) {
    backgroundUpdate(id: $id, date: $date) {
      id
      date
    }
  }`;

  const addClientq = `mutation addClient($companyId: String, $name: String) {
    clientCreate(companyId: $companyId, name: $name) {
      id
      name
    }
  }`;

  const addMatterq = `mutation addMatter($companyId: String, $name: String) {
    matterCreate(companyId: $companyId, name: $name) {
      id
      name
    }
  }`;

  const createClientMatterq = `mutation createClientMatter($companyId: String, $userId: ID, $client: ClientInput, $matter: MatterInput) {
    clientMatterCreate(
      companyId: $companyId
      userId: $userId
      client: $client
      matter: $matter
    ) {
      id
    }
  }`;

  const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int], $assistantId: String) {
    briefCreate(
      clientMatterId: $clientMatterId
      date: $date
      name: $name
      order: $order
      columnIds: $columnIds
      assistantId: $assistantId
    ) {
      id
      name
      date
      createdAt
      assistantId
      order
      columnIds
    }
  }`;

  const mCreateActivity = `mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

  const mBulkCreateMatterFile = `mutation bulkCreateMatterFile($files: [MatterFileInput]) {
    matterFileBulkCreate(files: $files) {
      id
      name
      order
    }
  }`;

  const BACKGROUNDFILE_TAG_MUTATION = `mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileTag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }`;

  const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
    backgroundCreate(
      description: $description
      comments: $comments
      date: $date
      order: $order
      briefs: $briefs
    ) {
      id
      createdAt
      date
      description
      order
      briefs {
        id
        name
      }
    }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }`;

  const BACKGROUND_TABLE_CREATE = `mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
    backgroundTableCreate(
      clientMatterId: $clientMatterId
      initialPresets: $initialPresets
    ) {
      id
      columns {
        id
        accessorKey
        headerText
        enabled
        type
        optionsText
        order
      }
      createdAt
      updatedAt
    }
  }`;

  const mAddUserAcces = `mutation tagBriefUserTypeAccess($briefId: String, $userType: [UserType]) {
    briefAccessTypeTag(briefId: $briefId, userType: $userType) {
      id
    }
  }`;

  const UPDATE_BRIEF_PRESET_MUTATION = `mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    briefUpdate(id: $id, columnIds: $columnIds) {
      id
    }
  }`;

  const featureAccessFilters = async () => {
    const [inboxAccess, tasklistAccess, briefsAccess] = await Promise.all([
      AccessControl("INBOX"),
      AccessControl("TASKS"),
      AccessControl("BRIEFS"),
    ]);

    // Dashboard Tile Displays
    setShowInbox(inboxAccess.status !== "restrict");
    setShowTasklist(tasklistAccess.status !== "restrict");

    // if (briefsAccess.status !== "restrict") {
    //   const briefsFeatures = new Set(briefsAccess.data?.features || []);
    // }
  };

  useEffect(() => {
    // console.log"onboarding", localStorage.getItem("onboardingProgress"));
    if (taskListData === null) {
      getTasks();
    }
  }, [taskListData]);

  const getTasks = async () => {
    var fname = localStorage.getItem("firstName");
    var lname = localStorage.getItem("lastName");
    var userId = localStorage.getItem("userId");
    var userHistory = JSON.parse(localStorage.getItem("history"));

    //// console.log"TEST NAME", `${fname} ${lname}`);
    let assignees = [`${fname} ${lname}`, userId];

    if (userHistory) {
      for (let pastUser of userHistory) {
        assignees.push(`${pastUser.firstName + " " + pastUser.lastName}`);
      }
    }

    //get 2 latest only
    let vars = {
      companyId: companyId,
      isDeleted: false,
      assignee: assignees,
      dateSubmittedEnd: "",
      dateSubmittedStart: "",
      status: "",
      limit: 2,
    };

    const { data } = await API.graphql({
      query: TASKLIST_DATA_QUERY,
      variables: vars,
    }).catch((e) => {
      console.error(e.errors[0].message);
    });

    const dataTasks = data.taskListData.items;

    dataTasks.map((x) => {
      x.data = JSON.parse(x.data);
    });

    settTaskListData(dataTasks);

    // console.log"Tasklist", dataTasks);
  };

  const [showPanel, setShowPanel] = useState(true);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  //FUNCTIONS
  useEffect(() => {
    if (userInfo === null) {
      let ls = {
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        company: localStorage.getItem("company"),
        userType: localStorage.getItem("userType"),
        // access: JSON.parse(localStorage.getItem("access")),
      };
      setuserInfo(ls);
      // console.log"userInfo", ls);
    }
  }, [userInfo]);

  useEffect(async () => {
    // console.log"DEFAULT LIST IS:", defaultList);
    // if (defaultList === null) {
    //Get MatterList
    let result = [];
    const clientMattersOpt = await API.graphql({
      query: listClientMatters,
      variables: {
        companyId: companyId,
      },
    });
    // console.log"CLIENT MATTER IS:", clientMattersOpt);
    if (clientMattersOpt.data.company.clientMatters.items !== null) {
      result = clientMattersOpt.data.company.clientMatters.items;
      // console.log"Matterlist", result);
      // console.log"DATA IS:", clientMattersOpt);

      //Check if default client matter is existing
      var createClientMatter = true;
      result.map((x) => {
        if (x.client && x.client.name === "Default") {
          createClientMatter = false; //Do not create new
          clientMatterId = x.id; //client matter id
          setMatterId(x.matter.id);
          setMatterName(x.matter.name);
          setClientName(x.client.name);
          getBriefs(x.matter.id);
        }
      });
      setMatter_Id(clientMatterId);

      if (createClientMatter === true) {
        // console.log"CREATE CLIENT MATTER TRUE");
        //create a new client matter (new login/first time) !!MIGHT TAKE TIME!!

        //==========CHECK FOR DUPLICATE CLIENT/MATTER==========//
        //check or create matter named "Default"
        var createClient = true;
        var defaultClient = [];

        const clientsOpt = await API.graphql({
          query: listClient,
          variables: {
            companyId: companyId,
          },
        });

        // console.log"Client list", clientsOpt.data.company.clients.items);

        //check if Default client exists
        clientsOpt?.data?.company?.clients?.items.map((x) => {
          if (x.name === "Default") {
            defaultClient = [{ id: x.id, name: x.name }]; //use this existing client
            createClient = false;
          }
        });

        //Create new client if Default does not exist
        if (createClient === true) {
          const addClient = await API.graphql({
            query: addClientq,
            variables: {
              companyId: companyId,
              name: "Default",
            },
          }).then((result) => {
            defaultClient = [...defaultClient, result?.data?.clientCreate]; //use this new client
            // console.log"MATTERRRR ID", defaultClient);
          });
        }

        //check or create matter named "Default"
        var createMatter = true;
        var defaultMatter = [];

        const mattersOpt = await API.graphql({
          query: listMatter,
          variables: {
            companyId: companyId,
          },
        });

        // console.log"Matter list", clientsOpt.data.company.clients.items);

        //check if Default matter exists
        mattersOpt?.data?.company?.matters?.items.map((x) => {
          if (x.name === "Default") {
            defaultMatter = [{ id: x.id, name: x.name }]; //use this existing matter
            createMatter = false;
            // console.log"MATTERRRR ID 22", defaultMatter);
          }
        });

        //Create new matter if Default does not exist
        if (createMatter === true) {
          const addMatter = await API.graphql({
            query: addMatterq,
            variables: {
              companyId: companyId,
              name: "Default",
            },
          }).then((result) => {
            defaultMatter = [...defaultMatter, result?.data?.matterCreate];
            // console.log"MATTERRRR ID", defaultMatter);
          }); //use this new matter
        }

        setMatterId(defaultMatter[0]?.id);

        // console.log"client in use", defaultClient);
        // console.log"matter in use", defaultMatter);

        //==========CREATE NEW CLIENT MATTER PROPER==========//

        await Promise.all([
          API.graphql({
            query: createClientMatterq,
            variables: {
              companyId: companyId,
              userId: userId,
              client: defaultClient[0],
              matter: defaultMatter[0],
            },
          }),
          API.graphql({
            query: listClientMatters,
            variables: {
              companyId: companyId,
            },
          }),
        ]).then(async (res) => {
          // console.log"///", res);
          const newClientMatter = res[0].data.clientMatterCreate;
          clientMatterId = newClientMatter?.id;
          //==========CREATE DEFAULT BACKGROUNDS==========//
          //BACKGROUNDS: VARIATIONS, PROGRESS CLAIMS, PROGRESS PHOTOS, DELAY CLAIMS, CONTRACTS, PROJECT LOCATION, SITE DIARY

          let currentDate = moment
            .utc(moment(new Date(), "YYYY-MM-DD"))
            .toISOString();

          let {
            // Builder_Details,
            // Contract_Details,
            // Variations,
            // Provisional_Sum,
            // Budget,
            // Invoices,
            // Progress_Photos,
            // Progress_of_work,
            // Project_Location,
            // Site_Diary,
            // Prime_Cost,
            // Tenders,
            // Certificates,
            // Insurance,
            // Defects_List,
            // Development_Approvals,
            // Building_Permits,
            // Warranties_and_Certifications,
            // Work_Safety_Compliance,
            Contracts,
          } = await createDefaultBriefs(clientMatterId, currentDate);

          // Create Contract Backgrounds
          await createContractBackground(Contracts);

          const params = {
            query: BRIEFS_QUERY,
            variables: {
              id: clientMatterId,
            },
          };

          await API.graphql(params).then(async (brief) => {
            const briefsList = brief?.data?.clientMatter?.briefs?.items;
            setDefaultList(briefsList);
            await setDefaultBriefColumnIds(
              sortByOrder(briefsList),
              clientMatterId
            );
            window.location.reload();
          });
        });
      } else {
        setIsInitializingPortal(true);
        // console.log"CREATE CLIENT MATTER FALSE");
        //JUST DISPLAY BACKGROUNDS
        // console.log"will enter here", clientMatterId);
        const params = {
          query: BRIEFS_QUERY,
          variables: {
            id: clientMatterId,
          },
        };

        await API.graphql(params).then(async (brief) => {
          var briefsList = brief?.data?.clientMatter?.briefs?.items;
          // console.log"briefsList from up", briefsList);
          let currentDate = moment
            .utc(moment(new Date(), "YYYY-MM-DD"))
            .toISOString();

          let {
            Builder_Details,
            Contract_Details,
            Variations,
            Provisional_Sum,
            Budget,
            Invoices,
            Progress_Photos,
            Progress_of_work,
            Project_Location,
            Site_Diary,
            Prime_Cost,
            Tenders,
            Certificates,
            Insurance,
            Defects_List,
            Development_Approvals,
            Building_Permits,
            Warranties_and_Certifications,
            Work_Safety_Compliance,
            Contracts,
          } = await createDefaultBriefs(
            clientMatterId,
            currentDate,
            briefsList
          );

          if (Contracts) {
            await createContractBackground(Contracts);
          }

          if (
            Builder_Details ||
            Contract_Details ||
            Variations ||
            Provisional_Sum ||
            Budget ||
            Invoices ||
            Progress_Photos ||
            Progress_of_work ||
            Project_Location ||
            Site_Diary ||
            Prime_Cost ||
            Tenders ||
            Certificates ||
            Insurance ||
            Defects_List ||
            Development_Approvals ||
            Building_Permits ||
            Warranties_and_Certifications ||
            Work_Safety_Compliance ||
            Contracts
          ) {
            window.location.reload();
          }

          setDefaultList(briefsList);

          const contractsIndex = briefsList.findIndex(
            (item) => item.name === "Contracts"
          );
          setContractBrief(briefsList[contractsIndex]);

          const progressPhotoIndex = briefsList.findIndex(
            (item) => item.name === "Progress Photos"
          );

          if (progressPhotoIndex !== -1) {
            setProgressPhotoBrief(briefsList[progressPhotoIndex]);

            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[progressPhotoIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let retrievedRow = response.data.brief.backgrounds?.items;

            // Filter the retrievedRow array to only contain rows with images
            if (retrievedRow.length > 0) {
              const filteredItems = retrievedRow
                .filter((item) => {
                  // Check if at least one file in the files array is an image
                  return item.files.items.some((file) =>
                    isImageFile(file.s3ObjectKey)
                  );
                })
                .sort((a, b) => a.order - b.order);

              setPhotos(filteredItems);
            }
          }

          const projectLocationIndex = briefsList.findIndex(
            (item) => item.name === "Project Location"
          );

          if (projectLocationIndex !== -1) {
            setProjectLocationBrief(briefsList[projectLocationIndex]);
            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[projectLocationIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;

            if (brief.brief.backgrounds?.items?.length > 0) {
              setProjectLocationPhoto(brief.brief.backgrounds?.items[0]);
            }
          }

          await setDefaultBriefColumnIds(
            sortByOrder(briefsList),
            clientMatterId
          );
          // console.log"Briefs List: " + briefsList);
        });
        //end
        setIsInitDone(true);
        setIsInitializingPortal(false);
      }
      // }
    }
  }, []);

  useEffect(() => {
    if (isInitDone) window.location.reload();
  }, [companyId]);
  //Get Started Functions
  const scrollToSection = (ref, id) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
    if (id && document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChangeGetStartedProgress = (getStartedProgressKey) => {
    if (
      getStartedProgress[getStartedProgressKey] !== undefined &&
      getStartedProgress[getStartedProgressKey] === false
    ) {
      const newProgressObject = {
        ...getStartedProgress,
        [getStartedProgressKey]: true,
      };
      // console.log"newProgressObject", newProgressObject);
      setGetStartedProgress(newProgressObject);
      //localStorage.setItem('getStartedCompletion', JSON.stringify(newProgressObject))
    }
  };

  //Check if file is image file
  const isImageFile = (fileName) => {
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".webp",
      ".svg",
      ".avif",
      ".apng",
    ];
    const extension = fileName
      .toLowerCase()
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    return imageExtensions.includes("." + extension);
  };

  const previewAndDownloadFile = async (id) => {
    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  const previewOnlyFile = (id) => {
    // console.log"preview and download file: ", id);
    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    return API.graphql(params).then((result) => {
      return result.data.file; // Assuming the data structure returned has a "file" property
    });
  };

  const sortByOrder = (arr) => {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }
    return sort;
  };

  const getBackgrounds = async (id, state) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });

    if (backgroundOpt.data.brief.backgrounds?.items !== null) {
      var result = backgroundOpt.data.brief.backgrounds?.items.map(
        ({ id, description, date, createdAt, order, files, dynamicData }) => ({
          createdAt: createdAt,
          id: id,
          description: description,
          date: date,
          order: order,
          files: files,
          dynamicData: dynamicData,
        })
      );

      setBackground(sortByOrder(result));
      setShowBackgrounds(state);
      setShowBriefId(id);
    }
  };

  let getMatterFiles = async () => {
    const params = {
      query: qGetFilesByMatter,
      variables: {
        matterId: matterId,
        isDeleted: false,
        nextToken: null,
        sortOrder: "DATE_ASC",
      },
    };
    await API.graphql(params).then((files) => {
      let matterFilesList = files?.data?.matterFiles?.items;
      setFileTypeCollection(matterFilesList);
    });
  };

  useEffect(() => {
    getMatterFiles();
  }, [matterId]);

  async function bulkCreateMatterFile(param, bgId) {
    console.log("bulkCreateMatterFile", bgId, param);
    let idTag = [];

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    console.log("result", request);

    if (request.data.matterFileBulkCreate !== null) {
      request.data.matterFileBulkCreate.map((i) => {
        return (idTag = [...idTag, { id: i.id }]);
      });
    }

    console.log("iDTag", idTag);

    // let arrFiles = [];
    // let arrFileResult = [];
    // const seen = new Set();

    // // console.log("MID/BID", background_id);

    // const backgroundFilesOpt = await API.graphql({
    //   query: qlistBackgroundFiles,
    //   variables: {
    //     id: bgId,
    //   },
    // });

    // console.log("CHECKDATA", backgroundFilesOpt);

    // if (backgroundFilesOpt.data.background.files !== null) {
    //   arrFileResult = backgroundFilesOpt.data.background.files.items.map(
    //     ({ id }) => ({
    //       id: id,
    //     })
    //   );

    //   idTag.push(...arrFileResult);
    //   console.log("updatedidtag", idTag);

    //   const filteredArr = idTag.filter((el) => {
    //     const duplicate = seen.has(el.id);
    //     seen.add(el.id);
    //     return !duplicate;
    //   });

    //   console.log("rowid", bgId);

    //   API.graphql({
    //     query: BACKGROUNDFILE_TAG_MUTATION,
    //     variables: {
    //       backgroundId: bgId,
    //       files: filteredArr,
    //     },
    //   });
    // } else {
    await API.graphql({
      query: BACKGROUNDFILE_TAG_MUTATION,
      variables: {
        backgroundId: bgId,
        files: idTag,
      },
    });
    // }
    //return request;
  }

  const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; //list of rejected files

  useEffect(() => {
    var counter = 0;
    var dCounter = 0;
    if (uploadImage.length > 0 && counter === 0) {
      onSelectFile(uploadImage);
      counter = 1;
    }
  }, [uploadImage]);

  // useEffect(() => {
  //   console.log"Selected files are:" + JSON.stringify(selectedFiles[0]));
  //   console.log"Rejected files are:" + JSON.stringify(invalidFiles[0]));
  // }, [selectedFiles, invalidFiles]);
  const onSelectFile = (retrievedFiles) => {
    // console.log"You are here " + retrievedFiles);
    if (!retrievedFiles || retrievedFiles.length === 0) {
      return;
    }
    const tempArr = [];

    [...retrievedFiles].forEach((file) => {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];

      const result = rejectFiles.find((item) =>
        item.includes(re.exec(file.name)[0])
      );
      const fileSize = file.size;

      if (result || fileSize > 2147483648) {
        invalidFiles.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      } else {
        tempArr.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      }
    });

    selectedFiles.current = [...selectedFiles.current, ...tempArr];
  };

  // const myCurrentRef = useRef(selectedFiles);
  // const setSelectedFiles = (data) => {
  //   myCurrentRef.current = data;
  //   _setSelectedFiles(data);
  // };

  const handleUpload = async (bucketName) => {
    console.group("handleUpload");
    console.log("UPLOADING TO: ", bucketName);

    setUploadStart(true);
    if (!saveButtonClicked) {
      setSaveButtonClicked(true);
    }
    try {
      if (!selectedFiles.current || !Array.isArray(selectedFiles.current)) {
        console.error("Invalid or missing selectedFiles array");
        return;
      }

      var tempArr = [];
      var temp = [];
      var idxx = 0;

      for (let uf of selectedFiles.current) {
        if (isImageFile(uf.data.name)) {
          let bgId = await handleProgressPhotoUpload({
            datePicked,
            description,
          });
          tempArr = [...tempArr, { uf, bgId }];
        } else {
          alert(
            `File ${uf.data.name} is not an image. Only images can be uploaded.`
          );
          setImageURL(null); // Set image URL back to null
          setSaveButtonClicked(false);
          return;
        }
      }

      selectedFiles.current = tempArr.map((file) => file.uf);

      console.log("Uploading selectedFiles", selectedFiles.current);
      for (const file of tempArr) {
        const { uf, bgId } = file;
        if (!uf || !uf.data) {
          console.error("Invalid or missing data for file:", uf);
          continue; // Skip to the next iteration
        }

        if (uf.data.name.split(".").pop() == "docx") {
          var name = uf.data.name,
            size = uf.data.size,
            type =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            key = `${bucketName}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        } else {
          var name = uf.data.name,
            size = uf.data.size,
            type = uf.data.type,
            key = `${bucketName}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        }
        idxx = idxx + 1;

        try {
          await Storage.put(key, uf.data, {
            contentType: type,
            progressCallback(progress) {
              const progressInPercentage = Math.round(
                (progress.loaded / progress.total) * 100
              );

              if (temp.length > selectedFiles.current.length) {
                for (var i = 0; i < selectedFiles.current.length; i++) {
                  if (temp[i].name === uf.data.name) {
                    temp[i].prog = progressInPercentage;
                  }
                }
              } else {
                temp = [
                  ...temp,
                  { prog: progressInPercentage, name: uf.data.name },
                ];
              }
              console.log(temp);
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          })
            .then(async (fd) => {
              var fileData = {
                s3ObjectKey: fd.key,
                size: parseInt(size),
                type: type,
                name: name.split(".").slice(0, -1).join("."),
                oderSelected: orderSelected,
                order: orderSelected,
              };

              await handleUploadLink(
                {
                  files: [fileData],
                },
                progressPhotoBrief,
                bgId
              );
              // setUploadedFiles((prevState) => ({
              //   files: [...prevState.files, fileData],
              // }));

              selectedFiles.current = [];
            })
            .catch((err) => {
              console.error("Unexpected error while uploading", err);
            });
        } catch (e) {
          const response = {
            error: e.message,
            errorStack: e.stack,
            statusCode: 500,
          };
          console.error("Unexpected error while uploading", response);
        }
      }
    } catch (error) {
      console.error("Unexpected error in handleUpload", error);
    }

    setShowUploadModal(null);

    await triggerToast(
      `Photo has been uploaded! View it at Progress Photos Background`,
      false
    );

    if (uploadStart == false) {
      window.location.reload();
    }

    console.groupEnd("handleUpload");
  };

  useEffect(() => {
    // console.loguploadStart);
  }, [uploadStart]);

  // const uploadAndCheck = async () => {
  //   if (
  //     uploadedFiles.files.length === selectedFiles.length &&
  //     selectedFiles.length !== 0
  //   ) {
  //     const result = await handleUploadLink(uploadedFiles, progressPhotoBrief);
  //     // console.logresult);
  //     //setProgressPhotosDone(true);
  //     if (result === "done") {
  //       setShowRightPanel(false);
  //       setModalState(null);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (uploadStart == false) {
  //     uploadAndCheck();
  //   }
  // }, [selectedFiles, uploadedFiles, uploadStart]);

  const [logo, setLogo] = useState([]);
  const handleLogoDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const areAllFilesImages = Array.from(files).every((file) =>
        /\.(jpg|jpeg|png|gif)$/i.test(file.name)
      );

      if (areAllFilesImages) {
        setShowRightPanel(true);
        setModalState(modalChoicesEnum.BUILDER_DETAILS);
        // console.log"Images uploaded are " + files[0]);
        // setBuilderLogo(URL.createObjectURL(files[0]));
        setLogo(files);
        setLogoURL(URL.createObjectURL(files[0]));
      } else {
        alert("Please drop only image files (JPEG, PNG, GIF, etc.).");
      }
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const file = files[0];
      if (!isImageFile(file.name)) {
        alert(
          `File ${file.name} is not an image. Only images can be uploaded.`
        );
        setImageURL(null); // Set image URL back to null
        return;
      }

      setShowRightPanel(true);
      setModalState(modalChoicesEnum.PROGRESS_PHOTO);
      // console.log"Images uploaded are " + file.name);
      setImageURL(URL.createObjectURL(file));
      setUploadImage(files);
    }
  };

  const handleProgressPhotoUpload = async ({ datePicked, description }) => {
    let uploadToBrief = await API.graphql({
      query: mCreateBackground,
      variables: {
        briefs: [
          {
            id: progressPhotoBrief.id,
            name: progressPhotoBrief.name,
          },
        ],
        description: description !== null ? description : "",
        comments: "",
        date: datePicked,
        order: 1,
      },
    });
    // console.log"Uploaded to Brief is:", uploadToBrief);
    return uploadToBrief.data.backgroundCreate?.id;
  };
  //UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
  const handleUploadLink = async (uf, brief, bgId) => {
    console.log("handleUploadLink");
    await Promise.all(
      uf?.files.map(async (file) => {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: brief?.id,
            activity: `added a background file named ${file.name}-(${file.size} byte)`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: bgId,
          },
        };
        console.log("add activity params", params);
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });
      })
    );
    var uploadedFiles = uf.files.map((f) => ({
      ...f,
      matterId: matter_id,
    }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
    console.log("SF", sortedFiles);
    console.log("AO", addOrder);

    //insert in matter file list
    await bulkCreateMatterFile(addOrder, bgId);

    //set background content

    const backgroundFilesOptReq = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: bgId,
      },
    }).then((result) => {
      var newFilesResult = result.data.background.files.items.map(
        ({ createdAt, id, name, description, order }) => ({
          createdAt: createdAt,
          id: id,
          name: name,
          description: description,
          order: order,
        })
      );

      // Sort the newFilesResult array by createdAt in descending order
      newFilesResult?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      // console.log(newFilesResult);

      var updateArrFiles = background.map((obj) => {
        if (obj.id === bgId) {
          return { ...obj, files: { items: newFilesResult } };
        }
        return obj;
      });

      // Update order number based on uploaded file
      updateArrFiles.forEach((item) => {
        const orderFilesPerBackground = item?.files?.items?.map(
          ({ id }, index) => ({
            id: id,
            order: index + 1,
          })
        );

        // For FE purposes: Update existing background array with the updated order data
        const orderFilesPerBackgroundFE = item?.files?.items?.map(
          (
            {
              id,
              details,
              gmailMessage,
              gmailMessageId,
              isGmailAttachment,
              isGmailPDF,
              name,
              s3ObjectKey,
            },
            index
          ) => ({
            id: id,
            name: name,
            details: details,
            gmailMessage: gmailMessage,
            gmailMessageId: gmailMessageId,
            isGmailAttachment: isGmailAttachment,
            isGmailPDF: isGmailPDF,
            s3ObjectKey: s3ObjectKey,
            order: index + 1,
          })
        );

        if (
          orderFilesPerBackground !== undefined &&
          orderFilesPerBackground !== null
        ) {
          item.files.items = orderFilesPerBackgroundFE;
          // updateBackgroundFilesOrder(orderFilesPerBackground);
          console.log("Items", orderFilesPerBackground);
        }
      });

      console.log("Set New Background", updateArrFiles);
      setBackground(updateArrFiles);
    });

    // setGoToFileBucket(true);
  };

  async function triggerToast(message, isError) {
    setalertMessage(message);
    setShowToast(true);
    setIsToastError(isError);
    // setGoToFileBucket(true);

    await new Promise((resolve) => {
      setTimeout(() => {
        setShowToast(false);
        resolve();
      }, 1500);
    });
  }

  function handleHideChecklist() {
    if (
      Array.isArray(contractTC) &&
      Array.isArray(archiDrawings) &&
      Array.isArray(engDrawings) &&
      Array.isArray(specifications) &&
      Array.isArray(buildInsurance)
    ) {
      // console.log
      //   "CHECKING IS:",
      //   contractTC?.length > 0 &&
      //     archiDrawings?.length > 0 &&
      //     engDrawings?.length > 0 &&
      //     specifications?.length > 0 &&
      //     buildInsurance?.length > 0
      // );
      setHideChecklist(
        contractTC?.length > 0 &&
          archiDrawings?.length > 0 &&
          engDrawings?.length > 0 &&
          specifications?.length > 0 &&
          buildInsurance?.length > 0
      );
      setLoading(false);
    } else {
      setTimeout(() => {
        if (fileCounter.current < 5) {
          setLoading(false);
        }
      }, 1000);
      setHideChecklist(false);
    }
  }

  function handleUploadClick(index) {
    if (index == showUploadModal) {
      handleCloseUploadModal();
    } else {
      setShowUploadModal(index);
      setSelectedIndex(index);
      setSelectedRowId(contractBrief?.backgrounds?.items[index]?.id);
    }
  }

  function handleCloseUploadModal() {
    setShowUploadModal(null);
    setSelectedIndex(null);
    setSelectedRowId(null);
  }

  const fileCounter = useRef(0);
  useEffect(() => {
    if (contractBrief && Object.keys(contractBrief).length > 0) {
      if (
        contractBrief.backgrounds &&
        contractBrief.backgrounds?.hasOwnProperty("items")
      ) {
        fileList.forEach(async (file, index) => {
          if (contractBrief.backgrounds?.items[index]) {
            let backgroundFilesOpt = await API.graphql({
              query: qlistBackgroundFiles,
              variables: {
                id: contractBrief.backgrounds?.items[index].id,
              },
            });

            // let rowFiles = backgroundFilesOpt.data.background?.files?.items;
            // let tempFiles = fileTypeCollection.filter((fileCollection) => {
            // 	for (const rowFile of rowFiles) {
            // 		if (rowFile.id == fileCollection?.id) {
            // 			return true;
            // 		}
            // 	}
            // 	return false;
            // });

            let tempFiles = backgroundFilesOpt.data.background?.files?.items;
            // console.log"FILE IS:", file);
            // console.log"FILE LIST IS:", fileList[0]);
            // console.log"BACKGROUND FILE IS:", backgroundFilesOpt);
            // console.log"ROW FILES ARE:", rowFiles);
            // console.log"TEMP FILES ARE:", tempFiles);
            if (file == fileList[0]) {
              setContractTC(tempFiles);
            } else if (file == fileList[1]) {
              setArchiDrawings(tempFiles);
            } else if (file == fileList[2]) {
              setEngDrawings(tempFiles);
            } else if (file == fileList[3]) {
              setSpecifications(tempFiles);
            } else if (file == fileList[4]) {
              setBuildInsurance(tempFiles);
            }
            fileCounter.current++;
          }
        });
      }
    }
  }, [contractBrief]);

  useEffect(() => {
    handleHideChecklist();
  }, [contractTC, archiDrawings, engDrawings, specifications, buildInsurance]);

  useEffect(() => {
    // console.log"HELLO HERE:", !hideChecklist && !loading);
    // console.log"CHECKLIST HIDE ISL", hideChecklist);
    // console.log"LOADING IS:", loading);
  }, [hideChecklist, loading]);

  const handleActivity = (item) => {
    var str = "Created";

    const { createdAt, createdBy, updatedAt, updatedBy } = item;

    if (createdBy && createdBy.id !== "") {
      str += ` by ${createdBy.firstName} `;
    }
    if (createdAt) {
      str += ` on ${dateTimeNoSecondsFormatter(item.createdAt)} `;
    }

    if ((updatedBy && updatedBy.id !== "") || updatedAt) {
      str += ` / Updated`;
      if (updatedBy && updatedBy.id !== "") {
        str += ` by ${updatedBy.firstName} `;
      }
      if (updatedAt) {
        str += ` on ${dateTimeNoSecondsFormatter(item.updatedAt)} `;
      }
    }

    // return str?.replace(/\s\s/g, " ");
    return str.trim();
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  const handleAddUserAccess = async (e, dataId, dataInfo) => {
    // console.log"event", e);
    // console.log"dataInfo", dataInfo);
    var bId = dataId;
    var temp = [];

    e.map((item) => (temp = [...temp, item.label]));

    const params = {
      query: mAddUserAcces,
      variables: {
        briefId: bId,
        userType: temp,
      },
    };

    await API.graphql(params).then((ua) => {
      // console.log"uaa", ua);
    });
  };

  const defaultUserAccessValues = [
    {
      label: "LEGALADMIN",
      value: "LEGALADMIN",
    },
    {
      label: "LAWYER",
      value: "LAWYER",
    },
  ];

  // async function setDefaultColumnBriefs(backgroundTables, briefs) {
  //   console.group("setDefaultColumnBriefs");
  //   try {
  //     const { id, columns } = backgroundTables;

  //     let invoicePreset;
  //     let variationPreset;
  //     let builderDetailsPreset;
  //     let provisionalSumPreset;
  //     let contractDetailsPreset;
  //     let budgetPreset;
  //     let primeCostPreset;

  //     console.log("Current columns columns", columns);
  //     console.log("defaultColumnIds:", defaultColumnIds);

  //     if (id) {
  //       let presets = [];
  //       const commentsColPresets = [];

  //       for (const item of briefs) {
  //         console.log("ITEM IS:", item);
  //         const preset = { id: item.id, name: item.name };
  //         if (item.name == "Invoices") {
  //           invoicePreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Variations") {
  //           variationPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Provisional Sum") {
  //           provisionalSumPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Prime Cost") {
  //           primeCostPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Budget") {
  //           budgetPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Builder Details") {
  //           builderDetailsPreset = { id: item.id, name: item.name };
  //         }
  //         if (item.name == "Contract Details") {
  //           contractDetailsPreset = { id: item.id, name: item.name };
  //         }

  //         presets.push(preset);

  //         if (item.hasCommentAccess) {
  //           const commentPreset = { ...preset };
  //           commentsColPresets.push(commentPreset);
  //         }
  //       }
  //       console.log("presets", presets);
  //       let isDefaultPresent = false;
  //       let isLabelPresent = false;
  //       let isInvoicePresent = false;
  //       let isVariationPresent = false;
  //       let isProvisionalSumPresent = false;
  //       let isBuilderDetailsPresent = false;
  //       let isContractDetailsPresent = false;
  //       let isBudgetPresent = false;
  //       let isPrimeCostPresent = false;
  //       let breakDownPresetList;

  //       // Filters out null columns
  //       let filteredColumns = [];
  //       columns.map((obj) => {
  //         if (obj.headerText === null) return false;

  //         // If column is not duplicate
  //         if (!filteredColumns.some((e) => e.headerText === obj.headerText)) {
  //           // Remove null and undefined presets
  //           let filteredCol = {...obj}
  //           if (filteredCol?.presets) {
  //             filteredCol.presets = obj.presets.filter(
  //               (preset) => preset !== null && preset !== undefined
  //             );
  //           }

  //           filteredColumns.push(filteredCol);
  //         }
  //       });

  //       console.log("filteredColumns", filteredColumns)
  //       let defaultCols = [
  //         filteredColumns.find((col) => col.headerText === "Date"),
  //         filteredColumns.find((col) => col.headerText === "Gmail"),
  //         filteredColumns.find((col) => col.headerText === "Description of Background"),
  //         filteredColumns.find((col) => col.headerText === "Comments"),
  //         filteredColumns.find((col) => col.headerText === "Document"),
  //       ].filter((col) => col !== undefined && col !== null && col);

  //       // Sorts column with default column containing their default IDs.
  //       let sortedColumns = [
  //         ...defaultCols,
  //         ...filteredColumns.filter((col) =>
  //           !defaultCols.some((defaultCol) =>
  //               col.headerText === defaultCol.headerText)).sort((a, b) =>
  //                 a.order - b.order
  //               )
  //       ]

  //       console.log("sortedColumns", sortedColumns);
  //       let reorganizedColumns = sortedColumns.map((col, index) => ({
  //           ...col,
  //           accessorKey: index.toString(),
  //           id: index.toString(),
  //           order: index + 1,
  //         })
  //       )

  //       console.log("reorganizedColumns", reorganizedColumns)
  //       const defaultColumns = reorganizedColumns.map((obj) => {
  //         var tag;
  //         if (
  //           obj.headerText == "Date" ||
  //           obj.headerText == "Gmail" ||
  //           obj.headerText == "Description of Background" ||
  //           obj.headerText == "Comments" ||
  //           obj.headerText == "Document"
  //         ) {
  //           isDefaultPresent = true;
  //         }
  //         if (obj.headerText == "Label") {
  //           isLabelPresent = true;
  //         }
  //         if (
  //           obj.headerText == "Claim Status" ||
  //           obj.headerText == "Payment Status" ||
  //           obj.headerText == "Claim Amount ($)" ||
  //           obj.headerText == "Due Date for Claim"
  //         ) {
  //           tag = "Invoices";
  //           isInvoicePresent = true;
  //         }
  //         if (
  //           obj.headerText == "Variation Status" ||
  //           obj.headerText == "Variation Cost" ||
  //           obj.headerText == "Variation Cost ($)"
  //         ) {
  //           tag = "Variations";
  //           isVariationPresent = true;
  //         }
  //         if (
  //           obj.headerText == "Provisional Amount" ||
  //           obj.headerText == "Cost Amount" ||
  //           obj.headerText == "Description of Work" ||
  //           obj.headerText == "Paid to Date Amount" ||
  //           obj.headerText == "Action"
  //         ) {
  //           tag = "Provisional Sum";
  //           isProvisionalSumPresent = true;
  //         }
  //         if (
  //           obj.headerText == "Builder Name" ||
  //           obj.headerText == "Builder Logo" ||
  //           obj.headerText == "Builder Contact Name" ||
  //           obj.headerText == "Builder Contact Email" ||
  //           obj.headerText == "Builder Contact Number"
  //         ) {
  //           tag = "Builder Details";
  //           isBuilderDetailsPresent = true;
  //         }

  //         if (
  //           obj.headerText == "Start Date" ||
  //           obj.headerText == "Contract Price" ||
  //           obj.headerText == "Contract Value"
  //         ) {
  //           isContractDetailsPresent = true;
  //           tag = "Contract Details";
  //         }

  //         console.log("obj.presets", obj.preset);
  //         breakDownPresetList = presets.filter((preset) =>
  //           preset.name.includes("_")
  //         );
  //         console.log("Break Down Preset List:", breakDownPresetList);
  //         return {
  //           ...obj,
  //           presets:
  //             obj.headerText == "Label"
  //               ? presets?.length
  //                 ? presets.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       preset.name !== "Builder Details" &&
  //                       preset.name !== "Variations" &&
  //                       preset.name !== "Provisional Sum" &&
  //                       preset.name !== "Budget" &&
  //                       preset.name !== "Prime Cost" &&
  //                       !preset.name.includes("_")
  //                   )
  //                 : []
  //               : obj.headerText === "Cost Amount"
  //               ? [...obj.presets, ...breakDownPresetList]
  //               : obj.headerText == "Gmail" || obj.headerText === "Email Date"
  //               ? presets?.length
  //                 ? presets.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       preset.name !== "Builder Details" &&
  //                       !preset.name.includes("_")
  //                   )
  //                 : []
  //               : isVariationPresent ||
  //                 isInvoicePresent ||
  //                 isProvisionalSumPresent ||
  //                 isPrimeCostPresent
  //               ? obj.presets
  //               : isBuilderDetailsPresent || isContractDetailsPresent
  //               ? obj.presets.filter((preset) => preset.name === tag)
  //               : obj.headerText === "Description of Background" ||
  //                 obj.headerText === "Date"
  //               ? presets?.length
  //                 ? presets.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       preset.name !== "Builder Details"
  //                   )
  //                 : []
  //               : obj.headerText === "Document"
  //               ? presets?.length
  //                 ? presets?.filter(
  //                     (preset) =>
  //                       preset.name !== "Contract Details" &&
  //                       !preset.name.includes("_")
  //                   )
  //                 : []
  //               : presets,
  //         };
  //       });
  //       console.log("Default Columns:", defaultColumns);

  //       // console.log("INVOICE PRESET:", invoicePreset);

  //       let newColumns = [...defaultColumns];

  //       console.log("isInvoicePresent", isInvoicePresent);
  //       console.log("isVariationPresent", isVariationPresent);
  //       console.log("isBuilderDetailsPresent", isBuilderDetailsPresent);
  //       console.log("isContractDetailsPresent", isContractDetailsPresent);
  //       console.log("isProvisionalSumPresent", isProvisionalSumPresent);
  //       console.log("isBudgetPresent", isBudgetPresent);
  //       console.log("isPrimeCostPresent", isPrimeCostPresent);

  //       if (
  //         !isDefaultPresent ||
  //         !isLabelPresent ||
  //         !isInvoicePresent ||
  //         !isVariationPresent ||
  //         !isProvisionalSumPresent ||
  //         !isBuilderDetailsPresent ||
  //         !isContractDetailsPresent ||
  //         !isPrimeCostPresent
  //       ) {
  //         if (!isDefaultPresent) {
  //           let newColumnsAdjusted = newColumns.map((col) => ({
  //             ...col,
  //             id: (parseInt(col.id) + 5).toString(),
  //             accessorKey: (parseInt(col.accessorKey) + 5).toString(),
  //             order: col.order + 5,
  //           }));

  //           newColumns = [
  //             {
  //               accessorKey: "0",
  //               id: "0",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   preset.name !== "Builder Details"
  //               ),
  //               headerText: "Date",
  //               type: "DATE",
  //               enabled: true,
  //               order: 0,
  //             },
  //             {
  //               accessorKey: "1",
  //               id: "1",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   preset.name !== "Builder Details" &&
  //                   !preset.name.includes("_")
  //               ),
  //               headerText: "Gmail",
  //               type: "READONLY",
  //               enabled: true,
  //               order: 1,
  //             },
  //             {
  //               accessorKey: "2",
  //               id: "2",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   preset.name !== "Builder Details" &&
  //                   !preset.name.includes("_")
  //               ),
  //               headerText: "Description of Background",
  //               type: "TEXTAREA",
  //               enabled: true,
  //               order: 2,
  //             },
  //             {
  //               accessorKey: "3",
  //               id: "3",
  //               presets: commentsColPresets,
  //               headerText: "Comments",
  //               type: "TEXTAREA",
  //               enabled: true,
  //               order: 3,
  //             },
  //             {
  //               accessorKey: "4",
  //               id: "4",
  //               presets: presets.filter(
  //                 (preset) =>
  //                   preset.name !== "Contract Details" &&
  //                   !preset.name.includes("_")
  //               ),
  //               headerText: "Document",
  //               type: "READONLY",
  //               enabled: true,
  //               order: 4,
  //             },
  //             ...newColumnsAdjusted,
  //           ];
  //         }
  //         if (!isLabelPresent) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: presets,
  //               headerText: "Label",
  //               type: "READONLY",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //           ];
  //         }
  //         if (
  //           !isInvoicePresent &&
  //           !isVariationPresent &&
  //           !isProvisionalSumPresent &&
  //           !isPrimeCostPresent
  //         ) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [invoicePreset],
  //               headerText: "Due Date for Claim",
  //               type: "DATE",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [invoicePreset],
  //               headerText: "Claim Status",
  //               type: "DROPDOWN",
  //               enabled: true,
  //               optionsText: ["Claimed", "Unclaimed"],
  //               order: newColumns.length + 1,
  //             },
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [invoicePreset],
  //               headerText: "Payment Status",
  //               type: "DROPDOWN",
  //               enabled: true,
  //               optionsText: ["Paid", "Unpaid"],
  //               order: newColumns.length + 2,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 1).toString(),
  //               id: (newColumns.length + 1).toString(),
  //               presets: [invoicePreset],
  //               headerText: "Claim Amount ($)",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 3,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 2).toString(),
  //               id: (newColumns.length + 2).toString(),
  //               presets: [variationPreset],
  //               headerText: "Variation Status",
  //               type: "DROPDOWN",
  //               enabled: true,
  //               optionsText: ["Approved", "For Approval"],
  //               order: newColumns.length + 4,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 3).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [variationPreset],
  //               headerText: "Variation Cost ($)",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 5,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 4).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //               headerText: "Provisional Amount",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 6,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 5).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //               headerText: "Cost Amount",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 7,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 6).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //               headerText: "Description of Work",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 8,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 7).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               // to add budget preset
  //               presets: [
  //                 provisionalSumPreset,
  //                 variationPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //               ],
  //               headerText: "Paid to Date Amount",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 9,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 8).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               // to add budget preset
  //               presets: [
  //                 provisionalSumPreset,
  //                 variationPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //               ],
  //               headerText: "Action",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 10,
  //             },
  //           ];
  //         }
  //         if (!isBuilderDetailsPresent) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Name",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 1).toString(),
  //               id: (newColumns.length + 1).toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Contact Name",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 1,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 2).toString(),
  //               id: (newColumns.length + 2).toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Contact Email",
  //               type: "TEXT",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 2,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 3).toString(),
  //               id: (newColumns.length + 3).toString(),
  //               presets: [builderDetailsPreset],
  //               headerText: "Builder Contact Number",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 3,
  //             },
  //           ];
  //         }
  //         if (!isContractDetailsPresent) {
  //           newColumns = [
  //             ...newColumns,
  //             {
  //               accessorKey: newColumns.length.toString(),
  //               id: newColumns.length.toString(),
  //               presets: [contractDetailsPreset],
  //               headerText: "Start Date",
  //               type: "DATE",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 1).toString(),
  //               id: (newColumns.length + 1).toString(),
  //               presets: [contractDetailsPreset],
  //               headerText: "Contract Price",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 1,
  //             },
  //             {
  //               accessorKey: (newColumns.length + 2).toString(),
  //               id: (newColumns.length + 2).toString(),
  //               presets: [contractDetailsPreset],
  //               headerText: "Contract Value",
  //               type: "NUMBER",
  //               enabled: true,
  //               optionsText: [],
  //               order: newColumns.length + 2,
  //             },
  //           ];
  //         }
  //       } else {
  //         //Filter Columns & set it to default HBA presets
  //         let filterBriefs = [];
  //         defaultColumns.map((obj) => {
  //           if (
  //             obj.headerText == "Claim Status" ||
  //             obj.headerText == "Payment Status" ||
  //             obj.headerText == "Claim Amount ($)" ||
  //             obj.headerText == "Due Date for Claim"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [invoicePreset],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Variation Status" ||
  //             obj.headerText == "Variation Cost" ||
  //             obj.headerText == "Variation Cost ($)"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [variationPreset],
  //             });
  //           }

  //           if (obj.headerText == "Cost Amount") {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [
  //                 provisionalSumPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //                 ...breakDownPresetList,
  //               ],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Provisional Amount" ||
  //             obj.headerText == "Description of Work"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Builder Name" ||
  //             obj.headerText == "Builder Logo" ||
  //             obj.headerText == "Builder Contact Name" ||
  //             obj.headerText == "Builder Contact Email" ||
  //             obj.headerText == "Builder Contact Number"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [builderDetailsPreset],
  //             });
  //           }

  //           if (
  //             obj.headerText == "Start Date" ||
  //             obj.headerText == "Contract Price" ||
  //             obj.headerText == "Contract Value"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               presets: [contractDetailsPreset],
  //             });
  //           }

  //           if (
  //             obj.headerText === "Paid to Date Amount" ||
  //             obj.headerText === "Action"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //               // to add budget preset
  //               presets: [
  //                 provisionalSumPreset,
  //                 variationPreset,
  //                 budgetPreset,
  //                 primeCostPreset,
  //               ],
  //             });
  //           }

  //           if (
  //             obj.headerText !== "Start Date" &&
  //             obj.headerText !== "Contract Price" &&
  //             obj.headerText !== "Contract Value" &&
  //             obj.headerText !== "Claim Status" &&
  //             obj.headerText !== "Payment Status" &&
  //             obj.headerText !== "Claim Amount ($)" &&
  //             obj.headerText !== "Due Date for Claim" &&
  //             obj.headerText !== "Variation Status" &&
  //             obj.headerText !== "Variation Cost" &&
  //             obj.headerText !== "Variation Cost ($)" &&
  //             obj.headerText !== "Description of Work" &&
  //             obj.headerText !== "Provisional Amount" &&
  //             obj.headerText !== "Cost Amount" &&
  //             obj.headerText !== "Paid to Date Amount" &&
  //             obj.headerText !== "Action" &&
  //             obj.headerText !== "Builder Name" &&
  //             obj.headerText !== "Builder Logo" &&
  //             obj.headerText !== "Builder Contact Name" &&
  //             obj.headerText !== "Builder Contact Email" &&
  //             obj.headerText !== "Builder Contact Number" &&
  //             obj.headerText !== "Start Date" &&
  //             obj.headerText !== "Contract Price" &&
  //             obj.headerText !== "Contract Value"
  //           ) {
  //             filterBriefs.push({
  //               ...obj,
  //             });
  //           }
  //         });
  //         console.log("filtered Briefs:", filterBriefs);
  //         newColumns = filterBriefs.map(col => ({
  //           ...col,
  //           presets: col.presets.filter(preset =>
  //             preset !== undefined && preset !== null && preset)
  //         }));
  //       }

  //       console.log(id, "new columns:", newColumns);

  //       const variables = {
  //         id,
  //         input: {
  //           columns: newColumns,
  //         },
  //       };

  //       console.log("VARIABLE IS:", variables);

  //       const updateBriefTable = await API.graphql({
  //         query: UPDATE_BACKGROUND_TABLE_MUTATION,
  //         variables,
  //       });

  //       console.log("updateBriefTable", updateBriefTable);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     console.groupEnd("setDefaultColumnBriefs");
  //   }
  //   console.groupEnd("setDefaultColumnBriefs");
  // }

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    // console.log"SETTING DEFAULT COLS", briefs);

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        // console.log"columnIds", columnIds);
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);

      // console.log"updatedBriefIds", updatedBriefIds);
      const newDefaultColumn = updatedBriefIds?.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      // console.log"Background Table for", clientMatterId, backgroundTable);

      if (backgroundTable?.data?.backgroundTable) {
        await setDefaultColumnBriefs(
          backgroundTable?.data?.backgroundTable,
          briefs
        );
      } else {
        // console.log"Create Background Table for ", clientMatterId);

        const initialPresets = [];
        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          initialPresets.push(preset);
        }

        let backgroundCreate = await API.graphql({
          query: BACKGROUND_TABLE_CREATE,
          variables: {
            clientMatterId: clientMatterId,
            initialPresets: initialPresets,
          },
        });

        await setDefaultColumnBriefs(
          backgroundCreate?.data?.backgroundTableCreate,
          briefs
        );
      }
      // }
    } catch (error) {
      console.error(error);
    }
  }

  const getBriefs = async () => {
    console.group("getBriefs");
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (briefsList) => {
      const briefsLists = briefsList?.data?.clientMatter?.briefs?.items;
      const contractDetailsBrief = briefsLists?.find(
        (brief) => brief.name === "Contract Details"
      );
      const builderDetailsBrief = briefsLists?.find(
        (brief) => brief.name === "Builder Details"
      );

      const progressPhotoBrief = briefsLists?.find(
        (brief) => brief.name === "Progress Photos"
      );

      setProgressPhotoBriefID(progressPhotoBrief?.id);
      setProgressPhotosDetails(progressPhotoBrief);
      // console.log"progressPhotoBrief", progressPhotoBrief);

      if (contractDetailsBrief) {
        let unparsedData = contractDetailsBrief?.backgrounds?.items;

        const parsedData = unparsedData?.map((item) => {
          if (item?.dynamicData) {
            try {
              const dynamicDataObject = JSON.parse(item.dynamicData);
              if (typeof dynamicDataObject === "object") {
                const dynamicDataArray = Object.keys(dynamicDataObject).map(
                  (key) => ({
                    id: key,
                    value: dynamicDataObject[key],
                  })
                );

                return {
                  ...item,
                  dynamicData: dynamicDataArray,
                };
              } else {
                console.warn(
                  "dynamicData is not a valid JSON object:",
                  dynamicDataObject
                );
              }
            } catch (error) {
              console.error("Error parsing dynamicData:", error);
            }
          } else {
            console.warn("dynamicData is missing or null for the item:", item);
          }
        });

        // Filter out items with dynamicData errors
        const validParsedData = parsedData.filter((item) => item);

        if (validParsedData !== "undefined" && validParsedData.length !== 0) {
          // let contractInfoTemp = {};
          // let dynamicDataTemp = validParsedData[0]?.dynamicData;

          // let contractStartDate = dynamicDataTemp.find((e) => e.id === "16");
          // if (contractStartDate) {
          //   contractInfoTemp.contractStartDate = contractStartDate.value;
          // }

          // let contractPrice = dynamicDataTemp.find((e) => e.id === "17");
          // if (contractPrice) {
          //   contractInfoTemp.contractPrice = contractPrice.value;
          // }

          // let contractValue = dynamicDataTemp.find((e) => e.id === "18");
          // if (contractValue) {
          //   contractInfoTemp.contractValue = contractValue.value;
          // }

          // setContractInfo(contractInfoTemp);
          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId,
            },
          });

          if (backgroundTable?.data?.backgroundTable) {
            let contractInfo = {
              contractStartDate: null,
              contractPrice: null,
              contractValue: null,
            };

            let backgroundTableInfo =
              backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
                if (obj.headerText == "Start Date") {
                  // console.log"Start Date", obj.accessorKey);
                  contractInfo.contractStartDate = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Contract Price") {
                  // console.log"Contract Price", obj.accessorKey);
                  contractInfo.contractPrice = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Contract Value") {
                  contractInfo.contractValue = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                return getValueById(parsedData, obj.accessorKey);
              });

            setContractInfo(contractInfo);
          } else {
            setContractInfo(false);
          }
        } else {
          setContractInfo(false);
        }
      }

      let buildInfo = {
        builderName: null,
        builderLogo: null,
        builderContactName: null,
        builderContactEmail: null,
        builderContactNumber: null,
      };

      if (builderDetailsBrief) {
        let unparsedData = builderDetailsBrief?.backgrounds?.items;
        // console.log"getFiles", unparsedData);

        const parsedData = unparsedData?.map((item) => {
          let arrFileResult = item.files.items.map(({ id }) => {
            previewOnlyFile(id)
              .then((fileData) => {
                // Do something with the fileData
                // console.log"preview link", fileData.downloadURL);
                // buildInfo.builderLogo = fileData.downloadURL;
                setBuilderLogo(fileData.downloadURL);
              })
              .catch((error) => {
                // Handle any errors
                // console.log"error");
              });
          });

          if (item?.dynamicData) {
            try {
              const dynamicDataObject = JSON.parse(item.dynamicData);
              if (typeof dynamicDataObject === "object") {
                const dynamicDataArray = Object.keys(dynamicDataObject).map(
                  (key) => ({
                    id: key,
                    value: dynamicDataObject[key],
                  })
                );

                return {
                  ...item,
                  dynamicData: dynamicDataArray,
                };
              } else {
                console.warn(
                  "dynamicData is not a valid JSON object:",
                  dynamicDataObject
                );
              }
            } catch (error) {
              console.error("Error parsing dynamicData:", error);
            }
          } else {
            console.warn("dynamicData is missing or null for the item:", item);
          }
        });

        // Filter out items with dynamicData errors
        const validParsedData = parsedData.filter((item) => item);
        if (validParsedData !== "undefined" && validParsedData.length !== 0) {
          // let buildInfoTemp = {};
          // let dynamicDataTemp = validParsedData[0]?.dynamicData;

          // let builderName = dynamicDataTemp.find((e) => e.id === "12");
          // if (builderName) {
          //   buildInfoTemp.builderName = builderName.value;
          // }

          // let builderLogo = dynamicDataTemp.find((e) =>
          //   e.id === '13'
          // );
          // if (builderLogo) {
          //   buildInfoTemp.builderLogo = builderLogo.value;
          // }

          // let builderContactName = dynamicDataTemp.find((e) => e.id === "14");
          // if (builderContactName) {
          //   buildInfoTemp.builderContactName = builderContactName.value;
          // }

          // let builderContactEmail = dynamicDataTemp.find((e) => e.id === "15");
          // if (builderContactEmail) {
          //   buildInfoTemp.builderContactEmail = builderContactEmail.value;
          // }

          // let builderContactNumber = dynamicDataTemp.find((e) => e.id === "16");
          // if (builderContactNumber) {
          //   buildInfoTemp.builderContactNumber = builderContactNumber.value;
          // }

          // setBuildInfo(buildInfoTemp);
          //       return getValueById(parsedData, obj.accessorKey);
          //     });
          // const backgroundTable = builderDetailsBrief.backgrounds
          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId,
            },
          });

          // console.log"builder details bg", backgroundTable);
          if (backgroundTable?.data?.backgroundTable) {
            let backgroundTableInfo =
              backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
                if (obj.headerText == "Builder Name") {
                  // console.log"Builder Name", obj.accessorKey);
                  buildInfo.builderName = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                // if ( obj.headerText == "Builder Logo" ) {
                //   // console.log"Builder Logo", obj.accessorKey);
                //   buildInfo.builderLogo = getValueById(parsedData, obj.accessorKey);
                // }

                if (obj.headerText == "Builder Contact Name") {
                  // console.log"Builder Contact Name", obj.accessorKey);
                  buildInfo.builderContactName = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Builder Contact Email") {
                  // console.log"Builder Contact Email", obj.accessorKey);
                  buildInfo.builderContactEmail = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Builder Contact Number") {
                  // console.log"Builder Contact Number", obj.accessorKey);
                  buildInfo.builderContactNumber = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                return getValueById(parsedData, obj.accessorKey);
              });

            // console.log"BUILD INFOS: ", buildInfo);
            setBuildInfo(buildInfo);
          } else {
            setBuildInfo(false);
          }
        } else {
          setBuildInfo(false);
        }
      }
    });
    console.groupEnd("getBriefs");
  };

  // Get a value by ID based on columns
  function getValueById(data, id) {
    const item = data[0]; // Assuming there's only one item in the array (only 1 row per brief)
    const dynamicData = item.dynamicData;
    const matchingObject = dynamicData?.find((obj) => obj.id === id);

    if (matchingObject) {
      return matchingObject.value;
    } else {
      return null; // ID not found
    }
  }

  function visitBrief(id, name) {
    // history.push(`${AppRoutes.BACKGROUND}/${id}`);
    const back_id = "1";

    if (name !== "Progress of work") {
      history.push(
        `${
          AppRoutes.BACKGROUND
        }/${back_id}/${matter_id}/${id}/?matter_name=${utf8_to_b64(
          matter_name
        )}&client_name=${utf8_to_b64(client_name)}&url=hba`
      );
    }
  }

  const handleChangeDate = async (selected, id, origDate, index) => {
    const data = {
      date:
        moment.utc(selected).toISOString() !== null
          ? String(moment.utc(selected).toISOString())
          : null,
    };
    // console.log"DATE", data);

    const origDateString =
      moment.utc(origDate).toISOString() !== null
        ? String(moment.utc(origDate).toISOString())
        : null;

    await updateBackgroundDate(id, data).then(async () => {
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
          briefId: showBriefId,
          activity: "changed the background date",
          field: "Date",
          current:
            data.date !== null ? moment.utc(data.date).toISOString() : null,
          previous: origDateString,
          appModule: "BACKGROUND",
          rowId: id,
        },
      };
      const addActivity = await API.graphql(params).then((result) => {
        // console.log"addActivity result", result);
      });
    });

    const updatedOSArray = background.map((p) =>
      p.id === id ? { ...p, date: data.date } : p
    );

    setBackground(updatedOSArray);
  };

  async function updateBackgroundDate(id, data) {
    // console.log"updateBackgroundDate:", data);

    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDate,
          variables: {
            id: id,
            date:
              data.date !== null ? moment.utc(data.date).toISOString() : null,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  //DESCRIPTION
  const [textDesc, setTextDesc] = useState("");
  const [saving, setSaving] = useState(false);

  // reference for MMA-2342 GMAIL Integration
  const handleDescContent = (e, description, id, index) => {
    const cleanDescription = handleDescription(description);
    setTextDesc(cleanDescription);
  };

  const handleDescription = (description) => {
    if (description) {
      var parsedDescription = description?.replace(
        /style="[^\"]*"/gi,
        `className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[div]+/g,
        `<div className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[span]+/g,
        `<span className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[p ]+/g,
        `<p className="${""}"`
      );
      parsedDescription = parsedDescription.replace(
        /<[font ]+/g,
        `<p className="${""}"`
      );
      return parsedDescription;
    }
  };

  const handleChangeDesc = (event, index) => {
    setTextDesc(event.currentTarget.textContent);
  };

  const handleSaveDesc = async (e, description, date, id, index) => {
    if (e.target !== document.activeElement) {
      const origDescription = description;
      // let newDescription = e.target.innerHTML;
      let newDescription = e.currentTarget.innerText;
      // newDescription = extractText(newDescription);
      // newDescription = newDescription?.replace(/\&nbsp;/g, "");

      if (textDesc?.length <= 0) {
        const data = {
          description: newDescription,
        };
        const success = await updateBackgroundDesc(id, data).then(async () => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: showBriefId,
              activity: "updated the background description",
              field: "Description",
              current: data.description,
              previous: origDescription,
              appModule: "BACKGROUND",
              rowId: id,
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            // console.log"addActivity result", result);
          });
        });
        // console.log"THIS IS ID", id);
      } else if (textDesc === description) {
        // console.log"2nd condition");
      } else {
        // console.log"3rd Condition");

        // console.log
        //   "ROWW ID --->",
        //   id,
        //   "\nmatter_id --->",
        //   matter_id,
        //   "\nshowBrief",
        //   showBriefId
        // );

        const data = {
          description: newDescription,
        };
        const success = await updateBackgroundDesc(id, data)
          .then(async (res) => {
            // console.logres);
            const params = {
              query: mCreateActivity,
              variables: {
                companyId: localStorage.getItem("companyId"),
                clientMatterId: matter_id,
                briefId: showBriefId,
                activity: "updated the background description",
                field: "Description",
                current: data.description,
                previous: origDescription,
                appModule: "BACKGROUND",
                rowId: id,
              },
            };
            const addActivity = await API.graphql(params).then((result) => {
              // console.log"addActivity result", result);
            });
          })
          .catch((err) => {
            // console.log"ERR", err);
          });
      }

      let foundIndex = background.findIndex((x) => x.id == id);
      background[foundIndex].description = e.target.innerHTML;
    }
  };

  function extractText(desc) {
    const regex = /(<([^>]+)>)/gi;
    const newString = desc?.replace(regex, "");

    return newString;
  }

  async function updateBackgroundDesc(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDesc,
          variables: {
            id: id,
            description: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }
  //Virtualize
  const bindlistM = useRef();
  const cacheM = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 30,
      minHeight: 30,
    })
  );

  useEffect(() => {
    // console.log"DEFAULT LIST IS:", defaultList);
  }, [defaultList]);

  function formatCurrency(amount, locale = "en-US", currency = "USD") {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    });
    return formatter.format(amount);
  }

  // mobile main header variables
  const [headerRead, setHeaderRead] = useState(false);

  // Get Dynamic Data of Rows on Tiles
  const excludeFixedTiles = [
    "Builder Details",
    "Contract Details",
    "Progress Photos",
    "Progress of work",
    "Project Location",
    "Contracts",
    "Site Diary",
    "Provisional Sum",
    "Budget",
    "Invoices",
    "Variations",
    "Prime Cost",
  ];

  useEffect(() => {
    if (defaultList && defaultList.length > 0) {
      // Check if defaultList is not null and not empty
      const filteredDefaultList = defaultList.filter(
        (item) =>
          !excludeFixedTiles.includes(item) && !item.name.startsWith("_")
      );

      filteredDefaultList.forEach((item) => {
        API.graphql({
          query: BACKGROUND_BY_BRIEF_ID_QUERY,
          variables: {
            id: item.id,
            nextToken: null,
            sortOrder: "ORDER_ASC",
            limit: 5,
          },
        })
          .then((backgroundOpt) => {
            if (backgroundOpt.data.brief.backgrounds?.items !== null) {
              var result = backgroundOpt.data.brief.backgrounds?.items.map(
                ({
                  id,
                  description,
                  date,
                  createdAt,
                  order,
                  files,
                  dynamicData,
                }) => ({
                  createdAt: createdAt,
                  id: id,
                  description: description,
                  date: date,
                  order: order,
                  files: files,
                  dynamicData: dynamicData,
                })
              );

              setDynamicTileRows((prevState) => ({
                ...prevState,
                [item.id]: sortByOrder(result),
              }));
            }
          })
          .catch((error) => {
            console.error("Error fetching dynamic backgrounds:", error);
          });
      });
    }
  }, [defaultList]); // Run the effect when defaultList changes

  return userInfo ? (
    <>
      {showToast && (
        <ToastNotification title={alertMessage} error={isToastError} />
      )}
      <div>
        <ScrollToTop
          smooth
          component={
            <BiArrowToTop
              style={{ color: "white", display: "block", margin: "auto" }}
            />
          }
          className="sm:hidden scrollButton"
          style={{ borderRadius: "50%" }}
        />

        {/* MAIN DIV */}
        <div className="py-5 flex-none p-0 bg-white h-auto">
          <div className="flex flex-col gap-8 px-3 py-5 font-open-sans bg-white rounded-lg contentDiv pl-6">
            {/* MAIN HEADER */}
            <div id="main-header" className="">
              {width > 844 ? (
                // web main header
                <>
                  <div
                    className={
                      "overflow-hidden bg-gradient-to-r from-white via-gray-100 to-blue-100 flex flex-row justify-between items-end rounded-2xl p-12"
                    }
                  >
                    <div className="w-auto">
                      <div className="relative">
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246)" }}
                        ></div>
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246, 0.75)" }}
                        ></div>
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246, 0.50)" }}
                        ></div>
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246, 0.25)" }}
                        ></div>
                      </div>

                      <p className="font-semibold text-base mb-4 relative">
                        Welcome to your home construction dashboard!<br></br>
                      </p>

                      <p className="mb-2 relative">
                        You have{" "}
                        <span className="text-red-500 font-semibold">
                          {8 -
                            Object.keys(getStartedProgress).reduce(
                              (count, key) =>
                                getStartedProgress[key] === true
                                  ? count + 1
                                  : count,
                              0
                            )}
                        </span>{" "}
                        outstanding actions to complete. Make sure to complete{" "}
                        <br></br>
                        the 'Getting Started with HBA' steps located at the top
                        of your page to <br></br>
                        optimise your experience.
                      </p>

                      <div className="relative">
                        <ProgressBar
                          completed={countProgress(getStartedProgress)}
                          bgColor="#1e3a8a"
                          height="10px"
                          animateOnRender={true}
                          labelSize="10px"
                          labelAlignment="outside"
                          labelClassName="text-black ml-2"
                          customLabel={`${countProgress(
                            getStartedProgress
                          )}% Completed`}
                          width="73%"
                        />
                      </div>
                    </div>

                    <div className="flex items-end">
                      {/* builder details */}
                      {visibleBriefsList.includes("Builder Details") ? (
                        <div className="w-52 justify-start ">
                          {(buildInfo && buildInfo?.builderName) ||
                          buildInfo?.builderContactName ||
                          buildInfo?.builderContactEmail ||
                          buildInfo?.builderContactNumber ||
                          builderLogo ? (
                            <>
                              {builderLogo ? (
                                <img
                                  src={builderLogo}
                                  className="w-5 h-5 mb-2"
                                  alt="logo"
                                ></img>
                              ) : (
                                <FaIcons.FaSquare className="mb-2" />
                              )}
                              <p className="font-semibold mb-2 text-xs">
                                {buildInfo?.builderName
                                  ? `${buildInfo?.builderName}`
                                  : ""}
                              </p>
                              <p className="text-xs">
                                {buildInfo?.builderContactName
                                  ? `${buildInfo?.builderContactName}`
                                  : ""}
                              </p>
                              <p className="text-xs">
                                {buildInfo?.builderContactEmail
                                  ? `${buildInfo?.builderContactEmail}`
                                  : ""}
                              </p>
                              <p className="text-xs">
                                {buildInfo?.builderContactNumber
                                  ? `${buildInfo?.builderContactNumber}`
                                  : ""}
                              </p>
                              <span
                                className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.BUILDER_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Edit
                              </span>
                            </>
                          ) : (
                            <>
                              <p className="text-xs text-gray-400">
                                Help us keep track of <br></br>
                                your building progress by <br></br>
                                providing us with your builder's <br></br>
                                contact details
                              </p>
                              <span
                                className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.BUILDER_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Add
                              </span>
                            </>
                          )}
                        </div>
                      ) : null}

                      {/* contract details */}
                      {visibleBriefsList.includes("Contract Details") ? (
                        <div className="ml-8">
                          {contractInfo?.contractStartDate ||
                          contractInfo?.contractPrice ||
                          contractInfo?.contractValue ? (
                            <>
                              <LiaIcons.LiaFileContractSolid className="mb-4" />
                              <div className="flex">
                                <div>
                                  {contractInfo.contractStartDate && (
                                    <p className="text-xs font-semibold">
                                      Contract Start Date:{" "}
                                    </p>
                                  )}
                                  <p className="text-xs font-semibold">
                                    Contract Price:{" "}
                                  </p>
                                </div>
                                <div className="ml-4">
                                  {contractInfo.contractStartDate && (
                                    <p className="text-xs text-right">
                                      {dateFormatter(
                                        contractInfo.contractStartDate
                                      )}
                                    </p>
                                  )}
                                  <p className="text-xs text-right">
                                    {contractInfo.contractPrice !== null
                                      ? formatCurrency(
                                          contractInfo.contractPrice
                                        )
                                      : formatCurrency(0)}
                                  </p>
                                </div>
                              </div>

                              <span
                                className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.CONTRACT_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Edit
                              </span>
                            </>
                          ) : (
                            <>
                              <p className="text-xs text-gray-400">
                                Provide your contract details to <br></br>
                                allow us to assist you in tracking <br></br>
                                important milestones and dates in <br></br>
                                your construction journey
                              </p>
                              <span
                                className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.CONTRACT_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Add
                              </span>
                            </>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                // end of web main header
                // mobile main header
                <>
                  <div
                    className={
                      "overflow-hidden bg-gradient-to-r from-white via-gray-100 to-blue-100 flex flex-col rounded-2xl p-8"
                    }
                  >
                    <div className="w-auto">
                      <div className="relative">
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246)" }}
                        ></div>
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246, 0.75)" }}
                        ></div>
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246, 0.50)" }}
                        ></div>
                        <div
                          className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                          style={{ borderColor: "rgb(243, 244, 246, 0.25)" }}
                        ></div>
                      </div>

                      <p className="font-semibold text-base mb-2 relative font-open-sans">
                        Welcome to your home construction dashboard!<br></br>
                      </p>

                      <p className="mb-2 relative text-xs font-open-sans font-medium">
                        You have{" "}
                        <span className="text-red-500 text-xs font-bold">
                          {8 -
                            Object.keys(getStartedProgress).reduce(
                              (count, key) =>
                                getStartedProgress[key] === true
                                  ? count + 1
                                  : count,
                              0
                            )}
                        </span>{" "}
                        outstanding actions to complete. Tackling this task will
                        provide you with a clearer, more organised view of your
                        project's progress.
                      </p>

                      <div className="relative">
                        <ProgressBar
                          completed={countProgress(getStartedProgress)}
                          bgColor="#1e3a8a"
                          height="8px"
                          animateOnRender={true}
                          labelSize="10px"
                          labelAlignment="outside"
                          labelClassName="text-black ml-2 text-xs font-normal font-open-sans"
                          customLabel={`${countProgress(
                            getStartedProgress
                          )}% Completed`}
                          width="60%"
                        />
                      </div>
                    </div>

                    {!headerRead ? (
                      //read more button
                      <Button
                        variant={"hbBlue"}
                        className="py-2 cursor-pointer mt-8"
                        block={"full"}
                        onClick={() => setHeaderRead(!headerRead)}
                      >
                        <div className="flex items-center">
                          <span className="font-open-sans">Read more</span>
                          <FaChevronRight className="ml-2 text-sm" />
                        </div>
                      </Button>
                    ) : (
                      <div className="flex flex-col mt-8">
                        <div className=" ">
                          {(buildInfo && buildInfo?.builderName) ||
                          buildInfo?.builderContactName ||
                          buildInfo?.builderContactEmail ||
                          buildInfo?.builderContactNumber ||
                          builderLogo ? (
                            <>
                              {builderLogo ? (
                                <img
                                  src={builderLogo}
                                  className="w-7 h-7"
                                  alt="logo"
                                ></img>
                              ) : (
                                <FaIcons.FaSquare className="" />
                              )}
                              <p className="font-semibold mt-2 text-xs mb-1 font-open-sans">
                                {buildInfo?.builderName
                                  ? `${buildInfo?.builderName}`
                                  : ""}
                              </p>
                              <p className="text-xs mb-1 font-normal font-open-sans">
                                {buildInfo?.builderContactName
                                  ? `${buildInfo?.builderContactName}`
                                  : ""}
                              </p>
                              <p className="text-xs mb-1 font-normal font-open-sans">
                                {buildInfo?.builderContactEmail
                                  ? `${buildInfo?.builderContactEmail}`
                                  : ""}
                              </p>
                              <p className="text-xs mb-1 font-normal font-open-sans">
                                {buildInfo?.builderContactNumber
                                  ? `${buildInfo?.builderContactNumber}`
                                  : ""}
                              </p>

                              <span
                                className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.BUILDER_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Edit
                              </span>
                            </>
                          ) : (
                            <div className="flex flex-row justify-between font-open-sans items-center">
                              <p className="text-xs font-normal text-gray-500 font-open-sans">
                                Help us keep track of your building progress by
                                providing us with your builder's contact details
                              </p>
                              <Button
                                variant={"hbBlue"}
                                className="cursor-pointer text-xs px-4 py-2 rounded-lg ml-6"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.BUILDER_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="mt-4">
                          {contractInfo?.contractStartDate ||
                          contractInfo?.contractPrice ||
                          contractInfo?.contractValue ? (
                            <div>
                              <img src={contractsIcon} alt="Contracts Icon" />
                              <div className="flex flex-row justify-between mt-2">
                                <div>
                                  <p className="text-xs mb-1 font-semibold">
                                    Contract Start Date:
                                  </p>
                                  <p className="text-xs mb-1 font-semibold">
                                    Contract Price:
                                  </p>
                                </div>
                                <div className="ml-4">
                                  <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                    {contractInfo.contractStartDate
                                      ? dateFormatter(
                                          contractInfo.contractStartDate
                                        )
                                      : "Day Month Year"}
                                  </p>
                                  <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                    {contractInfo.contractPrice &&
                                    contractInfo.contractPrice !== null
                                      ? formatCurrency(
                                          contractInfo.contractPrice
                                        )
                                      : formatCurrency(0)}
                                  </p>
                                </div>
                              </div>

                              <span
                                className="text-xs text-blue-900 font-medium cursor-pointer hover:text-blue-400"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.CONTRACT_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Edit
                              </span>
                            </div>
                          ) : (
                            <div className="flex flex-row justify-between font-open-sans items-center">
                              <p className="text-xs font-normal text-gray-500 font-open-sans">
                                Provide your contract details to allow us to
                                assist you in tracking important milestones and
                                dates in your construction journey
                              </p>
                              <Button
                                variant={"hbBlue"}
                                className="cursor-pointer text-xs px-4 py-2 rounded-lg ml-6"
                                onClick={(e) => {
                                  setModalState(
                                    modalChoicesEnum.CONTRACT_DETAILS
                                  );
                                  setShowRightPanel(true);
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          )}
                        </div>
                        <Button
                          variant={"hbBlue"}
                          className="py-2 cursor-pointer mt-8"
                          block={"full"}
                          onClick={() => setHeaderRead(!headerRead)}
                        >
                          <div className="flex items-center">
                            <span className="font-open-sans">Read less</span>
                            <FaChevronDown className="ml-2 text-sm" />
                          </div>
                        </Button>
                      </div>
                    )}
                  </div>
                </>
                // end of mobile main header
              )}
            </div>
            {/* END OF MAIN HEADER */}

            {/* GET STARTED WITH HBA: WEB ONLY */}
            {!isMobileDevice(width) && (
              <div id="getting-started" className="">
                <GetStartedTile
                  isInitDone={isInitDone}
                  matter_id={matter_id}
                  showPanel={showPanel}
                  setShowPanel={setShowPanel}
                  scrollToSection={scrollToSection}
                  getStartedStates={{
                    getStartedProgress,
                    setGetStartedProgress,
                  }}
                  infoDetails={{
                    buildInfo,
                    contractInfo,
                    defaultList,
                  }}
                  infoRefs={{
                    propertyDetailsRef,
                    propertyPhotoRef,
                    completionDateRef,
                    invoicesRef,
                    variationsRef,
                    uploadContractsRef,
                  }}
                  infoModal={{
                    modalChoicesEnum,
                    setShowRightPanel,
                    setModalState,
                  }}
                  setTilePlulse={setTilePlulse}
                />
              </div>
            )}
            {/* END OF GET STARTED WITH HBA: WEB ONLY */}

            {/* TABS */}
            <div className="w-full pb-3 pt-3 overflow-x-scroll overflow-y-hidden h-11 p-1 bg-gray-200 rounded-lg border border-gray-200 justify-start items-center gap-2 inline-flex">
              <div
                className={`px-2 py-2 hover:bg-white hover:shadow rounded-md ${
                  !activePhase ? "bg-white shadow" : ""
                } justify-center items-center gap-1 flex`}
              >
                <div
                  className={
                    "text-blue-950 text-sm font-normal font-open-sans leading-tight"
                  }
                >
                  <span onClick={() => resetPhases()}>
                    {isMobileDevice(width) ? "All" : "All Phases"}
                  </span>
                </div>
              </div>

              {phases.map((e) => {
                return (
                  <div
                    key={`phase-${e.name}`}
                    className={`px-2 py-2 hover:bg-white hover:shadow rounded-md ${
                      activePhase === e.label ? "bg-white shadow" : ""
                    } justify-center items-center gap-1 flex`}
                  >
                    <div className="text-blue-950 text-sm font-normal font-open-sans leading-tight">
                      <span
                        className="block"
                        onClick={() => setActivePhase(e.label)}
                      >
                        {isMobileDevice(width) && e.label === "Pre Construction"
                          ? "PreConstruction"
                          : e.label}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* END OF TABS */}

            {/* BRIEF TILES */}
            {defaultList && !loading ? (
              <>
                <div
                  id="hba-tiles"
                  className={
                    isMobileDevice(width)
                      ? "grid grid-cols-1 bg-gray-100 border px-1 py-1"
                      : activePhase === "Pre Construction" ||
                        activePhase === "Handover"
                      ? "grid md:grid-cols-3 gap-4 px-3 py-3 bg-gray-100 border"
                      : "grid md:grid-cols-4 gap-4 px-3 py-3 bg-gray-100 border"
                  }
                >
                  {/* 1ST COLUMN: ALL PHASES */}
                  <div
                    id="1st-all-phases"
                    className={
                      activePhase === "" ? "col-span-1 row-span-1" : "hidden"
                    }
                  >
                    {/* 1. Email Integration */}
                    {showInbox ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}

                    {/* 2. Site Diary */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Site Diary" &&
                        visibleBriefsList.includes("Site Diary")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <SiteDiary
                              briefId={item.id}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${1}/${matter_id}/${
                                item.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(
                                client_name
                              )}&url=hba`}
                              getBackgrounds={getBackgrounds}
                              background={background}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Tasklist */}
                    {showTasklist ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <TaskList
                          redirectUrl={AppRoutes.TASKS}
                          taskListData={taskListData}
                        />
                      </div>
                    ) : null}

                    {/* 4. Progress of Work */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Progress of work" &&
                        visibleBriefsList.includes("Progress of work")
                      ) {
                        return (
                          <div
                            key={`brief-${item.id}`}
                            className={"col-span-1 row-span-1 mb-4 relative"}
                            data-info={item.id}
                          >
                            {tilePulse === "ProgressOfWork" ? (
                              <PulsingBorderDiv>
                                <div
                                  onClick={() => visitBrief(item.id, item.name)}
                                >
                                  <ProgressOfWork
                                    items={item}
                                    completionDateRef={completionDateRef}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                  />
                                </div>
                              </PulsingBorderDiv>
                            ) : (
                              <div
                                onClick={() => visitBrief(item.id, item.name)}
                              >
                                <ProgressOfWork
                                  items={item}
                                  completionDateRef={completionDateRef}
                                  redirectUrl={`${
                                    AppRoutes.BACKGROUND
                                  }/${1}/${matter_id}/${
                                    item.id
                                  }/?matter_name=${utf8_to_b64(
                                    matter_name
                                  )}&client_name=${utf8_to_b64(client_name)}`}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 5. Contracts */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Contracts" &&
                        visibleBriefsList.includes("Contracts")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            ref={uploadContractsRef}
                            id={
                              activePhase === ""
                                ? "Contracts"
                                : "all-phases-contracts"
                            }
                          >
                            {tilePulse === "Contracts" ? (
                              <PulsingBorderDiv>
                                <div className="rounded cursor-pointer w-full">
                                  <Contracts
                                    visitBrief={visitBrief}
                                    briefDetails={defaultList.filter((item) => {
                                      return item?.name == "Contracts";
                                    })}
                                    isComplete={
                                      contractTC?.length > 0 &&
                                      archiDrawings?.length > 0 &&
                                      engDrawings?.length > 0 &&
                                      specifications?.length > 0 &&
                                      buildInsurance?.length > 0
                                    }
                                  />
                                </div>
                              </PulsingBorderDiv>
                            ) : (
                              <div className="rounded cursor-pointer w-full">
                                <Contracts
                                  visitBrief={visitBrief}
                                  briefDetails={defaultList.filter((item) => {
                                    return item?.name == "Contracts";
                                  })}
                                  isComplete={
                                    contractTC?.length > 0 &&
                                    archiDrawings?.length > 0 &&
                                    engDrawings?.length > 0 &&
                                    specifications?.length > 0 &&
                                    buildInsurance?.length > 0
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* END OF 1ST COLUMN: ALL PHASES */}

                  {/* 1ST COLUMN: PRE-CONSTRUCTION */}
                  <div
                    id="1st-pre-construction"
                    className={
                      activePhase === "Pre Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            id={
                              activePhase === "Pre Construction"
                                ? "ProjectLocation"
                                : "pre-construction-project-location"
                            }
                            ref={propertyDetailsRef}
                          >
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                            >
                              {tilePulse === "ProjectLocation" ? (
                                <>
                                  <ProjectLocation
                                    projectLocationBrief={projectLocationBrief}
                                    setProjectLocationBrief={
                                      setProjectLocationBrief
                                    }
                                    userInfo={userInfo}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                    invalidFiles={invalidFiles}
                                    rejectFiles={rejectFiles}
                                    clientMatterId={matter_id}
                                    projectLocationPhoto={projectLocationPhoto}
                                    triggerToast={triggerToast}
                                    modalChoicesEnum={modalChoicesEnum}
                                    setModalState={setModalState}
                                    setShowRightPanel={setShowRightPanel}
                                    isInitDone={isInitDone}
                                    setDefaultList={setDefaultList}
                                    setProjectLocationPhoto={
                                      setProjectLocationPhoto
                                    }
                                  />
                                  <PulsingBorderDivRelative />
                                </>
                              ) : (
                                <ProjectLocation
                                  projectLocationBrief={projectLocationBrief}
                                  setProjectLocationBrief={
                                    setProjectLocationBrief
                                  }
                                  userInfo={userInfo}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                  invalidFiles={invalidFiles}
                                  rejectFiles={rejectFiles}
                                  clientMatterId={matter_id}
                                  projectLocationPhoto={projectLocationPhoto}
                                  triggerToast={triggerToast}
                                  modalChoicesEnum={modalChoicesEnum}
                                  setModalState={setModalState}
                                  setShowRightPanel={setShowRightPanel}
                                  isInitDone={isInitDone}
                                  setDefaultList={setDefaultList}
                                  setProjectLocationPhoto={
                                    setProjectLocationPhoto
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Budget */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Budget" &&
                        visibleBriefsList.includes("Budget")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            key={`brief-${item.id}`}
                            data-info={item.id}
                          >
                            <Budget
                              briefId={item.id}
                              budgetRef={budgetRef}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${1}/${matter_id}/${
                                item.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(client_name)}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Contracts */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Contracts" &&
                        visibleBriefsList.includes("Contracts")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            ref={uploadContractsRef}
                            id={
                              activePhase === "Pre Construction"
                                ? "Contracts"
                                : "pre-construction-contracts"
                            }
                          >
                            {tilePulse === "Contracts" ? (
                              <PulsingBorderDiv>
                                <div className="rounded cursor-pointer w-full">
                                  <Contracts
                                    visitBrief={visitBrief}
                                    briefDetails={defaultList.filter((item) => {
                                      return item?.name == "Contracts";
                                    })}
                                    isComplete={
                                      contractTC?.length > 0 &&
                                      archiDrawings?.length > 0 &&
                                      engDrawings?.length > 0 &&
                                      specifications?.length > 0 &&
                                      buildInsurance?.length > 0
                                    }
                                  />
                                </div>
                              </PulsingBorderDiv>
                            ) : (
                              <div className="rounded cursor-pointer w-full">
                                <Contracts
                                  visitBrief={visitBrief}
                                  briefDetails={defaultList.filter((item) => {
                                    return item?.name == "Contracts";
                                  })}
                                  isComplete={
                                    contractTC?.length > 0 &&
                                    archiDrawings?.length > 0 &&
                                    engDrawings?.length > 0 &&
                                    specifications?.length > 0 &&
                                    buildInsurance?.length > 0
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* END OF 1ST COLUMN: PRE-CONSTRUCTION */}

                  {/* 1ST COLUMN: CONSTRUCTION */}
                  <div
                    id="1st-construction"
                    className={
                      activePhase === "Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Email Integration */}
                    {showInbox ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}

                    {/* 2. Site Diary */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Site Diary" &&
                        visibleBriefsList.includes("Site Diary")
                      ) {
                        return (
                          <div className={"col-span-1 row-span-1 mb-4"}>
                            <SiteDiary
                              briefId={item.id}
                              redirectUrl={`${
                                AppRoutes.BACKGROUND
                              }/${1}/${matter_id}/${
                                item.id
                              }/?matter_name=${utf8_to_b64(
                                matter_name
                              )}&client_name=${utf8_to_b64(
                                client_name
                              )}&url=hba`}
                              getBackgrounds={getBackgrounds}
                              background={background}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Tasklist */}
                    {showTasklist ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <TaskList
                          redirectUrl={AppRoutes.TASKS}
                          taskListData={taskListData}
                        />
                      </div>
                    ) : null}

                    {/* 4. Defects list */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            item.name === "Defects List" && (
                              <div
                                key={item.id}
                                className={"col-span-1 row-span-1 mb-4"}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(
                                      client_name
                                    )}&url=hba`}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 1ST COLUMN: CONSTRUCTION */}

                  {/* 1ST COLUMN: HANDOVER */}
                  <div
                    id="1st-handover"
                    className={
                      activePhase === "Handover"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            id={
                              activePhase === "Handover"
                                ? "ProjectLocation"
                                : "handover-project-location"
                            }
                            ref={propertyDetailsRef}
                          >
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                            >
                              {tilePulse === "ProjectLocation" ? (
                                <>
                                  <ProjectLocation
                                    projectLocationBrief={projectLocationBrief}
                                    setProjectLocationBrief={
                                      setProjectLocationBrief
                                    }
                                    userInfo={userInfo}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                    invalidFiles={invalidFiles}
                                    rejectFiles={rejectFiles}
                                    clientMatterId={matter_id}
                                    projectLocationPhoto={projectLocationPhoto}
                                    triggerToast={triggerToast}
                                    modalChoicesEnum={modalChoicesEnum}
                                    setModalState={setModalState}
                                    setShowRightPanel={setShowRightPanel}
                                    isInitDone={isInitDone}
                                    setDefaultList={setDefaultList}
                                    setProjectLocationPhoto={
                                      setProjectLocationPhoto
                                    }
                                  />
                                  <PulsingBorderDivRelative />
                                </>
                              ) : (
                                <ProjectLocation
                                  projectLocationBrief={projectLocationBrief}
                                  setProjectLocationBrief={
                                    setProjectLocationBrief
                                  }
                                  userInfo={userInfo}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                  invalidFiles={invalidFiles}
                                  rejectFiles={rejectFiles}
                                  clientMatterId={matter_id}
                                  projectLocationPhoto={projectLocationPhoto}
                                  triggerToast={triggerToast}
                                  modalChoicesEnum={modalChoicesEnum}
                                  setModalState={setModalState}
                                  setShowRightPanel={setShowRightPanel}
                                  isInitDone={isInitDone}
                                  setDefaultList={setDefaultList}
                                  setProjectLocationPhoto={
                                    setProjectLocationPhoto
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Email Integration */}
                    {showInbox ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}
                  </div>
                  {/* END OF 1ST COLUMN: HANDOVER */}

                  {/* 2ND AND 3RD COLUMN: ALL PHASES */}
                  {/* THIS IS SHARED FOR ALL PHASES AND CONSTRUCTION */}
                  <div
                    id="2nd-all-phases-construction"
                    className={
                      activePhase === "" || activePhase === "Construction"
                        ? "col-span-1 md:col-span-2"
                        : "hidden"
                    }
                  >
                    {/* 1. Progress photos (Stretch) */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Progress Photos" &&
                        visibleBriefsList.includes("Progress Photos")
                      ) {
                        return (
                          <div
                            className="col-span-3 md:col-span-3 sm:col-span-1 row-span-1 relative"
                            ref={propertyPhotoRef}
                            id={
                              activePhase === "" ||
                              activePhase === "Construction"
                                ? "ProgressPhoto"
                                : "all-phases-construction-progress-photos"
                            }
                          >
                            {tilePulse === "ProgressPhoto" ? (
                              <>
                                <ProgressPhotos
                                  progressPhotosDetails={progressPhotosDetails}
                                  setShowRightPanel={setShowRightPanel}
                                  setModalState={setModalState}
                                  modalChoicesEnum={modalChoicesEnum}
                                  progressPhotoBrief={progressPhotoBrief}
                                  handleUploadLink={handleUploadLink}
                                  handleProgressPhotoUpload={
                                    handleProgressPhotoUpload
                                  }
                                  matterId={matterId}
                                  uploadImage={uploadImage}
                                  setUploadImage={setUploadImage}
                                  imageURL={imageURL}
                                  setImageURL={setImageURL}
                                  datePicked={datePicked}
                                  setDatePicked={setDatePicked}
                                  description={description}
                                  setDescription={setDescription}
                                  uploadedFiles={uploadedFiles}
                                  setUploadedFiles={setUploadedFiles}
                                  invalidFiles={invalidFiles}
                                  setInvalidFiles={setInvalidFiles}
                                  selectedFiles={selectedFiles}
                                  // _setSelectedFiles={_setSelectedFiles}
                                  uploadStart={uploadStart}
                                  setUploadStart={setUploadStart}
                                  photos={photos}
                                  setPhotos={setPhotos}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                  handleUpload={handleUpload}
                                  rejectFiles={rejectFiles}
                                  saveButtonClicked={saveButtonClicked}
                                  clientMatterId={matter_id}
                                  isImageFile={isImageFile}
                                  redirectUrl={`${
                                    AppRoutes.BACKGROUND
                                  }/${1}/${matter_id}/${
                                    items.id
                                  }/?matter_name=${utf8_to_b64(
                                    matter_name
                                  )}&client_name=${utf8_to_b64(client_name)}`}
                                />
                                <PulsingBorderDivRelative />
                              </>
                            ) : (
                              <ProgressPhotos
                                progressPhotosDetails={progressPhotosDetails}
                                setShowRightPanel={setShowRightPanel}
                                setModalState={setModalState}
                                modalChoicesEnum={modalChoicesEnum}
                                progressPhotoBrief={progressPhotoBrief}
                                handleUploadLink={handleUploadLink}
                                handleProgressPhotoUpload={
                                  handleProgressPhotoUpload
                                }
                                matterId={matterId}
                                uploadImage={uploadImage}
                                setUploadImage={setUploadImage}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                                datePicked={datePicked}
                                setDatePicked={setDatePicked}
                                description={description}
                                setDescription={setDescription}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                invalidFiles={invalidFiles}
                                setInvalidFiles={setInvalidFiles}
                                selectedFiles={selectedFiles}
                                // _setSelectedFiles={_setSelectedFiles}
                                uploadStart={uploadStart}
                                setUploadStart={setUploadStart}
                                photos={photos}
                                setPhotos={setPhotos}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                                handleUpload={handleUpload}
                                rejectFiles={rejectFiles}
                                saveButtonClicked={saveButtonClicked}
                                clientMatterId={matter_id}
                                isImageFile={isImageFile}
                                redirectUrl={`${
                                  AppRoutes.BACKGROUND
                                }/${1}/${matter_id}/${
                                  items.id
                                }/?matter_name=${utf8_to_b64(
                                  matter_name
                                )}&client_name=${utf8_to_b64(client_name)}`}
                              />
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}

                    <div className="grid sm:grid-cols-2 gap-4">
                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 2.1 Invoices */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Invoices" &&
                            visibleBriefsList.includes("Invoices")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                {tilePulse === "Invoices" ? (
                                  <PulsingBorderDiv>
                                    <div
                                      onClick={(e) =>
                                        visitBrief(items.id, items.name)
                                      }
                                    >
                                      <Invoices
                                        items={items}
                                        invoicesRef={invoicesRef}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          items.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}`}
                                        handleChangeGetStartedProgress={
                                          handleChangeGetStartedProgress
                                        }
                                      />
                                    </div>
                                  </PulsingBorderDiv>
                                ) : (
                                  <div
                                    onClick={(e) =>
                                      visitBrief(items.id, items.name)
                                    }
                                  >
                                    <Invoices
                                      items={items}
                                      invoicesRef={invoicesRef}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        items.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                      handleChangeGetStartedProgress={
                                        handleChangeGetStartedProgress
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return null;
                        })}

                        {/* 2.2 Budget */}
                        {defaultList?.map((item) => {
                          if (
                            item.name === "Budget" &&
                            visibleBriefsList.includes("Budget")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${item.id}`}
                                data-info={item.id}
                              >
                                <Budget
                                  briefId={item.id}
                                  budgetRef={budgetRef}
                                  redirectUrl={`${
                                    AppRoutes.BACKGROUND
                                  }/${1}/${matter_id}/${
                                    item.id
                                  }/?matter_name=${utf8_to_b64(
                                    matter_name
                                  )}&client_name=${utf8_to_b64(client_name)}`}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>

                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 3.1 Variations */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Variations" &&
                            visibleBriefsList.includes("Variations")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                {tilePulse === "Variations" ? (
                                  <PulsingBorderDiv>
                                    <div
                                      onClick={(e) =>
                                        visitBrief(items.id, items.name)
                                      }
                                    >
                                      <Variations
                                        briefId={items.id}
                                        variationsRef={variationsRef}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          items.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}`}
                                      />
                                    </div>
                                  </PulsingBorderDiv>
                                ) : (
                                  <div
                                    onClick={(e) =>
                                      visitBrief(items.id, items.name)
                                    }
                                  >
                                    <Variations
                                      briefId={items.id}
                                      variationsRef={variationsRef}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        items.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return null;
                        })}

                        {/* 3.2 Provisional Sum */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Provisional Sum" &&
                            visibleBriefsList.includes("Provisional Sum")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                <div
                                  onClick={(e) =>
                                    visitBrief(items.id, items.name)
                                  }
                                >
                                  <ProvisionalSum
                                    briefId={items.id}
                                    provisionalSumRef={provisionalSumRef}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      items.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}

                        {/* 3.3 Prime Cost */}
                        {defaultList?.map((items) => {
                          if (
                            items.name === "Prime Cost" &&
                            visibleBriefsList.includes("Prime Cost")
                          ) {
                            return (
                              <div
                                className={"col-span-1 row-span-1 mb-4"}
                                key={`brief-${items.id}`}
                                data-info={items.id}
                              >
                                <div
                                  onClick={(e) =>
                                    visitBrief(items.id, items.name)
                                  }
                                >
                                  <PrimeCost
                                    briefId={items.id}
                                    primeCostRef={primeCostRef}
                                    briefName={items.name}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      items.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                  />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </div>
                  {/* END OF 2ND AND 3RD COLUMN: ALL PHASES AND CONSTRUCTION */}

                  {/* 2ND COLUMN: PRE CONSTRUCTION */}
                  <div
                    id="2nd-pre-construction"
                    className={
                      activePhase === "Pre Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Email Integration */}
                    {showInbox ? (
                      <div className={"col-span-1  row-span-1 mb-4"}>
                        <EmailInteg
                          width={width}
                          companyId={companyId}
                          redirectUrl={AppRoutes.INBOX}
                        />
                      </div>
                    ) : null}

                    {/* 2. Tasklist */}
                    {showTasklist ? (
                      <div className={"col-span-1 row-span-1 mb-4"}>
                        <TaskList
                          redirectUrl={AppRoutes.TASKS}
                          taskListData={taskListData}
                        />
                      </div>
                    ) : null}

                    {/* 3. Developmental approvals */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            item.name === "Development Approvals" && (
                              <div
                                key={item.id}
                                className={"col-span-1 row-span-1 mb-4"}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(
                                      client_name
                                    )}&url=hba`}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 2ND COLUMN: PRE CONSTRUCTION */}

                  {/* 2ND COLUMN: HANDOVER */}
                  <div
                    id="2nd-handover"
                    className={
                      activePhase === "Handover"
                        ? "col-span-3 sm:col-span-2 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Progress photos (Stretch) */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Progress Photos" &&
                        visibleBriefsList.includes("Progress Photos")
                      ) {
                        return (
                          <div
                            className="col-span-3 md:col-span-3 sm:col-span-1 row-span-1 relative"
                            ref={propertyPhotoRef}
                            id={
                              activePhase === "Handover"
                                ? "ProgressPhoto"
                                : "handover-progress-photos"
                            }
                          >
                            {tilePulse === "ProgressPhoto" ? (
                              <>
                                <ProgressPhotos
                                  progressPhotosDetails={progressPhotosDetails}
                                  setShowRightPanel={setShowRightPanel}
                                  setModalState={setModalState}
                                  modalChoicesEnum={modalChoicesEnum}
                                  progressPhotoBrief={progressPhotoBrief}
                                  handleUploadLink={handleUploadLink}
                                  handleProgressPhotoUpload={
                                    handleProgressPhotoUpload
                                  }
                                  matterId={matterId}
                                  uploadImage={uploadImage}
                                  setUploadImage={setUploadImage}
                                  imageURL={imageURL}
                                  setImageURL={setImageURL}
                                  datePicked={datePicked}
                                  setDatePicked={setDatePicked}
                                  description={description}
                                  setDescription={setDescription}
                                  uploadedFiles={uploadedFiles}
                                  setUploadedFiles={setUploadedFiles}
                                  invalidFiles={invalidFiles}
                                  setInvalidFiles={setInvalidFiles}
                                  selectedFiles={selectedFiles}
                                  // _setSelectedFiles={_setSelectedFiles}
                                  uploadStart={uploadStart}
                                  setUploadStart={setUploadStart}
                                  photos={photos}
                                  setPhotos={setPhotos}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                  handleUpload={handleUpload}
                                  rejectFiles={rejectFiles}
                                  saveButtonClicked={saveButtonClicked}
                                  clientMatterId={matter_id}
                                  isImageFile={isImageFile}
                                  redirectUrl={`${
                                    AppRoutes.BACKGROUND
                                  }/${1}/${matter_id}/${
                                    items.id
                                  }/?matter_name=${utf8_to_b64(
                                    matter_name
                                  )}&client_name=${utf8_to_b64(client_name)}`}
                                />
                                <PulsingBorderDivRelative />
                              </>
                            ) : (
                              <ProgressPhotos
                                progressPhotosDetails={progressPhotosDetails}
                                setShowRightPanel={setShowRightPanel}
                                setModalState={setModalState}
                                modalChoicesEnum={modalChoicesEnum}
                                progressPhotoBrief={progressPhotoBrief}
                                handleUploadLink={handleUploadLink}
                                handleProgressPhotoUpload={
                                  handleProgressPhotoUpload
                                }
                                matterId={matterId}
                                uploadImage={uploadImage}
                                setUploadImage={setUploadImage}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                                datePicked={datePicked}
                                setDatePicked={setDatePicked}
                                description={description}
                                setDescription={setDescription}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                invalidFiles={invalidFiles}
                                setInvalidFiles={setInvalidFiles}
                                selectedFiles={selectedFiles}
                                // _setSelectedFiles={_setSelectedFiles}
                                uploadStart={uploadStart}
                                setUploadStart={setUploadStart}
                                photos={photos}
                                setPhotos={setPhotos}
                                handleChangeGetStartedProgress={
                                  handleChangeGetStartedProgress
                                }
                                handleUpload={handleUpload}
                                rejectFiles={rejectFiles}
                                saveButtonClicked={saveButtonClicked}
                                clientMatterId={matter_id}
                                isImageFile={isImageFile}
                                redirectUrl={`${
                                  AppRoutes.BACKGROUND
                                }/${1}/${matter_id}/${
                                  items.id
                                }/?matter_name=${utf8_to_b64(
                                  matter_name
                                )}&client_name=${utf8_to_b64(client_name)}`}
                              />
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}

                    <div className="grid sm:grid-cols-2 gap-4">
                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 2.1 Defects List */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name === "Defects List" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          item.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}&url=hba`}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}

                        {/* 2.2 Insurance */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name === "Insurance" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          item.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}&url=hba`}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}
                      </div>

                      <div className="col-span-1 sm:col-span-1 row-span-1 mt-4">
                        {/* 3.1. Waranties and certifications */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name ===
                                  "Warranties and Certifications" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          item.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}&url=hba`}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}

                        {/* 3.2 certificates */}
                        {defaultList &&
                          defaultList.length > 0 &&
                          defaultList
                            .filter(
                              (item) =>
                                !excludeFixedTiles.includes(item.name) &&
                                !item.name.startsWith("_")
                            )
                            .map((item) => {
                              return (
                                visibleBriefsList.includes(item.name) &&
                                item.name === "Certificates" && (
                                  <div
                                    key={item.id}
                                    className={
                                      "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                    }
                                  >
                                    {dynamicTileRows[item.id] ? (
                                      <DynamicTiles
                                        briefId={item.id}
                                        tileName={item.name}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          item.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}&url=hba`}
                                        backgrounds={dynamicTileRows[item.id]}
                                      />
                                    ) : null}
                                  </div>
                                )
                              );
                            })}
                      </div>
                    </div>
                  </div>
                  {/* END OF 2ND COLUMN: HANDOVER */}

                  {/* 3RD COLUMN: PRE CONSTRUCTION */}
                  <div
                    id="3rd-pre-construction"
                    className={
                      activePhase === "Pre Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Other dynamic tiles */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            (item.name === "Tenders" ||
                              item.name === "Building Permits" ||
                              item.name === "Insurance") && (
                              <div
                                key={item.id}
                                className={"col-span-1 row-span-1 mb-4"}
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(
                                      client_name
                                    )}&url=hba`}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 3RD COLUMN: PRE CONSTRUCTION */}

                  {/* 4TH COLUMN: ALL PHASES */}
                  <div
                    id="4th-all-phases"
                    className={
                      activePhase === "" ? "col-span-1 row-span-1" : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            id={
                              activePhase === ""
                                ? "ProjectLocation"
                                : "all-phases-project-location"
                            }
                            ref={propertyDetailsRef}
                          >
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                            >
                              {tilePulse === "ProjectLocation" ? (
                                <>
                                  <ProjectLocation
                                    projectLocationBrief={projectLocationBrief}
                                    setProjectLocationBrief={
                                      setProjectLocationBrief
                                    }
                                    userInfo={userInfo}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                    invalidFiles={invalidFiles}
                                    rejectFiles={rejectFiles}
                                    clientMatterId={matter_id}
                                    projectLocationPhoto={projectLocationPhoto}
                                    triggerToast={triggerToast}
                                    modalChoicesEnum={modalChoicesEnum}
                                    setModalState={setModalState}
                                    setShowRightPanel={setShowRightPanel}
                                    isInitDone={isInitDone}
                                    setDefaultList={setDefaultList}
                                    setProjectLocationPhoto={
                                      setProjectLocationPhoto
                                    }
                                  />
                                  <PulsingBorderDivRelative />
                                </>
                              ) : (
                                <ProjectLocation
                                  projectLocationBrief={projectLocationBrief}
                                  setProjectLocationBrief={
                                    setProjectLocationBrief
                                  }
                                  userInfo={userInfo}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                  invalidFiles={invalidFiles}
                                  rejectFiles={rejectFiles}
                                  clientMatterId={matter_id}
                                  projectLocationPhoto={projectLocationPhoto}
                                  triggerToast={triggerToast}
                                  modalChoicesEnum={modalChoicesEnum}
                                  setModalState={setModalState}
                                  setShowRightPanel={setShowRightPanel}
                                  isInitDone={isInitDone}
                                  setDefaultList={setDefaultList}
                                  setProjectLocationPhoto={
                                    setProjectLocationPhoto
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Other dynamic tiles */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) && (
                              <div
                                key={item.id}
                                className={
                                  "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                }
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(
                                      client_name
                                    )}&url=hba`}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}
                  </div>
                  {/* END OF 4TH COLUMN: ALL PHASES */}

                  {/* 4TH COLUMN: CONSTRUCTION */}
                  <div
                    id="4th-construction"
                    className={
                      activePhase === "Construction"
                        ? "col-span-1 row-span-1"
                        : "hidden"
                    }
                  >
                    {/* 1. Project Location */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Project Location" &&
                        visibleBriefsList.includes("Project Location")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            id={
                              activePhase === "Construction"
                                ? "ProjectLocation"
                                : "construction-project-location"
                            }
                            ref={propertyDetailsRef}
                          >
                            <div
                              className={`w-full h-98 shadow-md rounded relative`}
                              style={
                                width < 844
                                  ? {
                                      width: "100%",
                                    }
                                  : null
                              }
                            >
                              {tilePulse === "ProjectLocation" ? (
                                <>
                                  <ProjectLocation
                                    projectLocationBrief={projectLocationBrief}
                                    setProjectLocationBrief={
                                      setProjectLocationBrief
                                    }
                                    userInfo={userInfo}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                    invalidFiles={invalidFiles}
                                    rejectFiles={rejectFiles}
                                    clientMatterId={matter_id}
                                    projectLocationPhoto={projectLocationPhoto}
                                    triggerToast={triggerToast}
                                    modalChoicesEnum={modalChoicesEnum}
                                    setModalState={setModalState}
                                    setShowRightPanel={setShowRightPanel}
                                    isInitDone={isInitDone}
                                    setDefaultList={setDefaultList}
                                    setProjectLocationPhoto={
                                      setProjectLocationPhoto
                                    }
                                  />
                                  <PulsingBorderDivRelative />
                                </>
                              ) : (
                                <ProjectLocation
                                  projectLocationBrief={projectLocationBrief}
                                  setProjectLocationBrief={
                                    setProjectLocationBrief
                                  }
                                  userInfo={userInfo}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                  invalidFiles={invalidFiles}
                                  rejectFiles={rejectFiles}
                                  clientMatterId={matter_id}
                                  projectLocationPhoto={projectLocationPhoto}
                                  triggerToast={triggerToast}
                                  modalChoicesEnum={modalChoicesEnum}
                                  setModalState={setModalState}
                                  setShowRightPanel={setShowRightPanel}
                                  isInitDone={isInitDone}
                                  setDefaultList={setDefaultList}
                                  setProjectLocationPhoto={
                                    setProjectLocationPhoto
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 2. Progress of Work */}
                    {defaultList?.map((item) => {
                      if (
                        item.name === "Progress of work" &&
                        visibleBriefsList.includes("Progress of work")
                      ) {
                        return (
                          <div
                            key={`brief-${item.id}`}
                            className={"col-span-1 row-span-1 mb-4 relative"}
                            data-info={item.id}
                          >
                            {tilePulse === "ProgressOfWork" ? (
                              <PulsingBorderDiv>
                                <div
                                  onClick={() => visitBrief(item.id, item.name)}
                                >
                                  <ProgressOfWork
                                    items={item}
                                    completionDateRef={completionDateRef}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                  />
                                </div>
                              </PulsingBorderDiv>
                            ) : (
                              <div
                                onClick={() => visitBrief(item.id, item.name)}
                              >
                                <ProgressOfWork
                                  items={item}
                                  completionDateRef={completionDateRef}
                                  redirectUrl={`${
                                    AppRoutes.BACKGROUND
                                  }/${1}/${matter_id}/${
                                    item.id
                                  }/?matter_name=${utf8_to_b64(
                                    matter_name
                                  )}&client_name=${utf8_to_b64(client_name)}`}
                                  handleChangeGetStartedProgress={
                                    handleChangeGetStartedProgress
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}

                    {/* 3. Other dynamic tiles */}
                    {defaultList &&
                      defaultList.length > 0 &&
                      defaultList
                        .filter(
                          (item) =>
                            !excludeFixedTiles.includes(item.name) &&
                            !item.name.startsWith("_")
                        )
                        .map((item) => {
                          return (
                            visibleBriefsList.includes(item.name) &&
                            (item.name === "Work Safety Compliance" ||
                              item.name ===
                                "Warranties and Certifications") && (
                              <div
                                key={item.id}
                                className={
                                  "col-span-1 sm:col-span-1 row-span-1 mb-4"
                                }
                              >
                                {dynamicTileRows[item.id] ? (
                                  <DynamicTiles
                                    briefId={item.id}
                                    tileName={item.name}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(
                                      client_name
                                    )}&url=hba`}
                                    backgrounds={dynamicTileRows[item.id]}
                                  />
                                ) : null}
                              </div>
                            )
                          );
                        })}

                    {/* 4. Contracts */}
                    {defaultList?.map((items) => {
                      if (
                        items.name === "Contracts" &&
                        visibleBriefsList.includes("Contracts")
                      ) {
                        return (
                          <div
                            className={"col-span-1 row-span-1 mb-4"}
                            ref={uploadContractsRef}
                            id={
                              activePhase === "Construction"
                                ? "Contracts"
                                : "construction-contracts"
                            }
                          >
                            {tilePulse === "Contracts" ? (
                              <PulsingBorderDiv>
                                <div className="rounded cursor-pointer w-full">
                                  <Contracts
                                    visitBrief={visitBrief}
                                    briefDetails={defaultList.filter((item) => {
                                      return item?.name == "Contracts";
                                    })}
                                    isComplete={
                                      contractTC?.length > 0 &&
                                      archiDrawings?.length > 0 &&
                                      engDrawings?.length > 0 &&
                                      specifications?.length > 0 &&
                                      buildInsurance?.length > 0
                                    }
                                  />
                                </div>
                              </PulsingBorderDiv>
                            ) : (
                              <div className="rounded cursor-pointer w-full">
                                <Contracts
                                  visitBrief={visitBrief}
                                  briefDetails={defaultList.filter((item) => {
                                    return item?.name == "Contracts";
                                  })}
                                  isComplete={
                                    contractTC?.length > 0 &&
                                    archiDrawings?.length > 0 &&
                                    engDrawings?.length > 0 &&
                                    specifications?.length > 0 &&
                                    buildInsurance?.length > 0
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* END OF 4TH COLUMN: CONSTRUCTION */}
                </div>
              </>
            ) : (
              <>
                <div className="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
                  {[...Array(12).keys()].map((data, index) => {
                    return (
                      <div
                        className="col-span-1 sm:col-span-1 row-span-1 bg-gray-100 rounded-lg p-5 hover:shadow-md transition-shadow h-56"
                        key={`loading-${index}`}
                      >
                        <div className="z-20">
                          <div className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none z-20">
                            <div className="dropdown">
                              <button
                                className={
                                  "bg-gray-100 p-0.5 sm:p-0 text-gray-300 sm:text-gray-300 font-semibold rounded inline-flex "
                                }
                                style={{
                                  borderRadius: "50%",
                                }}
                              >
                                <IoIcons.IoEllipsisVertical />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-1">
                              <SkeletonTheme width={"48px"} height={"48px"}>
                                <Skeleton circle={true} />
                              </SkeletonTheme>
                            </div>
                            <br />
                            <br />

                            <div className="col-span-3 grid place-self-end">
                              <Skeleton count={1} />
                            </div>
                          </div>
                          <h4
                            tabIndex="0"
                            className="focus:outline-none text-gray-800 dark:text-gray-100 font-semibold sm:font-bold"
                          >
                            <SkeletonTheme width={"300px"}>
                              <Skeleton count={2} />
                            </SkeletonTheme>
                          </h4>
                          <p
                            tabIndex="0"
                            className="focus:outline-none text-gray-400 sm:text-gray-800 dark:text-gray-100 text-sm mb-3"
                          >
                            <SkeletonTheme width={"250px"}>
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {/* END OF BRIEF TILES */}
          </div>

          {showRightPanel ? (
            <RightPanel
              modalChoicesEnum={modalChoicesEnum}
              setShowRightPanel={setShowRightPanel}
              buildInfo={buildInfo}
              setBuildInfo={setBuildInfo}
              contractInfo={contractInfo}
              setContractInfo={setContractInfo}
              whichModal={modalState}
              progressPhotoBrief={progressPhotoBrief}
              matterId={matterId}
              uploadImage={uploadImage}
              setUploadImage={setUploadImage}
              imageURL={imageURL}
              setImageURL={setImageURL}
              handleUploadLink={handleUploadLink}
              handleProgressPhotoUpload={handleProgressPhotoUpload}
              handleUpload={handleUpload}
              datePicked={datePicked}
              setDatePicked={setDatePicked}
              description={description}
              setDescription={setDescription}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              logoURL={logoURL}
              setLogoURL={setLogoURL}
              clientMatterId={matter_id}
              saveButtonClicked={saveButtonClicked}
              handleChangeGetStartedProgress={handleChangeGetStartedProgress}
              handleLogoDrop={handleLogoDrop}
              builderLogo={builderLogo}
              setBuilderLogo={setBuilderLogo}
              logo={logo}
              setLogo={setLogo}
              selectedFiles={selectedFiles}
              // setSelectedFiles={setSelectedFiles}
              // _setSelectedFiles={_setSelectedFiles}
            />
          ) : (
            <></>
          )}
        </div>
        {/* END OF MAIN DIV */}
      </div>

      {/* {isInitializingPortal && (
        <div className="fixed flex w-screen h-screen left-0 top-0 right-0 bottom-0 z-50 cursor-wait">
          <div className="absolute w-full h-full bg-black opacity-60 z-0" />
          <div
            className={`m-auto text-white z-10 font-medium text-center ${
              isMobileDevice(width) ? "text-lg" : "text-4xl"
            }`}
          >
            We're loading your Dashboard...
          </div>
        </div>
      )} */}
    </>
  ) : null;
}

export const PreCostruction = ({}) => {};
