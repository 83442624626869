const isMSdoc = function (file) {
  file = file.substring(file.lastIndexOf(".") + 1);
  switch (file) {
    // word
    case "asd":
      return true;
    case "doc":
      return true;
    case "svd":
      return true;
    case "wbk":
      return true;
    case "dot":
      return true;
    case "docx":
      return true;
    case "docm":
      return true;
    case "dotm":
      return true;
    case "dotx":
      return true;

    // excel
    case "xls":
      return true;
    case "xlt":
      return true;
    case "xla":
      return true;
    case "xll":
      return true;
    case "xar":
      return true;
    case "xlsb":
      return true;
    case "xlc":
      return true;
    case "xslb":
      return true;
    case "xlm":
      return true;
    case "xlam":
      return true;
    case "xlsm":
      return true;
    case "xltm":
      return true;
    case "xlsx":
      return true;
    case "xltx":
      return true;
    case "xl":
      return true;
    case "xlb":
      return true;
    case "xlw":
      return true;

    //powerpoint
    case "ppa":
      return true;
    case "pps":
      return true;
    case "ppt":
      return true;
    case "pot":
      return true;
    case "pa":
      return true;
    case "ppam":
      return true;
    case "ppsm":
      return true;
    case "pptm":
      return true;
    case "sldm":
      return true;
    case "potm":
      return true;
    case "ppsx":
      return true;
    case "pptx":
      return true;
    case "potx":
      return true;
    case "sldx":
      return true;

    default:
      return false;
  }
};

export default isMSdoc;
