import React, { useState, useRef, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import isMSdoc from "../../shared/msFileTypeChecker";
import { LuUploadCloud } from "react-icons/lu";
import RemoveFileModal from "../file-bucket/remove-file-modal";
import UploadFilesModal from "./upload-modal-mobile";
import { updateBackgroundOrders } from "../../shared/graphql/Backgrounds/mutations";
import { useHistory } from "react-router-dom";
import ToastNotification from "../toast-notification";

const ContractsContentComponent = ({
  background,
  style,
  readMoreState,
  isReadMoreExpanded,
  setSaving,
  getRowLabels,
  handleDescContent,
  handleReadMoreState,
  handleChangeDesc,
  handleSaveDesc,
  handleSnippetEmail,
  virtualRows,
  rows,
  getBackground,
  brief_id,
  briefName,
  sortByOrder,
  setBackground,
  setPreviousBackground,
  matter_id
}) => {
//   const [openMoreLabels, setOpenMoreLabels] = useState(false);
//   const [openMoreLabelsIndex, setOpenMoreLabelsIndex] = useState(null);
// Initialize isExpanded state with an array of the same length as visibleFiles, filled with false values
const [isExpanded, setIsExpanded] = useState(false);
const [isIndexExpanded, setIsIndexExpanded] = useState(null);
const [showRemoveFileModal, setshowRemoveFileModal] = useState(false);
const [selectedRowsBG, setSelectedRowsBG] = useState([]);
const [showUploadModal, setShowUploadModal] = useState(false);
const [uploadURL, setUploadURL] = useState(null);
const [selectedFiles, _setSelectedFiles] = useState([]);
const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
const [selectedRowId, setSelectedRowId] = useState("");
const [showToast, setShowToast] = useState(false);
const [alertMessage, setalertMessage] = useState("");

  const uploadAndCheck = async () => {
    if (
      uploadedFiles.files.length === selectedFiles.length &&
      selectedFiles.length !== 0
    ) {
      const result = await handleUploadLink(uploadedFiles, brief_id);
    }
  };

  useEffect(() => {
    uploadAndCheck();
  }, [selectedFiles, uploadedFiles]);

  const myCurrentRef = useRef(selectedFiles);
  const setSelectedFiles = (data) => {
    myCurrentRef.current = data;
    _setSelectedFiles(data);
  };

  // Function to handle expansion toggle
	const handleExpandToggle = (index) => {
		setIsExpanded({
			...isExpanded,
			[index]: !isExpanded[index]
		});
	};
  

  const handleUpload = (id) => {
    console.log("Upload clicked");
	setShowUploadModal(true);
	setSelectedRowId(id);
  };

  const handleDelete = (item) => {
    const mDeleteBackground = `
			mutation untagBriefBackground($briefId: ID, $background: [BackgroundInput]) {
				briefBackgroundUntag(briefId: $briefId, background: $background) {
					id
				}
			}
		`;

    // Convert single object to array
    const itemsArray = Array.isArray(item) ? item : [item];

    const resultId = itemsArray?.map(({ id }) => ({
      id: id,
    }));

    const deletedId = API.graphql({
      query: mDeleteBackground,
      variables: {
        briefId: brief_id,
        background: resultId,
      },
    }).then(async (res) => {
		getBackground();
		setSelectedRowsBG([]);
		setshowRemoveFileModal(false);
    });
  };

  const handleDownloadAll = () => {
    // Iterate over each object in the array
    background?.forEach((obj) => {
      // Check if the object has a files property and if it's not null
      if (obj.files && obj.files.items) {
        // Iterate over each file item in the files.items array
        obj.files.items.forEach((file) => {
          // Access and log the ID of each file item
          console.log(file.id);
          previewAndDownloadFile(file.id);
        });
      }
    });
  };

  const qGetFileDownloadLink = `
	query getFileDownloadLink($id: ID) {
		file(id: $id) {
		downloadURL
		s3ObjectKey
		type
		}
	}`;

  const previewAndDownloadFile = async (id) => {
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  const handleModalClose = () => {
    setshowRemoveFileModal(false);
  };

  const handleDeleteModal = (backgroundItem) => {
	const backgroundArray = Array.isArray(backgroundItem) ? backgroundItem : [backgroundItem];
  
	const deleteId = backgroundArray?.map((item, index) => ({
	  id: item.id,
	  files: item.files,
	  order: index + 1,
	}));
  
	setSelectedRowsBG(deleteId);
	setshowRemoveFileModal(true);
  }

  var moment = require("moment");
  const history = useHistory();

  const mCreateBackground = `
	mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
	backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
		id
		createdAt
		date
		description
		order
	}
	}
	`;

  const handleAddRow = async () => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: brief_id, name: briefName }];
      console.log(tempBriefDetails);

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
        },
      });

      if (createBackgroundRow) {
        const result = {
          createdAt: dateToday,
          id: createBackgroundRow.data.backgroundCreate.id,
          description: "",
          date: null,
          order: 0,
          files: { items: [] },
          labels: { items: [] },
          briefs: [{ id: brief_id, name: briefName }],
        };

        setBackground((background) => sortByOrder(background.concat(result)));
        setPreviousBackground((background) =>
          sortByOrder(background.concat(result))
        );
        background.splice(0, 0, result);

        const rowArrangement = background?.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement);

        // For FE purposes: Update existing background array with the updated order data
        background.forEach((item, index) => {
          item.order = index + 1;
        });

        setBackground(background);
        setPreviousBackground(background);
        // autoAdjustAllRow();

        // Added .5 secs delay for saving updated arrangement of rows before refreshing the page
        // Removed for MMA 2309
        // setTimeout(() => {
        //   //** ADDED WINDOW RELOAD TO SIMULATE REFETCHING OF BACKGROUND AFTER SAVING DESC */
        //   window.location.reload();
        // }, 500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Upload Function */

  const handleUploadModalClose = () => {
    setShowUploadModal(false);
  };

  const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; //list of rejected files

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    handleNewUpload(files);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files
    handleNewUpload(files);
  }

  const handleNewUpload = (files) => {
    if (!files || !files.length) return;
    console.log("FILES!!!!", files)

    var file = files[0];
    console.log("RETRIEVED FILE", file);

    let objUrl = URL.createObjectURL(file)

    const tempArr = [];

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[0];

    const result = rejectFiles.find((item) =>
      item.includes(re.exec(file.name)[0])
    );
    const fileSize = file.size;

	setUploadURL(file.name);
    
	tempArr.push({
		data: file,
		url: objUrl,
	});

    setSelectedFiles([...myCurrentRef.current, ...tempArr]);
  }

  const handleSave = async (matter_id) => {
    try {
      if (!selectedFiles || !Array.isArray(selectedFiles)) {
        console.error("Invalid or missing selectedFiles array");
        return;
      }
  
      var tempArr = [];
      var temp = [];
      var idxx = 0;
  
      selectedFiles.forEach((uf) => {
        if (uf && uf.data) {
          tempArr = [...tempArr, uf];
        } else {
          console.error("Invalid or missing data for file in selectedFiles:", uf);
        }
      });
  
      setSelectedFiles(tempArr);
      _setSelectedFiles(tempArr);
  
      for (const uf of tempArr) {
        if (!uf || !uf.data) {
          console.error("Invalid or missing data for file:", uf);
          continue; // Skip to the next iteration
        }
  
        if (uf.data.name.split(".").pop() == "docx") {
          var name = uf.data.name,
            size = uf.data.size,
            type =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            key = `${matter_id}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        } else {
          var name = uf.data.name,
            size = uf.data.size,
            type = uf.data.type,
            key = `${matter_id}/${Number(new Date())}${name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        }
        idxx = idxx + 1;
  
        try {
          await Storage.put(key, uf.data, {
            contentType: type,
            progressCallback(progress) {
              const progressInPercentage = Math.round(
                (progress.loaded / progress.total) * 100
              );
  
              if (temp.length > selectedFiles.length) {
                for (var i = 0; i < selectedFiles.length; i++) {
                  if (temp[i].name === uf.data.name) {
                    temp[i].prog = progressInPercentage;
                  }
                }
              } else {
                temp = [
                  ...temp,
                  { prog: progressInPercentage, name: uf.data.name },
                ];
              }
              console.log(temp);
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          })
            .then((fd) => {
              var fileData = {
                s3ObjectKey: fd.key,
                size: parseInt(size),
                type: type,
                name: name.split(".").slice(0, -1).join("."),
                oderSelected: orderSelected,
                order: orderSelected,
              };

			  setUploadedFiles((prevState) => ({
                files: [...prevState.files, fileData],
              }));
            })
            .catch((err) => {
              console.error("Unexpected error while uploading", err);
            });
        } catch (e) {
          const response = {
            error: e.message,
            errorStack: e.stack,
            statusCode: 500,
          };
          console.error("Unexpected error while uploading", response);
        }
      }
    } catch (error) {
      console.error("Unexpected error in handleUpload", error);
    }
  };

  const handleUploadLink = async (uf, brief) => {
    
    var uploadedFiles = uf.files?.map((f) => ({ ...f, matterId: matter_id }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles?.map((x) => ({ ...x, order: 0 }));
    // console.log("SF",sortedFiles);
    // console.log("AO", addOrder);

    //insert in matter file list
    bulkCreateMatterFile(addOrder);

    setShowToast(true);
	setalertMessage(
		`File has been uploaded!`
	);
  };


  const mBulkCreateMatterFile = `
	mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
	matterFileBulkCreate(files: $files) {
		id
		name
		order
	}
	}
	`;

	var idTag = [];

	async function bulkCreateMatterFile(param) {
		param.forEach(function (i) {
			delete i.oderSelected; // remove orderSelected
		});

		setTimeout(async () => {
			const request = await API.graphql({
				query: mBulkCreateMatterFile,
				variables: {
					files: param,
				},
			});

			console.log("result", request);

			if (request.data.matterFileBulkCreate !== null) {
				request.data.matterFileBulkCreate.map((i) => {
					return (idTag = [...idTag, { id: i.id }]);
				});
			}

			console.log("iDTag", idTag);

			const BACKGROUNDFILE_TAG_MUTATION = `
      mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
          backgroundFileTag(backgroundId: $backgroundId, files: $files) {
            id
          }
        }
      `;

			//append in existing
			const qlistBackgroundFiles = `
    query getBackgroundByID($id: ID) {
      background(id: $id) {
        id
        files {
          items {
            createdAt
            id
            details
            name
            order
          }
        }
        labels {
          items {
            id
            name
          }
        }
      }
    }`;

			let arrFiles = [];
			let arrFileResult = [];
			const seen = new Set();

			// console.log("MID/BID", background_id);

			const backgroundFilesOpt = await API.graphql({
				query: qlistBackgroundFiles,
				variables: {
					id: selectedRowId,
				},
			});

			console.log("CHECKDATA", backgroundFilesOpt);

			if (backgroundFilesOpt.data.background.files !== null) {
				arrFileResult = backgroundFilesOpt.data.background.files.items.map(
					({ id }) => ({
						id: id,
					})
				);

				idTag.push(...arrFileResult);
				console.log("updatedidtag", idTag);

				const filteredArr = idTag.filter((el) => {
					const duplicate = seen.has(el.id);
					seen.add(el.id);
					return !duplicate;
				});

				console.log("rowid", selectedRowId);

				API.graphql({
					query: BACKGROUNDFILE_TAG_MUTATION,
					variables: {
						backgroundId: selectedRowId,
						files: filteredArr,
					},
				});
			} else {
				API.graphql({
					query: BACKGROUNDFILE_TAG_MUTATION,
					variables: {
						backgroundId: selectedRowId,
						files: idTag,
					},
				});
			}
			getBackground();
			setShowUploadModal(false);
			setUploadURL(null);
			setShowToast(false);
			setalertMessage("");
			setUploadedFiles({ files: [] });
			_setSelectedFiles([]);
      		setSelectedFiles([]);
		}, 1000);
	}

  return (
    <div>
      {/* Row for download and upload buttons */}
      <div className="flex justify-between items-center px-5 mb-4">
        <button
          className="hover:bg-gray-700 font-medium py-1.5 px-3 rounded flex-grow-0"
          style={{ width: "calc(50% - 2px)", border: "0.5px solid gray" }}
          onClick={() => handleDownloadAll()}
        >
          Download All
        </button>
        <div className="w-4"></div> {/* Add a spacer */}
        <button
          className="hover:bg-blue-700 text-white font-medium py-2 px-4 rounded flex-grow-0"
          style={{
            width: "calc(50% - 2px)",
            backgroundColor: "#133352",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
		  onClick={handleAddRow}
        >
          {/* <LuUploadCloud style={{ marginRight: "0.5rem" }} /> Upload */}
		  Add New
        </button>
      </div>

      <hr className="mb-4" />
      {virtualRows?.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const item = row.original;
        const index = row.index;

        const numAttachments = item.files.items.length;
  
		// Filter visible files based on expanded state
		const visibleFiles = isExpanded[index] ? item.files.items : item.files.items.slice(0, 1);
		const hiddenFilesCount = numAttachments - visibleFiles.length;

        let fileList = [];
        item?.files?.items?.forEach((row) => {
          if (row.gmailMessage?.gmailLabelIds?.includes("SENT")) {
            fileList.push("Sent");
          } else if (row.gmailMessage?.gmailLabelIds?.includes("INBOX")) {
            fileList.push("Inbox");
          } else if (row.gmailMessage === null || row.gmailMessageId === "") {
            fileList.push("Uploaded");
          }
        });
        fileList = fileList.filter(
          (item, index) => fileList.indexOf(item) === index
        );

        let gmailStatus = fileList.join(" / ");
        if (gmailStatus === "") {
          gmailStatus = item?.files?.items?.length === 0 ? "N/A" : "Uploaded";
        }

        return (
          <div
            key={item.id}
            className="flex flex-row w-full px-5"
            style={{
              ...style,
              width: "100%",
              borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
              borderBottomStyle: "solid",
              paddingTop: index === 0 ? 0 : 20,
              paddingBottom: 20,
            }}
          >
            {/* Checkbox */}
            <input type="checkbox" className="mr-2" />

            <div className="w-full ml-2">
              {/* Date */}
              {/* <p className="text-xs pb-1">
                {item.date !== null && item.date !== ""
                  ? dateFormatter(item.date)
                  : "No date"}
              </p> */}

              {/* Description */}
              <p
                id={item.id + ".descM"}
                className={`w-full overflow-y-auto pb-1 ${
                  isReadMoreExpanded(item.id)
                    ? "text-xs"
                    : "line-clamp-6 text-sm font-normal"
                }`}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
                style={{
                  wordBreak: "break-word",
                  outlineColor: "rgb(204, 204, 204, 0.5)",
                  outlineWidth: "thin",
                }}
                contentEditable={true}
                suppressContentEditableWarning
                onClick={(event) => {
                  handleDescContent(event, item.description, item.id, index);
                  setSaving(true);
                }}
                onInput={(event) => handleChangeDesc(event, index)}
                onBlur={(e) => {
                  handleSaveDesc(
                    e,
                    item.description,
                    item.date,
                    item.id,
                    index
                  );
                  setSaving(false);
                }}
              ></p>

			{/* Files List */}
			<div key={item.id} className="flex flex-row w-full px-5">
				<div className="text-left">
					{visibleFiles?.map((file, index) => (
					<div key={file.id} className="items-left">
						<button
						className="font-extralight text-sm focus:text-cyan-400 focus:border-cyan-400 rounded-lg pt-1 pb-1 flex-1"
						onClick={() => handleSnippetEmail(file)}
						>
						<span className="font-bold text-xs">
							{file.name.length > 25 ? file.name.slice(0, 25) + "..." : file.name}
							&nbsp;
						</span>
						</button>
					</div>
					))}
				</div>
				{/* Expand/Collapse Buttons */}
				{numAttachments > 1 && (
				<div>
					<button
					className="text-sm focus:text-cyan-400 focus:border-cyan-400 rounded-lg px-1 mt-1"
					onClick={() => handleExpandToggle(index)}
					>
					{isExpanded[index] ? "-" : "+"}
					</button>
					{hiddenFilesCount > 0 && (
					<span className="text-sm">{hiddenFilesCount}</span>
					)}
				</div>
				)}
			</div>

              {/* Labels List */}
              <div>
                {/* <div className="flex items-center mt-2">
                  <PiChartLineUpBold className="mr-2" />
                  <p className="text-xs break-words mr-2 rounded-full p-1 border-2 bg-gray-50">
                    {gmailStatus}
                  </p>

                  <button
                    className="w-full"
                    onClick={(e) => {
                      setOpenMoreLabels(!openMoreLabels);
                      setOpenMoreLabelsIndex(index);
                    }}
                  >
                    <div id={"label-cell-selected-no-" + index} className="text-xs flex flex-wrap">
                      {item?.briefs &&
                        item?.briefs?.length !== 0 &&
                        item?.briefs?.map((elem, innerIndex) =>
                          innerIndex < 3 ? (
                            <span
                              key={innerIndex}
                              className="mx-1 text-green-800 bg-green-100 rounded-full text-xs mt-1"
                              style={{
                                gap: "8px",
                                padding: "4px 8px",
                              }}
                            >
                              {getRowLabels(item?.briefs)[innerIndex].label}
                            </span>
                          ) : null
                        )}
                      {item?.briefs?.length > 3 && (
                        <span
                          className="mx-1 text-green-800 bg-green-100 rounded-full text-xs mt-1"
                          style={{
                            gap: "8px",
                            padding: "4px 8px",
                          }}
                        >
                          +{item?.briefs?.length - 3}
                        </span>
                      )}
                    </div>
                  </button>
                </div> */}

                <div className="flex items-center mt-2">
                  <span className="text-sm" onClick={() => handleUpload(item.id)} >
                    Upload
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span
                    className="text-sm text-red-700"
                    onClick={() => handleDeleteModal(item)}
                  >
                    Delete
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}

		{showToast && <ToastNotification title={alertMessage} error={false} />}

		{showRemoveFileModal && (
			<RemoveFileModal
			handleSave={handleDelete}
			handleModalClose={handleModalClose}
			selectedRowsBG={selectedRowsBG}
			/>
		)}

		{showUploadModal && (
			<UploadFilesModal
				pageName={"Contracts"}
				handleUploadModalClose={handleUploadModalClose}
				// setShowEdit={setShowEdit}
				// handleDrop={handleDrop}
				// handleDragOver={handleDragOver}
				// projectPhotoURL={projectPhotoURL}
				handleSave={handleSave}
				// setProjectAddress={setProjectAddress}
				// tempAddress={tempAddress}
				// setTempAddress={setTempAddress}
				uploadURL={uploadURL}
				setUploadURL={setUploadURL} 
				handleFileUpload={handleFileUpload}
				matter_id={matter_id}
			/>
		)}
    </div>
  );
};

export default ContractsContentComponent;
