import React, { useState, useEffect, useRef, useReducer } from "react";
import anime from "animejs";
import { addClientMatter } from "../dashboard/actions";
import { clientMatterReducers } from "../dashboard/reducers";
import { initialState } from "../dashboard/initialState";
import { toast } from "react-hot-toast";
import CreatableSelect from "react-select/creatable";
import { API } from "aws-amplify";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import { CgMathPlus, CgClose } from "react-icons/cg";
import Select from "react-select";
import { ListClients, ListMatters } from "../../shared/graphql/queries";
export default function AddMatterModal({
  close,
  //fetchCompanyClientMatters,
  //   setValueCopy,
  clientMatters,
  getMatters,
  setAddedMatter,
  matters,
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);

  const [cmModal, setcmModal] = useState("");
  const [clientsOptions, setClientsOptions] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [inputSaveClient, setSaveClient] = useState("");
  const [inputClient, setInputClient] = useState("");

  const [mattersOptions, setMattersOptions] = useState();
  const [selectedMatter, setSelectedMatter] = useState();
  const [inputSaveMatter, setSaveMatter] = useState("");
  const [inputMatter, setInputMatter] = useState("");
  const [clientName, setclientName] = useState(null);
  const [matterName, setmatterName] = useState(null);
  const [show, setshow] = useState(false);
  const [matterlist, dispatch] = useReducer(clientMatterReducers, initialState);
  const [count, setcount] = useState(0);
  const companyId = localStorage.getItem("companyId");

  const { height, width } = useWindowDimensions();
  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const listClient = `
query listClient($companyId: String) {
  company(id: $companyId) {
    clients {
      items {
        id
        name
      }
    }
  }
}
`;

  const listMatter = `
query listMatter($companyId: String) {
  company(id: $companyId) {
    matters {
      items {
        id
        name
      }
    }
  }
}
`;

  const addClient = `
mutation addClient($companyId: String, $name: String) {
    clientCreate(companyId:$companyId, name:$name) {
        id
        name
    }
}
`;

  const addMatter = `
mutation addMatter($companyId: String, $name: String) {
    matterCreate(companyId:$companyId, name:$name) {
        id
        name
    }
}
`;

  const ExitButton = ({ close }) => {
    return (
      <button
        onClick={() => {
          close();
        }}
        className="h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300 absolute top-5 right-5"
      >
        <CgClose />
      </button>
    );
  };

  const addClients = async (data) => {
    let result;

    const companyId = localStorage.getItem("companyId");

    const addedClientList = await API.graphql({
      query: addClient,
      variables: {
        companyId: companyId,
        name: data,
      },
    });

    result = [addedClientList.data.clientCreate].map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    setclientName(result[0]);
  };

  const addMatters = async (data) => {
    let result;

    const companyId = localStorage.getItem("companyId");

    const addedMatterList = await API.graphql({
      query: addMatter,
      variables: {
        companyId: companyId,
        name: data,
      },
    });

    result = [addedMatterList.data.matterCreate].map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    setmatterName(result[0]);
  };

  const Clients = async () => {
    let result;

    const clientsOpt = await ListClients(companyId)

    // const clientsOpt = await API.graphql({
    //   query: listClient,
    //   variables: {
    //     companyId: companyId,
    //   },
    // });

    if (clientsOpt.data.company.clients.items !== null) {
      result = clientsOpt.data.company.clients.items.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }

    var filtered = result?.filter(function (el) {
      return el.label != null && el.value != null;
    });

    setClientsOptions(filtered?.sort((a, b) => a.label - b.label));
  };

  const Matters = async () => {
    let result;

    const mattersOpt = await ListMatters(companyId)

    // const mattersOpt = await API.graphql({
    //   query: listMatter,
    //   variables: {
    //     companyId: companyId,
    //   },
    // });

    if (mattersOpt.data.company.matters.items !== null) {
      result = mattersOpt.data.company.matters.items.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }

    var filtered = result?.filter(function (el) {
      return el.label != null && el.value != null;
    });

    setMattersOptions(filtered?.sort((a, b) => a.label - b.label));
  };

  const handleNewMatter = async () => {
    let client = {
        id: clientName.value,
        name: clientName.label,
      },
      matter = {
        id: matterName.value,
        name: matterName.label,
      };

    console.log("LIST", clientMatters);

    // This part of the code is where we check if there is already an exisiting client/matter card
    let compare = `${clientName?.label}/${matterName?.label}`;
    console.log(compare)
    const duplicate = clientMatters.some((clientMatter) => {
      // Modified last August 10, 2023
      // MMA-1947 Contact: Ability to create duplicating Client/Matter in the Matters tab
      // fix was to change matter?.label to ${clientMatter?.client?.name}/${clientMatter?.matter?.name}
      // since matter.label is always default to null
      if (`${clientMatter?.client?.name}/${clientMatter?.matter?.name}`=== compare) {
        return true;
      } else {
        return false;
      }
    });

    //Add toast + refresh data
    if (duplicate) {
      toast.error(
        `  Client ${client.name} and Matter ${matter.name} already exists`
      );
    } else {
      const newClientMatterId = await addClientMatter(
        client,
        matter,
        companyId,
        localStorage.getItem("userId"),
        dispatch
      );

      //   setValueCopy(newClientMatterId);
      //setcmModal("");
      /* setTimeout(() => {
        //fetchCompanyClientMatters();
        getMatters();
      }, 1500); */

      toast.success(
        `Successfully added Client ${clientName?.label} and Matter ${matterName?.label}`
      );
      Clients();
      Matters();
      getMatters();
      //initContacts(matters[0]);
      //setAddedMatter(true);
    }
  };

  const handleClientChanged = (newValue) => {
    console.log(newValue);
    if (newValue?.__isNew__) {
      addClients(newValue.label);
    } else {
      setclientName(newValue);
    }
  };

  const handleMatterChanged = (newValue) => {
    if (newValue?.__isNew__) {
      addMatters(newValue.label);
    } else {
      setmatterName(newValue);
    }
  };

  useEffect(() => {
    Clients();
    Matters();
  }, [companyId, dispatch]);

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="p-10 flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen overflow-y-auto relative"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-row">
            <div className="font-semibold text-gray-900 text-lg pb-2">
              Add Matter
            </div>
            <button
              onClick={() => {
                close();
              }}
              className="h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300 absolute top-5 right-5"
            >
              <CgClose />
            </button>
          </div>
          <div className="text-gray-900 pb-2">
            Add Client and Matter
          </div>

          <div
            className={
              width > 844
                ? "flex flex-row items-center justify-between"
                : "flex flex-col items-center "
            }
          >
            <div className="m-2 w-full">
              <CreatableSelect
                options={clientsOptions}
                isClearable
                isSearchable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                onChange={
                  handleClientChanged 
                }
                value={selectedClient}
                className="relative bg-white rounded border-0 shadow focus:outline-none focus:ring ring-blue-500 w-72"
                placeholder={
                  inputSaveClient !== null && inputSaveClient !== ""
                    ? inputSaveClient
                    : "Client"
                }
                inputValue={inputClient}
                onInputChange={setInputClient}
                onMenuClose={() => setSaveClient(inputClient)}
                onFocus={(e) => {
                  setInputClient(inputSaveClient);
                  setSaveClient("");
                }}
                blurInputOnSelect
              />
              {/* <Select
                options={clientsOptions}
                isClearablelearable
                isSearchable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                onChange={(e) => handleClientChanged(e[0])}
                onDropdownOpen={(e) => {
                  setInputClient(inputSaveClient);
                  setSaveClient("");
                }}
                value={selectedClient}
                className="relative bg-white rounded border-0 shadow focus:outline-none focus:ring ring-blue-500 w-72"
                placeholder={
                  inputSaveClient !== null && inputSaveClient !== ""
                    ? inputSaveClient
                    : "Client"
                }
                create
                onCreateNew={(e) => {
                  console.log("NEW ", e);
                  console.log("Created, ", {
                    label: e.label,
                    value: e.value,
                    __isNew__: true,
                  });
                  handleClientChanged({
                    label: e.label,
                    value: e.value,
                    __isNew__: true,
                  });
                }}
                
              /> */}
            </div>
            <div className="m-2 w-full">
              <CreatableSelect
                options={mattersOptions}
                isClearable
                isSearchable
                onChange={handleMatterChanged}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                value={selectedMatter}
                className="w-72 relative bg-white rounded border-0 shadow focus:outline-none focus:ring ring-blue-500"
                placeholder={
                  inputSaveMatter !== null && inputSaveMatter !== ""
                    ? inputSaveMatter
                    : "Matter"
                }
                inputValue={inputMatter}
                onInputChange={setInputMatter}
                onMenuClose={() => setSaveMatter(inputMatter)}
                onFocus={() => {
                  setInputMatter(inputSaveMatter);
                  setSaveMatter("");
                }}
                blurInputOnSelect
              />
              {/* <Select
                options={mattersOptions}
                isClearable
                isSearchable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                onChange={(e) => handleMatterChanged(e[0])}
                value={selectedMatter}
                className="w-72 relative bg-white rounded border-0 shadow focus:outline-none focus:ring ring-blue-500"
                placeholder={
                  inputSaveMatter !== null && inputSaveMatter !== ""
                    ? inputSaveMatter
                    : "Matter"
                }
                inputValue={inputMatter}
                onDropdownOpen={() => {
                  setInputMatter(inputSaveMatter);
                  setSaveMatter("");
                }}
                keepSelectedInList={true}
                create
                onCreateNew={(e) => {
                  console.log("NEW ", e);
                  console.log("Created, ", {
                    label: e.label,
                    value: e.value,
                    __isNew__: true,
                  });
                  handleMatterChanged({
                    label: e.label,
                    value: e.value,
                    __isNew__: true,
                  });
                }}
              /> */}
            </div>
          </div>

          <button
            disabled={matterName === null || clientName === null}
            onClick={() => {
              handleNewMatter();
              close();
            }}
            className={`ml-auto mt-8 mr-auto text-white font-bold p-2 lg:px-4 rounded flex gap-2 items-center justify-center ${
              matterName === null || clientName === null
                ? "bg-blue-200 cursor-not-allowed"
                : "bg-blue-500"
            }`}
          >
            <span className="hidden lg:block font-bold">Add</span>
            <CgMathPlus size={20} />
          </button>
        </div>
      </div>
    </>
  );
}
