import React ,{useState,useEffect} from "react";
import ReactMarkdown from "react-markdown";
import { HiMinus } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
const ResearchOutput = ({
    setPhase,
    response,
    textareas,
    setTextareas,
    handleTextareaChange,
    handleResearchPrompt,
    imgURL,
    isLoading,
    setIsLoading,
    handleDeleteTextarea,
    deleteIsClicked,
    setDeleteIsClicked,
}) => {
    const [createPrompt,setCreatePrompt] = useState(false);
    const addPrompt = () => {
        setTextareas(prevTextareas => [
        ...prevTextareas,
        {
            id: prevTextareas.length+1,
            value: '',
        },
        ]);
    };

    const truncateUrl = (url) => {
        return url.length > 75 ? url.substring(0, 60) + "..." : url;
    }

    const formatResponse = (inputString) => {
        // Align links to the left
        const alignLinksToCenter = (str) => str.replace(/(<a.+?>.+?<\/a>)/g, '<div style="text-align: center; display: flex; flex-direction: column;">$1 \n</div> \n');
      
        // Bold identifiers
        const boldIdentifiers = (str) => {
          const identifiers = ['Image Suggestion:', 'Short Description:', 'Alt Text Suggestion:'];
          return identifiers.reduce((result, identifier) => {
            const pattern = new RegExp(`(${identifier})`, 'g');
            return result.replace(pattern, '<b>$1</b>');
          }, str);
        };
      
        // Bold enumerations
        const boldEnumerations = (str) => str.replace(/(\d+\.\s.*?:)/g, '<div style="text-align: center;"><b>$1</b> \n</div> \n');
      
        // Convert links to <a> tags with blue color and align to the left
        const convertLinks = (str) => str.replace(/((?:\d+\.|a\.)?(?:https?:\/\/|www\.)[^\n]+)/g, '<a href="$1" target="_blank" style="color: blue;">$1</a> \n');
      
        // Apply formatting
        const formattedString = convertLinks(boldEnumerations(boldIdentifiers(alignLinksToCenter(inputString))));
      
        // Render the formatted string as HTML
        return formattedString;
      };

    const textareaStyle = {
        width: '75%',
        height: "80px",
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        resize: 'none',
      };
      useEffect(() => {
        if(textareas !== null || textareas !== undefined ){
            setTextareas([]);
        }
      },[])
    return (

        <div
            className="justify-center items-center mt-8 flex flex-col z-50"
            style={{
                width: "65vw",
                borderRadius: '8px',
                padding: "10px",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}
            >
            <div className="justify-center items-center flex flex-col ">
                <b style={{
                fontSize: '40px',
                fontStyle: "italic",
                color: "rgba(220, 87, 51, 1)"
                }}>
                Research Prompt Output
                </b>
            </div>
            <div
                className="justify-center items-center flex flex-col border-2 border-gray-300 px-12"
                style={{
                borderRadius: '8px',
                }}
            >
                <div
                  className="justify-center items-center flex flex-col px-12 py-12"
                  dangerouslySetInnerHTML={{ __html: formatResponse(response) }}
                >
                    {/* <ReactMarkdown>{response}</ReactMarkdown> */}
                </div>
                {imgURL !== null ?
                <div
                    className="justify-center items-center flex flex-col -mt-2"
                >
                    <img src={imgURL}
                    alt=""
                    style={{
                        width: '780px',
                        height: '550px',
                    }}
                    className="py-12"
                    />
                    <div className="justify-center items-center flex flex-row pb-8">
                        Image URL:
                        {imgURL !== null ? (
                            <a href={imgURL} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', fontSize: 'small',overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {truncateUrl(imgURL)}
                            </a>
                        ) : null}
                    </div>
                </div> : null
                }
            </div>

            {textareas.map(textarea => (
                <div key={textarea.id} className="justify-center items-center flex flex-row mt-4" style={{ width : "100%"}}>
                <textarea
                    placeholder="Research Prompt"
                    value={textarea.value}
                    className="mb-8 mr-5"
                    onChange={(e) => handleTextareaChange(textarea.id, e.target.value)}
                    style={textareaStyle}
                />
                <button
                    onClick={() =>
                        handleDeleteTextarea(textarea.id)
                    }
                    className="-mt-9"
                    style={{
                        fontSize: '1.5rem',
                        color: !deleteIsClicked ? '#ffffff' : '#A0AEC0', 
                        backgroundColor: !deleteIsClicked ? '#E53E3E' : 'transparent', 
                        padding: '8px', 
                        borderRadius: '10%', 
                        cursor: 'pointer', 
                        }}
                >
                    <HiMinus
                        data-tip="Delete Prompt"
                    />
                    <ReactTooltip/>
                </button>
            </div>
            ))}
            <button
                className="w-min text-green-400 border my-2 border-green-400 px-4 py-2 mt-3 rounded-md"
                onClick={addPrompt}
            >
                Add Prompt
            </button>
            <button
                className="w-min text-cyan-400 border border-cyan-400 px-4 py-2 mt-3 rounded-md"
                onClick={() => handleResearchPrompt()}
            >
                Execute Prompt
            </button>
            <button
                className="w-max text-cyan-400 border border-cyan-400 px-4 py-2 mt-3 mb-3 rounded-md"
                style={{
                backgroundColor: 'blue',
                color: 'white',
                }}
                onClick={() => setPhase("outline")}
            >
                Proceed to Content Outlining
            </button>
            </div>
    )
}
export default ResearchOutput;