import React from "react";
import classNames from "classnames";
import PropType from "prop-types";
import colors from "tailwindcss/colors";

function Button(
  props,
  {
    type,
    variant,
    buttonText,
    onClick,
    loading,
    className,
    href,
    block,
    size,
    disabled,
  }
) {
  var variantSwitch = (variant, size) => {
    const cnames = classNames(
      {
        "justify-center items-center inline-flex flex-1 py-1 px-1 rounded-md text-md border-solid border font-medium shadow-md focus:ring focus:no-outline": true,
        "transition-colors": true,

        //disabled
        "cursor-not-allowed shadow-none": props.disabled === true,
        "bg-gray-100 text-gray-500 border-gray-100":
          props.disabled === true &&
          (variant === undefined ||
            variant === "primary-l" ||
            variant === "secondary-l" ||
            variant === "warning-l" ||
            variant === "danger-l"),

        //solid
        "text-primary-l border-primary bg-primary": variant === "primary", // primary
        "hover:text-primary hover:bg-primary-l focus:ring-primary-l":
          variant === "primary" && props.disabled !== true,
        "text-secondary-l border-secondary bg-secondary ":
          variant === "secondary", // secondary
        "hover:text-secondary hover:bg-secondary-l focus:ring-secondary-l":
          variant === "secondary" && props.disabled !== true,
        "text-warning-l border-warning bg-warning": variant === "warning", // warning
        "hover:text-warning hover:bg-warning-l focus:ring-warning-l":
          variant === "warning" && props.disabled !== true,
        "text-danger-l border-danger bg-danger": variant === "danger", // danger
        "hover:text-danger hover:bg-danger-l focus:ring-danger-l":
          variant === "danger" && props.disabled !== true,
        "text-white border-hbBlue bg-hbBlue": variant === "hbBlue", // hbBlue
        "hover:text-hbBlue hover:bg-white focus:ring-hbBlue":
          variant === "hbBlue" && props.disabled !== true,
        "text-hbBlue border-hbBlue bg-white": variant === "hbWhite", // hbBlue
        "hover:text-white hover:bg-hbBlue focus:ring-white":
          variant === "hbWhite" && props.disabled !== true,

        //light
        "text-primary bg-primary-l":
          variant === "primary-l" && props.disabled !== true, // primary-light
        "border-primary hover:text-primary-l hover:bg-primary focus:ring-primary":
          variant === "primary-l" && props.disabled !== true,
        "text-secondary border-secondary bg-secondary-l ":
          variant === "secondary-l" && props.disabled !== true, // secondary-light
        "hover:text-secondary-l hover:bg-secondary focus:ring-secondary":
          variant === "secondary-l" && props.disabled !== true,
        "text-gray-300 border-gray-600 bg-gray-200 ":
          variant === "gray-l" && props.disabled !== true, // gray-light
        "hover:text-gray-700 hover:border-gray-700 focus:bg-gray-700":
          variant === "gray-l" && props.disabled !== true,
        "text-warning border-warning bg-warning-l":
          variant === "warning-l" && props.disabled !== true, // warning-light
        "hover:text-warning-l hover:bg-warning focus:ring-warning":
          variant === "warning-l" && props.disabled !== true,
        "text-danger border-danger bg-danger-l":
          variant === "danger-l" && props.disabled !== true, // danger-light
        "hover:text-danger-l hover:bg-danger focus:ring-danger":
          variant === "danger-l" && props.disabled !== true,
        "text-hbBlue border-hbBlue bg-hbBlue-l":
          variant === "hbBlue-l" && props.disabled !== true, // hbBlue-light
        "hover:text-white hover:bg-hbBlue focus:ring-hbBlue":
          variant === "hbBlue-l" && props.disabled !== true,

        "text-white border-gray-600 bg-gray-600": variant === "default-s", // default-solid
        "hover:text-gray-600 hover:bg-white focus:ring-gray-600":
          variant === "default-s" && props.disabled !== true,

        "border-gray-200 text-gray-600 bg-white hover:bg-gray-600 hover:text-white focus:ring-gray-600":
          props.disabled !== true &&
          variant !== "primary" &&
          variant !== "secondary" &&
          variant !== "warning" &&
          variant !== "danger" && // default
          variant !== "primary-l" &&
          variant !== "secondary-l" &&
          variant !== "warning-l" &&
          variant !== "danger-l" &&
          variant !== "hbBlue" &&
          variant !== "hbBlue-l" &&
          variant !== "hbWhite" &&
          variant !== "default-s",

        "py-1 px-2 text-xs font-semibold": size === "xs", // size
        "py-1 px-2 text-sm font-semibold": size === "sm", // size
        "py-2 px-4 text-base font-medium": size === "medium",
        "py-2 px-4 text-lg font-semibold": size === "large",
        "py-2 px-4 text-xl font-semibold": size === "xl",
        "py-2 px-4 text-2xl font-semibold": size === "xxl",
        "py-2 px-4 text-3xl font-semibold": size === "xxxl",
      },
      props.className
    );

    const classNamesArray = cnames.split(" ");

    // [For the disabled state in onboarding]
    // Check if "bg-hbDirtyWhite" class exists in the array
    const hasDirtyWhiteBg = classNamesArray.includes("onboarding-disabled-btn");

    // Filter out "text-*-l" classNames if "bg-hbDirtyWhite" exists
    const filteredClassNamesArray = classNamesArray.filter((className) => {
      if (
        hasDirtyWhiteBg &&
        className.startsWith("text-") &&
        className.endsWith("-l") ||
        hasDirtyWhiteBg && className.startsWith("bg-")
      ) {
        return false; // Exclude "text-*-l" classNames
      }
      return true; // Include all other classNames
    });

    // Join the classNames back into a string
    const cleanedClassNames = filteredClassNamesArray.join(" ");
    return Array.from(new Set(cleanedClassNames.split(" ")))
      .join(" ")
      .replace(/\s+/g, " ");
  };

  var blockSwitch = (param) => {
    return classNames({
      flex: param === true,
      "flex w-full": param === "full",
    });
  };

  return (
    <>
      <a href={props.href} className={blockSwitch(props.block)}>
        <button
          type={props.type}
          onClick={props.onClick}
          className={variantSwitch(props.variant, props.size)}
          disabled={props.disabled}
        >
          {props.children}
        </button>
      </a>
    </>
  );
}

Button.defaultProps = {
  type: "button",
};

export default Button;
