import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { CgLogOut } from "react-icons/cg";
import { HiChevronDoubleLeft, HiChevronRight } from "react-icons/hi";
import { SidebarData } from "./SidebarData";
import "../../assets/styles/SideNavigation.css";
import { Auth, API } from "aws-amplify";
import "../../assets/styles/Inbox.css";
import { AppRoutes } from "../../constants/AppRoutes";
import CreatePortalModal from "../authentication/create-portal-modal";
import ReactTooltip from "react-tooltip";
import {
  isMobileDevice,
  useWindowDimensions,
} from "../../shared/mobileViewFunctions";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";
import ToastNotification from "../toast-notification";
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa";

const qGetCompanyEmailToken = `
  query getCompanyEmailToken($companyId: String) {
    company(id: $companyId) {
      gmailToken {
        id
        refreshToken
        isOutOfSync
      }
      outlookToken {
        email
        refreshToken
        isOutOfSync
      }
    }
  }`;

const removeRefreshTokenOutlook = `
    mutation removeRefreshToken($email: String!, $companyId: String!) {
      outlookDisconnect(companyId: $companyId, email: $email) {
        id
      }
    }
  `;
const removeRefreshToken = `
  mutation removeRefreshToken($email: String) {
    gmailDisconnect(email: $email) {
      id
    }
  }
  `;
function ExtendedBar({
  userInfo,
  setuserInfo,
  showSidebar,
  showInbox,
  showUserTypeAccess,
  showContacts,
  showTasks,
  showCompanyDetails,
  clickLogout,
  assigneeInitials,
  location,
  profilePic,
  companyName,
  setCompanyName,
  showInboxSublist,
  setShowInboxSublist,
  setIsInitializingPortal,
}) {
  let history = useHistory();
  const { height, width } = useWindowDimensions();

  const [showcreateportal, setshowcreateportal] = useState(false);
  const [showPortals, setShowPortals] = useState(false);
  const [userpages, setuserpages] = useState([]);
  const [defaultCompany, setdefaultCompany] = useState({
    companyId: "",
    company: "",
    userType: "",
  });
  const [user, setuser] = useState({
    firstName: "",
    lastName: "",
  });
  const [showToast, setshowToast] = useState({
    toast: false,
    error: null,
    message: "",
  });
  const [organisationslist, setorganisationslist] = useState([]);
  const companyId = localStorage.getItem("companyId");
  const [error, setError] = useState(null);
  const [isOutlookIntegrated, setIsOutlookIntegrated] = useState(
    localStorage.getItem("connectedOutlookAccount")
      ? true
      : localStorage.getItem("connectedGmailAccount")
      ? false
      : null
  );
  const connectedEmail = useRef(
    localStorage.getItem("connectedOutlookAccount")
      ? localStorage.getItem("connectedOutlookAccount")
      : localStorage.getItem("connectedGmailAccount")
  );
  const domain = window.location.hostname;

  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  const hbaFontClass =
    hbaDomains.includes(domain) || obaDomains.includes(domain)
      ? "font-open-sans text-neutral-500"
      : "";

  useEffect(() => {
    getUser();

    // CHECKS IF APPLICATION USES EMAIL INTEGRATION
    // THEN CHECKS CURRENT LOGGED ACCOUNT
    if (showInbox) checkIntegrationAccount();
  }, []);

  const USER_DETAILS_QUERY = `
  query user($id: String) {
    user(id: $id) {
      company {
        id
        name
      }
      email
      firstName
      lastName
      userType
      profilePicture
      hasPortalAccess
      organisations {
        items {
          company {
            id
            name
            representative {
              id
              firstName
              lastName
            }
          }
          userType
          hasPortalAccess
          isDeleted
          userCategory
        }
      }
    }
  }
`;

  const DEFAULT_PAGES_QUERY = `query getDefaults {
  pages {
    id
    name
    features {
      id
      name
    }
  }
}`;

  let getUser = async () => {
    try {
      const dafaults = await API.graphql({
        query: DEFAULT_PAGES_QUERY,
      });

      const pages = dafaults?.data?.pages;
      setuserpages(pages);

      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((cognitoUser) => {
        var userId = cognitoUser.attributes["sub"];
        var params = {
          query: USER_DETAILS_QUERY,
          variables: {
            id: userId,
          },
        };
        API.graphql(params).then((userInfo) => {
          const userData = userInfo.data;
          console.log("USERDATA AUTH", userData);
          const orgs = userData?.user?.organisations?.items;
          const accessibleOrgs = orgs
            .filter((item) => item?.isDeleted !== true)
            .sort(
              (a, b) =>
                new Date(a.company.createdAt) - new Date(b.company.createdAt)
            );

          setorganisationslist(accessibleOrgs);
        });
      });
    } catch (e) {
      setError(e.errors[0].message);
    }
  };

  //SELECTING COMPANY
  const USER_ACCESS_QUERY = `
query getPagesAndAccess($companyId: String, $userType: UserType) {
  companyAccessType(companyId: $companyId, userType: $userType) {
    id
    access {
      id
      name
      features {
        id
        name
      }
    }
  }
}
`;

  const UPDATE_ACCESS_MUTATION = `
mutation updateCompanyAccessType($id: String, $access: [AccessInput]) {
  companyAccessTypeUpdate(
    id: $id
    access: $access
  ) {
    id
    access {
      id
      features {
        id
      }
    }
  }
}`;

  async function updateCompanyAccessType(id, access) {
    try {
      await API.graphql({
        query: UPDATE_ACCESS_MUTATION,
        variables: {
          id,
          access,
        },
      });
    } catch (e) {
      setError(e.errors[0].message);
    }
  }

  const selectCompany = (cmpId, cmpName, userType, userCategory) => {
    if (cmpId == defaultCompany.companyId) {
      portalSwitch(userCategory);
    } else {
      setSelectedCompanyAsDefault(cmpId, cmpName, userType, userCategory);
    }
  };

  const setSelectedCompanyAsDefault = (
    cmpId,
    cmpName,
    userType,
    userCategory
  ) => {
    // const hbaUserCategories = ["HBA_ARCHITECT_SUPERINTENDENT", "HBA_HOMEOWNER"];

    localStorage.setItem("companyId", cmpId);
    localStorage.setItem("company", cmpName);
    localStorage.setItem("userType", userType);
    if (hbaDomains.includes(domain)) {
      localStorage.setItem("userCategory", userCategory);
    }
    setCompanyName(cmpName);

    var params = {
      query: USER_ACCESS_QUERY,
      variables: {
        companyId: cmpId,
        userType: userType,
      },
    };

    API.graphql(params).then(async (ua) => {
      if (ua.data.companyAccessType.length !== 0) {
        const userAccessId = ua.data.companyAccessType[0].id;
        const userAccess = ua.data.companyAccessType[0].access;

        const stringifyAllAccess = JSON.stringify(userpages);
        const stringifyUserAccess = JSON.stringify(userAccess);
        localStorage.setItem("access", stringifyUserAccess);

        if (userType === "OWNER") {
          if (stringifyAllAccess !== stringifyUserAccess) {
            console.log("Current Owner has limited access");
            const updateAccess = await updateCompanyAccessType(
              userAccessId,
              userpages
            );

            if (updateAccess) {
              // history.push(AppRoutes.DASHBOARD);
              // history.push(dashboardPath);
              portalSwitch(userCategory);
            } else {
              console.log("ERROR UPDATE ACCESS CHECK", updateAccess);
            }
          } else {
            //CURRENT END POINT OF FUNCTION
            // history.push(AppRoutes.DASHBOARD);
            // history.push(dashboardPath);
            portalSwitch(userCategory);
          }
        } else {
          console.log("ERROR COMPANY USERTYPE CHECK");
        }

        // history.push(AppRoutes.DASHBOARD);
        // history.push(dashboardPath);
        portalSwitch(userCategory);
      } else {
        history.push(AppRoutes.SIGNOUT);
      }
    });
  };

  function portalSwitch(userCategory) {
    // OWNERBUILDER or MMA Portal to /dashboard
    // HBA_ARCHITECT_SUPERINTENDENT or HBA_HOMEOWNER or HBA Portal to /hba-dashboard
    console.group("Switch Company");
    console.log("Persona is ", userCategory);

    const hbaUserCategories = ["HBA_ARCHITECT_SUPERINTENDENT", "HBA_HOMEOWNER"];

    let dashboardRoute;

    if (hbaDomains.includes(domain)) {
      if (!userCategory) {
        console.log("Old accounts (should be redirected to HBA dashboard)");
        dashboardRoute = AppRoutes.HBADASHBOARD;
      } else {
        if (!hbaUserCategories.includes(userCategory)) {
          console.log("Redirect to OWNERBUILDER dashboard");
          dashboardRoute = AppRoutes.DASHBOARD;
        }
      }
    } else if (obaDomains.includes(domain)) {
      history.push(AppRoutes.OBADASHBOARD); // OBA
    } else {
      dashboardRoute = AppRoutes.DASHBOARD; // MMA
    }

    history.push(dashboardRoute);
  }

  const signoutEmailIntegration = async () => {
    const companyId = localStorage.getItem("companyId");

    if (!isOutlookIntegrated) {
      // console.log("Logging out gmail from sidebar", connectedEmail.current);

      let params = {
        query: removeRefreshToken,
        variables: {
          email: connectedEmail.current,
        },
      };

      let result = await API.graphql(params);
      console.log("Signed out from sidebar", result);

      localStorage.removeItem("connectedGmailAccount");
      window.location.reload();

      return;
    } else {
      // console.log("Logging out outlook from sidebar", connectedEmail.current);

      let params = {
        query: removeRefreshTokenOutlook,
        variables: {
          email: connectedEmail.current,
          companyId: companyId,
        },
      };

      let result = await API.graphql(params);
      console.log("Signed out from sidebar", result);

      localStorage.removeItem("connectedOutlookAccount");
      window.location.reload();
      return;
    }
  };

  const checkIntegrationAccount = async () => {
    const result = await API.graphql({
      query: qGetCompanyEmailToken,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    });

    const tokens = result.data.company;
    console.log("Connected Accounts", tokens, isOutlookIntegrated);
    if (tokens.gmailToken.id) {
      let gmail = tokens.gmailToken.id;
      connectedEmail.current = gmail;
      localStorage.setItem("connectedGmailAccount", gmail);
      setIsOutlookIntegrated(false);
    } else if (tokens.outlookToken.email) {
      let outlookEmail = tokens.outlookToken.email;
      connectedEmail.current = outlookEmail;
      localStorage.setItem("connectedOutlookAccount", outlookEmail);
      setIsOutlookIntegrated(true);
    } else {
      localStorage.removeItem("connectedGmailAccount");
      localStorage.removeItem("connectedOutlookAccount");
      setIsOutlookIntegrated(null);
    }
  };
  return (
    <>
      <div className="sidebar z-50">
        <div className="main-grid">
          <div className="logo-grid">
            <Link
              onClick={() => showSidebar()}
              //className="nav-item"
              to={dashboardPath}
            >
              <img
                className="rounded-md object-scale-down h-8 w-8"
                src={process.env.REACT_APP_HEADER_ICON}
                alt="logo"
              />
            </Link>
            <button>
              <HiChevronDoubleLeft
                onClick={showSidebar}
                style={{ color: "var(--mysteryGrey)" }}
              />
            </button>
          </div>
          <ul className="nav-menus">
            {userInfo &&
              SidebarData.sort((a, b) => a.order - b.order).map(
                (item, index) => {
                  const navClass = [];

                  navClass.push(
                    location.pathname === item.path ? "active-page" : ""
                  );

                  navClass.push(
                    item.name !== "DASHBOARD" &&
                      item.name !== "INBOX" &&
                      item.name !== "TASKS" &&
                      item.name !== "ACCOUNTSETTINGS" &&
                      item.name !== "CONTACTS"
                      ? "hidden lg:block"
                      : "sm:block"
                  );

                  return item.name === "INBOX" && showInbox ? (
                    <li key={index}>
                      <div className={navClass.join(" ")}>
                        <a
                          className="nav-item"
                          onClick={() =>
                            setShowInboxSublist((prevState) => !prevState)
                          }
                        >
                          {item.icon}
                          <span
                            className={`${hbaFontClass} ${
                              location.pathname === "/inbox" ? "text-white" : ""
                            }`}
                          >
                            {item.title}
                          </span>
                        </a>
                      </div>
                      {showInboxSublist && (
                        <ul className="ml-9 mt-2 grid gap-1">
                          <li className="my-1">
                            <Link
                              to={{
                                pathname: item.path,
                                state: { sent: false },
                              }}
                              onClick={() => {
                                showSidebar();
                              }}
                              className={`text-neutral-700 ${hbaFontClass} ${
                                location.state?.sent === false && "font-medium"
                              }`}
                              replace
                            >
                              Inbox
                            </Link>
                          </li>
                          <li className="my-1">
                            <Link
                              to={{
                                pathname: item.path,
                                state: { sent: true },
                              }}
                              onClick={() => {
                                showSidebar();
                              }}
                              className={`text-neutral-700 ${hbaFontClass} ${
                                location.state?.sent === true && "font-medium"
                              }`}
                              replace
                            >
                              Sent
                            </Link>
                          </li>
                          {location.pathname === item.path &&
                          isOutlookIntegrated !== null ? (
                            <>
                              <hr className="mr-4" />
                              <Link
                                to={{
                                  pathname: item.path,
                                  state: {},
                                }}
                                onClick={() => {
                                  showSidebar();
                                  signoutEmailIntegration();
                                }}
                                className="text-neutral-700 hbaFontClass signout"
                              >
                                {isOutlookIntegrated ? (
                                  <FaMicrosoft />
                                ) : (
                                  <FcGoogle />
                                )}
                                Sign Out
                              </Link>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      )}
                    </li>
                  ) : item.name === "DASHBOARD" ||
                    item.name === "ACCOUNTSETTINGS" ||
                    (item.name === "INBOX" && showInbox) ||
                    (item.name === "USERTYPEACCESS" && showUserTypeAccess) ||
                    (item.name === "CONTACTS" && showContacts) ||
                    (item.name === "TASKS" && showTasks) ? (
                    <li className={navClass.join(" ")} key={index}>
                      <Link
                        className="nav-item"
                        to={item.path}
                        onClick={() => {
                          showSidebar();
                          setShowInboxSublist(false);
                        }}
                      >
                        {item.icon}
                        <span className={hbaFontClass}>{item.title}</span>
                      </Link>
                    </li>
                  ) : null;
                }
              )}
          </ul>
          <hr />
          <div className="logout-btn" onClick={clickLogout}>
            <CgLogOut style={{ color: "var(--mysteryGrey)" }} />
            <span className={hbaFontClass}>Log out</span>
          </div>
        </div>
        <div>
          {userInfo && (
            <div className="avatar-grid">
              <div className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center">
                {userInfo?.profilePicture === "null" ||
                userInfo?.profilePicture === null ||
                !userInfo?.profilePicture ? (
                  <span className={hbaFontClass}>
                    {assigneeInitials.toUpperCase()}
                  </span>
                ) : (
                  <img
                    alt={`${userInfo?.firstName} ${userInfo?.lastName}`}
                    className="rounded-full w-8 h-8"
                    src={profilePic}
                  />
                )}
              </div>

              <div>
                <div className="details-grid">
                  <span className={hbaFontClass + "name-txt"}>
                    {userInfo["firstName"]} {userInfo["lastName"]}
                  </span>
                  {/* {showCompanyDetails && (
                   * REMOVED THIS FOR NOW SINCE THIS CAUSES A BUG.
                   * ALSO, IT IS AN UNNECESSARY CHECK AS THE SYSTEM
                   * LOGS OUT USER IN AN INSTANT. PLEASE COME BACK
                   * TO THIS IF THERE'S A WAY FORWARD TO FIX THIS.
                   * FOR NOW I WILL PRIORITIZE THE BUG FIX.
                   */}
                  <span className={hbaFontClass}>
                    {companyName || localStorage?.getItem("company")}
                  </span>
                  {/* )} */}
                </div>
                <div
                  className="w-10 -ml-10 h-10 cursor-pointer"
                  style={{
                    position: "absolute",
                    right: "5px",
                    bottom: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <HiChevronRight
                    onClick={() => {
                      setShowPortals(!showPortals);
                      getUser();
                    }}
                    style={{ color: "var(--mysteryGrey)" }}
                    size="25px"
                  />
                </div>
              </div>

              {showPortals === true ? (
                <>
                  <div
                    className={
                      width > 844
                        ? "w-18 bg-gray-200 rounded-md fixed  ml-60 -mt-40 h-48 overflow-y-scroll test123 p-3"
                        : "w-18 bg-gray-200 rounded-md fixed  ml-60 -mt-40 h-48 overflow-y-scroll test123 p-3"
                    }
                  >
                    {organisationslist?.map((org, idx) => {
                      if (org.hasPortalAccess === false) {
                        return (
                          <>
                            <div
                              className={`${hbaFontClass} ${
                                companyId === org.company.id
                                  ? "flex bg-green-400 text-white w-16 h-16 rounded-full text-md font-medium justify-center items-center mb-1"
                                  : "flex bg-blue-400 text-white w-16 h-16 rounded-full text-md font-medium justify-center items-center mb-1"
                              }`}
                              data-tip={org.company.name}
                              onClick={() => {
                                setshowToast({
                                  toast: true,
                                  error: true,
                                  message:
                                    "You do not have an access in this portal",
                                });
                                setTimeout(() => {
                                  setshowToast({
                                    toast: false,
                                    error: null,
                                    message: "",
                                  });
                                }, 2500);
                              }}
                            >
                              {width > 844 ? (
                                <>
                                  <p className={`${hbaFontClass} font-medium`}>
                                    {org.company.name[0]}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className={`${hbaFontClass} text-xs`}>
                                    {org.company.name.substring(0, 8)}{" "}
                                    {org?.company?.name?.length > 8 ? ".." : ""}
                                  </p>
                                </>
                              )}
                            </div>

                            <ReactTooltip className={hbaFontClass} />
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div
                              className={`${hbaFontClass} ${
                                localStorage?.getItem("companyId") ===
                                org.company.id
                                  ? "flex bg-green-400 text-white w-16 h-16 rounded-full text-md font-medium justify-center items-center mb-1"
                                  : "flex bg-blue-400 text-white w-16 h-16 rounded-full text-md font-medium justify-center items-center mb-1"
                              }`}
                              data-tip={org.company.name}
                              onClick={() => {
                                selectCompany(
                                  org.company.id,
                                  org.company.name,
                                  org.userType,
                                  org.userCategory
                                );
                              }}
                            >
                              {width > 844 ? (
                                <>
                                  <p className="font-medium">
                                    {org.company.name[0]}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className={`${hbaFontClass} text-xs`}>
                                    {org.company.name.substring(0, 8)}{" "}
                                    {org?.company?.name?.length > 8 ? ".." : ""}
                                  </p>
                                </>
                              )}
                            </div>
                            <ReactTooltip className={hbaFontClass} />
                          </>
                        );
                      }
                    })}
                    <>
                      <div
                        className={`${hbaFontClass} flex bg-gray-600 text-white w-16 h-16 rounded-full text-md font-medium justify-center items-center mb-1`}
                        data-tip={"Create New"}
                        onClick={() => {
                          setShowPortals(false);
                          setshowcreateportal(true);
                        }}
                      >
                        +
                      </div>
                      <ReactTooltip className={hbaFontClass} />
                    </>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="spacer-div" onClick={() => showSidebar()}>
        {" "}
      </div>
      {showToast.toast &&
        (showToast.error ? (
          <ToastNotification
            title={showToast.message}
            error={showToast.error}
          />
        ) : (
          <ToastNotification title={showToast.message} />
        ))}
      {showcreateportal && (
        <CreatePortalModal
          close={() => setshowcreateportal(false)}
          setshowToast={(e) => setshowToast(e)}
          organisationslist={organisationslist}
          setIsInitializingPortal={setIsInitializingPortal}
        />
      )}
    </>
  );
}

export default ExtendedBar;
