import React , { useState } from 'react';
import { FiUserPlus, FiCheck } from 'react-icons/fi'
import Select, { components } from 'react-select';

export const People = ({ 
  name, 
  options, 
  profilePicture, 
  handleOpen,
  id,
  userSelectStates,
  handleUserSelect,
  handleUserTypeSelect
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  // state management
  const onTap = (e) => {
    handleUserSelect(id)
    if (!userSelectStates.isButtonClicked) {
      handleUserTypeSelect(id, options[0])
      handleOpen(true)
      setMenuOpen(true)
    } else {
      e.stopPropagation()
      handleUserTypeSelect(id, null)
      handleOpen(false)
      setMenuOpen(false)
    }
  }
  const onChange = (e) => {
    handleUserTypeSelect(id, e)
    setMenuOpen(false)
  }
  const onOpen = () => {
    handleOpen(true)
    setMenuOpen(true)
  }
  const onClose = () => {
    if (menuOpen) {
      handleOpen(false)
    }
  }

  // functions
  function handleInitials(name) {
    let names = name.split(" ");
    return names[1][0] ? names[0][0] + names[1][0] : names[0][0];
  }

  // styles
  const controlStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      padding: "0px",
      border: "none",
      fontSize: "14px",
      lineHeight: "20px",
      height: "25px",
      minHeight: "25px",
      boxShadow: "none",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "8px",
      width: "210px",
      left: "-120px",
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      padding: 0,
      margin: 0
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: "0px 4px"
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isSelected ? '#F9FAFB;' : 'inherit',
      padding: "10px 14px",
      color: state.isSelected ? '#101828;' : "#101828",
    }),
    indicatorSeparator: () => ({ display:'none' }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: "200px",
      "::-webkit-scrollbar": {
        width: "16px",
      },
      "::-webkit-scrollbar-thumb": {
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: "9999px",
        backgroundColor: "#EAECF0",
      },
      "::-webkit-scrollbar-thumb:hover": {
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: "9999px",
        backgroundColor: "#888",
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex">
          <div className="flex flex-1 gap-2 items-center">
            {props.data.label}
            <div className={`w-1.5 h-1.5 rounded-full ${props.data.color}`}></div>
          </div>
          {(userSelectStates.userTypeSelected.label === props.data.label) && <FiCheck className="text-green-700" size={20}/>}
        </div>
      </components.Option>
    )
  }

  return (
    <div className="w-full h-11 px-3.5 py-2.5 gap-2 inline-flex items-center">
      {
        profilePicture ? 
        <img className="w-6 h-6 rounded-full" src={profilePicture} alt={`${name.split()[0]}-pfp`}/> : 
        <div className="flex bg-gray-500 text-white w-6 h-6 rounded-full uppercase text-xs font-medium justify-center items-center">
          {handleInitials(name)}
        </div>
      }
      <div className="flex items-center gap-2 flex-1">
        {name}
        {
          userSelectStates.isButtonClicked ? <div className={`w-1.5 h-1.5 rounded-full ${userSelectStates.userTypeSelected.color}`} /> : <div className="w-1.5 h-1.5 bg-gray-300 rounded-full" />
        }
      </div>
      {
        userSelectStates.isButtonClicked && <div onClick={(e) => e.stopPropagation()}>
          <Select 
            options={options}
            styles={controlStyles}
            // defaultValue={options[0]}
            onChange={(value) => onChange(value)}
            components={{ Option }}
            onMenuOpen={onOpen}
            onMenuClose={onClose}
            menuIsOpen={menuOpen}
            menuPortalTarget={document.body}
            value={{ label: userSelectStates.userTypeSelected.value }}
          />
        </div>
      }
      <button onClick={onTap} className={`${userSelectStates.isButtonClicked ? "text-green-700" : "text-gray-300"}`}><FiUserPlus size={20} /></button>
    </div>
  );
};