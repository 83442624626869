import React from "react";
import { useEffect, useState } from "react";

function Switch ({
  row_index,
  row_id,
  defaultAccess,
  updateCommentAccess,
}) {

    const [isChecked, setisChecked] = useState(defaultAccess === null ? false : defaultAccess);

    const handleChange = (s) => {
        setisChecked((prev) => !prev);
    };

    useEffect(() => {
        updateCommentAccess(row_id, isChecked);
    }, [isChecked]);

  return (
    <>
    {isChecked}
    <div className="relative inline-block w-8 mr-2 align-middle select-none transition duration-200 ease-in">
      <input
        type="checkbox"
        name={row_index}
        id={row_index}
        className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer disabled:opacity-75"
        checked={isChecked}
        onChange={handleChange.bind(this)}
      />
      <label className="toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer"></label>
    </div>
    </>
  );
};

export default Switch;