import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";
import momentTZ from "moment-timezone";
import getQueryVariable from "../../shared/getQueryVariable";
// import { useAuthLoading } from "./authLoadingContext";
import loadingAnimation from "../../assets/images/post-authentication-loading.gif";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";

const APP_DEFAULTS_QUERY = `query getDefaults {
  pages {
    id
    name
    features {
      id
      name
    }
  }
  defaultUserType
}`;

const USER_DETAILS_QUERY = `query user($id: String) {
  user(id: $id) {
    company {
      id
      name
    }
    defaultCompany {
      id
      name
    }
    email
    firstName
    lastName
    userType
    userCategory
    profilePicture
    profilePictureKey
    businessName
    preferredTimezone
    preferredDateFormat
    isTwoFactorEnabled
    hasPortalAccess
    isDeleted
    history
    organisations {
      items {
        company {
          id
          name
          createdAt
          representative {
            id
            firstName
            lastName
          }
        }
        userType
        hasPortalAccess
        isDeleted
      }
    }
  }
}`;

const COMPANY_USERTYPES_QUERY = `query getCompanyUserTypes($companyId: String) {
  companyAccessType(companyId: $companyId) {
    userType
  }
}`;

const USER_ACCESS_QUERY = `query getPagesAndAccess($companyId: String, $userType: UserType) {
  companyAccessType(companyId: $companyId, userType: $userType) {
    id
    access {
      id
      name
      features {
        id
        name
      }
    }
  }
}`;

const CREATE_ACCESS_MUTATION = `mutation createCompanyAccessType($companyId: String, $userType: [UserType], $access: [AccessInput]){
  companyAccessTypeCreate(
    companyId: $companyId
    userType: $userType
    access: $access
  ) {
    id
  }
}`;

const UPDATE_ACCESS_MUTATION = `mutation updateCompanyAccessType($id: String, $access: [AccessInput]) {
  companyAccessTypeUpdate(
    id: $id
    access: $access
  ) {
    id
    access {
      id
      features {
        id
      }
    }
  }
}`;

const CREATE_ACTIVITY_MUTATION = `mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
    activity: $activity
    briefId: $briefId
    clientMatterId: $clientMatterId
    companyId: $companyId
    previous: $previous
    field: $field
    current: $current
    appModule: $appModule
    rowId: $rowId
  ) {
    id
  }
}`;

const domain = window.location.hostname;

export default function PostAuthentication({
  user,
  setUser,
  getCurrentSession,
}) {
  const [error, setError] = useState(null);
  const { height, width } = useWindowDimensions();

  // const { startAuthLoading, authLoading } = useAuthLoading();

  let history = useHistory();
  const nextStep = getQueryVariable("next");

  let redirectToOnboarding = false;
  // console.log("nextStep", nextStep);
  if (nextStep) {
    redirectToOnboarding = true;
  }

  // let nextQueryVariable = "";
  // console.log("nextStep", nextStep);
  // if (nextStep) {
  //   nextQueryVariable = `?next=${encodeURIComponent(nextStep)}`;
  // }

  useEffect(() => {
    // startAuthLoading();
    getCurrentSession();
    console.log("user", user);
  }, []);

  useEffect(() => {
    localStorage.removeItem("SignInError");
    getAccount();
  }, []);

  const getAccount = async () => {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then(async (cognitoUser) => {
        var userId = cognitoUser.attributes["sub"];
        const userData = await fetchAndStoreUserDetails(userId);

        const dafaults = await API.graphql({
          query: APP_DEFAULTS_QUERY,
        });

        const pages = dafaults?.data?.pages;
        const defaultUserType = dafaults?.data?.defaultUserType;

        // console.log("pages", pages);
        // console.log("defaultUserType", defaultUserType);
        // console.log("-----------");

        const companyUserTypes = await API.graphql({
          query: COMPANY_USERTYPES_QUERY,
          variables: {
            companyId: userData.user["company"]["id"],
          },
        });

        const companyAccessTypes = companyUserTypes?.data?.companyAccessType;
        const mappedCompanyAccessTypes = companyAccessTypes.map(
          (i) => i.userType
        );

        const missingUserTypes = defaultUserType.filter(
          (item) => !mappedCompanyAccessTypes.includes(item)
        );

        // console.log("Default UserTypes", defaultUserType.sort());
        // console.log("Current UserTypes", mappedCompanyAccessTypes.sort());
        // console.log("missingUserTypes", missingUserTypes);

        if (missingUserTypes?.length > 0) {
          await API.graphql({
            query: CREATE_ACCESS_MUTATION,
            variables: {
              companyId: userData.user["company"]["id"],
              access: pages,
              userType: missingUserTypes,
            },
          });
        }

        const orgs = userData?.user?.organisations?.items;
        const accessibleOrgs = orgs
          .filter(
            (item) =>
              item?.isDeleted !== true && item?.hasPortalAccess !== false
          )
          .sort(
            (a, b) =>
              new Date(a.company.createdAt) - new Date(b.company.createdAt)
          );

        if (accessibleOrgs?.length > 0) {
          const yourCreatedOrganization = accessibleOrgs.filter(
            (item) => item?.company?.representative?.id === userId
          );

          if (yourCreatedOrganization.length > 0) {
            console.log("Organizations you own", yourCreatedOrganization);
            selectCompany(
              yourCreatedOrganization[0].company.id,
              yourCreatedOrganization[0].company.name,
              yourCreatedOrganization[0].userType,
              yourCreatedOrganization[0].hasPortalAccess,
              pages,
              userId
            );
          } else {
            console.log(
              "First Organization you are involved",
              accessibleOrgs[0]
            );
            selectCompany(
              accessibleOrgs[0].company.id,
              accessibleOrgs[0].company.name,
              accessibleOrgs[0].userType,
              accessibleOrgs[0].hasPortalAccess,
              pages,
              userId
            );
          }
        } else {
          // User is deleted on all companies he is involved
          localStorage.setItem("SignInError", "User does not exist.");
          history.push(AppRoutes.SIGNOUT);
        }
      });
    } catch (e) {
      setError(e?.errors[0]?.message);
      console.error(e);
      localStorage.setItem(
        "SignInError",
        "Error signing in. Please try again."
      );
      history.push(AppRoutes.SIGNOUT);
    }
  };
  // Function to fetch and store user details into local storage
  const fetchAndStoreUserDetails = async (userId) => {
    try {
      // Prepare the GraphQL query parameters
      // kjmfedit1
      const params = {
        query: USER_DETAILS_QUERY,
        variables: { id: userId },
      };

      // Execute GraphQL query and wait for response
      const userInfoResponse = await API.graphql(params);
      // Extract user data from the GraphQL response
      const userData = userInfoResponse.data;

      // Check if setUser is a function and call it with the email of the user
      if (typeof setUser === "function") {
        setUser(userData.user.email);
      }

      // Proceed only if user data and the required nested properties exist
      if (userData && userData.user && userData.user.company) {
        // Construct a key-value pair object for all user details
        const userDetailsToStore = {
          userId,
          email: userData.user.email,
          firstName: userData.user.firstName,
          lastName: userData.user.lastName,
          userCategory: userData.user.userCategory,
          // Convert the history to string if exists, otherwise set to null
          history: userData.user.history
            ? JSON.stringify(userData.user.history.map((u) => JSON.parse(u)))
            : null,
          profilePicture: userData.user.profilePicture,
          // Convert defaultCompany to string if exists, otherwise set to null
          defaultCompany: userData.user.defaultCompany
            ? JSON.stringify({
                id: userData.user.defaultCompany.id,
                name: userData.user.defaultCompany.name,
              })
            : null,
          companyId: userData.user.company.id,
          company: userData.user.company.name,
          userType: userData.user.userType,
          hasPortalAccess: userData.user.hasPortalAccess,
          isDeleted: userData.user.isDeleted,
          // Guess timezone if none provided or if 'null', else use the provided one
          preferredTimezone:
            !userData.user.preferredTimezone ||
            userData.user.preferredTimezone === "null"
              ? momentTZ.tz.guess()
              : userData.user.preferredTimezone,
          // Use default date format if none provided or if 'null', else use the provided one
          userpreferredDateFormatType:
            !userData.user.preferredDateFormat ||
            userData.user.preferredDateFormat === "null"
              ? "MM-DD-YYYY"
              : userData.user.preferredDateFormat,
        };

        // Loop through each key in userDetailsToStore and save to local storage
        Object.keys(userDetailsToStore).forEach((key) => {
          const value = userDetailsToStore[key];
          // Only save to local storage if the value is not null
          if (value !== null) localStorage.setItem(key, value);
        });

        // Return the user data
        return userData;
      }
    } catch (error) {
      // Log any errors that may occur during the fetch/store process
      console.error("Error fetching and storing user details:", error);
      // Optionally handle the error, such as by notifying the user
    }
  };

  async function updateCompanyAccessType(id, access) {
    try {
      await API.graphql({
        query: UPDATE_ACCESS_MUTATION,
        variables: {
          id,
          access,
        },
      });
    } catch (e) {
      setError(e?.errors[0]?.message);
      console.error(e);
      localStorage.setItem(
        "SignInError",
        "Error signing in. Please try again."
      );
      history.push(AppRoutes.SIGNOUT);
    }
  }

  const selectCompany = (
    cmpId,
    cmpName,
    userType,
    hasPortalAccess,
    defaultPages,
    userId
  ) => {
    const defaultCompany = JSON.parse(localStorage.getItem("defaultCompany"));
    //Check if there is a defaultCompany so that it redirects to that portal on user post-auth
    if (defaultCompany) {
      console.log("Set Company to defaultCompany");
      localStorage.setItem("companyId", defaultCompany.id);
      // console.log("defaultCompany.id", defaultCompany.id);
      localStorage.setItem("company", defaultCompany.name);
    } else {
      console.log("No defaultCompany");
      localStorage.setItem("companyId", cmpId);
      // console.log("cmpId", cmpId);
      localStorage.setItem("company", cmpName);
    }
    localStorage.setItem("userType", userType);
    // console.log("CMPID LOCAL STORAGE", localStorage.getItem("companyId"));
    localStorage.setItem("hasPortalAccess", hasPortalAccess);

    var params = {
      query: USER_ACCESS_QUERY,
      variables: {
        companyId: cmpId,
        userType: userType,
      },
    };

    API.graphql(params).then(async (ua) => {
      if (ua.data.companyAccessType.length !== 0) {
        const userAccessId = ua.data.companyAccessType[0].id;
        const userAccess = ua.data.companyAccessType[0].access;

        // console.log("userAccess", userAccess);
        // console.log("defaultPages", defaultPages);
        const stringifyAllAccess = JSON.stringify(defaultPages);
        const stringifyUserAccess = JSON.stringify(userAccess);
        localStorage.setItem("access", stringifyUserAccess);

        const activityParams = {
          query: CREATE_ACTIVITY_MUTATION,
          variables: {
            companyId: localStorage.getItem("companyId"),
            activity: `User ${localStorage.getItem(
              "firstName"
            )} ${localStorage.getItem("lastName")} logged in`,
            field: "Authentication",
            appModule: "AUTH",
            previous: null,
            current: null,
          },
        };

        try {
          await API.graphql(activityParams);
        } catch (e) {
          console.error(e);
        }

        // Define the user types that should redirect to HBADASHBOARD
        const hbaUserCategories = [
          "HBA_ARCHITECT_SUPERINTENDENT",
          "HBA_HOMEOWNER",
        ];

        if (
          userType === "OWNER" &&
          (stringifyAllAccess !== stringifyUserAccess ||
            stringifyUserAccess === "[]")
        ) {
          console.log("Current Owner has limited access");
          await updateCompanyAccessType(userAccessId, defaultPages);

          // console.log("call updateCompanyAccessType");
          // console.log("set access to ", stringifyAllAccess);
          localStorage.setItem("access", stringifyAllAccess);

          const userCategory = localStorage.getItem("userCategory");

          // Check if the userType is in the HBA user types
          if (hbaDomains.includes(domain)) {
            if (redirectToOnboarding) {
              history.push(AppRoutes.GETTINGSTARTED); // HBA ONBOARDING
            } else if (
              userCategory === null ||
              userCategory === "" ||
              userCategory === undefined
            ) {
              //if no local data of user category, get the updated one
              const params = {
                query: USER_DETAILS_QUERY,
                variables: { id: userId },
              };
              const userInfoResponse = await API.graphql(params).then(
                (result) => {
                  const userData = result.data;

                  if (hbaUserCategories.includes(userData.user.userCategory)) {
                    history.push(AppRoutes.HBADASHBOARD); // HBA
                  } else {
                    window.location.href = AppRoutes.DASHBOARD;
                  }
                }
              );
            } else if (hbaUserCategories.includes(userCategory)) {
              history.push(AppRoutes.HBADASHBOARD); // HBA
            } else {
              // history.push(AppRoutes.DASHBOARD); // Redirect to default dashboard
              /** Update for updated sidebar component to refresh */
              window.location.href = AppRoutes.DASHBOARD;
            }
          } else if (obaDomains.includes(domain)) {
            history.push(AppRoutes.OBADASHBOARD); // OBA
          } else if (
            userCategory === null ||
            userCategory === "" ||
            userCategory === undefined
          ) {
            //if no local data of user category, get the updated one
            const params = {
              query: USER_DETAILS_QUERY,
              variables: { id: userId },
            };
            const userInfoResponse = await API.graphql(params).then(
              (result) => {
                const userData = result.data;

                if (hbaUserCategories.includes(userData.user.userCategory)) {
                  history.push(AppRoutes.HBADASHBOARD); // HBA
                } else {
                  window.location.href = AppRoutes.DASHBOARD;
                }
              }
            );
          } else {
            if (hbaUserCategories.includes(userCategory)) {
              history.push(AppRoutes.HBADASHBOARD); // Redirect to default dashboard
            } else {
              /** Update for updated sidebar component to refresh */
              window.location.href = AppRoutes.DASHBOARD;
            }
          }
        } else {
          localStorage.setItem("access", stringifyAllAccess);
          const userCategory = localStorage.getItem("userCategory");

          // Check if the domain is in the HBA domains list
          if (hbaDomains.includes(domain)) {
            // If redirectToOnboarding is true, redirect to the onboarding page
            if (redirectToOnboarding) {
              history.push(AppRoutes.GETTINGSTARTED); // HBA ONBOARDING
            } else if (
              userCategory === null ||
              userCategory === "" ||
              userCategory === undefined
            ) {
              //if no local data of user category, get the updated one
              const params = {
                query: USER_DETAILS_QUERY,
                variables: { id: userId },
              };
              const userInfoResponse = await API.graphql(params).then(
                (result) => {
                  const userData = result.data;

                  if (hbaUserCategories.includes(userData.user.userCategory)) {
                    history.push(AppRoutes.HBADASHBOARD); // HBA
                  } else {
                    window.location.href = AppRoutes.DASHBOARD;
                  }
                }
              );
            }
            // If the user is an OWNERBUILDER, redirect to the default dashboard; otherwise, redirect to the HBA dashboard
            else if (hbaUserCategories.includes(userCategory)) {
              history.push(AppRoutes.HBADASHBOARD); // Redirect to default dashboard
            } else {
              /** Update for updated sidebar component to refresh */
              window.location.href = AppRoutes.DASHBOARD;
            }
          }
          // If the domain is in the OBA domains list, redirect to the OBA dashboard
          else if (obaDomains.includes(domain)) {
            history.push(AppRoutes.OBADASHBOARD); // OBA
          }
          // If the domain is not in either list, redirect to the default dashboard
          else {
            // history.push(AppRoutes.DASHBOARD); // Redirect to default dashboard
            if (hbaUserCategories.includes(userCategory)) {
              history.push(AppRoutes.HBADASHBOARD); // Redirect to default dashboard
            } else {
              /** Update for updated sidebar component to refresh */
              window.location.href = AppRoutes.DASHBOARD;
            }
          }
        }
      } else {
        history.push(AppRoutes.SIGNOUT);
      }
    });
  };

  // Inside your function component
  return (
    <>
      {!redirectToOnboarding && (
        <div class={`flex justify-center items-center h-screen`}>
          <div class="relative text-center">
            <img
              src={loadingAnimation}
              style={{ height: isMobileDevice(width) ? "20rem" : "48rem" }}
              className={`m-auto`}
              alt="We're loading your Dashboard ..."
            />
            <p
              className={`text-black font-open-sans text-xl font-semibold ${
                isMobileDevice(width) ? "text-lg" : "text-3xl"
              }`}
            >
              We're loading your Dashboard ...
            </p>
          </div>
        </div>
      )}
      {error && <p>Oops! Something went wrong. {error}</p>}
    </>
  );
}
