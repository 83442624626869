//Libraries
import { useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Select from "react-select";

//Icons
import { IconContext } from "react-icons";
import { AiOutlineDelete } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import {
  MdOutlineRadioButtonUnchecked,
  MdCheckBoxOutlineBlank,
} from "react-icons/md";

//Dropdown Icons
import { HiOutlineBars2, HiOutlineBars3 } from "react-icons/hi2";
import { CgRadioChecked } from "react-icons/cg";
import { IoIosCheckboxOutline } from "react-icons/io";
import { BsCaretDownSquare } from "react-icons/bs";
import { PiListNumbersBold } from "react-icons/pi";
import { FaFont } from "react-icons/fa";
import { BiSolidUserCircle } from "react-icons/bi";
import { FaMapMarkedAlt } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { TbMailFilled } from "react-icons/tb";
import { FaCalendarWeek } from "react-icons/fa";
import { FaRegCalendarDays } from "react-icons/fa6";
import { AiFillClockCircle } from "react-icons/ai";
import { MdNumbers } from "react-icons/md";
import { BsFileEarmark } from "react-icons/bs";
import { FaSignature } from "react-icons/fa";

//Local Hooks, Functions, Reducers
import { FormBuilderContext, defaultPageState, defaultSectionState } from "./reducers/StoreProvider";
import { 
  mutationActionHandler,
  mCreatePage,
  mDeletePage,
  mBulkReorderPage,
  mUpdatePage,
  mCreateField,
  mDeleteField,
  mBulkReorderField,
  mUpdateField,
  mUpdateOptionsType,
  mUpdateOptions,
  mUpdateProperties,
} from "./graphql/formAPI";
import usePrevious from "../../shared/usePrevious";

//================================================
// Main Form Edit Component
//================================================

export default function FormEdit() {
  const { state, dispatch } = useContext(FormBuilderContext);
  const { formDetails, formContent, pages, pageType } = state.formData;

  const handleAddPage = () => {
    mutationActionHandler(
      {
        query: mCreatePage,
        variables: {
          formId: formDetails?.id,
          order: pages.length + 1,
        }
      },
      (response)=>{
        console.log(response)
        const pageCreate = response.data.customFormPageCreate
        dispatch({
          type: "ADD_PAGE",
          pageId: pageCreate.id,
          pageOrder: pageCreate.order,
        });
      }
    )

  }

  const handleChangePageType = (pageType) => {
    dispatch({
      type: "CHANGE_PAGE_TYPE",
      payload: pageType,
    })
  }

  return (
    <div className="w-full py-14 flex flex-col items-center gap-4">
      {pages.map((page) => (
        <PageArea key={page.id} page={page} />
      ))}
      {pageType == "multiple" && (
        <button className="" onClick={()=>handleAddPage()}>
          ---- Add Page ----
        </button>
      )}
    </div>
  );
}

export function PageArea({ page }) {
  const { state, dispatch } = useContext(FormBuilderContext);
  const { pages, formDetails } = state.formData;
  const { id, order, name, description, sections } = page;

  const handleChangeHeaderTitle = (titleText) => {
    mutationActionHandler(
      {
        query: mUpdatePage,
        variables: {
          id: id,
          formId: formDetails?.id,
          name: titleText,
          description: description,
        }
      },
      (response)=>{
        //console.log(response)
        dispatch({
          type: "CHANGE_HEADER_TITLE",
          pageId: id,
          payload: titleText,
        });

      }
    )

  };

  const handleChangeHeaderDescription = (descriptionText) => {
    mutationActionHandler(
      {
        query: mUpdatePage,
        variables: {
          id: id,
          formId: formDetails?.id,
          name: name,
          description: descriptionText,
        }
      },
      (response)=>{
        //console.log(response)
        dispatch({
          type: "CHANGE_HEADER_DESCRIPTION",
          pageId: id,
          payload: descriptionText,
        });

      }
    )

  }

  const handleDeletePage = () => {

    const deletedPage = pages.filter((page)=> page.id !== id)

    mutationActionHandler(
      {
        query: mDeletePage,
        variables: {
          formId: formDetails?.id,
          id: id,
        }
      },
      (response)=>{
        console.log(response)
        //const pageCreate = response.data.customFormPageCreate
        dispatch({
          type: "DELETE_PAGE",
          pageId: id 
        })
      }
    )

    const newPageOrder = deletedPage
      .map((page, pageIndex)=>({
        id: page.id,
        order: pageIndex + 1
      }))


    mutationActionHandler(
      {
        query: mBulkReorderPage,
        variables: {
          formId: formDetails?.id,
          arrangement: newPageOrder,
        }
      },
      (response)=>{
        console.log(response)
        //const pageCreate = response.data.customFormPageCreate
        dispatch({
          type: "REORDER_PAGES",
          payload: newPageOrder,
        })
      }
    )

  }


  const handleAddSection = () => {

    mutationActionHandler(
      {
        query: mCreateField,
        variables: {
          pageId: id,
          order: sections?.length + 1,
          type: defaultSectionState.optionsType,
          options: defaultSectionState.options,
          properties: defaultSectionState.properties,
        }
      },
      (response)=>{
        //console.log(response)
        const sectionCreate = response.data.customFormFieldCreate
        dispatch({
          type: "ADD_SECTION",
          pageId: id,
          sectionId: sectionCreate.id,
          sectionOrder: sectionCreate.order,
        });
      }
    )

  }

  return (
    <>
      <FormCard>
        <FormTextInput
          placeholder={"Page Title"}
          value={name}
          saveChange={(e) => handleChangeHeaderTitle(e.target.value)}
        />
        <FormTextArea
          placeholder={"Description"}
          value={description}
          saveChange={(e) => handleChangeHeaderDescription(e.target.value)}
        />
        <button
          onClick={()=>handleDeletePage()}
        >
          Delete Page
        </button>
      </FormCard>
      {sections.map((section) => (
        <SectionArea key={section.id} pageId={id} section={section} />
      ))}
      <button onClick={() => handleAddSection()}>Add Question</button>
    </>
  );
}

function SectionArea({ pageId, section }) {
  const { state, dispatch } = useContext(FormBuilderContext);
  const { id, order, question, sublabel, optionsType, options, properties } =
    section;

  const handleChangeOptionType = (optionsType, optionsCount, properties) => {

    const defaultOptions = Array(optionsCount).fill('')

    mutationActionHandler(
      {
        query: mUpdateOptionsType,
        variables: {
          pageId: pageId,
          id: id,
          type: optionsType,
          options: defaultOptions,
          properties: defaultSectionState.properties,
        }
      },
      (response)=>{
        console.log(response)

        const fieldUpdate = response.data.customFormFieldUpdate
        dispatch({
          type: "CHANGE_OPTION_TYPE",
          pageId: pageId,
          sectionId: id,
          payload: {
            optionsType: optionsType,
            defaultOptions: defaultOptions,
            properties: fieldUpdate.properties,
          },
        })
      }
    )
  };

  const handleChangeSectionQuestion = (questionText) => {
    mutationActionHandler(
      {
        query: mUpdateField,
        variables: {
          pageId: pageId,
          id: id,
          headerText: questionText,
          subText: sublabel,
        }
      },
      (response)=>{
        console.log(response)
        //const sectionCreate = response.data.customFormSectionCreate
        dispatch({
          type: "CHANGE_SECTION_QUESTION",
          pageId: pageId,
          sectionId: id,
          payload: questionText,
        });
      }
    )

  };

  const handleChangeSectionSublabel = (sublabelText) => {
    mutationActionHandler(
      {
        query: mUpdateField,
        variables: {
          pageId: pageId,
          id: id,
          headerText: question,
          subText: sublabelText,
        }
      },
      (response)=>{
        console.log(response)
        //const sectionCreate = response.data.customFormSectionCreate
        dispatch({
          type: "CHANGE_SECTION_SUBLABEL",
          pageId: pageId,
          sectionId: id,
          payload: sublabelText,
        });
      }
    )

  };

  return (
    <FormCard>
      <div className="flex gap-2">
        <FormTextInput
          className="flex-1 "
          placeholder={"Untitled Question"}
          value={question}
          saveChange={(e) => handleChangeSectionQuestion(e.target.value)}
        />
        <span
          className="text-red-400"
          style={{
            transform: "translateX(-60%)",
          }}
        >
          *
        </span>
        <TypeDropdownButton
          value={optionsType}
          pageId={pageId}
          sectionId={id}
          changeType={handleChangeOptionType}
        />
      </div>
      <FormTextInput
        placeholder={"Sublabel"}
        value={sublabel}
        saveChange={(e) => handleChangeSectionSublabel(e.target.value)}
      />
      <FormInputTypeFactory
        pageId={pageId}
        sectionId={id}
        optionsType={optionsType}
        options={options}
      />
      <hr />
      <PropertiesArea
        pageId={pageId}
        sectionId={id}
        optionsType={optionsType}
        properties={properties}
      />
    </FormCard>
  );
}

// This objects controls and maps all of the Input Types available for the Form Builder
// Any additional items here will be reflected to the Dropdown
// If adding a new Input Type, just add another item, change the properties, then link the component of the new Input Type
const inputTypes = {
  TEXT: {
    icon: <HiOutlineBars2 />,
    label: "Short Answer",
    component: ShortText,
    defaultCount: 1,
    properties: ["maxLength"],
  },
  TEXTAREA: {
    icon: <HiOutlineBars3 />,
    label: "Long Answer",
    component: LongText,
    defaultCount: 1,
    properties: ["rowHeight", "maxLength"],
  },
  RADIOBUTTON: {
    icon: <CgRadioChecked />,
    label: "Radio Buttons",
    component: RadioButtons,
    defaultCount: 3,
    optionIcon: <MdOutlineRadioButtonUnchecked />,
  },
  CHECKBOX: {
    icon: <IoIosCheckboxOutline />,
    label: "Checkboxes",
    component: Checkboxes,
    defaultCount: 3,
    optionIcon: <MdCheckBoxOutlineBlank />,
  },
  DROPDOWN: {
    icon: <BsCaretDownSquare />,
    label: "Dropdown List",
    component: DropdownList,
    defaultCount: 4,
    enumaratedOption: true,
  },
  LINEARSCALE: {
    icon: <PiListNumbersBold />,
    label: "Linear Scale",
    component: LinearScale,
    defaultCount: 5,
    enumaratedOption: true,
  },
  TITLE: {
    icon: <FaFont />,
    label: "Text Header",
    component: TextHeader,
    defaultCount: 0,
  },
  NAME: {
    icon: <BiSolidUserCircle />,
    label: "Name",
    component: NameText,
    defaultCount: 0,
  },
  ADDRESS: {
    icon: <FaMapMarkedAlt />,
    label: "Address",
    component: AddressText,
    defaultCount: 5,
  },
  PHONE: {
    icon: <BsTelephoneFill />,
    label: "Phone",
    component: PhoneNumber,
    defaultCount: 1,
  },
  EMAIL: {
    icon: <TbMailFilled />,
    label: "Email Address",
    component: EmailAddress,
    defaultCount: 1,
  },
  DATETIME: {
    icon: <FaCalendarWeek />,
    label: "Date Time",
    component: DateTimePicker,
    defaultCount: 2,
    properties: ["defaultDateTime"],
  },
  DATE: {
    icon: <FaRegCalendarDays />,
    label: "Date",
    component: DatePicker,
    defaultCount: 1,
    properties: ["defaultDate"],
  },
  TIME: {
    icon: <AiFillClockCircle />,
    label: "Time",
    component: TimePicker,
    defaultCount: 1,
    properties: ["defaultTime"],
  },
  NUMBER: {
    icon: <MdNumbers />,
    label: "Number",
    component: NumberPicker,
    defaultCount: 1,
    properties: ["defaultValue", "currencyType", "minRange", "maxRange"],
  },
  FILEUPLOAD: {
    icon: <BsFileEarmark />,
    label: "File Upload",
    component: FileUpload,
    defaultCount: 0,
  },
  SIGNATURE: {
    icon: <FaSignature />,
    label: "Signature",
    component: Signature,
    defaultCount: 0,
  },
};
/*
  icon - The icon that will be shown in the Dropdown
  label - The complete name of the Input Type, used by the Dropdown
  component - The main component that will be rendered on the Form Builder section
  defaultCount - The default number of Options text box that will be created when changing Input Types
  optionIcon - The icon that will be show beside the Options text box if applicable
*/

function TypeDropdownButton({
  className,
  value,
  pageId,
  sectionId,
  changeType,
}) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const buttonRef = useRef(null);

  return (
    <div className="relative w-48">
      <button
        ref={buttonRef}
        className={`w-full p-2 flex justify-between border rounded-md hover:bg-gray-50 ${className}`}
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <p className="flex items-center gap-3 text-gray-500 font-medium">
          {inputTypes[value].icon} {` ${inputTypes[value].label}`}
        </p>
        <IoMdArrowDropdown className="text-gray-500" />
      </button>
      <AnimatePresence>
        {openDropdown && (
          <TypeDropdownOptions
            value={value}
            pageId={pageId}
            sectionId={sectionId}
            changeType={changeType}
            buttonRef={buttonRef}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

function TypeDropdownOptions({
  value,
  pageId,
  sectionId,
  changeType,
  buttonRef,
  setOpenDropdown,
}) {
  const { state, dispatch } = useContext(FormBuilderContext);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutsie = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target)
      )
        setOpenDropdown(false);
    };
    document.addEventListener("mousedown", handleClickOutsie);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsie);
    };
  }, [dropdownRef]);

  const handleInitializeOptionsCount = (optionsCount) => {
    dispatch({
      type: "INITIALIZE_OPTIONS_COUNT",
      pageId: pageId,
      sectionId: sectionId,
      payload: optionsCount,
    });
  };

  const handleInitializeProperties = (propertiesArray) => {
    dispatch({
      type: "INITIALIZE_PROPERTIES",
      pageId: pageId,
      sectionId: sectionId,
      payload: propertiesArray || [],
    });
  };

  return (
    <motion.div
      ref={dropdownRef}
      initial={{ height: 0, overflowY: "hidden" }}
      animate={{ height: 300, overflowY: "scroll" }}
      exit={{ height: 0, overflowY: "hidden" }}
      //transition={{ duration: 0.5 }}
      className="absolute z-20 w-full bg-white thin-scroll overflow-x-hidden rounded-md shadow-md"
    >
      <div className="py-1 flex flex-col text-gray-500 ">
        {Object.keys(inputTypes).map((type) => (
          <button
            key={type}
            className={`flex items-center gap-3 px-3 py-2 text-left font-medium ${
              value == type && "bg-neutral-100"
            } hover:bg-gray-50`}
            onClick={() => {
              changeType(type, inputTypes[type].defaultCount, inputTypes[type].properties);
              setOpenDropdown(false);
              //handleInitializeOptionsCount(inputTypes[type].defaultCount);
              //handleInitializeProperties(inputTypes[type].properties);
            }}
          >
            <IconContext.Provider value={{ className: "text-base" }}>
              {inputTypes[type].icon}
            </IconContext.Provider>
            {inputTypes[type].label}
          </button>
        ))}
      </div>
    </motion.div>
  );
}

function FormInputTypeFactory({
  pageId,
  sectionId,
  optionsType,
  options,
}) {
  const { state, dispatch } = useContext(FormBuilderContext);


  const handleChangeOptionText = (optionOrder, optionText) => {
    mutationActionHandler(
      {
        query: mUpdateOptions,
        variables: {
          pageId: pageId,
          id: sectionId,
          options: options.map((option, index)=>
            index === optionOrder ? optionText : option
          ),
        }
      },
      (response)=>{
        //console.log(response)
        dispatch({
          type: "CHANGE_OPTION_TEXT",
          pageId: pageId,
          sectionId: sectionId,
          optionOrder: optionOrder,
          payload: optionText,
        })

      }
    )
  };

  const InputComponent = inputTypes[optionsType].component;


  return (
    <InputComponent
      pageId={pageId}
      sectionId={sectionId}
      optionsType={optionsType}
      options={options}
      handleChangeOptionText={handleChangeOptionText}
    />
  );
}

function PropertiesArea({ pageId, sectionId, optionsType, properties }) {
  const { state, dispatch } = useContext(FormBuilderContext);
  const { sections } = state.formData.pages.find((page)=>page.id === pageId);

  const propertyTypes = [
    { propertyType: "rowHeight", label: "Number of Lines", valueType: "number" },
    { propertyType: "maxLength", label: "Max Characters", valueType: "number" },
    { propertyType: "defaultDateTime", label: "Default Value (Current Date Time)"},
    { propertyType: "defaultDate", label: "Default Value (Current Date)" },
    { propertyType: "defaultTime", label: "Default Value (Current Time)" },
    { propertyType: "defaultValue", label: "Default Value", valueType: "number",},
    { propertyType: "currencyType", label: "Currency", valueType: "dropdown" },
    { propertyType: "minRange", label: "Min Value", valueType: "number" },
    { propertyType: "maxRange", label: "Max Value", valueType: "number" },
  ];

  // const handleToggleProperty = (propertyKey, newValue) =>{
  //   dispatch({
  //     type:'TOGGLE_PROPERTY',
  //     pageId: pageId,
  //     sectionId: sectionId,
  //     propertyKey: propertyKey,
  //     payload: newValue,
  //   })
  // }

  const handleChangePropertyValue = (propertyKey, newValue) => {
    mutationActionHandler(
      {
        query: mUpdateProperties,
        variables: {
          pageId: pageId,
          id: sectionId,
          properties: {
            ...properties,
            [propertyKey]: newValue
          },
        }
      },
      (response)=>{
        console.log(response)
        //const sectionCreate = response.data.customFormSectionCreate
        dispatch({
          type: "CHANGE_PROPERTY_VALUE",
          pageId: pageId,
          sectionId: sectionId,
          propertyKey: propertyKey,
          payload: newValue,
        })
      }
    )

  };

  const handleCopySection = () => {
    dispatch({
      type: "COPY_SECTION",
      pageId: pageId,
      sectionId: sectionId,
    });
  };

  const handleDeleteSection = () => {

    const deletedSection = sections.filter((section)=> section.id !== sectionId)

    mutationActionHandler(
      {
        query: mDeleteField,
        variables: {
          pageId: pageId,
          id: sectionId,
        }
      },
      (response)=>{
        console.log(response)
        //const sectionCreate = response.data.customFormSectionCreate
        dispatch({
          type: "DELETE_SECTION",
          pageId: pageId,
          sectionId: sectionId,
        });
      }
    )

    const newSectionOrder = deletedSection
      .map((section, sectionIndex)=>({
        id: section.id,
        order: sectionIndex + 1
      }))


    mutationActionHandler(
      {
        query: mBulkReorderField,
        variables: {
          pageId: pageId,
          arrangement: newSectionOrder,
        }
      },
      (response)=>{
        console.log(response)
        //const pageCreate = response.data.customFormPageCreate
        dispatch({
          type: "REORDER_SECTIONS",
          pageId: pageId,
          payload: newSectionOrder,
        })
      }
    )

  };

  

  const propertyValueType = (property) => {
    let textValue =
      typeof properties[property.propertyType] === "string"
        ? properties[property.propertyType]
        : "";

    switch (property.valueType) {
      case "number":
        return (
          <NumberBoxInput
            property={property}
            value={properties[property.propertyType]}
            saveChange={(e) =>
              handleChangePropertyValue(property.propertyType, e.target.value)
            }
            disabled={properties[property.propertyType] === null}
          />
        );

      case "dropdown":
        const currenyOptions = [
          { value: "usd", label: "USD ($)" },
          { value: "jpy", label: "JPY (¥)" },
          { value: "php", label: "PHP (₱)" },
        ];

        return (
          <Select
            value={properties[property.propertyType] ? 
              currenyOptions.find((option)=>option.value === properties[property.propertyType]) :
              ''
            }
            placeholder="currency..."
            options={currenyOptions}
            onChange={(e) =>
              handleChangePropertyValue(property.propertyType, e.value)
            }
          />
        );
    }
  };

  const inputCheckboxHandler = (property) => {
    if(property.valueType !== null){
      if(properties[property.propertyType] === null){
        switch(property.valueType){
          case "number":
            return 0
          case "dropdown":
            return ''
        }  
      } else {
        return null
      }
    } else {
      return !properties[property.propertyType]
    }
    
  }

  return (
    <div className="flex justify-between">
      <div className="flex-1 flex flex-col gap-3">
        <div className="flex gap-4">
          <FormCheckboxLabel
            label={"Required"}
            checkBoxStyle="cyan"
            checked={properties.isRequired}
            onChange={() =>
              handleChangePropertyValue("isRequired", !properties.isRequired)
            }
          />
          <FormCheckboxLabel
            label={"Hidden"}
            checkBoxStyle="cyan"
            checked={!properties.isVisible}
            onChange={() =>
              handleChangePropertyValue("isVisible", !properties.isVisible)
            }
          />
        </div>

        <div className="w-3/4 flex flex-wrap gap-4">
          {propertyTypes
            .filter((property) =>
              inputTypes[optionsType].properties?.includes(
                property.propertyType
              )
            )
            .map((property, propertyIndex) => (
              <div key={propertyIndex} className="flex items-center gap-3">
                <FormCheckboxLabel
                  label={property.label}
                  checkBoxStyle="cyan"
                  checked={properties[property.propertyType] !== null}
                  onChange={() =>
                    handleChangePropertyValue(
                      property.propertyType,
                      inputCheckboxHandler(property)
                    )
                  }
                />
                {propertyValueType(property)}
              </div>
            ))}
        </div>
      </div>

      <div className="h-8 flex items-center gap-3">
        <button onClick={handleCopySection}>
          <FaRegCopy size={"1rem"} className="text-gray-500" />
        </button>
        <button onClick={handleDeleteSection}>
          <AiOutlineDelete size={"1.3rem"} className="text-gray-500" />
        </button>
      </div>
    </div>
  );
}

function NumberBoxInput({ value, saveChange, disabled }) {
  const [inputValue, setInputValue] = useState(value);
  const previousInputValue = usePrevious(value);

  useEffect(()=>{
    setInputValue(value)
  },[value])

  return (
    <input
      type="number"
      className="p-2 w-11 border rounded-md text-gray-600"
      value={inputValue || ''}
      onBlur={saveChange}
      onChange={(e) => setInputValue(e.target.value)}
      disabled={disabled}
    />
  );
}

//================================================
// Component Factory
//================================================

function TextHeader({ pageId, sectionId, options }) {
  return <div></div>;
}

function ShortText({ pageId, sectionId, options, handleChangeOptionText }) {
  const { state, dispatch } = useContext(FormBuilderContext);


  return (
    <FormTextInput
      className="w-1/2"
      placeholder={"Short answer text"}
      value={options[0]}
      saveChange={(e) => handleChangeOptionText(0, e.target.value)}
    />
  );
}

function LongText({ pageId, sectionId, options, handleChangeOptionText }) {
  const { state, dispatch } = useContext(FormBuilderContext);


  return (
    <FormTextArea
      className=""
      placeholder={"Long answer text"}
      value={options[0]}
      onChange={(e) => handleChangeOptionText(0, e.target.value)}
      rows={3}
    />
  );
}

function RadioButtons({ pageId, sectionId, optionsType, options, handleChangeOptionText }) {
  const { state, dispatch } = useContext(FormBuilderContext);

  return (
    <MultipleOptionsFactory
      pageId={pageId}
      sectionId={sectionId}
      options={options}
      optionsType={optionsType}
      handleChangeOptionText={handleChangeOptionText}
    />
  );
}

function Checkboxes({ pageId, sectionId, optionsType, options, handleChangeOptionText }) {
  return (
    <MultipleOptionsFactory
      pageId={pageId}
      sectionId={sectionId}
      options={options}
      optionsType={optionsType}
      handleChangeOptionText={handleChangeOptionText}
    />
  );
}

function DropdownList({ pageId, sectionId, optionsType, options, handleChangeOptionText }) {
  return (
    <MultipleOptionsFactory
      pageId={pageId}
      sectionId={sectionId}
      options={options}
      optionsType={optionsType}
      handleChangeOptionText={handleChangeOptionText}
    />
  );
}

function LinearScale({ pageId, sectionId, optionsType, options, handleChangeOptionText }) {
  return (
    <>
      <div className="relative z-0 px-7 py-1 flex justify-between">
        <hr
          className="absolute w-11/12 border-2 border-neutral-300"
          style={{ top: "11px" }}
        />
        {options.map(({ order }) => (
          <div key={order} className="z-20 flex flex-col items-center gap-2">
            <BsCircleFill className="text-white border-2 border-neutral-300 rounded-full" />
            <p className="">{order}</p>
          </div>
        ))}
      </div>
      <MultipleOptionsFactory
        pageId={pageId}
        sectionId={sectionId}
        options={options}
        optionsType={optionsType}
        handleChangeOptionText={handleChangeOptionText}
      />
    </>
  );
}

function NameText({ pageId, sectionId, optionsType, options }) {
  const { state, dispatch } = useContext(FormBuilderContext);

  const handleToggleOption = (optionOrder, optionLabel) => {

    const payloadArray = options.some((option)=>option === optionLabel) ?
      options.filter((option)=>option !== optionLabel) :
      [
        ...options,
        optionLabel
      ].sort((a,b)=>{
        const orderA = nameChoices.find((choice) => choice.label === a)?.order || 0;
        const orderB = nameChoices.find((choice) => choice.label === b)?.order || 0;
        return orderA - orderB;
      })

    mutationActionHandler(
      {
        query: mUpdateOptions,
        variables: {
          pageId: pageId,
          id: sectionId,
          options: payloadArray,
        }
      },
      (response)=>{
        //console.log(response)
        dispatch({
          type: "TOGGLE_OPTION",
          pageId: pageId,
          sectionId: sectionId,
          payload: payloadArray,
        })

      }
    )
 
  }

  const nameChoices = [
    { order: 1, label: "First Name" },
    { order: 2, label: "Last Name" },
    { order: 3, label: "Middle Name" },
    { order: 4, label: "Middle Initial" },
    { order: 5, label: "Prefix" },
    { order: 6, label: "Suffix" },
  ];

  return (
    <div className="grid grid-cols-2 max-w-xs gap-y-2">
      {nameChoices.map((nameChoice, nameIndex) => (
        <FormCheckboxLabel
          key={nameChoice.order}
          label={nameChoice.label}
          checkBoxStyle="gray"
          checked={options.some((option) => option === nameChoice.label)}
          onChange={() =>
            handleToggleOption(nameChoice.order, nameChoice.label)
          }
        />
      ))}
    </div>
  );
}

function AddressText({ pageId, sectionId, optionsType, options }) {
  function TextWithLabel({ label, className }) {
    return (
      <div className="flex-1 flex flex-col gap-1">
        <label className="text-gray-400 text-xs font-medium">{label}</label>
        <FormTextInput
          className={className}
          placeholder={label}
          // value={}
          saveChange={() => {}}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <TextWithLabel label="Street Address" />
      <TextWithLabel label="Street Address Line 2" />
      <div className="flex gap-4 max-w-lg">
        <TextWithLabel label="City" className="flex-auto" />
        <TextWithLabel label="State/Province" className="flex-auto" />
      </div>
      <TextWithLabel label="Postal/Zip Code" className="max-w-min" />
    </div>
  );
}

function PhoneNumber({ pageId, sectionId, optionsType, options }) {
  return (
    <FormTextInput
      style={{
        maxWidth: "16rem",
      }}
      placeholder={"(000) 000-000"}
      // value={}
      saveChange={() => {}}
    />
  );
}

function EmailAddress({ pageId, sectionId, optionsType, options }) {
  return (
    <FormTextInput
      className={"max-w-xs"}
      placeholder={"Email"}
      // value={}
      saveChange={() => {}}
    />
  );
}

function DateTimePicker({ pageId, sectionId, optionsType, options }) {
  return (
    <div className="flex gap-5">
      <FormTextInput
        className=""
        placeholder="Date"
        // value={}
        saveChange={() => {}}
      />
      <FormTextInput
        className=""
        placeholder="Time"
        // value={}
        saveChange={() => {}}
      />
    </div>
  );
}

function DatePicker({ pageId, sectionId, optionsType, options }) {
  return (
    <FormTextInput
      className="max-w-min"
      placeholder="Date"
      // value={}
      saveChange={() => {}}
    />
  );
}

function TimePicker({ pageId, sectionId, optionsType, options }) {
  return (
    <FormTextInput
      className="max-w-min"
      placeholder="Time"
      // value={}
      saveChange={() => {}}
    />
  );
}

function NumberPicker({ pageId, sectionId, optionsType, options }) {
  return (
    <FormTextInput
      className="max-w-min"
      placeholder="Number"
      // value={}
      saveChange={() => {}}
    />
  );
}

function FileUpload({ pageId, sectionId, optionsType, options }) {
  const { state, dispatch } = useContext(FormBuilderContext);

  const handleToggleOption = (optionOrder, optionLabel) => {
    const payloadArray = options.some((option)=>option === optionLabel) ?
      options.filter((option)=>option !== optionLabel) :
      [
        ...options,
        optionLabel
      ].sort((a,b)=>{
        const orderA = uploadChoices.find((choice) => choice.label === a)?.order || 0;
        const orderB = uploadChoices.find((choice) => choice.label === b)?.order || 0;
        return orderA - orderB;
      })

    mutationActionHandler(
      {
        query: mUpdateOptions,
        variables: {
          pageId: pageId,
          id: sectionId,
          options: payloadArray,
        }
      },
      (response)=>{
        //console.log(response)
        dispatch({
          type: "TOGGLE_OPTION",
          pageId: pageId,
          sectionId: sectionId,
          payload: payloadArray,
        })

      }
    )

  }

  const uploadChoices = [
    { order: 1, label: "CSV / Excel" },
    { order: 2, label: "Text / HTML Files" },
    { order: 3, label: "Documents" },
    { order: 4, label: "Media (Images / Videos / Audio)" },
    { order: 5, label: "PDF" },
  ];

  return (
    <div
      className="grid auto-cols-auto grid-cols-2 max-w-md gap-y-2"
      style={{
        gridTemplateColumns: "1fr 2fr",
      }}
    >
      {uploadChoices.map((uploadChoice) => (
        <FormCheckboxLabel
          key={uploadChoice.order}
          label={uploadChoice.label}
          checkBoxStyle="gray"
          checked={options.some((option) => option == uploadChoice.label)}
          onChange={() =>
            handleToggleOption(uploadChoice.order, uploadChoice.label)
          }
        />
      ))}
    </div>
  );
}

function Signature({ pageId, sectionId, optionsType, options }) {
  return <div className="w-full h-44 border rounded-md"></div>;
}

//================================================
// Reusable Components
//================================================

// Handles the option types where you can add/delete items
function MultipleOptionsFactory({
  pageId,
  sectionId,
  options,
  optionsType,
  handleChangeOptionText,
}) {
  const { state, dispatch } = useContext(FormBuilderContext);

  const handleAddOption = () => {
    mutationActionHandler(
      {
        query: mUpdateOptions,
        variables: {
          pageId: pageId,
          id: sectionId,
          options: [
            ...options,
            ''
          ],
        }
      },
      (response)=>{
        //console.log(response)
        dispatch({
          type: "ADD_OPTION",
          pageId: pageId,
          sectionId: sectionId,
        })

      }
    )
  };

  const handleDeleteOption = (optionOrder) => {
    mutationActionHandler(
      {
        query: mUpdateOptions,
        variables: {
          pageId: pageId,
          id: sectionId,
          options: options.filter((option, index)=> index !== optionOrder),
        }
      },
      (response)=>{
        //console.log(response)
        dispatch({
          type: "DELETE_OPTION",
          pageId: pageId,
          sectionId: sectionId,
          optionOrder: optionOrder,
        })

      }
    )

  };



  return (
    <div className="w-full flex flex-col gap-2 ">
      {/* <AnimatePresence > */}
      {options.map((option, optionIndex) => (
        <div
          key={optionIndex}
          // transition={{duration:0.35}}
          // initial={{ scale: 0.5, opacity:0}}
          // animate={{ scale: 1, opacity:1}}
          // exit={{ scale:0.5, opacity:0}}
          className="flex items-center gap-2"
        >
          <div>
            {inputTypes[optionsType]?.optionIcon ? (
              <IconContext.Provider
                value={{ className: "text-xl text-gray-400" }}
              >
                {inputTypes[optionsType].optionIcon}
              </IconContext.Provider>
            ) : (
              <p className="w-5">{optionIndex + 1}.</p>
            )}
          </div>
          <FormTextInput
            className="flex-1"
            placeholder={`Option ${optionIndex + 1}`}
            value={option}
            saveChange={(e) =>
              handleChangeOptionText(optionIndex, e.target.value)
            }
          />
          <button onClick={() => handleDeleteOption(optionIndex)}>
            <IoClose className="text-gray-500" />
          </button>
        </div>
      ))}
      {/* </AnimatePresence> */}
      <button onClick={handleAddOption}>Add Option</button>
    </div>
  );
}

// Custom styled white card with light gray border
function FormCard({ children }) {
  return (
    <div
      className="w-full p-10 flex flex-col gap-5  bg-white border rounded-lg"
      style={{
        maxWidth: "50rem",
      }}
    >
      {children}
    </div>
  );
}

// Custom styled Text Input
function FormTextInput({ className, style, placeholder, value, saveChange }) {
  // Added this local state to prevent the input from returning a blank value
  const [inputValue, setInputValue] = useState(value);
  const previousInputValue = usePrevious(value);

  useEffect(()=>{
    setInputValue(value)
  },[value])

  return (
    <input
      className={`p-2 border rounded-md ${className}`}
      style={style}
      type="text"
      placeholder={placeholder}
      value={inputValue || ''}
      onBlur={saveChange}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
}

// Custom styled Text Area
function FormTextArea({ placeholder, value, saveChange, rows }) {
  // Added this local state to prevent the input from returning a blank value
  const [inputValue, setInputValue] = useState(value);
  const previousInputValue = usePrevious(value);

  useEffect(()=>{
    setInputValue(value)
  },[value])

  return (
    <textarea
      className="p-2 border rounded-md resize-none"
      type="text"
      placeholder={placeholder}
      value={inputValue || ''}
      rows={rows || 2}
      onBlur={saveChange}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
}

// Custom styled checkbox with label on the right side
// Used by properties, name input, and file upload input
function FormCheckboxLabel({
  className,
  checkBoxStyle,
  checked,
  onChange,
  label,
}) {
  const checkedStyle = {
    gray: "bg-gray-400 border-gray-400",
    cyan: "bg-cyan-400 border-cyan-400",
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <input
        type="checkbox"
        className={`flex-none appearance-none text-blue-600 w-4 h-4 border rounded-sm cursor-pointer
          ${
            checked
              ? checkedStyle[checkBoxStyle || "gray"]
              : "bg-white border-gray-400"
          } 
          ${checkBoxStyle}
          `}
        checked={checked}
        onChange={onChange}
      />
      <label>{label}</label>
      <svg
        className={`absolute w-3 h-3 ml-0.5 pointer-events-none ${
          checked ? "block" : "hidden"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </div>
  );
}
