import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { IoCaretDown } from "react-icons/io5";
import { MdSave } from "react-icons/md";
import { API } from "aws-amplify";
import ToastNotification from "../../toast-notification";
import { isMobileDevice } from "../../../shared/mobileViewFunctions";
import humaReadableFormat from "../../../shared/humanReadableFormat";
import { GetUserCM, ListClientMatters } from "../../../shared/graphql/queries";
import { Switch } from "@headlessui/react";
import _, { cloneDeep } from "lodash";

export default function AboutTab({
  close,
  user,
  proxyUser,
  isEditing,
  toEditid,
  ContactList,
  setContactList,
  UserTypes,
  width,
  handleSave,
  Firstname,
  setFirstname,
  Lastname,
  setLastname,
  Address,
  setAddress,
  Email,
  setEmail,
  UserType,
  setUserType,
  Mobile,
  setMobile,
  Company,
  setCompany,
  BusinessName,
  setBusinessName,
  isDisabled,
  setisDisabled,
  hasPortalAccessState,
  sethasPortalAccessState,
  aboutShowToast,
  setAboutShowToast,
  resultMessage,
  setResultMessage,
  hideToast,
  isSaving,
  setIsSaving,
  UserClientMatter,
  setUserClientMatter,
  setoriginaluserClientMatter,
}) {
  const userTypeOptions = UserTypes.map((item) => ({
    ...item,
    label: humaReadableFormat(
      item.label
        .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
        .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
    ),
  }));

  const ChangesHaveMade = (obj) => {
    if (
      obj.lastName !== Lastname ||
      obj.firstName !== Firstname ||
      obj.email !== Email ||
      obj.contactNumber !== Mobile ||
      obj.businessName !== BusinessName ||
      obj.userType !== UserType.value ||
      obj.hasPortalAccess !== hasPortalAccessState
    ) {
      return false;
    } else return true;
  };

  useEffect(() => {
    console.log("USER", proxyUser.current);
    console.log("Business", proxyUser.current.businessName);
    setCompany(localStorage.getItem("company"));
  }, []);

  useEffect(() => {
    setisDisabled(ChangesHaveMade(user));
  }, [
    Firstname,
    Lastname,
    Company,
    Email,
    Lastname,
    UserType,
    Address,
    Mobile,
    proxyUser,
    BusinessName,
    hasPortalAccessState,
  ]);

  const SaveButton = () => {
    return (
      <button
        onClick={() => {
          handleSave();
          setIsSaving(true);
        }}
        className={`border border-gray-200 ml-auto rounded-md text-white flex flex-row justify-center items-center gap-2font-normal px-6 py-1.5 mt-2 hover:bg-green-200 gap-2 ${
          isDisabled ? "bg-green-200" : "bg-green-400"
        }`}
        disabled={isDisabled}
      >
        Save <MdSave color={`white`} />
      </button>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IoCaretDown className="text-sm" />
        </components.DropdownIndicator>
      )
    );
  };

  useEffect(() => {
    //Sets the values for the non-editing state
    if (!isEditing) {
      console.log("user", user);
      console.log("proxy", proxyUser.current);

      setFirstname(user?.firstName ?? "");
      setLastname(user?.lastName ?? "");
      setEmail(user?.email ?? "");
      setMobile(user?.contactNumber ?? "");
      setUserType({
        value: user?.userType ?? "",
        label: user?.userType ?? "",
      });
      setBusinessName(user?.businessName ?? "");
      sethasPortalAccessState(user?.hasPortalAccess !== false);

      //Resets the proxyUser to the 'previous' data
      proxyUser.current = _.cloneDeep(user);
    }
  }, [isEditing]);

  //console.log(proxyUser.current, user);

  const handleDataChange = async (e, type) => {
    /*
     * I've tried using a switch statement, but compared to this
     * implementation, they don't function the same. Switch statement
     * weirdly destroys the ability of maintaining states between tabs
     * (About, Teams, Client/Matter)
     * (MMA-1942)
     *
     */
    if (type === "firstName") {
      setFirstname(e.target.value);
      proxyUser.current.firstName = e.target.value;
    }

    if (type === "lastName") {
      setLastname(e.target.value);
      proxyUser.current.lastName = e.target.value;
    }

    if (type === "email") {
      setEmail(e.target.value);
      proxyUser.current.email = e.target.value;
    }

    if (type === "contactNumber") {
      setMobile(e.target.value);
      proxyUser.current.contactNumber = e.target.value;
    }

    if (type === "userType") {
      setUserType(e);
      proxyUser.current.userType = e.value;
      let clientMatterResult = await ListClientMatters(
        proxyUser.current.company.id
      );
      console.log(
        "CLIENT MATTER RESULT IS:",
        clientMatterResult.data.company.clientMatters.items,
        "--",
        e.value
      );
      getUserClientMatter();
      let temp = [];
      clientMatterResult.data.company.clientMatters.items.forEach(
        (item, index) => {
          temp.push({ clientMatter: item, userType: e.value });
        }
      );
      console.log("TEMP IS HERE:", temp);
      setUserClientMatter(temp);
    }

    if (type === "businessName") {
      setBusinessName(e.target.value);
      proxyUser.current.businessName = e.target.value;
    }

    if (type === "access") {
      sethasPortalAccessState(e);
      proxyUser.current.hasPortalAccess = e;
    }
  };

  const getUserClientMatter = async (e) => {
    const { data } = await GetUserCM(
      proxyUser.current.id,
      proxyUser.current.company.id
    );

    const clientMatters = data?.user?.clientMatterAccess?.items ?? [];

    const userClientMatter = clientMatters.map(
      ({ userType, clientMatter }) => ({
        userType,
        clientMatter,
      })
    );

    // setUserClientMatter(userClientMatter);
    // console.log("USER CLIENT MATTER CURRENT IS:", userClientMatter);

    //To make a deep copy of the userClientMatter, and later enable cancelling of changes.
    setoriginaluserClientMatter(cloneDeep(userClientMatter));
  };

  return (
    <>
      <div className="flex flex-col">
        <div
          className={
            "flex " + (isMobileDevice(width) ? "flex-col" : "flex-row")
          }
        >
          <div className={"flex flex-col p-1"}>
            <div className="text-xs font-medium text-gray-400">
              {`First Name`}
            </div>
            <input
              // readOnly={isEditing ? "" : "0"}
              disabled={isEditing ? false : true}
              name={`firstname`}
              type="text"
              value={Firstname}
              className={
                "rounded-md p-2 border border-gray-300 outline-0 " +
                (isMobileDevice(width) ? "w-full" : "w-80")
              }
              onChange={(e) => handleDataChange(e, "firstName")}
            />
          </div>
          <div className="flex flex-col p-1">
            <div className="text-xs font-medium text-gray-400">{`Last Name`}</div>
            <input
              // readOnly={isEditing ? "" : "0"}
              disabled={isEditing ? false : true}
              name={`lastname`}
              type="text"
              value={Lastname}
              className={
                "rounded-md p-2 border border-gray-300 outline-0 " +
                (isMobileDevice(width) ? "w-full" : "w-80")
              }
              onChange={(e) => handleDataChange(e, "lastName")}
            />
          </div>
        </div>
        {/* <div className="flex flex-col p-1">
          <div className="text-xs font-medium text-gray-400">{`Address`}</div>
          <input
            // readOnly={isEditing ? "" : "0"}
            disabled={isEditing ? false : true}
            name={`address`}
            type="text"
            value={Address}
            className="rounded-md p-2 border border-gray-300 outline-0 w-full"
            onChange={(e) => setAddress(e.target.value)}
          />
        </div> */}

        <div
          className={
            "flex " + (isMobileDevice(width) ? "flex-col" : "flex-row")
          }
        >
          <div className="flex flex-col p-1">
            <div className="text-xs font-medium text-gray-400">{`Email`}</div>
            <input
              // readOnly={isEditing ? "" : "0"}
              disabled
              name={`email`}
              type="email"
              value={Email}
              className={
                "rounded-md p-2 border border-gray-300 outline-0 " +
                (isMobileDevice(width) ? "w-full" : "w-80")
              }
              onChange={(e) => handleDataChange(e, "email")}
            />
          </div>
          <div className="flex flex-col p-1">
            <div className="text-xs font-medium text-gray-400">
              {`Mobile Number`}
            </div>
            <input
              // readOnly={isEditing ? "" : "0"}
              disabled={isEditing ? false : true}
              name={`mobile`}
              type="text"
              value={Mobile}
              className={
                "rounded-md p-2 border border-gray-300 outline-0 " +
                (isMobileDevice(width) ? "w-full" : "w-80")
              }
              onChange={(e) => handleDataChange(e, "contactNumber")}
            />
          </div>
        </div>

        <div
          className={
            "flex " + (isMobileDevice(width) ? "flex-col" : "flex-row")
          }
        >
          <div className="flex flex-col p-1 relative">
            <div className="text-xs font-medium text-gray-400">{`User Type`}</div>
            <Select
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator,
              }}
              menuPortalTarget={document.body}
              styles={{
                control: (styles, { isDisabled }) => {
                  return {
                    ...styles,
                    cursor: isDisabled ? "not-allowed" : "default",
                    backgroundColor: "white",
                    color: "black",
                  };
                },
                container: (base) => ({
                  ...base,
                  zIndex: "99999",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: "7rem",
                }),
              }}
              isDisabled={isEditing ? false : true}
              options={userTypeOptions}
              name={`usertype`}
              type="text"
              value={UserType}
              className={
                "outline-0 " + (isMobileDevice(width) ? "w-full" : "w-80")
              }
              onChange={(e, val) => {
                console.log("value", e);
                handleDataChange(e, "userType");
              }}
            />
          </div>
          <div className="flex flex-col p-1">
            <div className="text-xs font-medium text-gray-400">{`Business`}</div>
            <input
              // readOnly={isEditing ? "" : "0"}
              disabled={isEditing ? false : true}
              name={`businessName`}
              type="text"
              value={BusinessName}
              className={
                "rounded-md p-2 border border-gray-300 outline-0 " +
                (isMobileDevice(width) ? "w-full" : "w-80")
              }
              onChange={(e) => handleDataChange(e, "businessName")}
            />
          </div>
        </div>

        <div
          className={
            "flex " + (isMobileDevice(width) ? "flex-col" : "flex-row")
          }
        >
          <div className="flex flex-col p-1">
            <div className="text-xs font-medium text-gray-400 mb-2 mt-3">{`Give access to the portal`}</div>

            <Switch
              disabled={isEditing ? false : true}
              checked={hasPortalAccessState}
              onChange={(e) => {
                handleDataChange(!hasPortalAccessState, "access");
              }}
              className={`${
                hasPortalAccessState
                  ? `cursor-pointer ${
                      isEditing ? "bg-green-500" : "bg-green-200"
                    }`
                  : "bg-gray-300"
              } relative inline-flex h-5 w-8 items-center rounded-full`}
            >
              <span
                className={`${
                  hasPortalAccessState ? "translate-x-4" : "translate-x-1"
                } inline-block h-3 w-3 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </div>
      </div>
      {isEditing && <SaveButton />}
      {aboutShowToast && resultMessage && (
        <ToastNotification title={resultMessage} hideToast={hideToast} />
      )}
    </>
  );
}
