import React, { useState } from 'react';

const SearchBar = (props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = event => {
    props.handleChange(event.target.value);
    setSearchTerm(event.target.value);
  };

  return (
      <input
        type="text"
        placeholder="Search for Labels..."
        value={searchTerm}
        onChange={handleChange}
        className="px-2 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pl-10"
      />
  );
};

export default SearchBar;
